import React, { useState, useMemo } from 'react';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import {
  Tiles,
  Table,
} from 'components/templates/rpm/practice/patientsStatusReport';
import AutoCompleteClinics from 'components/molecules/autocompleteFilter/clinic';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import Pagination from 'components/molecules/paginationTransition';
import { showResult } from 'infrastructure/hooks/utils';
import useTable from 'utils/useTable';
import common from 'constants/common';
import {
  API_PATIENTS_STATUS_REPORT,
  API_PATIENTS_STATUS_COUNT,
  PATIENTS_STATUS_CSV_REPORT,
} from 'services/dataService/resources';
import dataService from 'services/dataService';
import withLoader from 'components/organisms/withLoader';
import forms from 'constants/forms';
import { skipEmptyStrings } from 'utils/queryHelpers';
import AutoCompleteDoctorMultiSelect from 'components/molecules/autocompleteFilter/doctorMulti';
import BaseSelect from 'components/atoms/baseSelect';
import { useTranslation } from 'react-i18next';
import { useClinicInfo } from 'infrastructure/hooks/clinic/use-clinic-info';

const DEFAULT_CLINIC_FILTER = forms.clinicFilterOptions[0];
const DEFAULT_CHILD_CLINIC = forms.childClinicSelect[0];
const PER_PAGE = 100;

const PatientsStatusReport = () => {
  const [csvLoading, setCsvLoading] = useState(false);
  const {
    data: statuses,
    loading: statusesLoading,
    setFilters: setFiltersCount,
    filters: filtersCount,
  } = useTable(API_PATIENTS_STATUS_COUNT, null, {});

  const {
    data: patients,
    loading,
    page,
    setPage,
    filters,
    setFilters: setFiltersData,
  } = useTable(API_PATIENTS_STATUS_REPORT, PER_PAGE, {
    statusUpdatedDateSort: 'asc',
  });
  const [selectedClinic, setSelectedClinic] = useState('');
  const { clinicInfo, loading: clinicInfoLoading } = useClinicInfo({
    clinicGuid: selectedClinic,
  });

  const patientStatuses = useMemo(() => {
    if (!statuses) return;
    const orderedStatuses = {
      patient_referral: [statuses.patient_referral],
      referral_on_hold: [statuses.referral_on_hold],
      referral_withdrawn: [statuses.referral_withdrawn],
      calling_queue: [statuses.calling_queue],
      onboarding: [statuses.onboarding],
      onboarding_unsuccessful: [statuses.onboarding_unsuccessful],
      patient_refused: [statuses.patient_refused],
      insurance_refused: [statuses.insurance_refused],
      patient_no_contact: [statuses.patient_no_contact],
      active: [statuses.active],
      pending_review: [statuses.pending_review],
      inactive_patient: [statuses.inactive_patient],
    };
    return orderedStatuses;
  }, [statuses]);

  const sendReport = async () => {
    setCsvLoading(true);
    const doctorGuids = filters?.doctorGuid?.split(',') || undefined;
    const cleanedDoctorGuids =
      doctorGuids && doctorGuids.filter((guid) => guid !== '');

    const postData = {
      ...filters,
      doctorGuid: undefined,
      doctorGuids: cleanedDoctorGuids,
    };
    const { error } = await dataService.createOne(
      PATIENTS_STATUS_CSV_REPORT,
      skipEmptyStrings(postData),
    );
    setCsvLoading(false);
    return showResult(error, common.submitSuccessTypes.sendReport);
  };

  const handleClinicChange = ({ value }) => {
    setSelectedClinic(value);

    setFiltersData({
      ...filters,
      clinicGuid: value,
      childClinic: true,
    });
    setFiltersCount({
      ...filtersCount,
      clinicGuid: value,
      childClinic: true,
    });
  };

  const { t } = useTranslation();

  return (
    <Container className="patients-status-report">
      {withLoader(
        statusesLoading,
        <Tiles
          tiles={patientStatuses}
          filters={filters}
          setFilter={(statusFilter) =>
            setFiltersData({ ...filters, ...statusFilter })
          }
        />,
      )}
      <Card className="report card-border card--full-width">
        <CardBody className="card-outer">
          <div className="performance-review__header">
            <h3 className="report__header-text" data-cy="status-report__title">
              Report: Patients Status
            </h3>
            <div className="performance-review__header-icon">
              {withLoader(
                csvLoading,
                <button onClick={sendReport}>
                  <DownloadRoundedIcon className="performance-review__header-icon-svg" />
                  <br />
                  <span>Download</span>
                </button>,
              )}
            </div>
          </div>
          <Row>
            <Col xl="3" xs="12" md="6" className="report__filter">
              <p
                className="report__filter-title"
                data-cy="status-report__clinic-filter-label"
              >
                Clinic
              </p>
              <AutoCompleteClinics
                filter={DEFAULT_CLINIC_FILTER}
                handleClick={handleClinicChange}
              />
            </Col>

            <Col xl="3" xs="12" md="6" className="report__filter">
              <p
                className="report__filter-title"
                data-cy="status-report__clinic-filter-label"
              >
                Provider
              </p>
              <AutoCompleteDoctorMultiSelect
                clinicGuid={filters.clinicGuid}
                onChange={(value) => {
                  setFiltersData({ ...filters, doctorGuid: value });
                  setFiltersCount({ ...filtersCount, doctorGuid: value });
                }}
              />
            </Col>

            {clinicInfo?.childClinics.length > 0 && !clinicInfoLoading && (
              <Col xl="2" xs="12" md="3" className="report__filter">
                <p
                  className="report__filter-title"
                  data-cy="status-report__clinic-filter-label"
                >
                  {t('labels.childClinics')}
                </p>
                <BaseSelect
                  options={forms.childClinicSelect}
                  defaultValue={DEFAULT_CHILD_CLINIC}
                  classNamePrefix="select-type"
                  onChange={({ value }) => {
                    setFiltersData({ ...filters, childClinic: value });
                    setFiltersCount({ ...filtersCount, childClinic: value });
                  }}
                />
              </Col>
            )}
          </Row>
          {withLoader(
            loading,
            <>
              <Table
                data={patients?.items}
                setFilters={(sorts) => setFiltersData({ ...filters, ...sorts })}
                filters={filters}
              />
              <div className="report__pagination-button flex-row-reverse">
                <Pagination
                  title=""
                  page={page}
                  pageTotal={patients?.items?.length}
                  itemsPerPage={PER_PAGE}
                  total={patients?.count}
                  handleFirstClick={() => setPage(1)}
                  handleLastClick={() =>
                    setPage(Math.ceil(patients?.count / PER_PAGE))
                  }
                  handlePrevClick={() => setPage(page - 1)}
                  handleNextClick={() => setPage(page + 1)}
                  handleGoToPage={(numberPage) => setPage(numberPage)}
                />
              </div>
            </>,
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default PatientsStatusReport;
