import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const IRS = ({ data }) => (
  <Table borderless border="0" cellSpacing="0" cellPadding="0">
    <thead>
      <tr>
        <th colSpan="2">
          <div className="pagination-container">
            <h5>IRS</h5>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td />
        <td>
          {data.npiNumber && (
            <>
              <span className="subheader">NPI: </span>
              <span data-cy="irs-npi">{data.npiNumber}</span>
            </>
          )}
          <>
            <span className="subheader">User Alert Email: </span>
            <span data-cy="user-alert-email">
              {data.alertEmail ? 'Yes' : 'No'}
            </span>
          </>
          <>
            <span className="subheader">User Alert Sms: </span>
            <span data-cy="user-alert-sms">{data.alertSms ? 'Yes' : 'No'}</span>
          </>
        </td>
      </tr>
    </tbody>
  </Table>
);

IRS.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default IRS;
