import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { showResult } from 'infrastructure/hooks/utils';

import { updateConsumablesDateFn } from './api';

interface Props {
  onSuccess: () => void;
}

export const useUpdateConsumablesDate = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: updateConsumablesDateFn,
    onSuccess(data, variables) {
      const patientInfoKey = getPatientInfoKey(variables.patientGuid);
      queryClient.setQueryData(patientInfoKey, (oldData: any) => {
        if (oldData) {
          return {
            ...oldData,
            consumablesArrivingDate: null,
          };
        }
      });

      showResult();
      onSuccess();
    },
  });

  return {
    updateConsumablesDate: mutateAsync,
    consumablesDateLoading: isLoading,
  };
};
