import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_PATIENT_DIAGNOSES } from 'services/dataService/ccmResources';
import { useAppSelector } from 'store';
import { DateFormats } from 'infrastructure/enums';
import { showResult } from 'infrastructure/hooks/utils';
import { DateFormatter } from 'infrastructure/functions';

import { getConditionsKey, CONDITIONS_PER_PAGE } from './use-get-conditions';

import type { ICondition } from 'infrastructure/interfaces';

interface Props {
  page: number;
  patientGuid: string;
}

export const deleteConditionFn = async (guid: string, patientGuid: string) => {
  const { data, error } = await dataService.deleteObject<ICondition>(
    `${API_PATIENT_DIAGNOSES}/${guid}`,
    { patientGuid },
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useDeleteCondition = ({ page, patientGuid }: Props) => {
  const [startTime, endTime] = useAppSelector<[Date, Date] | []>(
    (state) => state.ccmPatientDetails.dates,
  );
  const queryClient = useQueryClient();

  const filter = {
    page,
    items: CONDITIONS_PER_PAGE,
    startTime: DateFormatter({
      date: startTime,
      format: DateFormats['yyyy-MM-dd HH:mm:s'],
    }),
    endTime: DateFormatter({
      date: endTime,
      format: DateFormats['yyyy-MM-dd HH:mm:s'],
    }),
  };

  const {
    mutate: deleteCondition,
    mutateAsync: deleteConditionAsync,
    isLoading,
    ...other
  } = useMutation({
    mutationFn: async (guid: string) => deleteConditionFn(guid, patientGuid),
    onSuccess() {
      const queryKey = getConditionsKey({
        ...filter,
        patientGuid,
      });
      queryClient.invalidateQueries(queryKey);
      showResult();
    },
  });

  return {
    deleteCondition,
    deleteConditionAsync,
    deleteLoading: isLoading,
    ...other,
  };
};
