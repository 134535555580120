import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/atoms/button';
import * as Yup from 'yup';
import { scrollToErrorIfExist } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';
import { Formik, Form } from 'formik';
import { Card, CardBody, Row, Col } from 'reactstrap';
import Insurances from 'components/molecules/adminUsers/insurances';
import Devices from 'components/molecules/adminUsers/devices';
import DxCodes from 'components/molecules/adminUsers/dxCodes';
import Clinic from 'components/molecules/adminUsers/clinic';
import Physician from 'components/molecules/adminUsers/physician';
import common from 'constants/common';
import { phoneRegex } from 'constants/regex';
import { rpmAndCcmClaimOptions } from 'constants/forms';

import PersonalInfo from './components/personalInfo';
import ContactInfo from './components/contactInfo';
import { formatDevices } from '../../../../utils/patientDeviceStatus';
import Input from '../../../atoms/input';
import consts from '../../../../constants/common';
import AccountInfo from './components/accountInfo';

const AddPatient = () => {
  const clinics = useSelector((state) => state.clinics.data);
  const doctors = useSelector((state) => state.adminPanelPatients.doctors);
  const { createPatient: createPatientLoading } = useSelector(
    (state) => state.loading.effects.adminPanelPatients,
  );
  const navigate = useNavigate();

  const {
    clinics: { getClinics },
    adminPanelPatients: { createPatient, getDoctors },
  } = useDispatch();

  useEffect(() => {
    getClinics();
    getDoctors({
      clinicGuid: undefined,
    });
  }, []);

  const PatientSchema = Yup.object().shape({
    title: Yup.string().required('required'),
    firstName: Yup.string()
      .required('required')
      .test(
        'is-not-only-spaces',
        'First Name is not valid',
        (value) => !!value && value?.trim().length > 0,
      ),
    lastName: Yup.string()
      .required('required')
      .test(
        'is-not-only-spaces',
        'Last Name is not valid',
        (value) => !!value && value?.trim().length > 0,
      ),
    middleName: Yup.string(),
    birthDate: Yup.string()
      .matches(
        /^(0[1-9]|1[012])[-]([012][0-9]|3[01])[-]([0-9]{4})$/,
        'Birth Date must be in the format MM-DD-YYYY.',
      )
      .required('required'),
    phone: Yup.string()
      .matches(phoneRegex, 'Phone number is not correct')
      .required('required'),
    workNumber: Yup.string()
      .matches(phoneRegex, 'Phone number is not correct')
      .nullable(),
    email: Yup.string()
      .matches(consts.emailRegex, 'Email is not correct')
      .required('required'),
    timezone: Yup.string().optional(),
    bloodGlucose: Yup.string(),
    bloodPressure: Yup.string(),
    weight: Yup.string(),
    pulmonary: Yup.string(),
    sleep: Yup.string(),
    allCodes: Yup.string(),
    clinicGuid: Yup.string().required('required'),
    doctorGuid: Yup.string().required('required'),
    insurances: Yup.array().of(
      Yup.object().shape({
        planName: Yup.string().nullable(),
        provider: Yup.string()
          .required('required')
          .test(
            'is-not-only-spaces',
            'Provider is not valid',
            (value) => !!value && value?.trim().length > 0,
          ),
        number: Yup.string()
          .required('required')
          .test(
            'is-not-only-spaces',
            'Number is not valid',
            (value) => !!value && value?.trim().length > 0,
          ),
        type: Yup.string().nullable(),
      }),
    ),
    devices: Yup.array().of(
      Yup.object().shape({
        manufacturer: Yup.string()
          .required('required')
          .test(
            'is-not-only-spaces',
            'Devica Name is not valid',
            (value) => !!value && value?.trim().length > 0,
          ),
        deviceId: Yup.string()
          .required('required')
          .test(
            'is-not-only-spaces',
            'Number is not valid',
            (value) => !!value && value?.trim().length > 0,
          ),
        readingTypes: Yup.array()
          .of(
            Yup.object().shape({
              value: Yup.string(),
              id: Yup.string(),
              label: Yup.string(),
            }),
          )
          .required('Reading type is required'),
      }),
    ),
    deviceOrderSpecifications: Yup.string(),
    auth0Enabled: Yup.boolean()
      .required()
      .test(
        'is-false',
        `This fields must be "no" when the email field is ends with ${common.disabledEmailDomain}`,
        // eslint-disable-next-line func-names
        function (value) {
          return (
            // eslint-disable-next-line react/no-this-in-sfc
            !value || !this.parent.email?.includes(common.disabledEmailDomain)
          );
        },
      ),
    rpmClaimStatus: Yup.string(),
    ccmClaimStatus: Yup.string(),
  });

  const processDiagnosis = (values) => {
    const diagnosis = {
      bloodGlucose: values.bloodGlucose?.trim(),
      bloodPressure: values.bloodPressure?.trim(),
      weight: values.weight?.trim(),
      pulmonary: values.pulmonary?.trim(),
      sleep: values.sleep?.trim(),
      allCodes: values.allCodes?.trim(),
    };
    if (Object.values(diagnosis).every((value) => !value)) {
      return undefined;
    }
    return diagnosis;
  };

  const handleSubmit = async (values) => {
    let devicesFormatted = [];
    if (values.devices) {
      devicesFormatted = JSON.parse(JSON.stringify(values.devices));
      for (let i = 0; i < devicesFormatted.length; i++) {
        devicesFormatted[i].readingTypes = formatDevices(
          devicesFormatted[i].readingTypes,
        );
      }
    }
    const postData = {
      patientInfo: {
        title: values.title,
        middleName: values.middleName?.trim(),
        firstName: values.firstName?.trim(),
        lastName: values.lastName?.trim(),
        deviceOrderSpecifications: values.deviceOrderSpecifications?.trim(),
        birthDate: values.birthDate,
        primaryNumber: values.phone,
        email: values.email,
        timezone: values.timezone,
        auth0Enabled: values.auth0Enabled,
        claimStatus: values.claimStatus,
        ccmClaimStatus: values.ccmClaimStatus,
      },
      diagnosisCodes: processDiagnosis(values),
      clinicGuid: values.clinicGuid,
      doctorGuid: values.doctorGuid,
      devices: devicesFormatted?.map((item) => ({
        ...item,
        manufacturer: item.manufacturer?.trim(),
        deviceId: item.deviceId?.trim(),
      })),
      patientInsurances: values.insurances?.map((item) => ({
        ...item,
        number: item.number?.trim(),
        planName: item.planName?.trim(),
      })),
    };

    const { data, error } = await createPatient(postData);
    showResult(error);

    if (data.patientGuid) {
      navigate(`/admin/patient/${data.patientGuid}`);
    }
  };

  return (
    <div className="admin-patient">
      <Card className="card-border">
        <CardBody>
          <span>Add Patient's page</span>
          <Formik
            initialValues={{
              title: '',
              birthDate: '',
              firstName: '',
              lastName: '',
              auth0Enabled: false,
              insurances: [],
              devices: [],
              claimStatus: rpmAndCcmClaimOptions[0].id,
              ccmClaimStatus: rpmAndCcmClaimOptions[0].id,
            }}
            validationSchema={PatientSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, values, setFieldValue }) => (
              <Form>
                <div className="admin-patient-form">
                  <Row>
                    <Col>
                      <PersonalInfo
                        setFieldValue={setFieldValue}
                        errors={errors}
                        values={values}
                      />
                      <ContactInfo errors={errors} />
                      <AccountInfo errors={errors} />
                      <Insurances values={values} errors={errors} />
                      <Devices values={values} errors={errors} />
                      <div className="admin-patient-form-block">
                        <span>Device Order Specifications</span>
                        <Row>
                          <Col sm="7" md="7" lg="7">
                            <Input
                              label="Device Order Specifications"
                              id="deviceOrderSpecifications"
                              type="text"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col>
                      <DxCodes errors={errors} />
                      <Clinic
                        errors={errors}
                        clinics={clinics}
                        values={values}
                        getDoctors={getDoctors}
                      />
                      <Physician
                        errors={errors}
                        doctors={doctors}
                        values={values}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="text-center">
                  <Button
                    type="submit"
                    text="Save"
                    data-cy="save-button"
                    disabled={createPatientLoading}
                    handleClick={scrollToErrorIfExist}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddPatient;
