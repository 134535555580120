const MissedCallIcon = (props) => (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 512 512"
    height="20"
    viewBox="0 0 512 512"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="m369.789 133.48 44.839 44.839 32.461-143.349-143.35 32.461 44.839 44.839-92.578 92.578-109.395-109.395-21.21 21.211 130.605 130.606z" />
      <path d="m256 270.945c-154.838 3.596-249.161 119.758-256 124.201l81.899 81.885 102.514-50.537 10.459-52.28c19.415-7.266 40.258-10.984 61.128-11.157 21.881-.179 44.792 3.539 65.116 11.157l10.459 52.28 100.913 50.537 79.512-79.512c-3.005-2.405-86.904-130.5-256-126.574z" />
    </g>
  </svg>
);

export default MissedCallIcon;
