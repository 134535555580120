import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { PatientDevice } from 'infrastructure/classes/patient/patient-device';

interface IAlertsTimeProps {
  data: PatientDevice;
}

const formatTime = (time: string) =>
  dayjs(time, 'hh:mm').format(DateFormats['h:mm a']);

const AlertsTime: React.FC<IAlertsTimeProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <div className={s['alerts-time']} data-cy="device-table-alerts-time">
      <div className={s.time}>
        <b>{t('labels.start')}: </b>
        {data.alarm && formatTime(data.alarm.start)}
        {data.alarm && data.alarmChange && data.alarmChange.start && <> - </>}
        {data.alarmChange?.start && <>({formatTime(data.alarmChange.start)})</>}
      </div>
      <div className={s.time}>
        <b>{t('labels.end')}: </b>
        {data.alarm && formatTime(data.alarm.end)}
        {data.alarm && data.alarmChange && data.alarmChange.end && <> - </>}
        {data.alarmChange?.end && <>({formatTime(data.alarmChange.end)})</>}
      </div>
    </div>
  );
};

export default AlertsTime;
