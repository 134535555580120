import getBloodPressureStatus from 'utils/getBloodPressureStatus';

const readingsUnits = {
  glucose: 'mg/dL',
  systolic: 'mmHg',
  diastolic: 'mmHg',
  pulse: 'bpm',
  weight: 'lbs',
  spo2: '%',
  fev1: 'L',
  pef: 'L/min',
  temperature: 'F',
};

export const hiddenReadingTypes = [
  'duration',
  'rem',
  'respiration',
  'bodyTurnOver',
  'lightSleep',
  'deepSleep',
  'bedTime',
  'getUpTime',
  'unit',
  'diastolic',
];

const readingTypePresenter = {
  pef: 'PEF',
  fev1: 'FEV1',
  systolic: 'Blood Pressure',
};

export const getReadingUnit = (readingType) => readingsUnits[readingType] || '';

export const getReadingInfo = (activity, readingType) => {
  const result = {
    key: readingTypePresenter[readingType] || readingType,
    value: activity.value[readingType],
    unit: getReadingUnit(readingType),
    className: `reading ${activity.statuses[readingType]}`,
  };

  if (readingType === 'systolic') {
    result.value = `${activity.value.systolic}/${activity.value.diastolic}`;
    result.className = `reading ${getBloodPressureStatus(
      activity.statuses.systolic,
      activity.statuses.diastolic,
    )}`;
  }

  if (readingType === 'spo2') {
    result.className = `reading ${activity.statuses.oxygen}`;
  }

  return result;
};
