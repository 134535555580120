import React from 'react';
import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminUserBlock from 'components/templates/admin/user';

const AdminUser = () => (
  <Container>
    <AdminHeader />
    <AdminUserBlock />
  </Container>
);

export default AdminUser;
