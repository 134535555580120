import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { showResult } from 'infrastructure/hooks/utils';

import { doNotCallFn } from './api';

import type { IPatient } from 'infrastructure/interfaces';

export const useDoNotCall = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: doNotCallFn,
    onSuccess(data, variables) {
      const patientInfoKey = getPatientInfoKey(variables.patientGuid);
      queryClient.setQueryData<IPatient>(patientInfoKey, (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            doNotCall: data.doNotCall,
          };
        }
      });
      showResult();
    },
  });

  return { updateDoNotCall: mutate, doNotCallLoading: isLoading };
};
