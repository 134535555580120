import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';

import { emailSubscriptionStatusFn } from './api';

import type { IPatient } from 'infrastructure/interfaces';

interface Props {
  patientGuid: string;
  onSuccess: () => void;
}
interface DataProps {
  reason: string;
}

export const useEmailSubscriptionStatus = ({
  patientGuid,
  onSuccess,
}: Props) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: async ({ reason }: DataProps) =>
      emailSubscriptionStatusFn({ reason, patientGuid }),
    onSuccess(data) {
      const patientInfoKey = getPatientInfoKey(patientGuid);
      queryClient.setQueryData<IPatient>(patientInfoKey, (oldData) => {
        if (oldData && data) {
          return {
            ...oldData,
            emailSubscription: data.subscription,
          };
        }
      });
      onSuccess();
      notificationController.success({
        message: 'Patient has been resubscribed to emails',
      });
    },
  });

  return {
    updateSubscriptionStatus: mutate,
    subscriptionStatusLoading: isLoading,
  };
};
