import React from 'react';

const StickyTemperatureYAxis = () => (
  <div className="custom-axis-container">
    <svg className="custom-axis-svg-container">
      <g
        transform="translate(0,10)"
        className="rv-xy-plot__axis rv-xy-plot__axis--vertical"
      >
        <line
          x1="40"
          x2="40"
          y1="0"
          y2="240"
          className="rv-xy-plot__axis__line"
        />
        <g transform="translate(45, 0)" className="rv-xy-plot__axis__ticks">
          <g transform="translate(0, 240)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.60em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              94
            </text>
          </g>
          <g transform="translate(0, 225)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.60em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              94.5
            </text>
          </g>
          <g transform="translate(0, 210)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.60em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              95
            </text>
          </g>
          <g transform="translate(0, 195)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.60em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              95.5
            </text>
          </g>
          <g transform="translate(0, 180)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.60em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              96
            </text>
          </g>
          <g transform="translate(0, 165)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              96.5
            </text>
          </g>
          <g transform="translate(0, 150)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              97
            </text>
          </g>
          <g transform="translate(0, 135)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              97.5
            </text>
          </g>
          <g transform="translate(0, 120)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              98
            </text>
          </g>
          <g transform="translate(0, 105)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              98.5
            </text>
          </g>
          <g transform="translate(0, 90)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              99
            </text>
          </g>
          <g transform="translate(0, 75)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              99.5
            </text>
          </g>
          <g transform="translate(0, 60)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              100
            </text>
          </g>
          <g transform="translate(0, 45)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              100.5
            </text>
          </g>
          <g transform="translate(0, 30)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              101
            </text>
          </g>
          <g transform="translate(0, 15)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              101.5
            </text>
          </g>
          <g transform="translate(0, 0)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              102
            </text>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export default StickyTemperatureYAxis;
