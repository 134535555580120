import React from 'react';
import BaseGrid from 'components/atoms/base-grid';

import PatientLogTabs from '../../log-tabs';
import s from './styles.module.scss';

import type { ModuleType } from 'infrastructure/enums';

interface IAdminTabbedFormLogsProps {
  patientGuid: string;
  module: ModuleType;
}

const AdminTabbedFormLogs: React.FC<IAdminTabbedFormLogsProps> = (props) => {
  const { patientGuid, module } = props;

  return (
    <BaseGrid columns={1} columnGap={40} className={s['logs-wrapper']}>
      <PatientLogTabs patientGuid={patientGuid} module={module} />
    </BaseGrid>
  );
};

export default AdminTabbedFormLogs;
