import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';
import { fullNamePresenter } from 'utils';

import type { IAdminPanelSearchPatientResponse } from 'infrastructure/interfaces';

interface IPostData {
  searchValue: string;
  searchKey: string;
}

const aminPanelSearchPatientsFn = async ({
  searchValue,
  searchKey,
}: IPostData) => {
  const { data, error } =
    await dataService.getList<IAdminPanelSearchPatientResponse>(
      apiUrlGenerator('core/search/patients', {
        getInactiveClinicData: true,
        isPrimaryDoctorRequired: true,
        items: 100,
        [searchKey]: searchValue,
      }),
    );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  const preparedData =
    data?.data?.map((patient) => ({
      value: patient.guid,
      label: fullNamePresenter({
        firstName: patient.firstName,
        middleName: patient.lastName,
        lastName: `- ${patient.birthDate}`,
      }),
    })) ?? [];

  if (data) {
    return { data: preparedData, count: data.count };
  }
};

export const useAminPanelSearchPatients = () => {
  const { mutate, data, isLoading, ...other } = useMutation({
    mutationFn: aminPanelSearchPatientsFn,
  });

  return {
    searchPatients: mutate,
    patients: data,
    searchPatientsLoading: isLoading,
    ...other,
  };
};
