import React, { forwardRef, useImperativeHandle } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseGrid from 'components/atoms/base-grid';
import BaseTextArea from 'components/atoms/base-textarea';
import ICDCodeSelectBox from 'components/molecules/icd-code-select-box';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import { usePatientIcdCode } from 'infrastructure/hooks/patient/use-patient-icd-code';

import { conditionsValidationSchema } from './validation-schema';

import type { ICreatePatientIcdCode } from 'infrastructure/interfaces';

export interface IConditionCreateFormRef {
  submit: () => Promise<void>;
  reset: () => void;
}

interface IConditionCreateFormProps {
  patientGuid: string;
  onCancel?: () => void;
  onSaved?: () => void;
}

const ConditionCreateForm = forwardRef<
  IConditionCreateFormRef,
  IConditionCreateFormProps
>((props, ref) => {
  const { patientGuid, onCancel, onSaved } = props;

  const { t } = useTranslation();

  const { loading, createPatientIcdCode } = usePatientIcdCode({ patientGuid });

  const formik = useFormik<ICreatePatientIcdCode>({
    initialValues: {
      patientGuid,
      description: '',
      diagnoseGuid: '',
    },
    onSubmit: async (values) => {
      await createPatientIcdCode(values).then(() => {
        if (onSaved) onSaved();
      });
    },
    validateOnChange: false,
    validationSchema: conditionsValidationSchema(),
  });

  const { submitForm, resetForm } = formik;

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: resetForm,
  }));

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseForm
        loading={loading}
        formik={formik}
        plaintext={false}
        readonly={false}
        onSave={submitForm}
        onCancel={onCancel}
        withControls
      >
        <BaseGrid columns={1}>
          <BaseFormItem name="diagnoseGuid" label={t('labels.icdCode')}>
            <ICDCodeSelectBox
              onChange={(value, option) => {
                formik.setFieldValue('diagnoseGuid', value);
                formik.setFieldValue('description', option.record?.description);
              }}
            />
          </BaseFormItem>
          <BaseFormItem name="description" label={t('labels.description')}>
            <BaseTextArea />
          </BaseFormItem>
        </BaseGrid>
      </BaseForm>
    </BaseSpinWrapper>
  );
});

ConditionCreateForm.displayName = 'ConditionCreateForm';

export default ConditionCreateForm;
