import common from 'constants/common';
import format from 'date-fns/format';

const MIN_AMOUNT = 16;
const calculatePercent = (number, total) => Math.round((number / total) * 100);

export const toFixedNumber = (number) => Number(number.toFixed(2));

const getSleepMatDuration = ({ lightSleep, rem, deepSleep }) => {
  const total = Number(lightSleep) + Number(rem) + Number(deepSleep);
  return toFixedNumber(total / 60);
};

const offsetify = (t) => (t < 10 ? `0${t}` : t);

export const getAllDurations = (data) => {
  const durations = [];

  data.forEach((e) => {
    const { dateTime } = e;
    const formattedDateTime = format(
      Number(dateTime),
      common.dateFormats.chartXDate,
    );

    const readingWithSameDate = durations.find(
      (x) => x.formattedDateTime === formattedDateTime,
    );

    if (readingWithSameDate) {
      readingWithSameDate.value =
        getSleepMatDuration(e.data) + readingWithSameDate.value;
    } else {
      durations.push({ value: getSleepMatDuration(e.data), formattedDateTime });
    }
  });

  return durations.map((e) => e.value);
};

const getTimeByDuration = (duration) => {
  const [hours, m] = duration.toFixed(2).split('.') || '00.00';
  const minutes = Math.round((Number(m) / 100) * 60);
  return `${hours}hr ${offsetify(minutes)}min`;
};

export const normalizeChartData = (chartData) => {
  const normalizedData = [];

  chartData.forEach((e) => {
    const { dateTime, data } = e;
    const { deepSleep, lightSleep, rem, pulse, respiration, bodyTurnOver } =
      data;

    const formattedDateTime = format(
      Number(dateTime),
      common.dateFormats.chartXDate,
    );

    const readingWithSameDate = normalizedData.find(
      (x) => x.formattedDateTime === formattedDateTime,
    );

    const duration =
      Number(lightSleep) +
      Number(rem) +
      Number(deepSleep) +
      (readingWithSameDate?.duration || 0);

    const deepSleepNumber =
      Number(deepSleep) + (readingWithSameDate?.deepSleepNumber || 0);
    const lightSleepNumber =
      Number(lightSleep) + (readingWithSameDate?.lightSleepNumber || 0);
    const remNumber = Number(rem) + (readingWithSameDate?.remNumber || 0);

    const total = deepSleepNumber + lightSleepNumber + remNumber;

    const deepSleepPercent = calculatePercent(deepSleepNumber, total);
    const lightSleepPercent = calculatePercent(lightSleepNumber, total);
    const remPercent = 100 - deepSleepPercent - lightSleepPercent;

    const deepSleepDuration = getTimeByDuration(
      toFixedNumber(deepSleepNumber / 60),
    );
    const lightSleepDuration = getTimeByDuration(
      toFixedNumber(lightSleepNumber / 60),
    );
    const remDuration = getTimeByDuration(toFixedNumber(remNumber / 60));
    const sleepTime = data.duration.replace('~', '-');

    const formattedData = {
      deepSleepNumber,
      lightSleepNumber,
      remNumber,
      deepSleepPercent,
      lightSleepPercent,
      remPercent,
      deepSleepDuration,
      lightSleepDuration,
      remDuration,
      deepSleepHeight: toFixedNumber(deepSleepNumber / 60),
      lightSleepHeight: toFixedNumber(lightSleepNumber / 60),
      remHeight: toFixedNumber(remNumber / 60),
      pulse,
      respiration,
      bodyTurnOver,
      durationTime: getTimeByDuration(toFixedNumber(duration / 60)),
      dateTime,
      duration,
      sleepTime,
      formattedDateTime,
    };

    if (readingWithSameDate) {
      const updatedData = {
        ...formattedData,
        sleepTime: `${readingWithSameDate.sleepTime}, ${sleepTime}`,
        pulse: Math.round(
          (Number(readingWithSameDate.pulse) + Number(pulse)) / 2,
        ),
        bodyTurnOver: Math.round(
          (Number(readingWithSameDate.bodyTurnOver) + Number(bodyTurnOver)) / 2,
        ),
        respiration: Math.round(
          (Number(readingWithSameDate.respiration) + Number(respiration)) / 2,
        ),
      };

      Object.assign(readingWithSameDate, updatedData);
    } else {
      normalizedData.push(formattedData);
    }
  });

  if (normalizedData.length <= MIN_AMOUNT) {
    const arr = Array.from(Array(MIN_AMOUNT - normalizedData.length).keys());
    arr.forEach(() => {
      const lastDay = Number(
        normalizedData[normalizedData.length - 1].dateTime,
      );
      const formattedDateTime = format(
        Number(lastDay),
        common.dateFormats.chartXDate,
      );
      const date = new Date(lastDay);
      date.setDate(date.getDate() + 1);
      normalizedData.push({
        dateTime: date.getTime().toString(),
        formattedDateTime,
        deepSleepHeight: 0,
        remHeight: 0,
        lightSleepHeight: 0,
        pulse: 0,
      });
    });
  }

  return normalizedData;
};

const getDurationByPercent = (percent, totalDuration) => {
  const duration = (percent / 100) * totalDuration;
  return getTimeByDuration(duration);
};

export const calculateAverageData = (data, durations) => {
  const totalDuration = durations.reduce((a, b) => a + b, 0);
  const avgDuration = totalDuration / durations.length;

  const total = data.reduce(
    (acc, current) => {
      acc.deepSleepTotal += Number(current.data.deepSleep);
      acc.lightSleepTotal += Number(current.data.lightSleep);
      acc.remTotal += Number(current.data.rem);
      acc.bodyTurnOverTotal += Number(current.data.bodyTurnOver);
      acc.respirationTotal += Number(current.data.respiration);

      return acc;
    },
    {
      deepSleepTotal: 0,
      lightSleepTotal: 0,
      remTotal: 0,
      bodyTurnOverTotal: 0,
      respirationTotal: 0,
    },
  );

  const {
    deepSleepTotal,
    lightSleepTotal,
    remTotal,
    bodyTurnOverTotal,
    respirationTotal,
  } = total;

  const totalSleep = deepSleepTotal + lightSleepTotal + remTotal;

  const deepSleepPercent = calculatePercent(deepSleepTotal, totalSleep);
  const lightSleepPercent = calculatePercent(lightSleepTotal, totalSleep);
  const remPercent = 100 - deepSleepPercent - lightSleepPercent;

  const deepSleepDuration = getDurationByPercent(deepSleepPercent, avgDuration);
  const lightSleepDuration = getDurationByPercent(
    lightSleepPercent,
    avgDuration,
  );
  const remDuration = getDurationByPercent(remPercent, avgDuration);

  const bodyTurnOver = Math.round(bodyTurnOverTotal / data.length);
  const respiration = Math.round(respirationTotal / data.length);

  return {
    deepSleepDuration,
    lightSleepDuration,
    remDuration,
    deepSleepPercent,
    lightSleepPercent,
    remPercent,
    bodyTurnOver,
    respiration,
  };
};

export const getAverageDuration = (durations) => {
  const totalDuration = durations.reduce((a, b) => a + b, 0);
  const avgDuration = (totalDuration / durations.length).toFixed(2) || '00.00';
  const [hours, m] = avgDuration.split('.');

  const minutes = offsetify(Math.floor((Number(m) / 100) * 60));
  return [hours, minutes];
};
