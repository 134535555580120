import { endOfMonth, startOfMonth } from 'date-fns';
import { Adherence } from 'infrastructure/classes/adherence/adherence';
import { useState } from 'react';
import { getUTCDates } from 'utils';
import { useQuery } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import useApi from './use-api';

import type { Dispatch, SetStateAction } from 'react';
import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IAdherencePaginatedData,
} from 'infrastructure/interfaces';
import type { SortState } from 'infrastructure/types';

const adherenceReportsApi = 'reports/reports/adherence';

export interface IUseAdherenceMeta {
  startTime?: number;
  endTime?: number;
  doctorGuid?: string;
  sortField?: [string, SortState];
}

type mergedMeta = IUseAdherenceMeta & IPaginationMeta;

type UseAdherence = {
  loading: boolean;
  meta: mergedMeta;
  items: Array<Adherence>;
  setMeta: Dispatch<SetStateAction<mergedMeta>>;
};

interface IUseAdherenceProps {
  meta?: IUseAdherenceMeta & IPartialPaginationMeta;
}

const today = new Date();
const [startTime, endTime] = getUTCDates([
  startOfMonth(today),
  endOfMonth(today),
]);

export const useAdherence = (props?: IUseAdherenceProps): UseAdherence => {
  const options = props || {};
  const metaProps = options.meta || {};

  const [meta, setMeta] = useState<mergedMeta>({
    page: 1,
    items: 100,
    totalCount: 0,
    startTime: startTime.getTime(),
    endTime: endTime.getTime(),
    ...metaProps,
  });
  const { loadData } = useApi();

  const queryKey = [
    adherenceReportsApi,
    {
      sortField: JSON.stringify(meta.sortField),
      doctorGuid: meta.doctorGuid,
      startTime: meta.startTime,
      endTime: meta.endTime,
      page: meta.page,
    },
  ];

  const { data = [], isLoading } = useQuery({
    queryKey,
    queryFn: () =>
      loadData<IAdherencePaginatedData, Omit<IUseAdherenceMeta, 'sortField'>>(
        adherenceReportsApi,
        {
          sortField: JSON.stringify(meta.sortField),
          doctorGuid: meta.doctorGuid,
          startTime: meta.startTime,
          endTime: meta.endTime,
          page: meta.page,
          items: meta.items,
        },
      ).then((res) => {
        if (res) {
          const { data: items, count } = res;
          setMeta({
            ...meta,
            totalCount: count,
          });
          return items.map((el) => new Adherence(el));
        }
      }),
    staleTime: STALE_TIME,
  });

  return {
    items: data,
    loading: isLoading,
    meta,
    setMeta,
  };
};
