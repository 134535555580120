import React, { useRef } from 'react';
import RadioButton from 'components/atoms/radioButton';
import useOutsideClick from 'utils/useOutsideClick';
import PropTypes from 'prop-types';
import CloseIcon from 'components/atoms/icons/closeIcon';
import { Field } from 'formik';

const SelectStatusPopup = ({ toggle }) => {
  const ref = useRef();
  useOutsideClick(ref, () => {
    toggle(false);
  });

  return (
    <div className="select-status-popup" ref={ref}>
      <div
        onClick={() => toggle(false)}
        className="select-status-popup__close-btn"
      >
        <CloseIcon />
      </div>
      <div className="select-status-popup__title">Add status</div>
      <div>
        <Field
          name="status"
          render={({ field, form }) => (
            <>
              <RadioButton
                name="status"
                id="status-active"
                label="Active"
                onChange={() => form.setFieldValue('status', 'active')}
                isChecked={field.value === 'active'}
                dataCy="radio-button-status-active"
              />
              <RadioButton
                {...field}
                name="status"
                id="status-inactive"
                label="Inactive"
                onChange={() => form.setFieldValue('status', 'inactive')}
                isChecked={field.value === 'inactive'}
                dataCy="radio-button-status-inactive"
              />
            </>
          )}
        />
      </div>
    </div>
  );
};

SelectStatusPopup.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default SelectStatusPopup;
