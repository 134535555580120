import dataService from 'services/dataService';
import {
  apiDoctorMedicareReadings,
  MEDICARE_BILLINGS_CSV_REPORT,
} from 'services/dataService/resources';
import { isEmpty } from 'lodash';

const ITEMS_PER_PAGE = 100;

const INITIAL_STATE = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  totalPages: 0,
  totalCount: 0,
  dates: [],
  medicareReport: [],
  activeMedicare: 0,
  99453: 0,
  99454: 0,
  99457: 0,
  994581: 0,
  994582: 0,
  clinic: '',
  doctor: undefined,
  childClinic: null,
};

const medicare = {
  state: INITIAL_STATE,
  reducers: {
    setMedicareReport: (state, payload) => ({
      ...state,
      medicareReport: payload,
    }),
    setPage: (state, payload) => ({
      ...state,
      page: payload < 1 ? 1 : payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    setDates: (state, payload) => ({
      ...state,
      dates: payload,
    }),
    setCounts: (state, payload) => ({
      ...state,
      activeMedicare: payload.activeMedicare,
      99453: payload.newPatientNumber,
      99454: payload.activeNumber,
      99457: payload.lowActivityCount,
      994581: payload.mediumActivityCount,
      994582: payload.highActivityCount,
    }),
    setClinic: (state, payload) => ({
      ...state,
      clinic: payload,
    }),
    setChildClinic: (state, payload) => ({
      ...state,
      childClinic: payload,
    }),
    setDoctor: (state, payload) => ({
      ...state,
      doctor: payload,
    }),
  },
  effects: (dispatch) => ({
    async getMedicareReport(payload, state) {
      const { page, itemsPerPage, dates, clinic, doctor, childClinic } =
        state.medicare;
      const [startTime, endTime] = dates;
      const { data } = await dataService.getList(
        apiDoctorMedicareReadings(
          itemsPerPage,
          page,
          clinic,
          doctor,
          startTime,
          endTime,
          childClinic,
        ),
      );
      if (data) {
        dispatch.medicare.setMedicareReport(data?.data || []);
        const totalCount = data?.activeCount || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.medicare.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
        const activeMedicare = data?.allActiveNumber || 0;
        const activeNumber = data?.activeNumber || 0;
        const newPatientNumber = data?.newPatientNumber || 0;
        const lowActivityCount = data?.lowActivityCount || 0;
        const mediumActivityCount = data?.mediumActivityCount || 0;
        const highActivityCount = data?.highActivityCount || 0;
        dispatch.medicare.setCounts({
          activeMedicare,
          activeNumber,
          newPatientNumber,
          lowActivityCount,
          mediumActivityCount,
          highActivityCount,
        });
      }
    },
    async setPageMedicare({ pageNumber }) {
      dispatch.medicare.setPage(pageNumber);

      return dispatch.medicare.getMedicareReport();
    },
    async setDatesMedicare({ newDates }) {
      dispatch.medicare.setDates(newDates);
      dispatch.medicare.setPage(1);
      return dispatch.medicare.getMedicareReport();
    },
    async setDoctorMedicare({ doctor }) {
      dispatch.medicare.setDoctor(doctor);
      dispatch.medicare.setPage(1);
      return dispatch.medicare.getMedicareReport();
    },
    async setClinicMedicare({ clinic }) {
      dispatch.medicare.setClinic(clinic);
      dispatch.medicare.setPage(1);
      return dispatch.medicare.getMedicareReport();
    },
    async setChildClinicMedicare({ childClinic }) {
      dispatch.medicare.setChildClinic(childClinic);
      dispatch.medicare.setPage(1);
      return dispatch.medicare.getMedicareReport();
    },
    async getMedicareBillingsCsvReport(payload, state) {
      const { dates, clinic, doctor, childClinic } = state.medicare;
      const [startTime, endTime] = dates;

      const doctorGuids = doctor?.split(',') || undefined;
      const cleanedDoctorGuids =
        doctorGuids && doctorGuids.filter((guid) => guid !== '');

      const { error, data } = await dataService.createOne(
        MEDICARE_BILLINGS_CSV_REPORT,
        {
          startTime,
          endTime,
          clinicGuid: clinic || undefined,
          doctorGuids: isEmpty(cleanedDoctorGuids) ? undefined : doctorGuids,
          childClinic: childClinic || undefined,
        },
      );
      return { error, data };
    },
  }),
};

export default medicare;
