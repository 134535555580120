import React from 'react';
import classNames from 'classnames';

import s from './styles.module.scss';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

const BaseTitle: React.FC<IBaseComponentProps> = (props) => {
  const { children, className } = props;
  const titleClassNames = classNames(s.title, className);
  return <h3 className={titleClassNames}>{children}</h3>;
};

export default BaseTitle;
