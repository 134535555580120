import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { CcmAgentCountData } from 'infrastructure/hooks/ccmAgentDashboard/types';

interface Props {
  data: CcmAgentCountData['appointmentCompleted'];
}

const CompletedAppointments: FC<Props> = ({ data }) => {
  const { enrolledInCurrentMonth, enrolledInPreviousMonth } = data;
  const { t } = useTranslation();

  return (
    <div className={s['completed-appointment-wrapper']}>
      <div className={s.title}>
        {t('ccmAgentDashboard.completedAppointments')}
      </div>
      <div>
        <Item
          title={t('labels.yesterday')}
          followUp={enrolledInPreviousMonth.last1Day}
          onboarding={enrolledInCurrentMonth.last1Day}
        />
        <Item
          title={t('labels.dayAgo', {
            count: 2,
          })}
          followUp={enrolledInPreviousMonth.last2Days}
          onboarding={enrolledInCurrentMonth.last2Days}
        />
        <Item
          title={t('labels.dayAgo', {
            count: 3,
          })}
          followUp={enrolledInPreviousMonth.last3Days}
          onboarding={enrolledInCurrentMonth.last3Days}
        />
      </div>
    </div>
  );
};

const Item: FC<{ title: string; followUp: number; onboarding: number }> = ({
  title,
  followUp,
  onboarding,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.item}>
      <div className={s.key}>{title}</div>
      <div className={s.value}>
        <div>
          {t('ccmAgentDashboard.followUps')}:{' '}
          <span className={s.bold}>{followUp}</span>
        </div>
        <div className={s.divider} />
        <div>
          {t('ccmAgentDashboard.onboarding')}:{' '}
          <span className={s.bold}>{onboarding}</span>
        </div>
      </div>
    </div>
  );
};

export default CompletedAppointments;
