import React from 'react';
import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AddPatient from 'components/templates/admin/patientAdd';

const AdminPatientAdd = () => (
  <Container>
    <AdminHeader />
    <AddPatient />
  </Container>
);

export default AdminPatientAdd;
