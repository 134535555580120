import withLoader from 'components/organisms/withLoader';
import TurkDashboardTable from 'components/molecules/turkDashboard/table';
import TurkDashboardHeader from 'components/molecules/turkDashboard/header';
import useTurkDashboard from 'utils/hooks/turkDashboard/useTurkDashboard';
import useTurkDashboardDate from 'utils/hooks/turkDashboard/useDate';

const Activity = () => {
  const { endTime, startTime, timezoneOffset } = useTurkDashboardDate();
  const {
    isReadingsLoading,
    isStatsLoading,
    readings,
    refetchStats,
    removeReading,
    scheduleRefetch,
    selectReadings,
    stats,
  } = useTurkDashboard();

  return (
    <div className="activity">
      <TurkDashboardHeader
        stats={{
          earnings: stats?.earned || 0,
          readingsCount: stats?.count || 0,
        }}
        getReadings={async (filter) => {
          await selectReadings({
            startTime,
            endTime,
            ...filter,
          });
          refetchStats();
        }}
        getReadingsTimeoutTime={stats?.timeouts?.readingsRefreshTime}
        disabled={isStatsLoading || isReadingsLoading || readings.length > 0}
      />
      <div className="activity-body">
        {withLoader(
          isReadingsLoading,
          <TurkDashboardTable
            readings={readings}
            dates={{ startTime, endTime, timezoneOffset }}
            onCommentSubmit={(reading) => {
              removeReading(reading);
              refetchStats();
              scheduleRefetch();
            }}
          />,
        )}
      </div>
    </div>
  );
};

export default Activity;
