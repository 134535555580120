import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import AutoComplete from 'components/molecules/autocompleteFilter';
import AutoCompleteClinics from 'components/molecules/autocompleteFilter/clinic';
import forms from 'constants/forms';
import common from 'constants/common';
import useConfirm from 'utils/useConfirm';
import useUser from 'utils/useUser';
import { isAgencyDoctor } from 'utils/userTypeHelper';
import { isAtRiskRole } from 'utils/accessManager/helpers';
import { isCurrentMonthSelected } from 'utils/timeHelper';
import { getRangeDates } from 'utils';
import { addQueryParams } from 'utils/queryHelpers';
import { usStatesSelectWithAll } from 'constants/states';
import AutoCompleteDoctorMultiSelect from 'components/molecules/autocompleteFilter/doctorMulti';
import BaseSelect from 'components/atoms/baseSelect';

import TimeFilter from '../../../../organisms/timeFilter';

const DEFAULT_RANGE = forms.timeFilterRanges[4];

const showConfirm = useConfirm({
  message: 'You have unsaved changes, are you sure you want to leave?',
  title: 'Confirm',
});
const DEFAULT_PATIENT_FILTER = forms.patientFilterOptions[0];
const DEFAULT_CLINIC_FILTER = forms.clinicFilterOptions[0];
const DEFAULT_CLINIC_STATE = usStatesSelectWithAll[0];
const DEFAULT_READING_TYPE = forms.readingTypeSelect[0];
const DEFAULT_INSURANCE = forms.clinicInsuranceTypeSelect[0];
const DEFAULT_ASSIGNEE = forms.assigneeSelect[0];
const DEFAULT_ACTION_TIME_QUOTA = forms.actionTimesQuotaSelect[0];

const Sorting = ({ toggleAction, action, loading }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userType, roleNames } = useUser();
  const timeFilterRef = useRef();
  const clinicChangerRef = useRef();
  const patientChangerRef = useRef();
  const providerChangerRef = useRef();
  const {
    insuranceTypes,
    filters: {
      status,
      readingType,
      clinicGuid,
      clinicState,
      insuranceType,
      assignee,
      actionTimesQuota,
    },
    unsavedComments,
  } = useSelector((state) => state.doctor);
  const {
    doctor: { applyTimeFilter, applyFilters, clearComments },
  } = useDispatch();

  const handleSubmit = (newDates) => {
    applyTimeFilter(newDates);
    if (isCurrentMonthSelected(newDates, action)) {
      toggleAction();
    }
  };

  const actionChanged = () => {
    addQueryParams({ navigate, location }, { action: !action, page: 1 });
    toggleAction();
  };

  const patientFilterChanged = (option) => {
    if (option) {
      const { value } = option;
      applyFilters({ patientGuid: value, assignee: 'all' });
      if (!option.noQueryParams) {
        addQueryParams({ navigate, location }, { patientGuid: value, page: 1 });
      }
    }
  };

  const providerFilterChanged = (option) => {
    if (option) {
      const { value } = option;
      applyFilters({ doctorGuid: value });
      if (!option.noQueryParams) {
        addQueryParams({ navigate, location }, { doctorGuid: value, page: 1 });
      }
    }
  };

  const clinicFilterChanged = (option) => {
    if (option) {
      const { value } = option;
      applyFilters({ clinicGuid: value });
      if (!option.noQueryParams) {
        addQueryParams({ navigate, location }, { clinicGuid: value, page: 1 });
      }
    }
  };

  const clinicStateFilterChanged = (option) => {
    if (option) {
      const { value } = option;
      applyFilters({ clinicState: value });
      if (!option.noQueryParams) {
        addQueryParams({ navigate, location }, { clinicState: value, page: 1 });
      }
    }
  };

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 12,
    colors: {
      ...theme.colors,
      ...common.primaryColors,
    },
  });

  const clinicStateValue = (state) => {
    const value = usStatesSelectWithAll.find(
      (element) => element.value === state,
    );
    return value;
  };

  const readingTypeValue = (readingTypeName) => {
    const value = forms.readingTypeSelect.find(
      (element) => element.value === readingTypeName,
    );
    return value;
  };

  const assigneeValue = (assigneeName) => {
    const value = forms.assigneeSelect.find(
      (element) => element.value === assigneeName,
    );
    return value;
  };

  const actionTimesQuotaValue = (quotaName) => {
    const value = forms.actionTimesQuotaSelect.find(
      (element) => element.value === quotaName,
    );
    return value;
  };

  const insuranceTypeValue = (currentInsuranceType) => {
    const value = [
      DEFAULT_INSURANCE,
      ...insuranceTypes.map((el) => ({ label: el, value: el })),
    ].find((element) => element.value === currentInsuranceType);
    return value;
  };

  const ignoreUnsavedChanges =
    (ok, cancel) =>
    async (...props) => {
      let confirm = true;
      if (Object.keys(unsavedComments).length) {
        confirm = await showConfirm();
      }
      if (confirm) {
        clearComments();
        return ok && ok(...props);
      }
      return cancel && cancel(...props);
    };

  const statusChanged = async ({ value, noQueryParams }) => {
    if (value !== status) {
      let confirm = true;
      if (!noQueryParams) {
        addQueryParams({ navigate, location }, { status: value, page: 1 });
      }
      if (Object.keys(unsavedComments).length) {
        confirm = await showConfirm();
      }
      if (confirm) {
        clearComments();
        return applyFilters({ status: value });
      }
    }
  };

  const readingTypeChanged = async ({ value, noQueryParams }) => {
    if (value !== readingType) {
      let confirm = true;
      if (!noQueryParams) {
        addQueryParams({ navigate, location }, { readingType: value, page: 1 });
      }
      if (Object.keys(unsavedComments).length) {
        confirm = await showConfirm();
      }
      if (confirm) {
        clearComments();
        return applyFilters({ readingType: value });
      }
    }
  };

  const assigneeChanged = async ({ value, noQueryParams }) => {
    if (value !== assignee) {
      let confirm = true;
      if (!noQueryParams) {
        addQueryParams({ navigate, location }, { assignee: value, page: 1 });
      }
      if (Object.keys(unsavedComments).length) {
        confirm = await showConfirm();
      }
      if (confirm) {
        clearComments();
        return applyFilters({ assignee: value });
      }
    }
  };

  const actonTimesQuotaChanged = async ({ value, noQueryParams }) => {
    if (value !== actionTimesQuota) {
      let confirm = true;
      if (!noQueryParams) {
        addQueryParams(
          { navigate, location },
          { actionTimesQuota: value, page: 1 },
        );
      }
      if (Object.keys(unsavedComments).length) {
        confirm = await showConfirm();
      }
      if (confirm) {
        clearComments();
        return applyFilters({ actionTimesQuota: value });
      }
    }
  };

  const insuranceTypeChanged = async ({ value, noQueryParams }) => {
    if (value !== insuranceType) {
      let confirm = true;
      if (!noQueryParams) {
        addQueryParams(
          { navigate, location },
          { insuranceType: value, page: 1 },
        );
      }
      if (Object.keys(unsavedComments).length) {
        confirm = await showConfirm();
      }
      if (confirm) {
        clearComments();
        return applyFilters({ insuranceType: value });
      }
    }
  };

  const timeFilterChanged = async (newDates, range, addParams, page) => {
    let confirm = true;
    const [startDate, endDate] = newDates;
    if (startDate instanceof Date && endDate instanceof Date && addParams) {
      addQueryParams(
        {
          navigate,
          location,
        },
        {
          startDate: startDate.getTime(),
          endDate: endDate.getTime(),
          rangeLabel: !range ? 'custom' : range?.label,
          page: Number(page) || 1,
        },
      );
    }
    if (Object.keys(unsavedComments).length) {
      confirm = await showConfirm();
    }
    if (confirm) {
      clearComments();
      return handleSubmit(newDates);
    }
    timeFilterRef.current.setValue(range);
  };

  const resetFilters = () => {
    const { startRangeDate, endRangeDate } = getRangeDates(DEFAULT_RANGE);
    applyFilters({ slaReadings: null });
    statusChanged({ value: null, noQueryParams: true });
    readingTypeChanged({ value: null, noQueryParams: true });
    insuranceTypeChanged({ value: null, noQueryParams: true });
    assigneeChanged({ value: null, noQueryParams: true });
    patientFilterChanged({ value: null, noQueryParams: true });
    providerFilterChanged({ value: null, noQueryParams: true });
    clinicFilterChanged({ value: null, noQueryParams: true });
    clinicStateFilterChanged({ value: null, noQueryParams: true });
    actonTimesQuotaChanged({ value: null, noQueryParams: true });
    timeFilterChanged([startRangeDate, endRangeDate], DEFAULT_RANGE);
    patientChangerRef.current.setValue('');
    clinicChangerRef.current.setValue('');
    providerChangerRef.current?.reset();
    timeFilterRef.current.setValue(DEFAULT_RANGE);
  };

  const clearFilters = () => {
    resetFilters();
    navigate('/', { replace: true });
  };

  useEffect(() => {
    const data = queryString.parse(location.search);
    if (data) {
      if (data?.newDates) applyTimeFilter(data.newDates);
      applyFilters(data);
    }
  }, []);

  useEffect(() => {
    if (location.search === '' && !loading) {
      clearFilters();
    }
  }, [location.search]);

  return (
    <>
      <div className="sort-row-rpm">
        <TimeFilter
          inputRef={timeFilterRef}
          handleSubmit={timeFilterChanged}
          noUTC
          isRpmPanel
        />
        <div className="sort-row-item">
          <p>Patient Filter</p>
          <AutoComplete
            inputRef={patientChangerRef}
            filter={DEFAULT_PATIENT_FILTER}
            handleClick={ignoreUnsavedChanges(
              (patient) => patientFilterChanged(patient),
              () => {
                patientChangerRef.current.setValue('');
              },
            )}
          />
        </div>
        <div className="sort-row-item">
          <p data-cy="reading-filter-label">Reading Type</p>
          <BaseSelect
            options={forms.readingTypeSelect}
            defaultValue={DEFAULT_READING_TYPE}
            theme={(theme) => selectTheme(theme)}
            classNamePrefix="select-type"
            value={readingTypeValue(readingType)}
            onChange={readingTypeChanged}
          />
        </div>
        <div className="sort-row-item">
          <p>Clinic</p>
          <AutoCompleteClinics
            inputRef={clinicChangerRef}
            filter={DEFAULT_CLINIC_FILTER}
            handleClick={ignoreUnsavedChanges(
              (option) => {
                clinicFilterChanged(option);
              },
              () => {
                clinicChangerRef.current.setValue('');
              },
            )}
          />
        </div>
        <div className="sort-row-item">
          <p data-cy="provider-filter-label">Provider</p>
          <AutoCompleteDoctorMultiSelect
            onChange={(value) => providerFilterChanged({ value })}
            clinicGuid={clinicGuid}
            ref={providerChangerRef}
          />
        </div>
        <div className="sort-row-item">
          <p data-cy="clinicState-filter-label">Clinics State</p>
          <BaseSelect
            options={usStatesSelectWithAll}
            defaultValue={DEFAULT_CLINIC_STATE}
            theme={(theme) => selectTheme(theme)}
            classNamePrefix="select-type"
            value={clinicStateValue(clinicState)}
            onChange={clinicStateFilterChanged}
          />
        </div>
        {clinicGuid && (
          <div className="sort-row-item">
            <p data-cy="insuranceProvider-filter-label">Insurance Provider</p>
            <BaseSelect
              options={[
                DEFAULT_INSURANCE,
                ...insuranceTypes.map((currentInsuranceType) => ({
                  label: currentInsuranceType,
                  value: currentInsuranceType,
                })),
              ]}
              defaultValue={DEFAULT_INSURANCE}
              value={insuranceTypeValue(insuranceType)}
              theme={(theme) => selectTheme(theme)}
              classNamePrefix="select-type"
              onChange={insuranceTypeChanged}
            />
          </div>
        )}
        {isAgencyDoctor(userType) && (
          <div className="sort-row-item">
            <p data-cy="assignee-filter-label">Assignee</p>
            <BaseSelect
              options={forms.assigneeSelect}
              defaultValue={DEFAULT_ASSIGNEE}
              theme={(theme) => selectTheme(theme)}
              classNamePrefix="select-type"
              value={assigneeValue(assignee)}
              onChange={assigneeChanged}
            />
          </div>
        )}
        {isAtRiskRole(roleNames) && (
          <div className="sort-row-item">
            <p data-cy="action-quota-filter-label">Time</p>
            <BaseSelect
              options={forms.actionTimesQuotaSelect}
              defaultValue={DEFAULT_ACTION_TIME_QUOTA}
              theme={(theme) => selectTheme(theme)}
              classNamePrefix="select-type"
              value={actionTimesQuotaValue(actionTimesQuota)}
              onChange={actonTimesQuotaChanged}
            />
          </div>
        )}
      </div>
      <div className="sort-row-two">
        <div
          className="activity-switcher sort-row-two-switcher"
          data-cy="actioned-switch"
        >
          <span>{!action ? 'Unaction' : 'Actioned'}</span>
          <Switch
            className="evelyn-switch"
            onChange={ignoreUnsavedChanges(actionChanged)}
            checked={action}
            uncheckedIcon={false}
            checkedIcon={false}
            onHandleColor={common.commonColors.green}
            offHandleColor={common.commonColors.gray}
            onColor={common.commonColors.white}
            offColor={common.commonColors.white}
            handleDiameter={24}
            height={30}
            width={59}
            activeBoxShadow="0"
          />
        </div>
        {!!location.search && location.search !== '?page=1' && (
          <button
            className="reset-button"
            data-cy="reset-filters-button"
            onClick={() => navigate('/', { replace: true })}
          >
            Reset Filters
          </button>
        )}
      </div>
    </>
  );
};

Sorting.propTypes = {
  toggleAction: PropTypes.func.isRequired,
  action: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

export default Sorting;
