import 'react-datepicker/dist/react-datepicker.css';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import dateFnsFormat from 'date-fns/format';
import enUs from 'date-fns/locale/en-US';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Input } from 'reactstrap';
import common from 'constants/common';
import useOutsideClick from 'utils/useOutsideClick';
import { parse } from 'date-fns';
import { parseDateSafe } from 'utils/timeHelper';

registerLocale('en-Us', enUs);
const TimePicker = ({
  value,
  valueChanged,
  format,
  disabled,
  timeCaption,
  timeIntervals = 1,
}) => {
  const selected = value && format && parse(value, format, new Date());

  const ref = useRef();
  const [showTimeSelector, setShowTimeSelector] = useState(false);

  const onTimeChange = (date) => {
    const timeFormat = format || common.dateFormats.hoursMinutesPM;
    setShowTimeSelector(false);
    const time = dateFnsFormat(new Date(date), timeFormat);
    valueChanged(time);
  };

  useOutsideClick(ref, () => {
    setShowTimeSelector(false);
  });

  const startDate = useState(new Date());

  return (
    <div ref={ref}>
      <div
        onClick={() => {
          setShowTimeSelector(true);
        }}
      >
        <Input
          className="main-field-input"
          value={value}
          onChange={(e) => {
            valueChanged(e.target.value);
          }}
          disabled={disabled}
        />
      </div>
      {showTimeSelector && (
        <div className="time-picker">
          <DatePicker
            selected={parseDateSafe(selected)}
            startDate={startDate}
            locale="en-Us"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={timeIntervals}
            inline
            timeFormat={format || common.dateFormats.hoursMinutesPM}
            onChange={onTimeChange}
            timeCaption={timeCaption}
          />
        </div>
      )}
    </div>
  );
};
TimePicker.propTypes = {
  value: PropTypes.string,
  timeCaption: PropTypes.string,
  format: PropTypes.string,
  disabled: PropTypes.bool,
  valueChanged: PropTypes.func,
  timeIntervals: PropTypes.number,
};
export default TimePicker;
