import React from 'react';

const AsteriskIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.29738 3.64457C6.14189 3.72732 5.99028 3.79878 5.84257 3.85896C5.69485 3.91914 5.54325 3.96427 5.38776 3.99436C5.69874 4.05454 6.00194 4.17489 6.29738 4.35543L8 5.3371L7.16035 6.67983L5.45773 5.69817C5.14675 5.53268 4.89796 5.35214 4.71137 5.15656C4.80467 5.42736 4.85131 5.71697 4.85131 6.02539V8H3.14869V6.04796C3.14869 5.88999 3.15646 5.73578 3.17201 5.58533C3.19534 5.43489 3.23032 5.29196 3.27697 5.15656C3.1759 5.26187 3.06317 5.35966 2.93878 5.44993C2.81438 5.53268 2.68222 5.61542 2.54227 5.69817L0.83965 6.65726L0 5.31453L1.70262 4.35543C2.00583 4.18242 2.30515 4.06206 2.60058 3.99436C2.45287 3.97179 2.30126 3.93042 2.14577 3.87024C1.99806 3.80254 1.85034 3.72732 1.70262 3.64457L0 2.66291L0.83965 1.30889L2.54227 2.30183C2.85326 2.46732 3.10204 2.64786 3.28863 2.84344C3.19534 2.57264 3.14869 2.28303 3.14869 1.97461V0H4.85131V1.95205C4.85131 2.11001 4.83965 2.26422 4.81633 2.41467C4.80078 2.56512 4.76968 2.70804 4.72303 2.84344C4.8241 2.73813 4.93683 2.6441 5.06123 2.56135C5.18562 2.47109 5.31778 2.38458 5.45773 2.30183L7.16035 1.33145L8 2.68547L6.29738 3.64457Z"
      fill="#FC7013"
    />
  </svg>
);

export default AsteriskIcon;
