import BaseFormGroup from 'components/atoms/base-form/group';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import BaseButton from 'components/atoms/baseButton';
import ThresholdsViewForm from 'components/molecules/thresholds/view-form';
import BaseModal from 'components/atoms/base-modal';
import ThresholdsForm from 'components/molecules/thresholds/update-form';
import { Thresholds } from 'infrastructure/classes/thresholds';

import type { ClinicThresholds } from 'infrastructure/classes/clinic-thresholds';
import type { IClinicThreshold } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const FormDisplayName = 'ClinicThresholdsForm';

interface IControlsProps {
  onClick: () => void;
}

const Controls: React.FC<IControlsProps> = (props) => {
  const { onClick } = props;
  const { t } = useTranslation();
  return (
    <BaseButton
      dataCy="editThreshold-button"
      width="160px"
      onClick={onClick}
      label={t('controls.edit')}
    />
  );
};

interface IProps extends IIndependentFormProps {
  data?: ClinicThresholds;
}

const ClinicThresholdsForm = forwardRef<
  IIndependentFormRef<IClinicThreshold>,
  IProps
>((props, ref) => {
  const { data } = props;
  const { t } = useTranslation();

  const [thresholds, setThresholds] = useState<Thresholds>(
    Thresholds.getDefaultThresholds(),
  );

  const [opened, setOpened] = useState(false);

  const onSave = async (val: Thresholds) => {
    setOpened(false);
    setThresholds(val);
  };

  const onEdit = () => {
    setOpened(true);
  };

  const onCancel = () => {
    setOpened(false);
  };

  useImperativeHandle(ref, () => ({
    submit: async () => {
      return {
        values: { threshold: thresholds },
        formName: FormDisplayName,
        isValid: true,
      };
    },
    reset: () => {},
  }));

  useEffect(() => {
    if (data) {
      setThresholds(data);
    }
  }, [data]);

  return (
    <BaseFormGroup
      label={t('labels.thresholds')}
      controls={<Controls onClick={onEdit} />}
    >
      <ThresholdsViewForm
        data={thresholds}
        hasBloodGlucoseType
        hasWeightType
        hasSPO2Type
        hasBloodPressureType
        hasPeakFlowType
        hasSleepMatType
        showBaselineWeight={false}
      />
      <BaseModal
        title={t('labels.patientThresholds')}
        open={opened}
        onCancel={onCancel}
        width="1000px"
      >
        <ThresholdsForm
          data={thresholds}
          onSubmit={onSave}
          onCancel={onCancel}
          hasBloodGlucoseType
          hasWeightType
          hasSPO2Type
          hasBloodPressureType
          hasPeakFlowType
          hasSleepMatType
          showBaselineWeight={false}
        />
      </BaseModal>
    </BaseFormGroup>
  );
});

ClinicThresholdsForm.displayName = FormDisplayName;

export default ClinicThresholdsForm;
