import PropTypes from 'prop-types';
import classnames from 'classnames';
import LabelWithTooltip from 'components/atoms/label/labelWithTooltip';
import { extractContent } from 'utils/stringHelpers';

const CheckboxInput = ({
  id,
  title,
  setValue,
  values,
  isRequired,
  disabled,
  hasOtherOption,
  renderOtherOptionFirst,
  otherOptionLabel = 'Other: ',
  choices,
  requiredIf,
  helpText,
  showToolTipForChoices,
  notRequiredIf,
}) => {
  let required = isRequired;
  if (requiredIf) {
    required = isRequired || values[requiredIf.key] === requiredIf.value;
  }

  if (notRequiredIf) {
    Object.keys(values).forEach((key) => {
      if (notRequiredIf.includes(key)) {
        const value = values[key];
        if (Array.isArray(value) && value.length) {
          required = false;
        }
        if (!Array.isArray(value) && value) {
          required = false;
        }
      }
    });
  }

  const addChoice = (choice) => {
    const newValues = values[id]
      ? [...values[id], { type: 'default', value: choice }]
      : [{ type: 'default', value: choice }];

    setValue({
      [id]: newValues,
    });
  };
  const removeChoice = (choice) => {
    const newValues = values[id].filter(({ value }) => value !== choice);

    setValue({
      [id]: newValues,
    });
  };
  const onChangeOther = (value) => {
    if (value) {
      const newValues = values[id]
        ? [
            ...values[id]?.filter(({ type }) => type !== 'other'),
            { type: 'other', value },
          ]
        : [{ type: 'other', value }];
      setValue({
        [id]: newValues,
      });
    } else {
      const newValues = values[id]
        ? [...values[id]?.filter(({ type }) => type !== 'other')]
        : undefined;
      setValue({
        [id]: newValues,
      });
    }
  };
  const handleOtherValue = (other) => {
    if (other && typeof other.find === 'function') {
      return other?.find((i) => i.type === 'other')?.value;
    }
  };

  const OtherOption = hasOtherOption && (
    <div
      className={classnames('other-input-wrapper', {
        'pd-b-10': renderOtherOptionFirst,
      })}
    >
      <label htmlFor={id}>{otherOptionLabel}</label>
      <input
        id={id}
        className="form-control"
        type="text"
        autoComplete="off"
        disabled={disabled}
        value={handleOtherValue(values[id]) || ''}
        onChange={(e) => {
          onChangeOther(e.target.value);
        }}
      />
    </div>
  );
  return (
    <div className="input-wrapper" data-cy={id}>
      <label htmlFor={id}>
        {title} {required && <span className="color-red">*</span>}
      </label>
      {helpText && <p className="help-text">{helpText}</p>}
      {renderOtherOptionFirst && OtherOption}
      {choices.map((choice) => {
        const isValuesArray = Array.isArray(values[id]);
        const checked =
          isValuesArray && values[id]?.find(({ value }) => value === choice);
        const onChange = () => {
          if (checked) {
            removeChoice(choice);
          } else {
            addChoice(choice);
          }
        };
        const key = `${id}${choice}`;
        const label = choice.split('(')[0];
        const tooltipText = extractContent(choice);
        const showTooltip = showToolTipForChoices && tooltipText;
        return (
          <div className="checkbox-input-wrapper" key={key}>
            <input
              type="checkbox"
              id={key}
              label={choice}
              onChange={onChange}
              disabled={disabled}
              value={checked}
              defaultChecked={checked}
            />
            {showTooltip && (
              <LabelWithTooltip tooltipText={tooltipText} htmlFor={key}>
                {label}
              </LabelWithTooltip>
            )}
            {!showTooltip && <label htmlFor={key}>{label}</label>}
          </div>
        );
      })}
      {!renderOtherOptionFirst && OtherOption}
    </div>
  );
};

CheckboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  helpText: PropTypes.string,
  choices: PropTypes.array,
  setValue: PropTypes.func,
  isRequired: PropTypes.bool,
  requiredIf: PropTypes.object,
  hasOtherOption: PropTypes.bool,
  renderOtherOptionFirst: PropTypes.bool,
  otherOptionLabel: PropTypes.string,
  disabled: PropTypes.bool,
  values: PropTypes.shape(),
  showToolTipForChoices: PropTypes.bool,
  notRequiredIf: PropTypes.array,
};

export default CheckboxInput;
