import { Container } from 'reactstrap';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import UsersFilter from 'components/organisms/admin-panel/users-filter';
import AdminUsersTable from 'components/organisms/admin-panel/users-table';
import { useTranslation } from 'react-i18next';
import ScrollToTopButton from 'components/atoms/scroll-to-top-button';

import s from './styles.module.scss';

const AdminUsers = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <AdminNavigationHeader title={t('labels.clinicUsersList')} />
      <div className={s.body}>
        <UsersFilter />
        <AdminUsersTable />
      </div>
      <ScrollToTopButton />
    </Container>
  );
};

export default AdminUsers;
