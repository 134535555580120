import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';
import i18next from 'i18next';

export const patientThresholdsValidationSchema = () =>
  createValidationSchema({
    minCriticalSystolic: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      lessThan: {
        field: 'maxCriticalSystolic',
        fieldName: i18next.t('labels.maxCritical'),
      },
    }),
    maxCriticalSystolic: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      moreThan: {
        field: 'minCriticalSystolic',
        fieldName: i18next.t('labels.minCritical'),
      },
    }),
    minRiskSystolic: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      lessThan: {
        field: 'maxRiskSystolic',
        fieldName: i18next.t('labels.maxAtRisk'),
      },
      moreThan: {
        field: 'minCriticalSystolic',
        fieldName: i18next.t('labels.minCritical'),
      },
    }),
    maxRiskSystolic: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      moreThan: {
        field: 'minRiskSystolic',
        fieldName: i18next.t('labels.minAtRisk'),
      },
      lessThan: {
        field: 'maxCriticalSystolic',
        fieldName: i18next.t('labels.maxCritical'),
      },
    }),
    minCriticalDiastolic: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      lessThan: {
        field: 'maxCriticalDiastolic',
        fieldName: i18next.t('labels.maxCritical'),
      },
    }),
    maxCriticalDiastolic: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      moreThan: {
        field: 'minCriticalDiastolic',
        fieldName: i18next.t('labels.minCritical'),
      },
    }),
    minRiskDiastolic: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      lessThan: {
        field: 'maxRiskDiastolic',
        fieldName: i18next.t('labels.maxAtRisk'),
      },
      moreThan: {
        field: 'minCriticalDiastolic',
        fieldName: i18next.t('labels.minCritical'),
      },
    }),
    maxRiskDiastolic: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      moreThan: {
        field: 'minRiskDiastolic',
        fieldName: i18next.t('labels.minAtRisk'),
      },
      lessThan: {
        field: 'maxCriticalDiastolic',
        fieldName: i18next.t('labels.maxCritical'),
      },
    }),
    minCriticalPulse: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      lessThan: {
        field: 'maxCriticalPulse',
        fieldName: i18next.t('labels.maxCritical'),
      },
    }),
    maxCriticalPulse: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      moreThan: {
        field: 'minCriticalPulse',
        fieldName: i18next.t('labels.minCritical'),
      },
    }),
    minCriticalSleepPulse: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      lessThan: {
        field: 'maxCriticalSleepPulse',
        fieldName: i18next.t('labels.maxCritical'),
      },
    }),
    maxCriticalSleepPulse: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      moreThan: {
        field: 'minCriticalSleepPulse',
        fieldName: i18next.t('labels.minCritical'),
      },
    }),
    minRiskPulse: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      lessThan: {
        field: 'maxRiskPulse',
        fieldName: i18next.t('labels.maxAtRisk'),
      },
      moreThan: {
        field: 'minCriticalPulse',
        fieldName: i18next.t('labels.minCritical'),
      },
    }),
    maxRiskPulse: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      moreThan: {
        field: 'minRiskPulse',
        fieldName: i18next.t('labels.minAtRisk'),
      },
      lessThan: {
        field: 'maxCriticalPulse',
        fieldName: i18next.t('labels.maxCritical'),
      },
    }),
    minRiskSleepPulse: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      lessThan: {
        field: 'maxRiskSleepPulse',
        fieldName: i18next.t('labels.maxAtRisk'),
      },
      moreThan: {
        field: 'minCriticalSleepPulse',
        fieldName: i18next.t('labels.minCritical'),
      },
    }),
    maxRiskSleepPulse: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 200,
      moreThan: {
        field: 'minRiskSleepPulse',
        fieldName: i18next.t('labels.minAtRisk'),
      },
      lessThan: {
        field: 'maxCriticalSleepPulse',
        fieldName: i18next.t('labels.maxCritical'),
      },
    }),
    criticalBloodoxygen: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 100,
      lessThan: {
        field: 'riskBloodoxygen',
        fieldName: i18next.t('labels.atRisk'),
      },
    }),
    riskBloodoxygen: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 100,
      moreThan: {
        field: 'criticalBloodoxygen',
        fieldName: i18next.t('labels.critical'),
      },
    }),
    minCriticalGlucose: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 400,
      lessThan: {
        field: 'minRiskGlucose',
        fieldName: i18next.t('labels.minAtRisk'),
      },
    }),
    maxCriticalGlucose: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 400,
      moreThan: {
        field: 'minCriticalGlucose',
        fieldName: i18next.t('labels.minCritical'),
      },
    }),
    minRiskGlucose: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 400,
      lessThan: {
        field: 'maxRiskGlucose',
        fieldName: i18next.t('labels.maxAtRisk'),
      },
      moreThan: {
        field: 'minCriticalGlucose',
        fieldName: i18next.t('labels.minCritical'),
      },
    }),
    maxRiskGlucose: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 400,
      moreThan: {
        field: 'minRiskGlucose',
        fieldName: i18next.t('labels.minAtRisk'),
      },
      lessThan: {
        field: 'maxCriticalGlucose',
        fieldName: i18next.t('labels.maxCritical'),
      },
    }),
    baseLineWeight: commonValidationSchemas.number({ min: 0, required: false }),
    minCriticalWeight: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      moreThan: {
        field: 'minRiskWeight',
        fieldName: i18next.t('labels.minAtRisk'),
      },
    }),
    maxCriticalWeight: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      moreThan: {
        field: 'maxRiskWeight',
        fieldName: i18next.t('labels.maxAtRisk'),
      },
    }),
    minRiskWeight: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 400,
    }),
    maxRiskWeight: commonValidationSchemas.number({
      required: true,
      isInteger: true,
      min: 0,
      max: 400,
    }),

    minRiskPef: commonValidationSchemas.number({
      min: 0,
      moreThan: {
        field: 'minCriticalPef',
        fieldName: i18next.t('labels.minCritical'),
      },
    }),
    minCriticalPef: commonValidationSchemas.number({ min: 0 }),
    minCriticalFev1: commonValidationSchemas.number({ min: 0 }),
  });
