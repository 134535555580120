import React, { forwardRef, useImperativeHandle } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseGrid from 'components/atoms/base-grid';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import BaseInput from 'components/atoms/base-input';
import { useCareLocation } from 'infrastructure/hooks/patient/use-patient-care-location';
import StateSelectBox from 'components/molecules/states-types-select-box';
import { States } from 'infrastructure/enums';

import { careLocationsValidationSchema } from './validation-schema';

import type { CareLocation } from 'infrastructure/classes/patient/care-location';

export interface ICareLocationCreateFormRef {
  submit: () => Promise<void>;
  reset: () => void;
}

interface ICareLocationCreateFormProps {
  patientGuid: string;
  data?: CareLocation;
  onCancel?: () => void;
  onSaved?: () => void;
}

const CareLocationCreateForm = forwardRef<
  ICareLocationCreateFormRef,
  ICareLocationCreateFormProps
>((props, ref) => {
  const { patientGuid, data = {}, onCancel, onSaved } = props;

  const { t } = useTranslation();

  const { loading, createCareLocation, updateCareLocation } = useCareLocation({
    patientGuid,
  });

  const formik = useFormik<CareLocation>({
    initialValues: {
      patientGuid,
      facilityName: '',
      facilityAddress: '',
      facilityNumber: '',
      city: '',
      state: States.None,
      zipCode: '',
      ...data,
    } as CareLocation,
    onSubmit: async (values) => {
      const req = values.guid ? updateCareLocation : createCareLocation;
      await req(values).then(() => {
        if (onSaved) onSaved();
      });
    },
    validateOnChange: false,
    validationSchema: careLocationsValidationSchema(),
  });

  const { submitForm, resetForm } = formik;

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: resetForm,
  }));

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseForm
        loading={loading}
        formik={formik}
        plaintext={false}
        readonly={false}
        onSave={submitForm}
        onCancel={onCancel}
        withControls
      >
        <BaseGrid columns={2}>
          <BaseFormItem name="facilityName" label={t('labels.nameLocation')}>
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem name="facilityAddress" label={t('labels.address')}>
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem name="city" label={t('labels.city')}>
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem name="state" label={t('labels.state')}>
            <StateSelectBox withNone />
          </BaseFormItem>
          <BaseFormItem name="zipCode" label={t('labels.zipCode')}>
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="facilityNumber"
            label={t('labels.facilityNumber')}
          >
            <BaseInput />
          </BaseFormItem>
        </BaseGrid>
      </BaseForm>
    </BaseSpinWrapper>
  );
});

CareLocationCreateForm.displayName = 'CareLocationCreateForm';

export default CareLocationCreateForm;
