import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Patient } from 'infrastructure/classes/patient/patient';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useApi from '../use-api';
import { getClinicDoctorsInfoKey } from '../doctor/use-clinic-doctors';
import { getPatientClinicInfoKey } from './use-patient-clinic-info';
import { showResult } from '../utils';

import type { Clinic } from 'infrastructure/classes/clinic';
import type { CustomError } from 'infrastructure/classes/custom-error';
import type {
  IPatient,
  IUpdatePatientClinicInfo,
} from 'infrastructure/interfaces';

const patientInfoApi = (patientGuid: string) =>
  `admins/admin-panel/patients/${patientGuid}/patient-info`;

const patientClinicInfoApi = (patientGuid: string) =>
  `admins/admin-panel/patients/${patientGuid}/clinic-info`;

interface IUsePatientInfo {
  loading: boolean;
  patientInfo: Patient | undefined;
  error?: CustomError;
  updatePatientInfo: (body: Patient) => Promise<void>;
  updatePatientClinicInfo: (
    data: IUpdatePatientClinicInfo,
    clinic?: Clinic,
  ) => Promise<IUpdatePatientClinicInfo>;
  setPatientInfo: (body: Patient) => void;
}

interface IUsePatientInfoProps {
  patientGuid: string;
}

export const getPatientInfoKey = (patientGuid: string) => [
  'patientInfo',
  patientGuid,
];

export const usePatientInfo = (
  props: IUsePatientInfoProps,
): IUsePatientInfo => {
  const navigate = useNavigate();
  const { patientGuid } = props;

  const { loading, loadData, updateData } = useApi();

  const queryClient = useQueryClient();
  const queryKey = getPatientInfoKey(patientGuid);
  const { t } = useTranslation();

  const loadPatient = async () => {
    try {
      const res = await loadData<IPatient>(patientInfoApi(patientGuid));
      if (res) {
        return new Patient(res);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes('invalid input syntax for type uuid:')) {
          navigate('/404', { replace: true });
          showResult(t('errors.invalidGuid'));
        } else {
          showResult(t('errors.unableDuePrivileges'));
        }
        throw error;
      }
    }
  };

  const {
    data: patientInfo,
    isLoading,
    error,
  } = useQuery({
    queryKey,
    queryFn: loadPatient,
    staleTime: STALE_TIME,
    enabled: Boolean(patientGuid),
    retry: false,
  });

  const updatePatientInfo = async (body: Patient): Promise<void> => {
    const data = await updateData<IPatient>(
      patientInfoApi(patientGuid),
      Patient.prepareToUpdate(body),
    );
    queryClient.setQueryData(queryKey, new Patient(data));
  };

  const updatePatientClinicInfo = async (
    body: IUpdatePatientClinicInfo,
    clinic?: Clinic,
  ): Promise<IUpdatePatientClinicInfo> => {
    const data = await updateData<IUpdatePatientClinicInfo>(
      patientClinicInfoApi(patientGuid),
      body,
    );

    queryClient.setQueryData<IPatient>(
      queryKey,
      (oldData) =>
        oldData && {
          ...oldData,
          clinicGuid: data.clinicGuid,
          doctorGuid: data.doctorGuid,
        },
    );
    if (clinic) {
      const queryClinicKey = getPatientClinicInfoKey(patientGuid);
      queryClient.setQueryData(queryClinicKey, clinic);
      const queryClinicDoctorsKey = getClinicDoctorsInfoKey(patientGuid);
      queryClient.invalidateQueries(queryClinicDoctorsKey);
    }

    return data;
  };

  const setPatientInfo = (body: Patient): void => {
    queryClient.setQueryData(queryKey, body);
  };

  return {
    loading: isLoading || loading,
    patientInfo,
    error: error as CustomError,
    updatePatientInfo,
    updatePatientClinicInfo,
    setPatientInfo,
  };
};
