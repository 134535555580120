import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const ChevronDoubleDownIcon = ({
  color = COLORS.WHITE,
  ...props
}: IIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.64645 6.64645C1.84171 6.45118 2.15829 6.45118 2.35355 6.64645L8 12.2929L13.6464 6.64645C13.8417 6.45118 14.1583 6.45118 14.3536 6.64645C14.5488 6.84171 14.5488 7.15829 14.3536 7.35355L8.35355 13.3536C8.15829 13.5488 7.84171 13.5488 7.64645 13.3536L1.64645 7.35355C1.45118 7.15829 1.45118 6.84171 1.64645 6.64645Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.64645 2.64645C1.84171 2.45118 2.15829 2.45118 2.35355 2.64645L8 8.29289L13.6464 2.64645C13.8417 2.45118 14.1583 2.45118 14.3536 2.64645C14.5488 2.84171 14.5488 3.15829 14.3536 3.35355L8.35355 9.35355C8.15829 9.54882 7.84171 9.54882 7.64645 9.35355L1.64645 3.35355C1.45118 3.15829 1.45118 2.84171 1.64645 2.64645Z"
      fill={color}
    />
  </svg>
);

export default ChevronDoubleDownIcon;
