import { useState } from 'react';
import { PatientPriorAuthorization } from 'infrastructure/classes/patient/patient-prior-authorization';
import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import useApi from '../use-api';

import type {
  IPatientPriorAuthorization,
  IUpdatePatientPriorAuthorization,
} from 'infrastructure/interfaces';

type patientPriorAuthorizations = Array<PatientPriorAuthorization>;

const priorAuthorizationsApi = (patientGuid: string) =>
  `admins/admin-panel/patients/${patientGuid}/patient-prior-authorizations`;

type UsePatientPriorAuthorization = {
  loading: boolean;
  priorAuthorizations: patientPriorAuthorizations;
  loadPriorAuthorizations: () => Promise<patientPriorAuthorizations>;
  updatePriorAuthorizations: (
    updatingPriorAuthorizations: Array<IUpdatePatientPriorAuthorization>,
  ) => Promise<patientPriorAuthorizations>;
};

interface IUsePatientPriorAuthorizationProps {
  patientGuid: string;
}

export const usePatientPriorAuthorization = (
  props: IUsePatientPriorAuthorizationProps,
): UsePatientPriorAuthorization => {
  const { patientGuid } = props;

  const { loading, isMounted, loadData, updateData } = useApi();

  const queryClient = useQueryClient();
  const queryKey = ['patientPriorAuthorization', patientGuid];

  const [priorAuthorizations, setPriorAuthorizations] =
    useState<patientPriorAuthorizations>([]);

  const loadPriorAuthorizations = async () => {
    const data = await queryClient.fetchQuery(
      queryKey,
      () =>
        loadData<Array<IPatientPriorAuthorization>>(
          priorAuthorizationsApi(patientGuid),
        ).then((res) => {
          if (res) {
            return res.map((el) => new PatientPriorAuthorization(el));
          }
        }),
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      setPriorAuthorizations(data);
      return data;
    }
    return [];
  };

  const updatePriorAuthorizations = async (
    updatingPriorAuthorizations: Array<IUpdatePatientPriorAuthorization>,
  ) => {
    const data = await updateData<Array<IPatientPriorAuthorization>>(
      priorAuthorizationsApi(patientGuid),
      {
        patientGuid,
        priorAuths: updatingPriorAuthorizations,
      },
    ).then((res) => {
      if (res) {
        return res.map((el) => new PatientPriorAuthorization(el));
      }
      return [];
    });

    setPriorAuthorizations(data);
    queryClient.setQueryData(queryKey, data);

    return data;
  };

  return {
    loading,
    priorAuthorizations,
    loadPriorAuthorizations,
    updatePriorAuthorizations,
  };
};
