import type { StringifiableRecord } from 'query-string';
import type { Filters, Option } from './types';

const isOption = (value: unknown): value is Option => {
  return typeof value === 'object' && value !== null && 'value' in value;
};

export const generateQueryFromFilters = (filters: Filters) => {
  const query: StringifiableRecord = {};

  Object.keys(filters).forEach((key) => {
    const value = filters[key as keyof Filters];
    if (Array.isArray(value)) {
      query[key] = value.map((item) => item.value);
    } else if (isOption(value)) {
      query[key] = value.value;
    } else if (value !== null && value !== undefined) {
      query[key] = value;
    }
  });

  return query;
};
