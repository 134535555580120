import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Input from 'components/atoms/input';
import ModalPopup from 'components/organisms/modal';
import Button from 'components/atoms/button';

import { caseInEhrSchema } from './validationSchema';

const MANUAL_INTERACTIVE_TIME_FIELDS = [
  { id: 'subject', type: 'text', label: 'Subject' },
  { id: 'description', type: 'textarea', label: 'Description', rows: 8 },
];

const CreateCaseInEhrModal = ({
  isOpen,
  toggle,
  isRpmPanel,
  patientGuid,
  submit,
  loading,
}) => {
  const handleSubmit = async (values) => {
    const postData = {
      isRpm: isRpmPanel,
      subject: values.subject,
      description: values.description,
      patientGuid,
    };
    await submit(postData);
  };
  return (
    <ModalPopup
      isOpen={isOpen}
      toggle={toggle}
      title="Create Case in EHR"
      className="popup manual-interactive-popup"
    >
      <Formik
        initialValues={{ subject: '', description: '' }}
        validationSchema={caseInEhrSchema()}
        onSubmit={handleSubmit}
      >
        {({ errors }) => (
          <Form>
            {MANUAL_INTERACTIVE_TIME_FIELDS.map((field, index) => (
              <Fragment key={`input-${index}-${field.id}`}>
                <Input {...field} />
                {errors[field.id] ? (
                  <div
                    key={`error-${index}-${field.id}`}
                    className="input-error"
                  >
                    {errors[field.id]}
                  </div>
                ) : null}
              </Fragment>
            ))}
            <div className="text-center">
              <Button
                type="submit"
                text="Send"
                data-cy="send-button"
                disabled={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </ModalPopup>
  );
};
CreateCaseInEhrModal.defaultProps = {
  isOpen: false,
};

CreateCaseInEhrModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  patientGuid: PropTypes.string.isRequired,
  isRpmPanel: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CreateCaseInEhrModal;
