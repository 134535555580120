import { useTranslation } from 'react-i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { FC } from 'react';

interface PatientTimeColProps {
  idx: number;
  createdAt: string;
}

const PatientTimeCol: FC<PatientTimeColProps> = ({ createdAt, idx }) => {
  const { t } = useTranslation();

  return (
    <div className={s['patient-time-cell-wrapper']}>
      <div className={s['patient-time-cell-index']}>#{idx}</div>
      <div className={s['patient-time-cell-date']}>
        <p className={s['patient-time-cell-date-label']}>{t('labels.date')}:</p>
        <p>
          {DateFormatter({
            date: new Date(parseInt(createdAt)),
            format: DateFormats['EEE, MMM d, yyyy'],
          })}
        </p>
      </div>
      <div className={s['patient-time-cell-time']}>
        <p className={s['patient-time-cell-time-label']}>{t('labels.time')}:</p>
        <p>
          {DateFormatter({
            date: new Date(parseInt(createdAt)),
            format: DateFormats['hh:mm:ss a'],
          })}
        </p>
      </div>
    </div>
  );
};
export default PatientTimeCol;
