import dataService from 'services/dataService';
import { API_PRACTICE_OVERVIEW } from 'services/dataService/resources';

const INITIAL_STATE = {
  callsPerAgent: [],
  readingHours: [],
};

const practiceOverview = {
  state: INITIAL_STATE,
  reducers: {
    setPracticeReport: (state, payload) => ({
      ...state,
      callsPerAgent: payload.callsPerAgent,
      readingHours: payload.readingHours,
    }),
  },
  effects: (dispatch) => ({
    async getPracticeReport() {
      const { data } = await dataService.getList(API_PRACTICE_OVERVIEW);
      if (data) {
        dispatch.practiceOverview.setPracticeReport(data || []);
      }
    },
  }),
};

export default practiceOverview;
