export class LocalStorageService {
  static setValue(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static getValue<ValueType>(key: string): ValueType | string | undefined {
    const value = localStorage.getItem(key);
    if (!value) return undefined;

    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  }
}
