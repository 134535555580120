import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Routes } from 'infrastructure/consts/routes';
import ParentClinicIcon from 'components/atoms/icons/parent-clinic.svg?react';

import s from './styles.module.scss';

import type { Clinic } from 'infrastructure/classes/clinic';

interface IProps {
  data: Clinic;
}

const ClinicColumn: React.FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  const isParent = !!data.childClinicsGuid.length;
  const isChild =
    Boolean(data?.parentClinicName) || Boolean(data?.parentClinicGuid);
  const parentClinicName = data?.parentClinicName ?? '';
  const isSingle = !isParent && !isChild;

  return (
    <Link
      data-cy="clinicListTable-clinic-link"
      to={Routes.adminClinicDetail(data.guid)}
      className={s.clinic}
    >
      {isParent && (
        <p className={s['name-wrapper']}>
          <span className={s.parent}>
            <ParentClinicIcon width={16} height={16} />
            {t('labels.parentClinic')}
          </span>
          <span className={s.name}>{data.legalname}</span>
        </p>
      )}
      {isChild && (
        <p className={s['name-wrapper']}>
          <span className={s.name}>{data.legalname}</span>
          <span className={s.child}>
            {t('labels.parent')}: {parentClinicName}
          </span>
        </p>
      )}
      {isSingle && (
        <p className={s['name-wrapper']}>
          <span className={s.name}>{data.legalname}</span>
        </p>
      )}
    </Link>
  );
};

export default ClinicColumn;
