import React from 'react';
import ReceptionIcon from 'components/atoms/icons/reception';

import type { PatientDevice } from 'infrastructure/classes/patient/patient-device';

interface ICellularSignalProps {
  data: PatientDevice;
}

const CellularSignal: React.FC<ICellularSignalProps> = (props) => {
  const { data } = props;
  return (
    <div data-cy="device-table-cellural-signal">
      <i>
        <ReceptionIcon level={data.log && data.log.signalStrength} />
      </i>{' '}
      {data.log && data.log.signalStrength}
    </div>
  );
};

export default CellularSignal;
