import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Pagination from 'components/molecules/pagination';
import AutoComplete from 'components/molecules/autocompleteFilter/patient';
import forms from 'constants/forms';
import common from 'constants/common';
import BaseSelect from 'components/atoms/baseSelect';

const selectTheme = (theme) => ({
  ...theme,
  borderRadius: 12,
  colors: {
    ...theme.colors,
    ...common.primaryColors,
  },
});
const DEFAULT_FILTER = forms.patientFilterOptions[0];

const Sorting = ({ count, data }) => {
  const FulfillmentOptions = forms.ccmFulfillmentStage;

  const {
    ccmOnboardingStatus: {
      setPatient,
      setStatus,
      getPatientsReport,
      setPcmStatus,
    },
  } = useDispatch();
  const { clinic, doctor, ITEMS_PER_PAGE, pcmStatus, ccmStatus } = useSelector(
    (state) => state.ccmOnboardingStatus,
  );
  const [page, setPage] = useState(1);
  const onChangePatient = (item) => {
    setPatient(item?.id);
    setPage(1);
  };

  useEffect(() => {
    getPatientsReport({
      page,
    });
  }, [page]);

  const onStatusChange = (value, action) => {
    if (value.length === 0 || action.option?.label === 'All') {
      setStatus([FulfillmentOptions[0]]);
      return;
    }
    setStatus(value.filter((item) => item.label !== 'All'));
  };
  const onPcmStatusChange = (value) => {
    setPcmStatus(value);
    setPage(1);
    getPatientsReport({
      page,
    });
  };
  const styles = {
    multiValueLabel: (base, state) => {
      return state.data.label === 'All'
        ? { ...base, paddingRight: 10, paddingLeft: 10 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.label === 'All' ? { ...base, display: 'none' } : base;
    },
  };
  return (
    <Row>
      <Col xl="2" xs="12" md="3">
        <p>Patient Name</p>
        <AutoComplete
          filter={DEFAULT_FILTER}
          handleClick={onChangePatient}
          clinicGuid={clinic}
          doctorGuid={doctor}
        />
      </Col>
      <Col xl="2" xs="12" md="3">
        <p>CCM Status</p>
        <BaseSelect
          styles={styles}
          options={FulfillmentOptions}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          onChange={onStatusChange}
          value={ccmStatus}
          isClearable={ccmStatus.length > 1}
          isMulti
        />
      </Col>
      <Col xl="2" xs="12" md="3">
        <p>PCM Status</p>
        <BaseSelect
          styles={styles}
          options={forms.pcmStatusesFilter}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          onChange={onPcmStatusChange}
          value={pcmStatus}
        />
      </Col>
      <Col xl={{ col: 2, offset: 3 }} xs="12" md="3">
        <Pagination
          title=""
          page={page || 1}
          pageTotal={data.length}
          itemsPerPage={ITEMS_PER_PAGE}
          total={count}
          handleFirstClick={() => setPage(1)}
          handleLastClick={() => setPage(Math.ceil(count / ITEMS_PER_PAGE))}
          handlePrevClick={() => setPage(page - 1)}
          handleNextClick={() => setPage(page + 1)}
          loading={false}
        />
      </Col>
    </Row>
  );
};
Sorting.propTypes = {
  data: PropTypes.array,
  count: PropTypes.number,
};

export default Sorting;
