import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const Contact = ({ data }) => (
  <div className="admin-user-block">
    <span>Contact</span>
    <Table>
      <tbody>
        <tr>
          <td>Legal Name</td>
          <td>{data.legalname}</td>
        </tr>
        {data.email && (
          <tr>
            <td>Business Email</td>
            <td>{data.email}</td>
          </tr>
        )}
        {data.phone && (
          <tr>
            <td>Business Phone</td>
            <td>{data.phone}</td>
          </tr>
        )}
        {data.fax && (
          <tr>
            <td>Business Fax</td>
            <td>{data.fax}</td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);

Contact.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Contact;
