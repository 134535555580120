import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addQueryParams } from 'utils/queryHelpers';

export type TAgencyUsersFilter = {
  page: number;
  roleName?: string;
  status?: string;
  guid?: string;
  doctorLabel?: string;
  doctorKey?: string;
  agencyGuid?: string;
  agencyLabel?: string;
};

export const useAgencyUsersFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    page,
    status,
    roleName,
    agencyGuid,
    agencyLabel,
    guid,
    doctorLabel,
    doctorKey,
  } = queryString.parse(location.search);

  const parseSearchStringValue = (value: string | (string | null)[] | null) => {
    if (typeof value === 'string') return value;
  };

  const filter = useMemo(
    () => ({
      page: Number(page || 1),
      roleName: parseSearchStringValue(roleName),
      status: parseSearchStringValue(status),
      guid: parseSearchStringValue(guid),
      agencyGuid: parseSearchStringValue(agencyGuid),
      doctorLabel: parseSearchStringValue(doctorLabel),
      agencyLabel: parseSearchStringValue(agencyLabel),
      doctorKey: parseSearchStringValue(doctorKey) ?? 'fullName',
    }),
    [
      page,
      status,
      roleName,
      agencyGuid,
      agencyLabel,
      guid,
      doctorLabel,
      doctorKey,
    ],
  );

  const resetFilter = () => {
    navigate(
      {
        search: queryString.stringify({}),
      },
      {
        replace: true,
      },
    );
  };

  const updateQueryParams = (newFilter: Partial<TAgencyUsersFilter>) => {
    addQueryParams({ navigate, location }, newFilter);
  };

  const exclude = ['page', 'doctorLabel', 'doctorKey', 'agencyLabel'];

  const countOfChangedFilterField = Object.entries(filter).filter(
    ([k, v]) => !exclude.includes(k) && Boolean(v),
  ).length;

  return {
    filter,
    updateQueryParams,
    resetFilter,
    countOfChangedFilterField,
  };
};
