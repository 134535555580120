import type { SVGProps } from 'react';

const KeyNoteEditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="6" fill="#FB9318" />
    <path
      d="M17.1465 5.14645C17.3417 4.95118 17.6583 4.95118 17.8536 5.14645L20.8536 8.14645C21.0488 8.34171 21.0488 8.65829 20.8536 8.85355L10.8536 18.8536C10.8057 18.9014 10.7486 18.9391 10.6857 18.9642L5.68571 20.9642C5.5 21.0385 5.28789 20.995 5.14646 20.8536C5.00503 20.7121 4.96149 20.5 5.03578 20.3143L7.03578 15.3143C7.06092 15.2514 7.09858 15.1943 7.14646 15.1464L17.1465 5.14645ZM16.2071 7.5L18.5 9.79289L19.7929 8.5L17.5 6.20711L16.2071 7.5ZM17.7929 10.5L15.5 8.20711L9.00001 14.7071V15H9.50001C9.77616 15 10 15.2239 10 15.5V16H10.5C10.7762 16 11 16.2239 11 16.5V17H11.2929L17.7929 10.5ZM8.03167 15.6755L7.92614 15.781L6.39754 19.6025L10.219 18.0739L10.3246 17.9683C10.135 17.8973 10 17.7144 10 17.5V17H9.50001C9.22387 17 9.00001 16.7761 9.00001 16.5V16H8.50001C8.28561 16 8.10272 15.865 8.03167 15.6755Z"
      fill="white"
      transform="translate(3, 3)"
    />
  </svg>
);

export default KeyNoteEditIcon;
