import React from 'react';

import type { SVGProps } from 'react';

const ControlPanelChatRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.0922 4.62403C10.1244 -0.473549 16.8707 -1.50509 21.1809 2.27339C25.4911 6.05187 25.7201 16.4803 21.1809 21.0786C16.6416 25.6768 6.17952 24.2016 2.61701 21.0786C-0.945501 17.9555 0.0600217 9.72162 5.0922 4.62403Z"
      fill="#FB9318"
    />
    <path
      d="M8 6.75C7.58579 6.75 7.25 7.08579 7.25 7.5V13.5C7.25 13.9142 7.58579 14.25 8 14.25H15.1893C15.5872 14.25 15.9687 14.408 16.25 14.6893L17.75 16.1893V7.5C17.75 7.08579 17.4142 6.75 17 6.75H8ZM17 6C17.8284 6 18.5 6.67157 18.5 7.5V17.0947C18.5 17.4288 18.0961 17.5961 17.8598 17.3598L15.7197 15.2197C15.579 15.079 15.3883 15 15.1893 15H8C7.17157 15 6.5 14.3284 6.5 13.5V7.5C6.5 6.67157 7.17157 6 8 6H17Z"
      fill="white"
    />
    <path
      d="M8.75 8.625C8.75 8.41789 8.91789 8.25 9.125 8.25H15.875C16.0821 8.25 16.25 8.41789 16.25 8.625C16.25 8.83211 16.0821 9 15.875 9H9.125C8.91789 9 8.75 8.83211 8.75 8.625ZM8.75 10.5C8.75 10.2929 8.91789 10.125 9.125 10.125H15.875C16.0821 10.125 16.25 10.2929 16.25 10.5C16.25 10.7071 16.0821 10.875 15.875 10.875H9.125C8.91789 10.875 8.75 10.7071 8.75 10.5ZM8.75 12.375C8.75 12.1679 8.91789 12 9.125 12H12.875C13.0821 12 13.25 12.1679 13.25 12.375C13.25 12.5821 13.0821 12.75 12.875 12.75H9.125C8.91789 12.75 8.75 12.5821 8.75 12.375Z"
      fill="white"
    />
  </svg>
);

export default ControlPanelChatRightIcon;
