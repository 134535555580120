import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Input from 'components/atoms/input';
import { formatRoleName } from 'utils/formatters';

const Profession = ({ errors, isLoading, agencies, agencyGuids }) => {
  const agencyOptions = useMemo(() =>
    agencies.map(({ guid, legalname }) => ({
      value: guid,
      label: legalname,
    })),
  );

  const roles = useSelector((state) => state.adminPanelAgencyDoctors.roles);
  const rolesOptions = useMemo(() =>
    roles.map(({ guid, name }) => ({
      value: guid,
      label: formatRoleName(name),
    })),
  );

  return (
    <div className="admin-patient-form-block">
      <span>Profession Info</span>
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <Input
            dataCy="agency-user-agency-input"
            label="Agencies"
            id="agencies"
            type="select"
            disabled={isLoading}
            isMulti
            options={agencyOptions}
            onChange={(e, setFieldValue) => setFieldValue('agencies', e)}
          />
          {!agencyGuids && errors.agencies ? (
            <div className="input-error">{errors.agencies}</div>
          ) : null}
        </Col>
        <Col lg="12" md="12" sm="12" xs="12">
          <Input
            dataCy="agency-user-profession-input"
            id="professionType"
            label="Profession"
            onChange={(e, setFieldValue) =>
              setFieldValue('professionType', e.target.value)
            }
          />
          {errors.professionType ? (
            <div className="input-error">
              {errors.professionType.split('.')[0]}
            </div>
          ) : null}
        </Col>
        <Col lg="12" md="12" sm="12" xs="12">
          <Input
            dataCy="agency-user-role-input"
            label="Roles"
            id="roles"
            type="select"
            isMulti
            options={rolesOptions}
            onChange={(e, setFieldValue) => setFieldValue('roles', e)}
          />
          {errors.roles ? (
            <div className="input-error">{errors.roles.split('.')[0]}</div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

Profession.defaultProps = {
  isLoading: false,
};

Profession.propTypes = {
  errors: PropTypes.shape({
    professionType: PropTypes.string,
    roles: PropTypes.array,
    agencies: PropTypes.array,
  }).isRequired,
  isLoading: PropTypes.bool,
  agencies: PropTypes.array.isRequired,
  agencyGuids: PropTypes.array,
};

export default Profession;
