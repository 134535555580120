import i18next from 'i18next';
import { ContactMethods } from 'infrastructure/enums';

export interface IContactMethodItem {
  id: ContactMethods;
  name: string;
}

export const contactMethodsDataSource = (): IContactMethodItem[] => {
  return [
    {
      id: ContactMethods.Email,
      name: i18next.t('contactMethods.email'),
    },
    {
      id: ContactMethods.Phone,
      name: i18next.t('contactMethods.phone'),
    },
    {
      id: ContactMethods.Sms,
      name: i18next.t('contactMethods.sms'),
    },
  ];
};
