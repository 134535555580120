import React from 'react';

const DropdownOpenIcon = (props: any) => (
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.27903 2.90403C5.40107 2.78199 5.59893 2.78199 5.72097 2.90403L9.47097 6.65403C9.59301 6.77607 9.59301 6.97393 9.47097 7.09597C9.34893 7.21801 9.15107 7.21801 9.02903 7.09597L5.5 3.56694L1.97097 7.09597C1.84893 7.21801 1.65107 7.21801 1.52903 7.09597C1.40699 6.97393 1.40699 6.77607 1.52903 6.65403L5.27903 2.90403Z"
      fill="#667180"
      {...props}
    />
  </svg>
);

export default DropdownOpenIcon;
