const createPagination = (currentPage = 0, itemsPerPage = 100, total = 0) => {
  let totalPages = Math.floor(total / itemsPerPage);
  if (total % itemsPerPage) totalPages += 1;

  const set = new Set();
  set.add(1);
  set.add(totalPages);
  set.add(currentPage - 4);
  set.add(currentPage - 3);
  set.add(currentPage - 2);
  set.add(currentPage - 1);
  set.add(currentPage);
  set.add(currentPage + 1);
  set.add(currentPage + 2);
  set.add(currentPage + 3);
  set.add(currentPage + 4);
  const pages = [];
  set.forEach((val) => {
    if (val > 0 && val <= totalPages) pages.push(val);
  });
  pages.sort((a, b) => a - b);
  if (pages[1] - pages[0] > 1) {
    pages.splice(1, 0, -1);
  }
  const last = pages.length - 1;
  const prevLast = pages.length - 2;
  if (pages[last] - pages[prevLast] > 1) {
    pages.splice(last, 0, -2);
  }
  return pages;
};

export default createPagination;
