import i18next from 'i18next';
import {
  AppointmentCompletionMethod,
  AppointmentMethod,
} from 'infrastructure/enums';

export interface IAppointmentMethodItem {
  id: AppointmentMethod | AppointmentCompletionMethod;
  name: string;
}

export const appointmentMethodsDataSource = (
  isCompletionMethods?: boolean,
): IAppointmentMethodItem[] => {
  if (isCompletionMethods) {
    return [
      {
        id: AppointmentMethod.PhoneOrVideoConference,
        name: i18next.t('appointmentMethod.phone_or_video_conference'),
      },
      {
        id: AppointmentCompletionMethod.SMSOrEmail,
        name: i18next.t('appointmentMethod.smsOrEmail'),
      },
    ];
  }

  return [
    {
      id: AppointmentMethod.PhoneOrVideoConference,
      name: i18next.t('appointmentMethod.phone_or_video_conference'),
    },
    {
      id: AppointmentMethod.SMS,
      name: i18next.t('appointmentMethod.sms'),
    },
    {
      id: AppointmentMethod.Email,
      name: i18next.t('appointmentMethod.email'),
    },
  ];
};
