import React from 'react';

const CrossStitchIcon = () => (
  <svg
    width="9"
    height="10"
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.21959 7.72157C-0.0731967 8.01457 -0.0731967 8.48961 0.21959 8.78261C0.512377 9.07561 0.987078 9.07561 1.27986 8.78261L4.49775 5.56239L7.71875 8.78198C8.01171 9.07481 8.48669 9.07481 8.77965 8.78198C9.0726 8.48915 9.0726 8.01438 8.77965 7.72155L5.55802 4.50134L8.77427 1.28275C9.06706 0.989749 9.06706 0.514703 8.77427 0.221703C8.48149 -0.0712967 8.00679 -0.0712971 7.714 0.221703L4.49713 3.44091L1.28087 0.22606C0.987911 -0.0667694 0.512933 -0.0667694 0.219976 0.22606C-0.0729818 0.518889 -0.0729818 0.993658 0.219976 1.28649L3.43686 4.50196L0.21959 7.72157Z"
      fill="#2C2543"
    />
  </svg>
);

export default CrossStitchIcon;
