import React from 'react';

const StickyWeightYAxis = () => (
  <div className="custom-axis-container">
    <svg className="glucose-axis-svg-container">
      <g
        transform="translate(0,10)"
        className="rv-xy-plot__axis rv-xy-plot__axis--vertical"
      >
        <line
          x1="40"
          x2="40"
          y1="0"
          y2="150"
          className="rv-xy-plot__axis__line"
        />
        <g transform="translate(40, 0)" className="rv-xy-plot__axis__ticks">
          <g transform="translate(0, 150)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              0
            </text>
          </g>
          <g transform="translate(0, 125)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              100
            </text>
          </g>
          <g transform="translate(0, 100)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              200
            </text>
          </g>
          <g transform="translate(0, 75)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              300
            </text>
          </g>
          <g transform="translate(0, 50)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              400
            </text>
          </g>
          <g transform="translate(0, 25)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              500
            </text>
          </g>
          <g transform="translate(0, 0)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              600
            </text>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export default StickyWeightYAxis;
