import i18next from 'i18next';
import { InsuranceProvider } from 'infrastructure/enums';

export interface IInsuranceProviderItem {
  id: InsuranceProvider;
  name: string;
}

export const insuranceProvidersDataSource = (): IInsuranceProviderItem[] => {
  return [
    {
      id: InsuranceProvider.Medicare,
      name: i18next.t('insuranceProviders.medicare'),
    },
    {
      id: InsuranceProvider.TexasMedicaid,
      name: i18next.t('insuranceProviders.texasMedicaid'),
    },
    {
      id: InsuranceProvider.PrivatePay,
      name: i18next.t('insuranceProviders.privatePay'),
    },
    {
      id: InsuranceProvider.TexasMedicaidHMO,
      name: i18next.t('insuranceProviders.texasMedicaidHMO'),
    },
    {
      id: InsuranceProvider.Commercial,
      name: i18next.t('insuranceProviders.commercial'),
    },
    {
      id: InsuranceProvider.CaliforniaMedicaid,
      name: i18next.t('insuranceProviders.californiaMedicaid'),
    },
  ];
};
