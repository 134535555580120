import { phoneRegex } from 'constants/regex';
import { REQUIRED_FIELD } from 'constants/validationMessages';
import * as Yup from 'yup';

export default Yup.object().shape({
  firstName: Yup.string()
    .required(REQUIRED_FIELD)
    .test(
      'is-not-only-spaces',
      'First Name is not valid',
      (value) => !!value && value?.trim().length > 0,
    ),
  lastName: Yup.string()
    .required(REQUIRED_FIELD)
    .test(
      'is-not-only-spaces',
      'Last Name is not valid',
      (value) => !!value && value?.trim().length > 0,
    ),
  dateOfBirth: Yup.string()
    .matches(
      /^(0[1-9]|1[012])[-]([012][0-9]|3[01])[-]([0-9]{4})$/,
      'Date of birth is not correct',
    )
    .required(REQUIRED_FIELD),
  gender: Yup.string().required(REQUIRED_FIELD).nullable(),
  street: Yup.string().required(REQUIRED_FIELD).nullable(),
  city: Yup.string().required(REQUIRED_FIELD).nullable(),
  state: Yup.string().required(REQUIRED_FIELD).nullable(),
  zip: Yup.string().required(REQUIRED_FIELD).nullable(),
  patientPrimaryPhone: Yup.string()
    .required(REQUIRED_FIELD)
    .matches(phoneRegex, 'Patient primary phone is not correct'),
  patientSecondaryPhone: Yup.string()
    .matches(phoneRegex, 'Patient secondary phone is not correct')
    .nullable(),
  patientEmail: Yup.string().email('Patient Email is not correct'),
  insurances: Yup.array().of(
    Yup.object().shape({
      insuranceNumber: Yup.string()
        .required(REQUIRED_FIELD)
        .test(
          'is-not-only-spaces',
          'Insurance Number is not valid',
          (value) => !!value && value?.trim().length > 0,
        )
        .nullable(),
      insuranceProvider: Yup.string()
        .required(REQUIRED_FIELD)
        .test(
          'is-not-only-spaces',
          'Insurance Provider is not valid',
          (value) => !!value && value?.trim().length > 0,
        )
        .nullable(),
      insuranceType: Yup.string().nullable(),
    }),
  ),
  primaryLanguage: Yup.string().required(REQUIRED_FIELD).nullable(),
  race: Yup.string().required(REQUIRED_FIELD).nullable(),
  ethnicity: Yup.string().required(REQUIRED_FIELD).nullable(),
  workingStatus: Yup.string().required(REQUIRED_FIELD).nullable(),
  timeZone: Yup.string().required(REQUIRED_FIELD).nullable(),
  maritalStatus: Yup.string().required(REQUIRED_FIELD).nullable(),
  physicianFirstName: Yup.string().required(REQUIRED_FIELD).nullable(),
  physicianLastName: Yup.string().required(REQUIRED_FIELD).nullable(),
  physicianNPI: Yup.string().nullable(),
  patientWillingToProvideAnEmergencyContact:
    Yup.string().required(REQUIRED_FIELD),
  emergencyContactCaregiverFirstName: Yup.string()
    .nullable()
    .when('patientWillingToProvideAnEmergencyContact', {
      is: 'yes',
      then: Yup.string()
        .required(REQUIRED_FIELD)
        .typeError('Emergency Contact / Caregiver First Name is required'),
      otherwise: Yup.string().nullable(),
    }),
  emergencyContactCaregiverLastName: Yup.string()
    .when('patientWillingToProvideAnEmergencyContact', {
      is: 'yes',
      then: Yup.string()
        .required(REQUIRED_FIELD)
        .typeError('Emergency Contact / Caregiver Last Name is required'),
      otherwise: Yup.string().nullable(),
    })
    .nullable(),
  emergencyContactCaregiverPhone: Yup.string()
    .when('patientWillingToProvideAnEmergencyContact', {
      is: 'yes',
      then: Yup.string()
        .matches(
          phoneRegex,
          'Emergency Contact / Caregiver Phone is not correct',
        )
        .required(REQUIRED_FIELD)
        .typeError('Emergency Contact / Caregiver Phone is required'),
      otherwise: Yup.string()
        .matches(
          phoneRegex,
          'Emergency Contact / Caregiver Phone is not correct',
        )
        .nullable(),
    })
    .nullable(),
  emergencyContactCaregiverRelationship: Yup.string()
    .when('patientWillingToProvideAnEmergencyContact', {
      is: 'yes',
      then: Yup.string()
        .required(REQUIRED_FIELD)
        .typeError('Emergency Contact / Caregiver Relationship is required'),
      otherwise: Yup.string().nullable(),
    })
    .nullable(),

  doYouHaveAnyOfTheseConditions: Yup.array().of(Yup.string()).nullable(),
  doYouAlwaysTakeYourMedicationsAsDirectedByYourPhysician:
    Yup.string().nullable(),
  doYouHaveTroubleGettingYourMedications: Yup.string().nullable(),
  haveYouBeenHospitalizedInTheLast12Months: Yup.string().nullable(),
  haveYouBeenInTheEmergencyRoomInTheLast6Months: Yup.string().nullable(),
  haveYouHadTwoOrMoreFallsInTheLast6Months: Yup.string().nullable(),
  doYouHaveABloodPressureOrGlucometerForPersonalUse: Yup.string().nullable(),
  doYouLiveAlone: Yup.string().nullable(),
  doYouHaveProvider: Yup.string().nullable(),
  whoDoYouRelyOnForTransportation: Yup.string().nullable(),
  doYouHavePowerOfAttorney: Yup.string().nullable(),
  powerOfAttorneyFullNameAndPhoneNumber: Yup.string().nullable(),
  verbalAuthorizationProvidedBy: Yup.string().nullable(),
  verbalAuthorizationDate: Yup.string()
    .matches(
      /^(0[1-9]|1[012])[-]([012][0-9]|3[01])[-]([0-9]{4})$/,
      'Verbal authorization Date is not correct',
    )
    .nullable(),
  verbalAuthorizationTime: Yup.string()
    .matches(
      /^([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AP]M)$/,
      'Verbal Authorization Time is not correct',
    )
    .nullable(),
  lastMdVisit: Yup.string()
    .matches(
      /^(0[1-9]|1[012])[-]([012][0-9]|3[01])[-]([0-9]{4})$/,
      'Last Md Visit is not correct',
    )
    .nullable(),

  companyType: Yup.string().nullable(),
  representativeName: Yup.string().nullable(),
  companyNumberCalled: Yup.string().nullable(),
  date: Yup.string()
    .matches(
      /^(0[1-9]|1[012])[-]([012][0-9]|3[01])[-]([0-9]{4})$/,
      'Date is not correct',
    )
    .nullable(),
  time: Yup.string()
    .matches(
      /^([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AP]M)$/,
      'Time is not correct',
    )
    .nullable(),
  additionalComments: Yup.string().nullable(),
});
