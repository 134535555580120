import ControlButtonDropdown from 'components/atoms/controlPanelDropdownButton/controlButtonDropdown';
import ControlPanelSuccessIcon from 'components/atoms/icons/controlPanelSuccessIcon';
import PatientControlButton from 'components/atoms/patentControlButton';
import { clinicStatusesDataSource } from 'infrastructure/data-sources/clinic-statuses';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ClinicStatus } from 'infrastructure/enums';

interface IProps {
  value: ClinicStatus;
  onChange?: (value: ClinicStatus) => void;
}

const ClinicStatusControlButton: React.FC<IProps> = (props) => {
  const { value, onChange } = props;

  const [opened, setOpened] = useState(false);

  const { t } = useTranslation();

  const clinicStatuses = clinicStatusesDataSource();

  const selectedValue = useMemo(() => {
    const val = clinicStatuses.find((el) => el.id === value);
    return val ?? clinicStatuses[0];
  }, [value, clinicStatuses]);

  const ToggleButton = (
    <PatientControlButton
      label={t('labels.status')}
      status={selectedValue.name}
      Icon={ControlPanelSuccessIcon}
      handleClick={() => setOpened(!opened)}
    />
  );

  const handleChange = (val: ClinicStatus) => {
    if (onChange) onChange(val);
    setOpened(false);
  };

  return (
    <div>
      <ControlButtonDropdown
        toggleBtn={ToggleButton}
        options={clinicStatuses.map((el) => ({
          id: el.id,
          value: el.id,
          label: el.name,
        }))}
        handleOptionClick={handleChange}
      />
    </div>
  );
};

export default ClinicStatusControlButton;
