import dataService from 'services/dataService';
import {
  API_PATIENTS_PROFILE,
  API_PATIENTS_THRESHOLDS,
  apiPatientDetails,
  apiPatientInteractiveTime,
  apiPatientVitalsReport,
  apiPatientPlanOfCareReport,
  apiPatientBingoNumber,
} from 'services/dataService/resources';

const INITIAL_STATE = {
  profile: {},
  devices: [],
  thresholds: {},
  doctor: {},
  clinic: {},
  priorAuthorizations: [],
  insurances: [],
  interactiveTime: {},
  diagnosis: {},
  emr: {},
  dates: [],
  bingoNumber: null,
};

const patient = {
  state: INITIAL_STATE,
  reducers: {
    reset(state) {
      return {
        ...INITIAL_STATE,
        dates: state.dates.length > 0 ? state.dates : [],
      };
    },
    setDetails(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    setCharts(state, payload) {
      return {
        ...state,
        charts: payload,
      };
    },
    setInteractiveTime(state, payload) {
      return {
        ...state,
        interactiveTime: payload,
      };
    },
    setFilterDates(state, payload) {
      return {
        ...state,
        dates: payload,
      };
    },
    setProfile(state, payload) {
      return {
        ...state,
        profile: payload,
      };
    },
    setBingoNumber(state, payload) {
      return {
        ...state,
        bingoNumber: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async resetPatient() {
      dispatch.patient.reset();
    },
    async getDetails(patientGuid) {
      const { data, error } = await dataService.getList(
        apiPatientDetails(patientGuid),
      );
      if (data) {
        dispatch.patient.setDetails({
          profile: data.profile || {},
          devices: data.devices || [],
          charts: data.charts || {},
          thresholds: data.thresholds || {},
          doctor: data.doctor || {},
          clinic: data.clinic || {},
          priorAuthorizations: data.priorAuths || [],
          insurances: data.insurances || [],
          diagnosis: data.diagnosis || {},
          emr: data.emr || {},
          careAgents: data.careAgents || [],
        });
      }
      return {
        data,
        error,
      };
    },
    async submit(postData, state) {
      const { error, data } = await dataService.updateOnly(
        API_PATIENTS_PROFILE,
        postData,
      );
      if (!error) {
        dispatch.patient.setDetails({
          profile: {
            ...state.patient.profile,
            ...data,
          },
        });
      }
      return { error, data };
    },
    async submitThresholds(postData, state) {
      const { error, data } = await dataService.updateOnly(
        API_PATIENTS_THRESHOLDS,
        postData,
      );
      if (!error) {
        dispatch.patient.setDetails({
          thresholds: {
            ...state.patient.thresholds,
            ...data,
          },
        });
        const patientGuid = state.patient.profile.guid;
        const { clinicGuid } = state.patient.clinic;

        dispatch.activity.setPage(1);
        dispatch.activity.setReviewed(false);
        dispatch.activity.getActivities({ patientGuid, clinicGuid });
      }
      return { error, data };
    },
    async applyTimeFilter(dates) {
      if (dates.length === 0) {
        dispatch.patient.setFilterDates([]);
      } else {
        const [startDate, endDate] = dates;
        const startTime = startDate.getTime();
        const endTime = endDate.getTime();
        dispatch.patient.setFilterDates([startTime, endTime]);
      }
    },
    async getInteractiveTime({ patientGuid, date }) {
      const { data } = await dataService.getList(
        apiPatientInteractiveTime(patientGuid, date),
      );
      if (data) {
        dispatch.patient.setInteractiveTime(data);
      }
    },
    async updateInteractiveTime(
      { patientGuid, time, clinicGuid, billingMonth, billingYear },
      state,
    ) {
      const dateObj = new Date();
      const month = dateObj.getUTCMonth() + 1;
      const year = dateObj.getUTCFullYear();

      if (
        patientGuid === state.patient.profile.guid &&
        month === billingMonth &&
        year === billingYear
      ) {
        if (clinicGuid) {
          dispatch.patient.setInteractiveTime({
            ...state.patient.interactiveTime,
            clinicTime: state.patient.interactiveTime.clinicTime + time,
            totalTime: state.patient.interactiveTime.totalTime + time,
          });
        }
        if (!clinicGuid) {
          dispatch.patient.setInteractiveTime({
            ...state.patient.interactiveTime,
            totalTime: state.patient.interactiveTime.totalTime + time,
          });
        }
      }
    },
    async getVitalsReport({ patientGuid, type }, state) {
      const timezoneOffset = new Date().getTimezoneOffset();

      const [startTime, endTime] = state.patient.dates;
      const { error, data } = await dataService.getOnly(
        apiPatientVitalsReport(
          patientGuid,
          type,
          startTime,
          endTime,
          timezoneOffset,
        ),
      );
      return { error, data };
    },
    async getPlanOfCareReport({ patientGuid, type }, state) {
      const timezoneOffset = new Date().getTimezoneOffset();

      const [startTime, endTime] = state.patient.dates;
      const { error, data } = await dataService.getOnly(
        apiPatientPlanOfCareReport(
          patientGuid,
          type,
          startTime,
          endTime,
          timezoneOffset,
        ),
      );
      return { error, data };
    },
    async getBingoNumber() {
      const { data, error } = await dataService.getList(
        apiPatientBingoNumber(),
      );
      if (data) {
        dispatch.patient.setBingoNumber(data.todays_bingo_number);
      }
      return {
        data,
        error,
      };
    },
  }),
};

export default patient;
