import { jwtDecode } from 'jwt-decode';

import { debug } from './logger';

const isJwtTokenExpired = (token: string) => {
  try {
    const { exp } = jwtDecode(token);
    if (exp === undefined) return true;
    const expireTime = exp * 1000;
    const isExpired = Date.now() >= expireTime;

    return isExpired;
  } catch (error) {
    debug('jwtDecode error: ', error);
    return false;
  }
};

export const refreshTokenIfExpired = async (token: string) => {
  const tokenExpired = isJwtTokenExpired(token);
  if (tokenExpired && typeof window.getAccessTokenSilently === 'function') {
    await window.getAccessTokenSilently({
      ignoreCache: true,
    });
  }
};
