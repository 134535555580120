import { Card, CardBody } from 'reactstrap';

import BloodPressureChart from './bloodPressure';
import BloodGlucoseChart from './bloodGlucose';
import Pulse from './pulse';
import Weight from './weight';
import SpO2 from './spO2';
import TemperatureChart from './temperatureChart';
import SleepMatChart from './sleepMatChart';
import PeakFlowChart from './peakFlow';

import type { Nullable } from 'infrastructure/types';
import type {
  TPulseChart,
  NormalizeChartsData,
} from 'infrastructure/interfaces/i-rpm-patient-charts';
import type { FC } from 'react';
import type { IPatientThresholds } from 'infrastructure/interfaces';

interface IChartsProps {
  charts?: NormalizeChartsData;
  thresholds?: Nullable<IPatientThresholds>;
  patientDetailsLoading?: boolean;
  chartsLoading?: boolean;
}

const Charts: FC<IChartsProps> = ({
  charts,
  thresholds,
  patientDetailsLoading,
  chartsLoading,
}) => {
  const glucoseChart = charts?.bloodGlucose ?? [];
  const bloodPressureChart = charts?.bloodPressure ?? [];
  const weightChart = charts?.weight ?? [];
  const spO2Chart = charts?.spO2 ?? [];
  const temperatureChart = charts?.temperature ?? [];
  const sleepingMatDurationChart = charts?.sleepingMatDuration ?? [];
  const peakFlowChart = charts?.peakFlow ?? [];

  let pulseChart: TPulseChart = [];
  if (bloodPressureChart) {
    pulseChart = [...bloodPressureChart];
  }

  if (spO2Chart) {
    pulseChart = [...pulseChart, ...spO2Chart];
  }

  if (pulseChart && pulseChart.length > 0) {
    pulseChart.sort((a, b) => +a.createdAt - +b.createdAt);
  }

  const showPulse =
    !patientDetailsLoading && !chartsLoading && pulseChart?.length > 0;
  const showGlucose =
    !patientDetailsLoading && !chartsLoading && glucoseChart?.length > 0;
  const showBloodPressure =
    !patientDetailsLoading && !chartsLoading && bloodPressureChart?.length > 1;
  const showWeight =
    !(patientDetailsLoading && chartsLoading) && weightChart?.length > 0;
  const showSpO2 =
    !patientDetailsLoading && !chartsLoading && spO2Chart?.length > 0;
  const showTemperatureChart =
    !patientDetailsLoading && !chartsLoading && temperatureChart?.length > 0;
  const showSleepMatChart =
    !patientDetailsLoading &&
    !chartsLoading &&
    sleepingMatDurationChart?.length > 0;
  const showPeakFlowChart =
    !(patientDetailsLoading && chartsLoading) && peakFlowChart?.length > 0;

  return (
    <div className="charts" id="graphs">
      {showPeakFlowChart && (
        <PeakFlowChart
          data={peakFlowChart}
          loading={patientDetailsLoading || chartsLoading}
          thresholds={thresholds}
        />
      )}
      {showBloodPressure && (
        <div className="chart-item">
          <Card className="card-border">
            <CardBody>
              <div className="charts-body">
                <BloodPressureChart
                  data={bloodPressureChart}
                  loading={patientDetailsLoading || chartsLoading}
                  thresholds={thresholds}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      {showPulse && (
        <div className="chart-item">
          <Card className="card-border">
            <CardBody>
              <div className="charts-body">
                <Pulse
                  data={pulseChart}
                  loading={patientDetailsLoading || chartsLoading}
                  thresholds={thresholds}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      {showGlucose && (
        <div className="chart-item">
          <Card className="card-border">
            <CardBody>
              <div className="charts-body">
                <BloodGlucoseChart
                  data={glucoseChart}
                  thresholds={thresholds}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      {showWeight && (
        <div className="chart-item">
          <Card className="card-border">
            <CardBody>
              <div className="charts-body">
                <Weight
                  data={weightChart}
                  loading={patientDetailsLoading || chartsLoading}
                  thresholds={thresholds}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      {showSpO2 && (
        <div className="chart-item">
          <Card className="card-border">
            <CardBody>
              <div className="charts-body">
                <SpO2
                  data={spO2Chart}
                  loading={patientDetailsLoading || chartsLoading}
                  thresholds={thresholds}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      {showTemperatureChart && (
        <div className="chart-item">
          <Card className="card-border">
            <CardBody>
              <div className="charts-body">
                <TemperatureChart
                  data={temperatureChart}
                  loading={patientDetailsLoading || chartsLoading}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      {showSleepMatChart && (
        <div className="chart-item">
          <Card className="card-border">
            <CardBody>
              <div className="charts-body">
                <SleepMatChart
                  data={sleepingMatDurationChart}
                  loading={patientDetailsLoading || chartsLoading}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  );
};

export default Charts;
