import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const clinicProviderInfoValidationSchema = () =>
  createValidationSchema({
    clinicGuid: commonValidationSchemas.string(),
    doctorGuid: commonValidationSchemas.string({
      when: {
        fields: ['clinicGuid'],
        is: (clinicGuid) => !!clinicGuid,
        then: {
          required: true,
        },
      },
    }),
  });
