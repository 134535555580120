import { DateFormats } from 'infrastructure/enums';
import { DateFormatter, isObjectEmpty } from 'infrastructure/functions';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import type { AppointmentMethod } from 'infrastructure/enums';
import type { INextAppointment } from 'infrastructure/interfaces';
import type { Nullable } from 'infrastructure/types';

interface ISelectedDoctor {
  guid: string;
  type: string;
}

interface IScheduledWithDataProps {
  agencyDoctorGuid?: string;
  doctorGuid?: string;
}

export class NextAppointment {
  patientGuid: string;
  appointment?: {
    guid: string;
    scheduledWith: string;
    appointmentMethod: Nullable<AppointmentMethod>;
    scheduledDate: Date;
    scheduledTime: string;
  };
  selectedDoctor?: ISelectedDoctor;

  constructor(patientGuid: string, data?: INextAppointment) {
    this.patientGuid = patientGuid;
    if (data && !isObjectEmpty(data)) {
      this.appointment = {
        guid: data.guid,
        scheduledWith: data.agencyDoctorGuid || data.doctorGuid || '',
        appointmentMethod: data.appointmentMethod,
        scheduledDate: utcToZonedTime(data.scheduledDate, 'UTC'),
        scheduledTime: DateFormatter({
          date: utcToZonedTime(data.scheduledDate, 'UTC'),
          format: DateFormats['HH:mm'],
        }),
      };
      this.initializeSelectedDoctor(data);
    }
  }

  prepareDate(scheduledDate: Date, scheduledTime: string): string {
    const [hours, minutes] = scheduledTime.split(':').map(Number);

    const preparedDate = zonedTimeToUtc(scheduledDate, 'UTC');
    preparedDate.setUTCHours(hours, minutes, 0, 0);

    return preparedDate.toISOString();
  }

  initializeSelectedDoctor(data: INextAppointment): void {
    if (data.agencyDoctorGuid) {
      this.selectedDoctor = {
        guid: data.agencyDoctorGuid,
        type: 'agencyDoctor',
      };
    } else if (data.doctorGuid) {
      this.selectedDoctor = {
        guid: data.doctorGuid,
        type: 'doctor',
      };
    } else {
      this.selectedDoctor = undefined;
    }
  }

  getScheduledWithData(): IScheduledWithDataProps {
    let scheduledWithData: IScheduledWithDataProps = {
      agencyDoctorGuid: this.appointment?.scheduledWith,
    };

    if (this.selectedDoctor?.type === 'doctor') {
      scheduledWithData = {
        doctorGuid: this.appointment?.scheduledWith,
      };
    }

    return scheduledWithData;
  }
}
