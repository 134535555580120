import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Input from 'components/atoms/input';

const Registration = ({ errors, formRegistration }) => (
  <div className="admin-patient-form-block">
    <span>Registration</span>
    <Row>
      {formRegistration.map((field) => (
        <Col lg="6" md="6" sm="6" xs="12" key={field.id}>
          <Input {...field} disabled={false} />
          {errors[field.id] ? (
            <div className="input-error">{errors[field.id].split('.')[0]}</div>
          ) : null}
        </Col>
      ))}
    </Row>
  </div>
);

Registration.propTypes = {
  errors: PropTypes.shape({}).isRequired,
  formRegistration: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Registration;
