import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';
import { fullNamePresenter } from 'utils';

import type { ISearchPatientResponse } from 'infrastructure/interfaces/i-rpm-patient-activity';

const searchPatientsFn = async (searchValue: string) => {
  const { data, error } = await dataService.getList<ISearchPatientResponse>(
    apiUrlGenerator('core/patients', {
      patientStatus: 'calling_queue',
      patientRequestType: 'search',
      items: 100,
      fullName: searchValue,
    }),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  if (data) {
    const preparedData = data.items.map((patient) => ({
      value: patient.guid,
      label: fullNamePresenter({
        firstName: patient.firstName,
        middleName: patient.lastName,
        lastName: `- ${patient.birthDate}`,
      }),
    }));

    return { items: preparedData, count: data.count };
  }
};

const getPatientByGuidFn = async (guid: string) => {
  const { data, error } = await dataService.getList<ISearchPatientResponse>(
    apiUrlGenerator('core/patients', {
      patientStatus: 'calling_queue',
      patientRequestType: 'search',
      items: 1,
      guid,
    }),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  if (data) {
    const preparedData = data.items.map((patient) => ({
      value: patient.guid,
      label: fullNamePresenter({
        firstName: patient.firstName,
        middleName: patient.lastName,
        lastName: `- ${patient.birthDate}`,
      }),
    }));

    return { items: preparedData, count: data.count };
  }
};

export const useSearchPatients = () => {
  const {
    mutate: getPatientByGuid,
    data: patient,
    isLoading: patientLoading,
    reset,
  } = useMutation({
    mutationFn: getPatientByGuidFn,
  });

  const { mutate, data, isLoading } = useMutation({
    mutationFn: searchPatientsFn,
    onMutate() {
      if (patient) reset();
    },
  });

  return {
    searchPatients: mutate,
    getPatientByGuid,
    patients: patient || data,
    searchPatientsLoading: patientLoading || isLoading,
  };
};
