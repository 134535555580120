import type { FC, SVGProps } from 'react';

const SubscriptionControlIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5922 4.62403C9.62437 -0.473549 16.3707 -1.50509 20.6809 2.27339C24.9911 6.05187 25.2201 16.4803 20.6809 21.0786C16.1416 25.6768 5.67952 24.2016 2.11701 21.0786C-1.4455 17.9555 -0.439978 9.72162 4.5922 4.62403Z"
        fill="#FB9318"
      />
      <path
        d="M9.73547 9C9.62155 9 9.51381 9.05178 9.44264 9.14074L7.15524 12H10.5C10.7071 12 10.875 12.1679 10.875 12.375C10.875 12.9963 11.3787 13.5 12 13.5C12.6213 13.5 13.125 12.9963 13.125 12.375C13.125 12.1679 13.2929 12 13.5 12H16.8448L14.5574 9.14074C14.4862 9.05178 14.3785 9 14.2645 9H9.73547ZM8.85699 8.67222C9.07049 8.40535 9.39371 8.25 9.73547 8.25H14.2645C14.6063 8.25 14.9295 8.40535 15.143 8.67222L17.9178 12.1407C17.9811 12.2198 18.0097 12.3211 17.9971 12.4215L17.7042 14.7645C17.6339 15.3275 17.1553 15.75 16.5879 15.75H7.41209C6.84472 15.75 6.36615 15.3275 6.29578 14.7645L6.0029 12.4215C5.99034 12.3211 6.01893 12.2198 6.08218 12.1407L8.85699 8.67222Z"
        fill="white"
      />
    </svg>
  );
};

export default SubscriptionControlIcon;
