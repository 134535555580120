import dataService from 'services/dataService';
import { API_CLINICAL_ESCALATION } from 'services/dataService/resources';

const clinicalEscalation = {
  state: {},
  reducers: {
    setDischarge: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  effects: () => ({
    async submit(postData) {
      const { error, data } = await dataService.createOne(
        API_CLINICAL_ESCALATION,
        postData,
      );
      return { error, data };
    },
  }),
};

export default clinicalEscalation;
