import common from 'constants/common';

export default [
  {
    title: 'Pulse',
    color: common.chartColors.sleepMat.pulse,
    strokeStyle: 'solid',
  },
  {
    title: 'REM',
    color: common.chartColors.sleepMat.rem,
    strokeStyle: 'solid',
  },
  {
    title: 'Light',
    color: common.chartColors.sleepMat.lightSleep,
    strokeStyle: 'solid',
  },
  {
    title: 'Deep',
    color: common.chartColors.sleepMat.deepSleep,
    strokeStyle: 'solid',
  },
];
