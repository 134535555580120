import classNames from 'classnames';
import ButtonLoadingIcon from 'components/atoms/icons/buttonLoadingIcon';
import BaseSwitch from 'components/atoms/base-switch';
import { useTranslation } from 'react-i18next';
import useUser from 'utils/useUser';
import { useMemo, type FC } from 'react';

import s from './styles.module.scss';

import type { Nullable } from 'infrastructure/types';
import type { IActivityReserved } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface AssignSwitchProps {
  isLoading: boolean;
  reserved: Nullable<IActivityReserved>;
  loginGuid: string;
  checked: boolean;
  onChange: () => void;
}

const AssignSwitch: FC<AssignSwitchProps> = ({
  isLoading,
  reserved,
  loginGuid,
  checked,
  onChange,
}) => {
  const { isMobileDevice } = useUser();
  const { t } = useTranslation();
  const isAssignedToMe = reserved && reserved.loginGuid === loginGuid;
  const isAssignedToOther = reserved && reserved.loginGuid !== loginGuid;

  const assignedToMeTitle = isAssignedToMe
    ? t('labels.assignedToMe')
    : undefined;
  const profession = reserved?.professionType
    ? `- ${reserved?.professionType}`
    : '';
  const isAssignedToOtherTitle = isAssignedToOther
    ? `${reserved?.title ?? ''} ${reserved?.firstName ?? ''} ${
        reserved?.lastName ?? ''
      } ${profession}`
    : undefined;

  const assignedTitle = assignedToMeTitle ?? isAssignedToOtherTitle;

  const assignLabel = useMemo(() => {
    if (reserved) {
      return isAssignedToOther ? '' : t('labels.unassign');
    }
    return t('labels.assign');
  }, [reserved, isAssignedToOther, t]);

  return (
    <div data-cy="reading-assign" className={classNames(s.assign, {})}>
      {isAssignedToOther && !isMobileDevice ? (
        <div className={s['assigned-to-wrapper']}>
          <p>{t('labels.assigned')}: </p>
          <p className={s['assigned-to']}>{assignedTitle}</p>
        </div>
      ) : (
        <p>{assignLabel}</p>
      )}
      {!isAssignedToOther && (
        <div>
          {isLoading ? (
            <ButtonLoadingIcon className={s['spin-loading-icon']} />
          ) : (
            <BaseSwitch
              size="small"
              value={checked}
              onChange={onChange}
              disabled={isLoading || !!isAssignedToOther}
              title={assignedTitle}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default AssignSwitch;
