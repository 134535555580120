import type { SVGProps } from 'react';

const ControlHospitalizationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.92423 4.62403C9.9564 -0.473549 16.7027 -1.50509 21.0129 2.27339C25.3231 6.05187 25.5522 16.4803 21.0129 21.0786C16.4737 25.6768 6.01155 24.2016 2.44904 21.0786C-1.11347 17.9555 -0.107947 9.72162 4.92423 4.62403Z"
      fill="#FB9318"
    />
    <path
      d="M12.707 9.77578V10.6011L13.4218 10.1885L13.7968 10.838L13.082 11.2507L13.7968 11.6633L13.4218 12.3129L12.707 11.9002V12.7255H11.957V11.9002L11.2422 12.3129L10.8672 11.6633L11.582 11.2507L10.8672 10.838L11.2422 10.1885L11.957 10.6011V9.77578H12.707Z"
      fill="white"
    />
    <path
      d="M16.2695 12.75C16.166 12.75 16.082 12.8339 16.082 12.9375V13.3125C16.082 13.4161 16.166 13.5 16.2695 13.5H16.6445C16.7481 13.5 16.832 13.4161 16.832 13.3125V12.9375C16.832 12.8339 16.7481 12.75 16.6445 12.75H16.2695Z"
      fill="white"
    />
    <path
      d="M16.082 14.4375C16.082 14.3339 16.166 14.25 16.2695 14.25H16.6445C16.7481 14.25 16.832 14.3339 16.832 14.4375V14.8125C16.832 14.9161 16.7481 15 16.6445 15H16.2695C16.166 15 16.082 14.9161 16.082 14.8125V14.4375Z"
      fill="white"
    />
    <path
      d="M16.2695 15.75C16.166 15.75 16.082 15.8339 16.082 15.9375V16.3125C16.082 16.4161 16.166 16.5 16.2695 16.5H16.6445C16.7481 16.5 16.832 16.4161 16.832 16.3125V15.9375C16.832 15.8339 16.7481 15.75 16.6445 15.75H16.2695Z"
      fill="white"
    />
    <path
      d="M8.01953 12.75C7.91598 12.75 7.83203 12.8339 7.83203 12.9375V13.3125C7.83203 13.4161 7.91598 13.5 8.01953 13.5H8.39453C8.49808 13.5 8.58203 13.4161 8.58203 13.3125V12.9375C8.58203 12.8339 8.49808 12.75 8.39453 12.75H8.01953Z"
      fill="white"
    />
    <path
      d="M8.01953 14.25C7.91598 14.25 7.83203 14.3339 7.83203 14.4375V14.8125C7.83203 14.9161 7.91598 15 8.01953 15H8.39453C8.49808 15 8.58203 14.9161 8.58203 14.8125V14.4375C8.58203 14.3339 8.49808 14.25 8.39453 14.25H8.01953Z"
      fill="white"
    />
    <path
      d="M7.83203 15.9375C7.83203 15.8339 7.91598 15.75 8.01953 15.75H8.39453C8.49808 15.75 8.58203 15.8339 8.58203 15.9375V16.3125C8.58203 16.4161 8.49808 16.5 8.39453 16.5H8.01953C7.91598 16.5 7.83203 16.4161 7.83203 16.3125V15.9375Z"
      fill="white"
    />
    <path
      d="M10.082 6.75C10.082 6.33579 10.4178 6 10.832 6H13.832C14.2462 6 14.582 6.33579 14.582 6.75V7.5C14.9962 7.5 15.332 7.83579 15.332 8.25V11.25H17.582C17.9962 11.25 18.332 11.5858 18.332 12V17.25C18.332 17.6642 17.9962 18 17.582 18H7.08203C6.66782 18 6.33203 17.6642 6.33203 17.25V12C6.33203 11.5858 6.66782 11.25 7.08203 11.25H9.33203V8.25C9.33203 7.83579 9.66782 7.5 10.082 7.5V6.75ZM11.582 17.25H13.082V15H11.582V17.25ZM13.832 17.25H14.582V8.25L10.082 8.25V17.25H10.832V15C10.832 14.5858 11.1678 14.25 11.582 14.25H13.082C13.4962 14.25 13.832 14.5858 13.832 15V17.25ZM13.832 6.75H10.832V7.5H13.832V6.75ZM15.332 12V17.25H17.582V12H15.332ZM9.33203 17.25V12H7.08203V17.25H9.33203Z"
      fill="white"
    />
  </svg>
);

export default ControlHospitalizationIcon;
