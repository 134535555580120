import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'components/molecules/pagination';

const Footer = () => {
  const { page, data, itemsPerPage, totalCount } = useSelector(
    (state) => state.adminPanelAgencies,
  );
  const { getAgencies: patientsLoading } = useSelector(
    (state) => state.loading.effects.adminPanelAgencies,
  );
  const {
    adminPanelAgencies: { setAgenciesPage },
  } = useDispatch();
  const setPage = (pageOffset) => {
    setAgenciesPage({ pageOffset });
  };
  return (
    <div className="adminPanel__footer">
      <Pagination
        title=""
        page={page}
        pageTotal={data?.length}
        itemsPerPage={itemsPerPage}
        total={totalCount}
        handleFirstClick={() => setPage(-(page - 1))}
        handleLastClick={() => setPage(Math.ceil(totalCount / itemsPerPage))}
        handlePrevClick={() => setPage(-1)}
        handleNextClick={() => setPage(+1)}
        loading={patientsLoading}
      />
    </div>
  );
};

export default Footer;
