import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_MEDICAL_HISTORY } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import { apiUrlGenerator } from '../../use-api';

import type {
  IMedicalHistoryResponse,
  IPartialPaginationMeta,
} from 'infrastructure/interfaces';

interface Props {
  patientGuid: string;
  meta: IPartialPaginationMeta;
}

type TFilter = {
  patientGuid: string;
  isActive: boolean;
} & IPartialPaginationMeta;

export const getMedicalHistoryKey = (filter: TFilter) => [
  'getMedicalHistory',
  filter,
];

export const MEDICAL_HISTORY_PER_PAGE = 5;
export const MEDICAL_HISTORY_IS_ACTIVE = true;

const getMedicalHistoryFn = async (filter: TFilter) => {
  const url = API_MEDICAL_HISTORY;
  const { error, data } = await dataService.getList<IMedicalHistoryResponse>(
    apiUrlGenerator(url, filter),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useGetMedicalHistory = ({ patientGuid, meta }: Props) => {
  const filter: TFilter = {
    ...meta,
    patientGuid,
    isActive: MEDICAL_HISTORY_IS_ACTIVE,
    items: meta.items ?? MEDICAL_HISTORY_PER_PAGE,
  };

  const { data: medicalHistory, ...other } = useQuery({
    queryKey: getMedicalHistoryKey(filter),
    queryFn: async () => getMedicalHistoryFn(filter),
    staleTime: STALE_TIME,
  });
  return {
    medicalHistory,
    ...other,
  };
};
