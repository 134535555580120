import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const Address = ({ data }) => (
  <Table borderless border="0" cellSpacing="0" cellPadding="0">
    <thead>
      <tr>
        <th colSpan="2">
          <div className="pagination-container">
            <h5>Address</h5>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td />
        <td>
          {data.addressLine1 && (
            <>
              <span className="subheader">User Address Line 1: </span>
              <span data-cy="user-address-line-1">{data.addressLine1}</span>
            </>
          )}
          {data.addressLine2 && (
            <>
              <span className="subheader">User Address Line 2: </span>
              <span data-cy="user-address-line-2">{data.addressLine2}</span>
            </>
          )}
          {data.city && (
            <>
              <span className="subheader">City: </span>
              <span data-cy="city">{data.city}</span>
            </>
          )}
          {data.state && (
            <>
              <span className="subheader">State: </span>
              <span data-cy="state">{data.state}</span>
            </>
          )}
          {data.zip && (
            <>
              <span className="subheader">Zip: </span>
              <span data-cy="zip">{data.zip}</span>
            </>
          )}
        </td>
      </tr>
    </tbody>
  </Table>
);

Address.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Address;
