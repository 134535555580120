import { useQuery } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { apiGetAgencyByGuid } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';
import dataService from 'services/dataService';
import { AgencyInfo } from 'infrastructure/classes/agency/agency-info';

import type { IAgencyInfo } from 'infrastructure/interfaces';

interface Props {
  agencyGuid?: string;
}

export const getAgencyInfoKey = (agencyGuid: string) => [
  'agencyInfo',
  agencyGuid,
];

export const useAgencyInfo = ({ agencyGuid }: Props) => {
  const { data: agencyInfo, isLoading } = useQuery({
    queryKey: getAgencyInfoKey(agencyGuid ?? ''),
    queryFn: async () => {
      const { data, error } = await dataService.getOnly<IAgencyInfo>(
        apiGetAgencyByGuid(agencyGuid),
      );

      if (error) {
        showResult(error);
        throw new Error(error);
      }

      if (data) {
        return new AgencyInfo(data);
      }
    },
    staleTime: STALE_TIME,
    enabled: Boolean(agencyGuid),
  });

  return {
    agencyInfo,
    agencyInfoLoading: isLoading,
  };
};
