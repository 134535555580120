import COLORS from 'assets/styles/color.module.scss';

import type { SVGProps } from 'react';

const ChevronRightIcon = ({
  color = COLORS.WHITE,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.78048 1.9006C4.96793 1.71315 5.27185 1.71315 5.4593 1.9006L11.2193 7.6606C11.4068 7.84805 11.4068 8.15197 11.2193 8.33942L5.4593 14.0994C5.27185 14.2869 4.96793 14.2869 4.78048 14.0994C4.59303 13.912 4.59303 13.6081 4.78048 13.4206L10.2011 8.00001L4.78048 2.57942C4.59303 2.39197 4.59303 2.08805 4.78048 1.9006Z"
      fill={color}
    />
  </svg>
);

export default ChevronRightIcon;
