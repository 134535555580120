import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/organisms/header';

const Layout = ({ children, disableNavigation }) => (
  <div>
    <Header disableNavigation={disableNavigation} />
    <div className="patient-key-note-portal-container" />
    <div className="content">{children}</div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.object,
  disableNavigation: PropTypes.bool,
};

export default Layout;
