import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showResult } from 'infrastructure/hooks/utils';
import dataService from 'services/dataService';
import { API_READINGS_STOP_SLA_TIMER } from 'services/dataService/resources';

import { setSingleSlaReviewedTime } from './helpers/update-cache';

import type { QueryKey } from '@tanstack/react-query';
import type {
  IRpmPatientActivity,
  IStopSlaTimerResponse,
} from 'infrastructure/interfaces/i-rpm-patient-activity';

interface DataProps {
  readingId: string;
  deviceId: string;
}

interface Props {
  getActivityQueryKey: QueryKey;
}

const stopSlaTimerFn = async (postData: DataProps) => {
  const { error, data } = await dataService.createOne<IStopSlaTimerResponse>(
    API_READINGS_STOP_SLA_TIMER,
    postData,
  );
  if (error) {
    showResult(error);
    return false;
  }

  return data;
};

export const useStopSlaTimer = ({ getActivityQueryKey }: Props) => {
  const queryClient = useQueryClient();
  const { mutate, ...other } = useMutation({
    mutationFn: stopSlaTimerFn,
    onSuccess(data) {
      queryClient.setQueryData<IRpmPatientActivity>(
        getActivityQueryKey,
        (oldData) => {
          if (data && oldData) {
            const newData = structuredClone(oldData);
            const { deviceId, readingId, slaReviewedTime } = data;
            setSingleSlaReviewedTime({
              newData,
              data: { deviceId, readingId, slaReviewedTime },
            });

            return newData;
          }
        },
      );
    },
  });

  return {
    stopSlaTimer: mutate,
    ...other,
  };
};
