import dataService from 'services/dataService';
import {
  apiAgencyDoctorProfile,
  API_ADMIN_PANEL_AGENCY_DOCTORS,
  API_ADMIN_PANEL_AGENCIES,
  API_ROLES,
} from 'services/dataService/resources';

const ITEMS_PER_PAGE = 100;

const EMPTY_AGENCY_DOCTOR = {
  roles: [],
  agencies: [],
};

const INITIAL_STATE = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  filters: {
    agencyDoctorGuid: null,
    roleName: 'all',
    agencyGuid: null,
    status: null,
  },
  agencies: [],
  roles: [],
  agencyDoctor: EMPTY_AGENCY_DOCTOR,
  data: [],
  totalPages: 0,
  totalCount: 0,
};

const adminPanelAgencyDoctors = {
  state: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    setFilters: (state, payload) => ({
      ...state,
      filters: { ...state.filters, ...payload },
    }),
    setAgencies: (state, payload) => ({
      ...state,
      agencies: payload,
    }),
    setAgencyDoctor: (state, payload) => ({
      ...state,
      agencyDoctor: payload,
    }),
    setRoles: (state, payload = []) => ({
      ...state,
      roles: payload,
    }),
  },
  effects: (dispatch) => ({
    async getRoles() {
      const { data } = await dataService.getList(
        `${API_ROLES}?userType=homeHealth`,
      );
      const rolesWithoutTurk = data.filter(
        (role) => role.name.toLowerCase() !== 'turk',
      );
      dispatch.adminPanelAgencyDoctors.setRoles(rolesWithoutTurk);
    },
    async getAgencies() {
      const { error, data } = await dataService.getList(
        `${API_ADMIN_PANEL_AGENCIES}?noLimit=true`,
      );

      if (error) {
        throw error;
      }

      if (data && data.count > 0) {
        dispatch.adminPanelAgencyDoctors.setAgencies(data.items);
      }
    },
    async getAgencyDoctor(agentGuid) {
      if (agentGuid) {
        const { error, data } = await dataService.getList(
          apiAgencyDoctorProfile(agentGuid),
        );
        if (error) throw error;
        if (data) {
          dispatch.adminPanelAgencyDoctors.setAgencyDoctor(data);
        }
      } else {
        dispatch.adminPanelAgencyDoctors.setAgencyDoctor(EMPTY_AGENCY_DOCTOR);
      }
    },
    async createAgencyDoctor(body) {
      const { error, data } = await dataService.createOne(
        API_ADMIN_PANEL_AGENCY_DOCTORS,
        body,
      );
      return { error, data };
    },
    async updateAgencyDoctor(body) {
      const { error, data } = await dataService.updateOnly(
        API_ADMIN_PANEL_AGENCY_DOCTORS,
        body,
      );
      return { error, data };
    },
  }),
};

export default adminPanelAgencyDoctors;
