import {
  isAdminModule,
  isCcmModule,
  isRpmModule,
} from 'infrastructure/functions';
import BaseButton from 'components/atoms/baseButton';
import RiskScoreTag from 'components/atoms/tags/riskScoreTag';
import classNames from 'classnames';
import SlaTag from 'components/atoms/tags/SlaTag';
import DoNotCallTag from 'components/atoms/tags/DoNotCallTag';
import EmailUnsubscribedTag from 'components/atoms/tags/EmailUnsubscribedTag';
import { capitalize, isUndefined } from 'lodash';
import BaseLoader from 'components/atoms/base-loader';
import { useTranslation } from 'react-i18next';
import CopyableText from 'components/atoms/buttons/copyable-text';
import CopyIcon from 'components/atoms/icons/copyIcon';
import PcmTag from 'components/atoms/tags/PcmTag';

import s from './styles.module.scss';
import PatientServiceRow from '../patient-service-row';
import PatientStatusRow from '../patient-status-row';
import { ActionDropdownButton } from '../action-dropdown-button';
import { useActionButtons } from '../../hooks';

import type { ModuleType } from 'infrastructure/enums';
import type { IPatientHoldingStatus } from 'infrastructure/interfaces';
import type { FC } from 'react';

interface MobileBannerProps {
  patientGuid: string;
  module: ModuleType;
  showHoldingStatusBadge: boolean;
  showTemporaryHoldBadge?: boolean;
  isLoading: boolean;
  holdingStatus?: IPatientHoldingStatus;
  remainingDays: number;
  birthDate: string;
  fullName: string;
  statusColor?: string;
  isShowDoNotCallTag?: boolean;
  isShowEmailTag?: boolean;
  pcmStatus?: boolean;
  sla?: number;
  providerServiceType?: string;
  serviceDeliveryModel?: string;
  clinicName?: string;
  physician?: string;
  referralTypes?: string;
  rpmStatusObj?: Readonly<{
    label: string;
    color: string;
  }>;
  ccmStatusObj?: Readonly<{
    label: string;
    color: string;
  }>;
  riskScore?: number;
  disabledCreateInternalAdmissionForms?: boolean;
  rpmSmsSubscription?: string;
  ccmSmsSubscription?: string;
}

const MobileBanner: FC<MobileBannerProps> = ({
  patientGuid,
  module,
  showHoldingStatusBadge,
  showTemporaryHoldBadge,
  isLoading,
  statusColor,
  holdingStatus,
  remainingDays,
  isShowDoNotCallTag,
  isShowEmailTag,
  pcmStatus,
  sla,
  birthDate,
  providerServiceType,
  serviceDeliveryModel,
  clinicName,
  physician,
  referralTypes,
  fullName,
  rpmStatusObj,
  ccmStatusObj,
  riskScore,
  disabledCreateInternalAdmissionForms,
  rpmSmsSubscription,
  ccmSmsSubscription,
}) => {
  const { t } = useTranslation();
  const { actionButtons, actionDropdownButtons, adminActionButtons } =
    useActionButtons({
      patientGuid,
      disabledCreateInternalAdmissionForms,
    });

  return (
    <div
      data-cy="patient-header"
      className={classNames(s.wrapper, {
        [s.corner]: showHoldingStatusBadge || showTemporaryHoldBadge,
      })}
      id="patient-banner-mob"
    >
      {isLoading ? (
        <div className={s['loading-box']}>
          <BaseLoader loading={isLoading} />
        </div>
      ) : (
        <>
          <div
            className={s['status-indicator']}
            style={{ backgroundColor: statusColor }}
          />
          {showTemporaryHoldBadge && (
            <div
              data-cy="temporary-hold-tag"
              className={classNames(s['holding-status'], {
                [s.corner]: true,
              })}
            >
              <p>{t('labels.temporaryHold')}</p>
            </div>
          )}
          {showHoldingStatusBadge && (
            <div
              data-cy="holding-status-tag"
              className={classNames(s['holding-status'], {
                [s.corner]: !showTemporaryHoldBadge,
                [s['move-right']]: showTemporaryHoldBadge,
              })}
            >
              <p>{holdingStatus && capitalize(holdingStatus.status)}:</p>
              <p>
                {remainingDays + 1}{' '}
                {remainingDays > 1 ? t('labels.day') : t('labels.days')}
              </p>
            </div>
          )}
          <div className={s['title-wrapper']}>
            <h4 className={s.title} data-cy="patient-name">
              {fullName}
            </h4>
            <p className={s.date} data-cy="patient-birthday-date">
              {birthDate}
            </p>
            <p data-cy="patient-id" className={s['patient-id']}>
              <CopyableText value={patientGuid} icon={<CopyIcon />} />
            </p>
            <div className={s['tags-wrapper']}>
              {!isUndefined(sla) && <SlaTag hours={sla} small />}
              {isShowDoNotCallTag && <DoNotCallTag small />}
              {isShowEmailTag && <EmailUnsubscribedTag small />}
              {pcmStatus && <PcmTag small />}
            </div>
          </div>
          <div className={s.divider} />
          <div
            className={classNames(s.status, {
              [s.reverse]: isCcmModule(module),
            })}
          >
            <PatientStatusRow
              isActiveLabel={isRpmModule(module)}
              isActiveValue={isRpmModule(module) || isAdminModule(module)}
              label={t('labels.rpmStatus')}
              value={rpmStatusObj?.label ?? 'Invalid Status'}
              color={rpmStatusObj?.color}
            />
            <PatientStatusRow
              isActiveLabel={isCcmModule(module)}
              isActiveValue={isCcmModule(module) || isAdminModule(module)}
              label={t('labels.ccmStatus')}
              value={ccmStatusObj?.label ?? 'Invalid Status'}
              color={ccmStatusObj?.color}
            />
            {isAdminModule(module) && (
              <>
                {rpmSmsSubscription && (
                  <PatientStatusRow
                    isActiveLabel={false}
                    isActiveValue
                    label={t('labels.rpmSmsSubscription')}
                    value={rpmSmsSubscription}
                  />
                )}
                {ccmSmsSubscription && (
                  <PatientStatusRow
                    isActiveLabel={false}
                    isActiveValue
                    label={t('labels.ccmSmsSubscription')}
                    value={ccmSmsSubscription}
                  />
                )}
              </>
            )}
          </div>
          <div className={s.divider} />
          <div className={s.services}>
            <PatientServiceRow
              label={t('labels.providerServiceType')}
              value={providerServiceType}
            />
            <PatientServiceRow
              label={t('labels.serviceDeliveryModel')}
              value={serviceDeliveryModel}
            />
            <PatientServiceRow
              label={t('labels.bannerClinic')}
              value={clinicName}
            />
            <PatientServiceRow
              label={t('labels.bannerProvider')}
              value={physician}
            />
            <PatientServiceRow
              label={t('labels.bannerReferralType')}
              value={referralTypes}
            />
          </div>

          {!isUndefined(riskScore) && isCcmModule(module) && (
            <RiskScoreTag score={riskScore} />
          )}
          <div className={s['buttons-wrapper']}>
            {isCcmModule(module) &&
              actionButtons.map((btn) => (
                <BaseButton
                  key={btn.key}
                  label={btn.label}
                  icon={<btn.Icon />}
                  onClick={btn.handleClick}
                  loading={btn.loading}
                  type="secondary"
                  dataCy={btn.dataCy}
                />
              ))}
            {isRpmModule(module) &&
              actionDropdownButtons.map((b) => (
                <ActionDropdownButton
                  key={b.key}
                  options={b.options}
                  label={b.label}
                  dataCy={b.dataCy}
                  loading={b.loading}
                />
              ))}
            {isAdminModule(module) &&
              adminActionButtons.map((btn) => (
                <BaseButton
                  key={btn.key}
                  label={btn.label}
                  icon={<btn.Icon />}
                  onClick={btn.handleClick}
                  loading={btn.loading}
                  type="secondary"
                  dataCy={btn.dataCy}
                  className={s['admin-action-btn']}
                  disabled={btn.disabled}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
export default MobileBanner;
