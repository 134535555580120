import i18next from 'i18next';
import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';
import { numberRegExp } from 'infrastructure/regexp';

export const careLocationsValidationSchema = () =>
  createValidationSchema({
    facilityName: commonValidationSchemas.string({ required: true }),
    facilityAddress: commonValidationSchemas.string({}),
    facilityNumber: commonValidationSchemas.string({
      matches: {
        regexp: numberRegExp,
        message: i18next.t('validation.number'),
      },
    }),
    zipCode: commonValidationSchemas.string(),
    city: commonValidationSchemas.string({
      when: {
        fields: ['zipCode'],
        is: (zipCode) => !!zipCode,
        then: {
          required: true,
        },
      },
    }),
    state: commonValidationSchemas.string({
      when: {
        fields: ['zipCode', 'city'],
        is: (zipCode, city) => !!zipCode || !!city,
        then: {
          required: true,
        },
      },
    }),
  });
