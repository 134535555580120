import { booleanPresenter } from 'utils/presenters/boolean';
import {
  interactiveTimeGroupPresenter,
  readingsToBillableSeconds,
} from 'infrastructure/services/interactiveTime';
import { convertSecondsToTime } from 'utils/timeHelper';
import useUser from 'utils/useUser';
import { Link } from 'react-router-dom';
import { isInteractiveTimeAssignAllowed } from 'constants/permissions';
import { DateFormatter } from 'infrastructure/functions';
import { useTranslation } from 'react-i18next';

import Assignee from './assignee';
import s from './style.module.scss';
import ContactAvailabilityCol from './contact-availability';

import type { InteractiveTimeItem } from 'infrastructure/hooks/interactiveTimeDashboard/types';
import type { TableProps } from 'antd';

export const useColumns = (): TableProps<InteractiveTimeItem>['columns'] => {
  const { userType } = useUser();
  const showAssigneeFilter = isInteractiveTimeAssignAllowed(userType);

  const { t } = useTranslation();

  return [
    {
      title: t('labels.patientGuid'),
      key: 'patientGuid',
      render: (_, record) => (
        <Link to={`/patient/${record.patient.guid}`} className={s.link}>
          {record.patient.guid}
        </Link>
      ),
    },
    {
      title: t('labels.contactAvailability'),
      key: 'contactAvailability',
      align: 'center',
      width: '105px',
      className: s.contact,
      render: (_, record) => <ContactAvailabilityCol record={record} />,
    },
    {
      title: t('labels.patientName'),
      key: 'patientFullName',
      render: (_, record) => <span>{record.patient.fullName}</span>,
    },
    {
      title: t('labels.interactiveTimeGroup'),
      key: 'interactiveTimeGroup',
      render: (_, record) => (
        <span>{interactiveTimeGroupPresenter(record.actionTimes.total)}</span>
      ),
    },
    {
      title: t('labels.criticalReadings'),
      key: 'criticalReadings',
      render: (_, record) => <b>{record.readingsCount.critical}</b>,
    },
    {
      title: t('labels.atRiskReadings'),
      key: 'atRiskReadings',
      render: (_, record) => <b>{record.readingsCount.atRisk}</b>,
    },
    {
      title: t('labels.normalReadings'),
      key: 'normalReadings',
      render: (_, record) => <b>{record.readingsCount.normal}</b>,
    },
    {
      title: t('labels.totalReadings'),
      key: 'totalReadings',
      render: (_, record) => (
        <b>
          {record.readingsCount.atRisk +
            record.readingsCount.critical +
            record.readingsCount.normal}
        </b>
      ),
    },
    {
      title: t('labels.totalInteractiveTime'),
      key: 'totalInteractiveTime',
      render: (_, record) => (
        <span>{convertSecondsToTime(record.actionTimes.total)}</span>
      ),
    },
    {
      title: t('labels.availableBillableMinutes'),
      key: 'availableBillableMinutes',
      render: (_, record) => (
        <span>
          {convertSecondsToTime(
            readingsToBillableSeconds(
              record.readingsCount.unreviewedAgencyReadings,
            ),
          )}
        </span>
      ),
    },
    {
      title: t('labels.lastCallDate'),
      key: 'lastPhoneCall',
      width: '105px',
      render: (_, record) => (
        <span className={s.lastPhoneCall}>
          {record.lastPhoneCall &&
            DateFormatter({ date: record.lastPhoneCall })}
        </span>
      ),
    },
    {
      title: t('labels.interactionFlag'),
      key: 'interactionFlag',
      render: (_, record) => {
        return (
          <div className={s.interactionStatus}>
            {booleanPresenter(record.patientInteractionStatus)}
            {record.patientInteractionStatus &&
              record.lastInteractionStatusChange && (
                <span className={s.date}>
                  {t('labels.flagSet')}: <br />
                  {DateFormatter({ date: record.lastInteractionStatusChange })}
                </span>
              )}
          </div>
        );
      },
    },
    {
      title: t('labels.assignee'),
      key: 'assignee',
      hidden: showAssigneeFilter === false,
      render: (_, record) => <Assignee interactiveTimeItem={record} />,
    },
  ];
};
