import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { registerLocale } from 'react-datepicker';
import enGb from 'date-fns/locale/en-GB';
import 'react-datepicker/dist/react-datepicker.css';
import { useCcmAppointmentByMonth } from 'utils/hooks/ccmAppointmentByMonth';
import BaseLoader from 'components/atoms/base-loader';

registerLocale('en-Gb', enGb);

const AppointmentList = ({ endDate }) => {
  const { loadData, items, loading } = useCcmAppointmentByMonth();

  useEffect(() => {
    if (endDate) {
      loadData(endDate);
    }
  }, [endDate]);

  const appointmentMethodMap = {
    phone_or_video_conference: 'Phone/Video Conference',
    sms_or_email: 'SMS/Email',
  };

  return (
    <div className="range-datepicker--appointment-completions">
      <div className="range-datepicker--appointment-completions--title">
        Appointments list
      </div>
      <div className="range-datepicker--appointment-completions--list-wrapper">
        {loading && (
          <BaseLoader width={30} height={30} className="loader" laoding />
        )}
        {!loading &&
          items.map((item) => {
            const appointmentMethod =
              appointmentMethodMap[item?.appointmentMethod] ||
              item?.appointmentMethod;
            return (
              <div
                className="range-datepicker--appointment-completions--list-wrapper--item"
                key={item.guid}
              >
                <div className="range-datepicker--appointment-completions--list-wrapper--item--doctor-name">
                  {item.completedBy}
                </div>
                <div className="range-datepicker--appointment-completions--list-wrapper--item--date">
                  {item.completedDate}
                </div>
                <div className="range-datepicker--appointment-completions--list-wrapper--item--date">
                  {appointmentMethod}
                </div>
              </div>
            );
          })}
        {!loading && items.length === 0 && (
          <div className="range-datepicker--appointment-completions--list-wrapper--item no-bg">
            No appointments
          </div>
        )}
      </div>
    </div>
  );
};

AppointmentList.propTypes = {
  endDate: PropTypes.any,
};

export default AppointmentList;
