import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import CountCard from 'components/atoms/billingCountCard';

const BillingCount = () => {
  const activeNumber = useSelector((state) => state.medicare[99454]);
  const newPatientNumber = useSelector((state) => state.medicare[99453]);
  const lowActivityCount = useSelector((state) => state.medicare[99457]);
  const mediumActivityCount = useSelector((state) => state.medicare[994581]);
  const highActivityCount = useSelector((state) => state.medicare[994582]);

  return (
    <div className="billing-count">
      <Card className="card-border">
        <CardBody>
          <div className="billing-count-cards">
            <CountCard
              text="99453"
              count={newPatientNumber}
              dataCy="ccm-billing-report-99453"
            />
            <CountCard
              text="99454"
              count={activeNumber}
              dataCy="ccm-billing-report-99454"
            />
            <CountCard
              text="99457"
              count={lowActivityCount}
              dataCy="ccm-billing-report-99457"
            />
            <CountCard
              text="99458 (1)"
              count={mediumActivityCount}
              dataCy="ccm-billing-report-99458-1"
            />
            <CountCard
              text="99458 (2)"
              count={highActivityCount}
              dataCy="ccm-billing-report-99458-2"
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default BillingCount;
