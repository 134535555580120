import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const Emr = ({ data }) => (
  <Table borderless border="0" cellSpacing="0" cellPadding="0">
    <thead>
      <tr>
        <th colSpan="2">
          <div className="pagination-container">
            <h5>EMR Details</h5>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td />
        <td>
          {data.emrName && (
            <>
              <span className="subheader">EMR Name: </span>
              <span data-cy="emr-name">{data.emrName}</span>
            </>
          )}
          <>
            <span className="subheader">Billing: </span>
            <span data-cy="billing">{data.emrBilling ? 'Yes' : 'No'}</span>
          </>
          {data.emrBillingCycle && (
            <>
              <span className="subheader">Billing Cycle: </span>
              <span data-cy="user-billing-cycle">{data.emrBillingCycle}</span>
            </>
          )}
          {data.emrId1 && (
            <>
              <span className="subheader">EMR ID 1: </span>
              <span data-cy="emr-id-1">{data.emrId1}</span>
            </>
          )}
          {data.emrId2 && (
            <>
              <span className="subheader">EMR ID 2: </span>
              <span data-cy="emr-id-2">{data.emrId2}</span>
            </>
          )}
          {data.supervisingProviderId && (
            <>
              <span className="subheader">Supervising Provider: </span>
              <span data-cy="supervising-provider">
                {data.supervisingProviderId}
              </span>
            </>
          )}
          {data.renderingProviderId && (
            <>
              <span className="subheader">Rendering Provider: </span>
              <span data-cy="rendering-provider">
                {data.renderingProviderId}
              </span>
            </>
          )}
          <>
            <span className="subheader">Readings: </span>
            <span data-cy="readings">
              {data.emrReadingsFlag ? 'Yes' : 'No'}
            </span>
          </>
          {data.emrReports && (
            <>
              <span className="subheader">Report: </span>
              <span data-cy="report">{data.emrReports}</span>
            </>
          )}
        </td>
      </tr>
    </tbody>
  </Table>
);

Emr.propTypes = {
  data: PropTypes.shape(),
};

export default Emr;
