import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseForm from 'components/atoms/base-form';
import BaseFormGroup from 'components/atoms/base-form/group';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseGrid from 'components/atoms/base-grid';
import BaseInput from 'components/atoms/base-input';
import BooleanSelectBox from 'components/molecules/boolean-select-box';
import ClinicReferralTypeSelectBox from 'components/molecules/clinic-referral-types-select-box';
import ClinicRevenueChannelSelectBox from 'components/molecules/clinic-revenue-channels-select-box';
import ClinicServiceDeliveryModelSelectBox from 'components/molecules/clinic-service-delivery-models-select-box';
import ProviderServiceTypeSelectBox from 'components/molecules/provider-service-type-select-box';
import ServiceDeliveryThresholdSelectBox from 'components/molecules/service-delivery-threshold-select-box';
import useUser from 'utils/useUser';
import { mergeFieldsValue } from 'infrastructure/functions';
import BaseDatepicker from 'components/atoms/base-datepicker';

import s from './styles.module.scss';
import { clinicDetailValidationSchema } from './validation-schema';

import type { Clinic } from 'infrastructure/classes/clinic';
import type { IClinicDetail } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const prepareValue = (data?: Clinic): IClinicDetail => {
  let defaultValue: IClinicDetail = {
    npiNumber: null,
    stateIdNumber: null,
    taxIdNumber: '',
    medicalIdTpiNumber: null,
    ots: null,
    otsStartDate: null,
    referralTypes: null,
    providerServiceType: [],
    serviceDeliveryThresholds: null,
    serviceDeliveryModel: null,
    revenueChannels: null,
    autoGeneratedClaimDrop: null,
    finId: null,
    taxonomyNumber: null,
    medicareNumber: null,
    officeHours: null,
  };
  if (data) {
    defaultValue = mergeFieldsValue(defaultValue, data);
  }

  return defaultValue;
};

const FormDisplayName = 'ClinicDetailForm';

interface IProps extends IIndependentFormProps {
  data?: Clinic;
}

const ClinicDetailForm = forwardRef<IIndependentFormRef<IClinicDetail>, IProps>(
  (props, ref) => {
    const { data } = props;

    const { t } = useTranslation();

    const user = useUser();

    const formik = useFormik<IClinicDetail>({
      initialValues: prepareValue(data),
      onSubmit: () => {},
      validateOnChange: false,
      validationSchema: clinicDetailValidationSchema(),
    });

    const submitForm = async () => {
      await formik.submitForm();
      const isValid = await formik
        .validateForm()
        .then((res) => !Object.keys(res).length);

      return {
        values: formik.values,
        formName: FormDisplayName,
        isValid,
      };
    };

    useImperativeHandle(ref, () => ({
      submit: submitForm,
      reset: formik.resetForm,
    }));

    useEffect(() => {
      if (data) formik.resetForm({ values: prepareValue(data) });
    }, [data]);

    return (
      <BaseForm formik={formik}>
        <BaseFormGroup label={t('labels.details')} contentClassName={s.content}>
          <BaseGrid columns={3} columnGap={0}>
            <div className={s.col}>
              <BaseFormItem
                name="npiNumber"
                label={t('labels.npi')}
                isBlackLabel
              >
                <BaseInput />
              </BaseFormItem>
              <BaseFormItem
                name="taxIdNumber"
                label={t('labels.taxIdNumber')}
                isBlackLabel
              >
                <BaseInput />
              </BaseFormItem>
              <BaseFormItem
                name="medicalIdTpiNumber"
                label={t('labels.medicalIdTpiNumber')}
                isBlackLabel
              >
                <BaseInput />
              </BaseFormItem>
              <BaseGrid columns={2}>
                <BaseFormItem name="ots" label={t('labels.ots')} isBlackLabel>
                  <BooleanSelectBox />
                </BaseFormItem>
                <BaseFormItem
                  name="otsStartDate"
                  label={t('labels.otsStartDate')}
                  isBlackLabel
                >
                  <BaseDatepicker
                    onChange={(_, date) =>
                      formik.setFieldValue('otsStartDate', date)
                    }
                  />
                </BaseFormItem>
              </BaseGrid>
              <BaseFormItem
                name="referralTypes"
                label={t('labels.referralTypes')}
                isBlackLabel
              >
                <ClinicReferralTypeSelectBox mode="multiple" allowClear />
              </BaseFormItem>
            </div>
            <div className={s.col}>
              <BaseFormItem
                name="providerServiceType"
                label={t('labels.providerServiceType')}
                required
                isBlackLabel
              >
                <ProviderServiceTypeSelectBox mode="multiple" allowClear />
              </BaseFormItem>
              {user.isSlaReadingsAllowed && (
                <BaseFormItem
                  name="serviceDeliveryThresholds"
                  label={t('labels.serviceDeliveryThresholds')}
                  isBlackLabel
                >
                  <ServiceDeliveryThresholdSelectBox withNone />
                </BaseFormItem>
              )}
              <BaseFormItem
                name="serviceDeliveryModel"
                label={t('labels.serviceDeliveryModel')}
                required
                isBlackLabel
              >
                <ClinicServiceDeliveryModelSelectBox />
              </BaseFormItem>
              <BaseFormItem
                name="revenueChannels"
                label={t('labels.revenueChannels')}
                isBlackLabel
              >
                <ClinicRevenueChannelSelectBox mode="multiple" allowClear />
              </BaseFormItem>
              <BaseFormItem
                name="autoGeneratedClaimDrop"
                label={t('labels.autoGeneratedClaimDrop')}
                isBlackLabel
              >
                <BooleanSelectBox />
              </BaseFormItem>
            </div>
            <div className={s.col}>
              <BaseFormItem name="finId" label={t('labels.finId')} isBlackLabel>
                <BaseInput />
              </BaseFormItem>
              <BaseFormItem
                name="stateIdNumber"
                label={t('labels.stateIdNumber')}
                isBlackLabel
              >
                <BaseInput />
              </BaseFormItem>
              <BaseFormItem
                name="taxonomyNumber"
                label={t('labels.taxonomyNumber')}
                isBlackLabel
              >
                <BaseInput />
              </BaseFormItem>
              <BaseFormItem
                name="medicareNumber"
                label={t('labels.medicareNumber')}
                isBlackLabel
              >
                <BaseInput />
              </BaseFormItem>
              <BaseFormItem
                name="officeHours"
                label={t('labels.officeHours')}
                isBlackLabel
              >
                <BaseInput />
              </BaseFormItem>
            </div>
          </BaseGrid>
        </BaseFormGroup>
      </BaseForm>
    );
  },
);

ClinicDetailForm.displayName = FormDisplayName;

export default ClinicDetailForm;
