import React from 'react';

const MedicationIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.23248 12.4583L3.2325 12.4583L3.23509 12.4557L12.5202 3.17062C12.5202 3.17062 12.5202 3.17061 12.5202 3.1706C13.6222 2.06888 15.1166 1.44995 16.6749 1.44995C18.2331 1.44995 19.7276 2.06887 20.8295 3.17058C21.9313 4.27256 22.5502 5.76702 22.5502 7.32528C22.5502 8.88353 21.9313 10.378 20.8295 11.48C20.8295 11.48 20.8295 11.48 20.8295 11.48L11.5444 20.7651L11.5444 20.765L11.5419 20.7677C10.9992 21.3264 10.3509 21.7717 9.63457 22.0777C8.91826 22.3836 8.14828 22.5441 7.36939 22.5498C6.59051 22.5555 5.81826 22.4063 5.09756 22.1108C4.37686 21.8154 3.72208 21.3796 3.17131 20.8288C2.62054 20.2781 2.18476 19.6233 1.88932 18.9026C1.59387 18.1819 1.44466 17.4096 1.45035 16.6308C1.45604 15.8519 1.61653 15.0819 1.92248 14.3656C2.22842 13.6493 2.67372 13.0009 3.23248 12.4583ZM10.1939 19.4364L10.1939 19.4365L10.1989 19.4315L14.0039 15.6265L14.1807 15.4497L14.0039 15.273L8.72718 9.99625L8.5504 9.81948L8.37362 9.99625L4.56865 13.8012L4.56799 13.8019C3.82517 14.5503 3.40832 15.5619 3.40832 16.6164C3.40832 17.6708 3.82517 18.6825 4.56799 19.4308L4.5679 19.4309L4.57369 19.4364C5.33321 20.1543 6.33868 20.5543 7.38378 20.5543C8.42888 20.5543 9.43435 20.1543 10.1939 19.4364ZM19.496 10.1344L19.4961 10.1345L19.5017 10.1286C20.2085 9.37403 20.5943 8.3742 20.5774 7.34042C20.5606 6.30664 20.1424 5.31992 19.4113 4.58884C18.6802 3.85775 17.6935 3.43958 16.6597 3.42272C15.6259 3.40586 14.6261 3.79163 13.8716 4.49849L13.8715 4.4984L13.8657 4.50416L10.0607 8.30914L9.88396 8.48592L10.0607 8.66269L15.3375 13.9394L15.5142 14.1162L15.691 13.9394L19.496 10.1344Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);

export default MedicationIcon;
