const SendIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8301 11.6915L2.22462 1.86183C2.14494 1.82199 2.05353 1.81261 1.96681 1.83371C1.76759 1.88293 1.64337 2.08449 1.69259 2.28605L3.71291 10.5407C3.74337 10.665 3.83478 10.7657 3.95666 10.8056L7.41837 11.9939L3.959 13.1821C3.83712 13.2243 3.74572 13.3228 3.71759 13.447L1.69259 21.7134C1.6715 21.8001 1.68087 21.8915 1.72072 21.9689C1.81212 22.154 2.03712 22.229 2.22462 22.1376L21.8301 12.3642C21.9028 12.329 21.9613 12.2681 21.9988 12.1978C22.0903 12.0103 22.0153 11.7853 21.8301 11.6915ZM4.00353 19.365L5.18244 14.5462L12.1012 12.172C12.1551 12.1532 12.1996 12.1111 12.2184 12.0548C12.2512 11.9564 12.1996 11.8509 12.1012 11.8157L5.18244 9.44386L4.00822 4.64386L18.727 12.0243L4.00353 19.365Z"
      fill="currentColor"
    />
  </svg>
);

export default SendIcon;
