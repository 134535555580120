import { Col } from 'reactstrap';

import type { FC } from 'react';

type Props = {
  name: string;
  value: string;
};

const Metric: FC<Props> = ({ name, value }) => (
  <Col>
    <div className="border text-center metric">
      <h6 data-cy={`${name}-name`}>{name}</h6>
      <p data-cy={`${name}-value`}>{value}</p>
    </div>
  </Col>
);

export default Metric;
