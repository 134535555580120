import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import httpCodes from 'constants/httpCodes';
import { useTranslation } from 'react-i18next';
import QueryParams from 'utils/queryParams';
import { useQueryClient } from '@tanstack/react-query';
import { getAgencyDoctorsApi } from 'infrastructure/hooks/select/use-agency-doctor-select';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import useUser from 'utils/useUser';
import { isAgencyDoctor } from 'utils/userTypeHelper';
import { useState, type FC } from 'react';

import BaseAutocomplete from './base';

import type { QueryClient } from '@tanstack/react-query';
import type { Roles } from 'infrastructure/enums';
import type { Option } from './base';
import type {
  IAgencyDoctorSelect,
  IAgencyDoctorSelectPaginatedData,
} from 'infrastructure/interfaces';

const generateLabel = (item: IAgencyDoctorSelect) => item.label;

const fetchAgencyDoctorsList = async ({
  search,
  items,
  page,
  roles,
  queryClient,
}: {
  queryClient: QueryClient;
  search: string;
  page: number;
  items: number;
  roles?: Array<Roles>;
}): Promise<{ count: number; data: Option[] } | undefined> => {
  const emptyResult = {
    data: [],
    count: 0,
  };
  if (!search) return emptyResult;
  const filters = {
    page,
    items,
    search,
    roles,
  };
  const queryKey = [getAgencyDoctorsApi, filters] as const;
  const url = QueryParams.stringifyUrl(getAgencyDoctorsApi, filters);
  const { data, status, error } = await queryClient.fetchQuery(
    queryKey,
    () => dataService.getList<IAgencyDoctorSelectPaginatedData>(url),
    { staleTime: STALE_TIME },
  );

  if (status === httpCodes.notFount) {
    return emptyResult;
  }

  if (error) {
    showResult(error);
  }
  if (!data || error) return emptyResult;

  const values: Option[] =
    data.items?.map<Option>((item) => ({
      label: generateLabel(item),
      value: item.guid,
    })) || [];

  return {
    data: values,
    count: data.count || 0,
  };
};

interface Props {
  value?: Option[];
  onChange: (value: Option[]) => void;
  label?: string;
  roles?: Array<Roles>;
}

const AgencyDoctorsAutocomplete: FC<Props> = ({
  onChange,
  value,
  roles,
  label,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { guid: userGuid, userType } = useUser();

  const defaultOptions = [
    {
      id: 'all',
      value: 'all',
      label: 'All',
    },
    {
      id: 'empty',
      value: 'empty',
      label: 'Empty',
    },
  ];

  if (isAgencyDoctor(userType)) {
    defaultOptions.splice(1, 0, {
      id: userGuid,
      value: userGuid,
      label: 'Me',
    });
  }

  return (
    <BaseAutocomplete
      label={label || t('labels.agencyDoctor')}
      placeholder={t('labels.all')}
      mode="multiple"
      fetchOptions={(args) =>
        fetchAgencyDoctorsList({ ...args, queryClient, roles })
      }
      value={value}
      onChange={(newValues) => {
        let items = Array.isArray(newValues) ? newValues : [newValues];
        const isAllSelected = items.some((item) => item.value === 'all');
        if (isAllSelected) {
          setOpen(false);
          onChange([]);
          return;
        }
        if (items.at(0)?.value === 'empty' && items.length > 1) {
          items = items.filter((item) => item.value !== 'empty');
        }
        const emptyValue = items.find((item) => item.value === 'empty');
        if (emptyValue) {
          onChange([emptyValue]);
          return;
        }
        onChange(items);
      }}
      defaultOptions={defaultOptions}
      setOpenCallback={setOpen}
      open={open}
    />
  );
};

export default AgencyDoctorsAutocomplete;
