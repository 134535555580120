import { startOfMonth, endOfMonth } from 'date-fns';
import { useMemo } from 'react';

const useTurkDashboardDate = () => {
  const dates = useMemo(() => {
    const now = new Date();
    return {
      startTime: startOfMonth(now).getTime().toString(),
      endTime: endOfMonth(now).getTime().toString(),
      timezoneOffset: now.getTimezoneOffset(),
    };
  }, []);

  return dates;
};

export default useTurkDashboardDate;
