const PlusIcon = (props) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5008 2V19"
      stroke="#FB9318"
      strokeWidth="2.9"
      strokeLinecap="round"
    />
    <path
      d="M2 10.4982L19 10.4982"
      stroke="#FB9318"
      strokeWidth="2.9"
      strokeLinecap="round"
    />
  </svg>
);

export default PlusIcon;
