import React from 'react';
import classNames from 'classnames';
import EllipseIcon from 'components/atoms/icons/ellipse';
import HospitalIcon from 'components/atoms/icons/hospital';
import BaseCheckbox from 'components/atoms/base-checkbox';
import BaseTag from 'components/atoms/base-tag';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

interface IProps {
  name: string;
  isParent: boolean;
  isLast: boolean;
  checked: boolean;
  isControl?: boolean;
  withoutParent?: boolean;
  showIcon?: boolean;
  showCheckBox?: boolean;
  onChange: (checked: boolean) => void;
}

const ClinicFilterFormListItem: React.FC<IProps> = ({
  name,
  isParent,
  isLast,
  checked,
  isControl = false,
  showIcon = true,
  showCheckBox = true,
  withoutParent = false,
  onChange,
}) => {
  const { t } = useTranslation();

  const icon = isParent ? <HospitalIcon size="small" /> : <EllipseIcon />;

  const itemClassNames = classNames(s.item, {
    [s.child]: !isParent && !withoutParent,
  });

  const onCheck = () => {
    if (showCheckBox) onChange(!checked);
  };

  return (
    <li className={itemClassNames} onClick={onCheck} data-cy="clinicList-item">
      <div className={s.title} data-cy="clinicList-title">
        {showIcon && <i>{icon}</i>}
        {name}
        {isParent && !isLast && <BaseTag label={t('labels.parent')} />}
      </div>
      {showCheckBox && !isControl && (
        <div className={s.checkbox} data-cy="clinicList-checkbox">
          <BaseCheckbox value={checked} />
        </div>
      )}
    </li>
  );
};

export default ClinicFilterFormListItem;
