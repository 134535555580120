import forms from 'constants/forms';

export const deviceStatusConverter = (isActive) =>
  isActive ? 'Active' : 'Not active';

export const formatDevices = (readingTypes) => {
  const types = [];
  for (let i = 0; i < readingTypes.length; i++) {
    if (readingTypes[i].value === 'SleepingMat') {
      forms.sleepingMatTypes.forEach((sleepMatType) => {
        types.push(sleepMatType);
      });
    } else {
      types.push(readingTypes[i].value);
    }
  }
  return types;
};

export const reformatReadingTypes = (types) => {
  if (
    types.includes('SleepingMatActivity') &&
    types.includes('SleepingMatDuration')
  ) {
    return [
      ...types.filter(
        (e) => e !== 'SleepingMatActivity' && e !== 'SleepingMatDuration',
      ),
      'SleepingMat',
    ];
  }
  return types;
};
