import ArrowUpIcon from 'components/atoms/icons/arrowUpIcon';
import ArrowDownIcon from 'components/atoms/icons/arrowDownIcon';
import { Button } from 'antd';

import s from './styles.module.scss';

import type { FC } from 'react';

interface Props {
  expanded: boolean;
  onClick: () => void;
}

const ExpandButton: FC<Props> = ({ expanded, onClick }) => {
  return (
    <Button onClick={onClick} className={s['expand-button']}>
      {expanded ? (
        <ArrowUpIcon fill="currentColor" height="14" width="10" />
      ) : (
        <ArrowDownIcon fill="currentColor" height="14" width="10" />
      )}
    </Button>
  );
};

export default ExpandButton;
