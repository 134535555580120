import dataService from 'services/dataService';
import { API_HOSPITAL_PREVENTION } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';
import i18next from 'i18next';

const preferredContactMethodError =
  'preferredContactMethod is a required field';
interface DataProps {
  agentEmail: string;
  agentName: string;
  asEvidencedBy: string[];
  dateOfInstance: string;
  didYouHaveBiDirectionalConversationWithPatient: string;
  doctorGuid: string;
  doctorName: string;
  patientGuid: string;
  patientName: string;
  patientVitalsDateAndTime: string;
  preferredContactMethod: string;
  riskForBloodPressure: string[];
  riskForRespiratory: string[];
}

export const hospitalPreventionFn = async (postData: DataProps) => {
  const { error, data } = await dataService.createOne(
    API_HOSPITAL_PREVENTION,
    postData,
  );

  if (error) {
    if (error?.includes(preferredContactMethodError))
      showResult(i18next.t('errors.preferredContactMethod'));
    else showResult(error);
    throw new Error(error);
  }

  return data;
};
