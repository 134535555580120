import { useWebsocketRoom } from 'infrastructure/hooks/websockets/use-room';
import RpmActivity from 'components/organisms/rpm-dashboard-activity';
import Filter from 'components/organisms/rpm-dashboard-filter';
import RiskConditionFilter from 'components/organisms/rpm-dashboard-risk-condition-filter';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import CustomLocationPrompt from 'components/molecules/custom-location-prompt';
import BaseButton from 'components/atoms/baseButton';
import DesktopIcon from 'components/atoms/icons/desktop.svg?react';

import s from './styles.module.scss';

import type { FC } from 'react';

interface IOverviewDashboardPageNewProps {
  setMode: (mode: string) => void;
}
const OverviewDashboardPageNew: FC<IOverviewDashboardPageNewProps> = ({
  setMode,
}) => {
  const actions = useAppSelector((state) => state.activityActions.actions);
  const {
    activityActions: { resetActions },
  } = useAppDispatch();
  const { t } = useTranslation();

  const hasUnsavedMessage = Object.values(actions).some((item) =>
    Boolean(item?.message?.length),
  );

  useWebsocketRoom('rpmDashboard');

  return (
    <>
      <CustomLocationPrompt when={hasUnsavedMessage} callback={resetActions} />
      <div className={s.wrapper}>
        <div className={s['title-wrapper']}>
          <h1 className={s.title}>{t('labels.realTimePatientReadings')}</h1>
          <BaseButton
            label="To Old Design"
            type="secondary"
            onClick={() => setMode('old')}
            icon={<DesktopIcon width={20} height={20} />}
            rtl
            className={s['mode-btn']}
          />
        </div>
        <RiskConditionFilter />
        <Filter />
        <RpmActivity />
      </div>
    </>
  );
};

export default OverviewDashboardPageNew;
