import { phoneRegex } from 'constants/regex';
import * as Yup from 'yup';

const AgencySchema = () =>
  Yup.object().shape({
    guid: Yup.string(),
    legalname: Yup.string().required('Legal name is required field'),
    email: Yup.string().email('Email is not correct').nullable(),
    phone: Yup.string()
      .matches(phoneRegex, 'Phone number is not correct')
      .nullable(),
    fax: Yup.string().nullable(),
    addressLine1: Yup.string().nullable(),
    addressLine2: Yup.string().nullable(),
    city: Yup.string().when('zip', {
      is: (zip) => !!zip,
      then: (schema) => schema.required().nullable(),
      otherwise: (schema) => schema.nullable(),
    }),
    state: Yup.string().when(['zip', 'city'], {
      is: (zip, city) => !!zip || !!city,
      then: Yup.string().required().nullable(),
      otherwise: Yup.string().nullable(),
    }),
    zip: Yup.string().nullable(),
    npiNumber: Yup.string().nullable(),
    stateIdNumber: Yup.string().nullable(),
    taxIdNumber: Yup.string().nullable(),
    taxonomyNumber: Yup.string().nullable(),
    medicalIdTpiNumber: Yup.string().nullable(),
    medicareNumber: Yup.string().nullable(),
    agencyId: Yup.string().nullable(),
  });

export default AgencySchema;
