import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import ModalPopup from 'components/organisms/modal';
import TextArea from 'components/atoms/textarea';
import Button from 'components/atoms/button';
import { showResult } from 'infrastructure/hooks/utils';

const SmsDoctorModal = ({ isOpen, toggle, patientGuid, loading, submit }) => {
  const handleSubmit = async (values) => {
    const postData = {
      patientGuid,
      type: 'sms',
      message: values.comment,
    };
    const { error } = await submit(postData);
    showResult(error, null, toggle);
  };
  return (
    <ModalPopup
      isOpen={isOpen}
      toggle={toggle}
      title="SMS to primary doctor"
      className="popup general-comment-popup"
    >
      <Formik
        initialValues={{
          comment: '',
        }}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <Field name="comment" type="comment" as={TextArea} rows="8" />
            <div className="text-center">
              <Button
                type="submit"
                text="Send"
                data-cy="send-button"
                disabled={loading || props.values.comment === ''}
              />
            </div>
          </Form>
        )}
      </Formik>
    </ModalPopup>
  );
};
SmsDoctorModal.defaultProps = {
  isOpen: false,
};

SmsDoctorModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  patientGuid: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  values: PropTypes.shape(),
};

export default SmsDoctorModal;
