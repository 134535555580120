import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import forms from 'constants/forms';
import Input from 'components/atoms/input';

const DxCodes = ({ errors }) => {
  const dxcodes = [...forms.adminPanelsUser.userEdit.dxcodes];
  return (
    <div className="admin-patient-form-block">
      <span>Diagnosis Codes</span>
      <Row>
        {dxcodes.map((field) => (
          <Col lg="6" md="6" sm="6" xs="12" key={field.id}>
            <Input {...field} />
            {errors[field.id] ? (
              <div className="input-error">{errors[field.id]}</div>
            ) : null}
          </Col>
        ))}
      </Row>
    </div>
  );
};

DxCodes.propTypes = {
  errors: PropTypes.shape(),
};

export default DxCodes;
