import i18next from 'i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import { splitByUppercase } from 'infrastructure/functions/split-by-uppercase';

import type { IChangeLog } from 'infrastructure/interfaces';

const dateFields = [
  'endDate',
  'startDate',
  'scheduledDate',
  'birthDate',
  'statusUpdatedAt',
];

const specialFields = ['ccmStatus', 'ccmClaimStatus', 'pcmStatus'];

export class ChangeLog {
  field: string;
  from: string;
  to: string;

  constructor(data: IChangeLog) {
    const { field, from, to } = data;
    this.field = specialFields.includes(field)
      ? splitByUppercase(field)
          .map((word, index) => (index === 0 ? word.toUpperCase() : word))
          .join(' ')
      : splitByUppercase(field).join(' ');
    this.from = from;
    this.to = to;
    if (field === 'claimStatus' || field === 'ccmClaimStatus') {
      this.from = i18next.exists(`claimStatuses.${from}`)
        ? i18next.t(`claimStatuses.${from}` as any)
        : from;
      this.to = i18next.exists(`claimStatuses.${to}`)
        ? i18next.t(`claimStatuses.${to}` as any)
        : to;
    }
    if (field === 'martialStatus') {
      this.from = i18next.exists(`maritalStatuses.${from}`)
        ? i18next.t(`maritalStatuses.${from}` as any)
        : from;
      this.to = i18next.exists(`maritalStatuses.${to}`)
        ? i18next.t(`maritalStatuses.${to}` as any)
        : to;
    }
    if (field === 'gender') {
      this.from = i18next.exists(`genders.${from}`)
        ? i18next.t(`genders.${from}` as any)
        : from;
      this.to = i18next.exists(`genders.${to}`)
        ? i18next.t(`genders.${to}` as any)
        : to;
    }
    if (field === 'preferredContactMethod') {
      this.from = i18next.exists(`contactMethods.${from}`)
        ? i18next.t(`contactMethods.${from}` as any)
        : from;
      this.to = i18next.exists(`contactMethods.${to}`)
        ? i18next.t(`contactMethods.${to}` as any)
        : to;
    }
    if (field === 'appointmentMethod') {
      this.from = i18next.exists(`appointmentMethod.${from}`)
        ? i18next.t(`appointmentMethod.${from}` as any)
        : from;
      this.to = i18next.exists(`appointmentMethod.${to}`)
        ? i18next.t(`appointmentMethod.${to}` as any)
        : to;
    }
    if (field === 'weekMealStatus') {
      this.from = i18next.exists(`weekMealStatuses.${from}`)
        ? i18next.t(`weekMealStatuses.${from}` as any)
        : from;
      this.to = i18next.exists(`weekMealStatuses.${to}`)
        ? i18next.t(`weekMealStatuses.${to}` as any)
        : to;
    }
    if (field === 'race') {
      this.from = i18next.exists(`races.${from}`)
        ? i18next.t(`races.${from}` as any)
        : from;
      this.to = i18next.exists(`races.${to}`)
        ? i18next.t(`races.${to}` as any)
        : to;
    }
    if (field === 'workingStatus') {
      this.from = i18next.exists(`workingStatuses.${from}`)
        ? i18next.t(`workingStatuses.${from}` as any)
        : from;
      this.to = i18next.exists(`workingStatuses.${to}`)
        ? i18next.t(`workingStatuses.${to}` as any)
        : to;
    }
    if (
      field === 'emergencyContactNumberType' ||
      field === 'secondaryNumberType' ||
      field === 'numberType'
    ) {
      this.from = i18next.exists(`numberTypes.${from}`)
        ? i18next.t(`numberTypes.${from}` as any)
        : from;
      this.to = i18next.exists(`numberTypes.${to}`)
        ? i18next.t(`numberTypes.${to}` as any)
        : to;
    }
    if (field === 'ccmStatus') {
      this.from = i18next.exists(`ccmStatuses.${from}`)
        ? i18next.t(`ccmStatuses.${from}` as any)
        : from;
      this.to = i18next.exists(`ccmStatuses.${to}`)
        ? i18next.t(`ccmStatuses.${to}` as any)
        : to;
    }
    if (field === 'status') {
      this.from = i18next.exists(`rpmStatuses.${from}`)
        ? i18next.t(`rpmStatuses.${from}` as any)
        : from;
      this.to = i18next.exists(`rpmStatuses.${to}`)
        ? i18next.t(`rpmStatuses.${to}` as any)
        : to;
    }
    if (field === 'readingTypes') {
      const parsedFrom: Array<string> | undefined = JSON.parse(from);
      const parsedTo: Array<string> | undefined = JSON.parse(to);

      this.from =
        parsedFrom && parsedFrom.length
          ? parsedFrom
              .map((el) => {
                return i18next.exists(`readingTypes.${el}`)
                  ? i18next.t(`readingTypes.${el}` as any)
                  : el;
              })
              .join(', ')
          : from;
      this.to =
        parsedTo && parsedTo.length
          ? parsedTo
              .map((el) => {
                return i18next.exists(`readingTypes.${el}`)
                  ? i18next.t(`readingTypes.${el}` as any)
                  : el;
              })
              .join(', ')
          : to;
    }
    if (dateFields.includes(field)) {
      this.from = DateFormatter({
        date: from,
        format: DateFormats['MM-dd-yyyy'],
      });
      this.to = DateFormatter({
        date: to,
        format: DateFormats['MM-dd-yyyy'],
      });
    }
  }
}
