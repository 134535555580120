import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import debounce from 'lodash/debounce';
import dataService from 'services/dataService';
import List from 'components/atoms/list';
import classNames from 'classnames';
import useOutsideClick from 'utils/useOutsideClick';
import ResetSearchIcon from 'components/atoms/icons/searchResetIcon';
import { apiGetDoctorsByFilter } from 'services/dataService/resources';

const loadingOptions = [{ id: 'loading', label: 'Loading...' }];
let timer;

const DoctorAutocomplete = forwardRef(
  (
    {
      filter,
      handleClick,
      showLabel,
      patient,
      clinicGuid,
      roleName,
      status,
      getInactiveClinicData,
    },
    ref,
  ) => {
    const [query, setQuery] = useState('');
    const inputRef = useRef();
    const [show, setShow] = useState(false);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useOutsideClick(inputRef, () => {
      setShow(false);
    });

    const generateFilterLabel = (item) => `${item.firstName} ${item.lastName}`;

    const debouncedSearch = debounce(async (string) => {
      const { data: doctors } = await dataService.getList(
        apiGetDoctorsByFilter({
          [filter.id]: string,
          clinicGuid,
          roleName,
          status,
          getInactiveClinicData,
        }),
      );

      setIsLoading(false);
      if (doctors) {
        setOptions(
          doctors.map((item) => ({
            id: item.guid,
            value: item.guid,
            label: generateFilterLabel(item),
          })),
        );
      }
    }, 800);

    const handleInputChange = (string) => {
      if (string.target) {
        setQuery(string.target.value);
      }
      setIsLoading(true);
      clearTimeout(timer);
      timer = setTimeout(() => {
        debouncedSearch(string.target ? string.target.value : query);
      }, 500);
    };

    const handleOptionClick = (option) => {
      setQuery(option.label);
      handleInputChange(option.label);
      setShow(false);
      return handleClick(option);
    };

    const handleFocus = () => {
      if (
        filter.id === 'fullName' ||
        filter.id === 'name' ||
        filter.id === 'email'
      ) {
        setShow(true);
      }
    };

    const autoCompleteClass = () => {
      let className = 'card-border';
      if (showLabel) {
        className = 'card-border card-shadow border-0';
      }
      return className;
    };

    const resetSearch = () => {
      setQuery('');
      handleInputChange('');
      debouncedSearch('');
      handleClick({ value: '' });
    };

    const resetQuery = () => {
      setQuery('');
    };

    useImperativeHandle(ref, () => ({
      resetQuery,
    }));

    return (
      <div className="patient-filter-autocomplete" ref={inputRef}>
        <div className="dropdown" role="button" aria-hidden>
          <Card className={autoCompleteClass()}>
            <CardBody
              className={classNames({
                'card-body-sort': !patient,
              })}
            >
              {showLabel && <p>{filter.label}</p>}
              <div className="dropdown-body">
                <input
                  data-cy="practice-filter-input"
                  className={query !== '' ? 'arrow' : ''}
                  placeholder={filter.placeholder}
                  type="text"
                  onChange={handleInputChange}
                  value={query}
                  onFocus={handleFocus}
                />
                {query !== '' && <ResetSearchIcon onClick={resetSearch} />}
              </div>
            </CardBody>
          </Card>
        </div>
        {isLoading && show && (
          <div className="patient-filter-dropdown-body">
            <List onChange={() => {}} options={loadingOptions} />
          </div>
        )}
        {!isLoading && options && options.length > 0 && show && (
          <div className="patient-filter-dropdown-body">
            <List
              onChange={handleOptionClick}
              options={options}
              dataCy="practice-filter-result"
            />
          </div>
        )}
      </div>
    );
  },
);

DoctorAutocomplete.propTypes = {
  clinicGuid: PropTypes.string,
  roleName: PropTypes.string,
  filter: PropTypes.shape().isRequired,
  handleClick: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  patient: PropTypes.bool,
  status: PropTypes.string,
  getInactiveClinicData: PropTypes.bool,
};

export default DoctorAutocomplete;
