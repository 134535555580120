import dataService from 'services/dataService';
import { apiPatientHoldingStatusLogs } from 'services/dataService/resources';

const ITEMS_PER_PAGE = 10;

const patientHoldingStatusLogs = {
  state: {
    page: 1,
    pageStep: ITEMS_PER_PAGE,
    reversed: false,
    totalCount: 1,
    totalPages: 0,
    data: [],
  },
  reducers: {
    setPatientHoldingStatusLogs: (state, payload) => ({
      ...state,
      data: [...payload],
    }),
    setPage: (state, payload) => ({
      ...state,
      page: payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    toggleReversed: (state) => ({
      ...state,
      reversed: !state.reversed,
    }),
  },
  effects: (dispatch) => ({
    async getPatientHoldingStatusLogs(patientGuid, state) {
      const { page, reversed } = state.patientHoldingStatusLogs;
      const { data } = await dataService.getList(
        apiPatientHoldingStatusLogs(patientGuid, page, !reversed),
      );
      dispatch.patientHoldingStatusLogs.setPatientHoldingStatusLogs(
        data?.data || [],
      );
      const totalCount = data?.count || 0;
      const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);

      dispatch.patientHoldingStatusLogs.setTotalCountAndTotalPages({
        totalCount,
        totalPages,
      });
    },
    async setPagePatientHoldingStatusLogs({ pageOffset }, state) {
      const { page, totalPages } = state.patientHoldingStatusLogs;
      const newPage = page + pageOffset;
      dispatch.patientHoldingStatusLogs.setPage(
        newPage <= totalPages ? newPage : totalPages,
      );
    },
    async toggleOrder() {
      dispatch.patientHoldingStatusLogs.toggleReversed();
      dispatch.patientHoldingStatusLogs.setPage(1);
    },
  }),
};

export default patientHoldingStatusLogs;
