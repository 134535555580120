import React from 'react';
import { useTranslation } from 'react-i18next';
import ChatLeftTextIcon from 'components/atoms/icons/chat-left-text';

import s from './styles.module.scss';

interface IGeneralCommentsEmptyBlockProps {
  withFilters?: boolean;
  height?: number;
}

const GeneralCommentsEmptyBlock: React.FC<IGeneralCommentsEmptyBlockProps> = (
  props,
) => {
  const { withFilters, height = 535 } = props;

  const { t } = useTranslation();

  const text = withFilters
    ? t('messages.emptyCommentsWithFilters')
    : t('messages.emptyComments');

  return (
    <div className={s.empty} style={{ height }}>
      <div className={s.icon}>
        <i>
          <ChatLeftTextIcon />
        </i>
        <p className={s.text}>{text}</p>
      </div>
    </div>
  );
};

export default GeneralCommentsEmptyBlock;
