import { memo, type FC } from 'react';
import ExpandableFilterPanel from 'components/molecules/expandable-filter-panel';

import { useDashboardFilter } from './hooks/use-dashboard-filter';
import Body from './components/body';

interface FilterProps {}

const Filter: FC<FilterProps> = () => {
  const { countOfChangedFilterField, onResetFilter, ...bodyProps } =
    useDashboardFilter();

  return (
    <>
      <ExpandableFilterPanel
        filtersCount={countOfChangedFilterField}
        onReset={onResetFilter}
      >
        <Body {...bodyProps} />
      </ExpandableFilterPanel>
    </>
  );
};
export default memo(Filter);
