import React from 'react';
import { Table } from 'reactstrap';
import classNames from 'classnames';
import { getObjValueByStringKey } from 'infrastructure/functions';
import Pagination from 'components/molecules/paginationTransition';

import BaseTableHeader from './header';
import s from './styles.module.scss';
import BaseTableLoader from './loader';
import { getColumnClassNames } from './utils';

import type { SortState } from 'infrastructure/types';
import type { IBaseTableColumnProps, IPaginationProps } from './types';

interface IBaseTableProps {
  columns: Array<IBaseTableColumnProps>;
  items: Array<any>;
  loading?: boolean;
  height?: string;
  className?: string;
  pagination?: IPaginationProps;
  onChangePage?: (page: number) => void;
  onSort?: (field: string, sort: SortState) => void;
}

const BaseTable: React.FC<IBaseTableProps> = (props) => {
  const {
    columns,
    items,
    className,
    pagination,
    onSort,
    onChangePage,
    loading = false,
    height = '100%',
  } = props;

  const tableClassNames = classNames(s['table-wrapper'], className);
  const paginationWrapperClassNames = classNames(s.paginationWrapper, {
    [s.disabled]: loading,
  });

  const changePage = (page: number) => {
    if (onChangePage) {
      onChangePage(page);
    }
  };

  const changeSort = (field: string, sort: SortState) => {
    if (onSort) {
      onSort(field, sort);
    }
  };

  return (
    <div>
      <div style={{ height }} className={tableClassNames}>
        <Table borderless cellSpacing="0" cellPadding="0">
          <BaseTableHeader columns={columns} onClick={changeSort} />
          <tbody data-cy="table-body">
            <BaseTableLoader loading={loading}>
              {items.map((item, index) => (
                <tr key={index}>
                  {columns.map((column) => (
                    <td
                      className={classNames(
                        s['col-wrapper'],
                        getColumnClassNames(column, item, index),
                        { [s.fixed]: column.fixed },
                      )}
                      key={String(column.key)}
                    >
                      <div
                        className={classNames(
                          s.column,
                          getColumnClassNames(column, item, index),
                        )}
                        style={{ width: column.width }}
                      >
                        {column.render
                          ? column.render(
                              getObjValueByStringKey(item, String(column.key)),
                              item,
                              index,
                            )
                          : getObjValueByStringKey(item, String(column.key))}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </BaseTableLoader>
          </tbody>
        </Table>
      </div>
      {pagination && (
        <div className={paginationWrapperClassNames}>
          <Pagination
            page={pagination.current}
            pageTotal={items.length}
            itemsPerPage={pagination.pageSize}
            total={pagination.total}
            handleFirstClick={() => changePage(1)}
            handleLastClick={() =>
              changePage(Math.ceil(pagination.total / pagination.pageSize))
            }
            handlePrevClick={() => changePage(pagination.current - 1)}
            handleNextClick={() => changePage(pagination.current + 1)}
            handleGoToPage={(pageNumber: number) => changePage(pageNumber)}
          />
        </div>
      )}
    </div>
  );
};

export default BaseTable;
