import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const IRS = ({ data }) => (
  <div className="admin-agent-block">
    <span>IRS</span>
    <Table>
      <tbody>
        {data.npiNumber && (
          <tr>
            <td>NPI</td>
            <td data-cy="agency-user-npi">{data.npiNumber}</td>
          </tr>
        )}
        <tr>
          <td>User Alert Email </td>
          <td data-cy="agency-user-alert-email">
            {data.alertEmail ? 'Yes' : 'No'}
          </td>
        </tr>
        <tr>
          <td>User Alert Sms</td>
          <td data-cy="agency-user-alert-sms">
            {data.alertSms ? 'Yes' : 'No'}
          </td>
        </tr>
      </tbody>
    </Table>
  </div>
);

IRS.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default IRS;
