import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showResult } from 'infrastructure/hooks/utils';
import dataService from 'services/dataService';
import { API_PATIENTS_READINGS_RESERVE } from 'services/dataService/resources';

import { changeAssign, setAllSlaReviewedTime } from './helpers/update-cache';

import type { QueryKey } from '@tanstack/react-query';
import type {
  IReserveResponse,
  IRpmPatientActivity,
} from 'infrastructure/interfaces/i-rpm-patient-activity';

interface Props {
  getActivityQueryKey: QueryKey;
}

const reservePatientFn = async (patientGuid: string) => {
  const { error, data } = await dataService.createOne<IReserveResponse>(
    API_PATIENTS_READINGS_RESERVE,
    {
      patientGuid,
    },
  );
  if (error) {
    showResult(error);
    return false;
  }

  return data;
};

export const useReservePatient = ({ getActivityQueryKey }: Props) => {
  const queryClient = useQueryClient();
  const { mutate, ...other } = useMutation({
    mutationFn: reservePatientFn,
    onSuccess(data, patientGuid) {
      queryClient.setQueryData<IRpmPatientActivity>(
        getActivityQueryKey,
        (oldData) => {
          if (data && oldData) {
            const newData = structuredClone(oldData);
            const { slaReviewedTime, action, body } = data.data;
            changeAssign({
              newData,
              data: { action, author: body, patientGuid },
            });
            if (data.data?.slaReviewedTime) {
              setAllSlaReviewedTime({
                newData,
                data: { patientGuid, slaReviewedTime },
              });
            }

            return newData;
          }

          return oldData;
        },
      );
    },
  });

  return {
    reservePatient: mutate,
    ...other,
  };
};
