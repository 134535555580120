import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'components/molecules/pagination';

const Footer = () => {
  const { page, data, itemsPerPage, totalCount } = useSelector(
    (state) => state.adminPanelAgencyDoctors,
  );
  const {
    adminPanelAgencyDoctors: { updateAgencyDoctorPage },
  } = useDispatch();
  const setPage = (pageOffset) => {
    updateAgencyDoctorPage({ pageOffset });
  };
  return (
    <div className="adminPanel__footer">
      <Pagination
        title=""
        page={page}
        pageTotal={data.length}
        itemsPerPage={itemsPerPage}
        total={totalCount}
        handleFirstClick={() => setPage(-(page - 1))}
        handleLastClick={() => setPage(Math.ceil(totalCount / itemsPerPage))}
        handlePrevClick={() => setPage(-1)}
        handleNextClick={() => setPage(+1)}
        loading={false}
      />
    </div>
  );
};

export default Footer;
