import React from 'react';
import BaseTableNew from 'components/atoms/base-table/new';
import { useEditorLog } from 'infrastructure/hooks/logs/use-editor-log';
import useUpdateEffect from 'infrastructure/hooks/utils/use-update-effect';

import { editorlogColumns } from './cols';

import type { DateRange } from 'infrastructure/types';

interface IPatientEditorLogProps {
  patientGuid: string;
  dateRange?: DateRange;
}

const PatientEditorLog: React.FC<IPatientEditorLogProps> = (props) => {
  const { patientGuid, dateRange } = props;

  const { loading, meta, items, setMeta } = useEditorLog({
    patientGuid,
    meta: {
      dateRange,
    },
  });

  const loadData = async (page: number) => {
    setMeta({
      ...meta,
      page,
      dateRange,
    });
  };

  useUpdateEffect(() => {
    setMeta({
      ...meta,
      page: 1,
      dateRange,
    });
  }, [dateRange]);

  return (
    <BaseTableNew
      dataCy="patient-editor-log-table"
      rowKey="key"
      columns={editorlogColumns()}
      items={items}
      loading={loading}
      pagination={{
        current: meta.page,
        pageSize: meta.items,
        total: meta.totalCount,
        position: ['bottomCenter'],
      }}
      onChangePage={(page) => loadData(page)}
      bordered
    />
  );
};

export default PatientEditorLog;
