import React, { useMemo, useEffect } from 'react';
import { isMobile } from 'utils/deviceHelper';
import { useSelector, useDispatch } from 'react-redux';
import {
  isCcmActionAllowedStatus,
  hasPatientProfileCcm,
} from 'utils/userTypeHelper';
import useUser from 'utils/useUser';
import { useNavigate, useParams } from 'react-router-dom';
import PatientTopBanner from 'components/molecules/patientTopBanner';
import AdminPatientKeyNotes from 'components/organisms/adminPanelKeyNotes';
import AdminPanelPatientDetailTabs from 'components/organisms/admin-panel/patient-detail/tabbed-forms';
import GeneralComments from 'components/molecules/general-comments';
import { ModuleType } from 'infrastructure/enums';
import PatientAppointmentDetails from 'components/organisms/appointment-details';
import PatientHealthQuestionnaire from 'components/organisms/patient-health-questionnaire';
import PastMedicalHistory from 'components/organisms/past-medical-history';
import PatientAllergyLog from 'components/organisms/allergy-log';
import PatientControlPanel from 'components/organisms/patient-control-panel';
import InteractiveTimeNew from 'components/organisms/interactiveTime/new';
import paths from 'constants/paths';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { useTranslation } from 'react-i18next';
import MiniNav from 'components/molecules/mini-nav';
import PatientMedication from 'components/organisms/patient-medication';
import PatientSelfGoals from 'components/organisms/patient-self-goals';
import ClinicalActivityAndInterventions from 'components/organisms/clinical-activity-and-interventions';
import RpmCcmNavigationHeader from 'components/organisms/rpm-ccm-navigation-header';
import NotFound from 'components/atoms/notFound';
import { isValidGuid } from 'infrastructure/functions/is-valid-guid';
import { showResult } from 'infrastructure/hooks/utils';

import s from './styles.module.scss';

const PatientDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { patientGuid } = useParams();
  useEffect(() => {
    if (!patientGuid || !isValidGuid(patientGuid)) {
      showResult(t('errors.invalidGuid'));
      navigate('/404', { replace: true });
    }
  }, [patientGuid, navigate]);
  if (!patientGuid || !isValidGuid(patientGuid)) {
    return null;
  }
  const { isPatient, isAdmin, isAgencyDoctor } = useUser();
  const deviceType = useSelector((state) => state.user.deviceType);
  const { patientInfo } = usePatientInfo({ patientGuid });
  const canEditPage = isCcmActionAllowedStatus(patientInfo?.ccmStatus);
  const haveAccessToPage = hasPatientProfileCcm(patientInfo?.ccmStatus);

  const {
    adminPanelPatients: { getPatient },
  } = useDispatch();

  const isMobileDevice = useMemo(() => isMobile(deviceType), [deviceType]);

  useEffect(() => {
    const run = async () => {
      const { error } = await getPatient({ patientGuid });

      if (error) {
        navigate('/404', { replace: true });
      }
    };
    run();
  }, [patientGuid]);

  if (patientInfo && !haveAccessToPage) {
    return (
      <div>
        <NotFound />
      </div>
    );
  }

  return (
    <>
      {haveAccessToPage && (
        <div className={s.wrapper}>
          <RpmCcmNavigationHeader
            patientGuid={patientGuid}
            clinicGuid={patientInfo?.clinicGuid}
            module={ModuleType.CCM}
            goBackPath={paths.ccm}
            title={t('labels.ccmPatientProfileTitle')}
          />
          <MiniNav module={ModuleType.CCM} />
          {!isMobileDevice && (isAdmin || isAgencyDoctor) && (
            <AdminPatientKeyNotes
              patientGuid={patientGuid}
              module="ccm"
              canChangeModule={false}
            />
          )}
          <div className={s.body}>
            {!isPatient && (
              <div className={s['banner-control-panel-wrapper']}>
                <PatientTopBanner
                  patientGuid={patientGuid}
                  module={ModuleType.CCM}
                />
                <div className={s['control-panel-wrapper']} id="control-panel">
                  <PatientControlPanel
                    patientGuid={patientGuid}
                    module={ModuleType.CCM}
                  />
                  <InteractiveTimeNew
                    patientGuid={patientGuid}
                    module={ModuleType.CCM}
                  />
                </div>
              </div>
            )}
            <PatientAppointmentDetails patientGuid={patientGuid} />
            <PatientHealthQuestionnaire patientGuid={patientGuid} />
            <PastMedicalHistory patientGuid={patientGuid} />
            <PatientAllergyLog patientGuid={patientGuid} />
            <ClinicalActivityAndInterventions patientGuid={patientGuid} />
            <PatientMedication
              patientGuid={patientGuid}
              module={ModuleType.CCM}
            />
            <PatientSelfGoals patientGuid={patientGuid} />
            <div id="user-profile">
              <AdminPanelPatientDetailTabs
                patientGuid={patientGuid}
                module={ModuleType.CCM}
                readOnly={!canEditPage}
              />
            </div>
            <div id="general-comments">
              <GeneralComments
                patientGuid={patientGuid}
                readOnly={!canEditPage}
                module={ModuleType.CCM}
                withSwitcher
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PatientDetails;
