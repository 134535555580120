import * as Yup from 'yup';

const turkUserSchema = Yup.object().shape({
  guid: Yup.string().nullable(),
  title: Yup.string().required('required').typeError('required'),
  firstName: Yup.string().required('required'),
  lastName: Yup.string().required('required'),
  middleName: Yup.string().nullable(),
  agencies: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    )
    .min(1, 'required'),
  email: Yup.string()
    .email('Email is not correct')
    .required('required')
    .test('sign', '+ sign not allowed', (val) => val?.indexOf('+') === -1),
  professionType: Yup.string().required('required'),
  ratePerReading: Yup.number()
    .test(
      'decimal-with-max-two-digits-after-comma',
      'Can have max 2 digits after comma',
      (value: any) => {
        if (value === undefined || value === null || value === '') {
          return true;
        }
        return Number(parseFloat(value).toFixed(2)) === value;
      },
    )
    .nullable()
    .typeError('must be number'),
  primaryLanguage: Yup.string().nullable(),
  secondaryLanguage: Yup.string().nullable(),
  timezone: Yup.string().nullable(),
  status: Yup.string(),
});

export type TurkUserSchema = Yup.InferType<typeof turkUserSchema>;

export default turkUserSchema;
