import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';

import { showResult } from '../utils';
import { apiUrlGenerator } from '../use-api';

import type {
  IAdminPanelPatientsResponse,
  IPartialPaginationMeta,
} from 'infrastructure/interfaces';

export type TAdminPanelPatientsFilter = {
  patientGuid?: string;
  clinicGuid?: string[];
  excludeClinicGuid?: string[];
  ccmStatus?: string[];
  status?: string[];
} & IPartialPaginationMeta;

export const ADMIN_PANEL_PATIENTS_PER_PAGE = 100;

const getAdminPanelPatientsFn = async (filter: TAdminPanelPatientsFilter) => {
  const url = `admins/admin-panel/patients`;
  const { data, error } =
    await dataService.getList<IAdminPanelPatientsResponse>(
      apiUrlGenerator(url, filter),
    );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
export const getAdminPanelPatientsKey = (filter: TAdminPanelPatientsFilter) => [
  'getAdminPanelPatients',
  filter,
];

export const useGetAdminPanelPatients = (filter: TAdminPanelPatientsFilter) => {
  const filterWithItems: TAdminPanelPatientsFilter = {
    ...filter,
    items: ADMIN_PANEL_PATIENTS_PER_PAGE,
  };

  const { data: patients, ...other } = useQuery({
    queryKey: getAdminPanelPatientsKey(filterWithItems),
    queryFn: async () => getAdminPanelPatientsFn(filterWithItems),
    keepPreviousData: true,
  });

  return { patients, ...other };
};
