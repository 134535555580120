import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const Address = ({ data }) => (
  <div className="admin-user-block">
    <span>Address</span>
    <Table>
      <tbody>
        {data.addressLine1 && (
          <tr>
            <td>Business Address line 1</td>
            <td>{data.addressLine1}</td>
          </tr>
        )}
        {data.addressLine2 && (
          <tr>
            <td>Business Address line 2</td>
            <td>{data.addressLine2}</td>
          </tr>
        )}
        {data.city && (
          <tr>
            <td>City</td>
            <td>{data.city}</td>
          </tr>
        )}
        {data.state && (
          <tr>
            <td>State</td>
            <td>{data.state}</td>
          </tr>
        )}
        {data.zip && (
          <tr>
            <td>Zip</td>
            <td>{data.zip}</td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);

Address.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Address;
