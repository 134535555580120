import BaseButton from 'components/atoms/baseButton';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import BaseModal from 'components/atoms/base-modal';
import DoctorAndAgencyDoctorSelectBox from 'components/molecules/all-doctor-select-box';
import BaseDatepicker from 'components/atoms/base-datepicker';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseForm from 'components/atoms/base-form';
import BaseGrid from 'components/atoms/base-grid';
import AppointmentMethodSelectBox from 'components/molecules/appointment-method-select-box';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import BaseTableOptionsDropdown from 'components/atoms/base-table-option-dropdown';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import { fullNamePresenter } from 'utils';
import BaseTimePicker from 'components/atoms/base-timepicker';

import s from './styles.module.scss';
import { useAppointment } from './hooks/use-appointment';

import type { FC } from 'react';

interface Props {
  patientGuid: string;
}

const AppointmentCard: FC<Props> = ({ patientGuid }) => {
  const { t } = useTranslation();

  const {
    open,
    Icon,
    emptyData,
    appointmentMethod,
    doctor,
    updateLoading,
    isLoading,
    nextAppointment,
    Alert,
    formik,
    popupTitle,
    disableButton,
    toggleOpen,
    getDateAndTime,
    onCancelForm,
    onDelete,
    submitForm,
    onChangeSelect,
    addNexAppointment,
  } = useAppointment({ patientGuid });

  const lastUpdatedName = fullNamePresenter({
    firstName: nextAppointment?.updatedBy?.firstName,
    lastName: nextAppointment?.updatedBy?.lastName,
    title: nextAppointment?.updatedBy?.title,
  });

  const dropdownInfo = (
    <p className={s['dropdown-info']}>
      <span>
        {t('labels.lastEditedBy')}: {lastUpdatedName},
      </span>
      <span>
        {DateFormatter({
          date: nextAppointment?.updatedAt,
          format: DateFormats['MMM dd yyyy | hh:mm a'],
        })}
      </span>
    </p>
  );

  return (
    <>
      <BaseSpinWrapper spinning={isLoading}>
        <div className={s.next}>
          <h5 className={s.title} data-cy="appointmentDetailsSection">
            {t('labels.nextAppointmentDetails')}
          </h5>
          <div
            className={classNames(s.wrapper, { [s['not-planed']]: emptyData })}
          >
            <div className={s.left}>
              {Icon}
              <div className={s['left-info']}>
                {emptyData ? (
                  <p className={s['left-info-title']}>
                    {t('labels.notPlanned')}
                  </p>
                ) : (
                  <>
                    <p
                      className={s['left-info-title']}
                      data-cy="nextAppointment-method"
                    >
                      {appointmentMethod}
                    </p>
                    <p
                      className={s['left-info-subtitle']}
                      data-cy="nextAppointment-doctor"
                    >
                      {doctor?.label}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className={s.right}>
              {!nextAppointment || emptyData ? (
                <BaseButton
                  dataCy="bookAppointment-button"
                  type="secondary"
                  label={`+ ${t('controls.bookAppointment')}`}
                  onClick={addNexAppointment}
                  disabled={disableButton || isLoading}
                />
              ) : (
                <>
                  <div className={s['right-date']}>
                    <p
                      className={s['right-date-title']}
                      data-cy="nextAppointment-dateAndTime"
                    >
                      {t('labels.dateAndTime')}:
                    </p>
                    <p
                      className={s['right-date-value']}
                      data-cy="nextAppointment-dateAndTimeValue"
                    >
                      {getDateAndTime(nextAppointment.scheduledDate)}
                    </p>
                  </div>
                  <BaseTableOptionsDropdown
                    loading={updateLoading}
                    onDelete={onDelete}
                    onEdit={toggleOpen}
                    bordered
                    disabled={disableButton}
                    triggerBtnClassName={s.trigger}
                    info={dropdownInfo}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </BaseSpinWrapper>
      <BaseModal title={popupTitle} open={open} onCancel={onCancelForm}>
        <BaseForm
          formik={formik}
          withControls
          plaintext={false}
          readonly={false}
          onSave={submitForm}
          onCancel={onCancelForm}
          loading={updateLoading}
        >
          <BaseGrid rowGap={4}>
            <BaseGrid columns={2}>
              <BaseFormItem
                required
                name="appointment.scheduledDate"
                label={t('labels.appointmentDate')}
                isBlackLabel
              >
                <BaseDatepicker />
              </BaseFormItem>
              <BaseFormItem
                required
                name="appointment.scheduledTime"
                label={t('labels.appointmentTime')}
                isBlackLabel
              >
                <BaseTimePicker format={DateFormats['HH:mm']} />
              </BaseFormItem>
            </BaseGrid>
            <BaseFormItem
              required
              name="appointment.scheduledWith"
              label={t('labels.scheduledWith')}
              isBlackLabel
            >
              <DoctorAndAgencyDoctorSelectBox
                patientGuid={patientGuid}
                onChange={onChangeSelect}
              />
            </BaseFormItem>
            <BaseFormItem
              required
              name="appointment.appointmentMethod"
              label={t('labels.appointmentMethod')}
              isBlackLabel
            >
              <AppointmentMethodSelectBox />
            </BaseFormItem>
          </BaseGrid>
        </BaseForm>
      </BaseModal>
      {Alert}
    </>
  );
};
export default AppointmentCard;
