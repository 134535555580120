import React from 'react';

const ShareIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector (Stroke)"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.97626 0.706955C7.26758 0.431015 7.73242 0.431015 8.02374 0.706955L11.0987 3.61958C11.3952 3.90041 11.4011 4.36141 11.1118 4.64926C10.8226 4.93712 10.3477 4.94281 10.0513 4.66198L8.25 2.95584V11.1311C8.25 11.5332 7.91421 11.8592 7.5 11.8592C7.08579 11.8592 6.75 11.5332 6.75 11.1311V2.95584L4.94874 4.66198C4.65226 4.94281 4.17742 4.93712 3.88816 4.64927C3.59891 4.36141 3.60477 3.90041 3.90126 3.61958L6.97626 0.706955ZM0.75 7.49029C1.16421 7.49029 1.5 7.8163 1.5 8.21845V13.3155C1.5 13.5087 1.57902 13.6939 1.71967 13.8304C1.86032 13.967 2.05108 14.0437 2.25 14.0437H12.75C12.9489 14.0437 13.1397 13.967 13.2803 13.8304C13.421 13.6939 13.5 13.5087 13.5 13.3155V8.21845C13.5 7.8163 13.8358 7.49029 14.25 7.49029C14.6642 7.49029 15 7.8163 15 8.21845V13.3155C15 13.8949 14.7629 14.4505 14.341 14.8602C13.919 15.2699 13.3467 15.5 12.75 15.5H2.25C1.65327 15.5 1.08097 15.2699 0.65901 14.8602C0.237052 14.4505 0 13.8949 0 13.3155V8.21845C0 7.8163 0.335786 7.49029 0.75 7.49029Z"
      fill="#CCCAD7"
    />
  </svg>
);

export default ShareIcon;
