type KeyType = string | number | symbol;

interface Grouped<T> {
  [key: KeyType]: T[];
}

export const groupArrayByFieldname = <T extends Record<KeyType, any>>(
  array: T[],
  field: keyof T,
): Grouped<T> => {
  return array.reduce((acc, item) => {
    const key = item[field];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {} as Grouped<T>);
};
