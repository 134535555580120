import { useState } from 'react';
import { DeviceLog } from 'infrastructure/classes/device-log';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { useQueryClient } from '@tanstack/react-query';

import useApi from '../use-api';

import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IDeviceLogPaginatedData,
} from 'infrastructure/interfaces';
import type { SortState, DateString } from 'infrastructure/types';
import type { DeviceStateType } from 'infrastructure/enums';

const patientDeviceLogsApi = (deviceId: string) =>
  `core/patients/devices/${deviceId}/state-logs`;

export interface IUseDeviceLogMeta {
  type?: DeviceStateType;
  startTime?: DateString;
  endTime?: DateString;
  sortByDeviceId?: SortState;
  sortByType?: SortState;
  sortByTime?: SortState;
}

type mergedMeta = IUseDeviceLogMeta & IPaginationMeta;

type UseDeviceLog = {
  deviceLogs: Array<DeviceLog>;
  loading: boolean;
  meta: mergedMeta;
  loadDeviceLogs: (deviceId: string, meta: mergedMeta) => Promise<void>;
};

interface IUseDeviceLogProps {
  meta?: IUseDeviceLogMeta & IPartialPaginationMeta;
}

export const useDeviceLog = (props?: IUseDeviceLogProps): UseDeviceLog => {
  const options = props || {};
  const metaProps = options.meta || {};

  const { loading, isMounted, loadData } = useApi();

  const queryClient = useQueryClient();

  const [deviceLogs, setDeviceLogs] = useState<DeviceLog[]>([]);
  const [meta, setMeta] = useState<mergedMeta>({
    page: 1,
    items: 10,
    totalCount: 0,
    ...metaProps,
  });

  const loadDeviceLogs = async (deviceId: string, filters: mergedMeta) => {
    const queryKey = ['loadDeviceLogs', deviceId, filters];
    const data = await queryClient.fetchQuery(
      queryKey,
      () =>
        loadData<IDeviceLogPaginatedData, Omit<IUseDeviceLogMeta, 'sortField'>>(
          patientDeviceLogsApi(deviceId),
          {
            sortByTime: 'DESC',
            page: filters.page,
            items: filters.items,
          },
        ),
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      const { data: loadedItems, count } = data;
      setMeta({
        ...meta,
        ...filters,
        totalCount: count,
      });

      setDeviceLogs(loadedItems.map((el) => new DeviceLog(el)));
    }
  };

  return {
    loading,
    deviceLogs,
    meta,
    loadDeviceLogs,
  };
};
