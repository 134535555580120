import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import C from 'classnames';
import { Card, CardBody } from 'reactstrap';
import useConfirm from 'utils/useConfirm';
import ConnectHideIcon from 'components/atoms/icons/connectHideIcon';
import Connect from 'components/organisms/connect/connect';
import ConnectComments from 'components/organisms/connect/comments';

const BOUNDARY_LIMIT = 40;

const ConnectModal = () => {
  const [callId, setCallId] = useState();
  const [windowExpanded, setWindowExpanded] = useState(true);
  const [pcctValues, setPcctValues] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 130, y: 130 });
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const style = { top: position.y, left: position.x, width: 360 };
  const isOpen = useSelector((state) => state.connect.connectPopoutOpen);
  const connectDetails = useSelector((state) => state.connect.connectDetails);
  const {
    connect: { handleConnectPopoutOpen, connectDetails: handleConnectDetails },
  } = useDispatch();

  const showConfirm = useConfirm({
    message: 'Are you sure you want to exit, there are unsaved changes',
    title: 'Confirm',
  });

  const handleClick = () => {
    const details = {
      phone: '',
      guid: '',
      isRpmCall: true,
      readingId: '',
      deviceId: '',
    };
    handleConnectDetails(details);
    handleConnectPopoutOpen(false);
  };

  const onClick = async () => {
    if (Object.keys(pcctValues).length) {
      if (await showConfirm()) {
        handleClick();
      }
    } else {
      handleClick();
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setInitialPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      if (
        e.clientY <= BOUNDARY_LIMIT ||
        e.clientX <= BOUNDARY_LIMIT ||
        e.clientX >= viewportWidth - BOUNDARY_LIMIT ||
        e.clientY >= viewportHeight - BOUNDARY_LIMIT
      )
        return;

      const newPosition = {
        x: e.clientX - initialPosition.x,
        y: e.clientY - initialPosition.y,
      };

      setPosition(newPosition);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
    <div
      id="connect-modal"
      className={C('connect-modal', { isOpen })}
      style={style}
    >
      <Card>
        <CardBody>
          <div
            className="connect-modal-header"
            id="connect-modal-header"
            onMouseDown={handleMouseDown}
            style={{ cursor: isDragging ? 'grabbing' : 'pointer' }}
          >
            <h5>Accuhealth Connect</h5>
            <button className="connect-modal-close" onClick={onClick}>
              &times;
            </button>
          </div>
          <div className="connect-modal-body">
            <Connect
              isOpen={isOpen}
              phoneNumber={connectDetails.phone}
              patientGuid={connectDetails.guid}
              setCallId={setCallId}
              isRpmCall={connectDetails.isRpmCall}
              readingId={connectDetails.readingId}
              deviceId={connectDetails.deviceId}
              windowExpanded={windowExpanded}
            />
            <div className="connect-modal-body-expand-button">
              <ConnectHideIcon
                className={C(
                  windowExpanded ? 'button-expanded' : 'button-collapsed',
                )}
                onClick={() => setWindowExpanded(!windowExpanded)}
              />
            </div>
            <ConnectComments
              patientGuid={connectDetails.guid}
              isOpen={isOpen}
              callId={callId}
              isRpmCall={connectDetails.isRpmCall}
              readingId={connectDetails.readingId}
              deviceId={connectDetails.deviceId}
              pcctValues={pcctValues}
              setPcctValues={setPcctValues}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ConnectModal;
