import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PatientRow = ({ item }) => (
  <tr className="activity-row">
    <td className="name">
      <Link to={`/patient/${item.patientGuid}`}>
        <strong>{item.patientName}</strong>
      </Link>
    </td>
    <td>{item.mrn}</td>
    <td>{item.count}</td>
  </tr>
);

PatientRow.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default PatientRow;
