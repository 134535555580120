import { useQuery } from '@tanstack/react-query';
import { showResult } from 'infrastructure/hooks/utils';
import dataService from 'services/dataService';
import { API_DOCTOR_INSURANCE_TYPES } from 'services/dataService/resources';

const getInsuranceTypesFn = async () => {
  const { data: response, error } = await dataService.getList<string[]>(
    API_DOCTOR_INSURANCE_TYPES,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  const defaultValue = { value: null, label: 'All' };

  if (response) {
    const resData = [...new Set(response)].map((i) => ({ value: i, label: i }));
    return [defaultValue, ...resData];
  }
};

const getInsuranceTypesKey = () => ['getInsuranceTypes'];

export const useGetInsuranceTypes = (enabled: boolean) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: getInsuranceTypesKey(),
    queryFn: getInsuranceTypesFn,
    enabled,
  });

  return {
    insuranceTypes: data,
    insuranceTypesLoading: isLoading,
    refetchInsuranceType: refetch,
  };
};
