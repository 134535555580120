import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SlaTag from 'components/atoms/tags/SlaTag';
import { capitalize, fullNamePresenter } from 'utils';

const PatientNameHoldingStatusFlag = ({
  title,
  firstName,
  middleName,
  lastName,
  holdingStatus,
  displayFlag = true,
  className,
  to,
  dataCy,
  ccmHoldingStatus,
  ccmHoldingStatusStartDate,
  consumablesArrivingDate,
  clinicServiceDeliveryThresholds,
}) => (
  <div className="patient-name-holding-status">
    <Link className={className} data-cy={dataCy} to={to}>
      <strong>
        {fullNamePresenter({ title, middleName, firstName, lastName })}
      </strong>
    </Link>

    {((holdingStatus && holdingStatus !== 'active' && displayFlag) ||
      ccmHoldingStatus ||
      consumablesArrivingDate ||
      clinicServiceDeliveryThresholds) && (
      <div className="holding-status-flag__wrapper">
        {clinicServiceDeliveryThresholds && (
          <div>
            <SlaTag hours={clinicServiceDeliveryThresholds} small />
          </div>
        )}
        {holdingStatus && holdingStatus !== 'active' && displayFlag && (
          <div className="holding-status-flag">{capitalize(holdingStatus)}</div>
        )}
        {consumablesArrivingDate && (
          <div className="holding-status-flag" title={consumablesArrivingDate}>
            Await. Consum.
          </div>
        )}
        {ccmHoldingStatus && (
          <div
            className="holding-status-flag"
            title={ccmHoldingStatusStartDate}
          >
            Temp. Hold
          </div>
        )}
      </div>
    )}
  </div>
);

PatientNameHoldingStatusFlag.propTypes = {
  title: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  holdingStatus: PropTypes.string,
  displayFlag: PropTypes.bool,
  ccmHoldingStatus: PropTypes.bool,
  ccmHoldingStatusStartDate: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
  dataCy: PropTypes.string,
  consumablesArrivingDate: PropTypes.string,
  clinicServiceDeliveryThresholds: PropTypes.string,
};

export default PatientNameHoldingStatusFlag;
