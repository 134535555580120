import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseInput from 'components/atoms/base-input';
import BaseTextArea from 'components/atoms/base-textarea';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseGrid from 'components/atoms/base-grid';
import { type FC } from 'react';
import BaseDatepicker from 'components/atoms/base-datepicker';
import classNames from 'classnames';
import MedicationAutocomplete from 'components/molecules/medication-autocomplate';
import BaseModal from 'components/atoms/base-modal';
import BaseButton from 'components/atoms/baseButton';

import { medicationValidationSchema } from './validation-schema';
import s from './styles.module.scss';

import type { IMedicationForUpdate } from 'infrastructure/interfaces';
import type { FormikHelpers } from 'formik';
import type { Values } from './validation-schema';

interface AddMedicationFormProps {
  onCancelForm: () => void;
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  loading: boolean;
  modalTitle: string;
  open: boolean;
  defaultValues?: IMedicationForUpdate;
}

const AddMedicationForm: FC<AddMedicationFormProps> = ({
  onCancelForm,
  onSubmit,
  loading,
  defaultValues,
  modalTitle,
  open,
}) => {
  const { t } = useTranslation();
  const formik = useFormik<Values>({
    initialValues: medicationValidationSchema().cast(defaultValues),
    enableReinitialize: true,
    onSubmit,
    validateOnChange: false,
    validationSchema: medicationValidationSchema(),
  });

  const { submitForm, resetForm } = formik;

  const onCancel = () => {
    resetForm();
    onCancelForm();
  };

  const isStatusActive = formik.values.isActive;
  const statusLabel = isStatusActive
    ? t('labels.active')
    : t('labels.inactive');

  return (
    <BaseModal
      title={modalTitle}
      open={open}
      onCancel={onCancel}
      smallBodyPaddingWithFooter
      withFooter={[
        <BaseButton
          dataCy="cancel-button"
          key="cancel"
          type="secondary"
          label={t('controls.cancel')}
          onClick={onCancel}
        />,
        <BaseButton
          dataCy="save-button"
          key="save"
          type="primary"
          label={t('controls.save')}
          loading={loading}
          onClick={submitForm}
        />,
      ]}
      width="1000px"
    >
      <BaseForm
        formik={formik}
        withControls
        plaintext={false}
        readonly={false}
        loading={loading}
      >
        <div className={s.wrapper}>
          <div className={s.medication}>
            <BaseFormItem
              name="name"
              label={`${t('labels.medication')}`}
              required
              isBlackLabel
            >
              <MedicationAutocomplete
                disabled={typeof defaultValues !== 'undefined'}
              />
            </BaseFormItem>
            <div className={s.status}>
              <p>{t('labels.status')}</p>
              <div
                data-cy="medication-current-status"
                className={classNames({
                  [s['active-status']]: isStatusActive,
                  [s['inactive-status']]: !isStatusActive,
                })}
              >
                {statusLabel}
              </div>
            </div>
          </div>
          <div className={s.divider} />
          <BaseGrid columns={2} columnGap={64} className={s['inputs-wrapper']}>
            <BaseGrid
              columns={2}
              columnGap={24}
              className={s['inputs-wrapper']}
            >
              <BaseFormItem
                name="startDate"
                label={t('labels.startDate')}
                isBlackLabel
              >
                <BaseDatepicker placeholder={t('labels.selectDate')} />
              </BaseFormItem>
              <BaseFormItem
                name="endDate"
                label={t('labels.endDate')}
                isBlackLabel
              >
                <BaseDatepicker placeholder={t('labels.selectDate')} />
              </BaseFormItem>
            </BaseGrid>
            <BaseGrid
              columns={2}
              columnGap={24}
              className={s['inputs-wrapper']}
            >
              <BaseFormItem name="dosage" label={t('labels.dose')} isBlackLabel>
                <BaseInput />
              </BaseFormItem>
              <BaseFormItem name="route" label={t('labels.route')} isBlackLabel>
                <BaseInput />
              </BaseFormItem>
            </BaseGrid>
          </BaseGrid>
          <BaseGrid columns={2} columnGap={64} className={s['inputs-wrapper']}>
            <BaseFormItem
              name="duration"
              label={t('labels.duration')}
              isBlackLabel
            >
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="frequency"
              label={t('labels.frequency')}
              isBlackLabel
            >
              <BaseInput />
            </BaseFormItem>
          </BaseGrid>
          <BaseFormItem name="note" label={t('labels.note')} isBlackLabel>
            <BaseTextArea rows={8} placeholder={t('labels.typeYourNoteHere')} />
          </BaseFormItem>
        </div>
      </BaseForm>
    </BaseModal>
  );
};
export default AddMedicationForm;
