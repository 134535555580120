import ControlButtonDropdown from 'components/atoms/controlPanelDropdownButton/controlButtonDropdown';
import ControlPanelSuccessIcon from 'components/atoms/icons/controlPanelSuccessIcon';
import PatientControlButton from 'components/atoms/patentControlButton';
import React, { useMemo, useState } from 'react';
import { booleansDataSource } from 'infrastructure/data-sources/booleans';

import type { JSXElementConstructor, SVGProps } from 'react';

interface IProps {
  value: boolean;
  label: string;
  icon?: JSXElementConstructor<SVGProps<SVGSVGElement>>;
  onChange?: (val: boolean) => void;
}

const BooleanControlButton: React.FC<IProps> = (props) => {
  const { label, value, icon, onChange } = props;

  const [opened, setOpened] = useState(false);

  const booleans = booleansDataSource();

  const selectedValue = useMemo(() => {
    const val = booleans.find((el) => el.id === value);
    return val ?? booleans[0];
  }, [value, booleans]);

  const ToggleButton = (
    <PatientControlButton
      label={label}
      status={selectedValue.name}
      Icon={icon ?? ControlPanelSuccessIcon}
      handleClick={() => setOpened(!opened)}
    />
  );

  const handleChange = (val: boolean) => {
    if (onChange) onChange(val);
    setOpened(false);
  };

  return (
    <div>
      <ControlButtonDropdown
        toggleBtn={ToggleButton}
        options={booleans.map((el) => ({
          id: el.name,
          value: el.id,
          label: el.name,
        }))}
        handleOptionClick={handleChange}
      />
    </div>
  );
};

export default BooleanControlButton;
