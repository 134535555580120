import PencilIcon from 'components/atoms/icons/pencilIcon';
import TurkConfigurationButton from 'components/molecules/turkConfiguration/button';
import LabelValueElement from 'components/molecules/turkConfiguration/labelValueElement';

import type { FC } from 'react';
import type { TurkConfigurations } from 'utils/hooks/turkDashboard/useSettings';

type Props = {
  turkConfiguration: TurkConfigurations;
  changeModeToEdit: () => void;
};

const TurkConfigurationView: FC<Props> = ({
  turkConfiguration,
  changeModeToEdit,
}) => {
  return (
    <>
      <div className="turk-configuration--control">
        <TurkConfigurationButton
          title="Edit"
          onClick={changeModeToEdit}
          icon={<PencilIcon height={14} width={14} />}
        />
      </div>
      <LabelValueElement
        label="Default Rate Per Reading"
        value={turkConfiguration.defaultRatePerReading}
      />
      <LabelValueElement
        label="Maximum Daily Readings"
        value={turkConfiguration.maximumDailyReadings}
      />
      <LabelValueElement
        label="Maximum Readings Per Batch"
        value={turkConfiguration.maximumReadingsPerBatch}
      />
      <LabelValueElement
        label="Minimum Reading Batch Refresh Rate (Minutes)"
        value={turkConfiguration.minimumReadingBatchRefreshRate}
      />
      <LabelValueElement
        label="Maximum Reading Review Response Time (Minutes)"
        value={turkConfiguration.maximumReadingReviewResponseTime}
      />
      <LabelValueElement
        label="Maximum Allowable Idle Time (Minutes)"
        value={turkConfiguration.maximumAllowableIdleTime}
      />
    </>
  );
};

export default TurkConfigurationView;
