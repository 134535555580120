import DateColumnMobile from './components/date-col/mobile';
import PatientReadingColumn from './components/patient-reading-col';
import s from './styles.module.scss';

import type { IActivityData } from 'infrastructure/interfaces/i-rpm-patient-activity';
import type { FC } from 'react';

interface IPatientMobileCellProps {
  createdAt: string;
  record: IActivityData;
  idx: number;
}

const PatientMobileCell: FC<IPatientMobileCellProps> = ({
  createdAt,
  idx,
  record,
}) => {
  return (
    <div className={s['mob-wrapper']}>
      <DateColumnMobile
        createdAt={createdAt}
        globalStatus={record.globalStatus}
        idx={idx}
      />
      <PatientReadingColumn
        globalStatus={record.globalStatus}
        readingType={record.readingType}
        statuses={record.statuses}
        thresholds={record.patient.threshold}
        values={record.value}
        key={`reading-col-${idx}`}
      />
    </div>
  );
};

export default PatientMobileCell;
