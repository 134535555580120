import React from 'react';
import classNames from 'classnames';

import s from './styles.module.scss';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

const BaseWrapper: React.FC<IBaseComponentProps> = (props) => {
  const { className, children } = props;
  const wrapperClassNames = classNames(s.wrapper, className);
  return <div className={wrapperClassNames}>{children}</div>;
};

export default BaseWrapper;
