import React from 'react';
import { useAdherence } from 'infrastructure/hooks';
import BaseTable from 'components/atoms/base-table';

import AdherenceReportTableFilters from './filters';
import { adherenceReportColumns } from './cols';
import s from './styles.module.scss';

import type { IBaseComponentProps } from 'infrastructure/interfaces';
import type { IUseAdherenceMeta } from 'infrastructure/hooks';

interface IAdherenceReportTableProps extends IBaseComponentProps {
  onFilterChange?: (filters: IUseAdherenceMeta) => void;
}

const AdherenceReportTable: React.FC<IAdherenceReportTableProps> = (props) => {
  const { className, onFilterChange } = props;
  const { items, loading, setMeta, meta } = useAdherence();

  const loadReports = async (filters: IUseAdherenceMeta) => {
    const newMeta = {
      ...meta,
      ...filters,
    };
    setMeta(newMeta);
    if (onFilterChange) onFilterChange(newMeta);
  };

  const changePage = (page: number) => {
    setMeta({
      ...meta,
      page,
    });
  };

  return (
    <div className={className}>
      <AdherenceReportTableFilters
        className={s.filters}
        onChange={loadReports}
      />
      <BaseTable
        loading={loading}
        columns={adherenceReportColumns()}
        items={items}
        height="500px"
        pagination={{
          current: meta.page,
          pageSize: meta.items,
          total: meta.totalCount,
        }}
        onSort={(field, sort) => {
          loadReports({
            sortField: [field, sort],
          });
        }}
        onChangePage={changePage}
      />
    </div>
  );
};

export default AdherenceReportTable;
