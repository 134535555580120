import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { components as SelectComponents } from 'react-select';
import { apiGetClinicsByFilter } from 'services/dataService/resources';
import { debounce } from 'lodash';
import dataService from 'services/dataService';
import common from 'constants/common';
import DropdownOpenIcon from 'components/atoms/icons/dropdownOpenIcon';
import DropdownClosedIcon from 'components/atoms/icons/dropdownClosedIcon';

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '52px',
  }),
};

const selectTheme = (theme) => ({
  ...theme,
  borderRadius: 12,
  colors: {
    ...theme.colors,
    ...common.primaryColors,
  },
});

const customSelectComponents = {
  DropdownIndicator: (defaultProps) => {
    const { selectProps } = defaultProps;

    return (
      <SelectComponents.DropdownIndicator {...defaultProps}>
        {selectProps.menuIsOpen ? <DropdownOpenIcon /> : <DropdownClosedIcon />}
      </SelectComponents.DropdownIndicator>
    );
  },
};

const search = async (string) => {
  const { data } = await dataService.getList(
    `${apiGetClinicsByFilter({ legalname: string })}`,
  );
  return data ?? [];
};

const _loadSuggestions = (query, callback) => {
  search(query).then((response) => {
    const data = response.map((item) => ({
      id: item.guid,
      value: item.guid,
      label: item.legalname,
      parentClinic: item.childClinics.length > 0,
    }));
    return callback(data);
  });
};

const loadSuggestions = debounce(_loadSuggestions, 800);

const AutoCompleteClinicsMultiSelect = ({ setClinicFilters }) => {
  const clinicChanged = (values) => {
    const clinicGuids = values.map((value) => value.id).join(',');
    setClinicFilters(clinicGuids, values);
  };

  return (
    <AsyncSelect
      classNamePrefix="select-type"
      theme={(theme) => selectTheme(theme)}
      components={customSelectComponents}
      isMulti
      styles={selectStyles}
      loadOptions={loadSuggestions}
      onChange={clinicChanged}
    />
  );
};

AutoCompleteClinicsMultiSelect.propTypes = {
  setClinicFilters: PropTypes.func.isRequired,
};

export default AutoCompleteClinicsMultiSelect;
