import { useTranslation } from 'react-i18next';
import AverageIcon from 'components/atoms/icons/averageIcon';
import { useGetAverages } from 'infrastructure/hooks/rpm';
import { makeid } from 'utils/helpers/makeid';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import { usePatientThresholds } from 'infrastructure/hooks/patient/use-patient-thresholds';

import s from './styles.module.scss';
import AverageItem from './components/average-item';

import type { FC } from 'react';

interface PatientAveragesProps {
  patientGuid: string;
}

const PatientAverages: FC<PatientAveragesProps> = ({ patientGuid }) => {
  const { averages, isLoading } = useGetAverages({ patientGuid });
  const { thresholds, loading } = usePatientThresholds({ patientGuid });
  const { t } = useTranslation();

  return (
    <div className={s.wrapper} id="average-metrics">
      <h4 className={s.title}>
        <AverageIcon />
        {t('labels.averages')}
      </h4>
      <div className={s.divider} />
      <div className={s.body}>
        <BaseSpinWrapper spinning={isLoading || loading}>
          <div className={s['item-wrapper']}>
            <h5 className={s.subtitle}>{t('labels.averageLast30Days')}</h5>
            <div className={s.items}>
              {averages?.averages30.map((average) => (
                <AverageItem
                  average={average}
                  thresholds={thresholds}
                  key={makeid(5)}
                />
              ))}
            </div>
          </div>
        </BaseSpinWrapper>
        <div className={s.divider} />
        <BaseSpinWrapper spinning={isLoading || loading}>
          <div className={s['item-wrapper']}>
            <h5 className={s.subtitle}>{t('labels.averageLast90Days')}</h5>
            <div className={s.items}>
              {averages?.averages90.map((average) => (
                <AverageItem
                  average={average}
                  thresholds={thresholds}
                  key={makeid(5)}
                />
              ))}
            </div>
          </div>
        </BaseSpinWrapper>
      </div>
    </div>
  );
};
export default PatientAverages;
