export enum ReadingType {
  BloodGlucose = 'BloodGlucose',
  BloodPressure = 'BloodPressure',
  SpO2 = 'SpO2',
  Temperature = 'Temperature',
  Weight = 'Weight',
  Pulse = 'Pulse',
  SleepingMat = 'SleepingMat', /// combine for SleepingMatDuration & SleepingMatActivity
  SleepingMatDuration = 'SleepingMatDuration',
  SleepingMatActivity = 'SleepingMatActivity',
  PeakFlow = 'PeakFlow',
}
