import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { showResult } from 'infrastructure/hooks/utils';

import { updatePatientDischargeStatusFn } from './api';

import type { IPatient } from 'infrastructure/interfaces';

interface Props {
  onSuccess: () => void;
}

export const useUpdatePatientDischargeStatus = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: updatePatientDischargeStatusFn,
    onSuccess(data, variables) {
      const patientInfoKey = getPatientInfoKey(variables.patientGuid);
      queryClient.setQueryData<IPatient>(patientInfoKey, (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            status: variables.dischargeStatus,
          };
        }
      });
      showResult();
      onSuccess();
    },
  });

  return {
    updateDischargeStatus: mutate,
    updateDischargeStatusLoading: isLoading,
  };
};
