import type { FC, SVGProps } from 'react';

const EmptyClinicalSmsIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.5 2C29.6046 2 30.5 2.89543 30.5 4V20C30.5 21.1046 29.6046 22 28.5 22H23.5C22.241 22 21.0554 22.5928 20.3 23.6L16.5 28.6667L12.7 23.6C11.9446 22.5928 10.759 22 9.5 22H4.5C3.39543 22 2.5 21.1046 2.5 20V4C2.5 2.89543 3.39543 2 4.5 2H28.5ZM4.5 0C2.29086 0 0.5 1.79086 0.5 4V20C0.5 22.2091 2.29086 24 4.5 24H9.5C10.1295 24 10.7223 24.2964 11.1 24.8L14.9 29.8667C15.7 30.9333 17.3 30.9333 18.1 29.8667L21.9 24.8C22.2777 24.2964 22.8705 24 23.5 24H28.5C30.7091 24 32.5 22.2091 32.5 20V4C32.5 1.79086 30.7091 0 28.5 0H4.5Z"
        fill="#667180"
      />
      <path
        d="M6.5 7C6.5 6.44772 6.94772 6 7.5 6H25.5C26.0523 6 26.5 6.44772 26.5 7C26.5 7.55228 26.0523 8 25.5 8H7.5C6.94772 8 6.5 7.55228 6.5 7ZM6.5 12C6.5 11.4477 6.94772 11 7.5 11H25.5C26.0523 11 26.5 11.4477 26.5 12C26.5 12.5523 26.0523 13 25.5 13H7.5C6.94772 13 6.5 12.5523 6.5 12ZM6.5 17C6.5 16.4477 6.94772 16 7.5 16H17.5C18.0523 16 18.5 16.4477 18.5 17C18.5 17.5523 18.0523 18 17.5 18H7.5C6.94772 18 6.5 17.5523 6.5 17Z"
        fill="#667180"
      />
    </svg>
  );
};

export default EmptyClinicalSmsIcon;
