import React, { useState } from 'react';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import withLoader from 'components/organisms/withLoader';
import { API_INTERACTION_STATUS_V2 } from 'services/dataService/resources';
import { Card, CardBody, Container, Table } from 'reactstrap';
import dataService from 'services/dataService';
import ActivityHeader from 'components/templates/rpm/practice/interactionStatus/activityHeader';
import Pagination from 'components/molecules/pagination';
import { generateTimeRangesYearMonth } from 'utils/dateHelpers';
import useTable from 'utils/useTable';
import { showResult } from 'infrastructure/hooks/utils';
import common from 'constants/common';
import forms from 'constants/forms';
import ActivityRow from 'components/templates/rpm/practice/interactionStatus/activityRow';
import Toolbar from 'components/templates/rpm/practice/interactionStatus/toolbar';
import { isEmpty } from 'lodash';

const PER_PAGE = 100;
const timeRanges = generateTimeRangesYearMonth();

const InteractionStatusV2 = () => {
  const {
    data: { data = [] },
    count,
    loading: reportLoading,
    page,
    setPage,
    filters,
    setFilters,
  } = useTable(API_INTERACTION_STATUS_V2, PER_PAGE, {
    year: timeRanges[0].value[0],
    month: timeRanges[0].value[1],
    patientStatus: forms.patientStatuses[0].id,
  });

  const [downloadLoading, setDownloadLoading] = useState(false);

  const sendReport = async () => {
    if (downloadLoading) {
      return;
    }
    const doctorGuids = filters?.doctorGuid?.split(',') || undefined;
    const cleanedDoctorGuids =
      doctorGuids && doctorGuids.filter((guid) => guid !== '');

    const postData = {
      ...filters,
      doctorGuid: undefined,
      doctorGuids: isEmpty(cleanedDoctorGuids) ? undefined : doctorGuids,
    };
    setDownloadLoading(true);
    const { error } = await dataService.createOne(
      API_INTERACTION_STATUS_V2,
      postData,
    );
    setDownloadLoading(false);
    return showResult(error, common.submitSuccessTypes.sendReport);
  };

  return (
    <Container className="performance-review">
      <div>
        <Card className="card-border card--full-width card--margin-bottom">
          <CardBody>
            <div className="performance-review__header">
              <div className="performance-review__header--toolbox__wrapper">
                <h3 className="performance-review__header-title">
                  Report: Monthly Interaction Status
                </h3>
                <p style={{ display: 'inline-block', marginTop: '0px' }}>
                  This report only includes patients with one or more readings
                  for the month
                </p>
                <Toolbar
                  filters={filters}
                  setFilters={(newFilters) =>
                    setFilters({ ...filters, ...newFilters })
                  }
                  timeRanges={timeRanges}
                />
              </div>
              <div className="performance-review__header-icon">
                {withLoader(
                  downloadLoading,
                  <button onClick={sendReport}>
                    <DownloadRoundedIcon className="performance-review__header-icon-svg" />
                    <span>Download</span>
                  </button>,
                )}
              </div>
            </div>
            <div className="performance-review__table table-sticky-header">
              {withLoader(
                reportLoading,
                <>
                  <Table borderless cellSpacing="0" cellPadding="0">
                    <ActivityHeader />
                    <tbody>
                      {data.map(({ patient, doctor, clinic }, id) => (
                        <ActivityRow
                          key={id}
                          patient={patient}
                          doctor={doctor}
                          clinic={clinic}
                        />
                      ))}
                    </tbody>
                  </Table>
                </>,
              )}
            </div>
            <div className="performance-review__table-button-pagination">
              <Pagination
                page={page}
                pageTotal={data.length}
                itemsPerPage={PER_PAGE}
                total={count}
                handleFirstClick={() => setPage(1)}
                handleLastClick={() => setPage(Math.ceil(count / PER_PAGE))}
                handlePrevClick={() => setPage(page - 1)}
                handleNextClick={() => setPage(page + 1)}
                handleGoToPage={(pageNumber) => setPage(pageNumber)}
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default InteractionStatusV2;
