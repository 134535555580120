import i18next from 'i18next';
import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';
import { phoneNumberRegExp } from 'infrastructure/regexp';

export const adminPanelContactInfoValidationSchema = () =>
  createValidationSchema(
    {
      homeNumber: commonValidationSchemas.string({
        matches: {
          regexp: phoneNumberRegExp,
          message: i18next.t('validation.phoneNumber'),
        },
      }),
      secondaryNumberType: commonValidationSchemas.string(),
      preferredContactMethod: commonValidationSchemas.string(),
      address: commonValidationSchemas.string({
        when: {
          fields: ['loginInfo.zipPostalCode'],
          is: (zipPostalCode) => !!zipPostalCode,
          then: {
            required: true,
          },
        },
      }),
      emergencyContactNumber: commonValidationSchemas.string({
        matches: {
          regexp: phoneNumberRegExp,
          message: i18next.t('validation.phoneNumber'),
        },
      }),
      emergencyContactName: commonValidationSchemas.string(),
      emergencyContactRelationship: commonValidationSchemas.string(),
      emergencyContactNumberType: commonValidationSchemas.string(),
      loginInfo: createValidationSchema(
        {
          phone: commonValidationSchemas.string({
            required: true,
            matches: {
              regexp: phoneNumberRegExp,
              message: i18next.t('validation.phoneNumber'),
            },
          }),
          primaryNumberType: commonValidationSchemas.string(),
          email: commonValidationSchemas.email({
            required: true,
          }),
          state: commonValidationSchemas.string({
            when: {
              fields: ['zipPostalCode', 'city'],
              is: (zipPostalCode, city) => !!zipPostalCode || !!city,
              then: {
                required: true,
              },
            },
          }),
          city: commonValidationSchemas.string({
            when: {
              fields: ['zipPostalCode'],
              is: (zipPostalCode) => !!zipPostalCode,
              then: {
                required: true,
              },
            },
          }),
          zipPostalCode: commonValidationSchemas.string({
            when: {
              fields: ['address', 'state'],
              is: (address, state) => {
                return !!address || (!!state && state !== 'none');
              },
              then: {
                required: true,
              },
            },
          }),
          timezone: commonValidationSchemas.string(),
        },
        [
          ['address', 'zipPostalCode'],
          ['zipPostalCode', 'state'],
        ],
      ),
    },
    [
      ['address', 'loginInfo.zipPostalCode'],
      ['loginInfo.zipPostalCode', 'loginInfo.state'],
    ],
  );
