import useUser from 'utils/useUser';
import { useMemo, type FC } from 'react';

import CommentsColumn from './components/comments-col';
import CommentColumn from './components/comment-col';
import ReviewedCheckbox from './components/reviewed-checkbox';
import s from './styles.module.scss';

import type { TAddCommentFn } from 'infrastructure/hooks/rpm';
import type { IActivityData } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface CommentCellProps {
  createdAt: string;
  record: IActivityData;
  idx: number;
  addComment: TAddCommentFn;
  showReviewCol: boolean;
}

const CommentCell: FC<CommentCellProps> = ({
  idx,
  record,
  addComment,
  showReviewCol,
}) => {
  const { userType } = useUser();

  const isReviewed = useMemo(() => {
    const reviewedByUserType = record.comments.find(
      (c) => c.reviewed,
    )?.userType;
    return reviewedByUserType === userType && record.reviewed;
  }, [record.comments.length]);

  return (
    <div className={s['comments-cell']}>
      <div className={s['comments-wrapper']}>
        <CommentsColumn
          comments={record.comments}
          patientGuid={record.patient.guid}
        />
        <CommentColumn
          addComment={addComment}
          deviceId={record.deviceId}
          readingId={record.id}
          idx={idx}
          key={`comment-col-${idx}`}
          createdAt={record.createdAt}
          hideAiButton={showReviewCol}
          currentInteractionStatus={record.patient.interactionStatus}
          patientGuid={record.patient.guid}
        />
        {showReviewCol && (
          <div className={s.reviewed}>
            <ReviewedCheckbox
              key={record.id + record.deviceId}
              addComment={addComment}
              deviceId={record.deviceId}
              readingId={record.id}
              idx={idx}
              currentInteractionStatus={record.patient.interactionStatus}
              isReviewed={isReviewed}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default CommentCell;
