import { DateFormats } from 'infrastructure/enums';
import { DateFormatter, openInNewTab } from 'infrastructure/functions';
import { useTranslation } from 'react-i18next';
import { fullNamePresenter } from 'utils';
import PhoneFilledIcon from 'components/atoms/icons/phone-filled.svg?react';
import CrossButtonIcon from 'components/atoms/icons/cross-button.svg?react';
import WaitingCallIcon from 'components/atoms/icons/waiting-call.svg?react';
import PlayIcon from 'components/atoms/icons/play.svg?react';
import AmazonConnectIcon from 'components/atoms/icons/amazon-connect';
import { useComment } from 'infrastructure/hooks/use-comment';
import classNames from 'classnames';
import ChatDotsIcon from 'components/atoms/icons/chat-dots';
import { useState, type FC } from 'react';
import CollapseAnimation from 'components/atoms/base-animations/collapse';
import BaseLoader from 'components/atoms/base-loader';

import s from './styles.module.scss';

import type {
  IActivityComments,
  ICallComments,
} from 'infrastructure/interfaces/i-rpm-patient-activity';

interface CallItemProps {
  comment: IActivityComments;
  patientGuid: string;
}

const CallItem: FC<CallItemProps> = ({ comment, patientGuid }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { loading, getAudioCommentUrl } = useComment({
    patientGuid,
  });
  const isCallCommentAction = comment.callComments?.some((c) => c.newAction);

  const toggleOpen = () => setOpen((prev) => !prev);
  const hasAudio = !!comment.duration;

  const openCall = async () => {
    if (!comment.message || !hasAudio) return;
    const res = await getAudioCommentUrl(comment.message);
    if (res) openInNewTab(res.url);
  };

  const trackIcon = hasAudio ? (
    <PlayIcon width={24} height={24} />
  ) : (
    <CrossButtonIcon width={24} height={24} />
  );

  const commentFooter = (
    comt: ICallComments | IActivityComments,
    isCall?: boolean,
  ) => (
    <div className={s['call-item-footer']} data-cy="call-comment">
      <p className={s['call-item-signed-by']}>
        <span>
          {isCall ? t('labels.callBy') : t('labels.electronicallySignedBy')}:
        </span>
        <span>
          {fullNamePresenter({
            firstName: comt?.createdByFirstName,
            lastName: comt?.createdByLastName,
            title: comt?.createdByTitle,
          })}
        </span>
      </p>
      <p className={s['call-item-date']}>
        {DateFormatter({
          date: comt?.createdAt ?? '',
          format: DateFormats['MM-dd-yyyy | h:mm a'],
        })}
      </p>
    </div>
  );

  const callBody = (
    <div className={s['call-item-body']}>
      <button
        onClick={openCall}
        className={s['call-item-body-btn']}
        disabled={!hasAudio || loading}
      >
        {loading ? <BaseLoader loading width={18} height={18} /> : trackIcon}
        <p className={s['call-item-body-message']}>
          {hasAudio ? comment.message : t('labels.attemptedToCall')}
        </p>
      </button>
    </div>
  );

  const waitingCallBody = (
    <div className={s['call-item-body-waiting']}>
      <WaitingCallIcon width={24} height={24} />
      <p>{t('labels.waitingForCallDetails')}...</p>
    </div>
  );

  return (
    <li
      className={s['call-item']}
      key={comment.guid}
      data-new-action={comment?.newAction}
    >
      <div className={s['call-item-wrapper']}>
        <div className={s['call-item-header']}>
          <p
            className={classNames(s['call-item-title'], {
              [s.red]: !hasAudio && !comment.hidden,
            })}
          >
            <PhoneFilledIcon width={16} height={16} /> {t('labels.call')}
          </p>
          <AmazonConnectIcon width="24" height="24" />
        </div>
        {comment.hidden ? waitingCallBody : callBody}
        {commentFooter(comment, true)}
        {!!comment.callComments?.length && (
          <>
            <button
              className={s['call-item-comments-btn']}
              onClick={toggleOpen}
              data-new-action={isCallCommentAction}
            >
              <ChatDotsIcon />
              {`${comment.callComments.length} ${t('labels.callComments')}`}
            </button>
            <CollapseAnimation opened={open}>
              <ul className={s['call-item-comments-list']}>
                {comment.callComments?.map((callComment) => (
                  <li key={callComment.callId}>
                    {callComment.message}
                    {commentFooter(callComment)}
                  </li>
                ))}
              </ul>
            </CollapseAnimation>
          </>
        )}
      </div>
    </li>
  );
};
export default CallItem;
