import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  forms,
  allRequiredEscalationForm,
} from 'constants/clinicalEscalationForm';
import DraggableModal from 'components/organisms/draggableModal/draggableModal';
import Section from 'components/templates/ccm/patientDetails/components/narrativeTemplates/components/Section';
import Button from 'components/atoms/button';
import { useRunbookLog } from 'infrastructure/hooks/clinic/use-runbook-logs';

const ClinicEscalationFormModal = ({
  isOpen,
  closeModal,
  onSubmit,
  clinicGuid,
  defaultValue = {},
  submitLoading,
}) => {
  const { runbookLogs, loading, loadRunbookLogs, meta } = useRunbookLog({
    meta: {
      items: 1,
    },
  });

  const [modalValue, setModalValue] = useState(defaultValue);
  const setValue = (value) => {
    setModalValue((prev) => ({ ...prev, ...value }));
  };

  const loadData = async (pageParam) => {
    await loadRunbookLogs(
      {
        ...meta,
        page: pageParam,
        clinicGuid,
      },
      true,
    );
  };

  useEffect(() => {
    if (clinicGuid) loadData(1);
  }, []);

  useEffect(() => {
    if (runbookLogs.length > 0) {
      const contactMethod = runbookLogs[0]?.contactMethod;
      const phoneOrEmailOfPersonYouAreCalling =
        runbookLogs[0]?.contactMethodValue;
      const patientContactMethod = runbookLogs[0]?.contactMethod;
      const contactMethodValue = runbookLogs[0]?.contactMethodValue;
      const criticalBusinessHours = runbookLogs[0]?.criticalBusinessHours;
      const criticalAfterHours = runbookLogs[0]?.criticalAfterHours;
      const afterHours = runbookLogs[0]?.afterHours;
      const businessHours = runbookLogs[0]?.businessHours;
      const notes = runbookLogs[0]?.notes;
      const dateNoteLogged = runbookLogs[0]?.dateNoteLogged;
      setModalValue({
        ...defaultValue,
        contactMethod,
        phoneOrEmailOfPersonYouAreCalling,
        patientContactMethod,
        contactMethodValue,
        criticalBusinessHours,
        criticalAfterHours,
        afterHours,
        businessHours,
        notes,
        dateNoteLogged,
      });
    }
  }, [runbookLogs]);

  const disableSaveButton = useMemo(
    () =>
      allRequiredEscalationForm.some((formId) => {
        const value = modalValue[formId];
        if (Array.isArray(value) && !value.length) {
          return true;
        }
        if (!value) {
          return true;
        }
        return false;
      }),
    [modalValue],
  );

  const submit = () => {
    const values = {};
    Object.keys(modalValue).forEach((key) => {
      if (Array.isArray(modalValue[key])) {
        values[key] = modalValue[key].map((item) => item.value);
      } else {
        values[key] = modalValue[key];
      }
    });
    onSubmit(values);
  };

  return (
    <DraggableModal
      isOpen={isOpen}
      toggle={closeModal}
      title="Clinical Escalation"
      styleName="popup narrative-popup"
    >
      <div className="input-wrapper">
        <span>
          This form is used for all clinics with the exception of WATSON
          escalations must go through the Care Link application found in the run
          book.
        </span>
      </div>
      <Section forms={forms} setValue={setValue} values={modalValue} />
      <Button
        handleClick={submit}
        disabled={disableSaveButton || submitLoading || loading}
        text={submitLoading ? 'Saving...' : 'Save'}
      />
    </DraggableModal>
  );
};

ClinicEscalationFormModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  defaultValue: PropTypes.object,
  clinicGuid: PropTypes.string,
  submitLoading: PropTypes.bool,
};

export default ClinicEscalationFormModal;
