import React from 'react';

const ActivityHeader = () => (
  <thead>
    <tr>
      <th>Patient GUID</th>
      <th>MRN</th>
      <th>Full Name</th>
      <th>DOB</th>
      <th>Primary Phone</th>
      <th>Secondary Phone</th>
      <th>Preferred Contact Method</th>
      <th>Patient Status</th>
      <th>Interaction Status</th>
      <th>Interaction Status Change Date</th>
      <th>Clinic Name</th>
      <th>Clinic GUID</th>
      <th>Provider Name</th>
      <th>Provider GUID</th>
    </tr>
  </thead>
);

export default ActivityHeader;
