import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import forms from 'constants/forms';
import Input from 'components/atoms/input';

const Contact = ({ errors }) => {
  const contact = [...forms.adminPanelsAgency.agencyEdit.contact];

  return (
    <div className="admin-patient-form-block">
      <span>Contact</span>
      <Row>
        {contact.map((field) => (
          <Col lg="6" md="6" sm="6" xs="12" key={field.id}>
            <Input {...field} disabled={false} />
            {errors[field.id] ? (
              <div className="input-error">
                {errors[field.id].split('.')[0]}
              </div>
            ) : null}
          </Col>
        ))}
      </Row>
    </div>
  );
};

Contact.propTypes = {
  errors: PropTypes.shape({
    agencyGuid: PropTypes.string,
  }).isRequired,
};

export default Contact;
