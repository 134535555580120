import dataService from 'services/dataService';
import { API_CLINICS } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';
import { useTranslation } from 'react-i18next';
import httpCodes from 'constants/httpCodes';
import QueryParams from 'utils/queryParams';

import BaseAutocomplete from './base';

import type { DefaultOptionType } from 'antd/es/select';
import type { Option } from './base';
import type { FC } from 'react';

const generateLabel = (item: any) => item.legalname;

const fetchClinicsList = async ({
  search,
  items,
  page,
}: {
  search: string;
  page: number;
  items: number;
}): Promise<{ count: number; data: Option[] } | undefined> => {
  const emptyResult = {
    data: [],
    count: 0,
  };
  const url = QueryParams.stringifyUrl(API_CLINICS, {
    items,
    page,
    legalname: search,
    showOnInteractiveTimeDashboard: true,
  });
  // right now clinics api does not support pagination
  // TODO: add pagination support for api and refactor this
  // interface ClinicsResponse {
  //   data: any[];
  //   count: number;
  // }
  const { data, error, status } = await dataService.getList<any[]>(url);

  if (status === httpCodes.notFount) {
    return emptyResult;
  }
  if (error) {
    showResult(error);
  }
  if (!data || error) return emptyResult;

  const values: Option[] =
    data.map<Option>((item) => ({
      label: generateLabel(item),
      value: item.guid,
      parentClinic: item.childClinics.length > 0,
    })) || [];

  return {
    data: values,
    count: values.length,
  };
};

interface Props {
  value?: Option[];
  onChange: (value: Option[], options: DefaultOptionType[]) => void;
  showSelectAll?: boolean;
}

const ClinicsAutocomplete: FC<Props> = ({ onChange, value, showSelectAll }) => {
  const { t } = useTranslation();
  return (
    <BaseAutocomplete
      label={t('labels.clinic')}
      placeholder={t('labels.all')}
      mode="multiple"
      fetchOptions={fetchClinicsList}
      value={value}
      onChange={(newValues, option) => {
        const items = Array.isArray(newValues) ? newValues : [newValues];
        const options = Array.isArray(option) ? option : [option];
        onChange(items, options);
      }}
      showSelectAll={showSelectAll}
    />
  );
};

export default ClinicsAutocomplete;
