const minToSec = (min: number) => min * 60;

export const interactiveTimeGroupsOptions = [
  { value: '=0', label: '0 Mins' },
  { value: `1-${minToSec(10) - 1}`, label: '0-9 Mins' },
  { value: `${minToSec(10)}-${minToSec(15) - 1}`, label: '10-14 Mins' },
  { value: `${minToSec(15)}-${minToSec(20) - 1}`, label: '15-19 Mins' },
  { value: `${minToSec(20)}-${minToSec(25) - 1}`, label: '20-24 Mins' },
  { value: `${minToSec(25)}-${minToSec(30) - 1}`, label: '25-29 Mins' },
  { value: `${minToSec(30)}-${minToSec(35) - 1}`, label: '30-34 Mins' },
  { value: `${minToSec(35)}-${minToSec(40) - 1}`, label: '35-39 Mins' },
  { value: `${minToSec(40)}-${minToSec(45) - 1}`, label: '40-44 Mins' },
  { value: `${minToSec(45)}-${minToSec(50) - 1}`, label: '45-49 Mins' },
  { value: `${minToSec(50)}-${minToSec(55) - 1}`, label: '50-54 Mins' },
  { value: `${minToSec(55)}-${minToSec(60) - 1}`, label: '55-59 Mins' },
  { value: `>=${minToSec(60)}`, label: '>=60 Mins' },
];

const mapper = [
  [10, '0-9 Mins'],
  [15, '10-14 Mins'],
  [20, '15-19 Mins'],
  [25, '20-24 Mins'],
  [30, '25-29 Mins'],
  [35, '30-34 Mins'],
  [40, '35-39 Mins'],
  [45, '40-44 Mins'],
  [50, '45-49 Mins'],
  [55, '50-54 Mins'],
  [60, '55-59 Mins'],
] as const;

export const interactiveTimeGroupPresenter = (seconds: number): string => {
  if (seconds === 0) return '0 Mins';
  const minutes = Math.floor(seconds / 60);

  const group = mapper.find(([max]) => {
    return minutes < max;
  });

  return group?.[1] ?? '>=60 Mins';
};

export const availableBillableMinutesToUnregisterReadings = (value: string) => {
  const number = parseInt(value, 10);
  if (Number.isNaN(number)) return null;
  return (number * 60) / 130;
};

export const readingsToBillableSeconds = (readingCount: number) => {
  const SECONDS_PER_READING = 130;
  return readingCount * SECONDS_PER_READING;
};
