import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';

import type { IClinicPaginatedData } from 'infrastructure/interfaces';

const aminPanelSearchClinicFn = async (searchValue: string) => {
  const { data, error } = await dataService.getList<IClinicPaginatedData>(
    apiUrlGenerator('admins/admin-panel/clinics', {
      legalname: searchValue,
    }),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  const preparedData =
    data?.items?.map((clinic) => ({
      value: clinic.guid,
      label: clinic.legalname,
    })) ?? [];

  if (data) {
    return { items: preparedData, count: data.count };
  }
};

export const useAminPanelSearchClinics = () => {
  const { mutate, data, isLoading, ...other } = useMutation({
    mutationFn: aminPanelSearchClinicFn,
  });

  return {
    searchClinic: mutate,
    clinics: data,
    searchClinicLoading: isLoading,
    ...other,
  };
};
