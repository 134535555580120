import React from 'react';
import PropTypes from 'prop-types';

import CommentItem from '../commentItem';
import CallComment from '../callCommentItem';
import ReviewedItem from '../reviewedItem';

const ActivityComments = ({ comments, patientGuid }) => {
  const actionType = (comment) => {
    if (comment.reviewed) {
      return <ReviewedItem key={comment.guid} comment={comment} />;
    }
    if (comment.type === 'text') {
      return <CommentItem key={comment.guid} comment={comment} />;
    }
    if (comment.type === 'call') {
      return (
        <CallComment
          key={comment.guid}
          comment={comment}
          patientGuid={patientGuid}
        />
      );
    }
  };

  return (
    <div className="activity-comments">
      {comments
        .filter((comment) => comment.message || comment.reviewed)
        .map((comment) => actionType(comment))}
    </div>
  );
};

ActivityComments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  patientGuid: PropTypes.string,
};

export default ActivityComments;
