import React from 'react';
import { PropTypes } from 'prop-types';

const MessageIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25281 3.46803C6.81726 -0.355162 11.5959 -1.12882 14.649 1.70504C17.702 4.5389 17.8643 12.3602 14.649 15.8089C11.4337 19.2576 4.023 18.1512 1.49955 15.8089C-1.0239 13.4667 -0.311651 7.29121 3.25281 3.46803Z"
      // eslint-disable-next-line react/destructuring-assignment
      fill={props.color}
      {...props}
    />
    <path
      d="M12.7727 12.3987V13.1986C12.7727 13.9111 11.8914 14.268 11.376 13.7641L9.97928 12.3987H6.22727C5.54947 12.3987 5 11.8616 5 11.199V7.19976C5 6.53715 5.54947 6 6.22727 6H12.7727C13.4505 6 14 6.53715 14 7.19976V11.199C14 11.8616 13.4505 12.3987 12.7727 12.3987Z"
      fill="white"
    />
  </svg>
);

MessageIcon.propTypes = {
  color: PropTypes.string,
};

MessageIcon.defaultProps = {
  color: '#FB9318',
};

export default MessageIcon;
