import queryString from 'query-string';
import { pickBy, identity } from 'lodash';

import {
  API_ADMIN_PANEL_CLINICS,
  API_CLINICS,
  API_CLINICS_RUNBOOK_LOGS,
} from './constants';

export const apiClinicThreshold = (guid) => `core/clinics/${guid}/thresholds`;
export const apiGetClinicsByFilter = (filter) =>
  `${API_CLINICS}?${queryString.stringify(filter)}`;
export const apiGetClinicByGuid = (guid) => `core/clinics/${guid}`;

export const apiAdminPanelClinicsGet = (items, page, noLimit, filters) => {
  const endpoint = API_ADMIN_PANEL_CLINICS;
  const query = queryString.stringify(
    pickBy(
      {
        guid: filters?.guid,
        status: filters?.status,
        agencyGuid: filters?.agencyGuid,
        noLimit,
        page,
        items,
      },
      identity,
    ),
  );
  return `${endpoint}?${query}`;
};

export const apiAdminPanelGetClinicsByFilter = (filters) => {
  const endpoint = API_ADMIN_PANEL_CLINICS;
  const { legalname, status, agencyGuid } = filters;
  const query = queryString.stringify(
    pickBy(
      {
        legalname,
        status,
        agencyGuid,
      },
      identity,
    ),
  );
  return `${endpoint}?${query}`;
};

export const apiGetRunBookLogs = (guidValue, items, page, reverse) => {
  const endpoint = API_CLINICS_RUNBOOK_LOGS;
  const query = queryString.stringify(
    pickBy({
      guidValue,
      reverse,
      page,
      items,
    }),
  );
  return `${endpoint}?${query}`;
};
