import { prepareDoctorName } from 'infrastructure/functions';

import { Login } from '../login';

import type {
  CcmStatuses,
  ClaimStatus,
  ContactMethods,
  Genders,
  MaritalStatuses,
  NumberTypes,
  RpmStatuses,
  Races,
  WeekMealStatuses,
  WorkingStatuses,
} from 'infrastructure/enums';
import type {
  IPatient,
  IPatientHoldingStatus,
} from 'infrastructure/interfaces';
import type { Nullable } from 'infrastructure/types';

export class Patient {
  guid: string;
  loginGuid: string;
  clinicGuid?: string;
  doctorGuid?: string;
  createdAt: string;
  address: Nullable<string>;
  ccmStatus: CcmStatuses;
  claimStatus: ClaimStatus;
  ccmClaimStatus: ClaimStatus;
  emergencyContactFullName: string;
  emergencyContactFirstName: Nullable<string>;
  emergencyContactLastName: Nullable<string>;
  emergencyContactRelationship: Nullable<string>;
  emergencyContactNumber: Nullable<string>;
  emergencyContactNumberType: Nullable<NumberTypes>;
  ethnicity: Nullable<string>;
  gender: Nullable<Genders>;
  martialStatus: Nullable<MaritalStatuses>;
  status: RpmStatuses;
  deviceOrderSpecifications: Nullable<string>;
  pcmStatus: boolean;
  preferredContactMethod: Nullable<ContactMethods>;
  secondaryNumberType: Nullable<NumberTypes>;
  race: Nullable<Races>;
  workingStatus: Nullable<WorkingStatuses>;
  loginInfo?: Login;
  holdingStatus?: IPatientHoldingStatus;
  emailSubscription?: boolean;
  doNotCall?: boolean;
  homeNumber: Nullable<string>;
  weekMealStatus?: WeekMealStatuses;
  interactionStatus?: boolean;
  ccmInteractionStatus?: boolean;
  acceptPvrLite?: boolean;
  ccmConsentFormStatus?: boolean;
  disabledCreateInternalAdmissionForms?: boolean;
  rpmSmsSubscription?: boolean;
  ccmSmsSubscription?: boolean;
  adherenceSmsSubscription?: boolean;
  appointmentsFlagActive?: boolean;
  ccmHoldingStatus?: boolean;
  ccmHoldingStatusStartDate?: string;
  consumablesArrivingDate?: string | null;

  constructor(data: IPatient) {
    this.guid = data.guid;
    this.createdAt = data.createdAt;
    this.loginGuid = data.loginGuid;
    this.clinicGuid = data.clinicGuid;
    this.doctorGuid = data.doctorGuid;
    this.address = data.address;
    this.ccmStatus = data.ccmStatus;
    this.claimStatus = data.claimStatus;
    this.ccmClaimStatus = data.ccmClaimStatus;
    this.emergencyContactFullName = prepareDoctorName({
      firstName: data.emergencyContactFirstName,
      lastName: data.emergencyContactLastName,
    });
    this.emergencyContactFirstName = data.emergencyContactFirstName;
    this.emergencyContactLastName = data.emergencyContactLastName;
    this.emergencyContactRelationship = data.emergencyContactRelationship;
    this.emergencyContactNumber = data.emergencyContactNumber;
    this.emergencyContactNumberType = data.emergencyContactNumberType;
    this.ethnicity = data.ethnicity;
    this.gender = data.gender;
    this.martialStatus = data.martialStatus;
    this.status = data.status;
    this.deviceOrderSpecifications = data.deviceOrderSpecifications;
    this.pcmStatus = data.pcmStatus;
    this.preferredContactMethod = data.preferredContactMethod;
    this.secondaryNumberType = data.secondaryNumberType;
    this.race = data.race;
    this.workingStatus = data.workingStatus;
    this.loginInfo = data.loginInfo ? new Login(data.loginInfo) : undefined;
    this.holdingStatus = data?.holdingStatus;
    this.emailSubscription = data?.emailSubscription;
    this.doNotCall = data?.doNotCall;
    this.homeNumber = data?.homeNumber;
    this.weekMealStatus = data?.weekMealStatus;
    this.interactionStatus = data?.interactionStatus;
    this.ccmInteractionStatus = data?.ccmInteractionStatus;
    this.acceptPvrLite = data?.acceptPvrLite;
    this.ccmConsentFormStatus = data?.ccmConsentFormStatus;
    this.disabledCreateInternalAdmissionForms =
      data?.disabledCreateInternalAdmissionForms;
    this.rpmSmsSubscription = data?.rpmSmsSubscription;
    this.ccmSmsSubscription = data?.ccmSmsSubscription;
    this.adherenceSmsSubscription = data?.adherenceSmsSubscription;
    this.appointmentsFlagActive = data?.appointmentsFlagActive;
    this.ccmHoldingStatus = data?.ccmHoldingStatus;
    this.ccmHoldingStatusStartDate = data?.ccmHoldingStatusStartDate;
    this.consumablesArrivingDate = data?.consumablesArrivingDate;
  }

  static prepareToUpdate(data: Patient): IPatient {
    let [emergencyContactFirstName, emergencyContactLastName] = ['', ''];

    if (data.emergencyContactFullName) {
      [emergencyContactFirstName, emergencyContactLastName] =
        data.emergencyContactFullName.trim().split(' ');
    }

    return {
      guid: data.guid,
      createdAt: data.createdAt,
      loginGuid: data.loginGuid,
      clinicGuid: data.clinicGuid,
      doctorGuid: data.doctorGuid,
      address: data.address,
      ccmStatus: data.ccmStatus,
      claimStatus: data.claimStatus,
      ccmClaimStatus: data.ccmClaimStatus,
      emergencyContactFirstName:
        emergencyContactFirstName === '' ? null : emergencyContactFirstName,
      emergencyContactLastName:
        emergencyContactLastName === '' ? null : emergencyContactLastName,
      emergencyContactRelationship: data.emergencyContactRelationship,
      emergencyContactNumber: data.emergencyContactNumber,
      emergencyContactNumberType: data.emergencyContactNumberType,
      ethnicity: data.ethnicity,
      gender: data.gender,
      martialStatus: data.martialStatus,
      status: data.status,
      deviceOrderSpecifications: data.deviceOrderSpecifications,
      pcmStatus: data.pcmStatus,
      preferredContactMethod: data.preferredContactMethod,
      secondaryNumberType: data.secondaryNumberType,
      race: data.race,
      workingStatus: data.workingStatus,
      loginInfo: data.loginInfo
        ? Login.prepareToUpdate(data.loginInfo)
        : undefined,
      holdingStatus: data?.holdingStatus,
      emailSubscription: data?.emailSubscription,
      doNotCall: data?.doNotCall,
      homeNumber: data?.homeNumber,
      weekMealStatus: data?.weekMealStatus,
      interactionStatus: data?.interactionStatus,
      ccmInteractionStatus: data?.ccmInteractionStatus,
      acceptPvrLite: data?.acceptPvrLite,
      ccmConsentFormStatus: data?.ccmConsentFormStatus,
      disabledCreateInternalAdmissionForms:
        data?.disabledCreateInternalAdmissionForms,
      rpmSmsSubscription: data?.rpmSmsSubscription,
      ccmSmsSubscription: data?.ccmSmsSubscription,
      adherenceSmsSubscription: data?.adherenceSmsSubscription,
      appointmentsFlagActive: data?.appointmentsFlagActive,
      ccmHoldingStatus: data?.ccmHoldingStatus,
      ccmHoldingStatusStartDate: data?.ccmHoldingStatusStartDate,
      consumablesArrivingDate: data?.consumablesArrivingDate,
    };
  }
}
