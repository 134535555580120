import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { arrUniqEl } from 'infrastructure/functions';
import { AgencySelect } from 'infrastructure/classes/select/agency-select';

import useApi from '../use-api';

import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IAgencySelectPaginatedData,
} from 'infrastructure/interfaces';

const getAgenciesApi = 'core/agencies/select-options';

export interface IUseAgencyMeta {
  search?: string;
  guid?: string;
}

type MergedMeta = IUseAgencyMeta & IPaginationMeta;

type UseAgency = {
  loading: boolean;
  meta: MergedMeta;
  loadAgencies: (meta: MergedMeta) => Promise<AgencySelect[]>;
};

interface IUseAgencySelectProps {
  meta?: IUseAgencyMeta & IPartialPaginationMeta;
}

export const useAgencySelect = (props?: IUseAgencySelectProps): UseAgency => {
  const { meta: metaProps = {} } = props || {};
  const { loading, loadData, isMounted } = useApi();
  const queryClient = useQueryClient();

  const [meta, setMeta] = useState<MergedMeta>({
    page: 1,
    items: 100,
    totalCount: 0,
    ...metaProps,
  });

  const loadAgencies = async (filters: MergedMeta) => {
    const queryKey = [
      getAgenciesApi,
      {
        page: filters.page,
        search: filters.search,
        guid: filters.guid,
      },
    ];

    const data = await queryClient.fetchQuery(
      queryKey,
      () =>
        loadData<IAgencySelectPaginatedData, Omit<IUseAgencyMeta, 'sortField'>>(
          getAgenciesApi,
          {
            sortField: JSON.stringify(filters.sortField),
            page: filters.page,
            items: filters.items,
            guid: filters.guid,
            search: filters.search,
          },
        ),
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      const newMeta = {
        ...meta,
        ...filters,
        totalCount: data.count,
      };

      setMeta(newMeta);

      return arrUniqEl(data.items, 'guid').map((el) => new AgencySelect(el));
    }

    return [];
  };

  return {
    loading,
    meta,
    loadAgencies,
  };
};
