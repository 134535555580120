import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import common from 'constants/common';
import { getPatientPcmStatus } from 'utils';

const statusMapper = {
  ccm_calling_queue: 'CCM Calling Queue',
  ccm_patient_refused_no_contact: 'CCM Patient Refused',
  ccm_patient_refused_wrong_contact_information: 'CCM Patient Refused',
  ccm_patient_refused_copay_insurance: 'CCM Patient Refused',
  ccm_patient_refused_expired: 'CCM Patient Refused',
  ccm_patient_refused_changed_doctor: 'CCM Patient Refused',
  ccm_patient_refused_not_interested_not_necessary_suspect_scam:
    'CCM Patient Refused',
  ccm_patient_refused_wants_to_discuss_with_physician: 'CCM Patient Refused',
  ccm_patient_refused_other: 'CCM Patient Refused',
  ccm_referral_received: 'CCM Referral Received',
  ccm_referral_on_hold: 'CCM Referral On-Hold',
  ccm_referral_withdrawn: 'CCM Referral Withdrawn',
  ccm_patient_no_contact: 'CCM Patient No Contact',
  ccm_onboarding: 'CCM Onboarding',
  ccm_onboarding_unsuccessful_no_contact: 'CCM Onboarding Unsuccessful',
  ccm_onboarding_unsuccessful_patient_refused: 'CCM Onboarding Unsuccessful',
  ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm:
    'CCM Onboarding Unsuccessful',
  ccm_onboarding_unsuccessful_changed_doctor: 'CCM Onboarding Unsuccessful',
  ccm_onboarding_unsuccessful_other: 'CCM Onboarding Unsuccessful',
};

const PatientRow = ({ patient }) => {
  const {
    connect: { handleConnectPopoutOpen, connectDetails },
  } = useDispatch();
  const onClickConnect = (isRpmCall) => {
    const details = {
      phone: patient.phone,
      guid: patient.guid,
      isRpmCall,
    };
    connectDetails(details);
    handleConnectPopoutOpen(false);
  };
  return (
    <tr className="activity-row">
      <td data-label="Patient Name" className="text-nowrap">
        <strong>{patient.name}</strong>
      </td>
      <td data-label="MRN">{patient.mrn}</td>
      <td data-label="DOB" className="text-nowrap">
        {patient.dob}
      </td>
      <td data-label="Phone Number">
        <div
          onClick={() => onClickConnect(true)}
          className="activity-row-number"
        >
          <strong onClick={() => onClickConnect(true)}>{patient.phone}</strong>
        </div>
      </td>
      <td data-label="Clinic Name">{patient.clinic}</td>
      <td data-label="Provider">{patient.primaryDoctor}</td>
      <td data-label="Provider Email">{patient.practiceEmail}</td>
      <td data-label="Insurance Type">{patient.insType}</td>
      <td data-label="Insurance Provider">{patient.insProvider}</td>
      <td data-label="Insurance Number">{patient.insNumber}</td>
      <td
        data-label="CCM Status"
        style={{
          backgroundColor:
            common.ccmStatusNames[statusMapper[patient.ccmStatus]]?.color,
          borderBottom: '1px solid #E8E8E8',
          color: '#FFF',
        }}
        className={patient.ccmStatus}
      >
        {common.ccmStatus[patient.ccmStatus]?.label}
      </td>
      <td
        data-label="RPM Status"
        style={{
          backgroundColor: common.statusPatients[patient.status]?.color,
          borderBottom: '1px solid #E8E8E8',
          color: '#FFF',
        }}
      >
        {common.statusPatients[patient.status]?.label}
      </td>
      <td data-label="PCM Status">{getPatientPcmStatus(patient.pcmStatus)}</td>
    </tr>
  );
};

PatientRow.propTypes = {
  patient: PropTypes.shape().isRequired,
};

export default PatientRow;
