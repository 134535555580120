import type { FC, SVGProps } from 'react';

const DefaultEmptyTableIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.57917 7.74072L1.50006 6.00024C1.50006 3.79111 3.29092 2.00024 5.50006 2.00024H12.8432C13.9041 2.00024 14.9215 2.42167 15.6716 3.17182L17.3285 4.82867C18.0786 5.57882 19.0961 6.00024 20.1569 6.00024H28.1199C30.4721 6.00024 32.3165 8.01992 32.1035 10.3624L30.8308 24.3624C30.6435 26.4227 28.916 28.0002 26.8472 28.0002H6.15294C4.08412 28.0002 2.35666 26.4227 2.16936 24.3624L0.896636 10.3624C0.808525 9.39317 1.07263 8.47922 1.57917 7.74072ZM4.88021 8.00024C3.70415 8.00024 2.78195 9.01008 2.88842 10.1813L4.16115 24.1813C4.2548 25.2115 5.11853 26.0002 6.15294 26.0002H26.8472C27.8816 26.0002 28.7454 25.2115 28.839 24.1813L30.1117 10.1813C30.2182 9.01008 29.296 8.00024 28.1199 8.00024H4.88021ZM14.2574 4.58603C13.8823 4.21096 13.3736 4.00024 12.8432 4.00024H5.50006C4.4081 4.00024 3.52053 4.87534 3.50041 5.96249L3.513 6.23953C3.93861 6.08486 4.39877 6.00024 4.88021 6.00024H15.6716L14.2574 4.58603Z"
        fill="#667180"
      />
    </svg>
  );
};

export default DefaultEmptyTableIcon;
