import React from 'react';

import type { SVGProps } from 'react';

const ControlPanelFilesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8422 4.62403C9.87437 -0.473549 16.6207 -1.50509 20.9309 2.27339C25.2411 6.05187 25.4701 16.4803 20.9309 21.0786C16.3916 25.6768 5.92952 24.2016 2.36701 21.0786C-1.1955 17.9555 -0.189978 9.72162 4.8422 4.62403Z"
      fill="#FB9318"
    />
    <path
      d="M16 6H10.75C9.92157 6 9.25 6.67157 9.25 7.5C8.42157 7.5 7.75 8.17157 7.75 9V16.5C7.75 17.3284 8.42157 18 9.25 18H14.5C15.3284 18 16 17.3284 16 16.5C16.8284 16.5 17.5 15.8284 17.5 15V7.5C17.5 6.67157 16.8284 6 16 6ZM16 15.75V9C16 8.17157 15.3284 7.5 14.5 7.5H10C10 7.08579 10.3358 6.75 10.75 6.75H16C16.4142 6.75 16.75 7.08579 16.75 7.5V15C16.75 15.4142 16.4142 15.75 16 15.75ZM8.5 9C8.5 8.58579 8.83579 8.25 9.25 8.25H14.5C14.9142 8.25 15.25 8.58579 15.25 9V16.5C15.25 16.9142 14.9142 17.25 14.5 17.25H9.25C8.83579 17.25 8.5 16.9142 8.5 16.5V9Z"
      fill="white"
    />
  </svg>
);

export default ControlPanelFilesIcon;
