import i18next from 'i18next';

import s from './styles.module.scss';

import type { IBaseTableColumns } from 'components/atoms/base-table/types';
import type { EditorLog } from 'infrastructure/classes/logs/editor-log';

export const editorlogColumns = (): IBaseTableColumns<EditorLog> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'date',
      width: '170px',
      render(value) {
        return <span className={s.date}>{value}</span>;
      },
    },
    {
      label: i18next.t('labels.changes'),
      key: 'eventTitle',
      render(_, record) {
        return record.changes.map((el, index) => (
          <div key={index} className={s.change}>
            <span className={s.date}>{el.field}</span>
            <div className={s.info}>
              <span className={s.from}>
                {`${i18next.t('labels.from')}: ${el.from} `}
              </span>
              &#10095;
              <span className={s.to}>
                {` ${i18next.t('labels.to')}: ${el.to}`}
              </span>
            </div>
          </div>
        ));
      },
    },
    {
      label: i18next.t('labels.person'),
      key: 'person',
      width: '250px',
      render(value) {
        return <span className={s.date}>{value}</span>;
      },
    },
    {
      label: i18next.t('labels.userType'),
      key: 'userType',
      width: '100px',
      render(value) {
        return <span className={s.date}>{value}</span>;
      },
    },
  ];
};
