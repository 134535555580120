import type { FC, SVGProps } from 'react';

const EHRControlIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5922 4.62403C9.62437 -0.473549 16.3707 -1.50509 20.6809 2.27339C24.9911 6.05187 25.2201 16.4803 20.6809 21.0786C16.1416 25.6768 5.67952 24.2016 2.11701 21.0786C-1.4455 17.9555 -0.439978 9.72162 4.5922 4.62403Z"
        fill="#FB9318"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 16.125C9.75 15.9179 9.91789 15.75 10.125 15.75H15.375C15.5821 15.75 15.75 15.9179 15.75 16.125C15.75 16.3321 15.5821 16.5 15.375 16.5H10.125C9.91789 16.5 9.75 16.3321 9.75 16.125Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 13.875C6.75 13.6679 6.91789 13.5 7.125 13.5H15.375C15.5821 13.5 15.75 13.6679 15.75 13.875C15.75 14.0821 15.5821 14.25 15.375 14.25H7.125C6.91789 14.25 6.75 14.0821 6.75 13.875Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 11.625C6.75 11.4179 6.91789 11.25 7.125 11.25H15.375C15.5821 11.25 15.75 11.4179 15.75 11.625C15.75 11.8321 15.5821 12 15.375 12H7.125C6.91789 12 6.75 11.8321 6.75 11.625Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 9.375C6.75 9.16789 6.91789 9 7.125 9H15.375C15.5821 9 15.75 9.16789 15.75 9.375C15.75 9.58211 15.5821 9.75 15.375 9.75H7.125C6.91789 9.75 6.75 9.58211 6.75 9.375Z"
        fill="white"
      />
      <path
        d="M18 7.32761H17.1046V8.25H16.6408V7.32761H15.75V6.91791H16.6408V6H17.1046V6.91791H18V7.32761Z"
        fill="white"
      />
    </svg>
  );
};

export default EHRControlIcon;
