import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { useDispatch, useSelector } from 'react-redux';

import { getSmsDashboardConversationsKey } from './useConversations';
import { showResult } from '../utils';

import type {
  IConversation,
  IConversationsListPages,
} from 'infrastructure/interfaces';
import type { RootDispatch, RootState } from 'store';

interface Props {
  onSuccess?: () => void;
}

interface FnProps {
  conversationId: number;
  read: boolean;
}

const markConversationFn = async ({ conversationId, read }: FnProps) => {
  const API_MARK_READ_UNREAD = (id: number) =>
    `core/conversations/${id}/mark-read-unread`;

  const { data, error } = await dataService.createOne(
    API_MARK_READ_UNREAD(conversationId),
    {
      read,
    },
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useMarkConversation = (props: Props) => {
  const queryClient = useQueryClient();
  const { conversationType, filters, currentConversation } = useSelector(
    (state: RootState) => state.smsDashboard,
  );
  const {
    smsDashboard: { changeCurrentConversation },
  } = useDispatch<RootDispatch>();

  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: markConversationFn,
    onSuccess(data, variables) {
      const сonversationKey = getSmsDashboardConversationsKey({
        type: conversationType,
        read: filters.read,
        closed: filters.closed,
        lastMessageDate: filters.lastMessageDate,
        searchQuery: filters.searchQuery,
      });

      const conversations =
        queryClient.getQueryData<IConversationsListPages>(сonversationKey);

      const pages = conversations?.pages ?? [];
      const [firstPage, ...restPages] = pages;

      pages.forEach((page) => {
        const index = page.items.findIndex(
          (item: IConversation) => item.id === variables.conversationId,
        );
        if (index !== -1) {
          page.items[index] = {
            ...page.items[index],
            read: variables.read,
            unreadMessageCount: 0,
            closed: false,
          };
        }
      });

      const updatedPages = [firstPage, ...restPages];

      queryClient.setQueryData<IConversationsListPages>(
        сonversationKey,
        (oldConversations) => {
          if (!oldConversations) return;
          return { ...oldConversations, pages: updatedPages };
        },
      );
      if (currentConversation.id === variables.conversationId) {
        changeCurrentConversation({
          ...currentConversation,
          read: variables.read,
        });
      }
      if (props.onSuccess) {
        props.onSuccess();
      }
    },
  });

  return {
    markConversation: mutate,
    markConversationLoading: isLoading,
    ...other,
  };
};
