import React from 'react';
import PropTypes from 'prop-types';
import { format, add } from 'date-fns';

import InfoTileItem from './infoTileItem';

const beforeYesterdayDate = format(
  add(new Date(), { days: -2 }),
  'MMM dd yyyy',
);

const InfoTiles = ({ infos }) => (
  <>
    {infos && (
      <>
        <InfoTileItem
          data={infos.medicaidPatients}
          title="Medicaid Patients"
          prevDate={beforeYesterdayDate}
          dataCy="yesterdays-stats__medicaid"
        />
        <span className="info-tiles__spacer" />
        <InfoTileItem
          data={infos.medicarePatients}
          title="Medicare Patients"
          prevDate={beforeYesterdayDate}
          dataCy="yesterdays-stats__medicare"
        />
        <span className="info-tiles__spacer" />
        <InfoTileItem
          data={infos.discharges}
          title="Discharges"
          prevDate={beforeYesterdayDate}
          dataCy="yesterdays-stats__discharges"
        />
        <span className="info-tiles__spacer" />
        <InfoTileItem
          data={infos.timespanDischarges}
          title="30 Day # of Discharges"
          prevDate="VS prior 30 days"
          dataCy="yesterdays-stats__30DayDischarges"
        />
      </>
    )}
  </>
);

InfoTiles.propTypes = {
  infos: PropTypes.object,
};

export default InfoTiles;
