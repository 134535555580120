import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { FC } from 'react';

const Title: FC = () => {
  const { t } = useTranslation();

  return (
    <h3 className={s.title}>{t('interactiveTimeDashboard.reportHeader')}</h3>
  );
};

export default Title;
