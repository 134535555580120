import dataService from 'services/dataService';
import { API_PATIENT_CCM_HOLDING_STATUS } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

export interface IUpdateTempOnHoldProps {
  ccmHoldingStatus: boolean;
  patientGuid: string;
}

export const updateTempOnHoldStatusFn = async (
  postData: IUpdateTempOnHoldProps,
) => {
  const { error, data } = await dataService.createOne(
    API_PATIENT_CCM_HOLDING_STATUS,
    postData,
  );

  if (error) {
    showResult(error);
  }

  return data;
};
