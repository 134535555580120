import { Select } from 'antd';
import { useId } from 'infrastructure/hooks/utils/useId';
import { useSelectAll } from 'infrastructure/antd/hooks/use-select-all';

import s from './styles.module.scss';

import type { SelectProps } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';

export interface BaseSelectProps<Value extends DefaultOptionType = any>
  extends SelectProps<Value | Value[]> {
  options: Value[];
  label: string;
  showSelectAll?: boolean;
}

export interface Option {
  label: string;
  value: string;
}

const BaseSelect = <T extends DefaultOptionType>({
  label,
  options,
  showSelectAll,
  value,
  onChange,
  ...props
}: BaseSelectProps<T>) => {
  const id = props.id || useId('select_');

  const selectProps = useSelectAll({
    showSelectAll,
    value,
    onChange,
    options,
  });

  return (
    <div style={{ width: '100%' }}>
      <label htmlFor={id} className={s.label}>
        {label}
      </label>
      <Select
        id={id}
        style={{ width: '100%' }}
        labelInValue
        optionFilterProp="label"
        allowClear
        {...props}
        {...selectProps}
      />
    </div>
  );
};

export default BaseSelect;
