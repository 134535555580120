import type { FC, SVGProps } from 'react';

const EmptyClinicalVoiceIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.30775 2.65698C6.80686 2.01299 5.8549 1.95372 5.27801 2.53061L3.21016 4.59846C2.24321 5.56541 1.88774 6.93531 2.3102 8.13694C4.00675 12.9625 6.7843 17.4934 10.6454 21.3546C14.5066 25.2157 19.0375 27.9932 23.8631 29.6898C25.0647 30.1123 26.4346 29.7568 27.4015 28.7898L29.4694 26.722C30.0463 26.1451 29.987 25.1931 29.343 24.6923L24.7306 21.1048C24.4017 20.849 23.9733 20.7585 23.5689 20.8596L19.1908 21.9542C18.0016 22.2515 16.7437 21.903 15.8769 21.0363L10.9637 16.1231C10.097 15.2563 9.74855 13.9984 10.0458 12.8092L11.1404 8.43105C11.2415 8.02671 11.151 7.59835 10.8952 7.26936L7.30775 2.65698ZM3.76955 1.02215C5.25377 -0.462077 7.70299 -0.309594 8.99166 1.34727L12.5791 5.95965C13.2374 6.80608 13.47 7.90817 13.21 8.94845L12.1154 13.3266C11.9999 13.7888 12.1353 14.2777 12.4722 14.6146L17.3854 19.5278C17.7223 19.8647 18.2112 20.0001 18.6734 19.8846L23.0515 18.79C24.0918 18.53 25.1939 18.7626 26.0403 19.4209L30.6527 23.0083C32.3096 24.297 32.4621 26.7462 30.9778 28.2305L28.91 30.2983C27.4306 31.7777 25.2178 32.4274 23.1555 31.7023C18.0351 29.9021 13.2288 26.9548 9.13698 22.863C5.04519 18.7712 2.0979 13.9649 0.297676 8.8445C-0.427365 6.78225 0.22226 4.56944 1.7017 3.09L3.76955 1.02215Z"
        fill="#667180"
      />
    </svg>
  );
};

export default EmptyClinicalVoiceIcon;
