import BaseSelect from 'components/atoms/baseSelect';
import Button from 'components/atoms/button';
import languages from 'constants/languages';
import { useMemo, type FC } from 'react';
import { Col, Container, Card, Row } from 'reactstrap';
import { fullNamePresenter } from 'utils';
import useTurkProfileUpdate from 'utils/hooks/turkDashboard/useProfileUpdate';
import useUser from 'utils/useUser';
import { format } from 'date-fns';
import { parseDateSafe } from 'utils/timeHelper';

import s from './styles.module.scss';

const KeyValue: FC<{ Key: string; Value?: string | null }> = ({
  Key: key,
  Value: value,
}) => {
  if (!value) return null;
  return (
    <div className="info-value">
      <p className={s.label}>{key}:</p>
      <p>{value}</p>
    </div>
  );
};

const TurkUserProfile: FC = () => {
  const {
    firstName,
    lastName,
    title,
    email,
    guid,
    primaryLanguage,
    secondaryLanguage,
    agencies,
    professionType,
    createdAt,
  } = useUser();

  const presentedCreatedAt = useMemo(() => {
    const createdAtDate = parseDateSafe(createdAt);
    if (!createdAtDate) return null;
    return format(createdAtDate, 'MMM dd yyyy HH:mm:ss');
  }, [createdAt]);

  const name = fullNamePresenter({ firstName, lastName, title });
  const agenciesNames = agencies?.map((agency) => agency.legalname).join(', ');

  const {
    primaryLanguageValue,
    secondaryLanguageValue,
    setPrimaryLanguageValue,
    setSecondaryLanguageValue,
    onUpdate,
    isLoading,
  } = useTurkProfileUpdate({
    primaryLanguage,
    secondaryLanguage,
  });

  return (
    <Container>
      <Card className="card-border profile-page">
        <h3 className="title-section m-0">Account Data</h3>
        <Row className="m-0">
          <Col xs="12" className="info-section">
            <section className="personal-info-section">
              <p className="profile-info-label">Personal Info</p>
              <KeyValue Key="User Account GUID" Value={guid} />
              <KeyValue Key="Name" Value={name} />
              <KeyValue Key="Email Address" Value={email} />
              <div className="info-value">
                <p className={s.label}>Primary Language:</p>
                <BaseSelect
                  isDisabled={isLoading}
                  className={s.select}
                  classNamePrefix="select-type"
                  options={languages}
                  value={primaryLanguageValue}
                  onChange={(value) => {
                    setPrimaryLanguageValue(value);
                  }}
                />
              </div>
              <div className="info-value">
                <p className={s.label}>Secondary Language:</p>
                <BaseSelect
                  isDisabled={isLoading}
                  classNamePrefix="select-type"
                  className={s.select}
                  options={languages}
                  value={secondaryLanguageValue}
                  onChange={(value) => {
                    setSecondaryLanguageValue(value);
                  }}
                />
              </div>
            </section>
            <section className="personal-info-section">
              <p className="profile-info-label">Profession Information</p>
              <KeyValue Key="Agency" Value={agenciesNames} />
              <KeyValue Key="Profession" Value={professionType} />
            </section>
            <section className="contact-info-section">
              <p className="profile-info-label">Registration Details</p>
              <KeyValue Key="Registered At" Value={presentedCreatedAt} />
              <KeyValue Key="Username" Value={email} />
            </section>
          </Col>
        </Row>
        <section className="save-button-section">
          <Button
            text="Save"
            data-cy="save-button"
            handleClick={onUpdate}
            disabled={isLoading}
          />
        </section>
      </Card>
    </Container>
  );
};

export default TurkUserProfile;
