import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { forms } from 'constants/hospitalPrevention';
import DraggableModal from 'components/organisms/draggableModal/draggableModal';
import Section from 'components/templates/ccm/patientDetails/components/narrativeTemplates/components/Section';
import Button from 'components/atoms/button';
import { useRunbookLog } from 'infrastructure/hooks/clinic/use-runbook-logs';

const HospitalPreventionFormModal = ({
  isOpen,
  closeModal,
  onSubmit,
  clinicGuid,
  defaultValue = {},
  submitLoading,
}) => {
  const { runbookLogs, loading, loadRunbookLogs, meta } = useRunbookLog({
    meta: {
      items: 1,
    },
  });
  const [modalValue, setModalValue] = useState(defaultValue);
  const setValue = (value) => {
    setModalValue((prev) => ({ ...prev, ...value }));
  };

  const loadData = async (pageParam) => {
    await loadRunbookLogs(
      {
        ...meta,
        page: pageParam,
        clinicGuid,
      },
      true,
    );
  };

  useEffect(() => {
    if (clinicGuid) loadData(1);
  }, []);

  useEffect(() => {
    if (runbookLogs.length > 0) {
      const notes = runbookLogs[0]?.notes;
      const dateNoteLogged = runbookLogs[0]?.dateNoteLogged;
      const patientContactMethod = runbookLogs[0]?.contactMethod;
      const contactMethodValue = runbookLogs[0]?.contactMethodValue;
      const criticalBusinessHours = runbookLogs[0]?.criticalBusinessHours;
      const criticalAfterHours = runbookLogs[0]?.criticalAfterHours;
      const afterHours = runbookLogs[0]?.afterHours;
      const businessHours = runbookLogs[0]?.businessHours;

      setModalValue({
        ...defaultValue,
        patientContactMethod,
        contactMethodValue,
        criticalBusinessHours,
        criticalAfterHours,
        afterHours,
        businessHours,
        notes,
        dateNoteLogged,
      });
    }
  }, [runbookLogs]);

  const disableSaveButton = useMemo(() => {
    return forms.some(({ id, notRequiredIf }) => {
      let disabled = true;

      const value = modalValue[id];
      if (Array.isArray(value) && !value.length) {
        disabled = false;
      }
      if (!value) {
        disabled = false;
      }

      if (notRequiredIf) {
        Object.keys(modalValue).forEach((key) => {
          if (notRequiredIf.includes(key)) {
            const innerValue = modalValue[key];
            if (Array.isArray(innerValue) && innerValue.length) {
              disabled = true;
            }
            if (!Array.isArray(innerValue) && innerValue) {
              disabled = true;
            }
          }
        });
      }
      return !disabled;
    });
  }, [modalValue]);
  const submit = () => {
    const values = {};
    Object.keys(modalValue).forEach((key) => {
      if (Array.isArray(modalValue[key])) {
        values[key] = modalValue[key].map((item) => item.value);
      } else {
        values[key] = modalValue[key];
      }
    });
    onSubmit(values);
  };

  return (
    <DraggableModal
      isOpen={isOpen}
      toggle={closeModal}
      title="Hospitalization Prevention"
      styleName="popup narrative-popup"
    >
      <div
        className="input-wrapper"
        style={{
          margin: '0 6px',
        }}
      >
        <span>
          Please document any instance where Accuhealth prevented a
          hospitalization while following our Alert Process.
        </span>
        <br />
        <br />
        <span>
          This form will report incidents in the following format: Risk For
          ______, evidenced by _________.
        </span>
        <br />
        <strong>
          ex: "risk for infection, evidenced by inadequate vaccination and
          immunosuppression"
        </strong>
      </div>
      <Section forms={forms} setValue={setValue} values={modalValue} />
      <Button
        handleClick={submit}
        disabled={disableSaveButton || submitLoading || loading}
        text={submitLoading ? 'Saving...' : 'Save'}
      />
    </DraggableModal>
  );
};

HospitalPreventionFormModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  defaultValue: PropTypes.object,
  clinicGuid: PropTypes.string,
  submitLoading: PropTypes.bool,
};

export default HospitalPreventionFormModal;
