import { useGenAiComment } from 'infrastructure/hooks/rpm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'store';
import useUser from 'utils/useUser';

import type { TAddCommentFn } from 'infrastructure/hooks/rpm';
import type { BookmarkComment } from 'infrastructure/classes/bookmark-comment';

type TValue = { type: string; value: string }[];

interface ITemplateValues {
  compliant: TValue;
  patient: TValue;
  typeofcritical: TValue;
  symptoms: TValue;
  time: string;
  timeframe: string;
}

interface Props {
  addComment: TAddCommentFn;
  deviceId: string;
  readingId: string;
  idx: number;
  patientGuid: string;
  currentInteractionStatus?: boolean;
}

export const useCommentColumn = ({
  addComment,
  idx,
  deviceId,
  readingId,
  currentInteractionStatus,
  patientGuid,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [openBookmarkModal, setOpenBookmarkModal] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [templateValues, setTemplateValues] = useState<ITemplateValues | {}>(
    {},
  );
  const { t } = useTranslation();
  const actions = useAppSelector((state) => state.activityActions.actions[idx]);
  const {
    activityActions: { updateAction, resetCurrentActions },
  } = useAppDispatch();
  const { isPractice, isAdmin } = useUser();
  const {
    genAiComment,
    isLoading,
    data: aiData,
    isCommentGenerationLimitReached,
  } = useGenAiComment({
    patientGuid,
    tableRowIdx: idx,
  });

  const onSubmitComment = async () => {
    if (!actions?.message) return;

    setLoading(true);
    updateAction({ idx, action: { sending: true } });
    await addComment(
      {
        deviceId,
        readingId,
        message: actions.message,
        generatedByModel: aiData?.result.model,
        originalGeneratedText: aiData?.result.comment,
        currentInteractionStatus,
      },
      {
        onSuccess: () => {
          resetCurrentActions(idx);
          setTemplateValues({});
        },
        onSettled() {
          setLoading(false);
          updateAction({ idx, action: { sending: false } });
        },
      },
    );

    setLoading(false);
    updateAction({ idx, action: { sending: false } });
  };

  const disabled = loading || actions?.sending;

  const toggleOpenBookmark = () => setOpenBookmarkModal((prev) => !prev);
  const toggleOpenTemplate = () => setOpenTemplateModal((prev) => !prev);

  const onApplyBookmark = (data: BookmarkComment) => {
    updateAction({ idx, action: { message: data.message } });
    toggleOpenBookmark();
  };

  const submitCommentLockByAi =
    Boolean(actions?.message) && aiData?.result.comment === actions?.message;

  let aiButtonTitle = t('controls.aiGenerateComment');
  if (isCommentGenerationLimitReached) {
    aiButtonTitle = t('controls.aiGenerationLimitReached');
  }
  if (loading) {
    aiButtonTitle = t('controls.aiGeneratingComment');
  }
  let sendCommentButtonTitle = t('controls.submitComment');
  if (submitCommentLockByAi)
    sendCommentButtonTitle = t('controls.submitCommentLockByAi');

  return {
    openBookmarkModal,
    openTemplateModal,
    templateValues,
    showBookmarkBtn: isPractice || isAdmin,
    isLoading,
    message: actions?.message,
    disabled,
    aiButtonTitle,
    updateAction,
    isCommentGenerationLimitReached,
    loading,
    submitCommentLockByAi,
    sendCommentButtonTitle,
    setTemplateValues,
    toggleOpenBookmark,
    genAiComment,
    toggleOpenTemplate,
    onApplyBookmark,
    onSubmitComment,
  };
};
