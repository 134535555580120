import i18next from 'i18next';
import { Races } from 'infrastructure/enums';

export interface IRaceItem {
  id: Races;
  name: string;
}

export const racesDataSource = (): IRaceItem[] => {
  return [
    {
      id: Races.AmericanIndianOrAlaskaNative,
      name: i18next.t('races.american_indian_or_alaska_native'),
    },
    {
      id: Races.Asian,
      name: i18next.t('races.asian'),
    },
    {
      id: Races.BlackOrAfricanAmerican,
      name: i18next.t('races.black_or_african_american'),
    },
    {
      id: Races.HispanicOrLatino,
      name: i18next.t('races.hispanic_or_latino'),
    },
    {
      id: Races.NativeHawaiianOrOtherPacificIslander,
      name: i18next.t('races.native_hawaiian_or_other_pacific_islander'),
    },
    {
      id: Races.White,
      name: i18next.t('races.white'),
    },
    {
      id: Races.Dynamic,
      name: i18next.t('races.dynamic'),
    },
    {
      id: Races.Other,
      name: i18next.t('races.other'),
    },
  ];
};
