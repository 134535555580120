import BaseButton from 'components/atoms/baseButton';
import { useEligibilityDownload } from 'infrastructure/hooks/eligibilityTool/useEligibilityDownload';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from 'components/atoms/icons/chevronLeft';
import { useNavigate } from 'react-router-dom';
import paths from 'constants/paths';

import s from './style.module.scss';

import type { EligibilityProcess } from 'infrastructure/hooks/eligibilityTool/types';
import type { FC } from 'react';

interface Props {
  processGuid: string;
  eligibilityProcess: EligibilityProcess | undefined;
}

const EligibilityDownload: FC<Props> = ({
  processGuid,
  eligibilityProcess,
}) => {
  const { t } = useTranslation();

  const { download, isLoading } = useEligibilityDownload();
  const navigate = useNavigate();

  const getTitle = () => {
    if (!eligibilityProcess) {
      return '';
    }
    const { itemsCount, processedItemsCount, name } = eligibilityProcess;
    return `${name} (${processedItemsCount}/${itemsCount})`;
  };

  return (
    <div className={s['download-wrapper']}>
      <BaseButton
        icon={<ChevronLeftIcon />}
        type="secondary"
        height={32}
        width={32}
        onClick={() => {
          navigate(paths.eligibilityTool);
        }}
        dataCy="go-back-btn"
      />
      <p className={s.title}>{getTitle()}</p>
      <BaseButton
        onClick={() => {
          download(processGuid);
        }}
        loading={isLoading}
        height={32}
        type="primary"
        label={t('labels.download')}
      />
    </div>
  );
};

export default EligibilityDownload;
