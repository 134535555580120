import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_GOALS } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';

import { getPatientGoalsKey, PATIENT_GOALS_PER_PAGE } from './use-get-goals';

import type {
  IAddPatientGoal,
  IPatientGoal,
  IPatientGoalResponse,
} from 'infrastructure/interfaces';

interface Props {
  page: number;
}

export const addPatientGoalFn = async (postData: IAddPatientGoal) => {
  const { data, error } = await dataService.createOne<IPatientGoal>(
    API_GOALS,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useAddPatientGoal = ({ page }: Props) => {
  const queryClient = useQueryClient();

  const filter = {
    page,
    items: PATIENT_GOALS_PER_PAGE,
  };

  const {
    mutate: addPatientGoal,
    mutateAsync: addPatientGoalAsync,
    isLoading,
    ...other
  } = useMutation({
    mutationFn: addPatientGoalFn,
    onSuccess(data, variables) {
      const queryKey = getPatientGoalsKey({
        ...filter,
        patientGuid: variables.patientGuid,
        type: variables.type,
      });
      queryClient.setQueryData<IPatientGoalResponse>(queryKey, (oldData) => {
        if (oldData && data) {
          const newData = structuredClone(oldData);
          newData.count += 1;
          newData.items.unshift(data);

          if (newData.items.length >= PATIENT_GOALS_PER_PAGE)
            newData.items.length = PATIENT_GOALS_PER_PAGE;

          return newData;
        }
        return oldData;
      });
      showResult();
    },
  });

  return {
    addPatientGoal,
    addPatientGoalAsync,
    addLoading: isLoading,
    ...other,
  };
};
