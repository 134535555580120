import React from 'react';
import { SendOutlined } from '@ant-design/icons';

import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SendIcon: React.FC<Partial<CustomIconComponentProps>> = (props) => {
  return <SendOutlined {...props} />;
};

export default SendIcon;
