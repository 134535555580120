import { Radio } from 'antd';
import CircleIcon from 'components/atoms/icons/circleIcon';
import InformationTooltip from 'components/atoms/information-tooltip';
import { type FC } from 'react';

import s from './styles.module.scss';

import type { IQuestion } from 'infrastructure/interfaces';
import type { FormikProps } from 'formik';

const SMOKING_QUESTION_DESC_LIST = [
  'If yes, Advise the patient to quit.',
  'Is the patient willing to quit?',
  'If yes, Resources provided related to cessation.',
  'If no, Provide a brief motivational message, set expectations, and leave the door open to future conversations',
];

interface Props {
  questions: IQuestion[];
  formik: FormikProps<any>;
  disabled: boolean;
  isSmokingQuestion: boolean;
  isFallRiskQuestion: boolean;
}

const AnswersRow: FC<Props> = ({
  formik,
  questions,
  disabled,
  isSmokingQuestion,
  isFallRiskQuestion,
}) => {
  return (
    <div className={s.wrapper}>
      {questions.map((question) => {
        const options = question.answers.map((a) => ({
          label: a.answer,
          value: a.guid,
        }));

        if (options.length > 2) {
          return null;
        }

        return (
          <div className={s.body}>
            <div className={s.answer}>
              <Radio.Group
                disabled={disabled}
                data-cy="questionareYesNoAnswers"
                options={options}
                name={question.guid}
                className={s.radio}
                onChange={formik.handleChange}
                value={formik.values[question.guid]}
              />
            </div>
            <p className={s.question}>{question.question}</p>
            {isFallRiskQuestion && (
              <InformationTooltip title={question.description} />
            )}
          </div>
        );
      })}
      {isSmokingQuestion && (
        <div className={s.smoking}>
          {SMOKING_QUESTION_DESC_LIST.map((smokingDesc, idx) => (
            <div className={s['smoking-item']} key={idx}>
              <CircleIcon />
              <p>{smokingDesc}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AnswersRow;
