import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/atoms/button';
import ErrorImg from 'assets/img/error.svg';
import { Container, Card, CardBody, Row, Col, CardImg } from 'reactstrap';

const NotFound = () => (
  <Container>
    <div className="not-found">
      <Card className="card-border">
        <CardBody className="card-outer">
          <Row>
            <Col xl={6} md={6} className="not-found-text">
              <div className="not-found-text-first">Uh-oh what did you do?</div>
              <div className="not-found-text-second">
                No worries click here to go back to your home page
              </div>
              <Link to="/">
                <Button text="Back Home" />
              </Link>
            </Col>
            <Col xl={6} md={6}>
              <CardImg src={ErrorImg} alt="error" />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  </Container>
);

export default NotFound;
