import React from 'react';

import type { IBaseTableColumnProps } from '../../types';

const BaseTableHeaderBaseColumn: React.FC<IBaseTableColumnProps> = (props) => {
  const { label } = props;

  return <>{label}</>;
};

export default BaseTableHeaderBaseColumn;
