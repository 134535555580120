import dataService from 'services/dataService';
import {
  API_DOCTOR_UPDATE_PROFILE,
  API_USERS_PROFILE,
  API_USER_TERMS_OF_SERVICE,
} from 'services/dataService/resources';
import UAParser from 'ua-parser-js';
import {
  getUserPlatformMode,
  toggleUserPlatformMode,
} from 'utils/accessManager/helpers';
import { PLATFORM_MODE_RPM } from 'utils/accessManager/constants';
import { showResult } from 'infrastructure/hooks/utils';
import { apm } from '@elastic/apm-rum';
import { createModel } from '@rematch/core';

import type { RootModel } from 'store/models';

const DEFAULT_PLATFORM_MODE = PLATFORM_MODE_RPM;

interface Agency {
  guid: string;
  legalname: string;
}

export const DEFAULT_USER_DETAILS = {
  guid: '',
  userType: '',
  agencies: [] as Agency[],
  roleNames: [] as string[],
  deviceType: '',
  deviceOS: '',
  loginGuid: '',
  loginStatus: '',
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  birthDate: '',
  phone: '',
  timezone: '',
  alertSms: false,
  alertEmail: false,
  adminFlag: false,
  logoutFlag: false,
  platformMode: DEFAULT_PLATFORM_MODE,
  termsOfService: false,
  isLoggingOut: false,
  primaryLanguage: null as string | null,
  secondaryLanguage: null as string | null,
  professionType: null as string | null,
  createdAt: null as string | null,
};

const user = createModel<RootModel>()({
  state: { ...DEFAULT_USER_DETAILS },
  reducers: {
    setUser(state, payload: Partial<typeof DEFAULT_USER_DETAILS>) {
      return {
        ...state,
        ...payload,
        logoutFlag: false,
      };
    },
    logout() {
      return {
        ...DEFAULT_USER_DETAILS,
        logoutFlag: true,
      };
    },
    device(state, payload: string) {
      return {
        ...state,
        deviceType: payload,
      };
    },
    deviceOS(state, payload: string) {
      return {
        ...state,
        deviceOS: payload,
      };
    },
    setLoggingOut(state, payload: boolean) {
      return {
        ...state,
        isLoggingOut: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async setDevice() {
      const parser = new UAParser();
      const result = parser.getResult();
      dispatch.user.deviceOS(result.os.name?.toLocaleLowerCase() || '');
      dispatch.user.device(result.device.type || '');
    },
    async acceptTermsOfService() {
      const { data, error } = await dataService.updateOnly(
        API_USER_TERMS_OF_SERVICE,
      );
      if (error) showResult(error);
      if (data) {
        const platformMode = getUserPlatformMode(data);
        dispatch.user.setUser({ ...data, platformMode });
      }
    },
    async getProfile() {
      const { data, error } = await dataService.getOnly(API_USERS_PROFILE);

      if (error) {
        dispatch.user.logout();
      }

      if (data) {
        const platformMode = getUserPlatformMode(data);
        dispatch.user.setUser({
          ...data,
          platformMode,
        });
        if (apm) {
          apm.setUserContext({ id: data.loginGuid });
        }
      }
    },
    async toggleAlerts(
      postData: {
        alertEmail?: boolean;
        alertSms?: boolean;
      },
      state,
    ): Promise<{ error?: any; data?: any }> {
      const { guid } = state.user;
      const { error, data } = await dataService.updateOnly(
        API_DOCTOR_UPDATE_PROFILE,
        {
          guid,
          ...postData,
        },
      );
      if (!error) {
        dispatch.user.setUser({
          ...postData,
        });
      }
      return { error, data };
    },
    switchPlatformMode(_, state): string {
      const { user: currentUser } = state;
      const platformMode = toggleUserPlatformMode(currentUser);
      dispatch.user.setUser({ ...user, platformMode });

      return platformMode;
    },
  }),
});

export default user;
