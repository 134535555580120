import common from 'constants/common';

export const generateFullName = (
  title,
  firstName,
  middleName,
  lastName,
  profession,
) =>
  `${title || ''} ${firstName || ''} ${middleName || ''} ${lastName || ''}${
    profession ? ` - ${profession}` : ''
  }`;

export const checkIsInactive = (status) => status?.includes('inactive');

export const checkIsOnboarding = (status) => status?.includes('onboarding');

export const getIndicatorColor = (score) => {
  if (score <= 24) return common.commonColors.green;
  if (score <= 50) return common.commonColors.yellow;
  return common.commonColors.red;
};
