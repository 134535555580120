export enum Roles {
  CcmBiller = 'ccm-biller',
  CcmDoctor = 'ccm-doctor',
  CcmNursePractitioner = 'ccm-nursePractitioner',
  CcmPhysicianAssistant = 'ccm-physicianAssistant',
  CcmQHCP = 'ccm-QHCP',
  CcmSupport = 'ccm-support',
  CcmRegisteredNurse = 'ccm-registeredNurse',
  Doctor = 'doctor',
  PhysicianAssistant = 'physicianAssistant',
  NursePractitioner = 'nursePractitioner',
  RegisteredNurse = 'registeredNurse',
  QHCP = 'QHCP',
  PharmD = 'pharmD',
  ClientSuccessScaling = 'clientSuccessScaling',
  ClientSuccessOnboarding = 'clientSuccessOnboarding',
  SalesRepresentative = 'salesRepresentative',
  PatientAcceptance = 'patientAcceptance',
}

export const CCMRoles = [
  Roles.CcmBiller,
  Roles.CcmDoctor,
  Roles.CcmNursePractitioner,
  Roles.CcmPhysicianAssistant,
  Roles.CcmQHCP,
  Roles.CcmSupport,
  Roles.CcmRegisteredNurse,
];

export const CCMOnboardingAgentRoles = [...CCMRoles, Roles.PatientAcceptance];

export const appointmentCompletedByAllowedRoles = [
  Roles.CcmQHCP,
  Roles.CcmRegisteredNurse,
];

export const rpmDashboardFilterDoctorSearchRoles = [
  Roles.Doctor,
  Roles.NursePractitioner,
  Roles.RegisteredNurse,
  Roles.PhysicianAssistant,
  Roles.PharmD,
];
