import { createModel } from '@rematch/core';
import WS from 'services/wsService';

import type { RootModel } from 'store/models';

type SocketState = {
  client: typeof WS | null;
  isConnected: boolean;
};

const socket = createModel<RootModel>()({
  state: {
    client: null,
    isConnected: false,
  } as SocketState,
  reducers: {
    setClient: (state, client: typeof WS) => ({
      ...state,
      client,
    }),
    setSocketConnected: (state) => ({
      ...state,
      isConnected: true,
    }),
    setSocketDisconnected: (state) => ({
      ...state,
      isConnected: false,
    }),
  },
  effects: (dispatch) => ({
    async init() {
      await WS.init();
      dispatch.socket.setClient(WS);
      dispatch.socket.setSocketConnected();
      WS.on('disconnected', () => {
        dispatch.socket.setSocketDisconnected();
      });
      WS.on('connected', () => {
        dispatch.socket.setSocketConnected();
      });
    },
  }),
});

export default socket;
