import { Popover } from 'antd';
import { useState, type FC, type ReactNode } from 'react';
import common from 'constants/common';

import InformationIcon from '../icons/informationIcon';
import s from './styles.module.scss';
import CrossIcon from '../icons/crossIcon';

interface BaseTooltipProps {
  title?: ReactNode;
}

const InformationTooltip: FC<BaseTooltipProps> = ({ title }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  return (
    <Popover
      open={open}
      trigger="click"
      placement="bottomRight"
      arrow={false}
      content={() => (
        <div className={s.content}>
          <p>{title}</p>
          <CrossIcon onClick={toggleOpen} className={s['close-icon']} />
        </div>
      )}
      onOpenChange={setOpen}
    >
      <InformationIcon
        color={
          open ? common.commonColors.orange60 : common.commonColors.dargGray
        }
        className={s.icon}
      />
    </Popover>
  );
};
export default InformationTooltip;
