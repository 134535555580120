/* eslint-disable no-nested-ternary */
import ControlPanelSuccessIcon from 'components/atoms/icons/controlPanelSuccessIcon';
import CrossControlIcon from 'components/atoms/icons/crossControlIcon';
import HoldingControlStatusIcon from 'components/atoms/icons/holdingControlStatusIcon';
import { useState } from 'react';
import { makeid } from 'utils/helpers/makeid';
import useUser from 'utils/useUser';
import GeneralCommentIcon from 'components/atoms/icons/generalCommentIcon';
import CallControlIcon from 'components/atoms/icons/callControlIcon';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { useDoctorInfo } from 'infrastructure/hooks/doctor/use-doctor-info';
import SmsControlIcon from 'components/atoms/icons/smsControlIcon';
import SubscriptionControlIcon from 'components/atoms/icons/subscriptionControlIcon';
import EHRControlIcon from 'components/atoms/icons/ehrControlIcon';
import {
  useUpdatePatientHoldingStatus,
  useCallButtons,
  useSmsButtons,
  useEmailSubscriptionStatus,
  useSendSms,
  useUpdatePatientCcmStatus,
  useClinicEscalation,
  useRequestButtons,
  useAppointmentRequest,
  useHospitalPrevention,
  useUpdateInteractionStatus,
  useUpdateTempHoldStatus,
  useDoctorSendSms,
  useUpdateConsumablesDate,
} from 'infrastructure/hooks/control-panel';
import {
  isAdmin,
  isAgencyDoctor,
  isBiller,
  isCcmActionAllowedStatus,
  isPractice,
} from 'utils/userTypeHelper';
import { useCreateCaseInEhr } from 'infrastructure/hooks/control-panel/use-create-case-in-ehr';
import { isMobile } from 'utils/deviceHelper';
import { useAppSelector } from 'store';
import ControlClinicalEscalationIcon from 'components/atoms/icons/controlClinicalEscalationIcon';
import { useUpdatePatientDischargeStatus } from 'infrastructure/hooks/control-panel/use-update-discharge-status';
import ControlRequestIcon from 'components/atoms/icons/controlRequestIcon';
import ControlHospitalizationIcon from 'components/atoms/icons/controlHospitalizationIcon';
import { differenceInCalendarDays } from 'date-fns';
import { parseDateSafe } from 'utils/timeHelper';
import { capitalize } from 'utils';
import { isCcmModule, isRpmModule } from 'infrastructure/functions';
import { useComment } from 'infrastructure/hooks/use-comment';
import FalseMonthlyInteractionIcon from 'components/atoms/icons/falseMontlyInteractionIcon';
import { type ModuleType } from 'infrastructure/enums';

import type { ICreateComment } from 'infrastructure/interfaces';

interface Props {
  patientGuid: string;
  module: ModuleType;
}

export const useControlButtons = ({ patientGuid, module }: Props) => {
  const [holdingStatusPopup, setHoldingStatusPopup] = useState(false);
  const [openCcmInteractionModal, setOpenCcmInteractionModal] = useState(false);
  const [isPrimaryNumber, setIsPrimaryNumber] = useState(false);
  const [smsPopupOpen, setSmsPopupOpen] = useState(false);
  const [doctorSmsPopupOpen, setDoctorSmsPopupOpen] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [manualEnteredCallPopupOpen, setManualEnteredCallPopupOpen] =
    useState(false);
  const [createCaseInEhrModalOpen, setCreateCaseInEhrModalOpen] =
    useState(false);
  const [clinicalEscalationModalOpen, setClinicalEscalationModalOpen] =
    useState(false);
  const [hospitalPreventionModalOpen, setHospitalPreventionModalOpen] =
    useState(false);
  const [commentsPopupOpen, setCommentsPopupOpen] = useState(false);
  const [dischargePopupOpen, setDischargePopupOpen] = useState(false);
  const [CCMDischargePopupOpen, setCCMDischargePopupOpen] = useState(false);
  const { userType, roleNames, timezone } = useUser();
  const device = useAppSelector((state) => state.user.deviceType);
  const { togglePatientCcmInteractionStatus: updateCcmInteractionLoading } =
    useAppSelector((state) => state.loading.effects.adminPanelPatients);
  const { patientInfo, loading } = usePatientInfo({ patientGuid });
  const { doctorInfo, doctorInfoLoading } = useDoctorInfo({
    doctorGuid: patientInfo?.doctorGuid,
  });
  const { loading: commentLoading, sendComment } = useComment({
    patientGuid,
  });

  const { updateHoldingStatus, holdingStatusLoading } =
    useUpdatePatientHoldingStatus({
      onSuccess() {
        setHoldingStatusPopup(false);
      },
    });
  const { updateTempHoldStatus, tempHoldingStatusLoading } =
    useUpdateTempHoldStatus({
      onSuccess() {
        setHoldingStatusPopup(false);
      },
    });
  const { updateConsumablesDate, consumablesDateLoading } =
    useUpdateConsumablesDate({
      onSuccess() {
        setHoldingStatusPopup(false);
      },
    });
  const { updateSubscriptionStatus, subscriptionStatusLoading } =
    useEmailSubscriptionStatus({
      patientGuid,
      onSuccess() {
        setOpenSubscriptionModal(false);
      },
    });
  const sendCommentHandler = async (data: ICreateComment) => {
    const response = await sendComment(data);
    setCommentsPopupOpen(false);
    return response;
  };
  const { sendSms, smsLoading } = useSendSms();
  const { sendDoctorSms, doctorSmsLoading } = useDoctorSendSms();
  const { createCaseInEhr, createCaseInEhrLoading } = useCreateCaseInEhr({
    onSuccess() {
      setCreateCaseInEhrModalOpen(false);
    },
  });
  const { updateCcmStatus, ccmStatusLoading } = useUpdatePatientCcmStatus({
    onSuccess() {
      setCCMDischargePopupOpen(false);
    },
  });
  const { sendClinicEscalation, sendClinicEscalationLoading } =
    useClinicEscalation({
      onSuccess() {
        setClinicalEscalationModalOpen(false);
      },
    });
  const { sendAppointmentRequest, sendAppointmentRequestLoading } =
    useAppointmentRequest();
  const { updateDischargeStatus, updateDischargeStatusLoading } =
    useUpdatePatientDischargeStatus({
      onSuccess() {
        setDischargePopupOpen(false);
      },
    });
  const { sendHospitalPrevention, sendHospitalPreventionLoading } =
    useHospitalPrevention({
      onSuccess() {
        setHospitalPreventionModalOpen(false);
      },
    });
  const { updateInteractionStatus, updateInteractionStatusLoading } =
    useUpdateInteractionStatus();

  const disableButton =
    !isCcmActionAllowedStatus(patientInfo?.ccmStatus) && isCcmModule(module);
  const { callButtons, callButtonsAlert } = useCallButtons({
    isCcmPanel: isCcmModule(module),
    isRpmPanel: isRpmModule(module),
    patientGuid,
    userType,
    doNotCall: patientInfo?.doNotCall,
    emergencyContactNumber: patientInfo?.emergencyContactNumber ?? '',
    patientHomeNumber: patientInfo?.homeNumber ?? '',
    patientPhone: patientInfo?.loginInfo?.phone ?? '',
    doctorPhone: doctorInfo?.phone ?? undefined,
    setManualEnteredCallPopupOpen,
    roleNames,
    patientTimeZone: timezone,
  });
  const ccmSmsButtons = useSmsButtons({
    isAdminPanel: false,
    patientHasSecondaryNumber: Boolean(patientInfo?.homeNumber),
    setIsPrimaryNumber,
    setSmsPopupOpen,
    userType,
    doctorWorkNumber: doctorInfo?.workNumber ?? undefined,
    setDoctorSmsPopupOpen,
  });
  const requestButtons = useRequestButtons({
    isRpmPanel: isRpmModule(module),
    status: patientInfo?.status ?? '',
    setDischargePopupOpen,
    appointmentsFlagActive: patientInfo?.appointmentsFlagActive ?? false,
    sendAppointmentRequest() {
      sendAppointmentRequest({
        patientGuid,
        rogerModule: isCcmModule(module) ? 'ccm' : undefined,
      });
    },
  });

  const ccmInteractionStatus = patientInfo?.ccmInteractionStatus;
  const emailSubscriptionStatus = patientInfo?.emailSubscription;
  const interactionStatus = patientInfo?.interactionStatus;
  const today = new Date();
  const holdingStatusStartTime = parseDateSafe(
    patientInfo?.holdingStatus?.startTime,
  );
  const holdingStatusEndTime = parseDateSafe(
    patientInfo?.holdingStatus?.endTime,
  );
  const remainingDays =
    holdingStatusEndTime &&
    differenceInCalendarDays(holdingStatusEndTime, today);

  const currentHoldingStatus =
    patientInfo?.holdingStatus?.status !== 'active' &&
    typeof patientInfo?.holdingStatus?.endTime !== 'undefined' &&
    typeof remainingDays !== 'undefined'
      ? `${remainingDays + 1} Day${remainingDays > 1 ? 's' : ''} ${capitalize(
          patientInfo?.holdingStatus.status,
        )}`
      : '';

  const ccmHoldingStatus = patientInfo?.ccmHoldingStatus;
  const ccmHoldingStatusStartDate = patientInfo?.ccmHoldingStatusStartDate;
  const consumablesArrivingDate = patientInfo?.consumablesArrivingDate;
  const consumablesOrdered = !!consumablesArrivingDate;

  const controlButtons = [
    {
      index: 0,
      key: makeid(5),
      label: 'General Comments',
      Icon: GeneralCommentIcon,
      loading: commentLoading,
      handleClick: () => {
        setCommentsPopupOpen(true);
      },
      render: true,
      disabled: disableButton,
    },
    {
      index: isCcmModule(module) ? 1 : isMobile(device) ? 9 : 2,
      key: makeid(5),
      label: 'Holding Status',
      Icon: HoldingControlStatusIcon,
      status: currentHoldingStatus,
      loading: holdingStatusLoading,
      handleClick: () => {
        setHoldingStatusPopup(true);
      },
      render: !isBiller(roleNames),
      disabled: disableButton,
    },
    {
      index: 3,
      key: makeid(5),
      label: 'Call',
      Icon: CallControlIcon,
      loading: false,
      options: callButtons,
      render: true,
      disabled: disableButton,
    },
    {
      index: 4,
      key: makeid(5),
      label: 'Follow-up Appointment Complete',
      Icon: ccmInteractionStatus
        ? CallControlIcon
        : FalseMonthlyInteractionIcon,
      status: ccmInteractionStatus ? 'True' : 'False',
      loading: updateCcmInteractionLoading,
      handleClick: () => {
        setOpenCcmInteractionModal(true);
      },
      disabled: ccmInteractionStatus || disableButton,
      render: isCcmModule(module),
      extraAccent: !ccmInteractionStatus,
    },
    {
      index: isCcmModule(module) ? 5 : isMobile(device) ? 1 : 6,
      key: makeid(5),
      label: 'SMS',
      Icon: SmsControlIcon,
      loading: false,
      options: ccmSmsButtons,
      render: true,
      disabled: disableButton,
    },
    {
      index: isCcmModule(module) ? 6 : isMobile(device) ? 10 : 8,
      key: makeid(5),
      label: 'Email Subscription',
      Icon: SubscriptionControlIcon,
      status: emailSubscriptionStatus ? 'Subscribed' : 'Unsubscribed',
      loading: subscriptionStatusLoading,
      handleClick: () => {
        setOpenSubscriptionModal(true);
      },
      disabled: emailSubscriptionStatus || disableButton,
      render: true,
    },
    {
      index: isCcmModule(module) ? 7 : isMobile(device) ? 5 : 1,
      key: makeid(5),
      label: 'Create Case in EHR',
      Icon: EHRControlIcon,
      loading: createCaseInEhrLoading,
      handleClick: () => {
        setCreateCaseInEhrModalOpen(true);
      },
      disabled: disableButton,
      render: isAdmin(userType) || isAgencyDoctor(userType),
    },
    {
      index: 8,
      key: makeid(5),
      label:
        patientInfo?.ccmStatus === 'enrolled'
          ? 'Unenroll Patient'
          : 'Patient Unenrolled',
      Icon:
        patientInfo?.ccmStatus === 'enrolled'
          ? ControlPanelSuccessIcon
          : CrossControlIcon,
      loading: ccmStatusLoading,
      handleClick: () => {
        setCCMDischargePopupOpen(true);
      },
      disabled: disableButton,
      render: !isMobile(device) && isCcmModule(module),
    },
    {
      index: isMobile(device) ? 9 : 5,
      key: makeid(5),
      label: 'Monthly Interaction',
      Icon: interactionStatus ? CallControlIcon : FalseMonthlyInteractionIcon,
      loading: updateInteractionStatusLoading,
      status: interactionStatus ? 'Yes' : 'No',
      handleClick: () => {
        updateInteractionStatus({
          patientGuid,
          isInteracted: !interactionStatus,
        });
      },
      disabled: interactionStatus,
      render: isRpmModule(module),
      extraAccent: !interactionStatus,
    },
    {
      index: isMobile(device) ? 6 : 7,
      key: makeid(5),
      label: 'Clinical Escalations',
      Icon: ControlClinicalEscalationIcon,
      loading: sendClinicEscalationLoading,
      handleClick: () => {
        setClinicalEscalationModalOpen(true);
      },
      disabled: sendClinicEscalationLoading,
      render: isRpmModule(module) && !isPractice(userType),
    },
    {
      index: isMobile(device) ? 3 : 9,
      key: makeid(5),
      label: 'Requests',
      Icon: ControlRequestIcon,
      loading: sendAppointmentRequestLoading || updateDischargeStatusLoading,
      disabled: disableButton,
      options: requestButtons,
      render: isRpmModule(module),
    },
    {
      index: isMobile(device) ? 6 : 10,
      key: makeid(5),
      label: 'Hospitalization Prevention',
      Icon: ControlHospitalizationIcon,
      loading: sendHospitalPreventionLoading,
      handleClick: () => {
        setHospitalPreventionModalOpen(true);
      },
      disabled: disableButton,
      render: isRpmModule(module) && !isPractice(userType),
    },
  ]
    .filter(({ render }) => render)
    .sort((a, b) => a.index - b.index);

  return {
    controlButtons,
    holdingStatusPopup,
    holdingStatus: patientInfo?.holdingStatus?.status ?? null,
    nextHoldingStatus: patientInfo?.holdingStatus?.nextStatus ?? null,
    holdingStatusStartTime,
    holdingStatusEndTime,
    isLoading: loading || doctorInfoLoading,
    openCcmInteractionModal,
    isPrimaryNumber,
    smsPopupOpen,
    openSubscriptionModal,
    manualEnteredCallPopupOpen,
    commentsPopupOpen,
    interactionStatus,
    commentLoading,
    smsLoading,
    subscriptionStatusLoading,
    createCaseInEhrModalOpen,
    CCMDischargePopupOpen,
    clinicalEscalationModalOpen,
    dischargePopupOpen,
    hospitalPreventionModalOpen,
    holdingStatusLoading,
    createCaseInEhrLoading,
    sendClinicEscalationLoading,
    updateDischargeStatusLoading,
    sendHospitalPreventionLoading,
    ccmStatusLoading,
    ccmHoldingStatus,
    ccmHoldingStatusStartDate,
    doctorSmsPopupOpen,
    sendDoctorSms,
    doctorSmsLoading,
    setDoctorSmsPopupOpen,
    consumablesArrivingDate,
    consumablesOrdered,
    tempHoldingStatusLoading,
    consumablesDateLoading,
    setHospitalPreventionModalOpen,
    setDischargePopupOpen,
    setClinicalEscalationModalOpen,
    setCCMDischargePopupOpen,
    setCreateCaseInEhrModalOpen,
    setOpenSubscriptionModal,
    sendSms,
    setSmsPopupOpen,
    setCommentsPopupOpen,
    setManualEnteredCallPopupOpen,
    setHoldingStatusPopup,
    updateHoldingStatus,
    updateSubscriptionStatus,
    sendComment: sendCommentHandler,
    setOpenCcmInteractionModal,
    createCaseInEhr,
    updateCcmStatus,
    sendClinicEscalation,
    updateDischargeStatus,
    sendHospitalPrevention,
    updateTempHoldStatus,
    updateConsumablesDate,
    callButtonsAlert,
  };
};
