import { useTranslation } from 'react-i18next';
import { useRemoveAppointmentCompletion } from 'infrastructure/hooks/patient/use-remove-patient-appointment-completion';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import BaseTableOptionsDropdown from 'components/atoms/base-table-option-dropdown';

import s from './styles.module.scss';

import type { FC } from 'react';

interface Props {
  guid: string;
  patientGuid: string;
  disabled?: boolean;
}

const OptionsDropdown: FC<Props> = ({ guid, patientGuid, disabled }) => {
  const { t } = useTranslation();
  const { removeAppointmentCompletion, removeLoading } =
    useRemoveAppointmentCompletion({
      patientGuid,
    });
  const { Alert, showAlert } = useAlert();

  const handleRemove = async () => {
    const res = await showAlert({
      title: t('labels.deleteAppointment'),
      messageTitle: t('messages.confirmDeleteAppointment'),
      type: 'danger',
    });
    if (res) {
      removeAppointmentCompletion(guid);
    }
  };

  return (
    <>
      <div className={s.wrapper} data-cy="next-appointment-options">
        <BaseTableOptionsDropdown
          loading={removeLoading}
          onDelete={handleRemove}
          disabled={removeLoading || disabled}
        />
      </div>
      {Alert}
    </>
  );
};
export default OptionsDropdown;
