import React, { useEffect, useState } from 'react';
import BaseTableNew from 'components/atoms/base-table/new';
import BaseButton from 'components/atoms/baseButton';
import PlusIcon from 'components/atoms/icons/plus';
import { useTranslation } from 'react-i18next';
import BaseModal from 'components/atoms/base-modal';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import { CareLocation } from 'infrastructure/classes/patient/care-location';
import { useCareLocation } from 'infrastructure/hooks/patient/use-patient-care-location';

import CareLocationCreateForm from '../create-update-form';
import { careLocationColumns } from './cols';
import s from './styles.module.scss';

interface ICareLocationTableProps {
  patientGuid: string;
  readOnly: boolean;
}

const CareLocationTable: React.FC<ICareLocationTableProps> = (props) => {
  const { patientGuid, readOnly } = props;

  const { t } = useTranslation();

  const [open, setIsOpen] = useState(false);
  const [careLocation, setCareLocation] = useState<CareLocation | undefined>();
  const [items, setItems] = useState<Array<CareLocation>>([]);
  const { loading, loadCareLocation, deleteCareLocation } = useCareLocation({
    patientGuid,
  });

  const modalLabel = careLocation
    ? t('labels.editCareLocation')
    : t('labels.createCareLocation');

  const { Alert, showAlert } = useAlert();

  const loadData = async () => {
    const data = await loadCareLocation();
    if (data) {
      setItems(data.map((el) => new CareLocation(el)));
    }
  };

  const onCreate = () => {
    setCareLocation(undefined);
    setIsOpen(true);
  };

  const onEdit = (record: CareLocation) => {
    setCareLocation(record);
    setIsOpen(true);
  };

  const onDelete = async (record: CareLocation) => {
    const res = await showAlert({
      title: t('labels.confirmDeleting'),
      messageTitle: t('messages.conditionDelitingCareLocation'),
      type: 'danger',
    });
    if (res) {
      await deleteCareLocation({
        patientGuid,
        guid: record.guid,
      });
      await loadData();
    }
  };

  const onSaved = async () => {
    setIsOpen(false);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, [patientGuid]);

  return (
    <div>
      <div className={s.header}>
        <h3 className={s.title}>{t('labels.careLocations')}</h3>
      </div>
      <BaseTableNew
        dataCy="care-location-table"
        loading={loading}
        columns={careLocationColumns({ readOnly, onEdit, onDelete })}
        items={items}
        bordered
      />
      {!readOnly && (
        <div className={s.controls}>
          <BaseButton
            loading={loading}
            icon={<PlusIcon color="" />}
            label={t('controls.addCareLocation')}
            onClick={onCreate}
            dataCy="addCareLocation-button"
          />
        </div>
      )}

      <BaseModal
        title={modalLabel}
        open={open}
        onCancel={() => setIsOpen(false)}
      >
        <CareLocationCreateForm
          patientGuid={patientGuid}
          data={careLocation}
          onSaved={onSaved}
          onCancel={() => setIsOpen(false)}
        />
      </BaseModal>
      {Alert}
    </div>
  );
};

export default CareLocationTable;
