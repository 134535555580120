import React from 'react';
import PropTypes from 'prop-types';
import PaginationOrderIconUp from 'components/atoms/icons/paginationOrderIconUp';
import PaginationOrderIconDown from 'components/atoms/icons/paginationOrderIconDown';

const PaginationOrder = ({ reversed, onChange }) => (
  <button onClick={onChange} className="pagination-order-button">
    <PaginationOrderIconUp active={reversed} />
    <PaginationOrderIconDown active={!reversed} />
  </button>
);

PaginationOrder.defaultProps = {
  reversed: false,
  onChange: () => {},
};

PaginationOrder.propTypes = {
  reversed: PropTypes.bool,
  onChange: PropTypes.func,
};

export default PaginationOrder;
