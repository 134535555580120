import React from 'react';

const OnCallIcon = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.4342 9.38869C11.6015 9.38869 10.7858 9.25867 10.0127 9.00357C9.63544 8.87355 9.207 8.97373 8.95903 9.22669L7.42643 10.3841C5.66787 9.44554 4.5424 8.32077 3.61659 6.575L4.74277 5.07864C5.02627 4.79514 5.12788 4.38018 5.00638 3.99153C4.74916 3.2135 4.61844 2.39711 4.61844 1.56579C4.61844 0.978173 4.14026 0.5 3.55265 0.5H1.06579C0.478173 0.5 0 0.978173 0 1.56579C0 8.42165 5.57835 14 12.4342 14C13.0218 14 13.5 13.5218 13.5 12.9342V10.4545C13.5 9.86687 13.0218 9.38869 12.4342 9.38869Z"
      fill="#2C2543"
      {...props}
    />
  </svg>
);

export default OnCallIcon;
