const CcmIcon = (props) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.02876 6.86236H8.89713C9.11174 6.86236 9.42522 6.67886 9.53952 6.47659L10.7363 4.49609H3.02876C1.35615 4.49609 0 5.85224 0 7.52496V8.52707C0.639781 7.52785 1.75667 6.86236 3.02876 6.86236Z"
      fill="#FB9318"
    />
    <path
      d="M19.9711 3.26562H12.8724C12.4538 3.26562 11.9489 3.56102 11.744 3.92561L11.3993 4.49605L10.0254 6.7703C9.82059 7.1348 9.3155 7.43024 8.89718 7.43024H3.02876C1.35615 7.43024 0 8.78644 0 10.4591V16.0435V16.706C0 18.3787 1.35615 19.7349 3.02876 19.7349H19.9711C21.6439 19.7349 23 18.3787 23 16.706V16.0435V7.52496V6.29444C23 4.62182 21.6439 3.26562 19.9711 3.26562Z"
      fill="#FB9318"
    />
    <path
      d="M9.16734 15.0628C9.39696 15.07 9.60588 14.9675 9.67687 14.8022L10.551 12.7704L10.8512 12.0714L10.9721 12.7564L11.4867 15.6714C11.5196 15.8575 11.7252 15.9972 11.971 16C11.9741 16 11.9771 16 11.9798 16C12.222 16 12.4287 15.8668 12.4699 15.6844L13.1243 12.7651C13.2127 12.8831 13.3772 12.9574 13.5561 12.9574H16.5025C16.7771 12.9574 17 12.7871 17 12.577C17 12.3672 16.7771 12.197 16.5025 12.197H13.8551L13.318 11.4261C13.2191 11.2825 13.0142 11.2017 12.7998 11.2284C12.5904 11.2548 12.4245 11.3795 12.3883 11.5393L12.1242 12.7188L12.0371 13.1061L11.9697 12.7242L11.5467 10.329C11.516 10.1536 11.3298 10.0179 11.0993 10.0016C10.8651 9.98535 10.6529 10.0934 10.5815 10.2617L9.48739 12.8052L9.3558 13.1102L9.2887 12.8111L9.04201 11.708C9.00559 11.5443 8.83369 11.4175 8.6181 11.3952C8.40369 11.3703 8.19343 11.4601 8.10046 11.6104L7.5415 12.5216H6.49769C6.22286 12.5216 6 12.6918 6 12.9016C6 13.1117 6.22286 13.282 6.49769 13.282H7.85986C8.05288 13.282 8.22884 13.1969 8.31014 13.0631L8.33025 13.0315L8.71444 14.7478C8.75256 14.9197 8.93992 15.0499 9.16734 15.0628Z"
      fill="white"
    />
  </svg>
);

export default CcmIcon;
