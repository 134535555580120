import React from 'react';

import type { SVGProps } from 'react';

const ControlPanelSuccessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5922 4.62403C9.62437 -0.473549 16.3707 -1.50509 20.6809 2.27339C24.9911 6.05187 25.2201 16.4803 20.6809 21.0786C16.1416 25.6768 5.67952 24.2016 2.11701 21.0786C-1.4455 17.9555 -0.439978 9.72162 4.5922 4.62403Z"
      fill="#FB9318"
    />
    <path
      d="M15.5524 8.97725C15.7692 8.75758 16.1206 8.75758 16.3374 8.97725C16.5515 9.19423 16.5542 9.54436 16.3453 9.7646L11.9102 15.0073C11.9059 15.0127 11.9014 15.0179 11.8965 15.0227C11.6798 15.2424 11.3283 15.2424 11.1115 15.0227L8.41259 12.2879C8.1958 12.0682 8.1958 11.7121 8.41259 11.4924C8.62937 11.2727 8.98085 11.2727 9.19763 11.4924L11.4873 13.8126L15.5376 8.99408C15.5422 8.98818 15.5471 8.98256 15.5524 8.97725Z"
      fill="white"
    />
  </svg>
);

export default ControlPanelSuccessIcon;
