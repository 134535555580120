import classNames from 'classnames';

import type {
  ButtonHTMLAttributes,
  CSSProperties,
  DOMAttributes,
  FC,
  ReactElement,
} from 'react';

interface Props {
  title: string;
  icon?: ReactElement;
  dataCy?: string;
  onClick?: DOMAttributes<HTMLButtonElement>['onClick'];
  variant?: 'solid' | 'outline';
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  style?: CSSProperties;
  loading?: boolean;
  disabled?: boolean;
}

const TurkConfigurationButton: FC<Props> = ({
  title,
  icon: Icon,
  dataCy,
  onClick,
  type,
  variant = 'solid',
  style,
  loading = false,
  disabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames('admin-panel-button', {
        'admin-panel-button--solid': variant === 'solid',
        'admin-panel-button--outline': variant === 'outline',
        'admin-panel-button--loading': loading === true,
      })}
      data-cy={dataCy}
      type={type}
      style={style}
      disabled={loading || disabled}
    >
      {Icon && <div className="admin-panel-button--icon">{Icon}</div>}
      <div className="admin-panel-button--text">{title}</div>
    </button>
  );
};

export default TurkConfigurationButton;
