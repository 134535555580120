export class CustomError extends Error {
  code?: number;

  constructor({
    error,
    errorName,
    status,
  }: {
    error?: string;
    errorName?: string;
    status?: number;
  }) {
    super(error);
    this.code = status;
    this.name = errorName || this.constructor.name;
  }

  static isCustomError(err: any) {
    return err instanceof CustomError;
  }
}
