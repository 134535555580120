import React from 'react';

import s from './styles.module.scss';

interface IProps {
  title: string;
  children: React.ReactNode;
  controls?: React.ReactNode;
}

const BaseTitledCard: React.FC<IProps> = (props) => {
  const { title, controls, children } = props;

  return (
    <div className={s.card}>
      <div className={s.header}>
        {title} {controls}
      </div>
      <div className={s.body}>{children}</div>
    </div>
  );
};

export default BaseTitledCard;
