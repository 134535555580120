import i18next from 'i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import type { IManualClinicNotes } from 'infrastructure/interfaces';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

export const columns = (): IBaseTableColumns<IManualClinicNotes> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'date',
      width: '8%',
      render(value: string) {
        return (
          <span data-cy="manual-clinic-notes-date">
            {DateFormatter({
              date: value,
              format: DateFormats['MM-dd-yyyy h:mm a'],
            })}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.electronicallySignedBy'),
      key: 'author',
      width: '15%',
      render(value) {
        return <span data-cy="manual-clinic-notes-author">{value}</span>;
      },
    },
    {
      label: i18next.t('labels.duration'),
      key: 'timeInMinutes',
      width: '10%',
      render(value: any) {
        return <span data-cy="manual-clinic-notes-duration">{value}</span>;
      },
    },
    {
      label: i18next.t('labels.comment'),
      key: 'comment',
      width: '65%',
      render(value: any) {
        return <span data-cy="manual-clinic-notes-comment">{value}</span>;
      },
    },
  ];
};
