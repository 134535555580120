import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { apiPatientAverageMetrics } from 'services/dataService/resources';
import { useAppSelector } from 'store';

import { showResult } from '../utils';

import type { IAveragesResponse } from 'infrastructure/interfaces';

interface IGetAverages {
  patientGuid: string;
  endTime?: Date;
  timezoneOffset: number;
}

const getAveragesFn = async ({
  patientGuid,
  timezoneOffset,
  endTime,
}: IGetAverages) => {
  const { data, error } = await dataService.getList<IAveragesResponse>(
    apiPatientAverageMetrics(patientGuid, {
      endTime,
      timezoneOffset,
    }),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

interface Props {
  patientGuid: string;
}

export const getAveragesKey = (filter: IGetAverages) => ['getAverages', filter];

export const useGetAverages = ({ patientGuid }: Props) => {
  const [_, endTime] = useAppSelector<[Date, Date] | []>(
    (state) => state.patient.dates,
  );
  const timezoneOffset = new Date().getTimezoneOffset();

  const { data: averages, isLoading } = useQuery({
    queryKey: getAveragesKey({ patientGuid, timezoneOffset, endTime }),
    queryFn: async () =>
      getAveragesFn({ patientGuid, timezoneOffset, endTime }),
    enabled: Boolean(endTime),
  });

  return {
    averages,
    isLoading,
  };
};
