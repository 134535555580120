import PropTypes from 'prop-types';

import BaseLoader from '../base-loader';

const Loader = ({ height, width }) => {
  return (
    <BaseLoader className="loader" height={height} width={width} loading />
  );
};

Loader.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};
export default Loader;
