import i18next from 'i18next';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import dataService from 'services/dataService';
import {
  CONSENT_FORM_PDF_GENERATE,
  API_ADMIN_PANEL_PATIENT_CCMDATA,
} from 'services/dataService/resources';
import {
  API_PATIENT_NOTE_CREATE,
  apiCcmPatientDischarge,
} from 'services/dataService/ccmResources';
import { callCommentWithUndo } from 'infrastructure/functions/call-comment-with-undo';
import { TimerCancelledError } from 'infrastructure/classes/timer-cancelled-error';

const INITIAL_STATE = {
  ccmConsentFormAgreed: null,
  ccmConsentFromRejected: null,
};

const ccmPatients = {
  state: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    setIsAgreed: (state, payload) => ({
      ...state,
      ccmConsentFormAgreed: payload,
      ccmConsentFromRejected: !payload,
    }),
    setIsRejected: (state, payload) => ({
      ...state,
      ccmConsentFromRejected: payload,
      ccmConsentFormAgreed: !payload,
    }),
  },
  effects: () => ({
    async generatePDF(patientGuid) {
      const { error, data } = await dataService.createOne(
        CONSENT_FORM_PDF_GENERATE,
        patientGuid,
      );
      return { error, data };
    },
    async setConsentFormAnswer({ patientGuid, answer }) {
      const payload = {
        patientGuid,
        ccmData: {
          ccmConsentForm: answer,
        },
      };

      const { error, data } = await dataService.updateOnly(
        API_ADMIN_PANEL_PATIENT_CCMDATA,
        payload,
      );
      return { error, data };
    },
    async setCcmStatus({ patientGuid, dischargeStatus }) {
      const { error, data } = await dataService.updateOnly(
        apiCcmPatientDischarge(patientGuid),
        { dischargeStatus },
      );

      return { error, data };
    },
    async submitCallNote(postData) {
      const { undoTimeoutPromise } = callCommentWithUndo(postData);

      try {
        await undoTimeoutPromise;

        const { error, data } = await dataService.createOne(
          API_PATIENT_NOTE_CREATE,
          postData,
        );
        notificationController.success({
          message: i18next.t('labels.commentSubmitted'),
        });
        return { error, data };
      } catch (error) {
        if (error instanceof TimerCancelledError) {
          return {};
        }
        return { error };
      }
    },
  }),
};

export default ccmPatients;
