import { intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

export type AssigneeType = 'me' | 'anotherUser' | null;

interface BaseAssignee {
  expireAtTimestamp: number;
  loginGuid: string;
}

export const useAssignee = ({
  assignee,
  loginGuid,
}: {
  assignee?: BaseAssignee | null;
  loginGuid: string;
}) => {
  const [assigneeType, setAssigneeType] = useState<AssigneeType>(null);
  const [timer, setTimer] = useState('');

  const update = () => {
    const _assigneeType = getAssigneeType({
      loginGuid,
      assignee,
    });
    setAssigneeType(_assigneeType);

    if (assignee) {
      const _timer = calculateTimeDifference(assignee.expireAtTimestamp);
      setTimer(_timer);
    } else {
      setTimer('');
    }
  };

  useEffect(() => {
    update();
  }, [`${assignee?.loginGuid}-${assignee?.expireAtTimestamp}`]);
  useInterval(
    () => {
      update();
    },
    assigneeType === null ? null : 1000,
  );

  return { timer, assigneeType };
};

export const calculateTimeDifference = (expireAt: number) => {
  const timeDifference = expireAt - new Date().getTime();
  if (timeDifference <= 0) return '';
  const { minutes, seconds } = intervalToDuration({
    start: 0,
    end: timeDifference,
  });
  const zeroPad = (num = 0) => String(num).padStart(2, '0');
  const time = `${zeroPad(minutes)} : ${zeroPad(seconds)}`;
  return time;
};

export const getAssigneeType = ({
  assignee,
  loginGuid,
}: {
  loginGuid: string;
  assignee?: BaseAssignee | null;
}): AssigneeType => {
  if (!assignee) return null;

  if (Date.now() > assignee.expireAtTimestamp) {
    return null;
  }
  if (assignee.loginGuid === loginGuid) return 'me';
  return 'anotherUser';
};
