import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useMediaQuery } from 'utils/useMediaQuery';

import StatsCard from './card';

const Stats = ({ stats }) => {
  const [md, setMd] = useState(3);
  const isPageWide = useMediaQuery('(max-width: 1360px)');

  useEffect(() => {
    if (isPageWide) {
      setMd(6);
    }
  }, [isPageWide]);

  return (
    <div className="stats">
      <Row className="statsRow">
        <Col
          sm={12}
          md={md}
          className="stats-item"
          style={{ marginBottom: '1rem' }}
        >
          <StatsCard
            title="Total Active Patient"
            subTitle={`Last Patient Added ${stats?.activePatients?.lastCreatedDate}`}
            value={stats?.activePatients?.totalCount}
            lastCreatedDateCount={stats?.activePatients?.lastCreatedDateCount}
            compare={stats?.activePatients?.comparePrev}
            chartValues={stats?.activePatients?.charts?.map((y, x) => ({
              x,
              y,
            }))}
          />
        </Col>
        <Col
          sm={12}
          md={md}
          className="stats-item"
          style={{ marginBottom: '1rem' }}
        >
          <StatsCard
            title="Adherence"
            subTitle="Yesterday’s Adherence"
            value={stats?.adherence}
            percentIcon
          />
        </Col>
        <Col sm={12} md={md} className="stats-item">
          <StatsCard
            title="At Risk Alerts"
            subTitle="Yesterday’s Trend To Overall Last Week"
            value={stats?.riskReport?.week}
            lastCreatedDateCount={stats?.riskReport?.day}
            compare={stats?.riskReport?.compare}
            changeColor
            chartValues={stats?.riskReport?.charts?.map((y, x) => ({ x, y }))}
          />
        </Col>
        <Col sm={12} md={md} className="stats-item">
          <StatsCard
            title="Critical Alerts"
            subTitle="Yesterday’s Trend To Overall Last Week"
            value={stats?.criticalReport?.week}
            lastCreatedDateCount={stats?.criticalReport?.day}
            compare={stats?.criticalReport?.compare}
            changeColor
            chartValues={stats?.criticalReport?.charts.map((y, x) => ({
              x,
              y,
            }))}
          />
        </Col>
      </Row>
      <Row className="statsRow">
        <Col sm={12} md={4} className="stats-item">
          <StatsCard
            title="Male / Systolic"
            value={stats?.maleReport?.systolic?.week}
            lastCreatedDateCount={stats?.maleReport?.systolic?.day}
            compare={stats?.maleReport?.systolic?.compare}
            changeColor
            chartValues={stats?.maleReport?.systolic?.charts.map((y, x) => ({
              x,
              y,
            }))}
          />
        </Col>
        <Col sm={12} md={4} className="stats-item">
          <StatsCard
            title="Male / Diastolic"
            value={stats?.maleReport?.diastolic?.week}
            lastCreatedDateCount={stats?.maleReport?.diastolic?.day}
            compare={stats?.maleReport?.diastolic?.compare}
            changeColor
            chartValues={stats?.maleReport?.diastolic?.charts.map((y, x) => ({
              x,
              y,
            }))}
          />
        </Col>
        <Col sm={12} md={4} className="stats-item">
          <StatsCard
            title="Male / Glucose"
            value={stats?.maleReport?.glucose?.week}
            lastCreatedDateCount={stats?.maleReport?.glucose?.day}
            compare={stats?.maleReport?.glucose?.compare}
            changeColor
            chartValues={stats?.maleReport?.glucose?.charts.map((y, x) => ({
              x,
              y,
            }))}
          />
        </Col>
      </Row>
      <Row className="statsRow">
        <Col sm={12} md={4} className="stats-item">
          <StatsCard
            title="Female / Systolic"
            value={stats?.femaleReport?.systolic?.week}
            lastCreatedDateCount={stats?.femaleReport?.systolic?.day}
            compare={stats?.femaleReport?.systolic?.compare}
            changeColor
            chartValues={stats?.femaleReport?.systolic?.charts.map((y, x) => ({
              x,
              y,
            }))}
          />
        </Col>
        <Col sm={12} md={4} className="stats-item">
          <StatsCard
            title="Female / Diastolic"
            value={stats?.femaleReport?.diastolic?.week}
            lastCreatedDateCount={stats?.femaleReport?.diastolic?.day}
            compare={stats?.femaleReport?.diastolic?.compare}
            changeColor
            chartValues={stats?.femaleReport?.diastolic?.charts.map((y, x) => ({
              x,
              y,
            }))}
          />
        </Col>
        <Col sm={12} md={4} className="stats-item">
          <StatsCard
            title="Female / Glucose"
            value={stats?.femaleReport?.glucose?.week}
            lastCreatedDateCount={stats?.femaleReport?.glucose?.day}
            compare={stats?.femaleReport?.glucose?.compare}
            changeColor
            chartValues={stats?.femaleReport?.glucose?.charts.map((y, x) => ({
              x,
              y,
            }))}
          />
        </Col>
      </Row>
    </div>
  );
};

Stats.propTypes = {
  stats: PropTypes.shape(),
};

export default Stats;
