import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_PATIENT_NOTE_CREATE } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';
import { useAppSelector } from 'store';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import {
  CLINICAL_NITES_PER_PAGE,
  getClinicalNotesKey,
} from './use-get-clinical-notes';

import type {
  IAddClinicNotes,
  IAddClinicNotesResponse,
  IClinicNotesResponse,
} from 'infrastructure/interfaces';

interface Props {
  page: number;
}

export const addClinicalNotesFn = async (postData: IAddClinicNotes) => {
  const { data, error } = await dataService.createOne<IAddClinicNotesResponse>(
    API_PATIENT_NOTE_CREATE,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useAddClinicalNotes = ({ page }: Props) => {
  const [startTime, endTime] = useAppSelector<[Date, Date] | []>(
    (state) => state.ccmPatientDetails.dates,
  );
  const queryClient = useQueryClient();

  const filter = {
    page,
    items: CLINICAL_NITES_PER_PAGE,
    startTime: DateFormatter({
      date: startTime,
      format: DateFormats['yyyy-MM-dd HH:mm:s'],
    }),
    endTime: DateFormatter({
      date: endTime,
      format: DateFormats['yyyy-MM-dd HH:mm:s'],
    }),
  };

  const {
    mutate: addClinicalNotes,
    mutateAsync: addClinicalNotesAsync,
    isLoading,
    ...other
  } = useMutation({
    mutationFn: addClinicalNotesFn,
    onSuccess(data, variables) {
      const queryKey = getClinicalNotesKey({
        ...filter,
        patientGuid: variables.patientGuid,
      });
      queryClient.setQueryData<IClinicNotesResponse>(queryKey, (oldData) => {
        if (oldData && data) {
          const newData = structuredClone(oldData);
          newData.count += 1;
          newData.items.unshift({
            author: data.author,
            createdAt: data.createdAt,
            guid: data.guid,
            note: data.note,
          });

          if (newData.items.length >= CLINICAL_NITES_PER_PAGE)
            newData.items.length = CLINICAL_NITES_PER_PAGE;

          return newData;
        }
        return oldData;
      });
      showResult();
    },
  });

  return {
    addClinicalNotes,
    addClinicalNotesAsync,
    addLoading: isLoading,
    ...other,
  };
};
