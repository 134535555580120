import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableClinicsFilters from 'components/molecules/clinics/expandable-filters';
import ClinicsTable from 'components/molecules/clinics/table';
import { useClinicQueryFilterNew } from 'infrastructure/hooks/clinic/use-clinic-query-filter';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import paths from 'constants/paths';
import BaseContainer from 'components/atoms/base-container';

import s from './styles.module.scss';

const AdminClinics: React.FC = () => {
  const { t } = useTranslation();

  const { count, filter, resetFilter, updateFilter } =
    useClinicQueryFilterNew();

  return (
    <BaseContainer>
      <AdminNavigationHeader
        goBackPath={paths.dashboard}
        title={t('labels.clinicsList')}
      />
      <div className={s.page}>
        <ExpandableClinicsFilters
          data={filter}
          updateFilter={updateFilter}
          resetFilter={resetFilter}
          count={count}
        />
        <ClinicsTable filters={filter} updateFilter={updateFilter} />
      </div>
    </BaseContainer>
  );
};

export default AdminClinics;
