import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';

import { updatePatientCcmStatusFn } from './api';

import type { IPatient } from 'infrastructure/interfaces';

interface Props {
  onSuccess?: () => void;
}

export const useUpdatePatientCcmStatus = ({ onSuccess }: Props = {}) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: updatePatientCcmStatusFn,
    onSuccess(data, variables) {
      const patientInfoKey = getPatientInfoKey(variables.patientGuid);
      queryClient.setQueryData<IPatient>(patientInfoKey, (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            ccmStatus: variables.dischargeStatus,
          };
        }
      });
      if (onSuccess) {
        onSuccess();
      }
    },
  });

  return { updateCcmStatus: mutateAsync, ccmStatusLoading: isLoading };
};
