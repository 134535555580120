import i18next from 'i18next';
import { Titles } from 'infrastructure/enums';

export interface ITitleItem {
  id: Titles;
  name: string;
}

export const titlesDataSource = (): ITitleItem[] => {
  return [
    {
      id: Titles.Mr,
      name: i18next.t('titles.Mr'),
    },
    {
      id: Titles.Mrs,
      name: i18next.t('titles.Mrs'),
    },
    {
      id: Titles.Ms,
      name: i18next.t('titles.Miss'),
    },
  ];
};
