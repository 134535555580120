import phoneNumbers from 'constants/phoneNumbers';
import { useDispatch } from 'react-redux';
import { makeid } from 'utils/helpers/makeid';
import { isPractice, isRoleNameOnboarding } from 'utils/userTypeHelper';
import { Availability } from 'infrastructure/classes/availability';
import { useTranslation } from 'react-i18next';

import { usePatientContactAvailability } from '../patient/use-patient-contact-availability';
import useAlert from '../utils/use-alert';

import type { Nullable } from 'infrastructure/types';

interface Props {
  patientGuid: string;
  doNotCall?: boolean;
  doctorPhone?: Nullable<string>;
  patientPhone?: string;
  patientHomeNumber?: string;
  emergencyContactNumber?: string;
  isCcmPanel: boolean;
  isRpmPanel: boolean;
  userType: string;
  roleNames: string[];
  patientTimeZone: string;
  setManualEnteredCallPopupOpen?: (arg: boolean) => void;
}

export const useCallButtons = ({
  patientGuid,
  doNotCall,
  patientPhone,
  patientHomeNumber,
  emergencyContactNumber,
  isCcmPanel,
  isRpmPanel,
  userType,
  roleNames,
  patientTimeZone,
  setManualEnteredCallPopupOpen,
}: Props) => {
  const {
    connect: { handleConnectPopoutOpen, connectDetails },
  } = useDispatch();

  const { availabilities } = usePatientContactAvailability({
    patientGuid,
  });
  const { Alert, showAlert } = useAlert();

  const { t } = useTranslation();

  const onClickConnect = async (
    phoneNumber?: string,
    isRpmCall?: boolean,
    isPatient: boolean = false,
  ) => {
    const isAvailable = Availability.isAvailable(
      availabilities,
      patientTimeZone,
    );

    if (isPatient && !isAvailable) {
      const confirmed = await showAlert({
        title: t('confirm.title'),
        messageTitle: t('confirm.patientIsNotAvailable'),
        type: 'primary',
      });

      if (!confirmed) return;
    }

    const details = {
      phone: phoneNumber,
      guid: patientGuid,
      isRpmCall,
    };
    connectDetails(details);
    handleConnectPopoutOpen(true);
  };

  const callButtons = [
    {
      id: makeid(5),
      render: true,
      label: 'Call patient primary phone number',
      disabled: doNotCall,
      handleClick: () => onClickConnect(patientPhone, isRpmPanel, true),
    },
    {
      id: makeid(5),
      render: patientHomeNumber,
      label: 'Call patient secondary phone number',
      disabled: doNotCall,
      handleClick: () => onClickConnect(patientHomeNumber, isRpmPanel, true),
    },
    {
      id: makeid(5),
      render: emergencyContactNumber,
      label: 'Call emergency contact',
      disabled: doNotCall,
      handleClick: () => onClickConnect(emergencyContactNumber, isRpmPanel),
    },
    {
      id: makeid(5),
      render: !isPractice(userType) || isRoleNameOnboarding(roleNames),
      label: 'Call translation service',
      disabled: doNotCall,
      handleClick: () =>
        onClickConnect(phoneNumbers.TRANSLATION_SERVICE_NUMBER, isRpmPanel),
    },
    {
      id: makeid(5),
      render:
        typeof setManualEnteredCallPopupOpen === 'function' &&
        (isCcmPanel || isRpmPanel),
      label: 'Manual-entered call',
      disabled: false,
      handleClick: () => setManualEnteredCallPopupOpen?.(true),
    },
  ].filter(({ render }) => render);

  return { callButtons, callButtonsAlert: Alert };
};
