import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { PlatformEnums } from 'infrastructure/enums';

import { getSmsDashboardConversationsKey } from './useConversations';
import { useSmsDashboardUnreadCountKey } from './useUnreadConversationsCount';

import type {
  IConversation,
  IConversationsListPages,
  IUnreadConversationsCount,
} from 'infrastructure/interfaces';
import type { RootState } from 'store';

interface ISocketData {
  conversationId: number;
  read: boolean;
  rpmTotalUnreadCount: number;
  ccmTotalUnreadCount: number;
  unregisteredTotalUnreadCount: number;
}

export const useUpdateConversationState = () => {
  const queryClient = useQueryClient();
  const { conversationType, filters } = useSelector(
    (state: RootState) => state.smsDashboard,
  );
  const updateConversationState = (socketData: ISocketData) => {
    const сonversationKey = getSmsDashboardConversationsKey({
      type: conversationType,
      read: filters.read,
      closed: filters.closed,
      lastMessageDate: filters.lastMessageDate,
      searchQuery: filters.searchQuery,
    });

    const smsDashboardUnreadCountKey = useSmsDashboardUnreadCountKey();

    const conversations =
      queryClient.getQueryData<IConversationsListPages>(сonversationKey);

    const pages = conversations?.pages ?? [];
    const [firstPage, ...restPages] = pages;
    let platform = '';

    pages.forEach((page) => {
      const index = page.items.findIndex(
        (item: IConversation) => item.id === socketData.conversationId,
      );
      if (index !== -1) {
        platform = page.items[index].type;
        page.items[index] = {
          ...page.items[index],
          read: socketData.read,
          unreadMessageCount: 0,
          closed: false,
        };
      }
    });

    const calculate = (number: number) => {
      if (socketData.read === true) return number - 1;
      if (socketData.read === false) return number + 1;
      return number;
    };

    queryClient.setQueryData<IUnreadConversationsCount>(
      smsDashboardUnreadCountKey,
      (oldData) => {
        if (!oldData) return;
        return {
          ...oldData,
          rpmTotalUnreadCount:
            platform === PlatformEnums.RPM
              ? calculate(socketData.rpmTotalUnreadCount)
              : socketData.rpmTotalUnreadCount,
          ccmTotalUnreadCount:
            platform === PlatformEnums.CCM
              ? calculate(socketData.ccmTotalUnreadCount)
              : socketData.ccmTotalUnreadCount,
          unregisteredTotalUnreadCount:
            platform === PlatformEnums.UNREGISTERED
              ? calculate(socketData.unregisteredTotalUnreadCount)
              : socketData.unregisteredTotalUnreadCount,
        };
      },
    );

    const updatedPages = [firstPage, ...restPages];

    queryClient.setQueryData<IConversationsListPages>(
      сonversationKey,
      (oldConversations) => {
        if (!oldConversations) return;
        return { ...oldConversations, pages: updatedPages };
      },
    );
  };

  return { updateConversationState };
};
