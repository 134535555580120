import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { RadialChart } from 'react-vis';
import randomColor from 'randomcolor';

const colors = {};

const InsuranceTypeChart = ({ insuranceBlocks }) => {
  const getColorByKey = (key) => {
    if (!colors[key]) colors[key] = randomColor();
    return colors[key];
  };

  const chartData = useMemo(
    () =>
      Object.keys(insuranceBlocks).map((key) => ({
        angle: parseInt(insuranceBlocks[key].count, 10),
        label: insuranceBlocks[key].provider,
        color: getColorByKey(insuranceBlocks[key].provider),
        value: insuranceBlocks.count,
      })),
    [insuranceBlocks],
  );

  return (
    <div className="onboarding-chart">
      <RadialChart
        data={chartData}
        width={220}
        height={220}
        colorType="literal"
      />
      <div className="onboarding-chart-legend">
        {Object.keys(chartData).map((item) => (
          <div key={item} className="onboarding-chart-legend-item">
            <div
              style={{ backgroundColor: chartData[item].color }}
              className="onboarding-chart-legend-item-dot"
            />
            <div className="onboarding-chart-legend-item-title">
              {chartData[item].label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

InsuranceTypeChart.propTypes = {
  insuranceBlocks: PropTypes.array.isRequired,
};

export default InsuranceTypeChart;
