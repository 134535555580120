import { useQuery } from '@tanstack/react-query';
import common from 'constants/common';
import { format, startOfMonth } from 'date-fns';
import dataService from 'services/dataService';
import { API_APPOINTMENT_COMPLETIONS } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';

import type { appointmentMethodMap } from 'constants/common';

interface Props {
  patientGuid?: string;
  endDate?: Date;
}

interface IItem {
  guid: string;
  patientGuid: string;
  completedDate: string;
  completedBy: string;
  appointmentMethod: keyof typeof appointmentMethodMap;
}

interface IResponse {
  count: number;
  items: IItem[];
}

const getAppointmentListFn = async ({ patientGuid, endDate }: Props) => {
  if (!patientGuid || !endDate) return undefined;

  const date = format(
    startOfMonth(endDate),
    common.dateFormats.priorAuthorizationAdmin,
  );
  const params = new URLSearchParams({ date });

  const { data, error } = await dataService.getList<IResponse>(
    `${API_APPOINTMENT_COMPLETIONS}/${patientGuid}?${params}`,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useGetAppointmentList = ({ patientGuid, endDate }: Props) => {
  const { data, isFetching } = useQuery({
    queryKey: ['dateRangeAppointmentList', patientGuid, endDate],
    queryFn: () => getAppointmentListFn({ patientGuid, endDate }),
    enabled: Boolean(patientGuid) && Boolean(endDate),
  });
  return { appointmentList: data, isFetching };
};
