import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { showResult } from 'infrastructure/hooks/utils';
import { isFuture } from 'date-fns';

import { updatePatientHoldingStatusFn } from './api';

import type { IPatient } from 'infrastructure/interfaces';

interface Props {
  onSuccess: () => void;
}

export const useUpdatePatientHoldingStatus = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: updatePatientHoldingStatusFn,
    onSuccess(data, variables) {
      const patientInfoKey = getPatientInfoKey(variables.patientGuid);
      queryClient.setQueryData<IPatient>(patientInfoKey, (oldData) => {
        if (oldData) {
          const isStartInFuture =
            variables.startTime &&
            isFuture(new Date(Number(variables.startTime)));

          const nextStatus = isStartInFuture ? variables.holdingStatus : null;

          let status: string | null = oldData.holdingStatus?.status || '';

          if (!variables.holdingStatus || isStartInFuture) status = 'active';
          if (variables.holdingStatus && !nextStatus)
            status = variables.holdingStatus;

          const res = {
            ...oldData,
            holdingStatus: {
              status,
              startTime: variables.startTime
                ? new Date(Number(variables.startTime)).toISOString()
                : undefined,
              endTime: variables.endTime
                ? new Date(Number(variables.endTime)).toISOString()
                : undefined,
              nextStatus,
            },
          };
          return res;
        }
      });
      showResult();
      onSuccess();
    },
  });

  return { updateHoldingStatus: mutate, holdingStatusLoading: isLoading };
};
