import { useState } from 'react';
import { EditorLog } from 'infrastructure/classes/logs/editor-log';
import { useQuery } from '@tanstack/react-query';
import { prepareLogsDateRange } from 'infrastructure/functions/logs';

import useApi from '../use-api';

import type { DateRange } from 'infrastructure/types';
import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IPaginatedEditorLogs,
} from 'infrastructure/interfaces';
import type { Dispatch, SetStateAction } from 'react';

const editorListLogsApi = (patientGuid: string) =>
  `admins/admin-panel/patients/${patientGuid}/audit-logs`;

export interface IEditorLogMeta {
  reverse?: boolean;
  dateRange?: DateRange;
}

type mergedMeta = IEditorLogMeta & IPaginationMeta;

type UseLog = {
  loading: boolean;
  meta: mergedMeta;
  items: Array<EditorLog>;
  setMeta: Dispatch<SetStateAction<mergedMeta>>;
};

interface IEditorLogProps {
  patientGuid: string;
  meta?: IEditorLogMeta & IPartialPaginationMeta;
}

export const useEditorLog = (props: IEditorLogProps): UseLog => {
  const { patientGuid, meta: metaProps } = props;

  const [meta, setMeta] = useState<mergedMeta>({
    page: 1,
    items: 15,
    totalCount: 0,
    ...metaProps,
  });

  const { loadData } = useApi();

  const queryKey = [
    editorListLogsApi(patientGuid),
    prepareLogsDateRange(meta.dateRange),
    meta.page,
  ];

  const { data = [], isLoading } = useQuery({
    queryKey,
    queryFn: () =>
      loadData<IPaginatedEditorLogs, Omit<IEditorLogMeta, 'sortField'>>(
        editorListLogsApi(patientGuid),
        {
          sortField: JSON.stringify(meta.sortField),
          page: meta.page,
          items: meta.items,
          ...prepareLogsDateRange(meta.dateRange),
        },
      ).then((res) => {
        if (res) {
          const { count, data: rows } = res;

          setMeta({
            ...meta,
            totalCount: count,
          });

          return rows.map((el) => new EditorLog(el));
        }
      }),
    enabled: Boolean(patientGuid),
  });

  return {
    items: data,
    loading: isLoading,
    meta,
    setMeta,
  };
};
