import { init } from '@rematch/core';
import loadingPlugin from '@rematch/loading';
import { useDispatch, useSelector } from 'react-redux';

import { models } from './models';

import type { RematchDispatch, RematchRootState } from '@rematch/core';
import type { ExtraModelsFromLoading } from '@rematch/loading';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootModel } from './models';

type FullModel = ExtraModelsFromLoading<RootModel>;
const store = init<RootModel, FullModel>({
  plugins: [loadingPlugin()],
  models,
});

export type Store = typeof store;
export type RootDispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;
export default store;

export type AppRootState = ReturnType<Store['getState']>;
export type AppDispatch = Store['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppRootState> = useSelector;
