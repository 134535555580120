import i18next from 'i18next';
import { BatteriesConsumables, ReadingType } from 'infrastructure/enums';

export interface IBatteriesConsumablesItem {
  id: BatteriesConsumables;
  name: string;
}

interface IProps {
  readingType?: ReadingType;
}

export const batteriesConsumablesDataSource = ({
  readingType,
}: IProps): IBatteriesConsumablesItem[] => {
  const items: IBatteriesConsumablesItem[] = [];

  const batteriesMap = {
    [BatteriesConsumables.aa]: {
      id: BatteriesConsumables.aa,
      name: i18next.t('consumables.aa'),
    },
    [BatteriesConsumables.aaa]: {
      id: BatteriesConsumables.aaa,
      name: i18next.t('consumables.aaa'),
    },
  };

  if (
    readingType === ReadingType.Temperature ||
    readingType === ReadingType.BloodPressure
  ) {
    items.push(batteriesMap[BatteriesConsumables.aa]);
  } else if (
    readingType === ReadingType.SpO2 ||
    readingType === ReadingType.PeakFlow
  ) {
    items.push(batteriesMap[BatteriesConsumables.aaa]);
  } else {
    items.push(
      batteriesMap[BatteriesConsumables.aa],
      batteriesMap[BatteriesConsumables.aaa],
    );
  }

  return items;
};
