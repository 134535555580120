import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableFilterPanel from 'components/molecules/expandable-filter-panel';
import AgencySelectBox from 'components/molecules/agency-select-box';
import ServiceDeliveryThresholdSelectBox from 'components/molecules/service-delivery-threshold-select-box';
import BaseInput from 'components/atoms/base-input';
import ParentClinicSelectBox from 'components/molecules/parent-clinic-select-box';

import s from './styles.module.scss';
import ClinicStatusSelectBox from '../status-select-box';

import type { FC, PropsWithChildren } from 'react';
import type { IClinicListFilters } from 'infrastructure/interfaces';

interface IProps {
  data: IClinicListFilters;
  updateFilter: (data: IClinicListFilters) => void;
  resetFilter: () => void;
  count: number;
}

const Item: FC<PropsWithChildren<{ label: string }>> = ({
  children,
  label,
}) => {
  return (
    <div className={s.item}>
      <label className={s.label}>
        <span>{label}</span>
        {children}
      </label>
    </div>
  );
};

const ExpandableClinicsFilters: React.FC<IProps> = (props) => {
  const { data, count, updateFilter, resetFilter } = props;

  const { t } = useTranslation();

  return (
    <ExpandableFilterPanel
      filtersCount={count}
      onReset={resetFilter}
      openWithValue
      isExpanded
    >
      <div className={s.form}>
        <Item label={t('labels.clinic')}>
          <BaseInput
            placeholder={t('labels.legalName')}
            allowClear
            value={data?.legalname}
            onChange={(legalname) => {
              updateFilter({ legalname, page: undefined });
            }}
          />
        </Item>
        <Item label={t('labels.parentClinicFilter')}>
          <ParentClinicSelectBox
            allowClear
            value={data?.parentClinicGuid}
            onChange={(parentClinicGuid) => {
              updateFilter({ parentClinicGuid, page: undefined });
            }}
          />
        </Item>
        <Item label={t('labels.agency')}>
          <AgencySelectBox
            placeholder={t('labels.legalName')}
            allowClear
            value={data?.agencyGuid}
            onChange={(agencyGuid) => {
              updateFilter({ agencyGuid, page: undefined });
            }}
          />
        </Item>
        <Item label={t('labels.clinicStatus')}>
          <ClinicStatusSelectBox
            withAll
            value={data?.status}
            onChange={(status) => {
              updateFilter({ status });
            }}
          />
        </Item>
        <Item label={t('labels.slaStatus')}>
          <ServiceDeliveryThresholdSelectBox
            withAll
            value={data?.serviceDeliveryThresholds ?? 'All'}
            onChange={(serviceDeliveryThresholds) => {
              updateFilter({ serviceDeliveryThresholds, page: undefined });
            }}
          />
        </Item>
      </div>
    </ExpandableFilterPanel>
  );
};

export default ExpandableClinicsFilters;
