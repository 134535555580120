import React from 'react';
import { Spin } from 'antd';

import BaseLoader from '../base-loader';

interface IBaseSpinWrapperProps {
  children?: React.ReactNode;
  spinning: boolean;
  tip?: string;
  size?: 'small' | 'default' | 'large';
}

const BaseSpinWrapper: React.FC<IBaseSpinWrapperProps> = (props) => {
  const { children, spinning, tip = '', size = 'default' } = props;

  let sizes = {
    width: 30,
    height: 30,
  };

  if (size === 'small') {
    sizes = {
      width: 20,
      height: 20,
    };
  }
  if (size === 'large') {
    sizes = {
      width: 40,
      height: 40,
    };
  }

  return (
    <Spin
      tip={tip}
      spinning={spinning}
      indicator={<BaseLoader className="loader" {...sizes} loading />}
      size={size}
    >
      {children || <div />}
    </Spin>
  );
};

export default BaseSpinWrapper;
