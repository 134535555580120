import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminPanelAgencies from 'components/templates/admin/panel/agencies';

const AdminAgencies = () => {
  const adminPanelAgencies = useSelector((state) => state.adminPanelAgencies);
  const {
    adminPanelAgencies: { getAgencies, reset: resetAgencies },
  } = useDispatch();

  const getAgenciesCallBack = useCallback(
    (signal) => {
      getAgencies({ signal });
    },
    [adminPanelAgencies.filters],
  );

  useEffect(() => {
    resetAgencies();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    getAgenciesCallBack(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [getAgenciesCallBack]);

  const adminAgencies = useSelector((state) => state.adminPanelAgencies.data);
  const { getAgencies: loading } = useSelector(
    (state) => state.loading.effects.adminPanelAgencies,
  );

  return (
    <Container>
      <AdminHeader />
      <AdminPanelAgencies agencies={adminAgencies} loading={loading} />
    </Container>
  );
};

export default AdminAgencies;
