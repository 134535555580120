import React from 'react';
import { Tag } from 'antd';
import { convertHexToRgb } from 'infrastructure/functions/convert-hex-to-rgb';
import { hexRegExp } from 'infrastructure/regexp';

interface IBaseTagProps {
  label?: string;
  color?: string;
  width?: string;
  dataCy?: string;
  transparent?: boolean;
  size?: 'default' | 'large';
}

const BaseTag: React.FC<IBaseTagProps> = (props) => {
  const {
    label,
    color: colorProps,
    dataCy,
    width,
    transparent,
    size = 'default',
  } = props;

  const isValidHex = colorProps ? hexRegExp.test(colorProps) : false;

  const color = colorProps ? convertHexToRgb(colorProps, 0.1) : '';

  const isDefault = size === 'default';

  const baseStyles: React.CSSProperties = {
    padding: isDefault ? '1.5px 8px' : '10px 15px',
    fontWeight: isDefault ? 400 : 700,
    fontSize: isDefault ? '12px' : '14px',
    borderRadius: isDefault ? '4px' : '8px',
  };

  const styles: React.CSSProperties = {
    ...baseStyles,
    width,
    color: transparent ? colorProps : 'white',
    border: `1px solid ${colorProps}`,
    background: transparent ? color : colorProps,
    textAlign: 'center',
    margin: '0',
  };

  if (!transparent) styles.boxShadow = `0px 0px 100px 0px ${color} inset`;

  const params = {
    style: isValidHex ? styles : baseStyles,
    color: isValidHex ? undefined : color,
  };

  return (
    <Tag data-cy={dataCy} bordered {...params}>
      {label}
    </Tag>
  );
};

export default BaseTag;
