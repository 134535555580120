import PropTypes from 'prop-types';

import BaseLoader from '../base-loader';

const StopIcon = ({ loading }) => {
  const size = 10;
  return (
    <>
      {!loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: size,
            height: size,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              width: 8,
              height: 8,
              borderRadius: '2px',
            }}
          />
        </div>
      )}
      {loading && <BaseLoader width={10} height={10} loading />}
    </>
  );
};

StopIcon.propTypes = {
  loading: PropTypes.bool,
};

export default StopIcon;
