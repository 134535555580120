export const formSections = [
  {
    title: 'Patient Info',
    forms: [
      {
        type: 'TEXT',
        title: 'Doctor (confirm they are still seeing this provider)',
        id: 'doctor',
      },
      {
        type: 'TEXT',
        title: 'Patient Age',
        id: 'age',
      },
      {
        type: 'MULTIPLE_CHOICE',
        title: 'Patient Gender',
        id: 'gender',
        hasOtherOption: false,
        choices: ['Male', 'Female'],
      },
    ],
  },
  {
    title: 'Past Medical History',
    forms: [
      {
        type: 'TEXT',
        title:
          'ANSWER ONLY FOR ONBOARDING APPOINTMENTS\nSurgical, Procedures, Other \n(Add all other medical history, including other diagnosis not listed above)',
        id: 'dx1',
        requiredIf: { key: 'appttype', value: 'onboarding' },
      },
      {
        type: 'CHECKBOX',
        title: 'Allergies',
        id: 'allergies',
        isRequired: true,
        hasOtherOption: true,
        choices: [
          'no known',
          'NSAIDS',
          'penicillin',
          'sulfa',
          'iodine/radioactive dye',
          'opiates',
          'latex',
        ],
      },
    ],
  },
  {
    title: 'Current Patient Status',
    forms: [
      {
        type: 'CHECKBOX',
        title: 'Patient Representative',
        id: 'patientcaregiver',
        hasOtherOption: true,
        otherOptionLabel: 'Patient’s Spouse or Caregivers name: ',
        isRequired: true,
        choices: ['Patient', "Patient's spouse", "Patient's caregiver"],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title:
          'Patient is Alert, Oriented and Coherent \n1: Person\n2: Person, place\n3: Person, place, time\n4: Person, place, time, situation',
        id: 'mentalstatus',
        isRequired: true,
        choices: ['1', '2', '3', '4'],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title:
          'ANXIETY Q1: Ask if patient is feeling nervous, anxious or on edge\n(3 or above between both questions = cutoff for Generalized Anxiety Disorder)',
        id: 'nervous',
        isRequired: true,
        choices: [
          'not at all (0)',
          'several days (+1)',
          'more than half the days (+2)',
          'nearly every day (+3)',
        ],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title:
          'ANXIETY Q2: Ask the patient if they are not able to stop or control worrying\n(3 or above between both questions = cutoff for Generalized Anxiety Disorder)',
        id: 'worry',
        isRequired: true,
        choices: [
          'not at all (0)',
          'several days (+1)',
          'more than half the days (+2)',
          'nearly every day (+3)',
        ],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title:
          'ANXIETY SCORE\nNote if they scored above 3 total on either the anxiety or depression questions.',
        id: 'anxiety',
        isRequired: true,
        choices: ['below 3', '3+'],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title:
          'DEPRESSION Q1: Ask the patient if they have little interest or pleasure in doing things\n(3 or above between both questions = cutoff for major depressive disorder)',
        id: 'interest',
        isRequired: true,
        choices: [
          'not at all (0)',
          'several days (+1)',
          'more than half the days (+2)',
          'nearly every day (+3)',
        ],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title:
          'DEPRESSION Q2: Ask the patient if they are feeling down, depressed, or hopeless\n(3 or above between both questions = cutoff for major depressive disorder)',
        id: 'down',
        isRequired: true,
        choices: [
          'not at all (0)',
          'several days (+1)',
          'more than half the days (+2)',
          'nearly every day (+3)',
        ],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title:
          'DEPRESSION SCORE\nNote if they scored above 3 total on either the anxiety or depression questions.',
        id: 'depression',
        isRequired: true,
        choices: ['below 3', '3+'],
      },
      {
        type: 'COMPLEX',
        title:
          'Most recent past appointment(s) (Click to plus to add more recent past appointments)',
        id: 'pastapptcontainer',
        subForms: [
          {
            id: 'pastapptprovidertype',
            title: 'Past Appt Provider Type',
            type: 'SELECT',
            choices: [
              'PCP',
              'dermatologist',
              'cardiologist',
              'endocrinologist',
              'gastroenterologist',
              'oncologist',
              'nephrologist',
              'psychologist/psychiatrist',
              'pulmonologist',
              'rheumatologist',
              'urologist',
            ],
          },
          {
            id: 'pastapptprovidername',
            title: 'Past Appt Provider Name',
            type: 'TEXT',
          },
          {
            id: 'pastappttime',
            title: 'Past Appt Timeframe',
            type: 'SELECT',
            choices: [
              'within the last month',
              'within the last 3 months',
              'within the last 6 months',
            ],
          },
        ],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title: 'Any labs or results pending?\nIf yes, specify in "Other"',
        id: 'labs',
        isRequired: true,
        hasOtherOption: true,
        choices: ['no labs or study'],
      },
      {
        type: 'COMPLEX',
        title:
          'Upcoming appointment(s) (Click to plus to add more upcoming appointments)',
        id: 'upcomingapptcontainer',
        subForms: [
          {
            id: 'upcomingapptprovidertype',
            title: 'Upcoming Appt Provider Type',
            type: 'SELECT',
            choices: [
              'PCP',
              'dermatologist',
              'cardiologist',
              'endocrinologist',
              'gastroenterologist',
              'oncologist',
              'nephrologist',
              'psychologist/psychiatrist',
              'pulmonologist',
              'rheumatologist',
              'urologist',
            ],
          },
          {
            id: 'upcomingapptprovidername',
            title: 'Upcoming Appt Provider Name',
            type: 'TEXT',
          },
          {
            id: 'upcomingappttime',
            title: 'Upcoming Appt Timeframe',
            type: 'SELECT',
            choices: [
              'within the next month',
              'within the next 3 months',
              'within the next 6 months',
            ],
          },
        ],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title: 'Is the patient taking all of their medications as prescribed?',
        id: 'medications',
        isRequired: true,
        choices: ['are', 'are not'],
      },
      {
        type: 'CHECKBOX',
        title: 'If non-compliant, specify why',
        id: 'medwhy',
        hasOtherOption: true,
        choices: [
          'The patient cannot afford medication',
          'The patient does not have transportation to pick up medication',
          'The patient needs refills of medication',
          'The patient requires assistance with medication',
          'The patient needs reminders to take medication regularly',
        ],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title:
          "Did you review and confirm the patient's active medications listed in their chart?",
        id: 'activemedications',
        isRequired: true,
        choices: ['Yes', 'No, patient was not willing to discuss'],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title:
          'Any changes in medication?\n(If yes, describe in Other. Ex: dosage, frequency, discontinue/inactive)',
        id: 'medchanges',
        isRequired: true,
        choices: ['no changes in medication'],
        hasOtherOption: true,
      },
      {
        type: 'MULTIPLE_CHOICE',
        title: 'Falls in the past 30 days',
        id: 'falls',
        isRequired: true,
        choices: ['no falls', '1 fall', '2 falls', '3+ falls'],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title: 'Fall type',
        id: 'falltype',
        choices: [
          'without injury',
          'with injury, but required no medical attention',
          'with injury requiring medical assistance',
        ],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title: 'Any Emergency Room visits in the past 30 days',
        id: 'emergency',
        isRequired: true,
        choices: [
          'no emergency room visits',
          '1 emergency room visit',
          '2 emergency room visits',
          '3+ emergency room visits',
        ],
      },
      {
        type: 'TEXT',
        title: 'Emergency Room visit details',
        id: 'ERdetail',
      },
      {
        type: 'TEXT',
        title:
          'Last RPM reading (Ex: Blood Pressure: 120/72 mmHg Pulse: 64 bpm)',
        id: 'rpm',
      },
      {
        type: 'CHECKBOX',
        title: 'Actions needed related to RPM readings',
        id: 'vitals',
        choices: [
          'Education given on the importance of taking their readings every day',
          "Education given on the patient's acceptable parameters",
          'Education given on therapeutic range of medication',
          'Performed troubleshoot of device with patient',
          'Created a support ticket for replacement device',
          'Escalated to appropriate physician',
          "Patient's readings are within acceptable threshold",
        ],
      },
    ],
  },
  {
    title: 'Narrative',
    forms: [
      {
        type: 'MULTIPLE_CHOICE',
        title: 'Is the patient experiencing any pain?',
        id: 'pain',
        hasOtherOption: false,
        isRequired: true,
        choices: ['Yes', 'No'],
      },
      {
        type: 'CHECKBOX',
        title: 'Location of pain (ex: click right side, type knee into Other)',
        id: 'location',
        hasOtherOption: true,
        otherOptionLabel: 'Location: ',
        requiredIf: { key: 'pain', value: 'Yes' },
        choices: [
          'is located on the right',
          'is located on the left',
          'is located bilateral',
          'is generalized',
        ],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title: 'Severity of pain',
        id: 'severity',
        requiredIf: { key: 'pain', value: 'Yes' },
        choices: ['1-4 (mild)', '5-7 (moderate)', '8-10 (severe)'],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title:
          'Is the patient taking medications for pain management?\n(If yes, specify dose, route, and frequency in Other)',
        id: 'painmeds',
        hasOtherOption: true,
        requiredIf: { key: 'pain', value: 'Yes' },
        choices: ['no medication'],
      },
      {
        type: 'CHECKBOX',
        title: 'How many hours is patient sleeping on average?',
        id: 'sleep',
        choices: [
          'less than 4 hours',
          '4-6 hours',
          '6-8 hours',
          '8-10 hours',
          '10-12 hours',
          '12+ hours',
          'and experiences insomnia',
          'and has a sporadic sleep pattern',
          'and has hypersomnia',
          'and experiences fatigue',
          'and regularly takes naps throughout the day',
          'managed with the use of prescription medication',
          'managed with the use of OTC medication/supplements',
        ],
      },
      {
        type: 'CHECKBOX',
        title: 'Respiratory: Does the patient have any respiratory concerns?',
        id: 'respiratory',
        choices: [
          'no respiratory concerns',
          'congestion',
          'cough',
          'mucus',
          'pain with breathing',
          'shortness of breath',
          'trouble breathing at night',
          'impacting the ability to perform activities',
          'is an active smoker',
          'has a history of smoking',
          'requiring head to be elevated',
          'requiring the use of CPAP/BIPAP',
          'requiring the use of oxygen',
          'requiring the use of spirometer/peak flow meter',
          'escalated to provider',
          'provider is aware',
        ],
      },
      {
        type: 'CHECKBOX',
        title: 'Appetite\n(ex: click poor, click requiring use of supplements)',
        id: 'appetite',
        hasOtherOption: true,
        choices: [
          'poor (1 meal a day)',
          'good (2-3 meals a day)',
          'overeats (4+ meals a day)',
          'requiring use of banana bag',
          'requiring use of supplements',
          'requiring use of tube feeding (g-tube/peg tube)',
          'managing with small, frequent meals',
        ],
      },
      {
        type: 'CHECKBOX',
        title: 'Nutrition: What foods does the patient regularly consume?',
        id: 'nutrition',
        hasOtherOption: true,
        choices: [
          'alcohol',
          'carbohydrates',
          'coffee',
          'fruits',
          'processed food',
          'proteins',
          'snack food',
          'vegetables',
          'and follows a renal diet (low sodium, phosphorous, and protein)',
          'and follows a diabetic diet (nutrient rich, low fat, little processed foods)',
          'and follows a bariatric diet',
          'and follows a dash diet (low sodium, fat free/low fat products, limited added sugar and saturated fat)',
        ],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title: 'Water Intake',
        id: 'water',
        hasOtherOption: true,
        choices: [
          'little to no water daily',
          '1-2 glasses of water a day',
          '3-4 glasses of water a day',
          '5+ glasses of water a day',
        ],
      },
      {
        type: 'CHECKBOX',
        title:
          'Bowel (Add instruction/action in Other. Ex: managed with medication and escalated to provider)',
        id: 'bowel',
        hasOtherOption: true,
        choices: [
          'none',
          'constipation',
          'cramping',
          'diarrhea',
          'fissure',
          'heartburn',
          'hemorrhoid',
          'hernia',
          'incontinence',
          'indigestion',
          'irritable bowel',
          'lactose intolerance',
          'nausea',
          'painful defecation',
          'rectal bleeding',
          'requiring the use of briefs',
          'requiring the use of chux',
          'requiring the use of pads',
          'requiring the use of a skin barrier',
          'escalated to provider',
          'provider is aware',
        ],
      },
      {
        type: 'CHECKBOX',
        title:
          'Bladder (Add instruction/action in Other. Ex: managed with medication and escalated to provider)',
        id: 'bladder',
        hasOtherOption: true,
        choices: [
          'none',
          'catheter',
          'dysuria',
          'frequency',
          'hematuria',
          'incontinence',
          'nocturia',
          'prolapse',
          'retention',
          'urgency',
          'UTI',
          'requiring the use of briefs',
          'requiring the use of catheter supplies',
          'requiring the use of chux',
          'requiring the use of pads',
          'requiring the use of a skin barrier',
          'escalated to provider',
          'provider is aware',
        ],
      },
      {
        type: 'CHECKBOX',
        title:
          'Skin Conditions/Concerns (Add detail in Other. Ex: click bruising, click Other, type on right arm)',
        id: 'skin',
        hasOtherOption: true,
        choices: [
          'no skin concerns',
          'area hot to touch',
          'area of concern',
          'bruising',
          'dryness',
          'eczema',
          'edema',
          'hives',
          'hyperpigmentation',
          'lesions',
          'open wounds',
          'psoriasis',
          'rash',
          'redness to extremities',
          'scaling',
          'sores',
          'managed with the use of medication',
          'managed with the use of ointment',
          'escalated to provider',
          'provider is aware',
        ],
      },
      {
        type: 'CHECKBOX',
        title: 'Does patient use any other DMEs to perform ADLs?',
        id: 'DME',
        hasOtherOption: true,
        choices: [
          'none',
          'adaptive utensils',
          'bedside commode',
          'cane',
          'elevated toilet seat',
          'grab bars',
          'hospital bed',
          'nebulizer',
          'shower bench/tub chair',
          'walker',
          'wheelchair',
        ],
      },
      {
        type: 'CHECKBOX',
        title: 'Are there any safety concerns?',
        id: 'safety',
        hasOtherOption: true,
        choices: [
          'none',
          'abuse (reported to manager and proper authorities)',
          'aspiration',
          'at risk for medication errors',
          'exploitation (reported to manager and proper authorities)',
          'fall risk',
          'medication noncompliance',
          'neglect (reported to manager and proper authorities)',
          'oxygen',
          'skin break down',
        ],
      },
    ],
  },
  {
    title: 'Final Section',
    forms: [
      {
        type: 'MULTIPLE_CHOICE',
        title: 'Review of previous month’s goals resulted in:',
        id: 'prevmonthgoals',
        isRequired: true,
        choices: [
          'their goals have been attained',
          'they are making progress, the patient is on track and would like to have goals/outcomes ongoing at this time',
          'their current goals are unattainable and would like to set new goals',
        ],
      },
      {
        type: 'CHECKBOX',
        title:
          'Health Goals (MUST add expected outcomes. If the patient will be continuing last month\'s goals, in "Other" below, document in full sentences the expected outcomes for this month)',
        id: 'goals',
        hasOtherOption: true,
        isRequired: true,
        delimeter: ' ',
        choices: [
          'The patient will participate in physical activity within their capabilities for a minimum of 30 mins, at least 3 times per week for the next 4 weeks. Outcome expected: the patient will achieve an improved condition of physical state.',
          'The patient will not eat fast food more than 3 times per week for the next 4 weeks to help make better food choices in order to help maintain or lower current BP readings. Outcome expected: reduction in the patient blood pressure readings to see reduction in number of critical and at risk readings.',
          'The patient will take daily blood pressure readings for the next month. Outcome expected: reduction in the patient blood pressure readings to see reduction in number of critical and at risk readings.',
          'The patient will monitor blood sugar a minimum of 1 time each day for the next month to learn trends in blood sugar and how food choices affect these readings. Outcome expected: patient will identify factors that may contribute to unstable glucose.',
          "The patient will make healthier food choices and lose 3 or more pounds in the next 4 weeks. Outcome expected: patient's calorie intake will be reduced by making healthier choices, which leads to weight loss.",
          'The patient will take a daily weight for the next 4 weeks and keep a record so we can discuss trends in weight and provide education about what those trends mean in regards to CHF. Outcome expected: the patient will be able to maintain adequate cardiac output.',
          'The patient will begin practicing meditation to help control anxiety for a minimum of 15 mins, 3 days per week for the next month. Outcome expected: patient will appear calm and relaxed with vital signs within normal limits.',
          'The patient will use progressive relaxation techniques for pain relief at each occurrence of pain for a minimum of 15 minutes for the next 4 weeks. Outcome expected: patient will have vital signs within normal limits after experiencing a reduction in pain.',
          'The patient will be aware of fall risk and will practice fall risk exercises at a minimum of 3 times per week for the next 4 weeks in order to reduce risk of falls. The goal is 0 falls for the next 4 weeks. Outcome expected: patient will increase strength and balance reducing the number of falls to zero.',
        ],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title: 'Does the patient agree to have education sent to them?',
        id: 'sendEducation',
        isRequired: true,
        choices: ['Yes', 'No'],
      },
      {
        type: 'MULTIPLE_CHOICE',
        title:
          'How will we be sending the education? Add email address or home address, alternative mailing address in the Delivery instructions field.',
        id: 'sendEducationHow',
        requiredIf: { key: 'sendEducation', value: 'Yes' },
        choices: ['Mail', 'Email'],
        noteOption: {
          label: 'Delivery instruction: ',
          id: 'sendEducationHowNote',
        },
      },
      {
        type: 'CHECKBOX',
        title: 'Education',
        id: 'education',
        isRequired: true,
        hasOtherOption: true,
        choices: [
          'Acute MI',
          'Alcohol Abuse',
          'Alzheimer’s Disease',
          'Anemia',
          'Asthma',
          'Atrial Fibrillation',
          'Autism Spectrum Disorder',
          'BPH',
          'Cancer',
          'Cerebral vascular accident',
          'Chronic Kidney Disease',
          'Chronic Obstructive Pulmonary Disease',
          'Chronic pain',
          'Depression',
          'Diabetes',
          'Down Syndrome',
          'Drug Abuse',
          'Glaucoma',
          'Heart Failure',
          'Hepatitis B',
          'Hepatitis C',
          'HIV/AIDS',
          'Hyperlipidemia (High cholesterol)',
          'Hypertension (High blood pressure)',
          'Hypothyroidism',
          'Ischemic Heart Disease',
          'Osteoarthritis',
          'Osteoporosis',
          "Parkinson's",
          'Peripheral vascular disease',
          'Rheumatoid Arthritis',
          'Schizophrenia',
          'Senile Dementia',
          'Substance Abuse',
          'Transient ischemic attack',
        ],
      },
      {
        type: 'TEXT',
        title:
          'Were any resources provided to the patient during this visit? (ex: meals on wheels)',
        id: 'resources',
      },
      {
        type: 'MULTIPLE_CHOICE',
        title: 'The patient’s next appointment will be by:',
        id: 'nextApptWillBeBy',
        isRequired: true,
        choices: ['Phone/Video Conference', 'Email', 'SMS'],
      },
      {
        type: 'DATE',
        title: 'Next CCM Appointment date',
        id: 'nextapptdate',
        requiredIf: { key: 'nextApptWillBeBy', value: 'Phone' },
      },
      {
        type: 'TEXT',
        title: 'Next CCM Appointment Time (Add Timezone)\n(ex: 4pm CT)',
        requiredIf: { key: 'nextApptWillBeBy', value: 'Phone' },
        id: 'nextappttime',
      },
      {
        type: 'TEXT',
        title:
          'Place any other notes regarding this appointment here to be included in the narrative. *Use full sentences',
        id: 'extra',
      },
    ],
  },
];
