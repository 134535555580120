import { withAuthenticationRequired } from '@auth0/auth0-react';

import BaseLoader from '../base-loader';

import type { ComponentType } from 'react';

export const ProtectedComponent = ({
  component,
}: {
  component: ComponentType;
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <BaseLoader className="loader fixed" loading />,
  });
  return <Component />;
};
