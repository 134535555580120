import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import common from 'constants/common';

interface IBaseModalProps {
  open: boolean;
  children: React.ReactNode;
  bodyClassNames?: string;
  title?: string;
  withFooter?: boolean | React.ReactElement[];
  destroyOnClose?: boolean;
  outsideClose?: boolean;
  loading?: boolean;
  saveBtnText?: string;
  width?: string;
  onCancel?: () => void;
  onSave?: () => void;
  smallBodyPaddingWithFooter?: boolean;
  allowScrolling?: boolean;
}

const BaseModal: React.FC<IBaseModalProps> = (props) => {
  const { t } = useTranslation();
  const {
    open,
    children,
    onCancel,
    onSave,
    bodyClassNames,
    destroyOnClose = true,
    outsideClose = true,
    title,
    withFooter = false,
    loading = false,
    saveBtnText = t('controls.save'),
    width,
    smallBodyPaddingWithFooter,
    allowScrolling,
  } = props;

  const modalStyles = {
    body: {
      padding: withFooter
        ? `32px 24px ${smallBodyPaddingWithFooter ? '0px' : '64px'} 24px`
        : '32px 24px 24px 24px',
    },
    footer: {
      borderTop: withFooter ? `1px solid ${common.commonColors.gray}` : 'none',
      padding: withFooter ? '24px' : 0,
    },
  };

  return (
    <Modal
      title={title}
      open={open}
      footer={withFooter}
      onCancel={onCancel}
      onOk={onSave}
      destroyOnClose={destroyOnClose}
      maskClosable={outsideClose}
      okText={saveBtnText}
      confirmLoading={loading}
      width={width}
      zIndex={1010}
      styles={modalStyles}
      classNames={{
        body: bodyClassNames,
      }}
      getContainer={allowScrolling ? false : undefined}
    >
      {children}
    </Modal>
  );
};

export default BaseModal;
