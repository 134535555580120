import i18next from 'i18next';
import { formatAuthorName } from 'utils/formatters';
import { DateFormatter } from 'infrastructure/functions';
import BaseTableOptionsDropdown from 'components/atoms/base-table-option-dropdown';
import { DateFormats } from 'infrastructure/enums';

import type {
  IMedicalHistory,
  IMedicalHistoryAuthor,
  IUpdateMedicalHistory,
} from 'infrastructure/interfaces/i-medical-history';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

type TArgs = Omit<IUpdateMedicalHistory, 'patientGuid'>;

interface Props {
  setHistoryForUpdate: (args: TArgs) => void;
  loading: boolean;
  updatedItemGuid?: string;
  disableActions?: boolean;
}
export const columns = ({
  setHistoryForUpdate,
  loading,
  updatedItemGuid,
  disableActions,
}: Props): IBaseTableColumns<IMedicalHistory> => {
  return [
    {
      label: i18next.t('labels.lastUpdatedDate'),
      key: 'updatedAt',
      width: '10%',
      render(date: string) {
        return (
          <span data-cy="past-medical-history-updatedAt">
            {DateFormatter({
              date,
              format: DateFormats['MM-dd-yyyy h:mm a'],
            })}
          </span>
        );
      },
    },
    {
      label: i18next.t(
        'labels.medicalIssueSurgeryAndOrHospitalizationHistoryDescription',
      ),
      key: 'record',
      width: '60%',
      render(value: string) {
        return <span data-cy="past-medical-history-record">{value}</span>;
      },
    },
    {
      label: i18next.t('labels.electronicallySignedBy'),
      key: 'author',
      width: '25%',
      render(author: IMedicalHistoryAuthor) {
        return (
          <span data-cy="past-medical-history-author">
            {formatAuthorName(author)}
          </span>
        );
      },
      align: 'right',
    },
    {
      label: i18next.t('labels.option'),
      key: 'guid',
      width: '5%',
      render(value, { guid, record }) {
        return (
          <BaseTableOptionsDropdown
            loading={loading && guid === updatedItemGuid}
            onEdit={() => setHistoryForUpdate({ guid, record })}
            disabled={disableActions}
          />
        );
      },
    },
  ];
};
