import React from 'react';
import LogTable from 'components/atoms/log-table';
import { useCcmStatusLog } from 'infrastructure/hooks/logs/use-ccm-status-log';
import useUpdateEffect from 'infrastructure/hooks/utils/use-update-effect';

interface IPatientCcmStatusLogProps {
  patientGuid: string;
  dateRange?: [Date, Date];
}

const PatientCcmStatusLog: React.FC<IPatientCcmStatusLogProps> = (props) => {
  const { patientGuid, dateRange } = props;

  const { loading, meta, items, setMeta } = useCcmStatusLog({
    patientGuid,
    meta: {
      dateRange,
    },
  });

  const onChangePage = (page: number) => {
    setMeta({
      ...meta,
      page,
      dateRange,
    });
  };

  useUpdateEffect(() => {
    setMeta({
      ...meta,
      page: 1,
      dateRange,
    });
  }, [dateRange]);

  return (
    <LogTable
      dataCy="patient-ccm-status-log-table-table"
      items={items}
      loading={loading}
      pagination={{
        current: meta.page,
        pageSize: meta.items,
        total: meta.totalCount,
        position: 'bottomCenter',
      }}
      onChangePage={onChangePage}
    />
  );
};

export default PatientCcmStatusLog;
