import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

import Fev1Chart from './Fev1Chart';
import PefChart from './PefChart';

const PeakFlowChart = ({ data, thresholds, loading }) => {
  return (
    <>
      <div className="chart-item">
        <Card className="card-border">
          <CardBody>
            <div className="charts-body">
              <Fev1Chart
                data={data}
                loading={loading}
                thresholds={thresholds}
              />
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="chart-item">
        <Card className="card-border">
          <CardBody>
            <div className="charts-body">
              <PefChart data={data} loading={loading} thresholds={thresholds} />
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

PeakFlowChart.propTypes = {
  data: PropTypes.array,
  thresholds: PropTypes.shape(),
  loading: PropTypes.bool,
};

export default PeakFlowChart;
