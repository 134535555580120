import React, { useEffect, useRef, useState } from 'react';
import BaseContainer from 'components/atoms/base-container';
import ClinicForm from 'components/molecules/clinic/form';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import { useTranslation } from 'react-i18next';
import { Routes } from 'infrastructure/consts/routes';
import { useNavigate, useParams } from 'react-router-dom';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import { useClinicInfo } from 'infrastructure/hooks/clinic/use-clinic-info';
import { Clinic } from 'infrastructure/classes/clinic';
import { useEditClinic } from 'infrastructure/hooks/clinic/use-edit-clinic';
import { useClinicThresholds } from 'infrastructure/hooks/clinic/use-clinic-thresholds';
import ClinicReferralTypeChangesReason from 'components/molecules/clinic/form/referral-type';
import { arraysEqual } from 'infrastructure/functions/arrays-equal';
import SaveButton from 'components/atoms/controls/save-button';
import { isNotUndefinedOrNull } from 'infrastructure/functions';

import type { IClinicReferralTypeChangesReasonRef } from 'components/molecules/clinic/form/referral-type';
import type { IClinicFormRef } from 'components/molecules/clinic/form';

const AdminClinicEditPage: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { clinicGuid: _clinicGuid } = useParams<{
    clinicGuid: string;
  }>();
  const clinicGuid = _clinicGuid!;

  const { loading: clinicLoading, clinicInfo } = useClinicInfo({ clinicGuid });
  const { loading: editLoading, editClinic } = useEditClinic();
  const {
    thresholds,
    loading: thresholdLoading,
    updateClinicThresholds,
  } = useClinicThresholds({
    clinicGuid,
  });

  const loading = clinicLoading || editLoading || thresholdLoading;

  const [clinic, setClinic] = useState<Clinic | undefined>();

  const ref = useRef<IClinicFormRef>(null);
  const changesReasonRef = useRef<IClinicReferralTypeChangesReasonRef>(null);

  const onSave = async () => {
    if (ref.current) {
      const res = await ref.current.submit();

      if (res.isValid) {
        let referralTypeDeleteReason: string | undefined;
        let isReferralTypeChanges: boolean = false;
        const hasValues =
          isNotUndefinedOrNull(clinic?.referralTypes) ||
          isNotUndefinedOrNull(res.values.referralTypes);

        if (hasValues) {
          isReferralTypeChanges = !arraysEqual(
            clinic?.referralTypes,
            res.values.referralTypes,
          );
        }

        if (isReferralTypeChanges) {
          const reason = await changesReasonRef.current?.open();
          if (!reason) return;
          referralTypeDeleteReason = reason.referralTypeDeleteReason;
        }

        await Promise.all([
          editClinic({
            ...res.values,
            guid: clinicGuid,
            clientSuccessOnboarding: res.values.clientSuccessOnboardingGuid,
            salesRepresentative: res.values.salesRepresentativeGuid,
            clientSuccessScaling: res.values.clientSuccessScalingGuid,
            referralTypeDeleteReason,
          }),
          updateClinicThresholds({
            ...res.values.threshold,
            clinicGuid,
          }),
        ]).then(() => {
          navigate(Routes.adminClinicsDetail(clinicGuid), { replace: true });
        });
      }
    }
  };

  const controls = (
    <SaveButton
      width="160px"
      label={t('controls.saveChanges')}
      onClick={onSave}
    />
  );

  useEffect(() => {
    if (clinicInfo) {
      setClinic(new Clinic(clinicInfo));
    }
  }, [clinicInfo]);

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseContainer>
        <AdminNavigationHeader
          goBackPath={Routes.adminClinics}
          title={t('labels.editClinic')}
          controls={controls}
        />
        <ClinicForm ref={ref} info={clinic} thresholds={thresholds} />
        <ClinicReferralTypeChangesReason ref={changesReasonRef} />
      </BaseContainer>
    </BaseSpinWrapper>
  );
};

export default AdminClinicEditPage;
