import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Profile from 'components/templates/profile';
import useUser from 'utils/useUser';
import { isTurkUserRole } from 'utils/userTypeHelper';
import TurkUserProfile from 'components/templates/profile/turkUserProfile';

import type { RootDispatch, RootState } from 'store';

const ProfilePage = () => {
  const {
    userType,
    firstName,
    lastName,
    title,
    birthDate,
    email,
    phone,
    roleNames,
  } = useUser();

  const {
    user: { toggleAlerts },
  } = useDispatch<RootDispatch>();

  const { alertEmail, alertSms } = useSelector(
    (state: RootState) => state.user,
  );

  if (isTurkUserRole(roleNames)) {
    return <TurkUserProfile />;
  }

  return (
    <Profile
      alertEmail={alertEmail}
      alertSms={alertSms}
      userType={userType}
      firstName={firstName}
      lastName={lastName}
      title={title}
      birthDate={birthDate}
      email={email}
      phone={phone}
      toggleAlerts={toggleAlerts}
    />
  );
};

export default ProfilePage;
