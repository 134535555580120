import { Status, DeviceGeneration, ReadingType } from 'infrastructure/enums';
import { makeid } from 'utils/helpers/makeid';

import { DeviceLog } from '../device-log';

import type { IPatientDevice } from 'infrastructure/interfaces';

export const reformatReadingTypes = (types: Array<ReadingType>) => {
  if (
    types.includes(ReadingType.SleepingMatActivity) ||
    types.includes(ReadingType.SleepingMatDuration)
  ) {
    return [
      ...types.filter((e) => {
        const isSleepMat =
          e === ReadingType.SleepingMatActivity ||
          e === ReadingType.SleepingMatDuration;
        return !isSleepMat;
      }),
      ReadingType.SleepingMat,
    ];
  }
  return types;
};

export class PatientDevice {
  key: string;
  patientGuid: string;
  deviceId: string;
  readingTypes: Array<ReadingType>;
  manufacturer: string;
  createdAt?: Date;
  status: Status;
  log?: DeviceLog;
  generation: DeviceGeneration;
  alarm?: IPatientDevice['alarm'];
  alarmChange?: IPatientDevice['alarmChange'];

  constructor(patientGuid: string, data: IPatientDevice) {
    this.key = makeid(5);
    this.patientGuid = patientGuid;
    this.createdAt = data.createdAt ? new Date(data.createdAt) : undefined;
    this.deviceId = data.deviceId;
    this.readingTypes = reformatReadingTypes(data.readingTypes);
    this.manufacturer = data.manufacturer;
    this.status = data.isActive ? Status.Active : Status.Inactive;
    this.log = data.lastLog && new DeviceLog(data.lastLog);
    this.alarm = data.alarm;
    this.alarmChange = data.alarmChange;
    this.generation = data.lastLog
      ? DeviceGeneration.Gen2
      : DeviceGeneration.Gen1;
  }

  static checkReadingType(
    devices: Array<PatientDevice>,
    readingTypes: Array<ReadingType>,
  ) {
    return devices.some((device) =>
      device.readingTypes.some((type) =>
        readingTypes.some((rt) => type === rt),
      ),
    );
  }

  get isBloodGlucoseType() {
    return PatientDevice.checkReadingType([this], [ReadingType.BloodGlucose]);
  }
  get isWeightType() {
    return PatientDevice.checkReadingType([this], [ReadingType.Weight]);
  }
  get isSPO2Type() {
    return PatientDevice.checkReadingType([this], [ReadingType.SpO2]);
  }
  get isBloodPressureType() {
    return PatientDevice.checkReadingType([this], [ReadingType.BloodPressure]);
  }
  get isPeakFlowType() {
    return PatientDevice.checkReadingType([this], [ReadingType.PeakFlow]);
  }
  get isSleepMatType() {
    return PatientDevice.checkReadingType([this], [ReadingType.SleepingMat]);
  }
  get isTemperatureType() {
    return PatientDevice.checkReadingType([this], [ReadingType.Temperature]);
  }

  static prepareReadingTypes(readingTypes: Array<ReadingType>) {
    const rTypes = readingTypes.map((rt) => {
      let type = [rt];

      if (rt === ReadingType.SleepingMat) {
        type = [
          ReadingType.SleepingMatActivity,
          ReadingType.SleepingMatDuration,
        ];
      }

      return type;
    });
    return rTypes.flat(2);
  }
}
