/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { sendToBackgroundViaRelay } from '@plasmohq/messaging';
import { getAuth0Token } from 'services/dataService/getHeaders';

const SSO = () => {
  useEffect(() => {
    const sendAuth = async () => {
      const token = getAuth0Token();
      try {
        await sendToBackgroundViaRelay({
          name: 'token',
          extensionId: process.env.REACT_APP_CHROME_EXTENSION_ID,
          body: { token },
        });
      } catch (e) {
        console.error(e);
      }
    };
    sendAuth();
  }, []);

  return <Container className="terms-page content" />;
};

export default SSO;
