import React from 'react';
import classNames from 'classnames';
import { TimePicker } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { ICommonInputProps } from 'infrastructure/interfaces';

dayjs.extend(customParseFormat);

interface IBaseInputProps extends ICommonInputProps<string> {
  format?: DateFormats;
  onChange?: (dateString: string) => void;
}

const BaseTimePicker: React.FC<IBaseInputProps> = (props) => {
  const {
    value,
    disabled,
    plaintext,
    readonly,
    invalid,
    onChange,
    className,
    style,
    placeholder = '',
    format = DateFormats['h:mm a'],
  } = props;

  const selectBoxClassNames = classNames(
    {
      [s.readonly]: readonly,
    },
    className,
  );

  return (
    <TimePicker
      use12Hours
      format={DateFormats['h:mm a']}
      style={{ width: '100%', ...style }}
      placeholder={placeholder}
      value={value ? dayjs(value, format) : undefined}
      disabled={disabled}
      className={selectBoxClassNames}
      onChange={(time, dateString) => {
        if (onChange && !Array.isArray(dateString) && time)
          onChange(time.format(DateFormats['HH:mm']));
      }}
      variant={plaintext ? 'borderless' : 'outlined'}
      status={invalid ? 'error' : undefined}
      suffixIcon={plaintext ? <></> : <ClockCircleOutlined />}
    />
  );
};

export const BaseTimePickerDisplayName = 'BaseTimePicker';
BaseTimePicker.displayName = BaseTimePickerDisplayName;

export default BaseTimePicker;
