import i18next from 'i18next';
import { SortOrder } from 'infrastructure/enums';

export interface ISortOrderItem {
  id: SortOrder;
  name: string;
}

export const sortOrdersDataSource = (): ISortOrderItem[] => {
  return [
    {
      id: SortOrder.ASC,
      name: i18next.t('labels.atoz'),
    },
    {
      id: SortOrder.DESC,
      name: i18next.t('labels.ztoa'),
    },
  ];
};
