import dataService from 'services/dataService';
import {
  apiClinicThreshold,
  apiAdminPanelClinicsGet,
  apiGetClinicByGuid,
  API_ADMIN_PANEL_CLINICS,
  API_CLINICS_THRESHOLD,
  API_AGENCIES,
  API_ADMIN_PANEL_CLINICS_UPDATE_STATUS,
  API_ADMIN_PANEL_AGENCY_DOCTORS,
} from 'services/dataService/resources';
import DEFAULT_CLINIC_THRESHOLD_VALUES from 'constants/defaultThreshold';
import { fullNamePresenter } from 'utils';

const ITEMS_PER_PAGE = 100;
const INITIAL_STATE = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  filters: {
    guid: null,
    status: null,
    agencyGuid: null,
  },
  data: [],
  totalPages: 0,
  totalCount: 0,
  clinic: {},
  threshold: {},
};

const adminPanelClinics = {
  state: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    resetStatus: (state) => ({
      ...state,
      filters: { ...state.filters, status: null },
    }),
    setPage: (state, payload) => ({
      ...state,
      page: payload < 1 ? 1 : payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    setClinics: (state, payload) => ({
      ...state,
      data: payload,
    }),
    setFilters: (state, payload) => ({
      ...state,
      filters: { ...state.filters, ...payload },
    }),
    setClinic: (state, payload) => ({
      ...state,
      clinic: {
        ...state.clinic,
        ...payload,
      },
    }),
    setClinicThreshold: (state, payload) => ({
      ...state,
      threshold: payload,
    }),
    setAgencies: (state, payload) => ({
      ...state,
      agencies: payload,
    }),
    setClientSuccessOnboardingOptions: (state, payload) => ({
      ...state,
      clinic: {
        ...state.clinic,
        clientSuccessOnboardingOptions: payload,
      },
    }),
    setClientSuccessScalingOptions: (state, payload) => ({
      ...state,
      clinic: {
        ...state.clinic,
        clientSuccessScalingOptions: payload,
      },
    }),
    setSalesOptions: (state, payload) => ({
      ...state,
      clinic: {
        ...state.clinic,
        salesRepresentatives: payload,
      },
    }),
  },
  effects: (dispatch) => ({
    async sort({ value, desc }, state) {
      let data = [...state.adminPanelClinics.data];
      data = data.sort((a, b) => {
        if (desc) {
          return a[value] > b[value] ? 1 : -1;
        }
        return b[value] > a[value] ? 1 : -1;
      });
      dispatch.adminPanelClinics.setClinics(data);
    },
    async getClinics(
      { items = ITEMS_PER_PAGE, noLimit = null, signal } = {
        items: ITEMS_PER_PAGE,
        noLimit: null,
      },
      state,
    ) {
      const { filters } = state.adminPanelClinics;
      const { page } = state.adminPanelClinics;
      const { data } = await dataService.getList(
        apiAdminPanelClinicsGet(items, page, noLimit, filters),
        { signal },
      );
      if (data) {
        dispatch.adminPanelClinics.setClinics(data?.items || []);
        const totalCount = data?.count || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.adminPanelClinics.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
      } else {
        dispatch.adminPanelClinics.setClinics([]);
        dispatch.adminPanelClinics.setTotalCountAndTotalPages({
          totalCount: 0,
          totalPages: 0,
        });
      }
    },
    async setClinicsPage({ clinicGuid, pageOffset, getList = true }, state) {
      const { adminPanelClinics: adminPanelClinicsState } = state;
      const { page, totalPages } = adminPanelClinicsState;
      const newPage = page + pageOffset;
      dispatch.adminPanelClinics.setPage(
        newPage <= totalPages ? newPage : totalPages,
      );
      if (getList) {
        return dispatch.adminPanelClinics.getClinics({
          clinicGuid,
          page: newPage,
        });
      }
    },
    async getAgencies() {
      const { data } = await dataService.getList(API_AGENCIES);
      if (data) {
        dispatch.adminPanelClinics.setAgencies(data);
      }
    },
    async getRepresentatives() {
      await Promise.all([
        dataService
          .getList(
            `${API_ADMIN_PANEL_AGENCY_DOCTORS}?noLimit=true&roleName=clientSuccessOnboarding`,
          )
          .then(({ data }) =>
            dispatch.adminPanelClinics.setClientSuccessOnboardingOptions(
              data?.items.map((item) => ({
                id: item.guid,
                label: fullNamePresenter(item),
              })),
            ),
          ),
        dataService
          .getList(
            `${API_ADMIN_PANEL_AGENCY_DOCTORS}?noLimit=true&roleName=clientSuccessScaling`,
          )
          .then(({ data }) =>
            dispatch.adminPanelClinics.setClientSuccessScalingOptions(
              data?.items.map((item) => ({
                id: item.guid,
                label: fullNamePresenter(item),
              })),
            ),
          ),
        dataService
          .getList(
            `${API_ADMIN_PANEL_AGENCY_DOCTORS}?noLimit=true&roleName=salesRepresentative`,
          )
          .then(({ data }) =>
            dispatch.adminPanelClinics.setSalesOptions(
              data?.items.map((item) => ({
                id: item.guid,
                label: fullNamePresenter(item),
              })),
            ),
          ),
      ]);
    },
    async getClinic(clinicGuid) {
      if (clinicGuid) {
        const { data } = await dataService.getList(
          apiGetClinicByGuid(clinicGuid),
        );
        if (data) {
          for (const item in data) {
            if (data[item] === null) data[item] = undefined;
          }
          dispatch.adminPanelClinics.setClinic(data);
        }
      } else dispatch.adminPanelClinics.setClinic({});
    },
    async getThreshold(clinicGuid) {
      if (clinicGuid) {
        const { data } = await dataService.getList(
          apiClinicThreshold(clinicGuid),
        );
        if (data) {
          dispatch.adminPanelClinics.setClinicThreshold(data);
        }
      } else {
        dispatch.adminPanelClinics.setClinicThreshold(
          DEFAULT_CLINIC_THRESHOLD_VALUES,
        );
      }
    },
    async editThreshold(body) {
      const { error, data } = await dataService.updateOnly(
        API_CLINICS_THRESHOLD,
        body,
      );
      return { error, data };
    },
    async createClinic(body) {
      const { error, data } = await dataService.createOne(
        API_ADMIN_PANEL_CLINICS,
        body,
      );
      return { error, data };
    },
    async editClinic(body) {
      const { error, data } = await dataService.updateOnly(
        `${API_ADMIN_PANEL_CLINICS}`,
        body,
      );
      for (const item in data) {
        if (data[item] === null) data[item] = undefined;
      }
      dispatch.adminPanelClinics.setClinic(data);
      return { error, data };
    },
    async updateStatus(body) {
      const { error } = await dataService.updateOnly(
        `${API_ADMIN_PANEL_CLINICS_UPDATE_STATUS}`,
        body,
      );
      return { error };
    },
  }),
};

export default adminPanelClinics;
