import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { arrUniqEl } from 'infrastructure/functions';
import { Clinic } from 'infrastructure/classes/clinic';

import useApi from '../use-api';

import type { ClinicStatus } from 'infrastructure/enums';
import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IClinic,
} from 'infrastructure/interfaces';

const getClinicsApi = 'core/clinics';
const getAdminPanelClinicsApi = 'admins/admin-panel/clinics';

export interface IUseClinicListMeta {
  legalname?: string;
  status?: ClinicStatus;
  showOnInteractiveTimeDashboard?: boolean;
  useAdminApi?: boolean;
}

type MergedMeta = IPaginationMeta & IUseClinicListMeta;

type UseClinic = {
  loading: boolean;
  clinics: Clinic[];
  meta: MergedMeta;
  loadClinics: (meta: MergedMeta) => Promise<void>;
};

interface IUseClinicListProps {
  meta?: IUseClinicListMeta & IPartialPaginationMeta;
}

type TClinicResponse = { count: number; items: IClinic[] } | IClinic[];

export const useClinicList = (props?: IUseClinicListProps): UseClinic => {
  const { meta: { useAdminApi, ...metaProps } = {} } = props || {};
  const { loading, loadData, isMounted } = useApi();
  const queryClient = useQueryClient();
  const apiUrl = useAdminApi ? getAdminPanelClinicsApi : getClinicsApi;

  const [clinics, setClinics] = useState<Clinic[]>([]);
  const [meta, setMeta] = useState<MergedMeta>({
    page: 1,
    items: 100,
    totalCount: 0,
    ...metaProps,
  });

  const loadClinics = async (filters: MergedMeta) => {
    const queryKey = [
      apiUrl,
      {
        page: filters.page,
      },
    ];

    const data = await queryClient.fetchQuery(
      queryKey,
      () =>
        loadData<
          TClinicResponse,
          Omit<IPaginationMeta, 'sortField' | 'totalCount'>
        >(apiUrl, {
          sortField: JSON.stringify(filters.sortField),
          page: filters.page,
          items: filters.items,
          legalname: filters.legalname,
          status: filters.status,
          showOnInteractiveTimeDashboard:
            filters.showOnInteractiveTimeDashboard ?? true,
        }),
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      const newMeta = {
        ...meta,
        ...filters,
      };

      setMeta(newMeta);

      const clinicsData = 'count' in data ? data.items : data;
      setClinics(arrUniqEl(clinicsData, 'guid').map((el) => new Clinic(el)));
    }
  };

  return {
    meta,
    loading,
    clinics,
    loadClinics,
  };
};
