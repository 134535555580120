export enum ClinicRevenueChannel {
  Medicare = 'Medicare',
  TexasMedicaid = 'Texas Medicaid',
  Commercial = 'Commercial',
  FQHC = 'FQHC',
  Academia = 'Academia',
  ChannelPartner = 'Channel Partner',
  SelfInsuredPayer = 'Self-Insured Payer',
  Payer = 'Payer',
  PrivatePay = 'Private Pay',
}
