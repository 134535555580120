import dataService from 'services/dataService';
import { API_PATIENT_KEY_NOTE } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

import type { IKeyNoteData } from 'infrastructure/interfaces';
import type { ModuleType } from 'infrastructure/enums';

interface IActionsKeyNotesProps {
  patientGuid: string;
  module: ModuleType;
  note?: string;
}

export const saveKeyNotes = async ({
  patientGuid,
  module,
  note,
}: IActionsKeyNotesProps) => {
  const url = API_PATIENT_KEY_NOTE(patientGuid);
  const newValue = { module, note };
  const { error, data } = await dataService.updateOnly<IKeyNoteData>(
    url,
    newValue,
  );

  if (error) {
    showResult(error);
  }

  return data;
};
