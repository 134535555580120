import CopyableText from 'components/atoms/buttons/copyable-text';
import React from 'react';

import type { Adherence } from 'infrastructure/classes';

interface IDeviceIdsColProps {
  data: Adherence;
}

const DeviceIdsCol: React.FC<IDeviceIdsColProps> = (props) => {
  const { data } = props;
  const deviceIds = data.devices?.ids.join(', ');

  return <>{deviceIds && <CopyableText width="70px" value={deviceIds} />}</>;
};

export default DeviceIdsCol;
