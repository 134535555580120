import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import PaginationCaption from './caption';

const Pagination = ({
  title,
  page,
  pageTotal,
  itemsPerPage,
  total,
  loading,
  handlePrevClick,
  handleNextClick,
  handleLastClick,
  handleFirstClick,
  handleGoToPage,
  isRpmPanel,
}) => {
  const totalPage = useMemo(() => Math.ceil(total / itemsPerPage));
  return (
    <div
      className={
        isRpmPanel
          ? 'pagination-container activity-header-pagination'
          : 'pagination-container'
      }
      data-cy="pagination__panel"
    >
      <h5>{title}</h5>
      <div className="pagination-body">
        {page > 1 && (
          <>
            <div className="pagination-buttons">
              <button
                type="button"
                aria-label="Previous"
                className="first"
                data-cy="pagination__first"
                onClick={handleFirstClick}
                disabled={loading}
              />
            </div>
            <div className="pagination-buttons">
              <button
                type="button"
                aria-label="Previous"
                className="prev"
                data-cy="pagination__prev"
                onClick={handlePrevClick}
                disabled={loading}
              />
            </div>
          </>
        )}
        {totalPage > 1 && (
          <PaginationCaption
            page={page}
            itemsPerPage={itemsPerPage}
            total={total || 1}
            loading={loading}
            handleGoToPage={handleGoToPage}
          />
        )}

        {(page - 1) * itemsPerPage + pageTotal < total && (
          <>
            <div className="pagination-buttons">
              <button
                type="button"
                aria-label="Next"
                className="next"
                data-cy="pagination__next"
                onClick={handleNextClick}
                disabled={loading}
              />
            </div>
            <div className="pagination-buttons">
              <button
                type="button"
                aria-label="last"
                className="last"
                data-cy="pagination__last"
                onClick={handleLastClick}
                disabled={loading}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  title: '',
  loading: false,
};

Pagination.propTypes = {
  title: PropTypes.string,
  page: PropTypes.number,
  pageTotal: PropTypes.number,
  itemsPerPage: PropTypes.number,
  total: PropTypes.number,
  loading: PropTypes.bool,
  handlePrevClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func.isRequired,
  handleLastClick: PropTypes.func.isRequired,
  handleFirstClick: PropTypes.func.isRequired,
  handleGoToPage: PropTypes.func.isRequired,
  isRpmPanel: PropTypes.bool,
};

export default Pagination;
