export const convertUTCToLocal = (
  utcDateString: string | null | undefined,
): Date | null => {
  if (!utcDateString) return null;
  const utcDate = new Date(`${utcDateString}Z`);
  const localDate = new Date(utcDate);
  return localDate;
};

export const getLatestDate = (
  ...dates: Array<Date | undefined | null>
): Date | null => {
  const timestamps: Array<number> = dates
    .map((date) => date?.getTime())
    .filter((time) => time !== undefined && time !== null) as Array<number>;

  if (timestamps.length === 0) {
    return null;
  }

  const maxTimestamp = Math.max(...timestamps);
  return new Date(maxTimestamp);
};
