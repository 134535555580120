import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const adherenceStyle = (adherence) => {
  if (adherence < 36) return 'rangeRed';
  if (adherence < 66) return 'rangeYellow';
  return 'rangeLightGreen';
};

const ActivityRowLast30Days = ({ activity }) => (
  <tr className="activity-row">
    <td data-label="Patient Guid">{activity.patientGuid}</td>
    <td data-label="Patient Name">
      <Link to={`/patient/${activity.patientGuid}`}>
        <strong>{activity.patientName}</strong>
      </Link>
    </td>
    <td data-label="MRN">{activity.mrn}</td>
    <td data-label="Patient Phone Number">{activity.patientPhone}</td>
    <td data-label="Clinic Name">{activity.patientClinicName}</td>
    <td data-label="Patient First Reading Date">{activity.firstReadingDate}</td>
    <td data-label="Days Since Start">{activity.daysSinceStart}</td>
    <td data-label="# of Reading Days">{activity.successDayCount}</td>
    <td
      data-label="Patient Adherence"
      className={adherenceStyle(activity.adherence)}
    >
      {activity.adherence}%
    </td>
  </tr>
);

ActivityRowLast30Days.propTypes = {
  activity: PropTypes.shape().isRequired,
};

export default ActivityRowLast30Days;
