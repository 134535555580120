import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  conversationReadFilter,
  conversationClosedFilter,
  conversationLastMessageDateFilter,
} from 'constants/forms';
import common from 'constants/common';
import useOutsideClick from 'utils/useOutsideClick';
import { INITIAL_STATE } from 'store/smsDashboard';

import NewConversationButton from './newConversationButton';
import BaseSelect from '../baseSelect';
import ChatFilterIcon from '../icons/chatFilterIcon';
import BaseButton from '../baseButton';
import ChatFilterActiveIcon from '../icons/chatFilterActiveIcon';

import type { IFilterConversation } from 'infrastructure/interfaces';
import type { RootDispatch, RootState } from 'store';

const ConversationHeader = () => {
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>();

  const { filters } = useSelector((state: RootState) => state.smsDashboard);
  const {
    smsDashboard: { updateConversationsFilters },
  } = useDispatch<RootDispatch>();

  const selectTheme = (theme: any) => ({
    ...theme,
    borderRadius: 12,
    colors: {
      ...theme.colors,
      ...common.primaryColors,
    },
  });

  useOutsideClick(ref, () => {
    setIsMenuOpen(false);
  });

  const filterChanged = async (e: {
    type: string;
    value?: string | boolean;
  }) => {
    if (!e) return;
    updateConversationsFilters({ ...filters, [e.type]: e.value });
  };

  const debouncedSearch = useCallback(
    debounce((data) => {
      filterChanged(data);
    }, 800),
    [],
  );

  const filterReset = async () => {
    updateConversationsFilters({ ...INITIAL_STATE.filters });
  };

  const searchApply = async (e: { type: string; value?: string | boolean }) => {
    if (!e) return;
    if (e.type === 'searchQuery') {
      setSearchText(String(e.value));
      debouncedSearch({ type: e.type, value: e.value });
    }
  };

  const getFilterValue = (
    options: IFilterConversation[],
    value: string | boolean,
  ) => {
    const res = options.find((element) => element.value === value);
    return { label: res?.label, value: res?.value };
  };

  const isDefaultFilter =
    filters.read === INITIAL_STATE.filters.read &&
    filters.closed === INITIAL_STATE.filters.closed &&
    filters.lastMessageDate === INITIAL_STATE.filters.lastMessageDate;

  const filterIcon = isDefaultFilter ? (
    <ChatFilterIcon />
  ) : (
    <ChatFilterActiveIcon />
  );

  return (
    <div className="conversations-header">
      <div className="conversations-search">
        <input
          type="text"
          className={classNames('conversations-search-input')}
          placeholder={t('smsDashboard.search_number_name')}
          value={searchText}
          onChange={(e) =>
            searchApply({
              value: e.target.value,
              type: 'searchQuery',
            })
          }
        />
        <span className="chat-search-icon" />
      </div>
      <div className="conversations-filter">
        <button
          className="conversations-filter-button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {filterIcon}
        </button>
        {isMenuOpen && (
          <div className="conversations-filter-modal" ref={ref}>
            <div className="conversations-filter-header">
              <p>Filters</p>
            </div>

            <div className="conversations-filter-body">
              <div className="conversations-filter">
                <span>{t('smsDashboard.statuses')}</span>
                <BaseSelect
                  options={conversationClosedFilter}
                  defaultValue={conversationClosedFilter[0]}
                  value={getFilterValue(
                    conversationClosedFilter,
                    filters.closed,
                  )}
                  theme={(theme) => selectTheme(theme)}
                  classNamePrefix="select-type"
                  onChange={(e) =>
                    filterChanged({
                      type: 'closed',
                      value: (e as unknown as { [key: string]: string }).value,
                    })
                  }
                />
              </div>
              <div className="conversations-filter">
                <span>{t('smsDashboard.message_type')}</span>
                <BaseSelect
                  options={conversationReadFilter}
                  defaultValue={conversationReadFilter[0]}
                  value={getFilterValue(conversationReadFilter, filters.read)}
                  theme={(theme) => selectTheme(theme)}
                  classNamePrefix="select-type"
                  onChange={(e) =>
                    filterChanged({
                      type: 'read',
                      value: (e as unknown as { [key: string]: string }).value,
                    })
                  }
                />
              </div>
              <div className="conversations-filter">
                <span>{t('smsDashboard.sorting')}</span>
                <BaseSelect
                  options={conversationLastMessageDateFilter}
                  defaultValue={conversationLastMessageDateFilter[0]}
                  value={getFilterValue(
                    conversationLastMessageDateFilter,
                    filters.lastMessageDate,
                  )}
                  theme={(theme) => selectTheme(theme)}
                  classNamePrefix="select-type"
                  onChange={(e) =>
                    filterChanged({
                      type: 'lastMessageDate',
                      value: (e as unknown as { [key: string]: string }).value,
                    })
                  }
                />
              </div>
            </div>
            <div className="conversations-filter-footer">
              <BaseButton
                type="secondary"
                label={t('controls.close')}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              />
              <BaseButton
                label={t('controls.reset')}
                onClick={() => {
                  filterReset();
                  setIsMenuOpen(!isMenuOpen);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <NewConversationButton />
    </div>
  );
};

export default ConversationHeader;
