import React from 'react';
import PropTypes from 'prop-types';
import { Hint } from 'react-vis';

const KeyValue = ({ keyText, value }) => (
  <p>
    {keyText}:<strong> {value}</strong>
  </p>
);

KeyValue.propTypes = {
  keyText: PropTypes.string,
  value: PropTypes.string,
};

const SleepMatHint = ({ hoveredPoint, currentReading, ...rest }) => {
  const {
    deepSleepPercent,
    lightSleepPercent,
    remPercent,
    deepSleepDuration,
    lightSleepDuration,
    remDuration,
    pulse,
    durationTime,
    respiration,
    bodyTurnOver,
    sleepTime,
  } = currentReading;

  return (
    <Hint
      value={{
        x: hoveredPoint.x,
        y: -1,
      }}
      {...rest}
    >
      <div className="chart-hint">
        <p>{hoveredPoint.x}</p>
        <KeyValue keyText="Duration" value={`${durationTime}`} />
        <KeyValue keyText="Time" value={`${sleepTime}`} />
        <KeyValue
          keyText="Deep sleep"
          value={`${deepSleepDuration} - ${deepSleepPercent}%`}
        />
        <KeyValue
          keyText="Light sleep"
          value={`${lightSleepDuration} - ${lightSleepPercent}%`}
        />
        <KeyValue keyText="Rem" value={`${remDuration} - ${remPercent}%`} />
        <KeyValue keyText="Turn Over" value={`${bodyTurnOver}`} />
        <KeyValue keyText="Respiration" value={`${respiration}`} />
        <KeyValue keyText="Pulse" value={`${pulse}`} />
      </div>
    </Hint>
  );
};

SleepMatHint.propTypes = {
  hoveredPoint: PropTypes.shape(),
  currentReading: PropTypes.shape(),
};

export default SleepMatHint;
