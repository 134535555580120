import React from 'react';
import { PropTypes } from 'prop-types';
import SortableColumn from 'components/atoms/sortableColumn';
import { useTranslation } from 'react-i18next';

const HeaderRow = ({
  setSorts,
  activityTimeSort,
  ccmStatusSort,
  hasAccessToCareAgent,
}) => {
  const activityTimeSortHandler = () => {
    if (activityTimeSort === 'ASC') {
      setSorts({ activityTimeSort: 'DESC' });
      return;
    }

    setSorts({ activityTimeSort: 'ASC' });
  };

  const ccmStatusSortHandler = () => {
    if (ccmStatusSort === 'ASC') {
      setSorts({ ccmStatusSort: 'DESC' });
      return;
    }

    setSorts({ ccmStatusSort: 'ASC' });
  };

  const { t } = useTranslation();

  return (
    <tr>
      <th>{t('labels.patientName')}</th>
      <th>{t('labels.dob')}</th>
      <th>
        <div>{t('labels.careModulesICD10Codes')}</div>
      </th>
      <th>Provider</th>
      {hasAccessToCareAgent && <th>{t('labels.caseManager')}</th>}
      {hasAccessToCareAgent && <th>{t('labels.onboardingAgent')}</th>}
      <th>
        <SortableColumn
          title={t('labels.totalInteractiveTime')}
          handler={activityTimeSortHandler}
          sort={activityTimeSort}
        />
      </th>
      <th>
        <SortableColumn
          title={t('labels.status')}
          handler={ccmStatusSortHandler}
          sort={ccmStatusSort}
        />
      </th>
    </tr>
  );
};

HeaderRow.propTypes = {
  setSorts: PropTypes.func.isRequired,
  activityTimeSort: PropTypes.string,
  ccmStatusSort: PropTypes.string,
  hasAccessToCareAgent: PropTypes.bool,
};

export default HeaderRow;
