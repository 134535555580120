import { useQueryClient } from '@tanstack/react-query';
import { useEffectEvent } from 'infrastructure/hooks/use-effect-event';

import {
  changeAssignPatient,
  removeNewActionFlag,
  setComment,
  setOnCallPatient,
  setSingleOrAllSlaReviewedTime,
} from './helpers/update-cache';

import type { TRpmDashboardActivityQueryKey } from './use-get-dashboard-activity';
import type {
  IAsignSocketData,
  IRpmPatientActivity,
  IStopSlaTimerSocketData,
  IUpdateReadingAction,
} from 'infrastructure/interfaces/i-rpm-patient-activity';

export const useSocketActions = (queryKey: TRpmDashboardActivityQueryKey) => {
  const queryClient = useQueryClient();

  const stopSlaTimerAction = useEffectEvent((data: IStopSlaTimerSocketData) => {
    if (data.slaReviewedTime) {
      queryClient.setQueryData<IRpmPatientActivity>(queryKey, (oldData) =>
        setSingleOrAllSlaReviewedTime({ oldData, data }),
      );
    }
  });

  const reservedReadingAction = useEffectEvent((data: IAsignSocketData) => {
    queryClient.setQueryData<IRpmPatientActivity>(queryKey, (oldData) => {
      return changeAssignPatient({ oldData, data });
    });
  });

  const setOnCallPatientAction = useEffectEvent(
    (patientGuid: string, isOnCall: boolean) => {
      queryClient.setQueryData<IRpmPatientActivity>(queryKey, (oldData) =>
        setOnCallPatient({ oldData, patientGuid, isOnCall }),
      );
    },
  );

  const onActionChange = useEffectEvent((data: IUpdateReadingAction) => {
    queryClient.setQueryData<IRpmPatientActivity>(queryKey, (oldData) =>
      setComment({ oldData, data }),
    );

    if (data?.newAction) {
      setTimeout(() => {
        queryClient.setQueryData<IRpmPatientActivity>(queryKey, (oldData) =>
          removeNewActionFlag(oldData),
        );
      }, 3000);
    }
  });

  return {
    stopSlaTimerAction,
    reservedReadingAction,
    setOnCallPatientAction,
    onActionChange,
  };
};
