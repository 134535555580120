import { useSelector } from 'react-redux';
import React from 'react';

const PatientInfo = () => {
  const patient = useSelector((state) => state.adminPanelPatients.patient);
  const insuranceNumber =
    patient?.insurances?.length > 0
      ? patient?.insurances[0]?.number
      : 'No insurance';
  return (
    <div className="data-wrapper">
      <div className="insurance-data">
        <div>
          <p className="name">Patient Name:&nbsp;</p>
          <p>
            {patient?.profile?.firstName} {patient?.profile?.lastName}
          </p>
        </div>
        <div>
          <p className="insurance">Primary Insurance:&nbsp;</p>
          <p className="insurance">{insuranceNumber}</p>
        </div>
        <div>
          <p className="number">Phone Number:&nbsp;</p>
          <p className="number">{patient.profile?.phone}</p>
        </div>
      </div>
      <div className="personal-data">
        <div>
          <p className="birthday">DOB:&nbsp;</p>
          <p className="birthday">{patient.profile?.birthDate}</p>
        </div>
        <div>
          <p className="ID">Member ID:&nbsp;</p>
          <p className="ID">{patient.profile?.guid}</p>
        </div>
        <div>
          <p className="mail">Preferred Email:&nbsp;</p>
          <p className="mail">{patient.profile?.email}</p>
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
