import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const clinicRepresentativesValidationSchema = () =>
  createValidationSchema({
    clientSuccessOnboarding: commonValidationSchemas.string({
      nullable: true,
    }),
    salesRepresentative: commonValidationSchemas.string({ nullable: true }),
    clientSuccessScaling: commonValidationSchemas.string({
      nullable: true,
    }),
  });
