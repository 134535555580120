import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormGroup from 'components/atoms/base-form/group';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseGrid from 'components/atoms/base-grid';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseInput from 'components/atoms/base-input';
import AgencySelectBox from 'components/molecules/agency-select-box';
import ClinicSelectBox from 'components/molecules/clinic-select-box';
import useUser from 'utils/useUser';
import { mergeFieldsValue } from 'infrastructure/functions';

import { clinicMappingsValidationSchema } from './validation-schema';

import type { AgencySelectOption } from 'components/molecules/agency-select-box';
import type { Clinic } from 'infrastructure/classes/clinic';
import type { IClinicMappings } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const prepareValue = (
  data?: Clinic,
  agencies?: AgencySelectOption[],
): IClinicMappings => {
  let defaultValue: IClinicMappings = {
    clinicId: null,
    parentClinicGuid: null,
    agencyGuid: agencies ? agencies[0].value : '',
  };
  if (data) {
    defaultValue = mergeFieldsValue(defaultValue, data);
  }

  return defaultValue;
};

const FormDisplayName = 'ClinicMappingsForm';

interface IProps extends IIndependentFormProps {
  data?: Clinic;
}

const ClinicMappingsForm = forwardRef<
  IIndependentFormRef<IClinicMappings>,
  IProps
>((props, ref) => {
  const { data } = props;

  const { t } = useTranslation();

  const user = useUser();

  const agencyOptions = useMemo<AgencySelectOption[] | undefined>(() => {
    if (user.isAgencyDoctor) {
      return user.agencies.map((el) => ({
        label: el.legalname,
        value: el.guid,
      }));
    }

    return undefined;
  }, [user]);

  const formik = useFormik<IClinicMappings>({
    initialValues: prepareValue(data, agencyOptions),
    onSubmit: () => {},
    validateOnChange: false,
    validationSchema: clinicMappingsValidationSchema(),
  });

  const { values, setFieldValue } = formik;

  const submitForm = async () => {
    await formik.submitForm();
    const isValid = await formik
      .validateForm()
      .then((res) => !Object.keys(res).length);

    return {
      values: formik.values,
      formName: FormDisplayName,
      isValid,
    };
  };

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: formik.resetForm,
  }));

  useEffect(() => {
    if (data) formik.resetForm({ values: prepareValue(data, agencyOptions) });
  }, [data]);

  return (
    <BaseForm formik={formik}>
      <BaseFormGroup label={t('labels.mappings')}>
        <BaseGrid columns={2}>
          {(user.isAdmin || user.isAgencyDoctor) && (
            <BaseFormItem
              name="agencyGuid"
              label={t('labels.agency')}
              required
              isBlackLabel
            >
              <AgencySelectBox
                options={agencyOptions}
                allowClear
                onChange={(val) => {
                  setFieldValue('agencyGuid', val);
                  setFieldValue('parentClinicGuid', null);
                }}
              />
            </BaseFormItem>
          )}

          {!user.isPractice && (
            <BaseFormItem
              name="parentClinicGuid"
              label={t('labels.parentClinic')}
              isBlackLabel
            >
              <ClinicSelectBox
                agencyGuid={[values.agencyGuid]}
                disabled={!values.agencyGuid}
                allowClear
              />
            </BaseFormItem>
          )}
          <BaseFormItem
            name="clinicId"
            label={t('labels.registrationId')}
            isBlackLabel
          >
            <BaseInput />
          </BaseFormItem>
        </BaseGrid>
      </BaseFormGroup>
    </BaseForm>
  );
});

ClinicMappingsForm.displayName = FormDisplayName;

export default ClinicMappingsForm;
