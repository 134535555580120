import SentMessageIcon from 'components/atoms/icons/sent-message-check-mark.svg?react';
import { useTranslation } from 'react-i18next';
import { fullNamePresenter } from 'utils';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import { type FC } from 'react';

import s from './styles.module.scss';

import type { IActivityComments } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface ReviewedItemProps {
  comment: IActivityComments;
}

const ReviewedItem: FC<ReviewedItemProps> = ({ comment }) => {
  const { t } = useTranslation();

  return (
    <li
      data-cy="comment-reviewed"
      className={s['reviewed-item']}
      key={comment.guid}
      data-new-action={comment?.newAction}
    >
      <div className={s['reviewed-item-wrapper']}>
        <SentMessageIcon className={s['sent-check-mark']} />
        <div>
          <p className={s['reviewed-item-title']}>
            {t('labels.readingWasReviewed')}
          </p>
          <p className={s['reviewed-item-signed-by']}>
            <span>{t('labels.electronicallySignedBy')}:</span>
            <span>
              {fullNamePresenter({
                firstName: comment.createdByFirstName,
                lastName: comment.createdByLastName,
                title: comment.createdByTitle,
              })}
            </span>
          </p>
        </div>
        <p className={s['reviewed-item-date']}>
          {DateFormatter({
            date: comment.createdAt,
            format: DateFormats['MM-dd-yyyy | h:mm a'],
          })}
        </p>
      </div>
    </li>
  );
};
export default ReviewedItem;
