import React from 'react';

const SmsIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25281 3.46803C6.81726 -0.355162 11.5959 -1.12882 14.649 1.70504C17.702 4.5389 17.8643 12.3602 14.649 15.8089C11.4337 19.2576 4.023 18.1512 1.49955 15.8089C-1.0239 13.4667 -0.311651 7.29121 3.25281 3.46803Z"
      fill="#FB9318"
      {...props}
    />
    <path
      d="M12.5325 14.4259L10.177 12.4931H6.93689C5.86901 12.4931 5 11.6051 5 10.5139V8.55341C5 7.46221 5.86901 6.57422 6.93689 6.57422H12.0631C13.131 6.57422 14 7.46221 14 8.55341V10.5139C14 11.4321 13.3845 12.2165 12.5325 12.4335V14.4259Z"
      fill="white"
    />
    <path
      d="M6.60326 9.8668C6.70351 9.92778 6.91143 9.99947 7.07266 9.99947C7.23745 9.99947 7.30557 9.94207 7.30557 9.85253C7.30557 9.76272 7.25174 9.71987 7.04768 9.65176C6.68567 9.52982 6.54588 9.33288 6.54944 9.12497C6.54944 8.79895 6.82905 8.55176 7.26246 8.55176C7.4668 8.55176 7.64974 8.59817 7.75711 8.65201L7.66043 9.02828C7.5816 8.98515 7.43109 8.92776 7.28057 8.92776C7.14791 8.92776 7.07266 8.98159 7.07266 9.07113C7.07266 9.15353 7.14076 9.19665 7.35582 9.2719C7.68899 9.38644 7.82878 9.55507 7.83237 9.81299C7.83237 10.139 7.57445 10.3791 7.07266 10.3791C6.84332 10.3791 6.63897 10.3291 6.50659 10.2574L6.60326 9.8668Z"
      fill="#FB8A17"
      {...props}
    />
    <path
      d="M8.16175 9.14996C8.16175 8.93161 8.15461 8.74511 8.14746 8.59103H8.60615L8.63115 8.82751H8.64185C8.71711 8.71655 8.87119 8.55176 9.17221 8.55176C9.39798 8.55176 9.57706 8.6663 9.65232 8.84894H9.65946C9.724 8.7594 9.80284 8.68771 9.88523 8.63774C9.98219 8.58034 10.0896 8.55178 10.2187 8.55178C10.5554 8.55178 10.81 8.78826 10.81 9.31121V10.3434H10.2797V9.39028C10.2797 9.13567 10.197 8.98874 10.0215 8.98874C9.89623 8.98874 9.80642 9.07469 9.77071 9.1788C9.75642 9.21807 9.74929 9.27549 9.74929 9.31859V10.3433H9.21894V9.36145C9.21894 9.13924 9.14011 8.98874 8.96789 8.98874C8.82837 8.98874 8.74569 9.0964 8.71356 9.18595C8.69571 9.2288 8.69214 9.27905 8.69214 9.32218V10.3434H8.16179V9.14996H8.16175Z"
      fill="#FB8A17"
      {...props}
    />
    <path
      d="M11.2393 9.8668C11.3395 9.92778 11.5474 9.99947 11.7087 9.99947C11.8735 9.99947 11.9416 9.94207 11.9416 9.85253C11.9416 9.76272 11.8877 9.71987 11.6837 9.65176C11.3217 9.52982 11.1819 9.33288 11.1854 9.12497C11.1854 8.79895 11.465 8.55176 11.8984 8.55176C12.1028 8.55176 12.2854 8.59817 12.3931 8.65201L12.2964 9.02828C12.2176 8.98515 12.0671 8.92776 11.9166 8.92776C11.7839 8.92776 11.7086 8.98159 11.7086 9.07113C11.7086 9.15353 11.7767 9.19665 11.9918 9.2719C12.325 9.38644 12.4648 9.55507 12.4684 9.81299C12.4684 10.139 12.2105 10.3791 11.7086 10.3791C11.4793 10.3791 11.275 10.3291 11.1426 10.2574L11.2393 9.8668Z"
      fill="#FB8A17"
      {...props}
    />
  </svg>
);

export default SmsIcon;
