import { Card, CardBody } from 'reactstrap';
import AverageIcon from 'components/atoms/icons/averageIcon';

import Average from './average';

import type { FC } from 'react';
import type { PatientAverages } from 'utils/hooks/turkDashboard/useAverages';

type Props = {
  averages: PatientAverages;
};

const Averages: FC<Props> = ({ averages }) => {
  return (
    <>
      <div className="average-metrics" data-cy="average-30-days-metrics">
        <Card className="card-border">
          <CardBody>
            <h3>
              <AverageIcon /> &nbsp; Average Last 30 Days
            </h3>
            <Average data={averages.averages30} />
          </CardBody>
        </Card>
      </div>
      <div className="average-metrics" data-cy="average-90-days-metrics">
        <Card className="card-border">
          <CardBody>
            <h3>
              <AverageIcon /> &nbsp; Average Last 90 Days
            </h3>
            <Average data={averages?.averages90} />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Averages;
