import React from 'react';
import { isNotUndefined } from 'infrastructure/functions';
import common from 'constants/common';

import s from './styles.module.scss';

import type { IAdminPanelPatient } from 'infrastructure/interfaces';

interface IProps {
  record: IAdminPanelPatient;
}

const RpmStatus: React.FC<IProps> = ({ record }) => {
  const status = isNotUndefined(record.status)
    ? common.statusPatients[record.status]
    : undefined;
  const background = status?.color;

  return (
    <div className={s['rpm-status-wrapper']}>
      <p className={s.status} style={{ background }}>
        {status?.label}
      </p>
    </div>
  );
};

export default RpmStatus;
