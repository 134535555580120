import i18next from 'i18next';
import BaseTableActions from 'components/atoms/base-table/actions';

import type { CareLocation } from 'infrastructure/classes/patient/care-location';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

interface ICareLocationColumnsProps {
  readOnly: boolean;
  onEdit: (record: CareLocation) => void;
  onDelete: (record: CareLocation) => void;
}

export const careLocationColumns = ({
  readOnly,
  onEdit,
  onDelete,
}: ICareLocationColumnsProps): IBaseTableColumns<CareLocation> => {
  const columns: IBaseTableColumns<CareLocation> = [
    {
      label: i18next.t('labels.nameLocation'),
      key: 'facilityName',
      width: '230px',
    },
    {
      label: i18next.t('labels.address'),
      key: 'facilityAddress',
      width: '230px',
    },
    {
      label: i18next.t('labels.city'),
      key: 'city',
      width: '230px',
    },
    {
      label: i18next.t('labels.state'),
      key: 'state',
      width: '230px',
    },
    {
      label: i18next.t('labels.zipCode'),
      key: 'zipCode',
      width: '230px',
    },
    {
      label: i18next.t('labels.facilityNumber'),
      key: 'facilityNumber',
      width: '230px',
    },
  ];

  if (!readOnly) {
    columns.push({
      label: i18next.t('labels.actions'),
      key: 'actions',
      align: 'center',
      width: '120px',
      render: (_, record) => (
        <BaseTableActions
          onEdit={() => onEdit(record)}
          onDelete={() => onDelete(record)}
        />
      ),
    });
  }

  return columns;
};
