import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'components/molecules/paginationTransition';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import withLoader from 'components/organisms/withLoader';
import { format, add } from 'date-fns';
import { showResult } from 'infrastructure/hooks/utils';
import common from 'constants/common';
import { YESTERDAYS_STATS_CSV_REPORT } from 'services/dataService/resources';
import dataService from 'services/dataService';

import ReadingsTable from './table';

const getReport = async () => {
  const { error, data } = await dataService.getList(
    YESTERDAYS_STATS_CSV_REPORT,
  );
  return { error, data };
};

const Statistics = ({ readingsCount, count, page, setPage, itemsPerPage }) => {
  const [loading, setLoading] = useState(false);
  const sendReport = async () => {
    setLoading(true);
    const { error } = await getReport();
    setLoading(false);
    return showResult(error, common.submitSuccessTypes.sendReport);
  };

  return (
    <>
      <div className="performance-review__header">
        <div className="statistics-report__header">
          <h1 className="statistics-report__header-text">
            Report: Yesterday's Statistics
          </h1>
          <div className="statistics-report__header-date">
            {format(add(new Date(), { days: -1 }), 'MMM dd yyyy')}
          </div>
        </div>
        <div className="performance-review__header-icon">
          {withLoader(
            loading,
            <button onClick={sendReport}>
              <DownloadRoundedIcon className="performance-review__header-icon-svg" />
              <br />
              <span>Download</span>
            </button>,
          )}
        </div>
      </div>
      <div className="statistics-report__wrapper">
        <div>
          <strong># Of Active Patients By Day</strong>
          <div className="statistics-report__table">
            <ReadingsTable
              weeks={readingsCount}
              dataCy="yesterdays-stats__readings-table"
            />
          </div>
        </div>
        <div className="statistics-report__spacer" />
        <div>
          <strong>Variance From Same Day Previous Week</strong>
          <div className="statistics-report__table">
            <ReadingsTable
              weeks={readingsCount}
              dataCy="yesterdays-stats__increase-table"
              displayPercentage
            />
          </div>
          <Pagination
            title=""
            page={page}
            pageTotal={readingsCount.length}
            itemsPerPage={itemsPerPage}
            total={count}
            handleFirstClick={() => setPage(1)}
            handleLastClick={() => setPage(Math.ceil(count / itemsPerPage))}
            handlePrevClick={() => setPage(page - 1)}
            handleNextClick={() => setPage(page + 1)}
            handleGoToPage={(numberPage) => setPage(numberPage)}
          />
        </div>
      </div>
    </>
  );
};

Statistics.propTypes = {
  readingsCount: PropTypes.array,
  count: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
  itemsPerPage: PropTypes.number,
};

export default Statistics;
