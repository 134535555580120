import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import TimeSelectBox from 'components/molecules/time-select-box';
import BaseButton from 'components/atoms/baseButton';
import ChatPlusIcon from 'components/atoms/icons/chatPlusIcon';
import CrossIcon from 'components/atoms/icons/cross-icon';
import { useFormik } from 'formik';
import { Availability } from 'infrastructure/classes/availability';
import classNames from 'classnames';
import ListAnimation from 'components/atoms/base-animations/list';
import { useTranslation } from 'react-i18next';
import SpringAnimation from 'components/atoms/base-animations/spring';
import FadeAnimation from 'components/atoms/base-animations/fade';

import s from './styles.module.scss';
import { contactAvailabilityTimesValidationSchema } from './validation-schema';

import type { IWeekDayItem } from 'infrastructure/data-sources/week-days';

interface IProps {
  patientGuid: string;
  anytime?: boolean;
  day: IWeekDayItem;
  times: Availability[];
}

export interface IContactAvailabilityViewDayRef {
  resetForm: () => void;
  validate: () => {
    isValid: boolean;
    day: IWeekDayItem;
    times: Availability[];
  };
}

const ContactAvailabilityViewDay = forwardRef<
  IContactAvailabilityViewDayRef,
  IProps
>((props, ref) => {
  const { day, times, anytime = false, patientGuid } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      times,
    },
    onSubmit: () => {},
    validationSchema: contactAvailabilityTimesValidationSchema(),
  });

  const nameClassNames = classNames(s.name, {
    [s.active]: !!formik.values.times.length,
  });

  const getError = (index: number, field: 'startTime' | 'endTime') => {
    const err: any = formik.errors.times ? formik.errors?.times[index] : false;
    return err ? Boolean(err[field]) : false;
  };

  const onDelete = (index: number) => {
    formik.setValues({
      times: formik.values.times.filter((_, i) => index !== i),
    });
  };

  const onCreate = () => {
    const newItem = Availability.getInitialValues(patientGuid);
    formik.setValues({
      times: [newItem, ...formik.values.times],
    });
  };

  const validate = () => {
    formik.validateForm();
    formik.validateForm();
    return {
      isValid: formik.isValid,
      day,
      times: formik.values.times,
    };
  };

  const resetForm = () => {
    formik.resetForm();
  };

  useImperativeHandle(ref, () => ({ validate, resetForm }));

  useEffect(() => {
    formik.resetForm({ values: { times } });
  }, [times]);

  return (
    <li
      className={s.day}
      data-cy={`contactAvailability-editBlock-${props.day.name}`}
    >
      <div className={s.controls}>
        <span className={nameClassNames} data-cy="contactAvailability-editDay">
          {day.name}
        </span>
        {!anytime && (
          <FadeAnimation uniqueKey={`${anytime}`}>
            <BaseButton
              dataCy="contactAvailability-addTime"
              type="secondary"
              icon={<ChatPlusIcon />}
              onClick={onCreate}
            />
          </FadeAnimation>
        )}
      </div>
      <ul className={s.times}>
        <ListAnimation>
          {!anytime &&
            formik.values.times.map((time, index) => (
              <SpringAnimation key={time.key} uniqueKey={time.key}>
                <li className={s.time} key={time.key}>
                  <div
                    className={s.time}
                    data-cy="contactAvailability-timeInputs"
                  >
                    <TimeSelectBox
                      dataCy="contactAvailability-startTime-dropdown"
                      value={formik.values.times[index].startTime}
                      minWidth="140px"
                      disabledAfter={formik.values.times[index].endTime}
                      invalid={getError(index, 'startTime')}
                      onChange={(val) =>
                        formik.setFieldValue(`times.${index}.startTime`, val)
                      }
                    />
                    <TimeSelectBox
                      dataCy="contactAvailability-endTime-dropdown"
                      value={formik.values.times[index].endTime}
                      minWidth="140px"
                      disabledUntil={formik.values.times[index].startTime}
                      invalid={getError(index, 'endTime')}
                      onChange={(val) =>
                        formik.setFieldValue(`times.${index}.endTime`, val)
                      }
                    />
                  </div>
                  <BaseButton
                    dataCy="contactAvailability-deleteTime"
                    type="secondary"
                    icon={<CrossIcon />}
                    onClick={() => onDelete(index)}
                  />
                </li>
              </SpringAnimation>
            ))}
          {!anytime && !formik.values.times.length && (
            <SpringAnimation>
              <li
                className={s.notification}
                data-cy="contactAvailability-notAvailable"
              >
                {t('labels.notAvailable')}
              </li>
            </SpringAnimation>
          )}
          {anytime && (
            <SpringAnimation>
              <li
                className={s.notification}
                data-cy="contactAvailability-anytimeAvailable"
              >
                {t('labels.anytime')}
              </li>
            </SpringAnimation>
          )}
        </ListAnimation>
      </ul>
    </li>
  );
});

ContactAvailabilityViewDay.displayName = 'ContactAvailabilityViewDay';

export default ContactAvailabilityViewDay;
