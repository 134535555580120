import React from 'react';

import s from './styles.module.scss';

import type { DateString } from 'infrastructure/types';

interface IDateBlockProps {
  date: DateString;
}

const DateBlock: React.FC<IDateBlockProps> = (props) => {
  const { date } = props;
  return <div className={s['date-block']}>{date}</div>;
};

export default DateBlock;
