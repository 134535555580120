import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import C from 'classnames';
import DatePicker, { registerLocale } from 'react-datepicker';
import forms from 'constants/forms';
import { Col, Row } from 'reactstrap';
import {
  sub,
  parse,
  format,
  endOfDay,
  startOfDay,
  differenceInHours,
} from 'date-fns';
import enGb from 'date-fns/locale/en-GB';
import 'react-datepicker/dist/react-datepicker.css';
import { getRangeDates } from 'utils';
import ResetRangesIcon from 'components/atoms/icons/rangeDatesResetIcon';

registerLocale('en-Gb', enGb);

const CustomDatePicker = ({
  rangeValue,
  handleChange,
  setDates,
  selectedDates,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [startDateInput, setStartDateInput] = useState('');
  const [endDateInput, setEndDateInput] = useState('');

  const onChange = (dates) => {
    const [start, end] = dates;
    const difference = differenceInHours(end, start);
    const differenceCondition =
      !isCustomRange && (difference === 4 || difference === 24);
    const startOfStartDate = differenceCondition
      ? start
      : (start && startOfDay(start)) ?? null;
    const endOfEndDate = differenceCondition
      ? end
      : (end && endOfDay(end)) ?? null;
    setStartDate(startOfStartDate);
    setEndDate(endOfEndDate);
    setDates([startOfStartDate, endOfEndDate]);
  };

  const onSelect = () => {
    handleChange(null);
    setIsCustomRange(true);
  };

  const setRange = (range) => {
    if (!range) {
      setIsCustomRange(true);
      onChange([sub(new Date(), { days: 1 }), new Date()]);
      handleChange(null);
      return;
    }
    const { startRangeDate, endRangeDate } = getRangeDates(range);
    onChange([startRangeDate, endRangeDate]);
    handleChange(range);
    setIsCustomRange(false);
  };

  useEffect(() => {
    if (selectedDates.length > 0) {
      const [selectedStartDate, selectedEndDate] = selectedDates;
      setStartDate(selectedStartDate);
      setEndDate(selectedEndDate);
    } else {
      setRange(rangeValue);
    }
  }, []);

  const handleResetClick = () => {
    setIsCustomRange(false);
    setRange(forms.timeFilterRanges[4]);
  };
  const handleFirstDateChange = (data) => {
    setStartDateInput(data.target.value);
    setIsCustomRange(true);
    const date = parse(data.target.value, 'MM/dd/yyyy', new Date());
    if (!Number.isNaN(date.getTime())) {
      onChange([date, endDate]);
      handleChange([date, endDate]);
    }
  };
  const handleFirstDateReset = () => {
    setStartDateInput('');
  };
  const handleLastDateChange = (data) => {
    setEndDateInput(data.target.value);
    setIsCustomRange(true);
    const date = parse(data.target.value, 'MM/dd/yyyy', new Date());
    if (!Number.isNaN(date.getTime())) {
      onChange([startDate, date]);
      handleChange([startDate, date]);
    }
  };
  const handleLastDateReset = () => {
    setEndDateInput('');
  };

  return (
    <div className="range-datepicker-mobile">
      <Row>
        <Col xs={12} md={6}>
          <div className="range-datepicker--datepicker">
            <div className="range-datepicker-inputs">
              <div
                onClick={handleResetClick}
                className="range-datepicker-inputs-reset"
              >
                Reset
              </div>
              <div className="range-datepicker-inputs-values">
                <input
                  type="text"
                  onChange={handleFirstDateChange}
                  placeholder={format(startDate, 'MM/dd/yyyy')}
                  value={startDateInput}
                />
                <ResetRangesIcon onClick={handleFirstDateReset} />
              </div>
              <div className="range-datepicker-inputs-values">
                <input
                  type="text"
                  onChange={handleLastDateChange}
                  placeholder={endDate ? format(endDate, 'MM/dd/yyyy') : ''}
                  value={endDateInput}
                />
                <ResetRangesIcon onClick={handleLastDateReset} />
              </div>
            </div>
            <DatePicker
              locale="en-Gb"
              formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
              selected={endDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              onSelect={onSelect}
              maxDate={new Date()}
              selectsRange
              inline
            />
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="range-datepicker--ranges">
            <ul>
              <li
                className={C({ active: isCustomRange })}
                onClick={() => setRange()}
              >
                Custom Date Range
              </li>
              {forms.timeFilterRanges.map((range) => (
                <li
                  className={C({
                    active: !isCustomRange && rangeValue?.id === range.id,
                  })}
                  key={range.id}
                  onClick={() => setRange(range)}
                >
                  {range.label}
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

CustomDatePicker.propTypes = {
  rangeValue: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  setDates: PropTypes.func.isRequired,
  selectedDates: PropTypes.array,
};

export default CustomDatePicker;
