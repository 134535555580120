import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_APPOINTMENT_COMPLETIONS } from 'services/dataService/ccmResources';

import { getAppointmentCompletionKey } from './use-patient-appointment-completion';
import { showResult } from '../utils';

interface Props {
  patientGuid: string;
}

const removePatientAppointmentCompletionFn = async (guid: string) => {
  const { data, error } = await dataService.deleteOne(
    API_APPOINTMENT_COMPLETIONS,
    { id: guid },
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useRemoveAppointmentCompletion = ({ patientGuid }: Props) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: async (guid: string) =>
      removePatientAppointmentCompletionFn(guid),
    onSuccess() {
      showResult();
      const queryKey = getAppointmentCompletionKey(patientGuid, 1);
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return { removeAppointmentCompletion: mutate, removeLoading: isLoading };
};
