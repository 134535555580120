import { endOfDay, startOfMonth } from 'date-fns';
import {
  parseSearchArrayValue,
  parseSearchStringValue,
} from 'infrastructure/functions/parse-query-string';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { addQueryParams } from 'utils/queryHelpers';

export const DEFAULT_DATE_RANGE = [
  startOfMonth(new Date()),
  endOfDay(new Date()),
] as [Date, Date];

export const useActivityFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    page,
    action,
    readingType,
    clinicState,
    doctorGuid,
    patientGuid,
    startTime,
    endTime,
    assignee,
    status,
    slaReadings,
    clinicGuid,
    doctorSearchValue,
    insuranceType,
    reverse,
    actionTimesQuota,
  } = queryString.parse(location.search);

  const [startDate, endDate] = DEFAULT_DATE_RANGE;

  const filter = useMemo(
    () => ({
      applySpecialFilter: true,
      page: Number(page || 1),
      reviewed: Boolean(action) ?? false,
      reverse: Boolean(reverse),
      readingType: parseSearchStringValue(readingType),
      clinicState: parseSearchStringValue(clinicState),
      doctorGuid: parseSearchArrayValue(doctorGuid),
      patientGuid: parseSearchStringValue(patientGuid),
      clinicGuid: parseSearchStringValue(clinicGuid),
      startTime: parseSearchStringValue(startTime)
        ? Number(startTime)
        : startDate.getTime(),
      endTime: parseSearchStringValue(endTime)
        ? Number(endTime)
        : endDate.getTime(),
      assignee: parseSearchStringValue(assignee),
      status: parseSearchStringValue(status),
      slaReadings: parseSearchStringValue(slaReadings),
      insuranceType: parseSearchStringValue(insuranceType),
      actionTimesQuota: parseSearchStringValue(actionTimesQuota),
    }),
    [
      page,
      action,
      readingType,
      clinicState,
      String(doctorGuid),
      patientGuid,
      startTime,
      endTime,
      assignee,
      status,
      slaReadings,
      clinicGuid,
      insuranceType,
      reverse,
      actionTimesQuota,
    ],
  );

  const resetFilter = () => {
    navigate(
      {
        search: queryString.stringify({}),
      },
      { replace: true },
    );
  };

  const updateQueryParams = (
    newFilter: Partial<typeof filter> & {
      action?: boolean;
      doctorSearchValue?: string;
    },
  ) => {
    addQueryParams({ navigate, location }, newFilter);
  };

  const updateQueryParamsAndResetPage = (
    newFilter: Partial<typeof filter> & {
      action?: boolean;
      doctorSearchValue?: string;
    },
  ) => {
    addQueryParams({ navigate, location }, { ...newFilter, page: undefined });
  };

  return {
    filter,
    doctorSearchValue: parseSearchStringValue(doctorSearchValue),
    updateQueryParams,
    updateQueryParamsAndResetPage,
    resetFilter,
  };
};
