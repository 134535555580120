import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const deviceValidationSchema = () =>
  createValidationSchema({
    manufacturer: commonValidationSchemas.string({ required: true }),
    deviceId: commonValidationSchemas.string({ required: true }),
    readingTypes: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      {
        required: true,
        min: 1,
      },
    ),
  });
