import i18n from 'infrastructure/i18n';

import s from './styles.module.scss';
import Sla from './sla';
import PatientInfo from './patient-info';
import CcmStatus from './ccm-status';
import RpmStatus from './rpm-status';

import type { IAdminPanelPatient } from 'infrastructure/interfaces';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

export const adminPanelPatientsColumns: IBaseTableColumns<IAdminPanelPatient> =
  [
    {
      label: '№',
      key: 'guid',
      width: '50px',
      align: 'center',
      render: (_, record, idx) => <span className={s.number}>{idx + 1}</span>,
    },
    {
      label: i18n.t('labels.sla'),
      key: 'clinicServiceDeliveryThresholds',
      width: '20px',
      align: 'center',
      render: (sla?: number) => <Sla value={sla} />,
    },
    {
      label: i18n.t('labels.patientName'),
      key: 'firstName',
      render: (_, record) => <PatientInfo record={record} />,
    },
    {
      label: i18n.t('labels.rpmStatus'),
      key: 'status',
      width: '270px',
      render: (_, record) => <RpmStatus record={record} />,
    },
    {
      label: i18n.t('labels.ccmStatus'),
      key: 'ccmStatus',
      width: '430px',
      render: (_, record) => <CcmStatus record={record} />,
    },
    {
      label: i18n.t('labels.clinic'),
      key: 'clinic',
      width: '185px',
      render: (value: string) => <p className={s.clinic}>{value}</p>,
    },
  ];
