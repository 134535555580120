import { getReadingInfo, hiddenReadingTypes } from 'utils/readings';

import type { FC } from 'react';

interface Props {
  activity: {
    value: {
      [key: string]: string | number;
    };
  };
}

const ReadingItem: FC<Props> = ({ activity }) => {
  return (
    <>
      {Object.keys(activity.value).map((readingType, index) => {
        if (hiddenReadingTypes.includes(readingType)) {
          return null;
        }

        const { className, key, unit, value } = getReadingInfo(
          activity,
          readingType,
        );

        return (
          <span className={className} key={readingType + index}>
            {key === 'spo2' ? 'SpO2' : key}:
            <strong>
              {` ${value}`}
              <span className="nobr">{` ${unit}`}</span>
            </strong>
          </span>
        );
      })}
    </>
  );
};

export default ReadingItem;
