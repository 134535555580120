import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Input from 'components/atoms/input';
import forms from 'constants/forms';

const IRS = ({ errors }) => {
  const irs = [...forms.adminPanelsAgency.agencyEdit.irs];
  return (
    <div className="admin-patient-form-block">
      <span>IRS</span>
      <Row>
        {irs.map((field) => (
          <Col lg="6" md="6" sm="6" xs="12" key={field.id}>
            <Input {...field} disabled={false} />
            {errors[field.id] ? (
              <div className="input-error">
                {errors[field.id].split('.')[0]}
              </div>
            ) : null}
          </Col>
        ))}
      </Row>
    </div>
  );
};

IRS.propTypes = {
  errors: PropTypes.shape({}).isRequired,
};

export default IRS;
