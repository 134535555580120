import s from './style.module.scss';

import type { FC } from 'react';

interface Props {
  count: number;
}
const EggIcon: FC<Props> = ({ count }) => {
  return (
    <div className={s.wrapper}>
      <span className={s.count}>{count}</span>
      <svg
        width="32"
        height="32"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.3463 7.70672C23.9594 -0.789249 12.7155 -2.50849 5.53184 3.78898C-1.65186 10.0864 -2.03357 27.4672 5.53184 35.1309C13.0972 42.7947 30.5341 40.336 36.4716 35.1309C42.4092 29.9259 40.7333 16.2027 32.3463 7.70672Z"
          fill="#FB9318"
        />
      </svg>
    </div>
  );
};

export default EggIcon;
