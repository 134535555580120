import { useState, type FC } from 'react';
import { usStatesSelectWithCompact } from 'constants/states';
import { useInterval } from 'react-use';
import { format } from 'date-fns';

import StateSelect from './select';
import GetReadingsButton from './button';
import s from './styles.module.scss';

interface Props {
  getReadingsTimeoutTime: number | undefined;
  getReadings: (filter: { states: Array<string | undefined> }) => any;
  isLoading?: boolean;
}

const Sort: FC<Props> = ({
  getReadingsTimeoutTime = 0,
  getReadings,
  isLoading,
}) => {
  const [timerText, setTimerText] = useState('');
  const isTimerRunning = getReadingsTimeoutTime - Date.now() > 0;

  useInterval(
    () => {
      if (!getReadingsTimeoutTime) return;
      const diff = getReadingsTimeoutTime - Date.now();
      setTimerText(format(diff, 'mm:ss'));
    },
    isTimerRunning ? 1000 : null,
  );

  const [states, setStates] = useState([usStatesSelectWithCompact[0]]);
  const disableButton = isLoading || isTimerRunning;

  return (
    <div className={s.wrapper}>
      <StateSelect
        value={states}
        setValue={setStates}
        disabled={disableButton}
      />
      <GetReadingsButton
        disabled={disableButton}
        onClick={() => {
          getReadings({ states: states.map((state) => state.value) });
        }}
        isTimerRunning={isTimerRunning}
        timerText={timerText}
      />
    </div>
  );
};

export default Sort;
