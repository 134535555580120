export const clinicProviderServiceTypeOptions = [
  { label: 'Cardiology', value: 'Cardiology' },
  {
    label: 'Endocrinology and Metabolism',
    value: 'Endocrinology and Metabolism',
  },
  { label: 'Family Medicine', value: 'Family Medicine' },
  { label: 'Gastroenterology', value: 'Gastroenterology' },
  { label: 'General Internal Medicine', value: 'General Internal Medicine' },
  { label: 'Geriatric Medicine', value: 'Geriatric Medicine' },
  { label: 'Infectious Disease', value: 'Infectious Disease' },
  { label: 'Oncology', value: 'Oncology' },
  { label: 'Nephrology', value: 'Nephrology' },
  { label: 'Neurology', value: 'Neurology' },
  { label: 'Pain Specialist', value: 'Pain Specialist' },
  { label: 'Pediatrics', value: 'Pediatrics' },
  { label: 'Psychiatry', value: 'Psychiatry' },
  { label: 'Pulmonology', value: 'Pulmonology' },
  { label: 'Rheumatology', value: 'Rheumatology' },
  { label: 'Urology', value: 'Urology' },
  {
    label: 'Rural Healthcare Center (RHC)',
    value: 'Rural Healthcare Center (RHC)',
  },
  { label: 'Academia', value: 'Academia' },
  { label: 'FQHC', value: 'FQHC' },
  { label: 'Health and Wellness', value: 'Health and Wellness' },
  { label: 'Surgical', value: 'Surgical' },
];

export const clinicServiceDeliveryModel = [
  { label: 'SaaS', id: 'SaaS', value: 'SaaS' },
  { label: 'Fully Managed', id: 'Fully Managed', value: 'Fully Managed' },
];

export const clinicServiceDeliveryThresholds = [
  { label: 'No SLA', id: null, value: null },
  { label: '15 Minutes', id: 15, value: 15 },
  { label: '2 Hours', id: 120, value: 120 },
  { label: '4 Hours', id: 240, value: 240 },
  { label: '8 Hours', id: 480, value: 480 },
];

export const clinicReferralTypes = [
  {
    label: 'Remote Monitoring Services',
    id: 'Remote Monitoring Services',
    value: 'Remote Monitoring Services',
  },
  {
    label: 'Remote Monitoring Services and Chronic Care Management',
    id: 'Remote Monitoring Services and Chronic Care Management',
    value: 'Remote Monitoring Services and Chronic Care Management',
  },
  {
    label: 'Chronic Care Management',
    id: 'Chronic Care Management',
    value: 'Chronic Care Management',
  },
  { label: 'PCM', id: 'PCM', value: 'PCM' },
  { label: 'Wellness', id: 'Wellness', value: 'Wellness' },
];
