import type { FC, SVGProps } from 'react';

const GeneralCommentIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5922 4.62403C9.62437 -0.473549 16.3707 -1.50509 20.6809 2.27339C24.9911 6.05187 25.2201 16.4803 20.6809 21.0786C16.1416 25.6768 5.67952 24.2016 2.11701 21.0786C-1.4455 17.9555 -0.439978 9.72162 4.5922 4.62403Z"
        fill="#FB9318"
      />
      <path
        d="M9.75 12C9.75 12.4142 9.41421 12.75 9 12.75C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25C9.41421 11.25 9.75 11.5858 9.75 12Z"
        fill="white"
      />
      <path
        d="M12.75 12C12.75 12.4142 12.4142 12.75 12 12.75C11.5858 12.75 11.25 12.4142 11.25 12C11.25 11.5858 11.5858 11.25 12 11.25C12.4142 11.25 12.75 11.5858 12.75 12Z"
        fill="white"
      />
      <path
        d="M15 12.75C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25C14.5858 11.25 14.25 11.5858 14.25 12C14.25 12.4142 14.5858 12.75 15 12.75Z"
        fill="white"
      />
      <path
        d="M7.6235 17.8522L7.63867 17.8492C9.01075 17.5772 9.85002 17.2178 10.2396 17.0204C10.7963 17.1697 11.3874 17.25 12 17.25C15.3137 17.25 18 14.8995 18 12C18 9.10051 15.3137 6.75 12 6.75C8.68629 6.75 6 9.10051 6 12C6 13.3207 6.5573 14.5274 7.47734 15.45C7.42197 16.0283 7.27067 16.6488 7.08486 17.1886L7.08194 17.1971C7.05934 17.2625 7.03623 17.3267 7.01273 17.3896C6.97573 17.4884 6.93775 17.5838 6.89923 17.6748C6.84027 17.8141 6.95453 17.9702 7.10379 17.9458C7.20948 17.9285 7.3125 17.9106 7.41286 17.8922C7.48442 17.8791 7.55463 17.8658 7.6235 17.8522ZM8.22393 15.5215C8.24522 15.2991 8.16621 15.0787 8.00841 14.9205C7.21298 14.1228 6.75 13.1011 6.75 12C6.75 9.60566 9.0033 7.5 12 7.5C14.9967 7.5 17.25 9.60566 17.25 12C17.25 14.3943 14.9967 16.5 12 16.5C11.4531 16.5 10.9272 16.4283 10.4339 16.296C10.2554 16.2481 10.0655 16.2679 9.90061 16.3514C9.61018 16.4986 8.97125 16.7791 7.92508 17.0209C8.06626 16.5389 8.1762 16.0201 8.22393 15.5215Z"
        fill="white"
      />
    </svg>
  );
};

export default GeneralCommentIcon;
