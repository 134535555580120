import React, { useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import StickiesIcon from 'components/atoms/icons/stickies';

import s from './styles.module.scss';

import type { ReactElement } from 'react';
import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface ICopyableTextProps extends IBaseComponentProps {
  value: string;
  label?: React.ReactNode;
  width?: string;
  icon?: ReactElement | false;
  children?: React.ReactNode;
}

const CopyableText: React.FC<ICopyableTextProps> = (props) => {
  const { className, value, label, width, icon, children } = props;

  const { t } = useTranslation();

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string>(t('controls.copy'));

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const copyTextToClipboard = () => {
    setTooltipText(t('controls.copied'));
    navigator.clipboard.writeText(value);
    setTimeout(() => {
      setTooltipText(t('controls.copy'));
    }, 1000);
  };

  const buttonClassNames = classNames(
    s.btn,
    {
      [s.dots]: width,
    },
    className,
  );

  const ref = useRef(null);

  return (
    <div>
      <button
        style={{
          width,
        }}
        ref={ref}
        className={buttonClassNames}
        onClick={copyTextToClipboard}
      >
        {icon !== false && <i>{icon || <StickiesIcon className={s.icon} />}</i>}
        <span>{children || label || value}</span>
      </button>
      <Tooltip target={ref} isOpen={tooltipOpen} toggle={toggle} flip autohide>
        {tooltipText}
      </Tooltip>
    </div>
  );
};

export default CopyableText;
