import { useQuery } from '@tanstack/react-query';
import QueryParams from 'utils/queryParams';
import { API_ELIGIBILITY_PROCESS_ITEMS_LIST } from 'services/dataService/resources';
import dataService from 'services/dataService';

import type { EligibilityItemsData, ItemsFilters } from './types';

const REFETCH_INTERVAL = 10 * 1000;

export const useEligibilityItemsData = (filters: ItemsFilters) => {
  const queryKey = ['eligibilityItemsDashboard', filters] as const;

  const { data, error, isLoading, isPreviousData } = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: () => getItems(filters),
    refetchInterval: (_data) => {
      if (!_data) return false;
      const { eligibilityProcess } = _data;
      const { itemsCount, processedItemsCount } = eligibilityProcess;
      const haveUncompleted = itemsCount !== processedItemsCount;
      if (haveUncompleted) {
        return REFETCH_INTERVAL;
      }
      return false;
    },
  });

  return {
    data: {
      data: data?.eligibilityItem,
      count: data?.count,
      eligibilityProcess: data?.eligibilityProcess,
    },
    isLoading: isLoading || isPreviousData,
    error,
  };
};

const getItems = async (filters: ItemsFilters) => {
  const url = QueryParams.stringifyUrl(
    API_ELIGIBILITY_PROCESS_ITEMS_LIST,
    filters,
  );
  const { error, data } = await dataService.getList<EligibilityItemsData>(url);
  if (error) {
    throw new Error(error);
  }
  return data;
};
