import dataService from 'services/dataService';
import { API_PATIENT_DOCTOR_MESSAGES } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface DataProps {
  patientGuid: string;
  type: 'sms';
  message: string;
}

export const sendDoctorSmsFn = async (dataProps: DataProps) => {
  const endpoint = API_PATIENT_DOCTOR_MESSAGES;
  const { error, data } = await dataService.createOne(endpoint, dataProps);

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
