import dataService from 'services/dataService';
import {
  apiPatientServicePlans,
  apiPatientServicePlansByGuid,
} from 'services/dataService/resources';

const ITEMS_PER_PAGE = 10;

const INITIAL_STATE = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  data: [],
  totalPages: 0,
  totalCount: 0,
};

const servicePlans = {
  state: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    setPage: (state, payload) => ({
      ...state,
      page: payload < 1 ? 1 : payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    setServicePlans: (state, payload) => ({
      ...state,
      data: payload,
    }),
  },
  effects: (dispatch) => ({
    async getServicePlansByGuid({ patientGuid, status, insuranceProvider }) {
      const { data } = await dataService.getList(
        apiPatientServicePlansByGuid(patientGuid, status, insuranceProvider),
      );
      if (data) {
        dispatch.servicePlans.setServicePlans(data?.data || []);
        const totalCount = data?.count || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.activity.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
      }
    },
    async getServicePlans({ status, insuranceProvider }) {
      const { data } = await dataService.getList(
        apiPatientServicePlans(status, insuranceProvider),
      );
      if (data) {
        dispatch.servicePlans.setServicePlans(data || []);
        const totalCount = data?.count || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.activity.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
      }
    },
  }),
};

export default servicePlans;
