import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  XYPlot,
  LineSeries,
  XAxis,
  YAxis,
  DiscreteColorLegend,
  HorizontalGridLines,
  MarkSeries,
} from 'react-vis';
import common from 'constants/common';
import BaseLoader from 'components/atoms/base-loader';

import ChartHint from './chartHint';
import StickyBloodOrPulseYAxis from './bloodOrPulseStickyYAxis';

const legendItems = [
  {
    title: 'Pulse',
    color: common.chartColors.pulse.main,
    strokeStyle: 'solid',
  },
  {
    title: 'Pulse High',
    color: common.chartColors.pulse.high,
    strokeStyle: 'dashed',
  },
  {
    title: 'Pulse Low',
    color: common.chartColors.pulse.low,
    strokeStyle: 'dashed',
  },
];

const normalizeChartData = (data, thresholds, dates) => {
  const deviceData = data;
  const [startTime, endTime] = dates;

  let pulseData = deviceData.map((item) => ({
    value: item.data.pulse,
    createdAt: item.createdAt,
    readingType: item.readingType,
  }));
  if (pulseData.length < 1) {
    const emptyData = [
      {
        value: null,
        createdAt: startTime.getTime(),
      },
      {
        value: null,
        createdAt: endTime.getTime(),
      },
    ];
    pulseData = emptyData;
  }
  const pulseLow = pulseData.map((item, i) => ({
    x: +item.createdAt + i * 5000,
    y: thresholds.minCriticalPulse,
    label: item.readingType,
  }));
  const pulseHigh = pulseData.map((item, i) => ({
    x: +item.createdAt + i * 5000,
    y: thresholds.maxCriticalPulse,
    label: item.readingType,
  }));
  const pulseMain = pulseData.map((item, i) => ({
    x: +item.createdAt + i * 5000,
    y: item.value,
    label: item.readingType,
  }));
  if (pulseHigh.length === 1) {
    const xValue = pulseHigh[0].x;
    pulseHigh.unshift({
      x: xValue - 86400000,
      y: thresholds.maxCriticalPulse,
    });
    pulseHigh.push({
      x: parseInt(xValue) + 86400000,
      y: thresholds.maxCriticalPulse,
    });
    pulseLow.unshift({
      x: xValue - 86400000,
      y: thresholds.minCriticalPulse,
    });
    pulseLow.push({
      x: parseInt(xValue) + 86400000,
      y: thresholds.minCriticalPulse,
    });
  }
  return {
    pulse: {
      low: {
        data: pulseLow,
        stroke: common.chartColors.pulse.low,
        strokeStyle: 'dashed',
      },
      high: {
        data: pulseHigh,
        stroke: common.chartColors.pulse.high,
        strokeStyle: 'dashed',
      },
      main: {
        data: pulseMain,
        stroke: common.chartColors.pulse.main,
        strokeStyle: 'solid',
      },
    },
  };
};

const Pulse = ({ data, thresholds, loading }) => {
  const dates = useSelector((state) => state.patient.dates);

  const isHoveringOverLine = useRef({});
  const [hoveredPoint, setHoveredPoint] = useState(null);
  const [hintType, setHintType] = useState('');
  const yScale = common.chartYaxisScales.pulse;
  let chartDate = '';

  if (loading) return <BaseLoader className="loader" loading />;
  const chartData = normalizeChartData(data, thresholds, dates);

  return (
    <div className="chart" data-cy="pulse-chart">
      <div className="chart-header">
        <div className="chart-header-title" data-cy="pulse-chart-title">
          <p>Pulse</p>
          <p>BPM</p>
        </div>
        <DiscreteColorLegend items={legendItems} orientation="horizontal" />
      </div>
      <div className="chart-main">
        <StickyBloodOrPulseYAxis />
        <XYPlot
          xType="time"
          height={common.chartHeight}
          margin={{ left: 50 }}
          onMouseLeave={() => {
            setHoveredPoint(null);
          }}
          yDomain={yScale}
          width={common.chartWidth}
        >
          <HorizontalGridLines tickTotal={11} />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => {
              const dayMonth = format(d, 'MMM-dd');
              if (dayMonth !== chartDate) {
                chartDate = dayMonth;
                return format(d, common.dateFormats.chartXDate);
              }
              return '';
            }}
            tickSizeOuter={5}
            tickSizeInner={0}
            tickPadding={24}
          />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => format(d, common.dateFormats.chartXTime)}
            tickTotal={15}
            tickSizeOuter={5}
            tickSizeInner={0}
          />
          <YAxis
            attr="y"
            attrAxis="x"
            orientation="left"
            tickTotal={11}
            className="hidden-axis"
          />
          <LineSeries curve={null} opacity={1} {...chartData.pulse.low} />
          <LineSeries curve={null} opacity={1} {...chartData.pulse.high} />
          {chartData.pulse.main.data[0].y !== null && data.length === 1 && (
            <MarkSeries
              color={common.chartColors.pulse.main}
              data={chartData.pulse.main.data}
              opacity={1}
              size={2.5}
              onNearestXY={(datapoint) => {
                setHoveredPoint(datapoint);
                setHintType(chartData.pulse.main.data[0].label);
              }}
            />
          )}
          {chartData.pulse.main.data[0].y !== null && data.length > 1 && (
            <LineSeries
              curve={null}
              opacity={1}
              {...chartData.pulse.main}
              onSeriesMouseOver={() => {
                isHoveringOverLine.current[0] = true;
              }}
              onSeriesMouseOut={() => {
                isHoveringOverLine.current[0] = false;
              }}
              onNearestXY={(datapoint) => {
                if (isHoveringOverLine.current[0]) {
                  setHoveredPoint(datapoint);
                  setHintType('Pulse');
                }
              }}
            />
          )}
          {hoveredPoint && (
            <ChartHint hoveredPoint={hoveredPoint} type={hintType} />
          )}
        </XYPlot>
      </div>
    </div>
  );
};

Pulse.propTypes = {
  data: PropTypes.array,
  thresholds: PropTypes.shape(),
  loading: PropTypes.bool,
};

export default Pulse;
