import React from 'react';

const ClinicalActivityIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.75 7.55156V9.20226L14.1795 8.37692L14.9295 9.67596L13.5 10.5013L14.9295 11.3267L14.1795 12.6257L12.75 11.8004V13.4511H11.25V11.8003L9.8204 12.6257L9.0704 11.3267L10.5 10.5013L9.07041 9.67596L9.82041 8.37692L11.25 9.20229V7.55156H12.75Z"
      fill="currentColor"
    />
    <path
      d="M19.875 13.5C19.6679 13.5 19.5 13.6679 19.5 13.875V14.625C19.5 14.8321 19.6679 15 19.875 15H20.625C20.8321 15 21 14.8321 21 14.625V13.875C21 13.6679 20.8321 13.5 20.625 13.5H19.875Z"
      fill="currentColor"
    />
    <path
      d="M19.5 16.875C19.5 16.6679 19.6679 16.5 19.875 16.5H20.625C20.8321 16.5 21 16.6679 21 16.875V17.625C21 17.8321 20.8321 18 20.625 18H19.875C19.6679 18 19.5 17.8321 19.5 17.625V16.875Z"
      fill="currentColor"
    />
    <path
      d="M19.875 19.5C19.6679 19.5 19.5 19.6679 19.5 19.875V20.625C19.5 20.8321 19.6679 21 19.875 21H20.625C20.8321 21 21 20.8321 21 20.625V19.875C21 19.6679 20.8321 19.5 20.625 19.5H19.875Z"
      fill="currentColor"
    />
    <path
      d="M3.375 13.5C3.16789 13.5 3 13.6679 3 13.875V14.625C3 14.8321 3.16789 15 3.375 15H4.125C4.33211 15 4.5 14.8321 4.5 14.625V13.875C4.5 13.6679 4.33211 13.5 4.125 13.5H3.375Z"
      fill="currentColor"
    />
    <path
      d="M3.375 16.5C3.16789 16.5 3 16.6679 3 16.875V17.625C3 17.8321 3.16789 18 3.375 18H4.125C4.33211 18 4.5 17.8321 4.5 17.625V16.875C4.5 16.6679 4.33211 16.5 4.125 16.5H3.375Z"
      fill="currentColor"
    />
    <path
      d="M3 19.875C3 19.6679 3.16789 19.5 3.375 19.5H4.125C4.33211 19.5 4.5 19.6679 4.5 19.875V20.625C4.5 20.8321 4.33211 21 4.125 21H3.375C3.16789 21 3 20.8321 3 20.625V19.875Z"
      fill="currentColor"
    />
    <path
      d="M7.5 1.5C7.5 0.671573 8.17157 0 9 0L15 0C15.8284 0 16.5 0.671573 16.5 1.5V3C17.3284 3 18 3.67157 18 4.5V10.5H22.5C23.3284 10.5 24 11.1716 24 12V22.5C24 23.3284 23.3284 24 22.5 24H1.5C0.671574 24 0 23.3284 0 22.5L0 12C0 11.1716 0.671573 10.5 1.5 10.5H6L6 4.5C6 3.67157 6.67157 3 7.5 3V1.5ZM10.5 22.5H13.5V18H10.5V22.5ZM15 22.5H16.5V4.5L7.5 4.5L7.5 22.5H9V18C9 17.1716 9.67157 16.5 10.5 16.5H13.5C14.3284 16.5 15 17.1716 15 18V22.5ZM15 1.5L9 1.5V3L15 3V1.5ZM18 12V22.5H22.5V12H18ZM6 22.5L6 12H1.5L1.5 22.5H6Z"
      fill="currentColor"
    />
  </svg>
);

export default ClinicalActivityIcon;
