import { convertSecondsToTime } from 'utils/timeHelper';

import { ColumnFormBoldItem } from '../form';

import type { CcmAgentItem } from 'infrastructure/hooks/ccmAgentDashboard/types';
import type { FC } from 'react';

interface Props {
  item: CcmAgentItem;
}

const InteractiveTime: FC<Props> = ({ item }) => {
  return <ColumnFormBoldItem value={convertSecondsToTime(item.actionTime)} />;
};

export default InteractiveTime;
