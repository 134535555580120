import { FormGroup, Label, Input } from 'reactstrap';

import type { FormikValues, useFormik } from 'formik';
import type { InputType } from 'reactstrap/es/Input';

interface Props<T extends FormikValues> {
  label: string;
  formik: ReturnType<typeof useFormik<T>>;
  name: keyof T & string;
  type?: InputType;
  required?: boolean;
  disabled?: boolean;
}

const TurkConfigurationInput = <T extends FormikValues>({
  label,
  formik,
  name,
  type,
  required,
  disabled,
}: Props<T>) => {
  return (
    <FormGroup className="turk-configuration--label-value-element">
      <Label className="turk-configuration--label" for={name}>
        {label}: {required === true && <span className="mark-required">*</span>}
      </Label>
      <Input
        id={name}
        name={name}
        className="turk-configuration--input"
        type={type}
        value={formik.values[name]}
        onChange={formik.handleChange}
        disabled={disabled}
      />
      {formik.errors[name] && (
        <div className="turk-configuration--input--error">
          {formik.errors[name]}
        </div>
      )}
    </FormGroup>
  );
};

export default TurkConfigurationInput;
