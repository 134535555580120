import { useLocation } from 'react-router-dom';
import NotFound from 'components/atoms/notFound';
import { userHasAccess } from 'utils/accessManager/index';
import useUser from 'utils/useUser';

import type { ElementType } from 'react';

type Props = {
  component: ElementType;
  path: string;
};

export const AccessRouteComponent = ({ component: Component, path }: Props) => {
  const { pathname } = useLocation();
  const user = useUser();
  const paths = user.userType === 'patient' ? pathname : path;
  const hasAccess = userHasAccess(user, paths);

  if (!hasAccess) {
    return <NotFound />;
  }

  return <Component />;
};
