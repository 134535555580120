import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import debounce from 'lodash/debounce';
import dataService from 'services/dataService';
import List from 'components/atoms/list';
import classNames from 'classnames';
import useOutsideClick from 'utils/useOutsideClick';
import ResetSearchIcon from 'components/atoms/icons/searchResetIcon';
import { apiGetAgenciesByFilter } from 'services/dataService/resources';

const loadingOptions = [{ id: 'loading', label: 'Loading...' }];
let timer;

const AgencyAutocomplete = ({
  filter,
  handleClick,
  isDisplayLabel,
  isPatient,
  inputRef,
}) => {
  const [query, setQuery] = useState('');
  if (inputRef) inputRef.current = { setValue: setQuery };
  const containerRef = useRef();
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useOutsideClick(containerRef, () => {
    setShow(false);
  });
  const generateFilterLabel = (item) => `${item.legalname}`;

  const debouncedSearch = debounce(async (string) => {
    const { data } = await dataService.getList(
      `${apiGetAgenciesByFilter({ legalname: string })}`,
    );
    setIsLoading(false);
    if (data?.count > 0) {
      setOptions(
        data.items.map((item) => ({
          id: item.guid,
          value: item.guid,
          label: generateFilterLabel(item),
        })),
      );
    }
  }, 800);

  const handleInputChange = (string) => {
    if (string.target) {
      setQuery(string.target.value);
    }
    setIsLoading(true);
    clearTimeout(timer);
    timer = setTimeout(() => {
      debouncedSearch(string.target ? string.target.value : query);
    }, 500);
  };

  const handleOptionClick = (option) => {
    setQuery(option.label);
    handleInputChange(option.label);
    setShow(false);
    return handleClick(option);
  };

  const handleFocus = () => {
    if (filter.id === 'legalname') {
      setShow(true);
    }
  };

  const autoCompleteClass = () => {
    let className = 'card-border';
    if (isDisplayLabel) {
      className = 'card-border card-shadow border-0';
    }
    return className;
  };

  const resetSearch = () => {
    setQuery('');
    handleInputChange('');
    debouncedSearch('');
    handleClick({ value: '' });
  };

  return (
    <div className="patient-filter-autocomplete" ref={containerRef}>
      <div className="dropdown" role="button" aria-hidden>
        <Card className={autoCompleteClass()}>
          <CardBody
            className={classNames({
              'card-body-sort': !isPatient,
            })}
          >
            {isDisplayLabel && <p>{filter.label}</p>}

            <div className="dropdown-body">
              <input
                className={query !== '' ? 'arrow' : ''}
                placeholder={filter.placeholder}
                type="text"
                onChange={handleInputChange}
                value={query}
                onFocus={handleFocus}
              />
              {query !== '' && <ResetSearchIcon onClick={resetSearch} />}
            </div>
          </CardBody>
        </Card>
      </div>
      {isLoading && show && (
        <div className="patient-filter-dropdown-body">
          <List onChange={() => {}} options={loadingOptions} />
        </div>
      )}
      {!isLoading && options && options.length > 0 && show && (
        <div className="patient-filter-dropdown-body">
          <List onChange={handleOptionClick} options={options} />
        </div>
      )}
    </div>
  );
};

AgencyAutocomplete.propTypes = {
  filter: PropTypes.shape().isRequired,
  inputRef: PropTypes.shape(),
  handleClick: PropTypes.func.isRequired,
  isDisplayLabel: PropTypes.bool,
  isPatient: PropTypes.bool,
};

export default AgencyAutocomplete;
