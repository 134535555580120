import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

import type { TSchema } from 'infrastructure/functions';

export const patientGoalValidationSchema = () =>
  createValidationSchema({
    log: commonValidationSchemas.string({ required: true }),
  });

export type Values = TSchema<ReturnType<typeof patientGoalValidationSchema>>;
