import { useNavigate } from 'react-router-dom';
import Button from 'components/atoms/button';
import { scrollToErrorIfExist } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';
import { Formik, Form } from 'formik';
import { Card, CardBody, Table } from 'reactstrap';
import Info from 'components/organisms/adminPanels/turkUser/components/info';
import Registration from 'components/organisms/adminPanels/turkUser/components/registration';
import Profession from 'components/organisms/adminPanels/turkUser/components/profession';
import turkUserSchema from 'components/organisms/adminPanels/turkUser/validationSchema';
import useAgencies from 'utils/hooks/adminPanel/useAgencies';
import turkUserRequestGenerator from 'components/organisms/adminPanels/turkUser/requestBodyGenerator';
import dataService from 'services/dataService';
import { API_ADMIN_PANEL_AGENCY_DOCTORS } from 'services/dataService/resources';
import { useState } from 'react';
import useRoles from 'utils/hooks/adminPanel/useRoles';

import type { TurkUserSchema } from 'components/organisms/adminPanels/turkUser/validationSchema';

const AdminTurkUserAdd = () => {
  const navigate = useNavigate();
  const { agencies, isLoading: agenciesLoading } = useAgencies({
    noLimit: true,
  });
  const { roles, isLoading } = useRoles('homeHealth');
  const [submitLoading, setSubmitLoading] = useState(false);

  const submitHandler = async (doctorRequest: TurkUserSchema) => {
    const turkRole = roles?.find((role) => role.name === 'turk');
    if (!turkRole) {
      showResult(
        'Technical error occurred. Please refresh the page and try again.',
      );
      return;
    }
    const body = turkUserRequestGenerator(doctorRequest, {
      roleGuid: turkRole.guid,
    });
    setSubmitLoading(true);
    const { error, data } = await dataService.createOne(
      API_ADMIN_PANEL_AGENCY_DOCTORS,
      body,
    );
    setSubmitLoading(false);

    if (error) {
      showResult(error);
    }
    if (data) {
      showResult();
      navigate(`/admin/turk-users/${data.guid}`);
    }
  };

  return (
    <div className="admin-agent">
      <Card className="card-border">
        <CardBody>
          <div className="admin-agent-header">
            <span className="admin-header__title">Add Turk User Page</span>
          </div>
          <Formik
            onSubmit={submitHandler}
            validationSchema={turkUserSchema}
            // @ts-ignore
            initialValues={{ agencies: [] }}
          >
            {({ errors }) => (
              <Form>
                <div className="admin-agent-edit-form">
                  <Table>
                    <tbody>
                      <tr>
                        <td rowSpan={2}>
                          <Info errors={errors} />
                        </td>
                        <td>
                          <Profession
                            agencies={agencies}
                            errors={errors}
                            agenciesLoading={agenciesLoading}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Registration />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="text-center">
                  <Button
                    disabled={submitLoading || isLoading || agenciesLoading}
                    type="submit"
                    text="Save"
                    handleClick={scrollToErrorIfExist}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
};

export default AdminTurkUserAdd;
