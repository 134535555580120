import React from 'react';
import BatteryIcon from 'components/atoms/icons/battery';

import type { DeviceLog } from 'infrastructure/classes/device-log';

interface IBatteryLevelProps {
  data: DeviceLog;
}

const BatteryLevel: React.FC<IBatteryLevelProps> = (props) => {
  const { data } = props;
  return (
    <div>
      <i>
        <BatteryIcon level={data.batteryLevel} />
      </i>{' '}
      {data.batteryLevel}
    </div>
  );
};

export default BatteryLevel;
