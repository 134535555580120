export const prepareArrToCopy = (params: (string | undefined | null)[]) => {
  let str = '';

  params.forEach((el) => {
    if (el) {
      str += `${el} `;
    }
  });

  return str.trim();
};
