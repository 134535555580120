// FIXME: Can be deleted. Unused component

import { Popover } from 'antd';
import { HashLink } from 'react-router-hash-link';
import UserProfileIcon from 'components/atoms/icons/userProfileIcon';
import ControlPanelIcon from 'components/atoms/icons/controlPanelIcon';
import BannerProfileIcon from 'components/atoms/icons/bannerProfileIcon';
import GeneralCommentsIcon from 'components/atoms/icons/generalCommentsIcon';

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
  const yOffset = -110; // header + mini banner height
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const AdminMiniNav = () => {
  return (
    <div className="mini-nav mini-nav--new">
      <Popover
        placement="right"
        trigger="hover"
        title="Patient Banner"
        arrow={false}
        getPopupContainer={(trigger) => trigger}
      >
        <HashLink
          smooth
          to="#patient-banner"
          scroll={(el) => scrollWithOffset(el)}
        >
          <div className="mini-nav__item" data-cy="nav-banner">
            <UserProfileIcon />
          </div>
        </HashLink>
      </Popover>
      <div className="mini-nav__divider" />
      <Popover
        placement="right"
        trigger="hover"
        title="Control Panel"
        arrow={false}
        getPopupContainer={(trigger) => trigger}
      >
        <HashLink
          smooth
          to="#control-panel"
          scroll={(el) => scrollWithOffset(el)}
        >
          <div className="mini-nav__item" data-cy="nav-cpanel">
            <ControlPanelIcon />
          </div>
        </HashLink>
      </Popover>
      <div className="mini-nav__divider" />
      <Popover
        placement="right"
        trigger="hover"
        title="Patient Info"
        arrow={false}
        getPopupContainer={(trigger) => trigger}
      >
        <HashLink
          smooth
          to="#user-profile"
          scroll={(el) => scrollWithOffset(el)}
        >
          <div className="mini-nav__item" data-cy="nav-patient-profile">
            <BannerProfileIcon />
          </div>
        </HashLink>
      </Popover>
      <div className="mini-nav__divider" />
      <Popover
        placement="right"
        trigger="hover"
        title="General Comments"
        arrow={false}
        getPopupContainer={(trigger) => trigger}
      >
        <HashLink
          smooth
          to="#general-comments"
          scroll={(el) => scrollWithOffset(el)}
        >
          <div className="mini-nav__item" data-cy="nav-patient-comments">
            <GeneralCommentsIcon />
          </div>
        </HashLink>
      </Popover>
    </div>
  );
};
export default AdminMiniNav;
