import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import ClinicTime from 'components/templates/rpm/practice/clinicalMetrics/clinicTime';
import CriticalReportTable from 'components/templates/rpm/practice/clinicalMetrics/criticalReportTable';
import Stats from 'components/templates/rpm/practice/clinicalMetrics/stats';

const ClinicalMetrics = () => {
  const {
    clinicalMetrics: { getClinicalMetricsReport },
  } = useDispatch();
  const { data: clinicalMetrics, filters } = useSelector(
    (state) => state.clinicalMetrics,
  );

  useEffect(() => {
    getClinicalMetricsReport();
  }, []);

  return (
    <Container>
      <ClinicTime
        totalTime={clinicalMetrics?.totalTimeReport?.totalTimeByMonth || 0}
        avgTime={clinicalMetrics?.totalTimeReport?.onePatientByMonth || 0}
      />
      <Stats
        stats={{
          activePatients: clinicalMetrics.activePatientReport,
          adherence: clinicalMetrics.adherenceReport,
          lastWeekRisk: clinicalMetrics.lastWeekAvgRisk,
          criticalReport: clinicalMetrics.criticalReport,
          riskReport: clinicalMetrics.riskReport,
          maleReport: clinicalMetrics.maleReport,
          femaleReport: clinicalMetrics.femaleReport,
        }}
      />
      <CriticalReportTable filters={filters} />
    </Container>
  );
};

export default ClinicalMetrics;
