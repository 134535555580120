import common from 'constants/common';
import Switch from 'react-switch';
import { type FC } from 'react';
import { useAssignee } from 'infrastructure/hooks/rpm/useAssignee';
import { parseDateSafe } from 'utils/timeHelper';
import { Tooltip } from 'antd';

interface Props {
  togglePatientReserved: (patientGuid: string) => Promise<void>;
  setToggleAssignLoading: ({
    patientGuid,
    state,
  }: {
    patientGuid: string;
    state: boolean;
  }) => void;
  loginGuid: string;
  activity: {
    reserved: null | {
      expireAt: string;
      firstName: string;
      lastName: string;
      loginGuid: string;
      professionType: string | null;
      title: string | null;
    };
    patient: {
      guid: string;
    };
  };
  toggleAssignLoading: boolean;
}

const Assignee: FC<Props> = ({
  togglePatientReserved,
  setToggleAssignLoading,
  loginGuid,
  activity,
  toggleAssignLoading,
}) => {
  const patientGuid = activity.patient.guid;
  const { reserved } = activity;

  const expireAtTimestamp = parseDateSafe(reserved?.expireAt)?.getTime();
  const assignee =
    expireAtTimestamp && reserved?.loginGuid
      ? {
          expireAtTimestamp,
          loginGuid: reserved.loginGuid,
        }
      : null;

  const { assigneeType, timer } = useAssignee({ loginGuid, assignee });
  const assigneeText = getAssigneeText(reserved, loginGuid);
  const assigned = assigneeType !== null;

  return (
    <div className="activity-row-assign">
      {(!reserved || assigneeType !== 'anotherUser') && (
        <>
          <label className="switch-label">
            <Switch
              className="evelyn-switch"
              onChange={async () => {
                setToggleAssignLoading({
                  patientGuid,
                  state: true,
                });
                await togglePatientReserved(patientGuid);
                setToggleAssignLoading({
                  patientGuid,
                  state: false,
                });
              }}
              checked={assigned}
              onHandleColor={common.commonColors.green}
              offHandleColor={common.commonColors.gray}
              onColor={common.commonColors.white}
              offColor={common.commonColors.white}
              handleDiameter={14}
              height={12}
              width={22}
              activeBoxShadow="0"
              disabled={toggleAssignLoading}
            />
            <span>{reserved ? 'Unassign' : 'Assign'}</span>
          </label>
        </>
      )}
      {assigned && assigneeText && (
        <Tooltip title={timer}>
          <span>{assigneeText}</span>
        </Tooltip>
      )}
    </div>
  );
};

const getAssigneeText = (
  reserved: Props['activity']['reserved'],
  loginGuid: string,
) => {
  if (!reserved) {
    return;
  }
  if (reserved.loginGuid === loginGuid) {
    return 'Assigned to me';
  }
  if (reserved?.loginGuid !== loginGuid) {
    return `Assigned: ${reserved.title} ${reserved.firstName} ${reserved.lastName} - ${reserved.professionType}`;
  }
};

export default Assignee;
