import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { Row, Col } from 'reactstrap';
import forms from 'constants/forms';
import AutoCompleteDoctor from 'components/molecules/autocompleteFilter/doctor';
import AgencyDoctorAutocomplete from 'components/molecules/autocompleteFilter/agencyDoctor';
import useUser from 'utils/useUser';
import { isPractice } from 'utils/userTypeHelper';
import BaseSelect from 'components/atoms/baseSelect';

const DEFAULT_PATIENT_FILTER = forms.patientFilterOptions[0];

const Toolbar = ({ setFilters, filters, timeRanges }) => {
  const { userType } = useUser();
  const onboardingStatuses = forms.patientStatuses;

  const statusOptions = useMemo(() => [
    { value: 'only_onboarding_flow', label: 'All' },
    ...onboardingStatuses,
  ]);

  const statusValue = (statusName) => {
    const value = statusOptions.find((element) => element.value === statusName);
    return value;
  };

  return (
    <Row>
      <Col xl="2" lg="3" md="4" sm="6" xs="12">
        <strong>Time Range</strong>
        <BaseSelect
          options={timeRanges}
          defaultValue={timeRanges[0]}
          classNamePrefix="select-type"
          onChange={({ value: [startTime, endTime] }) =>
            setFilters({ startTime, endTime })
          }
        />
      </Col>
      <Col xl="2" lg="3" md="4" sm="6" xs="12">
        <strong>Doctor</strong>
        <AutoCompleteDoctor
          filter={DEFAULT_PATIENT_FILTER}
          status="active"
          handleClick={({ value }) => {
            setFilters({ doctorGuid: value });
          }}
        />
      </Col>
      {!isPractice(userType) && (
        <>
          <Col xl="2" lg="3" md="4" sm="6" xs="12">
            <strong>Client Success Rep</strong>
            <AgencyDoctorAutocomplete
              filter={DEFAULT_PATIENT_FILTER}
              roleName="clientSuccess"
              handleClick={({ value }) => {
                setFilters({
                  clientSuccessGuid: value,
                });
              }}
            />
          </Col>
          <Col xl="2" lg="3" md="4" sm="6" xs="12">
            <strong>Sales Rep</strong>
            <AgencyDoctorAutocomplete
              filter={DEFAULT_PATIENT_FILTER}
              roleName="salesRepresentative"
              handleClick={({ value }) => {
                setFilters({ salesRepresentativeGuid: value });
              }}
            />
          </Col>
        </>
      )}
      <Col xl="2" lg="3" md="4" sm="6" xs="12">
        <strong>Patient Status</strong>
        <BaseSelect
          options={statusOptions}
          defaultValue={statusOptions[0]}
          classNamePrefix="select-type"
          value={statusValue(filters.status)}
          onChange={({ value }) => setFilters({ patientStatus: value })}
        />
      </Col>
    </Row>
  );
};

Toolbar.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
  timeRanges: PropTypes.array,
};

export default Toolbar;
