import TurkConfigurationEdit from 'components/organisms/turkConfiguration/edit';
import TurkConfigurationView from 'components/organisms/turkConfiguration/view';
import withLoader from 'components/organisms/withLoader';
import { useState } from 'react';
import useTurkDashboardSettings from 'utils/hooks/turkDashboard/useSettings';

import type { FC } from 'react';

const TurkConfiguration: FC = () => {
  const { turkConfiguration, isLoading, removeFromCache } =
    useTurkDashboardSettings();
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  return (
    <>
      <div className="turk-configuration--body">
        {withLoader(
          isLoading,
          turkConfiguration && (
            <>
              <h3 className="turk-configuration--header">Turk Configuration</h3>
              {mode === 'view' && (
                <TurkConfigurationView
                  turkConfiguration={turkConfiguration}
                  changeModeToEdit={() => setMode('edit')}
                />
              )}
              {mode === 'edit' && (
                <TurkConfigurationEdit
                  turkConfiguration={turkConfiguration}
                  changeModeToView={() => setMode('view')}
                  onSubmit={() => {
                    setMode('view');
                    removeFromCache();
                  }}
                />
              )}
            </>
          ),
        )}
      </div>
    </>
  );
};

export default TurkConfiguration;
