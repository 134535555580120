import { useMutation } from '@tanstack/react-query';
import { showResult } from 'infrastructure/hooks/utils';

import { hospitalPreventionFn } from './api';

interface Props {
  onSuccess: () => void;
}

export const useHospitalPrevention = ({ onSuccess }: Props) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: hospitalPreventionFn,
    onSuccess() {
      showResult();
      onSuccess();
    },
  });

  return {
    sendHospitalPrevention: mutate,
    sendHospitalPreventionLoading: isLoading,
  };
};
