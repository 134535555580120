import { AVERAGE_UNITS } from 'infrastructure/consts/average-units';
import AverageRiskIcon from 'components/atoms/icons/averageRiskIcon';
import AverageCriticalIcon from 'components/atoms/icons/averageCriticalIcon';

import s from './style.module.scss';
import { getAverageStatus } from '../../helpers/get-average-status';

import type { IAverage, IPatientThresholds } from 'infrastructure/interfaces';
import type { FC } from 'react';

interface AverageItemProps {
  average: IAverage;
  thresholds?: void | IPatientThresholds;
}

const AverageItem: FC<AverageItemProps> = ({ average, thresholds }) => {
  const { name, value } = average;

  const { status, text } = getAverageStatus(average, thresholds) ?? {};

  return (
    <div className={s.wrapper}>
      <p className={s.title} data-cy={`average-${average.name}`}>
        {name}{' '}
        {status === 'risk' && (
          <span data-cy={`average-${average.name}-risk-icon`}>
            <AverageRiskIcon />
          </span>
        )}
        {status === 'critical' && (
          <span data-cy={`average-${average.name}-critical-icon`}>
            <AverageCriticalIcon />
          </span>
        )}
      </p>
      <div className={s.divider} />
      <p className={s.value} data-cy={`average-${average.name}-value`}>
        {parseInt(value) ? value : ''}
      </p>
      {status && (
        <p
          className={s['max-value']}
          data-cy={`average-${average.name}-max-value`}
        >
          {text}
        </p>
      )}
      <p
        className={s.measurement}
        data-cy={`average-${average.name}-measurement`}
      >
        {AVERAGE_UNITS[name]}
      </p>
    </div>
  );
};
export default AverageItem;
