import React from 'react';
import PropTypes from 'prop-types';

const colors = {
  active: '#2C2543',
  notActive: '#BBB',
};

const PaginationOrderIconDown = ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="8"
    viewBox="0 0 7 5"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.601562 0.775391L3.64546 4.77263L6.68935 0.775391"
      fill={active ? colors.active : colors.notActive}
    />
  </svg>
);

PaginationOrderIconDown.propTypes = {
  active: PropTypes.bool,
};

export default PaginationOrderIconDown;
