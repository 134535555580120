import { useFormik } from 'formik';
import { useQuestions, useSendQuestions } from 'infrastructure/hooks/ccm';
import { useState, useMemo } from 'react';
import {
  FALL_RISK_QUESTION_NAME,
  SMOCKING_QUESTION_NAME,
} from 'constants/ccmQuestions';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { isCcmActionAllowedStatus } from 'utils/userTypeHelper';

import type { ISendAnswers } from 'infrastructure/interfaces';

interface Props {
  patientGuid: string;
}

type TInitialValues = Record<string, string | undefined>;

export const useQuestionnaire = ({ patientGuid }: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { questions, isLoading } = useQuestions({ patientGuid });
  const { sendAnswers, sendLoading } = useSendQuestions();
  const { patientInfo, loading } = usePatientInfo({ patientGuid });
  const disableButton = !isCcmActionAllowedStatus(patientInfo?.ccmStatus);

  const increaseStep = () => setCurrentStep((prev) => prev + 1);
  const decreaseStep = () => setCurrentStep((prev) => prev - 1);

  const currentQuestionGroup = useMemo(
    () => questions?.groups[currentStep],
    [currentStep, questions?.groups],
  );

  const initialValues = useMemo(
    () =>
      currentQuestionGroup?.questions.reduce<TInitialValues>((acc, q) => {
        acc[q.guid] = q.patientAnswer?.Answer?.guid;

        return acc;
      }, {}) ?? {},
    [currentStep, currentQuestionGroup],
  );

  const onSubmit = async (values: TInitialValues) => {
    const answers = Object.entries(values).reduce<ISendAnswers[]>(
      (acc, [questionGuid, answerGuid]) => {
        if (answerGuid) acc.push({ answerGuid, questionGuid });

        return acc;
      },
      [],
    );
    sendAnswers({ patientGuid, answers });
  };

  const formik = useFormik<TInitialValues>({
    initialValues,
    enableReinitialize: true,
    onSubmit,
  });

  const isSmokingQuestion = useMemo(
    () => currentQuestionGroup?.name === SMOCKING_QUESTION_NAME,
    [currentStep, currentQuestionGroup],
  );

  const isFallRiskQuestion = useMemo(
    () => currentQuestionGroup?.name.includes(FALL_RISK_QUESTION_NAME) ?? false,
    [currentStep, currentQuestionGroup],
  );

  const disabledIncrease =
    currentStep ===
    (questions?.groups.length ? questions?.groups.length - 1 : 0);

  return {
    isLoading,
    isSmokingQuestion,
    disabledIncrease,
    sendLoading,
    patientInfoLoading: loading,
    formik,
    groups: questions?.groups,
    currentQuestionGroup,
    currentStep,
    disableButton,
    isFallRiskQuestion,
    setCurrentStep,
    decreaseStep,
    increaseStep,
  };
};
