import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import s from './styles.module.scss';

import type { Thresholds } from 'infrastructure/classes/thresholds';

interface IFieldProps {
  name: string;
  value: number;
  dataCy?: string;
}

const Field: React.FC<IFieldProps> = ({ name, value, dataCy }) => {
  return (
    <div className={s.field} data-cy={dataCy}>
      <span>{name}</span>
      <span>{value}</span>
    </div>
  );
};

interface ITitleProps {
  label: string;
  unit: string;
  dataCy?: string;
}

const Title: React.FC<ITitleProps> = ({ label, unit, dataCy }) => {
  return (
    <div className={s.title} data-cy={dataCy}>
      <b>{label}</b>
      <span>{unit}</span>
    </div>
  );
};

interface IThresholdsViewFormProps {
  data: Thresholds;
  hasBloodGlucoseType: boolean;
  hasWeightType: boolean;
  hasSPO2Type: boolean;
  hasBloodPressureType: boolean;
  hasPeakFlowType: boolean;
  hasSleepMatType: boolean;
  showBaselineWeight?: boolean;
}

const ThresholdsViewForm: React.FC<IThresholdsViewFormProps> = (props) => {
  const {
    data,
    hasBloodGlucoseType,
    hasWeightType,
    hasSPO2Type,
    hasBloodPressureType,
    hasPeakFlowType,
    hasSleepMatType,
    showBaselineWeight = true,
  } = props;

  const { t } = useTranslation();

  const borderlessBlockClassNames = classNames(s.block, s.borderless);
  const singleGroupBodyClassNames = classNames(s.body, s.single);

  const minCriticalLabel = t('labels.minCritical');
  const minAtRiskLabel = t('labels.minAtRisk');
  const maxCriticalLabel = t('labels.maxCritical');
  const maxAtRiskLabel = t('labels.maxAtRisk');
  const baselineLabel = t('labels.baseline');

  return (
    <div className={s.wrapper} data-cy="thresholds-table">
      {hasBloodPressureType && (
        <div className={s.block}>
          <Title
            label={t('labels.systolic')}
            unit={t('units.mmHg')}
            dataCy="systolic-info"
          />
          <div className={s.body}>
            <div className={s.group}>
              <Field
                name={minCriticalLabel}
                value={data.minCriticalSystolic}
                dataCy="systolic-minCritical"
              />
              <Field
                name={minAtRiskLabel}
                value={data.minRiskSystolic}
                dataCy="systolic-minAtRisk"
              />
            </div>
            <div className={s.group}>
              <Field
                name={maxCriticalLabel}
                value={data.maxCriticalSystolic}
                dataCy="systolic-maxCritical"
              />
              <Field
                name={maxAtRiskLabel}
                value={data.maxRiskSystolic}
                dataCy="systolic-maxAtRisk"
              />
            </div>
          </div>
        </div>
      )}
      {hasBloodPressureType && (
        <div className={s.block}>
          <Title
            label={t('labels.diastolic')}
            unit={t('units.mmHg')}
            dataCy="diastolic-info"
          />
          <div className={s.body}>
            <div className={s.group}>
              <Field
                name={minCriticalLabel}
                value={data.minCriticalDiastolic}
                dataCy="diastolic-minCritical"
              />
              <Field
                name={minAtRiskLabel}
                value={data.minRiskDiastolic}
                dataCy="diastolic-minAtRisk"
              />
            </div>
            <div className={s.group}>
              <Field
                name={maxCriticalLabel}
                value={data.maxCriticalDiastolic}
                dataCy="diastolic-maxCritical"
              />
              <Field
                name={maxAtRiskLabel}
                value={data.maxRiskDiastolic}
                dataCy="diastolic-maxAtRisk"
              />
            </div>
          </div>
        </div>
      )}
      {hasWeightType && (
        <div className={s.block}>
          <Title
            label={t('labels.weight')}
            unit={t('units.lbs')}
            dataCy="weight-info"
          />
          <div className={s.body}>
            <div className={s.group}>
              <Field
                name={maxCriticalLabel}
                value={data.maxCriticalWeight}
                dataCy="weight-maxCritical"
              />
              {showBaselineWeight && (
                <Field
                  name={baselineLabel}
                  value={data.baseLineWeight}
                  dataCy="weight-baseline"
                />
              )}
            </div>
            <div className={s.group}>
              <Field
                name={maxAtRiskLabel}
                value={data.maxRiskWeight}
                dataCy="weight-maxAtRisk"
              />
              <div />
            </div>
          </div>
        </div>
      )}
      {hasBloodGlucoseType && (
        <div className={s.block}>
          <Title
            label={t('labels.glucose')}
            unit={t('units.mgdL')}
            dataCy="glucose-info"
          />
          <div className={s.body}>
            <div className={s.group}>
              <Field
                name={minCriticalLabel}
                value={data.minCriticalGlucose}
                dataCy="glucose-minCritical"
              />
              <Field
                name={minAtRiskLabel}
                value={data.minRiskGlucose}
                dataCy="glucose-minAtRisk"
              />
            </div>
            <div className={s.group}>
              <Field
                name={maxCriticalLabel}
                value={data.maxCriticalGlucose}
                dataCy="glucose-maxCritical"
              />
              <Field
                name={maxAtRiskLabel}
                value={data.maxRiskGlucose}
                dataCy="glucose-maxAtRisk"
              />
            </div>
          </div>
        </div>
      )}
      {(hasBloodPressureType || hasSPO2Type) && (
        <div className={s.block}>
          <Title
            label={t('labels.pulse')}
            unit={t('units.BPM')}
            dataCy="pulse-info"
          />
          <div className={s.body}>
            <div className={s.group}>
              <Field
                name={minCriticalLabel}
                value={data.minCriticalPulse}
                dataCy="pulse-minCritical"
              />
              <Field
                name={minAtRiskLabel}
                value={data.minRiskPulse}
                dataCy="pulse-minAtRisk"
              />
            </div>
            <div className={s.group}>
              <Field
                name={maxCriticalLabel}
                value={data.maxCriticalPulse}
                dataCy="pulse-maxCritical"
              />
              <Field
                name={maxAtRiskLabel}
                value={data.maxRiskPulse}
                dataCy="pulse-maxAtRisk"
              />
            </div>
          </div>
        </div>
      )}
      {hasSleepMatType && (
        <div className={s.block}>
          <Title
            label={t('labels.restingPulse')}
            unit={t('units.BPM')}
            dataCy="restingPulse-info"
          />
          <div className={s.body}>
            <div className={s.group}>
              <Field
                name={minCriticalLabel}
                value={data.minCriticalSleepPulse}
                dataCy="restingPulse-minCritical"
              />
              <Field
                name={minAtRiskLabel}
                value={data.minRiskSleepPulse}
                dataCy="restingPulse-minAtRisk"
              />
            </div>
            <div className={s.group}>
              <Field
                name={maxCriticalLabel}
                value={data.maxCriticalSleepPulse}
                dataCy="restingPulse-maxCritical"
              />
              <Field
                name={maxAtRiskLabel}
                value={data.maxRiskSleepPulse}
                dataCy="restingPulse-maxAtRisk"
              />
            </div>
          </div>
        </div>
      )}
      {hasPeakFlowType && (
        <div className={borderlessBlockClassNames}>
          <Title
            label={t('labels.pef')}
            unit={t('units.LMin')}
            dataCy="pef-info"
          />
          <div className={singleGroupBodyClassNames}>
            <div className={s.group}>
              <Field
                name={minCriticalLabel}
                value={data.minCriticalPef}
                dataCy="pef-minCritical"
              />
              <Field
                name={minAtRiskLabel}
                value={data.minRiskPef}
                dataCy="pef-minAtRisk"
              />
            </div>
          </div>
        </div>
      )}
      {hasPeakFlowType && (
        <div className={borderlessBlockClassNames}>
          <Title
            label={t('labels.fev')}
            unit={t('units.L')}
            dataCy="fev-info"
          />
          <div className={singleGroupBodyClassNames}>
            <div className={s.group}>
              <Field
                name={minCriticalLabel}
                value={data.minCriticalFev1}
                dataCy="fev-minCritical"
              />
            </div>
          </div>
        </div>
      )}
      {hasSPO2Type && (
        <div className={borderlessBlockClassNames}>
          <Title label={t('labels.spo2')} unit="%" dataCy="spo2-info" />
          <div className={singleGroupBodyClassNames}>
            <div className={s.group}>
              <Field
                name={minCriticalLabel}
                value={data.criticalBloodoxygen}
                dataCy="spo2-minCritical"
              />
              <Field
                name={minAtRiskLabel}
                value={data.riskBloodoxygen}
                dataCy="spo2-minAtRisk"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ThresholdsViewForm;
