// FIXME: Can be deleted. Unused component

import { useFormik } from 'formik';
import ChatIcon from 'components/atoms/icons/chatIcon';
import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { useInterval } from 'react-use';
import { useTranslation } from 'react-i18next';
import useUser from 'utils/useUser';
import { isAdmin, isPractice } from 'utils/userTypeHelper';
import BaseLoader from 'components/atoms/base-loader';

import GenerateCommentButton from './generateCommentButton';
import SubmitButton from './submitButton';
import CommentBookmarkModal from '../comment-bookmark/modal';

interface Props {
  handleSubmit: (values: {
    comment: string;
    generatedByModel: string | undefined;
    originalGeneratedText: string | undefined;
  }) => Promise<void>;
  valueChanged: (value: string) => void;
  activity: any;
  dates: Array<string>;
  submitLoading: boolean;
  setSubmitLoading: (loading: boolean) => void;
  openTemplateModal?: (params: any) => void;
  disabledTemplateModalButton?: boolean;
  submitCommentLockByAi?: boolean;
  setSubmitCommentLockByAi: (lock: boolean) => void;
  hideAiButton?: boolean;
  isCommentGenerationLimitReached: boolean;
  onCommentGenerationStatusChange: (action: 'start' | 'end') => void;
}

export type ActivityCommentFormRef = ReturnType<
  typeof useFormik<{
    comment: string;
    generatedByModel: undefined | string;
    originalGeneratedText: undefined | string;
  }>
>;

const ActivityCommentForm = forwardRef<ActivityCommentFormRef, Props>(
  (props, ref) => {
    const {
      handleSubmit,
      valueChanged,
      activity,
      dates,
      submitLoading,
      setSubmitLoading,
      openTemplateModal,
      disabledTemplateModalButton,
      submitCommentLockByAi,
      setSubmitCommentLockByAi,
      hideAiButton,
      isCommentGenerationLimitReached,
      onCommentGenerationStatusChange,
    } = props;

    const { t } = useTranslation();

    const generatedComment = useRef('');
    const [generateCommentLoading, setGenerateCommentLoading] = useState(false);
    const [modalValues, setModalValues] = useState({});
    const { userType } = useUser();
    const [dots, setDots] = useState(`...`);

    const formik = useFormik({
      initialValues: {
        comment: '',
        generatedByModel: undefined as undefined | string,
        originalGeneratedText: undefined as undefined | string,
      },
      onSubmit: (values) => {
        setSubmitLoading(true);
        handleSubmit(values)
          .then(() => {
            setModalValues({});
            formik.resetForm();
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      },
    });

    useInterval(
      () => {
        if (generateCommentLoading) {
          setDots((prev) => {
            if (prev === `...`) return `.`;
            if (prev === `.`) return `..`;
            if (prev === `..`) return `...`;
            return `...`;
          });
        }
      },
      generateCommentLoading ? 300 : null,
    );

    useImperativeHandle(ref, () => ({
      ...formik,
    }));

    const isLoading = submitLoading || generateCommentLoading;

    return (
      <>
        <form onSubmit={formik.handleSubmit} className="activity-comment-form">
          <div className="activity-comment-form--input-wrapper">
            <textarea
              value={formik.values.comment}
              onChange={(e) => {
                formik.setFieldValue('comment', e.target.value);
                valueChanged(e.target.value);
                if (generatedComment.current) {
                  if (e.target.value !== generatedComment.current) {
                    setSubmitCommentLockByAi(false);
                  } else {
                    setSubmitCommentLockByAi(true);
                  }
                }
              }}
              name="comment"
              className={classNames('activity-comment-form--input', {
                'activity-comment-form--input--expanded':
                  formik.values.comment?.length > 0,
              })}
              placeholder={
                generateCommentLoading
                  ? `${t('controls.generatingComment')} ${dots}`
                  : `${t('controls.typeYourComment')} ...`
              }
              disabled={submitLoading || generateCommentLoading}
            />
            {!hideAiButton && (
              <GenerateCommentButton
                isCommentGenerationLimitReached={
                  isCommentGenerationLimitReached
                }
                onCommentGenerationStatusChange={
                  onCommentGenerationStatusChange
                }
                setLoading={setGenerateCommentLoading}
                loading={generateCommentLoading}
                activity={activity}
                dates={dates}
                disabled={submitLoading}
                setText={(text, model) => {
                  formik.setValues({
                    comment: text,
                    generatedByModel: model,
                    originalGeneratedText: text,
                  });
                  if (valueChanged) valueChanged(text);
                  generatedComment.current = text;
                  setSubmitCommentLockByAi(true);
                }}
              />
            )}
          </div>
          <div className="activity-comment-form--buttons-wrapper">
            {(isPractice(userType) || isAdmin(userType)) && (
              <CommentBookmarkModal
                style={{ margin: '0 0 5px 0' }}
                onApply={(val) => {
                  formik.setFieldValue('comment', val.message);
                }}
              />
            )}
            {openTemplateModal && (
              <button
                type="button"
                disabled={disabledTemplateModalButton}
                title={
                  disabledTemplateModalButton
                    ? t('controls.onlyUseButtonOncePerComment')
                    : t('controls.openTemplateModal')
                }
                onClick={() =>
                  openTemplateModal({
                    activity,
                    values: modalValues,
                    setValues: setModalValues,
                    handleSubmit: (value: string) => {
                      if (valueChanged) valueChanged(value);
                      formik.setFieldValue('comment', value);
                    },
                  })
                }
              >
                <ChatIcon />
              </button>
            )}
            <SubmitButton
              disabled={formik.values.comment === ''}
              submitLoading={submitLoading}
              submitCommentLockByAi={submitCommentLockByAi}
            />
          </div>
        </form>
        {isLoading && (
          <div className="loader-wrapper loader-overlay">
            <BaseLoader width={24} height={24} loading />
          </div>
        )}
      </>
    );
  },
);

export default ActivityCommentForm;
