const Footer = () => (
  <footer>
    <p>
      200 South 10th Street McAllen, Suite 103, TX, 78501 Phone Number: (888)
      407-4108 Fax: (956) 202-0260
    </p>
    <p>
      <a href="https://accuhealth.tech">www.accuhealth.tech</a> Monday-Friday
      8:00 A.M. - 5:00 P.M. On-call 24 hours
    </p>
  </footer>
);

export default Footer;
