import classNames from 'classnames';
import { fullNamePresenter } from 'utils';
import useUser from 'utils/useUser';

import DashboardReadingColumn from './components/dashboard-reading-col';
import PatientReadingColumn from './components/patient-reading-col';
import s from './styles.module.scss';

import type { ReadingType } from 'infrastructure/enums';
import type { IActivityData } from 'infrastructure/interfaces/i-rpm-patient-activity';
import type { QueryKey } from '@tanstack/react-query';
import type { FC, MutableRefObject } from 'react';

interface ReadingCellProps {
  record: IActivityData;
  idx: number;
  view: 'patient' | 'dashboard';
  getActivityQueryKey: QueryKey;
  hideCommentsColumn: boolean;
  slaReadings: string | (string | null)[] | null;
  readingType: ReadingType;
  rowRefs: MutableRefObject<Map<number, HTMLDivElement | null>>;
}

const ReadingCell: FC<ReadingCellProps> = ({
  idx,
  record,
  getActivityQueryKey,
  readingType,
  view,
  hideCommentsColumn,
  slaReadings,
  rowRefs,
}) => {
  const showCountdown =
    (view === 'dashboard' &&
      slaReadings === 'true' &&
      record.slaDeadline &&
      record.serviceDeliveryThresholds) ??
    false;
  const { isPatient } = useUser();

  return (
    <div
      className={classNames(s['reading-wrapper'], {
        [s['without-comments']]: hideCommentsColumn,
        [s['is-patient']]: isPatient,
      })}
      ref={(el) => rowRefs.current.set(idx, el)}
    >
      {view === 'patient' ? (
        <PatientReadingColumn
          globalStatus={record.globalStatus}
          readingType={readingType}
          statuses={record.statuses}
          thresholds={record.patient.threshold}
          values={record.value}
          key={`reading-col-${idx}`}
        />
      ) : (
        <DashboardReadingColumn
          globalStatus={record.globalStatus}
          readingType={readingType}
          statuses={record.statuses}
          thresholds={record.patient.threshold}
          values={record.value}
          key={`reading-col-${idx}`}
          patient={record.patient}
          provider={fullNamePresenter(record.doctor)}
          reserved={record.reserved}
          getActivityQueryKey={getActivityQueryKey}
          deviceId={record.deviceId}
          readingId={record.id}
          hideCommentsColumn={hideCommentsColumn}
          showCountdown={Boolean(showCountdown)}
        />
      )}
    </div>
  );
};
export default ReadingCell;
