import Row from './row';

import type { FC } from 'react';
import type { AgencyDoctor } from 'utils/hooks/adminPanel/useAgencyDoctors';

interface Props {
  agencyDoctors: AgencyDoctor[] | undefined;
}

const TableBody: FC<Props> = ({ agencyDoctors }) => {
  return (
    <tbody className="adminPanel-agencyDoctors">
      {agencyDoctors?.map((agencyDoctor, index) => (
        <Row
          key={`${agencyDoctor.guid}-${index}`}
          agencyDoctor={agencyDoctor}
        />
      ))}
    </tbody>
  );
};

export default TableBody;
