export const removeEmptyValuesFromObject = <T extends Record<any, any>>(
  obj: T,
): Partial<T> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, v]) => {
      if (Array.isArray(v)) {
        return v.length > 0;
      }
      return v !== undefined && v !== null && v !== '';
    }),
  ) as Partial<T>;
};

export const filterDefaultValues = ({
  defaultValues,
  values,
}: {
  defaultValues: Record<any, any>;
  values: Record<any, any>;
}) => {
  const result = { ...values };
  for (const key in defaultValues) {
    if (defaultValues[key] === result[key]) {
      delete result[key];
    }
  }
  return result;
};
