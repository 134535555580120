import React, { useEffect, useState } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import BaseFormItemGroup from 'components/atoms/base-form/item-group';
import CopyableText from 'components/atoms/buttons/copyable-text';
import RpmStatusSelectBox from 'components/molecules/rpm-status-select-box';
import CcmStatusSelectBox from 'components/molecules/ccm-status-select-box';
import BooleanSelectBox from 'components/molecules/boolean-select-box';
import { DateFormatter } from 'infrastructure/functions';
import { ModuleType, DateFormats } from 'infrastructure/enums';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import ClaimStatusSelectBox from 'components/molecules/claim-status-select-box';
import { useEdit } from 'infrastructure/providers/edit-provider';
import classNames from 'classnames';
import useUser from 'utils/useUser';
import { isMobile } from 'utils/deviceHelper';

import s from './styles.module.scss';
import { adminPanelAccountInfoValidationSchema } from './validation-schema';

import type { Patient } from 'infrastructure/classes/patient/patient';

const formKey = 'AdminPanelAccountInfoForm';

interface IAdminPanelAccountInfoFormProps {
  data: Patient;
  module: ModuleType;
  loading?: boolean;
}

const AdminPanelAccountInfoForm: React.FC<IAdminPanelAccountInfoFormProps> = (
  props,
) => {
  const { data, module, loading: loadingProp = false } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const isAdmin = module === ModuleType.ADMIN;

  const { canEdit, setEdit } = useEdit();
  const { t } = useTranslation();
  const { deviceType, iamPatientAcceptance } = useUser();

  const { loading, updatePatientInfo } = usePatientInfo({
    patientGuid: data.guid,
  });

  const formik = useFormik({
    initialValues: data,
    onSubmit: async (values) => {
      await updatePatientInfo(values).then(() => {
        formik.resetForm({ values });
      });
      setIsEdit(false);
      setEdit(false);
    },
    validationSchema: adminPanelAccountInfoValidationSchema(),
    validateOnChange: false,
  });

  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };
  const onSave = () => {
    formik.submitForm();
  };
  const onCancel = () => {
    formik.resetForm();
    setIsEdit(false);
    setEdit(false);
  };

  useEffect(() => {
    formik.resetForm({ values: data });
  }, [data]);

  return (
    <PatientBlock
      title={t('labels.accountInfo')}
      canEdit={isAdmin}
      isEdit={isEdit}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      loading={loading || loadingProp}
      disabled={!canEdit(formKey)}
    >
      <BaseForm formik={formik} plaintext={!isEdit} readonly={!isEdit}>
        <BaseFormItemGroup
          className={classNames(s['border-bottom'], s['inputs-wrapper'])}
          label={t('labels.registrationDetails')}
        >
          <BaseFormItem
            name="patientID"
            label={t('labels.patientID')}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <CopyableText
              className={s.input}
              value={data.guid}
              label={data.guid}
            />
          </BaseFormItem>
          <BaseFormItem
            name="accountCreationDate"
            label={t('labels.accountCreationDate')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <span className={s.input}>
              {DateFormatter({
                date: data.createdAt,
                format: DateFormats['MMM dd, yyyy'],
              })}
            </span>
          </BaseFormItem>
        </BaseFormItemGroup>
        <BaseFormItemGroup
          label={t('labels.statuses')}
          className={s['inputs-wrapper']}
        >
          <BaseFormItem
            name="status"
            label={t('labels.rpmStatus')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <RpmStatusSelectBox />
          </BaseFormItem>
          <BaseFormItem
            name="ccmStatus"
            label={t('labels.ccmStatus')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <CcmStatusSelectBox />
          </BaseFormItem>
          <BaseFormItem
            name="pcmStatus"
            label={t('labels.pcmStatus')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BooleanSelectBox disabled={iamPatientAcceptance} />
          </BaseFormItem>
          <BaseFormItem
            name="claimStatus"
            label={t('labels.rpmClaimStatus')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <ClaimStatusSelectBox disabled={iamPatientAcceptance} />
          </BaseFormItem>
          <BaseFormItem
            name="ccmClaimStatus"
            label={t('labels.ccmClaimStatus')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <ClaimStatusSelectBox disabled={iamPatientAcceptance} />
          </BaseFormItem>
        </BaseFormItemGroup>
      </BaseForm>
    </PatientBlock>
  );
};

export default AdminPanelAccountInfoForm;
