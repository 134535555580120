export const prepareSortOrder = (
  sort?: [string, string],
  nullLast: boolean = false,
) => {
  if (!sort) return undefined;

  let order = sort[1].toLowerCase();

  order = nullLast ? `${order}_nulls_last` : order;

  return [sort[0], order];
};
