import { appointmentMethodMap } from 'constants/common';
import i18next from 'i18next';
import { type AppointmentCompletionMethod } from 'infrastructure/enums';
import { DateFormatter } from 'infrastructure/functions';
import { parse } from 'date-fns';

import OptionsDropdown from './components/options-dropdown';

import type { IAppointmentCompletion } from 'infrastructure/interfaces';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

interface Props {
  disabledAction?: boolean;
}

export const columns = ({
  disabledAction,
}: Props): IBaseTableColumns<IAppointmentCompletion> => {
  return [
    {
      label: i18next.t('labels.appointmentCompletedOn'),
      key: 'completedDate',
      width: '20%',
      render(value) {
        return (
          <span data-cy="next-appointment-completedDate">
            {DateFormatter({ date: parse(value, 'MMM dd yyyy', new Date()) })}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.completedBy'),
      key: 'completedBy',
      width: '45%',
      render(value) {
        return <span data-cy="next-appointment-completedBy">{value}</span>;
      },
    },
    {
      label: i18next.t('labels.appointmentMethod'),
      key: 'appointmentMethod',
      width: '30%',
      render(value: AppointmentCompletionMethod) {
        return (
          <span data-cy="next-appointment-appointmentMethod">
            {appointmentMethodMap[value]}
          </span>
        );
      },
    },
    {
      label: 'Options',
      key: 'guid',
      width: '5%',
      render(value: string, record) {
        return (
          <OptionsDropdown
            guid={value}
            patientGuid={record.patientGuid}
            disabled={disabledAction}
          />
        );
      },
    },
  ];
};
