import type { FC } from 'react';

interface IHospitalIconProps {
  size?: 'small' | 'medium';
}

const HospitalIcon: FC<IHospitalIconProps> = (props) => {
  const { size = 'medium' } = props;

  let sizes = {
    width: '24',
    height: '24',
  };

  if (size === 'small') {
    sizes = {
      width: '16',
      height: '16',
    };
  }

  return (
    <svg
      {...sizes}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 5.03437V6.13484L9.45302 5.58461L9.95302 6.45064L8.99998 7.00088L9.95303 7.55112L9.45303 8.41714L8.5 7.86691V8.96738H7.5V7.86689L6.54694 8.41714L6.04694 7.55112L6.99998 7.00088L6.04694 6.45064L6.54694 5.58461L7.5 6.13486V5.03437H8.5Z"
        fill="#667180"
      />
      <path
        d="M13.25 9C13.1119 9 13 9.11193 13 9.25V9.75C13 9.88807 13.1119 10 13.25 10H13.75C13.8881 10 14 9.88807 14 9.75V9.25C14 9.11193 13.8881 9 13.75 9H13.25Z"
        fill="#667180"
      />
      <path
        d="M13 11.25C13 11.1119 13.1119 11 13.25 11H13.75C13.8881 11 14 11.1119 14 11.25V11.75C14 11.8881 13.8881 12 13.75 12H13.25C13.1119 12 13 11.8881 13 11.75V11.25Z"
        fill="#667180"
      />
      <path
        d="M13.25 13C13.1119 13 13 13.1119 13 13.25V13.75C13 13.8881 13.1119 14 13.25 14H13.75C13.8881 14 14 13.8881 14 13.75V13.25C14 13.1119 13.8881 13 13.75 13H13.25Z"
        fill="#667180"
      />
      <path
        d="M2.25 9C2.11193 9 2 9.11193 2 9.25V9.75C2 9.88807 2.11193 10 2.25 10H2.75C2.88807 10 3 9.88807 3 9.75V9.25C3 9.11193 2.88807 9 2.75 9H2.25Z"
        fill="#667180"
      />
      <path
        d="M2.25 11C2.11193 11 2 11.1119 2 11.25V11.75C2 11.8881 2.11193 12 2.25 12H2.75C2.88807 12 3 11.8881 3 11.75V11.25C3 11.1119 2.88807 11 2.75 11H2.25Z"
        fill="#667180"
      />
      <path
        d="M2 13.25C2 13.1119 2.11193 13 2.25 13H2.75C2.88807 13 3 13.1119 3 13.25V13.75C3 13.8881 2.88807 14 2.75 14H2.25C2.11193 14 2 13.8881 2 13.75V13.25Z"
        fill="#667180"
      />
      <path
        d="M5 1C5 0.447715 5.44772 0 6 0L10 0C10.5523 0 11 0.447715 11 1V2C11.5523 2 12 2.44772 12 3V7H15C15.5523 7 16 7.44772 16 8V15C16 15.5523 15.5523 16 15 16L1 16C0.447716 16 0 15.5523 0 15L0 8C0 7.44772 0.447715 7 1 7H4V3C4 2.44772 4.44771 2 5 2V1ZM7 15H9L9 12H7V15ZM10 15H11L11 3L5 3L5 15H6V12C6 11.4477 6.44772 11 7 11H9C9.55228 11 10 11.4477 10 12L10 15ZM10 1L6 1V2L10 2V1ZM12 8L12 15L15 15L15 8H12ZM4 15V8H1L1 15H4Z"
        fill="#667180"
      />
    </svg>
  );
};

export default HospitalIcon;
