import React from 'react';
import { DiscreteColorLegend } from 'react-vis';

import legendItems from './legendItems';

const Header = () => (
  <div className="chart-header">
    <div className="chart-header-title" data-cy="sleepMatActivity-chart-title">
      <p>Sleeping Mat Activity</p>
      <p>hour, minutes</p>
    </div>
    <DiscreteColorLegend items={legendItems} orientation="horizontal" />
  </div>
);

export default Header;
