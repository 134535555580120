import i18next from 'i18next';
import { WeekDays } from 'infrastructure/enums';
import { ModifiedArray } from 'infrastructure/classes/common/modified-array';

export interface IWeekDayItem {
  id: WeekDays;
  name: string;
  index: number;
}

type WeekDaysArray = ModifiedArray<IWeekDayItem>;

export const weekDaysDataSource = (): WeekDaysArray => {
  const weekDays = new ModifiedArray<IWeekDayItem>(
    {
      id: WeekDays.Sunday,
      name: i18next.t('dates.sunday'),
      index: 0,
    },
    {
      id: WeekDays.Monday,
      name: i18next.t('dates.monday'),
      index: 1,
    },
    {
      id: WeekDays.Tuesday,
      name: i18next.t('dates.tuesday'),
      index: 2,
    },
    {
      id: WeekDays.Wednesday,
      name: i18next.t('dates.wednesday'),
      index: 3,
    },
    {
      id: WeekDays.Thursday,
      name: i18next.t('dates.thursday'),
      index: 4,
    },
    {
      id: WeekDays.Friday,
      name: i18next.t('dates.friday'),
      index: 5,
    },
    {
      id: WeekDays.Saturday,
      name: i18next.t('dates.saturday'),
      index: 6,
    },
  );

  return weekDays;
};
