import type { IPatientDiagnosis } from 'infrastructure/interfaces';

export class PatientDiagnosis {
  patientGuid: string;
  bloodGlucose: string;
  bloodPressure: string;
  weight: string;
  pulmonary: string;
  sleep: string;
  allCodes: string;

  constructor(data: IPatientDiagnosis) {
    this.patientGuid = data.patientGuid;
    this.bloodGlucose = data.bloodGlucose;
    this.bloodPressure = data.bloodPressure;
    this.weight = data.weight;
    this.pulmonary = data.pulmonary;
    this.sleep = data.sleep;
    this.allCodes = data.allCodes;
  }
}
