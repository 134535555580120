import type { FC, SVGProps } from 'react';

const EllipseIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="6.5"
        cy="6.5"
        r="5"
        transform="matrix(1 0 0 -1 0.5 13)"
        fill="white"
        stroke="#667180"
        strokeWidth="3"
      />
    </svg>
  );
};

export default EllipseIcon;
