import dataService from 'services/dataService';
import {
  API_PATIENTS_ORDER_CONSUMABLES,
  apiPatientConsumableLogs,
} from 'services/dataService/resources';

const ITEMS_PER_PAGE = 5;

const orderConsumable = {
  state: {
    page: 1,
    pageStep: ITEMS_PER_PAGE,
    totalCount: 1,
    totalPages: 0,
    data: [],
  },
  reducers: {
    setPatientConsumableLogs: (state, payload) => ({
      ...state,
      data: [...payload],
    }),
    setPage: (state, payload) => ({
      ...state,
      page: payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    setDischarge: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch) => ({
    async submit(postData) {
      const { error, data } = await dataService.createOne(
        API_PATIENTS_ORDER_CONSUMABLES,
        postData,
      );
      return { error, data };
    },
    async getPatientConsumableLogs(patientGuid, state) {
      const { page, pageStep } = state.orderConsumable;
      const { data } = await dataService.getList(
        apiPatientConsumableLogs(patientGuid, page, pageStep),
      );

      dispatch.orderConsumable.setPatientConsumableLogs(data?.items || []);
      const totalCount = data?.count || 0;
      const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);

      dispatch.orderConsumable.setTotalCountAndTotalPages({
        totalCount,
        totalPages,
      });
    },
    async setPagePatientConsumableLogs({ pageOffset }, state) {
      const { page, totalPages } = state.orderConsumable;
      const newPage = page + pageOffset;
      dispatch.orderConsumable.setPage(
        newPage <= totalPages ? newPage : totalPages,
      );
    },
  }),
};

export default orderConsumable;
