import React from 'react';
import { serviceDeliveryThresholdsDataSource } from 'infrastructure/data-sources/service-delivery-thresholds';
import classNames from 'classnames';
import CrownOutlinedIcon from 'components/atoms/icons/crownOutlinedIcon';

import s from './styles.module.scss';

import type { Nullable } from 'infrastructure/types';

interface IProps {
  hours?: Nullable<number>;
}

const SlaColumn: React.FC<IProps> = ({ hours }) => {
  const sla = serviceDeliveryThresholdsDataSource({
    withNone: true,
    shortName: true,
  }).findByField('id', hours ?? null);

  const hasSla = Boolean(sla) && sla?.id;
  const label = sla!.name;

  const slaClassNames = classNames(s.sla, {
    [s.active]: hasSla,
  });

  return (
    <div className={slaClassNames} data-cy="clinicListTable-sla">
      <div className={s.icon}>
        <CrownOutlinedIcon width={16} height={16} />
        <div>{label}</div>
      </div>
    </div>
  );
};

export default SlaColumn;
