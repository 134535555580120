import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showResult } from 'infrastructure/hooks/utils';

import { sendAppointmentRequestFn } from './api';
import { getPatientInfoKey } from '../patient/use-patient-info';

import type { IPatient } from 'infrastructure/interfaces';

export const useAppointmentRequest = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: sendAppointmentRequestFn,
    onSuccess(data, variables) {
      const patientInfoKey = getPatientInfoKey(variables.patientGuid);
      queryClient.setQueryData<IPatient>(patientInfoKey, (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            appointmentsFlagActive: true,
          };
        }
      });
      queryClient.invalidateQueries(patientInfoKey);
      showResult();
    },
  });

  return {
    sendAppointmentRequest: mutate,
    sendAppointmentRequestLoading: isLoading,
  };
};
