import React, { useContext, useEffect, useState } from 'react';

type tKey = string | number | undefined;

interface IEditContext {
  key: tKey;
  isEdit: boolean;
  setEdit: (state: boolean, key?: tKey) => void;
  canEdit: (key?: tKey) => boolean;
}

const EditContext = React.createContext<IEditContext | undefined>(undefined);

export const useEdit = () => {
  const context = useContext(EditContext);
  if (!context) {
    throw new Error('useEdit must be used within a EditProvider');
  }

  useEffect(
    () => () => {
      if (context) {
        context.setEdit(false);
      }
    },
    [],
  );
  return context;
};

interface IEditProviderProps {
  children: React.ReactNode;
}

const EditProvider: React.FC<IEditProviderProps> = (props) => {
  const { children } = props;

  const [key, setKey] = useState<tKey>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const setEdit = (state: boolean, k?: tKey) => {
    setIsEdit(state);
    setKey(k);
  };

  const canEdit = (k: tKey) => {
    if (!key) return true;
    return k === key;
  };

  return (
    <EditContext.Provider
      value={{
        key,
        isEdit,
        setEdit,
        canEdit,
      }}
    >
      {children}
    </EditContext.Provider>
  );
};

export default EditProvider;
