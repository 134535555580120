import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { useEffect } from 'react';

export const useOfflineToast = () => {
  useEffect(() => {
    const toastId = 'onlineToastKey';
    const handleOnline = () => {
      notificationController.destroy(toastId);
    };
    const handleOffline = () => {
      notificationController.info({
        key: toastId,
        message: 'You are offline',
        closable: false,
        duration: 10e6,
      });
    };
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
};
