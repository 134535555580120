import { DateFormatter, isRpmModule } from 'infrastructure/functions';
import { useQueryClient } from '@tanstack/react-query';

import { getRpmInteractionTimeKey } from './get-rpm-interactive-time';

import type { ModuleType } from 'infrastructure/enums';
import type { Res } from './get-rpm-interactive-time';
import type { Nullable } from 'infrastructure/types';

interface ISocketData {
  billingMonth: number;
  billingYear: number;
  clinicGuid: Nullable<string>;
  patientGuid: string;
  time: number;
}

interface Props {
  module: ModuleType;
  patientGuid: string;
}

export const useUpdateInteractiveTime = ({ module, patientGuid }: Props) => {
  const queryClient = useQueryClient();
  const today = new Date();
  const formattedDate = DateFormatter({ date: today });
  const month = today.getUTCMonth() + 1;
  const year = today.getUTCFullYear();

  const updateInteractionTime = (socketData: ISocketData) => {
    const { billingMonth, billingYear } = socketData;

    if (
      patientGuid !== socketData.patientGuid &&
      month !== billingMonth &&
      year !== billingYear
    )
      return;

    const queryKey = getRpmInteractionTimeKey(
      socketData.patientGuid,
      formattedDate,
    );

    if (isRpmModule(module)) {
      queryClient.setQueryData<Res>(queryKey, (old) => {
        if (!old) return old;

        if (socketData.clinicGuid) {
          old.clinicTime += socketData.time;
          old.totalTime += socketData.time;
        } else {
          old.totalTime += socketData.time;
        }

        return old;
      });
    }
  };

  return { updateInteractionTime };
};
