import dataService from 'services/dataService';
import {
  apiDoctorCompliance,
  COMPLIANCE_CSV_REPORT,
} from 'services/dataService/resources';

const ITEMS_PER_PAGE = 100;

const INITIAL_STATE = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  totalPages: 0,
  totalCount: 0,
  dates: [],
  data: [],
  filters: {
    patientGuid: null,
    doctorGuid: null,
  },
};

const compliance = {
  state: INITIAL_STATE,
  reducers: {
    setPage: (state, payload) => ({
      ...state,
      page: payload < 1 ? 1 : payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    reset: (state) => ({
      ...INITIAL_STATE,
      dates: state.dates.length > 0 ? state.dates : [],
    }),
    setCompliance: (state, payload) => ({
      ...state,
      data: payload,
    }),
    setFilterDates: (state, payload) => ({
      ...state,
      dates: payload,
    }),
    setFilters: (state, payload) => ({
      ...state,
      filters: {
        ...state.filters,
        ...payload,
      },
    }),
  },
  effects: (dispatch) => ({
    async getReport(payload, state) {
      const { dates, filters, page, itemsPerPage: items } = state.compliance;
      const [startTime, endTime] = dates;

      const { data } = await dataService.getList(
        apiDoctorCompliance(startTime, endTime, {
          ...filters,
          page,
          items,
        }),
      );
      if (data) {
        dispatch.compliance.setCompliance(data?.data || []);
        const totalCount = data?.count || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.compliance.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
      }
    },
    async setPageCompliance({ pageOffset }, state) {
      const { page, totalPages } = state.compliance;
      const newPage = page + pageOffset;
      dispatch.compliance.setPage(newPage <= totalPages ? newPage : totalPages);

      return dispatch.compliance.getReport();
    },
    async applyFilters(filters, state) {
      const { filters: currentFilters } = state.compliance;

      Object.keys(filters).forEach((key) => {
        if (filters[key] === undefined) delete filters[key];
        else if (filters[key] === 'all') filters[key] = null;
      });
      dispatch.compliance.setPage(1);
      dispatch.compliance.setFilters({
        ...currentFilters,
        ...filters,
      });
    },
    async applyTimeFilter(dates) {
      if (dates.length === 0) {
        dispatch.compliance.setFilterDates([]);
      } else {
        const [startDate, endDate] = dates;
        const startTime = startDate.getTime();
        const endTime = endDate.getTime();
        dispatch.compliance.setFilterDates([startTime, endTime]);
      }
      dispatch.compliance.setPage(1);
    },
    async getComplianceCsvReport(payload, state) {
      const { dates, filters } = state.compliance;
      const [startTime, endTime] = dates;

      const reportFilters = {};
      Object.keys(filters).forEach((key) => {
        const filter = filters[key];
        if (filter) {
          reportFilters[key] = filter;
        }
      });

      if (filters.doctorGuid) {
        reportFilters.doctorGuids = filters.doctorGuid.split(',');
        reportFilters.doctorGuid = undefined;
      }

      const { error, data } = await dataService.createOne(
        COMPLIANCE_CSV_REPORT,
        {
          startTime,
          endTime,
          ...reportFilters,
        },
      );
      return { error, data };
    },
  }),
};

export default compliance;
