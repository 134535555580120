export const timeframeOptions = {
  '15 minutes':
    '{{{ patient }}} was reminded to take their reading in 15 minutes.',
  '30 minutes':
    '{{{ patient }}} was reminded to take their reading in 30 minutes.',
  '1 hour': '{{{ patient }}} was reminded to take their reading in 1 hour.',
  '2 hours': '{{{ patient }}} was reminded to take their reading in 2 hours.',
  'repeat reading done on phone':
    '{{{ patient }}} was reminded and repeat reading was done on phone.',
  daily: '{{{ patient }}} was reminded to take their reading daily.',
};

export const template = [
  {
    key: 'typeofcritical',
    value:
      'Spoke to {{{ patient }}} regarding their {{{ typeofcritical }}} reading and notified them they are on a recorded line for quality and compliance purposes.',
  },
  {
    key: 'AOC',
    value: 'Patient is alert, oriented, and coherent {{{ AOC }}}.',
  },
  {
    key: 'deniescomplains',
    value:
      'Patient {{{ deniescomplains }}} {{{ symptoms }}} at this time {{{ time }}}.',
  },
  {
    key: 'trigger',
    value:
      '{{{ patient }}} verbalized reasons that may have triggered the critical reading are {{{ trigger }}}.',
  },
  {
    key: 'compliant',
    value:
      '{{{ patient }}} verbalized they are {{{ compliant }}} with related medication(s).',
  },
  {
    key: 'education',
    value: 'Provided education on {{{ education }}}.',
  },
  {
    key: 'last',
    value: 'Their last doctor’s appointment was {{{ last }}}.',
  },
  {
    key: 'next',
    value: 'Their next upcoming doctor’s appointment is {{{ next }}}.',
  },
  {
    key: 'timeframe',
  },
  {
    key: 'deviceeducation',
    value: '{{{ deviceeducation }}}.',
  },
  {
    key: 'comments',
    value: '{{{ comments }}}.',
  },
  {
    key: 'patient',
    value:
      '{{{ patient }}} was reminded that they can reach us at 888-407-4108 or by text 90105.',
  },
];

export const forms = [
  {
    id: 'patient',
    type: 'CHECKBOX',
    title: `Did you speak to patient? Choose other if unlisted family member & type in their relationship.\
            (Ex: Daughter in law) Add name of individual you spoke to in other.`,
    choices: ['Patient', 'Spouse', 'Caregiver'],
    hasOtherOption: true,
    isRequired: true,
    disabled: false,
  },
  {
    id: 'typeofcritical',
    type: 'CHECKBOX',
    title: 'Type of critical reading?',
    choices: [
      'Blood pressure',
      'Blood glucose',
      'SpO2',
      'Pulse (includes sleep mat)',
      'Weight',
      'Temperature',
      'Peak Flow',
    ],
    hasOtherOption: false,
    isRequired: true,
    disabled: false,
  },
  {
    id: 'AOC',
    type: 'CHECKBOX',
    title: `Patient is Alert, Oriented and Coherent 1: Person 2: Person, place 3: Person, place, time\
            4: Person, place, time, situation`,
    choices: ['x1', 'x2', 'x3', 'x4'],
    hasOtherOption: false,
    isRequired: false,
    disabled: false,
  },
  {
    id: 'deniescomplains',
    type: 'MULTIPLE_CHOICE',
    title: 'Signs and symptoms',
    choices: ['denies', 'complaining of'],
    hasOtherOption: false,
    isRequired: true,
    disabled: false,
  },
  {
    id: 'symptoms',
    type: 'CHECKBOX',
    title: 'Select related Signs & Symptoms',
    choices: [
      'altered vision',
      'blurry vision',
      'chest pain',
      'chest discomfort',
      'clammy',
      'dizziness',
      'double vision',
      'edema',
      'fatigue',
      'frequent urination',
      'headache',
      'increased thirst',
      'shortness of breath',
      'shaky',
    ],
    hasOtherOption: true,
    isRequired: true,
    disabled: false,
  },
  {
    id: 'time',
    type: 'TEXT',
    title: 'If patient has symptoms: what is the duration?',
    isRequired: true,
    disabled: false,
  },
  {
    id: 'trigger',
    type: 'CHECKBOX',
    title: 'Reasons that may have triggered the critical reading?',
    choices: [
      'strenuous activity',
      'fasting blood sugar',
      'random blood sugar',
      'diet',
      'use of oxygen',
      'change in medication',
    ],
    hasOtherOption: true,
    isRequired: false,
    disabled: false,
  },
  {
    id: 'compliant',
    type: 'CHECKBOX',
    title: 'Medications',
    choices: ['compliant', 'noncompliant'],
    hasOtherOption: true,
    isRequired: true,
    disabled: false,
  },
  {
    id: 'education',
    type: 'TEXT',
    title: 'Provided education on',
    isRequired: false,
    disabled: false,
  },
  {
    id: 'last',
    type: 'TEXT',
    title: 'Last doctor’s appointment',
    isRequired: false,
    disabled: false,
  },
  {
    id: 'next',
    type: 'TEXT',
    title: 'Next doctor’s appointment',
    isRequired: false,
    disabled: false,
  },
  {
    id: 'timeframe',
    type: 'MULTIPLE_CHOICE',
    title: 'Patient reminded to retake their reading - timeframe',
    choices: [
      '15 minutes',
      '30 minutes',
      '1 hour',
      '2 hours',
      'repeat reading done on phone',
      'daily',
    ],
    hasOtherOption: false,
    isRequired: true,
    disabled: false,
  },
  {
    id: 'deviceeducation',
    type: 'MULTIPLE_CHOICE',
    title: 'Device Education',
    choices: ['Provided device education to ensure accurate readings'],
    hasOtherOption: false,
    disabled: false,
  },
  {
    id: 'comments',
    type: 'TEXT',
    title: 'Additional comments',
    isRequired: false,
    disabled: false,
  },
];

export const allRequiredForm = forms
  .map((form) => (form.isRequired ? form.id : null))
  .filter(Boolean);
