/* eslint-disable */
import React, { useState } from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import {
  Tiles,
  Table,
  Filters,
} from 'components/templates/ccm/practice/statusReport';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import Pagination from 'components/molecules/paginationTransition';
import { showResult } from 'infrastructure/hooks/utils';
import useTable from 'utils/useTable';
import common from 'constants/common';
import {
  API_CCM_PATIENTS_STATUS_REPORT,
  API_CCM_PATIENTS_STATUS_COUNT,
  CCM_PATIENTS_STATUS_CSV_REPORT,
} from 'services/dataService/resources';
import dataService from 'services/dataService';
import withLoader from 'components/organisms/withLoader';
import { skipEmptyStrings } from 'utils/queryHelpers';
import { StatusReportStatistic } from 'infrastructure/classes';
import { useTranslation } from 'react-i18next';

const PER_PAGE = 100;

const CCMStatusReport: React.FC = () => {
  const [csvLoading, setCsvLoading] = useState(false);
  const {
    data: patients,
    loading,
    page,
    setPage,
    filters,
    setFilters: setReportFilters,
  } = useTable(API_CCM_PATIENTS_STATUS_REPORT, PER_PAGE, {});

  const {
    data: statuses,
    loading: statusesLoading,
    setFilters: setStatisticFilters,
  } = useTable(API_CCM_PATIENTS_STATUS_COUNT, null, filters);

  const {
    data: totalOnHoldPatients,
    loading: onHoldLoading,
    setFilters: setTotalHoldFilters,
  } = useTable(API_CCM_PATIENTS_STATUS_REPORT, PER_PAGE, { ccmHoldingStatus: true });

  const sendReport = async () => {
    setCsvLoading(true);
    const { error } = await dataService.createOne(
      CCM_PATIENTS_STATUS_CSV_REPORT,
      skipEmptyStrings(filters),
    );
    setCsvLoading(false);
    return showResult(error, common.submitSuccessTypes.sendReport);
  };

  const setFilters = (filters: any, onlyTable = false) => {
    setReportFilters(filters);
    if (onlyTable) {
      setStatisticFilters(filters);
      setTotalHoldFilters({...filters, ccmHoldingStatus: true})
    }
  };

  const { t } = useTranslation();

  return (
    <Container className="patients-status-report">
      {withLoader(
        statusesLoading,
        <Tiles
          tiles={new StatusReportStatistic(statuses as any)}
          filters={filters}
          setFilter={(statusFilter) =>
            setFilters({ ...filters, ...statusFilter })
          }
        />,
      )}
      <Card className="report card-border card--full-width">
        <CardBody className="card-outer">
          <div className="performance-review__header mb-2">
            <h3
              className="report__header-text"
              data-cy="ccm-status-report__title"
            >
              {t('ccmStatusReportDashboard.reportHeader')}
            </h3>
            <div className="performance-review__header-icon">
              {withLoader(
                csvLoading,
                <button onClick={sendReport}>
                  <DownloadRoundedIcon className="performance-review__header-icon-svg" />
                  <br />
                  <span>{t('controls.download')}</span>
                </button>,
              )}
              {withLoader(
                onHoldLoading,
                <div className="report-table-on-hold">
                  <div
                    className="billing-table-active-medicare-count"
                    data-cy="active-medicare-counter"
                  >
                    {totalOnHoldPatients.count}
                  </div>
                  <div>{t('ccmStatusReportDashboard.totalOnHold')}</div>
                </div>
              )}
            </div>

          </div>
          
          <div className="sort-row">
            <Filters
              filters={filters}
              setFilters={(filters: any) => setFilters(filters, true)}
            />
          </div>
          {withLoader(
            loading,
            <>
              <Table data={patients?.items} />
              <div className="report__pagination-button flex-row-reverse">
                <Pagination
                  title=""
                  page={page}
                  pageTotal={patients?.items?.length}
                  itemsPerPage={PER_PAGE}
                  total={patients?.count}
                  handleFirstClick={() => setPage(1)}
                  handleLastClick={() =>
                    setPage(Math.ceil(patients?.count / PER_PAGE))
                  }
                  handlePrevClick={() => setPage(page - 1)}
                  handleNextClick={() => setPage(page + 1)}
                  handleGoToPage={(numberPage) => setPage(numberPage)}
                />
              </div>
            </>,
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default CCMStatusReport;
