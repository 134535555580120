import { useState } from 'react';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import forms from 'constants/forms';
import common from 'constants/common';
import Pagination from 'components/molecules/pagination';
import AutoCompleteAgencyDoctor from 'components/molecules/autocompleteFilter/agencyDoctor';
import AutoCompleteAgency from 'components/molecules/autocompleteFilter/agency';

import type { FC } from 'react';
import type { AgencyDoctorFilter } from 'utils/hooks/adminPanel/useAgencyDoctors';

const DEFAULT_AGENCY_DOCTOR_FILTER = forms.agencyDoctorFilterOptions[0];
const DEFAULT_AGENCY_FILTER = forms.agencyDoctorFilterOptions[1];

interface Props {
  count: number;
  filters: AgencyDoctorFilter;
  updateFilters: (newFilter: Partial<AgencyDoctorFilter>) => void;
}
const Toolbar: FC<Props> = ({ count, filters, updateFilters }) => {
  const setPage = (page: number) => {
    updateFilters({ page });
  };
  const updateFilter = (newFilter: Partial<AgencyDoctorFilter>) => {
    updateFilters({ page: 1, ...newFilter });
  };
  const { page } = filters;
  const itemsPerPage = filters.items;

  const [userFilterType, setUserFilterType] = useState<any>(
    DEFAULT_AGENCY_DOCTOR_FILTER,
  );

  const selectTheme = (theme: any) => ({
    ...theme,
    borderRadius: 12,
    colors: {
      ...theme.colors,
      ...common.primaryColors,
    },
  });

  const agencyFilter = DEFAULT_AGENCY_FILTER;

  return (
    <Row>
      <Col sm={3} md={2}>
        <strong>Turk User Filter</strong>
        <AutoCompleteAgencyDoctor
          filter={userFilterType}
          roleName={filters.roleName}
          handleClick={({ value }) => {
            updateFilter({ guid: value });
          }}
        />
      </Col>
      <Col sm={3} md={2}>
        <strong>User List Options</strong>
        <Select
          options={forms.adminPanelPracticeUserFilter}
          defaultValue={userFilterType}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          value={userFilterType}
          onChange={(value) => setUserFilterType(value)}
        />
      </Col>

      <Col sm={3} md={2}>
        <strong>Agency</strong>
        <AutoCompleteAgency
          filter={agencyFilter}
          handleClick={({ value }) => {
            updateFilter({ agencyGuid: value });
          }}
        />
      </Col>
      <Col sm={2} md={4} />
      <Col sm={3} md={2}>
        <Pagination
          title=""
          page={page}
          pageTotal={itemsPerPage}
          itemsPerPage={itemsPerPage}
          total={count}
          handleFirstClick={() => setPage(1)}
          handleLastClick={() => setPage(Math.ceil(count / itemsPerPage))}
          handlePrevClick={() => setPage(page - 1)}
          handleNextClick={() => setPage(page + 1)}
        />
      </Col>
    </Row>
  );
};

export default Toolbar;
