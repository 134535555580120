const PcmTagIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33333 12.6678L10.6667 14.0011L13.6667 11.0011M14.3234 8.36772C14.33 8.24636 14.3333 8.12414 14.3333 8.00114C14.3333 4.31924 11.3486 1.33447 7.66667 1.33447C3.98477 1.33447 1 4.31924 1 8.00114C1 11.6247 3.89101 14.5731 7.49233 14.6656M7.66667 4.00114V8.00114L10.1589 9.24726"
      stroke="#E28416"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PcmTagIcon;
