import BaseAutocomplete from 'components/atoms/base-autocomplete';
import { useSearchMedication } from 'infrastructure/hooks/ccm';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { BaseAutocompleteProps } from 'components/atoms/base-autocomplete/types';
import type { FC } from 'react';

interface MedicationAutocompleteProps {
  value?: string;
  onChange?: BaseAutocompleteProps<string>['onSelect'];
  disabled?: boolean;
  invalid?: boolean;
}

const MedicationAutocomplete: FC<MedicationAutocompleteProps> = ({
  value,
  onChange,
  disabled,
  invalid,
}) => {
  const { search, isLoading, data } = useSearchMedication();
  const { t } = useTranslation();

  const debounceSearch = useCallback(debounce(search, 800), []);

  return (
    <BaseAutocomplete
      options={data?.map((i) => ({ label: i, value: i }))}
      onSelect={onChange}
      value={value}
      onChange={debounceSearch}
      isLoading={isLoading}
      placeholder={t('labels.searchMedication')}
      disabled={disabled}
      invalid={invalid}
    />
  );
};
export default MedicationAutocomplete;
