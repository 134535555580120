const InfoIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none">
        <circle
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M12 17v-6"
        />
        <circle
          cx="1"
          cy="1"
          r="1"
          fill="currentColor"
          transform="matrix(1 0 0 -1 11 9)"
        />
      </g>
    </svg>
  );
};

export default InfoIcon;
