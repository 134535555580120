import { API_CCM_INTERACTIVE_TIME_REPORT } from './constants';

export const API_YESTERDAYS_STATS_INFO_TILES =
  'reports/reports/yesterdays-stats/info-tiles';
export const apiYesterdaysStatsReadings = (items, page = 1) =>
  `reports/reports/yesterdays-stats/readings?items=${items}&page=${page}`;

export const API_PERFORMANCE_REVIEW = 'reports/reports/performance-review';
export const API_PERFORMANCE_REVIEW_COUNT =
  'reports/reports/performance-review/count';

export const apiCcmInteractiveTime = (items, page = 1, filters) => {
  let endpoint = `${API_CCM_INTERACTIVE_TIME_REPORT}?items=${items}&page=${page}`;
  Object.keys(filters).forEach((key) => {
    const filterValue = filters[key];
    if (filterValue && filterValue !== 'all') {
      endpoint += `&${key}=${filterValue}`;
    }
  });
  return endpoint;
};
