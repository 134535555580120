import i18next from 'i18next';
import common from 'constants/common';
import ButtonLoadingIcon from 'components/atoms/icons/buttonLoadingIcon';
import ThreeDotsIconHorizontal from 'components/atoms/icons/threeDotsIconHorizontal';
import { Dropdown } from 'antd';
import classNames from 'classnames';

import s from './styles.module.scss';
import BaseButton from '../baseButton';

import type { ReactNode, FC } from 'react';
import type { MenuProps } from 'antd';

interface Props {
  loading: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  bordered?: boolean;
  disabled?: boolean;
  triggerBtnClassName?: string;
  info?: ReactNode;
}

type GenerateDropdownItems = [
  onEdit?: () => void,
  onDelete?: () => void,
  info?: ReactNode,
];

const generateDropdownItems = ([
  onEdit,
  onDelete,
  info,
]: GenerateDropdownItems): MenuProps['items'] =>
  [
    {
      label: i18next.t('controls.edit'),
      'data-cy': 'dropdown-edit-item',
      key: '0',
      onClick: onEdit,
    },
    {
      label: i18next.t('controls.delete'),
      'data-cy': 'dropdown-delete-item',
      key: '1',
      onClick: onDelete,
      danger: true,
    },
    {
      label: info,
      key: '2',
      onClick: info ? () => {} : undefined,
      dataItem: 'info',
    },
  ].filter((i) => typeof i.onClick !== 'undefined');

const BaseTableOptionsDropdown: FC<Props> = ({
  loading,
  onEdit,
  onDelete,
  bordered = false,
  disabled,
  triggerBtnClassName,
  info,
}) => {
  const items: MenuProps['items'] = generateDropdownItems([
    onEdit,
    onDelete,
    info,
  ]);

  return (
    <div className={s.wrapper}>
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        placement="bottomRight"
        rootClassName={s.menu}
      >
        <BaseButton
          onClick={(e) => e.preventDefault()}
          dataCy="table-options-button"
          className={classNames(
            s.trigger,
            { [s.border]: bordered },
            triggerBtnClassName,
          )}
          disabled={disabled || loading}
          type="secondary"
          icon={
            loading ? (
              <ButtonLoadingIcon
                color={common.disabledIconColor}
                className={s.loading}
              />
            ) : (
              <ThreeDotsIconHorizontal />
            )
          }
        />
      </Dropdown>
    </div>
  );
};
export default BaseTableOptionsDropdown;
