import { useEffect, useState } from 'react';
import { applyFields } from 'infrastructure/functions';
import { ClinicStatus, ServiceDeliveryThreshold } from 'infrastructure/enums';
import {
  parseSearchBooleanValue,
  parseSearchNumberValue,
  parseSearchStringValue,
} from 'infrastructure/functions/parse-query-string';

import { useQueryParams } from '../common/use-query-param';
import { useQueryFilterNew } from '../common/use-query-param-new';

import type { TParseFunctions } from '../common/use-query-param-new';
import type { IClinicListFilters } from 'infrastructure/interfaces';
import type { ServiceDeliveryThresholdWithoutAll } from 'infrastructure/enums';

type ClinicFilterKey = {
  clinic: IClinicListFilters;
};

export const defaultValues: IClinicListFilters = {
  status: ClinicStatus.Active,
  serviceDeliveryThresholds: ServiceDeliveryThreshold.All,
};

interface IUseClinicQueryFilter {
  filters: IClinicListFilters;
  setFilters: (query: IClinicListFilters | null) => void;
  getFilters: () => IClinicListFilters;
}

export const useClinicQueryFilter = (): IUseClinicQueryFilter => {
  const { setQuery, getQueryByName, resetQuery } = useQueryParams();

  const [filters, setQueryFilters] =
    useState<IClinicListFilters>(defaultValues);

  const setFilters = (query: IClinicListFilters | null) => {
    if (query === null) {
      resetQuery();
      setQueryFilters(defaultValues);
      return;
    }
    const withoutEmptyValues = applyFields<IClinicListFilters>({}, query);
    const preparedQuery: ClinicFilterKey = {
      clinic: withoutEmptyValues,
    };

    setQuery(preparedQuery);
    setQueryFilters(withoutEmptyValues);
  };

  const getFilters = (): IClinicListFilters => {
    const query = getQueryByName<IClinicListFilters>('clinic');
    if (query) return query;
    return defaultValues;
  };

  useEffect(() => {
    const query = getFilters();
    setQueryFilters(query);
  }, []);

  return {
    filters,
    setFilters,
    getFilters,
  };
};

// NEW

const defaultFilter: IClinicListFilters = {
  status: ClinicStatus.Active,
  serviceDeliveryThresholds: ServiceDeliveryThreshold.All,
  page: 1,
};

const parseFunctions: TParseFunctions<IClinicListFilters> = {
  page: parseSearchNumberValue,
  status: (v: any) => parseSearchStringValue<ClinicStatus>(v),
  agencyGuid: parseSearchStringValue,
  guid: parseSearchStringValue,
  legalname: parseSearchStringValue,
  parentClinicGuid: parseSearchStringValue,
  serviceDeliveryThresholds: (v: any) =>
    parseSearchNumberValue<ServiceDeliveryThresholdWithoutAll>(v),
  noLimit: parseSearchBooleanValue,
};

export const useClinicQueryFilterNew = () => {
  return useQueryFilterNew<IClinicListFilters>(defaultFilter, parseFunctions, [
    'page',
  ]);
};
