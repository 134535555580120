import React from 'react';
import { Input } from 'antd';

import type { ICommonInputProps } from 'infrastructure/interfaces';

export type TSize = { width: number; height: number };

interface IBaseTextAreaProps extends ICommonInputProps<string> {
  rows?: number;
  maxRows?: number;
  onResize?: (size: TSize) => void;
}

const BaseTextArea: React.FC<IBaseTextAreaProps> = (props) => {
  const {
    value,
    placeholder,
    disabled,
    plaintext,
    readonly,
    invalid,
    onChange,
    className,
    style,
    rows = 4,
    maxRows,
    onResize,
    dataCy = 'base-text-area',
    id,
  } = props;

  return (
    <Input.TextArea
      id={id}
      style={{
        resize: 'none',
        ...style,
      }}
      autoSize={{
        minRows: rows,
        maxRows: maxRows ?? rows,
      }}
      value={value}
      rows={rows}
      placeholder={placeholder}
      readOnly={readonly}
      disabled={disabled}
      className={className}
      onChange={(e) => {
        if (onChange) onChange(e.target.value);
      }}
      onResize={onResize}
      variant={plaintext ? 'borderless' : 'outlined'}
      status={invalid ? 'error' : undefined}
      data-cy={dataCy}
    />
  );
};

export const BaseTextAreaDisplayName = 'BaseTextArea';
BaseTextArea.displayName = BaseTextAreaDisplayName;

export default BaseTextArea;
