import React, { useMemo } from 'react';
import Select from 'react-select';
import {
  format,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import { getUTCDates } from 'utils';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import AutoCompleteDoctorMultiSelect from 'components/molecules/autocompleteFilter/doctorMulti';

import type { IBaseComponentProps } from 'infrastructure/interfaces';
import type { SingleValue } from 'react-select';
import type { IUseAdherenceMeta } from 'infrastructure/hooks/use-adherence';

const DEFAULT_TIME_RANGE_KEY = 4;
const today = new Date();
const yesterday = subDays(today, 1);
const sevenDaysAgo = subDays(today, 7);
const fourteenDaysAgo = subDays(today, 14);

interface IAdherenceReportTableFiltersProps extends IBaseComponentProps {
  onChange: (filters: IUseAdherenceMeta) => void;
}

interface ISelectOptions {
  value: [Date, Date];
  label: string;
}

const AdherenceReportTableFilters: React.FC<
  IAdherenceReportTableFiltersProps
> = (props) => {
  const { className, onChange } = props;
  const { t } = useTranslation();

  const dateRanges: ISelectOptions[] = useMemo(() => {
    const dates = [
      {
        value: getUTCDates([startOfDay(today), endOfDay(today)]),
        label: t('labels.today'),
      },
      {
        value: getUTCDates([startOfDay(yesterday), endOfDay(yesterday)]),
        label: t('labels.yesterday'),
      },
      {
        value: getUTCDates([startOfDay(sevenDaysAgo), endOfDay(today)]),
        label: t('labels.last7Days'),
      },
      {
        value: getUTCDates([startOfDay(fourteenDaysAgo), endOfDay(today)]),
        label: t('labels.last14Days'),
      },
    ];
    for (let i = 0; i < 12; i++) {
      const startTime = subMonths(today, i);
      const endTime = endOfMonth(startTime);
      const label = format(startTime, 'MMM-yyyy');
      dates.push({
        value: getUTCDates([startOfMonth(startTime), endOfMonth(endTime)]),
        label,
      });
    }
    return dates;
  }, []);

  return (
    <div className={className}>
      <Row>
        <Col md="3" sm="6" xs="12">
          <strong data-cy="adherence-time-report-filter">
            {t('labels.timeRange')}
          </strong>
          <Select
            options={dateRanges}
            defaultValue={dateRanges[DEFAULT_TIME_RANGE_KEY]}
            classNamePrefix="select-type"
            styles={{
              menu: (base) => {
                return { ...base, zIndex: 9999 } as any;
              },
            }}
            onChange={(newValue: SingleValue<ISelectOptions>) => {
              if (newValue) {
                const {
                  value: [startTime, endTime],
                } = newValue;

                onChange({
                  startTime: startTime.getTime(),
                  endTime: endTime.getTime(),
                });
              }
            }}
          />
        </Col>
        <Col md="3" sm="6" xs="12">
          <strong data-cy="adherence-provider-filter">
            {t('labels.provider')}
          </strong>
          <AutoCompleteDoctorMultiSelect
            // @ts-ignore
            onChange={(value: any) => onChange({ doctorGuid: value })}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdherenceReportTableFilters;
