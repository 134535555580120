import queryString from 'query-string';

import type { ParseOptions, StringifyOptions } from 'query-string';

const defaultParseOptions: ParseOptions = {};
const parse = (params: string, option?: ParseOptions) => {
  return queryString.parse(params, {
    ...defaultParseOptions,
    ...option,
  });
};

const defaultStringifyOptions: StringifyOptions = {
  skipNull: true,
  skipEmptyString: true,
};

const stringify = (query?: Record<string, any>, option?: StringifyOptions) => {
  if (!query) return '';
  return queryString.stringify(query, {
    ...defaultStringifyOptions,
    ...option,
  });
};
const stringifyUrl = (
  url: string,
  query?: Record<string, any>,
  option?: StringifyOptions,
) => {
  return queryString.stringifyUrl(
    {
      url,
      query,
    },
    {
      ...defaultStringifyOptions,
      ...option,
    },
  );
};

class QueryParams {
  static parse = parse;
  static stringify = stringify;
  static stringifyUrl = stringifyUrl;
}

export default QueryParams;
