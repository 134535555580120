import { useQuery } from '@tanstack/react-query';
import QueryParams from 'utils/queryParams';
import { API_ELIGIBILITY_PROCESS_LIST } from 'services/dataService/resources';
import dataService from 'services/dataService';

import type { EligibilityProcessesData, Filters } from './types';

const REFETCH_INTERVAL = 10 * 1000;

export const useEligibilityData = (filters: Filters) => {
  const queryKey = ['eligibilityProcessesDashboard', filters] as const;

  const { data, error, isLoading, isPreviousData, refetch } = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: () => getItems(filters),
    refetchInterval: (_data) => {
      const haveUncompleted = _data?.items.some(
        (item) => item.itemsCount !== item.processedItemsCount,
      );
      if (haveUncompleted) {
        return REFETCH_INTERVAL;
      }
      return false;
    },
  });

  return {
    data: {
      data: data?.items,
      count: data?.count,
    },
    refetch,
    isLoading: isLoading || isPreviousData,
    error,
  };
};

const getItems = async (filters: Filters) => {
  const url = QueryParams.stringifyUrl(API_ELIGIBILITY_PROCESS_LIST, filters);
  const { error, data } = await dataService.getList<EligibilityProcessesData>(
    url,
  );
  if (error) {
    throw new Error(error);
  }
  return data;
};
