import React, { forwardRef, useImperativeHandle } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseGrid from 'components/atoms/base-grid';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import InsuranceProviderSelectBox from 'components/molecules/insurance-provider-select-box';
import BaseInput from 'components/atoms/base-input';
import InsuranceTypeSelectBox from 'components/molecules/insurance-type-select-box';
import { usePatientInsurance } from 'infrastructure/hooks/patient/use-patient-insurance';

import { patientInsurancesValidationSchema } from './validation-schema';

import type { PatientInsurance } from 'infrastructure/classes/patient/patient-insurance';

export interface IPatientInsuranceCreateFormRef {
  submit: () => Promise<void>;
  reset: () => void;
}

interface IPatientInsuranceCreateFormProps {
  patientGuid: string;
  items: Array<PatientInsurance>;
  data?: PatientInsurance;
  onCancel?: () => void;
  onSaved?: () => void;
}

const PatientInsuranceCreateForm = forwardRef<
  IPatientInsuranceCreateFormRef,
  IPatientInsuranceCreateFormProps
>((props, ref) => {
  const { patientGuid, items, data = {}, onCancel, onSaved } = props;

  const { t } = useTranslation();

  const { loading, updatePatientInsurance } = usePatientInsurance({
    patientGuid,
  });

  const formik = useFormik<PatientInsurance>({
    initialValues: {
      patientGuid,
      provider: '' as any,
      type: null,
      number: '',
      planName: '',
      ...data,
    } as PatientInsurance,
    onSubmit: async (values) => {
      await updatePatientInsurance({
        patientGuid,
        insurances: [...items.filter((el) => el.guid !== values.guid), values],
      }).then(() => {
        if (onSaved) onSaved();
      });
    },
    validateOnChange: false,
    validationSchema: patientInsurancesValidationSchema(),
  });

  const { submitForm, resetForm } = formik;

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: resetForm,
  }));

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseForm
        loading={loading}
        formik={formik}
        plaintext={false}
        readonly={false}
        onSave={submitForm}
        onCancel={onCancel}
        withControls
      >
        <BaseGrid columns={1}>
          <BaseFormItem name="provider" label={t('labels.provider')}>
            <InsuranceProviderSelectBox />
          </BaseFormItem>
          <BaseFormItem name="number" label={t('labels.number')}>
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem name="type" label={t('labels.type')}>
            <InsuranceTypeSelectBox allowClear />
          </BaseFormItem>
          <BaseFormItem name="planName" label={t('labels.planName')}>
            <BaseInput />
          </BaseFormItem>
        </BaseGrid>
      </BaseForm>
    </BaseSpinWrapper>
  );
});

PatientInsuranceCreateForm.displayName = 'PatientInsuranceCreateForm';

export default PatientInsuranceCreateForm;
