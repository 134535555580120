import { Formik, Field, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import ModalPopup from 'components/organisms/modal';
import TextArea from 'components/atoms/textarea';
import Button from 'components/atoms/button';
import { showResult } from 'infrastructure/hooks/utils';

const SmsModalMobile = ({
  isOpen,
  toggle,
  patientGuid,
  smsTemplates,
  loading,
  submit,
  isRpmPanel,
  isPrimaryNumber,
}) => {
  const [topic, setTopic] = useState('');

  const handleSubmit = async (values) => {
    const postData = {
      patientGuid,
      topic,
      type: 'sms',
      message: values.message,
      isRpmPanel,
      isPrimaryNumber,
    };
    const { error } = await submit(postData);
    showResult(error, null, toggle);
  };

  const onTemplateChange = (templateGuid, setFieldValue) => {
    const smsTemplate = smsTemplates.find(
      (template) => templateGuid === template.guid,
    );
    setTopic(smsTemplate.topic);
    setFieldValue('message', smsTemplate.text);
    const messageBoxes = document.getElementsByClassName('message-mobile');
    const selectedMessageBox = document.getElementById(
      `message-mobile-${templateGuid}`,
    );
    for (let i = 0; i < messageBoxes.length; i++) {
      messageBoxes[i].style.height = 0;
    }
    selectedMessageBox.style.height = 'auto';
  };

  return (
    <ModalPopup
      isOpen={isOpen}
      toggle={toggle}
      title="Send SMS Primary Contact"
      className="popup sms-popup"
    >
      <Formik
        initialValues={{
          comment: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="sms-popup-content">
              <FormGroup tag="fieldset">
                {smsTemplates.map((template) => (
                  <FormGroup check key={template.guid}>
                    <Label check>
                      <Input
                        type="radio"
                        name="template_id"
                        value={template.guid}
                        onChange={() =>
                          onTemplateChange(template.guid, setFieldValue)
                        }
                      />{' '}
                      {template.topic}
                    </Label>
                    <div
                      className="message-mobile"
                      id={`message-mobile-${template.guid}`}
                    >
                      <Field
                        className="sms-textarea"
                        id="message"
                        name="message"
                        type="message"
                        as={TextArea}
                        rows="8"
                        placeholder="Type or select option on the left"
                      />
                      <div className="text-center">
                        <Button
                          type="submit"
                          text="Send"
                          data-cy="send-button"
                          disabled={loading}
                        />
                      </div>
                    </div>
                  </FormGroup>
                ))}
              </FormGroup>
            </div>
          </Form>
        )}
      </Formik>
    </ModalPopup>
  );
};
SmsModalMobile.defaultProps = {
  isOpen: false,
};

SmsModalMobile.propTypes = {
  toggle: PropTypes.func.isRequired,
  patientGuid: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  smsTemplates: PropTypes.arrayOf(PropTypes.shape).isRequired,
  loading: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  isRpmPanel: PropTypes.bool,
  isPrimaryNumber: PropTypes.bool,
};

export default SmsModalMobile;
