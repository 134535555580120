import React from 'react';

const EditProfileIcon = (props) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.7296 1.7031C10.7343 1.27883 10.5695 0.870223 10.2718 0.567879C9.96912 0.268515 9.56231 0.0979099 9.1366 0.0918118C8.71829 0.0899894 8.31642 0.254672 8.01971 0.54955L10.2718 2.81998C10.5739 2.52812 10.7399 2.12302 10.7296 1.7031Z"
      fill="#FB9318"
    />
    <path
      d="M9.75908 3.33217L7.50699 1.08008L2.28872 6.29835L4.54081 8.55044L9.75908 3.33217Z"
      fill="#FB9318"
    />
    <path
      d="M1.99578 7.03125L1.48309 9.3566L3.80844 8.84391L1.99578 7.03125Z"
      fill="#FB9318"
    />
    <path
      d="M12.6338 12.1758H0.366198C0.163946 12.1758 0 12.3397 0 12.542C0 12.7442 0.163946 12.9082 0.366198 12.9082H12.6338C12.8361 12.9082 13 12.7442 13 12.542C13 12.3397 12.8361 12.1758 12.6338 12.1758Z"
      fill="#FB9318"
    />
  </svg>
);

export default EditProfileIcon;
