import dataService from 'services/dataService';
import { API_PATIENTS_PCM_STATUS } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface DataProps {
  patientGuid: string;
  pcmStatus: boolean;
}

export const updatePatientPcmStatusFn = async ({
  patientGuid,
  pcmStatus,
}: DataProps) => {
  const { error, data } = await dataService.updateOnly(
    API_PATIENTS_PCM_STATUS(patientGuid),
    { pcmStatus },
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
