import type { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  animate: boolean;
}

const ProfileMenuIcon = ({ animate, ...props }: Props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.65366 7.89939C16.0406 -0.80898 27.2845 -2.5712 34.4682 3.8837C41.6519 10.3386 42.0336 28.1539 34.4682 36.0092C26.9028 43.8646 9.46587 41.3444 3.52835 36.0092C-2.40917 30.6741 -0.733297 16.6078 7.65366 7.89939Z"
      fill="#FB9318"
    />
    <path
      className={`menu-burger ${animate ? 'hide' : 'show'}`}
      d="M12 28H28C28.55 28 29 27.55 29 27C29 26.45 28.55 26 28 26H12C11.45 26 11 26.45 11 27C11 27.55 11.45 28 12 28ZM12 23H28C28.55 23 29 22.55 29 22C29 21.45 28.55 21 28 21H12C11.45 21 11 21.45 11 22C11 22.55 11.45 23 12 23ZM11 17C11 17.55 11.45 18 12 18H28C28.55 18 29 17.55 29 17C29 16.45 28.55 16 28 16H12C11.45 16 11 16.45 11 17Z"
      fill="white"
    />
    <path
      className={`menu-burger-close ${animate ? 'show' : 'hide'}`}
      d="M26 14L14 26M14 14L26 26"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProfileMenuIcon;
