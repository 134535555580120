import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import forms from 'constants/forms';
import AutoCompleteDoctorMultiSelect from 'components/molecules/autocompleteFilter/doctorMulti';
import AutoCompleteClinics from 'components/molecules/autocompleteFilter/clinic';
import BaseSelect from 'components/atoms/baseSelect';
import { useTranslation } from 'react-i18next';

const DEFAULT_PATIENT_FILTER = forms.patientFilterOptions[0];
const DEFAULT_CLINIC_FILTER = forms.clinicFilterOptions[0];
const INTERACTION_STATUS_FILTER = [
  { value: undefined, label: 'All' },
  { value: true, label: 'True' },
  { value: false, label: 'False' },
];
const DEFAULT_CHILD_CLINIC = forms.childClinicSelect[0];

const Toolbar = ({ setFilters, filters, timeRanges }) => {
  const [isParentClinicSelected, setIsParentClinicSelected] = useState(false);

  const onboardingStatuses = forms.patientStatuses;

  const statusOptions = useMemo(
    () => [{ value: undefined, label: 'All' }, ...onboardingStatuses],
    [],
  );

  const statusValue = (statusName) => {
    const value = statusOptions.find((element) => element.value === statusName);
    return value;
  };

  const { t } = useTranslation();

  const handleClinicChange = (option) => {
    setIsParentClinicSelected(option.parentClinic);
    setFilters({ clinicGuid: option.value, childClinic: option.parentClinic });
  };

  return (
    <Row>
      <Col xl="2" lg="3" md="4" sm="6" xs="12">
        <strong>Time Range</strong>
        <BaseSelect
          options={timeRanges}
          defaultValue={timeRanges[0]}
          classNamePrefix="select-type"
          onChange={({ value: [year, month] }) => setFilters({ year, month })}
        />
      </Col>
      <Col xl="2" lg="3" md="4" sm="6" xs="12">
        <strong>Interaction Status</strong>
        <BaseSelect
          options={INTERACTION_STATUS_FILTER}
          defaultValue={INTERACTION_STATUS_FILTER[0]}
          classNamePrefix="select-type"
          onChange={({ value }) => setFilters({ interactionStatus: value })}
        />
      </Col>
      <Col xl="2" lg="3" md="4" sm="6" xs="12">
        <strong>Clinic</strong>
        <AutoCompleteClinics
          filter={DEFAULT_CLINIC_FILTER}
          handleClick={handleClinicChange}
        />
      </Col>
      <Col xl="2" lg="3" md="4" sm="6" xs="12">
        <strong>Provider</strong>
        <AutoCompleteDoctorMultiSelect
          filter={DEFAULT_PATIENT_FILTER}
          status="active"
          clinicGuid={filters.clinicGuid}
          onChange={(value) => {
            setFilters({ doctorGuid: value });
          }}
        />
      </Col>
      <Col xl="2" lg="3" md="4" sm="6" xs="12">
        <strong>Patient Status</strong>
        <BaseSelect
          options={statusOptions}
          defaultValue={statusOptions[1]}
          classNamePrefix="select-type"
          value={statusValue(filters.patientStatus)}
          onChange={({ value }) => setFilters({ patientStatus: value })}
        />
      </Col>

      {isParentClinicSelected && (
        <Col xl="2" lg="3" md="4" sm="6" xs="12">
          <strong>{t('labels.childClinics')}</strong>

          <BaseSelect
            options={forms.childClinicSelect}
            defaultValue={DEFAULT_CHILD_CLINIC}
            classNamePrefix="select-type"
            onChange={({ value }) => setFilters({ childClinic: value })}
          />
        </Col>
      )}
    </Row>
  );
};

Toolbar.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
  timeRanges: PropTypes.array,
};

export default Toolbar;
