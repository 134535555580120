import dataService from '../../services/dataService';
import {
  apiPatientAttachmentsByPatientGuid,
  apiPatientAttachmentsByGuid,
} from '../../services/dataService/resources';

const ITEMS_PER_PAGE = 10;
const INITIAL_STATE = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  data: [],
  totalPages: 0,
  totalCount: 0,
};

const Attachments = {
  state: INITIAL_STATE,
  reducers: {
    setAttachments: (state, payload) => ({
      ...state,
      data: payload,
    }),
    removeAttachment: (state, payload) => ({
      ...state,
      data: payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
  },
  effects: (dispatch) => ({
    async getAttachments({ patientGuid }, state) {
      const { page, items = 10 } = state.attachments;
      const { data, error } = await dataService.getList(
        apiPatientAttachmentsByPatientGuid(patientGuid, page, items),
      );
      if (data) {
        dispatch.attachments.setAttachments(data?.items || []);
        const totalCount = data?.count || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.attachments.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
      }
      return { data, error };
    },
    async createAttachment({ patientGuid, body }, state) {
      const { data, error } = await dataService.uploadFile(
        apiPatientAttachmentsByPatientGuid(patientGuid),
        JSON.stringify(body),
        'POST',
      );
      if (!error) {
        dispatch.attachments.setAttachments(
          [data, ...state.attachments.data].slice(0, 10),
        );
      }
      await dispatch.attachments.getAttachments({ patientGuid }, state);
      return { error, data };
    },
    async updateAttachment({ guid, patientGuid, body }, state) {
      const { data, error } = await dataService.uploadFile(
        apiPatientAttachmentsByGuid(guid),
        JSON.stringify(body),
        'PUT',
      );
      await dispatch.attachments.getAttachments({ patientGuid }, state);
      return { data, error };
    },
    async deleteAttachment({ patientGuid, guid }, state) {
      const { data, error } = await dataService.deleteObject(
        apiPatientAttachmentsByGuid(guid),
      );
      await dispatch.attachments.getAttachments({ patientGuid }, state);
      return { data, error };
    },
    async downloadAttachment({ guid }) {
      const { data, error } = await dataService.getOnly(
        apiPatientAttachmentsByGuid(guid),
      );
      return { url: data?.url, error };
    },
  }),
};

export default Attachments;
