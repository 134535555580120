import React from 'react';
import classNames from 'classnames';

import s from './styles.module.scss';

interface IBaseGridProps {
  children?: React.ReactNode;
  columns?: number;
  columnGap?: number;
  rowGap?: number;
  className?: string;
}

const BaseGrid: React.FC<IBaseGridProps> = (props) => {
  const {
    children,
    columns = 1,
    columnGap = 14,
    rowGap = 14,
    className,
  } = props;

  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        columnGap: `${columnGap}px`,
        rowGap: `${rowGap}px`,
      }}
      className={classNames(s.grid, className)}
    >
      {children}
    </div>
  );
};

export default BaseGrid;
