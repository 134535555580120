import { makeid } from 'utils/helpers/makeid';
import {
  convertDateToTimeZone,
  convertTimeToTimeZone,
} from 'infrastructure/functions';
import { between } from 'infrastructure/functions/between';

import type {
  IAvailability,
  ICreateAvailability,
  IDeleteAvailability,
  IUpdateAvailability,
} from 'infrastructure/interfaces';

type action = 'create' | 'delete' | 'update';

export class Availability {
  key: string;
  guid: string;
  patientGuid: string;
  day: number;
  startTime: string;
  endTime: string;
  action?: action;

  constructor(data: IAvailability) {
    this.key = makeid(10);
    this.guid = data.guid;
    this.patientGuid = data.patientGuid;
    this.day = data.day;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
  }

  setAction(action?: action) {
    this.action = action;
  }

  static getInitialValues(patientGuid: string) {
    return new Availability({
      guid: '',
      patientGuid,
      day: 0,
      startTime: '',
      endTime: '',
    });
  }

  static resetAction(availabilities: Array<Availability>) {
    return availabilities.forEach((el) => el.setAction(undefined));
  }

  static prepareToDeleting(
    availabilities: Array<Availability>,
  ): Array<IDeleteAvailability> {
    return availabilities
      .filter(({ action }) => action === 'delete')
      .map(({ guid }) => ({ guid }));
  }

  static prepareToCreating(
    authorGuid: string,
    availabilities: Array<Availability>,
  ): Array<ICreateAvailability> {
    return availabilities
      .filter(({ action }) => action === 'create')
      .map(({ day, startTime, endTime }) => ({
        authorGuid,
        day,
        startTime,
        endTime,
      }));
  }

  static prepareToUpdating(
    authorGuid: string,
    availabilities: Array<Availability>,
  ): Array<IUpdateAvailability> {
    return availabilities
      .filter(({ action }) => action === 'update')
      .map(({ guid, day, patientGuid, startTime, endTime }) => ({
        guid,
        day,
        authorGuid,
        patientGuid,
        startTime,
        endTime,
      }));
  }

  static isAvailable(arr: Array<Availability>, timezone: string): boolean {
    if (arr.length === 0) return true;

    const currentPatientDate = convertDateToTimeZone(new Date(), timezone);

    return arr.some((el) => {
      const startTime = convertTimeToTimeZone(el.startTime, timezone).getTime();
      const endTime = convertTimeToTimeZone(el.endTime, timezone).getTime();
      const time = currentPatientDate.getTime();

      return (
        between(time, startTime, endTime) &&
        currentPatientDate.getDay() === el.day
      );
    });
  }
}
