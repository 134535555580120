import withLoader from 'components/organisms/withLoader';
import Charts from 'components/templates/rpm/patients/patient/charts';
import useTurkDashboardCharts from 'utils/hooks/turkDashboard/useCharts';

import type { FC } from 'react';

interface Props {
  patientGuid: string;
  devicesLoading: boolean;
  dates: {
    startTime: string;
    endTime: string;
  };
}

const PatientCharts: FC<Props> = ({ patientGuid, devicesLoading, dates }) => {
  const { data, isLoading } = useTurkDashboardCharts({
    patientGuid,
    enabled: !devicesLoading,
    startTime: dates.startTime,
    endTime: dates.endTime,
  });

  return (
    <div style={{ marginTop: 30 }}>
      {withLoader(
        isLoading,
        data ? (
          <Charts
            chartsLoading={isLoading}
            patientDetailsLoading={isLoading}
            // TODO: temp comment
            // thresholds={{}}
            charts={data}
          />
        ) : null,
      )}
    </div>
  );
};

export default PatientCharts;
