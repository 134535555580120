import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const patientInsurancesValidationSchema = () =>
  createValidationSchema({
    number: commonValidationSchemas.string({ required: true }),
    provider: commonValidationSchemas.string({ required: true }),
  });
