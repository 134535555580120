import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import CcmStatusBlock from 'components/atoms/statusBlock/ccmStatusBlock';

const statusCount = ({ statusBlocks }) => {
  return (
    <div className="status-count">
      <Card className="card-border">
        <CardBody className="card-body-status-count">
          <div className="status-count-elements">
            {Object.keys(statusBlocks).map((status, index) => (
              <CcmStatusBlock
                key={status}
                status={status}
                index={index}
                count={parseInt(statusBlocks[status], 10)}
              />
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

statusCount.propTypes = {
  statusBlocks: PropTypes.object.isRequired,
};

export default statusCount;
