import dataService from 'services/dataService';
import { API_CREATE_CASE_IN_EHR } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface DataProps {
  patientGuid: string;
  isRpm: boolean;
  subject: string;
  description: string;
}

export const createCaseInEhrFn = async ({
  patientGuid,
  ...postData
}: DataProps) => {
  const { data, error } = await dataService.createOne<{ response: string }>(
    API_CREATE_CASE_IN_EHR(patientGuid),
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  if (data) showResult();

  return data;
};
