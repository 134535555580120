import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Pagination from 'components/molecules/pagination';
import AutoComplete from 'components/molecules/autocompleteFilter/patient';
import forms from 'constants/forms';
import common from 'constants/common';
import BaseSelect from 'components/atoms/baseSelect';

const DEFAULT_FILTER = forms.patientFilterOptions[0];

const Sorting = ({ count, data }) => {
  const filter = DEFAULT_FILTER;
  const FulfillmentOptions = forms.fulfillmentStage.sort((a, b) =>
    a.label > b.label ? 1 : -1,
  );

  const {
    onboardingStatus: { setPatient, setStatus, getPatientsReport },
  } = useDispatch();
  const { patientStatus, clinic, doctor, ITEMS_PER_PAGE } = useSelector(
    (state) => state.onboardingStatus,
  );
  const [page, setPage] = useState(1);
  const onChangePatient = (item) => {
    setPatient(item?.id);
    setPage(1);
  };
  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 12,
    colors: {
      ...theme.colors,
      ...common.primaryColors,
    },
  });

  useEffect(() => {
    getPatientsReport({ page });
  }, [page]);

  const onStatusChange = (value) => {
    setStatus(value?.value);
    setPage(1);
  };
  return (
    <Row>
      <Col xl="2" xs="12" md="4">
        <p>Patient Name</p>
        <AutoComplete
          filter={filter}
          handleClick={onChangePatient}
          clinicGuid={clinic}
          doctorGuid={doctor}
          status={patientStatus}
        />
      </Col>
      <Col xl="2" xs="12" md="4">
        <p>Fulfillment Stage</p>
        <BaseSelect
          options={FulfillmentOptions}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          onChange={onStatusChange}
          isClearable
          defaultValue={FulfillmentOptions[0]}
        />
      </Col>
      <Col xl={{ col: 2, offset: 4 }} xs="12" md="4">
        <Pagination
          title=""
          page={page || 1}
          pageTotal={data.length}
          itemsPerPage={ITEMS_PER_PAGE}
          total={count}
          handleFirstClick={() => setPage(1)}
          handleLastClick={() => setPage(Math.ceil(count / ITEMS_PER_PAGE))}
          handlePrevClick={() => setPage(page - 1)}
          handleNextClick={() => setPage(page + 1)}
          loading={false}
        />
      </Col>
    </Row>
  );
};
Sorting.propTypes = {
  data: PropTypes.array,
  count: PropTypes.number,
};

export default Sorting;
