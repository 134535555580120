import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import enUs from 'date-fns/locale/en-US';
import 'react-datepicker/dist/react-datepicker.css';
import { parseDateSafe } from 'utils/timeHelper';

registerLocale('en-Us', enUs);

const CustomDatePickerWithTime = ({ onSelect, inDate, timeCaption }) => {
  const parsedDate = parseDateSafe(inDate);
  const [startDate, setStartDate] = useState(parsedDate || new Date());

  const years = Array(new Date().getFullYear() + 10 - 1800)
    .fill()
    .map((d, i) => i + 1800)
    .reverse();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <div className="range-datepicker">
      <div className="range-datepicker--datepicker">
        <DatePicker
          locale="en-Us"
          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            // onSelect(date);
          }}
          inline
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <div className="range-datepicker-select">
              <select
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) => {
                  changeMonth(months.indexOf(value));
                }}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <select
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
          timeCaption={timeCaption || 'Time'}
          showTimeSelect
          shouldCloseOnSelect={false}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            className="simple-small-button"
            onClick={() => {
              onSelect(startDate);
            }}
            type="button"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

CustomDatePickerWithTime.propTypes = {
  onSelect: PropTypes.func,
  inDate: PropTypes.string,
  timeCaption: PropTypes.string,
};

export default CustomDatePickerWithTime;
