import React from 'react';
import PropTypes from 'prop-types';
import { getActivityStatus } from 'utils';

const ActivityStatus = ({ status }) => (
  <div className={`activity-status activity-status-${status}`}>
    {getActivityStatus(status)}
  </div>
);

ActivityStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ActivityStatus;
