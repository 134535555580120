import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseTableNew from 'components/atoms/base-table/new';
import useUpdateEffect from 'infrastructure/hooks/utils/use-update-effect';
import { useSmsActivityLog } from 'infrastructure/hooks/logs/use-sms-activity-log';

import { smsActivityLogColumns } from './cols';
import s from './styles.module.scss';

import type { DateRange } from 'infrastructure/types';
import type { ModuleType } from 'infrastructure/enums';

interface IPatientSmsActivityLogProps {
  patientGuid: string;
  module: ModuleType;
  dateRange?: DateRange;
}

const PatientSmsActivityLog: React.FC<IPatientSmsActivityLogProps> = (
  props,
) => {
  const { patientGuid, module, dateRange } = props;

  const { t } = useTranslation();

  const { loading, items, meta, setMeta } = useSmsActivityLog({
    patientGuid,
    meta: {
      dateRange,
    },
  });

  const onChangePage = (page: number) => {
    setMeta({
      ...meta,
      page,
      dateRange,
      module,
    });
  };

  useUpdateEffect(() => {
    setMeta({
      ...meta,
      page: 1,
      dateRange,
    });
  }, [dateRange?.at(0), dateRange?.at(1)]);

  return (
    <div className={s.wrapper}>
      <h3 className={s.title}>{t('labels.smsActivityLogged')}</h3>
      <BaseTableNew
        dataCy="sms-activity-log-table"
        rowKey="key"
        columns={smsActivityLogColumns()}
        items={items}
        loading={loading}
        pagination={{
          current: meta.page,
          pageSize: meta.items,
          total: meta.totalCount,
        }}
        onChangePage={onChangePage}
        bordered
      />
    </div>
  );
};

export default React.memo(PatientSmsActivityLog);
