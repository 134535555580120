import PatientControlButton from 'components/atoms/patentControlButton';
import forms from 'constants/forms';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import GeneralCommentModal from 'components/templates/rpm/patients/patient/generalComments/modal';
import SmsModal from 'components/templates/rpm/patients/patient/messages/modal';
import { isMobile } from 'utils/deviceHelper';
import SmsModalMobile from 'components/templates/rpm/patients/patient/messages/modalMobile';
import { useAppSelector } from 'store';
import EmailsResubscriptionModal from 'components/molecules/resubscribeToEmails/modal';
import CcmInteractionStatusModal from 'components/molecules/ccmInteractionStatus/modal';
import InternalAdmissionFormModal from 'components/templates/admin/internalAdmissonForm/Modal';
import {
  useGetSmsTemplates,
  useFullPatientInfo,
} from 'infrastructure/hooks/control-panel';
import HoldingStatusModal from 'components/molecules/holding-status-modal';

import s from './styles.module.scss';
import ControlButtonDropdown from '../../atoms/controlPanelDropdownButton/controlButtonDropdown';
import { useStatusActionButtons } from './hooks/useStatusActionButtons';
import { useInteractionActionButtons } from './hooks/useInteractionActionButtons';
import { useProfileActionButtons } from './hooks/useProfileActionButtons';

import type { FC } from 'react';

interface PatientControlPanelProps {
  patientGuid: string;
}

const AdminPatientControlPanel: FC<PatientControlPanelProps> = ({
  patientGuid,
}) => {
  const device = useAppSelector((state) => state.user.deviceType);
  const { smsTemplates, smsTemplatesLoading } = useGetSmsTemplates();

  const {
    statusActionButtons,
    holdingStatusPopup,
    holdingStatus,
    nextHoldingStatus,
    holdingStatusStartTime,
    holdingStatusEndTime,
    consumablesArrivingDate,
    patientInfoLoading,
    holdingStatusLoading,
    setHoldingStatusPopup,
    updateHoldingStatus,
    updateConsumablesDate,
  } = useStatusActionButtons({
    patientGuid,
  });
  const {
    interactionButtons,
    commentsPopupOpen,
    doctorInfoLoading,
    smsPopupOpen,
    isPrimaryNumber,
    isRpmSms,
    smsLoading,
    commentLoading,
    setCommentsPopupOpen,
    sendComment,
    setSmsPopupOpen,
    sendSms,
    rpmCallButtonsAlert,
    ccmCallButtonsAlert,
  } = useInteractionActionButtons({ patientGuid });
  const {
    fullPatientDetails,
    patientFullDetailsLoading,
    fetchFullPatientDetails: refetchFullPatientDetails,
  } = useFullPatientInfo({ patientGuid });

  const {
    profileActionButtons,
    interactionStatus,
    subscriptionStatusLoading,
    openSubscriptionModal,
    openCcmInteractionModal,
    openIafModal,
    setOpenSubscriptionModal,
    updateSubscriptionStatus,
    setOpenCcmInteractionModal,
    setOpenIafModal,
  } = useProfileActionButtons({
    patientGuid,
    onIafFormOpen: refetchFullPatientDetails,
    iafFormLoading: patientFullDetailsLoading,
  });

  const loading = patientInfoLoading || doctorInfoLoading;

  return (
    <div className={s.wrapper} id="control-panel">
      <div className={s.status}>
        <BaseSpinWrapper spinning={loading}>
          <div className={s['status-wrapper']}>
            <h4 className={s.title}>Statuses </h4>
            <div className={s.divider} />
            <div className={s['status-buttons-wrapper']}>
              {statusActionButtons.map((btn) => {
                if (btn.options) {
                  const ToggleButton = (
                    <PatientControlButton
                      key={btn.key}
                      label={btn.label}
                      Icon={btn.Icon}
                      status={btn.status}
                      loading={btn.loading}
                      disabled={btn.disabled}
                    />
                  );
                  return (
                    <ControlButtonDropdown<any>
                      key={btn.key}
                      toggleBtn={ToggleButton}
                      options={btn.options}
                      handleOptionClick={btn.handleClick}
                    />
                  );
                }

                return (
                  <div key={btn.key}>
                    <PatientControlButton
                      key={btn.key}
                      label={btn.label}
                      Icon={btn.Icon}
                      status={btn.status}
                      handleClick={btn.handleClick}
                      loading={btn.loading}
                      disabled={btn.disabled}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </BaseSpinWrapper>
      </div>
      <div className={s.interaction}>
        <BaseSpinWrapper spinning={loading}>
          <div className={s['interaction-wrapper']}>
            <h4 className={s.title}>Interaction</h4>
            <div className={s.divider} />
            <div className={s['interaction-buttons-wrapper']}>
              {interactionButtons.map((btn) => {
                if (btn.options) {
                  const ToggleButton = (
                    <PatientControlButton
                      label={btn.label}
                      Icon={btn.Icon}
                      loading={btn.loading}
                    />
                  );
                  return (
                    <ControlButtonDropdown<any>
                      key={btn.key}
                      toggleBtn={ToggleButton}
                      options={btn.options}
                      handleOptionClick={btn.handleClick}
                    />
                  );
                }

                return (
                  <PatientControlButton
                    key={btn.key}
                    label={btn.label}
                    Icon={btn.Icon}
                    handleClick={btn.handleClick}
                    loading={btn.loading}
                  />
                );
              })}
            </div>
          </div>
        </BaseSpinWrapper>
      </div>
      <div className={s.profile}>
        <BaseSpinWrapper spinning={loading}>
          <div className={s['profile-wrapper']}>
            <h4 className={s.title}>Patient Profile</h4>
            <div className={s.divider} />
            <div className={s['profile-buttons-wrapper']}>
              {profileActionButtons.map((btn) => {
                return (
                  <PatientControlButton
                    key={btn.key}
                    label={btn.label}
                    Icon={btn.Icon}
                    handleClick={btn.handleClick}
                    loading={btn.loading}
                    status={btn.status}
                    disabled={btn.disabled}
                    extraAccent={btn.extraAccent}
                  />
                );
              })}
            </div>
          </div>
        </BaseSpinWrapper>
      </div>
      <GeneralCommentModal
        isOpen={commentsPopupOpen}
        toggle={setCommentsPopupOpen}
        patientGuid={patientGuid}
        loading={commentLoading}
        submit={sendComment}
        isAdminPanel
        interactionStatus={interactionStatus}
      />
      {isMobile(device) && (
        <SmsModalMobile
          isOpen={smsPopupOpen}
          toggle={setSmsPopupOpen}
          patientGuid={patientGuid}
          smsTemplates={smsTemplates ?? []}
          loading={smsTemplatesLoading || smsLoading}
          submit={sendSms}
          isRpmPanel
          isPrimaryNumber={isPrimaryNumber}
        />
      )}
      {!isMobile(device) && (
        <SmsModal
          isOpen={smsPopupOpen}
          toggle={setSmsPopupOpen}
          patientGuid={patientGuid}
          smsTemplates={smsTemplates ?? []}
          loading={smsTemplatesLoading || smsLoading}
          submit={sendSms}
          isRpmPanel={isRpmSms}
          isPrimaryNumber={isPrimaryNumber}
        />
      )}
      <EmailsResubscriptionModal
        setIsOpen={setOpenSubscriptionModal}
        isOpen={openSubscriptionModal}
        loading={subscriptionStatusLoading}
        onSubmit={async (d) => updateSubscriptionStatus(d)}
      />
      <CcmInteractionStatusModal
        isOpen={openCcmInteractionModal}
        toggle={setOpenCcmInteractionModal}
        patientGuid={patientGuid}
      />
      {patientFullDetailsLoading === false &&
        fullPatientDetails?.profile &&
        openIafModal && (
          <InternalAdmissionFormModal
            isOpen={openIafModal}
            patient={fullPatientDetails}
            onToggle={setOpenIafModal}
          />
        )}

      {holdingStatusPopup && (
        <HoldingStatusModal
          isOpen={holdingStatusPopup}
          patientGuid={patientGuid}
          toggle={setHoldingStatusPopup}
          patientHoldingStatus={holdingStatus}
          nextHoldingStatus={nextHoldingStatus}
          holdingStatusStartTime={holdingStatusStartTime}
          holdingStatusEndTime={holdingStatusEndTime}
          updateHoldingStatus={updateHoldingStatus}
          options={forms.patientHoldingStatuses}
          loading={holdingStatusLoading}
          isCcmPage={false}
          consumablesArrivingDate={consumablesArrivingDate}
          updateConsumablesDate={updateConsumablesDate}
        />
      )}
      {rpmCallButtonsAlert}
      {ccmCallButtonsAlert}
    </div>
  );
};
export default AdminPatientControlPanel;
