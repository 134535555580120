import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { showResult } from 'infrastructure/hooks/utils';

import { updatePatientPcmStatusFn } from './api';

import type { IPatient } from 'infrastructure/interfaces';

export const useUpdatePatientPcmStatus = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: updatePatientPcmStatusFn,
    onSuccess(data, { patientGuid, pcmStatus }) {
      const patientInfoKey = getPatientInfoKey(patientGuid);
      queryClient.setQueryData<IPatient>(patientInfoKey, (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            pcmStatus,
          };
        }
      });
      showResult();
    },
  });

  return { updatePcmStatus: mutate, pcmStatusLoading: isLoading };
};
