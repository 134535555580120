import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminTurkUserAdd from 'components/templates/admin/turkUserAdd';

const TurkUserAddPage = () => {
  return (
    <Container>
      <AdminHeader />
      <AdminTurkUserAdd />
    </Container>
  );
};

export default TurkUserAddPage;
