import React, { useRef, useState } from 'react';
import { format } from 'date-fns';
import { cropText } from 'infrastructure/functions/text-crop';

import AttachmentIcons from './attachmentIcons';
import AttachmentForm from './attachmentForm';
import AttachmentDelete from './attachmentDelete';
import common from '../../../constants/common';
import useOutsideClick from '../../../utils/useOutsideClick';

type Props = {
  attachment: {
    createdAt: string;
    note: string;
    fileName: string;
    guid: string;
    url: string;
  };
  patientGuid: string;
  updateAttachment: () => { data: any };
  deleteAttachment: () => { error: any };
  downloadAttachment: () => {
    url: string;
    error: any;
  };
  isEdit: boolean;
};
const AttachmentItem: React.FC<Props> = ({
  attachment,
  patientGuid,
  updateAttachment,
  deleteAttachment,
  downloadAttachment,
  isEdit,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  useOutsideClick(divRef, () => {
    setShowForm(false);
    setShowDelete(false);
  });

  return (
    <div className="attachment-item-container" ref={divRef}>
      <div className="attachment-item">
        <div>
          <span className="attachment-helper-text">
            {attachment.createdAt &&
              format(
                new Date(attachment?.createdAt),
                common.dateFormats.birthDate,
              )}
          </span>
          <p className="attachment-text">{cropText(attachment.fileName, 80)}</p>
          <span className="attachment-helper-text">
            {attachment.note ? cropText(attachment.note, 200) : 'Note'}
          </span>
        </div>
        {isEdit && (
          <div className="attachment-icons-block">
            <AttachmentIcons
              showForm={showForm}
              setShowForm={setShowForm}
              showDelete={showDelete}
              setShowDelete={setShowDelete}
              fileGuid={attachment.guid}
              downloadAttachment={downloadAttachment}
            />
          </div>
        )}
      </div>
      {showForm && (
        <AttachmentForm
          attachmentNote={attachment.note}
          attachmentGuid={attachment.guid}
          patientGuid={patientGuid}
          updateAttachment={updateAttachment}
          setShowForm={setShowForm}
        />
      )}
      {showDelete && (
        <AttachmentDelete
          attachmentGuid={attachment.guid}
          patientGuid={patientGuid}
          deleteAttachment={deleteAttachment}
          setShowDelete={setShowDelete}
        />
      )}
    </div>
  );
};

export default AttachmentItem;
