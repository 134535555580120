/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseInputNumber from 'components/atoms/base-input-number';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseGrid from 'components/atoms/base-grid';
import BaseFormItemGroup from 'components/atoms/base-form/item-group';

import { patientThresholdsValidationSchema } from './validation-schema';
import s from './styles.module.scss';

import type { Thresholds } from 'infrastructure/classes/thresholds';

interface IUnitGroup {
  label: string;
  unit: string;
}

const UnitGroup: React.FC<IUnitGroup> = ({ label, unit }) => {
  return (
    <>
      {label} <span className={s.unit}>{`[${unit}]`}</span>
    </>
  );
};

interface IThresholdsFormProps {
  data: Thresholds;
  hasBloodGlucoseType: boolean;
  hasWeightType: boolean;
  hasSPO2Type: boolean;
  hasBloodPressureType: boolean;
  hasPeakFlowType: boolean;
  hasSleepMatType: boolean;
  showBaselineWeight?: boolean;
  onSubmit?: (data: Thresholds) => void;
  onCancel?: () => void;
}

const ThresholdsForm: React.FC<IThresholdsFormProps> = (props) => {
  const { data, onSubmit, onCancel: onCancelProps } = props;
  const {
    hasBloodGlucoseType,
    hasWeightType,
    hasSPO2Type,
    hasBloodPressureType,
    hasPeakFlowType,
    hasSleepMatType,
    showBaselineWeight = true,
  } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: data,
    onSubmit: async (values) => {
      formik.resetForm({ values });
      if (onSubmit) onSubmit(values);
    },
    validateOnChange: false,
    validationSchema: patientThresholdsValidationSchema(),
  });

  const submitForm = () => {
    formik.submitForm();
  };

  const onCancel = () => {
    if (onCancelProps) onCancelProps();
  };

  useEffect(() => {
    if (data) formik.resetForm({ values: data });
  }, [data]);

  return (
    <BaseForm
      formik={formik}
      plaintext={false}
      readonly={false}
      onSave={submitForm}
      onCancel={onCancel}
      withControls
    >
      <BaseGrid columns={3}>
        {hasBloodPressureType && (
          <BaseFormItemGroup
            label={
              <UnitGroup label={t('labels.systolic')} unit={t('units.mmHg')} />
            }
          >
            <BaseGrid columns={2}>
              <BaseFormItem
                name="minCriticalSystolic"
                label={t('labels.minCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="maxCriticalSystolic"
                label={t('labels.maxCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="minRiskSystolic"
                label={t('labels.minAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="maxRiskSystolic"
                label={t('labels.maxAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
            </BaseGrid>
          </BaseFormItemGroup>
        )}
        {hasBloodPressureType && (
          <BaseFormItemGroup
            label={
              <UnitGroup label={t('labels.diastolic')} unit={t('units.mmHg')} />
            }
          >
            <BaseGrid columns={2}>
              <BaseFormItem
                name="minCriticalDiastolic"
                label={t('labels.minCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="maxCriticalDiastolic"
                label={t('labels.maxCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="minRiskDiastolic"
                label={t('labels.minAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="maxRiskDiastolic"
                label={t('labels.maxAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
            </BaseGrid>
          </BaseFormItemGroup>
        )}
        {hasWeightType && (
          <BaseFormItemGroup
            label={
              <UnitGroup label={t('labels.weight')} unit={t('units.lbs')} />
            }
          >
            <BaseGrid columns={2}>
              <BaseFormItem
                name="maxCriticalWeight"
                label={t('labels.maxCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="maxRiskWeight"
                label={t('labels.maxAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              {showBaselineWeight && (
                <BaseFormItem
                  name="baseLineWeight"
                  label={t('labels.baseline')}
                  isBlackLabel
                >
                  <BaseInputNumber />
                </BaseFormItem>
              )}
              <span />
            </BaseGrid>
          </BaseFormItemGroup>
        )}
        {(hasBloodPressureType || hasSPO2Type) && (
          <BaseFormItemGroup
            label={
              <UnitGroup label={t('labels.pulse')} unit={t('units.BPM')} />
            }
          >
            <BaseGrid columns={2}>
              <BaseFormItem
                name="minCriticalPulse"
                label={t('labels.minCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="maxCriticalPulse"
                label={t('labels.maxCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="minRiskPulse"
                label={t('labels.minAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="maxRiskPulse"
                label={t('labels.maxAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
            </BaseGrid>
          </BaseFormItemGroup>
        )}
        {hasSleepMatType && (
          <BaseFormItemGroup
            label={
              <UnitGroup
                label={t('labels.restingPulse')}
                unit={t('units.BPM')}
              />
            }
          >
            <BaseGrid columns={2}>
              <BaseFormItem
                name="maxCriticalSleepPulse"
                label={t('labels.maxCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="minCriticalSleepPulse"
                label={t('labels.minCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="maxRiskSleepPulse"
                label={t('labels.maxAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="minRiskSleepPulse"
                label={t('labels.minAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
            </BaseGrid>
          </BaseFormItemGroup>
        )}
        {hasBloodGlucoseType && (
          <BaseFormItemGroup
            label={
              <UnitGroup label={t('labels.glucose')} unit={t('units.mgdL')} />
            }
          >
            <BaseGrid columns={2}>
              <BaseFormItem
                name="maxCriticalGlucose"
                label={t('labels.maxCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="minCriticalGlucose"
                label={t('labels.minCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="maxRiskGlucose"
                label={t('labels.maxAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="minRiskGlucose"
                label={t('labels.minAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
            </BaseGrid>
          </BaseFormItemGroup>
        )}
        {hasPeakFlowType && (
          <BaseFormItemGroup
            label={<UnitGroup label={t('labels.pef')} unit={t('units.LMin')} />}
          >
            <BaseGrid columns={2}>
              <BaseFormItem
                name="minCriticalPef"
                label={t('labels.minCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="minRiskPef"
                label={t('labels.minAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
            </BaseGrid>
          </BaseFormItemGroup>
        )}
        {hasPeakFlowType && (
          <BaseFormItemGroup
            label={<UnitGroup label={t('labels.fev')} unit={t('units.L')} />}
          >
            <BaseGrid columns={2}>
              <BaseFormItem
                name="minCriticalFev1"
                label={t('labels.minCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
            </BaseGrid>
          </BaseFormItemGroup>
        )}
        {hasSPO2Type && (
          <BaseFormItemGroup
            label={<UnitGroup label={t('labels.spo2')} unit="%" />}
          >
            <BaseGrid columns={2}>
              <BaseFormItem
                name="criticalBloodoxygen"
                label={t('labels.minCritical')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
              <BaseFormItem
                name="riskBloodoxygen"
                label={t('labels.minAtRisk')}
                isBlackLabel
              >
                <BaseInputNumber />
              </BaseFormItem>
            </BaseGrid>
          </BaseFormItemGroup>
        )}
      </BaseGrid>
    </BaseForm>
  );
};

export default ThresholdsForm;
