import { useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { uniqBy } from 'lodash';

import type { DebounceSelectProps, Option } from '.';

const ITEMS_PER_PAGE = 50;

interface Props {
  fetchOptions: DebounceSelectProps['fetchOptions'];
  debounceTimeout: DebounceSelectProps['debounceTimeout'];
  defaultOptions?: DebounceSelectProps['defaultOptions'];
}

export const useHooks = ({
  fetchOptions,
  debounceTimeout,
  defaultOptions = [],
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<Option[]>(defaultOptions);
  const fetchIdRef = useRef(0);
  const pageRef = useRef(1);
  const totalPageRef = useRef(1);

  const loadOptions = async (value: string) => {
    fetchIdRef.current += 1;
    const fetchId = fetchIdRef.current;
    setFetching(true);
    const response = await fetchOptions({
      search: value,
      page: pageRef.current,
      items: ITEMS_PER_PAGE,
    });
    const isOutdated = fetchId !== fetchIdRef.current;
    if (!response || isOutdated) {
      return;
    }
    const { data, count } = response;
    totalPageRef.current = Math.ceil(count / ITEMS_PER_PAGE);
    setFetching(false);
    return [...defaultOptions, ...data];
  };

  const loadNextPage = async () => {
    pageRef.current += 1;
    const data = await loadOptions(searchValue);
    if (data) {
      setOptions((prev) =>
        uniqBy([...prev, ...data], (item) => `${item.label}-${item.value}`),
      );
    }
  };

  const debounceFetcher = useMemo(() => {
    return debounce(async (e) => {
      const newOptions = await loadOptions(e);
      if (newOptions) {
        setOptions(uniqBy(newOptions, (item) => `${item.label}-${item.value}`));
      }
    }, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const onScroll = (event: any) => {
    const { target } = event;
    const scrolled = Math.ceil(target.scrollTop + target.offsetHeight);
    const inTheBottom = scrolled === target.scrollHeight;
    const allItemsFetched = totalPageRef.current <= pageRef.current;

    if (inTheBottom && !fetching && !allItemsFetched) {
      loadNextPage();
    }
  };
  const onSearch = (value: string) => {
    pageRef.current = 1;
    setSearchValue(value);
    setOptions([]);
    debounceFetcher(value);
  };

  return { onScroll, onSearch, options, fetching };
};
