import i18next from 'i18next';
import { NumberTypes } from 'infrastructure/enums';

export interface INumberTypeItem {
  id: NumberTypes;
  name: string;
}

export const numberTypesDataSource = (): INumberTypeItem[] => {
  return [
    {
      id: NumberTypes.Mobile,
      name: i18next.t('numberTypes.mobile'),
    },
    {
      id: NumberTypes.Landline,
      name: i18next.t('numberTypes.landline'),
    },
    {
      id: NumberTypes.FixedVoip,
      name: i18next.t('numberTypes.fixedVoip'),
    },
    {
      id: NumberTypes.NonFixedVoip,
      name: i18next.t('numberTypes.nonFixedVoip'),
    },
    {
      id: NumberTypes.Personal,
      name: i18next.t('numberTypes.personal'),
    },
    {
      id: NumberTypes.TollFree,
      name: i18next.t('numberTypes.tollFree'),
    },
    {
      id: NumberTypes.Premium,
      name: i18next.t('numberTypes.premium'),
    },
    {
      id: NumberTypes.SharedCost,
      name: i18next.t('numberTypes.sharedCost'),
    },
    {
      id: NumberTypes.Uan,
      name: i18next.t('numberTypes.uan'),
    },
    {
      id: NumberTypes.Voicemail,
      name: i18next.t('numberTypes.voicemail'),
    },
    {
      id: NumberTypes.Pager,
      name: i18next.t('numberTypes.pager'),
    },
    {
      id: NumberTypes.Unknown,
      name: i18next.t('numberTypes.unknown'),
    },
  ];
};
