import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openInNewTab } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';
import PropTypes from 'prop-types';
import C from 'classnames';
import format from 'date-fns/format';
import common from 'constants/common';
import ConnectLogo from 'components/atoms/icons/connectLogoIcon';
import DownloadIcon from 'components/atoms/icons/downloadIcon';
import AttemptedCallIcon from 'components/atoms/icons/missedCallIcon';

const CallComment = ({ comment, patientGuid }) => {
  const {
    comments: { downloadCommentAudio },
  } = useDispatch();

  const isAudioLoading = useSelector(
    (state) => state.loading.effects.comments.downloadCommentAudio,
  );

  const downloadAudio = async (callId) => {
    const { error, data } = await downloadCommentAudio({ patientGuid, callId });
    if (error) return showResult(error);
    if (data && data.url) return openInNewTab(data.url);
  };

  const { createdAt, hidden, duration } = comment;
  return (
    <div className="activity-comments-item activity-comments-item-call">
      <div className="activity-comments-item-call-comment">
        {comment.callComments && comment.callComments.length > 0 && (
          <div className="activity-comments-item-call-comment-messages">
            <strong>Call comments</strong>
            {comment.callComments.map((callComment, index) => (
              <Fragment key={`${callComment.callId}${index}`}>
                <div>{callComment.message}</div>
                <div>
                  <strong>
                    Electronically Signed By: {callComment.createdByTitle}{' '}
                    {callComment.createdByFirstName}{' '}
                    {callComment.createdByLastName}{' '}
                    {callComment.createdByProfessionType ? ' - ' : ' '}
                    {callComment.createdByProfessionType}
                  </strong>
                </div>
              </Fragment>
            ))}
          </div>
        )}
        <>
          <p>Audio</p>
          {!hidden && !duration && (
            <>
              <span>
                <AttemptedCallIcon />
              </span>
              <span>Attempted Call</span>
              <span>
                Call By: {comment.createdByTitle} {comment.createdByFirstName}{' '}
                {comment.createdByLastName}
                {comment.createdByProfessionType ? ' - ' : ' '}
                {comment.createdByProfessionType}
              </span>
            </>
          )}
          {duration !== 0 && duration && !hidden && (
            <>
              <span
                className={C('comment-audio-download', {
                  disabled: isAudioLoading,
                })}
                onClick={() => downloadAudio(comment.message)}
                role="button"
                tabIndex={0}
              >
                <DownloadIcon /> {`${comment.message}.mp3`}
              </span>
              <span>
                {`Call By: ${comment.createdByFirstName} ${comment.createdByLastName}`}
              </span>
            </>
          )}
          {hidden && (
            <>
              <span>Waiting for call details</span>
              <span>
                {`Call By: ${comment.createdByFirstName} ${comment.createdByLastName}`}
              </span>
            </>
          )}
        </>
      </div>
      <div className="activity-comments-item-call-info">
        <ConnectLogo />
        <span>
          {createdAt
            ? format(new Date(createdAt), common.dateFormats.dateAndTime)
            : ''}
        </span>
      </div>
    </div>
  );
};
CallComment.propTypes = {
  comment: PropTypes.shape().isRequired,
  patientGuid: PropTypes.string,
};

export default CallComment;
