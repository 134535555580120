import React from 'react';
import { PropTypes } from 'prop-types';

const RemoveIcon = ({ height, width, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.570758 7.94948C0.249963 8.27046 0.321959 8.86339 0.731565 9.27384C1.14117 9.68428 1.73328 9.7568 2.05407 9.43582L4.8175 6.67078L7.94562 9.80011C8.26645 10.1211 8.85913 10.0491 9.26941 9.6393C9.67969 9.22953 9.75221 8.63717 9.43138 8.31622L6.30189 5.18553L9.79874 1.68665C10.1195 1.36567 10.0475 0.772734 9.63793 0.362293C9.22832 -0.0481467 8.63622 -0.120668 8.31542 0.200313L4.81736 3.70041L1.68596 0.567809C1.36514 0.246859 0.772456 0.318858 0.362174 0.728621C-0.0481066 1.13839 -0.120625 1.73075 0.200201 2.0517L3.33296 5.18567L0.570758 7.94948Z"
      fill={color}
    />
  </svg>
);

RemoveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

RemoveIcon.defaultProps = {
  width: '10',
  height: '10',
  color: '#2C2543',
};

export default RemoveIcon;
