import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { useDispatch } from 'react-redux';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { useTranslation } from 'react-i18next';

import { updateInteractionStatusFn } from './api';

import type { IPatient } from 'infrastructure/interfaces';

export const useUpdateInteractionStatus = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    adminPanelPatients: { updatePatientField },
  } = useDispatch();

  const { mutate, isLoading } = useMutation({
    mutationFn: updateInteractionStatusFn,
    onSuccess(data, variables) {
      if (data) {
        updatePatientField({
          field: 'interactionStatus',
          data: data.interactionStatus,
        });
      }

      const patientInfoKey = getPatientInfoKey(variables.patientGuid);
      queryClient.setQueryData<IPatient>(patientInfoKey, (oldData) => {
        if (oldData && data) {
          return {
            ...oldData,
            interactionStatus: data.interactionStatus,
          };
        }
      });
      notificationController.success({
        message: t('responseMessages.interactionStatusUpdate'),
      });
    },
  });

  return {
    updateInteractionStatus: mutate,
    updateInteractionStatusLoading: isLoading,
  };
};
