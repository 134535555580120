import { useTranslation } from 'react-i18next';
import { useState, type FC } from 'react';
import {
  SMS_ACTIVITIES_PER_PAGE,
  useGetSmsActivities,
} from 'infrastructure/hooks/ccm';
import BaseTableNew from 'components/atoms/base-table/new';
import EmptyTablePlaceholder from 'components/atoms/base-empty-table-placeholder';
import EmptyClinicalSmsIcon from 'components/atoms/icons/emptyClinicalSmsIcon';

import s from './styles.module.scss';
import { columns } from './columns';

interface SmsActivityLoggedProps {
  patientGuid: string;
}

const SmsActivityLogged: FC<SmsActivityLoggedProps> = ({ patientGuid }) => {
  const [page, setPage] = useState(1);
  const { smsActivity, isLoading } = useGetSmsActivities({
    patientGuid,
    meta: { items: SMS_ACTIVITIES_PER_PAGE, page },
  });
  const { t } = useTranslation();

  return (
    <div className={s.wrapper}>
      <h3 className={s.title} data-cy="sms-activity-section">
        {t('labels.smsActivityLogged')}
      </h3>
      <BaseTableNew
        items={smsActivity?.items ?? []}
        columns={columns()}
        bordered
        loading={isLoading}
        pagination={{
          current: page,
          pageSize: SMS_ACTIVITIES_PER_PAGE,
          total: smsActivity?.count ?? 0,
          position: ['bottomCenter'],
        }}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
        dataCy="sms-activity-table"
        // eslint-disable-next-line prettier/prettier
        emptyText={(
          <EmptyTablePlaceholder
            icon={<EmptyClinicalSmsIcon />}
            text={t('labels.noSMSActivityLogged')}
          />
          // eslint-disable-next-line prettier/prettier
        )}
      />
    </div>
  );
};
export default SmsActivityLogged;
