import React from 'react';
// import common from 'constants/common';
import { Card, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import type { IBaseComponentProps, IFilters } from 'infrastructure/interfaces';
import type { StatusReportStatistic } from 'infrastructure/classes';

interface ITilesProps extends IBaseComponentProps {
  tiles: StatusReportStatistic;
  filters: IFilters;
  setFilter: (filters: IFilters) => void;
}

const Tiles: React.FC<ITilesProps> = (props) => {
  const { tiles, setFilter, filters } = props;

  const { t } = useTranslation();

  const items = tiles.getStatisticItems();

  return (
    <div className="tiles-wrapper ccm">
      <div className="tiles">
        {items.map(({ key, value, filter }) => (
          <button
            className={classnames('tiles__item-btn', {
              'tiles__item-btn--active': filters.ccmStatuses === filter,
            })}
            data-cy="ccm-status-report__tile-btn"
            key={key}
            onClick={() => {
              if (key !== filters.ccmStatuses) {
                setFilter({
                  ccmStatuses: filter,
                });
                return;
              }
              setFilter({ ccmStatuses: '' });
            }}
          >
            <Card className="card-border">
              <CardBody className="tiles__item card-outer">
                <div
                  className="tiles__item-title"
                  data-cy="ccm-status-report__tile-title"
                >
                  {/* 
                  // @ts-ignore */}
                  {t(`ccmStatuses.${key}`)}
                </div>
                <div
                  className="tiles__item-value"
                  data-cy="ccm-status-report__tile-count"
                >
                  {value}
                </div>
              </CardBody>
            </Card>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tiles;
