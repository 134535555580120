import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Hint, RadialChart } from 'react-vis';
import randomColor from 'randomcolor';

const colors = {};
const getColorByKey = (key) => {
  if (!colors[key]) colors[key] = randomColor({ seed: `${key + key}123` });
  return colors[key];
};
const InsuranceTypeChart = ({ insuranceBlocks }) => {
  const keys = Object.keys(insuranceBlocks);
  const [disabledKeys, setDisabledKeys] = useState([]);
  const [hintState, setHintState] = useState({
    show: false,
    value: {},
  });

  const chartData = useMemo(() => {
    return keys.map((key) => ({
      key,
      angle: parseInt(insuranceBlocks[key].count, 10),
      label: insuranceBlocks[key].provider,
      color: getColorByKey(insuranceBlocks[key].provider),
      value: {
        Status: insuranceBlocks[key].provider,
        Patients: insuranceBlocks[key].count,
      },
    }));
  }, [insuranceBlocks]);
  return (
    <div className="onboarding-chart">
      <RadialChart
        className="radial-chart-with-hover"
        data={chartData.filter((item) => !disabledKeys.includes(item.key))}
        width={220}
        height={220}
        colorType="literal"
        onValueMouseOver={({ value }) => {
          setHintState({ show: true, value });
        }}
        onValueMouseOut={() => {
          setHintState({ show: false });
        }}
      >
        {hintState.show && <Hint value={hintState.value} />}
      </RadialChart>
      <div className="onboarding-chart-legend">
        {keys.map((key) => (
          <div
            key={key}
            className="onboarding-chart-legend-item"
            style={{
              cursor: 'pointer',
              textDecoration: disabledKeys.includes(key)
                ? 'line-through'
                : 'none',
            }}
            onClick={() => {
              if (disabledKeys.includes(key)) {
                setDisabledKeys(disabledKeys.filter((item) => item !== key));
              } else {
                setDisabledKeys([...disabledKeys, key]);
              }
            }}
          >
            <div
              style={{ backgroundColor: chartData[key].color }}
              className="onboarding-chart-legend-item-dot"
            />
            <div className="onboarding-chart-legend-item-title">
              {chartData[key].label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

InsuranceTypeChart.propTypes = {
  insuranceBlocks: PropTypes.array.isRequired,
};

export default InsuranceTypeChart;
