import type { FC } from 'react';

const AppointmentWebcamIcon: FC<{
  width?: number;
  height?: number;
}> = ({ height = 52, width = 52 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 19.5C0 15.9101 2.91015 13 6.5 13H45.5C49.0899 13 52 15.9101 52 19.5V29.25C52 32.8399 49.0899 35.75 45.5 35.75H30.1234C30.592 36.2783 31.1975 36.8043 31.85 37.2937C32.5975 37.8543 33.3537 38.3277 33.9282 38.6628C34.2137 38.8293 34.4503 38.9594 34.6131 39.0466C34.6944 39.0902 34.757 39.1229 34.7979 39.144L34.8424 39.1668L34.8516 39.1715C35.5254 39.5087 35.8796 40.265 35.7065 40.9984C35.5334 41.7318 34.8786 42.25 34.125 42.25H17.875C17.1214 42.25 16.4666 41.7318 16.2935 40.9984C16.1204 40.2651 16.4739 39.5091 17.1475 39.1719L17.1576 39.1668L17.2021 39.144C17.243 39.1229 17.3056 39.0902 17.3869 39.0466C17.5497 38.9594 17.7863 38.8293 18.0718 38.6628C18.6463 38.3277 19.4025 37.8543 20.15 37.2937C20.8025 36.8043 21.408 36.2783 21.8766 35.75H6.5C2.91015 35.75 0 32.8399 0 29.25V19.5ZM6.5 16.25C4.70507 16.25 3.25 17.7051 3.25 19.5V29.25C3.25 31.0449 4.70507 32.5 6.5 32.5H45.5C47.2949 32.5 48.75 31.0449 48.75 29.25V19.5C48.75 17.7051 47.2949 16.25 45.5 16.25H6.5Z"
      fill="#2C2543"
    />
    <path
      d="M26 21.125C24.2051 21.125 22.75 22.5801 22.75 24.375C22.75 26.1699 24.2051 27.625 26 27.625C27.7949 27.625 29.25 26.1699 29.25 24.375C29.25 22.5801 27.7949 21.125 26 21.125ZM19.5 24.375C19.5 20.7851 22.4101 17.875 26 17.875C29.5899 17.875 32.5 20.7851 32.5 24.375C32.5 27.9649 29.5899 30.875 26 30.875C22.4101 30.875 19.5 27.9649 19.5 24.375Z"
      fill="#2C2543"
    />
    <path
      d="M42.25 24.375C42.25 25.2725 41.5225 26 40.625 26C39.7275 26 39 25.2725 39 24.375C39 23.4775 39.7275 22.75 40.625 22.75C41.5225 22.75 42.25 23.4775 42.25 24.375Z"
      fill="#2C2543"
    />
  </svg>
);

export default AppointmentWebcamIcon;
