import React from 'react';

import s from './styles.module.scss';
import BaseButton from '../baseButton';
import PencilIcon from '../icons/pencilIcon';
import TrashIcon from '../icons/trashIcon';
import BaseDropdownButton from '../baseButton/dropdown';
import TreeDotsIcon from '../icons/treeDotsIcon';

import type { IBaseDropdownButtonProps } from '../baseButton/dropdown';

interface IBaseTableActionsProps {
  onEdit?: () => void;
  onDelete?: () => void;
  items?: IBaseDropdownButtonProps['items'];
  dataCy?: string;
  disabled?: boolean;
}

const BaseTableActions: React.FC<IBaseTableActionsProps> = (props) => {
  const {
    onEdit,
    onDelete,
    dataCy = 'base-table-actions',
    items = [],
    disabled = false,
  } = props;
  return (
    <div className={s.actions} data-cy={dataCy}>
      {onEdit && (
        <BaseButton
          type="secondary"
          icon={<PencilIcon />}
          dataCy={`${dataCy}-edit-button`}
          onClick={onEdit}
          disabled={disabled}
        />
      )}
      {onDelete && (
        <BaseButton
          type="secondary"
          icon={<TrashIcon />}
          dataCy={`${dataCy}-delete-button`}
          onClick={onDelete}
          disabled={disabled}
        />
      )}
      {!!items.length && (
        <BaseDropdownButton
          type="secondary"
          icon={<TreeDotsIcon />}
          dataCy={`${dataCy}-dropdown-button`}
          items={items}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default BaseTableActions;
