import QueryParams from 'utils/queryParams';

export const API_TURK_DASHBOARD_PATIENT_DETAILS = (patientGuid: string) => {
  return `core/turk/patients/${patientGuid}`;
};

export const API_TURK_DASHBOARD_SELECT_READINGS = (
  filter: Record<string, any> = {},
) => {
  const stringFilter = QueryParams.stringify(filter, { arrayFormat: 'comma' });
  return `core/turk/readings/select?${stringFilter}`;
};
