import dataService from 'services/dataService';
import { apiPatientHoldingStatus } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface DataProps {
  patientGuid: string;
  holdingStatus: string | null;
  startTime: string | undefined;
  endTime: string | undefined;
}

export const updatePatientHoldingStatusFn = async (postData: DataProps) => {
  const { error, data } = await dataService.createOne(
    apiPatientHoldingStatus(postData.patientGuid),
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
