import i18next from 'i18next';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import dataService from 'services/dataService';
import {
  API_PATIENTS_COMMENTS,
  apiPatientComments,
  apiPatientCommentAudio,
} from 'services/dataService/resources';
import { callCommentWithUndo } from 'infrastructure/functions/call-comment-with-undo';
import { TimerCancelledError } from 'infrastructure/classes/timer-cancelled-error';

const comments = {
  state: {
    page: 1,
    pageStep: 10,
    totalCount: 1,
    data: [],
  },
  reducers: {
    setPage: (state, payload) => {
      const page = payload < 1 ? 1 : payload;
      return {
        ...state,
        page,
      };
    },
    setTotalCount: (state, payload) => ({
      ...state,
      totalCount: payload,
    }),
    setComments: (state, payload) => ({
      ...state,
      data: payload,
    }),
  },
  effects: (dispatch) => ({
    async submit(postData, state) {
      const { undoTimeoutPromise } = callCommentWithUndo(postData);

      try {
        await undoTimeoutPromise;

        const { error, data } = await dataService.createOne(
          API_PATIENTS_COMMENTS,
          postData,
        );
        if (!error) {
          dispatch.comments.setComments(
            [data, ...state.comments.data].slice(0, 10),
          );
          notificationController.success({
            message: i18next.t('labels.commentSubmitted'),
          });
        }
        return { error, data };
      } catch (error) {
        if (error instanceof TimerCancelledError) {
          return {};
        }
        return { error };
      }
    },
    async getComments(patientGuid, state) {
      const { data } = await dataService.getList(
        apiPatientComments(patientGuid, state.comments.page),
      );
      dispatch.comments.setComments(data?.data || []);
      dispatch.comments.setTotalCount(data?.count || 0);
    },
    async downloadCommentAudio({ patientGuid, callId }) {
      const { error, data } = await dataService.getList(
        apiPatientCommentAudio(patientGuid, callId),
      );
      return { error, data };
    },
  }),
};

export default comments;
