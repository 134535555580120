import React from 'react';
import PropTypes from 'prop-types';

const FooterInfoBlock = ({ color, title, data, dataInBlock }) => (
  <div className="sleep-mat--chart__footer--info-block">
    <div
      className="sleep-mat--chart__footer--info-block__block"
      style={{ backgroundColor: color }}
    >
      {dataInBlock}
    </div>

    <div className="sleep-mat--chart__footer--info-block__data_wrapper">
      <span className="sleep-mat--chart__footer--info-block__title">
        {title}
      </span>

      <span className="sleep-mat--chart__footer--info-block__data">{data}</span>
    </div>
  </div>
);

FooterInfoBlock.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataInBlock: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default FooterInfoBlock;
