const whiteSpaceDelimeterKeys = ['goals', 'reading', 'deviceeducation'];

export const formatTemplateValues = (values, lowerCaseChoicesIds) => {
  const formattedValues = {};
  Object.keys(values).forEach((key) => {
    const value = values[key];
    if (Array.isArray(value)) {
      let arrayOfValues = value.map((e) => e?.value || e);
      if (lowerCaseChoicesIds && lowerCaseChoicesIds.includes(key)) {
        arrayOfValues = value.map((e, index) => {
          if (index === 0) {
            return e?.value || e;
          }
          return (e?.value || e).toLowerCase();
        });
      }
      if (key === 'patientcaregiver' && value.length > 1) {
        const defaultValues = value
          .filter((item) => item.type === 'default')
          .map((item) => item.value);
        const indexOfPatient = defaultValues.indexOf('Patient');

        if (indexOfPatient !== -1) {
          defaultValues.splice(indexOfPatient, 1);
          defaultValues.unshift('Patient');
        }
        const otherValue =
          value.find((item) => item.type === 'other')?.value || '';

        formattedValues[key] = `${defaultValues.join(' and ')} ${otherValue}`;
      } else if (key === 'pastapptcontainer' && value.length > 0) {
        const joinedArrayObjectValues = value
          .map((obj) => {
            const allValues = [];
            if (obj.pastapptprovidertype) {
              allValues.push(obj.pastapptprovidertype);
            }
            if (obj.pastapptprovidername) {
              allValues.push(obj.pastapptprovidername);
            }
            if (obj.pastappttime) {
              allValues.push(obj.pastappttime);
            }
            return allValues.join(', ');
          })
          .filter((el) => el.trim() !== '')
          .join(' and ');

        formattedValues.pastapptanwsers = joinedArrayObjectValues;
      } else if (key === 'upcomingapptcontainer' && value.length > 0) {
        const joinedArrayObjectValues = value
          .map((obj) => {
            const allValues = [];
            if (obj.upcomingapptprovidertype) {
              allValues.push(obj.upcomingapptprovidertype);
            }
            if (obj.upcomingapptprovidername) {
              allValues.push(obj.upcomingapptprovidername);
            }
            if (obj.upcomingappttime) {
              allValues.push(obj.upcomingappttime);
            }
            return allValues.join(', ');
          })
          .filter((el) => el.trim() !== '')
          .join(' and ');
        formattedValues.upcomingapptanwsers = joinedArrayObjectValues;
      } else if (whiteSpaceDelimeterKeys.includes(key)) {
        formattedValues[key] = arrayOfValues.join(' ');
      } else {
        formattedValues[key] = arrayOfValues.toString();
      }
    } else {
      formattedValues[key] = value;
    }
  });
  return formattedValues;
};

export const addSpaceAfterMarks = (string) => string.replace(/([,.])/g, '$1 ');
export const addSpaceAfterComma = (string) => string.replace(/([,])/g, '$1 ');
