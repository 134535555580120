import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { convertSecondsToTime } from 'utils/timeHelper';

const ActivityRow = ({ rowItem, date }) => {
  const timeSpented = (type) => {
    let value;
    const time = rowItem.totalActionTime
      ? parseInt(rowItem.totalActionTime)
      : 0;
    switch (type) {
      case 20:
        value = time >= 1200 ? 'Y: ' : 'No: ';
        break;
      case 40:
        value = time >= 2400 ? 'Y: ' : 'No: ';
        break;
      case 60:
        value = time >= 3600 ? 'Y: ' : 'No: ';
        break;
      case 'total':
        value = `${convertSecondsToTime(time)} `;
        break;
      default:
        break;
    }
    value += date;
    return value;
  };
  return (
    <tr className="activity-row">
      <td data-label="Patient Guid">{rowItem.patient.guid}</td>
      <td data-label="Insurance Number">{rowItem.insurances[0]?.number}</td>
      <td data-label="Patient Name">
        <Link to={`/ccm/patient/${rowItem.patient.guid}`}>
          <strong>
            {rowItem.patient.title} {rowItem.patient.firstName}{' '}
            {rowItem.patient.lastName}
          </strong>
        </Link>
      </td>
      <td data-label="DOB">{rowItem.patient.birthDate}</td>
      <td data-label="MRN">{rowItem.mrn}</td>
      <td data-label="ICD-10 Codes">
        <div className="billing-table-icd-code-col">
          {rowItem.icdCodes?.length
            ? rowItem.icdCodes.map(({ code }) => code).join(', ')
            : null}
        </div>
      </td>
      <td data-label="CPT 99490 20 min">{timeSpented(20)}</td>
      <td data-label="CPT 99439 40 min">{timeSpented(40)}</td>
      <td data-label="CPT 99439 60 min">{timeSpented(60)}</td>
      <td data-label="Time Spent">{timeSpented('total')}</td>
    </tr>
  );
};
ActivityRow.propTypes = {
  rowItem: PropTypes.shape().isRequired,
  date: PropTypes.string.isRequired,
};

export default ActivityRow;
