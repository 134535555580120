import dataService from 'services/dataService';
import { API_DOCTORS_BY_CLINIC_AND_AGENCY_DOCTORS_BY_ROLES } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

type DoctorTypes = 'doctor' | 'agencyDoctor';

interface ISetDataProps {
  guid: string;
  id: string;
  value: string;
  label: string;
  type: string;
}

interface IResponseDataProps {
  guid: string;
  fullName: string;
  type: DoctorTypes;
}

interface ILoadDoctorsAndAgencyDoctorsProps {
  patientGuid: string;
  roles: string;
  setData: (options: ISetDataProps) => void;
  setLoading: (param: boolean) => void;
}

export const loadDoctorsAndAgencyDoctorByRoles = ({
  patientGuid,
  roles,
  setData,
  setLoading,
}: ILoadDoctorsAndAgencyDoctorsProps) => {
  const params = new URLSearchParams({ roles });
  setLoading(true);
  dataService
    .getList(
      `${API_DOCTORS_BY_CLINIC_AND_AGENCY_DOCTORS_BY_ROLES(
        patientGuid,
      )}?${params.toString()}`,
    )
    .then(({ data, error }) => {
      if (error) return showResult(error);
      if (data?.items) {
        const options = data.items.map(
          ({ guid, fullName, type }: IResponseDataProps) => ({
            guid,
            id: guid,
            value: guid,
            label: fullName,
            type,
          }),
        );
        setData(options);
      }
    })
    .finally(() => {
      setLoading(false);
    });
};
