import { getActivityThresholdsValues, getLanguageLabelById } from 'utils';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useEffect, useRef, useState, type FC } from 'react';
import PhoneIcon from 'components/atoms/icons/phone.svg?react';
import { capitalize } from 'lodash';
import useUser from 'utils/useUser';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import { useReservePatient } from 'infrastructure/hooks/rpm';
import { useAppDispatch } from 'store';

import s from './styles.module.scss';
import { getReadingInfo, hiddenThresholds } from '../../utils/get-reading-info';
import AssignSwitch from '../assign-switch';

import type { QueryKey } from '@tanstack/react-query';
import type { Nullable } from 'infrastructure/types';
import type { ReadingType } from 'infrastructure/enums';
import type { ReadingStatus } from 'infrastructure/enums/reading-statuses';
import type { IPatientThresholds } from 'infrastructure/interfaces';
import type {
  IActivityPatient,
  IActivityReserved,
  StatusKeys,
  TStatuses,
  Value,
} from 'infrastructure/interfaces/i-rpm-patient-activity';

interface ReadingColumnProps {
  globalStatus: ReadingStatus;
  values: Value;
  statuses: TStatuses;
  thresholds: IPatientThresholds;
  readingType: ReadingType;
  patient: IActivityPatient;
  provider: string;
  reserved: Nullable<IActivityReserved>;
  getActivityQueryKey: QueryKey;
  readingId: string;
  deviceId: string;
  hideCommentsColumn: boolean;
  showCountdown?: boolean;
}

const DashboardReadingColumn: FC<ReadingColumnProps> = ({
  globalStatus,
  statuses,
  thresholds,
  values,
  readingType,
  patient,
  provider,
  reserved,
  getActivityQueryKey,
  readingId,
  deviceId,
  hideCommentsColumn,
  showCountdown,
}) => {
  const { t } = useTranslation();
  const riskItems = getActivityThresholdsValues(
    globalStatus,
    values,
    statuses,
    thresholds,
    readingType,
  );
  const { firstName, lastName, phone, language, guid } = patient;
  const [checked, setChecked] = useState(!!reserved);
  const location = useLocation();
  const paddingTopRef = useRef(16);
  const gapRef = useRef(4);
  const {
    loginGuid,
    isAgencyDoctor,
    isCriticalOrAtRiskAlertingRole,
    isBiller,
    isMobileDevice,
  } = useUser();
  const { reservePatient, isLoading } = useReservePatient({
    getActivityQueryKey,
  });
  const {
    connect: { handleConnectPopoutOpen, connectDetails },
  } = useAppDispatch();

  const holdingStatus = patient.holdingStatus?.status;
  const { action } = queryString.parse(location.search);

  const inVacationOrHospital =
    holdingStatus === 'vacation' || holdingStatus === 'hospital';

  const onClickConnect = () => {
    if (isCriticalOrAtRiskAlertingRole) {
      return;
    }
    const details = {
      phone,
      guid,
      isRpmCall: true,
      readingId,
      deviceId,
    };
    connectDetails(details);
    handleConnectPopoutOpen(true);
  };

  const isAssignedToOther = reserved && reserved.loginGuid !== loginGuid;
  const isAssignedToOtherTitle = isAssignedToOther
    ? `${reserved.title} ${reserved.firstName} ${reserved.lastName} - ${reserved.professionType}`
    : undefined;

  useEffect(() => {
    setChecked(!!reserved);
  }, [!!reserved]);

  const withCountdownHeight = showCountdown ? 220 : 180;
  const atRiskOrAtCriticalHeight = hideCommentsColumn
    ? withCountdownHeight
    : undefined;
  const isAssigned =
    isAgencyDoctor &&
    !isCriticalOrAtRiskAlertingRole &&
    !action &&
    isMobileDevice &&
    isAssignedToOther;

  return (
    <div
      className={classNames(s.reading, { [s.row]: hideCommentsColumn })}
      style={{ height: isMobileDevice ? undefined : atRiskOrAtCriticalHeight }}
    >
      <div
        className={s.readings}
        style={{
          paddingTop: paddingTopRef.current,
          rowGap: gapRef.current,
        }}
      >
        <div data-cy="reading-values" className={s['reading-items']}>
          {Object.keys(values).map((threshold, index, arr) => {
            if (hiddenThresholds.includes(threshold)) {
              return null;
            }

            riskItems.length > 1
              ? (paddingTopRef.current = 16)
              : (paddingTopRef.current = 24);
            riskItems.length <= 1 &&
            arr.filter((i) => !hiddenThresholds.includes(i)).length <= 1
              ? (gapRef.current = 4)
              : (gapRef.current = 10);
            const { className, key, value, unit } = getReadingInfo(
              statuses,
              values,
              threshold as StatusKeys,
            );

            return (
              <p
                className={classNames(s['reading-item'], s[className])}
                key={readingType + index}
              >
                <span>{key === 'spo2' ? 'SpO2' : key} </span>
                <span>{`${value} ${unit}`}</span>
              </p>
            );
          })}
        </div>
        <div data-cy="reading-thresholds" className={s['risk-items']}>
          {riskItems.length > 0 &&
            riskItems.map((riskItem: string) => {
              return (
                <p key={riskItem} className={s['risk-item']}>
                  {riskItem.startsWith('Glucose')
                    ? riskItem.replace('Glucose', '')
                    : riskItem}
                </p>
              );
            })}
        </div>
      </div>
      <div
        data-cy="reading-patient-data"
        className={s.patient}
        style={{ paddingTop: hideCommentsColumn ? 24 : undefined }}
      >
        {!isBiller && inVacationOrHospital && (
          <p
            data-cy="reading-patient-holding-status"
            className={s['holding-status']}
          >
            {capitalize(holdingStatus)}
          </p>
        )}
        <Link
          data-cy="reading-patient-link"
          className={classNames(s.name, {
            [s.disabled]: isCriticalOrAtRiskAlertingRole,
          })}
          to={`/patient/${patient.guid}`}
        >
          {firstName} {lastName}
        </Link>
        <p className={s.lang} data-cy="reading-patient-lang">
          {t('labels.lang')}: {getLanguageLabelById(language)}
        </p>
        <button
          data-cy="reading-patient-phone"
          className={s.phone}
          onClick={onClickConnect}
          disabled={patient.isOnCall}
          title={
            patient.isOnCall ? t('labels.onCall') : t('labels.clickToCall')
          }
        >
          <PhoneIcon className={s.icon} />
          {phone}
        </button>
        <p className={s.provider} data-cy="reading-patient-provider">
          <span>{t('labels.provider')}:</span>
          <span data-cy="reading-patient-provider-name">{provider}</span>
        </p>
        {isAssigned && (
          <p className={s.provider}>
            <span>{t('labels.assigned')}:</span>
            <span>{isAssignedToOtherTitle}</span>
          </p>
        )}
        {isAgencyDoctor && !isCriticalOrAtRiskAlertingRole && !action && (
          <AssignSwitch
            checked={checked}
            isLoading={isLoading}
            loginGuid={loginGuid}
            reserved={reserved}
            onChange={() => {
              setChecked((prev) => !prev);
              reservePatient(patient.guid);
            }}
          />
        )}
      </div>
    </div>
  );
};
export default DashboardReadingColumn;
