import Title from './title';
import s from './styles.module.scss';
import DownloadButton from './downloadButton';

import type { FC } from 'react';

interface Props {
  sendReportIsLoading: boolean;
  sendReportToEmail: () => void;
}

const InteractiveTimeReportHeader: FC<Props> = ({
  sendReportIsLoading,
  sendReportToEmail,
}) => {
  return (
    <div className={s.wrapper}>
      <Title />
      <DownloadButton
        sendReportIsLoading={sendReportIsLoading}
        sendReportToEmail={sendReportToEmail}
      />
    </div>
  );
};

export default InteractiveTimeReportHeader;
