import type { SVGProps } from 'react';

const CartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.5 2.5C0.5 2.22386 0.723858 2 1 2H2.5C2.72943 2 2.92943 2.15615 2.98507 2.37873L3.39039 4H15C15.154 4 15.2993 4.07094 15.3941 4.19229C15.4889 4.31365 15.5224 4.4719 15.4851 4.62127L13.9851 10.6213C13.9294 10.8439 13.7294 11 13.5 11H4.5C4.27057 11 4.07057 10.8439 4.01493 10.6213L2.10961 3H1C0.723858 3 0.5 2.77614 0.5 2.5ZM3.64039 5L4.89039 10H13.1096L14.3596 5H3.64039ZM5.5 13C4.94772 13 4.5 13.4477 4.5 14C4.5 14.5523 4.94772 15 5.5 15C6.05228 15 6.5 14.5523 6.5 14C6.5 13.4477 6.05228 13 5.5 13ZM3.5 14C3.5 12.8954 4.39543 12 5.5 12C6.60457 12 7.5 12.8954 7.5 14C7.5 15.1046 6.60457 16 5.5 16C4.39543 16 3.5 15.1046 3.5 14ZM12.5 13C11.9477 13 11.5 13.4477 11.5 14C11.5 14.5523 11.9477 15 12.5 15C13.0523 15 13.5 14.5523 13.5 14C13.5 13.4477 13.0523 13 12.5 13ZM10.5 14C10.5 12.8954 11.3954 12 12.5 12C13.6046 12 14.5 12.8954 14.5 14C14.5 15.1046 13.6046 16 12.5 16C11.3954 16 10.5 15.1046 10.5 14Z"
      fill="#2C2543"
    />
  </svg>
);

export default CartIcon;
