import { Link } from 'react-router-dom';
import ModalPopup from 'components/organisms/modal';
import UploadCsv from 'components/organisms/csvUpload';

import type { FC } from 'react';

interface Props {
  isOpen: boolean;
  toggle: () => any;
  uploadCsvFile: (file: File) => Promise<{ error?: any }>;
  sampleFileUrl: string;
  title: string;
}

const CsvUploadModal: FC<Props> = ({
  isOpen,
  toggle,
  uploadCsvFile,
  sampleFileUrl,
  title,
}) => (
  <ModalPopup
    isOpen={isOpen}
    toggle={toggle}
    title={title}
    className="popup upload-csv-popup"
  >
    <div className="upload-csv-modal-body">
      <div className="download-sample">
        <Link to={sampleFileUrl} target="_blank" download>
          download sample
        </Link>
      </div>
      <UploadCsv uploadFile={(file) => uploadCsvFile(file)} />
      <div className="required-info">
        <b>Requirements</b>
        <ul>
          <li>File should be in csv format.</li>
          <li>File size should be less than 10MB.</li>
          <li>
            File template must be the same as in example, which can be
            downloaded.
          </li>
        </ul>
      </div>
    </div>
  </ModalPopup>
);

export default CsvUploadModal;
