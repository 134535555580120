import EligibilityDownload from 'components/templates/eligibility/items/download';
import EligibilityItemsTable from 'components/templates/eligibility/items/table';
import { useEligibilityProcessesFilters } from 'infrastructure/hooks/eligibilityTool/useFilters';
import { useEligibilityItemsData } from 'infrastructure/hooks/eligibilityTool/useItemsData';
import { useParams } from 'react-router-dom';

const EligibilityItemsPage = () => {
  const { processGuid } = useParams<{ processGuid: string }>();

  const { changePage, filters } = useEligibilityProcessesFilters();
  const { data, isLoading } = useEligibilityItemsData({
    page: filters.page,
    items: filters.items,
    processGuid,
  });

  return (
    <div>
      <EligibilityDownload
        processGuid={processGuid}
        eligibilityProcess={data?.eligibilityProcess}
      />
      <EligibilityItemsTable
        changePage={changePage}
        filters={filters}
        data={data?.data}
        count={data?.count}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EligibilityItemsPage;
