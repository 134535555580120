import ControlPanelSuccessIcon from 'components/atoms/icons/controlPanelSuccessIcon';
import CrossControlIcon from 'components/atoms/icons/crossControlIcon';
import HoldingControlStatusIcon from 'components/atoms/icons/holdingControlStatusIcon';
import forms from 'constants/forms';
import { useMemo, useState } from 'react';
import { makeid } from 'utils/helpers/makeid';
import useUser from 'utils/useUser';
import {
  WeekMealStatuses,
  type CcmStatuses,
  type RpmStatuses,
} from 'infrastructure/enums';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import {
  capitalize,
  getPatientCcmStatus,
  getPatientPcmStatus,
  getPatientStatus,
} from 'utils';
import { parseDateSafe } from 'utils/timeHelper';
import { differenceInCalendarDays } from 'date-fns';
import { isAdmin, isBiller, isNutritionist } from 'utils/userTypeHelper';
import { usePatientDevices } from 'infrastructure/hooks/device/use-patient-devices';
import {
  useUpdatePatientHoldingStatus,
  useDoNotCall,
  useUpdatePatientCcmStatus,
  useUpdatePatientStatus,
  useUpdatePatientPcmStatus,
  useUpdatePatientNutritionAccount,
  useUpdateConsumablesDate,
} from 'infrastructure/hooks/control-panel';

interface Props {
  patientGuid: string;
}

export const useStatusActionButtons = ({ patientGuid }: Props) => {
  const [holdingStatusPopup, setHoldingStatusPopup] = useState(false);
  const { roleNames, userType, iamPatientAcceptance } = useUser();
  const { updateStatus, statusLoading } = useUpdatePatientStatus();
  const { updateCcmStatus, ccmStatusLoading } = useUpdatePatientCcmStatus();
  const { updatePcmStatus, pcmStatusLoading } = useUpdatePatientPcmStatus();
  const { updateHoldingStatus, holdingStatusLoading } =
    useUpdatePatientHoldingStatus({
      onSuccess() {
        setHoldingStatusPopup(false);
      },
    });

  const { updateConsumablesDate, consumablesDateLoading } =
    useUpdateConsumablesDate({
      onSuccess() {
        setHoldingStatusPopup(false);
      },
    });

  const { refetch: refetchDevices } = usePatientDevices({
    patientGuid,
    initialLoading: false,
  });

  const { updateNutritionAccount, nutritionAccountLoading } =
    useUpdatePatientNutritionAccount();
  const { updateDoNotCall, doNotCallLoading } = useDoNotCall();
  const { patientInfo, loading } = usePatientInfo({ patientGuid });

  const ccmStatusOptions = useMemo(() => [...forms.ccmStatusOptions], []);
  const currentRpmStatus = getPatientStatus(patientInfo?.status)?.label ?? '';
  const currentCcmStatus =
    getPatientCcmStatus(patientInfo?.ccmStatus)?.label ?? '';
  const currentPcmStatus = getPatientPcmStatus(patientInfo?.pcmStatus) ?? '';
  const today = new Date();
  const holdingStatusStartTime = parseDateSafe(
    patientInfo?.holdingStatus?.startTime,
  );
  const holdingStatusEndTime = parseDateSafe(
    patientInfo?.holdingStatus?.endTime,
  );
  const remainingDays =
    holdingStatusEndTime &&
    differenceInCalendarDays(holdingStatusEndTime, today);

  const currentDoNotCall = patientInfo?.doNotCall;
  const currentHoldingStatus =
    patientInfo?.holdingStatus?.status !== 'active' &&
    typeof patientInfo?.holdingStatus?.endTime !== 'undefined' &&
    typeof remainingDays !== 'undefined'
      ? `${remainingDays + 1} Day${remainingDays > 1 ? 's' : ''} ${capitalize(
          patientInfo?.holdingStatus.status,
        )}`
      : '';
  const currentNutritionStatus =
    patientInfo?.weekMealStatus === WeekMealStatuses.NeverEnrolled
      ? WeekMealStatuses.Inactive
      : patientInfo?.weekMealStatus;

  const consumablesArrivingDate = patientInfo?.consumablesArrivingDate;
  const consumablesOrdered = !!consumablesArrivingDate;

  const statusActionButtons = [
    {
      key: makeid(5),
      label: 'RPM Status',
      status: currentRpmStatus,
      Icon: ControlPanelSuccessIcon,
      loading: statusLoading,
      options: forms.patientStatuses,
      handleClick: async (value: RpmStatuses) => {
        await updateStatus({
          patientGuid,
          status: value,
        });
        await refetchDevices();
      },
      render: true,
    },
    {
      key: makeid(5),
      label: 'CCM Status',
      Icon: ControlPanelSuccessIcon,
      status: currentCcmStatus,
      loading: ccmStatusLoading,
      options: ccmStatusOptions,
      handleClick: (value: CcmStatuses) => {
        updateCcmStatus({
          patientGuid,
          dischargeStatus: value,
        });
      },
      render: true,
    },
    {
      key: makeid(5),
      label: 'PCM Status',
      Icon: ControlPanelSuccessIcon,
      status: currentPcmStatus,
      loading: pcmStatusLoading,
      options: forms.pcmStatuses,
      handleClick: (value: boolean) => {
        updatePcmStatus({ patientGuid, pcmStatus: value });
      },
      render: true,
      disabled: iamPatientAcceptance,
    },
    {
      key: makeid(5),
      label: 'Holding Status',
      Icon: HoldingControlStatusIcon,
      status: currentHoldingStatus,
      loading: holdingStatusLoading,
      handleClick: () => {
        setHoldingStatusPopup(true);
      },
      render: !isBiller(roleNames),
      disabled: iamPatientAcceptance,
    },
    {
      key: makeid(5),
      label: 'Nutrition Account',
      Icon:
        patientInfo?.weekMealStatus === WeekMealStatuses.Active
          ? ControlPanelSuccessIcon
          : CrossControlIcon,
      status: capitalize(currentNutritionStatus),
      loading: nutritionAccountLoading,
      handleClick: () => {
        updateNutritionAccount({ patientGuid });
      },
      render: isNutritionist(roleNames) || isAdmin(userType),
    },
    {
      key: makeid(5),
      label: 'Toggle "Do Not Call" Flag',
      Icon: currentDoNotCall ? ControlPanelSuccessIcon : CrossControlIcon,
      status: currentDoNotCall ? 'Yes' : 'No',
      loading: doNotCallLoading,
      handleClick: () => {
        updateDoNotCall({
          patientGuid,
          doNotCall: !currentDoNotCall,
        });
      },
      render: true,
      disabled: iamPatientAcceptance,
    },
  ].filter(({ render }) => render);

  return {
    statusActionButtons,
    holdingStatusPopup,
    holdingStatusLoading,
    holdingStatus: patientInfo?.holdingStatus?.status ?? null,
    nextHoldingStatus: patientInfo?.holdingStatus?.nextStatus ?? null,
    holdingStatusStartTime,
    holdingStatusEndTime,
    consumablesArrivingDate,
    consumablesOrdered,
    patientInfoLoading: loading,
    consumablesDateLoading,
    setHoldingStatusPopup,
    updateHoldingStatus,
    updateConsumablesDate,
  };
};
