import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import EvelynIcon from 'components/atoms/icons/evelyn';

import s from './styles.module.scss';

import type { CallComment } from 'infrastructure/classes/comments/call-comment';
import type { Comment } from 'infrastructure/classes/comments/comment';

interface IGeneralCommentsListMessageItemProps {
  data: Comment | CallComment;
}

const GeneralCommentsListMessageItem: React.FC<
  IGeneralCommentsListMessageItemProps
> = (props) => {
  const { data } = props;

  const { t } = useTranslation();

  const itemClassNames = classNames(s.item, s.message, {
    [s.mock]: data.createdBy === 'mock',
  });

  return (
    <div className={itemClassNames} data-cy="general-comment-item">
      <div className={s.head} data-cy="general-comment-header">
        <p className={s.title}>{t(`labels.comment`)}</p>
        <EvelynIcon />
      </div>

      <p className={s.text} data-cy="general-comment-text">
        {data.message}
      </p>

      <p className={s.footer} data-cy="general-comment-footer">
        <span>
          {`${t('labels.electronicallySignedBy')}: ${data.createdFullName}`}
        </span>
        <span>
          {DateFormatter({
            date: data.createdAt,
            format: DateFormats['MM-dd-yyyy | h:mm a'],
          })}
        </span>
      </p>
    </div>
  );
};

export default GeneralCommentsListMessageItem;
