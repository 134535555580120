import { useTranslation } from 'react-i18next';

import s from './style.module.scss';

import type { TableProps } from 'antd';
import type { EligibilityItem } from 'infrastructure/hooks/eligibilityTool/types';

export const useColumns = (): TableProps<EligibilityItem>['columns'] => {
  const { t } = useTranslation();

  const getEligibilityClassName = (eligible: EligibilityItem['eligible']) => {
    if (eligible === 'Unknown (error?)' || eligible === 'Not Approved') {
      return s['red-background'];
    }
    if (eligible === 'Approved') {
      return s['green-background'];
    }
    return undefined;
  };

  return [
    {
      title: t('labels.mrn'),
      render: (_, record) => <span>{record.mrn}</span>,
    },
    {
      title: t('labels.name'),
      render: (_, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: t('labels.dob'),
      render: (_, record) => <span>{record.dob}</span>,
    },
    {
      title: t('labels.address'),
      render: (_, record) => <span>{record.address}</span>,
    },
    {
      title: t('labels.city'),
      render: (_, record) => <span>{record.city}</span>,
    },
    {
      title: t('labels.state'),
      render: (_, record) => <span>{record.state}</span>,
    },
    {
      title: t('labels.zip'),
      render: (_, record) => <span>{record.zipCode}</span>,
    },
    {
      title: t('labels.message'),
      render: (_, record) => <span>{record.messageReason || '-'}</span>,
    },
    {
      title: t('labels.eligible'),
      onCell: (record) => {
        return {
          className: getEligibilityClassName(record.eligible),
        };
      },
      render: (_, record) => <span>{record.eligible}</span>,
    },
  ];
};
