import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BaseLoader from 'components/atoms/base-loader';

import AverageSleepTime from './averageSleepTime';
import Header from './header';
import Chart from './chart';
import { getAllDurations } from './utils';
import Footer from './footer';

const SleepMatChart = ({ data, loading }) => {
  const [durations, setDurations] = useState([]);

  useEffect(() => {
    setDurations(getAllDurations(data));
  }, [data]);

  return (
    <>
      {loading ? (
        <BaseLoader className="loader" loading />
      ) : (
        <div className="chart">
          <Header />
          <AverageSleepTime durations={durations} />
          <div className="chart-main">
            <Chart data={data} durations={durations} />
          </div>
          <Footer data={data} durations={durations} />
        </div>
      )}
    </>
  );
};

SleepMatChart.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default SleepMatChart;
