import i18next from 'i18next';
import { ClinicServiceDeliveryModel } from 'infrastructure/enums';

export interface IClinicServiceDeliveryModelItem {
  id: ClinicServiceDeliveryModel;
  name: string;
}

export const clinicServiceDeliveryModelsDataSource =
  (): IClinicServiceDeliveryModelItem[] => {
    return [
      {
        id: ClinicServiceDeliveryModel.SaaS,
        name: i18next.t('clinicServiceDeliveryModels.saaS'),
      },
      {
        id: ClinicServiceDeliveryModel.FullyManaged,
        name: i18next.t('clinicServiceDeliveryModels.fullyManaged'),
      },
    ];
  };
