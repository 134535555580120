import { isObjectValid } from 'infrastructure/functions';
import {
  CONDITIONS_PER_PAGE,
  useAddCondition,
  useEditCondition,
  useGetConditions,
} from 'infrastructure/hooks/ccm';
import { useDeleteCondition } from 'infrastructure/hooks/ccm/patient-goals/use-delete-condition';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { isCcmActionAllowedStatus } from 'utils/userTypeHelper';

import type { Values } from '../admin-panel/forms/patient-condition/validation-schema';
import type { IConditionForUpdate } from 'infrastructure/interfaces';
import type { FormikHelpers } from 'formik';

interface Props {
  patientGuid: string;
}

export const useCondition = ({ patientGuid }: Props) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const { goals, isLoading } = useGetConditions({
    patientGuid,
    meta: { items: CONDITIONS_PER_PAGE, page },
  });
  const [conditionForUpdate, setConditionForUpdate] =
    useState<IConditionForUpdate>();
  const [modifyItemGuid, setModifyItemGuid] = useState<string>();
  const { addCondition, addLoading } = useAddCondition({ page });
  const { editCondition, editLoading } = useEditCondition({ page });
  const { deleteCondition, deleteLoading } = useDeleteCondition({
    page,
    patientGuid,
  });
  const { Alert, showAlert } = useAlert();

  const toggleOpen = () => setOpen((prev) => !prev);

  const onCancelForm = () => {
    setOpen(false);
    setConditionForUpdate(undefined);
  };

  const onSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
    if (!isObjectValid(values)) return;

    const { description, diagnoseGuid } = values;

    if (conditionForUpdate) {
      setModifyItemGuid(conditionForUpdate.guid);
      editCondition(
        {
          description,
          diagnoseGuid,
          guid: conditionForUpdate.guid,
          patientGuid,
        },
        {
          onSuccess: () => {
            formikHelpers.resetForm();
            onCancelForm();
          },
        },
      );

      return;
    }

    addCondition(
      {
        description,
        diagnoseGuid,
        patientGuid,
      },
      {
        onSuccess: () => {
          formikHelpers.resetForm();
          onCancelForm();
        },
      },
    );
  };

  const handleDelete = async (guid: string) => {
    setModifyItemGuid(guid);
    const res = await showAlert({
      title: t('confirm.deleteCondition'),
      message: t('confirm.deleteConditionMessage'),
      messageTitle: t('confirm.areYouSureYouWantDeleteThis', {
        value: t('labels.condition'),
      }),
      type: 'danger',
    });
    if (res) {
      deleteCondition(guid);
      setConditionForUpdate(undefined);
    }
  };

  const { patientInfo } = usePatientInfo({ patientGuid });
  const disableButton = !isCcmActionAllowedStatus(patientInfo?.ccmStatus);

  return {
    open,
    page,
    goals,
    isLoading,
    modifyItemGuid,
    addLoading,
    editLoading,
    deleteLoading,
    Alert,
    conditionForUpdate,
    disableButton,
    setPage,
    setOpen,
    handleDelete,
    toggleOpen,
    onSubmit,
    setConditionForUpdate,
    onCancelForm,
  };
};
