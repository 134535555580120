import useUser from 'utils/useUser';
import { isSlaReadingsAllowed } from 'constants/permissions';
import { useTranslation } from 'react-i18next';

import CrownOutlinedIcon from '../icons/crownOutlinedIcon';
import BaseTag from './BaseTag';

import type { FunctionComponent } from 'react';

type Props = {
  hours: number;
  small?: boolean;
};

const slaTimeMapper: Record<number, string> = {
  15: '15min',
  120: '2h',
  240: '4h',
  480: '8h',
};

const SlaTag: FunctionComponent<Props> = ({ hours, small = false }) => {
  const { userType } = useUser();

  const { t } = useTranslation();

  if (isSlaReadingsAllowed(userType) === false) return null;
  const title = `${t('labels.sla')} ${slaTimeMapper[hours]}`;

  return (
    <BaseTag
      title={title}
      small={small}
      icon={<CrownOutlinedIcon width={14} height={14} />}
      text={title}
      color="gold"
      dataCy="sla-tag"
    />
  );
};

export default SlaTag;
