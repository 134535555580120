import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const CopyOutlineIcon = ({ color = COLORS.BUOYANT, ...props }: IIconProps) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 0.5H6C4.89543 0.5 4 1.39543 4 2.5C2.89543 2.5 2 3.39543 2 4.5V14.5C2 15.6046 2.89543 16.5 4 16.5H11C12.1046 16.5 13 15.6046 13 14.5C14.1046 14.5 15 13.6046 15 12.5V2.5C15 1.39543 14.1046 0.5 13 0.5ZM13 13.5V4.5C13 3.39543 12.1046 2.5 11 2.5H5C5 1.94772 5.44772 1.5 6 1.5H13C13.5523 1.5 14 1.94772 14 2.5V12.5C14 13.0523 13.5523 13.5 13 13.5ZM3 4.5C3 3.94772 3.44772 3.5 4 3.5H11C11.5523 3.5 12 3.94772 12 4.5V14.5C12 15.0523 11.5523 15.5 11 15.5H4C3.44772 15.5 3 15.0523 3 14.5V4.5Z"
      fill={color}
    />
  </svg>
);

export default CopyOutlineIcon;
