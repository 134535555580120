import { useMutation } from '@tanstack/react-query';
import { showResult } from 'infrastructure/hooks/utils';

import { clinicEscalationFn } from './api';

interface Props {
  onSuccess: () => void;
}

export const useClinicEscalation = ({ onSuccess }: Props) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: clinicEscalationFn,
    onSuccess() {
      showResult();
      onSuccess();
    },
  });

  return {
    sendClinicEscalation: mutate,
    sendClinicEscalationLoading: isLoading,
  };
};
