import i18next from 'i18next';
import { AppointmentCompletionMethod } from 'infrastructure/enums';
import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';
import * as Yup from 'yup';

export const addAppointmentCompletionValidationSchema = createValidationSchema({
  completedDate: commonValidationSchemas.date({ required: true }),
  appointmentMethod: Yup.mixed<AppointmentCompletionMethod>()
    .oneOf(Object.values(AppointmentCompletionMethod))
    .required(
      `${i18next.t('validation.field')} ${i18next.t('validation.required')}`,
    ),
  completedBy: commonValidationSchemas.string({ required: true }),
  type: commonValidationSchemas
    .string({ required: true })
    .oneOf(['agencyDoctor', 'doctor']),
});

type TSchema = Yup.InferType<typeof addAppointmentCompletionValidationSchema>;

export type Values = Partial<TSchema>;
