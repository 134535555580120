import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminTurkUserBlock from 'components/templates/admin/turkUser';

const AdminTurkUserInfoPage = () => (
  <Container>
    <AdminHeader />
    <AdminTurkUserBlock />
  </Container>
);

export default AdminTurkUserInfoPage;
