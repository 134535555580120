import common from 'constants/common';
import i18next from 'i18next';
import { AVERAGE_HIT_VALUES } from 'infrastructure/consts/average-units';
import { ThresholdType } from 'infrastructure/enums';
import { isNotUndefined } from 'infrastructure/functions';

import type { IAverage, IPatientThresholds } from 'infrastructure/interfaces';

export type TAverageHits = {
  minRisk?: number;
  maxRisk?: number;
  minCritical?: number;
  maxCritical?: number;
};

export type TAverageStatus = {
  status: 'critical' | 'risk';
  text: string;
};

const getIsInRiskRange = (averageHits: TAverageHits, value: number) => {
  const { minRisk, maxRisk, minCritical, maxCritical } = averageHits;

  const isLessThanMinRiskAndMoreThanMinCritical =
    isNotUndefined(minRisk) &&
    isNotUndefined(minCritical) &&
    value <= minRisk &&
    value > minCritical;
  const isMoreThanMaxRiskAndMoreThanMaxCritical =
    isNotUndefined(maxRisk) &&
    isNotUndefined(maxCritical) &&
    value >= maxRisk &&
    value < maxCritical;

  return (
    isLessThanMinRiskAndMoreThanMinCritical ||
    isMoreThanMaxRiskAndMoreThanMaxCritical
  );
};

const getIsInCriticalRange = (averageHits: TAverageHits, value: number) => {
  const { minCritical, maxCritical } = averageHits;

  const isLesThanMinCritical =
    isNotUndefined(minCritical) && value <= minCritical;
  const isMoreThanMaxCritical =
    isNotUndefined(maxCritical) && value >= maxCritical;

  return isLesThanMinCritical || isMoreThanMaxCritical;
};

export const getAverageStatus = (
  average: IAverage,
  thresholds?: void | IPatientThresholds,
): TAverageStatus | undefined => {
  if (!thresholds || !(average.name in AVERAGE_HIT_VALUES)) return;

  const itemHitKeys = Object.entries(AVERAGE_HIT_VALUES[average.name]);
  const averageHits = Object.entries(thresholds).reduce<TAverageHits>(
    (acc, [thresholdKey, thresholdValue]) => {
      const [hitKey] =
        itemHitKeys.find(([_, hitValue]) => hitValue === thresholdKey) ?? [];

      if (typeof hitKey !== 'undefined') {
        acc[hitKey as keyof TAverageHits] = thresholdValue;
      }

      if (average.name === ThresholdType.Temperature) {
        acc.maxCritical = common.temperatureCriticals.max;
        acc.minCritical = common.temperatureCriticals.min;
      }

      return acc;
    },
    {},
  );

  const parsedValue = parseFloat(average.value);

  if (Number.isNaN(parsedValue) || parsedValue === 0) return;

  const isInRiskRange = getIsInRiskRange(averageHits, parsedValue);
  const isInCriticalRange = getIsInCriticalRange(averageHits, parsedValue);

  if (
    average.name === ThresholdType.FEV1 &&
    isNotUndefined(averageHits.minCritical) &&
    parsedValue <= averageHits.minCritical
  ) {
    return {
      status: 'critical',
      text: `${i18next.t('labels.minCritical')}: ${averageHits.minCritical}`,
    };
  }

  if (
    (average.name === ThresholdType.SpO2 ||
      average.name === ThresholdType.PEF) &&
    isNotUndefined(averageHits.minCritical) &&
    parsedValue <= averageHits.minCritical
  ) {
    return {
      status: 'critical',
      text: `${i18next.t('labels.minCritical')}: ${averageHits.minCritical}`,
    };
  }

  if (
    (average.name === ThresholdType.SpO2 ||
      average.name === ThresholdType.PEF) &&
    isNotUndefined(averageHits.minRisk) &&
    parsedValue >= averageHits.minRisk
  ) {
    return {
      status: 'risk',
      text: `${i18next.t('labels.minAtRisk')}: ${averageHits.minRisk}`,
    };
  }

  if (isInCriticalRange) {
    if (
      isNotUndefined(averageHits.minCritical) &&
      isNotUndefined(averageHits.maxCritical)
    ) {
      return Math.abs(averageHits.minCritical - parsedValue) <
        Math.abs(averageHits.maxCritical - parsedValue)
        ? {
            status: 'critical',
            text: `${i18next.t('labels.minCritical')}: ${
              averageHits.minCritical
            }`,
          }
        : {
            status: 'critical',
            text: `${i18next.t('labels.maxCritical')}: ${
              averageHits.maxCritical
            }`,
          };
    }
  }

  if (isInRiskRange) {
    if (
      isNotUndefined(averageHits.minRisk) &&
      isNotUndefined(averageHits.maxRisk)
    ) {
      return Math.abs(averageHits.minRisk - parsedValue) <
        Math.abs(averageHits.maxRisk - parsedValue)
        ? {
            status: 'risk',
            text: `${i18next.t('labels.minAtRisk')}: ${averageHits.minRisk}`,
          }
        : {
            status: 'risk',
            text: `${i18next.t('labels.maxAtRisk')}: ${averageHits.maxRisk}`,
          };
    }
  }
};
