import dataService from 'services/dataService';
import { API_PATIENTS_NUTRITION_STATUS } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

import type { WeekMealStatuses } from 'infrastructure/enums';

interface DataProps {
  patientGuid: string;
}

interface IResponse {
  guid: string;
  loginGuid: string;
  weekMealAccountId: string;
  weekMealStatus: WeekMealStatuses;
  weekMealToken: string;
}

export const updatePatientNutritionAccount = async ({
  patientGuid,
}: DataProps) => {
  const { error, data } = await dataService.updateOnly<IResponse>(
    API_PATIENTS_NUTRITION_STATUS(patientGuid),
    {},
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
