import type { IAdherenceDevices } from 'infrastructure/interfaces';

export class AdherenceDevices {
  ids: Array<string>;
  manufacturers: Array<string>;

  constructor(data: IAdherenceDevices) {
    this.ids = data.ids;
    this.manufacturers = data.manufacturers;
  }
}
