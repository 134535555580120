import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import debounce from 'lodash/debounce';
import dataService from 'services/dataService';
import List from 'components/atoms/list';
import classNames from 'classnames';
import useOutsideClick from 'utils/useOutsideClick';
import ResetSearchIcon from 'components/atoms/icons/searchResetIcon';

const loadingOptions = [{ id: 'loading', label: 'Loading...' }];
let timer;

const Autocomplete = ({
  filter,
  handleClick,
  showLabel,
  patient,
  inputRef,
}) => {
  const [query, setQuery] = useState('');
  if (inputRef) inputRef.current = { setValue: setQuery };
  const containerRef = useRef();
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useOutsideClick(containerRef, () => {
    setShow(false);
  });

  const generateFilterLabel = (item) =>
    `${item.firstName} ${item.lastName} - ${item.birthDate}`;

  const debouncedSearch = debounce(async (string) => {
    const { data } = await dataService.getList(
      `core/patients?${filter.id}=${string}&patientStatus=calling_queue&patientRequestType=search&items=100`,
    );
    setIsLoading(false);
    if (data) {
      setOptions(
        data.items.map((item) => ({
          id: item.guid,
          value: item.guid,
          label: generateFilterLabel(item),
        })),
      );
    }
  }, 800);

  const handleInputChange = (string) => {
    if (string.target) {
      setQuery(string.target.value);
    }
    setIsLoading(true);
    clearTimeout(timer);
    timer = setTimeout(() => {
      debouncedSearch(string.target ? string.target.value : query);
    }, 500);
  };

  const handleOptionClick = (option) => {
    setQuery(option.label);
    handleInputChange(option.label);
    setShow(false);
    return handleClick(option);
  };

  const handleFocus = () => {
    if (filter.id === 'fullName') {
      setShow(true);
    }
  };

  const autoCompleteClass = () => {
    let className = 'card-border';
    if (showLabel) {
      className = 'card-border card-shadow border-0';
    }
    return className;
  };

  const resetSearch = () => {
    setQuery('');
    handleInputChange('');
    debouncedSearch('');
    handleClick({ value: '' });
  };

  useEffect(() => {
    const run = async () => {
      const { patientGuid } = queryString.parse(location.search);
      if (patientGuid) {
        const { data } = await dataService.getOnly(
          `patients/${patientGuid}/profile`,
        );
        setQuery(generateFilterLabel(data));
      }
    };
    run();
  }, []);

  return (
    <div className="patient-filter-autocomplete" ref={containerRef}>
      <div className="dropdown" role="button" aria-hidden>
        <Card className={autoCompleteClass()}>
          <CardBody
            className={classNames({
              'card-body-sort': !patient,
            })}
          >
            {showLabel && <p>{filter.label}</p>}
            <div className="dropdown-body">
              <input
                className={query !== '' ? 'arrow' : ''}
                placeholder={filter.placeholder}
                type="text"
                onChange={handleInputChange}
                value={query}
                onFocus={handleFocus}
                data-cy="patient-filter-input"
              />
              {query !== '' && <ResetSearchIcon onClick={resetSearch} />}
            </div>
          </CardBody>
        </Card>
      </div>
      {isLoading && show && (
        <div className="patient-filter-dropdown-body">
          <List onChange={() => {}} options={loadingOptions} />
        </div>
      )}
      {!isLoading && options && options.length > 0 && show && (
        <div className="patient-filter-dropdown-body">
          <List
            onChange={handleOptionClick}
            options={options}
            dataCy="patient-filter-patient-search-result"
          />
        </div>
      )}
    </div>
  );
};

Autocomplete.propTypes = {
  filter: PropTypes.shape().isRequired,
  inputRef: PropTypes.shape(),
  handleClick: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  patient: PropTypes.bool,
};

export default Autocomplete;
