import { useTranslation } from 'react-i18next';
import { parseDateSafe } from 'utils/timeHelper';
import { format } from 'date-fns';
import common from 'constants/common';
import { Link } from 'react-router-dom';
import BaseButton from 'components/atoms/baseButton';
import { useEligibilityDownload } from 'infrastructure/hooks/eligibilityTool/useEligibilityDownload';
import { Routes } from 'infrastructure/consts/routes';

import s from './style.module.scss';

import type { TableProps } from 'antd';
import type { EligibilityProcess } from 'infrastructure/hooks/eligibilityTool/types';

export const useColumns = (): TableProps<EligibilityProcess>['columns'] => {
  const { t } = useTranslation();
  const { download, isLoading } = useEligibilityDownload();

  const formatCreatedAt = (createdAt: string) => {
    const createdAtDate = parseDateSafe(createdAt);
    if (!createdAtDate) return null;
    return format(createdAtDate, common.dateFormats.dateAndTime);
  };

  return [
    {
      title: t('labels.filename'),
      width: '60%',
      render: (_, record) => (
        <Link to={Routes.eligibilityToolsItem(record.guid)} className={s.link}>
          {record.name}
        </Link>
      ),
    },
    {
      title: t('labels.processedPatients'),
      width: '10%',
      render: (_, record) => (
        <>
          <div>{record.processedItemsCount}</div>
        </>
      ),
    },
    {
      title: t('labels.totalPatients'),
      width: '10%',
      render: (_, record) => (
        <>
          <div>{record.itemsCount}</div>
        </>
      ),
    },
    {
      title: t('labels.createdAt'),
      width: '20%',
      render: (_, record) => (
        <>
          <div>{formatCreatedAt(record.createdAt)}</div>
        </>
      ),
    },
    {
      title: t('labels.download'),
      width: '20%',
      render: (_, record) => (
        <BaseButton
          label={t('labels.download')}
          loading={isLoading}
          onClick={() => {
            download(record.guid);
          }}
        />
      ),
    },
  ];
};
