import React, { useEffect, useState } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import BaseFormItemGroup from 'components/atoms/base-form/item-group';
import BaseInput from 'components/atoms/base-input';
import NumberTypeSelectBox from 'components/molecules/number-types-select-box';
import ContactMethodSelectBox from 'components/molecules/contact-methods-select-box';
import StateSelectBox from 'components/molecules/states-types-select-box';
import TimezoneSelectBox from 'components/molecules/timezone-select-box';
import BaseGrid from 'components/atoms/base-grid';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { useEdit } from 'infrastructure/providers/edit-provider';
import { prepareArrToCopy } from 'infrastructure/functions/prepare-arr-to-copy';
import classNames from 'classnames';
import { isMobile } from 'utils/deviceHelper';
import useUser from 'utils/useUser';

import s from './styles.module.scss';
import { adminPanelContactInfoValidationSchema } from './validation-schema';

import type { Patient } from 'infrastructure/classes/patient/patient';

const formKey = 'AdminPanelContactInfoForm';

interface IAdminPanelContactInfoFormProps {
  data: Patient;
  loading?: boolean;
  readOnly: boolean;
}

const AdminPanelContactInfoForm: React.FC<IAdminPanelContactInfoFormProps> = (
  props,
) => {
  const { data, loading: loadingProp = false, readOnly } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { t } = useTranslation();
  const { deviceType } = useUser();

  const { canEdit, setEdit } = useEdit();

  const { loading, updatePatientInfo } = usePatientInfo({
    patientGuid: data.guid,
  });

  const formik = useFormik({
    initialValues: data,
    onSubmit: async (values) => {
      await updatePatientInfo(values).then(() => {
        formik.resetForm({ values });
      });
      setIsEdit(false);
      setEdit(false);
    },
    validationSchema: adminPanelContactInfoValidationSchema(),
    validateOnChange: false,
  });

  const copyText = prepareArrToCopy([
    formik.values.address,
    formik.values?.loginInfo?.city,
    formik.values?.loginInfo?.state,
    formik.values?.loginInfo?.zipPostalCode,
  ]);

  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };
  const onSave = () => {
    formik.submitForm();
  };
  const onCancel = () => {
    formik.resetForm();
    setIsEdit(false);
    setEdit(false);
  };

  useEffect(() => {
    formik.resetForm({ values: data });
  }, [data]);

  return (
    <PatientBlock
      title={t('labels.contactInfo')}
      isEdit={isEdit}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      loading={loading || loadingProp}
      canEdit={!readOnly}
      disabled={!canEdit(formKey)}
    >
      <BaseForm formik={formik} plaintext={!isEdit} readonly={!isEdit}>
        <BaseFormItemGroup
          className={s['border-bottom']}
          label={t('labels.contactNumbers')}
        >
          <BaseGrid columns={2} className={s['inputs-wrapper']}>
            <BaseFormItem
              name="loginInfo.phone"
              label={t('labels.primaryPhoneNumber')}
              required
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="loginInfo.primaryNumberType"
              label={t('labels.primaryPhoneNumberType')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <NumberTypeSelectBox />
            </BaseFormItem>
            <BaseFormItem
              name="homeNumber"
              label={t('labels.secondaryPhoneNumber')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="secondaryNumberType"
              label={t('labels.secondaryPhoneNumberType')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isEdit}
            >
              <NumberTypeSelectBox />
            </BaseFormItem>

            <BaseFormItem
              name="preferredContactMethod"
              label={t('labels.preferredContactMethod')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <ContactMethodSelectBox />
            </BaseFormItem>
          </BaseGrid>
          <BaseFormItem
            name="loginInfo.email"
            label={t('labels.email')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
        </BaseFormItemGroup>
        <BaseFormItemGroup
          className={s['border-bottom']}
          label={t('labels.location')}
          copyText={copyText}
        >
          <BaseFormItem
            name="address"
            label={t('labels.address')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseGrid columns={2} className={s['inputs-wrapper']}>
            <BaseFormItem
              name="loginInfo.state"
              label={t('labels.state')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <StateSelectBox withNone />
            </BaseFormItem>
            <BaseFormItem
              name="loginInfo.city"
              label={t('labels.city')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="loginInfo.zipPostalCode"
              label={t('labels.zipCode')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="loginInfo.timezone"
              label={t('labels.timezone')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <TimezoneSelectBox />
            </BaseFormItem>
          </BaseGrid>
        </BaseFormItemGroup>

        <BaseFormItemGroup label={t('labels.emergencyContacts')}>
          <BaseGrid columns={2} className={s['inputs-wrapper']}>
            <BaseFormItem
              name="emergencyContactFullName"
              label={t('labels.emergencyContactName')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="emergencyContactRelationship"
              label={t('labels.emergencyContactRelationship')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="emergencyContactNumber"
              label={t('labels.emergencyPhoneNumber')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput />
            </BaseFormItem>
            <BaseFormItem
              name="emergencyContactNumberType"
              label={t('labels.emergencyPhoneNumberType')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <NumberTypeSelectBox />
            </BaseFormItem>
          </BaseGrid>
        </BaseFormItemGroup>
      </BaseForm>
    </PatientBlock>
  );
};

export default AdminPanelContactInfoForm;
