const ResetPasswordIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 3C1.4175 3 0.75 3.6675 0.75 4.5V13.5C0.75 13.8978 0.908035 14.2794 1.18934 14.5607C1.47064 14.842 1.85218 15 2.25 15H10.125C9.87745 14.4059 9.75 13.7686 9.75 13.125C9.75 11.8321 10.2636 10.5921 11.1779 9.67785C12.0921 8.76361 13.3321 8.25 14.625 8.25C15.004 8.25112 15.3815 8.29643 15.75 8.385V4.5C15.75 4.10218 15.592 3.72064 15.3107 3.43934C15.0294 3.15804 14.6478 3 14.25 3H2.25ZM2.25 4.5L8.25 8.25L14.25 4.5V6L8.25 9.75L2.25 6V4.5ZM14.25 9L12.5625 10.6875L14.25 12.375V11.25C14.7473 11.25 15.2242 11.4475 15.5758 11.7992C15.9275 12.1508 16.125 12.6277 16.125 13.125C16.125 13.425 16.0575 13.71 15.93 13.965L16.7475 14.7825C17.0625 14.31 17.25 13.74 17.25 13.125C17.25 11.4675 15.9075 10.125 14.25 10.125V9ZM11.7525 11.4675C11.4375 11.94 11.25 12.51 11.25 13.125C11.25 14.7825 12.5925 16.125 14.25 16.125V17.25L15.9375 15.5625L14.25 13.875V15C13.7527 15 13.2758 14.8025 12.9242 14.4508C12.5725 14.0992 12.375 13.6223 12.375 13.125C12.375 12.825 12.4425 12.54 12.57 12.285L11.7525 11.4675Z"
      fill="#413B56"
    />
  </svg>
);

export default ResetPasswordIcon;
