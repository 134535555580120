import { useTranslation } from 'react-i18next';
import EvelynIcon from 'components/atoms/icons/evelyn-monochrome.svg?react';
import BookmarkIcon from 'components/atoms/icons/bookmark-monochrome.svg?react';
import CommentIcon from 'components/atoms/icons/comment-monochrome.svg?react';
import SendCommentIcon from 'components/atoms/icons/send-comment.svg?react';
import classNames from 'classnames';
import { type FC } from 'react';
import BaseModal from 'components/atoms/base-modal';
import CommentBookmarkList from 'components/molecules/comment-bookmark/list';
import RpmCommentModal from 'components/templates/rpm/patients/patient/activity/modal';
import BaseIconButton from 'components/atoms/base-icon-button';

import s from './styles.module.scss';
import { useCommentColumn } from './hooks/use-comment-column';

import type { TAddCommentFn } from 'infrastructure/hooks/rpm';

interface CommentColumnProps {
  addComment: TAddCommentFn;
  deviceId: string;
  readingId: string;
  idx: number;
  createdAt: string;
  hideAiButton: boolean;
  currentInteractionStatus?: boolean;
  patientGuid: string;
}
const CommentColumn: FC<CommentColumnProps> = ({
  addComment,
  deviceId,
  readingId,
  idx,
  createdAt,
  hideAiButton,
  currentInteractionStatus,
  patientGuid,
}) => {
  const { t } = useTranslation();
  const {
    openBookmarkModal,
    openTemplateModal,
    templateValues,
    showBookmarkBtn,
    isLoading,
    disabled,
    aiButtonTitle,
    updateAction,
    isCommentGenerationLimitReached,
    loading,
    message,
    submitCommentLockByAi,
    sendCommentButtonTitle,
    onSubmitComment,
    setTemplateValues,
    toggleOpenBookmark,
    genAiComment,
    toggleOpenTemplate,
    onApplyBookmark,
  } = useCommentColumn({
    addComment,
    deviceId,
    idx,
    readingId,
    currentInteractionStatus,
    patientGuid,
  });

  return (
    <>
      <div className={s.comment} data-cy="comment-input">
        <textarea
          name="message"
          placeholder={t('controls.typeYourComment')}
          value={message ?? ''}
          onChange={(e) => {
            updateAction({ idx, action: { message: e.target.value } });
          }}
          disabled={disabled}
        />
        <div className={s['action-btns']}>
          <div>
            {hideAiButton && (
              <BaseIconButton
                onClick={() =>
                  genAiComment({
                    deviceId,
                    readingId,
                  })
                }
                title={aiButtonTitle}
                disabled={
                  isCommentGenerationLimitReached || isLoading || disabled
                }
                loading={isLoading}
                icon={
                  <EvelynIcon
                    width={24}
                    height={24}
                    className={s.icon}
                    data-disabled={isLoading || disabled}
                  />
                }
              />
            )}
            {showBookmarkBtn && (
              <BaseIconButton
                onClick={toggleOpenBookmark}
                title={t('labels.bookmarkedComments')}
                disabled={disabled || openBookmarkModal}
                icon={
                  <BookmarkIcon
                    width={20}
                    height={20}
                    className={s.icon}
                    data-disabled={disabled}
                  />
                }
              />
            )}
            <BaseIconButton
              onClick={toggleOpenTemplate}
              title={
                openTemplateModal
                  ? t('controls.onlyUseButtonOncePerComment')
                  : t('controls.openTemplateModal')
              }
              disabled={disabled || openTemplateModal}
              icon={
                <CommentIcon
                  width={18}
                  height={18}
                  className={s.icon}
                  data-disabled={disabled}
                />
              }
            />
          </div>
          <BaseIconButton
            dataCy="submit-reading-comment-button"
            onClick={onSubmitComment}
            title={sendCommentButtonTitle}
            disabled={disabled || submitCommentLockByAi}
            loading={loading}
            icon={
              <SendCommentIcon
                width={24}
                height={24}
                className={classNames(s.icon, s['send-icon'], {
                  [s.disabled]: disabled,
                })}
              />
            }
          />
        </div>
      </div>
      {openBookmarkModal && (
        <BaseModal
          open={openBookmarkModal}
          onCancel={toggleOpenBookmark}
          width="1000px"
          title="Bookmarked comments"
        >
          <CommentBookmarkList onApply={onApplyBookmark} />
        </BaseModal>
      )}
      {openTemplateModal && (
        <RpmCommentModal
          isOpen={openTemplateModal}
          closeModal={toggleOpenTemplate}
          activityProps={{
            activity: {
              id: readingId,
              createdAt,
            },
            setValues: setTemplateValues,
            values: templateValues,
            handleSubmit: (msg: string) => {
              updateAction({ idx, action: { message: msg } });
            },
          }}
        />
      )}
    </>
  );
};
export default CommentColumn;
