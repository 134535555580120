import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

import type { TSchema } from 'infrastructure/functions';

export const patientConditionValidationSchema = () =>
  createValidationSchema({
    code: commonValidationSchemas.string({ required: true }),
    diagnoseGuid: commonValidationSchemas.string({ required: true }),
    description: commonValidationSchemas.string({ required: true }),
  });

export type Values = TSchema<
  ReturnType<typeof patientConditionValidationSchema>
>;
