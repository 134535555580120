import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTableCard, {
  buildTableCardItems,
} from 'components/atoms/base-table-card';
import { useRunbookLog } from 'infrastructure/hooks/clinic/use-runbook-logs';
import { applyFields } from 'infrastructure/functions';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import RunbookLogSelectBox from 'components/molecules/runbook-log-select-box';
import useUser from 'utils/useUser';
import classNames from 'classnames';

import s from './styles.module.scss';

import type { RunbookLog } from 'infrastructure/classes/runbook-log';

interface IProps {
  clinicGuid: string;
}

const ClinicInstructionsCard: React.FC<IProps> = (props) => {
  const { clinicGuid } = props;

  const { t } = useTranslation();
  const user = useUser();

  const { runbookLogs, loading, loadRunbookLogs, meta } = useRunbookLog({
    meta: {
      items: 1,
    },
  });

  const emptyValue = {
    afterHours: '-',
    businessHours: '-',
    contactMethod: '-',
    contactMethodValue: '-',
    createdBy: '-',
    criticalAfterHours: '-',
    criticalBusinessHours: '-',
    dateNoteLogged: '-',
    notes: '-',
    createdAt: '',
  } as RunbookLog;

  const [runbook, setRunbook] = useState<RunbookLog>(emptyValue);

  const clinicInstructions = useMemo(() => {
    return buildTableCardItems([
      [
        {
          label: t('labels.criticalBusinessHours'),
          value: runbook.criticalBusinessHours,
          multiply: true,
        },
        {
          label: t('labels.criticalAfterHours'),
          value: runbook.criticalAfterHours,
          multiply: true,
        },
      ],
      [
        {
          label: t('labels.businessHours'),
          value: runbook.businessHours,
        },
        {
          label: t('labels.afterHours'),
          value: runbook.afterHours,
        },
        {
          label: t('labels.contactMethod'),
          value: runbook.contactMethod,
        },
        {
          label: t('labels.contactMethodValue'),
          value: runbook.contactMethodValue,
        },
        {
          label: t('labels.dateNote'),
          value: runbook.dateNoteLogged,
        },
        {
          label: t('labels.note'),
          value: runbook.notes,
          multiply: true,
        },
      ],
    ]);
  }, [runbook]);

  const loadData = async (pageParam: number) => {
    await loadRunbookLogs(
      {
        ...meta,
        page: pageParam,
        clinicGuid,
      },
      true,
    );
  };

  const controls = (
    <div
      className={classNames(s.controls, {
        [s.readonly]: !user.isAdmin,
      })}
    >
      <span>{t('labels.updateDate')}</span>
      <RunbookLogSelectBox
        minWidth="200px"
        value={runbook.createdAt}
        clinicGuid={clinicGuid}
        plaintext={!user.isAdmin}
        readonly={!user.isAdmin}
        onChange={(value, option) => {
          setRunbook(applyFields(emptyValue, option.record));
        }}
      />
    </div>
  );

  useEffect(() => {
    if (runbookLogs[0]) {
      setRunbook(applyFields(emptyValue, runbookLogs[0]));
    } else {
      setRunbook(emptyValue);
    }
  }, [runbookLogs]);

  useEffect(() => {
    if (clinicGuid) loadData(1);
  }, [clinicGuid]);

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseTableCard
        title={t('labels.clinicInstructions')}
        controls={controls}
        columns={2}
        rows={[1, 5]}
        items={clinicInstructions}
      />
    </BaseSpinWrapper>
  );
};

export default ClinicInstructionsCard;
