import classNames from 'classnames';

import DefaultEmptyTableIcon from '../icons/defaultEmptyTableIcon';
import s from './styles.module.scss';

import type { FC, ReactNode } from 'react';

interface Props {
  icon?: ReactNode;
  text: string;
  column?: boolean;
  textColor?: 'grey' | 'purple';
}

const EmptyTablePlaceholder: FC<Props> = ({
  icon,
  text,
  column,
  textColor = 'grey',
}) => (
  <div className={classNames(s.wrapper, { [s.col]: column })}>
    {icon ?? <DefaultEmptyTableIcon />}
    <p className={classNames(s.text, s[textColor])}>{text}</p>
  </div>
);

export default EmptyTablePlaceholder;
