import { isNumber } from 'lodash';

import { debug } from './logger';

export const calculatePercentages = (
  num1: number,
  num2: number,
): [string, string] => {
  const defaultResult: [string, string] = ['0.0', '0.0'];
  if (!isNumber(num1) || !isNumber(num2)) {
    debug('Invalid input for calculatePercentages');
    return defaultResult;
  }

  if (num1 === 0 && num2 === 0) {
    return defaultResult;
  }

  const total = num1 + num2;
  const percentage1 = Number(((num1 / total) * 100).toFixed(1));
  const percentage2 = 100 - percentage1;

  return [percentage1.toFixed(1), percentage2.toFixed(1)];
};

export const calculateCompletedPercentage = (
  completed: number,
  total: number,
): string => {
  if (total === 0) {
    return '0';
  }
  const percentage = (completed / total) * 100;
  return percentage.toFixed(1);
};
