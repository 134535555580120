import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import common from 'constants/common';
import DropdownClosedIcon from 'components/atoms/icons/dropdownClosedIcon';
import DropdownOpenIcon from 'components/atoms/icons/dropdownOpenIcon';

import TreeDotsIcon from '../../icons/treeDotsIcon';

const customStyles = {
  option: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#eee !important' : styles.backgroundColor,
    color: isDisabled ? '#999 !important' : styles.backgroundColor,
    borderRadius: isDisabled ? '0 !important' : styles.borderRadius,
  }),
};

const SelectBox = ({
  defaultValue,
  valueChanged,
  options,
  isMulti,
  isDotIcon,
  isClearable,
  isLoading,
  disabled,
  dataCy,
}) => {
  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 12,
    colors: {
      ...theme.colors,
      ...common.primaryColors,
    },
  });

  const dropdownIndicatorIcon = (menuIsOpen) => {
    if (isDotIcon) {
      return <TreeDotsIcon />;
    }
    if (menuIsOpen) {
      return <DropdownOpenIcon />;
    }
    if (!menuIsOpen) {
      return <DropdownClosedIcon />;
    }
  };
  const DropdownIndicator = (props) => {
    // eslint-disable-next-line react/prop-types
    const { selectProps } = props;
    // eslint-disable-next-line react/prop-types
    const { menuIsOpen } = selectProps;
    return (
      <components.DropdownIndicator {...props}>
        {dropdownIndicatorIcon(menuIsOpen)}
      </components.DropdownIndicator>
    );
  };

  return (
    <div data-cy={dataCy}>
      <Select
        options={options}
        isMulti={isMulti}
        defaultValue={defaultValue}
        value={defaultValue}
        classNamePrefix="input--select"
        theme={(theme) => selectTheme(theme)}
        components={{ DropdownIndicator }}
        onChange={valueChanged}
        isClearable={isClearable}
        isDisabled={disabled}
        isLoading={isLoading}
        styles={customStyles}
      />
    </div>
  );
};

SelectBox.propTypes = {
  defaultValue: PropTypes.any,
  valueChanged: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  isMulti: PropTypes.bool,
  isDotIcon: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  dataCy: PropTypes.string,
};

export default SelectBox;
