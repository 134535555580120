import { makeid } from 'utils/helpers/makeid';

import type { InsuranceProvider, InsuranceType } from 'infrastructure/enums';
import type { IPatientInsurance } from 'infrastructure/interfaces';
import type { Nullable } from 'infrastructure/types';

export class PatientInsurance {
  key: string;
  guid: string;
  patientGuid: string;
  provider: InsuranceProvider;
  type: Nullable<InsuranceType>;
  number: string;
  planName?: string;

  constructor(data: IPatientInsurance) {
    this.key = makeid(5);
    this.guid = data.guid;
    this.patientGuid = data.patientGuid;
    this.provider = data.provider;
    this.type = data.type;
    this.number = data.number;
    this.planName = data.planName;
  }
}
