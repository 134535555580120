import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_TURK_DASHBOARD_PATIENT_DETAILS } from 'services/dataService/resources';

import { PATIENT_DATA_STALE_TIME } from './config';

interface PatientDetails {
  guid: string;
  gender: string;
  birthDate: string;
  devices: Device[];
}

interface Device {
  patientGuid: string;
  deviceId: string;
  readingTypes: string[];
  manufacturer: string;
  isActive: boolean;
  action: string;
  statusUpdatedAt: string;
}

const useTurkDashboardPatientDetails = ({
  patientGuid,
  enabled,
}: {
  patientGuid: string;
  enabled: boolean;
}) => {
  const { data, error, isLoading } = useQuery({
    enabled,
    queryKey: ['turkDashboardPatient', patientGuid],
    queryFn: async ({ signal }) => {
      const { error: localError, data: localData } =
        await dataService.getList<PatientDetails>(
          API_TURK_DASHBOARD_PATIENT_DETAILS(patientGuid),
          {
            signal,
          },
        );
      if (localError) {
        throw new Error(localError);
      }
      return localData;
    },
    staleTime: PATIENT_DATA_STALE_TIME,
  });

  return { data, isLoading, error };
};

export default useTurkDashboardPatientDetails;
