import { forwardRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useTranslation } from 'react-i18next';
import BooleanOptedSelectBox from 'components/molecules/boolean-opted-select-box';
import BooleanSelectBox from 'components/molecules/boolean-select-box';

import s from './styles.module.scss';
import { adminPanelPatientInfoValidationSchema } from '../patient-info/validation-schema';

import type { Patient } from 'infrastructure/classes/patient/patient';

interface IPatientSubscriptionsFormItemProps {
  data: Patient;
  isEdit?: boolean;
}

export interface IPatientSubscriptionsFormItemRef {
  resetForm: () => void;
  validate: () => {
    isValid: boolean;
    value: Patient;
  };
}

const PatientSubscriptionsFormItem = forwardRef<
  IPatientSubscriptionsFormItemRef,
  IPatientSubscriptionsFormItemProps
>((props, ref) => {
  const { data, isEdit = false } = props;

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: data,
    onSubmit: () => {},
    isInitialValid: !!data.guid,
    validationSchema: adminPanelPatientInfoValidationSchema(),
    enableReinitialize: true,
  });

  const validate = () => {
    formik.validateForm();
    return {
      isValid: formik.isValid,
      value: formik.values,
    };
  };

  const resetForm = () => {
    formik.resetForm();
  };

  useImperativeHandle(ref, () => ({ validate, resetForm }));

  return (
    <li>
      <BaseForm
        formik={formik}
        plaintext={!isEdit}
        readonly={!isEdit}
        className={s.item}
      >
        <BaseFormItem
          name="rpmSmsSubscription"
          label={t('labels.rpmSmsSubscription')}
        >
          <BooleanOptedSelectBox
            onChange={(value) => {
              formik.setFieldValue('rpmSmsSubscription', value);
            }}
            value={
              formik.values.rpmSmsSubscription
                ? t('controls.optedIn')
                : t('controls.optedOut')
            }
          />
        </BaseFormItem>
        <BaseFormItem
          name="ccmSmsSubscription"
          label={t('labels.ccmSmsSubscription')}
        >
          <BooleanOptedSelectBox
            onChange={(value) => {
              formik.setFieldValue('ccmSmsSubscription', value);
            }}
            value={
              formik.values.ccmSmsSubscription
                ? t('controls.optedIn')
                : t('controls.optedOut')
            }
          />
        </BaseFormItem>
        <BaseFormItem
          name="adherenceSmsSubscription"
          label={t('labels.adherenceSmsSubscription')}
        >
          <BooleanOptedSelectBox
            onChange={(value) => {
              formik.setFieldValue('adherenceSmsSubscription', value);
            }}
            value={
              formik.values.adherenceSmsSubscription
                ? t('controls.optedIn')
                : t('controls.optedOut')
            }
          />
        </BaseFormItem>
        <BaseFormItem
          name="emailSubscription"
          label={t('labels.emailSubscription')}
        >
          <BooleanSelectBox
            value={
              formik.values.emailSubscription
                ? t('controls.optedIn')
                : t('controls.optedOut')
            }
            disabled={isEdit}
          />
        </BaseFormItem>
      </BaseForm>
    </li>
  );
});

PatientSubscriptionsFormItem.displayName = 'PriorAuthorizationFormItem';

export default PatientSubscriptionsFormItem;
