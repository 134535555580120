import dataService from 'services/dataService';
import { API_PATIENTS_DO_NOT_CALL } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface DataProps {
  patientGuid: string;
  doNotCall: boolean;
}

export const doNotCallFn = async (postData: DataProps) => {
  const { error, data } = await dataService.updateOnly(
    API_PATIENTS_DO_NOT_CALL,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
