import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Row, Col } from 'reactstrap';
import PlusIcon from 'components/atoms/icons/plus';
import RemoveIcon from 'components/atoms/icons/remove';
import Input from 'components/atoms/input';
import forms from 'constants/forms';

const Insurances = ({ values, errors }) => {
  const insurancesOptions = useMemo(
    () =>
      forms.insuranceProviders.map((e) => {
        if (values.insurances.some(({ provider }) => provider === e.value)) {
          return {
            ...e,
            isDisabled: true,
          };
        }
        return {
          ...e,
          isDisabled: false,
        };
      }),
    [values.insurances],
  );

  const canAddNewInsurance = useMemo(
    () => values.insurances.length < forms.insuranceProviders.length,
    [values.insurances],
  );

  return (
    <FieldArray name="insurances">
      {({ remove, push }) => (
        <div
          className="admin-patient-form-block"
          style={{ marginBottom: '10px' }}
        >
          <div className="admin-patient-form-block-add">
            <span>Patient Insurance</span>
            {canAddNewInsurance && (
              <span
                className="admin-patient-form__add-button"
                data-cy="admin-patient-form__add-insurances-button"
                onClick={() => {
                  push({
                    provider: '',
                    number: '',
                  });
                }}
              >
                <PlusIcon />
              </span>
            )}
          </div>
          {values.insurances.length > 0 &&
            values.insurances.map((insurance, index) => {
              const fieldsetHaveErrors =
                errors?.insurances && errors.insurances[index];

              const fieldsetErrors = fieldsetHaveErrors
                ? errors.insurances[index]
                : {};

              return (
                <div key={`insurances.${index}.${values.insurances.length}`}>
                  <Row>
                    <Col lg="6" md="6" sm="6" xs="12">
                      <Input
                        label="Provider"
                        type="select"
                        id={`insurances.${index}.provider`}
                        options={insurancesOptions}
                        onChange={(e, setFieldValue) => {
                          setFieldValue(
                            `insurances.${index}.provider`,
                            e.value,
                          );
                        }}
                      />
                      {fieldsetHaveErrors && !insurance.provider ? (
                        <div className="input-error">
                          {fieldsetErrors.provider}
                        </div>
                      ) : null}
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="12">
                      <div className="admin-patient-form-block-remove">
                        <div>
                          <Input
                            label="Number"
                            id={`insurances.${index}.number`}
                          />
                          {fieldsetHaveErrors ? (
                            <div className="input-error">
                              {fieldsetErrors.number}
                            </div>
                          ) : null}
                        </div>
                        <span
                          className="admin-patient-form__remove-button"
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          <RemoveIcon />
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" md="6" sm="6" xs="12">
                      <Input
                        label="Type"
                        type="select"
                        id={`insurances.${index}.type`}
                        options={forms.insuranceTypes}
                        isClearable
                        onChange={(e, setFieldValue) => {
                          setFieldValue(
                            `insurances.${index}.type`,
                            e?.value || null,
                          );
                        }}
                      />
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="12">
                      <Input
                        label="Plan Name"
                        id={`insurances.${index}.planName`}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
        </div>
      )}
    </FieldArray>
  );
};

Insurances.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
};

export default Insurances;
