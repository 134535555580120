import { useQuery } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { apiDoctorProfile } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';
import dataService from 'services/dataService';

import type { IDoctorInfo } from 'infrastructure/interfaces';

interface Props {
  doctorGuid?: string;
}

export const getDoctorInfoKey = (doctorGuid: string) => [
  'doctorInfo',
  doctorGuid,
];

export const useDoctorInfo = ({ doctorGuid }: Props) => {
  const { data: doctorInfo, isFetching } = useQuery({
    queryKey: getDoctorInfoKey(doctorGuid ?? ''),
    queryFn: async () => {
      const { data, error } = await dataService.getOnly<IDoctorInfo>(
        apiDoctorProfile(doctorGuid),
      );

      if (error) {
        showResult(error);
        throw new Error(error);
      }

      return data;
    },
    staleTime: STALE_TIME,
    enabled: Boolean(doctorGuid),
  });

  return {
    doctorInfo,
    doctorInfoLoading: isFetching,
  };
};
