import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import RadioButton from 'components/atoms/radioButton';

const RadioInput = ({ id, label, options, error, note }) => (
  <div className="input-wrapper">
    <Field
      name={id}
      render={({ form, field }) => (
        <>
          <label htmlFor={id}>{label}</label>
          <div className="input-radio-button-wrapper">
            {options.map((option) => (
              <RadioButton
                name={id}
                id={`${id}-${option.id}`}
                label={option.label}
                key={`${id}-${option.id}`}
                onChange={() => form.setFieldValue(id, option.value)}
                isChecked={field.value === option.value}
              />
            ))}
          </div>
        </>
      )}
    />
    {error && <div className="input-error">{error}</div>}
    {note && <div className="input-note">{note}</div>}
  </div>
);
RadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  note: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
};
export default RadioInput;
