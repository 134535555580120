import React from 'react';
import BatteryIcon from 'components/atoms/icons/battery';

import type { PatientDevice } from 'infrastructure/classes/patient/patient-device';

interface IBatteryLevelProps {
  data: PatientDevice;
}

const BatteryLevel: React.FC<IBatteryLevelProps> = (props) => {
  const { data } = props;

  return (
    <div data-cy="device-table-battery-level">
      <i>
        <BatteryIcon level={data.log && data.log.batteryLevel} />
      </i>{' '}
      {data.log && data.log.batteryLevel}
    </div>
  );
};

export default BatteryLevel;
