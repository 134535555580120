import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const adminPanelPatientInfoValidationSchema = () =>
  createValidationSchema({
    loginInfo: createValidationSchema({
      title: commonValidationSchemas.string({ required: true }),
      firstName: commonValidationSchemas.string({ required: true }),
      middleName: commonValidationSchemas.string(),
      lastName: commonValidationSchemas.string({ required: true }),
      birthDate: commonValidationSchemas.date({
        required: true,
      }),
      language: commonValidationSchemas.string(),
    }),
    gender: commonValidationSchemas.string(),
    race: commonValidationSchemas.string(),
    ethnicity: commonValidationSchemas.string(),
    martialStatus: commonValidationSchemas.string(),
    workingStatus: commonValidationSchemas.string(),
  });
