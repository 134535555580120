import i18next from 'i18next';
import { ModifiedArray } from 'infrastructure/classes/common/modified-array';
import { CcmStatuses } from 'infrastructure/enums';

export interface ICcmStatusItem {
  id: CcmStatuses;
  name: string;
  color: string;
}

type CcmStatusesArray = ModifiedArray<ICcmStatusItem>;

export const ccmStatusesDataSource = (): CcmStatusesArray => {
  return new ModifiedArray<ICcmStatusItem>(
    {
      id: CcmStatuses.Enrolled,
      name: i18next.t('ccmStatuses.enrolled'),
      color: '#84E075',
    },
    {
      id: CcmStatuses.NotEnrolled,
      name: i18next.t('ccmStatuses.not_enrolled'),
      color: '#667180',
    },
    {
      id: CcmStatuses.CCMCallingQueue,
      name: i18next.t('ccmStatuses.ccm_calling_queue'),
      color: '#C67B6E',
    },
    {
      id: CcmStatuses.CCMPatientRefusedCoPayInsurance,
      name: i18next.t('ccmStatuses.ccm_patient_refused_copay_insurance'),
      color: '#C67B6E',
    },
    {
      id: CcmStatuses.CCMPatientRefusedExpired,
      name: i18next.t('ccmStatuses.ccm_patient_refused_expired'),
      color: '#C67B6E',
    },
    {
      id: CcmStatuses.CCMPatientRefusedChangedDoctor,
      name: i18next.t('ccmStatuses.ccm_patient_refused_changed_doctor'),
      color: '#C67B6E',
    },
    {
      id: CcmStatuses.CCMPatientRefusedNotInterestedNotNecessarySuspectScam,
      name: i18next.t(
        'ccmStatuses.ccm_patient_refused_not_interested_not_necessary_suspect_scam',
      ),
      color: '#C67B6E',
    },
    {
      id: CcmStatuses.CCMPatientRefusedWrongContactInformation,
      name: i18next.t(
        'ccmStatuses.ccm_patient_refused_wrong_contact_information',
      ),
      color: '#C67B6E',
    },
    {
      id: CcmStatuses.CCMPatientRefusedNoContact,
      name: i18next.t('ccmStatuses.ccm_patient_refused_no_contact'),
      color: '#C67B6E',
    },
    {
      id: CcmStatuses.CCMPatientRefusedWantsToDiscussWithPhysician,
      name: i18next.t(
        'ccmStatuses.ccm_patient_refused_wants_to_discuss_with_physician',
      ),
      color: '#C67B6E',
    },
    {
      id: CcmStatuses.CCMPatientRefusedOther,
      name: i18next.t('ccmStatuses.ccm_patient_refused_other'),
      color: '#C67B6E',
    },
    {
      id: CcmStatuses.CCMOnboarding,
      name: i18next.t('ccmStatuses.ccm_onboarding'),
      color: '#CCCAD7',
    },
    {
      id: CcmStatuses.CCMOnboardingUnsuccessfulNoContact,
      name: i18next.t('ccmStatuses.ccm_onboarding_unsuccessful_no_contact'),
      color: '#cf93aa',
    },
    {
      id: CcmStatuses.CCMOnboardingUnsuccessfulPatientRefused,
      name: i18next.t(
        'ccmStatuses.ccm_onboarding_unsuccessful_patient_refused',
      ),
      color: '#cf93aa',
    },
    {
      id: CcmStatuses.CCMOnboardingUnsuccessfulPatientNotQualifiedForCCM,
      name: i18next.t(
        'ccmStatuses.ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
      ),
      color: '#cf93aa',
    },
    {
      id: CcmStatuses.CCMOnboardingUnsuccessfulChangedDoctor,
      name: i18next.t('ccmStatuses.ccm_onboarding_unsuccessful_changed_doctor'),
      color: '#cf93aa',
    },
    {
      id: CcmStatuses.CCMOnboardingUnsuccessfulOther,
      name: i18next.t('ccmStatuses.ccm_onboarding_unsuccessful_other'),
      color: '#cf93aa',
    },
    {
      id: CcmStatuses.CCMUnenrolledChangedDoctor,
      name: i18next.t('ccmStatuses.ccm_unenrolled_changed_doctor'),
      color: '#8f3d47',
    },
    {
      id: CcmStatuses.CCMUnenrolledClientRequest,
      name: i18next.t('ccmStatuses.ccm_unenrolled_client_request'),
      color: '#8f3d47',
    },
    {
      id: CcmStatuses.CCMUnenrolledCopayLossOfInsurance,
      name: i18next.t('ccmStatuses.ccm_unenrolled_copay_loss_of_insurance'),
      color: '#8f3d47',
    },
    {
      id: CcmStatuses.CCMUnenrolledNonAdherent,
      name: i18next.t('ccmStatuses.ccm_unenrolled_non_adherent'),
      color: '#8f3d47',
    },
    {
      id: CcmStatuses.CCMUnenrolledExpired,
      name: i18next.t('ccmStatuses.ccm_unenrolled_expired'),
      color: '#8f3d47',
    },
    {
      id: CcmStatuses.CCMUnenrolledHospice,
      name: i18next.t('ccmStatuses.ccm_unenrolled_hospice'),
      color: '#8f3d47',
    },
    {
      id: CcmStatuses.CCMUnenrolledLongTermSkilledNursingFacilityRehab,
      name: i18next.t(
        'ccmStatuses.ccm_unenrolled_long_term_skilled_nursing_facility_rehab',
      ),
      color: '#8f3d47',
    },
    {
      id: CcmStatuses.CCMUnenrolledPhysicianClinicRequest,
      name: i18next.t('ccmStatuses.ccm_unenrolled_physician_clinic_request'),
      color: '#8f3d47',
    },
    {
      id: CcmStatuses.CCMUnenrolledClientRequestMoving,
      name: i18next.t('ccmStatuses.ccm_unenrolled_client_request_moving'),
      color: '#8f3d47',
    },
    {
      id: CcmStatuses.CCMUnenrolledHealthPlanRequestGoalsMet,
      name: i18next.t(
        'ccmStatuses.ccm_unenrolled_health_plan_request_goals_met',
      ),
      color: '#8f3d47',
    },
    {
      id: CcmStatuses.CCMUnenrolledOther,
      name: i18next.t('ccmStatuses.ccm_unenrolled_other'),
      color: '#8f3d47',
    },
    {
      id: CcmStatuses.CCMReferralOnHold,
      name: i18next.t('ccmStatuses.ccm_referral_on_hold'),
      color: '#FDDA0D',
    },
    {
      id: CcmStatuses.CCMReferralReceived,
      name: i18next.t('ccmStatuses.ccm_referral_received'),
      color: '#E5D0FF',
    },
    {
      id: CcmStatuses.CCMReferralWithdrawn,
      name: i18next.t('ccmStatuses.ccm_referral_withdrawn'),
      color: '#947DBE',
    },
    {
      id: CcmStatuses.CCMPatientNoContact,
      name: i18next.t('ccmStatuses.ccm_patient_no_contact'),
      color: '#800000',
    },
  );
};
