import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SocketRoutes } from 'services/wsService';

import type { RootState } from 'store';

type RoomType =
  | 'smsDashboard'
  | 'smsDashboardUnregistered'
  | 'rpmDashboardSla'
  | 'rpmDashboard'
  | 'rpmPatientProfile';

export const useWebsocketRoom = (room: RoomType, patientGuid?: string) => {
  const socket = useSelector((state: RootState) => state.socket);

  useEffect(() => {
    if (socket.client && socket.isConnected) {
      socket.client.send(SocketRoutes.JoinToRoom, { room, patientGuid });
    }
  }, [socket.isConnected]);

  useEffect(() => {
    return () => {
      if (socket.client && socket.isConnected === true) {
        socket.client.send(SocketRoutes.LeaveRoom, { room, patientGuid });
      }
    };
  }, []);
};
