import React from 'react';
import BaseButton from 'components/atoms/baseButton';
import ChevronLeftIcon from 'components/atoms/icons/chevronLeft';
import useUser from 'utils/useUser';
import { useAppDispatch } from 'store';
import ListIcon from 'components/atoms/icons/listIcon';
import { useLocation, useNavigate } from 'react-router-dom';

import s from './styles.module.scss';

export interface INavigationHeaderProps {
  title: string;
  goBackPath?: string;
  children?: React.ReactNode;
}

const NavigationHeader: React.FC<INavigationHeaderProps> = ({
  title,
  goBackPath,
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();
  const {
    modalWindows: { toggleOpenMobileMiniNav },
  } = useAppDispatch();

  const goBack = () => {
    const canGoBack = location.key;
    const hasHash = location.hash;
    if (canGoBack && !hasHash) {
      navigate(-1);
    } else if (goBackPath) navigate(goBackPath);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.profile}>
        <div>
          {!user.isPatient && goBackPath && (
            <BaseButton
              size="large"
              icon={<ChevronLeftIcon />}
              type="secondary"
              onClick={goBack}
              dataCy="go-back-btn"
            />
          )}
          <p data-cy="admin-header-title">{title}</p>
        </div>
        <BaseButton
          icon={<ListIcon />}
          type="secondary"
          onClick={() => toggleOpenMobileMiniNav()}
          dataCy="mobile-mini-nav-btn"
        />
      </div>
      {children}
    </div>
  );
};
export default NavigationHeader;
