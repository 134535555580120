import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import BaseFormGroup from 'components/atoms/base-form/group';
import { useTranslation } from 'react-i18next';
import ControlPanelFilesIcon from 'components/atoms/icons/controlPanelFilesIcon';
import ControlPanelChatRightIcon from 'components/atoms/icons/controlPanelChatRightIcon';
import ControlPanelTelephoneIcon from 'components/atoms/icons/controlPanelTelephoneIcon';
import ClinicStatusControlButton from 'components/molecules/control-buttons/status';
import { useFormik } from 'formik';
import BaseForm from 'components/atoms/base-form';
import { ClinicStatus } from 'infrastructure/enums';
import BooleanControlButton from 'components/molecules/control-buttons/boolean';
import { mergeFieldsValue } from 'infrastructure/functions';

import s from './styles.module.scss';
import { clinicSettingsValidationSchema } from './validation-schema';
import ClinicOptedControlButton from '../../../control-buttons/opted';

import type { Clinic } from 'infrastructure/classes/clinic';
import type { IClinicSettings } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const prepareValue = (data?: Clinic): IClinicSettings => {
  let defaultValue: IClinicSettings = {
    status: ClinicStatus.Active,
    showOnInteractiveTimeDashboard: true,
    acceptPvrLite: true,
    adherenceSmsSubscription: true,
    doNotCall: true,
  };
  if (data) {
    defaultValue = mergeFieldsValue(defaultValue, data);
  }

  return defaultValue;
};

const FormDisplayName = 'ClinicSettingsForm';

interface IProps extends IIndependentFormProps {
  data?: Clinic;
}

const ClinicSettingsForm = forwardRef<
  IIndependentFormRef<IClinicSettings>,
  IProps
>((props, ref) => {
  const { data } = props;

  const { t } = useTranslation();

  const formik = useFormik<IClinicSettings>({
    initialValues: prepareValue(data),
    onSubmit: () => {},
    validateOnChange: false,
    validationSchema: clinicSettingsValidationSchema(),
  });

  const { values, setFieldValue } = formik;

  const submitForm = async () => {
    await formik.submitForm();
    const isValid = await formik
      .validateForm()
      .then((res) => !Object.keys(res).length);

    return {
      values: formik.values,
      formName: FormDisplayName,
      isValid,
    };
  };

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: formik.resetForm,
  }));

  useEffect(() => {
    if (data) formik.resetForm({ values: prepareValue(data) });
  }, [data]);

  return (
    <BaseForm formik={formik}>
      <BaseFormGroup label={t('labels.advancedSettings')}>
        <div className={s.content}>
          <BooleanControlButton
            label={t('labels.interactiveTimeDashboardInclusive')}
            value={values.showOnInteractiveTimeDashboard}
            onChange={(val) =>
              setFieldValue('showOnInteractiveTimeDashboard', val)
            }
          />
          <ClinicOptedControlButton
            label={t('labels.pvrLiteOpt')}
            value={values.acceptPvrLite}
            onChange={(val) => setFieldValue('acceptPvrLite', val)}
            icon={ControlPanelFilesIcon}
          />
          <ClinicOptedControlButton
            label={t('labels.smsAdherenceAlert')}
            value={values.adherenceSmsSubscription}
            onChange={(val) => setFieldValue('adherenceSmsSubscription', val)}
            icon={ControlPanelChatRightIcon}
          />
          <ClinicOptedControlButton
            label={t('labels.doNotCallOpt')}
            value={values.doNotCall}
            onChange={(val) => setFieldValue('doNotCall', val)}
            icon={ControlPanelTelephoneIcon}
          />

          <ClinicStatusControlButton
            value={values.status}
            onChange={(val) => setFieldValue('status', val)}
          />
        </div>
      </BaseFormGroup>
    </BaseForm>
  );
});

ClinicSettingsForm.displayName = FormDisplayName;

export default ClinicSettingsForm;
