import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import {
  API_CCM_PATIENTS_MANUAL_INTERACTIVE_TIME,
  API_PATIENTS_MANUAL_INTERACTIVE_TIME,
} from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface DataProps {
  patientGuid: string;
  isRpm: boolean;
  timeInMinutes: number;
  comment: string;
}

interface Response {
  guid: string;
  patientGuid: string;
  timeInMinutes: number;
  comment: string;
  createdAt: string;
}

interface Props {
  onSuccess: () => void;
}

export const useSetManualInteractionTime = ({ onSuccess }: Props) => {
  const { mutate, ...other } = useMutation({
    mutationFn: async (postData: DataProps) => {
      const url = postData.isRpm
        ? API_PATIENTS_MANUAL_INTERACTIVE_TIME(postData.patientGuid)
        : API_CCM_PATIENTS_MANUAL_INTERACTIVE_TIME(postData.patientGuid);

      const { error, data } = await dataService.createOne<Response>(url, {
        timeInMinutes: postData.timeInMinutes,
        comment: postData.comment,
      });

      if (error) {
        showResult(error);
        throw new Error(error);
      }

      return data;
    },
    onSuccess() {
      onSuccess();
      showResult();
    },
  });

  return { setManualInteractionTime: mutate, ...other };
};
