import i18next from 'i18next';
import { RpmStatuses } from 'infrastructure/enums';
import { ModifiedArray } from 'infrastructure/classes/common/modified-array';

export interface IRpmStatusItem {
  id: RpmStatuses;
  name: string;
  color: string;
}

type RpmStatusesArray = ModifiedArray<IRpmStatusItem>;

export const rpmStatusesDataSource = (): RpmStatusesArray => {
  return new ModifiedArray<IRpmStatusItem>(
    {
      id: RpmStatuses.PatientReferral,
      name: i18next.t('rpmStatuses.patient_referral'),
      color: '#0041C2',
    },
    {
      id: RpmStatuses.ReferralOnHold,
      name: i18next.t('rpmStatuses.referral_on_hold'),
      color: '#FDDA0D',
    },
    {
      id: RpmStatuses.ReferralWithdrawn,
      name: i18next.t('rpmStatuses.referral_withdrawn'),
      color: '#947DBE',
    },
    {
      id: RpmStatuses.CallingQueue,
      name: i18next.t('rpmStatuses.calling_queue'),
      color: '#C77C6E',
    },
    {
      id: RpmStatuses.PatientNoContact,
      name: i18next.t('rpmStatuses.patient_no_contact'),
      color: '#800000',
    },
    {
      id: RpmStatuses.PatientRefusedConnectivityTechBarier,
      name: i18next.t(
        'rpmStatuses.patient_refused_connectivity_or_tech_barier',
      ),
      color: '#330066',
    },
    {
      id: RpmStatuses.PatientRefusedCopayInsurance,
      name: i18next.t('rpmStatuses.patient_refused_copay_insurance'),
      color: '#330066',
    },
    {
      id: RpmStatuses.PatientRefusedNotInterestedNotNecessarySuspectScam,
      name: i18next.t(
        'rpmStatuses.patient_refused_not_interested_not_necessary_suspect_scam',
      ),
      color: '#330066',
    },
    {
      id: RpmStatuses.PatientRefusedExpired,
      name: i18next.t('rpmStatuses.patient_refused_expired'),
      color: '#330066',
    },
    {
      id: RpmStatuses.PatientRefusedThereIsNoOneToHelp,
      name: i18next.t('rpmStatuses.patient_refused_there_is_no_one_to_help'),
      color: '#330066',
    },
    {
      id: RpmStatuses.PatientRefusedHasANewPhysician,
      name: i18next.t('rpmStatuses.patient_refused_has_a_new_physician'),
      color: '#330066',
    },
    {
      id: RpmStatuses.PatientRefusedWantsToDiscussWithPhysician,
      name: i18next.t(
        'rpmStatuses.patient_refused_wants_to_discuss_with_physician',
      ),
      color: '#330066',
    },
    {
      id: RpmStatuses.PatientRefusedWrongContactInformation,
      name: i18next.t('rpmStatuses.patient_refused_wrong_contact_information'),
      color: '#330066',
    },
    {
      id: RpmStatuses.PatientRefusedNoContact,
      name: i18next.t('rpmStatuses.patient_refused_no_contact'),
      color: '#330066',
    },
    {
      id: RpmStatuses.InsuranceRefused,
      name: i18next.t('rpmStatuses.insurance_refused'),
      color: '#c22121',
    },
    {
      id: RpmStatuses.Onboarding,
      name: i18next.t('rpmStatuses.onboarding'),
      color: '#FB9518',
    },
    {
      id: RpmStatuses.Active,
      name: i18next.t('rpmStatuses.active'),
      color: '#84E075',
    },
    {
      id: RpmStatuses.InactiveBadNoSignalConnectivity,
      name: i18next.t('rpmStatuses.inactive_bad_no_signal_connectivity'),
      color: '#ed1a1a',
    },
    {
      id: RpmStatuses.InactiveChangedDoctor,
      name: i18next.t('rpmStatuses.inactive_changed_doctor'),
      color: '#d92b2b',
    },
    {
      id: RpmStatuses.InactiveClientRequestInconvenient,
      name: i18next.t('rpmStatuses.inactive_client_request_inconvenient'),
      color: '#b01e1e',
    },
    {
      id: RpmStatuses.InactiveClientRequestMoving,
      name: i18next.t('rpmStatuses.inactive_client_request_moving'),
      color: '#9e0e0e',
    },
    {
      id: RpmStatuses.InactiveClientRequestOther,
      name: i18next.t('rpmStatuses.inactive_client_request_other'),
      color: '#a81d1d',
    },
    {
      id: RpmStatuses.InactiveExpired,
      name: i18next.t('rpmStatuses.inactive_expired'),
      color: '#ff0000',
    },
    {
      id: RpmStatuses.InactiveHealthPlanRequestGoalsMet,
      name: i18next.t('rpmStatuses.inactive_health_plan_request_goals_met'),
      color: '#ab1a1a',
    },
    {
      id: RpmStatuses.InactiveHealthPlanRequestNonCompliant,
      name: i18next.t('rpmStatuses.inactive_health_plan_request_non_compliant'),
      color: '#ad1c1c',
    },
    {
      id: RpmStatuses.InactiveHealthPlanRequestOther,
      name: i18next.t('rpmStatuses.inactive_health_plan_request_other'),
      color: '#a60303',
    },
    {
      id: RpmStatuses.InactiveHospice,
      name: i18next.t('rpmStatuses.inactive_hospice'),
      color: '#a31212',
    },
    {
      id: RpmStatuses.InactiveCopay,
      name: i18next.t('rpmStatuses.inactive_copay'),
      color: '#bf1d1d',
    },
    {
      id: RpmStatuses.InactiveLongTermCareRehab,
      name: i18next.t('rpmStatuses.inactive_long_term_care_rehab'),
      color: '#ba2727',
    },
    {
      id: RpmStatuses.InactiveLossOfEligibility,
      name: i18next.t('rpmStatuses.inactive_loss_of_eligibility'),
      color: '#c92a2a',
    },
    {
      id: RpmStatuses.InactivePhysicianRequest,
      name: i18next.t('rpmStatuses.inactive_physician_request'),
      color: '#f53b3b',
    },
    {
      id: RpmStatuses.OnboardingUnsuccessful,
      name: i18next.t('rpmStatuses.onboarding_unsuccessful'),
      color: '#EF686D',
    },
    {
      id: RpmStatuses.PendingReview,
      name: i18next.t('rpmStatuses.pending_review'),
      color: '#667180',
    },
  );
};
