export const API_AGENCY_DOCTORS_BY_ROLES = 'core/agency-doctors/by-roles';
export const API_ADMIN_PANEL_AGENCY_DOCTORS =
  'admins/admin-panel/agency-doctors';
export const API_DOCTOR_UPDATE_PROFILE = 'core/doctors/profile';
export const API_AGENCY_DOCTOR_UPDATE_PROFILE = 'core/agency-doctors/profile';
export const API_PATIENT_DOCTOR_MESSAGES = 'core/doctors/messages';
export const API_DOCTOR = 'core/doctors';
export const apiAdminPanelUser = `admins/admin-panel/doctors`;
export const API_DOCTOR_CLINICS = 'core/clinics';
export const API_DOCTOR_INSURANCE_TYPES = 'core/insurances';
export const API_DOCTORS_BY_CLINIC_AND_AGENCY_DOCTORS_BY_ROLES = (
  patientGuid,
) => `core/ccm/patients/${patientGuid}/doctors-and-agency-doctors`;
export const API_AGENCY_DOCTORS_SELECT_OPTIONS =
  'core/agency-doctors/select-options';
export const API_DOCTORS_AND_AGENCY_DOCTORS_BY_ROLES =
  'core/ccm/doctors-and-agency-doctors';
