import React from 'react';
import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminAgencyDoctorBlock from 'components/templates/admin/hhaDoctor';

const AdminAgencyDoctorInfo = () => (
  <Container>
    <AdminHeader />
    <AdminAgencyDoctorBlock />
  </Container>
);

export default AdminAgencyDoctorInfo;
