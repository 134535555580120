import BaseForm from 'components/atoms/base-form';
import BaseFormGroup from 'components/atoms/base-form/group';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseGrid from 'components/atoms/base-grid';
import { useFormik } from 'formik';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import AgencyDoctorSelectBox from 'components/molecules/agency-doctor-select-box';
import { Roles } from 'infrastructure/enums';
import useUser from 'utils/useUser';
import { mergeFieldsValue } from 'infrastructure/functions';

import { clinicRepresentativesValidationSchema } from './validation-schema';

import type { Clinic } from 'infrastructure/classes/clinic';
import type { IClinicRepresentatives } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const prepareValue = (data?: Clinic): IClinicRepresentatives => {
  let defaultValue: IClinicRepresentatives = {
    clientSuccessOnboardingGuid: null,
    salesRepresentativeGuid: null,
    clientSuccessScalingGuid: null,
  };
  if (data) {
    defaultValue = mergeFieldsValue(defaultValue, data);
  }

  return defaultValue;
};

const FormDisplayName = 'ClinicRepresentativesForm';

interface IProps extends IIndependentFormProps {
  data?: Clinic;
}

const ClinicRepresentativesForm = forwardRef<
  IIndependentFormRef<IClinicRepresentatives>,
  IProps
>((props, ref) => {
  const { data } = props;

  const { t } = useTranslation();

  const user = useUser();
  const canEdit = !user.isPractice;

  const formik = useFormik<IClinicRepresentatives>({
    initialValues: prepareValue(data),
    onSubmit: () => {},
    validateOnChange: false,
    validationSchema: clinicRepresentativesValidationSchema(),
  });

  const submitForm = async () => {
    await formik.submitForm();
    const isValid = await formik
      .validateForm()
      .then((res) => !Object.keys(res).length);

    return {
      values: formik.values,
      formName: FormDisplayName,
      isValid,
    };
  };

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: formik.resetForm,
  }));

  useEffect(() => {
    if (data) formik.resetForm({ values: prepareValue(data) });
  }, [data]);

  return (
    <BaseForm formik={formik}>
      <BaseFormGroup label={t('labels.representatives')}>
        <BaseGrid columns={2}>
          <BaseFormItem
            name="clientSuccessOnboardingGuid"
            label={t('labels.clinicSuccessOnboarding')}
            isBlackLabel
          >
            <AgencyDoctorSelectBox
              allowClear
              roles={[Roles.ClientSuccessOnboarding]}
              disabled={!canEdit}
            />
          </BaseFormItem>
          <BaseFormItem
            name="salesRepresentativeGuid"
            label={t('labels.sales')}
            isBlackLabel
          >
            <AgencyDoctorSelectBox
              allowClear
              roles={[Roles.SalesRepresentative]}
              disabled={!canEdit}
            />
          </BaseFormItem>
          <BaseFormItem
            name="clientSuccessScalingGuid"
            label={t('labels.clinicSuccessScaling')}
            isBlackLabel
          >
            <AgencyDoctorSelectBox
              allowClear
              roles={[Roles.ClientSuccessScaling]}
              disabled={!canEdit}
            />
          </BaseFormItem>
        </BaseGrid>
      </BaseFormGroup>
    </BaseForm>
  );
});

ClinicRepresentativesForm.displayName = FormDisplayName;

export default ClinicRepresentativesForm;
