import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import useApi from '../use-api';

import type { IPatientDiagnosis } from 'infrastructure/interfaces';

const patientDiagnosisApi = (patientGuid: string) =>
  `admins/admin-panel/patients/${patientGuid}/patient-diagnosis`;

interface IUsePatientDiagnosis {
  loading: boolean;
  loadPatientDiagnosis: () => Promise<IPatientDiagnosis | void>;
  updatePatientDiagnosis: (body: IPatientDiagnosis) => Promise<void>;
}

interface IUsePatientDiagnosisProps {
  patientGuid: string;
}

export const usePatientDiagnosis = (
  props: IUsePatientDiagnosisProps,
): IUsePatientDiagnosis => {
  const { patientGuid } = props;

  const { loading, loadData, updateData } = useApi({ showError: false });

  const queryClient = useQueryClient();
  const queryKey = ['patientDiagnosis', patientGuid];

  const loadPatientDiagnosis = async () => {
    const data = await queryClient.fetchQuery(
      queryKey,
      () => loadData<IPatientDiagnosis>(patientDiagnosisApi(patientGuid)),
      { staleTime: STALE_TIME },
    );
    if (data) return data;
  };

  const updatePatientDiagnosis = async (body: IPatientDiagnosis) => {
    const data = await updateData<IPatientDiagnosis>(
      patientDiagnosisApi(patientGuid),
      body,
    );
    queryClient.setQueryData(queryKey, data);
  };

  return {
    loading,
    loadPatientDiagnosis,
    updatePatientDiagnosis,
  };
};
