import { prepareDateRange } from './format-date';

import type { DateRange } from 'infrastructure/types';

export const parseLogValue = (str?: string) =>
  str ? str.split('_').join(' ') : '';

export const prepareLogsDateRange = (dateRange?: DateRange) => {
  let startTime;
  let endTime;
  if (dateRange && dateRange.length) {
    [startTime, endTime] = prepareDateRange(dateRange);
  }

  return {
    startTime: startTime ? startTime.getTime() : undefined,
    endTime: endTime ? endTime.getTime() : undefined,
  };
};
