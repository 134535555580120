import dataService from 'services/dataService';
import { apiPatientCCMClaimStatusLogs } from 'services/dataService/resources';
import { createModel } from '@rematch/core';

import type { RootModel } from 'store/models';

const ITEMS_PER_PAGE = 10;

interface ICCMClaimStatusLogData {
  oldValue: string;
  newValue: string;
  patient: {
    firstName: string;
    lastName: string;
  };
  createdAt: string;
  haveUpdatedBy: boolean;
  updatedByLogin: {
    title: string;
    professionType: string;
    firstName: string;
    lastName: string;
  };
}

interface IState {
  page: number;
  pageStep: number;
  reversed: boolean;
  totalCount: number;
  totalPages: number;
  data: ICCMClaimStatusLogData[];
}

const INITIAL_STATE: IState = {
  page: 1,
  pageStep: ITEMS_PER_PAGE,
  reversed: false,
  totalCount: 1,
  totalPages: 0,
  data: [],
};

interface IPaginationOptions {
  pageOffset: number;
}

interface ICCMClaimStatusResponse {
  count: number;
  data: ICCMClaimStatusLogData[];
}

const patientCCMClaimStatusLogs = createModel<RootModel>()({
  state: INITIAL_STATE,
  reducers: {
    setPatientCCMClaimStatusLogs: (
      state,
      payload: ICCMClaimStatusResponse['data'],
    ) => ({
      ...state,
      data: [...payload],
    }),
    setPage: (state, payload: number) => ({
      ...state,
      page: payload,
    }),
    setTotalCountAndTotalPages: (
      state,
      payload: { totalCount: number; totalPages: number },
    ) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    toggleReversed: (state) => ({
      ...state,
      reversed: !state.reversed,
    }),
  },
  effects: (dispatch) => ({
    async getPatientCCMClaimStatusLogs(patientGuid: string, state) {
      const { page, reversed } = state.patientCCMClaimStatusLogs;
      const { data } = await dataService.getList<ICCMClaimStatusResponse>(
        apiPatientCCMClaimStatusLogs(patientGuid, page, reversed),
      );

      dispatch.patientCCMClaimStatusLogs.setPatientCCMClaimStatusLogs(
        data?.data || [],
      );
      const totalCount = data?.count || 0;
      const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);

      dispatch.patientCCMClaimStatusLogs.setTotalCountAndTotalPages({
        totalCount,
        totalPages,
      });
    },
    async setPagePatientCCMClaimStatusLogs(
      { pageOffset }: IPaginationOptions,
      state,
    ) {
      const { page, totalPages } = state.patientCCMClaimStatusLogs;
      const newPage = page + pageOffset;
      dispatch.patientCCMClaimStatusLogs.setPage(
        newPage <= totalPages ? newPage : totalPages,
      );
    },
    async toggleOrder() {
      dispatch.patientCCMClaimStatusLogs.toggleReversed();
      dispatch.patientCCMClaimStatusLogs.setPage(1);
    },
  }),
});

export default patientCCMClaimStatusLogs;
