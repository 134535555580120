import React from 'react';

const ArrowDownIcon = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.99503 0.771185L13.0773 0.777342C13.2843 0.777468 13.4531 0.946338 13.4539 1.15396L13.4926 11.2688C13.4968 12.3569 12.6177 13.2417 11.533 13.241C10.4484 13.2404 9.56254 12.3545 9.55838 11.2664L9.54352 7.38441L3.30965 13.6587C2.58124 14.3918 1.39047 14.3911 0.656441 13.6571C-0.0775871 12.923 -0.0821592 11.7284 0.646254 10.9953L6.88015 4.72099L3.0107 4.71863C1.92605 4.71797 1.04021 3.83213 1.03604 2.74397C1.03133 1.65636 1.91094 0.771046 2.99503 0.771185Z"
      fill="#84E075"
    />
  </svg>
);

export default ArrowDownIcon;
