import { API_ADMIN_PANEL_AGENCY_DOCTORS } from 'services/dataService/resources';
import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { useState } from 'react';

export interface AgencyDoctor {
  guid: string;
  loginGuid: string;
  agencies: Agency[];
  title: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  timezone: string;
  email: string;
  mobileNumber: string | null;
  workNumber: string | null;
  npiNumber: string | null;
  professionType: string | null;
  primaryLanguage: string | null;
  secondaryLanguage: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  state: string | null;
  city: string | null;
  zip: string | null;
  alertEmail: boolean;
  alertSms: boolean;
  createdAt: string;
  roles: Role[];
  status: string;
  numberType: string;
  ratePerReading: number | null;
}

export interface Agency {
  guid: string;
  legalname: string;
}

export interface Role {
  name: string;
  guid: string;
}

const useAgencyDoctor = ({ guid }: { guid: string }) => {
  const [enabled, setIsEnabled] = useState(true);
  const { data, error, isLoading, remove } = useQuery({
    enabled,
    queryKey: [API_ADMIN_PANEL_AGENCY_DOCTORS, guid],
    queryFn: async () => {
      const { error: localError, data: localData } =
        await dataService.getOne<AgencyDoctor>(
          API_ADMIN_PANEL_AGENCY_DOCTORS,
          guid,
        );
      if (localError) {
        throw new Error(localError);
      }
      return localData;
    },
  });

  const disableAndRemoveCache = () => {
    setIsEnabled(false);
    remove();
  };

  return {
    agencyDoctor: data,
    isLoading,
    error,
    disableAndRemoveCache,
  };
};

export default useAgencyDoctor;
