import React from 'react';
import ConditionsTable from 'components/molecules/admin-panel/forms/conditions/table';

interface IAdminTabbedFormConditionsProps {
  patientGuid: string;
}

const AdminTabbedFormConditions: React.FC<IAdminTabbedFormConditionsProps> = (
  props,
) => {
  const { patientGuid } = props;

  return <ConditionsTable patientGuid={patientGuid} />;
};

export default AdminTabbedFormConditions;
