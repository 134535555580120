import PropTypes from 'prop-types';
import CsvUploadModal from 'components/molecules/csvUploadModal';
import { RESEND_EMR_CSV_REPORT } from 'services/dataService/resources';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';

const ResendEmrModal = ({ isOpen, toggle }) => {
  const uploadCsvFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const { error, data } = await dataService.uploadFile(
      RESEND_EMR_CSV_REPORT,
      formData,
      'POST',
    );

    if (error) {
      return { error };
    }
    if (data) {
      showResult();
      if (toggle) {
        toggle();
      }
    }
  };

  return (
    <CsvUploadModal
      isOpen={isOpen}
      toggle={toggle}
      title="Resend EMR Readings"
      sampleFileUrl="/csvTemplates/resendEmrTemplate.csv"
      uploadCsvFile={uploadCsvFile}
    />
  );
};

ResendEmrModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ResendEmrModal;
