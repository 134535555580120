import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import common from 'constants/common';

import BaseLoader from '../base-loader';

const ControlPanelButton = ({
  icon: Icon,
  fillColor,
  label,
  bracketLabel,
  status,
  handleClick,
  disabled,
  loading,
  className,
  title,
}) => {
  const iconFillColor = disabled
    ? common.disabledIconColor
    : common.activeIconColor;
  return (
    <button
      className={classnames('control-panel-button', className, {
        disabled,
      })}
      onClick={handleClick}
      disabled={disabled}
      data-cy={`control-panel-button-${label
        .toLowerCase()
        .split(' ')
        .join('-')}`}
      type="button"
      title={title}
    >
      {loading && (
        <div className="loader__wrapper">
          <BaseLoader height={30} width={30} loading />
        </div>
      )}
      <span className="control-panel-button__title">
        <span className="buttons">
          {label}
          {!loading && bracketLabel && (
            <b style={{ fontSize: '0.620rem' }}>{` (${bracketLabel})`}</b>
          )}
        </span>
        <span>{status}</span>
      </span>
      <Icon
        color={fillColor || iconFillColor}
        fill={fillColor || iconFillColor}
        height="18"
        width="18"
      />
    </button>
  );
};
ControlPanelButton.defaultProps = {
  disabled: false,
  loading: false,
};

ControlPanelButton.propTypes = {
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  bracketLabel: PropTypes.string,
  fillColor: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  status: PropTypes.any,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default ControlPanelButton;
