import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getSmsDashboardConversationsKey } from './useConversations';
import { useSmsDashboardUnreadCountKey } from './useUnreadConversationsCount';
import { getSmsDashboardMessagesKey } from './useMessages';

import type {
  ConversationType,
  IConversation,
  IConversationsListPages,
  IMessagesListPages,
  IUnreadConversationsCount,
} from 'infrastructure/interfaces';
import type { RootState } from 'store';

export interface ISocketData {
  conversationId: number;
  title: string;
  patient: {
    patientGuid: string;
    patientPhone: string;
    email: string;
    fullNameWithOnlyTitle: string;
  };
  clinic: {
    guid: string;
    legalname: string;
  };
  doctorGuid: string;
  notes: string | null;
  lastMessage: string;
  lastMessageDate: string;
  read: boolean;
  type: ConversationType;
  closed: boolean;
  unreadMessageCount: number;
  hasPatientMessage: boolean;
  patientCurrentClinicGuid: string;
  createdAt: string;
  updatedAt: string;
  message: {
    id: string;
    authorType: string;
    authorGuid: string;
    body: string;
    status: string;
    action: string;
    read: boolean;
    from: string;
    createdAt: string;
  };
}

export const useNewMessageBroadcast = () => {
  const queryClient = useQueryClient();
  const { currentConversation, conversationType, filters } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const newMessageBroadcast = (socketData: ISocketData) => {
    const сonversationKey = getSmsDashboardConversationsKey({
      type: conversationType,
      read: filters.read,
      closed: filters.closed,
      lastMessageDate: filters.lastMessageDate,
      searchQuery: filters.searchQuery,
    });

    const messagesKey = getSmsDashboardMessagesKey({
      conversationId: socketData.conversationId,
      cursor: undefined,
      searchQuery: undefined,
    });

    const smsDashboardUnreadCountKey = useSmsDashboardUnreadCountKey();

    if (conversationType !== socketData.type) {
      if (
        socketData.message.authorType === 'patient' &&
        socketData.unreadMessageCount === 1
      ) {
        queryClient.setQueryData<IUnreadConversationsCount>(
          smsDashboardUnreadCountKey,
          (oldData) => {
            if (!oldData) return;
            return {
              ...oldData,
              [`${socketData.type}TotalUnreadCount`]:
                oldData[`${socketData.type}TotalUnreadCount`] + 1,
            };
          },
        );
      }
      return;
    }

    const conversations =
      queryClient.getQueryData<IConversationsListPages>(сonversationKey);

    const pages = conversations?.pages ?? [];
    const [firstPage, ...restPages] = pages;

    let foundConversation;

    pages.forEach((page) => {
      const index = page.items.findIndex(
        (item: IConversation) => item.id === socketData.conversationId,
      );
      if (index !== -1) {
        [foundConversation] = page.items.splice(index, 1);

        if (
          socketData.message.authorType === 'patient' &&
          socketData.unreadMessageCount === 1
        ) {
          const newUnreadCount =
            socketData.unreadMessageCount -
            foundConversation.unreadMessageCount;

          queryClient.setQueryData<IUnreadConversationsCount>(
            smsDashboardUnreadCountKey,
            (oldData) => {
              if (!oldData) return;
              return {
                ...oldData,
                [`${conversationType}TotalUnreadCount`]:
                  oldData[`${conversationType}TotalUnreadCount`] +
                  newUnreadCount,
              };
            },
          );
        }
      }
    });

    if (!foundConversation) {
      foundConversation = socketData;
      if (socketData.message.authorType === 'patient') {
        queryClient.setQueryData<IUnreadConversationsCount>(
          smsDashboardUnreadCountKey,
          (oldData) => {
            if (!oldData) return;
            return {
              ...oldData,
              [`${conversationType}TotalUnreadCount`]:
                oldData[`${conversationType}TotalUnreadCount`] +
                socketData.unreadMessageCount,
            };
          },
        );
      }
    }

    firstPage.items = [
      {
        ...socketData,
        id: socketData.conversationId,
        lastMessageDate: socketData.updatedAt,
      },
      ...firstPage.items,
    ];

    const updatedPages = [firstPage, ...restPages];

    queryClient.setQueryData<IConversationsListPages>(
      сonversationKey,
      (oldConversations) => {
        if (!oldConversations) return;
        return { ...oldConversations, pages: updatedPages };
      },
    );

    if (currentConversation.id === socketData.conversationId) {
      queryClient.setQueryData<IMessagesListPages>(
        messagesKey,
        (oldMessages) => {
          if (!oldMessages) return;
          return {
            ...oldMessages,
            pages: [
              {
                ...oldMessages.pages[0],
                items: [
                  {
                    ...oldMessages.pages[0].items[0],
                    ...socketData.message,
                    messageId: socketData.message.id,
                    updatedAt: new Date(socketData.message.createdAt),
                    createdAt: new Date(socketData.message.createdAt),
                    body: socketData.message.body,
                  },
                  ...oldMessages.pages[0].items,
                ],
              },
              ...oldMessages.pages,
            ],
          };
        },
      );
    }
  };

  return { newMessageBroadcast };
};
