import React, { useCallback, useState } from 'react';
import BaseAsyncSelectBox from 'components/atoms/base-select-box/async';
import { useDoctorAndAgencyDoctorSelect } from 'infrastructure/hooks/select/use-doctor-and-agency-doctor';
import { useTranslation } from 'react-i18next';
import { LoginStatus } from 'infrastructure/enums/login-status';

import s from './styles.module.scss';

import type { Roles } from 'infrastructure/enums';
import type { DoctorAndAgencyDoctorSelect } from 'infrastructure/classes/select/doctor-and-agency-doctor';
import type { IPaginationMeta } from 'infrastructure/interfaces';
import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';

type AgencyDoctorSelectOption = IBaseSelectBoxOption<
  string,
  DoctorAndAgencyDoctorSelect
>;

interface IDoctorAndAgencyDoctorSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {
  showInactiveWarning?: boolean;
  patientGuid: string;
  roles?: Roles[];
  onChange?: (value: string, option: AgencyDoctorSelectOption) => void;
  onLoaded?: (
    items: Array<AgencyDoctorSelectOption>,
    selected?: AgencyDoctorSelectOption,
  ) => void;
}

const DoctorAndAgencyDoctorSelectBox: React.FC<
  IDoctorAndAgencyDoctorSelectBoxProps
> = (props) => {
  const {
    patientGuid,
    onLoaded,
    onChange,
    showInactiveWarning = true,
    roles,
  } = props;

  const { t } = useTranslation();

  const { meta, loadDoctorsAndAgencyDoctors } = useDoctorAndAgencyDoctorSelect({
    patientGuid,
  });

  const [isInactive, setIsInactive] = useState<boolean>(false);

  const fetchOptions = useCallback(
    async (search: string, metaProps: IPaginationMeta) => {
      const agencyDoctors = await loadDoctorsAndAgencyDoctors({
        ...metaProps,
        search,
        roles,
      });

      if (agencyDoctors)
        return agencyDoctors.map((el) => {
          return {
            label: el.label,
            value: el.guid,
            record: el,
          };
        });

      return [];
    },
    [patientGuid],
  );

  const onValueLoaded = (
    items: Array<AgencyDoctorSelectOption>,
    selected?: AgencyDoctorSelectOption,
  ) => {
    if (selected?.record?.status === LoginStatus.Inactive) {
      setIsInactive(true);
    } else {
      setIsInactive(false);
    }
    if (onLoaded) onLoaded(items, selected);
  };
  const onValueChange = (value: string, option: AgencyDoctorSelectOption) => {
    if (option?.record?.status === LoginStatus.Inactive) {
      setIsInactive(true);
    } else {
      setIsInactive(false);
    }
    if (onChange) onChange(value, option);
  };

  return (
    <div className={s.wrapper}>
      <BaseAsyncSelectBox
        fetchOptions={fetchOptions}
        meta={meta}
        canSearch
        {...props}
        onValueLoaded={onValueLoaded}
        onChange={onValueChange}
      />
      {showInactiveWarning && isInactive && (
        <div className={s.error}>*{t('errors.currentlyInactive')}</div>
      )}
    </div>
  );
};

export default DoctorAndAgencyDoctorSelectBox;
