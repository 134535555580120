import { Formik, Form } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/atoms/button';
import isEmpty from 'lodash/isEmpty';
import WizardSection from 'components/templates/admin/internalAdmissonForm/controls/WizardSection';
import FormFieldChangeObserver from 'components/templates/admin/internalAdmissonForm/observers/FormFieldChangeObserver';
import {
  getTmphPatientQuestionnaireInitialValues,
  hasTexasInsurance,
  mapPatientToFormFields,
} from 'components/templates/admin/internalAdmissonForm/helpers';
import validationSchema from 'components/templates/admin/internalAdmissonForm/schema';
import { scrollToErrorIfExist } from 'utils';

const InternalAdmissionForm = ({
  onSetStep,
  onSubmit,
  onUpdateForms,
  step,
  forms,
  totalSteps,
  patient,
  loading,
}) => {
  const showWizard = !isEmpty(forms);

  const { title, fields } = showWizard
    ? Object.values(forms)[step - 1]
    : { title: '', fields: {} };

  const goToPrevStep = () => onSetStep(step - 1);
  const goToNextStep = () => onSetStep(step + 1);

  return (
    <>
      <div className="admin-patient">
        <Formik
          onSubmit={onSubmit}
          initialValues={mapPatientToFormFields(patient)}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
        >
          {({ errors, values, setValues, handleSubmit, validateForm }) => (
            <Form>
              <div className="internal-admission-form">
                {showWizard && (
                  <WizardSection
                    title={title}
                    fields={fields}
                    errors={errors}
                    values={values}
                  />
                )}
              </div>
              <div className="buttons-wrapper">
                {step !== 1 && (
                  <Button
                    type="button"
                    handleClick={() => goToPrevStep()}
                    text="< Back"
                    fillBg={false}
                  />
                )}
                {step === totalSteps ? (
                  <Button
                    type="button"
                    disabled={!isEmpty(errors) || loading}
                    text={loading ? 'Sending...' : 'Submit'}
                    onClick={(e) =>
                      validateForm().then((errorsFields) =>
                        isEmpty(errorsFields)
                          ? handleSubmit(e)
                          : scrollToErrorIfExist(),
                      )
                    }
                  />
                ) : (
                  <Button
                    type="button"
                    handleClick={() => {
                      validateForm().then((errorsFields) =>
                        isEmpty(errorsFields)
                          ? goToNextStep()
                          : scrollToErrorIfExist(),
                      );
                    }}
                    text="Forward >"
                  />
                )}
              </div>
              <FormFieldChangeObserver
                name="insurances"
                onFieldChange={(fieldValue) => {
                  const hasTexas = hasTexasInsurance(fieldValue);
                  if (!hasTexas) {
                    setValues({
                      ...values,
                      ...getTmphPatientQuestionnaireInitialValues(),
                    });
                  }
                  onUpdateForms(hasTexas);
                }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
InternalAdmissionForm.propTypes = {
  forms: PropTypes.shape({}),
  onUpdateForms: PropTypes.func,
  step: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  onSetStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  patient: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
};
InternalAdmissionForm.defaultProps = {
  loading: false,
};
export default InternalAdmissionForm;
