import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

const FormFieldChangeObserver = ({ name, onFieldChange }) => {
  const { values } = useFormikContext();
  const fieldValue = getIn(values, name);

  useEffect(() => {
    onFieldChange(fieldValue);
  }, [fieldValue]);

  return <></>;
};
FormFieldChangeObserver.propTypes = {
  name: PropTypes.string.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default FormFieldChangeObserver;
