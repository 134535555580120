import React from 'react';

import BaseLoader from '../base-loader';
import s from './styles.module.scss';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface IBaseTableLoaderProps extends IBaseComponentProps {
  loading: boolean;
}
const BaseTableLoader: React.FC<IBaseTableLoaderProps> = (props) => {
  const { loading, children } = props;
  return (
    <>
      {loading ? (
        <tr className={s['hidden-row']}>
          <td>
            <BaseLoader loading={loading} relative />
          </td>
        </tr>
      ) : (
        children
      )}
    </>
  );
};

export default BaseTableLoader;
