import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import {
  DateFormatter,
  endOfDate,
  startOfDate,
} from 'infrastructure/functions';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

import s from './styles.module.scss';

import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/ccmAgentDashboard/types';
import type { FC } from 'react';
import type { DateRange } from 'infrastructure/types';

interface Props {
  filters: Filters;
  appendFilters: AppendFilters;
}

const NextAppointmentDateRange: FC<Props> = ({ appendFilters, filters }) => {
  const changeTimeRange = async (range?: DateRange) => {
    if (range) {
      appendFilters({
        nextAppointmentDateFrom: startOfDate(range[0]).toISOString(),
        nextAppointmentDateTo: endOfDate(range[1]).toISOString(),
      });
      return;
    }
    appendFilters({
      nextAppointmentDateFrom: undefined,
      nextAppointmentDateTo: undefined,
    });
  };

  const selectedRange: [string, string] | undefined =
    filters.nextAppointmentDateTo && filters.nextAppointmentDateFrom
      ? [
          DateFormatter({ date: new Date(filters.nextAppointmentDateFrom) }),
          DateFormatter({ date: new Date(filters.nextAppointmentDateTo) }),
        ]
      : undefined;

  const { t } = useTranslation();

  dayjs.extend(updateLocale);
  dayjs.updateLocale('en', {
    weekStart: 1,
  });

  const presets = [
    {
      label: <span data-cy="ranges">{t('dates.thisWeek')}</span>,
      value: [dayjs().startOf('w').toDate(), dayjs().endOf('w').toDate()] as [
        Date,
        Date,
      ],
    },
    {
      label: <span data-cy="ranges">{t('dates.nextWeek')}</span>,
      value: [
        dayjs().add(7, 'd').startOf('w').toDate(),
        dayjs().add(7, 'd').endOf('w').toDate(),
      ] as [Date, Date],
    },
    {
      label: <span data-cy="ranges">{t('dates.currentMonth')}</span>,
      value: [dayjs().startOf('M').toDate(), dayjs().endOf('M').toDate()] as [
        Date,
        Date,
      ],
    },
    {
      label: <span data-cy="ranges">{t('dates.previousMonth')}</span>,
      value: [
        dayjs().add(-1, 'M').startOf('M').toDate(),
        dayjs().add(-1, 'M').endOf('M').toDate(),
      ] as [Date, Date],
    },
    {
      label: <span data-cy="ranges">{t('dates.twoMonthsPast')}</span>,
      value: [
        dayjs().add(-2, 'M').startOf('M').toDate(),
        dayjs().add(-2, 'M').endOf('M').toDate(),
      ] as [Date, Date],
    },
    {
      label: <span data-cy="ranges">{t('dates.threeMonthsPast')}</span>,
      value: [
        dayjs().add(-3, 'M').startOf('M').toDate(),
        dayjs().add(-3, 'M').endOf('M').toDate(),
      ] as [Date, Date],
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <div className={s['next-appointment-date-range']}>
        <label htmlFor="nextAppointmentDate">
          {t('labels.nextAppointmentDate')}
        </label>
        <div className={s['input-wrapper']}>
          <BaseRangeDatepicker
            withPresets
            presets={presets}
            defaultValue={selectedRange}
            onChange={(dates) => changeTimeRange(dates)}
          />
        </div>
      </div>
    </div>
  );
};

export default NextAppointmentDateRange;
