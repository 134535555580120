import { useMutation } from '@tanstack/react-query';

import { sendDoctorSmsFn } from './api';

export const useDoctorSendSms = () => {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: sendDoctorSmsFn,
  });

  return { sendDoctorSms: mutateAsync, doctorSmsLoading: isLoading };
};
