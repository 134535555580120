import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import ChatEmptyIcon from 'components/atoms/icons/chatEmptyIcon';
import Avatar from 'components/atoms/chat/avatar';
import 'react-loading-skeleton/dist/skeleton.css';
import { PlatformEnums } from 'infrastructure/enums/platformEnums';
import BaseButton from 'components/atoms/baseButton';
import EmailCreateIcon from 'components/atoms/icons/emailCreateIcon';
import { usePatientSearch } from 'infrastructure/hooks/patient/use-patient-search';

import type {
  PatientOptionType,
  PatientSearchInputOptionType,
  PatientSearchPatientType,
} from 'infrastructure/interfaces';

interface IPatientSearchProps {
  value: any;
  setValue: (data: PatientOptionType) => void;
  disabled: boolean;
  onClick: (data: PatientOptionType) => void;
  conversationType: string;
}

const generateLabel = (item: PatientSearchPatientType) => ({
  fullName: ` ${item.firstName} ${item.lastName} `,
  phone: `${item.phone}`,
  birthDate: `${item.birthDate}`,
});

const debouncedSearch = debounce(async ({ searchQuery, setValue }) => {
  setValue({
    phoneNumber: searchQuery,
  });
}, 800);

const PatientSearch: React.FC<IPatientSearchProps> = (props) => {
  const { value, setValue, disabled, onClick, conversationType } = props;
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();

  const { patientSearch, isFetching, isLoading } = usePatientSearch({
    searchQuery: value,
  });

  const handleOptionClick = (option: PatientSearchInputOptionType) => {
    onClick({ phoneNumber: option.value, patientGuid: option.id });
  };

  const handleInputChange = (string: string) => {
    if (conversationType === PlatformEnums.UNREGISTERED) {
      const inputValue =
        string !== ''
          ? `+${string
              .replace(/[a-zA-Z ]/g, '')
              .replace(/\+/g, '')
              .slice(0, 11)}`
          : '';

      setSearchValue(inputValue);

      if (
        (inputValue !== '+' && inputValue.length === 12) ||
        inputValue.length === 0
      ) {
        debouncedSearch({
          searchQuery: inputValue,
          setValue,
        });
      }
    }
    if (conversationType !== PlatformEnums.UNREGISTERED) {
      setSearchValue(string);

      debouncedSearch({
        searchQuery: string,
        setValue,
      });
    }
  };

  const isPatientFound = !isFetching && !isLoading && patientSearch?.length > 0;
  const isPatientNotFound = !isFetching && patientSearch?.length === 0;
  const isUnregisteredConversation =
    conversationType === PlatformEnums.UNREGISTERED;

  const searchInputPlaceholder = isUnregisteredConversation
    ? t('smsDashboard.type_number')
    : t('smsDashboard.type_number_or_name');

  const notFoundEmptyMessage = isUnregisteredConversation
    ? searchInputPlaceholder
    : t('smsDashboard.unregistered_phone_number_message');

  const emptyMessage =
    value.length > 0 ? notFoundEmptyMessage : searchInputPlaceholder;

  return (
    <div className="patient-name-phone">
      <input
        className="chat-input new-conversation-input"
        placeholder={searchInputPlaceholder}
        type="text"
        onChange={(e) => handleInputChange(e.target.value)}
        value={searchValue}
        disabled={disabled}
      />
      {isPatientFound && !isUnregisteredConversation && (
        <div className="patient-list">
          {!isUnregisteredConversation &&
            patientSearch?.map((option: PatientSearchPatientType) => (
              <div className="patient-item" key={option.guid}>
                <div className="patient-item-info">
                  <Avatar
                    patientName={generateLabel(option).fullName}
                    width={32}
                  />
                  <div className="patient-item-data">
                    <div className="patient-item-name">
                      <span>{generateLabel(option).fullName}</span>
                      <span className="patient-item-birthdate">
                        {generateLabel(option).birthDate &&
                          generateLabel(option).birthDate}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="patient-item-phone">
                  <div>Primary number:</div>
                  {option.phone ? (
                    <>
                      <div className="patient-item-phone-number">
                        &ensp;{option.phone}
                      </div>
                      <BaseButton
                        icon={<EmailCreateIcon />}
                        type="secondary"
                        onClick={() =>
                          handleOptionClick({
                            ...option,
                            value: option.phone,
                          })
                        }
                        disabled={!option.phone || disabled}
                      />
                    </>
                  ) : (
                    <div className="patient-item-phone-number">&ensp;-</div>
                  )}
                </div>
                <div className="patient-item-phone">
                  <div>Secondary number:</div>
                  {option.homeNumber ? (
                    <>
                      <div className="patient-item-phone-number">
                        &ensp;{option.homeNumber}
                      </div>
                      <BaseButton
                        icon={<EmailCreateIcon />}
                        type="secondary"
                        onClick={() =>
                          handleOptionClick({
                            ...option,
                            value: option.homeNumber,
                          })
                        }
                        disabled={!option.homeNumber || disabled}
                      />
                    </>
                  ) : (
                    <div className="patient-item-phone-number">&ensp;-</div>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
      {isFetching && (
        <div className="patient-list">
          {[...Array(7)].map((_, index) => (
            <div className="patient-item" key={index}>
              <div className="patient-item-info">
                <Skeleton circle height={32} width={32} />
                <div className="patient-item-data">
                  <div className="patient-item-name">
                    <Skeleton height={14} width={160} />
                    <Skeleton height={16} width={140} />
                  </div>
                </div>
              </div>

              <div className="patient-item-numbers">
                <div className="patient-item-phone">
                  <Skeleton height={16} width={140} />
                  <Skeleton height={14} width={160} />
                </div>
                <div className="patient-item-phone">
                  <Skeleton height={16} width={140} />
                  <Skeleton height={14} width={160} />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {isPatientFound && isUnregisteredConversation && value.length === 0 && (
        <div className="empty">
          <ChatEmptyIcon />
          <div className="empty-message">
            <div>{searchInputPlaceholder}</div>
          </div>
        </div>
      )}
      {isPatientFound && isUnregisteredConversation && value.length > 0 && (
        <div className="empty">
          <ChatEmptyIcon />
          <div className="empty-message">
            <div>{t('smsDashboard.patient_number_exist_message')}</div>
          </div>
        </div>
      )}
      {isPatientNotFound && isUnregisteredConversation && (
        <div className="create-unregistered">
          <p>{t('smsDashboard.new_conversation')}</p>
          <p>"{value}"</p>
          <button onClick={() => handleOptionClick({ value, id: undefined })}>
            {t('smsDashboard.start_conversation')}
          </button>
        </div>
      )}

      {isPatientNotFound && !isUnregisteredConversation && (
        <div className="empty">
          <ChatEmptyIcon />
          <div className="empty-message">
            <div>{emptyMessage}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientSearch;
