import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { apiPatientDetails } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface Props {
  patientGuid: string;
}

export interface IPatientDetails {
  profile: {
    guid: string;
    loginGuid: string;
    title: string;
    middleName: string | null;
    gender: string | null;
    preferredContactMethod: string;
    homeNumber: string | null;
    address: string | null;
    city: string | null;
    state: string | null;
    zipPostalCode: string | null;
    race: string | null;
    ethnicity: string | null;
    workingStatus: string | null;
    martialStatus: string | null;
    covidStatus: string;
    emergencyContactFirstName: string | null;
    emergencyContactLastName: string | null;
    emergencyContactRelationship: string | null;
    emergencyContactNumber: string | null;
    status: string;
    claimStatus: string;
    ccmClaimStatus: string;
    interactionStatus: boolean;
    ccmInteractionStatus: boolean;
    ccmStatus: string;
    pcmStatus: boolean;
    acceptPvrLite: boolean;
    doNotCall: boolean;
    firstName: string;
    lastName: string;
    deviceOrderSpecifications: string;
    phone: string;
    email: string;
    birthDate: string;
    language: string;
    appointmentsFlagActive: boolean;
    ordersFlagActive: boolean;
    timezone: string;
    holdingStatus: {
      status: string;
      endTime: any;
    };
    auth0Enabled: boolean;
    disabledCreateInternalAdmissionForms: boolean;
    primaryNumberType: string;
    secondaryNumberType: string;
    emergencyContactNumberType: string;
    emailSubscription: boolean;
    weekMealStatus: string;
  };
  clinic: {
    clinicGuid: string;
    legalname: string;
    email: string;
    phone: string | null;
    fax: string;
    addressLine1: string;
    addressLine2: string | null;
    city: string;
    state: string;
    zip: string;
    npiNumber: string;
    stateIdNumber: string;
    taxIdNumber: string;
    medicareNumber: string;
    status: string;
    onCallAfterHoursNumber: string | null;
    instructions: string | null;
    notes: string;
    dateNoteLogged: string;
    officeHours: string | null;
    providerServiceType: string[];
    serviceDeliveryModel: string;
    serviceDeliveryThresholds: number;
    referralTypes: string[];
  };
  doctor: {
    doctorGuid: string;
    firstName: string;
    lastName: string;
    title: string;
    phone: string | null;
    mobileNumber: string;
    workNumber: string;
    professionType: string | null;
    status: string;
  };
  emr: Record<string, unknown>;
  thresholds: any;
  diagnosis: Record<string, unknown>;
  devices: any[];
  priorAuths: any[];
  insurances: any[];
  careAgents: any[];
  icdCodes: any[];
}

export const getSmsDashboardPatientInfoKey = (patientGuid: string) => [
  'getSmsDashboardPatientInfo',
  patientGuid,
];

export const useSmsDashobardPatientInfo = ({ patientGuid }: Props) => {
  const {
    data: patientInfo,
    refetch,
    fetchStatus,
    isRefetching,
  } = useQuery({
    queryKey: getSmsDashboardPatientInfoKey(patientGuid),
    queryFn: async () => {
      const { data, error } = await dataService.getList<IPatientDetails>(
        apiPatientDetails(patientGuid),
      );
      if (error) {
        showResult(
          'Unable to retrieve patient details due to lack of privileges',
        );
        throw new Error(error);
      }

      return data;
    },
    enabled: !!patientGuid,
  });

  return {
    patientInfo,
    fetchPatientInfo: refetch,
    patientInfoLoading: fetchStatus === 'fetching' || isRefetching,
  };
};
