import { DateFormats } from 'infrastructure/enums';
import { DateFormatter } from 'infrastructure/functions';

import type { IRunbookLog } from 'infrastructure/interfaces';
import type { DateString, Nullable } from 'infrastructure/types';

export class RunbookLog {
  afterHours: Nullable<string>;
  businessHours: Nullable<string>;
  contactMethod: Nullable<string>;
  contactMethodValue: Nullable<string>;
  createdBy: Nullable<string>;
  criticalAfterHours: Nullable<string>;
  criticalBusinessHours: Nullable<string>;
  dateNoteLogged: Nullable<string>;
  notes: Nullable<string>;
  createdAt: DateString;

  constructor(data: IRunbookLog, createdAt: DateString) {
    let contactMethod = data.contactMethod ?? '';
    if (data.contactMethodValue) {
      contactMethod = contactMethod
        ? `${contactMethod}: ${data.contactMethodValue}`
        : `${data.contactMethodValue}`;
    }

    this.afterHours = data.afterHours;
    this.businessHours = data.businessHours;
    this.contactMethod = contactMethod;
    this.contactMethodValue = data.contactMethodValue;
    this.createdBy = data.createdBy;
    this.criticalAfterHours = data.criticalAfterHours;
    this.criticalBusinessHours = data.criticalBusinessHours;
    this.dateNoteLogged = data.dateNoteLogged;
    this.notes = data.notes;

    this.createdAt = DateFormatter({
      date: createdAt,
      format: DateFormats['MM-dd-yy | h:mm a'],
    });
  }
}
