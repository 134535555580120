import useApi from '../use-api';

import type { IClinic, ICreateClinicSchema } from 'infrastructure/interfaces';

const apiUrl = 'admins/admin-panel/clinics';

type UseCreateClinic = {
  loading: boolean;
  createClinic: (data: ICreateClinicSchema) => Promise<IClinic>;
};

export const useCreateClinic = (): UseCreateClinic => {
  const { loading, createData } = useApi();

  const createClinic = async (clinic: ICreateClinicSchema) => {
    const data = await createData<IClinic>(apiUrl, clinic);

    return data;
  };

  return { loading, createClinic };
};
