/* eslint-disable react/destructuring-assignment */
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import BaseLoader from 'components/atoms/base-loader';
import { Tooltip } from 'antd';
import i18n from 'infrastructure/i18n';
import PropTypes from 'prop-types';

const NoTooltipWrapper = ({ children, showTooltip }) => {
  return (
    <Tooltip
      placement="top"
      trigger="hover"
      title={showTooltip && i18n.t('messages.canNotUnconfirmedConsentForm')}
    >
      {children}
    </Tooltip>
  );
};

NoTooltipWrapper.propTypes = {
  showTooltip: PropTypes.bool,
  children: PropTypes.node,
};

const Content = (patient) => {
  const { patientGuid } = useParams();
  const { canPressNo } = window.history.state ?? {};

  const {
    ccmPatients: { setIsAgreed, setIsRejected, setConsentFormAnswer },
  } = useDispatch();

  const ccmConsentFormStatus = patient?.patient?.profile?.ccmConsentFormStatus;

  const isAgreed = useSelector(
    (state) => state.ccmPatients.ccmConsentFormAgreed,
  );
  const isRejected = useSelector(
    (state) => state.ccmPatients.ccmConsentFromRejected,
  );

  const [ccmStatus, setCcmStatus] = useState(ccmConsentFormStatus);
  const [loading, setLoading] = useState(false);

  const handleOnChange = async (value) => {
    setCcmStatus(null);
    setLoading(true);
    value === 'true' ? setIsAgreed(true) : setIsRejected(true);
    await setConsentFormAnswer({ patientGuid, answer: value });
    window.history.replaceState({}, '');
    if (value === 'true') {
      window.location.href = `/admin/patient/${patientGuid}`;
    } else {
      window.location.href = `/admin/patient/${patientGuid}`;
    }
  };

  const consentInfo = patient.patient.profile?.consentFormData?.signedDate
    ? `Consent obtained by: ${
        patient.patient.profile?.consentFormData.signedBy?.title || ''
      }
  ${patient.patient.profile?.consentFormData.signedBy?.firstName || ''} ${
        patient.patient.profile?.consentFormData.signedBy?.lastName || ''
      }
  ${
    patient.patient.profile?.consentFormData.signedBy?.professionType || ''
  }, on 
  ${patient.patient.profile?.consentFormData?.signedDate}`
    : null;

  const patientConsentInfo = patient.patient.profile?.consentFormData
    ?.signedDate
    ? `Patient - Verbal Consent: ${patient.patient.profile?.title || ''}
    ${patient.patient.profile?.firstName || ''} ${
        patient.patient.profile?.lastName || ''
      }
    , on 
    ${patient.patient.profile?.consentFormData?.signedDate}`
    : null;

  const choice = () => {
    let answer;
    if (ccmStatus) {
      answer = (
        <div className="choice">
          <p>I agree to participate in the Chronic Care Management program.</p>
          <div>
            <input
              type="checkbox"
              id="yes"
              name="answer"
              value
              checked
              disabled={loading}
              onChange={(e) => handleOnChange(e.target.value)}
            />{' '}
            Yes
          </div>
          <div>
            <NoTooltipWrapper showTooltip={!canPressNo}>
              <input
                type="checkbox"
                disabled={loading || !canPressNo}
                id="no"
                name="answer"
                value={false}
                checked={false}
                onChange={(e) => handleOnChange(e.target.value)}
              />{' '}
            </NoTooltipWrapper>{' '}
            No
          </div>
          {loading && (
            <BaseLoader
              width={20}
              height={20}
              className="loader-consent"
              loading
            />
          )}
        </div>
      );
    } else if (ccmStatus === false) {
      answer = (
        <div className="choice">
          <p>I agree to participate in the Chronic Care Management program.</p>
          <div>
            <input
              type="checkbox"
              id="yes"
              name="answer"
              value
              checked={false}
              onChange={(e) => handleOnChange(e.target.value)}
              disabled={loading}
            />{' '}
            Yes
          </div>
          <div>
            <NoTooltipWrapper showTooltip={!canPressNo}>
              <input
                disabled={loading || !canPressNo}
                type="checkbox"
                id="no"
                name="answer"
                value={false}
                checked
                onChange={(e) => handleOnChange(e.target.value)}
              />
            </NoTooltipWrapper>{' '}
            No
          </div>
          {loading && (
            <BaseLoader
              width={20}
              height={20}
              className="loader-consent"
              loading
            />
          )}
        </div>
      );
    } else {
      answer = (
        <div className="choice">
          <p>I agree to participate in the Chronic Care Management program.</p>
          <div>
            <input
              type="checkbox"
              id="yes"
              name="answer"
              value
              disabled={loading}
              checked={isAgreed}
              onChange={(e) => handleOnChange(e.target.value)}
            />{' '}
            Yes
          </div>
          <div>
            <NoTooltipWrapper showTooltip={!canPressNo}>
              <input
                type="checkbox"
                id="no"
                name="answer"
                value={false}
                disabled={loading || !canPressNo}
                checked={isRejected}
                onChange={(e) => handleOnChange(e.target.value)}
              />
            </NoTooltipWrapper>{' '}
            No
          </div>
          {loading && (
            <BaseLoader
              width={20}
              height={20}
              className="loader-consent"
              loading
            />
          )}
        </div>
      );
    }

    return answer;
  };

  return (
    <div>
      <div className="information-wrapper">
        <p className="information">Informed Consent</p>
        <p className="information-text">
          You are eligible for a new Medicare program that enables us to provide
          you with around-theclock service to oversee your chronic conditions
          and improve your overall wellness. Chronic conditions are ongoing
          medical problems like diabetes, high blood pressure, dementia, heart
          disease, depression, osteoporosis, and many others. These conditions
          must be managed effectively in partnership between the healthcare team
          and patient to maintain the best possible overall health and wellness.
          CCM Services are available to you because you have been diagnosed with
          two (2) or more chronic conditions which are expected to last at least
          twelve (12) months and which place you at significant risk of further
          decline.
        </p>
      </div>
      <div className="benefits-wrapper">
        <p className="benefits">
          What are the benefits of signing up for Chronic Care Management
          Services?
        </p>
        <ul>
          <li className="benefits-list">
            Coordinate visits with your doctors, facilities, labs, radiology, or
            others
          </li>
          <li className="benefits-list">
            Provide access to around-the-clock (24/7) services from your care
            team
          </li>
          <li className="benefits-list">
            Assist with management of medications
          </li>
          <li className="benefits-list">
            Provide a personalized and comprehensive care plan management
          </li>
          <li className="benefits-list">
            Assist with scheduling preventive care services, many of which are
            covered by Medicare
          </li>
        </ul>
        <p>
          NOTE: You must sign an agreement or provide verbal consent to receive
          this type of chronic care management services.
        </p>
      </div>
      <div className="need-to-know-wrapper">
        <p className="need-to-know">
          What do you need to know before signing up?
        </p>
        <p className="need-to-know-text">
          Medicare will allow us to bill approximately $42 for these services
          during any month that we have provided at least 20 minutes of
          non-face-to-face chronic care management services.
        </p>
        <p>
          Medicare will reimburse us approximately $32 and requires you to pay
          approximately $8 to $9 (your Medicare coinsurance amount, may be
          covered by your secondary insurance) each month that you receive at
          least 20 minutes of chronic care management. Our office will have the
          record of when and how the 20 minutes were spent and you will have
          24/7 access to your electronic medical record if you ever have
          questions. Our practice is compliant with HIPAA and all laws related
          to the privacy and security of Protected Health Information (PHI). As
          a part of this program, your PHI may be shared between caregivers
          directly involved with your health.
        </p>
      </div>
      <div className="rights-wrapper">
        <p className="rights">You have a right to:</p>
        <p>
          Discontinue this service at any time for any reason. Because your
          signature is required to end your chronic care management services,
          please ask any of our staff members for the CCM termination form. The
          provider will continue providing CCM services until the end of the
          month and may bill Medicare for those services. After the end of the
          month, the provider will discontinue CCM services and no longer bill
          for those services to Medicare.
        </p>
        <p className="rights-text">
          NOTE: Only one physician can bill for this service for you. Please let
          your physician or our staff know if you have entered into a similar
          agreement with another physician/ practice.
        </p>
      </div>
      <div className="confirmation-wrapper">
        <p className="confirmation">
          Beneficiary Acknowledgment and Authorization.
        </p>
        <p className="signing">
          By signing this Agreement, you agree to the following:
        </p>
        <ul>
          <li>
            You consent to the Provider providing CCM Services to you. You
            authorize electronic communication of your medical information with
            other treating providers as part of coordination of your care.
          </li>
          <li>
            You acknowledge that only one practitioner can furnish CCM Services
            to you during a calendar month.
          </li>
          <li>
            You understand that cost-sharing will apply to CCM Services, so you
            may be billed for a portion of CCM Services even though CCM Services
            will not involve a face-to-face meeting with the Provider.
          </li>
        </ul>
      </div>
      {choice()}
      <div className="consent-date">
        <p>{consentInfo}</p>
        <p>{patientConsentInfo}</p>
      </div>
      <div className="signatures">
        <div className="patient-signature">
          <p>Patient /Client/ Representative: </p>
          <div>
            <p>Signature:</p>
            <div className="signature-line" />
          </div>
          <div>
            <p>Date:</p>
            <div className="signature-line-date" />
          </div>
        </div>

        <div className="reason-signature">
          <div>
            <p>Authorized Representative/Reason for Rep Signature:</p>
            <div className="signature-line-date" />
          </div>
          <div>
            <p>/ Reason:</p>
            <div className="signature-line-date mr" />
          </div>
          <div>
            <p>Date:</p>
            <div className="signature-line-date" />
          </div>
        </div>

        <div className="agency-signature">
          <div>
            <p>Agency Representative:</p>
            <div className="signature-line-date" />
          </div>
          <div>
            <p>Signature:</p>
            <div className="signature-line" />
          </div>
          <div>
            <p>Date:</p>
            <div className="signature-line-date" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
