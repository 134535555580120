import dataService from 'services/dataService';
import {
  apiCcmInteractiveTime,
  API_CCM_INTERACTIVE_TIME_CSV_REPORT,
} from 'services/dataService/resources';

const ITEMS_PER_PAGE = 100;

const INITIAL_STATE = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  totalPages: 0,
  totalCount: 0,
  filters: {
    dates: [],
    ccmInteractionStatus: null,
    ccmOnboardingAgentGuid: null,
    ccmCaseManagerGuid: null,
    ccmPatManagerGuid: null,
    clinicGuid: null,
  },
  dates: [],
  ccmInteractiveTime: [],
};

const ccmInteractiveTime = {
  state: INITIAL_STATE,
  reducers: {
    setCcmInteractiveTime: (state, payload) => ({
      ...state,
      ccmInteractiveTime: payload,
    }),
    setPage: (state, payload) => ({
      ...state,
      page: payload < 1 ? 1 : payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    setFilters: (state, payload) => ({
      ...state,
      filters: { ...state.filters, ...payload },
    }),
  },
  effects: (dispatch) => ({
    async getCcmInteractiveReport(payload, state) {
      const { page, itemsPerPage, filters } = state.ccmInteractiveTime;

      const { data } = await dataService.getList(
        apiCcmInteractiveTime(itemsPerPage, page, filters),
      );
      if (data) {
        dispatch.ccmInteractiveTime.setCcmInteractiveTime(data?.data || []);
        const totalCount = data?.count || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.ccmInteractiveTime.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
      }
    },
    async setPageCcmInteractiveTime({ pageOffset }, state) {
      const { page, totalPages } = state.ccmInteractiveTime;
      const newPage = page + pageOffset;
      dispatch.ccmInteractiveTime.setPage(
        newPage <= totalPages ? newPage : totalPages,
      );

      return dispatch.ccmInteractiveTime.getCcmInteractiveReport();
    },
    async applyFilters(filters) {
      Object.keys(filters).forEach((key) => {
        if (filters[key] === undefined) delete filters[key];
        else if (filters[key] === 'all') filters[key] = null;
      });
      dispatch.ccmInteractiveTime.setFilters(filters);
      dispatch.ccmInteractiveTime.setPage(1);
    },
    async setDatesCcmInteractiveTime({ newDates }) {
      if (newDates.length === 0) {
        dispatch.ccmInteractiveTime.setFilters({ dates: [] });
      } else {
        const dateTimes = [newDates[0].getTime(), newDates[1].getTime()];
        dispatch.ccmInteractiveTime.setFilters({ dates: dateTimes });
        dispatch.ccmInteractiveTime.setPage(1);
        return dispatch.ccmInteractiveTime.getCcmInteractiveReport();
      }
    },
    async getCcmInteractiveCsvReport(filters) {
      const { error, data } = await dataService.createOne(
        API_CCM_INTERACTIVE_TIME_CSV_REPORT,
        filters,
      );
      return { error, data };
    },
  }),
};

export default ccmInteractiveTime;
