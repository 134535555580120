import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_ALLERGY_LOG } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';

import { apiUrlGenerator } from '../../use-api';

import type {
  IAllergyLogResponse,
  IPartialPaginationMeta,
} from 'infrastructure/interfaces';

interface Props {
  patientGuid: string;
  meta: IPartialPaginationMeta;
}

type TFilter = {
  patientGuid: string;
} & IPartialPaginationMeta;

export const getAllergyLogsKey = (filter: TFilter) => [
  'getAllergyLogs',
  filter,
];

export const ALLERGY_LOG_PER_PAGE = 5;

const getAllergyLogsFn = async (filter: TFilter) => {
  const url = API_ALLERGY_LOG;
  const { error, data } = await dataService.getList<IAllergyLogResponse>(
    apiUrlGenerator(url, filter),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useGetAllergyLogs = ({ patientGuid, meta }: Props) => {
  const filter: TFilter = {
    ...meta,
    patientGuid,
    items: ALLERGY_LOG_PER_PAGE,
  };

  const { data: allergyLogs, ...other } = useQuery({
    queryKey: getAllergyLogsKey(filter),
    queryFn: async () => getAllergyLogsFn(filter),
  });
  return {
    allergyLogs,
    ...other,
  };
};
