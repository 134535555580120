import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminAgencyAddBlock from 'components/templates/admin/agencyAdd';

const AdminAgencyAdd = () => {
  const {
    adminPanelAgencies: { getAgency },
  } = useDispatch();

  useEffect(() => {
    getAgency();
  }, []);
  return (
    <Container>
      <AdminHeader title=" " />
      <AdminAgencyAddBlock />
    </Container>
  );
};

export default AdminAgencyAdd;
