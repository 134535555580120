/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ArrowDownIcon from '../../statisticsRerport/infoTiles/arrowDownIcon';
import ArrowUpIcon from '../../statisticsRerport/infoTiles/arrowUpIcon';

const numberFormatter = new Intl.NumberFormat('en-En');

const formatNumber = (number = 0) => numberFormatter.format(number);

const calculatePercent = (a, b) =>
  b === 0 ? '0.0%' : `${(Math.abs(a / b - 1) * 100 || 0).toFixed(1)}%`;

const HeaderItem = ({ data, title, dataCy, reversed, isRevenue }) => {
  const { differenceInPercent, isPositive, isSameValue } = useMemo(
    () => ({
      differenceInPercent: calculatePercent(
        data.currentMonth,
        data.previousMonth,
      ),
      isPositive: reversed
        ? data.currentMonth < data.previousMonth
        : data.currentMonth > data.previousMonth,
      isSameValue: data.currentMonth === data.previousMonth,
    }),
    [data],
  );

  return (
    <div
      className="performance-review-counter__wrapper__item"
      data-cy="yesterdays-stats__info-tile"
    >
      <h4 className="performance-review-counter__wrapper__title">{title}</h4>
      <div className="performance-review-counter__wrapper__values-wrapper">
        <div
          className="performance-review-counter__wrapper__value"
          data-cy={`${dataCy}-current`}
        >
          {isRevenue && '$'}
          {formatNumber(data.currentMonth)}
        </div>
        <div>
          <div className="performance-review-counter__wrapper__prev-values">
            <p
              className="performance-review-counter__wrapper__prev-value"
              data-cy={`${dataCy}-prev`}
            >
              {isRevenue && '$'}
              {formatNumber(data.previousMonth)}
              <span> | </span>
              <span
                className={classnames(
                  'performance-review-counter__wrapper__prev-percent',
                  {
                    'performance-review-counter__wrapper__prev-percent--red':
                      !isSameValue && !isPositive,
                    'performance-review-counter__wrapper__prev-percent--green':
                      !isSameValue && isPositive,
                  },
                )}
                data-cy={`${dataCy}-percentage`}
              >
                {!isSameValue && (
                  <>
                    {isPositive && (
                      <span className="performance-review-counter__wrapper__percent-icon">
                        <ArrowUpIcon />
                      </span>
                    )}
                    {!isPositive && (
                      <span className="performance-review-counter__wrapper__percent-icon">
                        <ArrowDownIcon />
                      </span>
                    )}
                  </>
                )}
                {!isSameValue && !isPositive && '-'}
                {differenceInPercent}
              </span>
            </p>
            <p>VS Prior MTD</p>
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderItem.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  dataCy: PropTypes.string,
  reversed: PropTypes.bool,
  isRevenue: PropTypes.bool,
};

export default HeaderItem;
