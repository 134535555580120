/* eslint-disable lines-between-class-members */
import { getAuth0Token } from 'services/dataService/getHeaders';
import { refreshTokenIfExpired } from 'utils/jwt';
import { debug } from 'utils/logger';
import { generateRandomId } from 'utils/random';

const wsUrl = process.env.REACT_APP_WS;
const PING_INTERVAL = 25 * 1000; // 25 seconds
const MAX_RECONNECT_TIMEOUT = 30 * 1000; // 30 seconds

class WsService {
  constructor() {
    this.callbacks = {};
  }

  pingTimerId = null;
  reconnectTimerId = null;
  reconnectAttempts = 0;
  offline = false;
  unSentMessages = [];

  on(name, callback) {
    this.callbacks[name] = callback;
  }

  emit(name, data) {
    if (!this.callbacks[name]) return;
    this.callbacks[name](data);
  }

  off(name) {
    delete this.callbacks[name];
  }

  startPinging() {
    if (this.pingTimerId) return;
    debug('start interval ping');
    this.send('ping', {}, false);
    this.pingTimerId = setInterval(() => {
      this.send('ping', {}, false);
    }, PING_INTERVAL);
  }

  stopPinging() {
    if (this.pingTimerId) {
      debug('stop interval ping');
      clearInterval(this.pingTimerId);
      this.pingTimerId = null;
    }
  }

  reconnect() {
    debug('start reconnect');
    this.stopReconnect();
    this.reconnectAttempts += 1;
    const reconnectTimeout = this.reconnectAttempts * 3000;
    const timeout = Math.min(reconnectTimeout, MAX_RECONNECT_TIMEOUT);
    this.reconnectTimerId = setTimeout(() => {
      this.connect();
    }, timeout);
  }

  stopReconnect() {
    if (this.reconnectTimerId) {
      clearTimeout(this.reconnectTimerId);
      this.reconnectTimerId = null;
    }
  }

  async connect() {
    const token = getAuth0Token();
    if (!token) {
      debug('No token found. Reconnecting...');
      this.reconnect();
      return;
    }
    await refreshTokenIfExpired(token);
    this.client = new WebSocket(wsUrl, ['websocket', token]);
    this.client.onerror = this.onerror.bind(this);
    this.client.onopen = this.onopen.bind(this);
    this.client.onclose = this.onclose.bind(this);
    this.client.onmessage = this.onmessage.bind(this);
  }

  disconnect() {
    this.emit('disconnected');
    this.offline = true;
    if (
      this.client &&
      (this.client.readyState === WebSocket.OPEN ||
        this.client.readyState === WebSocket.CONNECTING)
    ) {
      this.client.close();
    }
  }

  onerror(e) {
    debug('websocket error: ', e);
  }
  onmessage(event) {
    const { type, data } = JSON.parse(event.data);
    this.emit(type, data);
  }
  onopen() {
    this.emit('connected');
    debug('WebSocket Connection Established');
    this.reconnectAttempts = 0;
    this.startPinging();
    this.offline = false;
    this.unSentMessages.forEach(({ action, data, id }) => {
      const res = this.send(action, data);
      if (res) {
        this.unSentMessages = this.unSentMessages.filter(
          (item) => item.id !== id,
        );
      }
    });
  }
  onclose(e) {
    debug('onclose: ', e);
    this.stopPinging();
    if (this.offline) {
      this.stopReconnect();
    } else {
      this.reconnect();
    }
  }
  async send(action, data, retry = true) {
    try {
      if (this.client.readyState !== WebSocket.OPEN) {
        if (retry) {
          const id = generateRandomId();
          this.unSentMessages.push({ action, data, id });
        }
        return false;
      }
      this.client.send(JSON.stringify({ action, postData: data }));
      return true;
    } catch (e) {
      debug('[WS SEND ERROR] action: ', action, 'error: ', e);
      return false;
    }
  }

  async init() {
    window.addEventListener('offline', () => {
      this.disconnect();
    });
    window.addEventListener('online', () => {
      this.reconnectAttempts = 0;
      this.connect();
    });
    await this.connect();
  }
}
const wsService = new WsService();
export default wsService;

export const SocketRoutes = {
  OnCallInitialized: 'callInitialized',
  OnCallStarted: 'callStarted',
  OnCallEnded: 'callEnded',
  JoinToRoom: 'joinToRoom',
  LeaveRoom: 'leaveRoom',
};

export const BroadcastTopics = {
  NewReading: 'newReading',
  SendReport: 'sendReport',
  Call: 'onCall',
  CallInit: 'callInit',
  CallEnded: 'callEnded',
  ReservedReadingStateChange: 'reservedReadingStateChange',
  ConversationStateChange: 'conversationStateChange',
  DoctorMessageStatusChange: 'doctorMessageStatusChange',
  NewMessage: 'newMessage',
  SlaTimerStop: 'slaTimerStop',
  InteractiveTimeInsert: 'interactiveTimeInsert',
  PatientStatusChange: 'patientStatusChange',
  NewReadingAction: 'newReadingAction',
};
