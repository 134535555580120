import { useState } from 'react';
import i18next from 'i18next';
import { useQuery } from '@tanstack/react-query';
import { DateFormatter, prepareDoctorName } from 'infrastructure/functions';
import { BaseLog } from 'infrastructure/classes/logs/base-log';
import { DateFormats } from 'infrastructure/enums';
import { prepareLogsDateRange } from 'infrastructure/functions/logs';

import useApi from '../use-api';

import type { DateRange } from 'infrastructure/types';
import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IPaginatedStatusLogs,
} from 'infrastructure/interfaces';
import type { Dispatch, SetStateAction } from 'react';

const ccmStatusLogsApi = (patientGuid: string) =>
  `core/patients/${patientGuid}/ccm-status-logs`;

export interface IUseCcmStatusLogMeta {
  reverse?: boolean;
  dateRange?: DateRange;
}

type mergedMeta = IUseCcmStatusLogMeta & IPaginationMeta;

type UseLog = {
  loading: boolean;
  meta: mergedMeta;
  items: Array<BaseLog>;
  setMeta: Dispatch<SetStateAction<mergedMeta>>;
};

interface IUseCcmStatusLogProps {
  patientGuid: string;
  meta?: IUseCcmStatusLogMeta & IPartialPaginationMeta;
}

export const useCcmStatusLog = (props: IUseCcmStatusLogProps): UseLog => {
  const { patientGuid, meta: metaProps } = props;

  const [meta, setMeta] = useState<mergedMeta>({
    page: 1,
    items: 15,
    totalCount: 0,
    ...metaProps,
  });

  const { loadData } = useApi();

  const queryKey = [
    ccmStatusLogsApi(patientGuid),
    prepareLogsDateRange(meta.dateRange),
    meta.page,
  ];

  const { data = [], isLoading } = useQuery({
    queryKey,
    queryFn: () =>
      loadData<IPaginatedStatusLogs, Omit<IUseCcmStatusLogMeta, 'sortField'>>(
        ccmStatusLogsApi(patientGuid),
        {
          sortField: JSON.stringify(meta.sortField),
          page: meta.page,
          items: meta.items,
          ...prepareLogsDateRange(meta.dateRange),
        },
      ).then((res) => {
        if (res) {
          const { count, data: rows } = res;

          setMeta({
            ...meta,
            totalCount: count,
          });

          return rows.map((el) => {
            const from = el.oldValue
              ? i18next.t(`ccmStatuses.${el.oldValue}` as any)
              : undefined;
            const to = i18next.t(`ccmStatuses.${el.newValue}` as any);
            const who = prepareDoctorName(el.updatedByLogin);
            return new BaseLog({
              who,
              from,
              to,
              date: DateFormatter({
                date: el.createdAt,
                format: DateFormats['MM-dd-yyyy | h:mm a'],
              }),
            });
          });
        }
      }),
    enabled: Boolean(patientGuid),
  });

  return {
    items: data,
    loading: isLoading,
    meta,
    setMeta,
  };
};
