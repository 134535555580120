import { createModel } from '@rematch/core';
import LastMessageDateEnums from 'infrastructure/enums/lastMessageDateFilterEnums';
import { PlatformEnums } from 'infrastructure/enums/platformEnums';

import type { ConversationType } from 'infrastructure/interfaces';
import type { RootModel } from 'store/models';

const CONVERSATION_INITIAL_STATE = {
  data: [],
  loading: false,
  allLoaded: false,
  error: false,
};

const CONVERSATION_TYPES = PlatformEnums;

const conversationTypes = Object.values(CONVERSATION_TYPES);
export const STATUS_LEVEL = {
  accepted: 0,
  scheduled: 1,
  queued: 2,
  sending: 3,
  sent: 4,
  delivery_unknown: 5,
  delivered: 6,
  undelivered: 7,
  failed: 8,
  receiving: 9,
  received: 10,
};

export const INITIAL_STATE = {
  conversationTypes,
  conversationType: conversationTypes[0],
  conversations: {
    rpm: CONVERSATION_INITIAL_STATE,
    ccm: CONVERSATION_INITIAL_STATE,
    unregistered: CONVERSATION_INITIAL_STATE,
  },
  newMessageBadge: {
    rpm: false,
    ccm: false,
    unregistered: false,
  },
  messages: {},
  messagesSearchValue: {
    conversationId: undefined,
    cursor: undefined,
    searchQuery: undefined,
  },
  currentConversation: {
    id: 0,
    title: '',
    patient: {
      patientGuid: '',
      patientPhone: '',
      email: '',
      fullNameWithOnlyTitle: '',
    },
    clinic: {
      guid: '',
      legalname: '',
    },
    doctorGuid: '',
    notes: '',
    lastMessage: '',
    read: false,
    unreadMessageCount: 0,
    hasPatientMessage: false,
    lastMessageDate: '',
    type: PlatformEnums.RPM as ConversationType,
    closed: false,
    patientCurrentClinicGuid: '',
    createdAt: '',
    updatedAt: '',
  },
  filters: {
    read: '',
    closed: 'false',
    lastMessageDate: LastMessageDateEnums.NEWEST,
    searchQuery: '',
  },
};
const smsDashboard = createModel<RootModel>()({
  state: { ...INITIAL_STATE },
  reducers: {
    reset: () => ({ ...INITIAL_STATE }),
    setConversationType: (state, payload) => ({
      ...state,
      conversationType: payload,
    }),
    setConversationsFilters: (state, payload) => ({
      ...state,
      filters: payload,
    }),
    setMessagesSearchValue: (state, payload) => ({
      ...state,
      messagesSearchValue: payload,
    }),

    setCurrentConversation: (state, payload) => ({
      ...state,
      currentConversation: payload,
    }),
  },
  effects: (dispatch) => ({
    async changeCurrentConversation(conversation) {
      dispatch.smsDashboard.setCurrentConversation(conversation);
    },
    updateConversationsFilters(filters) {
      dispatch.smsDashboard.setConversationsFilters(filters);
    },
  }),
});

export default smsDashboard;
