import { clinicRevenueChannelOptions } from './clinicsRevenueChannel';
import common from './common';
import languages from './languages';
import { ccmOnboardingStatuses } from './patient_statuses';
import { usStatesWithNone } from './states';
import {
  clinicProviderServiceTypeOptions,
  clinicReferralTypes,
  clinicServiceDeliveryModel,
  clinicServiceDeliveryThresholds,
} from './clinicProviderServiceTypeOptions.js';

import type {
  IFilterConversation,
  ISortConversation,
} from 'infrastructure/interfaces';

export const genders = [
  { id: 'male', label: 'Male' },
  { id: 'female', label: 'Female' },
];

export const maritalStatuses = [
  { id: 'married', label: 'Married' },
  { id: 'widowed', label: 'Widowed' },
  { id: 'separated', label: 'Separated' },
  { id: 'divorced', label: 'Divorced' },
  { id: 'single', label: 'Single' },
];

export const insuranceProviders = [
  { value: 'Medicare', label: 'Medicare' },
  { value: 'Texas Medicaid', label: 'Texas Medicaid' },
  { value: 'Private Pay', label: 'Private Pay' },
  { value: 'Texas Medicaid HMO', label: 'Texas Medicaid HMO' },
  { value: 'California Medicaid', label: 'California Medicaid' },
  { value: 'Commercial', label: 'Commercial' },
];

export const assigneeOptions = [
  { value: 'me', label: 'Assigned To Me', title: 'Assigned To Me' },
  { value: 'free', label: 'Free To Assign', title: 'Free To Assign' },
];

export const interactionStatusOptions = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
];

export const workingStatuses = [
  { id: 'permanent_employee', label: 'Permanent Employee' },
  { id: 'temporary_employee', label: 'Temporary Employee' },
  { id: 'self_employed', label: 'Self-Employed' },
  { id: 'retired', label: 'Retired' },
  { id: 'internship', label: 'Internship' },
  { id: 'freelance', label: 'Freelance' },
  { id: 'unemployed', label: 'Unemployed' },
];

export const races = [
  {
    id: 'american_indian_or_alaska_native',
    label: 'American Indian or Alaska Native',
  },
  { id: 'asian', label: 'Asian' },
  { id: 'black_or_african_american', label: 'Black or African American' },
  { id: 'hispanic_or_latino', label: 'Hispanic or Latino' },
  {
    id: 'native_hawaiian_or_other_pacific_islander',
    label: 'Native Hawaiian or Other Pacific Islander',
  },
  { id: 'white', label: 'White' },
  { id: 'dynamic', label: 'Dynamic' },
  { id: 'other', label: 'Other' },
];

export const insuranceTypes = [
  { value: 'MMP', label: 'MMP' },
  { value: 'MQMB', label: 'MQMB' },
  { value: 'SSI MEDICAID', label: 'SSI MEDICAID' },
];

export const TimeZones = [
  { id: 'Pacific/Niue', label: '(GMT-11:00) Niue' },
  { id: 'Pacific/Pago_Pago', label: '(GMT-11:00) Pago Pago' },
  { id: 'Pacific/Honolulu', label: '(GMT-10:00) Hawaii Time' },
  { id: 'Pacific/Rarotonga', label: '(GMT-10:00) Rarotonga' },
  { id: 'Pacific/Tahiti', label: '(GMT-10:00) Tahiti' },
  { id: 'Pacific/Marquesas', label: '(GMT-09:30) Marquesas' },
  { id: 'America/Anchorage', label: '(GMT-09:00) Alaska Time' },
  { id: 'Pacific/Gambier', label: '(GMT-09:00) Gambier' },
  { id: 'America/Los_Angeles', label: '(GMT-08:00) Pacific Time' },
  { id: 'America/Tijuana', label: '(GMT-08:00) Pacific Time - Tijuana' },
  { id: 'America/Vancouver', label: '(GMT-08:00) Pacific Time - Vancouver' },
  {
    id: 'America/Whitehorse',
    label: '(GMT-08:00) Pacific Time - Whitehorse',
  },
  { id: 'Pacific/Pitcairn', label: '(GMT-08:00) Pitcairn' },
  {
    id: 'America/Dawson_Creek',
    label: '(GMT-07:00) Mountain Time - Dawson Creek',
  },
  { id: 'America/Denver', label: '(GMT-07:00) Mountain Time' },
  { id: 'America/Edmonton', label: '(GMT-07:00) Mountain Time - Edmonton' },
  { id: 'America/Hermosillo', label: '(GMT-07:00) Mountain Time - Hermosillo' },
  {
    id: 'America/Mazatlan',
    label: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
  },
  { id: 'America/Phoenix', label: '(GMT-07:00) Mountain Time - Arizona' },
  {
    id: 'America/Yellowknife',
    label: '(GMT-07:00) Mountain Time - Yellowknife',
  },
  { id: 'America/Belize', label: '(GMT-06:00) Belize' },
  { id: 'America/Chicago', label: '(GMT-06:00) Central Time' },
  { id: 'America/Costa_Rica', label: '(GMT-06:00) Costa Rica' },
  { id: 'America/El_Salvador', label: '(GMT-06:00) El Salvador' },
  { id: 'America/Guatemala', label: '(GMT-06:00) Guatemala' },
  { id: 'America/Managua', label: '(GMT-06:00) Managua' },
  {
    id: 'America/Mexico_City',
    label: '(GMT-06:00) Central Time - Mexico City',
  },
  { id: 'America/Regina', label: '(GMT-06:00) Central Time - Regina' },
  {
    id: 'America/Tegucigalpa',
    label: '(GMT-06:00) Central Time - Tegucigalpa',
  },
  { id: 'America/Winnipeg', label: '(GMT-06:00) Central Time - Winnipeg' },
  { id: 'Pacific/Galapagos', label: '(GMT-06:00) Galapagos' },
  { id: 'America/Bogota', label: '(GMT-05:00) Bogota' },
  { id: 'America/Cancun', label: '(GMT-05:00) America Cancun' },
  { id: 'America/Cayman', label: '(GMT-05:00) Cayman' },
  { id: 'America/Guayaquil', label: '(GMT-05:00) Guayaquil' },
  { id: 'America/Havana', label: '(GMT-05:00) Havana' },
  { id: 'America/Iqaluit', label: '(GMT-05:00) Eastern Time - Iqaluit' },
  { id: 'America/Jamaica', label: '(GMT-05:00) Jamaica' },
  { id: 'America/Lima', label: '(GMT-05:00) Lima' },
  { id: 'America/Nassau', label: '(GMT-05:00) Nassau' },
  { id: 'America/New_York', label: '(GMT-05:00) Eastern Time' },
  { id: 'America/Panama', label: '(GMT-05:00) Panama' },
  { id: 'America/Port-au-Prince', label: '(GMT-05:00) Port-au-Prince' },
  { id: 'America/Rio_Branco', label: '(GMT-05:00) Rio Branco' },
  { id: 'America/Toronto', label: '(GMT-05:00) Eastern Time - Toronto' },
  { id: 'Pacific/Easter', label: '(GMT-05:00) Easter Island' },
  { id: 'America/Caracas', label: '(GMT-04:30) Caracas' },
  { id: 'America/Asuncion', label: '(GMT-03:00) Asuncion' },
  { id: 'America/Barbados', label: '(GMT-04:00) Barbados' },
  { id: 'America/Boa_Vista', label: '(GMT-04:00) Boa Vista' },
  { id: 'America/Campo_Grande', label: '(GMT-03:00) Campo Grande' },
  { id: 'America/Cuiaba', label: '(GMT-03:00) Cuiaba' },
  { id: 'America/Curacao', label: '(GMT-04:00) Curacao' },
  { id: 'America/Grand_Turk', label: '(GMT-04:00) Grand Turk' },
  { id: 'America/Guyana', label: '(GMT-04:00) Guyana' },
  { id: 'America/Halifax', label: '(GMT-04:00) Atlantic Time - Halifax' },
  { id: 'America/La_Paz', label: '(GMT-04:00) La Paz' },
  { id: 'America/Manaus', label: '(GMT-04:00) Manaus' },
  { id: 'America/Martinique', label: '(GMT-04:00) Martinique' },
  { id: 'America/Port_of_Spain', label: '(GMT-04:00) Port of Spain' },
  { id: 'America/Porto_Velho', label: '(GMT-04:00) Porto Velho' },
  { id: 'America/Puerto_Rico', label: '(GMT-04:00) Puerto Rico' },
  { id: 'America/Santo_Domingo', label: '(GMT-04:00) Santo Domingo' },
  { id: 'America/Thule', label: '(GMT-04:00) Thule' },
  { id: 'Atlantic/Bermuda', label: '(GMT-04:00) Bermuda' },
  { id: 'Canada/Atlantic', label: '(GMT-04:00) Atlantic Standard Time' },
  {
    id: 'America/St_Johns',
    label: '(GMT-03:30) Newfoundland Time - St. Johns',
  },
  { id: 'America/Araguaina', label: '(GMT-03:00) Araguaina' },
  { id: 'America/Argentina/Buenos_Aires', label: '(GMT-03:00) Buenos Aires' },
  { id: 'America/Bahia', label: '(GMT-03:00) Salvador' },
  { id: 'America/Belem', label: '(GMT-03:00) Belem' },
  { id: 'America/Cayenne', label: '(GMT-03:00) Cayenne' },
  { id: 'America/Fortaleza', label: '(GMT-03:00) Fortaleza' },
  { id: 'America/Godthab', label: '(GMT-03:00) Godthab' },
  { id: 'America/Maceio', label: '(GMT-03:00) Maceio' },
  { id: 'America/Miquelon', label: '(GMT-03:00) Miquelon' },
  { id: 'America/Montevideo', label: '(GMT-03:00) Montevideo' },
  { id: 'America/Paramaribo', label: '(GMT-03:00) Paramaribo' },
  { id: 'America/Recife', label: '(GMT-03:00) Recife' },
  { id: 'America/Santiago', label: '(GMT-03:00) Santiago' },
  { id: 'America/Sao_Paulo', label: '(GMT-02:00) Sao Paulo' },
  { id: 'Antarctica/Palmer', label: '(GMT-03:00) Palmer' },
  { id: 'Antarctica/Rothera', label: '(GMT-03:00) Rothera' },
  { id: 'Atlantic/Stanley', label: '(GMT-03:00) Stanley' },
  { id: 'America/Noronha', label: '(GMT-02:00) Noronha' },
  { id: 'Atlantic/South_Georgia', label: '(GMT-02:00) South Georgia' },
  { id: 'America/Scoresbysund', label: '(GMT-01:00) Scoresbysund' },
  { id: 'Atlantic/Azores', label: '(GMT-01:00) Azores' },
  { id: 'Atlantic/Cape_Verde', label: '(GMT-01:00) Cape Verde' },
  { id: 'Africa/Abidjan', label: '(GMT+00:00) Abidjan' },
  { id: 'Africa/Accra', label: '(GMT+00:00) Accra' },
  { id: 'Africa/Bissau', label: '(GMT+00:00) Bissau' },
  { id: 'Africa/Casablanca', label: '(GMT+00:00) Casablanca' },
  { id: 'Africa/El_Aaiun', label: '(GMT+00:00) El Aaiun' },
  { id: 'Africa/Monrovia', label: '(GMT+00:00) Monrovia' },
  { id: 'America/Danmarkshavn', label: '(GMT+00:00) Danmarkshavn' },
  { id: 'Atlantic/Canary', label: '(GMT+00:00) Canary Islands' },
  { id: 'Atlantic/Faroe', label: '(GMT+00:00) Faeroe' },
  { id: 'Atlantic/Reykjavik', label: '(GMT+00:00) Reykjavik' },
  { id: 'Etc/GMT', label: '(GMT+00:00) GMT (no daylight saving)' },
  { id: 'Europe/Dublin', label: '(GMT+00:00) Dublin' },
  { id: 'Europe/Lisbon', label: '(GMT+00:00) Lisbon' },
  { id: 'Europe/London', label: '(GMT+00:00) London' },
  { id: 'Africa/Algiers', label: '(GMT+01:00) Algiers' },
  { id: 'Africa/Ceuta', label: '(GMT+01:00) Ceuta' },
  { id: 'Africa/Lagos', label: '(GMT+01:00) Lagos' },
  { id: 'Africa/Ndjamena', label: '(GMT+01:00) Ndjamena' },
  { id: 'Africa/Tunis', label: '(GMT+01:00) Tunis' },
  { id: 'Africa/Windhoek', label: '(GMT+02:00) Windhoek' },
  { id: 'Europe/Amsterdam', label: '(GMT+01:00) Amsterdam' },
  { id: 'Europe/Andorra', label: '(GMT+01:00) Andorra' },
  {
    id: 'Europe/Belgrade',
    label: '(GMT+01:00) Central European Time - Belgrade',
  },
  { id: 'Europe/Berlin', label: '(GMT+01:00) Berlin' },
  { id: 'Europe/Brussels', label: '(GMT+01:00) Brussels' },
  { id: 'Europe/Budapest', label: '(GMT+01:00) Budapest' },
  { id: 'Europe/Copenhagen', label: '(GMT+01:00) Copenhagen' },
  { id: 'Europe/Gibraltar', label: '(GMT+01:00) Gibraltar' },
  { id: 'Europe/Luxembourg', label: '(GMT+01:00) Luxembourg' },
  { id: 'Europe/Madrid', label: '(GMT+01:00) Madrid' },
  { id: 'Europe/Malta', label: '(GMT+01:00) Malta' },
  { id: 'Europe/Monaco', label: '(GMT+01:00) Monaco' },
  { id: 'Europe/Oslo', label: '(GMT+01:00) Oslo' },
  { id: 'Europe/Paris', label: '(GMT+01:00) Paris' },
  { id: 'Europe/Prague', label: '(GMT+01:00) Central European Time - Prague' },
  { id: 'Europe/Rome', label: '(GMT+01:00) Rome' },
  { id: 'Europe/Stockholm', label: '(GMT+01:00) Stockholm' },
  { id: 'Europe/Tirane', label: '(GMT+01:00) Tirane' },
  { id: 'Europe/Vienna', label: '(GMT+01:00) Vienna' },
  { id: 'Europe/Warsaw', label: '(GMT+01:00) Warsaw' },
  { id: 'Europe/Zurich', label: '(GMT+01:00) Zurich' },
  { id: 'Africa/Cairo', label: '(GMT+02:00) Cairo' },
  { id: 'Africa/Johannesburg', label: '(GMT+02:00) Johannesburg' },
  { id: 'Africa/Maputo', label: '(GMT+02:00) Maputo' },
  { id: 'Africa/Tripoli', label: '(GMT+02:00) Tripoli' },
  { id: 'Asia/Amman', label: '(GMT+02:00) Amman' },
  { id: 'Asia/Beirut', label: '(GMT+02:00) Beirut' },
  { id: 'Asia/Damascus', label: '(GMT+02:00) Damascus' },
  { id: 'Asia/Gaza', label: '(GMT+02:00) Gaza' },
  { id: 'Asia/Jerusalem', label: '(GMT+02:00) Jerusalem' },
  { id: 'Asia/Nicosia', label: '(GMT+02:00) Nicosia' },
  { id: 'Europe/Athens', label: '(GMT+02:00) Athens' },
  { id: 'Europe/Bucharest', label: '(GMT+02:00) Bucharest' },
  { id: 'Europe/Chisinau', label: '(GMT+02:00) Chisinau' },
  { id: 'Europe/Helsinki', label: '(GMT+02:00) Helsinki' },
  { id: 'Europe/Istanbul', label: '(GMT+02:00) Istanbul' },
  { id: 'Europe/Kaliningrad', label: '(GMT+02:00) Moscow-01 - Kaliningrad' },
  { id: 'Europe/Kiev', label: '(GMT+02:00) Kyiv' },
  { id: 'Europe/Riga', label: '(GMT+02:00) Riga' },
  { id: 'Europe/Sofia', label: '(GMT+02:00) Sofia' },
  { id: 'Europe/Tallinn', label: '(GMT+02:00) Tallinn' },
  { id: 'Europe/Vilnius', label: '(GMT+02:00) Vilnius' },
  { id: 'Africa/Khartoum', label: '(GMT+03:00) Khartoum' },
  { id: 'Africa/Nairobi', label: '(GMT+03:00) Nairobi' },
  { id: 'Antarctica/Syowa', label: '(GMT+03:00) Syowa' },
  { id: 'Asia/Baghdad', label: '(GMT+03:00) Baghdad' },
  { id: 'Asia/Qatar', label: '(GMT+03:00) Qatar' },
  { id: 'Asia/Riyadh', label: '(GMT+03:00) Riyadh' },
  { id: 'Europe/Minsk', label: '(GMT+03:00) Minsk' },
  { id: 'Europe/Moscow', label: '(GMT+03:00) Moscow+00 - Moscow' },
  { id: 'Asia/Tehran', label: '(GMT+03:30) Tehran' },
  { id: 'Asia/Baku', label: '(GMT+04:00) Baku' },
  { id: 'Asia/Dubai', label: '(GMT+04:00) Dubai' },
  { id: 'Asia/Tbilisi', label: '(GMT+04:00) Tbilisi' },
  { id: 'Asia/Yerevan', label: '(GMT+04:00) Yerevan' },
  { id: 'Europe/Samara', label: '(GMT+04:00) Moscow+01 - Samara' },
  { id: 'Indian/Mahe', label: '(GMT+04:00) Mahe' },
  { id: 'Indian/Mauritius', label: '(GMT+04:00) Mauritius' },
  { id: 'Indian/Reunion', label: '(GMT+04:00) Reunion' },
  { id: 'Asia/Kabul', label: '(GMT+04:30) Kabul' },
  { id: 'Antarctica/Mawson', label: '(GMT+05:00) Mawson' },
  { id: 'Asia/Aqtau', label: '(GMT+05:00) Aqtau' },
  { id: 'Asia/Aqtobe', label: '(GMT+05:00) Aqtobe' },
  { id: 'Asia/Ashgabat', label: '(GMT+05:00) Ashgabat' },
  { id: 'Asia/Dushanbe', label: '(GMT+05:00) Dushanbe' },
  { id: 'Asia/Karachi', label: '(GMT+05:00) Karachi' },
  { id: 'Asia/Tashkent', label: '(GMT+05:00) Tashkent' },
  { id: 'Asia/Yekaterinburg', label: '(GMT+05:00) Moscow+02 - Yekaterinburg' },
  { id: 'Indian/Kerguelen', label: '(GMT+05:00) Kerguelen' },
  { id: 'Indian/Maldives', label: '(GMT+05:00) Maldives' },
  { id: 'Asia/Calcutta', label: '(GMT+05:30) India Standard Time' },
  { id: 'Asia/Colombo', label: '(GMT+05:30) Colombo' },
  { id: 'Asia/Katmandu', label: '(GMT+05:45) Katmandu' },
  { id: 'Antarctica/Vostok', label: '(GMT+06:00) Vostok' },
  { id: 'Asia/Almaty', label: '(GMT+06:00) Almaty' },
  { id: 'Asia/Bishkek', label: '(GMT+06:00) Bishkek' },
  { id: 'Asia/Dhaka', label: '(GMT+06:00) Dhaka' },
  { id: 'Asia/Omsk', label: '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk' },
  { id: 'Asia/Thimphu', label: '(GMT+06:00) Thimphu' },
  { id: 'Indian/Chagos', label: '(GMT+06:00) Chagos' },
  { id: 'Asia/Rangoon', label: '(GMT+06:30) Rangoon' },
  { id: 'Indian/Cocos', label: '(GMT+06:30) Cocos' },
  { id: 'Antarctica/Davis', label: '(GMT+07:00) Davis' },
  { id: 'Asia/Bangkok', label: '(GMT+07:00) Bangkok' },
  { id: 'Asia/Hovd', label: '(GMT+07:00) Hovd' },
  { id: 'Asia/Jakarta', label: '(GMT+07:00) Jakarta' },
  { id: 'Asia/Krasnoyarsk', label: '(GMT+07:00) Moscow+04 - Krasnoyarsk' },
  { id: 'Asia/Saigon', label: '(GMT+07:00) Hanoi' },
  { id: 'Asia/Ho_Chi_Minh', label: '(GMT+07:00) Ho Chi Minh' },
  { id: 'Indian/Christmas', label: '(GMT+07:00) Christmas' },
  { id: 'Antarctica/Casey', label: '(GMT+08:00) Casey' },
  { id: 'Asia/Brunei', label: '(GMT+08:00) Brunei' },
  { id: 'Asia/Choibalsan', label: '(GMT+08:00) Choibalsan' },
  { id: 'Asia/Hong_Kong', label: '(GMT+08:00) Hong Kong' },
  { id: 'Asia/Irkutsk', label: '(GMT+08:00) Moscow+05 - Irkutsk' },
  { id: 'Asia/Kuala_Lumpur', label: '(GMT+08:00) Kuala Lumpur' },
  { id: 'Asia/Macau', label: '(GMT+08:00) Macau' },
  { id: 'Asia/Makassar', label: '(GMT+08:00) Makassar' },
  { id: 'Asia/Manila', label: '(GMT+08:00) Manila' },
  { id: 'Asia/Shanghai', label: '(GMT+08:00) China Time - Beijing' },
  { id: 'Asia/Singapore', label: '(GMT+08:00) Singapore' },
  { id: 'Asia/Taipei', label: '(GMT+08:00) Taipei' },
  { id: 'Asia/Ulaanbaatar', label: '(GMT+08:00) Ulaanbaatar' },
  { id: 'Australia/Perth', label: '(GMT+08:00) Western Time - Perth' },
  { id: 'Asia/Pyongyang', label: '(GMT+08:30) Pyongyang' },
  { id: 'Asia/Dili', label: '(GMT+09:00) Dili' },
  { id: 'Asia/Jayapura', label: '(GMT+09:00) Jayapura' },
  { id: 'Asia/Seoul', label: '(GMT+09:00) Seoul' },
  { id: 'Asia/Tokyo', label: '(GMT+09:00) Tokyo' },
  { id: 'Asia/Yakutsk', label: '(GMT+09:00) Moscow+06 - Yakutsk' },
  { id: 'Pacific/Palau', label: '(GMT+09:00) Palau' },
  { id: 'Australia/Adelaide', label: '(GMT+10:30) Central Time - Adelaide' },
  { id: 'Australia/Darwin', label: '(GMT+09:30) Central Time - Darwin' },
  { id: 'Antarctica/DumontDUrville', label: "(GMT+10:00) Dumont D'Urville" },
  { id: 'Asia/Magadan', label: '(GMT+10:00) Moscow+07 - Magadan' },
  {
    id: 'Asia/Vladivostok',
    label: '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk',
  },
  { id: 'Australia/Brisbane', label: '(GMT+10:00) Eastern Time - Brisbane' },
  { id: 'Australia/Hobart', label: '(GMT+11:00) Eastern Time - Hobart' },
  {
    id: 'Australia/Sydney',
    label: '(GMT+11:00) Eastern Time - Melbourne, Sydney',
  },
  { id: 'Pacific/Chuuk', label: '(GMT+10:00) Truk' },
  { id: 'Pacific/Guam', label: '(GMT+10:00) Guam' },
  { id: 'Pacific/Port_Moresby', label: '(GMT+10:00) Port Moresby' },
  { id: 'Pacific/Efate', label: '(GMT+11:00) Efate' },
  { id: 'Pacific/Guadalcanal', label: '(GMT+11:00) Guadalcanal' },
  { id: 'Pacific/Kosrae', label: '(GMT+11:00) Kosrae' },
  { id: 'Pacific/Norfolk', label: '(GMT+11:00) Norfolk' },
  { id: 'Pacific/Noumea', label: '(GMT+11:00) Noumea' },
  { id: 'Pacific/Pohnpei', label: '(GMT+11:00) Ponape' },
  {
    id: 'Asia/Kamchatka',
    label: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
  },
  { id: 'Pacific/Auckland', label: '(GMT+13:00) Auckland' },
  { id: 'Pacific/Fiji', label: '(GMT+13:00) Fiji' },
  { id: 'Pacific/Funafuti', label: '(GMT+12:00) Funafuti' },
  { id: 'Pacific/Kwajalein', label: '(GMT+12:00) Kwajalein' },
  { id: 'Pacific/Majuro', label: '(GMT+12:00) Majuro' },
  { id: 'Pacific/Nauru', label: '(GMT+12:00) Nauru' },
  { id: 'Pacific/Tarawa', label: '(GMT+12:00) Tarawa' },
  { id: 'Pacific/Wake', label: '(GMT+12:00) Wake' },
  { id: 'Pacific/Wallis', label: '(GMT+12:00) Wallis' },
  { id: 'Pacific/Apia', label: '(GMT+14:00) Apia' },
  { id: 'Pacific/Enderbury', label: '(GMT+13:00) Enderbury' },
  { id: 'Pacific/Fakaofo', label: '(GMT+13:00) Fakaofo' },
  { id: 'Pacific/Tongatapu', label: '(GMT+13:00) Tongatapu' },
  { id: 'Pacific/Kiritimati', label: '(GMT+14:00) Kiritimati' },
];

export const numberType = [
  { id: 'mobile', label: 'Mobile', value: 'mobile' },
  { id: 'landline', label: 'Landline', value: 'landline' },
  { id: 'fixedVoip', label: 'FixedVoip', value: 'fixedVoip' },
  { id: 'nonFixedVoip', label: 'NonFixedVoip', value: 'nonFixedVoip' },
  { id: 'personal', label: 'Personal', value: 'personal' },
  { id: 'tollFree', label: 'TollFree', value: 'tollFree' },
  { id: 'premium', label: 'Premium', value: 'premium' },
  { id: 'sharedCost', label: 'SharedCost', value: 'sharedCost' },
  { id: 'uan', label: 'Uan', value: 'uan' },
  { id: 'voicemail', label: 'Voicemail', value: 'voicemail' },
  { id: 'pager', label: 'Pager', value: 'pager' },
  { id: 'unknown', label: 'Unknown', value: 'unknown' },
];

export const completionAppointmentMethod = [
  {
    id: 'phone_or_video_conference',
    label: 'Phone/Video Conference',
    value: 'Phone/Video Conference',
  },
  { id: 'sms_or_email', label: 'SMS/Email', value: 'SMS/Email' },
];

export const nextAppointmentMethod = [
  {
    id: 'phone_or_video_conference',
    label: 'Phone/Video Conference',
    value: 'Phone/Video Conference',
  },
  { id: 'sms', label: 'SMS', value: 'SMS' },
  { id: 'email', label: 'Email', value: 'Email' },
];

export const rpmAndCcmClaimOptions = [
  { id: 'feeForService', label: 'Fee for Service' },
  { id: 'pmpm', label: 'PMPM' },
  { id: 'valueBased', label: 'Value Based' },
  { id: 'grant', label: 'Grant' },
  { id: 'notBillable', label: 'Not Billable' },
] as const;

export const conversationReadFilter: IFilterConversation[] = [
  {
    id: 'all',
    type: 'read',
    label: 'All',
    value: '',
  },
  { id: 'read', type: 'read', label: 'Read', value: 'true' },
  { id: 'unread', type: 'read', label: 'Unread', value: 'false' },
];
export const conversationClosedFilter: IFilterConversation[] = [
  {
    id: 'all',
    type: 'closed',
    label: 'All',
    value: '',
  },
  { id: 'open', type: 'closed', label: 'Open', value: 'false' },
  { id: 'closed', type: 'closed', label: 'Closed', value: 'true' },
];
export const conversationLastMessageDateFilter: ISortConversation[] = [
  {
    id: 'newest',
    type: 'lastMessageDate',
    label: 'From new to old',
    value: 'newest',
  },
  {
    id: 'oldest',
    type: 'lastMessageDate',
    label: 'From old to new',
    value: 'oldest',
  },
];

export default {
  timeFilterRanges: [
    { id: 'last7days', label: 'Last 7 days', days: 7 },
    { id: 'last14days', label: 'Last 14 days', days: 14 },
    { id: 'last24hours', label: 'Last 24 hours', days: 1 },
    { id: 'last4hours', label: 'Last 4 hours', days: 0 },
    { id: 'currentmonth', label: 'Current Month' },
    { id: 'previousmonth', label: 'Previous Month' },
    { id: 'twomonthspast', label: 'Two months past' },
    { id: 'threemonthspast', label: 'Three months past' },
  ],
  adminPanelPatientFilter: [
    { id: 'fullName', label: 'Full Name', placeholder: 'John Doe' },
    { id: 'email', label: 'Email', placeholder: 'patient@gmail.com' },
    { id: 'phone', label: 'Phone Number', placeholder: 'Phone Number' },
    { id: 'deviceId', label: 'Device ID', placeholder: 'Device ID' },
    { id: 'guid', label: 'Patient ID', placeholder: 'Patient ID' },
  ],
  adminPanelPracticeUserFilter: [
    { id: 'fullName', label: 'Full Name', placeholder: 'John Doe' },
    { id: 'email', label: 'Email', placeholder: 'user@gmail.com' },
  ] as const,
  patientFilterOptions: [
    { id: 'fullName', label: 'Full Name', placeholder: 'John Doe' },
    { id: 'firstName', label: 'First Name', placeholder: 'John' },
    { id: 'lastName', label: 'Last Name', placeholder: 'Doe' },
    {
      id: 'birthDate',
      label: 'Birth Date',
      placeholder: common.dateFormats.birthDate,
    },
    { id: 'phone', label: 'Phone', placeholder: 'Phone Number' },
    { id: 'email', label: 'Email', placeholder: 'patient@gmail.com' },
    {
      id: 'guid',
      label: 'Patient ID',
      placeholder: 'Patient unique ID',
    },
    {
      id: 'insuranceNumber',
      label: 'Insurance Number',
      placeholder: 'Patient Insurance Number',
    },
  ] as const,
  clinicFilterOptions: [
    {
      id: 'legalname',
      label: 'Legal Name',
      placeholder: 'Legal Name',
    },
  ],
  agencyFilterOptions: [
    {
      id: 'legalname',
      label: 'Legal Name',
      placeholder: 'Legal Name',
    },
  ],
  doctorFilterOptions: [
    {
      id: 'name',
      label: 'Name',
      placeholder: 'Name',
    },
  ],
  agencyDoctorFilterOptions: [
    { id: 'fullName', label: 'Full Name', placeholder: 'John Doe' },
    {
      id: 'legalname',
      label: 'Legal Name',
      placeholder: 'Legal Name',
    },
    { id: 'email', label: 'Email', placeholder: 'patient@gmail.com' },
  ],
  patientPracticeOptions: [
    {
      id: 'birthDate',
      label: 'Birth Date',
      placeholder: common.dateFormats.birthDate,
    },
  ],
  profileFields: [
    { id: 'firstName', type: 'text', label: 'First Name' },
    { id: 'lastName', type: 'text', label: 'Last Name' },
    { id: 'middleName', type: 'text', label: 'Middle Name' },
    { id: 'email', type: 'text', label: 'Email' },
    { id: 'phone', type: 'text', label: 'Primary Phone Number' },
    { id: 'address', type: 'text', label: 'Address' },
    { id: 'birthDate', type: 'text', label: 'Birth Date' },
    {
      id: 'martialStatus',
      type: 'select',
      label: 'Marital Status',
      options: [...maritalStatuses],
    },
    {
      id: 'workingStatus',
      type: 'select',
      label: 'Working Status',
      options: [...workingStatuses],
    },
    {
      id: 'race',
      type: 'select',
      label: 'Race',
      options: [...races],
    },
    {
      id: 'gender',
      type: 'select',
      label: 'Gender',
      options: [...genders],
    },
    {
      id: 'timezone',
      type: 'select',
      label: 'Time Zone',
      options: TimeZones,
    },
    {
      id: 'emergencyContactNumber',
      type: 'text',
      label: 'Emergency Contact Number',
    },
    { id: 'city', type: 'text', label: 'City' },
    {
      id: 'state',
      type: 'select',
      label: 'State',
      options: usStatesWithNone,
      disabled: false,
    },
    { id: 'zipPostalCode', type: 'text', label: 'Zip Code' },
    { id: 'homeNumber', type: 'text', label: 'Secondary Phone Number' },
    {
      id: 'primaryNumberType',
      type: 'select',
      label: 'Primary Phone Number Type',
      options: [...numberType],
    },
    {
      id: 'secondaryNumberType',
      type: 'select',
      label: 'Secondary Phone Number Type',
      options: [...numberType],
    },
    {
      id: 'emergencyContactNumberType',
      type: 'select',
      label: 'Emergency Number Type',
      options: [...numberType],
    },
  ],
  orderConsumableFields: [
    {
      id: 'device_section',
      type: 'select',
      label: 'Glucometer Consumables',
      options: [
        { id: 'none', label: '' },
        {
          id: 'CBLS_G427B',
          label: 'G427B Strips and Lancets: Telli Health Device',
        },
      ],
    },
    {
      id: 'charger',
      type: 'select',
      label: 'Charger',
      options: [
        { id: 'none', label: '' },
        { id: 'USB-WC', label: 'USB-WC: USB Wall Charger' },
        { id: 'U807-PWR', label: 'U807-PWR: Telli Health BP Power Adapter' },
        { id: 'A600N-PWR', label: 'A600N-PWR: Telli Health BP Power Adapter' },
        {
          id: '4GTherm-PWR',
          label: '4GTherm-PWR: Telli Health BP Power Adapter',
        },
        {
          id: 'TSB8057-PWR',
          label: 'TSB8057-PWR: Telli Health BP Power Adapter',
        },
        {
          id: 'G427B-PWR',
          label: 'G427B-PWR: Telli Health BG Power Adapter',
        },
        {
          id: 'BP01-PWR',
          label: 'BP01-PWR: Telli Health BP Power Adapter',
        },
      ],
    },
    {
      id: 'blood_pressure',
      type: 'select',
      label: 'Blood Pressure',
      options: [
        { id: 'none', label: '' },
        {
          id: 'CUFF_SMALL_U807',
          label: 'CUFF_SMALL_U807: Bloodpressure Cuff, Small',
        },
        {
          id: 'CUFF_ADULT_U807',
          label: 'CUFF_ADULT_U807: Bloodpressure Cuff, Adult',
        },
        {
          id: 'CUFF_XL_U807',
          label: 'CUFF_XL_U807: Bloodpressure Cuff, Adult',
        },
        {
          id: 'CUFF_SMALL_A600N',
          label: 'CUFF_SMALL_A600N: Bloodpressure Cuff, Small',
        },
        {
          id: 'CUFF_ADULT_A600N',
          label: 'CUFF_ADULT_A600N: Bloodpressure Cuff, Adult',
        },
        {
          id: 'CUFF_XL_A600N',
          label: 'CUFF_XL_A600N: Bloodpressure Cuff, Adult',
        },
        {
          id: 'CUFF_SMALL_BP01',
          label: 'CUFF_SMALL_BP01: Bloodpressure Cuff, Small',
        },
        {
          id: 'CUFF_ADULT_BP01',
          label: 'CUFF_ADULT_BP01: Bloodpressure Cuff, Adult',
        },
        {
          id: 'CUFF_XL_BP01',
          label: 'CUFF_XL_BP01: Bloodpressure Cuff, Adult',
        },
      ],
    },
    {
      id: 'batteries',
      type: 'select',
      label: 'Batteries',
      options: [
        { id: 'none', label: '' },
        { id: 'aa', label: 'Batteries AA' },
        { id: 'aaa', label: 'Batteries AAA' },
      ],
    },
  ],
  sortSelect: [
    {
      label: 'Newest',
      value: false,
    },
    {
      label: 'Oldest',
      value: true,
    },
  ],
  medicationsFilterSelect: [
    { value: null, label: 'All' },
    { value: 'true', label: 'Active' },
    { value: 'false', label: 'Inactive' },
  ],
  clinicStatusFilterSelect: [
    { value: null, label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'delinquent', label: 'Delinquent' },
  ],
  loginStatusFilterSelect: [
    { value: null, label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'blocked', label: 'Blocked' },
  ],
  statusSelect: [
    { value: null, label: 'All' },
    { value: 'critical', label: 'Critical' },
    { value: 'risk', label: 'At-Risk' },
    { value: 'normal', label: 'Normal' },
  ],
  readingTypeSelect: [
    { value: null, label: 'All' },
    { value: 'BloodGlucose', label: 'Blood Glucose' },
    { value: 'BloodPressure', label: 'Blood Pressure' },
    { value: 'SpO2', label: 'SpO2' },
    { value: 'Temperature', label: 'Temperature' },
    { value: 'Weight', label: 'Weight' },
    { value: 'Pulse', label: 'Pulse' },
    { value: 'PeakFlow', label: 'Peak Flow' },
  ],
  assigneeSelect: [
    { value: null, label: 'All' },
    { value: 'me', label: 'Assigned To Me' },
    { value: 'free', label: 'Free To Assign' },
  ],
  activitiesType: [
    { value: false, label: 'Unaction' },
    { value: true, label: 'Actioned' },
  ],
  actionTimesQuotaSelect: [
    { value: null, label: 'All' },
    { value: 'over55', label: '55-60 mins' },
  ],
  patientDischargeStatuses: [
    {
      id: 'inactive_bad_no_signal_connectivity',
      label: 'Inactive Bad/No Signal Connectivity',
    },
    { id: 'inactive_changed_doctor', label: 'Inactive Changed Doctor' },
    {
      id: 'inactive_client_request_inconvenient',
      label: 'Inactive Client Request Inconvenient',
    },
    {
      id: 'inactive_client_request_moving',
      label: 'Inactive Client Request Moving',
    },
    {
      id: 'inactive_client_request_other',
      label: 'Inactive Client Request Other',
    },
    { id: 'inactive_expired', label: 'Inactive Expired' },
    {
      id: 'inactive_health_plan_request_goals_met',
      label: 'Inactive Health Plan Request Goals Met',
    },
    {
      id: 'inactive_health_plan_request_non_compliant',
      label: 'Inactive Health Plan Request Non Compliant',
    },
    {
      id: 'inactive_health_plan_request_other',
      label: 'Inactive Health Plan Request Other',
    },
    { id: 'inactive_hospice', label: 'Inactive Hospice' },
    { id: 'inactive_copay', label: 'Inactive Copay' },
    {
      id: 'inactive_long_term_care_rehab',
      label: 'Inactive Long Term Care/Rehab',
    },
    {
      id: 'inactive_loss_of_eligibility',
      label: 'Inactive Loss of Eligibility (Insurance)',
    },
    {
      id: 'inactive_physician_request',
      label: 'Inactive Physician Request',
    },
    {
      id: 'onboarding_unsuccessful',
      label: 'Onboarding Unsuccessful',
    },
  ],
  patientCcmDischargeStatuses: [
    {
      id: 'ccm_unenrolled_changed_doctor',
      label: 'CCM Unenrolled - Changed Doctor',
    },
    {
      id: 'ccm_unenrolled_client_request',
      label: 'CCM Unenrolled - Client Request',
    },
    {
      id: 'ccm_unenrolled_copay_loss_of_insurance',
      label: 'CCM Unenrolled - Copay/Loss of Insurance',
    },
    {
      id: 'ccm_unenrolled_non_adherent',
      label: 'CCM Unenrolled - Non-Adherent',
    },
    {
      id: 'ccm_unenrolled_expired',
      label: 'CCM Unenrolled - Expired',
    },
    {
      id: 'ccm_unenrolled_hospice',
      label: 'CCM Unenrolled - Hospice',
    },
    {
      id: 'ccm_unenrolled_long_term_skilled_nursing_facility_rehab',
      label: 'CCM Unenrolled - Long Term Skilled Nursing Facility/Rehab',
    },
    {
      id: 'ccm_unenrolled_physician_clinic_request',
      label: 'CCM Unenrolled - Physician/Clinic Request',
    },
    {
      id: 'ccm_unenrolled_client_request_moving',
      label: 'CCM Unenrolled - Client Request Moving',
    },
    {
      id: 'ccm_unenrolled_health_plan_request_goals_met',
      label: 'CCM Unenrolled - Health Plan Request Goals Met',
    },
    {
      id: 'ccm_unenrolled_other',
      label: 'CCM Unenrolled - Other',
    },
  ],
  patientCovidStatuses: [
    {
      id: 'covid_at_risk',
      label: 'Covid/At-riskCovid (2 days rule)',
    },
    {
      id: 'none',
      label: 'None (16 days rule)',
    },
  ],
  patientHoldingStatuses: [
    {
      id: 'vacation',
      label: 'Vacation',
    },
    {
      id: 'hospital',
      label: 'In Hospital',
    },
  ],
  ccmPatientStatuses: [
    { value: undefined, label: 'All' },
    {
      value: 'enrolled',
      label: 'Enrolled',
    },
    {
      value: 'ccm_calling_queue',
      label: 'CCM Calling Queue',
    },
    {
      value: 'ccm_patient_refused_copay_insurance',
      label: 'CCM Patient Refused - Co-pay/Insurance',
    },
    {
      value: 'ccm_patient_refused_expired',
      label: 'CCM Patient Refused - Expired',
    },
    {
      value: 'ccm_patient_refused_changed_doctor',
      label: 'CCM Patient Refused - Changed Doctor',
    },
    {
      value: 'ccm_patient_refused_not_interested_not_necessary_suspect_scam',
      label: 'CCM Patient Refused - Not interested/Not necessary/Suspect Scam',
    },
    {
      value: 'ccm_patient_refused_wrong_contact_information',
      label: 'CCM Patient Refused - Wrong Contact Information',
    },
    {
      value: 'ccm_patient_refused_no_contact',
      label: 'CCM Patient Refused - No Contact',
    },
    {
      value: 'ccm_patient_refused_wants_to_discuss_with_physician',
      label: 'CCM Patient Refused - Wants to Discuss with Physician',
    },
    {
      value: 'ccm_patient_refused_other',
      label: 'CCM Patient Refused - Other',
    },
    {
      id: 'ccm_referral_on_hold',
      value: 'ccm_referral_on_hold',
      label: 'CCM Referral On-Hold',
    },
    {
      id: 'ccm_referral_received',
      value: 'ccm_referral_received',
      label: 'CCM Referral Received',
    },
    {
      id: 'ccm_referral_withdrawn',
      value: 'ccm_referral_withdrawn',
      label: 'CCM Referral Withdrawn',
    },
    {
      id: 'ccm_patient_no_contact',
      value: 'ccm_patient_no_contact',
      label: 'CCM Patient No Contact',
    },
    {
      value: 'ccm_onboarding',
      label: 'CCM Onboarding',
    },
    {
      value: 'ccm_onboarding_unsuccessful_no_contact',
      label: 'CCM Onboarding Unsuccessful - No Contact',
    },
    {
      value: 'ccm_onboarding_unsuccessful_patient_refused',
      label: 'CCM Onboarding Unsuccessful - Patient Refused',
    },
    {
      value: 'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
      label: 'CCM Onboarding Unsuccessful - Patient Not Qualified for CCM',
    },
    {
      value: 'ccm_onboarding_unsuccessful_changed_doctor',
      label: 'CCM Onboarding Unsuccessful - Changed Doctor',
    },
    {
      value: 'ccm_onboarding_unsuccessful_other',
      label: 'CCM Onboarding Unsuccessful - Other',
    },
    {
      value: 'ccm_unenrolled_changed_doctor',
      label: 'CCM Unenrolled - Changed Doctor',
    },
    {
      value: 'ccm_unenrolled_client_request',
      label: 'CCM Unenrolled - Client Request',
    },
    {
      value: 'ccm_unenrolled_copay_loss_of_insurance',
      label: 'CCM Unenrolled - Copay/Loss of Insurance',
    },
    {
      value: 'ccm_unenrolled_non_adherent',
      label: 'CCM Unenrolled - Non-Adherent',
    },
    {
      value: 'ccm_unenrolled_expired',
      label: 'CCM Unenrolled - Expired',
    },
    {
      value: 'ccm_unenrolled_hospice',
      label: 'CCM Unenrolled - Hospice',
    },
    {
      value: 'ccm_unenrolled_long_term_skilled_nursing_facility_rehab',
      label: 'CCM Unenrolled - Long Term Skilled Nursing Facility/Rehab',
    },
    {
      value: 'ccm_unenrolled_physician_clinic_request',
      label: 'CCM Unenrolled - Physician/Clinic Request',
    },
    {
      value: 'ccm_unenrolled_client_request_moving',
      label: 'CCM Unenrolled - Client Request Moving',
    },
    {
      value: 'ccm_unenrolled_health_plan_request_goals_met',
      label: 'CCM Unenrolled - Health Plan Request Goals Met',
    },
    {
      value: 'ccm_unenrolled_other',
      label: 'CCM Unenrolled - Other',
    },
    {
      value: 'not_enrolled',
      label: 'Not Enrolled',
    },
  ],
  ccmPatientStatusesForPatientAcceptance: [
    { value: undefined, label: 'All' },
    {
      value: 'ccm_calling_queue',
      label: 'CCM Calling Queue',
    },
    {
      value: 'ccm_patient_refused_copay_insurance',
      label: 'CCM Patient Refused - Co-pay/Insurance',
    },
    {
      value: 'ccm_patient_refused_expired',
      label: 'CCM Patient Refused - Expired',
    },
    {
      value: 'ccm_patient_refused_changed_doctor',
      label: 'CCM Patient Refused - Changed Doctor',
    },
    {
      value: 'ccm_patient_refused_not_interested_not_necessary_suspect_scam',
      label: 'CCM Patient Refused - Not interested/Not necessary/Suspect Scam',
    },
    {
      value: 'ccm_patient_refused_wrong_contact_information',
      label: 'CCM Patient Refused - Wrong Contact Information',
    },
    {
      value: 'ccm_patient_refused_no_contact',
      label: 'CCM Patient Refused - No Contact',
    },
    {
      value: 'ccm_patient_refused_wants_to_discuss_with_physician',
      label: 'CCM Patient Refused - Wants to Discuss with Physician',
    },
    {
      value: 'ccm_patient_refused_other',
      label: 'CCM Patient Refused - Other',
    },
    {
      value: 'ccm_onboarding',
      label: 'CCM Onboarding',
    },
    {
      value: 'ccm_onboarding_unsuccessful_no_contact',
      label: 'CCM Onboarding Unsuccessful - No Contact',
    },
    {
      value: 'ccm_onboarding_unsuccessful_patient_refused',
      label: 'CCM Onboarding Unsuccessful - Patient Refused',
    },
    {
      value: 'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
      label: 'CCM Onboarding Unsuccessful - Patient Not Qualified for CCM',
    },
    {
      value: 'ccm_onboarding_unsuccessful_changed_doctor',
      label: 'CCM Onboarding Unsuccessful - Changed Doctor',
    },
    {
      value: 'ccm_onboarding_unsuccessful_other',
      label: 'CCM Onboarding Unsuccessful - Other',
    },
    {
      value: 'not_enrolled',
      label: 'Not Enrolled',
    },
    {
      id: 'ccm_referral_on_hold',
      value: 'ccm_referral_on_hold',
      label: 'CCM Referral On-Hold',
    },
    {
      id: 'ccm_referral_received',
      value: 'ccm_referral_received',
      label: 'CCM Referral Received',
    },
    {
      id: 'ccm_referral_withdrawn',
      value: 'ccm_referral_withdrawn',
      label: 'CCM Referral Withdrawn',
    },
    {
      id: 'ccm_patient_no_contact',
      value: 'ccm_patient_no_contact',
      label: 'CCM Patient No Contact',
    },
  ],
  ccmInteractionStatuses: [
    { value: undefined, label: 'All' },
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ],
  ccmPatientActivities: [
    {
      id: 'patient-notes',
      label: 'Note',
    },
    {
      id: 'patient-message-response',
      label: 'SMS',
    },
    {
      id: 'patient-call',
      label: 'Call',
    },
    {
      id: 'patient-icd',
      label: 'ICD-10-Code',
    },
    {
      id: 'manual',
      label: 'Manual Time Log',
    },
  ],
  ccmHoldingStatuses: [
    { value: undefined, label: 'All' },
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ],
  patientOnboardingStatus: [
    { value: 'only_onboarding_flow', label: 'All' },
    { value: 'calling_queue', label: 'Calling Queue' },
    { value: 'patient_referral', label: 'Patient Referral' },
    { value: 'referral_on_hold', label: 'Referral On-Hold' },
    {
      value: 'patient_no_contact',
      label: 'Patient No Contact',
    },
    {
      id: 'patient_refused_connectivity_or_tech_barier',
      value: 'patient_refused_connectivity_or_tech_barier',
      label: 'Patient Refused - Connectivity/Tech Barier',
    },
    {
      id: 'patient_refused_copay_insurance',
      value: 'patient_refused_copay_insurance',
      label: 'Patient Refused - Co-pay/Insurance',
    },
    {
      id: 'patient_refused_not_interested_not_necessary_suspect_scam',
      value: 'patient_refused_not_interested_not_necessary_suspect_scam',
      label: 'Patient Refused - Not interested/Not necessary/Suspect Scam',
    },
    {
      id: 'patient_refused_expired',
      value: 'patient_refused_expired',
      label: 'Patient Refused - Expired',
    },
    {
      id: 'patient_refused_there_is_no_one_to_help',
      value: 'patient_refused_there_is_no_one_to_help',
      label: 'Patient Refused - There is No one to Help',
    },
    {
      id: 'patient_refused_has_a_new_physician',
      value: 'patient_refused_has_a_new_physician',
      label: 'Patient Refused - Has a New Physician',
    },
    {
      id: 'patient_refused_wants_to_discuss_with_physician',
      value: 'patient_refused_wants_to_discuss_with_physician',
      label: 'Patient Refused - Wants to Discuss with Physician',
    },
    {
      id: 'patient_refused_wrong_contact_information',
      value: 'patient_refused_wrong_contact_information',
      label: 'Patient Refused - Wrong Contact Information',
    },
    {
      value: 'insurance_refused',
      label: 'Insurance Refused',
    },
    { value: 'onboarding', label: 'Onboarding' },
    { value: 'onboarding_unsuccessful', label: 'Onboarding Unsuccessful' },
  ],
  patientInactiveStatus: [
    {
      value: 'inactive_bad_no_signal_connectivity',
      label: 'Inactive Bad No Signal Connectivity',
    },
    { value: 'inactive_changed_doctor', label: 'Inactive Changed Doctor' },
    {
      value: 'inactive_client_request_inconvenient',
      label: 'Inactive Client Request Inconvenient',
    },
    {
      value: 'inactive_client_request_moving',
      label: 'Inactive Client Request Moving',
    },
    {
      value: 'inactive_client_request_other',
      label: 'Inactive Client Request Other',
    },
    { value: 'inactive_expired', label: 'Inactive Expired' },
    {
      value: 'inactive_health_plan_request_goals_met',
      label: 'Inactive Health Plan Request Goals Met',
    },
    {
      value: 'inactive_health_plan_request_non_compliant',
      label: 'Inactive Health Plan Request Non Compliant',
    },
    {
      value: 'inactive_health_plan_request_other',
      label: 'Inactive Health Plan Request Other',
    },
    { value: 'inactive_hospice', label: 'Inactive Hospice' },
    { value: 'inactive_copay', label: 'Inactive Copay' },
    {
      value: 'inactive_long_term_care_rehab',
      label: 'Inactive Long Term Care Rehab',
    },
    {
      value: 'inactive_loss_of_eligibility',
      label: 'Inactive Loss Of Eligibility',
    },
    {
      value: 'inactive_physician_request',
      label: 'Inactive Physician Request',
    },
  ],
  // Order:
  // 1.Active
  // 2.Onboarding statuses
  // 3.Inactive statuses
  // sorted by alphabet
  patientStatuses: [
    { id: 'active', value: 'active', label: 'Active' },
    {
      id: 'calling_queue',
      value: 'calling_queue',
      label: 'Calling Queue',
    },
    {
      id: 'insurance_refused',
      value: 'insurance_refused',
      label: 'Insurance Refused',
    },
    {
      id: 'onboarding',
      value: 'onboarding',
      label: 'Onboarding',
    },
    {
      id: 'onboarding_unsuccessful',
      value: 'onboarding_unsuccessful',
      label: 'Onboarding Unsuccessful',
    },
    {
      id: 'patient_no_contact',
      value: 'patient_no_contact',
      label: 'Patient No Contact',
    },
    {
      id: 'patient_referral',
      value: 'patient_referral',
      label: 'Patient Referral',
    },
    {
      id: 'referral_on_hold',
      value: 'referral_on_hold',
      label: 'Referral On-Hold',
    },
    {
      id: 'patient_refused_copay_insurance',
      value: 'patient_refused_copay_insurance',
      label: 'Patient Refused - Co-pay/Insurance',
    },
    {
      id: 'patient_refused_connectivity_or_tech_barier',
      value: 'patient_refused_connectivity_or_tech_barier',
      label: 'Patient Refused - Connectivity/Tech Barier',
    },
    {
      id: 'patient_refused_expired',
      value: 'patient_refused_expired',
      label: 'Patient Refused - Expired',
    },
    {
      id: 'patient_refused_has_a_new_physician',
      value: 'patient_refused_has_a_new_physician',
      label: 'Patient Refused - Has a New Physician',
    },
    {
      id: 'patient_refused_not_interested_not_necessary_suspect_scam',
      value: 'patient_refused_not_interested_not_necessary_suspect_scam',
      label: 'Patient Refused - Not interested/Not necessary/Suspect Scam',
    },
    {
      id: 'patient_refused_there_is_no_one_to_help',
      value: 'patient_refused_there_is_no_one_to_help',
      label: 'Patient Refused - There is No one to Help',
    },
    {
      id: 'patient_refused_wants_to_discuss_with_physician',
      value: 'patient_refused_wants_to_discuss_with_physician',
      label: 'Patient Refused - Wants to Discuss with Physician',
    },
    {
      id: 'patient_refused_wrong_contact_information',
      value: 'patient_refused_wrong_contact_information',
      label: 'Patient Refused - Wrong Contact Information',
    },
    {
      id: 'pending_review',
      value: 'pending_review',
      label: 'Pending Review',
    },
    {
      id: 'referral_withdrawn',
      value: 'referral_withdrawn',
      label: 'Referral Withdrawn',
    },
    {
      id: 'inactive_bad_no_signal_connectivity',
      value: 'inactive_bad_no_signal_connectivity',
      label: 'Inactive Bad/No Signal Connectivity',
    },
    {
      id: 'inactive_changed_doctor',
      value: 'inactive_changed_doctor',
      label: 'Inactive Changed Doctor',
    },
    {
      id: 'inactive_client_request_inconvenient',
      value: 'inactive_client_request_inconvenient',
      label: 'Inactive Client Request Inconvenient',
    },
    {
      id: 'inactive_client_request_moving',
      value: 'inactive_client_request_moving',
      label: 'Inactive Client Request Moving',
    },
    {
      id: 'inactive_client_request_other',
      value: 'inactive_client_request_other',
      label: 'Inactive Client Request Other',
    },
    {
      id: 'inactive_copay',
      value: 'inactive_copay',
      label: 'Inactive Copay',
    },
    {
      id: 'inactive_expired',
      value: 'inactive_expired',
      label: 'Inactive Expired',
    },
    {
      id: 'inactive_health_plan_request_goals_met',
      value: 'inactive_health_plan_request_goals_met',
      label: 'Inactive Health Plan Request Goals Met',
    },
    {
      id: 'inactive_health_plan_request_non_compliant',
      value: 'inactive_health_plan_request_non_compliant',
      label: 'Inactive Health Plan Request Non Compliant',
    },
    {
      id: 'inactive_health_plan_request_other',
      value: 'inactive_health_plan_request_other',
      label: 'Inactive Health Plan Request Other',
    },
    {
      id: 'inactive_hospice',
      value: 'inactive_hospice',
      label: 'Inactive Hospice',
    },
    {
      id: 'inactive_long_term_care_rehab',
      value: 'inactive_long_term_care_rehab',
      label: 'Inactive Long Term Care/Rehab',
    },
    {
      id: 'inactive_loss_of_eligibility',
      value: 'inactive_loss_of_eligibility',
      label: 'Inactive Loss of Eligibility (Insurance)',
    },
    {
      id: 'inactive_physician_request',
      value: 'inactive_physician_request',
      label: 'Inactive Physician Request',
    },
  ],
  // Order:
  // 1.Active
  // 2.Onboarding statuses
  // 3.Inactive statuses
  // sorted by alphabet
  ccmStatusOptions: [
    {
      id: 'enrolled',
      value: 'enrolled',
      label: 'Enrolled',
    },
    {
      id: 'ccm_calling_queue',
      value: 'ccm_calling_queue',
      label: 'CCM Calling Queue',
    },
    {
      id: 'ccm_patient_refused_copay_insurance',
      value: 'ccm_patient_refused_copay_insurance',
      label: 'CCM Patient Refused - Co-pay/Insurance',
    },
    {
      id: 'ccm_patient_refused_expired',
      value: 'ccm_patient_refused_expired',
      label: 'CCM Patient Refused - Expired',
    },
    {
      id: 'ccm_patient_refused_changed_doctor',
      value: 'ccm_patient_refused_changed_doctor',
      label: 'CCM Patient Refused - Changed Doctor',
    },
    {
      id: 'ccm_patient_refused_not_interested_not_necessary_suspect_scam',
      value: 'ccm_patient_refused_not_interested_not_necessary_suspect_scam',
      label: 'CCM Patient Refused - Not interested/Not necessary/Suspect Scam',
    },
    {
      id: 'ccm_patient_refused_no_contact',
      value: 'ccm_patient_refused_no_contact',
      label: 'CCM Patient Refused - No Contact',
    },
    {
      id: 'ccm_patient_refused_wrong_contact_information',
      value: 'ccm_patient_refused_wrong_contact_information',
      label: 'CCM Patient Refused - Wrong Contact Information',
    },
    {
      id: 'ccm_patient_refused_wants_to_discuss_with_physician',
      value: 'ccm_patient_refused_wants_to_discuss_with_physician',
      label: 'CCM Patient Refused - Wants to Discuss with Physician',
    },
    {
      id: 'ccm_patient_refused_other',
      value: 'ccm_patient_refused_other',
      label: 'CCM Patient Refused - Other',
    },
    {
      id: 'ccm_referral_on_hold',
      value: 'ccm_referral_on_hold',
      label: 'CCM Referral On-Hold',
    },
    {
      id: 'ccm_referral_received',
      value: 'ccm_referral_received',
      label: 'CCM Referral Received',
    },
    {
      id: 'ccm_referral_withdrawn',
      value: 'ccm_referral_withdrawn',
      label: 'CCM Referral Withdrawn',
    },
    {
      id: 'ccm_patient_no_contact',
      value: 'ccm_patient_no_contact',
      label: 'CCM Patient No Contact',
    },
    {
      id: 'ccm_onboarding',
      value: 'ccm_onboarding',
      label: 'CCM Onboarding',
    },
    {
      id: 'ccm_onboarding_unsuccessful_no_contact',
      value: 'ccm_onboarding_unsuccessful_no_contact',
      label: 'CCM Onboarding Unsuccessful - No Contact',
    },
    {
      id: 'ccm_onboarding_unsuccessful_patient_refused',
      value: 'ccm_onboarding_unsuccessful_patient_refused',
      label: 'CCM Onboarding Unsuccessful - Patient Refused',
    },
    {
      id: 'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
      value: 'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
      label: 'CCM Onboarding Unsuccessful - Patient Not Qualified for CCM',
    },
    {
      id: 'ccm_onboarding_unsuccessful_changed_doctor',
      value: 'ccm_onboarding_unsuccessful_changed_doctor',
      label: 'CCM Onboarding Unsuccessful - Changed Doctor',
    },
    {
      id: 'ccm_onboarding_unsuccessful_other',
      value: 'ccm_onboarding_unsuccessful_other',
      label: 'CCM Onboarding Unsuccessful - Other',
    },
    {
      id: 'ccm_unenrolled_changed_doctor',
      value: 'ccm_unenrolled_changed_doctor',
      label: 'CCM Unenrolled - Changed Doctor',
    },
    {
      id: 'ccm_unenrolled_client_request',
      value: 'ccm_unenrolled_client_request',
      label: 'CCM Unenrolled - Client Request',
    },
    {
      id: 'ccm_unenrolled_copay_loss_of_insurance',
      value: 'ccm_unenrolled_copay_loss_of_insurance',
      label: 'CCM Unenrolled - Copay/Loss of Insurance',
    },
    {
      id: 'ccm_unenrolled_non_adherent',
      value: 'ccm_unenrolled_non_adherent',
      label: 'CCM Unenrolled - Non-Adherent',
    },
    {
      id: 'ccm_unenrolled_expired',
      value: 'ccm_unenrolled_expired',
      label: 'CCM Unenrolled - Expired',
    },
    {
      id: 'ccm_unenrolled_hospice',
      value: 'ccm_unenrolled_hospice',
      label: 'CCM Unenrolled - Hospice',
    },
    {
      id: 'ccm_unenrolled_long_term_skilled_nursing_facility_rehab',
      value: 'ccm_unenrolled_long_term_skilled_nursing_facility_rehab',
      label: 'CCM Unenrolled - Long Term Skilled Nursing Facility/Rehab',
    },
    {
      id: 'ccm_unenrolled_physician_clinic_request',
      value: 'ccm_unenrolled_physician_clinic_request',
      label: 'CCM Unenrolled - Physician/Clinic Request',
    },
    {
      id: 'ccm_unenrolled_client_request_moving',
      value: 'ccm_unenrolled_client_request_moving',
      label: 'CCM Unenrolled - Client Request Moving',
    },
    {
      id: 'ccm_unenrolled_health_plan_request_goals_met',
      value: 'ccm_unenrolled_health_plan_request_goals_met',
      label: 'CCM Unenrolled - Health Plan Request Goals Met',
    },
    {
      id: 'ccm_unenrolled_other',
      value: 'ccm_unenrolled_other',
      label: 'CCM Unenrolled - Other',
    },
    {
      id: 'not_enrolled',
      value: 'not_enrolled',
      label: 'Not Enrolled',
    },
  ],
  ccmStatusOptionsForPatientAcceptanceRole: [
    {
      id: 'ccm_calling_queue',
      value: 'ccm_calling_queue',
      label: 'CCM Calling Queue',
    },
    {
      id: 'ccm_patient_refused_copay_insurance',
      value: 'ccm_patient_refused_copay_insurance',
      label: 'CCM Patient Refused - Co-pay/Insurance',
    },
    {
      id: 'ccm_patient_refused_expired',
      value: 'ccm_patient_refused_expired',
      label: 'CCM Patient Refused - Expired',
    },
    {
      id: 'ccm_patient_refused_changed_doctor',
      value: 'ccm_patient_refused_changed_doctor',
      label: 'CCM Patient Refused - Changed Doctor',
    },
    {
      id: 'ccm_patient_refused_not_interested_not_necessary_suspect_scam',
      value: 'ccm_patient_refused_not_interested_not_necessary_suspect_scam',
      label: 'CCM Patient Refused - Not interested/Not necessary/Suspect Scam',
    },
    {
      id: 'ccm_patient_refused_wrong_contact_information',
      value: 'ccm_patient_refused_wrong_contact_information',
      label: 'CCM Patient Refused - Wrong Contact Information',
    },
    {
      id: 'ccm_patient_refused_wants_to_discuss_with_physician',
      value: 'ccm_patient_refused_wants_to_discuss_with_physician',
      label: 'CCM Patient Refused - Wants to Discuss with Physician',
    },
    {
      id: 'ccm_patient_refused_no_contact',
      value: 'ccm_patient_refused_no_contact',
      label: 'CCM Patient Refused - No Contact',
    },
    {
      id: 'ccm_patient_refused_other',
      value: 'ccm_patient_refused_other',
      label: 'CCM Patient Refused - Other',
    },
    {
      id: 'ccm_referral_on_hold',
      value: 'ccm_referral_on_hold',
      label: 'CCM Referral On-Hold',
    },
    {
      id: 'ccm_referral_received',
      value: 'ccm_referral_received',
      label: 'CCM Referral Received',
    },
    {
      id: 'ccm_referral_withdrawn',
      value: 'ccm_referral_withdrawn',
      label: 'CCM Referral Withdrawn',
    },
    {
      id: 'ccm_patient_no_contact',
      value: 'ccm_patient_no_contact',
      label: 'CCM Patient No Contact',
    },
    {
      id: 'ccm_onboarding',
      value: 'ccm_onboarding',
      label: 'CCM Onboarding',
    },
    {
      id: 'ccm_onboarding_unsuccessful_no_contact',
      value: 'ccm_onboarding_unsuccessful_no_contact',
      label: 'CCM Onboarding Unsuccessful - No Contact',
    },
    {
      id: 'ccm_onboarding_unsuccessful_patient_refused',
      value: 'ccm_onboarding_unsuccessful_patient_refused',
      label: 'CCM Onboarding Unsuccessful - Patient Refused',
    },
    {
      id: 'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
      value: 'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
      label: 'CCM Onboarding Unsuccessful - Patient Not Qualified for CCM',
    },
    {
      id: 'ccm_onboarding_unsuccessful_changed_doctor',
      value: 'ccm_onboarding_unsuccessful_changed_doctor',
      label: 'CCM Onboarding Unsuccessful - Changed Doctor',
    },
    {
      id: 'ccm_onboarding_unsuccessful_other',
      value: 'ccm_onboarding_unsuccessful_other',
      label: 'CCM Onboarding Unsuccessful - Other',
    },
    {
      id: 'not_enrolled',
      value: 'not_enrolled',
      label: 'Not Enrolled',
    },
  ],
  pcmStatuses: [
    { id: true, value: true, label: 'True' },
    { id: false, value: false, label: 'False' },
  ],
  selectServiceStatus: [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'no_service_plan', label: 'No Service Plan' },
    { value: 'expiring_soon', label: 'Expiring Soon' },
    { value: 'expired', label: 'Expired' },
  ],
  clinicInsuranceTypeSelect: [
    { value: null, label: 'All' },
    { value: 'Medicare', label: 'Medicare' },
    { value: 'Texas Medicaid', label: 'Texas Medicaid' },
    { value: 'Private Pay', label: 'Private Pay' },
    { value: 'Texas Medicaid HMO', label: 'Texas Medicaid HMO' },
    { value: 'Commercial', label: 'Commercial' },
    { value: 'California Medicaid', label: 'California Medicaid' },
  ],
  insuranceProviders: [...insuranceProviders],
  insuranceTypes: [...insuranceTypes],
  fulfillmentStage: [
    { value: 'all', label: 'All' },
    { value: 'patient_referral', label: 'Patient Referral' },
    { value: 'referral_on_hold', label: 'Referral On-Hold' },
    { value: 'referral_withdrawn', label: 'Referral Withdrawn' },
    { value: 'calling_queue', label: 'Calling Queue' },
    { value: 'patient_no_contact', label: 'Patient No Contact' },
    {
      value: 'patient_refused_connectivity_or_tech_barier',
      label: 'Patient Refused - Connectivity/Tech Barier',
    },
    {
      value: 'patient_refused_copay_insurance',
      label: 'Patient Refused - Co-pay/Insurance',
    },
    {
      value: 'patient_refused_not_interested_not_necessary_suspect_scam',
      label: 'Patient Refused - Not interested/Not necessary/Suspect Scam',
    },
    { value: 'patient_refused_expired', label: 'Patient Refused - Expired' },
    {
      value: 'patient_refused_there_is_no_one_to_help',
      label: 'Patient Refused - There is No one to Help',
    },
    {
      value: 'patient_refused_has_a_new_physician',
      label: 'Patient Refused - Has a New Physician',
    },
    {
      value: 'patient_refused_wants_to_discuss_with_physician',
      label: 'Patient Refused - Wants to Discuss with Physician',
    },
    {
      value: 'patient_refused_wrong_contact_information',
      label: 'Patient Refused - Wrong Contact Information',
    },
    { value: 'insurance_refused', label: 'Insurance Refused' },
    { value: 'onboarding', label: 'Onboarding' },
    { value: 'onboarding_unsuccessful', label: 'Onboarding Unsuccessful' },
  ],
  pcmStatusesFilter: [
    { id: null, value: null, label: 'All' },
    { id: 'true', value: 'true', label: 'True' },
    { id: 'false', value: 'false', label: 'False' },
  ],
  ccmFulfillmentStage: [
    {
      value: ccmOnboardingStatuses.join(','),
      label: 'All',
    },
    { value: 'ccm_referral_received', label: 'CCM Referral Received' },
    { value: 'ccm_referral_on_hold', label: 'CCM Referral On-Hold' },
    { value: 'ccm_referral_withdrawn', label: 'CCM Referral Withdrawn' },
    { value: 'ccm_patient_no_contact', label: 'CCM Patient No Contact' },
    {
      value: 'ccm_calling_queue',
      label: 'CCM Calling Queue',
    },
    {
      value: 'ccm_patient_refused_copay_insurance',
      label: 'CCM Patient Refused - Co-pay/Insurance',
    },
    {
      value: 'ccm_patient_refused_expired',
      label: 'CCM Patient Refused - Expired',
    },
    {
      value: 'ccm_patient_refused_changed_doctor',
      label: 'CCM Patient Refused - Changed Doctor',
    },
    {
      value: 'ccm_patient_refused_not_interested_not_necessary_suspect_scam',
      label: 'CCM Patient Refused - Not interested/Not necessary/Suspect Scam',
    },
    {
      value: 'ccm_patient_refused_no_contact',
      label: 'CCM Patient Refused - No Contact',
    },
    {
      value: 'ccm_patient_refused_wrong_contact_information',
      label: 'CCM Patient Refused - Wrong Contact Information',
    },
    {
      value: 'ccm_patient_refused_wants_to_discuss_with_physician',
      label: 'CCM Patient Refused - Wants to Discuss with Physician',
    },
    {
      value: 'ccm_patient_refused_other',
      label: 'CCM Patient Refused - Other',
    },
    {
      value: 'ccm_onboarding',
      label: 'CCM Onboarding',
    },
    {
      value: 'ccm_onboarding_unsuccessful_no_contact',
      label: 'CCM Onboarding Unsuccessful - No Contact',
    },
    {
      value: 'ccm_onboarding_unsuccessful_patient_refused',
      label: 'CCM Onboarding Unsuccessful - Patient Refused',
    },
    {
      value: 'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
      label: 'CCM Onboarding Unsuccessful - Patient Not Qualified for CCM',
    },
    {
      value: 'ccm_onboarding_unsuccessful_changed_doctor',
      label: 'CCM Onboarding Unsuccessful - Changed Doctor',
    },
    {
      value: 'ccm_onboarding_unsuccessful_other',
      label: 'CCM Onboarding Unsuccessful - Other',
    },
  ],
  thresholdsFields: [
    {
      id: 'criticalBloodoxygen',
      type: 'number',
      label: 'Critical',
    },
    {
      id: 'riskBloodoxygen',
      type: 'number',
      label: 'At Risk',
    },
    {
      id: 'maxCriticalSystolic',
      type: 'number',
      label: 'Max Critical',
    },
    {
      id: 'minCriticalSystolic',
      type: 'number',
      label: 'Min Critical',
    },
    {
      id: 'maxRiskSystolic',
      type: 'number',
      label: 'Max at Risk',
    },
    {
      id: 'minRiskSystolic',
      type: 'number',
      label: 'Min at Risk',
    },
    {
      id: 'maxCriticalDiastolic',
      type: 'number',
      label: 'Max Critical',
    },
    {
      id: 'minCriticalDiastolic',
      type: 'number',
      label: 'Min Critical',
    },
    {
      id: 'maxRiskDiastolic',
      type: 'number',
      label: 'Max at Risk',
    },
    {
      id: 'minRiskDiastolic',
      type: 'number',
      label: 'Min at Risk',
    },
    {
      id: 'maxCriticalPulse',
      type: 'number',
      label: 'Max Critical',
    },
    {
      id: 'minCriticalPulse',
      type: 'number',
      label: 'Min Critical',
    },
    {
      id: 'maxCriticalSleepPulse',
      type: 'number',
      label: 'Max Critical',
    },
    {
      id: 'minCriticalSleepPulse',
      type: 'number',
      label: 'Min Critical',
    },
    {
      id: 'maxRiskPulse',
      type: 'number',
      label: 'Max at Risk',
    },
    {
      id: 'minRiskPulse',
      type: 'number',
      label: 'Min at Risk',
    },
    {
      id: 'maxRiskSleepPulse',
      type: 'number',
      label: 'Max at Risk',
    },
    {
      id: 'minRiskSleepPulse',
      type: 'number',
      label: 'Min at Risk',
    },
    {
      id: 'riskBloodoxygen',
      type: 'number',
      label: 'At Risk',
    },
    {
      id: 'maxCriticalGlucose',
      type: 'number',
      label: 'Max Critical',
    },
    {
      id: 'minCriticalGlucose',
      type: 'number',
      label: 'Min Critical',
    },
    {
      id: 'maxRiskGlucose',
      type: 'number',
      label: 'Max at Risk',
    },
    {
      id: 'minRiskGlucose',
      type: 'number',
      label: 'Min at Risk',
    },
    {
      id: 'baseLineWeight',
      type: 'number',
      label: 'Base Line',
    },
    {
      id: 'maxCriticalWeight',
      type: 'number',
      label: 'Max at Risk',
    },
    {
      id: 'minCriticalWeight',
      type: 'number',
      label: 'Min at Risk',
    },
    {
      id: 'minRiskWeight',
      type: 'number',
      label: 'Min at Risk',
    },
    {
      id: 'maxRiskWeight',
      type: 'number',
      label: 'Min at Risk',
    },
    {
      id: 'minCriticalFev1',
      type: 'number',
      label: 'Min Critical (L)',
    },
    {
      id: 'minCriticalPef',
      type: 'number',
      label: 'Min Critical (L/min)',
    },
    {
      id: 'minRiskPef',
      type: 'number',
      label: 'Min At Risk (L/min)',
    },
  ],
  careLocationsFields: [
    {
      id: 'facilityName',
      type: 'text',
      label: 'Name locations',
    },
    {
      id: 'facilityAddress',
      type: 'text',
      label: 'Address',
    },
    {
      id: 'city',
      type: 'text',
      label: 'City',
    },
    {
      id: 'state',
      type: 'select',
      label: 'State',
      options: usStatesWithNone,
      disabled: false,
    },
    {
      id: 'zipCode',
      type: 'text',
      label: 'Zip code',
    },
    {
      id: 'facilityNumber',
      type: 'text',
      label: 'Facility number',
    },
  ],
  titles: [
    { id: 'Mr.', label: 'Mr.' },
    { id: 'Mrs.', label: 'Mrs.' },
    { id: 'Ms.', label: 'Ms.' },
  ],
  preferredContactMethod: [
    { id: 'email', label: 'Email' },
    { id: 'phone', label: 'Phone' },
    { id: 'sms', label: 'Sms' },
  ],
  preferredLanguage: [...languages],
  readingTypes: [
    { value: 'BloodPressure', id: 'BloodPressure', label: 'Blood Pressure' },
    { value: 'BloodGlucose', id: 'BloodGlucose', label: 'Blood Glucose' },
    { value: 'Weight', id: 'Weight', label: 'Weight' },
    { value: 'SpO2', id: 'SpO2', label: 'SpO2' },
    { value: 'Temperature', id: 'Temperature', label: 'Temperature' },
    { value: 'SleepingMat', id: 'SleepingMat', label: 'Sleeping Mat' },
    { value: 'PeakFlow', id: 'PeakFlow', label: 'Peak Flow' },
  ],
  sleepingMatTypes: ['SleepingMatActivity', 'SleepingMatDuration'],
  userRoles: [
    { value: 'all', id: 'all', label: 'All' },
    { value: 'doctor', id: 'doctor', label: 'Doctor' },
    {
      value: 'md-physician',
      id: 'md-physician',
      label: 'Medical Doctor (MD)-Physician',
    },
    { value: 'physician', id: 'physician', label: 'Physician' },
    { value: 'pa', id: 'pa', label: 'Physician Assistant' },
    {
      value: 'pa-nurse',
      id: 'pa-nurse',
      label: 'Physician Assistant (PA)-Nurse',
    },
    { value: 'rn', id: 'rn', label: 'Registered Nurse' },
  ],
  adminPanelsUser: {
    userEdit: {
      personalInfo: [
        {
          id: 'title',
          type: 'select',
          label: 'Title',
          options: [
            { id: 'Mr.', label: 'Mr.' },
            { id: 'Mrs.', label: 'Mrs.' },
            { id: 'Ms.', label: 'Ms.' },
          ],
        },
        { id: 'firstName', type: 'text', label: 'First Name' },
        { id: 'middleName', type: 'text', label: 'Middle Name' },
        { id: 'lastName', type: 'text', label: 'Last Name' },
        { id: 'birthDate', type: 'text', label: 'Birth Date' },
        {
          id: 'race',
          type: 'select',
          label: 'Race',
          options: [...races],
        },
        {
          id: 'gender',
          type: 'select',
          label: 'Gender',
          options: [...genders],
        },
        {
          id: 'martialStatus',
          type: 'select',
          label: 'Marital Status',
          options: [...maritalStatuses],
        },
        {
          id: 'language',
          type: 'select',
          label: 'Preferred Language',
          options: [...languages],
        },
        {
          id: 'workingStatus',
          type: 'select',
          label: 'Working Status',
          options: [...workingStatuses],
        },
        { id: 'ethnicity', type: 'text', label: 'Ethnicity' },
        { id: 'auth0Enabled', type: 'text', label: 'Create Evelyn Account' },
      ],
      contactInfo: [
        { id: 'phone', type: 'text', label: 'Primary Phone Number' },
        {
          id: 'primaryNumberType',
          type: 'select',
          label: 'Primary Phone Number Type',
          options: [...numberType],
        },
        { id: 'address', type: 'text', label: 'Address' },
        {
          id: 'timezone',
          type: 'select',
          label: 'Timezone',
          defaultValue: TimeZones[0],
          options: TimeZones,
        },
        {
          id: 'state',
          type: 'select',
          label: 'State',
          options: usStatesWithNone,
          disabled: false,
        },
        { id: 'email', type: 'text', label: 'Email' },
        { id: 'city', type: 'text', label: 'City' },
        {
          id: 'preferredContactMethod',
          type: 'select',
          label: 'Preferred contact method',
          options: [
            { id: 'email', label: 'Email' },
            { id: 'phone', label: 'Phone' },
            { id: 'sms', label: 'Sms' },
          ],
        },
        { id: 'zipPostalCode', type: 'text', label: 'Zip code' },
        { id: 'emergencyContact', type: 'text', label: 'Emergency Contact' },
        {
          id: 'emergencyContactNumber',
          type: 'text',
          label: 'Emergency Contact Number',
        },
        {
          id: 'emergencyContactNumberType',
          type: 'select',
          label: 'Emergency Number Type',
          options: [...numberType],
        },
        {
          id: 'emergencyContactRelationship',
          type: 'text',
          label: 'Emergency Contact Relationship',
        },
        { id: 'homeNumber', type: 'text', label: 'Secondary Phone Number' },
        {
          id: 'secondaryNumberType',
          type: 'select',
          label: 'Secondary Phone Number Type',
          options: [...numberType],
        },
      ],
      dxcodes: [
        { id: 'bloodGlucose', type: 'text', label: 'Blood Glucose' },
        { id: 'bloodPressure', type: 'text', label: 'Blood Pressure' },
        { id: 'weight', type: 'text', label: 'Weight' },
        { id: 'pulmonary', type: 'text', label: 'Pulmonary' },
        { id: 'sleep', type: 'text', label: 'Sleep' },
        { id: 'allCodes', type: 'text', label: 'All Codes' },
      ],
      appointmentDetails: [
        {
          id: 'appointmentDate',
          type: 'date',
          label: 'Appointment Date',
          format: 'MMM dd yyyy',
        },
        {
          id: 'appointmentTime',
          type: 'time',
          label: 'Appointment Time (CST)',
          timeIntervals: 30,
        },
        {
          id: 'appointmentMethod',
          type: 'select',
          label: 'Appointment Method',
          options: [...nextAppointmentMethod],
        },
      ],
      doctorEmr: [
        { id: 'emrName', type: 'text', label: 'EMR Name' },
        { id: 'emrId1', type: 'text', label: 'EMR ID 1' },
        { id: 'emrId2', type: 'text', label: 'EMR ID 2' },
        {
          id: 'supervisingProviderId',
          type: 'text',
          label: 'Supervising Provider',
        },
        {
          id: 'renderingProviderId',
          type: 'text',
          label: 'Rendering Provider',
        },
      ],
      emr: [
        { id: 'account', type: 'text', label: 'Patient MRN ID' },
        { id: 'emrName', type: 'text', label: 'EMR Name' },
        { id: 'emrId1', type: 'text', label: 'EMR ID 1' },
        { id: 'emrId2', type: 'text', label: 'EMR ID 2' },
        {
          id: 'supervisingProviderId',
          type: 'text',
          label: 'Supervising Provider',
        },
        {
          id: 'renderingProviderId',
          type: 'text',
          label: 'Rendering Provider',
        },
      ],
      priorAuth: [
        {
          id: 'authorizationNumber',
          type: 'number',
          label: 'Authorization number',
        },
        { id: 'startDate', type: 'date', label: 'Start Date' },
        { id: 'endDate', type: 'date', label: 'End Date' },
      ],
      clinic: [
        { id: 'clinicAddress', label: 'Address', type: 'text' },
        { id: 'clinicState', label: 'State', type: 'text' },
        { id: 'clinicCity', label: 'City', type: 'text' },
      ],
      thresholds: {
        systolic: [
          { id: 'maxCriticalSystolic', label: 'Max Critical', type: 'text' },
          { id: 'minCriticalSystolic', label: 'Min Critical', type: 'text' },
          { id: 'maxRiskSystolic', label: 'Max at risk', type: 'text' },
          { id: 'minRiskSystolic', label: 'Min At Risk', type: 'text' },
        ],
        diastolic: [
          { id: 'maxCriticalDiastolic', label: 'Max Critical', type: 'text' },
          { id: 'minCriticalDiastolic', label: 'Min Critical', type: 'text' },
          { id: 'maxRiskDiastolic', label: 'Max at risk', type: 'text' },
          { id: 'minRiskDiastolic', label: 'Min At Risk', type: 'text' },
        ],
        pulse: [
          { id: 'maxCriticalPulse', label: 'Max Critical', type: 'text' },
          { id: 'minCriticalPulse', label: 'Min Critical', type: 'text' },
          { id: 'maxRiskPulse', label: 'Max at risk', type: 'text' },
          { id: 'minRiskPulse', label: 'Min At Risk', type: 'text' },
        ],
        sleepPulse: [
          { id: 'maxCriticalSleepPulse', label: 'Max Critical', type: 'text' },
          { id: 'minCriticalSleepPulse', label: 'Min Critical', type: 'text' },
          { id: 'maxRiskSleepPulse', label: 'Max at risk', type: 'text' },
          { id: 'minRiskSleepPulse', label: 'Min At Risk', type: 'text' },
        ],
        fev1: [
          { id: 'minCriticalFev1', label: 'Min Critical (L)', type: 'text' },
        ],
        pef: [
          { id: 'minCriticalPef', label: 'Min Critical (L/min)', type: 'text' },
          { id: 'minRiskPef', label: 'Min At Risk (L/min)', type: 'text' },
        ],
        glucose: [
          { id: 'maxCriticalGlucose', label: 'Max Critical', type: 'text' },
          { id: 'minCriticalGlucose', label: 'Min Critical', type: 'text' },
          { id: 'maxRiskGlucose', label: 'Max at risk', type: 'text' },
          { id: 'minRiskGlucose', label: 'Min At Risk', type: 'text' },
        ],
        sp02: [
          { id: 'criticalBloodoxygen', label: 'Min Critical', type: 'text' },
          { id: 'riskBloodoxygen', label: 'Min At risk', type: 'text' },
        ],
        weight: [
          { id: 'baseLineWeight', label: 'Baseline lbs', type: 'text' },
          { id: 'maxCriticalWeight', label: 'Critical +/-lbs', type: 'text' },
          { id: 'maxRiskWeight', label: 'At risk +/-lbs', type: 'text' },
        ],
      },
      careAgents: [
        {
          id: 'patManager',
          label: 'PAT Agent',
          type: 'select',
        },
        {
          id: 'onboardingManager',
          label: 'Onboarding Agent',
          type: 'select',
        },
        {
          id: 'ccmPatManager',
          label: 'CCM PAT Agent',
          type: 'select',
        },
        {
          id: 'ccmOnboardingAgent',
          label: 'CCM Onboarding Agent',
          type: 'select',
        },
        {
          id: 'ccmManager',
          label: 'CCM Case Manager',
          type: 'select',
        },
      ],
    },
    userAdd: {
      personalInfo: [
        {
          id: 'title',
          type: 'select',
          label: 'Title',
          options: [
            { id: 'Mr.', label: 'Mr.' },
            { id: 'Mrs.', label: 'Mrs.' },
            { id: 'Ms.', label: 'Ms.' },
          ],
        },
        { id: 'firstName', type: 'text', label: 'First Name' },
        { id: 'middleName', type: 'text', label: 'Middle Name' },
        { id: 'lastName', type: 'text', label: 'Last Name' },
        { id: 'birthDate', type: 'text', label: 'Birth Date' },
        { id: 'auth0Enabled', type: 'text', label: 'Create Evelyn Account' },
      ],
      contactInfo: [
        { id: 'phone', type: 'text', label: 'Mobile Number' },
        { id: 'workNumber', type: 'text', label: 'Work Number' },
        { id: 'email', type: 'text', label: 'Email' },
        {
          id: 'timezone',
          type: 'select',
          label: 'Timezone',
          defaultValue: TimeZones[0],
          options: TimeZones,
        },
      ],
      accountInfo: [
        {
          id: 'claimStatus',
          type: 'select',
          label: 'RPM Claim Status',
          defaultValue: rpmAndCcmClaimOptions[0],
          options: rpmAndCcmClaimOptions,
        },
        {
          id: 'ccmClaimStatus',
          type: 'select',
          label: 'CCM Claim Status',
          defaultValue: rpmAndCcmClaimOptions[0],
          options: rpmAndCcmClaimOptions,
        },
      ],
    },
  },
  adminPanelsDoctor: {
    userEdit: {
      personalInfo: [
        {
          id: 'title',
          type: 'select',
          label: 'Title',
          options: [
            { id: 'Mr.', label: 'Mr.' },
            { id: 'Mrs.', label: 'Mrs.' },
            { id: 'Ms.', label: 'Ms.' },
            { id: 'Dr.', label: 'Dr.' },
          ],
        },
        { id: 'firstName', type: 'text', label: 'First Name' },
        { id: 'middleName', type: 'text', label: 'Middle Name' },
        { id: 'lastName', type: 'text', label: 'Last Name' },
        { id: 'birthDate', type: 'date', label: 'Birth Date' },
        { id: 'mobileNumber', type: 'text', label: 'Mobile Number' },
        { id: 'workNumber', type: 'text', label: 'Work Number' },
        { id: 'email', type: 'text', label: 'User Email' },
        {
          id: 'primaryLanguage',
          type: 'select',
          label: 'Primary Language',
          options: [...languages],
        },
        {
          id: 'secondaryLanguage',
          type: 'select',
          label: 'Secondary Language',
          options: [...languages],
        },
        {
          id: 'timezone',
          type: 'select',
          label: 'Timezone',
          defaultValue: TimeZones[0],
          options: TimeZones,
        },
      ],
      address: [
        { id: 'addressLine1', type: 'text', label: 'User Address Line 1' },
        { id: 'city', type: 'text', label: 'City' },
        { id: 'addressLine2', type: 'text', label: 'User Address Line 2' },
        {
          id: 'state',
          type: 'select',
          label: 'State',
          options: usStatesWithNone,
          disabled: false,
        },
        { id: 'zip', type: 'text', label: 'Zip code' },
      ],
      registration: [
        { id: 'createdAt', type: 'text', label: 'Registered At' },
        { id: 'email', type: 'text', label: 'Username' },
      ],
    },
  },
  adminPanelAgencyDoctor: {
    userEdit: {
      personalInfo: [
        {
          id: 'title',
          type: 'select',
          label: 'Title',
          options: [
            { id: 'Mr.', label: 'Mr.' },
            { id: 'Mrs.', label: 'Mrs.' },
            { id: 'Ms.', label: 'Ms.' },
            { id: 'Dr.', label: 'Dr.' },
          ],
        },
        { id: 'firstName', type: 'text', label: 'First Name' },
        { id: 'middleName', type: 'text', label: 'Middle Name' },
        { id: 'lastName', type: 'text', label: 'Last Name' },
        { id: 'birthDate', type: 'date', label: 'Birth Date' },
        { id: 'mobileNumber', type: 'text', label: 'Mobile Number' },
        { id: 'workNumber', type: 'text', label: 'Work Number' },
        { id: 'email', type: 'text', label: 'User Email' },
        {
          id: 'primaryLanguage',
          type: 'select',
          label: 'Primary Language',
          options: [...languages],
        },
        {
          id: 'secondaryLanguage',
          type: 'select',
          label: 'Secondary Language',
          options: [...languages],
        },
        {
          id: 'timezone',
          type: 'select',
          label: 'Timezone',
          defaultValue: TimeZones[0],
          options: TimeZones,
        },
      ],
      address: [
        { id: 'addressLine1', type: 'text', label: 'User Address Line 1' },
        { id: 'city', type: 'text', label: 'City' },
        { id: 'addressLine2', type: 'text', label: 'User Address Line 2' },
        {
          id: 'state',
          type: 'select',
          label: 'State',
          options: usStatesWithNone,
          disabled: false,
        },
        { id: 'zip', type: 'text', label: 'Zip code' },
      ],
      registration: [
        { id: 'createdAt', type: 'text', label: 'Registered At' },
        { id: 'email', type: 'text', label: 'Username' },
      ],
    },
  },
  adminTurkUser: {
    userEdit: {
      personalInfo: [
        {
          id: 'title',
          type: 'select',
          label: 'Title',
          options: [
            { id: 'Mr.', label: 'Mr.' },
            { id: 'Mrs.', label: 'Mrs.' },
            { id: 'Ms.', label: 'Ms.' },
            { id: 'Dr.', label: 'Dr.' },
          ],
        },
        { id: 'firstName', type: 'text', label: 'First Name' },
        { id: 'middleName', type: 'text', label: 'Middle Name' },
        { id: 'lastName', type: 'text', label: 'Last Name' },
        { id: 'email', type: 'text', label: 'User Email' },
        {
          id: 'primaryLanguage',
          type: 'select',
          label: 'Primary Language',
          options: [...languages],
        },
        {
          id: 'secondaryLanguage',
          type: 'select',
          label: 'Secondary Language',
          options: [...languages],
        },
        {
          id: 'timezone',
          type: 'select',
          label: 'Timezone',
          defaultValue: TimeZones[0],
          options: TimeZones,
        },
      ],
      registration: [
        { id: 'createdAt', type: 'text', label: 'Registered At' },
        { id: 'email', type: 'text', label: 'Username' },
      ],
    },
  },
  adminPanelsClinic: {
    clinicEdit: {
      contact: [
        { id: 'legalname', type: 'text', label: 'Legal Name', disabled: false },
        { id: 'email', type: 'text', label: 'Business Email', disabled: false },
        { id: 'phone', type: 'text', label: 'Business Phone', disabled: false },
        { id: 'fax', type: 'text', label: 'Business Fax', disabled: false },
        {
          id: 'callerId',
          type: 'text',
          label: 'Clinic CallerID',
          disabled: false,
        },
        { id: 'crmId', type: 'text', label: 'CRM ID', disabled: false },
      ],
      address: [
        {
          id: 'addressLine1',
          type: 'text',
          label: 'Address Line 1',
          disabled: false,
        },
        { id: 'city', type: 'text', label: 'City', disabled: false },
        {
          id: 'addressLine2',
          type: 'text',
          label: 'Address Line 2',
          disabled: false,
        },
        {
          id: 'state',
          type: 'select',
          label: 'State',
          options: usStatesWithNone,
          disabled: false,
        },
        { id: 'zip', type: 'text', label: 'Zip Code', disabled: false },
        {
          id: 'timezone',
          type: 'select',
          label: 'Timezone',
          defaultValue: TimeZones[65],
          options: TimeZones,
        },
      ],
      registration: [
        {
          id: 'createdAt',
          disabled: true,
          type: 'text',
          label: 'Registered At',
        },
        { id: 'clinicId', type: 'text', label: 'ID', disabled: false },
      ],
      clinicDetails: [
        { id: 'npiNumber', type: 'text', label: 'NPI', disabled: false },
        {
          id: 'stateIdNumber',
          type: 'text',
          label: 'State ID',
          disabled: false,
        },
        {
          id: 'taxIdNumber',
          type: 'text',
          label: 'Tax ID EIN',
          disabled: false,
        },
        {
          id: 'taxonomyNumber',
          type: 'text',
          label: 'Taxonomy Number',
          disabled: false,
        },
        {
          id: 'medicalIdTpiNumber',
          type: 'text',
          label: 'Medicaid TPI',
          disabled: false,
        },
        {
          id: 'medicareNumber',
          type: 'text',
          label: 'Medicare Number',
          disabled: false,
        },
        {
          id: 'ots',
          type: 'select',
          label: 'OTS',
          disabled: false,
          options: [
            { id: true, label: 'True', value: true },
            { id: false, label: 'False', value: false },
          ],
        },
        {
          id: 'otsStartDate',
          type: 'date',
          label: 'OTS Start Date',
          disabled: false,
        },
        {
          id: 'autoGeneratedClaimDrop',
          type: 'select',
          label: 'Auto-generated Claim Drop',
          disabled: false,
          options: [
            { id: true, label: 'True', value: true },
            { id: false, label: 'False', value: false },
          ],
        },
        {
          id: 'officeHours',
          type: 'text',
          label: 'Office Hours',
          disabled: false,
        },
        {
          id: 'finId',
          type: 'text',
          label: 'FIN ID',
          disabled: false,
        },
        {
          id: 'revenueChannels',
          type: 'multiselect',
          label: 'Revenue Channels',
          disabled: false,
          options: clinicRevenueChannelOptions,
        },
        {
          id: 'providerServiceType',
          type: 'multiselect',
          label: 'Provider Service Type',
          disabled: false,
          options: clinicProviderServiceTypeOptions,
        },
        {
          id: 'serviceDeliveryModel',
          type: 'select',
          label: 'Service Delivery Model',
          disabled: false,
          options: clinicServiceDeliveryModel,
        },
        {
          id: 'serviceDeliveryThresholds',
          type: 'select',
          label: 'Service Delivery Thresholds',
          disabled: false,
          options: clinicServiceDeliveryThresholds,
        },
        {
          id: 'referralTypes',
          type: 'multiselect',
          label: 'Referral Type',
          disabled: false,
          options: clinicReferralTypes,
        },
      ],
      runBook: [
        {
          id: 'criticalBusinessHours',
          type: 'text',
          label: 'Critical Escalations Instructions At Business Hours',
          disabled: false,
        },
        {
          id: 'criticalAfterHours',
          type: 'text',
          label: 'Critical Escalations Instructions After Hours',
          disabled: false,
        },
        {
          id: 'businessHours',
          type: 'text',
          label: 'Business Hours',
          disabled: false,
        },
        {
          id: 'afterHours',
          type: 'text',
          label: 'After Hours',
          disabled: false,
        },
        {
          id: 'contactMethod',
          type: 'select',
          label: 'Contact Method',
          options: [
            { id: 'Email', label: 'Email' },
            { id: 'Phone', label: 'Phone' },
            { id: 'SMS', label: 'SMS' },
          ],
          disabled: false,
        },
        {
          id: 'contactMethodValue',
          type: 'text',
          label: 'Contact Method Value',
          disabled: false,
        },
        {
          id: 'notes',
          type: 'text',
          label: 'Note',
          disabled: false,
        },
        {
          id: 'dateNoteLogged',
          type: 'date',
          label: 'Date Note',
          disabled: false,
        },
      ],
    },
    clinicThreshold: {
      systolic: [
        {
          id: 'maxCriticalSystolic',
          label: 'Max Critical',
          type: 'text',
        },
        {
          id: 'minCriticalSystolic',
          label: 'Min Critical',
          type: 'text',
        },
        {
          id: 'maxRiskSystolic',
          label: 'Max at risk',
          type: 'text',
        },
        {
          id: 'minRiskSystolic',
          label: 'Min At Risk',
          type: 'text',
        },
      ],
      diastolic: [
        {
          id: 'maxCriticalDiastolic',
          label: 'Max Critical',
          type: 'text',
        },
        {
          id: 'minCriticalDiastolic',
          label: 'Min Critical',
          type: 'text',
        },
        {
          id: 'maxRiskDiastolic',
          label: 'Max at risk',
          type: 'text',
        },
        {
          id: 'minRiskDiastolic',
          label: 'Min At Risk',
          type: 'text',
        },
      ],
      pulse: [
        {
          id: 'maxCriticalPulse',
          label: 'Max Critical',
          type: 'text',
        },
        {
          id: 'minCriticalPulse',
          label: 'Min Critical',
          type: 'text',
        },
        { id: 'maxRiskPulse', label: 'Max at risk', type: 'text' },
        { id: 'minRiskPulse', label: 'Min At Risk', type: 'text' },
      ],
      sleepPulse: [
        {
          id: 'maxCriticalSleepPulse',
          label: 'Max Critical',
          type: 'text',
        },
        {
          id: 'minCriticalSleepPulse',
          label: 'Min Critical',
          type: 'text',
        },
        { id: 'maxRiskSleepPulse', label: 'Max at risk', type: 'text' },
        { id: 'minRiskSleepPulse', label: 'Min At Risk', type: 'text' },
      ],
      glucose: [
        {
          id: 'maxCriticalGlucose',
          label: 'Max Critical',
          type: 'text',
        },
        {
          id: 'minCriticalGlucose',
          label: 'Min Critical',
          type: 'text',
        },
        {
          id: 'maxRiskGlucose',
          label: 'Max at risk',
          type: 'text',
        },
        {
          id: 'minRiskGlucose',
          label: 'Min At Risk',
          type: 'text',
        },
      ],
      sp02: [
        {
          id: 'criticalBloodoxygen',
          label: 'Min Critical',
          type: 'text',
        },
        { id: 'riskBloodoxygen', label: 'Min At risk', type: 'text' },
      ],
      weight: [
        {
          id: 'maxCriticalWeight',
          label: 'Max Critical +/-lbs',
          type: 'text',
        },
        {
          id: 'minCriticalWeight',
          label: 'Min Critical +/-lbs',
          type: 'text',
        },
        {
          id: 'maxRiskWeight',
          label: 'Max at risk +/-lbs',
          type: 'text',
        },
        {
          id: 'minRiskWeight',
          label: 'Min Risk +/-lbs',
          type: 'text',
        },
      ],
      pef: [
        {
          id: 'minCriticalPef',
          label: 'Min Critical (L/min)',
          type: 'text',
        },
        {
          id: 'minRiskPef',
          label: 'Min At Risk (L/min)',
          type: 'text',
        },
      ],
      fev1: [
        {
          id: 'minCriticalFev1',
          label: 'Min Critical (L)',
          type: 'text',
        },
      ],
    },
    clinicAdd: {
      contact: [
        {
          id: 'legalname',
          type: 'text',
          label: 'Legal Name',
          disabled: false,
        },
        {
          id: 'email',
          type: 'text',
          label: 'Business Email',
          disabled: false,
        },
        {
          id: 'phone',
          type: 'text',
          label: 'Business Phone',
          disabled: false,
        },
        { id: 'fax', type: 'text', label: 'Business Fax', disabled: false },
        {
          id: 'callerId',
          type: 'text',
          label: 'Clinic CallerID',
          disabled: false,
        },
      ],
      address: [
        {
          id: 'addressLine1',
          type: 'text',
          label: 'Address Line 1',
          disabled: false,
        },
        { id: 'city', type: 'text', label: 'City', disabled: false },
        {
          id: 'addressLine2',
          type: 'text',
          label: 'Address Line 2',
          disabled: false,
        },
        {
          id: 'state',
          type: 'select',
          label: 'State',
          options: usStatesWithNone,
          disabled: false,
        },
        { id: 'zip', type: 'text', label: 'Zip Code', disabled: false },
      ],
      registration: [
        { id: 'clinicId', type: 'text', label: 'ID', disabled: false },
      ],
      clinicDetails: [
        { id: 'npiNumber', type: 'text', label: 'NPI', disabled: false },
        {
          id: 'stateIdNumber',
          type: 'text',
          label: 'State ID',
          disabled: false,
        },
        {
          id: 'taxIdNumber',
          type: 'text',
          label: 'Tax ID EIN',
          disabled: false,
        },
        {
          id: 'taxonomyNumber',
          type: 'text',
          label: 'Taxonomy Number',
          disabled: false,
        },
        {
          id: 'medicalIdTpiNumber',
          type: 'text',
          label: 'Medicaid TPI',
          disabled: false,
        },
        {
          id: 'medicareNumber',
          type: 'text',
          label: 'Medicare Number',
          disabled: false,
        },
        {
          id: 'ots',
          type: 'select',
          label: 'OTS',
          disabled: false,
          options: [
            { id: true, label: 'True' },
            { id: false, label: 'False' },
          ],
        },
        {
          id: 'otsStartDate',
          type: 'date',
          label: 'OTS Start Date',
          disabled: false,
        },
        {
          id: 'autoGeneratedClaimDrop',
          type: 'select',
          label: 'Auto-generated Claim Drop',
          disabled: false,
          options: [
            { id: 'true', label: 'True' },
            { id: 'false', label: 'False' },
          ],
        },
        {
          id: 'officeHours',
          type: 'text',
          label: 'Office Hours',
          disabled: false,
        },
        {
          id: 'finId',
          type: 'text',
          label: 'FIN ID',
          disabled: false,
        },
        {
          id: 'revenueChannels',
          type: 'multiselect',
          label: 'Revenue Channels',
          disabled: false,
          options: clinicRevenueChannelOptions,
        },
        {
          id: 'providerServiceType',
          type: 'multiselect',
          label: 'Provider Service Type',
          disabled: false,
          options: clinicProviderServiceTypeOptions,
        },
        {
          id: 'serviceDeliveryModel',
          type: 'select',
          label: 'Service Delivery Model',
          disabled: false,
          options: clinicServiceDeliveryModel,
        },
        {
          id: 'serviceDeliveryThresholds',
          type: 'select',
          label: 'Service Delivery Thresholds',
          disabled: false,
          options: clinicServiceDeliveryThresholds,
        },
        {
          id: 'referralTypes',
          type: 'multiselect',
          label: 'Referral Type',
          disabled: false,
          options: clinicReferralTypes,
        },
      ],
    },
  },
  adminPanelsAgency: {
    agencyEdit: {
      contact: [
        { id: 'legalname', type: 'text', label: 'Legal Name', disabled: false },
        { id: 'phone', type: 'text', label: 'Business Phone', disabled: false },
        { id: 'email', type: 'text', label: 'Business Email', disabled: false },
        { id: 'fax', type: 'text', label: 'Business Fax', disabled: false },
      ],
      address: [
        {
          id: 'addressLine1',
          type: 'text',
          label: 'Address Line 1',
          disabled: false,
        },
        { id: 'city', type: 'text', label: 'City', disabled: false },
        {
          id: 'addressLine2',
          type: 'text',
          label: 'Address Line 2',
          disabled: false,
        },
        {
          id: 'state',
          type: 'select',
          label: 'State',
          options: usStatesWithNone,
          disabled: false,
        },
        { id: 'zip', type: 'text', label: 'Zip Code', disabled: false },
      ],
      registration: [
        { id: 'agencyId', type: 'text', label: 'ID', disabled: false },
      ],
      irs: [
        { id: 'npiNumber', type: 'text', label: 'NPI', disabled: false },
        {
          id: 'taxonomyNumber',
          type: 'text',
          label: 'Taxonomy Number',
          disabled: false,
        },
        {
          id: 'stateIdNumber',
          type: 'text',
          label: 'State ID',
          disabled: false,
        },
        {
          id: 'medicalIdTpiNumber',
          type: 'text',
          label: 'Medicaid TPI',
          disabled: false,
        },
        {
          id: 'taxIdNumber',
          type: 'text',
          label: 'Tax ID EIN',
          disabled: false,
        },
        {
          id: 'medicareNumber',
          type: 'text',
          label: 'Medicare Number',
          disabled: false,
        },
      ],
    },
    agencyAdd: {
      contact: [
        {
          id: 'legalname',
          type: 'text',
          label: 'Legal Name',
          disabled: false,
        },
        {
          id: 'phone',
          type: 'text',
          label: 'Business Phone',
          disabled: false,
        },
        {
          id: 'email',
          type: 'text',
          label: 'Business Email',
          disabled: false,
        },
        { id: 'fax', type: 'text', label: 'Business Fax', disabled: false },
      ],
      address: [
        {
          id: 'addressLine1',
          type: 'text',
          label: 'Address Line 1',
          disabled: false,
        },
        { id: 'city', type: 'text', label: 'City', disabled: false },
        {
          id: 'addressLine2',
          type: 'text',
          label: 'Address Line 2',
          disabled: false,
        },
        {
          id: 'state',
          type: 'select',
          label: 'State',
          options: usStatesWithNone,
          disabled: false,
        },
        { id: 'zip', type: 'text', label: 'Zip Code', disabled: false },
      ],
      registration: [
        { id: 'agencyId', type: 'text', label: 'ID', disabled: false },
      ],
      irs: [
        { id: 'npiNumber', type: 'text', label: 'NPI', disabled: false },
        {
          id: 'taxonomyNumber',
          type: 'text',
          label: 'Taxonomy Number',
          disabled: false,
        },
        {
          id: 'stateIdNumber',
          type: 'text',
          label: 'State ID',
          disabled: false,
        },
        {
          id: 'medicalIdTpiNumber',
          type: 'text',
          label: 'Medicaid TPI',
          disabled: false,
        },
        {
          id: 'taxIdNumber',
          type: 'text',
          label: 'Tax ID EIN',
          disabled: false,
        },
        {
          id: 'medicareNumber',
          type: 'text',
          label: 'Medicare Number',
          disabled: false,
        },
      ],
    },
  },
  smsDashboardTemplates: [
    {
      label: '"AS" Meaning A600N - English',
      value: `The icon "AS" is a symbol of arteriosclerosis, please note this is not an assessment. However, you should discuss with your physician on your next visit.`,
    },
    {
      label: '"AS" Meaning A600N - Spanish',
      value: `El icono "AS" es un símbolo de arteriosclerosis, tenga en cuenta que esto no es una evaluación. Sin embargo, debe hablar con su médico en su próxima visita.`,
    },
    {
      label: 'A600N decimals - English',
      value: `Please perform the following steps to place the readings back to normal.
1. Make sure the device is off.
2. Press & Hold down on the Green START/STOP button until you see a 0 and mmHg underneath.
3. Turn off the monitor by pressing on the start/stop button. To make sure the readings are back to normal, press on the memory button once or take a blood pressure reading.`,
    },
    {
      label: 'A600N decimals - Spanish',
      value: `Realice los siguientes pasos para que el dispositivo le muestre las lecturas en las unidades deseadas.
1. Asegúrese de que el dispositivo esté apagado.
2. Mantenga presionado el botón verde Start/Stop hasta que vea 0 y mmHg debajo.
3. Apague el monitor presionando el botón de Start/Stop.
Finalmente, presione el botón de memoria una vez o tome una lectura de presión arterial.`,
    },

    {
      label: 'A600N Test Readings - English',
      value: `Hi {first_name}, we're doing a quick check to make sure your blood pressure monitor is sending readings properly.  Would you mind taking three consecutive blood pressure readings?  After each reading, if you could just let me know the numbers, and allow the monitor to turn off on its own after each reading. That will help us confirm everything is working as expected.`,
    },
    {
      label: 'A600N Test Readings - Spanish',
      value: `Hola {first_name}, estamos haciendo una verificación rápida para asegurarnos de que su monitor de presión arterial esté enviando lecturas correctamente. ¿Le importaría tomar tres lecturas consecutivas de presión arterial? Después de cada lectura, si pudiera dejarme saber los números, y permita que el monitor se apague solo después de cada lectura. Eso nos ayudará a confirmar que todo está funcionando como se esperaba.`,
    },
    {
      label: 'Accuhealth Response - English',
      value: `Hello, this is Evelyn from Accuhealth. How may we assist you today?`,
    },
    {
      label: 'Accuhealth response - Spanish',
      value: `Hola, soy su agente de Accuhealth. ¿Cómo puedo ayudarle hoy?`,
    },
    {
      label: 'Automation template - English',
      value: `We apologize for any confusion caused by the recent health reading reminder you received. Sometimes readings transmit later in the day, or there may not have been a reading available this morning when the report was generated.

You can disregard the reminder if you've already taken your readings. If you receive one of these reminders and are unsure if we've received your reading, please don't hesitate to reach out to us. We'll be happy to confirm for you.

We appreciate you taking your daily readings!
`,
    },
    {
      label: 'Automation template - Spanish',
      value: `Nos disculpamos por cualquier confusión causada por el reciente recordatorio de lectura de salud que recibió. A veces, las lecturas se transmiten más tarde en el día o es posible que no haya habido una lectura disponible esta mañana cuando se generó el informe.

Puede ignorar el recordatorio si ya tomó sus lecturas. Si recibe uno de estos recordatorios y no está seguro de que su lcetura haya sido transmitida correctmente, no dude en comunicarse con nosotros. Estaremos encantados de ayudarle.
¡Le agradecemos que tome sus lecturas diarias!
`,
    },
    {
      label: 'Billing Concern - English',
      value: `Accuhealth does not bill Medicare, we partner with your doctor to provide this great service to you. I encourage you to discuss with your insurance carrier to obtain more information.`,
    },
    {
      label: 'Billing Concern - Spanish',
      value: `Accuhealth no factura a Medicare, trabajamos en colaboración con su médico para brindarle este excelente servicio. Le invitamos a que contacte a su proveedor de seguro médico para obtener más información.`,
    },
    {
      label: 'Bingo - English',
      value: `{bingo_number} 

Good luck and a friendly reminder to continue taking your daily health readings. Also, is there anything else we can assist you with such as scheduling a follow-up with your referring provider? `,
    },
    {
      label: 'Bingo - Spanish',
      value: `{bingo_number} 

Buena suerte! Recuerde continuar tomando sus lecturas diarias ¿Hay algo adicional en lo que podamos ayudarle, cómo programar un seguimiento con su doctor?`,
    },
    {
      label: 'BP Inaccurate Readings - English',
      value: `Please check the following items that may be interfering with your readings:
1. Is the size of your cuff the right size? You should be able to slide two fingers on the top and bottom of the cuff.
2. Are you positioning your cuff properly? Remember to place it 2 inches above your elbow.
3. The gray rubber tubing should come out the bottom of the cuff and be in line with the palm of your hand.
4. Remove your cuff and squeeze out any air it may contain and repeat the reading.
5. Once you have performed these tests repeat your reading, if you still believe your device is giving you inaccurate readings, take your device to your doctor's office for comparison.
      `,
    },
    {
      label: 'BP Inaccurate Readings - Spanish',
      value: `Por favor revisemos los siguientes aspectos que pudieran estar interfiriendo en sus lecturas:
1. ¿La talla de su brazalete es la adecuada? Usted debe poder deslizar dos dedos en la parte superior e inferior del brazalete.
2. ¿Está colocando su brazalete adecuadamente? Recuerde que debe situarlo 2 pulgadas por encima de su codo.
3. El tubo gris de goma debe salir por la parte inferior del brazalete y alineado con la palma de su mano.
4. Retire su brazalete y exprima el aire que pueda contener y repita la lectura.
5. Una vez realizadas estas pruebas repita su lectura, si aún cree que su dispositivo le está dando lecturas inexactas, lleve su dispositivo al consultorio de su médico para que lo comparen.`,
    },
    {
      label: 'Condolences - English',
      value: `Our deepest condolences to you and your family, we want to extend our sympathy for such a sensitive loss. May the pain and sadness in your heart be lightened by the affection and love of those around you. Receive our condolences.`,
    },
    {
      label: 'Condolences - Spanish',
      value: `Nuestro más sentido pésame para usted y su familia, queremos extender nuestra solidaridad ante tan sensible pérdida. Que el dolor y tristeza que siente su corazón se vea aligerada por el cariño y amor de aquellos que le rodean. Reciba nuestras condolencias.`,
    },
    {
      label: 'Consumable Order Placed - English',
      value: `Thank you, your order has been placed, you will receive your consumables in a few days.`,
    },
    {
      label: 'Consumable Order Placed - Spanish',
      value: `Gracias, su pedido ha sido realizado, en unos días recibirá sus consumibles.`,
    },
    {
      label: 'Consumable order/Replacement placed - Updated version - English',
      value: `Thanks for confirming your address! Your order is placed and on its way. You can expect it within a few days. Disregard any friendly reminders you might receive. We hope you have a great day!`,
    },
    {
      label: 'Consumable order/Replacement placed - Updated version - Spanish',
      value: `¡Gracias por confirmar su dirección! Su pedido está realizado y en camino. Puede esperarlo dentro de unos días. Mientras tanto, ignore cualquier recordatorio amistoso que pueda recibir. ¡Esperamos que tenga un gran día!`,
    },
    {
      label: 'Contact availability - English',
      value: `To best suit your schedule, is there a preferred day or time during the week or weekend for us to reach you? We offer weekday support from 8am to 7pm central time and weekends from 8am to 5pm central time. Our nursing staff is available 24/7 for urgent concerns. This information will be added to your profile for future reference.`,
    },
    {
      label: 'Contact availability - Spanish',
      value: `Para adaptarnos mejor a su horario, ¿hay algún día u hora durante la semana o el fin de semana que usted prefiera para que podamos comunicarnos con usted? Ofrecemos soporte entre semana de 8 a.m. a 7 p.m. hora central y los fines de semana de 8 a. m. a 5 p. m. hora central. Nuestro personal de enfermería está disponible las 24 horas del día, los 7 días de la semana para inquietudes urgentes. Esta información se agregará a su perfil para referencia futura.`,
    },
    {
      label: 'Contact number - English',
      value: `Hi {first_name},

We're here to help if you have any questions! You can reach us by phone at 1-888-407-4108 or by text at 90105.

Have a great day!`,
    },
    {
      label: 'Contact number - Spanish',
      value: `Hola {first_name},

¡Accuhealth siempre está aquí para ayudarle si tiene alguna pregunta! Puede comunicarse con nosotros por teléfono al 1-888-407-4108 o por mensaje de texto al 90105.

¡Qué tenga un lindo día!`,
    },
    {
      label: 'Critical Reading Received - English',
      value: `Thank you so much! We have received your readings, we would like to take a moment of your time to thank you for taking your readings consecutively. How are you feeling today? Would you like to talk to a nurse?`,
    },
    {
      label: 'Critical Reading Received - Spanish',
      value: `Muchas gracias! Hemos recibido sus lecturas, queremos tomar un momento de su tiempo para agradecerle por tomar sus lecturas consecutivamente. ¿Cómo se siente hoy? ¿Le gustaría hablar con una enfermera?`,
    },
    {
      label: 'Critical reading received - Updated version - English',
      value: `Thank you for taking your readings consistently. We've received your readings, and one of them fell outside the expected range. This doesn't necessarily mean there's a problem, but we want to follow up to ensure everything is okay. A nurse will be calling you shortly to discuss the reading in more detail.`,
    },
    {
      label: 'Critical reading received - Updated version - Spanish',
      value: `Gracias por tomar sus lecturas consistentemente. Hemos estado recibiéndolas y una de ellas estuvo fuera del rango esperado. Esto no significa necesariamente que haya un problema, pero queremos hacer un seguimiento para asegurarnos de que todo esté bien. Una enfermera lo llamará en breve para analizar la lectura con más detalle.`,
    },
    {
      label: 'Cuff sizes - English',
      value: `We are happy to send you a bracelet that fits your arm properly, we have the following sizes available, please let us know which size you require:
Small = 15-22 cm or 6-9 inches.
Adult = 22-42 cm or 9 - 17 inches
XL = 38-55 cm or 15-22 inches`,
    },
    {
      label: 'Cuff sizes - Spanish',
      value: `Con gusto podemos enviarle un brazalete que se ajuste adecuadamente a su brazo, tenemos las siguientes tallas disponibles, por favor háganos saber cuál es la que requiere:
Pequeño = 15-22 cm o 6-9 pulgadas
Adulto = 22-42 cm o 9 -17 pulgadas
XL = 38-55 cm o 15-22 pulgadas
`,
    },
    {
      label: 'Discharge questions - English',
      value: `We are very sorry to hear that you will no longer be a part of Accuhealth. To confirm cancellation of service and to help us improve, we would appreciate it if you would take a moment to answer the following questions:

1. What is the main concern with your device or services?

2. How can we best help you or other patients in the future?

3. Is there anything we can do so we can continue to monitor your health?`,
    },
    {
      label: 'Discharge questions - Spanish',
      value: `Lamentamos mucho que ya no será parte de Accuhealth. Para confirmar la cancelación del servicio y para ayudarnos a mejorar, le agradeceríamos que dedicara un momento a contestar las siguientes preguntas:

1. ¿Cuál es la principal preocupación con su dispositivo o servicios?

2. ¿Cómo podemos ayudarle mejor a usted o a otros pacientes en el futuro?

3. ¿Hay algo que pueda hacer para que podamos seguir monitoreando su salud?
      `,
    },
    {
      label: 'Glucose Test Readings - English',
      value: `Great! Let's do a quick test on your glucometer to see if it transmits readings or to check the strips (without control solution).  We'll take 3 readings in a row.

Grab a test strip and insert it into the top of the meter. Make sure the blue side is facing up and the white arrow is pointing down.
We'll use a sugary liquid instead of blood. You can use something like orange juice, soda with sugar, or even water with sugar mixed in.
Put a tiny drop of the liquid on the test strip. The meter should give you a "HI" reading.
By using a sugary liquid, we expect the meter to read "HI" because the sugar level will be very high. This helps us see if the meter and strips are working properly.

Discard that strip and use a new one for your actual blood sugar test.

Remember, this is just a test.  For accurate blood sugar readings, you'll always need to use a fresh strip and a blood sample.`,
    },
    {
      label: 'Glucose Test Readings - Spanish',
      value: `¡Excelente! Hagamos una prueba rápida en su glucómetro para ver si transmite lecturas o para revisar las tiras (sin solución de control). Tomaremos 3 lecturas seguidas.

Tome una tira reactiva e insértela en la parte superior del medidor. Asegúrese de que el lado azul esté hacia arriba y la flecha blanca apunte hacia abajo.
Usaremos un líquido azucarado en lugar de sangre; puede usar algo como jugo de naranja, refresco con azúcar o incluso agua con azúcar mezclada.
Coloque una pequeña gota de líquido en la tira reactiva. El medidor debería dar una lectura "HI".
Al usar un líquido azucarado, esperamos que el medidor indique "HI" porque el nivel de azúcar será muy alto. Esto nos ayuda a ver si el medidor y las tiras funcionan correctamente.

Deseche esa tira y use una nueva para su prueba de azúcar en sangre real.

Recuerde, esto es sólo una prueba. Para obtener lecturas precisas de azúcar en sangre, siempre necesitará utilizar una tira nueva y una muestra de sangre.`,
    },
    {
      label: 'Information - English',
      value: `Your physician has recommended the use of this device as a preventive measure to better monitor your health. Accuhealth provides remote monitoring, so you can take your daily readings from the comfort of your home, making it easy for your doctor to receive them instantly without having to keep track on a paper or tablet. Every reading you take helps both your doctor and our nurses monitor your health and help you improve it, as well as prevent you from going to the emergency room. Would you like one of our representatives to contact you so we can answer all your questions?`,
    },
    {
      label: 'Information - Spanish',
      value: `Su médico le ha recomendado el uso de este dispositivo como medida preventiva para controlar mejor su salud. Accuhealth proporciona monitorización remota, por lo que puede tomar sus lecturas diarias desde la comodidad de su hogar, lo que facilita que su médico las reciba al instante sin tener que llevar un registro en un papel o una tableta. Cada lectura que tome ayuda tanto su doctor como a nuestras enfermeras supervisar su salud y ayudarle a mejorarla, así como evitar que acuda a urgencias. Desea que uno de nuestros representantes se ponga en contacto con usted para que pueda disipar todas sus dudas?`,
    },
    {
      label: 'No readings - Battery Related - English',
      value: `Hello {first_name}, we have not received your readings recently. Have you changed the batteries in your device, or have you allowed the device to turn itself off?`,
    },
    {
      label: 'No readings - Battery Related - Spanish',
      value: `Hola {first_name}, no hemos recibido tus lecturas recientemente ¿ha cambiado las pilas de su dispositivo o ha dejado que se apague solo?`,
    },
    {
      label: 'No Readings - Rechargeable - English',
      value: `Hello {first_name}, we have not received a recent reading. Have you charged your monitor recently and have you allowed it to turn off on its own?`,
    },
    {
      label: 'No Readings - Rechargeable - Spanish',
      value: `Hola {first_name}, no hemos recibido sus lecturas recientemente ¿ha cargado su dispositivo o ha dejado que se apague solo?`,
    },
    {
      label: 'No wifi requirements - English',
      value: `Hello. This monitor is really convenient! It doesn't need WiFi to connect, but it uses a secure cellular network to send your readings safely - that means you can use it anywhere with cell service.`,
    },
    {
      label: 'No wifi requirements - Spanish',
      value: `Hola ¡Este monitor es realmente increíble! No necesita WiFi para conectarse, utiliza una red celular para enviar sus lecturas de forma segura; eso significa que puede usarlo en cualquier lugar con señal celular.`,
    },
    {
      label: 'PAT - English',
      value: `Dear {first_name},
This is Evelyn, your Accuhealth assistant. We are trying to reach you to discuss the Remote Monitoring services that your doctor referred you to. We tried to reach you by phone, but you were not available, what is the best time to reach you, would you like us to set up an appointment with one of our agents?`,
    },
    {
      label: 'PAT - Spanish',
      value: `Estimado {first_name},
Soy Evelyn su asistente de Accuhealth. Estamos tratando de comunicarnos con usted para platicarle sobre los servicios de Monitoreo Remoto a los cuales su doctor lo refirió. Intentamos comunicarnos con usted por teléfono, pero no estaba disponible, ¿cuál es el mejor momento para comunicarnos con usted? ¿Desea que concretemos una cita con uno de nuestros agentes?`,
    },
    {
      label: 'Patient Account Request - English',
      value: `Thank you for confirming, your request is being processed, please keep an eye on your inbox. In the next 24 to 48 hours, you should receive an email from Accuhealth, which will contain a link that will direct you to your account. You will have 24 hours to open it and set up your account.`,
    },
    {
      label: 'Patient Account Request - Spanish',
      value: `Muchas gracias, su solicitud está siendo procesada, por favor esté al pendiente de su bandeja de entrada. En los próximas 24 a 48, horas usted deberá recibir un correo electrónico por parte de Accuhealth, el cual contendrá un link que lo direccionará a su cuenta. Tendrá 24 horas para abrirlo y configurar su cuenta.`,
    },
    {
      label: 'Patient in the Hospital - English',
      value: `Hello, I am so sorry to hear that. We hope you have a speedy recovery. Please disregard any further friendly reminders. If you do receive, please respond with the number "83". Thank you.`,
    },
    {
      label: 'Patient in the Hospital - Spanish',
      value: `Estimado paciente, lamentamos mucho escuchar eso. Esperamos que se recuperes pronto. En caso de que reciba algún mensaje de texto adicional, por favor responda con el número "93". Gracias.`,
    },
    {
      label: 'Reading Received - English',
      value: `Hi there! We are receiving your readings. Thank you and a friendly reminder to continue taking your daily readings. Also, is there anything else I can assist you with such as scheduling a follow-up with your referring provider?`,
    },
    {
      label: 'Reading Received - Spanish',
      value: `¡Hola! Le enviamos este mensaje para agradecerle por tomarse sus lecturas, las hemos estado recibiendo exitosamente. Por favor no olvide lo importante que es tomarlas diariamente!
      Háganos saber si necesita cualquier ayuda adicional, cómo programar una cita con tu doctor o hablar con alguna de nuestras enfermeras.`,
    },
    {
      label: 'Retention - English',
      value: `Thank you for being part of Accuhealth. Before we let you go we would love to remind you that Accuhealth provides remote monitoring, so you can take your daily readings from the comfort of your home, making it easy for your doctor to receive them instantly without having to keep track of them on paper or on a tablet. Each reading you take helps both your doctor and our nurses monitor your health and help you improve your health, as well as prevent you from going to the emergency room. If you decide you want to continue in our RPM program, you can contact us at any time.`,
    },
    {
      label: 'Retention -  Spanish',
      value: `Gracias por haber formado parte de Accuhealth. Antes de despedir, nos encantaría recordarle que la monitorización remota que proporciona Accuhealth le permite realizar sus lecturas diarias desde la comodidad de su hogar, facilitando que su médico las reciba al instante sin tener que llevar un control de las mismas en papel o en una tablet. Cada lectura que realiza ayuda tanto a su médico como a nuestras enfermeras a controlar su salud y ayudarle a mejorarla, así como a evitar que acuda a urgencias. Si decide que quiere continuar en nuestro programa, puede ponerte en contacto con nosotros en cualquier momento.`,
    },
    {
      label: 'Self Acceptance Follow Up - English',
      value: `Hi {first_name}, this is Evelyn from Accuhealth. One of our agents is trying to reach you by phone to complete your Acceptance and have your device(s) shipped out. Are you available to speak on the phone?`,
    },
    {
      label: 'Self Acceptance Follow Up - Spanish',
      value: `Hola {first_name}, soy Evelyn su asistente de Accuhealth. Uno de nuestros agentes ha estado tratando de comunicarse con usted por teléfono para completar su aceptación y enviarle su dispositivo. ¿Está disponible para hablar por atender la llamada de uno de nuestros agentes?`,
    },
    {
      label: 'Sick patients- Who do not want to take readings - English',
      value: `Hello, I am sorry to hear that. We hope you have a speedy recovery. Friendly reminder why it is important to continue taking your daily health readings. Taking your daily readings helps your physician oversee your health and how your health can be improved. Providing your daily readings can also prevent you from going to the ER. Please let us know if you have any questions, get well soon!`,
    },
    {
      label: 'Sick patients- Who do not want to take readings - Spanish',
      value: `Hola, lamento escuchar eso. Esperamos que tenga una pronta recuperación.
      Recuerde que cada lectura que tome ayuda tanto su doctor como a nuestras enfermeras supervisar su salud y ayudarle a mejorarla, así como evitar que acuda a urgencias. Por favor, háganos saber si tiene alguna pregunta, ¡que se recupere pronto!`,
    },
    {
      label: 'Test Mode - English (U807/BP01)',
      value: `Okay let's try to do a very simple reset. Here are the steps: 

1. Turn off the device.
2. Press and Hold the Start/Stop button until the device displays all segments.
3. Press the MEM, SET, MEM keys in sequence, when holding the Start/Stop Button.
4. Let go of the Start/Stop Button and the device will display a dotted line on the top right corner of the screen.
5. Then, press the MEM button and the device will trigger a test reading of 100/80/60 or 255/255/199
6. The device will be in-transit until the screen displays “END”

Please let us know when done so we can verify if readings came through.
Please let me know if you receive any error messages. Also, please keep an eye out for a letter M that will show on the bottom left of the screen, please let me know what number shows under the letter M.

This will help the monitor catch a signal in your nearest cell tower.`,
    },
    {
      label: 'Test Mode - Spanish (U807/BP01)',
      value: `Bien, intentemos hacer un reinicio muy simple. Aquí están los pasos:

1. Apague el dispositivo.
2. Mantenga presionado el botón Start/Stop hasta que el dispositivo muestre todos los segmentos.
3. Presione las teclas MEM, SET, MEM en secuencia mientras mantiene presionado el botón Start/Stop
4. Suelte el botón Start/Stop y el dispositivo mostrará una línea de puntos en la esquina superior derecha de la pantalla.
5. Luego, presione el botón MEM y el dispositivo activará una lectura de prueba de 100/80/60 o 255/255/199.
6. El dispositivo estará en tránsito hasta que la pantalla muestre "END"

Háganos saber cuando haya terminado para que podamos verificar si se obtuvieron las lecturas.
Por favor, avíseme si recibe algún mensaje de error. Además, esté atento a la letra M que aparecerá en la parte inferior izquierda de la pantalla. Déjeme saber qué número aparece debajo de la letra M.

Esto ayudará al monitor a captar una señal en la torre de telefonía móvil más cercana.`,
    },
    {
      label: 'Thank you Message - English',
      value: `Thank you for letting us know. Remember, if you need to speak to a nurse you can call us at 1-888-407-4108. Have a great day!`,
    },
    {
      label: 'Thank you Message - Spanish',
      value: `Gracias por informarnos. Recuerde que si necesita hablar con alguna enfermera puede llamarnos al 1-888-407-4108. Que tenga excelente día!`,
    },
    {
      label: 'Transmission Confirmation - English',
      value: `Thank you for letting us know. Please let us know once you've taken your readings so we can verify they've been transmitted with success.`,
    },
    {
      label: 'Transmission Confirmation - Spanish',
      value: `Gracias por informarnos. Por favor, háganoslo saber una vez que haya tomado sus lecturas para que podamos verificar que se han transmitido con éxito.`,
    },
    {
      label: 'U807/BP01 decimals - English',
      value: `Please perform the following steps to place the readings back to normal.
1. Make sure the monitor is off.
2. Press on SET.
3. Then press on MEM.
4. Then turn it off by pressing on the start/stop button.
To make sure the readings are back to normal, press on MEM once or take a blood pressure reading.`,
    },
    {
      label: 'U807/BP01 decimals - Spanish',
      value: `Por favor revisemos los siguientes aspectos que pudieran estar interfiriendo en sus lecturas:
1. Asegúrese de que el monitor esté apagado.
2. Presione SET
3. Luego presione MEM
4. Apague su dispositivo presionando el botón de Start/Stop
5. Finalmente, para asegurarse de que las lecturas vuelvan a la normalidad, presione MEM una vez o tome una lectura de presión arterial.
`,
    },
    {
      label: 'Upcoming Vacation - English',
      value: `Dear Patient,

Thank you for informing us about your upcoming vacation.

To ensure your account is placed on a vacation hold while you're away, kindly reply to this message with the number "82" upon your departure.

We wish you a pleasurable and relaxing vacation.

Sincerely,

Accuhealth`,
    },
    {
      label: 'Upcoming Vacation - Spanish',
      value: `Estimado Paciente,

Gracias por informarnos sobre sus próximas vacaciones.

Para asegurarnos de que su cuenta quede suspendida durante sus vacaciones, responda a este mensaje con el número "92" cuando se vaya.

Le deseamos unas placenteras y relajantes vacaciones.

Atentamente,

Accuhealth
`,
    },
    {
      label: 'Verify Address - English',
      value: `Can you please verify your address?`,
    },
    {
      label: 'Verify Address - Spanish',
      value: `¿Podría por favor confirmar su dirección?`,
    },
    {
      label: `You're welcome - English`,
      value: `It is a pleasure to serve you, we appreciate that you continue to take your daily readings. Your health and well-being is our priority!`,
    },
    {
      label: `You're welcome - Spanish`,
      value: `Es un gusto servirle, agradecemos que siga tomándose sus lecturas diariamente. Su salud y bienestar es nuestra prioridad!`,
    },
  ],
  childClinicSelect: [
    { value: 'true', label: 'Included' },
    { value: 'false', label: 'Excluded' },
  ],
};
