import React from 'react';
import PatientsActivity from 'components/templates/ccm/patientsActivities';
import { Container } from 'reactstrap';

const Index = () => (
  <div className="ccm-actvity">
    <Container>
      <PatientsActivity />
    </Container>
  </div>
);

export default Index;
