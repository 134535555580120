import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const XFillIcon = ({
  color = COLORS.BOYSENBERRY_SHADOW,
  ...props
}: IIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="4" fill={color} />
    <path
      d="M8.34153 8.78347C8.21949 8.66143 8.21949 8.46357 8.34153 8.34153C8.46357 8.21949 8.66143 8.21949 8.78347 8.34153L12 11.5581L15.2165 8.34153C15.3386 8.21949 15.5364 8.21949 15.6585 8.34153C15.7805 8.46357 15.7805 8.66143 15.6585 8.78347L12.4419 12L15.6585 15.2165C15.7805 15.3386 15.7805 15.5364 15.6585 15.6585C15.5364 15.7805 15.3386 15.7805 15.2165 15.6585L12 12.4419L8.78347 15.6585C8.66143 15.7805 8.46357 15.7805 8.34153 15.6585C8.21949 15.5364 8.21949 15.3386 8.34153 15.2165L11.5581 12L8.34153 8.78347Z"
      fill="#667180"
    />
  </svg>
);

export default XFillIcon;
