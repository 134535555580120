import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { filterDefaultValues } from 'utils/object';

import { getFiltersCount } from './useFilters';

import type { Filters } from './types';

export const useQueryParams = (defaultFilters: Filters) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { initialFilters, initialFiltersCount } = useMemo(() => {
    const defaultResult = {
      initialFilters: defaultFilters,
      initialFiltersCount: 0,
    };
    try {
      if (!location.search) {
        return defaultResult;
      }
      const urlParams = new URLSearchParams(location.search);
      const filtersFromUrl = urlParams.get('filters');
      if (!filtersFromUrl) {
        return defaultResult;
      }
      const parsedFilters = JSON.parse(filtersFromUrl);
      const filterWithoutDefaultValues = filterDefaultValues({
        values: parsedFilters,
        defaultValues: defaultFilters,
      });

      const filtersCount = getFiltersCount(filterWithoutDefaultValues);
      return {
        initialFilters: {
          ...defaultFilters,
          ...parsedFilters,
        },
        initialFiltersCount: filtersCount,
      };
    } catch (error) {
      return defaultResult;
    }
  }, []);

  const addFiltersToUrl = (newFilters: Partial<Filters>) => {
    if (Object.keys(newFilters).length === 0) {
      navigate({
        search: '',
      });
    } else {
      navigate({
        search: `filters=${JSON.stringify(newFilters)}`,
      });
    }
  };

  const resetFiltersUrl = () => {
    navigate({
      search: '',
    });
  };

  return {
    initialFilters,
    initialFiltersCount,
    addFiltersToUrl,
    resetFiltersUrl,
  };
};
