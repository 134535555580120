import { useQuery, useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { PatientDevice } from 'infrastructure/classes/patient/patient-device';

import useApi from '../use-api';
import { getPatientInfoKey } from '../patient/use-patient-info';

import type {
  ICreatePatientDevice,
  IOrderConsumable,
  IOrderResponse,
  IPatientDevice,
  IPatientDevices,
  IUpdatePatientDevice,
  IUpdatePatientDeviceAlert,
} from 'infrastructure/interfaces';

type Devices = Array<PatientDevice>;

const patientDevicesApi = (patientGuid: string) =>
  `admins/admin-panel/patients/${patientGuid}/devices`;

const deviceOrderConsumable = `core/patients/orders`;

const patientDevicesAlertUpdateApi = (deviceId: string) =>
  `core/patients/devices/${deviceId}/change-setting`;

interface IUsePatientDevices {
  loading: boolean;
  devices: Devices;
  refetch: () => Promise<any>;
  createPatientDevices: (
    body: ICreatePatientDevice,
  ) => Promise<IPatientDevice | void>;
  updatePatientDevices: (
    body: IUpdatePatientDevice,
  ) => Promise<IUpdatePatientDevice | void>;
  orderConsumable: (body: IOrderConsumable) => Promise<IOrderResponse | void>;
  updatePatientDevicesAlert: (
    deviceId: string,
    body: IUpdatePatientDeviceAlert,
  ) => Promise<void>;
  deletePatientDevices: (deviceId: string) => Promise<void>;
}

interface IUsePatientDevicesProps {
  patientGuid: string;
  initialLoading?: boolean;
  shadowLoading?: boolean;
}

export const usePatientDevices = (
  props: IUsePatientDevicesProps,
): IUsePatientDevices => {
  const { patientGuid, initialLoading = true, shadowLoading = false } = props;

  const { loading, loadData, updateData, createData, deleteData } = useApi();

  const queryClient = useQueryClient();
  const queryKey = ['patientDevices', patientGuid];

  const {
    data = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey,
    queryFn: () =>
      loadData<IPatientDevices>(patientDevicesApi(patientGuid)).then((res) => {
        if (res) {
          return res.devices.map((el) => new PatientDevice(patientGuid, el));
        }
      }),
    enabled: Boolean(patientGuid) && initialLoading,
    staleTime: shadowLoading ? undefined : STALE_TIME,
  });

  const createPatientDevices = async (
    body: ICreatePatientDevice,
  ): Promise<IPatientDevice> => {
    const res = await createData<IPatientDevice>(
      patientDevicesApi(patientGuid),
      body,
    );

    queryClient.setQueryData<Devices>(queryKey, (oldData) => [
      ...(oldData ?? []),
      new PatientDevice(patientGuid, res),
    ]);

    return res;
  };

  const orderConsumable = async (
    body: IOrderConsumable,
  ): Promise<IOrderResponse> => {
    const res = await createData<IOrderResponse>(deviceOrderConsumable, body);
    const patientInfoKey = getPatientInfoKey(patientGuid);
    queryClient.invalidateQueries(patientInfoKey);

    return res;
  };

  const updatePatientDevices = async (
    body: IUpdatePatientDevice,
  ): Promise<void> => {
    const res = await updateData<IPatientDevices>(
      patientDevicesApi(patientGuid),
      body,
    );

    queryClient.setQueryData<Devices>(
      queryKey,
      (oldData) =>
        oldData && res.devices.map((el) => new PatientDevice(patientGuid, el)),
    );
  };

  const updatePatientDevicesAlert = async (
    deviceId: string,
    body: IUpdatePatientDeviceAlert,
  ): Promise<void> => {
    await updateData(patientDevicesAlertUpdateApi(deviceId), body);
  };

  const deletePatientDevices = async (deviceId: string): Promise<void> => {
    await deleteData(patientDevicesApi(patientGuid), deviceId).then(() => {
      queryClient.setQueryData<Devices>(queryKey, (oldData) =>
        oldData?.filter((el) => el.deviceId !== deviceId),
      );
    });
  };

  return {
    loading: isLoading || loading,
    devices: data,
    refetch,
    orderConsumable,
    createPatientDevices,
    updatePatientDevices,
    updatePatientDevicesAlert,
    deletePatientDevices,
  };
};
