import dataService from 'services/dataService';
import { RESET_PASSWORD } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface DataProps {
  email: string;
}

export const resetPasswordFn = async (postData: DataProps) => {
  const { data, error } = await dataService.createList(
    RESET_PASSWORD,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
