const CloseIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="close-button"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.296513 14.2936C-0.0939478 14.6842 -0.0941782 15.3177 0.295998 15.7085C0.686175 16.0994 1.31901 16.0997 1.70947 15.7091L8.00032 9.41616L14.2903 15.707C14.6808 16.0975 15.3142 16.0978 15.705 15.7076C16.0958 15.3173 16.0961 14.6844 15.7056 14.2938L9.4143 8.00172L15.7057 1.70826C16.0962 1.31767 16.0964 0.684176 15.7062 0.293304C15.3161 -0.0975674 14.6832 -0.0977973 14.2928 0.29279L8.00018 6.58742L1.70799 0.294402C1.31749 -0.0961482 0.684106 -0.0964089 0.293286 0.293819C-0.0975346 0.684047 -0.097795 1.31699 0.292704 1.70754L6.5862 8.00186L0.296513 14.2936Z"
      fill="#2C2543"
      {...props}
    />
  </svg>
);

export default CloseIcon;
