import { createModel } from '@rematch/core';

import type { RootModel } from 'store/models';

interface IState {
  isMobileMiniNavOpen: boolean;
}

const INITIAL_STATE: IState = {
  isMobileMiniNavOpen: false,
};

const modalWindows = createModel<RootModel>()({
  state: INITIAL_STATE,
  reducers: {
    toggleOpenMobileMiniNav: (state) => ({
      ...state,
      isMobileMiniNavOpen: !state.isMobileMiniNavOpen,
    }),
  },
});

export default modalWindows;
