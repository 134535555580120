import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import withLoader from 'components/organisms/withLoader';
import Button from 'components/atoms/button';
import { scrollToErrorIfExist } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';
import { Formik, Form } from 'formik';
import { Card, CardBody, Table } from 'reactstrap';
import validationSchema from 'components/organisms/adminPanels/agencyDoctor/validationSchema';
import requestBodyGenerator from 'components/organisms/adminPanels/agencyDoctor/requestBodyGenerator';
import Info from 'components/organisms/adminPanels/agencyDoctor/components/info';
import Address from 'components/organisms/adminPanels/agencyDoctor/components/address';
import IRS from 'components/organisms/adminPanels/agencyDoctor/components/irs';
import Registration from 'components/organisms/adminPanels/agencyDoctor/components/registration';
import Profession from 'components/organisms/adminPanels/agencyDoctor/components/profession';

const AddAgencyDoctor = () => {
  const navigate = useNavigate();
  const {
    adminPanelAgencyDoctors: {
      createAgencyDoctor,
      getAgencies,
      reset,
      getRoles,
    },
  } = useDispatch();

  const agencies = useSelector(
    (state) => state.adminPanelAgencyDoctors.agencies,
  );

  const agencyDoctor = useSelector(
    (state) => state.adminPanelAgencyDoctors.agencyDoctor,
  );

  const [emailAlerts, setEmailAlerts] = useState(false);
  const [smsAlerts, setSmsAlerts] = useState(false);
  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    const run = async () => {
      try {
        await getAgencies();
      } catch (e) {
        showResult(e);
        navigate('/admin/agents');
      }
    };
    run();
  }, []);

  const { createAgencyDoctor: submitLoading, getAgencies: agenciesLoading } =
    useSelector((state) => state.loading.effects.adminPanelAgencyDoctors);

  const submitHandler = async (doctorRequest) => {
    const body = requestBodyGenerator(doctorRequest, emailAlerts, smsAlerts);
    const { data, error } = await createAgencyDoctor(body);
    if (error) return showResult(error);
    if (data) {
      showResult();
      navigate(`/admin/agent/${data.guid}`);
    }
  };

  return (
    <div className="admin-agent">
      {withLoader(
        agenciesLoading,
        <>
          {agencies && (
            <Card className="card-border">
              <CardBody>
                <div className="admin-agent-header">
                  <span className="admin-header__title">
                    Add Agency User Page
                  </span>
                </div>
                <Formik
                  onSubmit={submitHandler}
                  initialValues={{ ...agencyDoctor }}
                  validationSchema={validationSchema}
                >
                  {({ errors }) => (
                    <Form>
                      <div className="admin-agent-edit-form">
                        <Table>
                          <tbody>
                            <tr>
                              <td rowSpan={2}>
                                <Info errors={errors} />
                              </td>
                              <td>
                                <Profession
                                  agencies={agencies}
                                  errors={errors}
                                  isLoading={agenciesLoading}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Registration errors={errors} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Address errors={errors} />
                              </td>
                              <td>
                                <IRS
                                  emailAlert={emailAlerts}
                                  setEmailAlert={setEmailAlerts}
                                  smsAlert={smsAlerts}
                                  setSmsAlert={setSmsAlerts}
                                  errors={errors}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="text-center">
                        <Button
                          disabled={submitLoading && errors}
                          type="submit"
                          text="Save"
                          data-cy="save-button"
                          handleClick={scrollToErrorIfExist}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </>,
      )}
    </div>
  );
};

export default AddAgencyDoctor;
