import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { apiPatientInternalAdmissionFormFile } from 'services/dataService/resources';
import { downloadFileFromUrl } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';

interface Props {
  patientGuid: string;
}

export const useGetIafReport = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async ({ patientGuid }: Props) => {
      const { data, error } = await dataService.getOnly(
        apiPatientInternalAdmissionFormFile(patientGuid),
      );

      if (error) {
        showResult(error);
        throw new Error(error);
      }

      if (!error && data) {
        data?.forEach((url: string) => downloadFileFromUrl(url));
      }

      return data;
    },
  });

  return { ifaReportLoading: isLoading, getIfaReport: mutate };
};
