import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminAgencyEditBlock from 'components/templates/admin/agencyEdit';

const AdminAgencyEdit = () => {
  const { agencyGuid } = useParams();
  const {
    adminPanelAgencies: { getAgency },
  } = useDispatch();

  useEffect(() => {
    getAgency(agencyGuid);
  }, []);
  return (
    <Container>
      <AdminHeader title=" " />
      <div>
        <AdminAgencyEditBlock />
      </div>
    </Container>
  );
};

export default AdminAgencyEdit;
