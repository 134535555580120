import i18next from 'i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import BaseTableOptionsDropdown from 'components/atoms/base-table-option-dropdown';

import type {
  ICondition,
  IConditionForUpdate,
  IConditionIcdCode,
} from 'infrastructure/interfaces';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

interface Props {
  setConditionForUpdate: (args: IConditionForUpdate) => void;
  toggleOpen: () => void;
  handleDelete: (guid: string) => void;
  loading: boolean;
  updatedItemGuid?: string;
  disableActions?: boolean;
}

export const columns = ({
  setConditionForUpdate,
  toggleOpen,
  handleDelete,
  loading,
  updatedItemGuid,
  disableActions,
}: Props): IBaseTableColumns<ICondition> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'createdAt',
      width: '8%',
      render(date: string) {
        return DateFormatter({
          date,
          format: DateFormats['MM-dd-yyyy h:mm a'],
        });
      },
      dataCy: 'conditions-goal-date',
    },
    {
      label: i18next.t('labels.icdCode'),
      key: 'icdCode',
      width: '20%',
      render(icdCode: IConditionIcdCode) {
        return icdCode.code;
      },
      dataCy: 'conditions-goal-icd-code',
    },
    {
      label: i18next.t('labels.description'),
      key: 'description',
      width: '67%',
      dataCy: 'conditions-goal-description',
    },
    {
      label: i18next.t('labels.option'),
      width: '5%',
      key: 'guid',
      render(guid: string, record) {
        return (
          <BaseTableOptionsDropdown
            loading={loading && guid === updatedItemGuid}
            onEdit={() => {
              toggleOpen();
              setConditionForUpdate({
                code: record.icdCode.code,
                description: record.description,
                diagnoseGuid: record.icdCode.guid,
                patientGuid: record.patient.guid,
                guid,
              });
            }}
            onDelete={() => {
              setConditionForUpdate({ guid });
              handleDelete(guid);
            }}
            disabled={disableActions}
          />
        );
      },
    },
  ];
};
