import React from 'react';
import classNames from 'classnames';

import s from './styles.module.scss';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface IBaseFormItemGroupProps extends IBaseComponentProps {
  label: string;
  contentClassName?: string;
  shadow?: boolean;
  controls?: React.ReactNode;
  onHeaderClick?: () => void;
}

const BaseFormGroup: React.FC<IBaseFormItemGroupProps> = (props) => {
  const {
    label,
    children,
    className,
    contentClassName,
    shadow = false,
    onHeaderClick,
    controls,
  } = props;

  const groupClassNames = classNames(s.group, className, {
    [s.shadow]: shadow,
  });
  const contentClassNames = classNames(s.content, contentClassName);
  const headerClassNames = classNames(s.header, {
    [s.pointer]: onHeaderClick,
  });

  return (
    <div className={groupClassNames}>
      <div className={headerClassNames} onClick={onHeaderClick}>
        <p className={s.label}>{label}</p>
        <div>{controls}</div>
      </div>
      <div className={contentClassNames}>{children}</div>
    </div>
  );
};

export default BaseFormGroup;
