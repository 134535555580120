import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Conversations from 'components/organisms/smsDashboard/conversations';
import Chat from 'components/organisms/smsDashboard/chat';
import { isMobile } from 'utils/deviceHelper';
import { BroadcastTopics } from 'services/wsService';
import SmsDashboardSidebar from 'components/organisms/smsDashboard/sidebar/smsDashboardSidebar';
import { useNewMessageBroadcast } from 'infrastructure/hooks/smsDashboard/useNewMessageBroadcast';
import { useUpdateConversationState } from 'infrastructure/hooks/smsDashboard/useUpdateConversationState';
import { useTryUpdateMessageStatus } from 'infrastructure/hooks/smsDashboard/useTryUpdateMessageStatus';
import { useMarkConversation } from 'infrastructure/hooks/smsDashboard/useMarkConversation';
import { PlatformEnums } from 'infrastructure/enums';

import type { RootState } from 'store';
import type { ISocketData as INewMessageSocketData } from 'infrastructure/hooks/smsDashboard/useNewMessageBroadcast';

const SmsDashboard = () => {
  const { currentConversation, conversationType, filters } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const socket = useSelector((state: RootState) => state.socket);
  const device = useSelector((state: RootState) => state.user.deviceType);
  const deviceIsMobile = isMobile(device);

  const { newMessageBroadcast } = useNewMessageBroadcast();
  const { updateConversationState } = useUpdateConversationState();
  const { tryUpdateMessageStatus } = useTryUpdateMessageStatus();
  const { markConversation } = useMarkConversation({});

  useEffect(() => {
    if (socket.isConnected === true && socket.client) {
      socket.client.on(BroadcastTopics.ConversationStateChange, (data: any) => {
        updateConversationState({
          conversationId: Number(data.conversationId),
          read: data.read,
          rpmTotalUnreadCount: data.rpmTotalUnreadCount,
          ccmTotalUnreadCount: data.ccmTotalUnreadCount,
          unregisteredTotalUnreadCount: data.unregisteredTotalUnreadCount,
        });
      });

      socket.client.on(
        BroadcastTopics.DoctorMessageStatusChange,
        (data: any) => {
          tryUpdateMessageStatus({
            conversationId: Number(data.conversationId),
            messageId: data.messageId,
            status: data.status,
          });
        },
      );

      socket.client.on(
        BroadcastTopics.NewMessage,
        (data: INewMessageSocketData) => {
          newMessageBroadcast(data);
          if (
            currentConversation.id === data.conversationId &&
            data.message.authorType === 'patient'
          ) {
            markConversation({
              conversationId: data.conversationId,
              read: true,
            });
          }
        },
      );
    }

    return () => {
      socket.client?.off(BroadcastTopics.ConversationStateChange);
      socket.client?.off(BroadcastTopics.DoctorMessageStatusChange);
      socket.client?.off(BroadcastTopics.NewMessage);
    };
  }, [socket, conversationType, filters, currentConversation]);

  const isCurrentConversationExist = currentConversation.id !== 0;

  const mobilePage = currentConversation ? <Chat /> : <Conversations />;

  const desktopPage = (
    <>
      <Conversations />
      <Chat />
      {isCurrentConversationExist &&
        conversationType !== PlatformEnums.UNREGISTERED && (
          <SmsDashboardSidebar />
        )}
    </>
  );

  return (
    <div className="sms-dashboard">
      <div className="sms-dashboard-body">
        {deviceIsMobile ? mobilePage : desktopPage}
      </div>
    </div>
  );
};

export default SmsDashboard;
