import { useMemo, type FC } from 'react';
import { calculatePercentages } from 'utils/math';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { InteractiveTimeCountData } from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface Props {
  count: InteractiveTimeCountData;
}

const CountItem: FC<{
  title: string;
  percent: string;
  label: string;
}> = ({ title, percent, label }) => {
  return (
    <div className={s.counter} title={title}>
      <span className={s['counter-label']}>{label} </span>
      <span className={s['counter-value']}>{percent}%</span>
    </div>
  );
};

const Count: FC<Props> = ({ count }) => {
  const [truePercent, falsePercent] = useMemo(() => {
    return calculatePercentages(
      count.interactionStatusTrueCount,
      count.interactionStatusFalseCount,
    );
  }, [count.interactionStatusTrueCount, count.interactionStatusFalseCount]);

  const { t } = useTranslation();

  return (
    <div className={s.wrapper}>
      <div className={s.label}>Interaction status:</div>
      <CountItem
        title={t('interactiveTimeDashboard.patientsCount', {
          count: count.interactionStatusTrueCount,
        })}
        label={t('interactiveTimeDashboard.true')}
        percent={truePercent}
      />
      <div className={s.divider} />
      <CountItem
        title={t('interactiveTimeDashboard.patientsCount', {
          count: count.interactionStatusFalseCount,
        })}
        label={t('interactiveTimeDashboard.false')}
        percent={falsePercent}
      />
    </div>
  );
};

export default Count;
