import { Container } from 'reactstrap';
import AdminPatientsTable from 'components/organisms/admin-panel/patients-table';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import { useTranslation } from 'react-i18next';
import PatientsFilter from 'components/organisms/admin-panel/patients-filter';
import ScrollToTopButton from 'components/atoms/scroll-to-top-button';
// import AdminPatientStatistic from 'components/organisms/admin-panel/patients-statistic';

import s from './styles.module.scss';

const AdminPatients = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <AdminNavigationHeader title={t('labels.registeredPatientsList')} />
      <div className={s.body}>
        {/* temporary hidden. waiting for backend implementation */}
        {/* <AdminPatientStatistic /> */}
        <PatientsFilter />
        <AdminPatientsTable />
      </div>
      <ScrollToTopButton />
    </Container>
  );
};

export default AdminPatients;
