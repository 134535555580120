export const getObjValueByStringKey = <T>(obj: T, keyString: string): any => {
  const keys: string[] = keyString.split('.');
  let value: any = structuredClone(obj);
  for (const key of keys) {
    if (value && typeof value === 'object' && key in value) {
      value = value[key];
    } else {
      return undefined;
    }
  }
  return value;
};
