export enum LoginStatus {
  Active = 'active',
  Inactive = 'inactive',
  Blocked = 'blocked',
}

export const AllLoginStatuses = [
  LoginStatus.Active,
  LoginStatus.Inactive,
  LoginStatus.Blocked,
];
