/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import ReactSwitch from 'react-switch';
import common from 'constants/common';

import BaseLoader from '../base-loader';

interface SwitchWithTextProps {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
  loading?: boolean;
  onText?: string;
  offText?: string;
  onBackgroundColor?: string;
  offBackgroundColor?: string;
  backgroundColor?: string;
  dataCy?: string;
}

const SwitchWithText: React.FC<SwitchWithTextProps> = ({
  checked,
  onChange,
  disabled,
  loading,
  onText = 'On',
  offText = 'Off',
  onBackgroundColor = common.commonColors.green,
  offBackgroundColor = common.commonColors.gray,
  backgroundColor = common.commonColors.white,
  dataCy = 'switch-with-text',
}) => {
  return (
    <div className="evelyn-switch--with-text">
      {loading && (
        <div className="switch-loader">
          <BaseLoader loading width={20} height={20} />
        </div>
      )}
      <ReactSwitch
        data-cy={dataCy}
        disabled={loading || disabled}
        onChange={onChange}
        checked={checked}
        uncheckedIcon={
          <div
            style={{
              borderRadius: 20,
              fontWeight: 'semi-bold',
              marginTop: 4,
              color: common.commonColors.gray,
              fontSize: 12,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {onText}
          </div>
        }
        checkedHandleIcon={
          <div
            style={{
              padding: '1px 18px',
              marginLeft: '-18px',
              borderRadius: 20,
              fontWeight: 'semi-bold',
              backgroundColor: onBackgroundColor,
              color: common.commonColors.white,
              fontSize: 12,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {onText}
          </div>
        }
        uncheckedHandleIcon={
          <div
            style={{
              padding: '1px 18px',
              borderRadius: 20,
              fontWeight: 'semi-bold',
              backgroundColor: offBackgroundColor,
              color: common.commonColors.white,
              fontSize: 12,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {offText}
          </div>
        }
        checkedIcon={
          <div
            style={{
              marginTop: 4,
              borderRadius: 20,
              fontWeight: 'semi-bold',
              color: common.commonColors.gray,
              fontSize: 12,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {offText}
          </div>
        }
        width={82}
        height={28}
        borderRadius={20}
        handleDiameter={20}
        activeBoxShadow="0"
        offColor={backgroundColor}
        onColor={backgroundColor}
      />
    </div>
  );
};

export default SwitchWithText;
