import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_MEDICATION } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';

import type {
  IAddMedicationResponse,
  IAddMedicationData,
} from 'infrastructure/interfaces';

const addMedicationFn = async (dataProps: IAddMedicationData) => {
  const url = API_MEDICATION;
  const { error, data } = await dataService.createOne<IAddMedicationResponse>(
    url,
    dataProps,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useAddMedication = () => {
  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: addMedicationFn,
    onSuccess: () => {
      showResult();
    },
  });

  return {
    addMedication: mutate,
    addLoading: isLoading,
    ...other,
  };
};
