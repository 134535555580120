import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const clinicReferralTypeChangesReasonValidationSchema = () =>
  createValidationSchema({
    referralTypeDeleteReason: commonValidationSchemas.string({
      required: true,
      nullable: true,
    }),
  });
