import { useQuery, useQueryClient } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';
import { useState } from 'react';
import { BookmarkComment } from 'infrastructure/classes/bookmark-comment';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';

import type {
  IBookmarkComment,
  IBookmarkCommentsResponse,
} from 'infrastructure/interfaces';

const bookmarkCommentsApi = 'core/bookmark-comments';
const STALE_TIME = minutesToMilliseconds(30);
const QUERY_KEY = 'bookmark-comments';

type UseBookmarkedComments = {
  bookmarkComments: Array<BookmarkComment>;
  loading: boolean;
  addComment: (data: IBookmarkComment) => Promise<void>;
  updateComment: (data: BookmarkComment) => Promise<void>;
  removeComment: (data: BookmarkComment) => Promise<void>;
};

export const useBookmarkedComments = (): UseBookmarkedComments => {
  const queryFn = async () => {
    const { data, error } =
      await dataService.getList<IBookmarkCommentsResponse>(bookmarkCommentsApi);
    if (error) {
      showResult(error);
      throw new Error(error);
    }
    return data?.data.map((el) => new BookmarkComment(el));
  };

  const {
    data: bookmarkComments = [],
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn,
    staleTime: STALE_TIME,
  });

  const queryClient = useQueryClient();

  const [isActionLoading, setIsActionLoading] = useState(false);

  const addComment = async (comment: IBookmarkComment) => {
    setIsActionLoading(true);
    const { error, data } = await dataService.createList<IBookmarkComment>(
      bookmarkCommentsApi,
      comment,
    );
    setIsActionLoading(false);
    if (error) {
      showResult(error);
      return;
    }
    if (data) {
      const newItems = [new BookmarkComment(data), ...bookmarkComments];
      queryClient.setQueryData([QUERY_KEY], newItems);
    }
  };

  const updateComment = async (comment: BookmarkComment) => {
    setIsActionLoading(true);
    const { error, data } = await dataService.updateList<IBookmarkComment>(
      bookmarkCommentsApi,
      comment,
    );
    setIsActionLoading(false);
    if (error) {
      showResult(error);
      return;
    }
    if (data) {
      const newItems = [
        new BookmarkComment(data),
        ...bookmarkComments.filter((item) => item.guid !== comment.guid),
      ];
      queryClient.setQueryData([QUERY_KEY], newItems);
    }
  };

  const removeComment = async (comment: BookmarkComment) => {
    setIsActionLoading(true);
    const { error } = await dataService.deleteObject(
      bookmarkCommentsApi,
      comment,
    );
    setIsActionLoading(false);
    if (error) {
      showResult(error);
      return;
    }
    const newItems = bookmarkComments.filter(
      (item) => item.guid !== comment.guid,
    );
    queryClient.setQueryData([QUERY_KEY], newItems);
  };

  return {
    bookmarkComments,
    loading: isLoading || isRefetching || isActionLoading,
    addComment,
    updateComment,
    removeComment,
  };
};
