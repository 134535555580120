import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const CheckCircleOutlined = ({
  color = COLORS.FOREST_GREEN,
  ...props
}: IIconProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16ZM9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        fill={color}
      />
      <path
        d="M11.9697 5.96967C11.9626 5.97674 11.9559 5.98423 11.9498 5.9921L8.4774 10.4167L6.38388 8.32322C6.09098 8.03033 5.61611 8.03033 5.32322 8.32322C5.03032 8.61612 5.03032 9.09099 5.32322 9.38388L7.96966 12.0303C8.26256 12.3232 8.73743 12.3232 9.03032 12.0303C9.03685 12.0238 9.043 12.0169 9.04876 12.0097L13.041 7.01947C13.3232 6.72582 13.3196 6.25897 13.0303 5.96967C12.7374 5.67678 12.2626 5.67678 11.9697 5.96967Z"
        fill={color}
      />
      <path
        d="M9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16ZM9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9697 5.96967C11.9626 5.97674 11.9559 5.98423 11.9498 5.9921L8.4774 10.4167L6.38388 8.32322C6.09098 8.03033 5.61611 8.03033 5.32322 8.32322C5.03032 8.61612 5.03032 9.09099 5.32322 9.38388L7.96966 12.0303C8.26256 12.3232 8.73743 12.3232 9.03032 12.0303C9.03685 12.0238 9.043 12.0169 9.04876 12.0097L13.041 7.01947C13.3232 6.72582 13.3196 6.25897 13.0303 5.96967C12.7374 5.67678 12.2626 5.67678 11.9697 5.96967Z"
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckCircleOutlined;
