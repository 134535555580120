import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ConsumableLog } from 'infrastructure/classes/logs/consumable-log';
import { prepareLogsDateRange } from 'infrastructure/functions/logs';

import useApi from '../use-api';

import type { DateRange } from 'infrastructure/types';
import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IPaginatedConsumableLogs,
} from 'infrastructure/interfaces';
import type { Dispatch, SetStateAction } from 'react';

const consumableLogApi = (patientGuid: string) =>
  `core/patients/${patientGuid}/consumable-logs`;

interface IUseConsumableLogMeta {
  dateRange?: DateRange;
}

type mergedMeta = IUseConsumableLogMeta & IPaginationMeta;

type UseLog = {
  loading: boolean;
  items: Array<ConsumableLog>;
  meta: mergedMeta;
  setMeta: Dispatch<SetStateAction<mergedMeta>>;
};

interface IUseLogProps {
  patientGuid: string;
  meta?: IUseConsumableLogMeta & IPartialPaginationMeta;
}

export const useConsumableLog = (props: IUseLogProps): UseLog => {
  const options = props;
  const { patientGuid, meta: metaProps = {} } = options;

  const [meta, setMeta] = useState<mergedMeta>({
    page: 1,
    items: 15,
    totalCount: 0,
    ...metaProps,
  });

  const { loadData } = useApi();

  const queryKey = [
    consumableLogApi(patientGuid),
    prepareLogsDateRange(meta.dateRange),
    meta.page,
  ];

  const { data = [], isLoading } = useQuery({
    queryKey,
    queryFn: async () =>
      loadData<IPaginatedConsumableLogs>(consumableLogApi(patientGuid), {
        page: meta.page,
        items: meta.items,
        ...prepareLogsDateRange(meta.dateRange),
      }).then((res) => {
        if (res) {
          const { count, items: rows } = res;

          setMeta({
            ...meta,
            totalCount: count,
          });

          return rows.map((el) => new ConsumableLog(el));
        }
      }),
    enabled: Boolean(patientGuid),
  });

  return {
    loading: isLoading,
    items: data,
    meta,
    setMeta,
  };
};
