import { useState } from 'react';

const MAX_PARALLEL_COMMENT_GENERATIONS = 5;

export const useCommentGenerationLimit = () => {
  const [parallelGenerations, setParallelGenerations] = useState<number>(0);

  const onCommentGenerationStatusChange = (action: 'start' | 'end') => {
    if (action === 'start') {
      setParallelGenerations((prev) => prev + 1);
    } else {
      setParallelGenerations((prev) => {
        if (prev <= 0) return 0;
        return prev - 1;
      });
    }
  };

  const isCommentGenerationLimitReached =
    parallelGenerations >= MAX_PARALLEL_COMMENT_GENERATIONS;
  return {
    isCommentGenerationLimitReached,
    onCommentGenerationStatusChange,
  };
};
