import common from 'constants/common';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { parseDateSafe } from 'utils/timeHelper';

const formatDate = (
  rawDate: Date,
  dateFormat: string = common.dateFormats.full,
) => {
  const timeInfo = useMemo(() => {
    const date = parseDateSafe(rawDate);
    if (!date) return null;

    return format(date, dateFormat);
  }, [rawDate]);

  return timeInfo;
};

export default formatDate;
