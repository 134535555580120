import dataService from 'services/dataService';
import { API_INTERACTIVE_TIME_REPORT } from 'services/dataService/resources';
import { useMutation } from '@tanstack/react-query';
import { showResult } from 'infrastructure/hooks/utils';
import QueryParams from 'utils/queryParams';

import { generateQueryFromFilters } from './utils';

import type { Filters } from './types';

export const useInteractiveTimeDashboardSendReport = (filters: Filters) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const url = QueryParams.stringifyUrl(
        API_INTERACTIVE_TIME_REPORT,
        generateQueryFromFilters(filters),
      );
      const { error, data } = await dataService.createOne(url);
      if (error) {
        showResult(error);
        return;
      }
      return data;
    },
  });

  return {
    isLoading,
    sendReportToEmail: mutate,
  };
};
