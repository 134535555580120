import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { workingStatusesDataSource } from 'infrastructure/data-sources/working-statuses';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IWorkingStatusItem } from 'infrastructure/data-sources/working-statuses';

type Options = IBaseSelectBoxOption<
  IWorkingStatusItem['id'],
  IWorkingStatusItem
>[];

interface IWorkingStatusSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {}

const WorkingStatusSelectBox: React.FC<IWorkingStatusSelectBoxProps> = (
  props,
) => {
  const dataSource = useMemo(() => workingStatusesDataSource(), []);

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...props} />;
};

export default WorkingStatusSelectBox;
