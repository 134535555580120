export const onboardingStatuses = [
  'calling_queue',
  'patient_referral',
  'referral_on_hold',
  'patient_no_contact',
  'patient_refused',
  'patient_refused_connectivity_or_tech_barier',
  'patient_refused_copay_insurance',
  'patient_refused_not_interested_not_necessary_suspect_scam',
  'patient_refused_expired',
  'patient_refused_there_is_no_one_to_help',
  'patient_refused_has_a_new_physician',
  'patient_refused_wants_to_discuss_with_physician',
  'patient_refused_wrong_contact_information',
  'insurance_refused',
  'onboarding_unsuccessful',
];

export const ccmPageAvailableStatuses = [
  'enrolled',
  'ccm_onboarding',
  'ccm_onboarding_unsuccessful_no_contact',
  'ccm_onboarding_unsuccessful_patient_refused',
  'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
  'ccm_onboarding_unsuccessful_changed_doctor',
  'ccm_onboarding_unsuccessful_other',
  'ccm_unenrolled_changed_doctor',
  'ccm_unenrolled_client_request',
  'ccm_unenrolled_copay_loss_of_insurance',
  'ccm_unenrolled_non_adherent',
  'ccm_unenrolled_expired',
  'ccm_unenrolled_hospice',
  'ccm_unenrolled_long_term_skilled_nursing_facility_rehab',
  'ccm_unenrolled_physician_clinic_request',
  'ccm_unenrolled_other',
  'ccm_unenrolled_client_request_moving',
  'ccm_unenrolled_health_plan_request_goals_met',
];

export const ccmPageReadOnlyStatuses = [
  'ccm_onboarding_unsuccessful_no_contact',
  'ccm_onboarding_unsuccessful_patient_refused',
  'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
  'ccm_onboarding_unsuccessful_changed_doctor',
  'ccm_onboarding_unsuccessful_other',
  'ccm_unenrolled_changed_doctor',
  'ccm_unenrolled_client_request',
  'ccm_unenrolled_copay_loss_of_insurance',
  'ccm_unenrolled_non_adherent',
  'ccm_unenrolled_expired',
  'ccm_unenrolled_hospice',
  'ccm_unenrolled_long_term_skilled_nursing_facility_rehab',
  'ccm_unenrolled_physician_clinic_request',
  'ccm_unenrolled_other',
];

export const ccmPageActionAllowedStatues = ['enrolled', 'ccm_onboarding'];

export const ccmOnboardingStatuses = [
  'ccm_referral_received',
  'ccm_referral_on_hold',
  'ccm_referral_withdrawn',
  'ccm_patient_no_contact',
  'ccm_calling_queue',
  'ccm_patient_refused_copay_insurance',
  'ccm_patient_refused_expired',
  'ccm_patient_refused_changed_doctor',
  'ccm_patient_refused_not_interested_not_necessary_suspect_scam',
  'ccm_patient_refused_wrong_contact_information',
  'ccm_patient_refused_no_contact',
  'ccm_patient_refused_other',
  'ccm_onboarding',
  'ccm_onboarding_unsuccessful_no_contact',
  'ccm_onboarding_unsuccessful_patient_refused',
  'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
  'ccm_onboarding_unsuccessful_changed_doctor',
  'ccm_onboarding_unsuccessful_other',
];

export const patientPageStatuses = [
  'onboarding',
  'active',
  'pending_review',
  'inactive_bad_no_signal_connectivity',
  'inactive_changed_doctor',
  'inactive_client_request_inconvenient',
  'inactive_client_request_moving',
  'inactive_client_request_other',
  'inactive_expired',
  'inactive_health_plan_request_goals_met',
  'inactive_health_plan_request_non_compliant',
  'inactive_health_plan_request_other',
  'inactive_hospice',
  'inactive_long_term_care_rehab',
  'inactive_loss_of_eligibility',
  'inactive_physician_request',
  'inactive_copay',
];
