export enum ChargerConsumables {
  'USB-WC' = 'USB-WC',
  'U807-PWR' = 'U807-PWR',
  'A600N-PWR' = 'A600N-PWR',
  '4GTherm-PWR' = '4GTherm-PWR',
  'SLPMAT-PWR' = 'SLPMAT-PWR',
  'TSB8057-PWR' = 'TSB8057-PWR',
  'G427B-PWR' = 'G427B-PWR',
  'BP01-PWR' = 'BP01-PWR',
}
