import type { ICareAgent, ICareAgents } from 'infrastructure/interfaces';

export class CareAgents {
  ccmManager?: ICareAgent;
  patManager?: ICareAgent;
  onboardingManager?: ICareAgent;
  ccmPatManager?: ICareAgent;
  ccmOnboardingAgent?: ICareAgent;

  constructor(data: ICareAgents) {
    this.ccmManager = data.ccmManager;
    this.patManager = data.patManager;
    this.onboardingManager = data.onboardingManager;
    this.ccmPatManager = data.ccmPatManager;
    this.ccmOnboardingAgent = data.ccmOnboardingAgent;
  }
}
