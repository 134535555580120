import i18next from 'i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import { formatAuthorName } from 'utils/formatters';
import { MAX_TEXT_LENGTH_FOR_EXPAND } from 'infrastructure/consts/tables';

import s from './styles.module.scss';

import type {
  IClinicNotes,
  IClinicNotesAuthor,
} from 'infrastructure/interfaces';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

export interface ColumnsProps {
  expandedRecord?: {
    expanded: boolean;
    guid: string;
  };
}

export const columns = ({
  expandedRecord,
}: ColumnsProps): IBaseTableColumns<IClinicNotes> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'createdAt',
      width: '8%',
      render(value: string) {
        return (
          <span data-cy="clinic-notes-date">
            {DateFormatter({
              date: value,
              format: DateFormats['MM-dd-yyyy h:mm a'],
            })}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.electronicallySignedBy'),
      key: 'author',
      width: '20%',
      render(author: IClinicNotesAuthor) {
        return (
          <span data-cy="clinic-notes-author">
            {formatAuthorName({
              title: author.title,
              firstName: author.firstName,
              lastName: author.lastName,
              professionType: author.profession,
            })}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.noteComment'),
      key: 'note',
      width: '72%',
      render(value: string, record) {
        const isValueLongerThenMax = value.length >= MAX_TEXT_LENGTH_FOR_EXPAND;
        return (
          <div
            style={{
              cursor: isValueLongerThenMax ? 'pointer' : 'auto',
            }}
          >
            <div data-cy="clinic-notes-note" className={s.note}>
              {isValueLongerThenMax
                ? `${value.slice(0, MAX_TEXT_LENGTH_FOR_EXPAND)} ...`
                : value}
            </div>
            {isValueLongerThenMax && (
              <div className={s['show-more']}>
                {expandedRecord?.expanded &&
                expandedRecord?.guid === record.guid
                  ? i18next.t('controls.showLess')
                  : i18next.t('controls.showMore')}
              </div>
            )}
          </div>
        );
      },
    },
  ];
};
