import { useSearchIcdTenCode } from 'infrastructure/hooks/ccm';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import BaseSearchSelect from 'components/atoms/base-search-select';

import type { BaseSearchSelectProps } from 'components/atoms/base-search-select/types';
import type { FC } from 'react';

interface ConditionSearchSelectProps {
  value?: string;
  onChange?: BaseSearchSelectProps<string>['onSelect'];
  disabled?: boolean;
}

const ConditionSearchSelect: FC<ConditionSearchSelectProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const { search, isLoading, data } = useSearchIcdTenCode();
  const { t } = useTranslation();

  const debounceSearch = useCallback(debounce(search, 800), []);

  return (
    <BaseSearchSelect
      options={data?.items?.map((item) => ({
        value: item.guid,
        label: item.code,
        ...item,
      }))}
      onSelect={onChange}
      value={value}
      onChange={debounceSearch}
      isLoading={isLoading}
      placeholder={t('labels.searchCode')}
      disabled={disabled}
    />
  );
};
export default ConditionSearchSelect;
