import React from 'react';
import ArrowDown from 'components/atoms/icons/arrowDownIcon';
import ArrowUp from 'components/atoms/icons/arrowUpIcon';
import PropTypes from 'prop-types';

const getNextSort = (sort) => {
  if (sort?.match(/desc/i)) return 'asc';
  if (sort?.match(/asc/i)) return '';
  return 'desc';
};

const SortableColumn = ({ title, handler, sort, dataCy }) => {
  const onClick = () => {
    const nextSorting = getNextSort(sort);
    handler(nextSorting);
  };

  return (
    <button className="sortable-table-col" data-cy={dataCy} onClick={onClick}>
      {title}
      <div className="sortable-table-col__sort-icons">
        <ArrowUp
          className={
            !sort || sort.match(/desc/i)
              ? 'sortable-table-col__sort-icon--show'
              : 'sortable-table-col__sort-icon--hide'
          }
        />
        <ArrowDown
          className={
            !sort || sort.match(/asc/i)
              ? 'sortable-table-col__sort-icon--show'
              : 'sortable-table-col__sort-icon--hide'
          }
        />
      </div>
    </button>
  );
};

SortableColumn.defaultProps = {
  handler: () => {},
};

SortableColumn.propTypes = {
  title: PropTypes.string,
  handler: PropTypes.func,
  sort: PropTypes.string,
  dataCy: PropTypes.string,
};

export default SortableColumn;
