import React from 'react';

import type { SVGProps } from 'react';

const BookmarksFillIcon: React.FC<SVGProps<any>> = ({
  fill = '#FB9318',
  ...props
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 4C2 2.89543 2.89543 2 4 2H10C11.1046 2 12 2.89543 12 4V15.5C12 15.6844 11.8985 15.8538 11.7359 15.9408C11.5733 16.0278 11.3761 16.0183 11.2226 15.916L7 13.1009L2.77735 15.916C2.62392 16.0183 2.42665 16.0278 2.26407 15.9408C2.10149 15.8538 2 15.6844 2 15.5V4Z"
      fill={fill}
    />
    <path
      d="M4.26758 1C4.61339 0.402199 5.25973 0 6.00001 0H12C13.1046 0 14 0.89543 14 2V13.5C14 13.6844 13.8985 13.8538 13.7359 13.9408C13.5734 14.0278 13.3761 14.0183 13.2227 13.916L13 13.7676V2C13 1.51675 12.6572 1.11356 12.2015 1.02032C12.1365 1.007 12.069 1 12 1H4.26758Z"
      fill={fill}
    />
  </svg>
);

export default BookmarksFillIcon;
