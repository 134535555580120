import { useState, type FC } from 'react';

import s from './styles.module.scss';
import Header from './header';
import Body from './body';

import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface Props {
  appendFilters: AppendFilters;
  filters: Filters;
  filtersCount: number;
  resetFilters: () => void;
}

const InteractiveTimeReportFilters: FC<Props> = ({
  filtersCount,
  appendFilters,
  resetFilters,
  filters,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={s.wrapper}>
      <Header
        expanded={expanded}
        toggleExpanded={() => setExpanded((prev) => !prev)}
        filtersCount={filtersCount}
        resetFilters={resetFilters}
      />
      {expanded && <Body filters={filters} appendFilters={appendFilters} />}
    </div>
  );
};

export default InteractiveTimeReportFilters;
