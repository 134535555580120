import React from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { languagesDataSource } from 'infrastructure/data-sources/languages';
import { sortArrayByFieldName } from 'utils/helpers/sort-array-by-field-name';

import type { ILanguageItem } from 'infrastructure/data-sources/languages';
import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';

interface ILanguageSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {}

const LanguageSelectBox: React.FC<ILanguageSelectBoxProps> = (props) => {
  const languages = languagesDataSource();

  const options: IBaseSelectBoxOption<ILanguageItem['id'], ILanguageItem>[] =
    languages.map((language) => ({
      value: language.id,
      label: language.name,
      record: language,
    }));

  return (
    <BaseSelectBox
      options={sortArrayByFieldName(options, 'label')}
      {...props}
    />
  );
};

export default LanguageSelectBox;
