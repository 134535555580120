import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

import type { TSchema } from 'infrastructure/functions';

export const medicationValidationSchema = () =>
  createValidationSchema(
    {
      name: commonValidationSchemas.string({ required: true }),
      dosage: commonValidationSchemas.string(),
      duration: commonValidationSchemas.string(),
      frequency: commonValidationSchemas.string(),
      note: commonValidationSchemas.string(),
      route: commonValidationSchemas.string(),
      isActive: commonValidationSchemas.boolean().default(true),
      startDate: commonValidationSchemas
        .date()
        .when('endDate', (endDate, schema) => {
          return endDate
            ? schema.max(endDate, 'Start date must be earlier than End date')
            : schema;
        }),
      endDate: commonValidationSchemas
        .date()
        .when('startDate', (startDate, schema) => {
          return startDate
            ? schema.min(startDate, 'End date must be later than Start date')
            : schema;
        }),
    },
    [['endDate', 'startDate']],
  );

export type Values = TSchema<ReturnType<typeof medicationValidationSchema>>;
