import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_MEDICATION } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { useTranslation } from 'react-i18next';

import { getMedicationKey } from './use-get-medication';

import type {
  IAddMedicationResponse,
  IMedicationResponse,
} from 'infrastructure/interfaces';

interface Props {
  page: number;
  items: number;
  isActive?: boolean;
}

const deleteMedicationFn = async (guid: string) => {
  const url = API_MEDICATION;
  const { error, data } = await dataService.deleteOne<IAddMedicationResponse>(
    url,
    { id: guid },
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useDeleteMedication = (props: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: deleteMedicationFn,
    onSuccess: (data) => {
      notificationController.info({
        message: t('messages.deleteNoticeMessage', { delete: 'Medication' }),
      });
      const queryKey = getMedicationKey({
        ...props,
        patientGuid: data?.patientGuid ?? '',
      });

      queryClient.invalidateQueries<IMedicationResponse>(queryKey);
    },
  });

  return {
    deleteMedication: mutate,
    deleteLoading: isLoading,
    ...other,
  };
};
