const ConnectHideIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="10" cy="10" r="10" fill="#FB9318" />
    <path d="M14 10L10 14L6 10" stroke="white" strokeLinecap="round" />
    <path d="M14 6L10 10L6 6" stroke="white" strokeLinecap="round" />
  </svg>
);

export default ConnectHideIcon;
