import React, { useEffect, useState } from 'react';
import BaseTableNew from 'components/atoms/base-table/new';
import { PatientInsurance } from 'infrastructure/classes/patient/patient-insurance';
import BaseButton from 'components/atoms/baseButton';
import PlusIcon from 'components/atoms/icons/plus';
import { useTranslation } from 'react-i18next';
import BaseModal from 'components/atoms/base-modal';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import { usePatientInsurance } from 'infrastructure/hooks/patient/use-patient-insurance';
import { ModuleType } from 'infrastructure/enums';
import useUser from 'utils/useUser';

import PatientInsuranceCreateForm from '../create-update-form';
import { patientInsuranceColumns } from './cols';
import s from './styles.module.scss';

interface IPatientInsuranceTableProps {
  patientGuid: string;
  module: ModuleType;
}

const PatientInsuranceTable: React.FC<IPatientInsuranceTableProps> = (
  props,
) => {
  const { patientGuid, module } = props;

  const { t } = useTranslation();

  const [open, setIsOpen] = useState(false);
  const [patientInsurance, setPatientInsurance] = useState<
    PatientInsurance | undefined
  >();
  const [items, setItems] = useState<Array<PatientInsurance>>([]);
  const { loading, loadPatientInsurance, updatePatientInsurance } =
    usePatientInsurance({ patientGuid });

  const { iamPatientAcceptance } = useUser();

  const canCreate = module === ModuleType.ADMIN && !iamPatientAcceptance;

  const modalLabel = patientInsurance
    ? t('labels.editPatientInsurance')
    : t('labels.createPatientInsurance');

  const { Alert, showAlert } = useAlert();

  const loadData = async () => {
    const data = await loadPatientInsurance();
    if (data) {
      setItems(data.map((el) => new PatientInsurance(el)));
    }
  };

  const onCreate = () => {
    setPatientInsurance(undefined);
    setIsOpen(true);
  };

  const onEdit = (record: PatientInsurance) => {
    setPatientInsurance(record);
    setIsOpen(true);
  };

  const onDelete = async (record: PatientInsurance) => {
    const res = await showAlert({
      title: t('labels.confirmDeleting'),
      messageTitle: t('messages.conditionDelitingPatientInsurance'),
      type: 'danger',
    });
    if (res) {
      await updatePatientInsurance({
        patientGuid,
        insurances: items.filter((el) => el.guid !== record.guid),
      });
      await loadData();
    }
  };

  const onSaved = async () => {
    setIsOpen(false);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, [patientGuid]);

  return (
    <div>
      <BaseTableNew
        dataCy="patient-insurance-table"
        loading={loading}
        columns={patientInsuranceColumns({
          module,
          onEdit,
          onDelete,
          iamPatientAcceptance,
        })}
        items={items}
        bordered
      />
      {canCreate && (
        <div className={s.controls}>
          <BaseButton
            loading={loading}
            icon={<PlusIcon color="" />}
            label={t('controls.addPatientInsurance')}
            onClick={onCreate}
            dataCy="addPatientInsurance-button"
          />
        </div>
      )}

      <BaseModal
        title={modalLabel}
        open={open}
        onCancel={() => setIsOpen(false)}
      >
        <PatientInsuranceCreateForm
          patientGuid={patientGuid}
          data={patientInsurance}
          items={items}
          onSaved={onSaved}
          onCancel={() => setIsOpen(false)}
        />
      </BaseModal>
      {Alert}
    </div>
  );
};

export default PatientInsuranceTable;
