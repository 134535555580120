interface AnyObject {
  [key: string]: any;
}

interface IReplaceOrAddObjectProps<T> {
  array: Array<T>;
  field: keyof T;
  value: any;
  newObject: T;
}

export const replaceOrAddObject = <T extends AnyObject>({
  array,
  field,
  value,
  newObject,
}: IReplaceOrAddObjectProps<T>): T[] => {
  const index = array.findIndex((item) => item[field] === value);

  if (index !== -1) {
    const newArray = [...array];
    newArray[index] = newObject;
    return newArray;
  }
  return [...array, newObject];
};
