import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'constants/paths';
import useUser from 'utils/useUser';
import {
  isAdmin,
  isAgencyDoctor,
  isRoleNameAdministrator,
} from 'utils/userTypeHelper';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import AddButtonIcon from 'components/atoms/icons/addButtonIcon';
import PlusIcon from 'components/atoms/icons/plusIcon';
import { hasPatientAcceptanceRole } from 'utils/accessManager/helpers';

import BatchReportModal from './batchReportModal';
import SopReportModal from './sopReportModal';

const AdminDropdown = () => {
  const [vitalCsvUploadOpen, setVitalCsvUploadOpen] = useState(false);
  const [sopCsvUploadOpen, setSopCsvUploadOpen] = useState(false);
  const navigate = useNavigate();
  const { userType, roleNames } = useUser();
  const iamAdminOrHHA = useMemo(
    () => isAdmin(userType) || isAgencyDoctor(userType),
    [userType],
  );
  const iamPatientAcceptance = useMemo(
    () => hasPatientAcceptanceRole(roleNames),
    [roleNames],
  );
  const iamAdmin = useMemo(() => isAdmin(userType), [userType]);
  const iamAdminOrAdministrator = useMemo(
    () => isAdmin(userType) || isRoleNameAdministrator(roleNames),
    [userType, roleNames],
  );

  return (
    <>
      {!iamPatientAcceptance && (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle
            nav
            data-cy="add__toggle"
            className="navbar-dropdown-profile"
          >
            <AddButtonIcon />
          </DropdownToggle>
          <DropdownMenu right className="navbar-dropdown profile-menu">
            <DropdownItem
              data-cy="add__patient"
              className="navbar-dropdown-item"
              onClick={() => navigate(paths.adminPatientAdd)}
            >
              New Patients <PlusIcon />
              {/* <Link to={paths.adminBulkUpload}>
              <CsvIcon />
            </Link> */}
            </DropdownItem>
            <DropdownItem
              data-cy="add__user"
              className="navbar-dropdown-item"
              onClick={() => navigate(paths.adminUserAdd)}
            >
              New Users <PlusIcon />
            </DropdownItem>
            {iamAdminOrHHA && (
              <DropdownItem
                data-cy="add__agency-user"
                className="navbar-dropdown-item"
                onClick={() => navigate(paths.adminAgencyDoctorAdd)}
              >
                New Agency User <PlusIcon />
              </DropdownItem>
            )}
            {iamAdminOrHHA && (
              <DropdownItem
                data-cy="add__clinic"
                className="navbar-dropdown-item"
                onClick={() => navigate(paths.adminClinicAdd)}
              >
                New Clinics <PlusIcon />
              </DropdownItem>
            )}
            {iamAdmin && (
              <DropdownItem
                data-cy="add__hha"
                className="navbar-dropdown-item"
                onClick={() => navigate(paths.adminAgencyAdd)}
              >
                New HHA <PlusIcon />
              </DropdownItem>
            )}
            {iamAdmin && (
              <DropdownItem
                data-cy="add__turk-user"
                className="navbar-dropdown-item"
                onClick={() => navigate(paths.adminTurkUsersAdd)}
              >
                New Turk User <PlusIcon />
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
      {iamAdminOrAdministrator && (
        <BatchReportModal
          isOpen={vitalCsvUploadOpen}
          toggle={setVitalCsvUploadOpen}
        />
      )}
      <SopReportModal isOpen={sopCsvUploadOpen} toggle={setSopCsvUploadOpen} />
    </>
  );
};

export default AdminDropdown;
