import type { SVGProps } from 'react';

const ListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 14.375C6.25 14.0298 6.52982 13.75 6.875 13.75H18.125C18.4702 13.75 18.75 14.0298 18.75 14.375C18.75 14.7202 18.4702 15 18.125 15H6.875C6.52982 15 6.25 14.7202 6.25 14.375Z"
      fill="#2C2543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 9.375C6.25 9.02982 6.52982 8.75 6.875 8.75H18.125C18.4702 8.75 18.75 9.02982 18.75 9.375C18.75 9.72018 18.4702 10 18.125 10H6.875C6.52982 10 6.25 9.72018 6.25 9.375Z"
      fill="#2C2543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 4.375C6.25 4.02982 6.52982 3.75 6.875 3.75H18.125C18.4702 3.75 18.75 4.02982 18.75 4.375C18.75 4.72018 18.4702 5 18.125 5H6.875C6.52982 5 6.25 4.72018 6.25 4.375Z"
      fill="#2C2543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 5.625C3.19036 5.625 3.75 5.06536 3.75 4.375C3.75 3.68464 3.19036 3.125 2.5 3.125C1.80964 3.125 1.25 3.68464 1.25 4.375C1.25 5.06536 1.80964 5.625 2.5 5.625Z"
      fill="#2C2543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10.625C3.19036 10.625 3.75 10.0654 3.75 9.375C3.75 8.68464 3.19036 8.125 2.5 8.125C1.80964 8.125 1.25 8.68464 1.25 9.375C1.25 10.0654 1.80964 10.625 2.5 10.625Z"
      fill="#2C2543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 15.625C3.19036 15.625 3.75 15.0654 3.75 14.375C3.75 13.6846 3.19036 13.125 2.5 13.125C1.80964 13.125 1.25 13.6846 1.25 14.375C1.25 15.0654 1.80964 15.625 2.5 15.625Z"
      fill="#2C2543"
    />
  </svg>
);

export default ListIcon;
