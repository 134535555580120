import { useQuery } from '@tanstack/react-query';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';
import dataService from 'services/dataService';
import { API_PATIENT_CALLS } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';
import { useAppSelector } from 'store';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import type {
  IPartialPaginationMeta,
  IVoiceCallResponse,
} from 'infrastructure/interfaces';

interface Props {
  patientGuid: string;
  meta: IPartialPaginationMeta;
}

type TFilter = {
  patientGuid: string;
  startTime?: string;
  endTime?: string;
} & IPartialPaginationMeta;

export const getVoiceCallsFn = async ({ ...filter }: TFilter) => {
  const url = API_PATIENT_CALLS;
  const { data, error } = await dataService.getList<IVoiceCallResponse>(
    apiUrlGenerator(url, filter),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const getVoiceCallsKey = (filter: TFilter) => ['getVoiceCalls', filter];

export const VOICE_CALLS_PER_PAGE = 5;

export const useGetVoiceCalls = ({ patientGuid, meta }: Props) => {
  const [startTime, endTime] = useAppSelector<[Date, Date] | []>(
    (state) => state.ccmPatientDetails.dates,
  );

  const filter: TFilter = {
    ...meta,
    patientGuid,
    items: VOICE_CALLS_PER_PAGE,
    startTime: DateFormatter({
      date: startTime,
      format: DateFormats['yyyy-MM-dd HH:mm:s'],
    }),
    endTime: DateFormatter({
      date: endTime,
      format: DateFormats['yyyy-MM-dd HH:mm:s'],
    }),
  };

  const {
    data: calls,
    isLoading,
    isFetching,
    isRefetching,
    ...other
  } = useQuery({
    queryKey: getVoiceCallsKey(filter),
    queryFn: async () => getVoiceCallsFn({ ...filter }),
    enabled: Boolean(startTime) && Boolean(endTime),
  });

  return {
    calls,
    isLoading: isLoading || isFetching || isRefetching,
    isFetching,
    ...other,
  };
};
