import React from 'react';
import { useSelector } from 'react-redux';

const Header = () => {
  const { totalCount } = useSelector((state) => state.adminPanelAgencies);
  return (
    <div className="adminPanel__header">
      <h5>
        List of Agencies: <strong>{totalCount}</strong>
      </h5>
    </div>
  );
};

export default Header;
