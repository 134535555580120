import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConnectModal from 'components/organisms/connect';
import Layout from 'components/templates/layout';
import TermsPage from 'pages/termsOfService';
import NoActiveClinicPage from 'components/atoms/noActiveClinic';
import { BroadcastTopics } from 'services/wsService';
import { successMessages, errorMessages } from 'constants/messages';
import useUser from 'utils/useUser';
import { useAuth0 } from '@auth0/auth0-react';
import { UserLoginStatus } from 'constants/user';
import { useOfflineToast } from 'utils/hooks/common/useOfflineToast';
import useLogout from 'utils/hooks/auth/useLogout';
import BaseLoader from 'components/atoms/base-loader';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import ScrollToTop from './scroll-to-top';

import type { FC } from 'react';
import type { RootState, RootDispatch } from 'store';

const MainAppLayout: FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { logout } = useLogout();
  const { t } = useTranslation();

  useEffect(() => {
    window.getAccessTokenSilently = getAccessTokenSilently;
  }, [getAccessTokenSilently]);

  const {
    isLoading,
    userType,
    guid,
    logoutFlag,
    termsOfService,
    loginStatus,
    isLoggingOut,
  } = useUser();

  const socket = useSelector((state: RootState) => state.socket);

  const {
    user: { setDevice, getProfile },
    socket: { init: onSocketInit },
  } = useDispatch<RootDispatch>();

  const refreshPage = () => {
    window.location.reload();
  };

  useOfflineToast();

  useEffect(() => {
    const init = async () => {
      if (socket.isConnected === false || socket.client === null) {
        await onSocketInit();
      }

      if (!userType) await getProfile();
      setDevice();
    };

    if (!userType) init();

    if (userType && socket.isConnected === true && socket.client) {
      socket.client.on(BroadcastTopics.NewReading, () => {
        const alertKey = 'refreshToast';
        notificationController.info({
          key: alertKey,
          message: t('messages.newReadingMessage'),
          duration: 0,
          btn: <Button onClick={refreshPage}>{t('controls.refresh')}</Button>,
        });
      });
      socket.client.on(BroadcastTopics.SendReport, (data: any) => {
        if (data.status === 'succeed') {
          notificationController.success({
            message: successMessages.reportReady(data.reportType || ''),
          });
        } else if (data.status === 'failed') {
          notificationController.error({
            message: errorMessages.report(data.reportType, data.message),
          });
        }
      });
    }

    return () => {
      if (socket.isConnected === true && socket.client) {
        socket.client.off(BroadcastTopics.NewReading);
        socket.client.off(BroadcastTopics.SendReport);
      }
    };
  }, [userType]);

  if (logoutFlag) {
    logout();
    return <></>;
  }

  if (loginStatus === UserLoginStatus.BLOCKED) {
    return (
      <Layout disableNavigation>
        <NoActiveClinicPage />
      </Layout>
    );
  }

  if (isLoading || !userType || !guid || isLoggingOut)
    return <BaseLoader className="loader fixed" loading />;

  if (!termsOfService) return <TermsPage />;
  return (
    <>
      <ScrollToTop />
      <Layout>
        <Outlet />
      </Layout>
      <ConnectModal />
    </>
  );
};

export default MainAppLayout;
