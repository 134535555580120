import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PatientFilter from 'components/templates/rpm/patients/patient/patientFilter';

const PatientPage = () => (
  <div className="patient">
    <Container>
      <Row className="patient-container">
        <Col xs="12" md="9">
          <PatientFilter hideTimeFilter />
        </Col>
      </Row>
    </Container>
  </div>
);

export default PatientPage;
