export enum NumberTypes {
  Mobile = 'mobile',
  Landline = 'landline',
  FixedVoip = 'fixedVoip',
  NonFixedVoip = 'nonFixedVoip',
  Personal = 'personal',
  TollFree = 'tollFree',
  Premium = 'premium',
  SharedCost = 'sharedCost',
  Uan = 'uan',
  Voicemail = 'voicemail',
  Pager = 'pager',
  Unknown = 'unknown',
}
