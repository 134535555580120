import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import {
  XYPlot,
  LineSeries,
  XAxis,
  YAxis,
  DiscreteColorLegend,
  HorizontalGridLines,
  MarkSeries,
} from 'react-vis';
import common from 'constants/common';
import BaseLoader from 'components/atoms/base-loader';
import { useAppSelector } from 'store';

import ChartHint from './chartHint';
import StickyWeightYAxis from './weightStickyYAxis';

const legendItems = [
  {
    title: 'Weight',
    color: common.chartColors.weight.main,
    strokeStyle: 'solid',
  },
  {
    title: 'Weight High',
    color: common.chartColors.weight.high,
    strokeStyle: 'dashed',
  },
  {
    title: 'Weight Low',
    color: common.chartColors.weight.low,
    strokeStyle: 'dashed',
  },
];

const normalizeChartData = (data, thresholds, dates) => {
  const deviceData = data;
  const [startTime, endTime] = dates;
  let weightData = deviceData.map((item) => ({
    value: item.data.weight,
    createdAt: item.createdAt,
  }));
  if (weightData.length < 1) {
    const emptyData = [
      {
        value: null,
        createdAt: startTime.getTime(),
      },
      {
        value: null,
        createdAt: endTime.getTime(),
      },
    ];
    weightData = emptyData;
  }
  const weightLow = weightData.map((item) => ({
    x: item.createdAt,
    y: thresholds.minCriticalWeight,
  }));
  const weightHigh = weightData.map((item) => ({
    x: item.createdAt,
    y: thresholds.maxCriticalWeight,
  }));
  const weightMain = weightData.map((item) => ({
    x: item.createdAt,
    y: item.value,
  }));
  if (weightHigh.length === 1) {
    const xValue = weightHigh[0].x;
    weightHigh.unshift({
      x: xValue - 86400000,
      y: thresholds.maxCriticalWeight,
    });
    weightHigh.push({
      x: parseInt(xValue) + 86400000,
      y: thresholds.maxCriticalWeight,
    });
    weightLow.unshift({
      x: xValue - 86400000,
      y: thresholds.minCriticalWeight,
    });
    weightLow.push({
      x: parseInt(xValue) + 86400000,
      y: thresholds.minCriticalWeight,
    });
  }
  return {
    weight: {
      low: {
        data: weightLow,
        stroke: common.chartColors.weight.low,
        strokeStyle: 'dashed',
      },
      high: {
        data: weightHigh,
        stroke: common.chartColors.weight.high,
        strokeStyle: 'dashed',
      },
      main: {
        data: weightMain,
        stroke: common.chartColors.weight.main,
        strokeStyle: 'solid',
      },
    },
  };
};

const Weight = ({ data, thresholds, loading }) => {
  const dates = useAppSelector((store) => store.patient.dates);
  const isHoveringOverLine = useRef({});
  const [hoveredPoint, setHoveredPoint] = useState(null);
  const [hintType, setHintType] = useState('');
  const yScale = common.chartYaxisScales.weight;
  let chartDate = '';

  if (loading) return <BaseLoader className="loader" loading />;
  const chartData = normalizeChartData(data, thresholds, dates);

  return (
    <div className="chart" data-cy="weight-chart">
      <div className="chart-header">
        <div className="chart-header-title" data-cy="weight-chart-title">
          <p>Weight</p>
          <p>lbs</p>
        </div>
        <DiscreteColorLegend items={legendItems} orientation="horizontal" />
      </div>
      <div className="chart-main">
        <StickyWeightYAxis />
        <XYPlot
          xType="time"
          height={common.chartHeight}
          onMouseLeave={() => {
            setHoveredPoint(null);
          }}
          yDomain={yScale}
          width={common.chartWidth}
        >
          <HorizontalGridLines tickTotal={7} />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => {
              const dayMonth = format(d, 'MMM-dd');
              if (dayMonth !== chartDate) {
                chartDate = dayMonth;
                return format(d, common.dateFormats.chartXDate);
              }
              return '';
            }}
            tickSizeOuter={5}
            tickSizeInner={0}
            tickPadding={24}
          />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => format(d, common.dateFormats.chartXTime)}
            tickTotal={15}
            tickSizeOuter={5}
            tickSizeInner={0}
          />
          <YAxis
            attr="y"
            attrAxis="x"
            orientation="left"
            tickTotal={7}
            className="hidden-axis"
          />
          <LineSeries curve={null} opacity={1} {...chartData.weight.low} />
          <LineSeries curve={null} opacity={1} {...chartData.weight.high} />
          {chartData.weight.main.data[0].y !== null && data.length === 1 && (
            <MarkSeries
              color={common.chartColors.weight.main}
              data={chartData.weight.main.data}
              opacity={1}
              size={2.5}
              onNearestXY={(datapoint) => {
                setHoveredPoint(datapoint);
                setHintType('Weight');
              }}
            />
          )}
          {chartData.weight.main.data[0].y !== null && data.length > 1 && (
            <LineSeries
              curve={null}
              opacity={1}
              {...chartData.weight.main}
              onSeriesMouseOver={() => {
                isHoveringOverLine.current[0] = true;
              }}
              onSeriesMouseOut={() => {
                isHoveringOverLine.current[0] = false;
              }}
              onNearestXY={(datapoint) => {
                if (isHoveringOverLine.current[0]) {
                  setHoveredPoint(datapoint);
                  setHintType('Weight');
                }
              }}
            />
          )}
          {hoveredPoint && (
            <ChartHint hoveredPoint={hoveredPoint} type={hintType} />
          )}
        </XYPlot>
      </div>
    </div>
  );
};

Weight.propTypes = {
  data: PropTypes.array,
  thresholds: PropTypes.shape(),
  loading: PropTypes.bool,
};

export default Weight;
