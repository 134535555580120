import dataService from 'services/dataService';
import { apiDoctorClinicalMetrics } from 'services/dataService/resources';

const INITIAL_STATE = {
  filters: {
    clinicGuid: null,
    childClinic: null,
  },
  data: {},
};

const clinicalMetrics = {
  state: INITIAL_STATE,
  reducers: {
    setClinicalMetricsReport: (state, payload) => ({
      ...state,
      data: payload,
    }),
    setFilters: (state, payload) => ({
      ...state,
      filters: {
        ...state.filters,
        ...payload,
      },
    }),
  },
  effects: (dispatch) => ({
    async getClinicalMetricsReport(payload, state) {
      const { filters } = state.clinicalMetrics;

      const { data } = await dataService.getList(
        apiDoctorClinicalMetrics(filters),
      );
      if (data) {
        dispatch.clinicalMetrics.setClinicalMetricsReport(data || {});
      }
    },
    async applyFilters(filters, state) {
      const { filters: currentFilters } = state.clinicalMetrics;

      Object.keys(filters).forEach((key) => {
        if (filters[key] === undefined) delete filters[key];
        else if (filters[key] === 'all') filters[key] = null;
      });
      dispatch.clinicalMetrics.setFilters({
        ...currentFilters,
        ...filters,
      });
      dispatch.clinicalMetrics.getClinicalMetricsReport();
    },
  }),
};

export default clinicalMetrics;
