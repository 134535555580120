import React from 'react';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import s from './styles.module.scss';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface IDownloadButtonProps extends IBaseComponentProps {
  onClick?: () => void;
}

const DownloadButton: React.FC<IDownloadButtonProps> = (props) => {
  const { className, onClick } = props;

  const { t } = useTranslation();
  const buttonClassNames = classNames(s.btn, className);

  return (
    <button className={buttonClassNames} onClick={onClick}>
      <DownloadRoundedIcon />
      <br />
      <span>{t('controls.download')}</span>
    </button>
  );
};

export default DownloadButton;
