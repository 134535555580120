import { HashLink } from 'react-router-hash-link';
import { useAppDispatch, useAppSelector } from 'store';
import BaseButton from 'components/atoms/baseButton';
import CloseIcon from 'components/atoms/icons/closeIcon';

import s from './styles.module.scss';
import { useMiniNav } from '../hooks/use-mini-nav';

import type { ModuleType } from 'infrastructure/enums';
import type { FC } from 'react';

interface MobileMiniNavProps {
  charts?: Record<string, any[]>;
  module: ModuleType;
}

const scrollWithOffset = (el: HTMLElement, callBack: () => void) => {
  const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
  const yOffset = -110; // header + mini banner height
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  callBack();
};

const MobileMiniNav: FC<MobileMiniNavProps> = ({ module, charts = {} }) => {
  const isOpen = useAppSelector(
    (store) => store.modalWindows.isMobileMiniNavOpen,
  );
  const {
    modalWindows: { toggleOpenMobileMiniNav },
  } = useAppDispatch();
  const { linksList } = useMiniNav({ charts });

  const links = linksList[module];

  return isOpen ? (
    <div className={s.wrapper}>
      <div className={s.links}>
        {links?.map((link) => (
          <HashLink
            smooth
            to={link.to}
            scroll={(el) => scrollWithOffset(el, toggleOpenMobileMiniNav)}
            key={link.key}
          >
            <div className={s.item} data-cy={link.dataCy}>
              {link.icon}
              <p>{link.title}</p>
            </div>
          </HashLink>
        ))}
      </div>
      <BaseButton
        icon={<CloseIcon />}
        type="secondary"
        onClick={toggleOpenMobileMiniNav}
      />
    </div>
  ) : null;
};
export default MobileMiniNav;
