import React from 'react';
import { useSelector } from 'react-redux';
import { showResult } from 'infrastructure/hooks/utils';

import withLoader from '../../organisms/withLoader';

import type { RootState } from 'store';

type Props = {
  attachmentGuid: string;
  patientGuid: string;
  deleteAttachment: (data: any) => { error: any };
  setShowDelete: (data: boolean) => void;
};

const AttachmentDelete: React.FC<Props> = ({
  attachmentGuid,
  patientGuid,
  deleteAttachment,
  setShowDelete,
}) => {
  const deleteLoading = useSelector(
    (state: RootState) => state.loading.effects.attachments.deleteAttachment,
  );

  const handleDelete = async () => {
    const { error } = await deleteAttachment({
      patientGuid,
      guid: attachmentGuid,
    });
    if (error) {
      showResult(error);
    } else {
      showResult();
    }
  };

  return (
    <div className="attachment-delete">
      <p>Are you sure you want to delete this file?</p>
      {withLoader(
        deleteLoading,
        <div className="attachment-delete-buttons">
          <button onClick={handleDelete} data-cy="attachment-delete-button">
            Delete
          </button>
          <button
            onClick={() => setShowDelete(false)}
            data-cy="attachment-cancel-button"
          >
            Cancel
          </button>
        </div>,
      )}
    </div>
  );
};

export default AttachmentDelete;
