import SearchIconThin from 'components/atoms/icons/searchIconThin';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { FC } from 'react';

interface Props {
  isLoading?: boolean;
}

const EmptyTable: FC<Props> = ({ isLoading = false }) => {
  const { t } = useTranslation();
  return (
    <div className={s['empty-table-wrap']}>
      <SearchIconThin />
      {!isLoading && (
        <div className={s['no-result']}>{t('messages.emptySearchResult')}</div>
      )}
    </div>
  );
};

export default EmptyTable;
