import common from 'constants/common';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { isHtmlEmpty } from 'utils';
import classNames from 'classnames';
import { format } from 'date-fns';
import { parseDateSafe } from 'utils/timeHelper';
import ChevronRightIcon from 'components/atoms/icons/chevronRight';
import { useTranslation } from 'react-i18next';
import AdminCommentIcon from 'components/atoms/icons/adminCommentIcon';
import BaseTabs from 'components/atoms/base-tabs';
import KeyNoteAddIcon from 'components/atoms/icons/keyNoteAddIcon';
import KeyNoteEditIcon from 'components/atoms/icons/keyNoteEditIcon';
import { ModuleType } from 'infrastructure/enums';
import BaseLoader from 'components/atoms/base-loader';

import ExpandButton from './expandButton';
import s from './styles.module.scss';
import EditMode from './editMode';
import KeyNotesContent from './keyNotesContent';
import { usSaveKeyNote } from './hooks/use-save-key-note';
import { usGetKeyNote } from './hooks/use-get-key-note';

interface IAdminPatientKeyNotesProps {
  patientGuid: string;
  module?: ModuleType;
  canChangeModule?: boolean;
}

const AdminPatientKeyNotes: React.FC<IAdminPatientKeyNotesProps> = ({
  patientGuid,
  module,
  canChangeModule = true,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [currentModule, setCurrentModule] = useState<ModuleType>(
    module ?? ModuleType.RPM,
  );
  const [activeTab, setActiveTab] = useState(0);

  const [
    { data: rpmData, isFetching: rpmLoading },
    { data: ccmData, isFetching: ccmLoading },
  ] = usGetKeyNote({
    module: currentModule,
    patientGuid,
    canChangeModule,
  });

  const data = currentModule === 'rpm' ? rpmData : ccmData;
  const loading = rpmLoading || ccmLoading;

  const isDataEmpty = useMemo(
    () => isHtmlEmpty(data?.note && data.note),
    [data],
  );
  const closeEditMode = () => setIsEditMode(false);

  const { saveNote, isLoading } = usSaveKeyNote({
    onSuccess() {
      closeEditMode();
    },
    module: currentModule,
    patientGuid,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (canChangeModule) {
      activeTab === 1
        ? setCurrentModule(ModuleType.CCM)
        : setCurrentModule(ModuleType.RPM);
    }
  }, [activeTab]);

  const scrollableElementRef = useRef<HTMLDivElement | null>(null);

  const formattedUpdatedAt = useMemo(() => {
    const date = parseDateSafe(data?.updatedAt);
    if (!date) return null;
    return format(date, common.dateFormats.dateAndTime);
  }, [data]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    setIsEditMode(false);
    if (scrollableElementRef && scrollableElementRef.current) {
      scrollableElementRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const tabs = useMemo(
    () =>
      [
        { key: ModuleType.RPM, label: t('tabs.rpm') },
        { key: ModuleType.CCM, label: t('tabs.ccm') },
      ].filter((x) => canChangeModule || x.key === currentModule),
    [module, canChangeModule],
  );

  return (
    <div
      data-edit-mode={isEditMode}
      data-cy={s['admin-patient-key-note-wrapper']}
      className={classNames(s['admin-patient-key-note-wrapper'], {
        [s.expanded]: expanded,
      })}
    >
      <div className={s['key-note-wrapper']}>
        <div className={s['key-note-header']}>
          <div className={s['header-content']}>
            <span className={s['comment-icon']}>
              <AdminCommentIcon />
            </span>
            <span className={s['minified-hidden']}>{t('labels.keyNote')}</span>
            <span className={classNames(s['minified-hidden'], s['arrow-icon'])}>
              <ChevronRightIcon />
            </span>
          </div>
        </div>
        <div className={s['key-note-body']}>
          <div className={s['body-header']}>
            <BaseTabs
              tabs={tabs}
              onChange={(_tab, index) => handleTabClick(index)}
              size="small"
              defaultIndex={activeTab}
            />
          </div>
          {loading ? (
            <BaseLoader loading />
          ) : (
            <div className={s['body-content']} ref={scrollableElementRef}>
              {isEditMode ? (
                <EditMode
                  loading={isLoading}
                  defaultValue={data?.note || ''}
                  onSubmit={(note) => {
                    saveNote({
                      patientGuid,
                      module: currentModule,
                      note,
                    });
                  }}
                  onCancel={() => setIsEditMode(false)}
                />
              ) : (
                <KeyNotesContent
                  isDataEmpty={isDataEmpty}
                  data={data}
                  formattedUpdatedAt={formattedUpdatedAt}
                />
              )}
            </div>
          )}
          <div
            className={classNames(s['body-actions'], {
              [s['edit-mode']]: isEditMode,
            })}
          >
            <ExpandButton
              dependencies={[rpmData, ccmData, currentModule]}
              scrollableElementRef={scrollableElementRef}
              expanded={expanded}
              setExpanded={setExpanded}
            />
            {!isEditMode && !loading && (
              <>
                {isDataEmpty ? (
                  <div
                    className={s['edit-button']}
                    data-cy="keyNoteAddBtn"
                    onClick={() => setIsEditMode(true)}
                  >
                    <KeyNoteAddIcon />
                  </div>
                ) : (
                  <div
                    className={s['edit-button']}
                    data-cy="keyNoteEditBtn"
                    onClick={() => setIsEditMode(true)}
                  >
                    <KeyNoteEditIcon />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPatientKeyNotes;
