import { API_ADMIN_PANEL_AGENCIES } from 'services/dataService/resources';
import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import QueryParams from 'utils/queryParams';

export interface Agency {
  guid: string;
  legalname: string;
  email?: string | null;
  phone?: string | null;
  fax?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  npiNumber?: string | null;
  stateIdNumber?: string | null;
  taxIdNumber?: string | null;
  taxonomyNumber?: string | null;
  medicalIdTpiNumber?: string | null;
  medicareNumber?: string | null;
  agencyId?: string | null;
  numberType: string;
  createdAt: string;
  createdBy?: string | null;
}

export interface Data {
  items: Agency[];
  count: number;
}

type Filter = {
  noLimit?: boolean;
};

const useAgencies = ({ noLimit }: Filter) => {
  const filterString = QueryParams.stringify({ noLimit });

  const { data, error, isLoading } = useQuery({
    queryKey: [API_ADMIN_PANEL_AGENCIES, filterString],
    queryFn: async () => {
      const { error: localError, data: localData } =
        await dataService.getList<Data>(
          `${API_ADMIN_PANEL_AGENCIES}?${filterString}`,
        );
      if (localError) {
        throw new Error(localError);
      }
      return localData;
    },
  });

  return {
    agencies: data?.items,
    isLoading,
    error,
    count: data?.count,
  };
};

export default useAgencies;
