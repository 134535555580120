import React from 'react';
import PropTypes from 'prop-types';
import { startOfWeek } from 'date-fns';

const markReadingsCount = (curr, prev) =>
  curr < prev ? (
    <span className="statistics-report__table-val--red">{curr}</span>
  ) : (
    curr
  );

const CompareWeeks = ({
  current: currentWeek,
  previous: previousWeek,
  percent = false,
}) => {
  const readings = percent ? 'readingsPercentageIncrease' : 'readingsCount';

  return currentWeek[readings]?.map((currentCount, countDay) => {
    const today = new Date();
    const isPresentWeek = new Date(currentWeek.weekDate) >= startOfWeek(today);
    const previousCount = previousWeek?.[readings]?.[countDay];
    let count = Math.round(currentCount * 10) / 10;

    if (percent) count = currentCount === null ? '-' : `${count}%`;

    if (isPresentWeek && countDay > today.getDay())
      return <td key={countDay} />;

    return (
      <td key={countDay}>
        {!percent ? markReadingsCount(currentCount, previousCount) : count}
      </td>
    );
  });
};

CompareWeeks.propTypes = {
  current: PropTypes.shape({
    weekDate: PropTypes.string,
    readingsCount: PropTypes.array,
    readingsPercentageIncrease: PropTypes.array,
  }),
  previous: PropTypes.shape({
    weekDate: PropTypes.string,
    readingsCount: PropTypes.array,
    readingsPercentageIncrease: PropTypes.array,
  }),
  percent: PropTypes.bool,
};

export default CompareWeeks;
