import React from 'react';
import { Container, Row, Card, CardBody } from 'reactstrap';
import {
  InfoTiles,
  Statistics,
} from 'components/templates/rpm/practice/statisticsRerport';
import dataService from 'services/dataService';
import {
  API_YESTERDAYS_STATS_INFO_TILES,
  apiYesterdaysStatsReadings,
} from 'services/dataService/resources';
import withLoader from 'components/organisms/withLoader';
import useFetch from 'utils/useFetch';

const PER_PAGE = 4;

const StatisticsReport = () => {
  const { data: infoTiles, loading: infoTilesLoading } = useFetch(() =>
    dataService.getList(API_YESTERDAYS_STATS_INFO_TILES),
  );
  const {
    data: readingsStats,
    loading: readingsStatsLoading,
    page: tablePage,
    setPage,
  } = useFetch((page) =>
    dataService.getList(apiYesterdaysStatsReadings(PER_PAGE, page)),
  );

  return (
    <Container className="statistics-report">
      <Row>
        <Card className="card-border card--full-width card--margin-bottom">
          <CardBody className="info-tiles card-outer">
            {withLoader(infoTilesLoading, <InfoTiles infos={infoTiles} />)}
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Card className="card-border card--full-width">
          <CardBody className="card-outer">
            {withLoader(
              readingsStatsLoading,
              <Statistics
                readingsCount={readingsStats?.items}
                page={tablePage}
                itemsPerPage={PER_PAGE}
                count={readingsStats?.count}
                setPage={setPage}
              />,
            )}
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default StatisticsReport;
