import React from 'react';
import PropTypes from 'prop-types';
import ArrowIcon from 'components/atoms/icons/arrowIcon';
import { Card, CardBody } from 'reactstrap';
import PercentIcon from 'components/atoms/icons/percentIcon';
import { XYPlot, LineSeries } from 'react-vis';
import classNames from 'classnames';
import common from 'constants/common';

const StatsCard = ({
  title,
  subTitle,
  value,
  lastCreatedDateCount,
  compare,
  chartValues,
  percentIcon,
  changeColor,
}) => {
  let colorClass = '';
  let arrowColor = '';
  const strokeColor = common.commonColors.green;
  if (compare < 0) {
    colorClass = 'down';
    arrowColor = 'red';
  }
  if (compare > 0) {
    colorClass = 'up';
    arrowColor = 'green';
  }
  const arrowClass = classNames(
    'stats-card-values-changedStats-arrow',
    colorClass,
  );
  const changeClass = classNames(
    'stats-card-values-changedStats-changed',
    changeColor ? colorClass : '',
  );
  return (
    <Card className="card-border stats-card">
      <CardBody className="clinical-metrics">
        <div className="stats-card">
          {chartValues && (
            <div className="stats-card-chart">
              <XYPlot height={80} width={150} stroke={strokeColor}>
                <LineSeries data={chartValues} />
              </XYPlot>
            </div>
          )}
          <div className="stats-card-header">
            <div className="stats-card-title">{title}</div>
            <div className="stats-card-subtitle">{subTitle}</div>
          </div>
          <div className="stats-card-values">
            <div className="stats-card-values-value">{value}</div>
            <div className="stats-card-values-changedStats">
              <div className={arrowClass}>
                {!percentIcon && (
                  <ArrowIcon color={changeColor ? arrowColor : ''} />
                )}
                {percentIcon && <PercentIcon />}
              </div>
              <div className={changeClass}>{lastCreatedDateCount}</div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

StatsCard.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  lastCreatedDateCount: PropTypes.number.isRequired,
  compare: PropTypes.number.isRequired,
  chartValues: PropTypes.array,
  percentIcon: PropTypes.bool,
  changeColor: PropTypes.bool,
};

export default StatsCard;
