import type { FC, SVGProps } from 'react';

const CallControlIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5922 4.62403C9.62437 -0.473549 16.3707 -1.50509 20.6809 2.27339C24.9911 6.05187 25.2201 16.4803 20.6809 21.0786C16.1416 25.6768 5.67952 24.2016 2.11701 21.0786C-1.4455 17.9555 -0.439978 9.72162 4.5922 4.62403Z"
        fill="#FB9318"
      />
      <path
        d="M8.7404 6.99637C8.55257 6.75487 8.19559 6.73265 7.97925 6.94898L7.20381 7.72442C6.8412 8.08703 6.7079 8.60074 6.86633 9.05135C7.50253 10.8609 8.54411 12.56 9.99204 14.008C11.44 15.4559 13.1391 16.4975 14.9486 17.1337C15.3993 17.2921 15.913 17.1588 16.2756 16.7962L17.051 16.0207C17.2674 15.8044 17.2451 15.4474 17.0036 15.2596L15.274 13.9143C15.1506 13.8184 14.99 13.7845 14.8384 13.8224L13.1966 14.2328C12.7506 14.3443 12.2789 14.2136 11.9538 13.8886L10.1114 12.0462C9.78637 11.7211 9.6557 11.2494 9.76719 10.8034L10.1776 9.16165C10.2155 9.01002 10.1816 8.84938 10.0857 8.72601L8.7404 6.99637ZM7.41358 6.38331C7.97017 5.82672 8.88862 5.8839 9.37187 6.50523L10.7172 8.23487C10.964 8.55228 11.0513 8.96556 10.9537 9.35567L10.5433 10.9975C10.5 11.1708 10.5507 11.3542 10.6771 11.4805L12.5195 13.3229C12.6458 13.4493 12.8292 13.5 13.0025 13.4567L14.6443 13.0463C15.0344 12.9487 15.4477 13.036 15.7651 13.2829L17.4948 14.6281C18.1161 15.1114 18.1733 16.0298 17.6167 16.5864L16.8413 17.3619C16.2865 17.9167 15.4567 18.1603 14.6833 17.8884C12.7632 17.2133 10.9608 16.1081 9.42637 14.5736C7.89195 13.0392 6.78671 11.2368 6.11163 9.31669C5.83974 8.54334 6.08335 7.71354 6.63814 7.15875L7.41358 6.38331Z"
        fill="white"
      />
    </svg>
  );
};

export default CallControlIcon;
