import React from 'react';
import common from 'constants/common';

const Status = ({
  status,
  category,
}: {
  status?: string;
  category: string;
}) => {
  const titles =
    (
      common as {
        [key: string]: any;
      }
    )[category] || common.statusNames;

  const color =
    status && titles && titles[status] ? titles[status].color : '#61B753';

  const label =
    status && titles && titles[status] ? titles[status].label : 'Undefined';

  return (
    <div
      className="status"
      style={{
        color: `${color}`,
      }}
    >
      <p className="status-label" style={{ color }}>
        {label}
      </p>
    </div>
  );
};

export default Status;
