import React from 'react';
import AdminPatientInfoForm from 'components/molecules/admin-panel/forms/patient-info';
import AdminPanelAccountInfoForm from 'components/molecules/admin-panel/forms/account-info';
import AdminPanelContactInfoForm from 'components/molecules/admin-panel/forms/contact-info';
import AdminPanelAccountHubForm from 'components/molecules/admin-panel/forms/account-hub';
import BaseGrid from 'components/atoms/base-grid';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import ContactAvailabilityCombinedForm from 'components/molecules/contact-availability/combined-form';

import s from './styles.module.scss';

import type { Patient } from 'infrastructure/classes/patient/patient';
import type { ModuleType } from 'infrastructure/enums';

interface IAdminTabbedFormPatientInfoProps {
  patientGuid: string;
  module: ModuleType;
  readOnly: boolean;
}

const AdminTabbedFormPatientInfo: React.FC<IAdminTabbedFormPatientInfoProps> = (
  props,
) => {
  const { patientGuid, module, readOnly } = props;

  const { loading, patientInfo = {} as Patient } = usePatientInfo({
    patientGuid,
  });

  return (
    <BaseGrid rowGap={5} className={s.wrapper}>
      <div className={s['border-bottom']}>
        <BaseGrid columns={3} columnGap={20}>
          <div className={s['border-right']}>
            <AdminPatientInfoForm
              data={patientInfo}
              loading={loading}
              readOnly={readOnly}
            />
          </div>
          <div className={s['border-right']}>
            <div className={s['border-bottom']}>
              <AdminPanelAccountInfoForm
                data={patientInfo}
                loading={loading}
                module={module}
              />
            </div>
            <AdminPanelAccountHubForm
              data={patientInfo}
              loading={loading}
              module={module}
            />
          </div>
          <AdminPanelContactInfoForm
            data={patientInfo}
            loading={loading}
            readOnly={readOnly}
          />
        </BaseGrid>
      </div>
      <ContactAvailabilityCombinedForm
        patientGuid={patientGuid}
        readOnly={readOnly}
      />
    </BaseGrid>
  );
};

export default AdminTabbedFormPatientInfo;
