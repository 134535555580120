import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';

const searchMedication = async (searchValue: string) => {
  const url = `core/medications/search?query=${searchValue.trim()}`;

  const { data, error } = await dataService.getList<string[]>(url);

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useSearchMedication = () => {
  const { mutate, data, isLoading } = useMutation({
    mutationFn: searchMedication,
  });
  return { search: mutate, data, isLoading };
};
