import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { weekDaysDataSource } from 'infrastructure/data-sources/week-days';
import {
  convertDateToTimeZone,
  convertTimeToTimeZone,
  DateFormatter,
} from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import { between } from 'infrastructure/functions/between';

import s from './styles.module.scss';

import type { InteractiveTimeItem } from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface IProps {
  height: number;
  availabilities: InteractiveTimeItem['contactAvailabilities'];
  timezone: string;
}

const ContactAvailabilityColBody: React.FC<IProps> = (props) => {
  const { height, availabilities, timezone } = props;

  const { t } = useTranslation();

  const weekDays = weekDaysDataSource();
  const [currentPatientDate, setCurrentPatientDate] = useState(
    convertDateToTimeZone(new Date(), timezone),
  );

  const anytime = !availabilities.length;

  const isAvailable = useMemo(() => {
    return availabilities.some((el) => {
      const startTime = convertTimeToTimeZone(el.startTime, timezone).getTime();
      const endTime = convertTimeToTimeZone(el.endTime, timezone).getTime();
      const time = currentPatientDate.getTime();

      return (
        between(time, startTime, endTime) &&
        currentPatientDate.getDay() === el.day
      );
    });
  }, [availabilities, currentPatientDate]);

  const availableText = isAvailable
    ? t('labels.available')
    : t('labels.notAvailable');

  const statusText = anytime ? t('labels.anytime') : availableText;

  const timeClassNames = classNames(s.time, {
    [s.green]: isAvailable || anytime,
  });
  const bodyClassNames = classNames(s.body, {
    [s.anytime]: anytime,
  });

  const convertTime = (date: Date) => {
    return DateFormatter({
      date,
      format: DateFormats['h:mm a'],
    });
  };

  const groupedTimes = useMemo(() => {
    return weekDays
      .map((day, index) => {
        return {
          ...day,
          times: availabilities
            .filter((d) => d.day === index)
            .sort((a, b) => {
              const at = convertTimeToTimeZone(a.startTime, timezone).getTime();
              const bt = convertTimeToTimeZone(b.endTime, timezone).getTime();
              return at - bt;
            })
            .map((time) => {
              const startDate = convertTimeToTimeZone(time.startTime, timezone);
              const endDate = convertTimeToTimeZone(time.endTime, timezone);
              return `${convertTime(startDate)} - ${convertTime(endDate)};`;
            }),
        };
      })
      .filter((el) => el.times.length);
  }, [availabilities, weekDays]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentPatientDate(convertDateToTimeZone(new Date(), timezone));
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={bodyClassNames}>
      <div
        className={s.header}
        style={{
          height: `${height}px`,
        }}
      >
        <span className={s.title}>{t('labels.contactAvailability')}</span>
        <span className={timeClassNames}>
          {`${convertTime(currentPatientDate)} (${timezone}) – ${statusText}`}
        </span>
      </div>
      {!anytime && (
        <ul className={s.list}>
          {groupedTimes.map((el, i) => (
            <li
              key={i}
              style={{
                alignItems: el.times.length > 2 ? 'flex-start' : 'center',
              }}
            >
              <div className={s.day}>
                <span>{el.name.substring(0, 3)}</span>
              </div>
              <div className={s.times}>
                {el.times.length ? (
                  el.times.map((time, e) => (
                    <div key={e} className={s.time}>
                      {time}
                    </div>
                  ))
                ) : (
                  <div className={s.time}>
                    {anytime ? t('labels.anytime') : t('labels.notAvailable')}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ContactAvailabilityColBody;
