import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import { DateFormats, PresetKeyEnum } from 'infrastructure/enums';
import classNames from 'classnames';
import {
  DoubleRightOutlined,
  DoubleLeftOutlined,
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';
import BaseButton from '../baseButton';
import ArrowRightIcon from '../icons/arrow-right';

import type { SizeType } from '../base-select-box/types';
import type { DatePickerProps, GetProps } from 'antd';
import type { ICommonInputProps } from 'infrastructure/interfaces';

const DatePicker = generatePicker(dateFnsGenerateConfig);
const { RangePicker } = DatePicker;

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

type IValue = [Date, Date] | undefined;
type TNotUndefinedValue = [start: Date | null, end: Date | null];

interface BaseInfo {
  range?: 'start' | 'end';
}

interface IPresets {
  label: React.ReactNode;
  value: IValue;
  key?: string;
}

interface IBaseInputProps
  extends Omit<ICommonInputProps<[string, string]>, 'placeholder'> {
  format?: DateFormats;
  onChange?: (date: IValue, dateString: [string, string]) => void;
  placeholder?: [string, string];
  withPresets?: boolean;
  presets?: Array<IPresets>;
  picker?: DatePickerProps['picker'];
  panelRender?: ((originPanel: React.ReactNode) => React.ReactNode) | undefined;
  showExtraFooter?: boolean;
  open?: boolean;
  onApply?: () => void;
  setOpen?: (arg: boolean) => void;
  defaultValue?: [Date | string, Date | string];
  size?: SizeType;
  onCalendarChange?: (
    date: TNotUndefinedValue,
    dateString: [string, string],
    info: BaseInfo,
    presetKey?: string,
  ) => void;
  disabledDate?: RangePickerProps['disabledDate'];
  isMaxDayToday?: boolean;
}

type InputProps = Omit<IBaseInputProps, 'value'>;

const prepareDefaultValue = (
  value: string | Date,
  format: DateFormats,
): Date => {
  if (typeof value === 'string') {
    return dayjs(value, format).toDate();
  }
  return value;
};

const BaseRangeDatepicker: React.FC<InputProps> = (props) => {
  const { t } = useTranslation();

  const {
    disabled,
    plaintext,
    readonly,
    invalid,
    onChange,
    className,
    style,
    placeholder = [t('labels.startDate'), t('labels.endDate')],
    format = DateFormats['MM-DD-YYYY'],
    withPresets,
    presets,
    panelRender,
    picker = 'date',
    showExtraFooter,
    open,
    setOpen,
    onApply,
    defaultValue,
    size,
    onCalendarChange,
    disabledDate,
    isMaxDayToday,
  } = props;

  const ref = useRef<any>(null);

  const [localPlaceholder, setLocalPlaceholder] = useState(placeholder);

  const selectBoxClassNames = classNames(
    {
      [s.readonly]: readonly,
    },
    className,
  );

  const preparedValue: [Date, Date] | undefined = defaultValue
    ? [
        prepareDefaultValue(defaultValue[0], format),
        prepareDefaultValue(defaultValue[1], format),
      ]
    : undefined;

  const today = new Date();

  const rangePresets: IBaseInputProps['presets'] = presets || [
    {
      label: (
        <span data-cy="ranges">
          {t('dates.lastCountDays', {
            count: 7,
          })}
        </span>
      ),
      value: [dayjs().add(-7, 'd').toDate(), today],
      key: PresetKeyEnum.LastSevenDays,
    },
    {
      label: (
        <span data-cy="ranges">
          {t('dates.lastCountDays', {
            count: 14,
          })}
        </span>
      ),
      value: [dayjs().add(-14, 'd').toDate(), today],
      key: PresetKeyEnum.LastFourteenDays,
    },
    {
      label: (
        <span data-cy="ranges">
          {t('dates.lastCountHours', {
            count: 24,
          })}
        </span>
      ),
      value: [dayjs().add(-24, 'h').toDate(), today],
      key: PresetKeyEnum.LastTwentyFourDays,
    },
    {
      label: (
        <span data-cy="ranges">
          {t('dates.lastCountHours', {
            count: 4,
          })}
        </span>
      ),
      value: [dayjs().add(-4, 'h').toDate(), today],
      key: PresetKeyEnum.LastFourHours,
    },
    {
      label: <span data-cy="ranges">{t('dates.currentMonth')}</span>,
      value: [
        dayjs().startOf('M').toDate(),
        isMaxDayToday ? today : dayjs().endOf('M').toDate(),
      ],
      key: PresetKeyEnum.CurrentMonth,
    },
    {
      label: <span data-cy="ranges">{t('dates.previousMonth')}</span>,
      value: [
        dayjs().add(-1, 'M').startOf('M').toDate(),
        dayjs().add(-1, 'M').endOf('M').toDate(),
      ],
      key: PresetKeyEnum.PreviousMonth,
    },
    {
      label: <span data-cy="ranges">{t('dates.twoMonthsPast')}</span>,
      value: [
        dayjs().add(-2, 'M').startOf('M').toDate(),
        dayjs().add(-2, 'M').endOf('M').toDate(),
      ],
      key: PresetKeyEnum.TwoMonthsPast,
    },
    {
      label: <span data-cy="ranges">{t('dates.threeMonthsPast')}</span>,
      value: [
        dayjs().add(-3, 'M').startOf('M').toDate(),
        dayjs().add(-3, 'M').endOf('M').toDate(),
      ],
      key: PresetKeyEnum.ThreeMonthsPast,
    },
  ];

  const setDataCy = () => {
    if (ref.current) {
      const monthBtn = document.querySelectorAll('.ant-picker-month-btn');
      const yearBtn = document.querySelectorAll('.ant-picker-year-btn');
      if (monthBtn.length) {
        monthBtn.forEach((el) => {
          el.setAttribute('data-cy', 'range-picker-month-btn');
        });
      }
      if (yearBtn.length) {
        yearBtn.forEach((el) => {
          el.setAttribute('data-cy', 'range-picker-year-btn');
        });
      }
    }
  };

  const wrapOnCalendarChange = (
    dates: TNotUndefinedValue,
    dateString: [string, string],
    info: BaseInfo,
  ) => {
    if (!dates[0] || !dates[1]) {
      onCalendarChange?.(dates, dateString, info);
      return;
    }

    const preset = rangePresets.find((p) => {
      if (!p.value?.[0] || !p.value?.[1]) return;

      return (
        dayjs(dates[0]).isSame(p.value[0], 'minute') &&
        dayjs(dates[1]).isSame(p.value[1], 'minute')
      );
    });

    const presetKey = preset ? preset?.key : undefined;

    onCalendarChange?.(dates, dateString, info, presetKey);
  };

  return (
    <RangePicker
      ref={ref}
      onOpenChange={setDataCy}
      format={{
        format,
        type: 'mask',
      }}
      style={{ width: '100%', ...style }}
      placeholder={localPlaceholder}
      defaultValue={preparedValue}
      disabled={disabled}
      className={selectBoxClassNames}
      onChange={onChange as any}
      variant={plaintext ? 'borderless' : 'outlined'}
      status={invalid ? 'error' : undefined}
      presets={withPresets ? (rangePresets as any) : undefined}
      picker={picker}
      nextIcon={<RightOutlined data-cy="next" />}
      superNextIcon={<DoubleRightOutlined data-cy="super-next" />}
      prevIcon={<LeftOutlined data-cy="prev" />}
      superPrevIcon={<DoubleLeftOutlined data-cy="super-prev" />}
      separator={<ArrowRightIcon />}
      onFocus={() => {
        setLocalPlaceholder([format, format]);
      }}
      onBlur={() => {
        setLocalPlaceholder(placeholder);
      }}
      cellRender={(val, info) => {
        return React.cloneElement(info.originNode, {
          'data-cy': `date-picker-${info.type}-${info.originNode.props.children}`,
          style: {
            borderRadius: info.type === 'date' ? '50%' : 0,
          },
        });
      }}
      panelRender={panelRender}
      renderExtraFooter={() => {
        return showExtraFooter ? (
          <div className={s['extra-footer-wrapper']}>
            <BaseButton
              dataCy="close-time-filter"
              label={t('controls.cancel')}
              onClick={() => setOpen?.(false)}
              type="secondary"
            />
            <BaseButton
              dataCy="apply-time-filter"
              label={t('controls.apply')}
              onClick={onApply}
            />
          </div>
        ) : undefined;
      }}
      open={open}
      onClick={() => setOpen?.(true)}
      size={size}
      onCalendarChange={wrapOnCalendarChange}
      disabledDate={disabledDate}
    />
  );
};

export const BaseRangeDatepickerDisplayName = 'BaseRangeDatepicker';
BaseRangeDatepicker.displayName = BaseRangeDatepickerDisplayName;

export default BaseRangeDatepicker;
