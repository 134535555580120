import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminTurkUsers from 'components/templates/admin/panel/turkUsers';

const AdminTurkUsersPage = () => {
  return (
    <Container>
      <AdminHeader />
      <AdminTurkUsers />
    </Container>
  );
};

export default AdminTurkUsersPage;
