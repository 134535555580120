import classNames from 'classnames';

import s from './styles.module.scss';

import type { FC } from 'react';

interface PatientStatusRowProps {
  isActiveLabel: boolean;
  isActiveValue: boolean;
  label: string;
  value: string;
  color?: string;
}

const PatientStatusRow: FC<PatientStatusRowProps> = ({
  isActiveLabel,
  isActiveValue,
  label,
  value,
  color,
}) => {
  const dataCy = label.toLowerCase().split(' ').join('-');

  return (
    <div className={s.row}>
      <p
        className={classNames(s.label, {
          [s.active]: isActiveLabel,
        })}
        data-cy={`${dataCy}-label`}
      >
        {label}:
      </p>
      <p
        className={classNames(s.value, {
          [s.active]: isActiveValue,
        })}
        style={{ color: isActiveValue ? color : undefined }}
        data-cy={`${dataCy}-value`}
      >
        {value}
      </p>
    </div>
  );
};
export default PatientStatusRow;
