import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const contactAvailabilityFilterValidationSchema = () =>
  createValidationSchema({
    active: commonValidationSchemas.boolean(),
    startTime: commonValidationSchemas.string({
      when: {
        fields: ['active'],
        is: (active) => active,
        then: {
          required: true,
        },
      },
    }),
    endTime: commonValidationSchemas.string({
      when: {
        fields: ['active'],
        is: (active) => active,
        then: {
          required: true,
        },
      },
    }),
  });
