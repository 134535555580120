const ConversationEmptyIcon = (props: any) => (
  <svg
    width="350"
    height="350"
    viewBox="0 0 350 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8638_151160)">
      <path
        d="M224.969 174.461C252.579 146.851 252.579 102.087 224.969 74.4768C197.359 46.867 152.595 46.867 124.985 74.4768C97.3753 102.087 97.3753 146.851 124.985 174.461C152.595 202.071 197.359 202.071 224.969 174.461Z"
        fill="#F2F2FB"
      />
      <path
        d="M250.11 267.26H96.7403C94.5003 267.26 92.6104 265.44 92.6104 263.13C92.6104 260.89 94.4303 259 96.7403 259H250.11C252.35 259 254.24 260.82 254.24 263.13C254.17 265.44 252.35 267.26 250.11 267.26Z"
        fill="#FFF4E8"
      />
      <path
        d="M68.7405 88.0595L70.0005 88.4795C70.5605 88.6895 70.5605 89.5295 70.0005 89.6695L68.7405 90.0895C67.1305 90.5795 65.8705 91.8395 65.3805 93.4495L64.9605 94.7095C64.7505 95.2695 63.9105 95.2695 63.7705 94.7095L63.3505 93.4495C62.8605 91.8395 61.6005 90.5795 59.9905 90.0895L58.7305 89.6695C58.1705 89.4595 58.1705 88.6195 58.7305 88.4795L59.9905 88.0595C61.6005 87.5695 62.8605 86.3095 63.3505 84.6995L63.7705 83.4395C63.9805 82.8795 64.8205 82.8795 64.9605 83.4395L65.3805 84.6995C65.8705 86.3095 67.1305 87.5695 68.7405 88.0595Z"
        fill="white"
      />
      <path
        d="M251.37 80.8496L252.63 81.2696C253.19 81.4796 253.19 82.3196 252.63 82.4596L251.37 82.8796C249.76 83.3696 248.5 84.6296 248.01 86.2396L247.59 87.4996C247.38 88.0596 246.54 88.0596 246.4 87.4996L245.98 86.2396C245.49 84.6296 244.23 83.3696 242.62 82.8796L241.36 82.4596C240.8 82.2496 240.8 81.4096 241.36 81.2696L242.62 80.8496C244.23 80.3596 245.49 79.0996 245.98 77.4896L246.4 76.2296C246.61 75.6696 247.45 75.6696 247.59 76.2296L248.01 77.4896C248.57 79.0996 249.76 80.2896 251.37 80.8496Z"
        fill="white"
      />
      <path
        d="M334.6 178.219L335.37 178.429C335.72 178.569 335.72 178.989 335.37 179.129L334.6 179.339C333.69 179.619 332.92 180.389 332.64 181.299L332.43 182.069C332.29 182.419 331.87 182.419 331.73 182.069L331.52 181.299C331.24 180.389 330.47 179.619 329.56 179.339L328.79 179.129C328.44 178.989 328.44 178.569 328.79 178.429L329.56 178.219C330.47 177.939 331.24 177.169 331.52 176.259L331.73 175.489C331.87 175.139 332.29 175.139 332.43 175.489L332.64 176.259C332.92 177.169 333.62 177.939 334.6 178.219Z"
        fill="white"
      />
      <path
        d="M41.7903 227.43L42.5603 227.64C42.9103 227.78 42.9103 228.2 42.5603 228.34L41.7903 228.55C40.8803 228.83 40.1103 229.6 39.8303 230.51L39.6203 231.28C39.4803 231.63 39.0603 231.63 38.9203 231.28L38.7103 230.51C38.4303 229.6 37.6603 228.83 36.7503 228.55L35.9803 228.34C35.6303 228.2 35.6303 227.78 35.9803 227.64L36.7503 227.43C37.6603 227.15 38.4303 226.38 38.7103 225.47L38.9203 224.7C39.0603 224.35 39.4803 224.35 39.6203 224.7L39.8303 225.47C40.1803 226.38 40.8803 227.15 41.7903 227.43Z"
        fill="white"
      />
      <path
        d="M262.57 221.06H84.28C81.13 221.06 78.54 218.47 78.54 215.32V99.4002C78.54 96.2502 81.13 93.6602 84.28 93.6602H262.57C265.72 93.6602 268.31 96.2502 268.31 99.4002V215.32C268.31 218.47 265.72 221.06 262.57 221.06Z"
        fill="#2E344B"
      />
      <path
        d="M259.63 214.97H87.15C85.54 214.97 84.21 213.64 84.21 212.03V102.69C84.21 101.08 85.54 99.75 87.15 99.75H259.63C261.24 99.75 262.57 101.08 262.57 102.69V211.96C262.64 213.64 261.31 214.97 259.63 214.97Z"
        fill="#FFF8F3"
      />
      <path
        d="M259.84 99.75H87.01C85.47 99.75 84.21 101.01 84.21 102.55V106.26H262.64V102.55C262.64 101.01 261.38 99.75 259.84 99.75Z"
        fill="#8EADD5"
      />
      <path
        d="M253.82 104.58H257.88C258.37 104.58 258.79 104.16 258.79 103.67V102.13C258.79 101.64 258.37 101.22 257.88 101.22H253.82C253.33 101.22 252.91 101.64 252.91 102.13V103.67C252.91 104.16 253.33 104.58 253.82 104.58Z"
        fill="white"
      />
      <path
        d="M245.7 104.089H249.76C250.25 104.089 250.67 103.669 250.67 103.179V102.689C250.67 102.199 250.25 101.779 249.76 101.779H245.7C245.21 101.779 244.79 102.199 244.79 102.689V103.179C244.72 103.669 245.14 104.089 245.7 104.089Z"
        fill="white"
      />
      <path
        d="M238.07 104.089H242.13C242.62 104.089 243.04 103.669 243.04 103.179V102.689C243.04 102.199 242.62 101.779 242.13 101.779H238.07C237.58 101.779 237.16 102.199 237.16 102.689V103.179C237.09 103.669 237.51 104.089 238.07 104.089Z"
        fill="white"
      />
      <path
        d="M230.44 104.089H234.5C234.99 104.089 235.41 103.669 235.41 103.179V102.689C235.41 102.199 234.99 101.779 234.5 101.779H230.44C229.95 101.779 229.53 102.199 229.53 102.689V103.179C229.46 103.669 229.88 104.089 230.44 104.089Z"
        fill="white"
      />
      <path
        d="M89.2498 104.161H93.3098C93.7998 104.161 94.2199 103.741 94.2199 103.251V102.761C94.2199 102.271 93.7998 101.851 93.3098 101.851H89.2498C88.7598 101.851 88.3398 102.271 88.3398 102.761V103.251C88.3398 103.741 88.7598 104.161 89.2498 104.161Z"
        fill="white"
      />
      <path
        d="M96.9499 104.161H101.01C101.5 104.161 101.92 103.741 101.92 103.251V102.761C101.92 102.271 101.5 101.851 101.01 101.851H96.9499C96.4599 101.851 96.0399 102.271 96.0399 102.761V103.251C95.9699 103.741 96.3899 104.161 96.9499 104.161Z"
        fill="white"
      />
      <path
        d="M208.04 213.43C204.4 210.28 198.45 211.05 194.74 214.2C194.46 214.48 194.11 214.76 193.83 215.04H259.77C261.31 215.04 262.57 213.78 262.57 212.24V183.05C257.74 184.17 253.4 187.11 251.44 191.66C248.64 188.93 243.25 189.77 241.5 193.27C236.46 190.19 229.67 190.26 224.77 193.55C219.87 196.77 217.14 203 217.98 208.81C214.27 206.78 208.95 209.23 208.04 213.43Z"
        fill="white"
      />
      <path
        d="M151.76 212.52C148.47 210.56 143.85 210.49 141.05 213.15C139.65 207.62 133.14 204.05 127.75 205.87C124.25 200.55 115.85 199.29 111.02 203.42C109.9 199.78 105.07 197.82 101.71 199.64C100.38 191.45 92.3296 185.22 84.1396 185.08V212.17C84.1396 213.71 85.3996 214.97 86.9396 214.97H154.7C153.86 213.99 152.88 213.15 151.76 212.52Z"
        fill="white"
      />
      <path d="M195.72 221.061H151.13V253.541H195.72V221.061Z" fill="#6B667B" />
      <path
        d="M242.97 263.2H102.97C100.31 263.2 98.1396 261.03 98.1396 258.37C98.1396 255.71 100.31 253.54 102.97 253.54H242.97C245.63 253.54 247.8 255.71 247.8 258.37C247.8 261.03 245.63 263.2 242.97 263.2Z"
        fill="#2E344B"
      />
      <path d="M195.72 221.061L151.13 227.011V221.061H195.72Z" fill="#2E344B" />
      <path
        d="M301.841 265.509C309.959 265.509 316.541 263.88 316.541 261.87C316.541 259.859 309.959 258.229 301.841 258.229C293.722 258.229 287.141 259.859 287.141 261.87C287.141 263.88 293.722 265.509 301.841 265.509Z"
        fill="#FFF4E8"
      />
      <path
        d="M307.021 198.73C308.001 195.93 309.331 193.27 310.871 190.75C312.201 188.65 313.741 186.55 315.771 185.15C316.751 184.45 318.081 183.96 319.131 184.59C319.761 185.01 320.041 185.78 320.111 186.48C320.321 188.58 318.921 190.54 317.591 192.22C314.301 196.42 311.221 200.83 308.911 205.66C306.531 210.49 304.991 215.67 304.851 221.06C304.851 222.46 305.061 224.56 304.151 225.68C302.961 224.63 303.591 221.76 303.661 220.36C303.731 217.91 303.871 215.39 304.151 212.94C304.501 208.04 305.341 203.28 307.021 198.73Z"
        fill="#2E344B"
      />
      <path
        d="M300.16 196.281C299.39 193.411 298.34 190.611 297.01 187.951C295.89 185.711 294.56 183.541 292.6 181.931C291.69 181.161 290.36 180.531 289.31 181.091C288.68 181.441 288.33 182.211 288.19 182.911C287.84 185.011 289.1 187.041 290.22 188.861C293.09 193.341 295.82 197.961 297.78 203.001C299.74 207.971 300.86 213.361 300.51 218.681C300.44 220.081 300.02 222.111 300.79 223.371C302.05 222.391 301.7 219.521 301.77 218.051C301.91 215.601 301.98 213.081 301.91 210.631C301.84 205.801 301.42 200.971 300.16 196.281Z"
        fill="#2E344B"
      />
      <path
        d="M292.671 216.3C290.711 214.55 288.611 213.01 286.371 211.68C284.481 210.56 282.451 209.65 280.281 209.37C279.231 209.23 277.971 209.37 277.481 210.35C277.201 210.91 277.271 211.61 277.481 212.24C278.251 213.99 280.071 214.83 281.821 215.6C286.091 217.49 290.291 219.66 294.141 222.39C297.921 225.12 301.351 228.55 303.661 232.61C304.291 233.66 304.921 235.41 306.111 235.9C306.601 234.57 304.921 232.61 304.291 231.56C303.171 229.67 302.051 227.85 300.861 226.03C298.411 222.53 295.821 219.17 292.671 216.3Z"
        fill="#2E344B"
      />
      <path
        d="M311.15 217.14C313.11 215.39 315.21 213.85 317.45 212.52C319.34 211.4 321.37 210.49 323.54 210.21C324.59 210.07 325.85 210.21 326.34 211.19C326.62 211.75 326.55 212.45 326.34 213.08C325.57 214.83 323.75 215.67 322 216.44C317.73 218.33 313.53 220.5 309.68 223.23C305.83 225.96 302.47 229.39 300.16 233.45C299.53 234.5 298.9 236.25 297.71 236.74C297.22 235.41 298.9 233.45 299.53 232.4C300.65 230.51 301.77 228.69 302.96 226.87C305.34 223.3 308 219.94 311.15 217.14Z"
        fill="#2E344B"
      />
      <path
        d="M299.53 190.961C299.39 184.801 299.53 178.431 302.33 172.971C303.73 170.311 305.83 167.861 308.63 166.811C311.43 165.761 315.07 166.531 316.68 169.051C317.87 170.871 317.87 173.251 317.45 175.351C316.54 180.041 313.88 184.171 311.57 188.441C309.05 193.131 306.88 198.031 305.62 203.211C304.29 208.601 303.94 214.271 304.01 219.801C304.08 224.491 305.41 229.741 304.92 234.361C301.21 230.58 301.56 221.34 301.07 216.511C300.23 208.041 299.74 199.501 299.53 190.961Z"
        fill="#4F6E59"
      />
      <path
        d="M318.081 193.06C320.391 190.26 323.051 187.53 326.481 186.41C327.951 185.92 329.701 185.78 330.891 186.83C332.151 187.95 332.151 189.91 331.801 191.59C330.471 198.17 325.571 203.35 320.951 208.25C317.871 211.47 314.791 214.69 311.711 217.91C308.631 221.13 305.341 224.7 304.641 229.18C304.291 231.28 304.641 233.59 303.731 235.55C302.541 229.04 304.781 220.71 306.741 214.48C309.121 206.64 312.971 199.36 318.081 193.06Z"
        fill="#4F6E59"
      />
      <path
        d="M297.431 207.97C295.191 199.57 292.601 190.54 285.951 185.01C284.621 183.89 282.801 182.84 281.121 183.54C279.021 184.38 278.671 187.18 278.951 189.42C279.441 192.99 280.701 196.35 282.171 199.64C284.131 203.98 286.511 208.18 289.171 212.17C293.091 217.84 297.781 223.16 299.741 229.74C300.091 230.79 301.071 237.3 302.471 236.74C303.661 236.32 303.241 231.56 303.171 230.72C302.541 223.09 299.391 215.25 297.431 207.97Z"
        fill="#4F6E59"
      />
      <path
        d="M313.81 261.871H289.94L287.91 227.011H315.84L313.81 261.871Z"
        fill="#6B667B"
      />
      <path
        d="M315.281 235.97C314.721 235.76 314.161 235.62 313.601 235.55C312.131 235.27 310.871 235.55 309.471 235.97C308.701 236.25 307.931 236.46 307.091 236.39C306.321 236.32 305.621 236.04 304.921 235.76C303.661 235.27 302.471 234.71 301.071 234.78C298.341 234.85 296.101 237.16 293.301 236.25C292.041 235.83 290.851 235.2 289.521 235.27C289.101 235.27 288.751 235.41 288.331 235.48L288.401 236.18C289.101 235.97 289.801 235.83 290.501 236.04C291.691 236.32 292.741 237.16 293.931 237.37C296.521 237.86 298.551 235.83 301.071 235.76C303.661 235.69 305.761 237.79 308.421 237.3C309.961 237.02 311.221 236.32 312.831 236.46C313.671 236.53 314.441 236.74 315.141 237.02L315.281 235.97Z"
        fill="white"
      />
      <path
        d="M288.261 233.03C289.031 232.75 289.801 232.54 290.711 232.68C292.181 232.89 293.441 233.73 294.911 233.94C296.311 234.15 297.431 233.66 298.691 233.03C300.091 232.33 301.351 232.26 302.891 232.61C305.411 233.17 307.931 234.43 310.591 233.73C312.131 233.31 313.391 232.61 315.001 232.75C315.211 232.75 315.351 232.82 315.561 232.82L315.631 231.84C314.861 231.7 314.161 231.7 313.461 231.77C312.201 231.91 311.151 232.54 309.961 232.75C308.771 232.96 307.511 232.75 306.391 232.4C304.361 231.84 302.191 231 300.091 231.42C298.831 231.7 297.851 232.54 296.661 232.89C295.331 233.24 294.071 232.68 292.811 232.26C291.551 231.84 289.801 231.56 288.331 231.98L288.261 233.03Z"
        fill="white"
      />
      <path
        d="M60.3404 283.22C72.7116 283.22 82.7404 280.932 82.7404 278.11C82.7404 275.288 72.7116 273 60.3404 273C47.9692 273 37.9404 275.288 37.9404 278.11C37.9404 280.932 47.9692 283.22 60.3404 283.22Z"
        fill="#FFF4E8"
      />
      <path
        d="M45.6405 239.96H77.2105C77.8405 239.96 78.4005 240.45 78.4005 241.15V275.24C78.4005 277.34 76.6505 279.09 74.5505 279.09H48.3705C46.2705 279.09 44.5205 277.34 44.5205 275.24V241.15C44.5205 240.45 45.0105 239.96 45.6405 239.96Z"
        fill="#2C2543"
      />
      <path
        d="M49.9104 246.19H37.8704C35.1404 246.19 32.9004 248.43 32.9004 251.16V266.84C32.9004 269.57 35.1404 271.81 37.8704 271.81H49.9104C52.6404 271.81 54.8804 269.57 54.8804 266.84V251.16C54.8804 248.43 52.6404 246.19 49.9104 246.19ZM49.9104 264.25C49.9104 265.65 48.7904 266.77 47.3904 266.77H40.3904C38.9904 266.77 37.8704 265.65 37.8704 264.25V253.75C37.8704 252.35 38.9904 251.23 40.3904 251.23H47.3904C48.7904 251.23 49.9104 252.35 49.9104 253.75V264.25Z"
        fill="#2C2543"
      />
      <path d="M44.5201 246.19V251.23H43.3301L44.5201 246.19Z" fill="#2C2543" />
      <path d="M44.5201 266.77V271.81H43.3301L44.5201 266.77Z" fill="#2C2543" />
      <path
        d="M308.07 294.77C311.08 288.54 309.12 278.04 296.38 274.96C296.03 274.89 295.68 274.82 295.33 274.75C294.91 274.68 294.49 274.54 294 274.47C293.93 274.47 293.86 274.47 293.86 274.47C293.79 274.47 293.79 274.47 293.72 274.47C288.26 273.63 283.99 273.91 280.7 274.89C273.42 277.06 271.04 282.73 271.67 287.98C271.67 288.26 271.74 288.54 271.81 288.82C271.95 289.59 272.16 290.29 272.37 291.06C275.24 299.25 296.59 302.4 304.08 299.04C305.48 298.41 306.67 297.29 307.58 295.82C307.72 295.47 307.93 295.12 308.07 294.77Z"
        fill="#FFF4E8"
      />
      <path
        d="M295.68 271.39C275.87 268.31 271.6 279.58 274.4 287.63C277.2 295.68 298.13 298.76 305.55 295.47C312.97 292.18 314.51 274.33 295.68 271.39Z"
        fill="white"
      />
      <path
        d="M295.681 271.39C295.611 271.39 295.471 271.39 295.401 271.32C295.401 271.32 295.401 271.32 295.331 271.32C293.651 271.95 292.111 272.72 290.571 273.7C289.731 273.14 288.681 272.72 287.771 272.37C286.721 271.95 285.671 271.67 284.551 271.53C284.341 271.46 284.131 271.46 283.851 271.46C282.941 271.67 282.101 271.88 281.331 272.23C282.311 272.3 283.361 272.44 284.341 272.65C285.321 272.86 286.301 273.14 287.281 273.49C288.051 273.77 288.751 274.19 289.451 274.54C288.961 274.89 288.401 275.31 287.911 275.73C285.811 277.48 283.991 279.65 282.591 281.96C281.961 283.08 281.331 284.27 280.841 285.46C280.351 286.72 279.791 288.05 279.791 289.45C279.791 289.73 280.141 289.8 280.281 289.59C280.911 288.47 281.191 287.21 281.751 286.02C282.241 284.83 282.871 283.71 283.571 282.59C284.971 280.42 286.651 278.53 288.541 276.85C290.571 275.1 293.021 273.7 295.541 272.72C296.311 272.44 297.081 272.16 297.851 271.95C297.291 271.67 296.521 271.53 295.681 271.39Z"
        fill="#B9CFE6"
      />
      <path
        d="M308.98 292.32C309.19 292.04 309.33 291.69 309.47 291.34C304.99 292.95 300.02 293.37 295.33 293.44C288.82 293.58 282.31 292.25 277.13 287.98C275.94 287 274.82 285.88 273.84 284.69C273.77 284.62 273.77 284.55 273.7 284.55C273.77 285.11 273.84 285.67 273.98 286.23C274.47 286.86 275.03 287.42 275.59 287.91C277.83 290.01 280.49 291.62 283.29 292.67C289.73 295.12 297.29 294.84 303.94 293.58C305.69 293.3 307.37 292.88 308.98 292.32Z"
        fill="#B9CFE6"
      />
      <path
        d="M243.67 283.709C243.39 283.359 243.04 283.149 242.62 283.149H99.4004C98.9804 283.149 98.5604 283.359 98.3504 283.709L85.1904 302.259V304.289C85.1904 304.779 85.6104 305.199 86.1004 305.199H255.92C256.41 305.199 256.83 304.779 256.83 304.289V302.259L243.67 283.709Z"
        fill="#FFF4E8"
      />
      <path
        d="M257.95 299.95H86.2402L99.4002 281.4C99.6802 281.05 100.03 280.84 100.45 280.84H243.67C244.09 280.84 244.51 281.05 244.72 281.4L257.95 299.95Z"
        fill="#B9CFE6"
      />
      <path
        d="M253.61 298.27H90.8604L101.5 282.45H242.69L253.61 298.27Z"
        fill="white"
      />
      <path
        d="M87.2202 302.891H256.97C257.53 302.891 257.95 302.471 257.95 301.911V299.881H86.2402V301.911C86.2402 302.471 86.6602 302.891 87.2202 302.891Z"
        fill="#6B667B"
      />
      <path d="M246.82 284.41H98.21V284.76H246.82V284.41Z" fill="#B9CFE6" />
      <path d="M248.99 287.49H95.3398V287.84H248.99V287.49Z" fill="#B9CFE6" />
      <path
        d="M251.441 291.061H93.5205V291.411H251.441V291.061Z"
        fill="#B9CFE6"
      />
      <path
        d="M252.91 294.631H92.1201V294.981H252.91V294.631Z"
        fill="#B9CFE6"
      />
      <path d="M172.411 281.75H172.061V284.62H172.411V281.75Z" fill="#B9CFE6" />
      <path d="M172.27 287.7H171.92V291.27H172.27V287.7Z" fill="#B9CFE6" />
      <path
        d="M167.077 291.209L166.396 294.784L166.739 294.85L167.421 291.274L167.077 291.209Z"
        fill="#B9CFE6"
      />
      <path
        d="M166.741 287.7L166.391 287.63L167.091 284.55L167.371 284.62L166.741 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M161.631 284.621L161.351 284.551L161.981 281.681L162.331 281.751L161.631 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M161.892 287.638L161.208 291.213L161.552 291.279L162.236 287.703L161.892 287.638Z"
        fill="#B9CFE6"
      />
      <path
        d="M157.011 291.224L156.329 294.8L156.673 294.865L157.354 291.29L157.011 291.224Z"
        fill="#B9CFE6"
      />
      <path
        d="M156.661 287.7L156.311 287.63L157.011 284.55L157.361 284.62L156.661 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M151.621 284.621L151.271 284.551L151.971 281.681L152.251 281.751L151.621 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M151.825 287.653L151.141 291.229L151.484 291.294L152.169 287.719L151.825 287.653Z"
        fill="#B9CFE6"
      />
      <path
        d="M146.958 291.174L146.276 294.75L146.62 294.816L147.302 291.24L146.958 291.174Z"
        fill="#B9CFE6"
      />
      <path
        d="M146.58 287.7L146.23 287.63L146.93 284.55L147.28 284.62L146.58 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M141.54 284.621L141.19 284.551L141.89 281.681L142.24 281.751L141.54 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M141.758 287.669L141.073 291.244L141.417 291.31L142.101 287.735L141.758 287.669Z"
        fill="#B9CFE6"
      />
      <path
        d="M136.889 291.186L136.208 294.762L136.552 294.827L137.233 291.252L136.889 291.186Z"
        fill="#B9CFE6"
      />
      <path
        d="M136.501 287.7L136.221 287.63L136.851 284.55L137.201 284.62L136.501 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M131.46 284.621L131.11 284.551L131.81 281.681L132.16 281.751L131.46 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M131.703 287.615L131.019 291.19L131.362 291.256L132.047 287.681L131.703 287.615Z"
        fill="#B9CFE6"
      />
      <path
        d="M126.823 291.202L126.142 294.777L126.485 294.843L127.167 291.267L126.823 291.202Z"
        fill="#B9CFE6"
      />
      <path
        d="M126.491 287.7L126.141 287.63L126.841 284.55L127.121 284.62L126.491 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M121.381 284.621L121.101 284.551L121.731 281.681L122.081 281.751L121.381 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M121.635 287.631L120.95 291.206L121.294 291.272L121.978 287.697L121.635 287.631Z"
        fill="#B9CFE6"
      />
      <path
        d="M116.757 291.217L116.075 294.793L116.419 294.858L117.1 291.283L116.757 291.217Z"
        fill="#B9CFE6"
      />
      <path
        d="M116.411 287.7L116.061 287.63L116.761 284.55L117.041 284.62L116.411 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M111.371 284.621L111.021 284.551L111.721 281.681L112.001 281.751L111.371 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M111.567 287.647L110.883 291.223L111.227 291.288L111.911 287.713L111.567 287.647Z"
        fill="#B9CFE6"
      />
      <path
        d="M106.703 291.164L106.021 294.739L106.365 294.805L107.047 291.229L106.703 291.164Z"
        fill="#B9CFE6"
      />
      <path
        d="M106.33 287.7L105.98 287.63L106.68 284.55L107.03 284.62L106.33 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M238.979 291.194L238.635 291.26L239.314 294.836L239.658 294.77L238.979 291.194Z"
        fill="#B9CFE6"
      />
      <path
        d="M239.33 287.7L238.7 284.62L238.98 284.55L239.68 287.63L239.33 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M234.29 284.621L233.66 281.751L233.94 281.681L234.64 284.551L234.29 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M233.791 287.619L233.447 287.685L234.13 291.26L234.474 291.194L233.791 287.619Z"
        fill="#B9CFE6"
      />
      <path
        d="M228.969 291.184L228.625 291.249L229.304 294.825L229.648 294.76L228.969 291.184Z"
        fill="#B9CFE6"
      />
      <path
        d="M229.32 287.7L228.62 284.62L228.97 284.55L229.6 287.63L229.32 287.7Z"
        fill="#B9CFE6"
      />
      <path d="M223.93 281.75H223.58V284.62H223.93V281.75Z" fill="#B9CFE6" />
      <path
        d="M223.712 287.622L223.368 287.688L224.051 291.263L224.395 291.197L223.712 287.622Z"
        fill="#B9CFE6"
      />
      <path
        d="M218.889 291.187L218.545 291.252L219.224 294.828L219.568 294.763L218.889 291.187Z"
        fill="#B9CFE6"
      />
      <path
        d="M219.24 287.7L218.54 284.62L218.89 284.55L219.59 287.63L219.24 287.7Z"
        fill="#B9CFE6"
      />
      <path d="M213.85 281.75H213.5V284.62H213.85V281.75Z" fill="#B9CFE6" />
      <path
        d="M213.696 287.616L213.353 287.682L214.035 291.257L214.379 291.191L213.696 287.616Z"
        fill="#B9CFE6"
      />
      <path
        d="M208.81 291.191L208.466 291.256L209.145 294.832L209.489 294.766L208.81 291.191Z"
        fill="#B9CFE6"
      />
      <path
        d="M209.16 287.7L208.46 284.62L208.81 284.55L209.51 287.63L209.16 287.7Z"
        fill="#B9CFE6"
      />
      <path d="M203.77 281.75H203.42V284.62H203.77V281.75Z" fill="#B9CFE6" />
      <path
        d="M203.622 287.614L203.278 287.68L203.961 291.255L204.305 291.189L203.622 287.614Z"
        fill="#B9CFE6"
      />
      <path
        d="M198.726 291.197L198.382 291.263L199.061 294.839L199.405 294.773L198.726 291.197Z"
        fill="#B9CFE6"
      />
      <path
        d="M199.08 287.7L198.45 284.62L198.73 284.55L199.43 287.63L199.08 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M194.04 284.621L193.34 281.751L193.69 281.681L194.39 284.551L194.04 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M193.543 287.617L193.199 287.683L193.882 291.258L194.226 291.192L193.543 287.617Z"
        fill="#B9CFE6"
      />
      <path
        d="M188.72 291.183L188.376 291.248L189.055 294.824L189.399 294.759L188.72 291.183Z"
        fill="#B9CFE6"
      />
      <path
        d="M189 287.7L188.37 284.62L188.72 284.55L189.35 287.63L189 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M183.96 284.621L183.33 281.751L183.61 281.681L184.31 284.551L183.96 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M183.463 287.62L183.119 287.686L183.802 291.261L184.146 291.195L183.463 287.62Z"
        fill="#B9CFE6"
      />
      <path
        d="M141.706 294.847L141.022 298.422L141.366 298.488L142.05 294.912L141.706 294.847Z"
        fill="#B9CFE6"
      />
      <path
        d="M131.651 294.793L130.968 298.368L131.312 298.434L131.995 294.859L131.651 294.793Z"
        fill="#B9CFE6"
      />
      <path
        d="M121.584 294.809L120.9 298.384L121.244 298.45L121.928 294.874L121.584 294.809Z"
        fill="#B9CFE6"
      />
      <path
        d="M111.517 294.824L110.833 298.399L111.177 298.465L111.86 294.89L111.517 294.824Z"
        fill="#B9CFE6"
      />
      <path
        d="M233.787 294.838L233.443 294.903L234.126 298.479L234.47 298.413L233.787 294.838Z"
        fill="#B9CFE6"
      />
      <path
        d="M223.708 294.841L223.364 294.906L224.047 298.481L224.391 298.416L223.708 294.841Z"
        fill="#B9CFE6"
      />
      <path
        d="M213.722 294.813L213.378 294.879L214.061 298.454L214.405 298.388L213.722 294.813Z"
        fill="#B9CFE6"
      />
      <path
        d="M203.618 294.833L203.274 294.898L203.957 298.474L204.301 298.408L203.618 294.833Z"
        fill="#B9CFE6"
      />
      <path
        d="M178.641 291.187L178.297 291.252L178.976 294.828L179.32 294.763L178.641 291.187Z"
        fill="#B9CFE6"
      />
      <path
        d="M178.99 287.7L178.29 284.62L178.64 284.55L179.27 287.63L178.99 287.7Z"
        fill="#B9CFE6"
      />
      <rect
        opacity="0.4"
        x="107"
        y="155"
        width="128"
        height="6"
        rx="3"
        fill="#D5D3D9"
      />
      <rect
        opacity="0.4"
        x="107"
        y="165"
        width="128"
        height="6"
        rx="3"
        fill="#D5D3D9"
      />
      <rect
        opacity="0.4"
        x="107"
        y="176"
        width="128"
        height="6"
        rx="3"
        fill="#D5D3D9"
      />
      <path
        d="M127.75 145C130.649 145 133 142.649 133 139.75C133 136.851 130.649 134.5 127.75 134.5C124.851 134.5 122.5 136.851 122.5 139.75C122.5 142.649 124.851 145 127.75 145ZM125.5 139H130C130.414 139 130.75 139.336 130.75 139.75C130.75 140.164 130.414 140.5 130 140.5H125.5C125.086 140.5 124.75 140.164 124.75 139.75C124.75 139.336 125.086 139 125.5 139Z"
        fill="#6F767E"
      />
      <path
        d="M125.5 128.5C125.5 130.985 123.485 133 121 133C118.515 133 116.5 130.985 116.5 128.5C116.5 126.015 118.515 124 121 124C123.485 124 125.5 126.015 125.5 128.5Z"
        fill="#6F767E"
      />
      <path
        d="M112 140.5C112 142 113.5 142 113.5 142H121.384C121.135 141.296 121 140.539 121 139.75C121 137.72 121.897 135.898 123.315 134.661C122.616 134.557 121.846 134.5 121 134.5C113.5 134.5 112 139 112 140.5Z"
        fill="#6F767E"
      />
    </g>
    <defs>
      <clipPath id="clip0_8638_151160">
        <rect width="350" height="350" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ConversationEmptyIcon;
