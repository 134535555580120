import i18next from 'i18next';
import { Timezones } from 'infrastructure/enums';

export interface ITimezoneItem {
  id: Timezones;
  name: string;
}

export const timezonesDataSource = (): ITimezoneItem[] => {
  const timezones = (
    Object.keys(Timezones) as Array<keyof typeof Timezones>
  ).map((timezone) => ({
    id: Timezones[timezone],
    name: i18next.t(`timezones.${timezone}`),
  }));

  return timezones;
};
