import React from 'react';
import { Container } from 'reactstrap';
import Button from 'components/atoms/button';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'utils/deviceHelper';
import useLogout from 'utils/hooks/auth/useLogout';

const TermsPage = () => {
  const {
    user: { acceptTermsOfService },
  } = useDispatch();
  const device = useSelector((state) => state.user.deviceType);
  const { logout } = useLogout();

  return (
    <Container className="terms-page content">
      {isMobile(device) ? (
        <div className="google-doc-container">
          <div className="google-doc">
            <iframe
              className="terms-page__terms"
              src={process.env.REACT_APP_TERMS_OF_SERVICE}
            />
          </div>
        </div>
      ) : (
        <iframe
          className="terms-page__terms"
          src={process.env.REACT_APP_TERMS_OF_SERVICE}
        />
      )}

      <div className="terms-page__buttons">
        <Button text="Accept" handleClick={acceptTermsOfService} />
        <Button text="Reject" fillBg={false} handleClick={() => logout()} />
      </div>
    </Container>
  );
};

export default TermsPage;
