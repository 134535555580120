import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';

import { showResult } from '../utils';

interface Props {
  searchQuery: string;
}

const patientSearchFn = async ({ searchQuery }: { searchQuery: string }) => {
  let previousController: any = null;
  if (previousController) {
    previousController.abort();
  }
  const controller = new AbortController();
  const { signal } = controller;

  const phoneParams = new URLSearchParams(`query=${searchQuery}&items=${100}`);

  previousController = controller;

  const { data, error } = await dataService.getList(
    `core/search/patients/name-phone?${phoneParams.toString()}`,
    { signal },
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const getPatientSearchKey = ({ searchQuery }: Props) => [
  'getPatientSearch',
  searchQuery,
];

export const usePatientSearch = (props: Props) => {
  const {
    data: patientSearch,
    refetch,
    isFetching,
    isLoading,
    isRefetching,
    ...other
  } = useQuery({
    queryKey: getPatientSearchKey(props),
    queryFn: async () =>
      patientSearchFn({
        ...props,
      }),
  });

  return {
    patientSearch: patientSearch?.data,
    fetchPatientSearch: refetch,
    patientSearchLoading: isLoading || isFetching || isRefetching,
    isLoading,
    isFetching,
    ...other,
  };
};
