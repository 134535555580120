import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const clinicMappingsValidationSchema = () =>
  createValidationSchema({
    clinicId: commonValidationSchemas.string({ nullable: true }),
    parentClinicGuid: commonValidationSchemas.string({ nullable: true }),
    agencyGuid: commonValidationSchemas.string({ required: true }),
  });
