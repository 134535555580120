import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';

import type { IUnreadConversationsCount } from 'infrastructure/interfaces';

export const getSmsDashboardUnreadCountFn = async () => {
  const url = `core/conversations/unread-conversations-count`;

  const { data, error } = await dataService.getList<IUnreadConversationsCount>(
    apiUrlGenerator(url),
  );

  if (error) {
    showResult('Unable to retrieve unread conversations count');
    throw new Error(error);
  }
  return data;
};

export const useSmsDashboardUnreadCountKey = () => [
  'getSmsDashboardUnreadCount',
];

export const useSmsDashboardUnreadCount = () => {
  const {
    data: unreadConversationsCount,
    refetch,
    isFetching,
    isLoading,
    isRefetching,
    ...other
  } = useQuery({
    queryKey: useSmsDashboardUnreadCountKey(),
    queryFn: async () => getSmsDashboardUnreadCountFn(),
    initialData: {
      rpmTotalUnreadCount: 0,
      ccmTotalUnreadCount: 0,
      unregisteredTotalUnreadCount: 0,
    },
  });

  return {
    unreadConversationsCount,
    fetchUnreadConversationsCount: refetch,
    unreadConversationsCountLoading: isLoading || isFetching || isRefetching,
    isLoading,
    isFetching,
    ...other,
  };
};
