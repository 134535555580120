import { useTranslation } from 'react-i18next';
import MedicationIcon from 'components/atoms/icons/medicationIcon';
import BaseButton from 'components/atoms/baseButton';
import { type FC } from 'react';
import EmptyTablePlaceholder from 'components/atoms/base-empty-table-placeholder';
import BucketIcon from 'components/atoms/icons/bucketIcon';
import BaseLoader from 'components/atoms/base-loader';
import AddMedicationForm from 'components/molecules/admin-panel/forms/medication';
import BasePagination from 'components/atoms/base-pagination';
import BaseSelectBox from 'components/atoms/base-select-box';
import PlusIcon from 'components/atoms/icons/plus';
import { MEDICATION_PER_PAGE } from 'infrastructure/hooks/ccm';

import s from './styles.module.scss';
import MedicationItem from './components/medication-item';
import { useMedication } from './hooks/use-medication';

import type { ModuleType } from 'infrastructure/enums';
import type { TOption } from './hooks/use-medication';

interface PatientMedicationProps {
  patientGuid: string;
  module: ModuleType;
}

const PatientMedication: FC<PatientMedicationProps> = ({
  patientGuid,
  module,
}) => {
  const { t } = useTranslation();
  const {
    modalTitle,
    Alert,
    open,
    modifyItemGuid,
    medication,
    isLoading,
    isFetching,
    addLoading,
    editLoading,
    deleteLoading,
    loading,
    disableButton,
    wrapperRef,
    OPTIONS,
    status,
    page,
    itemPerPage,
    medicationForUpdate,
    onCancelForm,
    handleDelete,
    handleEdit,
    scrollToTop,
    onSubmit,
    setStatus,
    setPage,
    setItemPerPage,
    toggleOpen,
    remove,
  } = useMedication({ patientGuid, module });

  return (
    <>
      <div className={s.wrapper} ref={wrapperRef} id="medication">
        <div className={s.header}>
          <h4 className={s.title}>
            <MedicationIcon />
            {t('labels.medication')}
          </h4>
          <div className={s['header-select']}>
            <p data-cy="medication-status-filter">{t('labels.status')}</p>
            <BaseSelectBox
              value={status}
              style={{ width: 120 }}
              onChange={(v, opt) => {
                remove();
                setPage(1);
                setStatus(opt as TOption);
              }}
              options={OPTIONS}
            />
          </div>
          <BaseButton
            dataCy="addMedication-button"
            icon={<PlusIcon color="transparent" />}
            label={`${t('controls.addMedication')}`}
            onClick={toggleOpen}
            loading={addLoading}
            disabled={disableButton || loading}
          />
        </div>
        <div className={s.divider} />
        <BaseLoader loading={isLoading || isFetching}>
          <div className={s.body} style={{ position: 'relative' }}>
            {!!medication?.items.length && (
              <div className={s['items-wrapper']}>
                {medication?.items.map((item) => {
                  return (
                    <MedicationItem
                      item={item}
                      disableActions={disableButton}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                      loading={deleteLoading || editLoading}
                      modifyItemGuid={modifyItemGuid}
                    />
                  );
                })}
              </div>
            )}
            {medication?.items.length === 0 && (
              <EmptyTablePlaceholder
                text={t('labels.medicationHistoryIsEmpty')}
                icon={<BucketIcon width={32} height={32} />}
              />
            )}
            <BasePagination
              page={page}
              total={medication?.count}
              align="center"
              onChange={(newPage) => {
                scrollToTop();
                setPage(newPage);
              }}
              showSizeChanger
              pageSize={itemPerPage}
              onShowSizeChange={(current, size) => {
                scrollToTop();
                setItemPerPage(size);
              }}
              hide={(medication?.count ?? 0) < MEDICATION_PER_PAGE}
            />
          </div>
        </BaseLoader>
      </div>

      <AddMedicationForm
        loading={addLoading || editLoading}
        onSubmit={onSubmit}
        onCancelForm={onCancelForm}
        defaultValues={medicationForUpdate}
        modalTitle={modalTitle}
        open={open}
      />
      {Alert}
    </>
  );
};
export default PatientMedication;
