import dataService from 'services/dataService';
import {
  API_CCM_AGENT_DASHBOARD,
  API_CCM_AGENT_DASHBOARD_COUNT,
  API_DOCTORS_AND_AGENCY_DOCTORS_BY_ROLES,
} from 'services/dataService/resources';
import QueryParams from 'utils/queryParams';
import { showResult } from 'infrastructure/hooks/utils';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import httpCodes from 'constants/httpCodes';

import { generateQueryFromFilters } from './utils';

import type {
  IDoctorsAndAgencyDoctorSelect,
  IDoctorsAndAgencyDoctorsResponseDataProps,
} from 'infrastructure/interfaces';
import type { QueryClient } from '@tanstack/react-query';
import type {
  Filters,
  CcmAgentCountData,
  CcmAgentDashboardData,
} from './types';
import type { Roles } from 'infrastructure/enums';
import type { Option } from 'components/molecules/autocompleteFilter/base';

export const getItems = async (filters: Filters) => {
  const url = QueryParams.stringifyUrl(
    API_CCM_AGENT_DASHBOARD,
    generateQueryFromFilters(filters),
  );
  const { error, data } = await dataService.getList<CcmAgentDashboardData>(url);
  if (error) {
    showResult(error);
    return;
  }
  return data;
};

export const getCount = async (
  filters: Filters,
): Promise<CcmAgentCountData> => {
  const url = QueryParams.stringifyUrl(
    API_CCM_AGENT_DASHBOARD_COUNT,
    generateQueryFromFilters(filters),
  );
  const { error, data } = await dataService.getList<CcmAgentCountData>(url);
  if (error) {
    showResult(error);
  }
  if (!data) {
    return {
      totalCount: 0,
      appointmentCompleted: {
        enrolledInCurrentMonth: {
          currentMonth: 0,
          last1Day: 0,
          last2Days: 0,
          last3Days: 0,
        },
        enrolledInPreviousMonth: {
          currentMonth: 0,
          last1Day: 0,
          last2Days: 0,
          last3Days: 0,
        },
      },
    };
  }
  return data;
};

export const getDoctorsAndAgencyDoctors = async ({
  search,
  items,
  page,
  roles,
  queryClient,
  callback,
}: {
  queryClient: QueryClient;
  search: string;
  page: number;
  items: number;
  roles?: Array<Roles>;
  callback: (
    update: (
      prevOptions: IDoctorsAndAgencyDoctorSelect[],
    ) => IDoctorsAndAgencyDoctorSelect[],
  ) => void;
}): Promise<{ count: number; data: Option[] } | undefined> => {
  const emptyResult = {
    data: [],
    count: 0,
  };
  if (!search) return emptyResult;
  const filters = {
    page,
    items,
    search,
    roles: roles?.join(',') || [],
  };

  const queryKey = [API_DOCTORS_AND_AGENCY_DOCTORS_BY_ROLES, filters] as const;
  const url = QueryParams.stringifyUrl(
    API_DOCTORS_AND_AGENCY_DOCTORS_BY_ROLES,
    filters,
  );
  const { data, status, error } = await queryClient.fetchQuery(
    queryKey,
    () => dataService.getList<IDoctorsAndAgencyDoctorsResponseDataProps>(url),
    { staleTime: STALE_TIME },
  );

  if (status === httpCodes.notFount) {
    return emptyResult;
  }

  if (error) {
    showResult(error);
  }
  if (!data || error) return emptyResult;

  const seenGuids = new Set<string>();

  const values: Option[] =
    data.items?.reduce<Option[]>((acc, item) => {
      if (!seenGuids.has(item.guid)) {
        seenGuids.add(item.guid);
        acc.push({
          label: item.fullName,
          value: item.guid,
        });
      }
      return acc;
    }, []) || [];

  callback((prevOptions) => {
    const mergedOptions = [...prevOptions, ...data.items];
    const uniqueOptions = Array.from(
      new Map(mergedOptions.map((item) => [item.guid, item])).values(),
    );
    return uniqueOptions as IDoctorsAndAgencyDoctorSelect[];
  });

  return {
    data: values,
    count: data.count || 0,
  };
};
