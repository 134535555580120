import React from 'react';
import PropTypes from 'prop-types';

const colors = {
  active: '#2C2543',
  notActive: '#BBB',
};

const PaginationOrderIconUp = ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="8"
    viewBox="0 0 7 5"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.68848 4.77734L3.64458 0.780107L0.600688 4.77734"
      fill={active ? colors.active : colors.notActive}
    />
  </svg>
);

PaginationOrderIconUp.propTypes = {
  active: PropTypes.bool,
};

export default PaginationOrderIconUp;
