import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { arrUniqEl } from 'infrastructure/functions';
import { LoginStatus } from 'infrastructure/enums/login-status';
import { AgencyDoctorSelect } from 'infrastructure/classes/select/agency-doctor-select';

import useApi from '../use-api';

import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IAgencyDoctorSelectPaginatedData,
} from 'infrastructure/interfaces';
import type { Roles } from 'infrastructure/enums';

export const getAgencyDoctorsApi = 'core/agency-doctors/select-options';

export interface IUseAgencyDoctorMeta {
  search?: string;
  guid?: string;
  agencyGuid?: string;
  roles?: Array<Roles>;
  status?: Array<LoginStatus>;
}

type MergedMeta = IUseAgencyDoctorMeta & IPaginationMeta;

type UseAgencyDoctor = {
  loading: boolean;
  meta: MergedMeta;
  loadAgencyDoctors: (meta: MergedMeta) => Promise<AgencyDoctorSelect[]>;
};

interface IUseAgencyDoctorSelectProps {
  meta?: IUseAgencyDoctorMeta & IPartialPaginationMeta;
}

export const useAgencyDoctorSelect = (
  props?: IUseAgencyDoctorSelectProps,
): UseAgencyDoctor => {
  const { meta: metaProps = {} } = props || {};
  const { loading, loadData, isMounted } = useApi();
  const queryClient = useQueryClient();

  const [meta, setMeta] = useState<MergedMeta>({
    page: 1,
    items: 100,
    totalCount: 0,
    ...metaProps,
  });

  const loadAgencyDoctors = async (filters: MergedMeta) => {
    const queryKey = [
      getAgencyDoctorsApi,
      {
        page: filters.page,
        roles: filters.roles,
        search: filters.search,
        status: filters.status,
        guid: filters.guid,
        agencyGuid: filters.agencyGuid,
      },
    ];

    const data = await queryClient.fetchQuery(
      queryKey,
      () =>
        loadData<
          IAgencyDoctorSelectPaginatedData,
          Omit<IUseAgencyDoctorMeta, 'sortField'>
        >(getAgencyDoctorsApi, {
          sortField: JSON.stringify(filters.sortField),
          page: filters.page,
          items: filters.items,
          guid: filters.guid,
          search: filters.search,
          agencyGuid: filters.guid ? undefined : filters.agencyGuid,
          roles: filters.roles,
          status: filters.guid
            ? [LoginStatus.Active, LoginStatus.Blocked, LoginStatus.Inactive]
            : filters.status,
        }),
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      const newMeta = {
        ...meta,
        ...filters,
        totalCount: data.count,
      };

      setMeta(newMeta);

      return arrUniqEl(data.items, 'guid').map(
        (el) => new AgencyDoctorSelect(el),
      );
    }

    return [];
  };

  return {
    loading,
    meta,
    loadAgencyDoctors,
  };
};
