import dataService from 'services/dataService';
import {
  API_INTERACTIVE_TIME_DASHBOARD,
  API_INTERACTIVE_TIME_DASHBOARD_COUNT,
} from 'services/dataService/resources';
import QueryParams from 'utils/queryParams';
import { showResult } from 'infrastructure/hooks/utils';

import { generateQueryFromFilters } from './utils';

import type {
  Filters,
  InteractiveTimeCountData,
  InteractiveTimeDashboardData,
} from './types';

export const getItems = async (filters: Filters) => {
  const url = QueryParams.stringifyUrl(
    API_INTERACTIVE_TIME_DASHBOARD,
    generateQueryFromFilters(filters),
  );
  const { error, data } =
    await dataService.getList<InteractiveTimeDashboardData>(url);
  if (error) {
    showResult(error);
    return;
  }
  return data;
};

export const getCount = async (
  filters: Filters,
): Promise<InteractiveTimeCountData> => {
  const url = QueryParams.stringifyUrl(
    API_INTERACTIVE_TIME_DASHBOARD_COUNT,
    generateQueryFromFilters(filters),
  );
  const { error, data } = await dataService.getList<InteractiveTimeCountData>(
    url,
  );
  if (error) {
    showResult(error);
  }
  if (!data) {
    return {
      interactionStatusFalseCount: 0,
      interactionStatusTrueCount: 0,
    };
  }
  return data;
};
