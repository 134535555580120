import React, { useEffect, useState } from 'react';
import BaseContainer from 'components/atoms/base-container';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import { useTranslation } from 'react-i18next';
import { Routes } from 'infrastructure/consts/routes';
import { useNavigate, useParams } from 'react-router-dom';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import { useClinicInfo } from 'infrastructure/hooks/clinic/use-clinic-info';
import { Clinic } from 'infrastructure/classes/clinic';
import { useClinicThresholds } from 'infrastructure/hooks/clinic/use-clinic-thresholds';
import ClinicViewForm from 'components/molecules/clinic/view-form';
import EditButton from 'components/atoms/controls/edit-button';

const AdminClinicViewPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clinicGuid: _clinicGuid } = useParams<{
    clinicGuid: string;
  }>();
  const clinicGuid = _clinicGuid!;

  const { loading: clinicLoading, clinicInfo } = useClinicInfo({ clinicGuid });
  const { thresholds, loading: thresholdLoading } = useClinicThresholds({
    clinicGuid,
  });

  const loading = clinicLoading || thresholdLoading;

  const [clinic, setClinic] = useState<Clinic>({} as Clinic);

  const onEdit = async () => {
    navigate(Routes.adminClinicsEdit(clinicGuid));
  };

  const controls = <EditButton width="160px" onClick={onEdit} />;

  useEffect(() => {
    if (clinicInfo) {
      setClinic(new Clinic(clinicInfo));
    }
  }, [clinicInfo]);

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseContainer>
        <AdminNavigationHeader
          goBackPath={Routes.adminClinics}
          title={t('labels.clinicInformation')}
          controls={controls}
        />
        <ClinicViewForm info={clinic} thresholds={thresholds} />
      </BaseContainer>
    </BaseSpinWrapper>
  );
};

export default AdminClinicViewPage;
