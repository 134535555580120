import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useConfirm from 'utils/useConfirm';

import s from './styles.module.scss';

import type { BookmarkComment } from 'infrastructure/classes/bookmark-comment';

interface ICommentBookmarkCardProps {
  data: BookmarkComment;
  onApply: (data: BookmarkComment) => void;
  onSave: (data: BookmarkComment) => void;
  onDelete: (data: BookmarkComment) => void;
}

const CommentBookmarkCard: React.FC<ICommentBookmarkCardProps> = (props) => {
  const { data, onSave, onDelete, onApply } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(data.message);
  const [inEdit, setInEdit] = useState<boolean>(false);
  const showConfirm = useConfirm({
    message: t('confirm.deleteBookmark'),
    title: t('confirm.title'),
  });

  const primaryBtnClassNames = classNames(s.btn, s.primary);
  const outlinedBtnClassNames = classNames(s.btn, s.outlined);
  const editorClassNames = classNames(s.area, {
    [s.disabled]: !inEdit,
  });

  const startEditing = () => {
    setInEdit(true);
  };
  const cancelEditing = () => {
    setInEdit(false);
    setValue(data.message);
  };

  const save = () => {
    Object.assign(data, {
      message: value,
    });
    onSave(data);
    setInEdit(false);
  };
  const remove = async () => {
    const confirmed = await showConfirm();
    if (!confirmed) return;
    onDelete(data);
  };

  const apply = () => {
    onApply(data);
  };

  return (
    <div className={s.card}>
      <textarea
        rows={3}
        className={editorClassNames}
        value={value}
        disabled={!inEdit}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        data-cy="bookmarked-comments-card-edit-area"
      />
      <div className={s.controls}>
        {inEdit && (
          <>
            <button
              className={primaryBtnClassNames}
              onClick={save}
              data-cy="bookmarked-comments-card-save-btn"
            >
              {t('controls.save')}
            </button>
            <button
              className={outlinedBtnClassNames}
              onClick={cancelEditing}
              data-cy="bookmarked-comments-card-cancel-btn"
            >
              {t('controls.cancel')}
            </button>
          </>
        )}
        {!inEdit && (
          <>
            <button
              className={primaryBtnClassNames}
              onClick={apply}
              data-cy="bookmarked-comments-card-apply-btn"
            >
              {t('controls.apply')}
            </button>
            <button
              className={outlinedBtnClassNames}
              onClick={startEditing}
              data-cy="bookmarked-comments-card-edit-btn"
            >
              {t('controls.edit')}
            </button>
            <button
              className={outlinedBtnClassNames}
              onClick={remove}
              data-cy="bookmarked-comments-card-delete-btn"
            >
              {t('controls.delete')}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CommentBookmarkCard;
