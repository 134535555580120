import React from 'react';

const Calendar4Icon: React.FC<any> = ({ width, height, ...props }) => (
  <svg
    width={width ?? '13'}
    height={height ?? '13'}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.99939 0C3.22032 0 3.39941 0.179097 3.39941 0.400024V0.800049H9.7998V0.400024C9.7998 0.179097 9.9789 0 10.1998 0C10.4208 0 10.5999 0.179097 10.5999 0.400024V0.800049H11.3999C12.2836 0.800049 13 1.51644 13 2.40015V11.2007C13 12.0844 12.2836 12.8008 11.3999 12.8008H1.79932C0.915607 12.8008 0.199219 12.0844 0.199219 11.2007V2.40015C0.199219 1.51644 0.915607 0.800049 1.79932 0.800049H2.59937V0.400024C2.59937 0.179097 2.77846 0 2.99939 0ZM1.79932 1.6001C1.35746 1.6001 0.999268 1.95829 0.999268 2.40015V3.2002H12.2V2.40015C12.2 1.95829 11.8418 1.6001 11.3999 1.6001H1.79932ZM12.2 4.00024H0.999268V11.2007C0.999268 11.6425 1.35746 12.0007 1.79932 12.0007H11.3999C11.8418 12.0007 12.2 11.6425 12.2 11.2007V4.00024Z"
      fill="#2C2543"
      {...props}
    />
  </svg>
);

export default Calendar4Icon;
