import i18next from 'i18next';
import { ServiceDeliveryThreshold } from 'infrastructure/enums';
import { ModifiedArray } from 'infrastructure/classes/common/modified-array';

export interface IServiceDeliveryThresholdItem {
  id: ServiceDeliveryThreshold | null;
  name: string;
}

type ServiceDeliveryThresholdsArray =
  ModifiedArray<IServiceDeliveryThresholdItem>;

export const serviceDeliveryThresholdsDataSource = ({
  withNone = false,
  withAll = false,
  shortName = false,
}): ServiceDeliveryThresholdsArray => {
  const dataSource = new ModifiedArray<IServiceDeliveryThresholdItem>(
    {
      id: ServiceDeliveryThreshold['15M'],
      name: shortName
        ? i18next.t('serviceDeliveryThresholds.15mShort')
        : i18next.t('serviceDeliveryThresholds.15m'),
    },
    {
      id: ServiceDeliveryThreshold['2H'],
      name: shortName
        ? i18next.t('serviceDeliveryThresholds.2hShort')
        : i18next.t('serviceDeliveryThresholds.2h'),
    },
    {
      id: ServiceDeliveryThreshold['4H'],
      name: shortName
        ? i18next.t('serviceDeliveryThresholds.4hShort')
        : i18next.t('serviceDeliveryThresholds.4h'),
    },
    {
      id: ServiceDeliveryThreshold['8H'],
      name: shortName
        ? i18next.t('serviceDeliveryThresholds.8hShort')
        : i18next.t('serviceDeliveryThresholds.8h'),
    },
  );

  if (withNone) {
    dataSource.unshift({
      id: null,
      name: shortName
        ? i18next.t('serviceDeliveryThresholds.nullShort')
        : i18next.t('serviceDeliveryThresholds.null'),
    });
  }

  if (withAll) {
    dataSource.unshift({
      id: ServiceDeliveryThreshold.All,
      name: i18next.t('serviceDeliveryThresholds.all'),
    });
  }

  return dataSource;
};
