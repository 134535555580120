import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import ScrollToTopIcon from 'components/atoms/icons/scroll-to-top.svg?react';

import s from './styles.module.scss';
import BaseIconButton from '../base-icon-button';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const toggleVisibility = debounce(() => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, 50);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, [toggleVisibility]);

  return (
    <>
      {isVisible && (
        <BaseIconButton
          onClick={scrollToTop}
          className={s.scroll}
          icon={<ScrollToTopIcon className={s.icon} />}
        />
      )}
    </>
  );
};

export default ScrollToTopButton;
