import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { statesDataSource } from 'infrastructure/data-sources/states';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IStateItem } from 'infrastructure/data-sources/states';

type Options = IBaseSelectBoxOption<IStateItem['id'], IStateItem>[];

interface IStateSelectBoxProps extends Omit<IBaseSelectBoxProps, 'options'> {
  withAll?: boolean;
  withNone?: boolean;
}

const StateSelectBox: React.FC<IStateSelectBoxProps> = (props) => {
  const { withAll = false, withNone = false } = props;
  const dataSource = useMemo(
    () => statesDataSource(withAll, withNone),
    [withAll, withNone],
  );

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...props} />;
};

export default StateSelectBox;
