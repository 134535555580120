import React from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { serviceDeliveryThresholdsDataSource } from 'infrastructure/data-sources/service-delivery-thresholds';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IServiceDeliveryThresholdItem } from 'infrastructure/data-sources/service-delivery-thresholds';

interface IServiceDeliveryThresholdSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {
  withNone?: boolean;
  withAll?: boolean;
}

const ServiceDeliveryThresholdSelectBox: React.FC<
  IServiceDeliveryThresholdSelectBoxProps
> = (props) => {
  const { withNone = false, withAll = false } = props;

  const serviceDeliveryThresholds = serviceDeliveryThresholdsDataSource({
    withNone,
    withAll,
  });

  const options: IBaseSelectBoxOption<
    IServiceDeliveryThresholdItem['id'],
    IServiceDeliveryThresholdItem
  >[] = serviceDeliveryThresholds.map((serviceDeliveryThreshold) => ({
    value: serviceDeliveryThreshold.id,
    label: serviceDeliveryThreshold.name,
    record: serviceDeliveryThreshold,
  }));

  return <BaseSelectBox options={options} {...props} />;
};

export default ServiceDeliveryThresholdSelectBox;
