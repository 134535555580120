import { makeid } from 'utils/helpers/makeid';

import type { ISmsLog } from 'infrastructure/interfaces';
import type { DateString } from 'infrastructure/types';

export class SmsActivityLog {
  key: string;
  guid: string;
  message: string;
  status: string;
  from: string;
  to: string;
  createdAt: DateString;
  constructor(data: ISmsLog) {
    this.key = makeid(5);
    this.guid = data.guid;
    this.message = data.message;
    this.status = data.status;
    this.from = data.from;
    this.to = data.to;
    this.createdAt = data.createdAt;
  }
}
