import BaseButton from 'components/atoms/baseButton';
import SmallDropdownArrow from 'components/atoms/icons/dropdownSelectArrow';
import { type FC } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import s from './styles.module.scss';

interface Option {
  label: string;
  handleClick: () => void;
  dataCy: string;
}

interface ActionDropdownButtonProps {
  options: Option[];
  dataCy: string;
  label: string;
  loading: boolean;
}

export const ActionDropdownButton: FC<ActionDropdownButtonProps> = ({
  options,
  dataCy,
  label,
  loading,
}) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle className={s.toggle} data-cy={dataCy} tag="div">
        <BaseButton
          label={label}
          type="secondary"
          rightIcon={<SmallDropdownArrow style={{ height: 6, width: 13 }} />}
          loading={loading}
        />
      </DropdownToggle>
      <DropdownMenu right className={s.menu}>
        {options.map((option, index) => (
          <DropdownItem
            tag="button"
            key={index}
            className={s['menu-item']}
            data-cy={option.dataCy}
            onClick={option.handleClick}
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
