const userRequestGenerator = ({
  guid,
  legalname,
  email,
  phone,
  fax,
  addressLine1,
  addressLine2,
  city,
  state,
  zip,
  npiNumber,
  stateIdNumber,
  taxIdNumber,
  taxonomyNumber,
  medicalIdTpiNumber,
  medicareNumber,
  agencyId,
}) => {
  const body = {
    guid,
    legalname,
    email,
    phone: phone || null,
    fax,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    npiNumber,
    stateIdNumber,
    taxIdNumber,
    taxonomyNumber,
    medicalIdTpiNumber,
    medicareNumber,
    agencyId,
  };
  return body;
};
export default userRequestGenerator;
