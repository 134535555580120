import React, { useCallback, useState } from 'react';
import BaseAsyncSelectBox from 'components/atoms/base-select-box/async';
import { useClinicSelect } from 'infrastructure/hooks/select/use-clinic-select';
import { useTranslation } from 'react-i18next';
import { ClinicStatus } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { ClinicSelect } from 'infrastructure/classes/select/clinic-select';
import type { IPaginationMeta } from 'infrastructure/interfaces';
import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';

export type ClinicSelectOption = IBaseSelectBoxOption<string, ClinicSelect>;

interface IClinicSelectBoxProps extends Omit<IBaseSelectBoxProps, 'options'> {
  showInactiveWarning?: boolean;
  agencyGuid?: Array<string>;
  status?: Array<ClinicStatus>;
  onChange?: (value: string, option: ClinicSelectOption) => void;
  onLoaded?: (
    items: Array<ClinicSelectOption>,
    selected?: ClinicSelectOption,
  ) => void;
}

const ClinicSelectBox: React.FC<IClinicSelectBoxProps> = (props) => {
  const {
    agencyGuid,
    status,
    onLoaded,
    onChange,
    showInactiveWarning = true,
  } = props;

  const { t } = useTranslation();

  const { meta, loadClinics } = useClinicSelect();

  const [isInactive, setIsInactive] = useState<boolean>(false);

  const fetchOptions = useCallback(
    async (search: string, metaProps: IPaginationMeta) => {
      const clinics = await loadClinics({
        ...metaProps,
        search,
        agencyGuid,
        status,
      });

      if (clinics)
        return clinics.map((el) => {
          return {
            label: el.label,
            value: el.guid,
            record: el,
          };
        });

      return [];
    },
    [agencyGuid, status],
  );

  const onValueLoaded = (
    items: Array<ClinicSelectOption>,
    selected?: ClinicSelectOption,
  ) => {
    if (selected?.record?.status === ClinicStatus.Inactive) {
      setIsInactive(true);
    } else {
      setIsInactive(false);
    }
    if (onLoaded) onLoaded(items, selected);
  };
  const onValueChange = (value: string, option: ClinicSelectOption) => {
    if (option?.record?.status === ClinicStatus.Inactive) {
      setIsInactive(true);
    } else {
      setIsInactive(false);
    }
    if (onChange) onChange(value, option);
  };

  return (
    <div className={s.wrapper}>
      <BaseAsyncSelectBox
        key={agencyGuid?.join()}
        fetchOptions={fetchOptions}
        meta={meta}
        canSearch
        {...props}
        onValueLoaded={onValueLoaded}
        onChange={onValueChange}
      />
      {showInactiveWarning && isInactive && (
        <div className={s.error}>*{t('errors.currentlyInactive')}</div>
      )}
    </div>
  );
};

export default ClinicSelectBox;
