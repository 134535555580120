import i18next from 'i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { ConsumableLog } from 'infrastructure/classes/logs/consumable-log';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

export const consumableLogColumns = (): IBaseTableColumns<ConsumableLog> => {
  return [
    {
      label: i18next.t('labels.orderDate'),
      key: 'orderDate',
      width: '170px',
      render(date) {
        return (
          <div className={s.text}>
            {DateFormatter({
              date,
              format: DateFormats['MM-dd-yyyy | h:mm a'],
            })}
          </div>
        );
      },
    },
    {
      label: i18next.t('labels.orderedUser'),
      key: 'orderedUser',
      width: '170px',
      render(value) {
        return <div className={s.text}>{value}</div>;
      },
    },
    {
      label: i18next.t('labels.name'),
      key: 'consumableName',
      width: '300px',
      render(value) {
        return <div className={s.text}>{value}</div>;
      },
    },
    {
      label: i18next.t('labels.trackingData'),
      key: 'trackingNumber',
      width: '400px',
      responsive: ['md'],
      render(value) {
        return <div className={s.text}>{value}</div>;
      },
    },
  ];
};
