import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import forms from 'constants/forms';
import Input from 'components/atoms/input';

const PersonalInfo = ({ errors }) => {
  const personalInfo = [...forms.adminPanelsDoctor.userEdit.personalInfo];
  return (
    <div className="admin-patient-form-block">
      <span>Personal Info</span>
      <Row>
        {personalInfo.map((field) => (
          <Col lg="6" md="6" sm="6" xs="12" key={field.id}>
            <Input dataCy={`users-input-${field.id}`} {...field} />
            {errors[field.id] ? (
              <div className="input-error">
                {errors[field.id].split('.')[0]}
              </div>
            ) : null}
          </Col>
        ))}
      </Row>
    </div>
  );
};

PersonalInfo.propTypes = {
  errors: PropTypes.shape({}).isRequired,
};

export default PersonalInfo;
