export const camelCaseToWords = (value) => {
  const converted = value.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  return converted[0].toUpperCase() + converted.slice(1);
};
export const generateMessage = (reportType) => {
  let result;
  switch (reportType) {
    case 'adherence30Days':
      result = 'Adherence 30 Days';
      break;
    case 'yesterdaysStats':
      result = 'Yesterdays Statistics';
      break;
    case 'interactive':
      result = 'Interactive Time';
      break;
    case 'statusReport':
      result = 'Patient Status';
      break;
    case 'ccmStatusReport':
      result = 'CCM Patient Status';
      break;
    case 'ccm-billing':
      result = 'CCM Billing';
      break;
    case 'ccmInteractiveTime':
      result = 'CCM Interactive Time';
      break;
    case 'ccmOnboardingStatus':
      result = 'CCM Onboarding Status';
      break;
    default:
      result = reportType;
  }
  return `Your ${camelCaseToWords(
    result,
  )} Report is ready for download. Please check email.`;
};
