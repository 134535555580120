import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { showResult } from 'infrastructure/hooks/utils';

import { updatePatientNutritionAccount } from './api';

import type { IPatient } from 'infrastructure/interfaces';

export const useUpdatePatientNutritionAccount = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: updatePatientNutritionAccount,
    onSuccess(data, variables) {
      const patientInfoKey = getPatientInfoKey(variables.patientGuid);
      queryClient.setQueryData<IPatient>(patientInfoKey, (oldData) => {
        if (oldData && data) {
          return {
            ...oldData,
            weekMealStatus: data.weekMealStatus,
          };
        }
      });
      showResult();
    },
  });

  return { updateNutritionAccount: mutate, nutritionAccountLoading: isLoading };
};
