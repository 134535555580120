import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ccmPageAvailableStatuses } from 'constants/patient_statuses';
import {
  getPatientCcmStatus,
  getPatientPcmStatus,
  getPatientStatus,
} from 'utils';

const PatientsTable = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="report-table table-sticky-header patient-status-table-wrapper">
      <table
        cellSpacing="0"
        cellPadding="0"
        className="table-border"
        data-cy="ccm-status-report__table"
      >
        <thead>
          <tr>
            <th data-cy="ccm-status-report__table-header">Patient Name</th>
            <th data-cy="ccm-status-report__table-header">Patient DOB</th>
            <th data-cy="ccm-status-report__table-header">
              CCM Onboarding Agent
            </th>
            <th data-cy="ccm-status-report__table-header">CCM Case Manager</th>
            <th data-cy="ccm-status-report__table-header">Clinic Name</th>
            <th data-cy="ccm-status-report__table-header">Clinic State</th>
            <th data-cy="ccm-status-report__table-header">CCM Status</th>
            <th data-cy="ccm-status-report__table-header">RPM Status</th>
            <th data-cy="ccm-status-report__table-header">PCM Status</th>
            <th data-cy="ccm-status-report__table-header">
              CCM Temporary On-Hold Status
            </th>
            <th data-cy="ccm-status-report__table-header">
              CCM Temporary On-Hold Date
            </th>
            <th data-cy="ccm-status-report__table-header">
              Email Subscription Status
            </th>
            <th data-cy="ccm-status-report__table-header">
              RPM SMS Subscription Status
            </th>
            <th data-cy="ccm-status-report__table-header">
              CCM SMS Subscription Status
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td
                data-label="Patient Name"
                className="report-table__patient"
                data-cy="ccm-status-report__table-patient-name"
              >
                <strong>
                  {ccmPageAvailableStatuses.includes(row.ccmStatus) ? (
                    <Link to={`/ccm/patient/${row.guid}`}>
                      {row.patientName}
                    </Link>
                  ) : (
                    row.patientName
                  )}
                </strong>
              </td>
              <td
                data-label="Patient DOB"
                data-cy="ccm-status-report__table-patient-dob"
              >
                {row.birthDate}
              </td>
              <td
                data-label="CCM Onboarding Agent"
                data-cy="ccm-status-report__table-ccm-onboarding-agent"
              >
                {row.ccmOnboardingAgent}
              </td>
              <td
                data-label="CCM Case Manager"
                data-cy="ccm-status-report__table-ccm-case-manager"
              >
                {row.ccmManager}
              </td>
              <td
                data-label="Clinic Name"
                data-cy="ccm-status-report__table-clinic-name"
              >
                {row.clinic?.legalname}
              </td>
              <td
                data-label="Clinic State"
                data-cy="ccm-status-report__table-clinic-state"
              >
                {row.clinic?.state}
              </td>
              <td
                data-label="CCM Status"
                data-cy="ccm-status-report__table-ccmStatus"
              >
                {getPatientCcmStatus(row.ccmStatus).label}
              </td>
              <td
                data-label="RPM Status"
                data-cy="ccm-status-report__table-rpmStatus"
              >
                {getPatientStatus(row.status).label}
              </td>
              <td
                data-label="PCM Status"
                data-cy="ccm-status-report__table-pcmStatus"
              >
                {getPatientPcmStatus(row.pcmStatus)}
              </td>
              <td
                data-label="CCM Temporary On-Hold Status"
                data-cy="ccm-status-report__table-pcmStatus"
              >
                {row.ccmHoldingStatus ? 'True' : 'False'}
              </td>
              <td
                data-label="CCM Temporary On-Hold Date"
                data-cy="ccm-status-report__table-pcmStatus"
              >
                {row.ccmHoldingStatusStartDate && (
                  <>{row.ccmHoldingStatusStartDate}</>
                )}
              </td>
              <td
                data-label="Email Subscription Status"
                data-cy="ccm-status-report__table-emailSubscriptionStatus"
              >
                {row.emailSubscription === true ? 'Subscribed' : 'Unsubscribed'}
              </td>
              <td
                data-label="RPM SMS Subscription Status"
                data-cy="ccm-status-report__table-rpm-subscription-status"
              >
                {row.rpmSmsSubscription
                  ? t('controls.optedIn')
                  : t('controls.optedOut')}
              </td>
              <td
                data-label="CCM SMS Subscription Status"
                data-cy="ccm-status-report__table-ccm-subscription-status"
              >
                {row.ccmSmsSubscription
                  ? t('controls.optedIn')
                  : t('controls.optedOut')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

PatientsTable.propTypes = {
  data: PropTypes.array,
};

export default PatientsTable;
