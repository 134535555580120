import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

import DropdownClosedIcon from '../icons/dropdownClosedIcon';
import DropdownOpenIcon from '../icons/dropdownOpenIcon';

const Dropdown = ({ label, value, handleClick, isOpen = false }) => {
  return (
    <div className="dropdown" onClick={handleClick} role="button" aria-hidden>
      <Card className="card-border card-shadow border-0">
        <CardBody>
          <p>{label}</p>
          <div className="dropdown-body">
            <span>{value}</span>
            {isOpen ? <DropdownOpenIcon /> : <DropdownClosedIcon />}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default Dropdown;
