import type { IBookmarkComment } from 'infrastructure/interfaces';

export class BookmarkComment {
  key: string;
  guid: string;
  message: string;

  constructor(data: IBookmarkComment) {
    this.key = data.guid;
    this.guid = data.guid;
    this.message = data.message;
  }
}
