import useSlaTimer from './useSlaTimer';

import type { FC } from 'react';

type Props = {
  activity: {
    slaDeadline: string;
    slaReviewedTime?: string;
    serviceDeliveryThresholds: number;
  };
};

const Countdown: FC<Props> = ({ activity }) => {
  const { backgroundColor, color, countdown } = useSlaTimer(activity);
  return (
    <div
      className="text-nowrap sla-timer-stop-countdown"
      style={{
        color,
        backgroundColor,
      }}
    >
      {countdown}
    </div>
  );
};

export default Countdown;
