import { Container } from 'reactstrap';
import TurkDashboardTable from 'components/templates/turkDashboard/index';

const TurkDashboardPage = () => {
  return (
    <>
      <Container>
        <TurkDashboardTable />
      </Container>
    </>
  );
};

export default TurkDashboardPage;
