import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import ActivityComments from 'components/molecules/comments';
import ActivityStatus from 'components/atoms/activityStatus';
import common from 'constants/common';
import { useSelector } from 'react-redux';
import { isMobile } from 'utils/deviceHelper';
import { camelCaseToWords } from 'utils/formatReportMessage';
import ReadingItem from 'components/atoms/readingItem';

const ActivityRow = ({ activity }) => {
  const device = useSelector((state) => state.user.deviceType);

  return (
    <tr className="activity-row">
      <td data-label="Status" className="status-row">
        <ActivityStatus status={activity.globalStatus} />
      </td>
      <td data-label="Time">
        <span>
          R:{' '}
          {format(
            new Date(parseInt(activity.createdAt)),
            common.dateFormats.dayOfWeek,
          )}
        </span>
        <span>
          {format(
            new Date(parseInt(activity.createdAt)),
            common.dateFormats.timeAPM,
          )}
        </span>
      </td>
      <td data-label="Patient">
        <div>
          <Link to={`/patient/${activity.patient.guid}`}>
            <strong>
              {activity.patient.firstName} {activity.patient.lastName}
            </strong>
          </Link>
        </div>
      </td>
      <td data-label="MRN">
        <span>{activity.patient.mrn}</span>
        {isMobile(device) && !activity.patient.mrn && '-'}
      </td>
      <td data-label="Phone Number">
        <div>
          <strong>{activity.patient.phone}</strong>
        </div>
      </td>
      <td data-label="Provider">
        <div>
          {activity.doctor.title} {activity.doctor.firstName}{' '}
          {activity.doctor.lastName}
          {activity.doctor.professionType ? ' - ' : ' '}
          {activity.doctor.professionType}
        </div>
      </td>
      <td data-label="Category">
        <div>
          <span className={`reading ${activity.globalStatus}`}>
            {activity.readingType !== 'SpO2'
              ? camelCaseToWords(activity.readingType)
              : activity.readingType}
          </span>
        </div>
      </td>
      <td data-label="Reading">
        <ReadingItem activity={activity} />
      </td>
      <td data-label="Comments/Reviews">
        <ActivityComments comments={activity.comments} />
      </td>
    </tr>
  );
};

ActivityRow.propTypes = {
  activity: PropTypes.shape().isRequired,
};

export default ActivityRow;
