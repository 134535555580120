import dataService from 'services/dataService';
import {
  API_PATIENTS_MESSAGES,
  API_PATIENT_DOCTOR_MESSAGES,
  API_MESSAGES_VOCABULARY,
  apiPatientMessages,
  API_CCM_MESSAGES,
} from 'services/dataService/resources';
import { format } from 'date-fns';

const ITEMS_PER_PAGE = 10;

const smsTemplates = {
  state: {
    templates: [],
    dates: [],
    messages: {
      page: 1,
      pageStep: 10,
      totalCount: 1,
      data: [],
    },
  },
  reducers: {
    setTemplates: (state, payload) => ({ ...state, templates: [...payload] }),
    setMessages: (state, payload) => ({
      ...state,
      messages: { ...state.messages, data: [...payload] },
    }),
    setPage: (state, payload) => ({
      ...state,
      messages: {
        ...state.messages,
        page: payload,
      },
    }),
    setTimeRange: (state, payload) => ({
      ...state,
      dates: payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      messages: {
        ...state.messages,
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
      },
    }),
  },
  effects: (dispatch) => ({
    async getTemplates() {
      const { data } = await dataService.getList(API_MESSAGES_VOCABULARY);
      dispatch.sms.setTemplates(data);
    },
    async submit({
      patientGuid,
      topic,
      type = 'sms',
      message,
      isRpmPanel,
      isPrimaryNumber,
    }) {
      const endpoint = isRpmPanel ? API_PATIENTS_MESSAGES : API_CCM_MESSAGES;
      const { error, data } = await dataService.createOne(endpoint, {
        patientGuid,
        topic,
        type,
        message,
        isPrimaryNumber,
      });
      return { error, data };
    },
    async submitDoctorMessage(postData) {
      const { error, data } = await dataService.createOne(
        API_PATIENT_DOCTOR_MESSAGES,
        postData,
      );
      return { error, data };
    },
    async getMessages({ patientGuid, isCcmPanel }, state) {
      const startDate = format(new Date(state.sms.dates[0]), 'MM-dd-yyyy');
      const endDate = format(new Date(state.sms.dates[1]), 'MM-dd-yyyy');
      const { data } = await dataService.getList(
        apiPatientMessages(
          isCcmPanel,
          patientGuid,
          startDate,
          endDate,
          state.sms.messages.page,
          state.sms.messages.pageStep,
        ),
      );
      dispatch.sms.setMessages(data?.data || []);
      const totalCount = data?.count || 0;
      const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
      dispatch.sms.setTotalCountAndTotalPages({
        totalCount,
        totalPages,
      });
    },
    async setPageSms({ pageOffset }, state) {
      const { page, totalPages } = state.sms.messages;
      const newPage = page + pageOffset;
      dispatch.sms.setPage(newPage <= totalPages ? newPage : totalPages);
    },
    setTimeRange({ dates }) {
      if (dates) {
        dispatch.sms.setTimeRange(dates);
      }
    },
  }),
};

export default smsTemplates;
