import i18next from 'i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { SmsActivityLog } from 'infrastructure/classes/logs/sms-activity-log';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

export const smsActivityLogColumns = (): IBaseTableColumns<SmsActivityLog> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'createdAt',
      width: '120px',
      render(date) {
        return (
          <div className={s.date}>
            {DateFormatter({
              date,
              format: DateFormats['MM-dd-yyyy h:mm a'],
            })}
          </div>
        );
      },
    },
    {
      label: i18next.t('labels.smsTextElectronicallySignedBy'),
      key: 'message',
      width: '400px',
      responsive: ['md'],
      render(value) {
        return <div className={s.message}>{value}</div>;
      },
    },
    {
      label: i18next.t('labels.from'),
      key: 'from',
      width: '150px',
      render(value) {
        return <div className={s.text}>{value}</div>;
      },
    },
    {
      label: i18next.t('labels.to'),
      key: 'to',
      width: '150px',
      render(value) {
        return <div className={s.text}>{value}</div>;
      },
    },
  ];
};
