import { makeid } from 'utils/helpers/makeid';

import type { IManualClinicNotesLog } from 'infrastructure/interfaces';
import type { DateString } from 'infrastructure/types';

export class ManualClinicNotesLog {
  key: string;
  author: string;
  comment: string;
  date: DateString;
  guid: string;
  timeInMinutes: string;
  constructor(data: IManualClinicNotesLog) {
    this.key = makeid(5);
    this.author = data.author;
    this.comment = data.comment;
    this.date = data.date;
    this.guid = data.guid;
    this.timeInMinutes = data.timeInMinutes;
  }
}
