import { useTranslation } from 'react-i18next';

import ColumnForm, { ColumnFormEmptyItem, ColumnFormItem } from '../form';

import type { CcmAgentItem } from 'infrastructure/hooks/ccmAgentDashboard/types';
import type { FC } from 'react';

interface IProps {
  item: CcmAgentItem;
}

const ProviderClinicInfo: FC<IProps> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <ColumnForm>
      <ColumnFormItem
        label={t('labels.provider')}
        value={item.doctor?.fullName}
      />
      <ColumnFormItem label={t('labels.clinic')} value={item.clinic?.name} />
      <ColumnFormItem label={t('labels.state')} value={item.clinic?.state} />
      <ColumnFormEmptyItem />
    </ColumnForm>
  );
};

export default ProviderClinicInfo;
