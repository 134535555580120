import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import useApi from '../use-api';

import type {
  IUpdatePatientInsurance,
  TPatientInsurances,
} from 'infrastructure/interfaces';

const patientInsuranceApi = (patientGuid: string) =>
  `admins/admin-panel/patients/${patientGuid}/insurances`;

interface IUsePatientInsurance {
  loading: boolean;
  loadPatientInsurance: () => Promise<TPatientInsurances | void>;
  updatePatientInsurance: (body: IUpdatePatientInsurance) => Promise<void>;
}

interface IUsePatientInsuranceProps {
  patientGuid: string;
}

export const usePatientInsurance = (
  props: IUsePatientInsuranceProps,
): IUsePatientInsurance => {
  const { patientGuid } = props;

  const { loading, loadData, updateData } = useApi();

  const queryClient = useQueryClient();
  const queryKey = ['patientInsurance', patientGuid];

  const loadPatientInsurance = async (): Promise<TPatientInsurances | void> => {
    const data = await queryClient.fetchQuery(
      queryKey,
      () => loadData<TPatientInsurances>(patientInsuranceApi(patientGuid)),
      { staleTime: STALE_TIME },
    );
    if (data) return data;
  };

  const updatePatientInsurance = async (
    body: IUpdatePatientInsurance,
  ): Promise<void> => {
    const data = await updateData<TPatientInsurances>(
      patientInsuranceApi(patientGuid),
      body,
    );
    queryClient.setQueryData(queryKey, data);
  };

  return {
    loading,
    loadPatientInsurance,
    updatePatientInsurance,
  };
};
