import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { apiPatientNotes } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface Props {
  patientGuid: string;
}

export const getPatientNotesKey = (patientGuid: string) => [
  'getPatientNotes',
  patientGuid,
];

export const usePatientNotes = ({ patientGuid }: Props) => {
  const {
    data: patientNotes,
    refetch,
    fetchStatus,
    isRefetching,
  } = useQuery({
    queryKey: getPatientNotesKey(patientGuid),
    queryFn: async () => {
      const { data, error } = await dataService.getList(
        apiPatientNotes(patientGuid),
      );
      if (error) {
        showResult('Unable to retrieve patient notes');
        throw new Error(error);
      }

      return data;
    },
    enabled: !!patientGuid,
  });

  return {
    patientNotes,
    fetchPatientNotes: refetch,
    patientNotesLoading: fetchStatus === 'fetching' || isRefetching,
  };
};
