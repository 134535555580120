const SearchIconThin = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M73.3885 64.6493C78.3307 57.9206 81.25 49.6137 81.25 40.625C81.25 18.1884 63.0616 0 40.625 0C18.1884 0 0 18.1884 0 40.625C0 63.0616 18.1884 81.25 40.625 81.25C49.6161 81.25 57.9251 78.3291 64.6547 73.3845L64.6493 73.3885C64.8336 73.6384 65.0389 73.8778 65.2652 74.104L89.3306 98.1694C91.7714 100.61 95.7287 100.61 98.1694 98.1694C100.61 95.7287 100.61 91.7714 98.1694 89.3306L74.104 65.2652C73.8778 65.0389 73.6384 64.8336 73.3885 64.6493ZM75 40.625C75 59.6098 59.6098 75 40.625 75C21.6402 75 6.25 59.6098 6.25 40.625C6.25 21.6402 21.6402 6.25 40.625 6.25C59.6098 6.25 75 21.6402 75 40.625Z"
      fill="#CCCAD7"
    />
  </svg>
);

export default SearchIconThin;
