import { Col, Row } from 'reactstrap';
import forms from 'constants/forms';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'components/molecules/pagination';
import AutoCompleteAgencies from 'components/molecules/autocompleteFilter/agency';

const Toolbar = () => {
  const DEFAULT_AGENCY_FILTER = forms.agencyFilterOptions[0];
  const { page, data, itemsPerPage, totalCount } = useSelector(
    (state) => state.adminPanelAgencies,
  );
  const { getClinics: patientsLoading } = useSelector(
    (state) => state.loading.effects.adminPanelClinics,
  );
  const {
    adminPanelAgencies: { setFilters, setAgenciesPage },
  } = useDispatch();
  const setPage = (pageOffset, getList = true) => {
    setAgenciesPage({ pageOffset, getList });
  };
  const agenciesFilter = DEFAULT_AGENCY_FILTER;

  return (
    <Row>
      <Col sm={12} md={4}>
        <strong>Home Health Agencies</strong>
        <AutoCompleteAgencies
          filter={agenciesFilter}
          handleClick={({ value }) => {
            setPage(-(page - 1), false);
            setFilters({ guid: value });
          }}
        />
      </Col>
      <Col column="2">
        <Pagination
          title=""
          page={page}
          pageTotal={data?.length}
          itemsPerPage={itemsPerPage}
          total={totalCount}
          handleFirstClick={() => setPage(-(page - 1))}
          handleLastClick={() => setPage(Math.ceil(totalCount / itemsPerPage))}
          handlePrevClick={() => setPage(-1)}
          handleNextClick={() => setPage(+1)}
          loading={patientsLoading}
        />
      </Col>
    </Row>
  );
};

export default Toolbar;
