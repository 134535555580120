import React from 'react';

import BaseTableNew from '../base-table/new';
import { logsColumns } from './cols';

import type { BaseLog } from 'infrastructure/classes/logs/base-log';
import type { IBaseTableProps } from '../base-table/new';

interface ILogListProps extends Omit<IBaseTableProps, 'columns'> {
  items: Array<BaseLog>;
}

const LogTable: React.FC<ILogListProps> = (props) => {
  const { items, ...args } = props;
  return (
    <BaseTableNew
      {...args}
      rowKey="key"
      items={items}
      columns={logsColumns()}
      bordered
    />
  );
};

export default LogTable;
