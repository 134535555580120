import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Dropdown from 'components/atoms/dropdown';
import List from 'components/atoms/list';
import TimeFilter from 'components/templates/rpm/patients/patient/timeFilter';
import PatientAutocomplete from 'components/molecules/autocomplete';
import forms from 'constants/forms';
import useOutsideClick from 'utils/useOutsideClick';
import { isPatient } from 'utils/userTypeHelper';
import useUser from 'utils/useUser';

const DEFAULT_FILTER = forms.patientFilterOptions[0];

const PatientFilter = ({ handleSubmitTime, hideTimeFilter }) => {
  const { userType } = useUser();
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };

  const ref = useRef();
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  const onChange = (option) => {
    setFilter(option);
    toggleFilter();
  };

  const handleOnChange = (option) => {
    if (option) {
      const { value } = option;
      navigate(`/patient/${value}`);
    }
  };

  return (
    <div className="patient-filter">
      <Row>
        {!hideTimeFilter && (
          <Col xs="12" md="6" xl="4">
            <TimeFilter handleSubmit={handleSubmitTime} noUTC />
          </Col>
        )}
        {!isPatient(userType) && (
          <>
            <Col xs="12" md="6" xl="4">
              <div
                className="patient-filter-dropdown time-filter"
                data-cy="patient-filter-list-dropdown"
                ref={ref}
              >
                <Dropdown
                  // label="Patients List"
                  value={filter?.label}
                  handleClick={() => toggleFilter()}
                  isOpen={isOpen}
                />
                {isOpen && (
                  <div className="patient-filter-dropdown-body">
                    <List
                      onChange={onChange}
                      options={forms.patientFilterOptions}
                      dataCy="patient-list-filters"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col xs="12" md="6" xl="4">
              {filter && (
                <div
                  className="patient-filter-input"
                  data-cy="patient-filter-input"
                >
                  <PatientAutocomplete
                    dropDownIcon={filter === DEFAULT_FILTER}
                    filter={filter}
                    status="active_onboarding_inactive"
                    onFilterChange={onChange}
                    handleClick={handleOnChange}
                    showLabel={false}
                    patient
                    disabled={isOpen}
                  />
                </div>
              )}
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

PatientFilter.propTypes = {
  hideTimeFilter: PropTypes.bool,
  handleSubmitTime: PropTypes.func,
};

export default PatientFilter;
