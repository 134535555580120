import { useIdleTimer } from 'react-idle-timer';
import useTurkDashboardSettings from 'utils/hooks/turkDashboard/useSettings';
import { minutesToMilliseconds } from 'date-fns';
import useLogout from 'utils/hooks/auth/useLogout';

const useTurkDashboardIdle = () => {
  const { logout } = useLogout();
  const { turkConfiguration, DEFAULT_IDLE_TIME } = useTurkDashboardSettings();

  useIdleTimer({
    onIdle: () => {
      logout();
    },
    timeout: minutesToMilliseconds(
      turkConfiguration?.maximumAllowableIdleTime || DEFAULT_IDLE_TIME,
    ),
    crossTab: true,
    startOnMount: true,
  });
};

export default useTurkDashboardIdle;
