import React from 'react';
import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminUserAddBlock from 'components/templates/admin/userAdd';

const AdminUserAdd = () => (
  <Container>
    <AdminHeader />
    <AdminUserAddBlock />
  </Container>
);

export default AdminUserAdd;
