const DownloadIcon = (props) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.182 0.818057C13.6545 0.290537 12.9531 2.92969e-05 12.207 5.85938e-05L2.79299 0C1.25291 5.85938e-05 0 1.253 0 2.79296V12.207C0 13.7471 1.25297 15 2.79299 15H12.207C13.7471 15 15 13.7471 15 12.207V2.79302C15 2.04697 14.7095 1.34558 14.182 0.818057ZM4.85933 6.37189C5.03092 6.2003 5.30915 6.2003 5.4808 6.37189L7.06055 7.95164L7.06049 3.26364C7.06049 3.02095 7.25725 2.82419 7.49994 2.82419C7.74267 2.82419 7.93939 3.02095 7.93939 3.26364L7.93945 7.95152L9.51908 6.37184C9.69067 6.20024 9.96894 6.20024 10.1406 6.37184C10.3122 6.54343 10.3122 6.82169 10.1406 6.99331L7.81072 9.32323C7.72828 9.40567 7.61651 9.45196 7.49997 9.45196C7.38346 9.45196 7.27166 9.40567 7.18928 9.32323L4.85939 6.99337C4.68771 6.82178 4.68771 6.54352 4.85933 6.37189ZM11.7364 12.1758H3.26367C3.02101 12.1758 2.82422 11.979 2.82422 11.7363C2.82422 11.4936 3.02101 11.2969 3.26367 11.2969H11.7364C11.979 11.2969 12.1758 11.4936 12.1758 11.7363C12.1758 11.979 11.9791 12.1758 11.7364 12.1758Z"
      fill="#FB9318"
    />
  </svg>
);

export default DownloadIcon;
