export const forms = [
  {
    id: 'agentName',
    type: 'TEXT',
    title: 'Agent Name',
    disabled: true,
    isRequired: true,
  },
  {
    id: 'agentEmail',
    type: 'TEXT',
    title: 'Agent Email',
    disabled: true,
    isRequired: true,
  },
  {
    id: 'patientName',
    type: 'TEXT',
    title: 'Patient Name',
    disabled: true,
    isRequired: true,
  },
  {
    id: 'patientGuid',
    type: 'TEXT',
    title: 'Patient GUID',
    disabled: true,
    isRequired: true,
  },
  {
    id: 'dateOfInstance',
    type: 'DATE',
    title: 'Date of Instance',
    isRequired: true,
  },
  {
    id: 'didYouHaveBiDirectionalConversationWithPatient',
    type: 'MULTIPLE_CHOICE',
    title: 'Did you have a bi-directional conversation with the patient? ',
    hasOtherOption: false,
    isRequired: true,
    choices: ['Yes'],
  },
  {
    id: 'patientVitalsDateAndTime',
    type: 'TEXT',
    title: "What are the patient's vitals, date and time? ",
    helpText:
      'Ex:  Blood Pressure: 116/61 mmHg , 08/11/2023, 11:23 am \n Ex: Pulse: 63 bpm, 04/19/2023, 1:45 pm',
    isRequired: true,
  },
  {
    id: 'riskForBloodPressure',
    type: 'CHECKBOX',
    title: 'Risk for Blood Pressure',
    helpText:
      'These nursing diagnoses are meant to guide nursing care and interventions to prevent the development of certain conditions or complications. They should be based on a thorough assessment of the patients health status and risk factors. The risk factors described would be those linked to the environment and patients cognitive and physiological state, as well as caused by medication.',
    hasOtherOption: true,
    isRequired: true,
    showToolTipForChoices: true,
    notRequiredIf: ['riskForBloodGlucose', 'riskForRespiratory'],
    choices: [
      'Risk for decreased cardiac output (Vulnerability to a reduction in the amount of blood pumped by the heart which could lead to inadequate perfusion of body tissues)',
      'Risk for decreased cardiac tissue perfusion (Vulnerability to inadequate blood flow to the cardiac tissues, which can lead to compromised oxygen and nutrient delivery to the heart muscle)',
      'Risk for Ineffective cerebral tissue perfusion (Vulnerability to inadequate blood flow to the brain tissues which can lead to compromised oxygen and nutrient delivery to the brain cells)',
      'Risk for Ineffective peripheral tissue perfusion (Vulnerability to inadequate blood flow to peripheral tissues, which can lead to compromised tissue oxygenation and nutrient delivery)',
      'Risk for imbalanced fluid volume (Vulnerability to shifts in fluids levels that could affect blood pressure regulation)',
      'Risk for injury (Vulnerable to physical harm due to various factors or conditions)',
      'Risk for urinary retention (Vulnerable to the inability to fully empty the bladder, which can lead to urinary stasis and potential complications)',
      'Risk for vascular trauma (Vulnerability to injury or damage to the blood vessels, which can lead to other vascular complications)',
      'Risk for cardiovascular complications (Vulnerability to heart related issues due to the effects of prolonged high blood glucose levels (Susceptibility to potential heart related issues due to unstable blood pressure))',
      'Risk for cerebrovascular complications (Vulnerability to neurological issues related to blood pressure changes, such as risk of stroke)',
      'Risk for orthostatic hypotension (Susceptibility to a drop in blood pressure upon changes in position, increasing the risk of falls or fainting)',
    ],
  },
  {
    id: 'riskForBloodGlucose',
    type: 'CHECKBOX',
    title: 'Risk for Blood Glucose',
    hasOtherOption: true,
    isRequired: true,
    showToolTipForChoices: true,
    notRequiredIf: ['riskForBloodPressure', 'riskForRespiratory'],
    choices: [
      'Risk for hyperglycemia (Vulnerability to elevated blood glucose levels, which may lead to complications for individuals at risk for diabetes or other conditions)',
      'Risk for hypoglycemia (Susceptibility to low blood glucose levels, which can lead to adverse effects and complications)',
      'Risk for unstable blood glucose control (Vulnerability to fluctuations in blood glucose levels, potentially leading to poor glycemic control)',
      'Risk for Diabetic ketoacidosis (Susceptibility to a serious complication of diabetes characterized by high blood glucose levels, leading to dehydration and altered mental status)',
      'Risk for Hyperosmolar Hyperglycemic State (Vulnerability to a condition with extremely high blood glucose levels, leading to dehydration and altered mental status)',
      'Risk for Impaired Tissue Integrity (Susceptibility to compromised skin or tissue integrity due to poor circulation and high blood glucose levels)',
    ],
  },
  {
    id: 'riskForRespiratory',
    type: 'CHECKBOX',
    title: 'Risk for Respiratory',
    hasOtherOption: true,
    isRequired: true,
    showToolTipForChoices: true,
    notRequiredIf: ['riskForBloodPressure', 'riskForBloodGlucose'],
    choices: [
      'Risk for Impaired Respiratory Function (Susceptibility to reduced lung capacity or impaired respiratory mechanics, affecting oxygen exchange)',
      'Risk for Decreased Oxygen Saturation (Vulnerability to lower-than-normal levels of oxygen saturation in the blood, which could lead to tissue hypoxia)',
      'Risk for Impaired Gas Exchange (Susceptibility to inadequate oxygenation and carbon dioxide elimination due to factors such as respiratory conditions or compromised lung function)',
      'Risk for Hypoxemia (Vulnerability to lower than normal levels of oxygen in the blood, potentially leading to tissue damage)',
      'Risk for suicide behavior (Vulnerability to disturbances in the levels of electrolytes in the body, which can impact various physiological processes)',
    ],
  },
  {
    id: 'asEvidencedBy',
    type: 'CHECKBOX',
    title: 'As evidenced by.... (Risk Factors (subjective/objective))',
    hasOtherOption: true,
    isRequired: true,
    choices: [
      'Low heart rate',
      'Uncontrolled blood pressure',
      'Lethargic',
      'Dizzy',
      'Altered mental status',
      'History of falls',
      'Adult BMI greater than 25 kg/m2',
      'Reported or observed dysfunctional eating patterns',
      'Sedentary lifestyle',
      'Verbal report of fatigue or weakness',
      'Abnormal heart rate or BP response to activity',
      'Exertional discomfort or dyspnea',
      'Verbal reports of throbbing pain located in suboccipital region, present on awakening and disappearing spontaneously after being up and about',
      'Reluctance to move head, rubbing head, avoidance of bright lights and noise, wrinkled brow, clenched fists',
      'Changes in appetite',
      'Reports of stiffness of neck, dizziness, blurred vision, nausea, and vomiting',
      'Smoking',
      'Chronic Kidney Disease',
      'Unhealthy diet',
      'Physical Inactivity',
      'Headache',
      'Blurred Vision',
      'Flu',
      'Pneumonia',
      'Excessive thirst',
      'Frequent urination',
    ],
  },
];
