import dataService from 'services/dataService';
import format from 'date-fns/format';
import {
  API_QUESTIONS,
  API_PATIENT_DETAILS,
  API_CARE_LOCATIONS,
  API_PATIENT_CARE_LOCATIONS,
  apiCcmPatientInteractiveTime,
  API_CARE_PLAN,
  API_MEDICATION,
  API_MEDICAL_HISTORY,
  API_PATIENT_DIAGNOSES,
} from 'services/dataService/ccmResources';
import { apiUrlGenerator } from 'utils/useTable';

const INITIAL_STATE = {
  questions: {},
  answers: {},
  patient: {
    profile: {},
    clinic: {},
    agency: {},
    doctor: {},
    diagnosis: {},
    emr: {},
  },
  careLocations: null,
  interactiveTime: {},
  dates: [],
  medications: [],
  activeMedications: [],
  medicalHistory: [],
  allMedicalHistory: [],
  icdCodes: [],
  allIcdCodes: [],
};

const getDefaultAnswerGuid = (question) => {
  if (question.patientAnswer) {
    return question.patientAnswer?.answerGuid;
  }
  const answerWithZeroScore = question.answers.filter(
    (answer) => answer.score === 0,
  )[0];
  return answerWithZeroScore.guid;
};

const getDefautlAnswers = (groups) => {
  const defaultAnswers = [];
  groups.forEach(({ questions }) => {
    const answersByGroup = {};
    questions.forEach((question) => {
      answersByGroup[question.guid] = getDefaultAnswerGuid(question);
    });
    defaultAnswers.push(answersByGroup);
  });

  return defaultAnswers;
};

const convertAnswersToBackend = (answers) =>
  Object.keys(answers).map((key) => ({
    questionGuid: key,
    answerGuid: answers[key],
  }));

const ccmPatientDetails = {
  state: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    setQuestions: (state, payload) => ({
      ...state,
      questions: payload,
    }),
    setPatientDetails: (state, payload) => ({
      ...state,
      patient: payload,
    }),
    setInteractiveTime: (state, payload) => ({
      ...state,
      interactiveTime: payload,
    }),
    setFilterDates: (state, payload) => ({
      ...state,
      dates: payload,
    }),
    setAnswers: (state, payload) => ({
      ...state,
      answers: payload,
    }),
    setMedications: (state, payload) => ({
      ...state,
      medications: payload,
    }),
    setAllActiveMedications: (state, payload) => ({
      ...state,
      activeMedications: payload,
    }),
    setAnswer: (state, { group, questionGuid, answerGuid }) => {
      const answersGroup = state.answers[group];
      answersGroup[questionGuid] = answerGuid;
      return {
        ...state,
        answers: { ...state.answers },
      };
    },
    setCareLocations: (state, payload) => ({
      ...state,
      careLocations: payload,
    }),
    setMedicalHistory: (state, payload) => ({
      ...state,
      medicalHistory: payload,
    }),
    setAllMedicalHistory: (state, payload) => ({
      ...state,
      allMedicalHistory: payload,
    }),
    setIcdCodes: (state, payload) => ({
      ...state,
      icdCodes: payload,
    }),
    setAllIcdCodes: (state, payload) => ({
      ...state,
      icdCodes: payload,
    }),
    setCcmHoldingStatus: (state, payload) => ({
      ...state,
      patient: {
        ...state.patient,
        profile: {
          ...state.patient.profile,
          ccmHoldingStatus: payload.ccmHoldingStatus,
          ccmHoldingStatusStartDate: payload.ccmHoldingStatusStartDate,
        },
      },
    }),
  },
  effects: (dispatch) => ({
    async getPatientDetails(patientGuid) {
      const { data, error } = await dataService.getOne(
        API_PATIENT_DETAILS,
        patientGuid,
      );

      if (data) {
        dispatch.ccmPatientDetails.setPatientDetails(data);
      }
      return {
        data,
        error,
      };
    },
    async getInteractiveTime({ patientGuid, date }) {
      const { data } = await dataService.getList(
        apiCcmPatientInteractiveTime(patientGuid, date),
      );
      if (data) {
        dispatch.ccmPatientDetails.setInteractiveTime(data);
        dispatch.patient.setInteractiveTime(data);
      }
    },
    async applyDateFilter(dates) {
      if (dates.length === 0) {
        dispatch.ccmPatientDetails.setFilterDates([]);
      } else {
        const [startDate, endDate] = dates;
        dispatch.ccmPatientDetails.setFilterDates([startDate, endDate]);
      }
    },
    async getQuestions(patientGuid) {
      const { data } = await dataService.getOnly(API_QUESTIONS, {
        patientGuid,
      });

      if (data) {
        dispatch.ccmPatientDetails.setAnswers(getDefautlAnswers(data.groups));
        dispatch.ccmPatientDetails.setQuestions(data);
      } else {
        dispatch.ccmPatientDetails.setQuestions({});
      }
    },
    async getCarePlanPDF({ patientGuid, type }, state) {
      const [startTime, endTime] = state.ccmPatientDetails.dates;
      const { error, data } = await dataService.getOnly(API_CARE_PLAN, {
        patientGuid,
        type,
        startTime: format(startTime, 'yyyy-MM-dd HH:mm:s'),
        endTime: format(endTime, 'yyyy-MM-dd HH:mm:s'),
      });
      return { error, data };
    },
    async getCareLocations(patientGuid) {
      const { data } = await dataService.getOnly(API_CARE_LOCATIONS, {
        patientGuid,
      });

      if (data) {
        dispatch.ccmPatientDetails.setCareLocations(data.items);
      } else {
        dispatch.ccmPatientDetails.setCareLocations(null);
      }
    },
    async submitAllCareLocations({ patientGuid, careLocations }) {
      const response = await dataService.createOne(
        API_PATIENT_CARE_LOCATIONS(patientGuid),
        careLocations,
      );
      if (!response.error) {
        await dispatch.ccmPatientDetails.getCareLocations(patientGuid);
      }

      return response;
    },
    async submitCareLocations(postData, state) {
      const careLocationsState = state.ccmPatientDetails.careLocations;
      let response;

      if (postData.guid) {
        response = await dataService.updateOnly(API_CARE_LOCATIONS, postData);
        if (!response.error) {
          const updated = careLocationsState.map((location) => {
            if (location.guid === response.data.guid) {
              return response.data;
            }
            return location;
          });
          dispatch.ccmPatientDetails.setCareLocations(updated);
        }
      } else {
        response = await dataService.createOne(API_CARE_LOCATIONS, postData);
        if (!response.error) {
          dispatch.ccmPatientDetails.setCareLocations([
            ...careLocationsState,
            response.data,
          ]);
        }
      }

      return response;
    },
    async putQuestions({ patientGuid, answers }) {
      const questionAnswers = {
        patientGuid,
        answers: convertAnswersToBackend(answers),
      };

      const response = await dataService.updateOnly(
        API_QUESTIONS,
        questionAnswers,
      );

      if (response.data) {
        dispatch.ccmPatientDetails.setQuestions(response.data);
        return { success: true };
      }
      return { success: false, msg: response.name };
    },
    async getActiveMedications(patientGuid) {
      const url = API_MEDICATION;
      const items = 1000;
      const page = 1;
      const filters = {
        patientGuid,
        isActive: true,
      };
      const { data } = await dataService.getList(
        apiUrlGenerator(url, items, page, filters),
      );

      if (data) {
        dispatch.ccmPatientDetails.setAllActiveMedications(data.items);
      } else {
        dispatch.ccmPatientDetails.setAllActiveMedications([]);
      }

      if (data?.items?.length) {
        return data.items;
      }
    },
    async getAllMedications(patientGuid) {
      const url = API_MEDICAL_HISTORY;
      const items = 1000;
      const page = 1;
      const filters = {
        patientGuid,
      };
      const { data } = await dataService.getList(
        apiUrlGenerator(url, items, page, filters),
      );

      if (data) {
        dispatch.ccmPatientDetails.setAllMedicalHistory(data.items);
      } else {
        dispatch.ccmPatientDetails.setAllMedicalHistory([]);
      }

      if (data?.items?.length) {
        return data.items;
      }
    },
    async getAllIcdCodes(filters) {
      const url = API_PATIENT_DIAGNOSES;
      const items = 1000;
      const page = 1;
      const { data } = await dataService.getList(
        apiUrlGenerator(url, items, page, filters),
      );

      if (data) {
        dispatch.ccmPatientDetails.setAllIcdCodes(data.items);
      } else {
        dispatch.ccmPatientDetails.setAllIcdCodes([]);
      }

      if (data?.items?.length) {
        return data.items;
      }
    },
  }),
};

export default ccmPatientDetails;
