import { useTranslation } from 'react-i18next';
import { appointmentMethodPresenter } from 'infrastructure/functions/appointments';
import { prepareArrToCopy } from 'infrastructure/functions/prepare-arr-to-copy';

import s from './style.module.scss';
import ColumnForm, { ColumnFormBoldItem, ColumnFormItem } from '../form';

import type { CcmAgentItem } from 'infrastructure/hooks/ccmAgentDashboard/types';
import type { FC } from 'react';

interface IProps {
  item: CcmAgentItem;
}

const CurrentMonthAppointment: FC<IProps> = ({ item }) => {
  const { t } = useTranslation();
  const { appointmentMethod, date, completedBy } =
    item.currentMonthAppointment || {};
  const method = appointmentMethodPresenter(appointmentMethod);

  return (
    <ColumnForm short>
      <ColumnFormBoldItem value={`${t('labels.currentMonthsAppointment')}:`} />
      <ColumnFormItem
        oneLine
        label={t('labels.completedOn')}
        value={date || '—'}
      />
      <ColumnFormItem
        oneLine
        label={t('labels.completionMethod')}
        value={method || '—'}
      />
      <ColumnFormItem
        oneLine
        label={t('labels.CompletedBy')}
        value={completedBy || '—'}
      />
    </ColumnForm>
  );
};

const Appointments: FC<IProps> = ({ item }) => {
  const { t } = useTranslation();

  const { patient, icdCodes } = item;
  const { address, city, state, zipPostalCode } = patient;

  return (
    <div className={s['main-wrapper']}>
      <div className={s.top}>
        <CurrentMonthAppointment item={item} />
      </div>
      <div className={s.bottom}>
        <ColumnForm>
          <ColumnFormItem
            label={t('labels.ICD10Codes')}
            value={icdCodes.map(({ code }) => code).join(', ') || '—'}
          />
          <ColumnFormItem
            label={t('labels.addressLine')}
            value={
              prepareArrToCopy([address, city, state, zipPostalCode]) || '—'
            }
          />
        </ColumnForm>
      </div>
    </div>
  );
};

export default Appointments;
