import { AdherenceDevices } from './adherence-devices';
import { AdherencePatient } from './adherence-patient';
import { AdherenceDoctor } from './adherence-doctor';
import { AdherenceInsurance } from './adherence-insurance';

import type { IAdherence } from 'infrastructure/interfaces';
import type { adherenceStatus } from 'infrastructure/types';

const getAdherenceStatus = (adherence: number): adherenceStatus => {
  const value = Math.round(adherence * 100);
  if (value < 36) return 'danger';
  if (value < 66) return 'warning';
  return 'normal';
};

export class Adherence {
  adherence: number;

  adherencePercent: number;

  adherenceStatus: adherenceStatus;

  successDayCount: number;

  patientGuid: string;

  lastReading: string;

  devices: AdherenceDevices;

  patient: AdherencePatient;

  doctor: AdherenceDoctor;

  insurance?: AdherenceInsurance;

  constructor(data: IAdherence) {
    this.adherence = data.adherence;
    this.adherencePercent = Math.round(data.adherence * 100);
    this.adherenceStatus = getAdherenceStatus(data.adherence);
    this.successDayCount = data.successDayCount;
    this.patientGuid = data.patientGuid;
    this.lastReading = data.lastReading;
    this.devices = new AdherenceDevices(data.devices);
    this.patient = new AdherencePatient(data.patient);
    this.doctor = new AdherenceDoctor(data.doctor);
    this.insurance = data.insurance
      ? new AdherenceInsurance(data.insurance)
      : undefined;
  }
}
