import i18next from 'i18next';
import { clinicStatusesDataSource } from 'infrastructure/data-sources/clinic-statuses';

import SlaColumn from './sla';
import ClinicColumn from './clinic';
import InfoColumn from './info';
import s from './styles.module.scss';

import type { TableProps } from 'antd';
import type { Clinic } from 'infrastructure/classes/clinic';

export const clinicColumns = ({
  limit = 100,
  page = 1,
}): TableProps<Clinic>['columns'] => {
  return [
    {
      title: '№',
      key: 'guid',
      align: 'center',
      width: '50px',
      render(value, record, index) {
        let order = index + 1;
        if (page > 1) {
          return <b data-cy="clinicListTable-№">{(order += limit * page)}</b>;
        }
        return <b data-cy="clinicListTable-№">{order}</b>;
      },
    },
    {
      title: i18next.t('labels.sla'),
      key: 'serviceDeliveryThresholds',
      width: '55px',
      render(_, record) {
        return <SlaColumn hours={record.serviceDeliveryThresholds} />;
      },
    },
    {
      title: i18next.t('labels.clinic'),
      key: 'legalname',
      width: '335px',
      render(value, record) {
        return <ClinicColumn data={record} />;
      },
    },
    {
      title: i18next.t('labels.info'),
      key: 'address',
      render(_, record) {
        return <InfoColumn data={record} />;
      },
    },
    {
      title: i18next.t('labels.providerServiceType'),
      key: 'providerServiceType',
      width: '240px',
      render(_, record) {
        return (
          <span
            data-cy="clinicListTable-providerServiceType"
            className={s.address}
          >
            {record.providerServiceType.join(', ')}
          </span>
        );
      },
    },
    {
      title: i18next.t('labels.address'),
      key: 'address',
      width: '240px',
      render(_, record) {
        return (
          <span data-cy="clinicListTable-address" className={s.address}>
            {record.address}
          </span>
        );
      },
    },
    {
      title: i18next.t('labels.status'),
      key: 'status',
      width: '120px',
      render(_, record) {
        const status = clinicStatusesDataSource().findByField(
          'id',
          record.status,
        );
        return (
          <span
            data-cy="clinicListTable-status"
            className={s.status}
            style={{ color: status?.color }}
          >
            {status?.name}
          </span>
        );
      },
    },
  ];
};
