import BaseTableReport from 'components/atoms/base-table/report/report';
import { useTranslation } from 'react-i18next';

import { useColumns } from './useColumns';

import type {
  EligibilityItem,
  Filters,
} from 'infrastructure/hooks/eligibilityTool/types';
import type { FC } from 'react';

interface Props {
  data: EligibilityItem[] | undefined;
  count: number | undefined;
  filters: Filters;
  isLoading: boolean;
  changePage: (page: number) => void;
}

const EligibilityItemsTable: FC<Props> = ({
  changePage,
  count,
  data,
  filters,
  isLoading,
}) => {
  const columns = useColumns();
  const { t } = useTranslation();

  return (
    <div>
      <BaseTableReport
        isLoading={isLoading}
        columns={columns}
        headerTitle={t('labels.eligibilityProcessPatients')}
        dataSource={data}
        pagination={{
          current: filters.page,
          pageSize: filters.items,
          total: count,
          onChange: changePage,
        }}
        showCount
        anchor
      />
    </div>
  );
};

export default EligibilityItemsTable;
