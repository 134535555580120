import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import SmallDropdownArrow from 'components/atoms/icons/dropdownSelectArrow';

const NavbarDropdown = ({ label, options = [], dataCy }) => {
  const preparedLabel = label.toLowerCase().replace(' ', '-');

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle
        nav
        className="navbar-dropdown-title"
        data-cy={dataCy}
        role="button"
        aria-controls={`${preparedLabel}-nav-menu`}
        aria-labelledby={`${preparedLabel}-toggle`}
      >
        <div
          className="navbar-dropdown-title-text"
          id={`${preparedLabel}-toggle`}
        >
          {label}
        </div>
        <SmallDropdownArrow className="dropdown-arrow-svg" />
      </DropdownToggle>
      {options.length > 0 && (
        <DropdownMenu
          className="navbar-dropdown"
          id={`${preparedLabel}-nav-menu`}
        >
          {options.map((option, index) => {
            if (option.type === 'windows-link') {
              return (
                <DropdownItem
                  tag="a"
                  key={index}
                  href={option.link}
                  target="_blank"
                  data-cy={option.dataCy}
                  className="navbar-dropdown-item"
                  aria-label={option?.ariaLabel ?? option.label}
                >
                  {option.label}
                </DropdownItem>
              );
            }
            return (
              <DropdownItem
                tag={Link}
                key={index}
                to={option.link}
                className="navbar-dropdown-item"
                data-cy={option.dataCy}
                aria-label={option?.ariaLabel ?? option.label}
              >
                {option.label}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      )}
    </UncontrolledDropdown>
  );
};

NavbarDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
  dataCy: PropTypes.string,
};

export default NavbarDropdown;
