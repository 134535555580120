import dataService from 'services/dataService';
import { API_MESSAGES_VOCABULARY } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

import type { ISmsTemplate } from 'infrastructure/interfaces/i-sms-template';

export const getSmsTemplatesFn = async () => {
  const { data, error } = await dataService.getList<ISmsTemplate[]>(
    API_MESSAGES_VOCABULARY,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
