/**
 * Extracts content from a string that is enclosed within a specified start and end string.
 *
 * @param {string} inputString - The string to extract content from.
 * @param {Object} [options={}] - The options for extraction.
 * @param {string} [options.start] - The start string.
 * @param {string} [options.end] - The end string.
 * @returns {string} The extracted content. If no content can be extracted, an empty string is returned.
 *
 * @example
 * ```js
 * extractContent("text (some parentheses)") // -> "some parentheses"
 * ```
 */
export const extractContent = (
  inputString,
  { start = '(', end = ')' } = {},
) => {
  const startIndex = inputString.indexOf(start);
  const endIndex = inputString.lastIndexOf(end);

  if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
    return inputString.substring(startIndex + start.length, endIndex).trim();
  }
  return '';
};
