import React, { useRef, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format, subMonths } from 'date-fns';
import _ from 'lodash';
import forms from 'constants/forms';
import common from 'constants/common';
import useUser from 'utils/useUser';
import { hasPatientAcceptanceRole } from 'utils/accessManager/helpers';
import AutoCompletePatient from 'components/molecules/autocompleteFilter/patient';
import AutoCompleteClinic from 'components/molecules/autocompleteFilter/clinic';
import AutoCompleteDoctor from 'components/molecules/autocompleteFilter/doctor';
import AutoCompleteCareAgent from 'components/atoms/autocomplete';
import { API_ADMIN_PANEL_AGENCY_DOCTORS } from 'services/dataService/resources';
import MedIcon from 'components/atoms/icons/medIcon';
import CalendarIcon from 'components/atoms/icons/calendarIcon';
import BaseSelect from 'components/atoms/baseSelect';
import { CCMRoles } from 'infrastructure/enums';

const DEFAULT_VALUE = forms.ccmPatientStatuses[0];
const DEFAULT_PATIENT_FILTER = forms.patientFilterOptions[0];
const DEFAULT_CLINIC_FILTER = forms.clinicFilterOptions[0];

const generateTimeRanges = () => {
  const today = new Date();
  const values = [];
  for (let i = 0; i < 12; i++) {
    const date = subMonths(today, i);
    const billingYear = date.getFullYear();
    const billingMonth = date.getMonth() + 1;
    const label = format(new Date(date), 'MMM-yyyy');
    values.push({
      value: [billingYear, billingMonth],
      label,
    });
  }
  return values;
};

const Toolbar = ({
  setFilters,
  filters,
  defaultFilters,
  hasAccessToCareAgent,
}) => {
  const [showResetButton, setShowResetButton] = useState(false);
  const patientInputRef = useRef(null);
  const providerInputRef = useRef(null);
  const caseInputRef = useRef(null);
  const admissionInputRef = useRef(null);
  const clinicInputRef = useRef(null);
  const [monthSelect, setMonthSelect] = useState(null);
  const { roleNames } = useUser();
  const iamPatientAcceptance = useMemo(
    () => hasPatientAcceptanceRole(roleNames),
    [],
  );
  const ccmStatusOptionsByRole = iamPatientAcceptance
    ? forms.ccmPatientStatusesForPatientAcceptance
    : forms.ccmPatientStatuses;
  const ccmStatusOptions = useMemo(() => [...ccmStatusOptionsByRole], []);
  const timeRanges = generateTimeRanges();

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 12,
    colors: {
      ...theme.colors,
      ...common.primaryColors,
    },
  });

  const statusValue = (statusName) => {
    const value = ccmStatusOptions.find(
      (element) => element.value === statusName,
    );
    return value;
  };

  const handleMultiSelect = (selectedOptions) => {
    if (selectedOptions.length < 1) {
      setFilters({ ccmStatus: ccmStatusOptions[0].value });
      return;
    }
    const allOptionSelected =
      selectedOptions[selectedOptions.length - 1].value === undefined;
    if (allOptionSelected) {
      setFilters({ ccmStatus: ccmStatusOptions[0].value });
    } else {
      const values = selectedOptions
        .filter((option) => option.value !== undefined)
        .map((option) => option.value)
        .join(',');
      setFilters({ ccmStatus: values });
    }
  };

  const handleMonth = (t) => {
    setFilters({
      billingYear: t.value[0],
      billingMonth: t.value[1],
    });
    setMonthSelect(t.value[1]);
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
    setMonthSelect('');
    setShowResetButton(false);
    if (patientInputRef.current) {
      patientInputRef.current.resetQuery();
    }
    if (providerInputRef.current) {
      providerInputRef.current.resetQuery();
    }
    if (caseInputRef.current) {
      caseInputRef.current.resetQuery();
    }
    if (admissionInputRef.current) {
      admissionInputRef.current.resetQuery();
    }
    if (clinicInputRef.current) {
      clinicInputRef.current.resetQuery();
    }
  };

  const ccmRoleNames = CCMRoles.map(
    (role) => `roleName=${encodeURIComponent(role)}`,
  ).join('&');

  useEffect(() => {
    if (!_.isEqual(filters, defaultFilters)) setShowResetButton(true);
    else if (_.isEqual(filters, defaultFilters)) setShowResetButton(false);
  }, [filters]);

  return (
    <>
      <h3 className="ccm-activity__header-title">CCM Dashboard</h3>
      <div className="ccm-activity__row">
        <div className="ccm-activity__row-items">
          <AutoCompletePatient
            filter={DEFAULT_PATIENT_FILTER}
            handleClick={({ value }) => {
              setFilters({ patientGuid: value });
            }}
            ccmStatus={filters.ccmStatus}
            careAgentGuid={filters.careAgentGuid}
            isCcmPanel
            ref={patientInputRef}
          />
          <div className="ccm-activity__row-item" data-cy="ccm-status-filter">
            <BaseSelect
              isMulti
              options={ccmStatusOptions}
              defaultValue={DEFAULT_VALUE}
              theme={(theme) => selectTheme(theme)}
              classNamePrefix="select-type"
              value={statusValue(filters.ccmStatus)}
              isClearable={false}
              onChange={handleMultiSelect}
            />
          </div>
        </div>
        <div style={{ width: '5%' }}>
          <MedIcon className="ccm-activity__header-icon-svg" />
        </div>
      </div>

      <div className="ccm-activity__row-two">
        <div
          className="ccm-activity__row-two-item"
          data-cy="ccm-activity-header__doctor-filter"
        >
          <strong>Doctor</strong>
          <AutoCompleteDoctor
            filter={{ ...DEFAULT_PATIENT_FILTER }}
            handleClick={({ value }) => {
              setFilters({ doctorGuid: value });
            }}
            clinicGuid={filters.clinicGuid}
            ref={providerInputRef}
          />
        </div>
        {hasAccessToCareAgent && (
          <div className="ccm-activity__row-two-item">
            <strong>Case Manager</strong>
            <AutoCompleteCareAgent
              filter={DEFAULT_PATIENT_FILTER}
              handleClick={({ value }) => {
                setFilters({ caseManagerGuid: value });
              }}
              getApi={(queryString) =>
                `${API_ADMIN_PANEL_AGENCY_DOCTORS}?fullName=${queryString}&${ccmRoleNames}`
              }
              dataCy="ccm-activity-header__case-manager-filter"
              ref={caseInputRef}
            />
          </div>
        )}
        {hasAccessToCareAgent && (
          <div className="ccm-activity__row-two-item">
            <strong>Onboarding Agent</strong>
            <AutoCompleteCareAgent
              filter={DEFAULT_PATIENT_FILTER}
              handleClick={({ value }) => {
                setFilters({ onboardingAgentGuid: value });
              }}
              getApi={(queryString) =>
                `${API_ADMIN_PANEL_AGENCY_DOCTORS}?fullName=${queryString}&${ccmRoleNames}&roleName=patientAcceptance`
              }
              dataCy="ccm-activity-header__onboarding-agent-filter"
              ref={admissionInputRef}
            />
          </div>
        )}
        <div className="ccm-activity__row-two-item">
          <strong>Clinic</strong>
          <AutoCompleteClinic
            filter={DEFAULT_CLINIC_FILTER}
            handleClick={({ value }) => {
              setFilters({ clinicGuid: value });
            }}
            ref={clinicInputRef}
          />
        </div>

        <div
          className="ccm-activity__row-two-small-item dropdown"
          data-cy="ccm-dashboard-date-filter"
        >
          <div className="drop-button">
            <CalendarIcon
              className="ccm-activity__icon"
              width="24"
              height="26"
            />
          </div>
          <div className="dropdown-content">
            {timeRanges.map((t) => (
              <p
                data-cy={t.value}
                style={{
                  background: t.value[1] === monthSelect ? '#f1f1f1' : '',
                }}
                onClick={() => handleMonth(t)}
                key={t.value[1]}
              >
                {t.label}
              </p>
            ))}
          </div>
        </div>
        {showResetButton && (
          <button
            className="ccm-activity-reset"
            onClick={resetFilters}
            data-cy="reset-filters-button"
          >
            Reset Filters
          </button>
        )}
      </div>
    </>
  );
};

Toolbar.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
  defaultFilters: PropTypes.object,
  hasAccessToCareAgent: PropTypes.bool,
};

export default Toolbar;
