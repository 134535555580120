import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const Registration = ({ data }) => (
  <div className="admin-user-block">
    <span>Registration</span>
    <Table>
      <tbody>
        {data.createdAt && (
          <tr>
            <td>Registered At</td>
            <td>{data.createdAt}</td>
          </tr>
        )}
        {data.agencyId && (
          <tr>
            <td>ID</td>
            <td>{data.agencyId}</td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);

Registration.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Registration;
