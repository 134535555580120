import i18next from 'i18next';
import { ClinicRevenueChannel } from 'infrastructure/enums';

export interface IClinicRevenueChannelItem {
  id: ClinicRevenueChannel;
  name: string;
}

export const clinicRevenueChannelsDataSource =
  (): IClinicRevenueChannelItem[] => {
    return [
      {
        id: ClinicRevenueChannel.Medicare,
        name: i18next.t('clinicRevenueChannels.medicare'),
      },
      {
        id: ClinicRevenueChannel.TexasMedicaid,
        name: i18next.t('clinicRevenueChannels.texasMedicaid'),
      },
      {
        id: ClinicRevenueChannel.Commercial,
        name: i18next.t('clinicRevenueChannels.commercial'),
      },
      {
        id: ClinicRevenueChannel.FQHC,
        name: i18next.t('clinicRevenueChannels.fqhc'),
      },
      {
        id: ClinicRevenueChannel.Academia,
        name: i18next.t('clinicRevenueChannels.academia'),
      },
      {
        id: ClinicRevenueChannel.ChannelPartner,
        name: i18next.t('clinicRevenueChannels.channelPartner'),
      },
      {
        id: ClinicRevenueChannel.SelfInsuredPayer,
        name: i18next.t('clinicRevenueChannels.selfInsuredPayer'),
      },
      {
        id: ClinicRevenueChannel.Payer,
        name: i18next.t('clinicRevenueChannels.payer'),
      },
      {
        id: ClinicRevenueChannel.PrivatePay,
        name: i18next.t('clinicRevenueChannels.privatePay'),
      },
    ];
  };
