import { type FC } from 'react';
import PatientsAutocomplete from 'components/molecules/autocompleteFilter/patients';
import CcmInteractionStatusSelect from 'components/molecules/select/ccmInteractionStatus';
import AgencyDoctorsAutocomplete from 'components/molecules/autocompleteFilter/agencyDoctors';
import { CCMOnboardingAgentRoles, CCMRoles } from 'infrastructure/enums';
import { useTranslation } from 'react-i18next';
import CcmStatusAgentDashboardSelect from 'components/molecules/select/ccmStatusAgentDashboard';
import ScheduledWithAutocomplete from 'components/molecules/autocompleteFilter/scheduledWith';
import CompletedByAutocomplete from 'components/molecules/autocompleteFilter/completedBy';

import NextAppointmentDateRange from './nextAppointmentDateRange';
import s from './styles.module.scss';

import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/ccmAgentDashboard/types';

interface Props {
  filters: Filters;
  appendFilters: AppendFilters;
}

const Wrapper: FC = ({ children }) => {
  return <div className={s['filter-wrapper']}>{children}</div>;
};

const Body: FC<Props> = ({ appendFilters, filters }) => {
  const { t } = useTranslation();
  return (
    <div className={s.wrapper}>
      <Wrapper>
        <PatientsAutocomplete
          value={filters.patientGuid}
          onChange={(e) => appendFilters({ patientGuid: e })}
        />
      </Wrapper>
      <Wrapper>
        <AgencyDoctorsAutocomplete
          value={filters.ccmCaseManagerGuid || []}
          roles={CCMRoles}
          label={t('labels.ccmCaseManager')}
          onChange={(e) => appendFilters({ ccmCaseManagerGuid: e })}
        />
        <AgencyDoctorsAutocomplete
          value={filters.ccmOnboardingAgentGuid || []}
          roles={CCMOnboardingAgentRoles}
          label={t('labels.ccmOnboardingAgent')}
          onChange={(e) => appendFilters({ ccmOnboardingAgentGuid: e })}
        />
      </Wrapper>
      <Wrapper>
        <ScheduledWithAutocomplete
          value={[
            ...(filters.nextAppointmentScheduledWithDoctors || []),
            ...(filters.nextAppointmentScheduledWithAgencyDoctors || []),
          ]}
          roles={CCMRoles}
          label={t('labels.nextAppointmentScheduledWith')}
          appendFilters={appendFilters}
        />
      </Wrapper>
      <Wrapper>
        <CcmInteractionStatusSelect
          value={filters.ccmInteractionStatus}
          onChange={(e) => appendFilters({ ccmInteractionStatus: e })}
        />
      </Wrapper>
      <Wrapper>
        <CcmStatusAgentDashboardSelect
          value={filters.ccmStatus}
          onChange={(e) => appendFilters({ ccmStatus: e })}
        />
        <NextAppointmentDateRange
          filters={filters}
          appendFilters={appendFilters}
        />
      </Wrapper>
      <Wrapper>
        <CompletedByAutocomplete
          value={[
            ...(filters.currentMonthAppointmentCompletedByDoctors || []),
            ...(filters.currentMonthAppointmentCompletedByAgencyDoctors || []),
          ]}
          roles={CCMRoles}
          label={t('labels.currentMonthAppointmentCompletedBy')}
          appendFilters={appendFilters}
        />
      </Wrapper>
    </div>
  );
};

export default Body;
