import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import enUs from 'date-fns/locale/en-US';
import 'react-datepicker/dist/react-datepicker.css';
import { parse } from 'date-fns';
import common from 'constants/common';

registerLocale('en-Us', enUs);

const CustomDatePicker = ({ onSelect, inDate, customFormat }) => {
  const innerDate = parse(
    inDate,
    customFormat || common.dateFormats.birthDate,
    new Date(),
  );
  const [startDate, setStartDate] = useState(new Date());
  if (
    !Number.isNaN(Date.parse(innerDate)) &&
    `${innerDate.getFullYear()} ${innerDate.getMonth()} ${innerDate.getDate()}` !==
      `${startDate.getFullYear()} ${startDate.getMonth()} ${startDate.getDate()}`
  ) {
    setStartDate(innerDate);
  }
  const years = Array(new Date().getFullYear() + 10 - 1800)
    .fill()
    .map((d, i) => i + 1800)
    .reverse();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <div className="range-datepicker">
      <div className="range-datepicker--datepicker" data-cy="datepicker">
        <DatePicker
          locale="en-Us"
          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            onSelect(date);
          }}
          inline
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <div className="range-datepicker-select">
              <select
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) => {
                  changeMonth(months.indexOf(value));
                }}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <select
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
        />
      </div>
    </div>
  );
};

CustomDatePicker.propTypes = {
  onSelect: PropTypes.func,
  inDate: PropTypes.string,
  customFormat: PropTypes.string,
};

export default CustomDatePicker;
