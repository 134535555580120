import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useState, type FC } from 'react';
import useUser from 'utils/useUser';
import { isAgencyDoctor } from 'utils/userTypeHelper';
import { getDoctorsAndAgencyDoctors } from 'infrastructure/hooks/ccmAgentDashboard/fetchers';

import BaseAutocomplete from './base';

import type { Roles } from 'infrastructure/enums';
import type { Option } from './base';
import type { IDoctorsAndAgencyDoctorSelect } from 'infrastructure/interfaces';
import type { AppendFilters } from 'infrastructure/hooks/ccmAgentDashboard/types';

interface Props {
  value?: Option[];
  appendFilters: AppendFilters;
  label?: string;
  roles?: Array<Roles>;
}

const CompletedByAutocomplete: FC<Props> = ({
  appendFilters,
  value,
  roles,
  label,
}) => {
  const [selectOptions, setSelectOptions] = useState<
    IDoctorsAndAgencyDoctorSelect[] | []
  >([]);
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { guid: userGuid, userType } = useUser();

  const defaultOptions = [
    {
      id: 'all',
      value: 'all',
      label: 'All',
    },
    {
      id: 'empty',
      value: 'empty',
      label: 'Empty',
    },
  ];

  if (isAgencyDoctor(userType)) {
    defaultOptions.splice(1, 0, {
      id: userGuid,
      value: userGuid,
      label: 'Me',
    });
  }

  const handleOnChange = (options: Option | Option[]) => {
    const currentMonthAppointmentCompletedByDoctors: Option[] = [];
    const currentMonthAppointmentCompletedByAgencyDoctors: Option[] = [];

    let items = Array.isArray(options) ? options : [options];
    const isAllSelected = items.some((item) => item.value === 'all');
    if (isAllSelected) {
      setOpen(false);
      appendFilters({
        currentMonthAppointmentCompletedByDoctors: [],
        currentMonthAppointmentCompletedByAgencyDoctors: [],
      });
      return;
    }

    if (items.at(0)?.value === 'empty' && items.length > 1) {
      items = items.filter((item) => item.value !== 'empty');
    }

    const emptyValue = items.find((item) => item.value === 'empty');
    if (emptyValue) {
      appendFilters({
        currentMonthAppointmentCompletedByDoctors: [],
        currentMonthAppointmentCompletedByAgencyDoctors: [emptyValue],
      });
      return;
    }

    items.forEach((item: Option) => {
      const scheduledWithType = selectOptions.find(
        (e) => e.guid === item.value,
      )?.type;

      if (scheduledWithType === 'doctor') {
        currentMonthAppointmentCompletedByDoctors.push(item);
      } else {
        currentMonthAppointmentCompletedByAgencyDoctors.push(item);
      }
    });
    appendFilters({
      currentMonthAppointmentCompletedByDoctors,
      currentMonthAppointmentCompletedByAgencyDoctors,
    });
  };

  return (
    <BaseAutocomplete
      label={label || t('labels.currentMonthAppointmentCompletedBy')}
      placeholder={t('labels.all')}
      mode="multiple"
      fetchOptions={(args) =>
        getDoctorsAndAgencyDoctors({
          ...args,
          queryClient,
          roles,
          callback: setSelectOptions,
        })
      }
      value={value}
      onChange={handleOnChange}
      defaultOptions={defaultOptions}
      setOpenCallback={setOpen}
      open={open}
    />
  );
};

export default CompletedByAutocomplete;
