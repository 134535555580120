import { promiseWithResolvers } from 'utils/helpers/promise';

export const scrollToTop = (htmlElementId?: string, topOffset = 110) => {
  const element = htmlElementId && document.getElementById(htmlElementId);
  if (element) {
    const yCoordinate =
      element.getBoundingClientRect().top + window.scrollY - topOffset;
    window.scrollTo({ top: yCoordinate, behavior: 'smooth' });
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

export const scrollToTopAsync = async (
  htmlElementId: string,
  topOffset = 110,
) => {
  const { promise, resolve, reject } = promiseWithResolvers();
  const element = document.getElementById(htmlElementId);

  if (!element) {
    return reject(new Error(`Element with id ${htmlElementId} not found`));
  }

  const yCoordinate =
    element.getBoundingClientRect().top + window.scrollY - topOffset;

  window.scrollTo({ top: yCoordinate, behavior: 'smooth' });

  const checkIfDone = () => {
    const currentScroll = window.scrollY;

    if (Math.abs(currentScroll - yCoordinate) < 1) {
      resolve('');
    } else {
      requestAnimationFrame(checkIfDone);
    }
  };

  requestAnimationFrame(checkIfDone);

  return promise;
};
