import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Button from 'components/atoms/button';
import ModalPopup from 'components/organisms/modal';
import { showResult } from 'infrastructure/hooks/utils';

const DischargePatientModalMobile = ({
  isOpen,
  toggle,
  patientGuid,
  options,
  loading,
  submit,
  modalType = 'rpm',
}) => {
  const buttonLabel = modalType === 'rpm' ? 'Discharge' : 'Unenroll';

  const handleSubmit = async (values) => {
    const postData = {
      patientGuid,
      dischargeStatus: values.status,
    };
    const { error } = await submit(postData);
    showResult(error, null, null, toggle);
  };

  return (
    <ModalPopup
      isOpen={isOpen}
      toggle={toggle}
      title="Status"
      className="popup discharge-popup"
    >
      <Formik initialValues={{ status: '' }} onSubmit={handleSubmit}>
        {() => (
          <Form>
            <div className="discharge-popup-content">
              <FormGroup tag="fieldset">
                {options.map((option) => (
                  <FormGroup check key={option.id}>
                    <Label check>
                      <Field
                        className="form-check-input"
                        data-cy="discharge-button"
                        type="radio"
                        name="status"
                        value={option.id}
                        disabled={loading}
                      />
                      {option.label}
                    </Label>
                  </FormGroup>
                ))}
                <div className="text-center">
                  <Button type="submit" text={buttonLabel} disabled={loading} />
                </div>
              </FormGroup>
            </div>
          </Form>
        )}
      </Formik>
    </ModalPopup>
  );
};

DischargePatientModalMobile.defaultProps = {
  isOpen: false,
};

DischargePatientModalMobile.propTypes = {
  toggle: PropTypes.func.isRequired,
  patientGuid: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
  submit: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
};

export default DischargePatientModalMobile;
