import { prepareDoctorName } from 'infrastructure/functions';

import type { IAgencyInfo } from 'infrastructure/interfaces';
import type { DateString, Nullable } from 'infrastructure/types';

export class AgencyInfo {
  guid: string;
  legalname: string;
  email: Nullable<string>;
  phone: Nullable<string>;
  fax: Nullable<string>;
  addressLine1: Nullable<string>;
  addressLine2: Nullable<string>;
  addresses: Nullable<string>;
  city: Nullable<string>;
  state: Nullable<string>;
  zip: Nullable<string>;
  npiNumber: Nullable<string>;
  stateIdNumber: Nullable<string>;
  taxIdNumber: Nullable<string>;
  taxonomyNumber: Nullable<string>;
  medicalIdTpiNumber: Nullable<string>;
  medicareNumber: Nullable<string>;
  agencyId: Nullable<string>;
  numberType: Nullable<string>;
  createdAt: DateString;
  createdBy: Nullable<string>;
  constructor(data: IAgencyInfo) {
    this.guid = data.guid;
    this.legalname = data.legalname;
    this.email = data.email;
    this.phone = data.phone;
    this.fax = data.fax;
    this.addressLine1 = data.addressLine1;
    this.addressLine2 = data.addressLine2;
    this.addresses = prepareDoctorName({
      firstName: data.addressLine1,
      lastName: data.addressLine2,
    });
    this.city = data.city;
    this.state = data.state;
    this.zip = data.zip;
    this.npiNumber = data.npiNumber;
    this.stateIdNumber = data.stateIdNumber;
    this.taxIdNumber = data.taxIdNumber;
    this.taxonomyNumber = data.taxonomyNumber;
    this.medicalIdTpiNumber = data.medicalIdTpiNumber;
    this.medicareNumber = data.medicareNumber;
    this.agencyId = data.agencyId;
    this.numberType = data.numberType;
    this.createdAt = data.createdAt;
    this.createdBy = data.createdBy;
  }
}
