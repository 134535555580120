import { makeid } from 'utils/helpers/makeid';

import type { IPatientPriorAuthorization } from 'infrastructure/interfaces';

type action = 'create' | 'delete' | 'update';

export class PatientPriorAuthorization {
  key: string;
  guid?: string;
  patientGuid: string;
  startDate?: Date;
  endDate?: Date;
  authorizationNumber?: string;
  action?: action;

  constructor(data: IPatientPriorAuthorization) {
    this.key = makeid(5);
    this.guid = data.guid;
    this.patientGuid = data.patientGuid;
    this.startDate = data.startDate ? new Date(data.startDate) : undefined;
    this.endDate = data.endDate ? new Date(data.endDate) : undefined;
    this.authorizationNumber = data.authorizationNumber;
  }

  static getInitialValues(patientGuid: string) {
    return new PatientPriorAuthorization({
      patientGuid,
      authorizationNumber: undefined,
      startDate: undefined,
      endDate: undefined,
    });
  }

  setAction(action?: action) {
    this.action = action;
  }
}
