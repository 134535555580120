/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import OnCallIcon from 'components/atoms/icons/onCallIcon';
import ActivityCheckBox from 'components/atoms/checkbox';
import ActivityComments from 'components/molecules/comments';
import ActivityStatus from 'components/atoms/activityStatus';
import common from 'constants/common';
import useUser from 'utils/useUser';
import { isCriticalOrAtRiskAlertingRole } from 'utils/accessManager/helpers';
import { isAgencyDoctor, isBiller } from 'utils/userTypeHelper';
import {
  fullNamePresenter,
  getActivityThresholdsValues,
  getLanguageLabelById,
} from 'utils';
import PatientNameHoldingStatusFlag from 'components/atoms/patientStatus/patientNameHoldingStatusFlag';
import ReadingItem from 'components/atoms/readingItem';
import ActivityCommentForm from 'components/molecules/activityCommentForm';
import SlaTimeRemainingRow from 'components/atoms/slaTimeRemainingRow';
import { COMMENT_SUBMIT_ERROR_NO_CHANGES } from 'constants/messages';
import BaseLoader from 'components/atoms/base-loader';
import Assignee from 'components/atoms/assignee';

const ActivityRow = ({
  reviewed,
  activity,
  handleCommentSubmit,
  handleReviewedCheck,
  togglePatientReserved,
  isOnCall,
  columnNumber,
  disabledTemplateModalButton,
  openTemplateModal,
  toggleAssignLoading,
  setToggleAssignLoading,
  isCommentGenerationLimitReached,
  onCommentGenerationStatusChange,
  consumablesArrivingDate,
}) => {
  const formRef = useRef();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { loginGuid, userType, roleNames } = useUser();
  const [submitCommentLockByAi, setSubmitCommentLockByAi] = useState(false);
  const { dates } = useSelector((state) => state.doctor);
  const slaReadingsFilter = useSelector(
    (state) => state.doctor.filters.slaReadings,
  );

  const {
    connect: { handleConnectPopoutOpen, connectDetails },
    doctor: { addComment, removeComment },
  } = useDispatch();

  const onClickConnect = (isRpmCall) => {
    if (isCriticalOrAtRiskAlertingRole(roleNames)) {
      return;
    }
    const details = {
      phone: activity.patient.phone,
      guid: activity.patient.guid,
      isRpmCall,
      readingId: activity.id,
      deviceId: activity.deviceId,
    };
    connectDetails(details);
    handleConnectPopoutOpen(true);
  };

  const commentChanged = (value) => {
    if (value) addComment(activity.id);
    else removeComment(activity.id);
  };

  const commentSubmitted = async (values) => {
    removeComment(activity.id);
    handleCommentSubmit(activity.id, activity.deviceId, values);
  };

  return (
    <tr className="activity-row">
      <td data-label="№">{columnNumber}</td>
      {slaReadingsFilter === 'true' && (
        <td data-label="Time Remaining in SLA" style={{ width: '1%' }}>
          <SlaTimeRemainingRow activity={activity} />
        </td>
      )}
      <td data-label="Status" className="status-row">
        <ActivityStatus
          status={
            slaReadingsFilter === 'true' ? 'slaReading' : activity.globalStatus
          }
        />
      </td>
      <td data-label="Time">
        <span>
          R:{' '}
          {format(
            new Date(parseInt(activity.createdAt)),
            common.dateFormats.dayOfWeek,
          )}
        </span>
        <span>
          {format(
            new Date(parseInt(activity.createdAt)),
            common.dateFormats.timeAPM,
          )}
        </span>
      </td>
      <td data-label="Patient" style={{ position: 'relative' }}>
        {isCriticalOrAtRiskAlertingRole(roleNames) ? (
          <div>
            <strong>
              {activity.patient.firstName} {activity.patient.lastName}
            </strong>
          </div>
        ) : (
          <div>
            <PatientNameHoldingStatusFlag
              firstName={activity.patient.firstName}
              lastName={activity.patient.lastName}
              displayFlag={!isBiller(roleNames)}
              holdingStatus={activity.patient.holdingStatus?.status}
              consumablesArrivingDate={consumablesArrivingDate}
              dataCy="dashboard__patient-link"
              to={`/patient/${activity.patient.guid}`}
            />
          </div>
        )}
        {activity.patient.language && (
          <div>
            <strong>
              lang:&nbsp;
              {getLanguageLabelById(activity.patient.language)}
            </strong>
          </div>
        )}
        <div>{fullNamePresenter(activity.doctor)}</div>
        {isOnCall ? (
          <div className="on-call">
            {activity.patient.phone}{' '}
            <OnCallIcon fill="#2C2543" height="14" width="14" />
          </div>
        ) : (
          <div
            onClick={() => onClickConnect(true)}
            className="activity-row-number"
          >
            <strong>{activity.patient.phone}</strong>
          </div>
        )}
        {isAgencyDoctor(userType) &&
          !isCriticalOrAtRiskAlertingRole(roleNames) &&
          !reviewed && (
            <>
              <Assignee
                activity={activity}
                loginGuid={loginGuid}
                setToggleAssignLoading={setToggleAssignLoading}
                toggleAssignLoading={toggleAssignLoading}
                togglePatientReserved={togglePatientReserved}
              />
              {toggleAssignLoading && (
                <div className="loader-overlay">
                  <BaseLoader loading width={14} height={14} />
                </div>
              )}
            </>
          )}
      </td>
      <td data-label="Reading">
        <ReadingItem activity={activity} />
        {getActivityThresholdsValues(
          activity.globalStatus,
          activity.value,
          activity.statuses,
          activity.patient?.threshold,
          activity.readingType,
        ).map((threshold) => (
          <span key={threshold}>{`(${threshold})`}</span>
        ))}
      </td>
      {!isCriticalOrAtRiskAlertingRole(roleNames) && (
        <td data-label="Comment" className="rpm-comment">
          <ActivityComments
            comments={activity.comments}
            patientGuid={activity.patient.guid}
          />
          <ActivityCommentForm
            isCommentGenerationLimitReached={isCommentGenerationLimitReached}
            onCommentGenerationStatusChange={onCommentGenerationStatusChange}
            valueChanged={commentChanged}
            handleSubmit={commentSubmitted}
            activity={activity}
            dates={dates}
            submitLoading={submitLoading}
            setSubmitLoading={setSubmitLoading}
            openTemplateModal={openTemplateModal}
            disabledTemplateModalButton={disabledTemplateModalButton}
            submitCommentLockByAi={submitCommentLockByAi}
            setSubmitCommentLockByAi={setSubmitCommentLockByAi}
            hideAiButton={reviewed === true}
            ref={formRef}
          />
        </td>
      )}
      {!isCriticalOrAtRiskAlertingRole(roleNames) && !reviewed && (
        <td data-label="Reviewed" className="status-row">
          <ActivityCheckBox
            handleCheck={async () => {
              setSubmitLoading(true);
              await handleReviewedCheck(activity.id, activity.deviceId);
              if (formRef.current?.values?.comment) {
                await handleCommentSubmit(
                  activity.id,
                  activity.deviceId,
                  formRef.current.values,
                );
                formRef.current.resetForm();
                removeComment(activity.id);
              }
              setSubmitLoading(false);
            }}
            loading={
              submitLoading ||
              activity.disabled ||
              submitCommentLockByAi ||
              false
            }
            title={
              submitCommentLockByAi
                ? COMMENT_SUBMIT_ERROR_NO_CHANGES
                : undefined
            }
          />
        </td>
      )}
    </tr>
  );
};

ActivityRow.propTypes = {
  columnNumber: PropTypes.number,
  reviewed: PropTypes.bool.isRequired,
  activity: PropTypes.shape().isRequired,
  handleCommentSubmit: PropTypes.func.isRequired,
  handleReviewedCheck: PropTypes.func.isRequired,
  togglePatientReserved: PropTypes.func.isRequired,
  toggleAssignLoading: PropTypes.bool,
  setToggleAssignLoading: PropTypes.func.isRequired,
  isOnCall: PropTypes.bool,
  disabledTemplateModalButton: PropTypes.bool,
  openTemplateModal: PropTypes.func,
  isCommentGenerationLimitReached: PropTypes.bool,
  onCommentGenerationStatusChange: PropTypes.func,
  consumablesArrivingDate: PropTypes.string,
};

export default ActivityRow;
