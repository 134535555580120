import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { Row, Col } from 'reactstrap';
import forms from 'constants/forms';
import Input from 'components/atoms/input';
import DateSelector from 'components/molecules/dateSelector/range';
import common from 'constants/common';
import format from 'date-fns/format';
import useOutsideClick from 'utils/useOutsideClick';

const PersonalInfo = ({ setFieldValue, errors, values }) => {
  const [showDateSelector, setShowDateSelector] = useState(false);
  const personalInfo = [...forms.adminPanelsUser.userAdd.personalInfo];
  const onSelect = (date) => {
    setFieldValue(
      'birthDate',
      format(new Date(date), common.dateFormats.birthDate),
    );
    setShowDateSelector(false);
  };
  const ref = useRef();
  useOutsideClick(ref, () => {
    setShowDateSelector(false);
  });

  return (
    <div className="admin-patient-form-block">
      <span>Personal Info</span>
      <Row>
        {personalInfo.map((field) => (
          <Col lg="6" md="6" sm="6" xs="12" key={field.id}>
            {!['birthDate', 'auth0Enabled'].includes(field.id) && (
              <>
                <Input {...field} dataCy="title-input" />
                {errors[field.id] ? (
                  <div className="input-error">{errors[field.id]}</div>
                ) : null}
              </>
            )}
            {field.id === 'birthDate' && (
              <div ref={ref}>
                <div
                  onClick={() => {
                    setShowDateSelector(true);
                  }}
                >
                  <Input {...field} />
                </div>
                {errors[field.id] ? (
                  <div className="input-error">{errors[field.id]}</div>
                ) : null}
                {showDateSelector && (
                  <div className="datepicker">
                    <DateSelector
                      inDate={values.birthDate}
                      onSelect={onSelect}
                    />
                  </div>
                )}
              </div>
            )}
            {field.id === 'auth0Enabled' && (
              <div>
                <div
                  className="admin-patient-edit-form-label"
                  data-cy="create-evelyn-account-switch"
                >
                  {field.label}
                </div>
                <div className="admin-patient-edit-form-readings">
                  <div>
                    {values.auth0Enabled && `Yes`}
                    {!values.auth0Enabled && `No`}
                  </div>
                  <Switch
                    id={field.id}
                    className="evelyn-switch"
                    onChange={(checked) => {
                      setFieldValue('auth0Enabled', checked);
                    }}
                    checked={!!values.auth0Enabled}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onHandleColor={common.commonColors.green}
                    offHandleColor={common.commonColors.gray}
                    onColor={common.commonColors.white}
                    offColor={common.commonColors.white}
                    handleDiameter={24}
                    height={30}
                    width={59}
                    activeBoxShadow="0"
                  />
                </div>
                {errors[field.id] ? (
                  <div className="admin-patient-edit-form-field-error">
                    {errors[field.id]}
                  </div>
                ) : null}
              </div>
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};

PersonalInfo.propTypes = {
  errors: PropTypes.shape(),
  setFieldValue: PropTypes.func,
  values: PropTypes.shape(),
};

export default PersonalInfo;
