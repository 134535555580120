import { useQuery } from '@tanstack/react-query';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';
import dataService from 'services/dataService';
import { API_GOALS } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';

import type {
  IPartialPaginationMeta,
  IPatientGoalResponse,
  TPatientGoalType,
} from 'infrastructure/interfaces';

type TFilter = {
  patientGuid: string;
  type: TPatientGoalType;
} & IPartialPaginationMeta;

export const getPatientGoalsFn = async ({ ...filter }: TFilter) => {
  const url = API_GOALS;
  const { data, error } = await dataService.getList<IPatientGoalResponse>(
    apiUrlGenerator(url, filter),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const getPatientGoalsKey = (filter: TFilter) => [
  'getPatientGoals',
  filter,
];

export const PATIENT_GOALS_PER_PAGE = 5;

export const useGetPatientGoals = (props: TFilter) => {
  const filter: TFilter = {
    ...props,
    items: PATIENT_GOALS_PER_PAGE,
  };

  const {
    data: goals,
    isLoading,
    isFetching,
    isRefetching,
    ...other
  } = useQuery({
    queryKey: getPatientGoalsKey(filter),
    queryFn: async () => getPatientGoalsFn(filter),
  });

  return {
    goals,
    isLoading: isLoading || isFetching || isRefetching,
    isFetching,
    ...other,
  };
};
