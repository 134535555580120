import { AutoComplete, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import BaseLoader from '../base-loader';
import InputSearchIcon from '../icons/inputSearchIcon';

import type { BaseAutocompleteProps } from './types';
import type { FC } from 'react';

const BaseAutocomplete: FC<BaseAutocompleteProps> = ({
  options,
  isLoading,
  onChange,
  onSelect,
  value,
  placeholder,
  disabled,
  invalid,
}) => {
  const { t } = useTranslation();
  return (
    <AutoComplete
      popupClassName="certain-category-search-dropdown"
      style={{ width: '100%' }}
      options={options ?? []}
      size="middle"
      notFoundContent={
        isLoading ? (
          <BaseLoader loading={isLoading} height={20} width={20} />
        ) : (
          t('labels.bannerNoData')
        )
      }
      disabled={disabled}
      onSelect={onSelect}
      value={value}
      optionRender={(option) => (
        <div data-cy="autocomplete-option">{option.label}</div>
      )}
      status={invalid ? 'error' : undefined}
    >
      <Input
        size="middle"
        placeholder={placeholder}
        suffix={<InputSearchIcon />}
        onChange={(e) => {
          onChange?.(e.target.value);
        }}
        data-cy="autocomplete-input"
        status={invalid ? 'error' : undefined}
      />
    </AutoComplete>
  );
};
export default BaseAutocomplete;
