import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_APPOINTMENT_COMPLETIONS } from 'services/dataService/ccmResources';

import { apiUrlGenerator } from '../use-api';
import { showResult } from '../utils';

import type {
  IAppointmentCompletion,
  IPartialPaginationMeta,
} from 'infrastructure/interfaces';

interface Props {
  patientGuid: string;
  meta: IPartialPaginationMeta;
}

interface IResponse {
  items: IAppointmentCompletion[];
  count: number;
}

const appointmentCompletionFn = async ({ patientGuid, meta }: Props) => {
  const url = `${API_APPOINTMENT_COMPLETIONS}/${patientGuid}`;
  const { data, error } = await dataService.getList<IResponse>(
    apiUrlGenerator(url, meta),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const getAppointmentCompletionKey = (patientGuid: string, page = 1) => [
  'getAppointmentCompletion',
  patientGuid,
  page,
];

export const useAppointmentCompletion = ({ patientGuid, meta }: Props) => {
  const {
    data: appointmentCompletionData,
    isLoading,
    isFetching,
    isPreviousData,
  } = useQuery({
    queryKey: getAppointmentCompletionKey(patientGuid, meta.page),
    queryFn: async () => appointmentCompletionFn({ patientGuid, meta }),
    enabled: Boolean(patientGuid),
  });

  return {
    appointmentCompletionData,
    isLoading: isFetching || isLoading,
    isPreviousData,
  };
};
