import type { SVGProps } from 'react';

const ControlRequestIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25821 4.62403C10.2904 -0.473549 17.0367 -1.50509 21.3469 2.27339C25.6571 6.05187 25.8862 16.4803 21.3469 21.0786C16.8077 25.6768 6.34554 24.2016 2.78303 21.0786C-0.779485 17.9555 0.226037 9.72162 5.25821 4.62403Z"
      fill="#FB9318"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.666 7.5C12.8731 7.5 13.041 7.66789 13.041 7.875V11.625H16.791C16.9981 11.625 17.166 11.7929 17.166 12C17.166 12.2071 16.9981 12.375 16.791 12.375H13.041V16.125C13.041 16.3321 12.8731 16.5 12.666 16.5C12.4589 16.5 12.291 16.3321 12.291 16.125V12.375H8.54102C8.33391 12.375 8.16602 12.2071 8.16602 12C8.16602 11.7929 8.33391 11.625 8.54102 11.625H12.291V7.875C12.291 7.66789 12.4589 7.5 12.666 7.5Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ControlRequestIcon;
