import dataService from 'services/dataService';
import {
  apiAdminPanelUser,
  apiAdminPanelUsers,
  apiDoctorProfile,
  API_ROLES,
} from 'services/dataService/resources';

const EMPTY_USER = {
  // title: null,
  // firstName: null,
  // lastName: null,
  // middleName: null,
  // birthDate: null,
  // phone: null,
  // homeNumber: null,
  // email: null,
  roles: [],
  clinics: [],
  // city: null,
  // state: null,
  // race: null,
  // zip: null,
  // primaryLanguage: null,
  // secondaryLanguage: null,
  // addressLine1: null,
  // addressLine2: null,
  // alertEmail: null,
  // alertSms: null,
  // professionType: null,
  // npiNumber: null,
};
const ITEMS_PER_PAGE = 100;
const INITIAL_STATE = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  filters: {
    role: null,
    clinicGuid: null,
    doctorGuid: null,
    status: null,
  },
  roles: [],
  data: [],
  totalPages: 0,
  totalCount: 0,
};

const adminPanelUsers = {
  state: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    setPage: (state, payload) => ({
      ...state,
      page: payload < 1 ? 1 : payload,
    }),
    setUsers: (state, payload) => ({
      ...state,
      data: payload,
    }),
    setFilters: (state, payload) => ({
      ...state,
      filters: { ...state.filters, ...payload },
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    setClinics: (state, payload) => ({
      ...state,
      clinics: payload,
    }),
    setUser: (state, payload) => ({
      ...state,
      user: payload,
    }),
    setRoles: (state, payload = []) => ({
      ...state,
      roles: payload,
    }),
  },
  effects: (dispatch) => ({
    // FIXME: Can be deleted. Unused method
    async getRoles() {
      const { data } = await dataService.getList(
        `${API_ROLES}?userType=practice`,
      );
      dispatch.adminPanelUsers.setRoles(data);
    },
    // FIXME: Can be deleted. Unused component
    async setUsersPage({ pageOffset, getList = true }, state) {
      const { page, totalPages } = state.adminPanelUsers;
      const newPage = page + pageOffset;
      dispatch.adminPanelUsers.setPage(
        newPage <= totalPages ? newPage : totalPages,
      );
      if (getList) {
        return dispatch.adminPanelUsers.getUsers({
          page: newPage,
        });
      }
    },
    // FIXME: Can be deleted. Unused component
    async getUsers({ items = ITEMS_PER_PAGE, signal }, state) {
      const { filters, page } = state.adminPanelUsers;
      const { data } = await dataService.getList(
        apiAdminPanelUsers(items, page, filters),
        { signal },
      );
      if (data) {
        dispatch.adminPanelUsers.setUsers(data?.items || []);
        const totalCount = data?.count || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.adminPanelUsers.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
      } else {
        dispatch.adminPanelUsers.setUsers([]);
        dispatch.adminPanelUsers.setTotalCountAndTotalPages({
          totalCount: 0,
          totalPages: 0,
        });
      }
    },
    async getUser(userGuid) {
      if (userGuid) {
        const { error, data } = await dataService.getList(
          apiDoctorProfile(userGuid),
        );
        if (error) throw error;
        if (data?.clinics.length)
          data.clinics = data.clinics.map(({ guid, name }) => ({
            value: guid,
            label: name,
          }));
        if (data) {
          dispatch.adminPanelUsers.setUser(data);
        }
      } else dispatch.adminPanelUsers.setUser(EMPTY_USER);
    },
    async editUser(body) {
      const { error, data } = await dataService.updateOnly(
        apiAdminPanelUser,
        body,
      );
      return { error, data };
    },
    async addUser(body) {
      const { error, data } = await dataService.createOne(
        apiAdminPanelUser,
        body,
      );
      return { error, data };
    },
  }),
};

export default adminPanelUsers;
