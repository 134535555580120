import forms from 'constants/forms';
import { useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { useAminPanelSearchAgencyDoctor } from 'infrastructure/hooks/doctor';
import { useAminPanelSearchAgency } from 'infrastructure/hooks/agency';

import { useAgencyUsersFilter } from './use-users-filter';

export const options = forms.adminPanelPracticeUserFilter.map((opt) => ({
  value: opt.id,
  label: opt.label,
  placeholder: opt.placeholder,
}));

const DEFAULT_DOCTOR_OPTION = options[0];

export const useFilter = () => {
  const { filter, updateQueryParams, resetFilter, countOfChangedFilterField } =
    useAgencyUsersFilter();
  const { searchDoctor, searchDoctorLoading, doctors } =
    useAminPanelSearchAgencyDoctor();
  const { searchAgency, agencies, searchAgencyLoading } =
    useAminPanelSearchAgency();

  const statusOptions = forms.loginStatusFilterSelect;

  const placeholder = useMemo(
    () =>
      options.find(
        (o) =>
          o.value === filter.doctorKey ||
          o.value === DEFAULT_DOCTOR_OPTION.value,
      )?.placeholder,
    [filter.doctorKey],
  );

  const debounceSearchDoctor = useCallback(debounce(searchDoctor, 800), []);
  const debounceSearchAgency = useCallback(debounce(searchAgency, 800), []);

  const doctorOptions =
    doctors ??
    (filter.doctorLabel && filter.guid
      ? [
          {
            label: filter.doctorLabel,
            value: filter.guid,
          },
        ]
      : []);

  const agencyOptions =
    agencies?.items ??
    (filter.agencyLabel && filter.agencyGuid
      ? [
          {
            label: filter.agencyLabel,
            value: filter.agencyGuid,
          },
        ]
      : []);

  return {
    filter,
    updateQueryParams,
    searchDoctorLoading,
    searchAgencyLoading,
    statusOptions,
    placeholder,
    debounceSearchDoctor,
    debounceSearchAgency,
    doctorOptions,
    agencyOptions,
    countOfChangedFilterField,
    DEFAULT_DOCTOR_OPTION,
    options,
    resetFilter,
  };
};
