import C from 'classnames';

import type { ButtonHTMLAttributes, FunctionComponent } from 'react';

type Props = {
  text: string;
  Icon?: object;
  handleClick?: () => void;
  fillBg?: boolean;
  smallForPhone?: boolean;
  dataCy?: string;
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FunctionComponent<Props> = ({
  text,
  Icon,
  handleClick = () => {},
  fillBg = true,
  smallForPhone,
  dataCy,
  disabled,
  ...rest
}) => (
  <button
    onClick={handleClick}
    className={C('main-button', {
      disabled,
      'main-button--bg': fillBg,
      'small-for-phone': smallForPhone,
    })}
    data-cy={dataCy}
    disabled={disabled}
    {...rest}
  >
    <div className="main-button-text">{text}</div>
    {Icon && Icon}
  </button>
);

export default Button;
