import type { SVGProps } from 'react';

const ContactAvailabilityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8270_24940)">
      <path
        d="M10.2857 4.58329V10.0833L13.49 11.7967M17.628 5.35083C16.1271 2.69336 13.4004 0.916626 10.2857 0.916626C5.55179 0.916626 1.71423 5.02068 1.71423 10.0833C1.71423 14.431 4.54443 18.0717 8.34199 19.0133"
        stroke="#667180"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2854 11.0548C18.9251 12.1945 18.3631 13.3037 17.5915 14.3348C16.82 15.3659 15.9005 16.2369 14.883 16.9384C14.7955 16.9988 14.7517 17.0289 14.6946 17.0564C14.4917 17.154 14.2243 17.1594 14.025 17.0699C13.9689 17.0447 13.9191 17.0115 13.8194 16.9451C13.5145 16.7418 13.362 16.6402 13.2158 16.5823C12.6643 16.3638 12.0173 16.4821 11.5649 16.884C11.445 16.9906 11.3328 17.1406 11.1083 17.4406L10.9831 17.6079C10.6419 18.0639 10.4713 18.292 10.3948 18.5173C10.2428 18.9656 10.3199 19.448 10.6025 19.8168C10.7446 20.0022 10.9764 20.1567 11.4399 20.4656L11.5774 20.5572C12.0393 20.8651 12.2703 21.0191 12.5661 21.1148C12.8944 21.2211 13.3828 21.2513 13.7295 21.1869C14.0421 21.1289 14.2465 21.0322 14.6555 20.8387C16.8531 19.7992 18.8334 18.2339 20.3668 16.1846C21.9001 14.1354 22.8008 11.8504 23.1037 9.54794C23.1601 9.11951 23.1882 8.90529 23.1417 8.60791C23.09 8.27791 22.8964 7.85365 22.6806 7.59717C22.486 7.36602 22.2551 7.21208 21.7931 6.90418L21.6557 6.81254C21.1921 6.50359 20.9604 6.34912 20.7277 6.28472C20.265 6.15666 19.7575 6.24942 19.3608 6.53459C19.1613 6.67798 18.9907 6.90601 18.6495 7.36206L18.5243 7.52929C18.2999 7.82931 18.1876 7.97931 18.1213 8.12165C17.8712 8.65841 17.9695 9.27349 18.3721 9.69095C18.4789 9.80165 18.6313 9.90327 18.9362 10.1065C19.0359 10.173 19.0858 10.2062 19.1297 10.2477C19.2857 10.395 19.3698 10.6347 19.3402 10.8478C19.3318 10.9077 19.3163 10.9568 19.2854 11.0548Z"
        stroke="#667180"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    {/* <defs>
      <clipPath id="clip0_8270_24940">
        <rect width="24" height="22" fill="white" />
      </clipPath>
    </defs> */}
  </svg>
);

export default ContactAvailabilityIcon;
