import i18next from 'i18next';
import BaseTableActions from 'components/atoms/base-table/actions';
import { ModuleType } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { IBaseTableColumns } from 'components/atoms/base-table/types';
import type { PatientInsurance } from 'infrastructure/classes/patient/patient-insurance';

interface IPatientInsuranceColumnsProps {
  module: ModuleType;
  onEdit: (record: PatientInsurance) => void;
  onDelete: (record: PatientInsurance) => void;
  iamPatientAcceptance?: boolean;
}

export const patientInsuranceColumns = ({
  module,
  onEdit,
  onDelete,
  iamPatientAcceptance,
}: IPatientInsuranceColumnsProps): IBaseTableColumns<PatientInsurance> => {
  let columns: IBaseTableColumns<PatientInsurance> = [
    {
      label: i18next.t('labels.provider'),
      key: 'provider',
      width: '230px',
      render: (value) => {
        return <div className={s.number}>{value}</div>;
      },
    },
    {
      label: i18next.t('labels.number'),
      key: 'number',
      width: '230px',
      render: (value) => {
        return <div className={s.number}>{value}</div>;
      },
    },
    {
      label: i18next.t('labels.type'),
      key: 'type',
      width: '230px',
      render: (value) => {
        return <div className={s.number}>{value}</div>;
      },
    },
    {
      label: i18next.t('labels.planName'),
      key: 'planName',
      width: '230px',
      render: (value) => {
        return <div className={s.number}>{value}</div>;
      },
    },
    {
      label: i18next.t('labels.actions'),
      key: 'actions',
      align: 'center',
      width: '120px',
      render: (_, record) => (
        <BaseTableActions
          onEdit={() => onEdit(record)}
          onDelete={() => onDelete(record)}
          disabled={iamPatientAcceptance}
        />
      ),
    },
  ];

  if (module !== ModuleType.ADMIN) {
    const excludedColumns = ['actions'];
    columns = columns.filter((el) => !excludedColumns.includes(el.key));
  }

  return columns;
};
