import Title from './title';
import Count from './count';
import s from './styles.module.scss';

import type { CcmAgentCountData } from 'infrastructure/hooks/ccmAgentDashboard/types';
import type { FC } from 'react';

interface Props {
  count: CcmAgentCountData;
}

const CcmAgentDashboardHeader: FC<Props> = ({ count }) => {
  return (
    <div className={s.wrapper}>
      <Title />
      <Count count={count} />
    </div>
  );
};

export default CcmAgentDashboardHeader;
