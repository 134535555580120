import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const ActivityCheckbox = ({ handleCheck, loading, title }) => (
  <div className="activity-checkbox">
    <Input
      type="checkbox"
      data-cy="review-reading-checkbox"
      onChange={handleCheck}
      disabled={loading}
      title={title}
    />
  </div>
);

ActivityCheckbox.propTypes = {
  handleCheck: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default ActivityCheckbox;
