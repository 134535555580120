import React, { forwardRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseSwitch from 'components/atoms/base-switch';
import TimeSelectBox from 'components/molecules/time-select-box';
import useUpdateEffect from 'infrastructure/hooks/utils/use-update-effect';

import s from './styles.module.scss';
import { contactAvailabilityFilterValidationSchema } from './validation-schema';

import type { IAvailableDay } from 'infrastructure/hooks/interactiveTimeDashboard/types';

export interface IContactAvailabilityFilterDayRef {
  validate: () => boolean;
}

interface IDayProps {
  data: IAvailableDay;
  onChange: (data: IAvailableDay) => void;
}

const ContactAvailabilityFilterDay = forwardRef<
  IContactAvailabilityFilterDayRef,
  IDayProps
>(({ data, onChange }, ref) => {
  const { t } = useTranslation();

  const formik = useFormik<IAvailableDay>({
    initialValues: data,
    onSubmit: () => {},
    validateOnChange: true,
    validationSchema: contactAvailabilityFilterValidationSchema(),
  });

  const { values: time, setFieldValue, errors, isValid, validateForm } = formik;

  const disabled = !time.active;
  const getError = (field: 'startTime' | 'endTime') => Boolean(errors[field]);

  useImperativeHandle(ref, () => ({
    validate: () => {
      validateForm();
      return isValid;
    },
  }));

  useUpdateEffect(
    () =>
      onChange({
        ...time,
        value: {
          day: time.day,
          startTime: time.startTime,
          endTime: time.endTime,
        },
      }),
    [time],
  );

  return (
    <li>
      <div className={s.name}>
        <BaseSwitch
          value={time.active}
          onChange={(val) => setFieldValue('active', val)}
        />
        {data.name}
      </div>
      <div className={s.selects}>
        <TimeSelectBox
          labelPrefix={t('controls.from')}
          value={time.startTime}
          disabledAfter={time.endTime}
          onChange={(val) => setFieldValue('startTime', val)}
          invalid={getError('startTime')}
          disabled={disabled}
        />
        <TimeSelectBox
          labelPrefix={t('controls.to')}
          value={time.endTime}
          disabledUntil={time.startTime}
          onChange={(val) => setFieldValue('endTime', val)}
          invalid={getError('endTime')}
          disabled={disabled}
        />
      </div>
    </li>
  );
});

ContactAvailabilityFilterDay.displayName = 'ContactAvailabilityFilterDay';

export default ContactAvailabilityFilterDay;
