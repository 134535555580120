import React from 'react';
import BaseLoader from 'components/atoms/base-loader';
import { useTranslation } from 'react-i18next';
import { appointmentMethodMap } from 'constants/common';

import s from './styles.module.scss';
import { useGetAppointmentList } from './hook';

import type { FC } from 'react';

interface Props {
  children: React.ReactNode;
  dateRange?: [Date, Date];
  patientGuid?: string;
}

const AppointmentList: FC<Props> = ({ children, dateRange, patientGuid }) => {
  const { appointmentList, isFetching } = useGetAppointmentList({
    endDate: dateRange?.[1],
    patientGuid,
  });
  const { t } = useTranslation();

  return (
    <div className={s.wrapper}>
      {children}
      <div className={s.divider} />
      <div className={s['appointment-wrapper']}>
        <p className={s.title}>{t('labels.appointmentsList')}</p>
        <div className={s['item-wrapper']}>
          {isFetching ? (
            <BaseLoader loading={isFetching} width={30} height={30} />
          ) : (
            appointmentList?.items?.map((i) => (
              <div
                key={i.guid}
                className={s.item}
                data-cy="reportTimeWindow-appointment"
              >
                <p>{i.completedBy}</p>
                <p>{i.completedDate}</p>
                <p>
                  {appointmentMethodMap[i.appointmentMethod] ??
                    i.appointmentMethod}
                </p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentList;
