export const MAX_FILE_SIZE_MB = 4;
export const ALLOWED_FILE_EXTENSIONS = [
  // Documents
  '.pdf',
  '.doc',
  '.docx',
  // Images
  '.png',
  '.jpeg',
  '.jpg',
  '.webp',
  // Videos
  '.mp4',
  '.mov',
];
