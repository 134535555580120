import i18next from 'i18next';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { createWaitPromise } from 'utils/helpers/timer';

const UNDO_TIMEOUT_SECONDS = 5;

export const callCommentWithUndo = () => {
  const { cancel, promise: undoTimeoutPromise } = createWaitPromise(
    UNDO_TIMEOUT_SECONDS * 1000,
  );

  notificationController.showUndo({
    duration: UNDO_TIMEOUT_SECONDS,
    message: i18next.t('labels.commentSubmitting'),
    onUndo: () => {
      cancel();
      setTimeout(() => {
        notificationController.info({
          message: i18next.t('labels.commentUndone'),
        });
      }, 1000);
    },
  });

  return {
    undoTimeoutPromise,
  };
};
