import { capitalize } from 'utils';

import s from './styles.module.scss';

import type { FC } from 'react';

interface Props {
  patient?: {
    guid: string;
    birthDate: string;
    gender?: string;
  };
}

const PatientInfo: FC<Props> = ({ patient }) => {
  return (
    <div className={s.wrapper}>
      <h3 className={s.title}>Patient</h3>
      {patient && (
        <div className={s.info}>
          {patient.guid && (
            <>
              GUID: {patient.guid}
              <span className={s.divider}>|</span>
            </>
          )}
          {patient.birthDate && (
            <>
              DOB: {patient.birthDate}
              <span className={s.divider}>|</span>
            </>
          )}
          {patient.gender && <>Gender: {capitalize(patient.gender)}</>}
        </div>
      )}
    </div>
  );
};

export default PatientInfo;
