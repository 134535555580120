import {
  DateFormats,
  MedicationStatusEnum,
  ModuleType,
} from 'infrastructure/enums';
import { DateFormatter } from 'infrastructure/functions';
import {
  useGetMedication,
  useAddMedication,
  useEditMedication,
  useDeleteMedication,
  MEDICATION_PER_PAGE,
} from 'infrastructure/hooks/ccm';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isCcmActionAllowedStatus } from 'utils/userTypeHelper';
import useUser from 'utils/useUser';

import s from '../styles.module.scss';

import type {
  IAddMedicationData,
  IMedicationForUpdate,
} from 'infrastructure/interfaces';
import type { FormikHelpers } from 'formik';
import type { Values } from 'components/molecules/admin-panel/forms/medication/validation-schema';

const OPTIONS = [
  { value: null, label: MedicationStatusEnum.ALL },
  { value: true, label: MedicationStatusEnum.ACTIVE },
  { value: false, label: MedicationStatusEnum.INACTIVE },
];

export type TOption = (typeof OPTIONS)[number];

interface Props {
  patientGuid: string;
  module: ModuleType;
}

export const useMedication = ({ patientGuid, module }: Props) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<TOption>(OPTIONS[0]);
  const [page, setPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(MEDICATION_PER_PAGE);
  const [modifyItemGuid, setModifyItemGuid] = useState<string>();
  const [medicationForUpdate, setMedicationForUpdate] =
    useState<IMedicationForUpdate>();
  const { medication, isLoading, isFetching, refetch, remove } =
    useGetMedication({
      patientGuid,
      meta: { page, items: itemPerPage },
      isActive: status.value ?? undefined,
    });
  const { addMedication, addLoading } = useAddMedication();
  const { editMedication, editLoading } = useEditMedication({
    page,
    items: itemPerPage,
    isActive: status.value ?? undefined,
  });
  const { deleteMedication, deleteLoading } = useDeleteMedication({
    page,
    items: itemPerPage,
    isActive: status.value ?? undefined,
  });
  const { patientInfo, loading } = usePatientInfo({ patientGuid });
  const disableButton = !isCcmActionAllowedStatus(patientInfo?.ccmStatus);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { Alert, showAlert } = useAlert();
  const { isPatient } = useUser();

  const toggleOpen = () => setOpen((prev) => !prev);

  const formatDate = (date: Date | string) => {
    return DateFormatter({
      date,
      format: DateFormats['MMM dd yyyy'],
    });
  };

  const onCancelForm = () => {
    setMedicationForUpdate(undefined);
    setOpen(false);
  };

  const onSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
    if (!values.name) return;

    const newMedicationData: IAddMedicationData = {
      ...values,
      patientGuid,
      startDate: values?.startDate ? formatDate(values.startDate) : undefined,
      endDate: values?.endDate ? formatDate(values.endDate) : undefined,
    };

    if (medicationForUpdate) {
      editMedication(newMedicationData, {
        onSuccess: () => {
          onCancelForm();
          formikHelpers.resetForm();
        },
      });
      return;
    }

    addMedication(newMedicationData, {
      onSuccess: () => {
        onCancelForm();
        formikHelpers.resetForm();
        refetch();
      },
    });
  };

  const scrollToTop = () => {
    const element = document.getElementById('medication');
    if (element) {
      const yCoordinate =
        element.getBoundingClientRect().top + window.scrollY - 110;
      window.scrollTo({ top: yCoordinate, behavior: 'smooth' });
    }
  };

  const handleEdit = (item: IMedicationForUpdate) => {
    setMedicationForUpdate(item);
    setModifyItemGuid(item.guid);
    toggleOpen();
  };

  const handleDelete = async (guid: string) => {
    const delRes = await showAlert({
      title: t('confirm.deleteMedication'),
      message: (
        <div className={s['alert-message']}>
          <p>{t('confirm.confirmDeleteMedication')} </p>
          <p> {t('confirm.deleteMedicationMessage')} </p>
        </div>
      ),
      type: 'danger',
    });
    if (delRes) {
      setModifyItemGuid(guid);
      deleteMedication(guid);
    }
  };

  const modalTitle = medicationForUpdate
    ? t('labels.editMedication')
    : t('labels.addMedication');

  return {
    modalTitle,
    Alert,
    open,
    modifyItemGuid,
    medication,
    isLoading,
    isFetching,
    addLoading,
    editLoading,
    deleteLoading,
    loading,
    disableButton: module === ModuleType.CCM ? disableButton : isPatient,
    wrapperRef,
    OPTIONS,
    status,
    page,
    itemPerPage,
    handleDelete,
    handleEdit,
    scrollToTop,
    onSubmit,
    setStatus,
    setPage,
    setItemPerPage,
    toggleOpen,
    onCancelForm,
    remove,
    medicationForUpdate,
  };
};
