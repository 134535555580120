import React from 'react';
import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';
import type { Nullable } from 'infrastructure/types';

const level0: React.FC<IBatteryIconProps> = ({
  color = COLORS.BUOYANT,
  ...props
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 6C0.5 4.89543 1.39543 4 2.5 4H12.5C13.6046 4 14.5 4.89543 14.5 6V10C14.5 11.1046 13.6046 12 12.5 12H2.5C1.39543 12 0.5 11.1046 0.5 10V6ZM2.5 5C1.94772 5 1.5 5.44772 1.5 6V10C1.5 10.5523 1.94772 11 2.5 11H12.5C13.0523 11 13.5 10.5523 13.5 10V6C13.5 5.44772 13.0523 5 12.5 5H2.5Z"
        fill={color}
      />
      <path
        d="M16.5 8C16.5 8.82843 15.8284 9.5 15 9.5V6.5C15.8284 6.5 16.5 7.17157 16.5 8Z"
        fill={color}
      />
    </svg>
  );
};

const level1: React.FC<IBatteryIconProps> = ({
  color = COLORS.BUOYANT,
  ...props
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.5 6H4.5V10H2.5V6Z" fill={color} />
      <path
        d="M2.5 4C1.39543 4 0.5 4.89543 0.5 6V10C0.5 11.1046 1.39543 12 2.5 12H12.5C13.6046 12 14.5 11.1046 14.5 10V6C14.5 4.89543 13.6046 4 12.5 4H2.5ZM12.5 5C13.0523 5 13.5 5.44772 13.5 6V10C13.5 10.5523 13.0523 11 12.5 11H2.5C1.94772 11 1.5 10.5523 1.5 10V6C1.5 5.44772 1.94772 5 2.5 5H12.5Z"
        fill={color}
      />
      <path
        d="M16.5 8C16.5 8.82843 15.8284 9.5 15 9.5V6.5C15.8284 6.5 16.5 7.17157 16.5 8Z"
        fill={color}
      />
    </svg>
  );
};

const level2: React.FC<IBatteryIconProps> = ({
  color = COLORS.BUOYANT,
  ...props
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.5 6H7.5V10H2.5V6Z" fill={color} />
      <path
        d="M2.5 4C1.39543 4 0.5 4.89543 0.5 6V10C0.5 11.1046 1.39543 12 2.5 12H12.5C13.6046 12 14.5 11.1046 14.5 10V6C14.5 4.89543 13.6046 4 12.5 4H2.5ZM12.5 5C13.0523 5 13.5 5.44772 13.5 6V10C13.5 10.5523 13.0523 11 12.5 11H2.5C1.94772 11 1.5 10.5523 1.5 10V6C1.5 5.44772 1.94772 5 2.5 5H12.5Z"
        fill={color}
      />
      <path
        d="M16.5 8C16.5 8.82843 15.8284 9.5 15 9.5V6.5C15.8284 6.5 16.5 7.17157 16.5 8Z"
        fill={color}
      />
    </svg>
  );
};

const level3: React.FC<IBatteryIconProps> = ({
  color = COLORS.BUOYANT,
  ...props
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.5 6H10.5V10H2.5V6Z" fill={color} />
      <path
        d="M2.5 4C1.39543 4 0.5 4.89543 0.5 6V10C0.5 11.1046 1.39543 12 2.5 12H12.5C13.6046 12 14.5 11.1046 14.5 10V6C14.5 4.89543 13.6046 4 12.5 4H2.5ZM12.5 5C13.0523 5 13.5 5.44772 13.5 6V10C13.5 10.5523 13.0523 11 12.5 11H2.5C1.94772 11 1.5 10.5523 1.5 10V6C1.5 5.44772 1.94772 5 2.5 5H12.5Z"
        fill={color}
      />
      <path
        d="M16.5 8C16.5 8.82843 15.8284 9.5 15 9.5V6.5C15.8284 6.5 16.5 7.17157 16.5 8Z"
        fill={color}
      />
    </svg>
  );
};

const level4: React.FC<IBatteryIconProps> = ({
  color = COLORS.BUOYANT,
  ...props
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.5 6H12.5V10H2.5V6Z" fill={color} />
      <path
        d="M2.5 4C1.39543 4 0.5 4.89543 0.5 6V10C0.5 11.1046 1.39543 12 2.5 12H12.5C13.6046 12 14.5 11.1046 14.5 10V6C14.5 4.89543 13.6046 4 12.5 4H2.5ZM12.5 5C13.0523 5 13.5 5.44772 13.5 6V10C13.5 10.5523 13.0523 11 12.5 11H2.5C1.94772 11 1.5 10.5523 1.5 10V6C1.5 5.44772 1.94772 5 2.5 5H12.5Z"
        fill={color}
      />
      <path
        d="M16.5 8C16.5 8.82843 15.8284 9.5 15 9.5V6.5C15.8284 6.5 16.5 7.17157 16.5 8Z"
        fill={color}
      />
    </svg>
  );
};

interface IBatteryIconProps extends IIconProps {
  level?: Nullable<number>;
}

const BatteryIcon: React.FC<IBatteryIconProps> = (props) => {
  const { level = 0 } = props;

  let icon = level0(props);

  if (level) {
    if (level > 0) {
      icon = level1(props);
    }
    if (level > 3) {
      icon = level2(props);
    }
    if (level > 5) {
      icon = level3(props);
    }
    if (level > 8) {
      icon = level4(props);
    }
  }

  return icon;
};

export default BatteryIcon;
