import { pickBy, identity } from 'lodash';
import queryString from 'query-string';

export const skipEmptyStrings = (query) =>
  Object.keys(query)
    .filter((k) => query[k] !== '')
    .reduce((a, k) => ({ ...a, [k]: query[k] }), {});

export const addQueryParams = ({ navigate, location }, newParams) => {
  const currentParams = queryString.parse(location?.search);
  const mergedParams = { ...currentParams, ...newParams };
  navigate({
    search: queryString.stringify(pickBy(mergedParams, identity)),
    pathname: window.location.pathname,
  });
};
