export enum ProviderServiceType {
  Cardiology = 'Cardiology',
  EndocrinologyAndMetabolism = 'Endocrinology and Metabolism',
  FamilyMedicine = 'Family Medicine',
  Gastroenterology = 'Gastroenterology',
  GeneralInternalMedicine = 'General Internal Medicine',
  GeriatricMedicine = 'Geriatric Medicine',
  InfectiousDisease = 'Infectious Disease',
  Oncology = 'Oncology',
  Nephrology = 'Nephrology',
  Neurology = 'Neurology',
  PainSpecialist = 'Pain Specialist',
  Pediatrics = 'Pediatrics',
  Psychiatry = 'Psychiatry',
  Pulmonology = 'Pulmonology',
  Rheumatology = 'Rheumatology',
  Urology = 'Urology',
  RuralHealthcareCenter = 'Rural Healthcare Center (RHC)',
  Academia = 'Academia',
  FQHC = 'FQHC',
  HealthAndWellness = 'Health and Wellness',
  Surgical = 'Surgical',
}
