import { useTranslation } from 'react-i18next';
import BaseButton from 'components/atoms/baseButton';
import { type FC } from 'react';
import BaseTableNew from 'components/atoms/base-table/new';
import { CONDITIONS_PER_PAGE } from 'infrastructure/hooks/ccm';
import EmptyTablePlaceholder from 'components/atoms/base-empty-table-placeholder';

import s from './styles.module.scss';
import { columns } from './columns';
import PatientConditionModalForm from '../admin-panel/forms/patient-condition';
import { useCondition } from './use-condition';

interface ConditionGoalsProps {
  patientGuid: string;
}

const ConditionGoals: FC<ConditionGoalsProps> = ({ patientGuid }) => {
  const {
    open,
    page,
    goals,
    isLoading,
    modifyItemGuid,
    addLoading,
    editLoading,
    deleteLoading,
    Alert,
    conditionForUpdate,
    disableButton,
    setPage,
    setOpen,
    handleDelete,
    toggleOpen,
    onSubmit,
    setConditionForUpdate,
    onCancelForm,
  } = useCondition({ patientGuid });
  const { t } = useTranslation();

  return (
    <>
      <div className={s.wrapper}>
        <div className={s['title-wrapper']}>
          <h3 className={s.title} data-cy="conditions-section">
            {t('labels.conditions')}
          </h3>
          <BaseButton
            label={`+ ${t('labels.addCondition')}`}
            dataCy="condition-goals-add-btn"
            onClick={() => setOpen(true)}
            disabled={isLoading || addLoading || disableButton}
          />
        </div>
        <BaseTableNew
          items={goals?.items ?? []}
          columns={columns({
            setConditionForUpdate,
            toggleOpen,
            handleDelete,
            loading: editLoading || deleteLoading,
            updatedItemGuid: modifyItemGuid,
            disableActions: disableButton,
          })}
          bordered
          loading={isLoading}
          pagination={{
            current: page,
            pageSize: CONDITIONS_PER_PAGE,
            total: goals?.count ?? 0,
            position: ['bottomCenter'],
          }}
          onChangePage={(newPage) => {
            setPage(newPage);
          }}
          dataCy="conditions-table"
          // eslint-disable-next-line prettier/prettier
          emptyText={
            <EmptyTablePlaceholder text={t('labels.noConditionsLogged')} />
            // eslint-disable-next-line prettier/prettier
          }
        />
      </div>
      <PatientConditionModalForm
        title={
          conditionForUpdate
            ? t('labels.editCondition')
            : t('labels.addCondition')
        }
        loading={addLoading || editLoading}
        onCancelForm={onCancelForm}
        onSubmit={onSubmit}
        open={open}
        defaultValues={conditionForUpdate}
      />
      {Alert}
    </>
  );
};
export default ConditionGoals;
