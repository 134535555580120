import React from 'react';
import { InputNumber } from 'antd';

import type { ICommonInputProps } from 'infrastructure/interfaces';

interface IBaseInputNumberProps extends ICommonInputProps<number> {
  min?: number;
  max?: number;
}

const BaseInputNumber: React.FC<IBaseInputNumberProps> = (props) => {
  const {
    value,
    placeholder,
    disabled,
    plaintext,
    readonly,
    invalid,
    onChange,
    className,
    style,
    min,
    max,
  } = props;

  return (
    <InputNumber
      style={{ width: '100%', ...style }}
      value={value}
      placeholder={placeholder}
      readOnly={readonly}
      disabled={disabled}
      className={className}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      variant={plaintext ? 'borderless' : 'outlined'}
      status={invalid ? 'error' : undefined}
      min={min}
      max={max}
    />
  );
};

export const BaseInputNumberDisplayName = 'BaseInputNumber';
BaseInputNumber.displayName = BaseInputNumberDisplayName;

export default BaseInputNumber;
