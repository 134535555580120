import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminTurkUserEdit from 'components/templates/admin/turkUserEdit';

const TurkUserEditPage = () => {
  return (
    <Container>
      <AdminHeader />
      <AdminTurkUserEdit />
    </Container>
  );
};

export default TurkUserEditPage;
