import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { appointmentMethodsDataSource } from 'infrastructure/data-sources/appointment-methods';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IAppointmentMethodItem } from 'infrastructure/data-sources/appointment-methods';
import type {
  AppointmentCompletionMethod,
  AppointmentMethod,
} from 'infrastructure/enums';

type Options = IBaseSelectBoxOption<
  AppointmentMethod | AppointmentCompletionMethod,
  IAppointmentMethodItem
>[];

interface IAppointmentMethodSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {
  isCompletionMethods?: boolean;
}

const AppointmentMethodSelectBox: React.FC<
  IAppointmentMethodSelectBoxProps
> = ({ isCompletionMethods, ...props }) => {
  const dataSource = useMemo(
    () => appointmentMethodsDataSource(isCompletionMethods),
    [isCompletionMethods],
  );

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...props} />;
};

export default AppointmentMethodSelectBox;
