import React from 'react';
import { PropTypes } from 'prop-types';

const PlusIcon = ({ height, width, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill={color} />
    <path
      d="M4.99918 10H14.9992"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10 15.0008L9.99903 5.00082"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

PlusIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

PlusIcon.defaultProps = {
  width: '20px',
  height: '20px ',
  color: '#FB9318',
};

export default PlusIcon;
