import { Table } from 'reactstrap';
import { useTurkDashboardSort } from 'utils/hooks/turkDashboard/useSort';
import { useReadingComment } from 'utils/hooks/turkDashboard/useReadingComment';
import common from 'constants/common';
import { showResult } from 'infrastructure/hooks/utils';
import { useCommentGenerationLimit } from 'utils/hooks/dashboard/useCommentGenerationLimit';

import Headers from './headers';
import Row from './row';
import NoData from './noData';

import type { Reading } from 'utils/hooks/turkDashboard/useReadings';
import type { FC } from 'react';

interface Props {
  readings: Reading[];
  onCommentSubmit: (reading: { readingId: string; deviceId: string }) => void;
  dates: {
    startTime: string;
    endTime: string;
    timezoneOffset: number;
  };
}

const TurkDashboardTable: FC<Props> = ({
  readings,
  onCommentSubmit,
  dates,
}) => {
  const { sort, toggleSort, sortedReadings } = useTurkDashboardSort(readings);
  const { submitComment } = useReadingComment();
  const { isCommentGenerationLimitReached, onCommentGenerationStatusChange } =
    useCommentGenerationLimit();

  if (readings.length === 0) {
    return <NoData />;
  }

  return (
    <Table borderless cellSpacing="0" cellPadding="0" className="rpm-table">
      <Headers sort={sort} toggleSort={toggleSort} />
      <tbody>
        {sortedReadings.map((reading) => (
          <Row
            isCommentGenerationLimitReached={isCommentGenerationLimitReached}
            onCommentGenerationStatusChange={onCommentGenerationStatusChange}
            key={`${reading.id}-${reading.deviceId}`}
            reading={reading}
            dates={dates}
            handleCommentSubmit={async (values) => {
              const { error } = await submitComment({
                readingId: reading.id,
                deviceId: reading.deviceId,
                message: values.comment,
                generatedByModel: values.generatedByModel,
                originalGeneratedText: values.originalGeneratedText,
                reviewed: true,
              });
              if (!error) {
                showResult(error, common.submitSuccessTypes.reviewed);

                onCommentSubmit({
                  readingId: reading.id,
                  deviceId: reading.deviceId,
                });
              }
            }}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default TurkDashboardTable;
