import React from 'react';
import BaseAsyncSelectBox from 'components/atoms/base-select-box/async';
import { useRunbookLog } from 'infrastructure/hooks/clinic/use-runbook-logs';

import type { IPaginationMeta } from 'infrastructure/interfaces';
import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { RunbookLog } from 'infrastructure/classes/runbook-log';

type RunbookLogSelectOption = IBaseSelectBoxOption<string, RunbookLog>;

interface IRunbookLogSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {
  clinicGuid: string;
  onLoaded?: (items: Array<RunbookLogSelectOption>) => void;
}

const RunbookLogSelectBox: React.FC<IRunbookLogSelectBoxProps> = (props) => {
  const { clinicGuid, onLoaded } = props;

  const { meta, loadRunbookLogs } = useRunbookLog({
    meta: {
      items: 100,
    },
  });

  const fetchOptions = async (
    searchValue: string,
    metaProps: IPaginationMeta,
  ) => {
    const runbookLog = await loadRunbookLogs({
      ...metaProps,
      clinicGuid,
    });

    if (runbookLog)
      return runbookLog.map((el) => {
        return {
          label: el.createdAt,
          value: el.createdAt,
          record: el,
        };
      });

    return [];
  };

  return (
    <BaseAsyncSelectBox
      fetchOptions={fetchOptions}
      meta={meta}
      onValueLoaded={onLoaded}
      {...props}
    />
  );
};

export default RunbookLogSelectBox;
