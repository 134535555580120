import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const ChatSendIcon = ({ color = COLORS.BUOYANT, ...props }: IIconProps) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.3301 11.692L2.72462 1.86232C2.64494 1.82248 2.55353 1.8131 2.46681 1.83419C2.26759 1.88341 2.14337 2.08498 2.19259 2.28654L4.21291 10.5412C4.24337 10.6654 4.33478 10.7662 4.45666 10.8061L7.91837 11.9944L4.459 13.1826C4.33712 13.2248 4.24572 13.3233 4.21759 13.4475L2.19259 21.7139C2.1715 21.8006 2.18087 21.892 2.22072 21.9694C2.31212 22.1545 2.53712 22.2295 2.72462 22.1381L22.3301 12.3647C22.4028 12.3295 22.4613 12.2686 22.4988 12.1983C22.5903 12.0108 22.5153 11.7858 22.3301 11.692ZM4.50353 19.3654L5.68244 14.5467L12.6012 12.1725C12.6551 12.1537 12.6996 12.1115 12.7184 12.0553C12.7512 11.9569 12.6996 11.8514 12.6012 11.8162L5.68244 9.44435L4.50822 4.64435L19.227 12.0248L4.50353 19.3654Z"
      fill={color}
    />
  </svg>
);

export default ChatSendIcon;
