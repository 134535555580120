// FIXME: Can be deleted. Unused component. The new one => src/components/molecules/reset-password-modal

import PropTypes from 'prop-types';
import ModalPopup from 'components/organisms/modal';
import Button from 'components/atoms/button';
import { useResetEmail } from 'utils/hooks/email/useResetEmail';

const ResetPasswordModal = ({ isOpen, toggle, email }) => {
  const { isLoading, resetPassword } = useResetEmail();

  const handleSubmit = async () => {
    await resetPassword(email);
    toggle(false);
  };
  return (
    <ModalPopup
      isOpen={isOpen}
      toggle={toggle}
      title=""
      className="popup resend-password-popup"
    >
      <div className="resend-password-body">
        <div className="resend-password-title">Send Reset Password Email?</div>
        <div className="resend-password-actions">
          <Button
            onClick={() => handleSubmit()}
            text="Send"
            disabled={isLoading}
            data-cy="send-button"
            smallForPhone
          />
          <Button onClick={() => toggle()} text="Cancel" smallForPhone />
        </div>
      </div>
    </ModalPopup>
  );
};
ResetPasswordModal.defaultProps = {
  isOpen: false,
};

ResetPasswordModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  email: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default ResetPasswordModal;
