import { useQuery, useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { PatientThresholds } from 'infrastructure/classes/patient/patient-thresholds';

import useApi from '../use-api';

import type { IPatientThresholds } from 'infrastructure/interfaces';

const patientThresholdsGetApi = (patientGuid: string) =>
  `core/patients/${patientGuid}/thresholds`;
const patientThresholdsUpdateApi = `core/patients/thresholds`;

interface IUsePatientThresholds {
  thresholds: PatientThresholds;
  loading: boolean;
  updatePatientThresholds: (body: IPatientThresholds) => Promise<void>;
}

interface IUsePatientThresholdsProps {
  patientGuid: string;
}

export const usePatientThresholds = (
  props: IUsePatientThresholdsProps,
): IUsePatientThresholds => {
  const { patientGuid } = props;

  const { loading, loadData, updateData } = useApi();

  const queryClient = useQueryClient();
  const queryKey = ['patientThresholds', patientGuid];

  const { data: thresholds = {} as IPatientThresholds, isLoading } = useQuery({
    queryKey,
    queryFn: async () =>
      loadData<IPatientThresholds>(patientThresholdsGetApi(patientGuid)).then(
        (res) => {
          if (res) {
            return new PatientThresholds(res);
          }
        },
      ),
    enabled: Boolean(patientGuid),
    staleTime: STALE_TIME,
  });

  const updatePatientThresholds = async (
    body: IPatientThresholds,
  ): Promise<void> => {
    const data = await updateData(patientThresholdsUpdateApi, body);
    queryClient.setQueryData(queryKey, data);
  };

  return {
    thresholds,
    loading: loading || isLoading,
    updatePatientThresholds,
  };
};
