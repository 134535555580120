import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import DateSelector from 'components/molecules/dateSelector/range';
import useOutsideClick from 'utils/useOutsideClick';
import common from 'constants/common';
import format from 'date-fns/format';

const DatePicker = ({
  value,
  valueChanged,
  format: customFormat,
  disabled,
}) => {
  const [showDateSelector, setShowDateSelector] = useState(false);
  const onSelect = (date) => {
    valueChanged(
      date
        ? format(new Date(date), customFormat || common.dateFormats.birthDate)
        : date,
    );
    setShowDateSelector(false);
  };
  const ref = useRef();
  useOutsideClick(ref, () => {
    setShowDateSelector(false);
  });
  return (
    <div ref={ref}>
      <div
        onClick={() => {
          setShowDateSelector(true);
        }}
      >
        <Input
          className="main-field-input datepicker-input"
          value={value}
          onChange={(e) => {
            valueChanged(e.target.value);
          }}
          disabled={disabled}
        />
      </div>
      {showDateSelector && (
        <div className="datepicker">
          <DateSelector
            inDate={value}
            onSelect={onSelect}
            customFormat={customFormat}
          />
        </div>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.string,
  format: PropTypes.string,
  valueChanged: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DatePicker;
