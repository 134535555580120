import React from 'react';
import classNames from 'classnames';

import s from './styles.module.scss';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface IBaseLoaderProps extends IBaseComponentProps {
  loading: boolean;
  relative?: boolean;
  width?: number;
  height?: number;
  style?: any;
}

const BaseLoader: React.FC<IBaseLoaderProps> = (props) => {
  const {
    loading,
    className,
    children,
    relative = false,
    width = 40,
    height = 40,
    style,
  } = props;

  const wrapperClassNames = classNames(
    {
      [s.relative]: relative,
    },
    className,
  );

  return (
    <>
      {loading ? (
        <div className={wrapperClassNames} style={style} data-cy="loader">
          <div
            className={s.spinner}
            style={{
              width: `${width}px`,
              height: `${height}px`,
            }}
          >
            <div className={s.dot1} />
            <div className={s.dot2} />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default BaseLoader;
