import PropTypes from 'prop-types';

const CrownOutlinedIcon = ({ height = 18, width = 18 }) => {
  return (
    <svg
      style={{ minHeight: '14px', minWidth: '14px' }}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8128 4.85994L12.3921 6.96756L9.11206 2.59236C9.09886 2.57475 9.08175 2.56046 9.06207 2.55062C9.04239 2.54077 9.02068 2.53564 8.99868 2.53564C8.97668 2.53564 8.95497 2.54077 8.93529 2.55062C8.91561 2.56046 8.8985 2.57475 8.8853 2.59236L5.60698 6.96756L2.18452 4.85994C2.08432 4.79842 1.95425 4.88104 1.97007 4.99881L3.31303 15.2047C3.33237 15.3435 3.4519 15.4508 3.59428 15.4508H14.4066C14.5472 15.4508 14.6685 15.3453 14.6861 15.2047L16.0291 4.99881C16.0431 4.88104 15.9148 4.79842 15.8128 4.85994ZM13.598 14.2484H4.39936L3.45366 7.05193L5.91108 8.56541L8.99956 4.4451L12.088 8.56541L14.5455 7.05193L13.598 14.2484ZM8.99956 8.94686C7.90796 8.94686 7.02026 9.83455 7.02026 10.9262C7.02026 12.0178 7.90796 12.9055 8.99956 12.9055C10.0912 12.9055 10.9789 12.0178 10.9789 10.9262C10.9789 9.83455 10.0912 8.94686 8.99956 8.94686ZM8.99956 11.7752C8.53198 11.7752 8.15229 11.3955 8.15229 10.9262C8.15229 10.4586 8.53198 10.0771 8.99956 10.0771C9.46714 10.0771 9.84682 10.4568 9.84682 10.9262C9.84682 11.3937 9.46714 11.7752 8.99956 11.7752Z"
        fill="currentColor"
      />
    </svg>
  );
};

CrownOutlinedIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default CrownOutlinedIcon;
