import React, { useEffect, useState } from 'react';
import { Col, Container, Card, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';
import Button from 'components/atoms/button';
import common from 'constants/common';
import { isPatient, isPractice } from 'utils/userTypeHelper';
import { showResult } from 'infrastructure/hooks/utils';

import type { FunctionComponent } from 'react';
import type { RootState } from 'store';

type Props = {
  alertEmail?: boolean;
  alertSms?: boolean;
  userType: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  birthDate: string;
  email: string;
  phone: string;
  toggleAlerts: (data: {
    alertEmail?: boolean;
    alertSms?: boolean;
  }) => Promise<{ error?: any; data?: any }>;
};

const Profile: FunctionComponent<Props> = ({
  alertEmail,
  alertSms,
  userType,
  firstName = '',
  lastName = '',
  title = '',
  birthDate,
  email,
  phone,
  toggleAlerts,
}) => {
  const [alertEmailValue, setAlertEmailValue] = useState(alertEmail);
  const [alertSmsValue, setAlertSmsValue] = useState(alertSms);

  useEffect(() => {
    setAlertEmailValue(alertEmail);
  }, [alertEmail]);

  useEffect(() => {
    setAlertSmsValue(alertSms);
  }, [alertSms]);

  const { toggleAlerts: toggleAlertsLoading } = useSelector(
    (state: RootState) => state.loading.effects.user,
  );

  const updateAlerts = async () => {
    if (alertEmailValue !== alertEmail || alertSmsValue !== alertSms) {
      const { error } = await toggleAlerts({
        alertEmail: alertEmailValue,
        alertSms: alertSmsValue,
      });
      showResult(error);
    }
  };

  const onSwitchEmailAlert = (checked: boolean) => {
    setAlertEmailValue(checked);
  };

  const onSwitchSmsAlert = (checked: boolean) => {
    setAlertSmsValue(checked);
  };

  return (
    <Container>
      <Card className="card-border profile-page">
        <h3 className="title-section m-0">Account Data</h3>
        <Row className="m-0">
          <Col xs="12" md="6" className="info-section">
            <section className="personal-info-section">
              <p className="profile-info-label">Personal Info</p>
              <p className="info-value">
                {`${title || ''} ${firstName || ''} ${lastName || ''}`}
              </p>
              {(isPatient(userType) || birthDate) && (
                <p className="info-value">{`DOB: ${birthDate}`}</p>
              )}
            </section>
            <section className="contact-info-section">
              <p className="profile-info-label">Contact Info</p>
              {phone && <p className="info-value">{`${phone}`}</p>}
              {email && <p className="info-value">{`${email}`}</p>}
            </section>
          </Col>
          {isPractice(userType) && (
            <Col xs="12" md="6" className="info-section">
              <section className="personal-info-section">
                <p className="profile-info-label">Profile Settings</p>
                <div className="switch-setting">
                  <p>User Alert Email</p>
                  <Switch
                    className="evelyn-switch"
                    onChange={onSwitchEmailAlert}
                    checked={Boolean(alertEmailValue)}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onHandleColor={common.commonColors.green}
                    offHandleColor={common.commonColors.gray}
                    onColor={common.commonColors.white}
                    offColor={common.commonColors.white}
                    handleDiameter={24}
                    height={30}
                    width={59}
                    activeBoxShadow="0"
                  />
                </div>
                <div className="switch-setting">
                  <p>User Alert Sms</p>
                  <Switch
                    className="evelyn-switch"
                    onChange={onSwitchSmsAlert}
                    checked={Boolean(alertSmsValue)}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onHandleColor={common.commonColors.green}
                    offHandleColor={common.commonColors.gray}
                    onColor={common.commonColors.white}
                    offColor={common.commonColors.white}
                    handleDiameter={24}
                    height={30}
                    width={59}
                    activeBoxShadow="0"
                  />
                </div>
              </section>
            </Col>
          )}
        </Row>
        {isPractice(userType) && (
          <section className="save-button-section">
            <Button
              text="Save"
              data-cy="save-button"
              handleClick={updateAlerts}
              disabled={toggleAlertsLoading}
            />
          </section>
        )}
      </Card>
    </Container>
  );
};

export default Profile;
