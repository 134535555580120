import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const ccmNextAppointmentValidationSchema = () =>
  createValidationSchema({
    appointment: createValidationSchema({
      scheduledDate: commonValidationSchemas.string({ required: true }),
      scheduledTime: commonValidationSchemas.string({ required: true }),
      scheduledWith: commonValidationSchemas.string({ required: true }),
      appointmentMethod: commonValidationSchemas.string({ required: true }),
    }),
  });
