export default {
  maxCriticalSystolic: 160,
  minCriticalSystolic: 85,
  maxCriticalDiastolic: 90,
  minCriticalDiastolic: 50,
  maxCriticalPulse: 100,
  minCriticalPulse: 50,
  maxCriticalSleepPulse: 130,
  minCriticalSleepPulse: 20,
  maxCriticalGlucose: 350,
  minCriticalGlucose: 60,
  maxRiskSystolic: 130,
  minRiskSystolic: 100,
  maxRiskDiastolic: 88,
  minRiskDiastolic: 60,
  maxRiskPulse: 90,
  minRiskPulse: 60,
  maxRiskSleepPulse: 119,
  minRiskSleepPulse: 30,
  maxRiskGlucose: 200,
  minRiskGlucose: 80,
  criticalBloodoxygen: 93,
  riskBloodoxygen: 94,
  maxCriticalWeight: 3,
  minCriticalWeight: 3,
  maxRiskWeight: 2,
  minRiskWeight: 2,
  frequencyHypertension: 0,
  frequencyDiabetes: 0,
  frequencyBloodoxygen: 0,
  frequencyPulse: 0,
  frequencyWeight: 0,
  minRiskPef: 120,
  minCriticalPef: 85,
  minCriticalFev1: 1.0,
};
