import { Input } from 'antd';
import { availableBillableMinutesToUnregisterReadings } from 'infrastructure/services/interactiveTime';

import s from './styles.module.scss';

import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/interactiveTimeDashboard/types';
import type { FC } from 'react';

interface Props {
  filters: Filters;
  appendFilters: AppendFilters;
}

const AvailableBillableMinutes: FC<Props> = ({ appendFilters, filters }) => {
  return (
    <>
      <div className={s['available-billable-minutes-wrapper']}>
        <label htmlFor="availableBillableMinutesMin">
          Available Billable Minutes
        </label>
        <div className={s['input-wrapper']}>
          <Input
            onWheel={(e) => e.currentTarget.blur()}
            id="availableBillableMinutesMin"
            placeholder="Min"
            type="number"
            value={filters.agencyAvailableBillableMinutesMin?.inputValue}
            onChange={(e) => {
              const value = availableBillableMinutesToUnregisterReadings(
                e.target.value,
              );
              appendFilters({
                agencyAvailableBillableMinutesMin: {
                  inputValue: e.target.value,
                  value,
                },
              });
            }}
          />
          <Input
            onWheel={(e) => e.currentTarget.blur()}
            id="agencyAvailableBillableMinutesMax"
            placeholder="Max"
            value={filters.agencyAvailableBillableMinutesMax?.inputValue}
            onChange={(e) => {
              const value = availableBillableMinutesToUnregisterReadings(
                e.target.value,
              );
              appendFilters({
                agencyAvailableBillableMinutesMax: {
                  inputValue: e.target.value,
                  value,
                },
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AvailableBillableMinutes;
