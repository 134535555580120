import i18next from 'i18next';
import { formatAuthorName } from 'utils/formatters';
import NewFileIcon from 'components/atoms/icons/newFileIcon';
import BaseButton from 'components/atoms/baseButton';
import BannerActionDownloadIcon from 'components/atoms/icons/bannerActionDownloadIcon';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { IVoiceCall, ICall, ICallAuthor } from 'infrastructure/interfaces';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

interface Props {
  onDownload: (callId: string) => void;
  downloadedCallId?: string;
  downloadLoading: boolean;
}

export const columns = ({
  onDownload,
  downloadedCallId,
  downloadLoading,
}: Props): IBaseTableColumns<IVoiceCall> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'call',
      width: '8%',
      render(call: ICall) {
        return (
          <span data-cy="voice-date">
            {DateFormatter({
              date: call.createdAt,
              format: DateFormats['MM-dd-yyyy h:mm a'],
            })}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.duration'),
      key: 'call',
      align: 'center',
      width: '8%',
      render(call: ICall) {
        return <span data-cy="voice-duration">{call.duration}</span>;
      },
    },
    {
      label: i18next.t('labels.electronicallySignedBy'),
      key: 'author',
      width: '40%',
      render(author: ICallAuthor) {
        return (
          <span data-cy="voice-author">
            {formatAuthorName({
              title: author.title,
              firstName: author.firstName,
              lastName: author.lastName,
              professionType: author.profession,
            })}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.outcome'),
      key: 'call',
      width: '39%',
      render(call: ICall) {
        return (
          <span className={s.outcome} data-cy="voice-outcome">
            <NewFileIcon />
            {call.callId}.wav
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.option'),
      key: 'call',
      width: '5%',
      align: 'center',
      render(call: ICall) {
        return (
          <BaseButton
            dataCy="voice-download-btn"
            type="secondary"
            icon={<BannerActionDownloadIcon />}
            className={s.btn}
            onClick={() => onDownload(call.callId)}
            loading={downloadedCallId === call.callId && downloadLoading}
          />
        );
      },
    },
  ];
};
