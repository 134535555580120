/* eslint-disable prettier/prettier */
import dataService from 'services/dataService';
import {
  apiDoctorOnboardingStatus,
  apiDoctorPatients,
  ONBOARDING_CSV_REPORT,
} from 'services/dataService/resources';
import { generateFullName } from 'utils/badgeHelpers';

const getCountByStatus = (patientsByStatus, statusFind) => {
  if (statusFind === 'patient_refused') {
    return (
      patientsByStatus
        .filter((el) => el.status.includes('patient_refused'))
        .reduce((acum, current) => acum + parseInt(current.count), 0) || 0
    );
  }
  const obj = patientsByStatus.find(({ status }) => status === statusFind);
  return obj ? obj.count : 0;
};

const INITIAL_STATE = {
  statuses: {
    patient_referral: 0,
    referral_on_hold: 0,
    referral_withdrawn: 0,
    calling_queue: 0,
    patient_no_contact: 0,
    refused: 0,
    insurance_refused: 0,
    onboarding: 0,
    onboarding_unsuccessful: 0,
    total: 0,
    active: 0,
  },
  insurances: [],
  patients: [],
  patientCount: null,
  clinic: null,
  doctor: null,
  patientGuid: null,
  childClinic: null,
  ITEMS_PER_PAGE: 100,
};

const onboardingStatus = {
  state: INITIAL_STATE,
  reducers: {
    resetState: () => INITIAL_STATE,
    setData: (
      state,
      { patientsByStatus, patientsByInsurances, activePatients, totalPatients },
    ) => ({
      ...state,
      statuses: {
        patient_referral: getCountByStatus(patientsByStatus, 'patient_referral'),
        referral_on_hold: getCountByStatus(patientsByStatus, 'referral_on_hold'),
        referral_withdrawn: getCountByStatus(
          patientsByStatus,
          'referral_withdrawn',
        ),
        calling_queue: getCountByStatus(patientsByStatus, 'calling_queue'),
        patient_no_contact: getCountByStatus(patientsByStatus, 'patient_no_contact'),
        refused: getCountByStatus(patientsByStatus, 'patient_refused'),
        insurance_refused: getCountByStatus(
          patientsByStatus,
          'insurance_refused',
        ),
        onboarding: getCountByStatus(patientsByStatus, 'onboarding'),
        onboarding_unsuccessful: getCountByStatus(
          patientsByStatus,
          'onboarding_unsuccessful',
        ),
        total: totalPatients,
        active: activePatients,
      },
      insurances: patientsByInsurances,
    }),
    setPatientData: (state, { items, count }) => ({
      ...state,
      patients: items.map((item) => ({
        guid: item.guid,
        name: `${item.firstName} ${item.lastName}`,
        mrn: item.mrn,
        dob: item.birthDate,
        phone: item.phone,
        insType: item.insurances.length ? item.insurances[0].type : null,
        clinic: item.clinic,
        primaryDoctor:
          item.primaryDoctor &&
          generateFullName(
            item.primaryDoctor.title,
            item.primaryDoctor.firstName,
            null,
            item.primaryDoctor.lastName,
          ),
        insProvider: item.insurances.length
          ? item.insurances.map((insurance) => insurance.provider).join(',\n')
          : null,
        insNumber: item.insurances.length
          ? item.insurances.map((insurance) => insurance.number).join(',\n')
          : null,
        fulfillment: item.status,
        practiceEmail: item.primaryDoctor.email,
        createdAt: item?.createdAt,
      })),
      patientCount: count,
    }),
    setPatientGuid: (state, { patientGuid }) => ({
      ...state,
      patientGuid,
    }),
    setClinic: (state, payload) => ({
      ...state,
      clinic: payload,
    }),
    setDoctor: (state, payload) => ({
      ...state,
      doctor: payload,
    }),
    setStatus: (state, payload) => ({
      ...state,
      status: payload,
      patientStatus: payload,
    }),
    setReferralDateSort: (state, payload) => ({
      ...state,
      referralDateSort: payload,
    }),
    setChildClinic: (state, payload) => ({
      ...state,
      childClinic: payload,
    }),
  },
  effects: (dispatch) => ({
    async getOnboardingStatusReport(payload, state) {
      const { clinic, doctor, childClinic } = state.onboardingStatus;
      const { data } = await dataService.getList(
        apiDoctorOnboardingStatus({ clinicGuid: clinic, doctorGuid: doctor, childClinic }),
      );

      if (data) {
        dispatch.onboardingStatus.setData(data);
      }
    },

    async getPatientsReport(payload, state) {
      const { clinic, doctor, status, referralDateSort, ITEMS_PER_PAGE, childClinic } =
        state.onboardingStatus;

      let requestStatus = status;
      if (!status || status === 'all') {
        requestStatus = 'only_onboarding_flow';
      }
      const { data } = await dataService.getList(
        apiDoctorPatients({
          clinicGuid: clinic,
          status: requestStatus,
          patientGuid: payload?.patient,
          doctorGuid: doctor,
          referralDateSort,
          clinicsStatus: 'active,delinquent',
          childClinic,
          page: payload?.page || 1,
          items: ITEMS_PER_PAGE,
        }),
      );

      if (data) {
        dispatch.onboardingStatus.setPatientData(data);
      }
    },

    async setClinicOnboardingStatus(clinic) {
      dispatch.onboardingStatus.setClinic(clinic);
      dispatch.onboardingStatus.setChildClinic(!!clinic);
      dispatch.onboardingStatus.getOnboardingStatusReport();
      dispatch.onboardingStatus.getPatientsReport();
    },
    async setDoctorOnboardingStatus(doctor) {
      dispatch.onboardingStatus.setDoctor(doctor);
      dispatch.onboardingStatus.getOnboardingStatusReport();
      dispatch.onboardingStatus.getPatientsReport();
    },
    async setPatient(patient) {
      dispatch.onboardingStatus.setPatientGuid({ patientGuid: patient });
      dispatch.onboardingStatus.getPatientsReport({ patient });
    },

    async setStatus(status) {
      dispatch.onboardingStatus.getPatientsReport({
        status,
      });
    },
    async setChildClinicValue(childClinic) {
      dispatch.onboardingStatus.setChildClinic(childClinic);
      dispatch.onboardingStatus.getOnboardingStatusReport();
      dispatch.onboardingStatus.getPatientsReport();
    },
    async getOnboardingCsvReport(payload, state) {
      const { clinic, status, doctor, patientGuid, childClinic } = state.onboardingStatus;
      let requestStatus = status;
      if (!status || status === 'all') {
        requestStatus = 'only_onboarding_flow';
      }
      const body = {
        status: requestStatus,
      };
      if (clinic) body.clinicGuid = clinic.split(',');
      if (doctor) body.doctorGuids = doctor.split(',');
      if (patientGuid) body.patientGuid = patientGuid;
      if (childClinic) body.childClinic = childClinic;
      const { error, data } = await dataService.createOne(
        ONBOARDING_CSV_REPORT,
        body,
      );
      return { error, data };
    },
    resetFilters() {
      dispatch.onboardingStatus.resetState();
    },
  }),
};

export default onboardingStatus;
