import { useQuery } from '@tanstack/react-query';
import { DateFormatter } from 'infrastructure/functions';
import dataService from 'services/dataService';
import { apiPatientInteractiveTime } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface Props {
  patientGuid: string;
  enabled: boolean;
}

export interface Res {
  patientGuid: string;
  clinicGuid: string;
  totalTime: number;
  clinicTime: number;
}

export const getRpmInteractionTimeKey = (patientGuid: string, date: string) => [
  'getRpmInteractionTime',
  patientGuid,
  date,
];

export const useGetRpmInteractionTime = ({ patientGuid, enabled }: Props) => {
  const today = new Date();
  const formattedDate = DateFormatter({ date: today });

  const { data: rpmInteractionTime, ...other } = useQuery({
    queryKey: getRpmInteractionTimeKey(patientGuid, formattedDate),
    queryFn: async () => {
      const { data, error } = await dataService.getList<Res>(
        apiPatientInteractiveTime(patientGuid, formattedDate),
      );

      if (error) {
        showResult(error);
        throw new Error(error);
      }

      return data;
    },
    enabled,
  });

  return { rpmInteractionTime, ...other };
};
