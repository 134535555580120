export const arraysEqual = <T>(
  arr1?: null | T[],
  arr2?: null | T[],
  key?: keyof T,
): boolean => {
  if (!arr1 || !arr2) return false;
  if (arr1.length !== arr2.length) return false;

  if (key) {
    const sortedArr1 = [...arr1].sort((a, b) => (a[key] > b[key] ? 1 : -1));
    const sortedArr2 = [...arr2].sort((a, b) => (a[key] > b[key] ? 1 : -1));

    return sortedArr1.every(
      (item, index) => item[key] === sortedArr2[index][key],
    );
  }

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};
