import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';
import { fullNamePresenter } from 'utils';

import type { IClinicDoctor } from 'infrastructure/interfaces';

interface IDataProps {
  searchValue: string;
  searchKey: string;
  status?: string;
  roleName?: string;
}

const aminPanelSearchDoctorsFn = async ({
  searchKey,
  searchValue,
  status,
  roleName,
}: IDataProps) => {
  const { data, error } = await dataService.getList<IClinicDoctor[]>(
    apiUrlGenerator('core/doctors', {
      getInactiveClinicData: true,
      [searchKey]: searchValue,
      status,
      roleName,
    }),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  const preparedData =
    data?.map((patient) => ({
      value: patient.guid,
      label: fullNamePresenter(patient),
    })) ?? [];

  if (data) {
    return preparedData;
  }
};

export const useAminPanelSearchDoctor = () => {
  const { mutate, data, isLoading, ...other } = useMutation({
    mutationFn: aminPanelSearchDoctorsFn,
  });

  return {
    searchDoctor: mutate,
    doctors: data,
    searchDoctorLoading: isLoading,
    ...other,
  };
};
