import ArrowSortIcon from 'components/atoms/icons/ArrowSortIcon';

import type { FC } from 'react';
import type { Sort } from 'utils/hooks/turkDashboard/useSort';

interface Props {
  sort: Sort;
  toggleSort: (key: keyof Sort) => void;
}

const Headers: FC<Props> = ({ sort, toggleSort }) => {
  return (
    <thead>
      <tr>
        <th className="table-status-column">Status</th>
        <th className="table-time-column text-nowrap">
          <button
            className="button-reset text-bold"
            onClick={() => {
              toggleSort('time');
            }}
          >
            Time
            <ArrowSortIcon
              up={sort.time === 'ASC'}
              down={sort.time === 'DESC'}
              style={{ marginLeft: 8 }}
            />
          </button>
        </th>
        <th className="table-patient-column">
          <button
            className="button-reset text-nowrap text-bold"
            onClick={() => {
              toggleSort('patientGuid');
            }}
          >
            Patient Guid
            <ArrowSortIcon
              up={sort.patientGuid === 'ASC'}
              down={sort.patientGuid === 'DESC'}
              style={{ marginLeft: 8 }}
            />
          </button>
        </th>
        <th className="table-reading-column">Reading</th>
        <th className="table-comment-column">Comment</th>
      </tr>
    </thead>
  );
};

export default Headers;
