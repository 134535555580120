import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const conditionsValidationSchema = () =>
  createValidationSchema({
    patientGuid: commonValidationSchemas.string({ required: true }),
    diagnoseGuid: commonValidationSchemas.string({ required: true }),
    description: commonValidationSchemas.string({ required: true }),
  });
