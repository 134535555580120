import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ModuleType } from 'infrastructure/enums';
import { ManualClinicNotesLog } from 'infrastructure/classes/logs/manual-clinic-notes-log';
import { prepareDateRange } from 'infrastructure/functions';

import useApi from '../use-api';

import type { DateRange } from 'infrastructure/types';
import type {
  IPaginatedManualClinicNotesLogs,
  IPaginationMeta,
  IPartialPaginationMeta,
} from 'infrastructure/interfaces';
import type { Dispatch, SetStateAction } from 'react';

const ManualClinicNotesLogApi = (patientGuid: string, isCcm: boolean) => {
  let endpoint = `core/patients/${patientGuid}/manual-time`;
  if (isCcm) {
    endpoint = `core/ccm/patients/${patientGuid}/manual-time`;
  }
  return endpoint;
};

const prepareDate = (dateRange?: DateRange) => {
  let startTime;
  let endTime;
  if (dateRange && dateRange.length) {
    [startTime, endTime] = prepareDateRange(dateRange);
  }

  return {
    startTime: startTime ? startTime.toISOString() : undefined,
    endTime: endTime ? endTime.toISOString() : undefined,
  };
};

export interface IUseLogMeta {
  module?: ModuleType;
  dateRange?: DateRange;
}

type mergedMeta = IUseLogMeta & IPaginationMeta;

type UseLog = {
  loading: boolean;
  items: Array<ManualClinicNotesLog>;
  meta: mergedMeta;
  setMeta: Dispatch<SetStateAction<mergedMeta>>;
  refetch: () => void;
};

interface IUseLogProps {
  patientGuid: string;
  meta?: IUseLogMeta & IPartialPaginationMeta;
}

export const useManualClinicNotesLog = (props: IUseLogProps): UseLog => {
  const options = props;
  const { patientGuid, meta: metaProps = {} } = options;

  const [meta, setMeta] = useState<mergedMeta>({
    page: 1,
    items: 15,
    totalCount: 0,
    ...metaProps,
  });

  const isCcm = meta.module === ModuleType.CCM;

  const { loadData } = useApi();

  const queryKey = [
    ManualClinicNotesLogApi(patientGuid, isCcm),
    prepareDate(meta.dateRange),
    meta.page,
  ];

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async () =>
      loadData<IPaginatedManualClinicNotesLogs, Omit<IUseLogMeta, 'sortField'>>(
        ManualClinicNotesLogApi(patientGuid, isCcm),
        {
          sortField: JSON.stringify(meta.sortField),
          page: meta.page,
          items: meta.items,
          patientGuid: isCcm ? patientGuid : undefined,
          ...prepareDate(meta.dateRange),
        },
      ).then((res) => {
        if (res) {
          const { count, data: rows } = res;

          setMeta({
            ...meta,
            totalCount: count,
          });

          return rows.map((el) => {
            return new ManualClinicNotesLog(el);
          });
        }
      }),
    enabled: Boolean(patientGuid),
  });

  return {
    loading: isLoading,
    items: data ?? [],
    meta,
    setMeta,
    refetch,
  };
};
