import BaseTableNew from 'components/atoms/base-table/new';
import React, { useState } from 'react';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import PlusIcon from 'components/atoms/icons/plus';
import BaseModal from 'components/atoms/base-modal';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import { ModuleType, Status } from 'infrastructure/enums';
import { usePatientDevices } from 'infrastructure/hooks/device/use-patient-devices';
import { PatientDevice } from 'infrastructure/classes/patient/patient-device';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import useUser from 'utils/useUser';

import { devicesColumns } from './cols';
import s from './styles.module.scss';
import DeviceCreateForm from '../create-form';
import DeviceUpdateForm from '../update-form';
import DevicesLogsTable from '../logs';
import DeviceConsumableForm from '../consumable-form';

import type { RpmStatuses } from 'infrastructure/enums';

type ModalContent = 'create' | 'edit' | 'logs' | 'consumable';

interface IDevicesTableProps {
  patientGuid: string;
  module: ModuleType;
  rpmStatus?: RpmStatuses;
}

const DevicesTable: React.FC<IDevicesTableProps> = (props) => {
  const { patientGuid, rpmStatus, module } = props;

  const { t } = useTranslation();
  const { Alert, showAlert } = useAlert();

  const { loading, devices, updatePatientDevices, deletePatientDevices } =
    usePatientDevices({ patientGuid });

  const { iamPatientAcceptance } = useUser();

  const [isOpened, setIsOpened] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContent>('logs');
  const [patientDevice, setPatientDevice] = useState<
    PatientDevice | undefined
  >();

  const canCreate = module === ModuleType.ADMIN && !iamPatientAcceptance;

  const modalWidth = modalContent === 'logs' ? '1000px' : undefined;

  const modalLabelMap = {
    consumable: t('labels.orderConsumables'),
    create: t('labels.addDevice'),
    edit: t('labels.editDevice'),
    logs: t('controls.activityLog'),
  };

  const onCreate = () => {
    setModalContent('create');
    setIsOpened(true);
  };

  const onCancel = () => {
    setIsOpened(false);
    setPatientDevice(undefined);
  };

  const onDelete = async (data: PatientDevice) => {
    const res = await showAlert({
      title: t('controls.deleteDevice'),
      messageTitle: t('confirm.areYouSureYouWantDeleteThis', {
        value: t('labels.device'),
      }),
      message: t('messages.deviceDeletingConfirm'),
      type: 'danger',
    });
    if (res) {
      await deletePatientDevices(data.deviceId);
    }
  };

  const onEdit = (data: PatientDevice) => {
    setModalContent('edit');
    setPatientDevice(data);
    setIsOpened(true);
  };

  const onToggleStatus = async (data: PatientDevice) => {
    const action = data.status === Status.Active ? 'disable' : 'enable';
    const status = data.status === Status.Active ? 'deactivate' : 'activate';
    const res = await showAlert({
      title: `${t(`labels.${status}`)} ${t('labels.device')}`,
      messageTitle: t('messages.deviceStatusChangingConfirm', {
        status: t(`labels.${status}`).toLowerCase(),
      }),
      type: 'primary',
      successBtnLabel: t(`labels.${status}`),
      rejectBtnLabel: t('controls.cancel'),
    });
    if (res) {
      await updatePatientDevices({
        patientGuid,
        deviceId: data.deviceId,
        manufacturer: data.manufacturer,
        readingTypes: PatientDevice.prepareReadingTypes(data.readingTypes),
        action,
      });
    }
  };

  const onActivity = (data: PatientDevice) => {
    setModalContent('logs');
    setPatientDevice(data);
    setIsOpened(true);
  };

  const onOrder = (data: PatientDevice) => {
    setModalContent('consumable');
    setPatientDevice(data);
    setIsOpened(true);
  };

  const onSaved = async () => {
    setIsOpened(false);
  };

  const onSendOrderConsumables = async () => {
    setIsOpened(false);
    notificationController.success({
      message: t('responseMessages.orderConsumablesSuccess'),
    });
  };

  return (
    <div>
      <div className={s.header}>
        <h3 className={s.title}>{t('labels.devices')}</h3>
        {canCreate && (
          <BaseButton
            icon={<PlusIcon color="" />}
            label={t('controls.addDevice')}
            onClick={onCreate}
            loading={loading}
            dataCy="addDevice-button"
          />
        )}
      </div>
      <BaseTableNew
        dataCy="devices-table"
        rowKey="key"
        columns={devicesColumns({
          module,
          onDelete,
          onEdit,
          onToggleStatus,
          onActivity,
          onOrder,
          rpmStatus,
          iamPatientAcceptance,
        })}
        bordered
        items={devices}
        loading={loading}
      />

      <BaseModal
        title={modalLabelMap[modalContent]}
        open={isOpened}
        onCancel={onCancel}
        width={modalWidth}
      >
        {modalContent === 'create' && (
          <DeviceCreateForm
            patientGuid={patientGuid}
            onSaved={onSaved}
            onCancel={onCancel}
          />
        )}
        {modalContent === 'edit' && patientDevice && (
          <DeviceUpdateForm
            patientGuid={patientGuid}
            data={patientDevice}
            onSaved={onSaved}
            onCancel={onCancel}
          />
        )}
        {modalContent === 'logs' && patientDevice && (
          <DevicesLogsTable deviceId={patientDevice.deviceId} />
        )}
        {modalContent === 'consumable' && patientDevice && (
          <DeviceConsumableForm
            patientGuid={patientGuid}
            data={patientDevice}
            onCancel={onCancel}
            onSaved={onSendOrderConsumables}
          />
        )}
      </BaseModal>
      {Alert}
    </div>
  );
};

export default DevicesTable;
