import React from 'react';
import { convertSecondsToTime } from 'utils/timeHelper';
import { CareAgentTypes } from 'constants/careAgentTypes';
import { PropTypes } from 'prop-types';
import { generateFullName } from 'utils/badgeHelpers';
import PatientNameHoldingStatusFlag from 'components/atoms/patientStatus/patientNameHoldingStatusFlag';
import { useSelector } from 'react-redux';
import { isMobile } from 'utils/deviceHelper';
import { getPatientCcmStatus } from 'utils';
import { useTranslation } from 'react-i18next';
import useUser from 'utils/useUser';

const BodyRow = ({ row, disabled, hasAccessToCareAgent, isBiller }) => {
  const { label } = getPatientCcmStatus(row.patient.ccmStatus);
  const device = useSelector((state) => state.user.deviceType);
  const ccmManagerCount = row.patient.careAgents.filter(
    (careAgent) => careAgent.type === CareAgentTypes.CCMManager,
  ).length;
  const ccmOnboardingAgentCount = row.patient.careAgents.filter(
    (careAgent) => careAgent.type === CareAgentTypes.CCMOnboardingAgent,
  ).length;

  const { t } = useTranslation();
  const { isAdmin } = useUser();

  return (
    <tr>
      <td
        data-label={`${t('labels.patientName')}:`}
        className="ccm-activity-row__name"
      >
        <PatientNameHoldingStatusFlag
          title={row.patient.title}
          firstName={row.patient.firstName}
          lastName={row.patient.lastName}
          holdingStatus={row.patient.holdingStatus?.status}
          ccmHoldingStatus={row.patient.ccmHoldingStatus}
          ccmHoldingStatusStartDate={row.patient.ccmHoldingStatusStartDate}
          displayFlag={!isBiller}
          to={`/ccm/patient/${row.patient.guid}`}
          dataCy="ccm-activity-row__patient-link"
          className={disabled ? 'patient-link disable-block' : 'patient-link'}
        />
      </td>

      <td data-label={`${t('labels.dob')}:`} className="ccm-activity-row__bod">
        {row.patient.birthDate}
      </td>
      <td data-label={`${t('labels.careModulesICD10Codes')}:`}>
        <div className="ccm-activity-row__icd-codes">
          {row.icdCodes.map((icd, index) => (
            <span key={`${icd.guid}-${index}`}>{icd.code};</span>
          ))}
          {isMobile(device) && !row.icdCodes?.length && '-'}
        </div>
      </td>
      <td data-label={`${t('labels.provider')}:`}>
        {generateFullName(
          row.provider.title,
          row.provider.firstName,
          null,
          row.provider.lastName,
          row.provider.profession,
        )}
      </td>
      {hasAccessToCareAgent && (
        <td
          data-label={`${t('labels.caseManager')}:`}
          data-cy="ccm-activity-row__care-agents"
          className="ccm-activity-row__care-agents"
        >
          {row.patient.careAgents?.map((careAgent) => {
            if (careAgent.type === CareAgentTypes.CCMManager) {
              return (
                <span>
                  {generateFullName(
                    careAgent.careAgentTitle,
                    careAgent.careAgentFirstName,
                    null,
                    careAgent.careAgentLastName,
                  )}
                  {ccmManagerCount > 1 && ','}
                  {!careAgent.isSameAgency && !isAdmin && (
                    <span className="care-agents__warning">
                      {t('labels.careAgentsWarning')}
                    </span>
                  )}
                </span>
              );
            }
            return null;
          })}
          {isMobile(device) && !row.patient.careAgents?.length && '-'}
        </td>
      )}
      {hasAccessToCareAgent && (
        <td
          data-label={`${t('labels.onboardingAgent')}:`}
          data-cy="ccm-activity-row__care-agents"
          className="ccm-activity-row__care-agents"
        >
          {row.patient.careAgents?.map((careAgent) => {
            if (careAgent.type === CareAgentTypes.CCMOnboardingAgent) {
              return (
                <span>
                  {generateFullName(
                    careAgent.careAgentTitle,
                    careAgent.careAgentFirstName,
                    null,
                    careAgent.careAgentLastName,
                  )}
                  {ccmOnboardingAgentCount > 1 && ','}
                  {!careAgent.isSameAgency && !isAdmin && (
                    <span className="care-agents__warning">
                      {t('labels.careAgentsWarning')}
                    </span>
                  )}
                </span>
              );
            }
            return null;
          })}
          {isMobile(device) && !row.patient.careAgents?.length && '-'}
        </td>
      )}
      <td data-label="Total Interactive Time:">
        {row.lastActivity ? (
          convertSecondsToTime(row.totalActionTime)
        ) : (
          <div className="table-rounded__activity--no-info">00:00:00</div>
        )}
      </td>
      <td data-label="Status:">{label}</td>
    </tr>
  );
};
BodyRow.propTypes = {
  row: PropTypes.object,
  disabled: PropTypes.bool,
  hasAccessToCareAgent: PropTypes.bool,
  isBiller: PropTypes.bool,
};

export default BodyRow;
