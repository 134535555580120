import { useInfiniteQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';

import type { IMessagesListRes } from 'infrastructure/interfaces';

interface Props {
  conversationId: number | string;
  cursor: Date | string | undefined;
  searchQuery: string | undefined;
}

const PAGE_SIZE = 5;

export const getSmsDashboardMessagesFn = async (props: Props) => {
  const url = (id: string) => `core/conversations/${id}/messages`;

  const { data, error } = await dataService.getList<IMessagesListRes>(
    apiUrlGenerator(url(String(props.conversationId)), {
      ...props,
      pageSize: PAGE_SIZE,
    }),
  );

  if (error) {
    showResult('Unable to retrieve messages');
    throw new Error(error);
  }
  return data;
};

export const getSmsDashboardMessagesKey = ({
  conversationId,
  cursor,
  searchQuery,
}: Props) => ['getSmsDashboardMessages', conversationId, searchQuery, cursor];

export const useSmsDashboardMessages = (props: Props) => {
  const {
    data: messages,
    refetch,
    isFetching,
    isLoading,
    isRefetching,
    ...other
  } = useInfiniteQuery({
    queryKey: getSmsDashboardMessagesKey(props),
    queryFn: async ({ pageParam = 0 }) =>
      getSmsDashboardMessagesFn({
        ...props,
        cursor: pageParam || props.cursor,
      }),
    getNextPageParam: (lastPage) => {
      return lastPage?.hasMore
        ? lastPage?.items[lastPage?.items.length - 1]?.createdAt
        : false;
    },
  });

  return {
    messages: messages?.pages.flat(),
    fetchMessages: refetch,
    messagesLoading: isLoading || isFetching || isRefetching,
    isLoading,
    isFetching,
    ...other,
  };
};
