import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import BaseSelectBox from 'components/atoms/base-select-box';
import { AutoComplete, Input } from 'antd';
import InputSearchIcon from 'components/atoms/icons/inputSearchIcon';
import BaseLoader from 'components/atoms/base-loader';
import { ModuleType } from 'infrastructure/enums';
import { useTranslation } from 'react-i18next';
import useUser from 'utils/useUser';
import { isMobile } from 'utils/deviceHelper';
import { useAppSelector } from 'store';
import NavigationHeader from 'components/molecules/navigation-header';
import classNames from 'classnames';

import s from './styles.module.scss';
import AppointmentList from './appointment-list';
import { useRpmCcmHeader } from './hooks/use-rpm-ccm-header';

import type { INavigationHeaderProps } from 'components/molecules/navigation-header';
import type { FC } from 'react';

interface IProps extends INavigationHeaderProps {
  patientGuid: string;
  module?: ModuleType;
  clinicGuid?: string;
}

const RpmCcmNavigationHeader: FC<IProps> = ({
  patientGuid,
  module = ModuleType.CCM,
  clinicGuid,
  goBackPath,
  title,
}) => {
  const { t } = useTranslation();
  const {
    dataOptions,
    debounceSearch,
    isLoading,
    placeholder,
    options,
    dateRange,
    openPicker,
    DEFAULT_RANGE,
    DEFAULT_FILTER,
    searchValue,
    searchKey,
    onApply,
    setSearchKey,
    setSearchValue,
    setOpenPicker,
    onSelect,
    onCalendarChange,
  } = useRpmCcmHeader({ module, clinicGuid, patientGuid });

  const { isPatient } = useUser();
  const deviceType = useAppSelector((state) => state.user.deviceType);

  return (
    <NavigationHeader goBackPath={goBackPath} title={title}>
      <div className={classNames(s.wrapper, { [s['for-patient']]: isPatient })}>
        <p className={s.title}>{t('labels.reportTimeWindow')}:</p>
        <div data-cy="report-time-window" className={s['date-wrapper']}>
          <BaseRangeDatepicker
            onCalendarChange={onCalendarChange}
            panelRender={(children) => (
              <AppointmentList dateRange={dateRange} patientGuid={patientGuid}>
                {children}
              </AppointmentList>
            )}
            withPresets
            showExtraFooter
            onApply={onApply}
            open={openPicker}
            setOpen={(x) => setOpenPicker(x)}
            defaultValue={DEFAULT_RANGE}
            size="large"
          />
        </div>
        {!isPatient && (
          <div className={s['inputs-wrapper']}>
            <div data-cy="patient-filter-list-dropdown">
              <BaseSelectBox
                options={options}
                defaultValue={DEFAULT_FILTER.value}
                className={s.dropdown}
                onChange={(v) => {
                  setSearchValue('');
                  setSearchKey(v);
                }}
                size="large"
                value={searchKey}
              />
            </div>
            <div data-cy="patient-filter-input">
              <AutoComplete
                popupClassName="certain-category-search-dropdown"
                popupMatchSelectWidth
                style={{ width: isMobile(deviceType) ? undefined : 250 }}
                options={dataOptions ?? []}
                size="large"
                notFoundContent={
                  isLoading ? (
                    <BaseLoader loading={isLoading} height={20} width={20} />
                  ) : (
                    'No Data'
                  )
                }
                onSelect={onSelect}
                value={searchValue}
                optionRender={(option) => (
                  <div data-cy="search-input-option">{option.label}</div>
                )}
              >
                <Input
                  size="large"
                  placeholder={placeholder}
                  suffix={<InputSearchIcon />}
                  className={s.search}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    debounceSearch({ searchKey, searchValue: e.target.value });
                  }}
                />
              </AutoComplete>
            </div>
          </div>
        )}
      </div>
    </NavigationHeader>
  );
};
export default RpmCcmNavigationHeader;
