import {
  sub,
  endOfDay,
  startOfMonth,
  endOfMonth,
  subMonths,
  differenceInCalendarYears,
} from 'date-fns';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import common from 'constants/common';
import forms, { rpmAndCcmClaimOptions } from 'constants/forms';
import languages from 'constants/languages';

import { getReadingUnit } from './readings';

export const getCareAgentLabelByType = (type) =>
  forms.adminPanelsUser.userEdit.careAgents.find(
    (careAgent) => careAgent.id === type,
  )?.label || type;

export const getLanguageLabelById = (id) =>
  languages.find((lang) => lang.id === id)?.label || id;

export const getInitialValues = (fields, initialData) => {
  const initialValues = {};
  if (!initialData) {
    for (const field of fields) {
      if (field.type === 'select' && field.options.length > 0) {
        const [firstOption] = field.options;
        initialValues[field.id] = firstOption.id;
      }
    }
    return initialValues;
  }
  for (const field of fields) {
    initialValues[field.id] = initialData[field.id];
  }
  return initialValues;
};

export const startTelemed = (token = 'some-token') => {
  const url = process.env.REACT_APP_TELEMED_URL;
  const win = window.open(`${url}?token=${token}`, '_blank');
  win.focus();
};

export const getActivityStatus = (status) => common.activity.statuses[status];
export const getPatientStatus = (status) => common.statusPatients[status] || {};
export const getPatientCcmStatus = (status) => common.ccmStatus[status] || {};
export const getPatientClaimStatus = (status) =>
  rpmAndCcmClaimOptions.find((s) => s.id === status)?.label ?? '';
export const getPatientConsentStatus = (status) =>
  common.consentStatus[status] || {};
export const patientStatusPresenter = (status) =>
  common.statusPatients[status]?.label || status;

export const patientCCMStatusPresenter = (status) =>
  forms.ccmPatientStatuses.find((element) => element.value === status)?.label ||
  '';

export const joinStrings = (...strings) => strings.filter(Boolean).join(' ');

export const fullNamePresenter = (doctor) => {
  if (!doctor) return '';
  const { firstName, middleName, lastName, title } = doctor;
  return joinStrings(title, firstName, middleName, lastName);
};

export const getPatientCovidStatus = (status) =>
  common.covidStatuses.positive.status === status
    ? ` | ${common.covidStatuses.positive?.label}`
    : '';

export const capitalize = (string) => {
  if (!string) return '';
  return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
};
export const upperCase = (string) => {
  if (!string) return '';
  return string.toUpperCase();
};

export const getPatientPcmStatus = (status) =>
  capitalize((status ?? false).toString());

export const getThresholdId = (limit, status, key) =>
  `${limit}${capitalize(status)}${capitalize(key)}`;

export const getThresholdText = (limit, threshold, status, key) =>
  `${key === 'spo2' ? 'SpO2' : capitalize(key)} ${capitalize(
    `${limit} At`,
  )} ${capitalize(status)}: ${threshold} ${getReadingUnit(key)}`;

export const getActivityThresholdsValues = (
  globalStatus,
  values,
  statuses,
  thresholds,
  readingType,
) => {
  const statusThresholds = [];
  Object.keys(values).forEach((key) => {
    if (
      statuses[key] === globalStatus &&
      ['risk', 'critical'].includes(globalStatus) &&
      key !== 'temperature'
    ) {
      let thresholdKey = key;
      if (readingType.startsWith('SleepingMat')) thresholdKey = 'SleepPulse';
      const minField = getThresholdId('min', globalStatus, thresholdKey);
      const maxField = getThresholdId('max', globalStatus, thresholdKey);

      const minThresholds = thresholds[minField] || 0;
      const maxThresholds = thresholds[maxField] || Number.MAX_SAFE_INTEGER;
      if (
        Math.abs(minThresholds - values[key]) <
        Math.abs(maxThresholds - values[key])
      ) {
        statusThresholds.push(
          getThresholdText('min', minThresholds, globalStatus, key),
        );
      } else {
        statusThresholds.push(
          getThresholdText('max', maxThresholds, globalStatus, key),
        );
      }
    }

    if (
      statuses[key] === globalStatus &&
      ['risk', 'critical'].includes(globalStatus) &&
      key === 'temperature'
    ) {
      if (
        Math.abs(common.temperatureCriticals.min - values[key]) <
        Math.abs(common.temperatureCriticals.max - values[key])
      ) {
        statusThresholds.push(
          getThresholdText(
            'min',
            common.temperatureCriticals.min,
            globalStatus,
            key,
          ),
        );
      } else {
        statusThresholds.push(
          getThresholdText(
            'max',
            common.temperatureCriticals.max,
            globalStatus,
            key,
          ),
        );
      }
    }
    if (
      statuses.oxygen === globalStatus &&
      ['risk', 'critical'].includes(globalStatus) &&
      key === 'spo2'
    ) {
      const risk = thresholds.riskBloodoxygen;
      const critical = thresholds.criticalBloodoxygen;

      if (values[key] <= critical) {
        statusThresholds.push(
          getThresholdText('Min', critical, globalStatus, key),
        );
      } else {
        statusThresholds.push(getThresholdText('Min', risk, globalStatus, key));
      }
    }
  });
  return statusThresholds;
};

const colors = common.statusColors;

export const statusColor = (status) => {
  let color;
  switch (status) {
    case 'critical':
      color = colors.critical;
      break;
    case 'risk':
      color = colors.risk;
      break;
    case 'normal':
      color = colors.normal;
      break;
    case 'slaReadings':
      color = colors.slaReadings;
      break;
    default:
      color = '';
  }
  return color;
};

export const normalizePatientInfo = (profile) => {
  if (Object.keys(profile).length === 0) return profile;

  const getFieldLabel = (fieldName, key) => {
    let field = forms.profileFields.find(
      (profileField) => profileField.id === fieldName,
    );
    if (fieldName === 'status') {
      field = { options: forms.patientStatuses };
    }

    const option = field.options.find((opt) => opt.id === key);

    return option ? option.label : null;
  };

  const gender = getFieldLabel('gender', profile.gender);
  const martialStatus = getFieldLabel('martialStatus', profile.martialStatus);
  const workingStatus = getFieldLabel('workingStatus', profile.workingStatus);
  const race = getFieldLabel('race', profile.race);
  const status = getFieldLabel('status', profile.status);
  const language = getLanguageLabelById(profile.language);

  return {
    ...profile,
    gender,
    martialStatus,
    workingStatus,
    race,
    status,
    language,
  };
};

export const normalizeTimezone = (timezone) => {
  if (timezone === 'Europe/Kiev') return 'Europe/Kyiv';

  return timezone;
};

export const getPatientPreferredContactValue = (patient) => {
  const { preferredContactMethod } = patient;
  if (preferredContactMethod === 'email') {
    return patient.email;
  }
  if (preferredContactMethod === 'sms' || preferredContactMethod === 'phone') {
    return patient.phone;
  }
  return '';
};

export const formatBirthDate = (birthDate) => {
  const formattedDate = parse(birthDate, 'MM-dd-yyyy', new Date());
  return format(
    new Date(formattedDate).getTime() +
      new Date(formattedDate).getTimezoneOffset() * 1000 * 60,
    common.dateFormats.birthDate,
  );
};

export const generateReportName = (name, [startTime, endTime], ext) =>
  `${name}_${format(+startTime, common.dateFormats.birthDate)}_${format(
    +endTime,
    common.dateFormats.birthDate,
  )}.${ext}`;

const decodeBase64 = (encodedString, applicationType) => {
  const binaryData = atob(encodedString);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type: applicationType });
  const fileURL = URL.createObjectURL(blob);

  return fileURL;
};

export const downloadPDF = (data, reportName) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = decodeBase64(data, 'application/pdf;base64');
  downloadLink.download = reportName;
  downloadLink.click();
  downloadLink.remove();
};

export const downloadCSV = (data, reportName) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = decodeBase64(data, 'application/text-csv;base64');
  downloadLink.download = reportName;
  downloadLink.click();
  downloadLink.remove();
};

export const getFileNameFromUrl = (url) => {
  const instance = new URL(url);
  const filename = instance.pathname.split('/').pop();
  return filename;
};

export const downloadFileFromUrl = (url) => {
  const fileName = getFileNameFromUrl(url);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.target = '_blank';
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );
  document.body.removeChild(link);
};

export const openInNewTab = (url) => {
  window.open(url, '_blank').focus();
};

export const getRangeDates = (range) => {
  let startRangeDate = new Date();
  let endRangeDate = new Date();

  if (range.id === 'currentmonth') {
    startRangeDate = startOfMonth(endRangeDate);
    endRangeDate = endOfDay(endRangeDate);
  }

  if (range.id === 'previousmonth') {
    startRangeDate = startOfMonth(subMonths(endRangeDate, 1));
    endRangeDate = endOfMonth(startRangeDate);
  }

  if (range.id === 'twomonthspast') {
    startRangeDate = startOfMonth(subMonths(endRangeDate, 2));
    endRangeDate = endOfMonth(startRangeDate);
  }

  if (range.id === 'threemonthspast') {
    startRangeDate = startOfMonth(subMonths(endRangeDate, 3));
    endRangeDate = endOfMonth(startRangeDate);
  }

  if (range.id === 'last7days' || range.id === 'last14days') {
    startRangeDate = sub(endRangeDate, { days: range.days - 1 });
  }

  if (range.id === 'last24hours') {
    startRangeDate = sub(endRangeDate, { hours: 24 });
  }

  if (range.id === 'last4hours') {
    startRangeDate = sub(endRangeDate, { hours: 4 });
  }

  return { startRangeDate, endRangeDate };
};

export const getUTCDates = (dates) =>
  dates.map((date) => sub(date, { minutes: date.getTimezoneOffset() }));

// a ** b => a^b
export const getRoundedNumber = (num, length) =>
  Math.round(num * 10 ** length) / 10 ** length;
export const getAgeByDOB = (dob) => {
  if (!dob) return;
  const dobDate = new Date(dob);
  const now = new Date();

  return differenceInCalendarYears(now, dobDate);
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const scrollToErrorIfExist = () => {
  let elementWithError = document.querySelector('.input-error');
  if (!elementWithError) {
    elementWithError = document.querySelector('.error-message');
  }
  if (!elementWithError) return;
  elementWithError.scrollIntoView({
    block: 'center',
    behavior: 'smooth',
  });
  elementWithError.classList.add('shaking');
  const animationFinishPromises = elementWithError
    .getAnimations()
    .map((animation) => animation.finished);
  Promise.all(animationFinishPromises).then(() =>
    elementWithError.classList.remove('shaking'),
  );
};

export const isHtmlEmpty = (htmlString) => {
  if (!htmlString) return true;
  const temp = document.createElement('div');
  temp.innerHTML = htmlString;
  return !temp.textContent.trim().length;
};

export const isAbortError = (errorName) => errorName === 'AbortError';
