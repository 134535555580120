import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_ALLERGY_LOG } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';

import {
  ALLERGY_LOG_PER_PAGE,
  getAllergyLogsKey,
} from './use-get-allergy-logs';

import type {
  IAllergyLog,
  IAllergyLogResponse,
  IUpdateAllergyLog,
} from 'infrastructure/interfaces';

interface Props {
  page: number;
}

const updateAllergyLogFn = async (postData: IUpdateAllergyLog) => {
  const url = `${API_ALLERGY_LOG}/${postData.guid}`;
  const { error, data } = await dataService.updateOnly<IAllergyLog>(
    url,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useUpdateAllergyLog = ({ page }: Props) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: async (postData: IUpdateAllergyLog) =>
      updateAllergyLogFn(postData),
    onSuccess(data, variables) {
      const queryKey = getAllergyLogsKey({
        patientGuid: variables.patientGuid,
        items: ALLERGY_LOG_PER_PAGE,
        page,
      });
      queryClient.setQueryData<IAllergyLogResponse>(queryKey, (oldData) => {
        if (oldData && data) {
          const newItemsData = oldData.items.map((i) => {
            if (i.guid === variables.guid) {
              return data;
            }
            return i;
          });
          oldData.items = newItemsData;
        }

        return oldData;
      });
      showResult();
    },
  });
  return {
    updateAllergyLog: mutate,
    updateLoading: isLoading,
    ...other,
  };
};
