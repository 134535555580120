import { useTranslation } from 'react-i18next';
import { useState, type FC } from 'react';
import {
  MANUAL_CLINIC_NOTES_PER_PAGE,
  useGetManualClinicNotes,
} from 'infrastructure/hooks/ccm';
import BaseTableNew from 'components/atoms/base-table/new';
import EmptyTablePlaceholder from 'components/atoms/base-empty-table-placeholder';

import s from './styles.module.scss';
import { columns } from './columns';

interface ManualClinicalNotesLoggedProps {
  patientGuid: string;
}

const ManualClinicalNotesLogged: FC<ManualClinicalNotesLoggedProps> = ({
  patientGuid,
}) => {
  const [page, setPage] = useState(1);
  const { manualClinicNotes, isLoading } = useGetManualClinicNotes({
    patientGuid,
    meta: { items: MANUAL_CLINIC_NOTES_PER_PAGE, page },
  });
  const { t } = useTranslation();

  return (
    <div className={s.wrapper}>
      <h3 className={s.title}>{t('labels.manualClinicNotesLogged')}</h3>
      <BaseTableNew
        items={manualClinicNotes?.items ?? []}
        columns={columns()}
        bordered
        loading={isLoading}
        pagination={{
          current: page,
          pageSize: MANUAL_CLINIC_NOTES_PER_PAGE,
          total: manualClinicNotes?.count ?? 0,
          position: ['bottomCenter'],
        }}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
        dataCy="manual-clinic-notes-table"
        // eslint-disable-next-line prettier/prettier
        emptyText={(
          <EmptyTablePlaceholder text={t('labels.noManualClinicNotesLogged')} />
          // eslint-disable-next-line prettier/prettier
        )}
      />
    </div>
  );
};
export default ManualClinicalNotesLogged;
