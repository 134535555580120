import { Row, Col } from 'reactstrap';
import forms from 'constants/forms';
import Input from 'components/atoms/input';

import type { FC } from 'react';

type Props = {
  errors: Record<string, any>;
  disabledFields?: string[];
};

const Info: FC<Props> = ({ errors, disabledFields }) => {
  const personalInfo = [...forms.adminTurkUser.userEdit.personalInfo];
  return (
    <div className="admin-agent-form-block">
      <span>Personal Info</span>
      <Row>
        {personalInfo.map((field) => (
          <Col lg="6" md="6" sm="6" xs="12" key={field.id}>
            <Input {...field} disabled={disabledFields?.includes(field.id)} />
            {errors[field.id] ? (
              <div className="input-error">
                {errors[field.id].split('.')[0]}
              </div>
            ) : null}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Info;
