import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const clinicAddressValidationSchema = () =>
  createValidationSchema({
    addressLine1: commonValidationSchemas.string({
      nullable: true,
    }),
    addressLine2: commonValidationSchemas.string({ nullable: true }),
    state: commonValidationSchemas.string({
      nullable: true,
      when: {
        fields: ['zip', 'city'],
        is: (zip, city) => !!zip || !!city,
        then: {
          required: true,
        },
      },
    }),
    city: commonValidationSchemas.string({
      nullable: true,
      when: {
        fields: ['zip'],
        is: (zip) => !!zip,
        then: {
          required: true,
        },
      },
    }),
    zip: commonValidationSchemas.string({
      nullable: true,
    }),
    timezone: commonValidationSchemas.string({ nullable: true }),
  });
