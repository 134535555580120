import { useFormik } from 'formik';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseTextArea from 'components/atoms/base-textarea';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import UseTemplateBtnIcon from 'components/atoms/icons/useTemplateBtnIcon';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import BaseModal from 'components/atoms/base-modal';
import { useEffect, type FC } from 'react';

import { clinicalNotesValidationSchema } from './validation-schema';
import s from './styles.module.scss';

import type { FormikHelpers } from 'formik';
import type { Values } from './validation-schema';
import type { IAddClinicNotes } from 'infrastructure/interfaces';

interface ClinicalNotesFormProps {
  open: boolean;
  onCancelForm: () => void;
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  loading: boolean;
  onUseTemplate: () => void;
  defaultValues?: Omit<IAddClinicNotes, 'patientGuid'>;
}

const ClinicalNotesModalForm: FC<ClinicalNotesFormProps> = ({
  open,
  loading,
  onCancelForm,
  onSubmit,
  onUseTemplate,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const { Alert, showAlert } = useAlert();
  const formik = useFormik<Values>({
    initialValues: clinicalNotesValidationSchema().cast(defaultValues),
    enableReinitialize: true,
    onSubmit,
    validateOnChange: false,
    validationSchema: clinicalNotesValidationSchema(),
  });

  const { submitForm, resetForm, values } = formik;

  const onCancel = async () => {
    if (values.note) {
      const confirmRes = await showAlert({
        title: t('confirm.cancelNote'),
        messageTitle: t('confirm.closeClinicalNotePopup'),
        successBtnLabel: t('controls.yes'),
        rejectBtnLabel: t('controls.no'),
      });

      if (confirmRes) {
        resetForm();
        onCancelForm();
      }
    } else {
      resetForm();
      onCancelForm();
    }
  };

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  return (
    <>
      <BaseModal
        title={t('labels.addClinicalNote')}
        open={open}
        onCancel={onCancel}
        width="1000px"
        allowScrolling
      >
        <BaseForm
          formik={formik}
          withControls
          plaintext={false}
          readonly={false}
        >
          <BaseFormItem name="note">
            <BaseTextArea rows={10} placeholder="Type your note here" />
          </BaseFormItem>
          <div className={s.footer}>
            <BaseButton
              label={t('controls.useTemplate')}
              dataCy="useTemplate-button"
              type="secondary"
              disabled={loading}
              icon={<UseTemplateBtnIcon />}
              onClick={onUseTemplate}
            />

            <div className={s.right}>
              <BaseButton
                label={t('controls.cancel')}
                dataCy="clinicalNotes-cancel-button"
                type="secondary"
                onClick={onCancel}
                disabled={loading}
              />
              <BaseButton
                label={t('controls.save')}
                dataCy="clinicalNotes-save-button"
                onClick={submitForm}
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>
        </BaseForm>
      </BaseModal>
      {Alert}
    </>
  );
};
export default ClinicalNotesModalForm;
