export enum ThresholdType {
  Systolic = 'Systolic',
  Diastolic = 'Diastolic',
  Pulse = 'Pulse',
  Glucose = 'Glucose',
  SpO2 = 'SpO2',
  Weight = 'Weight',
  Temperature = 'Temperature',
  RestingPulse = 'Resting Pulse',
  PEF = 'PEF',
  FEV1 = 'FEV1',
}

export const THRESHOLD_UNITS = {
  [ThresholdType.Systolic]: 'mm Hg',
  [ThresholdType.Diastolic]: 'mm Hg',
  [ThresholdType.Pulse]: 'BPM',
  [ThresholdType.RestingPulse]: 'BPM',
  [ThresholdType.Glucose]: 'mg/dL',
  [ThresholdType.SpO2]: '%',
  [ThresholdType.Weight]: 'lbs',
  [ThresholdType.Temperature]: '°F',
  [ThresholdType.PEF]: 'L/Min',
  [ThresholdType.FEV1]: 'L',
} as const;
