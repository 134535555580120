// FIXME: Can be deleted. Unused component

import React from 'react';

import CsvDropdown from './csvDropdown';
import Dropdown from './dropdown';

const AdminHeader = () => (
  <div className="admin-header">
    <CsvDropdown />
    <Dropdown />
  </div>
);

export default AdminHeader;
