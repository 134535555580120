import { useEffect, useState } from 'react';
import dataService from 'services/dataService';
import queryString from 'query-string';
import { sleep } from 'utils';

import { skipEmptyStrings } from './queryHelpers';

export const apiUrlGenerator = (url, items, page = 1, filters) => {
  const trimmedFilters = skipEmptyStrings(filters);

  let endpoint = `${url}?items=${items}&page=${page}`;
  if (filters)
    endpoint = `${endpoint}&${queryString.stringify(trimmedFilters)}`;

  return endpoint;
};

const fetchingData = async (url, items, filters, page, signal) => {
  const { data, error, errorName } = await dataService.getList(
    apiUrlGenerator(url, items, page, filters),
    { signal },
  );
  return { data, error, errorName };
};

const saveNewRow = (reload, setSaveLoading) => async (url, row) => {
  setSaveLoading(true);
  const { data, error } = await dataService.createOne(url, row);
  await reload();
  setSaveLoading(false);
  return { data, error };
};

const updateRow = (reload, setSaveLoading) => async (url, row) => {
  setSaveLoading(true);
  const { data, error } = await dataService.updateOnly(url, row);
  await reload();
  setSaveLoading(false);
  return { data, error };
};

const INITIAL_TABLE_DATA = { count: 0, items: [] };
const deleteRow = (reload, setSaveLoading) => async (url, guid) => {
  setSaveLoading(true);
  const { data, error } = await dataService.deleteOne(url, { id: guid });
  await reload();
  setSaveLoading(false);
  return { data, error };
};

const deleteRowWithBody = (reload, setSaveLoading) => async (url, body) => {
  setSaveLoading(true);
  const { data, error } = await dataService.deleteObject(url, { ...body });
  await reload();
  setSaveLoading(false);
  return { data, error };
};

const useTable = (
  url,
  items,
  filters,
  { makeInitialRequest } = {
    makeInitialRequest: true,
  },
) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableFilters, setFilters] = useState(filters);
  const [tablePage, setPage] = useState(1);
  const [tableData, setData] = useState(INITIAL_TABLE_DATA);
  const [isInitialRequest, setIsInitialRequest] = useState(true);

  const loadData = async (signal) => {
    if (makeInitialRequest || !isInitialRequest) {
      await sleep(10);
      setLoading(true);

      const { data, error } = await fetchingData(
        url,
        items,
        tableFilters,
        tablePage,
        signal,
      );
      if (error !== 'The user aborted a request.') {
        setData(data || INITIAL_TABLE_DATA);
      }
      setLoading(false);
    }
    setIsInitialRequest(false);
  };
  useEffect(() => {
    const abortController = new AbortController();
    loadData(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [tablePage, tableFilters]);

  return {
    setPage,
    setFilters: (newFilters) => {
      setFilters(newFilters);
      setPage(1);
    },
    saveNewRow: saveNewRow(loadData, setSaveLoading),
    updateRow: updateRow(loadData, setSaveLoading),
    deleteRow: deleteRow(loadData, setSaveLoading),
    deleteRowWithBody: deleteRowWithBody(loadData, setSaveLoading),
    saveLoading,
    loading,
    data: tableData,
    count: tableData.count,
    filters: tableFilters,
    page: tablePage,
  };
};

export default useTable;
