import CcmAgentDashboardTable from 'components/templates/ccm/ccmAgentDashboard/table';
import CcmAgentDashboardFilters from 'components/templates/ccm/ccmAgentDashboard/filters';
import CcmAgentDashboardHeader from 'components/templates/ccm/ccmAgentDashboard/header';
import { useCcmAgentDashboardCount } from 'infrastructure/hooks/ccmAgentDashboard/useCount';
import { useCcmAgentDashboardData } from 'infrastructure/hooks/ccmAgentDashboard/useData';
import { useCcmAgentDashboardFilters } from 'infrastructure/hooks/ccmAgentDashboard/useFilters';

import type { FC } from 'react';

const CcmAgentDashboardPage: FC = () => {
  const {
    resetFilters,
    appendFilters,
    changePage,
    handleSort,
    filters,
    filtersCount,
  } = useCcmAgentDashboardFilters();
  const { data, isLoading } = useCcmAgentDashboardData(filters);
  const { data: count } = useCcmAgentDashboardCount(filters);

  return (
    <>
      <CcmAgentDashboardHeader count={count} />
      <CcmAgentDashboardFilters
        appendFilters={appendFilters}
        filtersCount={filtersCount}
        resetFilters={resetFilters}
        filters={filters}
      />
      <CcmAgentDashboardTable
        count={data?.count}
        items={data?.data}
        filters={filters}
        isLoading={isLoading}
        changePage={changePage}
        onSort={handleSort}
      />
    </>
  );
};

export default CcmAgentDashboardPage;
