import React, { useState, useEffect } from 'react';
import { Container, Row, Card, CardBody, Table } from 'reactstrap';
import Pagination from 'components/molecules/paginationTransition';
import dataService from 'services/dataService';
import {
  API_PERFORMANCE_REVIEW,
  API_PERFORMANCE_REVIEW_COUNT,
} from 'services/dataService/resources';
import withLoader from 'components/organisms/withLoader';
import useFetch from 'utils/useFetch';
import ReportHeader from 'components/templates/rpm/practice/performanceReview/header';
import classNames from 'classnames';
import useTable from 'utils/useTable';
import Toolbar from 'components/templates/rpm/practice/performanceReview/toolbar';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import ActivityRow from 'components/templates/rpm/practice/performanceReview/activityRow';
import ActivityHeader from 'components/templates/rpm/practice/performanceReview/activityHeader';
import common from 'constants/common';
import { showResult } from 'infrastructure/hooks/utils';
import { generateTimeRanges } from 'utils/dateHelpers';

const PER_PAGE = 15;
const timeRanges = generateTimeRanges();

const PerformanceReview = () => {
  const {
    data: { data: patients = [] },
    count,
    loading: reportLoading,
    page,
    setPage,
    filters,
    setFilters,
  } = useTable(API_PERFORMANCE_REVIEW, PER_PAGE, {
    startTime: timeRanges[0].value[0],
    endTime: timeRanges[0].value[1],
  });

  const [downloadLoading, setDownloadLoading] = useState(false);
  const {
    data: headerData,
    loading: infoTilesLoading,
    refresh,
  } = useFetch(
    () => {
      const searchParams = new URLSearchParams();
      searchParams.append('startTime', filters.startTime);
      searchParams.append('endTime', filters.endTime);
      return dataService.getList(
        `${API_PERFORMANCE_REVIEW_COUNT}?${searchParams.toString()}`,
      );
    },
    {
      initialRequest: false,
    },
  );

  useEffect(() => {
    refresh();
  }, [filters.startTime, filters.endTime]);

  const sendReport = async () => {
    if (downloadLoading) {
      return;
    }
    setDownloadLoading(true);
    const { error } = await dataService.createOne(
      API_PERFORMANCE_REVIEW,
      filters,
    );
    setDownloadLoading(false);
    return showResult(error, common.submitSuccessTypes.sendReport);
  };

  return (
    <Container className="performance-review">
      <Row>
        <Card className="card-border card--full-width card--margin-bottom">
          <CardBody
            className={classNames('performance-review-counter__wrapper', {
              loading: infoTilesLoading,
            })}
          >
            {withLoader(infoTilesLoading, <ReportHeader count={headerData} />)}
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Card className="card-border card--full-width card--margin-bottom">
          <CardBody>
            <div className="performance-review__header">
              <div className="performance-review__header--toolbox__wrapper">
                <h3 className="performance-review__header-title">
                  Report: Performance Review
                </h3>
                <Toolbar
                  filters={filters}
                  timeRanges={timeRanges}
                  setFilters={(newFilters) =>
                    setFilters({ ...filters, ...newFilters })
                  }
                />
              </div>
              <div className="performance-review__header-icon">
                {withLoader(
                  downloadLoading,
                  <button onClick={sendReport}>
                    <DownloadRoundedIcon className="performance-review__header-icon-svg" />
                    <span>Download</span>
                  </button>,
                )}
              </div>
            </div>

            <div className="performance-review__table table-sticky-header">
              {withLoader(
                reportLoading,
                <Table borderless cellSpacing="0" cellPadding="0">
                  <ActivityHeader />
                  <tbody>
                    {patients.map((patient, id) => (
                      <ActivityRow key={id} patient={patient} />
                    ))}
                  </tbody>
                </Table>,
              )}
            </div>

            <div className="performance-review__table-button-pagination">
              <Pagination
                page={page}
                pageTotal={patients.length}
                itemsPerPage={PER_PAGE}
                total={count}
                handleFirstClick={() => setPage(1)}
                handleLastClick={() => setPage(Math.ceil(count / PER_PAGE))}
                handlePrevClick={() => setPage(page - 1)}
                handleNextClick={() => setPage(page + 1)}
                handleGoToPage={(pageNumber) => setPage(pageNumber)}
              />
            </div>
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

export default PerformanceReview;
