import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import forms from 'constants/forms';
import Input from 'components/atoms/input';
import useOutsideClick from 'utils/useOutsideClick';
import Switch from 'react-switch';
import common from 'constants/common';
import EmrPopOut from 'components/molecules/old/emrPopOut';

const Emr = ({ errors, setFieldValue, values = {} }) => {
  const emr = [...forms.adminPanelsUser.userEdit.doctorEmr];

  const [emrOpen, setEmrPopOut] = useState(false);
  const onSetReadings = (value) => {
    setFieldValue('emrReports', value);
  };

  const ref = useRef();
  useOutsideClick(ref, () => {
    setEmrPopOut(false);
  });

  return (
    <div className="admin-patient-form-block">
      <span>EMR Details</span>
      <Row>
        {emr.map((field) => (
          <Col lg="6" md="6" sm="6" xs="12" key={field.id}>
            <Input dataCy={`users-input-${field.id}`} {...field} />
            {errors[field.id] ? (
              <div className="input-error">{errors[field.id]}</div>
            ) : null}
          </Col>
        ))}
      </Row>
      <Row>
        <Col lg="6" md="6" sm="6" xs="12">
          <div
            className="admin-patient-edit-form-label"
            data-cy="emr-billing-switch"
          >
            Billing
          </div>
          <div className="admin-patient-edit-form-readings">
            <div>{values.emrBilling ? 'Yes' : 'No'}</div>
            <Switch
              data-cy="users-input-billing-switch"
              className="evelyn-switch"
              onChange={() => {
                setFieldValue('emrBilling', !values.emrBilling);
              }}
              checked={!!values.emrBilling}
              uncheckedIcon={false}
              checkedIcon={false}
              onHandleColor={common.commonColors.green}
              offHandleColor={common.commonColors.gray}
              onColor={common.commonColors.white}
              offColor={common.commonColors.white}
              handleDiameter={24}
              height={30}
              width={59}
              activeBoxShadow="0"
            />
          </div>
        </Col>
        <Col lg="6" md="6" sm="6" xs="12">
          <Input label="Billing Cycle" id="emrBillingCycle" />
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" sm="6" xs="12">
          <div
            className="admin-patient-edit-form-label"
            data-cy="emr-reading-switch"
          >
            Readings
          </div>
          <div className="admin-patient-edit-form-readings">
            <div>{values.emrReadingsFlag ? 'Yes' : 'No'}</div>
            <Switch
              data-cy="users-input-readings-switch"
              className="evelyn-switch"
              onChange={() => {
                setFieldValue('emrReadingsFlag', !values.emrReadingsFlag);
              }}
              checked={!!values.emrReadingsFlag}
              uncheckedIcon={false}
              checkedIcon={false}
              onHandleColor={common.commonColors.green}
              offHandleColor={common.commonColors.gray}
              onColor={common.commonColors.white}
              offColor={common.commonColors.white}
              handleDiameter={24}
              height={30}
              width={59}
              activeBoxShadow="0"
            />
          </div>
        </Col>
        <Col lg="6" md="6" sm="6" xs="12">
          <div ref={ref}>
            <div
              data-cy="emr-reports-popup-button-open"
              onClick={() => {
                setEmrPopOut(true);
              }}
            >
              <Input
                dataCy="users-input-reports"
                label="Report"
                id="emrReports"
              />
            </div>
            {emrOpen && <EmrPopOut onSetReadings={onSetReadings} />}
          </div>
        </Col>
      </Row>
    </div>
  );
};

Emr.propTypes = {
  errors: PropTypes.shape(),
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape(),
};

export default Emr;
