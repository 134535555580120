import React from 'react';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

const DownloadRoundedIcon: React.FC<IBaseComponentProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="32"
    viewBox="0 0 31 32"
    fill="none"
  >
    <g clipPath="url(#clip0_11242_81452)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5489 6.78004C12.6294 0.408064 20.7812 -0.881366 25.9894 3.84173C31.1976 8.56483 31.4743 21.6004 25.9894 27.3482C20.5045 33.096 7.86276 31.252 3.55806 27.3482C-0.746647 23.4444 0.468359 13.152 6.5489 6.78004Z"
        fill="white"
        stroke="#FB9318"
        strokeWidth="0.5"
      />
      <path
        d="M10 13.6667C10 13.2246 10.1712 12.8007 10.476 12.4882C10.7807 12.1756 11.194 12 11.625 12H15.6875L17.3125 13.6667H21.375C21.806 13.6667 22.2193 13.8423 22.524 14.1548C22.8288 14.4674 23 14.8913 23 15.3333V20.3333C23 20.7754 22.8288 21.1993 22.524 21.5118C22.2193 21.8244 21.806 22 21.375 22H11.625C11.194 22 10.7807 21.8244 10.476 21.5118C10.1712 21.1993 10 20.7754 10 20.3333V13.6667Z"
        fill="#FB9318"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 16C16.6744 16 16.8158 16.1414 16.8158 16.3158V17.9218L17.4609 17.2767C17.5842 17.1534 17.7842 17.1534 17.9075 17.2767C18.0308 17.4 18.0308 17.6 17.9075 17.7233L16.7233 18.9075C16.6 19.0308 16.4 19.0308 16.2767 18.9075L15.0925 17.7233C14.9692 17.6 14.9692 17.4 15.0925 17.2767C15.2158 17.1534 15.4158 17.1534 15.5391 17.2767L16.1842 17.9218V16.3158C16.1842 16.1414 16.3256 16 16.5 16Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_11242_81452">
        <rect width="31" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DownloadRoundedIcon;
