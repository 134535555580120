const auth0LocalStorage = '@@auth0spajs@@';

export const getAuth0Token = () => {
  let token = '';
  Object.keys(localStorage).forEach((key) => {
    if (key.includes(auth0LocalStorage)) {
      const data = JSON.parse(localStorage.getItem(key));
      token = data?.body?.access_token;
    }
  });
  return token;
};

const getHeaders = (authenticated, upload) => {
  const headers = new Headers();

  if (!upload) {
    headers.set('Content-Type', 'application/json');
  }

  if (authenticated) {
    const token = getAuth0Token();
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
};

export default getHeaders;
