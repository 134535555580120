import { getActivityThresholdsValues } from 'utils';
import useUser from 'utils/useUser';
import classNames from 'classnames';

import { getReadingInfo, hiddenThresholds } from '../../utils/get-reading-info';
import s from './styles.module.scss';

import type {
  StatusKeys,
  TStatuses,
  Value,
} from 'infrastructure/interfaces/i-rpm-patient-activity';
import type { ReadingStatus } from 'infrastructure/enums/reading-statuses';
import type { IPatientThresholds } from 'infrastructure/interfaces';
import type { ReadingType } from 'infrastructure/enums';
import type { FC } from 'react';

interface PatientReadingColumnProps {
  globalStatus: ReadingStatus;
  values: Value;
  statuses: TStatuses;
  thresholds: IPatientThresholds;
  readingType: ReadingType;
}

const PatientReadingColumn: FC<PatientReadingColumnProps> = ({
  globalStatus,
  statuses,
  thresholds,
  values,
  readingType,
}) => {
  const riskItems = getActivityThresholdsValues(
    globalStatus,
    values,
    statuses,
    thresholds,
    readingType,
  );
  const { isPatient, isMobileDevice } = useUser();
  const riskItemsGap = riskItems.length === 3 ? 8 : 12;

  return (
    <div className={classNames(s.reading, { [s['is-patient']]: isPatient })}>
      <div
        data-cy="reading-values"
        className={classNames(s['reading-items'], {
          [s['is-patient']]: isPatient && !isMobileDevice,
        })}
      >
        {Object.keys(values).map((threshold, index, array) => {
          if (hiddenThresholds.includes(threshold)) {
            return null;
          }
          const fontSize = 17;
          const flexDirection =
            array.filter((val) => !hiddenThresholds.includes(val)).length ===
              1 &&
            !isPatient &&
            !isMobileDevice
              ? 'column'
              : 'row';
          const { className, key, value, unit } = getReadingInfo(
            statuses,
            values,
            threshold as StatusKeys,
          );

          return (
            <p
              className={s[className]}
              key={readingType + index}
              style={{ fontSize, flexDirection }}
            >
              <span>{key === 'spo2' ? 'SpO2' : key} </span>
              <span>{`${value} ${unit}`}</span>
            </p>
          );
        })}
      </div>
      <div
        data-cy="reading-thresholds"
        className={s['risk-items']}
        style={{ gap: isPatient && !isMobileDevice ? 4 : riskItemsGap }}
      >
        {riskItems.length > 0 &&
          riskItems.map((riskItem: string) => {
            let fontSize = riskItems.length === 1 ? 18 : 16;
            fontSize = riskItems.length === 3 ? 14 : 16;

            const [risk, value] = riskItem?.split(':');

            return (
              <p
                style={{
                  fontSize: isPatient && !isMobileDevice ? 15 : fontSize,
                  flexDirection:
                    isPatient && !isMobileDevice ? 'row' : 'column',
                }}
                key={riskItem}
              >
                <span>{risk}:</span>
                <span>{value}</span>
              </p>
            );
          })}
      </div>
    </div>
  );
};
export default PatientReadingColumn;
