import { useTranslation } from 'react-i18next';
import { useState, type FC } from 'react';
import {
  VOICE_CALLS_PER_PAGE,
  useDownloadVoiceRecord,
  useGetVoiceCalls,
} from 'infrastructure/hooks/ccm';
import BaseTableNew from 'components/atoms/base-table/new';
import EmptyClinicalVoiceIcon from 'components/atoms/icons/emptyClinicalVoiceIcon';
import EmptyTablePlaceholder from 'components/atoms/base-empty-table-placeholder';

import s from './styles.module.scss';
import { columns } from './columns';

interface VoiceCallsLoggedProps {
  patientGuid: string;
}

const VoiceCallsLogged: FC<VoiceCallsLoggedProps> = ({ patientGuid }) => {
  const [page, setPage] = useState(1);
  const [downloadedCallId, setDownloadedCallId] = useState<string>();
  const { calls, isLoading } = useGetVoiceCalls({
    patientGuid,
    meta: { items: VOICE_CALLS_PER_PAGE, page },
  });
  const { downloadVoiceRecord, downloadLoading } = useDownloadVoiceRecord();
  const { t } = useTranslation();

  const onDownload = (callId: string) => {
    setDownloadedCallId(callId);
    downloadVoiceRecord(
      { callId, patientGuid },
      {
        onSuccess() {
          setDownloadedCallId(undefined);
        },
      },
    );
  };

  return (
    <div className={s.wrapper}>
      <h3 className={s.title} data-cy="voice-calls-section">
        {t('labels.voiceCallsLogged')}
      </h3>
      <BaseTableNew
        items={calls?.items ?? []}
        columns={columns({ onDownload, downloadedCallId, downloadLoading })}
        bordered
        loading={isLoading}
        pagination={{
          current: page,
          pageSize: VOICE_CALLS_PER_PAGE,
          total: calls?.count ?? 0,
          position: ['bottomCenter'],
        }}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
        dataCy="voice-calls-table"
        // eslint-disable-next-line prettier/prettier
        emptyText={(
          <EmptyTablePlaceholder
            icon={<EmptyClinicalVoiceIcon />}
            text={t('labels.noVoiceCallsLogged')}
          />
          // eslint-disable-next-line prettier/prettier
        )}
      />
    </div>
  );
};
export default VoiceCallsLogged;
