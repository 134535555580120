import filter from 'lodash/filter';
import startsWith from 'lodash/startsWith';
import { format, parse, addMinutes } from 'date-fns';
import common from 'constants/common';
import has from 'lodash/has';

export const formatTimeToCommonFormat = (timeString) => {
  const timeFormat = common.dateFormats.hoursMinutesPM;
  const commonTimeFormat = common.dateFormats.commonTime;
  return timeString
    ? format(parse(timeString, timeFormat, new Date()), commonTimeFormat)
    : timeString;
};

const getUTC = (date) => addMinutes(date, date.getTimezoneOffset());

const removeTime = (date) => new Date(date.toDateString());

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const transformDateAndTimeToUTC = (date, time) => {
  if (!date && !time) {
    return [null, null];
  }
  const commonTimeFormat = common.dateFormats.commonTime;
  const timeFormat = common.dateFormats.hoursMinutesPM;
  const dateFormat = common.dateFormats.birthDate;
  const parseDate = date ? parse(date, dateFormat, new Date()) : new Date();
  const localDate = time ? parse(time, timeFormat, parseDate) : parseDate;
  const utcDate = getUTC(localDate);
  return [
    date ? format(removeTime(utcDate), dateFormat) : null,
    time ? format(utcDate, commonTimeFormat) : null,
  ];
};

// eslint-disable-next-line no-unused-vars
const convertTo24Hour = (timeString) => {
  const [time, ampm] = timeString.split(' ');
  const [hours, minutes] = time.split(':').map(Number);

  let adjustedHours = hours;
  if (ampm === 'PM' && hours !== 12) {
    adjustedHours += 12;
  } else if (ampm === 'AM' && hours === 12) {
    adjustedHours = 0;
  }

  const formattedHours = String(adjustedHours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:00`;
};

export const getShowFromDepends = (fieldParams, values) => {
  if (has(fieldParams, 'showDepends')) {
    const { field, handler } = fieldParams.showDepends;
    const dependFieldValue = values[field];
    const show = handler(dependFieldValue);
    return !!show;
  }
  return true;
};

export const hasTexasInsurance = (insurances) =>
  filter(insurances, (insurance) =>
    startsWith(insurance.insuranceProvider, 'Texas'),
  )?.length > 0;

export const getTmphPatientQuestionnaireInitialValues = () => ({
  doYouHaveAnyOfTheseConditions: null,
  doYouAlwaysTakeYourMedicationsAsDirectedByYourPhysician: null,
  doYouHaveTroubleGettingYourMedications: null,
  haveYouBeenHospitalizedInTheLast12Months: null,
  haveYouBeenInTheEmergencyRoomInTheLast6Months: null,
  haveYouHadTwoOrMoreFallsInTheLast6Months: null,
  doYouHaveABloodPressureOrGlucometerForPersonalUse: null,
  doYouLiveAlone: null,
  doYouHaveProvider: null,
  whoDoYouRelyOnForTransportation: null,
  doYouHavePowerOfAttorney: null,
  powerOfAttorneyFullNameAndPhoneNumber: null,
  verbalAuthorizationProvidedBy: null,
  verbalAuthorizationDate: undefined,
  verbalAuthorizationTime: null,
  lastMdVisit: undefined,
});

export const mapPatientToFormFields = (patient) => {
  const patientInsurances = patient.insurances.map((insurance) => ({
    insuranceProvider: insurance.provider,
    insuranceType: insurance.type,
    insuranceNumber: insurance.number,
    insuranceGuid: insurance.guid,
  }));

  const today = new Date();
  const patientTimeZone = patient.profile.timezone;

  const generalInformation = {
    firstName: patient.profile.firstName,
    lastName: patient.profile.lastName,
    dateOfBirth: patient.profile.birthDate,
    gender: patient.profile.gender,
    street: patient.profile.address,
    city: patient.profile.city,
    state: patient.profile.state,
    zip: patient.profile.zipPostalCode,
    patientPrimaryPhone: patient.profile.phone,
    patientSecondaryPhone: patient.profile.homeNumber,
    patientEmail: patient.profile.email,
    insurances: patientInsurances,
    primaryLanguage: patient.profile.language,
    race: patient.profile.race,
    ethnicity: patient.profile.ethnicity,
    workingStatus: patient.profile.workingStatus,
    timeZone: patient.profile.timezone,
    maritalStatus: patient.profile.martialStatus,
    physicianNPI: patient.doctor.npiNumber || patient.clinic.npiNumber,
    physicianFirstName: patient.doctor.firstName,
    physicianLastName: patient.doctor.lastName,
    emergencyContactCaregiverFirstName:
      patient.profile.emergencyContactFirstName,
    emergencyContactCaregiverLastName: patient.profile.emergencyContactLastName,
    emergencyContactCaregiverPhone: patient.profile.emergencyContactNumber,
    emergencyContactCaregiverRelationship:
      patient.profile.emergencyContactRelationship,
  };
  const tmphPatientQuestionnaire = getTmphPatientQuestionnaireInitialValues();
  const careCoordination = {
    companyType: null,
    representativeName: null,
    companyNumberCalled: null,
    date: today
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: patientTimeZone,
      })
      .replace(/\//g, '-')
      .replace(/,/, ''),
    time: today.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: patientTimeZone,
    }),
    additionalComments: null,
  };

  const fields = {
    ...generalInformation,
    ...tmphPatientQuestionnaire,
    ...careCoordination,
  };
  return fields;
};

export const formatSendData = (values) => {
  const insurances = values.insurances.map((insurance) => ({
    ...insurance,
    insuranceType: insurance.insuranceType || null,
  }));
  return {
    ...values,
    insurances,
    time: convertTo24Hour(values.time),
    verbalAuthorizationTime: formatTimeToCommonFormat(
      values.verbalAuthorizationTime,
    ),
  };
};
