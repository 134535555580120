import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { showResult } from 'infrastructure/hooks/utils';
import { useDispatch } from 'react-redux';

import { updateTempOnHoldStatusFn } from './api';

interface Props {
  onSuccess: () => void;
}

export const useUpdateTempHoldStatus = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const {
    ccmPatientDetails: { setCcmHoldingStatus },
  } = useDispatch();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: updateTempOnHoldStatusFn,
    onSuccess(data, variables) {
      setCcmHoldingStatus({
        ccmHoldingStatus: data.ccmHoldingStatus,
        ccmHoldingStatusStartDate: data.ccmHoldingStatusStartDate,
      });
      const patientInfoKey = getPatientInfoKey(variables.patientGuid);
      queryClient.setQueryData(patientInfoKey, (oldData: any) => {
        if (oldData) {
          return {
            ...oldData,
            ccmHoldingStatus: data?.ccmHoldingStatus,
            ccmHoldingStatusStartDate: data?.ccmHoldingStatusStartDate,
          };
        }
      });

      showResult();
      onSuccess();
    },
  });

  return {
    updateTempHoldStatus: mutateAsync,
    tempHoldingStatusLoading: isLoading,
  };
};
