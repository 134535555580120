import i18next from 'i18next';
import { ClinicReferralType } from 'infrastructure/enums';

export interface IClinicReferralTypeItem {
  id: ClinicReferralType;
  name: string;
}

export const clinicReferralTypesDataSource = (): IClinicReferralTypeItem[] => {
  return [
    {
      id: ClinicReferralType.RemoteMonitoringServices,
      name: i18next.t('clinicReferralTypes.remoteMonitoringServices'),
    },
    {
      id: ClinicReferralType.RemoteMonitoringServicesAndChronicCareManagement,
      name: i18next.t(
        'clinicReferralTypes.remoteMonitoringServicesAndChronicCareManagement',
      ),
    },
    {
      id: ClinicReferralType.ChronicCareManagement,
      name: i18next.t('clinicReferralTypes.chronicCareManagement'),
    },
    {
      id: ClinicReferralType.PCM,
      name: i18next.t('clinicReferralTypes.pcm'),
    },
    {
      id: ClinicReferralType.Wellness,
      name: i18next.t('clinicReferralTypes.wellness'),
    },
  ];
};
