import classNames from 'classnames';
import ChevronLeftIcon from 'components/atoms/icons/chevronLeft';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import s from './styles.module.scss';

import type { FC } from 'react';

interface ExpandItemProps {
  options: { label: string; link: string; dataCy: string }[];
  reactLink: boolean;
  label: string;
  onClick: () => void;
}

const ExpandItem: FC<ExpandItemProps> = ({
  options,
  label,
  reactLink = false,
  onClick,
}) => {
  const [expand, setExpand] = useState(false);
  const toggleExpand = () => setExpand((prev) => !prev);

  return (
    <li className={s['menu-item']}>
      <div
        className={classNames(s['menu-item-label'], {
          [s.accent]: expand,
        })}
        onClick={toggleExpand}
      >
        <p>{label}</p>
        <div>
          <ChevronLeftIcon
            className={classNames(s.icon, {
              [s.rotate]: expand,
            })}
          />
        </div>
      </div>
      <div
        className={classNames(s.divider, {
          [s.accent]: expand,
        })}
      />
      <div
        className={classNames(s['menu-item-links'], {
          [s.hide]: expand,
        })}
      >
        {options.map((opt, idx) =>
          reactLink ? (
            <Link
              key={idx}
              to={opt.link}
              data-cy={opt.dataCy}
              onClick={onClick}
            >
              {opt.label}
            </Link>
          ) : (
            <a
              key={idx}
              href={opt.link}
              data-cy={opt.dataCy}
              target="_blank"
              onClick={onClick}
            >
              {opt.label}
            </a>
          ),
        )}
      </div>
    </li>
  );
};
export default ExpandItem;
