import React from 'react';

const ArrowDownIcon = (props) => (
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 3.42702L7.14645 0.630175C7.34171 0.456608 7.65829 0.456608 7.85355 0.630175C8.04882 0.803741 8.04882 1.08515 7.85355 1.25871L4.35355 4.36983C4.15829 4.54339 3.84171 4.54339 3.64645 4.36983L0.146447 1.25871C-0.0488155 1.08515 -0.0488155 0.803741 0.146447 0.630175C0.341709 0.456608 0.658291 0.456608 0.853553 0.630175L4 3.42702Z"
      fill="#413B56"
      {...props}
    />
  </svg>
);

export default ArrowDownIcon;
