import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_MEDICAL_HISTORY } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';

import {
  MEDICAL_HISTORY_IS_ACTIVE,
  MEDICAL_HISTORY_PER_PAGE,
  getMedicalHistoryKey,
} from './use-get-medical-history';

import type {
  IMedicalHistory,
  IMedicalHistoryResponse,
} from 'infrastructure/interfaces';

interface DataProps {
  patientGuid: string;
  record: string;
}

const addMedicalHistoryFn = async (postData: DataProps) => {
  const url = API_MEDICAL_HISTORY;
  const { error, data } = await dataService.createOne<IMedicalHistory>(
    url,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useAddMedicalHistory = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: async (postData: DataProps) => addMedicalHistoryFn(postData),
    onSuccess(data, variables) {
      const queryKey = getMedicalHistoryKey({
        patientGuid: variables.patientGuid,
        isActive: MEDICAL_HISTORY_IS_ACTIVE,
        items: MEDICAL_HISTORY_PER_PAGE,
        page: 1,
      });
      queryClient.setQueryData<IMedicalHistoryResponse>(queryKey, (oldData) => {
        if (oldData && data) {
          const newData = structuredClone(oldData);
          newData.items.unshift(data);
          newData.count += 1;

          if (newData.items.length >= MEDICAL_HISTORY_PER_PAGE)
            newData.items.length = MEDICAL_HISTORY_PER_PAGE;

          return newData;
        }
        return oldData;
      });
      showResult();
    },
  });
  return {
    addMedicalHistory: mutate,
    addLoading: isLoading,
    ...other,
  };
};
