import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import dataService from 'services/dataService';
import { apiPatientCharts } from 'services/dataService/resources';
import { useAppSelector } from 'store';
import { toFirstLowerCase } from 'infrastructure/functions';

import type {
  NormalizeChartsData,
  IRpmPatientChart,
} from 'infrastructure/interfaces/i-rpm-patient-charts';

interface Props {
  patientGuid: string;
}

export const useRpmPatientChartsKey = (
  patientGuid: string,
  startTime?: number,
  endTime?: number,
) => ['getRpmPatientCharts', patientGuid, startTime, endTime];

export const useRpmPatientCharts = ({ patientGuid }: Props) => {
  const [startTime, endTime] = useAppSelector<[number, number] | []>(
    (state) => state.patient.dates,
  );

  const {
    patient: { setCharts },
  } = useDispatch();
  const { data: charts, ...other } = useQuery({
    queryKey: useRpmPatientChartsKey(patientGuid, startTime, endTime),
    queryFn: async () => {
      const { data, error } = await dataService.getList<IRpmPatientChart[]>(
        apiPatientCharts(patientGuid, startTime, endTime),
      );

      if (error) {
        throw new Error(error);
      }

      const normalizedData = data?.reduce<NormalizeChartsData>((acc, chart) => {
        const key = toFirstLowerCase(chart.readingType);
        // @ts-ignore
        acc[key] = chart.data;
        return acc;
      }, {} as NormalizeChartsData);

      setCharts(normalizedData);

      return normalizedData;
    },
    enabled: Boolean(startTime) && Boolean(endTime),
  });

  return {
    charts,
    ...other,
  };
};
