import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_TURK_SETTINGS } from 'services/dataService/resources';

export interface TurkConfigurations {
  defaultRatePerReading: number;
  maximumDailyReadings: number;
  maximumReadingsPerBatch: number;
  minimumReadingBatchRefreshRate: number;
  maximumReadingReviewResponseTime: number;
  maximumAllowableIdleTime: number;
  updatedBy: string;
  updatedAt: string;
}

const queryFn = async () => {
  const { error, data } = await dataService.getList<TurkConfigurations>(
    API_TURK_SETTINGS,
  );
  if (error) {
    throw new Error(error);
  }
  return data;
};

const useTurkDashboardSettings = () => {
  const DEFAULT_IDLE_TIME = 10;
  const DEFAULT_REFRESH_RATE = 7;
  const { data, error, isLoading, remove } = useQuery({
    queryKey: ['turkDashboardSettings'],
    queryFn,
  });

  return {
    turkConfiguration: data,
    isLoading,
    error,
    removeFromCache: remove,
    DEFAULT_IDLE_TIME,
    DEFAULT_REFRESH_RATE,
  };
};

export default useTurkDashboardSettings;
