import { useQuery } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';
import { useRef } from 'react';
import dataService from 'services/dataService';
import { API_TURK_STATS } from 'services/dataService/resources';

export interface Stats {
  count: number;
  earned: number;
  timeouts?: {
    readingsRefreshTime: number;
    readingsReviewResponseTime: number;
  };
}
const _10_MINUTES = minutesToMilliseconds(10);

const useTurkUserStats = () => {
  const { data, error, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['turkDashboardStats'],
    queryFn: async ({ signal }) => {
      const { error: localError, data: localData } =
        await dataService.getList<Stats>(API_TURK_STATS, {
          signal,
        });
      if (localError) {
        throw new Error(localError);
      }
      return localData;
    },
    refetchInterval: _10_MINUTES,
  });

  const timeoutTimerRef = useRef<number | null>(null);

  const scheduleRefetch = (minutes = 1) => {
    if (timeoutTimerRef.current) {
      clearTimeout(timeoutTimerRef.current);
    }
    timeoutTimerRef.current = window.setTimeout(() => {
      refetch();
    }, minutesToMilliseconds(minutes));
  };

  return {
    stats: data,
    isLoading,
    error,
    refetch,
    isRefetching,
    scheduleRefetch,
  };
};

export default useTurkUserStats;
