import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Header from './components/header';
import PatientInfo from './components/patientInfo';
import Content from './components/content';
import Footer from './components/footer';

const ConsentFormContent = () => {
  const { patientGuid } = useParams();
  const {
    adminPanelPatients: { getPatient, getPatientDetailsWithCcmData },
  } = useDispatch();

  const { getPatient: patientsDataLoading } = useSelector(
    (state) => state.loading.effects.adminPanelPatients,
  );

  const { getPatientDetailsWithCcmData: ccmDataLoading } = useSelector(
    (state) => state.loading.effects.adminPanelPatients,
  );

  const patient = useSelector((state) => state.adminPanelPatients.patient);
  useEffect(() => {
    const run = async () => {
      await getPatient({ patientGuid });
      await getPatientDetailsWithCcmData(patientGuid);
    };
    run();
  }, []);

  return (
    <div>
      {!ccmDataLoading && !patientsDataLoading && (
        <div>
          <Header />
          <PatientInfo />
          <Content patient={patient} />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default ConsentFormContent;
