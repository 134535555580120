import { useFormik } from 'formik';
import { useState } from 'react';
import { showResult } from 'infrastructure/hooks/utils';
import dataService from 'services/dataService';
import { API_TURK_SETTINGS_UPDATE } from 'services/dataService/resources';

import { schema } from './schema';

import type { TurkConfigurations } from 'utils/hooks/turkDashboard/useSettings';

export const useTurkConfigurationEdit = ({
  turkConfiguration,
  onSubmit,
}: {
  turkConfiguration: TurkConfigurations;
  onSubmit: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      defaultRatePerReading: turkConfiguration.defaultRatePerReading,
      maximumDailyReadings: turkConfiguration.maximumDailyReadings,
      maximumReadingsPerBatch: turkConfiguration.maximumReadingsPerBatch,
      minimumReadingBatchRefreshRate:
        turkConfiguration.minimumReadingBatchRefreshRate,
      maximumReadingReviewResponseTime:
        turkConfiguration.maximumReadingReviewResponseTime,
      maximumAllowableIdleTime: turkConfiguration.maximumAllowableIdleTime,
    },
    onSubmit: async (values) => {
      setLoading(true);
      const { error } = await dataService.updateOnly(
        API_TURK_SETTINGS_UPDATE,
        values,
      );
      setLoading(false);
      if (error) {
        showResult(error);
        return;
      }
      onSubmit();
    },
  });

  return { formik, loading };
};
