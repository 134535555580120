import i18next from 'i18next';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { TimerCancelledError } from 'infrastructure/classes/timer-cancelled-error';
import { readingCommentWithUndo } from 'infrastructure/functions/reading-comment';
import dataService from 'services/dataService';
import {
  apiDoctorPatientReadings,
  apiDoctorPatientReadingsCount,
  API_READINGS_ACTIONS,
  API_DOCTOR_INSURANCE_TYPES,
  ACTIVITY_CSV_REPORT,
  API_PATIENTS_READINGS_RESERVE,
  API_READINGS_STOP_SLA_TIMER,
  API_PATIENTS_INTERACTION_STATUS,
} from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';
import store from 'store';

const ITEMS_PER_PAGE = 100;
const defaultProfile = {
  name: '',
  title: '',
  mobileNumber: '',
  workNumber: '',
  npiNumber: '',
  professionType: '',
  primaryLanguage: '',
  secondaryLanguage: '',
  addressLine1: '',
  addressLine2: '',
  state: '',
  zip: '',
  alertEmail: false,
  alertSms: false,
  logoUrl: '',
  designation: '',
  authorGuid: '',
};
const INITIAL_STATE = {
  reviewed: false,
  onCallPatients: [],
  filters: {
    status: null,
    clinicState: null,
    readingType: null,
    patientGuid: null,
    clinicGuid: null,
    doctorGuid: null,
    insuranceType: null,
    reverse: null,
    assignee: null,
    actionTimesQuota: null,
    childClinic: null,
  },
  unsavedComments: {},
  unsavedGeneralComment: {},
  sort: {
    time: false,
    name: null,
    readingType: null,
  },
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  data: [],
  totalPages: 0,
  totalCount: 0,
  dates: [],
  insuranceTypes: [],
  readingsCount: {
    normal: 0,
    risk: 0,
    critical: 0,
    slaReadings: 0,
  },
  profile: defaultProfile,
};

const doctor = {
  state: INITIAL_STATE,
  reducers: {
    reset: (state) => ({
      ...INITIAL_STATE,
      dates: state.dates.length > 0 ? state.dates : [],
    }),
    setFilterDates: (state, payload) => ({
      ...state,
      dates: payload,
    }),
    setReviewed: (state, payload) => ({
      ...state,
      reviewed: payload,
    }),
    setPage: (state, payload) => ({
      ...state,
      page: payload < 1 ? 1 : payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      // FIXME: Can be deleted. Unused method
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    setActivities: (state, payload) => ({
      // FIXME: Can be deleted. Unused method
      ...state,
      data: payload,
    }),
    setFilters: (state, filters) => ({
      ...state,
      filters: { ...state.filters, ...filters },
    }),
    setProfile: (state, payload) => ({
      ...state,
      profile: {
        ...state.profile,
        ...payload,
      },
    }),
    setInsuranceTypes: (state, payload) => ({
      ...state,
      insuranceTypes: payload,
    }),
    setReadingsCount: (state, payload) => ({
      ...state,
      readingsCount: payload,
    }),
    setUnsavedComments: (state, payload) => ({
      // FIXME: Can be deleted. Unused method
      ...state,
      unsavedComments: payload,
    }),
    setUnsavedGeneralComment: (state, payload) => ({
      ...state,
      unsavedGeneralComment: payload,
    }),
    toggleTimeSort: (state) => ({
      ...state,
      sort: {
        ...state.sort,
        time: !state.sort.time,
      },
    }),
    toggleNameSort: (state) => ({
      ...state,
      sort: {
        ...state.sort,
        name: !state.sort.name || state.sort.name === 'DESC' ? 'ASC' : 'DESC',
      },
    }),
    toggleReadingTypeSort: (state) => ({
      ...state,
      sort: {
        ...state.sort,
        readingType:
          !state.sort.readingType || state.sort.readingType === 'DESC'
            ? 'ASC'
            : 'DESC',
      },
    }),
    setOnCallPatients: (state, payload) => ({
      ...state,
      onCallPatients: payload,
    }),
    UPDATE_READING_SLA_REVIEWED_TIME: (state, payload) => {
      // FIXME: Can be deleted. Unused method
      const { data } = state;
      const newData = data.map((item) => {
        if (item.id === payload.id && item.deviceId === payload.deviceId) {
          return {
            ...item,
            slaReviewedTime: payload.slaReviewedTime,
          };
        }
        return item;
      });
      return {
        ...state,
        data: newData,
      };
    },
    UPDATE_PATIENT_SLA_REVIEWED_TIME: (state, payload) => {
      // FIXME: Can be deleted. Unused method
      const { data } = state;
      const newData = data.map((item) => {
        if (item.patient.guid === payload.patientGuid) {
          return {
            ...item,
            slaReviewedTime: payload.slaReviewedTime,
          };
        }
        return item;
      });
      return {
        ...state,
        data: newData,
      };
    },
    changeInteractionStatusForPatient: (
      state,
      { patientGuid, interactionStatus },
    ) => ({
      ...state,
      data: state.data.map((item) => {
        if (item.patient.guid === patientGuid) {
          return {
            ...item,
            patient: {
              ...item.patient,
              interactionStatus,
            },
          };
        }
        return item;
      }),
    }),
  },
  effects: (dispatch) => ({
    async getActivities(
      { reviewed, status, items = ITEMS_PER_PAGE, page, applySpecialFilter },
      state,
    ) {
      // FIXME: Can be deleted. Unused method
      if (page) {
        dispatch.doctor.setPage(page);
      }
      if (reviewed) {
        dispatch.doctor.setReviewed(reviewed);
      }
      const {
        page: statePage,
        dates,
        filters,
        sort,
        reviewed: stateReviewed,
      } = state.doctor;
      const [startTime, endTime] = dates;
      if (status) {
        filters.status = status;
      }
      const { data, error } = await dataService.getList(
        apiDoctorPatientReadings(
          reviewed ?? stateReviewed,
          items,
          page ?? statePage,
          startTime,
          endTime,
          sort.time,
          sort.name,
          sort.readingType,
          filters,
          applySpecialFilter,
        ),
      );
      if (error) {
        dispatch.doctor.setActivities([]);
      }
      if (data) {
        dispatch.doctor.setActivities(data?.data || []);
        const totalCount = data?.count || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.doctor.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
      } else {
        dispatch.doctor.setActivities([]);
        dispatch.doctor.setTotalCountAndTotalPages({
          totalCount: 0,
          totalPages: 0,
        });
      }
    },
    async getActivitiesCount(payload, state) {
      // FIXME: Can be deleted. Unused method
      const { reviewed, dates, filters } = state.doctor;
      const [startTime, endTime] = dates;

      const { data } = await dataService.getList(
        apiDoctorPatientReadingsCount(
          reviewed,
          startTime,
          endTime,
          filters,
          payload.applySpecialFilter,
        ),
      );
      if (data) {
        dispatch.doctor.setReadingsCount(data);
      } else {
        dispatch.doctor.setReadingsCount({
          slaReadings: 0,
          critical: 0,
          risk: 0,
          normal: 0,
        });
      }
    },
    async submitComment(postData) {
      // FIXME: Can be deleted. Unused method
      const appendComment = (data) => {
        const state = store.getState();
        const updatedData = state.doctor.data.map((item) =>
          item.id === data.readingId && item.deviceId === data.deviceId
            ? { ...item, comments: [data, ...item.comments] }
            : item,
        );
        dispatch.doctor.setActivities(updatedData);
        dispatch.doctor.setUnsavedComments({});
      };
      const removeComment = (data) => {
        const state = store.getState();
        const updatedData = state.doctor.data.map((item) =>
          item.id === data.readingId && item.deviceId === data.deviceId
            ? {
                ...item,
                comments: item.comments.filter(
                  (comment) => comment.guid !== data.guid,
                ),
              }
            : item,
        );
        dispatch.doctor.setActivities(updatedData);
        dispatch.doctor.setUnsavedComments({});
      };

      const state = store.getState();
      const { mockComment, undoTimeoutPromise } = readingCommentWithUndo({
        message: postData.message,
        readingId: postData.readingId,
        deviceId: postData.deviceId,
        createdBy: state.user.loginGuid,
        createdByFirstName: state.user.firstName,
        createdByLastName: state.user.lastName,
        createdByProfessionType: state.user.professionType,
        createdByTitle: state.user.title,
      });

      appendComment(mockComment);
      try {
        await undoTimeoutPromise;
        const { error, data } = await dataService.createOne(
          API_READINGS_ACTIONS,
          postData,
        );
        removeComment(mockComment);
        if (!error) {
          appendComment(data);
          notificationController.success({
            message: i18next.t('labels.commentSubmitted'),
          });
        }
        return { error, data };
      } catch (error) {
        if (error instanceof TimerCancelledError) {
          removeComment(mockComment);
          return {};
        }
        return { error };
      }
    },
    async actionReview(postData, state) {
      // FIXME: Can be deleted. Unused method
      const { error, data } = await dataService.createOne(
        API_READINGS_ACTIONS,
        postData,
      );
      if (!error) {
        const updatedData = state.doctor.data.map((item) => {
          if (item.id !== data.readingId || item.deviceId !== data.deviceId)
            return item;
          return {
            ...item,
            disabled: true,
            slaReviewedTime: data.slaReviewedTime,
          };
        });
        dispatch.doctor.setActivities(updatedData);
      }
      return { error, data };
    },
    async stopSlaTimer(activity) {
      // FIXME: Can be deleted. Unused method
      const postData = {
        readingId: activity.id,
        deviceId: activity.deviceId,
      };
      const { error, data } = await dataService.createOne(
        API_READINGS_STOP_SLA_TIMER,
        postData,
      );

      if (data) {
        dispatch.doctor.UPDATE_READING_SLA_REVIEWED_TIME({
          id: activity.id,
          deviceId: activity.deviceId,
          slaReviewedTime: data.slaReviewedTime,
        });
      }
      return { error, data };
    },
    async changeReviewed(payload, state) {
      const { reviewed } = state.doctor;
      dispatch.doctor.setPage(1);
      dispatch.doctor.setReviewed(!reviewed);
      dispatch.doctor.getActivities({ applySpecialFilter: true });
      dispatch.doctor.getActivitiesCount({ applySpecialFilter: true });
    },
    async setActivitiesPage({ reviewed = false, pageOffset, goToPage }, state) {
      // FIXME: Can be deleted. Unused method
      const { doctor: doctorState } = state;
      const { page, totalPages } = doctorState;
      const newPage = goToPage || page + pageOffset;

      dispatch.doctor.setPage(newPage <= totalPages ? newPage : totalPages);
      return dispatch.doctor.getActivities({
        page: newPage,
        reviewed: !reviewed ? doctorState.reviewed : reviewed,
        applySpecialFilter: true,
      });
    },
    async toggleActivitiesTimeSort() {
      dispatch.doctor.toggleTimeSort();
      return dispatch.doctor.getActivities({});
    },
    async toggleActivitiesNameSort() {
      dispatch.doctor.toggleNameSort();
      return dispatch.doctor.getActivities({});
    },
    async toggleActivitiesReadingTypeSort() {
      dispatch.doctor.toggleReadingTypeSort();
      return dispatch.doctor.getActivities({});
    },
    async getInsuranceTypes() {
      const { data } = await dataService.getList(API_DOCTOR_INSURANCE_TYPES);
      if (data) {
        dispatch.doctor.setInsuranceTypes(data);
      }
    },
    applyFilters(filters) {
      // FIXME: Can be deleted. Unused method
      Object.keys(filters).forEach((key) => {
        if (filters[key] === undefined) delete filters[key];
        else if (filters[key] === 'all') filters[key] = null;
      });
      dispatch.doctor.setFilters(filters);
      dispatch.doctor.setPage(Number(filters?.page) || 1);
    },
    applyTimeFilter(dates) {
      // FIXME: Can be deleted. Unused method
      if (dates.length === 0) {
        dispatch.doctor.setFilterDates([]);
      } else {
        const [startDate, endDate] = dates;
        const startTime = startDate.getTime();
        const endTime = endDate.getTime();
        dispatch.doctor.setFilterDates([startTime, endTime]);
      }
      dispatch.doctor.setPage(1);
    },
    async getActivityCsvReport(payload, state) {
      const { dates, filters } = state.doctor;
      const [startTime, endTime] = dates;

      const reportFilters = {};
      Object.keys(filters).forEach((key) => {
        const filter = filters[key];
        if (filter) {
          reportFilters[key] = filter;
        }
      });
      const { error, data } = await dataService.createOne(ACTIVITY_CSV_REPORT, {
        ...reportFilters,
        startTime,
        endTime,
      });
      return { error, data };
    },
    addComment(key, state) {
      // FIXME: Can be deleted. Unused method
      let comments;
      const { unsavedComments } = state.doctor;
      // eslint-disable-next-line prefer-const
      comments = { ...unsavedComments };
      if (!comments[key]) {
        comments[key] = true;
        dispatch.doctor.setUnsavedComments(comments);
      }
    },
    removeComment(key, state) {
      // FIXME: Can be deleted. Unused method
      let comments;
      const { unsavedComments } = state.doctor;
      // eslint-disable-next-line prefer-const
      comments = { ...unsavedComments };
      if (comments[key]) {
        delete comments[key];
        dispatch.doctor.setUnsavedComments(comments);
      }
    },
    clearComments() {
      // FIXME: Can be deleted. Unused method
      dispatch.doctor.setUnsavedComments({});
    },
    addGeneralComment(key, state) {
      let comments;
      const { unsavedGeneralComment } = state.doctor;
      // eslint-disable-next-line prefer-const
      comments = { ...unsavedGeneralComment };
      if (!comments[key]) {
        comments[key] = true;
        dispatch.doctor.setUnsavedGeneralComment(comments);
      }
    },
    removeGeneralComment(key, state) {
      let comments;
      const { unsavedGeneralComment } = state.doctor;
      // eslint-disable-next-line prefer-const
      comments = { ...unsavedGeneralComment };
      if (comments[key]) {
        delete comments[key];
        dispatch.doctor.setUnsavedGeneralComment(comments);
      }
    },
    clearGeneralComment() {
      dispatch.doctor.setUnsavedGeneralComment({});
    },
    addOnCallPatient(patientGuid, state) {
      // FIXME: Can be deleted. Unused method
      const { onCallPatients } = state.doctor;
      if (!onCallPatients.includes(patientGuid)) {
        dispatch.doctor.setOnCallPatients([...onCallPatients, patientGuid]);
      }
    },
    removeOnCallPatient(patientGuid, state) {
      // FIXME: Can be deleted. Unused method
      const { onCallPatients } = state.doctor;
      const index = onCallPatients.indexOf(patientGuid);
      if (index > -1) {
        onCallPatients.splice(index, 1);
      }
    },
    async togglePatientReserved(patientGuid) {
      // FIXME: Can be deleted. Unused method
      const { error, data: response } = await dataService.createOne(
        API_PATIENTS_READINGS_RESERVE,
        {
          patientGuid,
        },
      );
      if (error) {
        showResult(error);
        return false;
      }

      dispatch.doctor.assignOrUnassignPatientReading({
        action: response.data.action,
        patientGuid: response.data.patientGuid,
        author: response.data.body,
        expireAt: response.data.expireAt,
      });

      if (response.data.slaReviewedTime) {
        dispatch.doctor.UPDATE_PATIENT_SLA_REVIEWED_TIME({
          patientGuid: response.data.patientGuid,
          slaReviewedTime: response.data.slaReviewedTime,
        });
      }

      return true;
    },
    // FIXME: Can be deleted. Unused method
    assignOrUnassignPatientReading(
      { action, patientGuid, author, expireAt },
      state,
    ) {
      const { data } = state.doctor;
      const updatedActivities = data.map((item) => {
        if (item.patient.guid === patientGuid) {
          if (action === 'unassign') {
            return { ...item, reserved: null };
          }
          if (action === 'assign') {
            return {
              ...item,
              reserved: {
                ...author,
                expireAt,
              },
            };
          }
        }
        return item;
      });
      dispatch.doctor.setActivities(updatedActivities);
    },
    async togglePatientInteractionStatus({ patientGuid, interactionStatus }) {
      const { error } = await dataService.updateOnly(
        API_PATIENTS_INTERACTION_STATUS,
        {
          patientGuid,
          isInteracted: interactionStatus,
        },
      );

      if (error) {
        showResult(error);
        return;
      }
      dispatch.doctor.changeInteractionStatusForPatient({
        patientGuid,
        interactionStatus,
      });

      return { error };
    },
  }),
};

export default doctor;
