import React, { useEffect, useRef, useState } from 'react';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import { usePatientContactAvailability } from 'infrastructure/hooks/patient/use-patient-contact-availability';
import { useEdit } from 'infrastructure/providers/edit-provider';
import CollapseAnimation from 'components/atoms/base-animations/collapse';
import SlideAnimation from 'components/atoms/base-animations/slide';
import BaseSwitch from 'components/atoms/base-switch';
import useUser from 'utils/useUser';

import s from './styles.module.scss';
import ContactAvailabilityUpdateForm from '../update-form';
import ContactAvailabilityViewForm from '../view-form';

import type { IContactAvailabilityUpdateFormRef } from '../update-form';

const formKey = 'ContactAvailabilityForm';

interface IProps {
  patientGuid: string;
  readOnly?: boolean;
}

const ContactAvailabilityCombinedForm: React.FC<IProps> = (props) => {
  const { patientGuid, readOnly = false } = props;

  const { t } = useTranslation();
  const { isMobileDevice } = useUser();

  const { canEdit, setEdit } = useEdit();

  const [anytime, setAnytime] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { loading, availabilities } = usePatientContactAvailability({
    patientGuid,
  });

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const updateFormRef = useRef<IContactAvailabilityUpdateFormRef>(null);

  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };

  const onCancel = () => {
    setIsEdit(false);
    setEdit(false);
    setAnytime(!availabilities.length);
  };

  const onSaved = () => {
    setIsEdit(false);
    setEdit(false);
  };

  const onSave = async () => {
    if (updateFormRef.current) {
      setIsSaving(true);
      await updateFormRef.current.save().finally(() => {
        setIsSaving(false);
      });
    }
  };

  const additionalControls = (
    <BaseSwitch
      disabled={loading || isSaving}
      className={s.label}
      dataCy="contactAvailability-anytime-switch"
      label={t('labels.anytime')}
      value={anytime}
      onChange={setAnytime}
    />
  );

  useEffect(() => {
    setAnytime(!availabilities.length);
  }, [loading, isSaving]);

  return (
    <PatientBlock
      title={t('labels.contactAvailability')}
      isEdit={isEdit}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      loading={loading || isSaving}
      canEdit={!readOnly}
      disabled={!canEdit(formKey)}
      additionalControls={additionalControls}
      mobileTwoHeaderRows
    >
      <SlideAnimation
        uniqueKey={`${isEdit}`}
        // eslint-disable-next-line no-nested-ternary
        direction={isEdit ? (isMobileDevice ? 'left' : 'right') : 'left'}
      >
        <CollapseAnimation
          initialHeight={isMobileDevice ? 360 : 120}
          opened={isEdit}
          uniqueKey={`${loading}`}
        >
          {isEdit && !readOnly ? (
            <ContactAvailabilityUpdateForm
              ref={updateFormRef}
              patientGuid={patientGuid}
              onSaved={onSaved}
              anytime={anytime}
            />
          ) : (
            <ContactAvailabilityViewForm patientGuid={patientGuid} />
          )}
        </CollapseAnimation>
      </SlideAnimation>
    </PatientBlock>
  );
};

export default ContactAvailabilityCombinedForm;
