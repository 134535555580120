import DatePicker from 'components/atoms/input/types/datePicker';
import PropTypes from 'prop-types';

const DateInput = ({
  id,
  title,
  setValue,
  values,
  isRequired,
  requiredIf,
  helpText,
}) => {
  let required = isRequired;
  if (requiredIf) {
    required = isRequired || values[requiredIf.key] === requiredIf.value;
  }
  return (
    <div className="input-wrapper" data-cy={id}>
      <label htmlFor={id}>
        {title} {required && <span className="color-red">*</span>}
      </label>
      {helpText && <p className="help-text">{helpText}</p>}
      <DatePicker
        valueChanged={(e) => {
          setValue({
            [id]: e,
          });
        }}
        value={values[id] || ''}
      />
    </div>
  );
};

DateInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  helpText: PropTypes.string,
  setValue: PropTypes.func,
  isRequired: PropTypes.bool,
  requiredIf: PropTypes.object,
  values: PropTypes.shape(),
};

export default DateInput;
