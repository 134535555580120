import React from 'react';
import { useTranslation } from 'react-i18next';

import TextAreaAutoHeight from '../textarea/autoHeight';
import ChatSendIcon from '../icons/chatSendIcon';
import BaseButton from '../baseButton';

import type { ChangeEvent } from 'react';

interface IChatInputProps {
  value: string;
  setValue: (data: string) => void;
  onSubmit: () => void;
  loading: boolean;
  id: string;
}

const ChatInput: React.FC<IChatInputProps> = (props) => {
  const { value, setValue, onSubmit, loading, id } = props;

  const { t } = useTranslation();

  return (
    <div className="chat-input-wrapper">
      <TextAreaAutoHeight
        className="chat-input"
        id={id}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        onSubmit={onSubmit}
        value={value}
        loading={loading}
        placeholder={`${t('smsDashboard.type_message')}...`}
        hideHint
        height="37px"
      />
      <div className="chat-send-message-button">
        <BaseButton
          type="secondary"
          disabled={value === ''}
          loading={loading}
          onClick={onSubmit}
          icon={<ChatSendIcon />}
        />
      </div>
    </div>
  );
};

export default ChatInput;
