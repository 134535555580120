import {
  parseSearchArrayValue,
  parseSearchBooleanValue,
  parseSearchStringValue,
} from 'infrastructure/functions/parse-query-string';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addQueryParams } from 'utils/queryHelpers';

export type TPatientFilter = {
  page: number;
  ccmStatus?: string[];
  status?: string[];
  patientGuid?: string;
  patientLabel?: string;
  patientKey?: string;
  excludeClinicGuid?: string[];
  clinicGuid?: string[];
  allExcluded?: boolean;
};

export const usePatientsFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    page,
    status,
    ccmStatus,
    clinicGuid,
    patientGuid,
    patientLabel,
    patientKey,

    excludeClinicGuid,
    allExcluded,
  } = queryString.parse(location.search);

  const filter = useMemo(
    () => ({
      page: Number(page || 1),
      ccmStatus: parseSearchArrayValue(ccmStatus),
      status: parseSearchArrayValue(status),
      patientGuid: parseSearchStringValue(patientGuid),
      patientLabel: parseSearchStringValue(patientLabel),
      patientKey: parseSearchStringValue(patientKey) ?? 'fullName',
      clinicGuid: parseSearchArrayValue(clinicGuid),
      excludeClinicGuid: parseSearchArrayValue(excludeClinicGuid),
      allExcluded: parseSearchBooleanValue(allExcluded),
    }),
    [
      page,
      String(status),
      String(ccmStatus),
      String(excludeClinicGuid),
      clinicGuid,
      patientGuid,
      patientLabel,
      patientKey,
      allExcluded,
    ],
  );

  const resetFilter = () => {
    navigate(
      {
        search: queryString.stringify({}),
      },
      {
        replace: true,
      },
    );
  };

  const updateQueryParams = (newFilter: Partial<TPatientFilter>) => {
    newFilter.page = newFilter.page ?? undefined;
    addQueryParams({ navigate, location }, newFilter);
  };

  return {
    filter,
    updateQueryParams,
    resetFilter,
  };
};
