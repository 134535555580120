import type { FC, SVGProps } from 'react';

const CrossControlIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5922 4.62403C9.62437 -0.473549 16.3707 -1.50509 20.6809 2.27339C24.9911 6.05187 25.2201 16.4803 20.6809 21.0786C16.1416 25.6768 5.67952 24.2016 2.11701 21.0786C-1.4455 17.9555 -0.439978 9.72162 4.5922 4.62403Z"
        fill="#667180"
      />
      <rect width="12" height="12" transform="translate(6 6)" fill="#667180" />
      <path
        d="M7.60984 8.14017C7.46339 7.99372 7.46339 7.75628 7.60984 7.60983C7.75628 7.46339 7.99372 7.46339 8.14017 7.60983L12 11.4697L15.8598 7.60983C16.0063 7.46339 16.2437 7.46339 16.3902 7.60983C16.5366 7.75628 16.5366 7.99372 16.3902 8.14017L12.5303 12L16.3902 15.8598C16.5366 16.0063 16.5366 16.2437 16.3902 16.3902C16.2437 16.5366 16.0063 16.5366 15.8598 16.3902L12 12.5303L8.14017 16.3902C7.99372 16.5366 7.75628 16.5366 7.60984 16.3902C7.46339 16.2437 7.46339 16.0063 7.60984 15.8598L11.4697 12L7.60984 8.14017Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default CrossControlIcon;
