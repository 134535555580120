import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import common from 'constants/common';
import { Container, Card, CardBody } from 'reactstrap';
import PatientsTable from 'components/templates/rpm/practice/complianceReport/patientTable';
import withLoader from 'components/organisms/withLoader';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import { showResult } from 'infrastructure/hooks/utils';
import Pagination from 'components/molecules/pagination';

const ComplianceReport = () => {
  const complianceReport = useSelector((state) => state.compliance);
  const { page, totalCount } = complianceReport;

  const {
    compliance: {
      getReport,
      getComplianceCsvReport,
      reset: resetCompliance,
      setPageCompliance,
    },
  } = useDispatch();

  const {
    getReport: complianceReportLoading,
    getComplianceCsvReport: csvLoading,
  } = useSelector((state) => state.loading.effects.compliance);

  const getComplianceReport = useCallback(() => {
    if (complianceReport.dates.length > 0) getReport();
  }, [complianceReport.dates, complianceReport.filters]);

  const setPage = (pageOffset) => {
    setPageCompliance({ pageOffset });
  };

  const sendReport = async () => {
    const { error } = await getComplianceCsvReport();
    return showResult(error, common.submitSuccessTypes.sendReport);
  };

  useEffect(() => {
    resetCompliance();
  }, []);

  useEffect(() => {
    getComplianceReport();
  }, [getComplianceReport]);

  return (
    <Container className="compliance-report-container">
      <Card className="card-border">
        <CardBody className="card-outer">
          <div className="performance-review__header">
            <div className="compliance-report-title">
              Report: Practice Daily Reading Compliance
            </div>
            <div className="performance-review__header-icon">
              {withLoader(
                csvLoading,
                <button onClick={sendReport}>
                  <DownloadRoundedIcon className="performance-review__header-icon-svg" />
                  <br />
                  <span>Download</span>
                </button>,
              )}
            </div>
          </div>
          <PatientsTable
            compliance={complianceReport}
            loading={complianceReportLoading}
          />
          <div className="compliance-report-buttons flex-row-reverse">
            <Pagination
              title=""
              page={page}
              pageTotal={complianceReport.data.length}
              itemsPerPage={complianceReport.itemsPerPage}
              total={totalCount}
              handlePrevClick={() => setPage(-1)}
              handleNextClick={() => setPage(+1)}
              handleFirstClick={() => setPage(-(page - 1))}
              handleLastClick={() => setPage(Math.ceil(totalCount / 10))}
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};
export default ComplianceReport;
