import common from 'constants/common';
import { format, startOfMonth } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import dataService from 'services/dataService';
import { API_APPOINTMENT_COMPLETIONS } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';

export const useCcmAppointmentByMonth = () => {
  const { patientGuid } = useParams();

  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState({});
  const [loading, setLoading] = useState(false);

  const loadData = (dateRaw) => {
    if (!dateRaw) return;
    const date = format(
      startOfMonth(dateRaw),
      common.dateFormats.priorAuthorizationAdmin,
    );

    const alreadyFetched = allItems[date];

    if (alreadyFetched) {
      setItems(alreadyFetched);
      return;
    }

    setLoading(true);
    const params = new URLSearchParams({ date });

    dataService
      .getList(`${API_APPOINTMENT_COMPLETIONS}/${patientGuid}?${params}`)
      .then(({ data, error }) => {
        if (error) return showResult(error);
        setAllItems((prev) => ({
          ...prev,
          [date]: data.items,
        }));
        setItems(data.items);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loadData,
    items,
    loading,
  };
};
