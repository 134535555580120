import { useQuery } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { arrUniqEl } from 'infrastructure/functions';
import { Clinic } from 'infrastructure/classes/clinic';
import { ClinicStatus, ServiceDeliveryThreshold } from 'infrastructure/enums';

import useApi from '../use-api';

import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IClinicPaginatedData,
  IClinicListFilters,
} from 'infrastructure/interfaces';

const getClinicsApi = 'admins/admin-panel/clinics';

const prepareFilters = (filters?: IClinicListFilters) => {
  if (!filters) return {};
  const copiedFilters = structuredClone(filters);
  if (copiedFilters.status === ClinicStatus.All) {
    delete copiedFilters.status;
  }
  if (
    copiedFilters.serviceDeliveryThresholds === ServiceDeliveryThreshold.All
  ) {
    delete copiedFilters.serviceDeliveryThresholds;
  }
  return copiedFilters;
};

type MergedMeta = IPaginationMeta<IClinicListFilters>;

type UseAdminClinic = {
  loading: boolean;
  clinics: Clinic[];
  meta: MergedMeta;
};

interface IUseAdminClinicListProps {
  meta?: IClinicListFilters & IPartialPaginationMeta;
}

export const useAdminClinicList = (
  props?: IUseAdminClinicListProps,
): UseAdminClinic => {
  const { items = 100, page = 1 } = props?.meta ?? {};
  const { loadData } = useApi();

  const preparedFilter = prepareFilters(props?.meta);
  const queryKey = [getClinicsApi, preparedFilter];

  const { data, isLoading } = useQuery({
    queryKey,
    staleTime: STALE_TIME,
    queryFn: () =>
      loadData<
        IClinicPaginatedData,
        Omit<IPaginationMeta, 'sortField' | 'totalCount'>
      >(getClinicsApi, {
        sortField: JSON.stringify(props?.meta?.sortField),
        items,
        page,
        ...preparedFilter,
      }).then((res) => {
        if (res) {
          return {
            count: res.count,
            items: arrUniqEl(res.items, 'guid').map((el) => new Clinic(el)),
          };
        }
      }),
  });

  const meta = {
    totalCount: data?.count ?? 0,
    page,
    items,
    ...props?.meta,
  };

  return {
    meta,
    clinics: data ? data.items : [],
    loading: isLoading,
  };
};
