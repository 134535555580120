import React, { useMemo } from 'react';
import { useAdminClinicList } from 'infrastructure/hooks/clinic/use-admin-clinic-list';
import { useTranslation } from 'react-i18next';
import BaseTableReport from 'components/atoms/base-table/report/report';

import { clinicColumns } from './cols';

import type { IClinicListFilters } from 'infrastructure/interfaces';

interface IProps {
  filters: IClinicListFilters;
  updateFilter: (newFilter: Partial<IClinicListFilters>) => void;
}

const ClinicsTable: React.FC<IProps> = ({ filters, updateFilter }) => {
  const { t } = useTranslation();
  const { loading, clinics, meta } = useAdminClinicList({
    meta: filters,
  });

  const columns = useMemo(
    () => clinicColumns({ page: filters.page }),
    [filters.page],
  );

  const onChangePage = (page: number) => {
    updateFilter({ page });
  };

  return (
    <BaseTableReport
      rowKey="guid"
      headerTitle={t('labels.clinics')}
      dataCy="clinics-table"
      columns={columns}
      dataSource={clinics}
      isLoading={loading}
      pagination={{
        current: filters.page,
        pageSize: meta.items,
        total: meta.totalCount,
        onChange: onChangePage,
      }}
      showCount
      anchor
    />
  );
};

export default ClinicsTable;
