import React from 'react';
import { clinicStatusesDataSource } from 'infrastructure/data-sources/clinic-statuses';
import { debug } from 'utils/logger';
import BaseTag from 'components/atoms/base-tag';

import type { ClinicStatus } from 'infrastructure/enums';

type IProps = {
  status: ClinicStatus;
};

const ClinicStatusTag: React.FC<IProps> = ({ status }) => {
  const rec = clinicStatusesDataSource().findByField('id', status);

  if (!rec) {
    debug(`ClinicStatusTag error: Invalid clinic status: ${status}`);
    return <></>;
  }

  return (
    <BaseTag
      size="large"
      color={rec.color as any}
      label={rec.name}
      dataCy="clinic-status-tag"
      transparent
      width="140px"
    />
  );
};

export default ClinicStatusTag;
