import i18next from 'i18next';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { createWaitPromise } from 'utils/helpers/timer';
import { Comment } from 'infrastructure/classes/comments/comment';

import type { ICreateComment } from 'infrastructure/interfaces';

const UNDO_TIMEOUT_SECONDS = 5;

export const generalCommentWithUndo = (data: ICreateComment) => {
  const mockComment: Comment = new Comment({
    ...data,
    hidden: false,
    createdBy: 'mock',
    createdAt: new Date().toISOString(),
  });
  const { cancel, promise: undoTimeoutPromise } = createWaitPromise(
    UNDO_TIMEOUT_SECONDS * 1000,
  );

  notificationController.showUndo({
    duration: UNDO_TIMEOUT_SECONDS,
    message: i18next.t('labels.commentSubmitting'),
    onUndo: () => {
      cancel();
      setTimeout(() => {
        notificationController.info({
          message: i18next.t('labels.commentUndone'),
        });
      }, 1000);
    },
  });

  return {
    mockComment,
    undoTimeoutPromise,
  };
};
