import { Availability } from 'infrastructure/classes/availability';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import useApi from '../use-api';

import type {
  IAvailability,
  ICreateAvailability,
  IDeleteAvailability,
  IUpdateAvailability,
} from 'infrastructure/interfaces';

const patientContactAvailabilitiesApi = (patientGuid: string) =>
  `core/patients/${patientGuid}/availabilities`;
const deletePatientContactAvailabilitiesApi = (patientGuid: string) =>
  `${patientContactAvailabilitiesApi(patientGuid)}/delete`;

type Availabilities = Array<Availability>;

type UsePatientContactAvailability = {
  loading: boolean;
  availabilities: Availabilities;
  createAvailabilities: (
    createingAvailabilities: Array<ICreateAvailability>,
  ) => Promise<Availabilities>;
  updateAvailabilities: (
    updatingAvailabilities: Array<IUpdateAvailability>,
  ) => Promise<Availabilities>;
  deleteAvailabilities: (
    delitingAvailabilities: Array<Pick<Availability, 'guid'>>,
  ) => Promise<Availabilities>;
};

interface IUsePatientContactAvailabilityProps {
  patientGuid: string;
}

export const usePatientContactAvailability = (
  props: IUsePatientContactAvailabilityProps,
): UsePatientContactAvailability => {
  const { patientGuid } = props;

  const { loading, loadData, createData, updateData } = useApi();

  const queryClient = useQueryClient();
  const queryKey = ['patientContactAvailability', patientGuid];

  const { data = [], isLoading } = useQuery({
    queryKey,
    queryFn: () =>
      loadData<Array<IAvailability>>(
        patientContactAvailabilitiesApi(patientGuid),
      ).then((res) => {
        if (res) {
          return res.map((el) => new Availability(el));
        }
      }),
    enabled: Boolean(patientGuid),
    staleTime: STALE_TIME,
  });

  const createAvailabilities = async (
    createingAvailabilities: Array<ICreateAvailability>,
  ) => {
    const res = await createData<Array<IAvailability>>(
      patientContactAvailabilitiesApi(patientGuid),
      {
        availabilities: createingAvailabilities,
      },
    );
    const newData = [
      ...data.filter((availability) => availability.action !== 'create'),
      ...res.map((el) => new Availability(el)),
    ];
    queryClient.setQueryData(queryKey, newData);
    return newData;
  };

  const { mutateAsync: updateAvailabilities, isLoading: updateLoading } =
    useMutation({
      mutationKey: queryKey,
      mutationFn: async (
        updatingAvailabilities: Array<IUpdateAvailability>,
      ) => {
        const res = await updateData<Array<IAvailability>>(
          patientContactAvailabilitiesApi(patientGuid),
          {
            availabilities: updatingAvailabilities,
          },
        );

        const newData = res.map((el) => new Availability(el));

        queryClient.setQueryData(queryKey, newData);
        return newData;
      },
    });

  const deleteAvailabilities = async (
    delitingAvailabilities: Array<IDeleteAvailability>,
  ) => {
    await createData(deletePatientContactAvailabilitiesApi(patientGuid), {
      availabilities: delitingAvailabilities,
    });
    const newData = data.filter((availability) =>
      delitingAvailabilities.some((el) => el.guid !== availability.guid),
    );
    queryClient.setQueryData(queryKey, newData);
    return newData;
  };

  return {
    loading: loading || isLoading || updateLoading,
    availabilities: data,
    createAvailabilities,
    updateAvailabilities:
      updateAvailabilities as UsePatientContactAvailability['updateAvailabilities'],
    deleteAvailabilities,
  };
};
