import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import CommentIcon from 'components/atoms/icons/commentIcon';
import CheckIcon from 'components/atoms/icons/doubleCheckIcon';
import common from 'constants/common';
import BaseLoader from 'components/atoms/base-loader';

const CommentItem = ({ comment }) => (
  <div className="activity-comments-item">
    <div className="comment-header">
      <span>
        Electronically Signed By:&nbsp;
        <strong>
          {comment.createdByTitle} {comment.createdByFirstName}{' '}
          {comment.createdByLastName}
          {comment.createdByProfessionType ? ' - ' : ' '}
          {comment.createdByProfessionType}
        </strong>
      </span>
      <div className="comment-header-checked">
        <p>{format(new Date(comment.createdAt), common.dateFormats.full)}</p>
        {comment.reviewed && <CheckIcon />}
      </div>
    </div>
    <div className="comment-body">
      <div>
        <CommentIcon />
      </div>
      <span>{comment.message}</span>
    </div>
    {comment.showLoader && (
      <div className="loader-wrapper loader-overlay">
        <BaseLoader width={24} height={24} loading />
      </div>
    )}
  </div>
);

CommentItem.propTypes = {
  comment: PropTypes.shape().isRequired,
};

export default CommentItem;
