import { Table } from 'reactstrap';

import type { FC } from 'react';

interface Props {
  data: {
    agencies: {
      legalname: string;
    }[];
    ratePerReading?: number | null;
    professionType: string | null;
  };
}

const Profession: FC<Props> = ({ data }) => {
  return (
    <div className="admin-agent-block">
      <span>Profession Info</span>
      <Table>
        <tbody>
          {data.agencies && (
            <tr>
              <td>Agency</td>
              <td className="hight-light">
                {data.agencies.map(({ legalname }) => legalname).join(', ')}
              </td>
            </tr>
          )}

          {data.professionType && (
            <tr>
              <td>Profession</td>
              <td>{data.professionType}</td>
            </tr>
          )}
          {data.ratePerReading && (
            <tr>
              <td>Rate Per Reading</td>
              <td>{data.ratePerReading}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default Profession;
