import { useTranslation } from 'react-i18next';
import { startTelemed } from 'utils';
import { makeid } from 'utils/helpers/makeid';

interface Props {
  sendAppointmentRequest: () => void;
  isRpmPanel: boolean;
  setDischargePopupOpen: (arg: boolean) => void;
  appointmentsFlagActive: boolean;
  status: string;
}

export const useRequestButtons = ({
  sendAppointmentRequest,
  isRpmPanel,
  setDischargePopupOpen,
  appointmentsFlagActive,
  status,
}: Props) => {
  const { t } = useTranslation();

  const requestButtons = [
    {
      id: makeid(5),
      render: true,
      label: appointmentsFlagActive
        ? t('labels.requestAppointment')
        : t('labels.appointmentRequested'),
      handleClick: () => sendAppointmentRequest(),
      disabled: !appointmentsFlagActive,
    },
    {
      id: makeid(5),
      render: isRpmPanel,
      label: status !== 'active' ? 'Discharge Requested' : 'Request Discharge',
      handleClick: () => setDischargePopupOpen(true),
      disabled: status !== 'active',
    },
    {
      id: makeid(5),
      render: true,
      label: t('labels.startTelemed'),
      handleClick: () => startTelemed(),
    },
  ].filter(({ render }) => render);

  return requestButtons;
};
