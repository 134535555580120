import type { IAdherenceDoctor } from 'infrastructure/interfaces';

export class AdherenceDoctor {
  doctorGuid: string;

  firstName: string;

  lastName: string;

  title: string | null;

  mobileNumber: string | null;

  workNumber: string | null;

  professionType: string | null;

  label: string;

  constructor(data: IAdherenceDoctor) {
    this.doctorGuid = data.doctorGuid;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.title = data.title;
    this.mobileNumber = data.mobileNumber;
    this.workNumber = data.workNumber;
    this.professionType = data.professionType;
    this.label = `${data?.title} ${data?.firstName} ${data?.lastName}`;
  }
}
