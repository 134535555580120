import i18next from 'i18next';
import { InsuranceType } from 'infrastructure/enums';

export interface IInsuranceTypeItem {
  id: InsuranceType;
  name: string;
}

export const insuranceTypesDataSource = (): IInsuranceTypeItem[] => {
  return [
    {
      id: InsuranceType.Mmp,
      name: i18next.t('insuranceTypes.mmp'),
    },
    {
      id: InsuranceType.Mmqb,
      name: i18next.t('insuranceTypes.mmqb'),
    },
    {
      id: InsuranceType.SsiMedicaid,
      name: i18next.t('insuranceTypes.ssiMedicaid'),
    },
  ];
};
