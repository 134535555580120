import i18next from 'i18next';

import type { IAdherencePatient } from 'infrastructure/interfaces';

export class AdherencePatient {
  guid: string;

  firstName: string;

  lastName: string;

  middleName: string | null;

  fullName: string;

  loginGuid: string;

  title: string;

  email: string;

  preferredContactMethod: string | null;

  homeNumber: string | null;

  mrn: string | null;

  phone: string;

  birthDate: string;

  language: string;

  languageLabel: string;

  constructor(data: IAdherencePatient) {
    this.guid = data.guid;
    this.loginGuid = data.loginGuid;
    this.fullName = `${data.firstName} ${data.lastName}`;
    this.title = data.title;
    this.middleName = data.middleName;
    this.email = data.email;
    this.preferredContactMethod = data.preferredContactMethod;
    this.homeNumber = data.homeNumber;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.mrn = data.mrn;
    this.phone = data.phone;
    this.birthDate = data.birthDate;
    this.language = data.language;
    this.languageLabel = i18next.exists(`languages.${data.language}`)
      ? // @ts-ignore
        i18next.t(`languages.${data.language}`)
      : data.language ?? '';
  }
}
