import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const EmrPopOut = ({ onSetReadings }) => {
  const onOptions = ['Weekly', 'Monthly'];
  const everyOptions = Array.from({ length: 30 }, (_, i) => {
    const result = {
      label: `${i + 1}`,
      value: i < 9 ? `0${i + 1}` : `${i + 1}`,
    };

    return result;
  });
  const [repeatOn, setRepeatOn] = useState(onOptions[0]);
  const [repeatEvery, setRepeatEvery] = useState('Monday');
  useEffect(() => {
    onSetReadings(`${repeatOn}-${repeatEvery}`);
  }, [repeatOn, repeatEvery]);

  useEffect(() => {
    if (repeatOn === 'Weekly') {
      const days = document.getElementsByClassName('emr-pop-out-every-day');
      for (let i = 0; i < days.length; i++) {
        days[i].className = 'emr-pop-out-every-day';
      }
      const day = document.getElementById(`emr-${repeatEvery}`);
      day.className = 'emr-pop-out-every-day selected';
    }
  }, [repeatEvery]);

  const setDay = (id) => {
    const value = id.split('-')[1];
    setRepeatEvery(value);
  };

  return (
    <div className="emr-pop-out">
      <div className="emr-pop-out-title">
        <span>Custom recurence</span>
      </div>
      <div className="emr-pop-out-on">
        <span>Repeat every</span>
        <select
          value={repeatOn}
          onChange={(e) => {
            setRepeatOn(e.target.value);
            if (e.target.value === 'Monthly') {
              setRepeatEvery('01');
            } else {
              setRepeatEvery('Monday');
            }
          }}
        >
          {onOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="emr-pop-out-every">
        <div>
          <span>Repeat on</span>
        </div>
        {repeatOn === 'Weekly' && (
          <div className="emr-pop-out-every-days">
            <div
              onClick={(e) => {
                setDay(e.target.id);
              }}
              id="emr-Sunday"
              className="emr-pop-out-every-day selected"
            >
              S
            </div>
            <div
              onClick={(e) => {
                setDay(e.target.id);
              }}
              id="emr-Monday"
              className="emr-pop-out-every-day"
            >
              M
            </div>
            <div
              onClick={(e) => {
                setDay(e.target.id);
              }}
              id="emr-Tuesday"
              className="emr-pop-out-every-day"
            >
              T
            </div>
            <div
              onClick={(e) => {
                setDay(e.target.id);
              }}
              id="emr-Wednesday"
              className="emr-pop-out-every-day"
            >
              W
            </div>
            <div
              onClick={(e) => {
                setDay(e.target.id);
              }}
              id="emr-Thursday"
              className="emr-pop-out-every-day"
            >
              T
            </div>
            <div
              onClick={(e) => {
                setDay(e.target.id);
              }}
              id="emr-Friday"
              className="emr-pop-out-every-day"
            >
              F
            </div>
            <div
              onClick={(e) => {
                setDay(e.target.id);
              }}
              id="emr-Saturday"
              className="emr-pop-out-every-day"
            >
              S
            </div>
          </div>
        )}
        {repeatOn === 'Monthly' && (
          <div>
            <select
              value={repeatEvery}
              onChange={(e) => {
                setRepeatEvery(e.target.value);
              }}
            >
              {everyOptions.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

EmrPopOut.propTypes = {
  onSetReadings: PropTypes.func,
};

export default EmrPopOut;
