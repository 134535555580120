import { ThresholdType } from 'infrastructure/enums';

export const AVERAGE_UNITS = {
  [ThresholdType.Systolic]: 'mm Hg',
  [ThresholdType.Diastolic]: 'mm Hg',
  [ThresholdType.Pulse]: 'BPM',
  [ThresholdType.RestingPulse]: 'BPM',
  [ThresholdType.Glucose]: 'mg/dL',
  [ThresholdType.SpO2]: '%',
  [ThresholdType.Weight]: 'lbs',
  [ThresholdType.Temperature]: '°F',
  [ThresholdType.PEF]: 'L/Min',
  [ThresholdType.FEV1]: 'L',
} as const;

export const AVERAGE_HIT_VALUES = {
  [ThresholdType.Systolic]: {
    minRisk: 'minRiskSystolic',
    maxRisk: 'maxRiskSystolic',
    minCritical: 'minCriticalSystolic',
    maxCritical: 'maxCriticalSystolic',
  },
  [ThresholdType.Diastolic]: {
    minRisk: 'minRiskDiastolic',
    maxRisk: 'maxRiskDiastolic',
    minCritical: 'minCriticalDiastolic',
    maxCritical: 'maxCriticalDiastolic',
  },
  [ThresholdType.Pulse]: {
    minRisk: 'minRiskPulse',
    maxRisk: 'maxRiskPulse',
    minCritical: 'minCriticalPulse',
    maxCritical: 'maxCriticalPulse',
  },
  [ThresholdType.Glucose]: {
    minRisk: 'minRiskGlucose',
    maxRisk: 'maxRiskGlucose',
    minCritical: 'minCriticalGlucose',
    maxCritical: 'maxCriticalGlucose',
  },
  [ThresholdType.SpO2]: {
    minRisk: 'riskBloodoxygen',
    maxRisk: '',
    minCritical: 'criticalBloodoxygen',
    maxCritical: '',
  },
  [ThresholdType.Weight]: {
    minRisk: 'minRiskWeight',
    maxRisk: 'maxRiskWeight',
    minCritical: 'minCriticalWeight',
    maxCritical: 'maxCriticalWeight',
  },
  [ThresholdType.Temperature]: {
    minRisk: '',
    maxRisk: '',
    minCritical: '',
    maxCritical: '',
  },
  [ThresholdType.RestingPulse]: {
    minRisk: 'minRiskSleepPulse',
    maxRisk: 'maxRiskSleepPulse',
    minCritical: 'minCriticalSleepPulse',
    maxCritical: 'maxCriticalSleepPulse',
  },
  [ThresholdType.PEF]: {
    minRisk: 'minRiskPef',
    maxRisk: '',
    minCritical: 'minCriticalPef',
    maxCritical: '',
  },
  [ThresholdType.FEV1]: {
    minRisk: '',
    maxRisk: '',
    minCritical: 'minCriticalFev1',
    maxCritical: '',
  },
} as const;
