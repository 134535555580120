import React from 'react';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import DeviceOrderSpecificationForm from 'components/molecules/admin-panel/forms/device-order-specification';

import type { Patient } from 'infrastructure/classes/patient/patient';

interface Props {
  patientGuid: string;
}

const DeviceOrderSpecification: React.FC<Props> = (props) => {
  const { patientGuid } = props;

  const { loading, patientInfo = {} as Patient } = usePatientInfo({
    patientGuid,
  });

  return <DeviceOrderSpecificationForm data={patientInfo} loading={loading} />;
};

export default DeviceOrderSpecification;
