import React from 'react';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

interface IKeyNotesContentProps {
  isDataEmpty: boolean;
  data: any;
  formattedUpdatedAt: string | null;
}

const KeyNotesContent: React.FC<IKeyNotesContentProps> = ({
  isDataEmpty,
  data,
  formattedUpdatedAt,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isDataEmpty ? (
        <div data-cy="patient-key-note-content" className={s['empty-note']}>
          {t('labels.noNotes')}
        </div>
      ) : (
        <>
          <div className={s['updated-by-wrapper']}>
            {data?.updatedBy && (
              <div data-cy="patient-key-note-updated-by">
                {t('labels.electronicallySignedBy')}: <b>{data.updatedBy}</b>
              </div>
            )}
            {formattedUpdatedAt && (
              <div data-cy="patient-key-note-updated-at">
                <b>{formattedUpdatedAt}</b>
              </div>
            )}
          </div>
          <div
            data-cy="patient-key-note-content"
            className={s['raw-html']}
            dangerouslySetInnerHTML={{ __html: data?.note || '' }}
          />
        </>
      )}
    </>
  );
};

export default KeyNotesContent;
