import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import withLoader from 'components/organisms/withLoader';
import { showResult } from 'infrastructure/hooks/utils';
import EditButton from 'components/atoms/editButton';
import { Card, CardBody, Table } from 'reactstrap';
import useAgencyDoctor from 'utils/hooks/adminPanel/useAgencyDoctor';
import useTurkDashboardSettings from 'utils/hooks/turkDashboard/useSettings';

import Info from './components/info';
import Profession from './components/profession';
import Registration from './components/registration';

const TurkUser = () => {
  const navigate = useNavigate();

  const { agentGuid } = useParams<{ agentGuid: string }>();

  const {
    agencyDoctor,
    isLoading: isAgencyDoctorLoading,
    error: getDoctorError,
  } = useAgencyDoctor({
    guid: agentGuid!,
  });
  const { turkConfiguration, isLoading: isSettingsLoading } =
    useTurkDashboardSettings();

  useEffect(() => {
    if (getDoctorError) {
      showResult(String(getDoctorError));
      navigate('/admin/turk-users');
    }
  }, [getDoctorError]);

  const isLoading = isSettingsLoading || isAgencyDoctorLoading;

  return (
    <div className="admin-agent">
      <Card className="card-border">
        <CardBody>
          {withLoader(
            isLoading,
            <>
              {agencyDoctor && (
                <>
                  <div className="admin-agent-header">
                    <span className="admin-header__title">Turk User Page</span>
                    <div className="admin-agent-header-actions">
                      <div className="admin-agent-header-actions-status badge">
                        {agencyDoctor?.status}
                      </div>
                      <div className="admin-agent-header-actions-edit">
                        <Link
                          data-cy="hha-agent__edit-link"
                          to={`/admin/turk-users/${agencyDoctor.guid}/edit`}
                        >
                          <EditButton />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="admin-agent-info">
                    <Table>
                      <tbody>
                        <tr>
                          <td rowSpan={2} className="add-border-top-right">
                            <Info data={agencyDoctor} />
                          </td>
                          <td className="add-border-top">
                            <Profession
                              data={{
                                ...agencyDoctor,
                                ratePerReading:
                                  agencyDoctor.ratePerReading ||
                                  turkConfiguration?.defaultRatePerReading,
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="add-border-top">
                            <Registration data={agencyDoctor} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </>,
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default TurkUser;
