import i18next from 'i18next';
import { formatAuthorName } from 'utils/formatters';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import type {
  IPatientGoalAuthor,
  IPatientGoal,
} from 'infrastructure/interfaces';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

export const columns = (): IBaseTableColumns<IPatientGoal> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'createdAt',
      width: '8%',
      render(date: string) {
        return DateFormatter({
          date,
          format: DateFormats['MM-dd-yyyy h:mm a'],
        });
      },
      dataCy: 'personal-goal-date',
    },
    {
      label: i18next.t('labels.log'),
      key: 'log',
      width: '67%',
      dataCy: 'personal-goal-log',
    },
    {
      label: i18next.t('labels.electronicallySignedBy'),
      key: 'author',
      width: '25%',
      align: 'right',
      render(author: IPatientGoalAuthor) {
        return formatAuthorName({
          title: author.title,
          firstName: author.firstName,
          lastName: author.lastName,
          professionType: author.profession,
        });
      },
      dataCy: 'personal-goal-author',
    },
  ];
};
