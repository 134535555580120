import dataService from 'services/dataService';
import { API_CLINICAL_ESCALATION } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface DataProps {
  contactMethod: string;
  dateCriticalReading: string;
  doctorGuid: string;
  doctorName: string;
  nameOfPersonYouAreCalling: string;
  nurseName: string;
  patientBirthDate: string;
  patientFirstPhone: string;
  patientGuid: string;
  patientName: string;
  patientSecondPhone: string;
  phoneOrEmailOfPersonYouAreCalling: string;
  preferredContactMethod: string;
  signsSymptoms: string;
  valueCriticalReading: string;
}

export const clinicEscalationFn = async (postData: DataProps) => {
  const { error, data } = await dataService.createOne(
    API_CLINICAL_ESCALATION,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
