import React from 'react';
import { ConfigProvider } from 'antd';

import { lightTheme } from './themes/light';

interface IAntThemeProviderProps {
  children: React.ReactNode;
}

const AntThemeProvider: React.FC<IAntThemeProviderProps> = (props) => {
  const { children } = props;
  return (
    <ConfigProvider theme={lightTheme} wave={{ disabled: true }}>
      {children}
    </ConfigProvider>
  );
};

export default AntThemeProvider;
