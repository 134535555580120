import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PatientRow = ({ report }) => {
  const style = (value) => {
    let className = '';
    if (value > 0) {
      className = 'compliance-table-date colored';
    }
    return className;
  };

  return (
    <tr className="activity-row">
      <td data-label="Patient Name" className="compliance-table-name">
        <Link to={`/patient/${report.patient.guid}`}>
          <strong>
            {report.patient.firstName} {report.patient.lastName}
          </strong>
        </Link>
      </td>
      {report.readingsCount.map((reading) => (
        <td
          key={reading.date}
          data-label={reading.date}
          className={style(reading.count)}
        >
          {reading.count}
        </td>
      ))}
      <td data-label="Total" className="compliance-table-total colored">
        {report.total}
      </td>
    </tr>
  );
};

PatientRow.propTypes = {
  report: PropTypes.shape().isRequired,
};

export default PatientRow;
