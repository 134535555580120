import React from 'react';
import PropTypes from 'prop-types';
import common from 'constants/common';
import DescriptionPopup from 'components/atoms/descriptionPopup';

const CcmStatusBlock = ({ status, count, index }) => {
  const titles = common.ccmStatusNames;

  return (
    <div
      className="status-count-block"
      style={{ backgroundColor: count > 0 ? titles[status]?.color : 'white' }}
    >
      <div className="status-count-block__hint">
        {titles[status]?.description && (
          <DescriptionPopup
            msg={titles[status].description}
            right={index === 0}
          />
        )}
      </div>
      <div className="status-count-block-title">{status}</div>
      <div className="status-count-block-count">{count}</div>
    </div>
  );
};

CcmStatusBlock.propTypes = {
  status: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default CcmStatusBlock;
