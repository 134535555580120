import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const ConversationsEmptyIcon = ({
  color = COLORS.BUOYANT,
  ...props
}: IIconProps) => (
  <svg
    width="84"
    height="84"
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M73.125 5.6875C75.99 5.6875 78.3125 8.01002 78.3125 10.875V52.375C78.3125 55.24 75.99 57.5625 73.125 57.5625H23.3987C20.6471 57.5625 18.0082 58.6556 16.0625 60.6013L5.6875 70.9763V10.875C5.6875 8.01002 8.01002 5.6875 10.875 5.6875H73.125ZM10.875 0.5C5.14505 0.5 0.5 5.14505 0.5 10.875V77.2381C0.5 79.5489 3.29383 80.7062 4.9278 79.0722L19.7306 64.2694C20.7035 63.2965 22.0229 62.75 23.3987 62.75H73.125C78.855 62.75 83.5 58.105 83.5 52.375V10.875C83.5 5.14505 78.855 0.5 73.125 0.5H10.875Z"
      fill={color}
    />
  </svg>
);

export default ConversationsEmptyIcon;
