const languages = [
  {
    id: 'en',
    label: 'English',
  },
  {
    id: 'es',
    label: 'Spanish',
  },
  {
    id: 'ab',
    label: 'Abkhaz',
  },
  {
    id: 'aa',
    label: 'Afar',
  },
  {
    id: 'af',
    label: 'Afrikaans',
  },
  {
    id: 'ak',
    label: 'Akan',
  },
  {
    id: 'sq',
    label: 'Albanian',
  },
  {
    id: 'am',
    label: 'Amharic',
  },
  {
    id: 'ar',
    label: 'Arabic',
  },
  {
    id: 'an',
    label: 'Aragonese',
  },
  {
    id: 'hy',
    label: 'Armenian',
  },
  {
    id: 'as',
    label: 'Assamese',
  },
  {
    id: 'av',
    label: 'Avaric',
  },
  {
    id: 'ae',
    label: 'Avestan',
  },
  {
    id: 'ay',
    label: 'Aymara',
  },
  {
    id: 'az',
    label: 'Azerbaijani',
  },
  {
    id: 'bm',
    label: 'Bambara',
  },
  {
    id: 'ba',
    label: 'Bashkir',
  },
  {
    id: 'eu',
    label: 'Basque',
  },
  {
    id: 'be',
    label: 'Belarusian',
  },
  {
    id: 'bn',
    label: 'Bengali',
  },
  {
    id: 'bh',
    label: 'Bihari',
  },
  {
    id: 'bi',
    label: 'Bislama',
  },
  {
    id: 'bs',
    label: 'Bosnian',
  },
  {
    id: 'br',
    label: 'Breton',
  },
  {
    id: 'bg',
    label: 'Bulgarian',
  },
  {
    id: 'my',
    label: 'Burmese',
  },
  {
    id: 'khm',
    label: 'Cambodian',
  },
  {
    id: 'ca',
    label: 'Catalan',
  },
  {
    id: 'ch',
    label: 'Chamorro',
  },
  {
    id: 'ce',
    label: 'Chechen',
  },
  {
    id: 'ny',
    label: 'Chichewa',
  },
  {
    id: 'zh',
    label: 'Chinese',
  },
  {
    id: 'cv',
    label: 'Chuvash',
  },
  {
    id: 'kw',
    label: 'Cornish',
  },
  {
    id: 'co',
    label: 'Corsican',
  },
  {
    id: 'cr',
    label: 'Cree',
  },
  {
    id: 'cpe',
    label: 'Creole',
  },
  {
    id: 'hr',
    label: 'Croatian',
  },
  {
    id: 'cs',
    label: 'Czech',
  },
  {
    id: 'da',
    label: 'Danish',
  },
  {
    id: 'dv',
    label: 'Divehi',
  },
  {
    id: 'nl',
    label: 'Dutch',
  },
  {
    id: 'eo',
    label: 'Esperanto',
  },
  {
    id: 'et',
    label: 'Estonian',
  },
  {
    id: 'ee',
    label: 'Ewe',
  },
  {
    id: 'fo',
    label: 'Faroese',
  },
  {
    id: 'fj',
    label: 'Fijian',
  },
  {
    id: 'fi',
    label: 'Finnish',
  },
  {
    id: 'fr',
    label: 'French',
  },
  {
    id: 'ff',
    label: 'Fula',
  },
  {
    id: 'gl',
    label: 'Galician',
  },
  {
    id: 'ka',
    label: 'Georgian',
  },
  {
    id: 'de',
    label: 'German',
  },
  {
    id: 'el',
    label: 'Greek',
  },
  {
    id: 'gn',
    label: 'Guaraní',
  },
  {
    id: 'gu',
    label: 'Gujarati',
  },
  {
    id: 'ht',
    label: 'Haitian',
  },
  {
    id: 'ha',
    label: 'Hausa',
  },
  {
    id: 'he',
    label: 'Hebrew',
  },
  {
    id: 'iw',
    label: 'Hebrew',
  },
  {
    id: 'hz',
    label: 'Herero',
  },
  {
    id: 'hi',
    label: 'Hindi',
  },
  {
    id: 'ho',
    label: 'Hiri Motu',
  },
  {
    id: 'hu',
    label: 'Hungarian',
  },
  {
    id: 'ia',
    label: 'Interlingua',
  },
  {
    id: 'id',
    label: 'Indonesian',
  },
  {
    id: 'ie',
    label: 'Interlingue',
  },
  {
    id: 'ga',
    label: 'Irish',
  },
  {
    id: 'ig',
    label: 'Igbo',
  },
  {
    id: 'ik',
    label: 'Inupiaq',
  },
  {
    id: 'io',
    label: 'Ido',
  },
  {
    id: 'is',
    label: 'Icelandic',
  },
  {
    id: 'it',
    label: 'Italian',
  },
  {
    id: 'iu',
    label: 'Inuktitut',
  },
  {
    id: 'ja',
    label: 'Japanese',
  },
  {
    id: 'jv',
    label: 'Javanese',
  },
  {
    id: 'kl',
    label: 'Kalaallisut',
  },
  {
    id: 'kn',
    label: 'Kannada',
  },
  {
    id: 'kr',
    label: 'Kanuri',
  },
  {
    id: 'ks',
    label: 'Kashmiri',
  },
  {
    id: 'kk',
    label: 'Kazakh',
  },
  {
    id: 'km',
    label: 'Khmer',
  },
  {
    id: 'ki',
    label: 'Kikuyu',
  },
  {
    id: 'rw',
    label: 'Kinyarwanda',
  },
  {
    id: 'ky',
    label: 'Kyrgyz',
  },
  {
    id: 'kv',
    label: 'Komi',
  },
  {
    id: 'kg',
    label: 'Kongo',
  },
  {
    id: 'ko',
    label: 'Korean',
  },
  {
    id: 'ku',
    label: 'Kurdish',
  },
  {
    id: 'kj',
    label: 'Kwanyama',
  },
  {
    id: 'la',
    label: 'Latin',
  },
  {
    id: 'lb',
    label: 'Luxembourgish',
  },
  {
    id: 'lg',
    label: 'Luganda',
  },
  {
    id: 'li',
    label: 'Limburgish',
  },
  {
    id: 'ln',
    label: 'Lingala',
  },
  {
    id: 'lo',
    label: 'Lao',
  },
  {
    id: 'lt',
    label: 'Lithuanian',
  },
  {
    id: 'lu',
    label: 'Luba-Katanga',
  },
  {
    id: 'lv',
    label: 'Latvian',
  },
  {
    id: 'gv',
    label: 'Manx',
  },
  {
    id: 'mk',
    label: 'Macedonian',
  },
  {
    id: 'mg',
    label: 'Malagasy',
  },
  {
    id: 'ms',
    label: 'Malay',
  },
  {
    id: 'ml',
    label: 'Malayalam',
  },
  {
    id: 'mt',
    label: 'Maltese',
  },
  {
    id: 'mi',
    label: 'Māori',
  },
  {
    id: 'mr',
    label: 'Marathi (Marāṭhī)',
  },
  {
    id: 'mh',
    label: 'Marshallese',
  },
  {
    id: 'mn',
    label: 'Mongolian',
  },
  {
    id: 'na',
    label: 'Nauru',
  },
  {
    id: 'nv',
    label: 'Navajo',
  },
  {
    id: 'nb',
    label: 'Norwegian Bokmål',
  },
  {
    id: 'nd',
    label: 'North Ndebele',
  },
  {
    id: 'ne',
    label: 'Nepali',
  },
  {
    id: 'ng',
    label: 'Ndonga',
  },
  {
    id: 'nn',
    label: 'Norwegian Nynorsk',
  },
  {
    id: 'no',
    label: 'Norwegian',
  },
  {
    id: 'ii',
    label: 'Nuosu',
  },
  {
    id: 'nr',
    label: 'South Ndebele',
  },
  {
    id: 'oc',
    label: 'Occitan',
  },
  {
    id: 'oj',
    label: 'Ojibwe',
  },
  {
    id: 'cu',
    label: 'Old Church Slavonic',
  },
  {
    id: 'om',
    label: 'Oromo',
  },
  {
    id: 'or',
    label: 'Oriya',
  },
  {
    id: 'os',
    label: 'Ossetian',
  },
  {
    id: 'pa',
    label: 'Panjabi',
  },
  {
    id: 'pi',
    label: 'Pāli',
  },
  {
    id: 'fa',
    label: 'Persian',
  },
  {
    id: 'pl',
    label: 'Polish',
  },
  {
    id: 'ps',
    label: 'Pashto',
  },
  {
    id: 'pt',
    label: 'Portuguese',
  },
  {
    id: 'qu',
    label: 'Quechua',
  },
  {
    id: 'rm',
    label: 'Romansh',
  },
  {
    id: 'rn',
    label: 'Kirundi',
  },
  {
    id: 'ro',
    label: 'Romanian',
  },
  {
    id: 'ru',
    label: 'Russian',
  },
  {
    id: 'sa',
    label: 'Sanskrit (Saṁskṛta)',
  },
  {
    id: 'sc',
    label: 'Sardinian',
  },
  {
    id: 'sd',
    label: 'Sindhi',
  },
  {
    id: 'se',
    label: 'Northern Sami',
  },
  {
    id: 'sm',
    label: 'Samoan',
  },
  {
    id: 'sg',
    label: 'Sango',
  },
  {
    id: 'sr',
    label: 'Serbian',
  },
  {
    id: 'gd',
    label: 'Scottish Gaelic',
  },
  {
    id: 'sn',
    label: 'Shona',
  },
  {
    id: 'si',
    label: 'Sinhala',
  },
  {
    id: 'sk',
    label: 'Slovak',
  },
  {
    id: 'sl',
    label: 'Slovene',
  },
  {
    id: 'so',
    label: 'Somali',
  },
  {
    id: 'st',
    label: 'Southern Sotho',
  },
  {
    id: 'su',
    label: 'Sundanese',
  },
  {
    id: 'sw',
    label: 'Swahili',
  },
  {
    id: 'ss',
    label: 'Swati',
  },
  {
    id: 'sv',
    label: 'Swedish',
  },
  {
    id: 'ta',
    label: 'Tamil',
  },
  {
    id: 'te',
    label: 'Telugu',
  },
  {
    id: 'tg',
    label: 'Tajik',
  },
  {
    id: 'th',
    label: 'Thai',
  },
  {
    id: 'ti',
    label: 'Tigrinya',
  },
  {
    id: 'bo',
    label: 'Tibetan Standard',
  },
  {
    id: 'tk',
    label: 'Turkmen',
  },
  {
    id: 'tl',
    label: 'Tagalog',
  },
  {
    id: 'tn',
    label: 'Tswana',
  },
  {
    id: 'to',
    label: 'Tonga (Tonga Islands)',
  },
  {
    id: 'tr',
    label: 'Turkish',
  },
  {
    id: 'ts',
    label: 'Tsonga',
  },
  {
    id: 'tt',
    label: 'Tatar',
  },
  {
    id: 'tw',
    label: 'Twi',
  },
  {
    id: 'ty',
    label: 'Tahitian',
  },
  {
    id: 'ug',
    label: 'Uighur',
  },
  {
    id: 'uk',
    label: 'Ukrainian',
  },
  {
    id: 'ur',
    label: 'Urdu',
  },
  {
    id: 'uz',
    label: 'Uzbek',
  },
  {
    id: 've',
    label: 'Venda',
  },
  {
    id: 'vi',
    label: 'Vietnamese',
  },
  {
    id: 'vo',
    label: 'Volapük',
  },
  {
    id: 'wa',
    label: 'Walloon',
  },
  {
    id: 'cy',
    label: 'Welsh',
  },
  {
    id: 'wo',
    label: 'Wolof',
  },
  {
    id: 'fy',
    label: 'Western Frisian',
  },
  {
    id: 'xh',
    label: 'Xhosa',
  },
  {
    id: 'yi',
    label: 'Yiddish',
  },
  {
    id: 'yo',
    label: 'Yoruba',
  },
  {
    id: 'za',
    label: 'Zhuang',
  },
] as const;

export const languagesMap = languages.reduce<Record<string, string>>(
  (previousValue, currentValue) => {
    previousValue[currentValue.id] = currentValue.label;
    return previousValue;
  },
  {},
);

export default languages;
