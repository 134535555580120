import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_QUESTIONS } from 'services/dataService/ccmResources';
import { reverseQuestionnaireAnswers } from 'infrastructure/functions';

import { getQuestionsKey } from './use-get-questions';
import { showResult } from '../utils';

import type { IQuestionGroup, PutAnswersData } from 'infrastructure/interfaces';

interface IResponse {
  groups: IQuestionGroup[];
  score: number;
}

const sendQuestionsFn = async (putData: PutAnswersData) => {
  const { data, error } = await dataService.updateOnly<IResponse>(
    API_QUESTIONS,
    putData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useSendQuestions = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: sendQuestionsFn,
    onSuccess(data, variables) {
      const questionsKey = getQuestionsKey(variables.patientGuid);
      queryClient.setQueryData(questionsKey, reverseQuestionnaireAnswers(data));
    },
  });

  return {
    sendAnswers: mutate,
    sendLoading: isLoading,
    ...other,
  };
};
