import * as yup from 'yup';

const decimalWithMaxTwoDigitsAfterComma = yup
  .number()
  .test(
    'decimal-with-max-two-digits-after-comma',
    'Can have max 2 digits after comma',
    (value: any) => {
      if (value === undefined || value === null || value === '') {
        return true;
      }
      return Number(parseFloat(value).toFixed(2)) === value;
    },
  )
  .required('Required')
  .typeError('Must be number');

export const schema = yup.object().shape({
  defaultRatePerReading: decimalWithMaxTwoDigitsAfterComma,
  maximumDailyReadings: yup.number().required('required'),
  maximumReadingsPerBatch: yup.number().required('required'),
  minimumReadingBatchRefreshRate: decimalWithMaxTwoDigitsAfterComma,
  maximumReadingReviewResponseTime: decimalWithMaxTwoDigitsAfterComma,
  maximumAllowableIdleTime: decimalWithMaxTwoDigitsAfterComma,
});
