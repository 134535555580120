import React from 'react';

import type { SVGProps } from 'react';

const ControlPanelTelephoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.3422 4.62403C10.3744 -0.473549 17.1207 -1.50509 21.4309 2.27339C25.7411 6.05187 25.9701 16.4803 21.4309 21.0786C16.8916 25.6768 6.42952 24.2016 2.86701 21.0786C-0.695501 17.9555 0.310022 9.72162 5.3422 4.62403Z"
      fill="#FB9318"
    />
    <path
      d="M9.4904 6.99637C9.30257 6.75487 8.94559 6.73265 8.72925 6.94898L7.95381 7.72442C7.5912 8.08703 7.4579 8.60074 7.61633 9.05135C8.25253 10.8609 9.29411 12.56 10.742 14.008C12.19 15.4559 13.8891 16.4975 15.6986 17.1337C16.1493 17.2921 16.663 17.1588 17.0256 16.7962L17.801 16.0207C18.0174 15.8044 17.9951 15.4474 17.7536 15.2596L16.024 13.9143C15.9006 13.8184 15.74 13.7845 15.5884 13.8224L13.9466 14.2328C13.5006 14.3443 13.0289 14.2136 12.7038 13.8886L10.8614 12.0462C10.5364 11.7211 10.4057 11.2494 10.5172 10.8034L10.9276 9.16165C10.9655 9.01002 10.9316 8.84938 10.8357 8.72601L9.4904 6.99637ZM8.16358 6.38331C8.72017 5.82672 9.63862 5.8839 10.1219 6.50523L11.4672 8.23487C11.714 8.55228 11.8013 8.96556 11.7037 9.35567L11.2933 10.9975C11.25 11.1708 11.3007 11.3542 11.4271 11.4805L13.2695 13.3229C13.3958 13.4493 13.5792 13.5 13.7525 13.4567L15.3943 13.0463C15.7844 12.9487 16.1977 13.036 16.5151 13.2829L18.2448 14.6281C18.8661 15.1114 18.9233 16.0298 18.3667 16.5864L17.5913 17.3619C17.0365 17.9167 16.2067 18.1603 15.4333 17.8884C13.5132 17.2133 11.7108 16.1081 10.1764 14.5736C8.64195 13.0392 7.53671 11.2368 6.86163 9.31669C6.58974 8.54334 6.83335 7.71354 7.38814 7.15875L8.16358 6.38331Z"
      fill="white"
    />
  </svg>
);

export default ControlPanelTelephoneIcon;
