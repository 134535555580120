import React from 'react';

const AverageIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H5.25C6.49264 1.5 7.5 2.50736 7.5 3.75C8.37408 3.94769 10.235 4.52445 10.6861 5.25C10.9548 5.51911 11.1969 5.82207 11.4121 6.15693C12.3688 7.64513 12.75 9.68916 12.75 12C12.75 14.9896 13.1353 16.6387 13.8187 17.55C14.3538 18.2634 15.1622 18.6319 16.5001 18.7257C16.5132 17.4942 17.5155 16.5 18.75 16.5H20.25C21.4926 16.5 22.5 17.5074 22.5 18.75V20.25C22.5 21.4926 21.4926 22.5 20.25 22.5H18.75C17.5074 22.5 16.5 21.4926 16.5 20.25C15.3 20.25 13.5 19.5 12.8658 18.75C12.7804 18.6556 12.698 18.5556 12.6187 18.45C11.6147 17.1113 11.25 15.0104 11.25 12C11.25 9.81084 10.8812 8.10487 10.1504 6.96807C9.56636 6.05961 8.72223 5.46669 7.49948 5.29887C7.47348 6.51895 6.47631 7.5 5.25 7.5H3.75C2.50736 7.5 1.5 6.49264 1.5 5.25V3.75ZM3.75 3C3.33579 3 3 3.33579 3 3.75V5.25C3 5.66421 3.33579 6 3.75 6H5.25C5.66421 6 6 5.66421 6 5.25V3.75C6 3.33579 5.66421 3 5.25 3H3.75ZM18.75 18C18.3358 18 18 18.3358 18 18.75V20.25C18 20.6642 18.3358 21 18.75 21H20.25C20.6642 21 21 20.6642 21 20.25V18.75C21 18.3358 20.6642 18 20.25 18H18.75Z"
      fill="currentColor"
    />
    <line
      x1="0.75"
      y1="11.25"
      x2="23.25"
      y2="11.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeDasharray="3 3"
    />
  </svg>
);

export default AverageIcon;
