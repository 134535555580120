import i18next from 'i18next';
import * as Yup from 'yup';

import type { ObjectShape } from 'yup/lib/object';

interface ICommonValidationProps {
  fieldName?: string;
  message?: string;
  required?:
    | boolean
    | {
        message?: string;
      };
  when?: {
    fields: Array<string>;
    is: (...values: Array<any>) => boolean;
    then?: Omit<ICommonValidationProps, 'when'>;
    otherwise?: Omit<ICommonValidationProps, 'when'>;
  };
  matches?: {
    regexp: RegExp;
    message?: string;
  };
  moreThan?: {
    field: string;
    fieldName?: string;
    message?: string;
  };
  lessThan?: {
    field: string;
    fieldName?: string;
    message?: string;
  };
  nullable?: boolean;
  min?: number;
  max?: number;
}

interface IStringValidationProps extends ICommonValidationProps {
  uuid?: boolean;
}

interface INumberValidationProps extends ICommonValidationProps {
  isInteger?: boolean;
}

export const createValidationSchema = <T extends ObjectShape>(
  validationSchema: T,
  excludes?: Array<[string, string]>,
) => {
  return Yup.object().shape(validationSchema, excludes);
};

const getProps = (props?: ICommonValidationProps) => {
  const {
    fieldName = i18next.t('validation.field'),
    min = 0,
    ...args
  } = props ?? {};

  return {
    fieldName,
    min,
    ...args,
  };
};

const getRangeProps = <TSchema extends Yup.NumberSchema<any>>(
  providedSchema: TSchema,
  props?: ICommonValidationProps,
): TSchema => {
  const { message, ...args } = getProps(props);

  let schema = providedSchema;

  if ('min' in args) {
    schema = schema.min(
      args.min,
      message ?? `${i18next.t('validation.mustBeMoreThan')} ${args.min}`,
    );
  }
  if ('max' in args) {
    schema = schema.max(
      Number(args.max),
      message ?? `${i18next.t('validation.mustBeLessThan')} ${args.max}`,
    );
  }

  return schema;
};

const getWhenRangeProps = <TSchema extends Yup.NumberSchema<any>>(
  providedSchema: TSchema,
  props?: ICommonValidationProps,
): TSchema => {
  const { moreThan, lessThan } = getProps(props);

  let schema = providedSchema;

  if (moreThan) {
    const fieldName = moreThan.fieldName ?? moreThan.field;
    const message =
      moreThan.message ??
      `${i18next.t('validation.mustBeMoreThan')} ${fieldName}`;
    schema = schema.moreThan(Yup.ref(moreThan.field), message);
  }
  if (lessThan) {
    const fieldName = lessThan.fieldName ?? lessThan.field;
    const message =
      lessThan.message ??
      `${i18next.t('validation.mustBeLessThan')} ${fieldName}`;
    schema = schema.lessThan(Yup.ref(lessThan.field), message);
  }

  return schema;
};

const getRequiredProps = <TSchema extends Yup.BaseSchema>(
  providedSchema: TSchema,
  props?: ICommonValidationProps,
): TSchema => {
  const { fieldName, min, required, nullable } = getProps(props);

  let schema = providedSchema;
  if (required) {
    const message =
      typeof required !== 'boolean'
        ? required.message
        : `${i18next.t('validation.required')} ${fieldName}`;

    schema = schema
      .required(message)
      .test('is-not-only-spaces', message, (value: any) => {
        let isValid = !!value;
        if (Array.isArray(value)) {
          isValid = value.length >= min;
        }
        if (typeof value === 'string') {
          isValid = value.trim().length > 0;
        }
        if (typeof value === 'number') {
          isValid = Number.isInteger(value);
        }

        return isValid;
      });
  }
  if (nullable) {
    schema = schema.nullable();
  }
  return schema;
};

const getMatchesProps = <TSchema extends Yup.StringSchema<any>>(
  providedSchema: TSchema,
  props?: ICommonValidationProps,
) => {
  const { fieldName, matches } = getProps(props);

  let schema = providedSchema;
  if (matches) {
    const {
      regexp,
      message = `${fieldName} ${i18next.t('validation.notValid')}`,
    } = matches;
    schema = schema.matches(regexp, message);
  }
  return schema;
};

const getWhenProps = <TSchema extends Yup.BaseSchema>(
  providedSchema: TSchema,
  props?: ICommonValidationProps,
): TSchema => {
  const { when } = getProps(props);

  let schema = providedSchema;
  if (when) {
    const { fields, is, then, otherwise } = when;
    schema = schema.when(fields, {
      is,
      then: (currentSchema) => {
        const localProps = getProps(then);
        let localSchema = currentSchema;
        localSchema = getRequiredProps(localSchema, localProps);
        if (localSchema instanceof Yup.StringSchema) {
          localSchema = getMatchesProps(localSchema, localProps);
        }
        return Object.assign(currentSchema, localSchema);
      },
      otherwise: (currentSchema) => {
        const localProps = getProps(otherwise);
        let localSchema = currentSchema;
        localSchema = getRequiredProps(localSchema, localProps);
        if (localSchema instanceof Yup.StringSchema) {
          localSchema = getMatchesProps(localSchema, localProps);
        }
        return Object.assign(currentSchema, localSchema);
      },
    });
  }
  return schema;
};

export const commonValidationSchemas = {
  date: (props?: ICommonValidationProps) => {
    let schema = Yup.date();
    schema = getRequiredProps(schema, props);
    return schema;
  },
  string: (props?: IStringValidationProps) => {
    let schema = Yup.string().trim().nullable();

    if (props?.uuid) {
      schema = schema.uuid();
    }

    schema = getRequiredProps(schema, props);
    schema = getMatchesProps(schema, props);
    schema = getWhenProps(schema, props);
    return schema;
  },
  email: (props?: ICommonValidationProps) => {
    const { message } = getProps(props);

    let schema = Yup.string()
      .email(message ?? `${i18next.t('validation.notValidEmail')}`)
      .trim();

    schema = getRequiredProps(schema, props);
    schema = getWhenProps(schema, props);
    return schema;
  },
  number: (props?: INumberValidationProps) => {
    let schema = Yup.number()
      .nullable()
      .transform((value) => (Number.isNaN(value) ? null : value));

    if (props?.isInteger) {
      schema = schema.integer();
    }

    schema = getRequiredProps(schema, props);
    schema = getWhenProps(schema, props);
    schema = getRangeProps(schema, props);
    schema = getWhenRangeProps(schema, props);
    return schema;
  },
  boolean: (props?: ICommonValidationProps) => {
    let schema = Yup.boolean();

    schema = getRequiredProps(schema, props);

    return schema;
  },
  array: <T extends Yup.AnySchema>(of: T, props?: ICommonValidationProps) => {
    let schema = Yup.array().of(of);
    schema = getRequiredProps(schema, props);
    return schema;
  },
};
export type TSchema<T extends Yup.AnyObjectSchema> = Yup.InferType<T>;
