import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const clinicSettingsValidationSchema = () =>
  createValidationSchema({
    status: commonValidationSchemas.string(),
    showOnInteractiveTimeDashboard: commonValidationSchemas.boolean(),
    acceptPvrLite: commonValidationSchemas.boolean(),
    adherenceSmsSubscription: commonValidationSchemas.boolean(),
    doNotCall: commonValidationSchemas.boolean(),
  });
