import { useQueryClient } from '@tanstack/react-query';

import { getPatientInfoKey } from './use-patient-info';

import type { RpmStatuses } from 'infrastructure/enums';
import type { IPatient } from 'infrastructure/interfaces';

interface ISocketData {
  status: RpmStatuses;
}

interface Props {
  patientGuid: string;
}

export const useQueryUpdatePatientStatus = ({ patientGuid }: Props) => {
  const queryClient = useQueryClient();

  const updateQueryPatientStatus = (socketData: ISocketData) => {
    const queryKey = getPatientInfoKey(patientGuid);

    queryClient.setQueryData<IPatient>(
      queryKey,
      (oldData) =>
        oldData && {
          ...oldData,
          status: socketData.status,
        },
    );
  };

  return { updateQueryPatientStatus };
};
