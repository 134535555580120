import dataService from 'services/dataService';
import { API_PATIENT_STATUS } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

import type { RpmStatuses } from 'infrastructure/enums';

interface DataProps {
  patientGuid: string;
  status: RpmStatuses;
}

export const updatePatientStatusFn = async (postData: DataProps) => {
  const { error, data } = await dataService.updateOnly(
    API_PATIENT_STATUS,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
