import classNames from 'classnames';
import { formatAuthorName } from 'utils/formatters';
import { DateFormatter } from 'infrastructure/functions';
import BaseTableOptionsDropdown from 'components/atoms/base-table-option-dropdown';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useState, type FC } from 'react';
import BaseButton from 'components/atoms/baseButton';
import ChevronLeftIcon from 'components/atoms/icons/chevronLeft';
import { parse } from 'date-fns';
import { isMobile } from 'utils/deviceHelper';
import useUser from 'utils/useUser';

import s from './styles.module.scss';

import type {
  IMedicationForUpdate,
  IMedication,
} from 'infrastructure/interfaces';

interface MedicationItemProps {
  item: IMedication;
  disableActions: boolean;
  onDelete: (guid: string) => void;
  onEdit: (item: IMedicationForUpdate) => void;
  loading: boolean;
  modifyItemGuid?: string;
}

const MedicationItem: FC<MedicationItemProps> = ({
  item,
  disableActions,
  loading,
  modifyItemGuid,
  onDelete,
  onEdit,
}) => {
  const [expand, setExpand] = useState(false);
  const { t } = useTranslation();
  const { deviceType } = useUser();

  const { firstName, lastName, title } = item.updatedBy ?? item.author;
  const authorName = formatAuthorName({
    firstName,
    lastName,
    professionType: '',
    title,
  });
  const lastEditDate = DateFormatter({ date: item.updatedAt });

  const toggleExpand = () => setExpand((prev) => !prev);

  const dropdownInfo = (
    <p className={s['dropdown-info']}>
      <span>
        {t('labels.lastEditedBy')}: {authorName},
      </span>
      <span>{lastEditDate}</span>
    </p>
  );

  const handleEdit = () => {
    onEdit({
      dosage: item.dosage,
      duration: item.duration,
      endDate: item.endDate ? dayjs(item.endDate).toDate() : undefined,
      startDate: item.startDate ? dayjs(item.startDate).toDate() : undefined,
      frequency: item.frequency ?? '',
      name: item.name,
      isActive: item.isActive,
      note: item.note ?? '',
      route: item.route ?? '',
      guid: item.guid,
    });
  };

  const transformDate = (date: string | null) =>
    date ? DateFormatter({ date: parse(date, 'MMM dd yyyy', new Date()) }) : '';
  return (
    <div className={s.wrapper} data-cy="medication-item">
      <div className={s.header}>
        <div className={s['header-left']} data-cy="medication-name">
          <h5 className={s.title}>{item.name}</h5>
        </div>
        <div className={s['header-right']}>
          <p>Status:</p>
          <p
            className={classNames({ [s.active]: item.isActive })}
            data-cy="medication-status"
          >
            {item.isActive ? 'Active' : 'Inactive'}
          </p>
          <BaseButton
            dataCy="medication-dropdown"
            className={s.btn}
            type="secondary"
            onClick={toggleExpand}
            // eslint-disable-next-line prettier/prettier
            icon={(
              <ChevronLeftIcon
                className={classNames(s.icon, { [s.rotate]: expand })}
              />
              // eslint-disable-next-line prettier/prettier
            )}
          />
        </div>
      </div>
      <div
        className={classNames(s.body, { [s.hide]: expand })}
        data-cy="medication-description"
      >
        <div className={s['body-left']}>
          <div className={s['body-left-row']}>
            <p>Route</p>
            <p data-cy="medication-description-route">{item.route ?? ''}</p>
          </div>
          <div className={s['body-left-row']}>
            <p>Duration</p>
            <p data-cy="medication-description-duration">{item.duration}</p>
          </div>
          <div className={s['body-left-row']}>
            <p>Dose</p>
            <p data-cy="medication-description-dosage">{item.dosage}</p>
          </div>
          <div className={s['body-left-row']}>
            <p>Start Date</p>
            <p data-cy="medication-description-startDate">
              {transformDate(item.startDate)}
            </p>
          </div>
          <div className={s['body-left-row']}>
            <p>Frequency</p>
            <p data-cy="medication-description-frequency">{item.frequency}</p>
          </div>
          <div className={s['body-left-row']}>
            <p>End Date</p>
            <p data-cy="medication-description-endDate">
              {transformDate(item.endDate)}
            </p>
          </div>
        </div>
        <div className={s['vertical-divider']} />
        <div className={s['body-right']}>
          <p className={s['body-right-title']}>Note</p>
          <p
            className={s['body-right-note']}
            data-cy="medication-description-note"
          >
            {item.note}
          </p>
        </div>
        <div className={s['vertical-divider']} />
        <div
          className={s['body-right-action']}
          data-cy="medication-dropdown-options"
        >
          <BaseTableOptionsDropdown
            loading={loading && item.guid === modifyItemGuid}
            onDelete={() => {
              onDelete(item.guid);
            }}
            onEdit={handleEdit}
            disabled={disableActions}
            info={dropdownInfo}
            bordered={isMobile(deviceType)}
          />
        </div>
      </div>
    </div>
  );
};
export default MedicationItem;
