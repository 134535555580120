import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseTag from 'components/atoms/base-tag';
import BaseForm from 'components/atoms/base-form';
import { useFormik } from 'formik';
import BatteriesConsumablesSelectBox from 'components/molecules/batteries-consumables-select-box';
import GlucometerConsumablesSelectBox from 'components/molecules/glucometer-consumables-select-box';
import ChargerConsumablesSelectBox from 'components/molecules/charger-consumables-select-box';
import BloodPressureConsumablesSelectBox from 'components/molecules/blood-pressure-consumables-select-box';
import BaseFormItem from 'components/atoms/base-form/item';
import { usePatientDevices } from 'infrastructure/hooks/device/use-patient-devices';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';

import s from './styles.module.scss';

import type { IOrder } from 'infrastructure/interfaces';
import type { PatientDevice } from 'infrastructure/classes/patient/patient-device';

interface IProps {
  patientGuid: string;
  data: PatientDevice;
  onCancel?: () => void;
  onSaved?: () => void;
}

const DeviceConsumableForm: React.FC<IProps> = (props) => {
  const { patientGuid, data, onCancel, onSaved } = props;

  const { loading, orderConsumable } = usePatientDevices({
    patientGuid,
    initialLoading: false,
  });

  const {
    isBloodGlucoseType,
    isWeightType,
    isSPO2Type,
    isBloodPressureType,
    isPeakFlowType,
    isSleepMatType,
    isTemperatureType,
  } = data;

  const readingType = data.readingTypes[0];

  const glucometerView = isBloodGlucoseType;
  const chargerView =
    isBloodGlucoseType ||
    isBloodPressureType ||
    isTemperatureType ||
    isWeightType ||
    isSleepMatType;
  const bloodPressureView = isBloodPressureType;
  const batteriesView = isBloodPressureType || isSPO2Type || isPeakFlowType;

  const { t } = useTranslation();

  const formik = useFormik<{
    device_section: string | null;
    charger: string | null;
    blood_pressure: string | null;
    batteries: string | null;
  }>({
    initialValues: {
      device_section: null,
      charger: null,
      blood_pressure: null,
      batteries: null,
    },
    onSubmit: async (values) => {
      const orders: IOrder[] = (
        Object.keys(values) as Array<keyof typeof values>
      )
        .map((key) => {
          return {
            name: key,
            type: values[key],
          };
        })
        .filter((el) => el.type) as IOrder[];

      await orderConsumable({
        patientGuid,
        orders,
      }).then(() => {
        if (onSaved) onSaved();
      });
    },
    validateOnChange: false,
  });

  const { submitForm } = formik;

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseForm
        loading={loading}
        formik={formik}
        plaintext={false}
        readonly={false}
        onSave={submitForm}
        onCancel={onCancel}
        withControls
        confirmLabel={t('controls.send')}
      >
        <div className={s.info} data-cy="device-info">
          <div className={s.row}>
            <span className={s.title} data-cy="device-info-title">
              {data.manufacturer}
            </span>
            <span className={s.subtitle} data-cy="device-info-id">
              {t('labels.id')}: {data.deviceId}
            </span>
          </div>
          <div className={s.row}>
            <span className={s.subtitle} data-cy="device-info-readingType">
              {t('labels.readingType')}: {data.readingTypes.join(', ')}
            </span>
            <BaseTag
              dataCy="device-info-gen"
              label={`${t('labels.gen')}-${data.generation}`}
            />
          </div>
        </div>
        {glucometerView && (
          <BaseFormItem
            name="device_section"
            label={t('labels.glucometerConsumables')}
          >
            <GlucometerConsumablesSelectBox allowClear />
          </BaseFormItem>
        )}
        {chargerView && (
          <BaseFormItem name="charger" label={t('labels.charger')}>
            <ChargerConsumablesSelectBox allowClear readingType={readingType} />
          </BaseFormItem>
        )}
        {bloodPressureView && (
          <BaseFormItem name="blood_pressure" label={t('labels.pressureCuffs')}>
            <BloodPressureConsumablesSelectBox allowClear />
          </BaseFormItem>
        )}
        {batteriesView && (
          <BaseFormItem name="batteries" label={t('labels.batteries')}>
            <BatteriesConsumablesSelectBox
              allowClear
              readingType={readingType}
            />
          </BaseFormItem>
        )}
      </BaseForm>
    </BaseSpinWrapper>
  );
};

export default DeviceConsumableForm;
