import React from 'react';
import Icon from '@ant-design/icons';

import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ChatLeftTextSVG = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 1.25C18.1904 1.25 18.75 1.80964 18.75 2.5V12.5C18.75 13.1904 18.1904 13.75 17.5 13.75H5.51777C4.85472 13.75 4.21884 14.0134 3.75 14.4822L1.25 16.9822V2.5C1.25 1.80964 1.80964 1.25 2.5 1.25H17.5ZM2.5 0C1.11929 0 0 1.11929 0 2.5V18.4911C0 19.0479 0.673213 19.3268 1.06694 18.9331L4.63388 15.3661C4.8683 15.1317 5.18625 15 5.51777 15H17.5C18.8807 15 20 13.8807 20 12.5V2.5C20 1.11929 18.8807 0 17.5 0H2.5Z"
        fill="currentColor"
      />
      <path
        d="M6.25 7.5C6.25 8.19036 5.69036 8.75 5 8.75C4.30964 8.75 3.75 8.19036 3.75 7.5C3.75 6.80964 4.30964 6.25 5 6.25C5.69036 6.25 6.25 6.80964 6.25 7.5Z"
        fill="currentColor"
      />
      <path
        d="M11.25 7.5C11.25 8.19036 10.6904 8.75 10 8.75C9.30964 8.75 8.75 8.19036 8.75 7.5C8.75 6.80964 9.30964 6.25 10 6.25C10.6904 6.25 11.25 6.80964 11.25 7.5Z"
        fill="currentColor"
      />
      <path
        d="M16.25 7.5C16.25 8.19036 15.6904 8.75 15 8.75C14.3096 8.75 13.75 8.19036 13.75 7.5C13.75 6.80964 14.3096 6.25 15 6.25C15.6904 6.25 16.25 6.80964 16.25 7.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

const ChatLeftTextIcon: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => {
  return <Icon component={ChatLeftTextSVG} {...props} />;
};

export default ChatLeftTextIcon;
