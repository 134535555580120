import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import PlusIcon from 'components/atoms/icons/plus';
import RemoveIcon from 'components/atoms/icons/remove';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import FormInput from './FormInput';

const FormGroup = ({
  id,
  label,
  items,
  appendFields,
  emptyMessage,
  values,
  errors,
}) => {
  const inputs = Object.values(items);
  if (!values || !items) {
    return null;
  }
  return (
    <div id={id}>
      <FieldArray
        validateOnChange
        name={id}
        render={(helpers) => (
          <div className="input-group-wrapper">
            <span className="input-group-wrapper-label">{label}</span>
            {isEmpty(values[helpers.name]) && (
              <div className="input-group-empty-message">
                {emptyMessage || 'No data'}
              </div>
            )}
            {values[helpers.name].map((_v, index) => (
              <div key={index}>
                {!isEmpty(appendFields) && (
                  <div className="input-group-actions">
                    <button
                      className="input-group-button"
                      type="button"
                      title="Remove"
                      onClick={() => helpers.remove(index)}
                    >
                      <RemoveIcon color="#fb9318" />
                    </button>
                  </div>
                )}
                {inputs.map((input) => {
                  const error = get(errors, `${index}.${input.id}`);
                  const inputId = `${id}.${index}.${input.id}`;
                  return (
                    <FormInput
                      key={inputId}
                      error={error}
                      type={input.type}
                      params={{
                        id: inputId,
                        label: input.label,
                        required: input.required,
                        options: input.options,
                      }}
                    />
                  );
                })}
                <div className="input-line" />
              </div>
            ))}
            {!isEmpty(appendFields) && (
              <div className="input-group-actions">
                <button
                  type="button"
                  className="input-group-button"
                  title="append"
                  onClick={() => helpers.push(appendFields)}
                >
                  <PlusIcon />
                </button>
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

FormGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.shape({}),
  appendFields: PropTypes.shape({}),
  values: PropTypes.shape({}),
  errors: PropTypes.arrayOf(PropTypes.shape({})),
  emptyMessage: PropTypes.string,
};
FormGroup.defaultProps = {
  values: [],
  errors: [],
};

export default FormGroup;
