import { useMutation } from '@tanstack/react-query';
import { showResult } from 'infrastructure/hooks/utils';
import dataService from 'services/dataService';
import { API_PATIENT_CALL_RECORD } from 'services/dataService/ccmResources';
import { openInNewTab } from 'utils';

interface IDataProps {
  callId: string;
  patientGuid: string;
}

export const downloadVoiceRecordFn = async ({
  callId,
  patientGuid,
}: IDataProps) => {
  const { error, data } = await dataService.getList<{ url: string }>(
    API_PATIENT_CALL_RECORD(patientGuid, callId),
  );

  if (error) {
    return showResult(error);
  }
  if (data && data.url) return openInNewTab(data.url);
};

export const useDownloadVoiceRecord = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (dataProps: IDataProps) =>
      downloadVoiceRecordFn(dataProps),
  });

  return {
    downloadVoiceRecord: mutate,
    downloadLoading: isLoading,
  };
};
