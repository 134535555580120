import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const IRS = ({ data }) => (
  <div className="admin-user-block">
    <span>IRS</span>
    <Table>
      <tbody>
        {data.npiNumber && (
          <tr>
            <td>NPI</td>
            <td>{data.npiNumber}</td>
          </tr>
        )}
        {data.stateIdNumber && (
          <tr>
            <td>State Id</td>
            <td>{data.stateIdNumber}</td>
          </tr>
        )}
        {data.taxIdNumber && (
          <tr>
            <td>Tax Id EIN</td>
            <td>{data.taxIdNumber}</td>
          </tr>
        )}
        {data.taxonomyNumber && (
          <tr>
            <td>Taxonomy Number</td>
            <td>{data.taxonomyNumber}</td>
          </tr>
        )}
        {data.medicalIdTpiNumber && (
          <tr>
            <td>Medicaid Tpi</td>
            <td>{data.medicalIdTpiNumber}</td>
          </tr>
        )}
        {data.medicareNumber && (
          <tr>
            <td>Medicare Number</td>
            <td>{data.medicareNumber}</td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);

IRS.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default IRS;
