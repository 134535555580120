import QueryParams from 'utils/queryParams';

import httpClient from './httpClient';

const apiUrl = process.env.REACT_APP_API;

const transformData = <T>({ json }: { json?: T }) => ({ data: json });

const transformError = ({ message, name, status }: HttpClientError) => ({
  error: message,
  errorName: name,
  status,
});

interface HttpClientError {
  message?: string;
  name?: string;
  status?: number;
}

export interface IResponse<T> {
  data?: T;
  error?: string;
  errorName?: string;
  status?: number;
}

interface HttpClientOptions {
  signal?: AbortSignal;
}

class DataService {
  getList = <T = any>(
    resource: string,
    { signal }: HttpClientOptions = {},
  ): Promise<IResponse<T>> =>
    httpClient(`${apiUrl}/${resource}`, { signal })
      .then(transformData)
      .catch(transformError);

  getOne = <T = any>(
    resource: string,
    id: string,
    { signal }: HttpClientOptions = {},
  ): Promise<IResponse<T>> =>
    httpClient(`${apiUrl}/${resource}/${id}`, { signal })
      .then(transformData)
      .catch(transformError);

  getOnly = <T = any>(
    resource: string,
    params?: Record<string, any>,
    { signal }: HttpClientOptions = {},
  ): Promise<IResponse<T>> =>
    httpClient(QueryParams.stringifyUrl(`${apiUrl}/${resource}`, params), {
      signal,
    })
      .then(transformData)
      .catch(transformError);

  updateList = <T = any>(
    resource: string,
    params?: Record<string, any>,
  ): Promise<IResponse<T>> =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    })
      .then(transformData)
      .catch(transformError);

  updateOnly = <T = any>(
    resource: string,
    params?: Record<string, any>,
  ): Promise<IResponse<T>> =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    })
      .then(transformData)
      .catch(transformError);

  createOne = <T = any>(
    resource: string,
    params?: Record<string, any>,
  ): Promise<IResponse<T>> =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then(transformData)
      .catch(transformError);

  uploadFile = <T = any>(
    resource: string,
    data: FormData,
    method = 'PUT',
  ): Promise<IResponse<T>> =>
    httpClient(
      `${apiUrl}/${resource}`,
      {
        method,
        body: data,
      },
      true,
    )
      .then(transformData)
      .catch(transformError);

  createList = <T = any>(
    resource: string,
    params?: Record<string, any>,
  ): Promise<IResponse<T>> =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then(transformData)
      .catch(transformError);

  deleteObject = <T = any>(
    resource: string,
    params?: Record<string, any>,
  ): Promise<IResponse<T>> =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'DELETE',
      body: JSON.stringify(params),
    })
      .then(transformData)
      .catch(transformError);

  deleteOne = <T = any>(
    resource: string,
    params: Record<string, any>,
  ): Promise<IResponse<T>> =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    })
      .then(transformData)
      .catch(transformError);
}

const dataService = new DataService();

export default dataService;
