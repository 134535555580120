import useTurkDashboardReadings from 'utils/hooks/turkDashboard/useReadings';
import useTurkUserStats from 'utils/hooks/turkDashboard/useStats';

import useTurkDashboardIdle from './useIdle';
import useReviewResponseTimeout from './useReviewResponseTimer';

const useTurkDashboard = () => {
  const {
    readings,
    isLoading: isReadingsLoading,
    selectReadings,
    removeReading,
    removeAllReadings,
  } = useTurkDashboardReadings();
  const {
    stats,
    refetch: refetchStats,
    isLoading: isStatsLoading,
    scheduleRefetch,
  } = useTurkUserStats();

  useReviewResponseTimeout({
    readings,
    readingsReviewResponseTime: stats?.timeouts?.readingsReviewResponseTime,
    removeAllReadings,
  });
  useTurkDashboardIdle();

  return {
    stats,
    refetchStats,
    selectReadings,
    scheduleRefetch,
    isStatsLoading,
    readings,
    isReadingsLoading,
    removeReading,
  };
};

export default useTurkDashboard;
