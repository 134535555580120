import type { IICDCode } from 'infrastructure/interfaces';

export class ICDCode {
  guid: string;
  code: string;
  description: string;

  constructor(data: IICDCode) {
    this.guid = data.guid;
    this.code = data.code;
    this.description = data.description;
  }
}
