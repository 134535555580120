import BaseSelect from 'components/atoms/baseSelect';
import { usStatesSelectWithCompact } from 'constants/states';
import { LocalStorageService } from 'infrastructure/services/localStorage';
import { useEffect, type FC } from 'react';

import s from './styles.module.scss';

const localStorageKey = 'TurkDashboardStateSelectValueIds';
type StateValue = Array<(typeof usStatesSelectWithCompact)[number]>;

interface Props {
  value: StateValue;
  setValue: (newValue: StateValue) => void;
  disabled?: boolean;
}

const StateSelect: FC<Props> = ({ value, setValue, disabled }) => {
  const setNewValue = (newValue: StateValue) => {
    setValue(newValue);
    LocalStorageService.setValue(
      localStorageKey,
      newValue.map((item) => item.id),
    );
  };
  useEffect(() => {
    const idsFromStorage =
      LocalStorageService.getValue<string[]>(localStorageKey);
    if (!idsFromStorage || !Array.isArray(idsFromStorage)) return;
    const valuesFromStorage = usStatesSelectWithCompact.filter((item) =>
      idsFromStorage.includes(item.id),
    );
    if (valuesFromStorage.length) {
      setNewValue(valuesFromStorage);
    }
  }, []);

  const onChange = (newValue: any, actionMeta: any) => {
    let newValueToSave;
    if (newValue.length === 0 || actionMeta.option?.label === 'All') {
      newValueToSave = [usStatesSelectWithCompact[0]];
    } else {
      newValueToSave = newValue.filter((item: any) => item.label !== 'All');
    }
    setNewValue(newValueToSave);
  };

  return (
    <div className={s['select-wrapper']}>
      <label htmlFor="state-select">Clinic States</label>
      <BaseSelect
        isDisabled={disabled}
        name="state-select"
        isMulti
        options={usStatesSelectWithCompact}
        classNamePrefix="select-type"
        className={s.select}
        onChange={onChange}
        value={value}
        styles={{
          clearIndicator: (base, state) => {
            const currentValue: any = state.getValue();
            if (currentValue.length === 1 && currentValue[0].label === 'All') {
              return {
                ...base,
                display: 'none',
              };
            }
            return base;
          },
          multiValueRemove: (base, state: any) => {
            return state.data.label === 'All'
              ? { ...base, display: 'none' }
              : base;
          },
        }}
      />
    </div>
  );
};

export default StateSelect;
