import { useMutation } from '@tanstack/react-query';

import { createCaseInEhrFn } from './api';

interface Props {
  onSuccess: () => void;
}

export const useCreateCaseInEhr = ({ onSuccess }: Props) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: createCaseInEhrFn,
    onSuccess,
  });

  return { createCaseInEhr: mutate, createCaseInEhrLoading: isLoading };
};
