import React from 'react';
import ArrowUpIcon from 'components/atoms/icons/arrowUpIcon';
import BaseButton from 'components/atoms/baseButton';
import classNames from 'classnames';

import s from './styles.module.scss';

interface IProps {
  expanded: boolean;
  dataCy?: string;
  className?: string;
  onClick?: () => void;
}

const ExpandButton: React.FC<IProps> = (props) => {
  const { expanded, onClick, className, dataCy = 'expand-button' } = props;

  const toggleIcon = (
    <i
      className={classNames(s.icon, {
        [s.opened]: expanded,
      })}
    >
      <ArrowUpIcon fill="currentColor" />
    </i>
  );

  return (
    <BaseButton
      className={className}
      type="secondary"
      onClick={onClick}
      icon={toggleIcon}
      dataCy={dataCy}
    />
  );
};

export default ExpandButton;
