import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import CheckIcon from 'components/atoms/icons/doubleCheckIcon';
import common from 'constants/common';

const ReviewedItem = ({ comment }) => (
  <div className="activity-comments-item">
    <div className="comment-header">
      <span>
        Electronically Signed By:&nbsp;
        <strong>
          {comment.createdByTitle} {comment.createdByFirstName}{' '}
          {comment.createdByLastName}
          {comment.createdByProfessionType ? ' - ' : ' '}
          {comment.createdByProfessionType}
        </strong>
      </span>
      <div className="comment-header-checked">
        <p>{format(new Date(comment.createdAt), common.dateFormats.full)}</p>
        <CheckIcon />
      </div>
    </div>
  </div>
);

ReviewedItem.propTypes = {
  comment: PropTypes.shape().isRequired,
};

export default ReviewedItem;
