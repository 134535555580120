import React, { useEffect, useState } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import BaseGrid from 'components/atoms/base-grid';
import { CareAgents } from 'infrastructure/classes/care-agents';
import AgencyDoctorSelectBox from 'components/molecules/agency-doctor-select-box';
import { usePatientCareAgents } from 'infrastructure/hooks/patient/use-patient-care-agents';
import {
  CCMRoles,
  ModuleType,
  CCMOnboardingAgentRoles,
} from 'infrastructure/enums';
import { useEdit } from 'infrastructure/providers/edit-provider';
import classNames from 'classnames';
import { isMobile } from 'utils/deviceHelper';
import useUser from 'utils/useUser';

import s from './styles.module.scss';

const formKey = 'AdminPanelCareAgentForm';

interface IAdminPanelCareAgentFormProps {
  patientGuid: string;
  module: ModuleType;
}

const AdminPanelCareAgentForm: React.FC<IAdminPanelCareAgentFormProps> = (
  props,
) => {
  const { patientGuid, module } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const isAdmin = module === ModuleType.ADMIN;

  const { canEdit, setEdit } = useEdit();
  const { t } = useTranslation();
  const { deviceType } = useUser();

  const { loading, loadCareAgents, updateCareAgents } = usePatientCareAgents({
    patientGuid,
  });

  const formik = useFormik({
    initialValues: {} as CareAgents,
    onSubmit: async (values) => {
      await updateCareAgents({
        patientGuid,
        careAgents: values,
      }).then(() => {
        formik.resetForm({ values });
      });
      setIsEdit(false);
      setEdit(false);
    },
    validateOnChange: false,
  });

  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };
  const onSave = () => {
    formik.submitForm();
  };
  const onCancel = () => {
    formik.resetForm();
    setIsEdit(false);
    setEdit(false);
  };

  const loadData = async () => {
    const data = await loadCareAgents();
    if (data) {
      formik.resetForm({ values: new CareAgents(data) });
    }
  };

  const selectBoxPlaceholder = isEdit ? undefined : '';

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PatientBlock
      title={t('labels.careAgents')}
      canEdit={isAdmin}
      isEdit={isEdit}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      loading={loading}
      disabled={!canEdit(formKey)}
    >
      <BaseForm formik={formik} plaintext={!isEdit} readonly={!isEdit}>
        <BaseGrid columns={5} className={s['inputs-wrapper']}>
          <BaseFormItem
            name="patManager.agencyDoctorGuid"
            label={t('labels.patAgent')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <AgencyDoctorSelectBox
              allowClear
              placeholder={selectBoxPlaceholder}
            />
          </BaseFormItem>
          <BaseFormItem
            name="onboardingManager.agencyDoctorGuid"
            label={t('labels.onboardingAgent')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <AgencyDoctorSelectBox
              allowClear
              placeholder={selectBoxPlaceholder}
            />
          </BaseFormItem>
          <BaseFormItem
            name="ccmPatManager.agencyDoctorGuid"
            label={t('labels.ccmPatAgent')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <AgencyDoctorSelectBox
              allowClear
              placeholder={selectBoxPlaceholder}
            />
          </BaseFormItem>
          <BaseFormItem
            name="ccmOnboardingAgent.agencyDoctorGuid"
            label={t('labels.ccmOnboardingAgent')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <AgencyDoctorSelectBox
              roles={CCMOnboardingAgentRoles}
              allowClear
              placeholder={selectBoxPlaceholder}
            />
          </BaseFormItem>
          <BaseFormItem
            name="ccmManager.agencyDoctorGuid"
            label={t('labels.ccmCaseManager')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <AgencyDoctorSelectBox
              roles={CCMRoles}
              allowClear
              placeholder={selectBoxPlaceholder}
            />
          </BaseFormItem>
        </BaseGrid>
      </BaseForm>
    </PatientBlock>
  );
};

export default AdminPanelCareAgentForm;
