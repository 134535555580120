import React from 'react';
import classNames from 'classnames';
import { format, isToday, isYesterday } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/atoms/chat/avatar';
import common from 'constants/common';
import ConversationClosedIcon from 'assets/img/conversation-closed.svg';
import { useSelector } from 'react-redux';

import type { RootState } from 'store';

interface IConversationProps {
  patientName: string;
  unreadMessageCount: number;
  hasPatientMessage: boolean;
  isRead: boolean;
  lastMessage: string;
  lastMessageDate: string;
  closed: boolean;
  onClick: () => void;
  selected: boolean;
}

const Conversation: React.FC<IConversationProps> = (props) => {
  const {
    patientName,
    unreadMessageCount,
    hasPatientMessage,
    isRead,
    lastMessage,
    lastMessageDate,
    closed,
    onClick,
    selected,
  } = props;

  const { t } = useTranslation();

  const { conversationType, conversationTypes } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const formatLastMessageDate = (messageDate: string) => {
    const date = new Date(messageDate);

    if (isToday(date)) {
      return format(date, common.dateFormats.timeAMPM);
    }
    if (isYesterday(date)) {
      return t('smsDashboard.yesterday');
    }
    return format(date, common.dateFormats.birthDate);
  };

  const conversationClassNames = classNames('conversation-item', {
    selected,
    closed,
    unread: !isRead,
  });

  const closeOpenClassNames = classNames('closed-status', {
    closed,
    open: !closed,
  });

  const conversationStatusClassNames = classNames(
    'message-status-badge',
    isRead ? 'read' : 'unread',
  );

  const closeOpenText = closed
    ? t('smsDashboard.closed')
    : t('smsDashboard.open');

  const closeOpenAvatar = !closed ? (
    <Avatar patientName={patientName} />
  ) : (
    <div className="conversation-closed-avatar">
      <img src={ConversationClosedIcon} alt="conversation-closed" />
    </div>
  );

  const conversationAvatar =
    conversationType === conversationTypes[2] ? <Avatar /> : closeOpenAvatar;

  const displayName =
    patientName.length > 35 ? `${patientName.slice(0, 35)}...` : patientName;

  return (
    <button onClick={onClick} className={conversationClassNames}>
      <div className="message-wrapper">
        <div className="avatar-wrapper">{conversationAvatar}</div>
        <div className="patient-name" title={patientName}>
          {displayName}
        </div>
      </div>
      <div className="last-message-wrapper">
        <div className="last-message" title={lastMessage}>
          {lastMessage}
        </div>
        <div className="conversation-indicators">
          {unreadMessageCount > 0 && isRead === false ? (
            <div className="notification-badge">{unreadMessageCount}</div>
          ) : null}
          {unreadMessageCount === 0 && isRead === false ? (
            <div className="notification-badge">&nbsp;</div>
          ) : null}
          {hasPatientMessage === true && isRead === true && !closed ? (
            <div className={conversationStatusClassNames} />
          ) : null}
          {closed && !unreadMessageCount ? (
            <div className={closeOpenClassNames} title={closeOpenText}>
              {closeOpenText}
            </div>
          ) : null}
        </div>
      </div>
      <div className="time-wrapper">
        <p className="last-message-time">
          {formatLastMessageDate(lastMessageDate)}
        </p>
      </div>
    </button>
  );
};

export default Conversation;
