import { useQueryClient } from '@tanstack/react-query';

import useApi from '../use-api';
import { getClinicInfoKey } from './use-clinic-info';
import { runbookLogsLoadKey } from './use-runbook-logs';
import { getClinicThresholdsQueryKey } from './use-clinic-thresholds';

import type { IClinic, IEditClinicSchema } from 'infrastructure/interfaces';

const apiUrl = 'admins/admin-panel/clinics';

type UseCreateClinic = {
  loading: boolean;
  editClinic: (data: IEditClinicSchema) => Promise<IClinic>;
};

export const useEditClinic = (): UseCreateClinic => {
  const { loading, updateData } = useApi();

  const queryClient = useQueryClient();

  const editClinic = async (clinic: IEditClinicSchema) => {
    const data = await updateData<IClinic>(apiUrl, clinic).then((res) => {
      queryClient.removeQueries({
        queryKey: [runbookLogsLoadKey],
      });
      queryClient.removeQueries({
        queryKey: getClinicThresholdsQueryKey(clinic.guid),
      });
      queryClient.removeQueries({
        queryKey: getClinicInfoKey({
          clinicGuid: clinic.guid,
        }),
      });

      return res;
    });

    return data;
  };

  return { loading, editClinic };
};
