import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import debounce from 'lodash/debounce';
import dataService from 'services/dataService';
import List from 'components/atoms/list';
import classNames from 'classnames';
import useOutsideClick from 'utils/useOutsideClick';
import ResetSearchIcon from 'components/atoms/icons/searchResetIcon';
import { apiSearchPatients } from 'services/dataService/resources';

let previousController = null;
const loadingOptions = [{ id: 'loading', label: 'Loading...' }];
const noOptions = [{ id: 'noOptions', label: 'No options' }];

const generateFilterLabel = (item) =>
  `${item.firstName} ${item.lastName} - ${item.birthDate}`;

const debouncedSearch = debounce(
  async ({
    searchQuery,
    getInactiveClinicData,
    filter,
    status,
    clinicGuid,
    doctorGuid,
    ccmStatus,
    careAgentGuid,
    setIsLoading,
    setOptions,
  }) => {
    if (previousController && typeof previousController.abort === 'function') {
      previousController.abort();
    }

    if (!searchQuery) {
      setOptions([]);
      setIsLoading(false);
      return;
    }

    const filterQuery = {};
    if (getInactiveClinicData) {
      filterQuery.getInactiveClinicData = true;
      filterQuery.isPrimaryDoctorRequired = true;
    }
    if (searchQuery) {
      filterQuery[filter.id] = searchQuery;
    }
    if (status) {
      filterQuery.status = status;
    }
    if (clinicGuid) filterQuery.clinicGuid = clinicGuid;
    if (doctorGuid) filterQuery.doctorGuid = doctorGuid;
    if (ccmStatus !== undefined) {
      filterQuery.ccmStatus = ccmStatus;
    }
    if (careAgentGuid) {
      filterQuery.careAgentGuid = careAgentGuid;
    }

    const controller = new AbortController();
    const { signal } = controller;
    previousController = controller;
    const { data, error } = await dataService.getList(
      apiSearchPatients(100, 0, filterQuery),
      { signal },
    );
    if (error) {
      return;
    }
    setIsLoading(false);
    if (data) {
      setOptions(
        data.data.map((item) => ({
          id: item.guid,
          value: item.guid,
          label: generateFilterLabel(item),
        })),
      );
    }
  },
  500,
);

const PatientAutocomplete = forwardRef(
  (
    {
      filter,
      handleClick,
      showLabel,
      patient,
      clinicGuid,
      doctorGuid,
      status,
      ccmStatus,
      careAgentGuid,
      getInactiveClinicData,
      isCcmPanel,
    },
    ref,
  ) => {
    const [query, setQuery] = useState('');
    const inputRef = useRef();
    const [show, setShow] = useState(false);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useOutsideClick(inputRef, () => {
      setShow(false);
    });

    const handleInputChange = (string) => {
      if (string.target) {
        setQuery(string.target.value);
      }
      setIsLoading(true);
      const searchQuery = string.target ? string.target.value : query;
      debouncedSearch({
        searchQuery,
        getInactiveClinicData,
        filter,
        status,
        clinicGuid,
        doctorGuid,
        ccmStatus,
        careAgentGuid,
        setIsLoading,
        setOptions,
      });
    };

    const handleOptionClick = (option) => {
      setQuery(option.label);
      handleInputChange(option.label);
      setShow(false);
      return handleClick(option);
    };

    const handleFocus = () => {
      if (filter.id) {
        setShow(true);
      }
    };

    const autoCompleteClass = () => {
      let className = 'card-border';
      if (showLabel) {
        className = 'card-border card-shadow border-0';
      }
      return className;
    };

    const resetSearch = () => {
      setQuery('');
      handleInputChange('');
      debouncedSearch({
        searchQuery: '',
        getInactiveClinicData,
        filter,
        status,
        clinicGuid,
        doctorGuid,
        ccmStatus,
        careAgentGuid,
        setIsLoading,
        setOptions,
      });
      handleClick({ value: '' });
    };

    const resetQuery = () => {
      setQuery('');
    };

    useImperativeHandle(ref, () => ({
      resetQuery,
    }));

    return (
      <div
        className={
          isCcmPanel
            ? 'patient-filter-autocomplete ccm-activity__row-item'
            : 'patient-filter-autocomplete'
        }
        ref={inputRef}
      >
        <div className="dropdown" role="button" aria-hidden>
          <Card className={autoCompleteClass()}>
            <CardBody
              className={classNames({
                'card-body-sort': !patient,
              })}
            >
              {showLabel && <p>{filter.label}</p>}
              <div className="dropdown-body">
                <input
                  className={query !== '' ? 'arrow' : ''}
                  placeholder={isCcmPanel ? 'Patient' : filter.placeholder}
                  type="text"
                  onChange={handleInputChange}
                  value={query}
                  onFocus={handleFocus}
                  data-cy="patient-filter-autocomplete-input"
                />
                {query !== '' && <ResetSearchIcon onClick={resetSearch} />}
              </div>
            </CardBody>
          </Card>
        </div>
        {isLoading && show && (
          <div className="patient-filter-dropdown-body">
            <List onChange={() => {}} options={loadingOptions} />
          </div>
        )}
        {!isLoading && options.length === 0 && show && (
          <div className="patient-filter-dropdown-body">
            <List onChange={() => {}} options={noOptions} />
          </div>
        )}
        {!isLoading && options && options.length > 0 && show && (
          <div className="patient-filter-dropdown-body">
            <List
              onChange={handleOptionClick}
              options={options}
              dataCy="patient-list-patient-search-result"
            />
          </div>
        )}
      </div>
    );
  },
);

PatientAutocomplete.propTypes = {
  filter: PropTypes.shape().isRequired,
  handleClick: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  patient: PropTypes.bool,
  status: PropTypes.string,
  clinicGuid: PropTypes.string,
  doctorGuid: PropTypes.string,
  ccmStatus: PropTypes.string,
  careAgentGuid: PropTypes.string,
  getInactiveClinicData: PropTypes.bool,
  isCcmPanel: PropTypes.bool,
};

export default PatientAutocomplete;
