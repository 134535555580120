import React from 'react';
import PropTypes from 'prop-types';

const CountCard = ({ text, count, dataCy = 'counter' }) => (
  <div className="count-card">
    <div className="count-card-text" data-cy={`${dataCy}-text`}>
      {text}
    </div>
    <div className="count-card-subtitle" data-cy={`${dataCy}-subtitle`}>
      Count
    </div>
    <div className="count-card-count" data-cy={`${dataCy}-count`}>
      {count}
    </div>
  </div>
);

CountCard.propTypes = {
  text: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  dataCy: PropTypes.string,
};

export default CountCard;
