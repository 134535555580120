import { useTranslation } from 'react-i18next';

import s from './style.module.scss';

const EligibilityHeader = () => {
  const { t } = useTranslation();

  return <p className={s.title}>{t('labels.eligibilityTool')}</p>;
};

export default EligibilityHeader;
