import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dataService from 'services/dataService';
import { apiPatientDetails } from 'services/dataService/resources';
import { generateFullName } from 'utils/badgeHelpers';
import { isCcmActionAllowedStatus } from 'utils/userTypeHelper';

import type { IRpmPatientDetail } from 'infrastructure/interfaces/i-rpm-patient-details';

interface Props {
  patientGuid: string;
}

export const useRpmPatientDetailsKey = (patientGuid: string) => [
  'getRpmPatientDetails',
  patientGuid,
];

export const useRpmPatientDetails = ({ patientGuid }: Props) => {
  const navigate = useNavigate();
  const {
    patient: { setDetails },
  } = useDispatch();

  const { data: patient, ...other } = useQuery({
    queryKey: useRpmPatientDetailsKey(patientGuid),
    queryFn: async () => {
      const { data, error } = await dataService.getList<IRpmPatientDetail>(
        apiPatientDetails(patientGuid),
      );
      if (error) {
        navigate('/404', { replace: true });
        throw new Error(error);
      }
      setDetails(data);
      return data;
    },
    enabled: Boolean(patientGuid),
  });

  const patientHasSecondaryNumber = !!patient?.profile?.homeNumber;
  const canEditPage = isCcmActionAllowedStatus(patient?.profile?.ccmStatus);
  const fullName = generateFullName(
    patient?.profile.title,
    patient?.profile.firstName,
    patient?.profile.middleName,
    patient?.profile.lastName,
  );
  const physicianFullName = generateFullName(
    patient?.doctor?.title,
    patient?.doctor?.firstName,
    patient?.doctor?.lastName,
  );
  const birthDate = patient?.profile.birthDate.replaceAll('-', '.');
  const rpmStatus = patient?.profile.status;
  const ccmStatus = patient?.profile.ccmStatus;
  const serviceDeliveryModel = patient?.clinic?.serviceDeliveryModel ?? '';
  const providerServiceType = patient?.clinic?.providerServiceType
    ? patient.clinic.providerServiceType.join('; ')
    : '';
  const showEmailTag = patient?.profile?.emailSubscription === false;
  const doNotCall = patient?.profile?.doNotCall;
  const clinicGuid = patient?.clinic?.clinicGuid;
  const clinicName = patient?.clinic?.legalname ?? '';
  const deviceIds =
    patient?.devices?.map((device) => device.deviceId).join(',') ?? '';
  const holdingStatus = patient?.profile?.holdingStatus;
  const referralTypes = patient?.clinic?.referralTypes
    ? patient.clinic.referralTypes.join('; ')
    : '';
  const sla = patient?.clinic?.serviceDeliveryThresholds ?? undefined;
  const pcmStatus = patient?.profile.pcmStatus;

  return {
    query: {
      patient,
      ...other,
    },
    patientHasSecondaryNumber,
    canEditPage,
    fullName,
    birthDate,
    rpmStatus,
    serviceDeliveryModel,
    providerServiceType,
    showEmailTag,
    doNotCall,
    clinicGuid,
    deviceIds,
    clinicName,
    holdingStatus,
    referralTypes,
    ccmStatus,
    physicianFullName,
    sla,
    pcmStatus,
  };
};
