import { TimerCancelledError } from 'infrastructure/classes/timer-cancelled-error';

import { promiseWithResolvers } from './promise';

export const createWaitPromise = (ms: number) => {
  const { promise, reject, resolve } = promiseWithResolvers();
  const timeout = setTimeout(resolve, ms);

  const cancel = () => {
    clearTimeout(timeout);
    reject(new TimerCancelledError());
  };

  return { promise, cancel };
};
