import type { IUpdateInteractionResponse } from 'infrastructure/hooks/control-panel/api';
import type {
  IAddCommentResponse,
  IAsignSocketData,
  IRpmPatientActivity,
  IStopSlaTimerSocketData,
  IUpdateReadingAction,
} from 'infrastructure/interfaces/i-rpm-patient-activity';

interface ISetSingleSlaReviewedTime {
  newData: IRpmPatientActivity;
  data: {
    slaReviewedTime: string;
    deviceId: string;
    readingId: string;
  };
}

export const setSingleSlaReviewedTime = ({
  newData,
  data,
}: ISetSingleSlaReviewedTime) => {
  newData.data.forEach((item) => {
    if (item.id === data.readingId && item.deviceId === data.deviceId) {
      item.slaReviewedTime = data.slaReviewedTime;
    }
  });
};

interface ISetAllSlaReviewedTime {
  newData: IRpmPatientActivity;
  data: {
    slaReviewedTime: string;
    patientGuid: string;
  };
}

export const setAllSlaReviewedTime = ({
  newData,
  data,
}: ISetAllSlaReviewedTime) => {
  newData.data.forEach((item) => {
    if (item.patient.guid === data.patientGuid) {
      item.slaReviewedTime = data.slaReviewedTime;
    }
  });
};

interface IChangeAssign {
  newData: IRpmPatientActivity;
  data: IAsignSocketData;
}

export const changeAssign = ({ newData, data }: IChangeAssign) => {
  newData.data.forEach((item) => {
    if (item.patient.guid === data.patientGuid) {
      if (data.action === 'unassign' && item.reserved) {
        item.reserved = null;
      }
      if (data.action === 'assign' && !item.reserved) {
        item.reserved = data.author;
      }
    }
  });
};

interface ISetSingleOrAllSlaReviewedTime {
  oldData?: IRpmPatientActivity;
  data: IStopSlaTimerSocketData;
}

export const setSingleOrAllSlaReviewedTime = ({
  oldData,
  data,
}: ISetSingleOrAllSlaReviewedTime) => {
  if (data && oldData) {
    const newData = structuredClone(oldData);
    const { deviceId, readingId, slaReviewedTime, patientGuid } = data;
    if (data.type === 'single') {
      setSingleSlaReviewedTime({
        newData,
        data: { deviceId, readingId, slaReviewedTime },
      });
    }
    if (data.type === 'all') {
      setAllSlaReviewedTime({
        newData,
        data: { slaReviewedTime, patientGuid },
      });
    }
    return newData;
  }

  return oldData;
};

interface IChangeAssignPatient {
  oldData?: IRpmPatientActivity;
  data: IAsignSocketData;
}

export const changeAssignPatient = ({
  oldData,
  data,
}: IChangeAssignPatient) => {
  if (data && oldData) {
    const newData = structuredClone(oldData);
    changeAssign({ newData, data });
    return newData;
  }

  return oldData;
};

interface ISetComment {
  oldData?: IRpmPatientActivity;
  data: IUpdateReadingAction;
}

export const setComment = ({ oldData, data }: ISetComment) => {
  if (oldData) {
    const {
      readingId,
      deviceId,
      actionType,
      patientGuid: _,
      ...newComment
    } = data;
    const newData = structuredClone(oldData);

    // update call
    if (actionType === 'updateCall') {
      newData.data.forEach((item) => {
        const calComment = item.comments.find(
          (c) => c.type === 'call' && c.message === data.callId,
        );
        if (calComment) {
          calComment.hidden = data.hidden;
          calComment.duration = data.duration;
        }
      });

      return newData;
    }

    // add a call comment to the comment type call
    if (data.callId) {
      newData.data.forEach((item) => {
        if (item.id === readingId && item.deviceId === deviceId) {
          item.comments.forEach((comment) => {
            if (comment.type === 'call' && comment.message === data.callId) {
              const isCommentExist = comment?.callComments?.some(
                (callComment) => {
                  return callComment.guid === data.guid;
                },
              );
              if (isCommentExist) return;

              const { slaReviewedTime: __, ...callComment } = newComment;
              comment.callComments
                ? comment.callComments.unshift(callComment)
                : (comment.callComments = [callComment]);
            }
          });
        }
      });

      return newData;
    }

    // add comment
    newData.data.forEach((item) => {
      if (item.id === readingId && item.deviceId === deviceId) {
        const isCommentExist = item.comments.some(
          (comment) => comment.guid === data.guid,
        );
        if (isCommentExist) return;

        if (data.reviewed) item.reviewed = data.reviewed;
        item.comments.unshift(newComment);
      }
    });

    return newData;
  }

  return oldData;
};

export const removeNewActionFlag = (oldData?: IRpmPatientActivity) => {
  const newData = structuredClone(oldData);
  newData?.data?.forEach((item) => {
    item.comments.forEach((c) => {
      if (c.newAction) c.newAction = false;

      c?.callComments?.forEach((cc) => {
        if (cc.newAction) cc.newAction = false;
      });
    });
  });

  return newData;
};

interface IUndoComment {
  oldData?: IRpmPatientActivity;
  data: IAddCommentResponse;
}

export const undoComment = ({ oldData, data }: IUndoComment) => {
  if (oldData) {
    const { readingId, deviceId } = data;
    const newData = structuredClone(oldData);
    newData.data.forEach((item) => {
      if (item.id === readingId && item.deviceId === deviceId)
        item.comments = item.comments.filter(
          (comment) => comment.guid !== data.guid,
        );
    });

    return newData;
  }

  return oldData;
};

interface IReviewedComment {
  oldData?: IRpmPatientActivity;
  data: IAddCommentResponse;
}

export const setReviewedComment = ({ oldData, data }: IReviewedComment) => {
  if (oldData) {
    const newData = structuredClone(oldData);
    newData.data.forEach((item) => {
      if (item.id === data.readingId && item.deviceId === data.deviceId)
        item.reviewed = true;
    });

    return newData;
  }

  return oldData;
};

interface ISetOnCallPatient {
  oldData?: IRpmPatientActivity;
  patientGuid: string;
  isOnCall: boolean;
}

export const setOnCallPatient = ({
  oldData,
  patientGuid,
  isOnCall,
}: ISetOnCallPatient) => {
  if (oldData) {
    const newData = structuredClone(oldData);
    newData.data.forEach((item) => {
      if (item.patient.guid === patientGuid) {
        item.patient.isOnCall = isOnCall;
      }
    });

    return newData;
  }

  return oldData;
};

interface ISetPatientInteractionStatus {
  oldData?: IRpmPatientActivity;
  data?: IUpdateInteractionResponse;
}

export const setPatientInteractionStatus = ({
  oldData,
  data,
}: ISetPatientInteractionStatus) => {
  if (oldData) {
    const newData = structuredClone(oldData);
    newData.data.forEach((item) => {
      if (item.patient.guid === data?.patientGuid) {
        item.patient.interactionStatus = data?.interactionStatus;
      }
    });

    return newData;
  }

  return oldData;
};
