import React, { useEffect, useState } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import BaseFormItemGroup from 'components/atoms/base-form/item-group';
import BaseInput from 'components/atoms/base-input';
import ClinicSelectBox from 'components/molecules/clinic-select-box';
import BaseGrid from 'components/atoms/base-grid';
import BaseTag from 'components/atoms/base-tag';
import DoctorSelectBox from 'components/molecules/doctor-select-box';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { Clinic } from 'infrastructure/classes/clinic';
import { useClinicInfo } from 'infrastructure/hooks/clinic/use-clinic-info';
import { ModuleType, Roles } from 'infrastructure/enums';
import useUser from 'utils/useUser';
import { useEdit } from 'infrastructure/providers/edit-provider';
import { prepareArrToCopy } from 'infrastructure/functions/prepare-arr-to-copy';
import classNames from 'classnames';
import { isMobile } from 'utils/deviceHelper';

import s from './styles.module.scss';
import { clinicProviderInfoValidationSchema } from './validation-schema';

const formKey = 'AdminPanelClinicProviderInfoForm';

const doctorRoles = [
  Roles.Doctor,
  Roles.PhysicianAssistant,
  Roles.NursePractitioner,
  Roles.RegisteredNurse,
  Roles.QHCP,
  Roles.PharmD,
];

interface IAdminPanelClinicProviderInfoFormProps {
  patientGuid: string;
  clinicGuid?: string;
  doctorGuid?: string;
  loading?: boolean;
  onClinicChange?: (clinicGuid?: string, agencyGuid?: string) => void;
  module: ModuleType;
}

const AdminPanelClinicProviderInfoForm: React.FC<
  IAdminPanelClinicProviderInfoFormProps
> = (props) => {
  const {
    module,
    clinicGuid = '',
    doctorGuid,
    patientGuid,
    loading: loadingProp = false,
    onClinicChange,
  } = props;

  const { agencies, deviceType, iamPatientAcceptance } = useUser();

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { canEdit, setEdit } = useEdit();
  const { t } = useTranslation();

  const [doctorName, setDoctorName] = useState<string | undefined>('');

  const { loading, updatePatientClinicInfo } = usePatientInfo({ patientGuid });
  const { loading: clinicLoading, loadClinicInfo } = useClinicInfo({
    clinicGuid,
  });

  const isAdmin = module === ModuleType.ADMIN;

  const formik = useFormik({
    initialValues: {
      patientGuid,
      clinicGuid,
      doctorGuid,
      clinic: {} as Clinic,
      doctorName: '',
    },
    onSubmit: async (values) => {
      await updatePatientClinicInfo(
        {
          patientGuid: values.patientGuid,
          clinicGuid: values.clinicGuid,
          doctorGuid: values.doctorGuid,
        },
        values.clinic,
      ).then(() => {
        setDoctorName(values.doctorName);
        formik.resetForm({ values });
      });
      setIsEdit(false);
      setEdit(false);
      formik.initialValues = values;
    },
    validationSchema: clinicProviderInfoValidationSchema(),
    validateOnChange: false,
  });

  const copyText = prepareArrToCopy([
    formik.values.doctorName,
    formik.values.clinic?.legalname,
    formik.values.clinic?.email,
    formik.values.clinic?.phone,
    formik.values.clinic?.address,
    formik.values.clinic?.city,
    formik.values.clinic?.state,
    formik.values.clinic?.zip,
  ]);

  const { guid } = formik.values.clinic;

  const loadClinicData = async (clinicGuidParam: string) => {
    const data = await loadClinicInfo({ clinicGuid: clinicGuidParam });
    if (data) {
      if (onClinicChange) onClinicChange(data.guid, data.agencyGuid);
      formik.setFieldValue('clinic', new Clinic(data));
    }
  };

  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };

  const onSave = () => {
    if (formik.dirty) {
      formik.submitForm();
    } else {
      setIsEdit(false);
      setEdit(false);
    }
  };

  const onCancel = () => {
    formik.setValues({
      ...formik.values,
      doctorName: doctorName ?? '',
      patientGuid,
      clinicGuid,
      doctorGuid,
    });
    loadClinicData(clinicGuid);
    setIsEdit(false);
    setEdit(false);
    if (onClinicChange) onClinicChange();
  };

  useEffect(() => {
    formik.resetForm({
      values: {
        patientGuid,
        clinicGuid,
        doctorGuid,
        clinic: formik.values.clinic.guid
          ? formik.values.clinic
          : ({} as Clinic),
        doctorName: '',
      },
    });
  }, [clinicGuid, doctorGuid, patientGuid, loadingProp]);

  return (
    <PatientBlock
      title={t('labels.clinicProviderInformation')}
      canEdit={isAdmin}
      isEdit={isEdit}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      loading={!guid || loadingProp || loading || clinicLoading}
      disabled={!canEdit(formKey) || iamPatientAcceptance}
      copyText={copyText}
    >
      <BaseForm formik={formik} plaintext={!isEdit} readonly={!isEdit}>
        <BaseFormItemGroup
          className={s['border-bottom']}
          label={t('labels.provider')}
        >
          <BaseFormItem
            name="doctorGuid"
            label={t('labels.name')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <DoctorSelectBox
              roles={doctorRoles}
              clinicGuid={formik.values.clinicGuid}
              onChange={(value, record) => {
                formik.setFieldValue('doctorGuid', value);
                formik.setFieldValue('doctorName', record?.label);
              }}
              onLoaded={(_, record) => {
                if (!guid && record?.value) {
                  formik.setFieldValue('doctorName', record.label);
                  setDoctorName(`${record.label}`);
                }
              }}
            />
          </BaseFormItem>
        </BaseFormItemGroup>
        <BaseFormItemGroup label={t('labels.clinic')}>
          <div className={s['border-bottom']}>
            <BaseFormItem
              name="clinicGuid"
              label={t('labels.legalName')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <ClinicSelectBox
                agencyGuid={agencies.map((el) => el.guid)}
                maxWidth="832px"
                onChange={(value) => {
                  formik.setFieldValue('clinicGuid', value);
                  formik.setFieldValue('doctorGuid', undefined);
                  loadClinicData(value);
                }}
                onLoaded={(_, record) => {
                  if (!guid && record?.value) {
                    loadClinicData(record.value);
                  }
                }}
              />
            </BaseFormItem>
          </div>
          <BaseGrid columns={2} columnGap={40} className={s['inputs-wrapper']}>
            <BaseFormItem
              name="clinic.email"
              label={t('labels.email')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput readonly plaintext />
            </BaseFormItem>
          </BaseGrid>
          <BaseGrid columns={2} columnGap={40} className={s['inputs-wrapper']}>
            <BaseFormItem
              name="clinic.phone"
              label={t('labels.phoneNumber')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput readonly plaintext />
            </BaseFormItem>
            <BaseFormItem
              name="clinic.fax"
              label={t('labels.fax')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput readonly plaintext />
            </BaseFormItem>
          </BaseGrid>
          <BaseGrid columns={2} columnGap={40} className={s['inputs-wrapper']}>
            <BaseFormItem
              name="clinic.addressLine1"
              label={`${t('labels.addressLine')} 1`}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput readonly plaintext />
            </BaseFormItem>
            <BaseFormItem
              name="clinic.addressLine2"
              label={`${t('labels.addressLine')} 2`}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput readonly plaintext />
            </BaseFormItem>
          </BaseGrid>
          <BaseGrid columns={3} columnGap={40} className={s['inputs-wrapper']}>
            <BaseFormItem
              name="clinic.city"
              label={t('labels.city')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput readonly plaintext />
            </BaseFormItem>
            <BaseFormItem
              name="clinic.state"
              label={t('labels.state')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput readonly plaintext />
            </BaseFormItem>
            <BaseFormItem
              name="clinic.zip"
              label={t('labels.zip')}
              className={classNames({ [s['input-wrapper']]: !isEdit })}
              showErrorBlock={isMobile(deviceType) ? isEdit : true}
            >
              <BaseInput readonly plaintext />
            </BaseFormItem>
          </BaseGrid>
          <BaseFormItem
            name="clinic.providerServiceType"
            label={t('labels.providerServiceType')}
            className={s['mob-pb-10']}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <div>
              {formik.values.clinic.providerServiceType &&
                formik.values.clinic.providerServiceType.map((el) => (
                  <BaseTag label={el} key={el} />
                ))}
            </div>
          </BaseFormItem>
          <BaseFormItem
            name="clinic.serviceDeliveryModel"
            label={t('labels.serviceDeliveryModel')}
            className={classNames(s['mob-pb-10'], {
              [s['input-wrapper']]: !isEdit,
            })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput readonly plaintext />
          </BaseFormItem>
          <BaseFormItem
            name="clinic.referralTypes"
            label={t('labels.referralTypes')}
            className={s['mob-pb-10']}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <div>
              {formik.values.clinic.referralTypes &&
                formik.values.clinic.referralTypes.map((el) => (
                  <BaseTag label={el} key={el} />
                ))}
            </div>
          </BaseFormItem>
        </BaseFormItemGroup>
      </BaseForm>
    </PatientBlock>
  );
};

export default AdminPanelClinicProviderInfoForm;
