export const API_QUESTIONS = 'core/ccm/questions';
export const API_MEDICAL_HISTORY = 'core/ccm/medical-records';
export const API_ALLERGY_LOG = 'core/ccm/allergy-logs';
export const API_MEDICATION = 'core/ccm/patientMedications';
export const API_PATIENT_NOTE_CREATE = 'core/ccm/patientNotes';
export const API_PATIENT_NOTES = 'core/ccm/notes';
export const API_PATIENT_CALLS = 'core/ccm/calls';
export const API_MESSAGES_RESPONSE = 'core/ccm/sms-responses';
export const API_PATIENT_DIAGNOSES = 'core/ccm/diagnoses';
export const API_PATIENT_ICD__CODES = 'core/ccm/icd-ten-codes';
export const API_PATIENT_DETAILS = 'core/ccm/patient-details';
export const API_CARE_LOCATIONS = 'core/ccm/care-locations';
export const API_GOALS = 'core/ccm/goals';
export const API_ACTIVITY = 'core/ccm/activity';
export const API_BILLING_REPORTS = 'reports/ccm/reports/billing';
export const API_BILLING_REPORTS_V2 = 'reports/v2/ccm/reports/billing';
export const API_CARE_PLAN = 'reports/reports/care-plan';
export const API_NEXT_APPOINTMENTS = 'core/patients/next-appointments';
export const API_APPOINTMENT_COMPLETIONS =
  'core/patients/appointment-completions';

export const apiCcmPatientInteractiveTime = (patientGuid, date) =>
  `core/ccm/interactive-times/${patientGuid}?date=${date}`;
export const API_PATIENT_CALL_RECORD = (guid, callId) =>
  `core/ccm/patients/${guid}/call-records/${callId}`;
export const API_UPDATE_MEDICATION = (patientGuid) =>
  `core/ccm/patientMedications/${patientGuid}`;
export const API_PATIENT_CARE_LOCATIONS = (patientGuid) =>
  `core/ccm/care-locations/${patientGuid}`;
export const API_UPDATE_PATIENT_DIAGNOSES = (patientGuid) =>
  `core/ccm/diagnoses/${patientGuid}`;
export const apiCcmPatientManualInteractionTimes = (guid) =>
  `core/ccm/patients/${guid}/manual-time`;
export const apiCcmPatientDischarge = (guid) =>
  `core/ccm/patients/${guid}/discharge`;
export const API_UPDATE_ALLERGY_LOG = (guid) => `core/ccm/allergy-logs/${guid}`;
