import React from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import AutoComplete from 'components/molecules/autocompleteFilter';
import forms from 'constants/forms';
import TimeFilter from 'components/organisms/timeFilter';
import AutoCompleteDoctorMultiSelect from 'components/molecules/autocompleteFilter/doctorMulti';

const DEFAULT_FILTER = forms.patientFilterOptions[0];

const Sorting = () => {
  const {
    compliance: { applyTimeFilter, applyFilters },
  } = useDispatch();

  const filter = DEFAULT_FILTER;

  const handleSubmit = (dates) => {
    applyTimeFilter(dates);
  };

  const changePatientFilter = (option) => {
    if (option) {
      applyFilters({ patientGuid: option.value });
    }
  };
  return (
    <Row>
      <Col xl="2" xs="12" md="6">
        <p>Report Time Window</p>
        <TimeFilter handleSubmit={handleSubmit} />
      </Col>
      <Col xl="2" xs="12" md="6">
        <p>Provider</p>
        <AutoCompleteDoctorMultiSelect
          filter={filter}
          onChange={(doctorGuid) => {
            applyFilters({ doctorGuid });
          }}
        />
      </Col>
      <Col xl="2" xs="12" md="6">
        <p>Patient Name</p>
        <AutoComplete filter={filter} handleClick={changePatientFilter} />
      </Col>
    </Row>
  );
};

export default Sorting;
