import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { usePatientContactAvailability } from 'infrastructure/hooks/patient/use-patient-contact-availability';
import { weekDaysDataSource } from 'infrastructure/data-sources/week-days';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import useUser from 'utils/useUser';

import s from './styles.module.scss';
import ContactAvailabilityViewDay from './day';

import type { Availability } from 'infrastructure/classes/availability';
import type { IUpdateAvailability } from 'infrastructure/interfaces';
import type { IContactAvailabilityViewDayRef } from './day';
import type { IWeekDayItem } from 'infrastructure/data-sources/week-days';

export interface IContactAvailabilityUpdateFormRef {
  save: () => Promise<void>;
}

interface IProps {
  patientGuid: string;
  anytime?: boolean;
  onSaved?: () => void;
}

const ContactAvailabilityUpdateForm = forwardRef<
  IContactAvailabilityUpdateFormRef,
  IProps
>((props, ref) => {
  const { patientGuid, anytime = false, onSaved } = props;

  const { guid: authorGuid } = useUser();

  const { loading, availabilities, updateAvailabilities } =
    usePatientContactAvailability({ patientGuid });

  const itemsRef = useRef<Array<IContactAvailabilityViewDayRef>>([]);

  const weekDays = useMemo(() => weekDaysDataSource(), []);

  const prepareAvailabilities = (day: IWeekDayItem) => {
    const result: Availability[] = [];
    availabilities.forEach((availability) => {
      if (day.index === availability.day) result.push(availability);
    });

    return result;
  };

  const onSave = async () => {
    const values: Array<IUpdateAvailability> = [];
    const isValid: Array<boolean> = [];

    itemsRef.current.forEach((el) => {
      const res = el.validate();
      values.push(
        ...res.times.map(
          ({ endTime, startTime }): IUpdateAvailability => ({
            day: res.day.index,
            endTime,
            startTime,
            authorGuid,
          }),
        ),
      );
      isValid.push(res.isValid);
    });

    if (isValid.every((el) => el)) {
      await updateAvailabilities(anytime ? [] : values).then(() => {
        if (onSaved) onSaved();
      });
    }
  };

  useImperativeHandle(ref, () => ({ save: onSave }));

  const memoizedDays = useMemo(() => {
    return weekDays.map((el, index) => (
      <ContactAvailabilityViewDay
        key={el.index}
        ref={(e) => {
          if (e) itemsRef.current[index] = e;
        }}
        patientGuid={patientGuid}
        day={el}
        times={prepareAvailabilities(el)}
        anytime={anytime}
      />
    ));
  }, [anytime, availabilities.length]);

  return (
    <BaseSpinWrapper spinning={loading}>
      <ul className={s.list}>
        {memoizedDays}
        <li className={s.day} />
        <li className={s.day} />
      </ul>
    </BaseSpinWrapper>
  );
});

ContactAvailabilityUpdateForm.displayName = 'ContactAvailabilityUpdateForm';

export default ContactAvailabilityUpdateForm;
