import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { RunbookLog } from 'infrastructure/classes/runbook-log';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { arrUniqEl } from 'infrastructure/functions';

import useApi from '../use-api';

import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IRunbookLogPaginatedData,
} from 'infrastructure/interfaces';

const runbookLogsApi = 'core/runbook-logs';

export const runbookLogsLoadKey = 'loadRunbookLogs';

export interface IUseRunbookLogMeta {
  clinicGuid?: string;
  reverse?: string;
}

type mergedMeta = IUseRunbookLogMeta & IPaginationMeta;

type UseRunbookLog = {
  runbookLogs: Array<RunbookLog>;
  loading: boolean;
  meta: mergedMeta;
  loadRunbookLogs: (
    meta: mergedMeta,
    reset?: boolean,
  ) => Promise<Array<RunbookLog>>;
};

interface IUseRunbookLogProps {
  meta?: IUseRunbookLogMeta & IPartialPaginationMeta;
}

export const useRunbookLog = (props?: IUseRunbookLogProps): UseRunbookLog => {
  const options = props || {};
  const metaProps = options.meta || {};

  const { loading, isMounted, loadData } = useApi();

  const queryClient = useQueryClient();

  const [runbookLogs, setRunbookLogs] = useState<Array<RunbookLog>>([]);
  const [meta, setMeta] = useState<mergedMeta>({
    page: 1,
    items: 1,
    totalCount: 0,
    ...metaProps,
  });

  const loadRunbookLogs = async (filters: mergedMeta, reset = false) => {
    const queryKey = [runbookLogsLoadKey, filters];

    const data = await queryClient.fetchQuery(
      queryKey,
      () =>
        loadData<
          IRunbookLogPaginatedData,
          Omit<IUseRunbookLogMeta, 'sortField'>
        >(runbookLogsApi, {
          guidValue: filters.clinicGuid,
          page: filters.page,
          items: filters.items,
        }).then((res) => {
          if (res) {
            const { data: items, count } = res;
            return {
              items: items.map(
                (el) => new RunbookLog(el.newValue, el.createdAt),
              ),
              count,
            };
          }
        }),
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      setMeta({
        ...meta,
        ...filters,
        totalCount: data.count,
      });

      const newRows = reset
        ? data.items
        : arrUniqEl([...runbookLogs, ...data.items], 'createdAt');

      setRunbookLogs(newRows);

      return newRows;
    }
    return [];
  };

  return {
    runbookLogs,
    loading,
    meta,
    loadRunbookLogs,
  };
};
