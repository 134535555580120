import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Input from 'components/atoms/input';
import ModalPopup from 'components/organisms/modal';
import Button from 'components/atoms/button';
import { useDispatch } from 'react-redux';

import { manualEnterCallSchema } from './validationSchema';

const ManualEnterCall = ({ isOpen, toggle, patientGuid, isRpmPanel }) => {
  const {
    connect: { handleConnectPopoutOpen, connectDetails },
  } = useDispatch();

  const onClickConnect = ({ phoneNumber }) => {
    const details = {
      phone: phoneNumber,
      guid: patientGuid,
      isRpmCall: isRpmPanel,
    };
    connectDetails(details);
    handleConnectPopoutOpen(true);
    toggle();
  };

  return (
    <ModalPopup
      isOpen={isOpen}
      toggle={toggle}
      title="Manual Entered Call"
      className="popup manual-enter-call-popup"
    >
      <Formik
        initialValues={{ phoneNumber: '' }}
        validationSchema={manualEnterCallSchema()}
        onSubmit={onClickConnect}
      >
        {({ errors }) => (
          <Form>
            <>
              <Input type="text" id="phoneNumber" label="Phone Number" />
              {errors.phoneNumber && (
                <div className="input-error">{errors.phoneNumber}</div>
              )}
              <div className="text-center">
                <Button type="submit" text="Call" />
              </div>
            </>
          </Form>
        )}
      </Formik>
    </ModalPopup>
  );
};
ManualEnterCall.defaultProps = {
  isOpen: false,
};

ManualEnterCall.propTypes = {
  toggle: PropTypes.func.isRequired,
  patientGuid: PropTypes.string.isRequired,
  isRpmPanel: PropTypes.bool,
  isOpen: PropTypes.bool,
};

export default ManualEnterCall;
