import React, { useState } from 'react';
import CsvIcon from 'components/atoms/icons/csvIcon';

import type { ChangeEventHandler, DragEventHandler, FC } from 'react';

interface Props {
  uploadFile: (file: File) => Promise<{ error?: any }>;
}

const UploadCsv: FC<Props> = ({ uploadFile }) => {
  const [dropping, setDropping] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState<null | string>(null);

  const handleFile = async (file: File) => {
    setUploading(true);
    const { error } = await uploadFile(file);
    setUploading(false);
    setDropping(false);
    if (error) {
      setUploadError(error);
    }
  };

  const handleDrag: DragEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDropping(true);
    } else if (e.type === 'dragleave') {
      setDropping(false);
    }
  };

  const handleDrop: DragEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      if (!file.type.includes('csv')) {
        setUploadError(`File type of [${file.type}] is not allowed!`);
        return;
      }

      if (file.size > 10485760) {
        const size = file.size / (1024 * 1024);
        setUploadError(
          `File size of ${size.toFixed(2)} MB is larger than 10MB!`,
        );
        return;
      }

      setUploadError(null);
      handleFile(file);
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (!file.type.includes('csv')) {
        setUploadError(`File type of [${file.type}] is not allowed!`);
        return;
      }

      if (file.size > 10485760) {
        const size = file.size / (1024 * 1024);
        setUploadError(
          `File size of ${size.toFixed(2)} MB is larger than 10MB!`,
        );
        return;
      }

      setUploadError(null);
      handleFile(file);
    }
  };

  return (
    <div className="admin-csv-upload">
      <form
        id="form-file-upload"
        encType="multipart/form-data"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          name="file"
          type="file"
          id="input-file-upload"
          accept=".csv"
          onChange={handleChange}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dropping ? 'drag-active' : ''}
        >
          <div>
            {uploading ? (
              <div>Uploading...</div>
            ) : (
              <div>
                <CsvIcon />
                <p className="title">Select a CSV file to import</p>
                <p className="text">or drag and drop it here</p>
              </div>
            )}
            {uploadError && <p className="error-text">{uploadError}</p>}
          </div>
        </label>
        {dropping && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </form>
    </div>
  );
};

export default UploadCsv;
