import React, {
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import debounce from 'lodash/debounce';
import dataService from 'services/dataService';
import List from 'components/atoms/list';
import classNames from 'classnames';
import useOutsideClick from 'utils/useOutsideClick';
import ResetSearchIcon from 'components/atoms/icons/searchResetIcon';

const loadingOptions = [{ id: 'loading', label: 'Loading...' }];

const InputAutocomplete = forwardRef(
  ({ filter, handleClick, getApi, showLabel, patient, dataCy }, ref) => {
    const [query, setQuery] = useState('');
    const inputRef = useRef();
    const [showDropdownOptions, setShowDropdownOptions] = useState(false);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useOutsideClick(inputRef, () => {
      setShowDropdownOptions(false);
    });

    const generateFilterLabel = (item) => `${item.firstName} ${item.lastName}`;

    const debouncedSearch = useCallback(
      debounce(
        async (queryString) => {
          const {
            data: { items: optionList },
          } = await dataService.getList(getApi(queryString));

          setIsLoading(false);
          if (optionList) {
            setOptions(
              optionList.map((item) => ({
                id: item.guid,
                value: item.guid,
                label: generateFilterLabel(item),
              })),
            );
          }
        },
        800,
        {
          leading: false,
          trailing: true,
        },
      ),
      [],
    );

    const handleInputChange = ({ target }) => {
      if (target) {
        setQuery(target.value);
      }
      setIsLoading(true);
      debouncedSearch(target ? target.value : query);
    };

    const handleOptionClick = (option) => {
      setQuery(option.label);
      handleInputChange(option.label);
      setShowDropdownOptions(false);
      return handleClick(option);
    };

    const handleFocus = () => {
      setShowDropdownOptions(true);
    };

    const autoCompleteClass = () => {
      let className = 'card-border';
      if (showLabel) {
        className = 'card-border card-shadow border-0';
      }
      return className;
    };

    const resetSearch = () => {
      setQuery('');
      handleInputChange('');
      debouncedSearch('');
      handleClick({ value: '' });
    };

    const resetQuery = () => {
      setQuery('');
    };

    useImperativeHandle(ref, () => ({
      resetQuery,
    }));

    return (
      <div className="patient-filter-autocomplete" ref={inputRef}>
        <div className="dropdown" role="button" aria-hidden>
          <Card className={autoCompleteClass()}>
            <CardBody
              className={classNames({
                'card-body-sort': !patient,
              })}
            >
              {showLabel && <p>{filter.label}</p>}
              <div className="dropdown-body">
                <input
                  className={query !== '' ? 'arrow' : ''}
                  placeholder={filter.placeholder}
                  type="text"
                  onChange={handleInputChange}
                  value={query}
                  onFocus={handleFocus}
                  data-cy={dataCy}
                />
                {query !== '' && <ResetSearchIcon onClick={resetSearch} />}
              </div>
            </CardBody>
          </Card>
        </div>
        {isLoading && showDropdownOptions && (
          <div className="patient-filter-dropdown-body">
            <List onChange={() => {}} options={loadingOptions} />
          </div>
        )}
        {!isLoading && options && options.length > 0 && showDropdownOptions && (
          <div className="patient-filter-dropdown-body">
            <List
              dataCy="care-agents-filter-option"
              onChange={handleOptionClick}
              options={options}
            />
          </div>
        )}
      </div>
    );
  },
);

InputAutocomplete.propTypes = {
  filter: PropTypes.shape().isRequired,
  handleClick: PropTypes.func.isRequired,
  getApi: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  patient: PropTypes.bool,
  dataCy: PropTypes.string,
};

export default InputAutocomplete;
