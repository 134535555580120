import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Table, Container } from 'reactstrap';
import common from 'constants/common';
import Pagination from 'components/molecules/pagination';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import withLoader from 'components/organisms/withLoader';
import ActivityRow from 'components/templates/rpm/practice/activityReport/activityRow';
import Sorting from 'components/templates/rpm/practice/activityReport/sorting';
import { showResult } from 'infrastructure/hooks/utils';

const Activity = () => {
  const activity = useSelector((state) => state.doctor);
  const {
    getActivities: activitiesLoading,
    submitComment: submitCommentLoading,
    actionReview: actionReviewLoading,
    reviewed,
  } = useSelector((state) => state.loading.effects.doctor);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const {
    doctor: {
      getActivities,
      getActivityCsvReport,
      setActivitiesPage,
      submitComment,
      actionReview,
      getInsuranceTypes,
      reset: resetActivity,
    },
  } = useDispatch();

  const getDoctorPatientsActivity = useCallback(() => {
    if (activity.dates.length > 0) getActivities({ reviewed: 'all' });
  }, [activity.dates, activity.filters]);

  useEffect(() => {
    resetActivity();
    getInsuranceTypes();
    return () => {
      resetActivity();
    };
  }, []);

  useEffect(() => {
    getDoctorPatientsActivity();
  }, [getDoctorPatientsActivity]);

  const setPage = (pageOffset) => {
    setActivitiesPage({ reviewed: 'all', pageOffset });
  };

  const handleCommentSubmit = async (id, deviceId, values) => {
    const postData = {
      readingId: id,
      deviceId,
      message: values.comment,
      generatedByModel: values.generatedByModel,
      originalGeneratedText: values.originalGeneratedText,
    };
    const { error } = await submitComment(postData);
    showResult(error);
  };

  const handleReviewedCheck = async (id, deviceId) => {
    const postData = {
      readingId: id,
      deviceId,
      reviewed: true,
    };
    const { error } = await actionReview(postData);
    showResult(error);
  };

  const sendReport = async () => {
    if (downloadLoading) {
      return;
    }
    setDownloadLoading(true);
    const { error } = await getActivityCsvReport();
    setDownloadLoading(false);
    return showResult(error, common.submitSuccessTypes.sendReport);
  };

  return (
    <Container>
      <div className="activity activityReport">
        <Card className="card-border">
          <CardBody className="card-outer">
            <div className="performance-review__header">
              <div className="activity-header">
                <h3 className="title">Report: Activity</h3>
              </div>
              <div className="performance-review__header-icon">
                {withLoader(
                  downloadLoading,
                  <button onClick={sendReport}>
                    <DownloadRoundedIcon className="performance-review__header-icon-svg" />
                    <br />
                    <span>Download</span>
                  </button>,
                )}
              </div>
            </div>
            <div className="activity-body">
              <div className="sort-row">
                <Sorting />
              </div>
              {withLoader(
                activitiesLoading,
                <>
                  <div className="table-sticky-header activity-report-table">
                    <Table borderless cellSpacing="0" cellPadding="0">
                      <thead>
                        <tr>
                          <th className="table-status-column">Status</th>
                          <th className="table-time-column">Time</th>
                          <th className="table-patient-column">Patient</th>
                          <th className="table-patient-column">MRN</th>
                          <th className="table-patient-column">Phone Number</th>
                          <th className="table-patient-column">Provider</th>
                          <th className="table-patient-column">Category</th>
                          <th className="table-reading-column">Reading</th>
                          <th className="table-comment-column">
                            Comments/Reviews
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {activity.data.map((activityRow, index) => (
                          <ActivityRow
                            key={`${activityRow.id}-${index}`}
                            activity={activityRow}
                            handleCommentSubmit={handleCommentSubmit}
                            handleReviewedCheck={handleReviewedCheck}
                            action={reviewed}
                            setReviewedLoading={actionReviewLoading}
                            submitCommentLoading={submitCommentLoading}
                          />
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <div className="d-flex align-items-center justify-content-between flex-row-reverse">
                    <Pagination
                      title=""
                      page={activity.page}
                      pageTotal={activity.data.length}
                      itemsPerPage={activity.itemsPerPage}
                      total={activity.totalCount}
                      handlePrevClick={() => setPage(-1)}
                      handleNextClick={() => setPage(+1)}
                      handleFirstClick={() => setPage(-(activity.page - 1))}
                      handleLastClick={() =>
                        setPage(
                          Math.ceil(
                            activity.totalCount / activity.itemsPerPage,
                          ) - activity.page,
                        )
                      }
                      loading={activitiesLoading}
                    />
                  </div>
                </>,
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default Activity;
