import React from 'react';

const ArrowDownIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.99503 13.7737L13.0773 13.7676C13.2843 13.7675 13.4531 13.5986 13.4539 13.391L13.4926 3.27616C13.4968 2.18801 12.6177 1.30324 11.533 1.3039C10.4484 1.30457 9.56254 2.19041 9.55838 3.27857L9.54352 7.16051L3.30965 0.886227C2.58124 0.153092 1.39047 0.15382 0.656441 0.887848C-0.0775871 1.62188 -0.0821592 2.81649 0.646254 3.54962L6.88015 9.82393L3.0107 9.82629C1.92605 9.82695 1.04021 10.7128 1.03604 11.801C1.03133 12.8886 1.91094 13.7739 2.99503 13.7737Z"
      fill="#EF686D"
    />
  </svg>
);

export default ArrowDownIcon;
