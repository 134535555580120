import { useEffect, useState, useMemo } from 'react';
import { get, set } from 'idb-keyval';
import { formSections } from 'constants/ccmClinicalNotesForms';
import { ccmClinicalNotesTemplate } from 'constants/ccmClinicalNotesTemplate';
import { getAgeByDOB } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';
import mustache from 'mustache';
import { isObjectEmpty } from 'infrastructure/functions';
import { useGetMedicalHistory } from 'infrastructure/hooks/ccm';
import { useGetMedication } from 'infrastructure/hooks/ccm/medication/use-get-medication';
import { useGetConditions } from 'infrastructure/hooks/ccm/patient-goals/use-get-conditions';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { useDoctorInfo } from 'infrastructure/hooks/doctor/use-doctor-info';

import { addSpaceAfterComma, formatTemplateValues } from './templateHelpers';
import { generateFullName } from './badgeHelpers';
import { isCcmActionAllowedStatus } from './userTypeHelper';

const getDefaultValues = ({
  patient,
  medicalHistory,
  icdCodes,
  medications,
  doctorInfo,
}) => {
  const defaultValues = { appttype: 'follow up', program: 'CCM' };

  if (patient?.loginInfo?.birthDate) {
    const age = getAgeByDOB(patient.loginInfo.birthDate);
    defaultValues.age = age;
  }
  if (patient?.gender) {
    const { gender } = patient;
    defaultValues.gender = gender.charAt(0).toUpperCase() + gender.slice(1);
  }
  if (doctorInfo) {
    const { title, firstName, middleName, lastName, professionType } =
      doctorInfo;

    const doctor = generateFullName(
      title,
      firstName,
      middleName,
      lastName,
      professionType,
    );
    defaultValues.doctor = doctor;
  }

  if (medicalHistory?.length) {
    defaultValues.dx1 = '';
    medicalHistory.forEach((item) => {
      const { record } = item;
      if (!defaultValues.dx1) defaultValues.dx1 += record;
      else defaultValues.dx1 += `, ${record}`;
    });
  }

  if (patient.ccmStatus === 'ccm_onboarding') {
    defaultValues.appttype = 'onboarding';
  }

  if (patient.pcmStatus === true) {
    defaultValues.program = 'PCM';
  }

  if (icdCodes.length) {
    defaultValues.icdCodes = icdCodes
      .map((item) => item.description)
      .join(', ');
  }

  if (medications.length) {
    defaultValues.patientmedications = medications
      .map((item) => item.name)
      .join(', ');
  }

  return defaultValues;
};

const getCacheId = (patientGuid) => `ccmNarrativeForm-${patientGuid}`;

const cacheValues = async (patientGuid, values) => {
  await set(getCacheId(patientGuid), values);
};

const getValuesFromCache = async (patientGuid) => get(getCacheId(patientGuid));

const useNarrativeModal = ({
  defaultValues = {},
  onSubmit,
  closeModal,
  answers,
  patientGuid,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [values, setValues] = useState(defaultValues);
  const { medicalHistory, isLoading: medicalHistoryLoading } =
    useGetMedicalHistory({
      patientGuid,
      meta: { page: 1, items: 1000 },
    });
  const { medication, isLoading: medicationLoading } = useGetMedication({
    patientGuid,
    meta: { page: 1, items: 1000 },
    isActive: true,
  });
  const { goals, isLoading: conditionsLoading } = useGetConditions({
    patientGuid,
    meta: { items: 1000, page: 1 },
  });
  const { patientInfo, loading } = usePatientInfo({ patientGuid });
  const { doctorInfo, doctorInfoLoading } = useDoctorInfo({
    doctorGuid: patientInfo?.doctorGuid,
  });

  const canEditPage = isCcmActionAllowedStatus(patientInfo?.ccmStatus);

  const isLoading =
    medicalHistoryLoading ||
    medicationLoading ||
    conditionsLoading ||
    loading ||
    doctorInfoLoading;

  useEffect(() => {
    if (!isLoading) {
      getValuesFromCache(patientGuid).then((valuesFromCache) => {
        if (!isObjectEmpty(valuesFromCache)) {
          // HOTFIX: remove DME from cache
          valuesFromCache.DME = undefined;
          setValues(valuesFromCache);
        } else {
          const newDefaultValues = getDefaultValues({
            patient: patientInfo,
            answers,
            medicalHistory,
            icdCodes: goals?.items,
            medications: medication?.items,
            doctorInfo,
          });
          setValues(newDefaultValues);
        }
      });
    }
  }, [isLoading]);

  const errorSections = useMemo(() => {
    const sections = [];
    const fields = formSections.map((section) => {
      return section.forms.filter((form) => {
        let { isRequired } = form;
        if (form.requiredIf) {
          isRequired = values[form.requiredIf.key] === form.requiredIf.value;
        }
        if (isRequired) {
          const value = values[form.id];
          if ((Array.isArray(value) && !value.length) || !value) {
            return form;
          }
        }
        return null;
      });
    });
    fields.forEach((el, index) => {
      if (el.length) {
        sections.push(index + 1);
      }
    });
    return sections;
  }, [values]);

  useEffect(() => {
    cacheValues(patientGuid, values);
  }, [values]);

  const setValue = (value) => setValues((prev) => ({ ...prev, ...value }));
  const goToPrevStep = () => setCurrentStep((prev) => prev - 1);
  const goToNextStep = () => setCurrentStep((prev) => prev + 1);

  const submit = () => {
    const lowerCaseChoicesIds = ['medwhy', 'vitals'];
    const formattedValues = formatTemplateValues(values, lowerCaseChoicesIds);
    const conditions = {
      data: formattedValues,
      conditionsYes: !!formattedValues.icdCodes,
      medicalHistoryYes: !!formattedValues.dx1,
      pastAppointmentYes: !!formattedValues.pastapptanwsers,
      labsYes: !!formattedValues.labs,
      upcomingAppointmentYes: !!formattedValues.upcomingapptanwsers,
      nonComplaintYes: !!formattedValues.medwhy,
      activemedicationsYes: formattedValues.activemedications === 'Yes',
      medChangesYes: formattedValues.medChanges !== 'no changes in medication',
      lastReadingYes: !!formattedValues.rpm,
      vitalsYes: !!formattedValues.vitals,
      appetiteYes: !!formattedValues.appetite,
      nutritionYes: !!formattedValues.nutrition,
      waterYes: !!formattedValues.water,
      painYes: formattedValues.pain === 'Yes',
      skinYes: !!formattedValues.skin,
      respiratoryYes: !!formattedValues.respiratory,
      sleepYes: !!formattedValues.sleep,
      bowelYes: !!formattedValues.bowel,
      bladderYes: !!formattedValues.bladder,
      dmeYes: !!formattedValues.DME,
      safetyYes: !!formattedValues.safety,
      resourcesYes: !!formattedValues.resources,
      educationYes: formattedValues.sendEducation === 'Yes',
      extraYes: !!formattedValues.extra,
      nextApptWillBeByPhoneYes:
        formattedValues.nextApptWillBeBy === 'Phone/Video Conference',
      nextApptWillBeBySmsOrEmailYes:
        formattedValues.nextApptWillBeBy === 'Email' ||
        formattedValues.nextApptWillBeBy === 'SMS',
    };
    try {
      const resultText = mustache.render(ccmClinicalNotesTemplate, conditions);
      const formattedResultText = resultText
        .replaceAll('&#x2F;', '/')
        .replaceAll('&#39;', "'");
      onSubmit(addSpaceAfterComma(formattedResultText));
      closeModal();
    } catch (error) {
      showResult(`Error:${error.toString()}`);
    }
  };
  useEffect(() => {
    const modalElement = document.querySelector('#draggable-modal');
    modalElement?.scroll({
      top: 0,
    });
  }, [currentStep]);

  return {
    values,
    currentStep,
    errorSections,
    canEditPage,
    isLoading,
    setValue,
    goToPrevStep,
    goToNextStep,
    submit,
  };
};

export default useNarrativeModal;
