import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import {
  fullNamePresenter,
  getLanguageLabelById,
  normalizeTimezone,
} from 'utils';

const Info = ({ data }) => {
  return (
    <div className="admin-agent-block">
      <span>Personal Info</span>
      <Table>
        <tbody>
          <tr>
            <td>Name</td>
            <td>{fullNamePresenter(data)}</td>
          </tr>
          {data.mobileNumber && (
            <tr>
              <td>User Phone</td>
              <td>{data.mobileNumber}</td>
            </tr>
          )}
          {data.email && (
            <tr>
              <td>User Email</td>
              <td>{data.email}</td>
            </tr>
          )}
          {data.primaryLanguage && (
            <tr>
              <td>Primary Language</td>
              <td>{getLanguageLabelById(data.primaryLanguage)}</td>
            </tr>
          )}
          {data.secondaryLanguage && (
            <tr>
              <td>Secondary Language</td>
              <td>{getLanguageLabelById(data.secondaryLanguage)}</td>
            </tr>
          )}
          {data.timezone && (
            <tr>
              <td>Timezone</td>
              <td>{normalizeTimezone(data.timezone)}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

Info.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Info;
