import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const contactAvailabilityTimesValidationSchema = () =>
  createValidationSchema({
    times: commonValidationSchemas.array(
      createValidationSchema({
        startTime: commonValidationSchemas.string({ required: true }),
        endTime: commonValidationSchemas.string({ required: true }),
      }),
    ),
  });
