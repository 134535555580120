import type { CSSProperties, FC } from 'react';

interface Props {
  up?: boolean;
  down?: boolean;
  style?: CSSProperties;
}

const ArrowSortIcon: FC<Props> = ({ down, up, style }) => {
  return (
    <svg
      width="10"
      height="16"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      style={style}
    >
      <g>
        <path
          fill={up ? '#413B56' : '#cccad7'}
          d="m5,12.6625l3.1464,-2.79683c0.1953,-0.17356 0.5119,-0.17356 0.7072,0c0.1952,0.17357 0.1952,0.45498 0,0.62854l-3.50005,3.11109c-0.19526,0.1736 -0.51184,0.1736 -0.7071,0l-3.5,-3.11109c-0.19527,-0.17356 -0.19527,-0.45497 0,-0.62854c0.19526,-0.17356 0.51184,-0.17356 0.7071,0l3.14645,2.79683z"
        />
        <path
          fill={down ? '#413B56' : '#cccad7'}
          d="m4.95146,3.07298l-3.14645,2.79685c-0.19526,0.17356 -0.51184,0.17356 -0.7071,0c-0.19527,-0.17357 -0.19527,-0.45498 0,-0.62854l3.5,-3.11112c0.19526,-0.17356 0.51184,-0.17356 0.7071,0l3.50005,3.11112c0.1952,0.17356 0.1952,0.45497 0,0.62854c-0.1953,0.17356 -0.5119,0.17356 -0.7072,0l-3.1464,-2.79685z"
        />
      </g>
    </svg>
  );
};

export default ArrowSortIcon;
