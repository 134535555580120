import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { rpmStatusesDataSource } from 'infrastructure/data-sources/rpm-statuses';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IRpmStatusItem } from 'infrastructure/data-sources/rpm-statuses';

type Options = IBaseSelectBoxOption<IRpmStatusItem['id'], IRpmStatusItem>[];

interface IRpmStatusSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {}

const RpmStatusSelectBox: React.FC<IRpmStatusSelectBoxProps> = (props) => {
  const dataSource = useMemo(() => rpmStatusesDataSource(), []);

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...props} />;
};

export default RpmStatusSelectBox;
