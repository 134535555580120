import queryString from 'query-string';
import { pickBy, identity } from 'lodash';

import { API_ADMIN_PANEL_AGENCIES } from './constants';

export const apiGetAgenciesByFilter = (filter) =>
  `${API_ADMIN_PANEL_AGENCIES}?${queryString.stringify(filter)}`;

export const apiGetAgencyByGuid = (guid) =>
  `${API_ADMIN_PANEL_AGENCIES}/${guid}`;
export const apiAdminPanelAgenciesGet = (items, page, filters) => {
  const endpoint = API_ADMIN_PANEL_AGENCIES;
  const query = queryString.stringify(
    pickBy(
      {
        guid: filters.guid,
        page,
        items,
      },
      identity,
    ),
  );
  return `${endpoint}?${query}`;
};
