import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import withLoader from 'components/organisms/withLoader';
import { showResult } from 'infrastructure/hooks/utils';
import EditButton from 'components/atoms/editButton';
import { Card, CardBody, Table } from 'reactstrap';

import Info from './components/info';
import Profession from './components/profession';
import Registration from './components/registration';
import IRS from './components/irs';
import Address from './components/address';

const AgencyDoctor = () => {
  const navigate = useNavigate();
  const {
    adminPanelAgencyDoctors: { getAgencyDoctor },
  } = useDispatch();
  const { agentGuid } = useParams();

  useEffect(() => {
    const run = async () => {
      try {
        await getAgencyDoctor(agentGuid);
      } catch (e) {
        showResult(e);
        navigate(`/404`);
      }
    };
    run();
  }, [getAgencyDoctor]);

  const agencyDoctor = useSelector(
    (state) => state.adminPanelAgencyDoctors.agencyDoctor,
  );
  const { getAgencyDoctor: doctorDetailsLoading } = useSelector(
    (state) => state.loading.effects.adminPanelAgencyDoctors,
  );

  const doctorStatus = agencyDoctor.status;

  return (
    <div className="admin-agent">
      <Card className="card-border">
        <CardBody>
          {withLoader(
            doctorDetailsLoading,
            <>
              {agencyDoctor && (
                <>
                  <div className="admin-agent-header">
                    <span className="admin-header__title">HHA Agent Page</span>
                    <div className="admin-agent-header-actions">
                      <div
                        className="admin-agent-header-actions-status badge"
                        data-cy="agency-user-status"
                      >
                        {doctorStatus}
                      </div>
                      <div className="admin-agent-header-actions-edit">
                        <Link
                          data-cy="hha-agent__edit-link"
                          to={`/admin/agent/${agencyDoctor.guid}/edit`}
                        >
                          <EditButton data={agencyDoctor} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="admin-agent-info">
                    <Table>
                      <tbody>
                        <tr>
                          <td rowSpan="2" className="add-border-top-right">
                            <Info data={agencyDoctor} />
                          </td>
                          <td className="add-border-top">
                            <Profession data={agencyDoctor} />
                          </td>
                        </tr>
                        <tr>
                          <td className="add-border-top">
                            <Registration data={agencyDoctor} />
                          </td>
                        </tr>
                        <tr>
                          <td className="add-border-top-right">
                            <Address data={agencyDoctor} />
                          </td>
                          <td className="add-border-top">
                            <IRS data={agencyDoctor} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </>,
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default AgencyDoctor;
