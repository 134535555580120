import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/atoms/input';

const TimePickerInput = ({ id, label, error, note, required, format }) => (
  <div className="input-wrapper">
    <Input
      type="time"
      id={id}
      label={label}
      markRequired={required}
      format={format}
    />
    {error && <div className="input-error">{error}</div>}
    {note && <div className="input-note">{note}</div>}
  </div>
);
TimePickerInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  note: PropTypes.string,
  required: PropTypes.bool,
  format: PropTypes.string,
};
export default TimePickerInput;
