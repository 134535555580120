import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_MEDICATION } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';
import useUser from 'utils/useUser';

import { getMedicationKey } from './use-get-medication';

import type {
  IAddMedicationResponse,
  IAddMedicationData,
  IMedicationResponse,
} from 'infrastructure/interfaces';

interface Props {
  page: number;
  items: number;
  isActive?: boolean;
}

const editMedicationFn = async (dataProps: IAddMedicationData) => {
  const url = API_MEDICATION;
  const { error, data } = await dataService.updateOnly<IAddMedicationResponse>(
    `${url}/${dataProps.patientGuid}`,
    dataProps,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useEditMedication = (props: Props) => {
  const queryClient = useQueryClient();
  const { firstName, lastName, title, professionType, loginGuid } = useUser();

  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: editMedicationFn,
    onSuccess: (data, vars) => {
      showResult();
      const queryKey = getMedicationKey({
        ...props,
        patientGuid: vars.patientGuid,
      });
      queryClient.setQueryData<IMedicationResponse>(queryKey, (oldData) => {
        if (oldData && data) {
          const newData = structuredClone(oldData);
          const updatedItems = newData.items.map((i) => {
            if (i.guid === data.guid) {
              return {
                ...i,
                ...data,
                updatedBy: {
                  firstName,
                  lastName,
                  title,
                  profession: professionType ?? '',
                  loginGuid,
                },
                updatedAt: new Date().toISOString(),
              };
            }

            return i;
          });
          newData.items = updatedItems;

          return newData;
        }

        return oldData;
      });
    },
  });

  return {
    editMedication: mutate,
    editLoading: isLoading,
    ...other,
  };
};
