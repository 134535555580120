import React from 'react';
import PropTypes from 'prop-types';

import PaginationCaption from './caption';
import PaginationOrder from './order';

const Pagination = ({
  title,
  page,
  pageTotal,
  itemsPerPage,
  total,
  loading,
  handlePrevClick,
  handleNextClick,
  handleLastClick,
  handleFirstClick,
  reversed,
  reversedOnChange,
  renderOrder,
  renderCaption,
}) => (
  <div className="pagination-container">
    <div className="pagination-container__title--wrapper">
      <h5>{title}</h5>
      {renderOrder && (
        <PaginationOrder reversed={reversed} onChange={reversedOnChange} />
      )}
    </div>
    <div className="pagination-body">
      {page > 1 && (
        <>
          <div className="pagination-buttons">
            <button
              data-cy="pagination-first"
              type="button"
              aria-label="Previous"
              className="first"
              onClick={handleFirstClick}
              disabled={loading}
            />
          </div>
          <div className="pagination-buttons">
            <button
              data-cy="pagination-prev"
              type="button"
              aria-label="Previous"
              className="prev"
              onClick={handlePrevClick}
              disabled={loading}
            />
          </div>
        </>
      )}
      {renderCaption && (
        <>
          <PaginationCaption
            page={page}
            pageTotal={pageTotal}
            itemsPerPage={itemsPerPage}
            total={total || 1}
          />
          {(page - 1) * itemsPerPage + pageTotal < total && (
            <>
              <div className="pagination-buttons">
                <button
                  data-cy="pagination-next"
                  type="button"
                  aria-label="Next"
                  className="next"
                  onClick={handleNextClick}
                  disabled={loading}
                />
              </div>
              <div className="pagination-buttons">
                <button
                  data-cy="pagination-last"
                  type="button"
                  aria-label="last"
                  className="last"
                  onClick={handleLastClick}
                  disabled={loading}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  </div>
);

Pagination.defaultProps = {
  title: '',
  loading: false,
  renderCaption: true,
};

Pagination.propTypes = {
  title: PropTypes.string,
  page: PropTypes.number,
  pageTotal: PropTypes.number,
  itemsPerPage: PropTypes.number,
  total: PropTypes.number,
  loading: PropTypes.bool,
  handlePrevClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func.isRequired,
  handleLastClick: PropTypes.func.isRequired,
  handleFirstClick: PropTypes.func.isRequired,
  reversed: PropTypes.bool,
  reversedOnChange: PropTypes.func,
  renderOrder: PropTypes.bool,
  renderCaption: PropTypes.bool,
};

export default Pagination;
