import dataService from 'services/dataService';
import { API_CLINICS } from 'services/dataService/resources';

const clinics = {
  state: {
    data: [],
  },
  reducers: {
    setClinics: (state, payload) => ({
      ...state,
      data: payload,
    }),
  },
  effects: (dispatch) => ({
    async getClinics() {
      const { data } = await dataService.getList(API_CLINICS);
      dispatch.clinics.setClinics(data || []);
    },
  }),
};

export default clinics;
