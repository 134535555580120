import React, { useEffect } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import useTable from 'utils/useTable';
import withLoader from 'components/organisms/withLoader';
import { API_CRITICAL_READINGS } from 'services/dataService/resources';
import Pagination from 'components/molecules/pagination';

import PatientRow from './patientRow';

const PER_PAGE = 100;

const CriticalReport = ({ filters }) => {
  const {
    data: { items },
    count,
    page,
    loading,
    filters: tableFilters,
    setPage,
    setFilters,
  } = useTable(API_CRITICAL_READINGS, PER_PAGE, filters);

  useEffect(() => {
    if (
      filters.clinicGuid !== tableFilters.clinicGuid ||
      filters.childClinic !== tableFilters.childClinic
    ) {
      setFilters({
        clinicGuid: filters.clinicGuid,
        childClinic: filters.childClinic,
      });
    }
  }, [filters.clinicGuid, filters.childClinic]);

  return (
    <div className="critical-report">
      <Card className="card-border">
        <CardBody className="clinical-metrics">
          <div className="critical-report-table-title">
            Highest Risk Patients
          </div>
          <div className="critical-report-table-sub-title">
            Number of Critical Readings in Last 30 Days
          </div>
          <div className="critical-report-table">
            <Table borderless cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th className="name">Patient Name</th>
                  <th>MRN</th>
                  <th>Critical Readings</th>
                </tr>
              </thead>

              {withLoader(
                loading,
                <tbody>
                  {items.map((item) => (
                    <PatientRow item={item} key={item.patientGuid} />
                  ))}
                </tbody>,
              )}
            </Table>
          </div>
          <div className="ccm-table-section__pagination">
            <Pagination
              title=""
              page={page}
              pageTotal={items.length}
              itemsPerPage={PER_PAGE}
              total={count}
              handleFirstClick={() => setPage(1)}
              handleLastClick={() => setPage(Math.ceil(count / PER_PAGE))}
              handlePrevClick={() => setPage(page - 1)}
              handleNextClick={() => setPage(page + 1)}
              handleGoToPage={(numberPage) => setPage(numberPage)}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

CriticalReport.propTypes = {
  filters: PropTypes.shape({
    clinicGuid: PropTypes.string,
    childClinic: PropTypes.bool,
  }),
};

export default CriticalReport;
