import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';
import { API_CLINICS } from 'services/dataService/resources';

import type { IClinic } from 'infrastructure/interfaces';

const searchClinicsFn = async (searchValue: string) => {
  const { data, error } = await dataService.getList<IClinic[]>(
    apiUrlGenerator(API_CLINICS, { legalname: searchValue }),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data?.map((clinic) => ({
    value: clinic.guid,
    label: clinic.legalname,
  }));
};

const getClinicByGuidFn = async (guid: string) => {
  const { data, error } = await dataService.getOne<IClinic>(API_CLINICS, guid);

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data
    ? [data]?.map((clinic) => ({
        value: clinic.guid,
        label: clinic.legalname,
      }))
    : undefined;
};

export const useSearchClinics = () => {
  const {
    mutate: getClinicByGuid,
    data: clinic,
    isLoading: clinicLoading,
    reset,
  } = useMutation({
    mutationFn: getClinicByGuidFn,
  });

  const { mutate, data, isLoading } = useMutation({
    mutationFn: searchClinicsFn,
    onMutate() {
      if (clinic) reset();
    },
  });

  return {
    searchClinics: mutate,
    getClinicByGuid,
    clinics: clinic || data,
    searchClinicsLoading: clinicLoading || isLoading,
  };
};
