import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const adminPanelPriorAuthorizationValidationSchema = () =>
  createValidationSchema({
    authorizationNumber: commonValidationSchemas.number({ required: true }),
    startDate: commonValidationSchemas.date({
      required: true,
    }),
    endDate: commonValidationSchemas.date({
      required: true,
    }),
  });
