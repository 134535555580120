import i18next from 'i18next';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { createWaitPromise } from 'utils/helpers/timer';
import { generateRandomId } from 'utils/random';

import type { Nullable } from 'infrastructure/types';

const UNDO_TIMEOUT_SECONDS = 5;

export const readingCommentWithUndo = ({
  readingId,
  deviceId,
  message,
  createdBy,
  createdByFirstName,
  createdByLastName,
  createdByProfessionType,
  createdByTitle,
}: {
  readingId: string;
  deviceId: string;
  message: string;
  createdBy: string;
  createdByFirstName: string;
  createdByLastName: string;
  createdByProfessionType: Nullable<string>;
  createdByTitle: string;
}) => {
  const mockComment = {
    guid: generateRandomId(),
    message,
    deviceId,
    readingId,
    type: 'text',
    reviewed: false,
    callId: null,
    createdAt: new Date().toISOString(),
    createdBy,
    createdByFirstName,
    createdByLastName,
    createdByProfessionType,
    createdByTitle,
    showLoader: true,
  };

  const { cancel, promise: undoTimeoutPromise } = createWaitPromise(
    UNDO_TIMEOUT_SECONDS * 1000,
  );

  notificationController.showUndo({
    duration: UNDO_TIMEOUT_SECONDS,
    message: i18next.t('labels.commentSubmitting'),
    onUndo: () => {
      cancel();
      notificationController.info({
        message: i18next.t('labels.commentUndone'),
      });
    },
  });

  return {
    mockComment,
    undoTimeoutPromise,
  };
};
