export enum BloodPressureConsumables {
  'CUFF_SMALL_U807' = 'CUFF_SMALL_U807',
  'CUFF_ADULT_U807' = 'CUFF_ADULT_U807',
  'CUFF_XL_U807' = 'CUFF_XL_U807',
  'CUFF_SMALL_A600N' = 'CUFF_SMALL_A600N',
  'CUFF_ADULT_A600N' = 'CUFF_ADULT_A600N',
  'CUFF_XL_A600N' = 'CUFF_XL_A600N',
  'CUFF_SMALL_BP01' = 'CUFF_SMALL_BP01',
  'CUFF_ADULT_BP01' = 'CUFF_ADULT_BP01',
  'CUFF_XL_BP01' = 'CUFF_XL_BP01',
}
