import i18next from 'i18next';
import { ChargerConsumables, ReadingType } from 'infrastructure/enums';

export interface IChargerConsumablesItem {
  id: ChargerConsumables;
  name: string;
}

interface IProps {
  readingType?: ReadingType;
}

export const chargerConsumablesDataSource = ({
  readingType,
}: IProps): IChargerConsumablesItem[] => {
  const items: IChargerConsumablesItem[] = [];

  const chargerMap = {
    [ChargerConsumables['G427B-PWR']]: {
      id: ChargerConsumables['G427B-PWR'],
      name: i18next.t('consumables.G427B-PWR'),
    },
    [ChargerConsumables['4GTherm-PWR']]: {
      id: ChargerConsumables['4GTherm-PWR'],
      name: i18next.t('consumables.4GTherm-PWR'),
    },
    [ChargerConsumables['TSB8057-PWR']]: {
      id: ChargerConsumables['TSB8057-PWR'],
      name: i18next.t('consumables.TSB8057-PWR'),
    },
    [ChargerConsumables['SLPMAT-PWR']]: {
      id: ChargerConsumables['SLPMAT-PWR'],
      name: i18next.t('consumables.SLPMAT-PWR'),
    },
    [ChargerConsumables['U807-PWR']]: {
      id: ChargerConsumables['U807-PWR'],
      name: i18next.t('consumables.U807-PWR'),
    },
    [ChargerConsumables['A600N-PWR']]: {
      id: ChargerConsumables['A600N-PWR'],
      name: i18next.t('consumables.A600N-PWR'),
    },
    [ChargerConsumables['BP01-PWR']]: {
      id: ChargerConsumables['BP01-PWR'],
      name: i18next.t('consumables.BP01-PWR'),
    },
    [ChargerConsumables['USB-WC']]: {
      id: ChargerConsumables['USB-WC'],
      name: i18next.t('consumables.USB-WC'),
    },
  };

  if (readingType === ReadingType.BloodGlucose) {
    items.push(chargerMap[ChargerConsumables['G427B-PWR']]);
  } else if (readingType === ReadingType.Temperature) {
    items.push(chargerMap[ChargerConsumables['4GTherm-PWR']]);
  } else if (readingType === ReadingType.Weight) {
    items.push(chargerMap[ChargerConsumables['TSB8057-PWR']]);
  } else if (
    readingType === ReadingType.SleepingMat ||
    readingType === ReadingType.SleepingMatDuration ||
    readingType === ReadingType.SleepingMatActivity
  ) {
    items.push(chargerMap[ChargerConsumables['SLPMAT-PWR']]);
  } else if (readingType === ReadingType.BloodPressure) {
    items.push(
      chargerMap[ChargerConsumables['U807-PWR']],
      chargerMap[ChargerConsumables['A600N-PWR']],
      chargerMap[ChargerConsumables['BP01-PWR']],
    );
  } else {
    items.push(
      chargerMap[ChargerConsumables['USB-WC']],
      chargerMap[ChargerConsumables['U807-PWR']],
      chargerMap[ChargerConsumables['A600N-PWR']],
      chargerMap[ChargerConsumables['4GTherm-PWR']],
      chargerMap[ChargerConsumables['TSB8057-PWR']],
      chargerMap[ChargerConsumables['G427B-PWR']],
      chargerMap[ChargerConsumables['BP01-PWR']],
      chargerMap[ChargerConsumables['SLPMAT-PWR']],
    );
  }

  return items;
};
