import BaseTag from './BaseTag';
import ExclamationPointIcon from '../icons/exclamationPointIcon';

import type { FC } from 'react';

interface Props {
  small?: boolean;
}

const DoNotCallTag: FC<Props> = ({ small }) => {
  return (
    <BaseTag
      text="Do Not Call"
      title="Do Not Call"
      color="red"
      icon={<ExclamationPointIcon />}
      dataCy="do-not-call-tag"
      small={small}
    />
  );
};

export default DoNotCallTag;
