import React from 'react';
import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';
import { init as initApm } from '@elastic/apm-rum';
import { useNavigate } from 'react-router-dom';

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const AUTH0_CONNECTION = process.env.REACT_APP_AUTH0_CONNECTION;

const apmServerUrl = process.env.REACT_APP_APM_SERVER_URL;
const rogerEnv = process.env.REACT_APP_ENV || 'local';
const rogerVersion = process.env.APP_VERSION || '1.0.0';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  if (apmServerUrl && rogerEnv !== 'local') {
    initApm({
      serviceName: `roger-${rogerEnv}-frontend`,
      serverUrl: apmServerUrl,
      serviceVersion: rogerVersion,
      environment: rogerEnv,
      transactionSampleRate: 0.4,
    });
  }

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={AUTH0_AUDIENCE}
      cacheLocation="localstorage"
      connection={AUTH0_CONNECTION}
      useRefreshTokens="true"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.object,
};

export default AuthProvider;
