import { useMutation } from '@tanstack/react-query';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';

import { resetPasswordFn } from './api';

export const useResetPassword = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: resetPasswordFn,
    onSuccess() {
      notificationController.success({
        message: 'Patient`s password has been successfully reset.',
      });
    },
  });

  return { resetPassword: mutate, resetPassLoading: isLoading };
};
