import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import createPagination from 'utils/createPagination';

const PaginationCaption = ({
  page,
  itemsPerPage,
  total,
  loading,
  handleGoToPage,
}) => {
  const pagination = createPagination(page, itemsPerPage, total);
  return (
    <div className="numbers-container">
      {pagination.map((num) => (
        <div
          key={num}
          onClick={() => {
            if (num > 0 && !loading) handleGoToPage(num);
          }}
          data-cy="pagination__numbers"
          className={classNames('item', {
            dots: num < 0,
            currentPage: page === num,
          })}
        >
          {num > 0 ? num : '...'}
        </div>
      ))}
    </div>
  );
};

PaginationCaption.defaultProps = {
  page: 0,
  itemsPerPage: 0,
  total: 0,
  loading: false,
};

PaginationCaption.propTypes = {
  page: PropTypes.number,
  itemsPerPage: PropTypes.number,
  total: PropTypes.number,
  loading: PropTypes.bool,
  handleGoToPage: PropTypes.func.isRequired,
};

export default PaginationCaption;
