import { useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeZones } from 'constants/forms';

import BaseSelect from './base';

import type { Option } from './base';

interface Props {
  value?: Option[];
  showSelectAll?: boolean;
  onChange: (e: Option[]) => void;
}

const TimezoneSelect: FC<Props> = ({
  onChange,
  value,
  showSelectAll = false,
}) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return TimeZones.map((item) => ({
      label: item.label,
      value: item.id,
      title: item.label,
    }));
  }, []);

  return (
    <BaseSelect
      label={t('labels.timezone')}
      placeholder={t('labels.all')}
      mode="multiple"
      showSelectAll={showSelectAll}
      options={options}
      value={value}
      onChange={(newValues) => {
        const items = Array.isArray(newValues) ? newValues : [newValues];
        onChange(items);
      }}
    />
  );
};

export default TimezoneSelect;
