type ObjectWithOptionalFields<T> = {
  [K in keyof T]?: T[K];
};

export const mergeFieldsValue = <T extends Record<string, any>>(
  target: T,
  source: ObjectWithOptionalFields<T>,
): T => {
  const result = structuredClone(target);
  const keys = Object.keys(target) as (keyof T)[];

  for (const key of keys) {
    const value = source[key];
    if (value !== undefined && value !== null && value !== '') {
      result[key] = value;
    }
  }

  return result;
};
