import { useInterval } from 'react-use';
import { isAfter, secondsToMilliseconds } from 'date-fns';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';

interface Props {
  readings: any[];
  readingsReviewResponseTime?: number;
  removeAllReadings: () => void;
}

const CHECK_INTERVAL_SECONDS = 10;
const CHECK_INTERVAL = secondsToMilliseconds(CHECK_INTERVAL_SECONDS);

const useReviewResponseTimeout = ({
  readings,
  readingsReviewResponseTime,
  removeAllReadings,
}: Props) => {
  useInterval(() => {
    if (!readingsReviewResponseTime || readings.length === 0) {
      return;
    }

    const responseTimeReached = isAfter(new Date(), readingsReviewResponseTime);
    if (responseTimeReached) {
      removeAllReadings();
      notificationController.info({
        message:
          'Readings removed due to max time reached, please use "Get Readings" button to assign new readings',
      });
    }
  }, CHECK_INTERVAL);
};

export default useReviewResponseTimeout;
