import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const useConfirm =
  ({ message, title }: { message: string; title: string }) =>
  (): Promise<boolean> =>
    new Promise((resolve) => {
      confirmAlert({
        title,
        buttons: [
          {
            label: 'No',
            onClick: () => {
              resolve(false);
            },
            className: 'confirm__btn',
          },
          {
            label: 'Yes',
            onClick: () => {
              resolve(true);
            },
            className: 'confirm__btn confirm__btn__primary',
          },
        ],
        onClickOutside: () => {
          resolve(false);
        },
        onKeypressEscape: () => {
          resolve(false);
        },
        childrenElement: () => (
          <p className="react-confirm-alert-message">{message}</p>
        ),
      });
    });

export default useConfirm;
