import MedicalHistoryIcon from 'components/atoms/icons/medicalHistoryIcon';
import { useTranslation } from 'react-i18next';
import BaseButton from 'components/atoms/baseButton';
import { useState, type FC } from 'react';
import BaseModal from 'components/atoms/base-modal';
import { useAddMedicalHistory } from 'infrastructure/hooks/ccm';
import MedicalHistoryForm from 'components/molecules/admin-panel/forms/medical-history';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { isCcmActionAllowedStatus } from 'utils/userTypeHelper';

import MedicalHistoryTable from './components/medical-table';
import s from './styles.module.scss';

import type { Values } from 'components/molecules/admin-panel/forms/medical-history/validation-schema';

interface PastMedicalHistoryProps {
  patientGuid: string;
}

const PastMedicalHistory: FC<PastMedicalHistoryProps> = ({ patientGuid }) => {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { addMedicalHistory, addLoading } = useAddMedicalHistory();
  const { patientInfo, loading } = usePatientInfo({ patientGuid });
  const disableButton = !isCcmActionAllowedStatus(patientInfo?.ccmStatus);
  const toggleOpen = () => setOpen((prev) => !prev);

  const onSubmit = async ({ record }: Values) => {
    if (record)
      addMedicalHistory(
        { patientGuid, record },
        { onSuccess: () => setOpen(false) },
      );
  };

  const onCancelForm = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={s.wrapper} id="medical-history">
        <div className={s['title-wrapper']}>
          <h4 className={s.title}>
            <MedicalHistoryIcon />
            {t('labels.pastMedicalHistory')}
          </h4>
          <BaseButton
            label={`+ ${t('labels.addHistory')}`}
            dataCy="addHistory-button"
            onClick={toggleOpen}
            loading={addLoading}
            disabled={disableButton || loading}
          />
        </div>
        <div className={s.divider} />
        <MedicalHistoryTable
          patientGuid={patientGuid}
          page={page}
          setPage={setPage}
          disableActions={disableButton}
        />
      </div>
      <BaseModal
        title={t('labels.addHistory')}
        open={open}
        onCancel={toggleOpen}
      >
        <MedicalHistoryForm
          loading={addLoading}
          onCancelForm={onCancelForm}
          onSubmit={onSubmit}
        />
      </BaseModal>
    </>
  );
};
export default PastMedicalHistory;
