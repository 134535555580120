import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_QUESTIONS } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';
import { ModuleType } from 'infrastructure/enums';
import {
  isCcmModule,
  reverseQuestionnaireAnswers,
} from 'infrastructure/functions';

import type { IQuestionGroup } from 'infrastructure/interfaces';

interface Props {
  patientGuid: string;
  module?: ModuleType;
}

interface IResponse {
  groups: IQuestionGroup[];
  score: number;
}

export const getQuestionsFn = async (patientGuid: string) => {
  const { data, error } = await dataService.getOnly<IResponse>(API_QUESTIONS, {
    patientGuid,
  });

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return reverseQuestionnaireAnswers(data);
};

export const getQuestionsKey = (patientGuid: string) => [
  'getQuestions',
  patientGuid,
];

export const useQuestions = ({
  patientGuid,
  module = ModuleType.CCM,
}: Props) => {
  const {
    data: questions,
    isLoading,
    isFetching,
    isRefetching,
    ...other
  } = useQuery({
    queryKey: getQuestionsKey(patientGuid),
    queryFn: async () => getQuestionsFn(patientGuid),
    enabled: isCcmModule(module),
  });

  return {
    questions,
    isLoading: isLoading || isFetching || isRefetching,
    isFetching,
    ...other,
  };
};
