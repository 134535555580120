import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from 'components/atoms/icons/editProfileIcon';

const EditButton = ({ handleClick }) => (
  <div
    className="edit-button"
    data-cy="edit-button"
    onClick={handleClick}
    role="button"
    aria-hidden
  >
    <EditIcon width="18" height="18" />
  </div>
);

EditButton.defaultProps = {
  handleClick: () => {},
};

EditButton.propTypes = {
  handleClick: PropTypes.func,
};

export default EditButton;
