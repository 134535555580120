import dataService from 'services/dataService';
import { API_PATIENTS_DISCHARGES } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

import type { RpmStatuses } from 'infrastructure/enums';

interface DataProps {
  patientGuid: string;
  dischargeStatus: RpmStatuses;
}

export const updatePatientDischargeStatusFn = async (postData: DataProps) => {
  const { error, data } = await dataService.createOne(
    API_PATIENTS_DISCHARGES,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
