import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showResult } from 'infrastructure/hooks/utils';
import dataService from 'services/dataService';
import { API_INTERACTIVE_TIME_DASHBOARD_ASSIGN_UPDATE } from 'services/dataService/resources';
import { useSelector } from 'react-redux';

import type { RootState } from 'store';
import type { InteractiveTimeDashboardData } from './types';

interface Props {
  patientGuid: string;
  year: number;
  month: number;
}

interface Data {
  data: {
    month: number;
    year: number;
    patientGuid: string;
    assignee: {
      loginGuid: string;
      fullName: string;
      createdAt: string;
      expireAt: string;
      expireAtTimestamp: number;
    } | null;
  };
}

export const useInteractiveTimeDashboardAssign = ({
  month,
  patientGuid,
  year,
}: Props) => {
  const queryClient = useQueryClient();

  const queryKey = useSelector(
    (state: RootState) => state.queryKeys.interactiveTimeDashboardData,
  );

  const { mutate, isLoading } = useMutation({
    mutationFn: async (action: 'assign' | 'unassign') => {
      const { data, error } = await dataService.updateOnly<Data>(
        API_INTERACTIVE_TIME_DASHBOARD_ASSIGN_UPDATE,
        {
          month,
          patientGuid,
          year,
          action,
        },
      );

      if (!data || error) {
        showResult(error);
        throw new Error(error);
      }

      return data.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData<InteractiveTimeDashboardData>(
        queryKey,
        (oldData) => {
          if (!oldData) return undefined;
          const newData = oldData.data.map((item) => {
            if (item.patient.guid === data.patientGuid) {
              return {
                ...item,
                assignee: data.assignee,
              };
            }
            return item;
          });
          return { count: oldData.count, data: newData };
        },
      );
    },
  });

  return { assign: mutate, isLoading };
};
