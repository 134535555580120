import { useFormik } from 'formik';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseTextArea from 'components/atoms/base-textarea';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import BaseModal from 'components/atoms/base-modal';
import { useEffect, type FC } from 'react';

import { patientGoalValidationSchema } from './validation-schema';

import type { FormikHelpers } from 'formik';
import type { Values } from './validation-schema';

interface PatientGoalModalFormProps {
  open: boolean;
  onCancelForm: () => void;
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  loading: boolean;
  title: string;
}

const PatientGoalModalForm: FC<PatientGoalModalFormProps> = ({
  open,
  loading,
  onCancelForm,
  onSubmit,
  title,
}) => {
  const { t } = useTranslation();
  const formik = useFormik<Values>({
    initialValues: patientGoalValidationSchema().cast({}),
    enableReinitialize: true,
    onSubmit,
    validateOnChange: false,
    validationSchema: patientGoalValidationSchema(),
  });

  const { submitForm, resetForm } = formik;

  const onCancel = () => {
    resetForm();
    onCancelForm();
  };

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  return (
    <>
      <BaseModal
        title={title}
        open={open}
        onCancel={onCancel}
        width="1000px"
        smallBodyPaddingWithFooter
        withFooter={[
          <BaseButton
            dataCy="form-cancel-button"
            label={t('controls.cancel')}
            type="secondary"
            onClick={onCancel}
            disabled={loading}
          />,
          <BaseButton
            dataCy="form-save-button"
            label={t('controls.save')}
            onClick={submitForm}
            disabled={loading}
            loading={loading}
          />,
        ]}
        allowScrolling
      >
        <BaseForm
          formik={formik}
          withControls
          plaintext={false}
          readonly={false}
        >
          <BaseFormItem name="log">
            <BaseTextArea rows={10} />
          </BaseFormItem>
        </BaseForm>
      </BaseModal>
    </>
  );
};
export default PatientGoalModalForm;
