import { useEffect, useRef } from 'react';

import type { EffectCallback, DependencyList } from 'react';

const useUpdateEffect = (
  effect: EffectCallback,
  deps: DependencyList,
): void => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    return effect();
  }, deps);
};

export default useUpdateEffect;
