import React from 'react';
import { Card, CardBody } from 'reactstrap';
import CountCard from 'components/atoms/billingCountCard';
import PropTypes from 'prop-types';

const BillingCount = ({ highCount, mediumCount, lowCount }) => (
  <div className="billing-count">
    <Card className="card-border">
      <CardBody>
        <div className="billing-count-cards">
          <CountCard
            text="99490"
            count={lowCount}
            dataCy="ccm-billing-report-99490"
          />
          <CountCard
            text="99439 (1)"
            count={mediumCount}
            dataCy="ccm-billing-report-99439-1"
          />
          <CountCard
            text="99439 (2)"
            count={highCount}
            dataCy="ccm-billing-report-99439-2"
          />
        </div>
      </CardBody>
    </Card>
  </div>
);

BillingCount.propTypes = {
  highCount: PropTypes.number,
  mediumCount: PropTypes.number,
  lowCount: PropTypes.number,
};

export default BillingCount;
