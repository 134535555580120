import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const Registration = ({ data }) => (
  <div className="admin-agent-block">
    <span>Registration</span>
    <Table>
      <tbody>
        {data.createdAt && (
          <tr>
            <td>Registered At</td>
            <td data-cy="agency-user-registeredAt">{data.createdAt}</td>
          </tr>
        )}
        {data.email && (
          <tr>
            <td>Username</td>
            <td className="hight-light" data-cy="agency-user-userName">
              {data.email}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);

Registration.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Registration;
