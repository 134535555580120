import React from 'react';
import { Dropdown } from 'antd';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface IProps extends IBaseComponentProps {
  content?: React.ReactNode;
  trigger?: ('click' | 'hover' | 'contextMenu')[];
  placement?:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
    | 'top'
    | 'bottom';
  offset?: [number | `${number}%`, number | `${number}%`];
  onOpenChange?: (state: boolean) => void;
  destroyPopupOnHide?: boolean;
  zIndex?: number;
}

const BaseDropdown: React.FC<IProps> = (props) => {
  const {
    children,
    content,
    placement,
    offset,
    onOpenChange,
    destroyPopupOnHide = true,
    trigger = ['click'],
    zIndex = 100,
  } = props;
  return (
    <Dropdown
      align={{ offset }}
      placement={placement}
      trigger={trigger}
      dropdownRender={() => {
        return <div>{content}</div>;
      }}
      onOpenChange={onOpenChange}
      destroyPopupOnHide={destroyPopupOnHide}
      overlayStyle={{ zIndex }}
    >
      {children}
    </Dropdown>
  );
};

export default BaseDropdown;
