import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { formatRoleName } from 'utils/formatters';

const Profession = ({ data }) => (
  <div className="admin-agent-block">
    <span>Profession Info</span>
    <Table>
      <tbody>
        {data.agencies && (
          <tr>
            <td>Agency</td>
            <td className="hight-light" data-cy="agency-user-agency">
              {data.agencies.map(({ legalname }) => legalname).join(', ')}
            </td>
          </tr>
        )}
        {data.professionType && (
          <tr>
            <td>Profession</td>
            <td data-cy="agency-user-profession">{data.professionType}</td>
          </tr>
        )}
        {data.roles?.length && (
          <tr>
            <td>Roles</td>
            <td data-cy="agency-user-role">
              {data.roles.map(({ name }) => formatRoleName(name)).join(', ')}
            </td>
          </tr>
        )}
        {data.workNumber && (
          <tr>
            <td>Work Number</td>
            <td data-cy="agency-user-workNumber">{data.workNumber}</td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);

Profession.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Profession;
