import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Card, CardBody, Table, Row, Col } from 'reactstrap';
import SortableColumn from 'components/atoms/sortableColumn';
import common from 'constants/common';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import Pagination from 'components/molecules/paginationTransition';
import withLoader from 'components/organisms/withLoader';
import ActivityRowLast30Days from 'components/templates/rpm/practice/adherenceReport/activityRowLast30Days';
import { showResult } from 'infrastructure/hooks/utils';
import AutoCompleteDoctorMultiSelect from 'components/molecules/autocompleteFilter/doctorMulti';

const headings = [
  'Patient Guid',
  'Patient Name',
  'MRN',
  'Patient Phone Number',
  'Clinic Name',
  'Patient First Reading Date',
  'Days Since Start',
  '# of Reading Days',
  'Patient Adherence',
];

const AdherenceLast30DaysReport = () => {
  const adherenceReport = useSelector((state) => state.adherenceLast30Days);
  const { page, totalCount, sort, itemsPerPage } = adherenceReport;
  const {
    getAdherenceReport: reportLoading,
    getAdherenceCsvReport: csvLoading,
  } = useSelector((state) => state.loading.effects.adherenceLast30Days);

  const {
    adherenceLast30Days: {
      setPageAdherence,
      getAdherenceReport,
      getAdherenceCsvReport,
      toggleAdherence,
      setFilters,
    },
  } = useDispatch();

  const setPage = (pageNumber) => {
    setPageAdherence({ pageNumber });
  };

  const getSortValue = (value) => (value && value === 'desc' ? 'DESC' : 'ASC');

  const sendReport = async () => {
    const { error } = await getAdherenceCsvReport();
    return showResult(error, common.submitSuccessTypes.sendReport);
  };

  useEffect(() => {
    getAdherenceReport();
  }, []);

  return (
    <Container>
      <div className="activity-report">
        <Card className="card-border">
          <CardBody className="card-outer">
            <div className="performance-review__header">
              <div className="activity-header">
                <h3>Report: First 30 Days Adherence</h3>
              </div>
              <div className="performance-review__header-icon">
                {withLoader(
                  csvLoading,
                  <button onClick={sendReport}>
                    <DownloadRoundedIcon className="performance-review__header-icon-svg" />
                    <br />
                    <span>Download</span>
                  </button>,
                )}
              </div>
            </div>
            <div className="activity-body">
              <div className="sort-row">
                <Row>
                  <Col md="3" sm="6" xs="12">
                    <strong>Provider</strong>
                    <AutoCompleteDoctorMultiSelect
                      onChange={(value) => setFilters({ doctorGuid: value })}
                    />
                  </Col>
                </Row>
              </div>
              <div className="table-sticky-header">
                {withLoader(
                  reportLoading,
                  <Table borderless cellSpacing="0" cellPadding="0">
                    <thead>
                      <tr>
                        {headings &&
                          headings.map((heading, i) => (
                            <th
                              className="table-status-column"
                              key={`table-heading-${i}`}
                            >
                              {i === headings.length - 1 ? (
                                <div className="sort-div">
                                  <SortableColumn
                                    title={heading}
                                    handler={toggleAdherence}
                                    sort={getSortValue(sort.percent)}
                                  />
                                </div>
                              ) : (
                                <div className="sort-div">{heading}</div>
                              )}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {adherenceReport.data.map((activityRow, i) => (
                        <ActivityRowLast30Days
                          key={`table-row-30-days-${i}`}
                          activity={activityRow}
                        />
                      ))}
                    </tbody>
                  </Table>,
                )}
              </div>
              <div className="billing-table-button-pagination flex-row-reverse">
                <Pagination
                  title=""
                  page={page}
                  pageTotal={adherenceReport.data.length}
                  itemsPerPage={itemsPerPage}
                  total={
                    totalCount <= itemsPerPage
                      ? adherenceReport.data.length
                      : totalCount
                  }
                  handleFirstClick={() => setPage(1)}
                  handleLastClick={() =>
                    setPage(Math.ceil(totalCount / itemsPerPage))
                  }
                  handlePrevClick={() => setPage(page - 1)}
                  handleNextClick={() => setPage(page + 1)}
                  handleGoToPage={(numberPage) => setPage(numberPage)}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default AdherenceLast30DaysReport;
