import type { SVGProps } from 'react';

const KeyNoteAddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="6" fill="#FB9318" />
    <path
      d="M16 10C16.4142 10 16.75 10.3358 16.75 10.75V15.25H21.25C21.6642 15.25 22 15.5858 22 16C22 16.4142 21.6642 16.75 21.25 16.75H16.75V21.25C16.75 21.6642 16.4142 22 16 22C15.5858 22 15.25 21.6642 15.25 21.25V16.75H10.75C10.3358 16.75 10 16.4142 10 16C10 15.5858 10.3358 15.25 10.75 15.25H15.25V10.75C15.25 10.3358 15.5858 10 16 10Z"
      fill="#2C2543"
    />
  </svg>
);

export default KeyNoteAddIcon;
