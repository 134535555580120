const agencyDoctorRequestGenerator = (
  {
    guid,
    agencies,
    title,
    firstName,
    lastName,
    middleName,
    birthDate,
    mobileNumber,
    workNumber,
    email,
    primaryLanguage,
    secondaryLanguage,
    addressLine1,
    addressLine2,
    state,
    city,
    zip,
    timezone,
    npiNumber,
    professionType,
    roles,
    status,
  },
  emailAlerts,
  smsAlerts,
) => {
  const body = {
    guid,
    agencyGuids: agencies?.map(({ value }) => value),
    title,
    firstName,
    lastName,
    middleName,
    birthDate: birthDate || undefined,
    mobileNumber,
    workNumber,
    email,
    primaryLanguage,
    secondaryLanguage,
    addressLine1,
    addressLine2,
    state,
    city,
    zip,
    timezone,
    npiNumber,
    alertEmail: emailAlerts,
    alertSms: smsAlerts,
    professionType,
    roles: roles?.map(({ value }) => value),
    status,
  };
  return body;
};
export default agencyDoctorRequestGenerator;
