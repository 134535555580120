import {
  differenceInSeconds,
  intervalToDuration,
  minutesToSeconds,
} from 'date-fns';
import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { parseDateSafe } from 'utils/timeHelper';

const colors = {
  green: '#2F8521',
  orange: '#E28416',
  red: '#BD363B',
  gray: '#2C2543',
};

const backgroundColors = {
  green: `${colors.green}1A`,
  orange: `${colors.orange}1A`,
  red: `${colors.red}1A`,
  gray: '#F4F4FC',
};

type Color = keyof typeof colors;

const zeroPad = (num = 0) => String(num).padStart(2, '0');

const getColor = ({
  deadLineDate,
  serviceDeliveryThresholds,
  slaReviewedTime,
}: {
  deadLineDate?: Date;
  serviceDeliveryThresholds?: number;
  slaReviewedTime?: string;
}): Color => {
  if (slaReviewedTime || !deadLineDate || !serviceDeliveryThresholds)
    return 'gray';
  const thresholdInSeconds = minutesToSeconds(serviceDeliveryThresholds);
  const remainingSeconds = differenceInSeconds(deadLineDate, new Date());

  const isGreaterThanHalf = thresholdInSeconds / 2 <= remainingSeconds;
  if (isGreaterThanHalf) {
    return 'green';
  }
  const isGreaterThanQuarter = thresholdInSeconds / 4 <= remainingSeconds;
  if (isGreaterThanQuarter) {
    return 'orange';
  }
  return 'red';
};

const calculateTimeDifference = ({
  slaDeadline,
  slaReviewedTime,
}: {
  slaDeadline: number;
  slaReviewedTime?: string;
}) => {
  const currentDate = Number(slaReviewedTime) || new Date().getTime();
  const timeDifference = slaDeadline - currentDate;
  const sign = timeDifference <= 0 ? '-' : '';
  const { hours, minutes, seconds } = intervalToDuration({
    start: 0,
    end: timeDifference,
  });
  const time = `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;

  return `${sign}${time}`;
};

const retrieveSLAValues = ({
  serviceDeliveryThresholds,
  slaReviewedTime,
  slaDeadline,
}: {
  serviceDeliveryThresholds?: number;
  slaReviewedTime?: string;
  slaDeadline: number;
}) => {
  const color = getColor({
    deadLineDate: parseDateSafe(slaDeadline),
    serviceDeliveryThresholds,
    slaReviewedTime,
  });
  const timeDifference = calculateTimeDifference({
    slaDeadline,
    slaReviewedTime,
  });

  return { color, timeDifference };
};

interface Props {
  slaDeadline: string;
  slaReviewedTime?: string;
  serviceDeliveryThresholds: number;
}
const useSlaTimer = ({
  slaDeadline,
  slaReviewedTime,
  serviceDeliveryThresholds,
}: Props) => {
  const [countdown, setCountdown] = useState('');
  const [colorName, setColorName] = useState<Color>('gray');

  const updateColorAndCountdown = () => {
    const { color, timeDifference } = retrieveSLAValues({
      serviceDeliveryThresholds,
      slaReviewedTime,
      slaDeadline: Number(slaDeadline),
    });
    setColorName(color);
    setCountdown(timeDifference);
  };

  useEffect(updateColorAndCountdown, [slaReviewedTime]);
  useInterval(updateColorAndCountdown, slaReviewedTime ? null : 1000);

  const color = colors[colorName];
  const backgroundColor = backgroundColors[colorName];
  return {
    color,
    backgroundColor,
    countdown,
  };
};

export default useSlaTimer;
