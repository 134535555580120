import type { FC, SVGProps } from 'react';

const FalseMonthlyInteractionIcon: FC<SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5922 4.62403C9.62437 -0.473549 16.3707 -1.50509 20.6809 2.27339C24.9911 6.05187 25.2201 16.4803 20.6809 21.0786C16.1416 25.6768 5.67952 24.2016 2.11701 21.0786C-1.4455 17.9555 -0.439978 9.72162 4.5922 4.62403Z"
        fill="#BD363B"
      />
      <g clipPath="url(#clip0_7565_79345)">
        <path
          d="M8.465 8.465L15.535 15.535M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7565_79345">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FalseMonthlyInteractionIcon;
