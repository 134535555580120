import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from './locales/en/index';

i18n.use(initReactI18next).init({
  resources: {
    en,
  },
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

declare module 'i18next' {
  interface CustomTypeOptions {
    resources: typeof en;
  }
}

export default i18n;
