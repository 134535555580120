import React from 'react';
import { PropTypes } from 'prop-types';

const HintIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_6716_92017"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 5.37187 5.37187 0 12 0C18.6281 0 24 5.37187 24 12C24 18.6281 18.6281 24 12 24C5.37187 24 0 18.6281 0 12ZM16.0125 21.5016C17.2406 20.9836 18.3445 20.2383 19.2914 19.2914C20.2406 18.3445 20.9836 17.2406 21.5039 16.0125C22.0406 14.7422 22.3125 13.3922 22.3125 12C22.3125 10.6078 22.0406 9.25781 21.5016 7.98516C20.9836 6.75703 20.2383 5.65312 19.2914 4.70625C18.3422 3.75938 17.2406 3.01641 16.0125 2.49609C14.7422 1.95938 13.3922 1.6875 12 1.6875C10.6078 1.6875 9.25781 1.95938 7.98516 2.49844C6.75703 3.01641 5.65312 3.76172 4.70625 4.70859C3.75938 5.65781 3.01641 6.75938 2.49609 7.9875C1.95938 9.25781 1.6875 10.6078 1.6875 12C1.6875 13.3922 1.95938 14.7422 2.49844 16.0148C3.01641 17.243 3.76172 18.3469 4.70859 19.2938C5.65781 20.2406 6.75938 20.9836 7.9875 21.5039C9.25781 22.0406 10.6078 22.3125 12 22.3125C13.3922 22.3125 14.7422 22.0406 16.0125 21.5016ZM14.7703 16.5375H12.7734V8.33203C12.7734 7.86562 12.3961 7.48828 11.9297 7.48828H11.0859H9.67969C9.21328 7.48828 8.83594 7.86562 8.83594 8.33203C8.83594 8.79844 9.21328 9.17578 9.67969 9.17578H11.0859V16.5375H9.08906C8.62266 16.5375 8.24531 16.9148 8.24531 17.3813C8.24531 17.8477 8.62266 18.225 9.08906 18.225H11.0859H12.7734H14.7703C15.2367 18.225 15.6141 17.8477 15.6141 17.3813C15.6141 16.9148 15.2367 16.5375 14.7703 16.5375ZM11.9297 6.01172C11.4637 6.01172 11.0859 5.63396 11.0859 5.16797C11.0859 4.70198 11.4637 4.32422 11.9297 4.32422C12.3957 4.32422 12.7734 4.70198 12.7734 5.16797C12.7734 5.63396 12.3957 6.01172 11.9297 6.01172Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_6716_92017)">
      <rect width="24" height="24" fill={color} />
    </g>
  </svg>
);

HintIcon.propTypes = {
  color: PropTypes.string,
};

HintIcon.defaultProps = {
  color: '#667180',
};

export default HintIcon;
