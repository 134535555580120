import s from './styles.module.scss';

import type { FC } from 'react';

const NoData: FC = () => {
  return (
    <div className={s['no-data--wrapper']}>
      <span>To initiate readings, simply click the "Get Readings" button.</span>
      <img src="/img/turk-dashboard/no-data.svg" alt="No data" />
    </div>
  );
};

export default NoData;
