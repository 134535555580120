import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import forms from 'constants/forms';
import Input from 'components/atoms/input';

const Address = ({ errors }) => {
  const address = [...forms.adminPanelsDoctor.userEdit.address];
  return (
    <div className="admin-patient-form-block">
      <span>Address</span>
      <Row>
        {address.map((field) => (
          <Col lg="6" md="6" sm="6" xs="12" key={field.id}>
            <Input dataCy={`users-input-${field.id}`} {...field} />
            {errors[field.id] ? (
              <div className="input-error">
                {errors[field.id].split('.')[0]}
              </div>
            ) : null}
          </Col>
        ))}
      </Row>
    </div>
  );
};

Address.propTypes = {
  errors: PropTypes.shape({}).isRequired,
};

export default Address;
