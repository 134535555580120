import React from 'react';

const ActivityHeader = () => (
  <thead>
    <tr>
      <th>GUID</th>
      <th>Patient Name</th>
      <th>DOB</th>
      <th>MRN</th>
      <th>Patient Status</th>
      <th>Clinic Name</th>
      <th>Available Days</th>
      <th># Of Actual Readings</th>
      <th>Adherence</th>
    </tr>
  </thead>
);

export default ActivityHeader;
