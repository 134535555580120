import React from 'react';
import Icon from '@ant-design/icons';

import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const StickiesSVG = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.16663 0.5C1.3382 0.5 0.666626 1.17157 0.666626 2V13.5C0.666626 14.0523 1.11434 14.5 1.66663 14.5V2C1.66663 1.72386 1.89048 1.5 2.16663 1.5H14.6666C14.6666 0.947715 14.2189 0.5 13.6666 0.5H2.16663Z"
        fill="currentColor"
      />
      <path
        d="M4.16663 2.5C3.3382 2.5 2.66663 3.17157 2.66663 4V15C2.66663 15.8284 3.3382 16.5 4.16663 16.5H10.2524C10.6502 16.5 11.0318 16.342 11.3131 16.0607L16.2273 11.1464C16.5086 10.8651 16.6666 10.4836 16.6666 10.0858V4C16.6666 3.17157 15.9951 2.5 15.1666 2.5H4.16663ZM3.66663 4C3.66663 3.72386 3.89048 3.5 4.16663 3.5H15.1666C15.4428 3.5 15.6666 3.72386 15.6666 4V9.5H11.1666C10.3382 9.5 9.66663 10.1716 9.66663 11V15.5H4.16663C3.89048 15.5 3.66663 15.2761 3.66663 15V4ZM10.6666 15.2929V11C10.6666 10.7239 10.8905 10.5 11.1666 10.5H15.4595L10.6666 15.2929Z"
        fill="currentColor"
      />
    </svg>
  );
};

const StickiesIcon: React.FC<Partial<CustomIconComponentProps>> = (props) => {
  return <Icon component={StickiesSVG} {...props} />;
};

export default StickiesIcon;
