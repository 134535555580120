import { Select } from 'antd';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import { useId } from 'infrastructure/hooks/utils/useId';
import { useSelectAll } from 'infrastructure/antd/hooks/use-select-all';

import { useHooks } from './hooks';
import s from './styles.module.scss';

import type { SelectProps } from 'antd';

export interface Option {
  label: string;
  value: string;
}

export interface DebounceSelectProps
  extends Omit<SelectProps<Option | Option[]>, 'options' | 'children'> {
  showSelectAll?: boolean;
  withSelectedData?: boolean;
  fetchOptions: (args: {
    search: string;
    page: number;
    items: number;
  }) => Promise<{ data: Option[]; count: number } | undefined>;
  debounceTimeout?: number;
  label: string;
  defaultOptions?: Option[];
  setOpenCallback?: (value: boolean) => void;
}

const BaseAutocomplete = ({
  showSelectAll,
  withSelectedData,
  fetchOptions,
  debounceTimeout = 300,
  label,
  defaultOptions = [],
  setOpenCallback,
  ...props
}: DebounceSelectProps) => {
  const { onScroll, onSearch, fetching, options } = useHooks({
    debounceTimeout,
    fetchOptions,
    defaultOptions,
  });

  const selectProps = useSelectAll({
    showSelectAll,
    withSelectedData,
    value: props.value,
    onChange: props.onChange,
    options,
  });

  const id = props.id || useId('autocomplete_');

  return (
    <div style={{ width: '100%' }}>
      <label htmlFor={id} className={s.label}>
        {label}
      </label>
      <Select
        allowClear
        id={id}
        style={{ width: '100%' }}
        labelInValue
        filterOption={false}
        onSearch={onSearch}
        onPopupScroll={onScroll}
        onDropdownVisibleChange={
          setOpenCallback ? (visible) => setOpenCallback(visible) : undefined
        }
        dropdownRender={(menu) =>
          options.length ? (
            <>
              {menu}
              <BaseSpinWrapper size="small" spinning={fetching}>
                {fetching && <div className={s['empty-space']} />}
              </BaseSpinWrapper>
            </>
          ) : (
            <BaseSpinWrapper size="small" spinning={fetching}>
              {menu}
            </BaseSpinWrapper>
          )
        }
        {...props}
        {...selectProps}
      />
    </div>
  );
};

export default BaseAutocomplete;
