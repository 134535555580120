import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const GiftIcon = ({ color = COLORS.ARANCIO, ...props }: IIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 2.5C3 1.11929 4.11929 0 5.5 0C6.88071 0 8 1.11929 8 2.5C8 1.11929 9.11929 0 10.5 0C11.8807 0 13 1.11929 13 2.5L13 2.50557C13 2.57543 13.0001 2.77567 12.9622 3H15C15.5523 3 16 3.44772 16 4V6C16 6.55228 15.5523 7 15 7V14.5C15 15.3284 14.3284 16 13.5 16H2.5C1.67157 16 1 15.3284 1 14.5L1 7C0.447716 7 0 6.55229 0 6V4C0 3.44772 0.447715 3 1 3H3.03783C2.99987 2.77567 2.99996 2.57543 3 2.50557C3 2.50361 3 2.50175 3 2.5ZM4.06799 3H7V2.5C7 1.67157 6.32843 1 5.5 1C4.67157 1 4 1.67157 4 2.5C4 2.58475 4.00195 2.77351 4.04488 2.93094C4.05252 2.95895 4.06044 2.98186 4.06799 3ZM9 3H11.932C11.9396 2.98186 11.9475 2.95895 11.9551 2.93094C11.9981 2.77351 12 2.58475 12 2.5C12 1.67157 11.3284 1 10.5 1C9.67157 1 9 1.67157 9 2.5V3ZM1 4V6H7V4H1ZM9 4V6H15V4H9ZM14 7H9V15H13.5C13.7761 15 14 14.7761 14 14.5V7ZM7 15V7H2V14.5C2 14.7761 2.22386 15 2.5 15H7Z"
      fill={color}
    />
  </svg>
);

export default GiftIcon;
