import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const emrValidationSchema = () =>
  createValidationSchema({
    account: commonValidationSchemas.string().max(255),
    name: commonValidationSchemas.string().max(255),
    emrId1: commonValidationSchemas.string().max(255),
    emrId2: commonValidationSchemas.string().max(255),
    supervisingProviderId: commonValidationSchemas.string().max(255),
    renderingProviderId: commonValidationSchemas.string().max(255),
    billingCycle: commonValidationSchemas.string().max(255),
  });
