export const forms = [
  {
    type: 'TEXT',
    title: 'Name of Nurse',
    id: 'nurseName',
    disabled: true,
    isRequired: true,
  },
  {
    type: 'MULTIPLE_CHOICE',
    title: 'Contact Method',
    id: 'contactMethod',
    helpText:
      'The stipulated preferred method of contact by the Provider as per the Runbook',
    isRequired: true,
    choices: ['SMS', 'Email', 'Phone'],
  },
  {
    type: 'TEXT',
    title: 'Phone Number or email of Person you are calling ',
    id: 'phoneOrEmailOfPersonYouAreCalling',
    helpText:
      'The phone number of the person you are calling in the runbook or the email',
    isRequired: true,
  },
  {
    type: 'TEXT',
    title: 'Name of the Person you are calling',
    id: 'nameOfPersonYouAreCalling',
    helpText:
      'Please put the Name of the person you are calling. If you care calling a Doctor type in Doctor not Dr. no short forms. ',
    isRequired: true,
  },
  {
    type: 'TEXT',
    title: 'Patient Guid',
    id: 'patientGuid',
    disabled: true,
    isRequired: true,
  },
  {
    type: 'TEXT',
    title: 'Patient Name',
    id: 'patientName',
    disabled: true,
    isRequired: true,
  },
  {
    type: 'DATE',
    title: 'Patient DOB',
    id: 'patientBirthDate',
    disabled: true,
    isRequired: true,
  },
  {
    type: 'TEXT',
    title: 'Patient Phone1',
    id: 'patientFirstPhone',
    isRequired: true,
  },
  {
    type: 'TEXT',
    title: 'Patient Phone2',
    id: 'patientSecondPhone',
    isRequired: false,
  },
  {
    type: 'TEXT',
    title: 'Date/Time of Critical Reading',
    id: 'dateCriticalReading',
    helpText:
      '04-29-2022 at 10:45am - this is the time of the reading displayed in E3',
    isRequired: true,
  },
  {
    type: 'TEXT',
    title: 'Value of Critical Reading',
    id: 'valueCriticalReading',
    helpText:
      'Ex for: Blood Pressure 161 mmhg / 59 mmhg / 59 bpm \nEx for: Glucose 475 mgdL  \nEx for: SpO2 75 percent \nEx for: Weight 10 lbs gained',
    isRequired: true,
  },
  {
    type: 'TEXT',
    title: 'Signs and Symptoms',
    id: 'signsSymptoms',
    helpText:
      '(Protocol is either a) spoke with patient and escalated, or b) not able to reach patient and escalated)',
    isRequired: true,
  },
];

export const allRequiredEscalationForm = forms
  .map((form) => (form.isRequired ? form.id : null))
  .filter(Boolean);
