import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { RESET_PASSWORD } from 'services/dataService/resources';
import dataService from 'services/dataService';
import withLoader from 'components/organisms/withLoader';
import { scrollToErrorIfExist } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';
import useUser from 'utils/useUser';
import { isAgencyDoctor, isRoleNameAdministrator } from 'utils/userTypeHelper';
import Button from 'components/atoms/button';
import { Formik, Form } from 'formik';
import { Card, CardBody, Table } from 'reactstrap';
import validationSchema from 'components/organisms/adminPanels/agencyDoctor/validationSchema';
import requestBodyGenerator from 'components/organisms/adminPanels/agencyDoctor/requestBodyGenerator';
import ControlPanelButton from 'components/atoms/controlPanelButton';
import ResetPasswordIcon from 'components/atoms/icons/resetPassword.jsx';
import Info from 'components/organisms/adminPanels/agencyDoctor/components/info';
import Address from 'components/organisms/adminPanels/agencyDoctor/components/address';
import IRS from 'components/organisms/adminPanels/agencyDoctor/components/irs';
import Registration from 'components/organisms/adminPanels/agencyDoctor/components/registration';
import Profession from 'components/organisms/adminPanels/agencyDoctor/components/profession';
import { formatRoleName } from 'utils/formatters';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';

import RoleTypes from '../../../../constants/roleTypes';
import ActiveStatusButton from '../userEdit/components/activeStatusButton';

const resetPassword = async (email) => {
  const { data, error } = await dataService.createList(RESET_PASSWORD, {
    email,
  });
  if (error) return showResult(error);
  if (data) {
    notificationController.success({
      message: "'Reset password' link sent to email",
    });
  }
};

const EditAgencyDoctor = () => {
  const navigate = useNavigate();
  const { userType, roleNames } = useUser();
  const {
    adminPanelAgencyDoctors: {
      getAgencyDoctor,
      updateAgencyDoctor,
      getAgencies,
      getRoles,
    },
  } = useDispatch();
  useEffect(() => {
    getRoles();
  }, []);

  const { agentGuid } = useParams();

  const agencyDoctor = useSelector(
    (state) => state.adminPanelAgencyDoctors.agencyDoctor,
  );

  const [emailAlerts, setEmailAlerts] = useState(false);
  const [smsAlerts, setSmsAlerts] = useState(false);

  useEffect(() => {
    const run = async () => {
      try {
        await getAgencies();
        await getAgencyDoctor(agentGuid);
      } catch (e) {
        showResult(e);
        navigate(`/404`);
      }
    };
    run();
  }, [getAgencyDoctor]);

  const agencies = useSelector(
    (state) => state.adminPanelAgencyDoctors.agencies,
  );

  useEffect(() => {
    if (agencyDoctor) {
      setEmailAlerts(agencyDoctor.alertEmail);
      setSmsAlerts(agencyDoctor.alertSms);
    }
  }, [agencyDoctor]);

  const {
    updateAgencyDoctor: submitLoading,
    getAgencyDoctor: agencyDoctorProfileLoading,
    getAgencies: agenciesLoading,
  } = useSelector((state) => state.loading.effects.adminPanelAgencyDoctors);

  const submitHandler = async (agencyDoctorRequest) => {
    const body = requestBodyGenerator(
      agencyDoctorRequest,
      emailAlerts,
      smsAlerts,
    );
    const { data, error } = await updateAgencyDoctor(body);
    if (error) return showResult(error);
    if (data) {
      showResult();
      navigate(`/admin/agent/${data.guid}`);
    }
  };

  const profession = RoleTypes.find(
    ({ label }) => label === agencyDoctor.professionType,
  );

  let initialAgencies = [];
  if (isAgencyDoctor(userType) && isRoleNameAdministrator(roleNames)) {
    const authorAgencyGuids = agencies
      ?.filter(({ guid: authorAgencyGuid }) =>
        agencyDoctor?.agencies.find(
          ({ guid: agencyGuid }) => authorAgencyGuid === agencyGuid,
        ),
      )
      .map(({ guid, legalname }) => ({
        value: guid,
        label: legalname,
      }));
    initialAgencies = authorAgencyGuids;
  } else {
    initialAgencies = agencyDoctor?.agencies.map(({ guid, legalname }) => ({
      value: guid,
      label: legalname,
    }));
  }

  const initialRoles = agencyDoctor?.roles.map(({ guid, name }) => ({
    value: guid,
    label: formatRoleName(name),
  }));

  return (
    <div className="admin-agent">
      {withLoader(
        agencyDoctorProfileLoading || agenciesLoading,
        <>
          {agencyDoctor && (
            <Card className="card-border">
              <CardBody>
                <Formik
                  onSubmit={submitHandler}
                  initialValues={{
                    ...agencyDoctor,
                    profession,
                    roles: initialRoles,
                    agencies: initialAgencies,
                  }}
                  validationSchema={validationSchema}
                >
                  {({ errors, values }) => (
                    <Form>
                      <div className="admin-agent-header admin-user-edit__header__wrapper">
                        <span className="admin-header__title">
                          Edit Agency User Page
                        </span>
                        <div className="admin-agent-header-actions">
                          <div className="admin-patient-header-actions-call">
                            <ControlPanelButton
                              label="Send Password Reset Email"
                              icon={ResetPasswordIcon}
                              handleClick={(e) => {
                                e.preventDefault();
                                resetPassword(agencyDoctor.email);
                              }}
                            />
                          </div>
                          <div className="admin-agent-header-actions-status">
                            <ActiveStatusButton status={values.status} />
                          </div>
                        </div>
                      </div>
                      <div className="admin-agent-edit-form">
                        <Table>
                          <tbody>
                            <tr>
                              <td rowSpan={2}>
                                <Info errors={errors} />
                              </td>
                              <td>
                                <Profession
                                  agencies={agencies}
                                  errors={errors}
                                  isLoading={agenciesLoading}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Registration errors={errors} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Address errors={errors} />
                              </td>
                              <td>
                                <IRS
                                  emailAlert={emailAlerts}
                                  setEmailAlert={setEmailAlerts}
                                  smsAlert={smsAlerts}
                                  setSmsAlert={setSmsAlerts}
                                  errors={errors}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="text-center">
                        <Button
                          disabled={submitLoading && errors}
                          type="submit"
                          text="Save"
                          data-cy="save-button"
                          handleClick={scrollToErrorIfExist}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </>,
      )}
    </div>
  );
};

export default EditAgencyDoctor;
