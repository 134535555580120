import React from 'react';
import { useTranslation } from 'react-i18next';

import BaseButton from '../baseButton';
import PencilIcon from '../icons/pencilIcon';

import type { IBaseButtonProps } from '../baseButton';

const EditButton: React.FC<IBaseButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <BaseButton
      dataCy="edit-button"
      icon={<PencilIcon />}
      label={t('controls.edit')}
      size="large"
      {...props}
    />
  );
};

export default EditButton;
