import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getAverageDuration } from './utils';

const AverageSleepTime = ({ durations }) => {
  const [averageSleepTime, setAverageSleepTime] = useState({
    minutes: 0,
    hours: 0,
  });

  useEffect(() => {
    if (durations.length) {
      const [hours, minutes] = getAverageDuration(durations);
      setAverageSleepTime({ hours, minutes });
    }
  }, [durations]);

  return (
    <div className="average-sleep-time__wrapper">
      <div className="average-sleep-time">
        <span className="average-sleep-time__title">Average Sleep Time</span>

        <div className="average-sleep-time__value">
          <span className="big">{averageSleepTime.hours}</span>
          <span className="small">hr</span>
          <span className="big">{averageSleepTime.minutes}</span>
          <span className="small">min</span>
        </div>
      </div>
    </div>
  );
};

AverageSleepTime.propTypes = {
  durations: PropTypes.array,
};

export default AverageSleepTime;
