import { capitalize, fullNamePresenter } from 'utils';
import { Link } from 'react-router-dom';
import { formatRoleName } from 'utils/formatters';
import classNames from 'classnames';
import ResetEmailPassIcon from 'components/atoms/icons/reset-email-pass.svg?react';
import BaseIconButton from 'components/atoms/base-icon-button';
import CopyableText from 'components/atoms/buttons/copyable-text';
import i18n from 'infrastructure/i18n';

import s from './styles.module.scss';

import type { IAdminPanelAgencyDoctor } from 'infrastructure/interfaces';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

interface Props {
  onClickResetPass: (email: string) => void;
  disabledReset: boolean;
}

export const adminPanelAgencyUsersColumns = ({
  onClickResetPass,
  disabledReset,
}: Props): IBaseTableColumns<IAdminPanelAgencyDoctor> => [
  {
    label: '№',
    key: 'guid',
    width: '50px',
    align: 'center',
    render: (guid, record, idx) => <span className={s.number}>{idx + 1}</span>,
  },
  {
    label: i18n.t('labels.fullName'),
    key: 'firstName',
    render: (firstName: string, { lastName, guid }) => (
      <Link to={`/admin/agent/${guid}`} className={s['full-name']}>
        {fullNamePresenter({ firstName, lastName })}
      </Link>
    ),
  },
  {
    label: i18n.t('labels.role'),
    key: 'roles',
    width: '220px',
    render: (roles: string) =>
      roles
        .split(', ')
        .map((name) => formatRoleName(name))
        .join(', '),
  },
  {
    label: i18n.t('labels.agency'),
    key: 'agencies',
    width: '320px',
    render: (agencies: string[]) => agencies.join(', '),
  },
  {
    label: i18n.t('labels.email'),
    key: 'email',
    width: '290px',
    render: (email: string) => (
      <CopyableText
        label={email}
        className={s.email}
        value={email}
        icon={false}
      />
    ),
  },
  {
    label: i18n.t('labels.resetPass'),
    key: 'guid',
    width: '80px',
    align: 'center',
    render: (_, record) => (
      <BaseIconButton
        onClick={() => onClickResetPass(record.email)}
        className={s['reset-pass-btn']}
        disabled={disabledReset}
        icon={<ResetEmailPassIcon className={s['reset-pass-icon']} />}
      />
    ),
  },
  {
    label: i18n.t('labels.status'),
    key: 'status',
    width: '120px',
    render: (status: string) => (
      <p className={classNames(s.status, s[status])}>{capitalize(status)}</p>
    ),
  },
];
