import React from 'react';
import PropTypes from 'prop-types';

const PaginationCaption = ({ page, pageTotal, itemsPerPage, total }) => {
  const startItem = (page - 1) * itemsPerPage + 1;
  const endItem = Math.min(startItem + itemsPerPage - 1, total);
  const displayRange = `${startItem} - ${endItem} of ${total}`;

  if (page && total && pageTotal && itemsPerPage) {
    return <span data-cy="pagination-caption">{displayRange}</span>;
  }
  return <span />;
};

PaginationCaption.defaultProps = {
  page: 0,
  itemsPerPage: 0,
  total: 0,
};

PaginationCaption.propTypes = {
  page: PropTypes.number,
  pageTotal: PropTypes.number,
  itemsPerPage: PropTypes.number,
  total: PropTypes.number,
};

export default PaginationCaption;
