import { ADHERENCE_REPORT } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';
import common from 'constants/common';

import useApi from './use-api';

import type { IAdherenceReportFilters } from 'infrastructure/interfaces';

type UseReports = {
  loading: boolean;
  createAdherenceReport: (filters: IAdherenceReportFilters) => Promise<void>;
};

export const useReports = (): UseReports => {
  const { loading, createData } = useApi();
  const createAdherenceReport = async (filters: IAdherenceReportFilters) => {
    if (loading) return;
    const { error } = await createData(ADHERENCE_REPORT, filters);
    showResult(error, common.submitSuccessTypes.sendReport);
  };

  return {
    loading,
    createAdherenceReport,
  };
};
