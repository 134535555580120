import React, { useEffect } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import BaseInput from 'components/atoms/base-input';
import { useAgencyInfo } from 'infrastructure/hooks/agency/use-agency-info';
import StateSelectBox from 'components/molecules/states-types-select-box';

interface IAdminPanelCcmClinicManagerFormProps {
  agencyGuid?: string;
  loading?: boolean;
}

const AdminPanelCcmClinicManagerForm: React.FC<
  IAdminPanelCcmClinicManagerFormProps
> = (props) => {
  const { agencyGuid, loading: loadingProp = false } = props;
  const { t } = useTranslation();

  const { agencyInfo, agencyInfoLoading } = useAgencyInfo({
    agencyGuid,
  });

  const formik = useFormik({
    initialValues: agencyInfo ?? {},
    onSubmit: async () => {},
  });

  useEffect(() => {
    formik.resetForm({ values: agencyInfo });
  }, [agencyInfo]);

  return (
    <PatientBlock
      title={t('labels.ccmClinicalManager')}
      canEdit={false}
      loading={agencyInfoLoading || loadingProp}
    >
      <BaseForm formik={formik} plaintext readonly>
        <BaseFormItem name="legalname" label={t('labels.legalName')}>
          <BaseInput />
        </BaseFormItem>
        <BaseFormItem name="addresses" label={t('labels.addresses')}>
          <BaseInput />
        </BaseFormItem>
        <BaseFormItem name="city" label={t('labels.city')}>
          <BaseInput />
        </BaseFormItem>
        <BaseFormItem name="state" label={t('labels.state')}>
          <StateSelectBox />
        </BaseFormItem>
        <BaseFormItem name="phone" label={t('labels.office')}>
          <BaseInput />
        </BaseFormItem>
        <BaseFormItem name="fax" label={t('labels.fax')}>
          <BaseInput />
        </BaseFormItem>
      </BaseForm>
    </PatientBlock>
  );
};

export default AdminPanelCcmClinicManagerForm;
