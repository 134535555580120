import React from 'react';
import BaseAsyncSelectBox from 'components/atoms/base-select-box/async';
import BaseSelectBox from 'components/atoms/base-select-box';
import { useClinicSelect } from 'infrastructure/hooks/select/use-clinic-select';

import type { ClinicSelect } from 'infrastructure/classes/select/clinic-select';
import type { IPaginationMeta } from 'infrastructure/interfaces';
import type {
  IBaseAsyncSelectBoxProps,
  IBaseSelectBoxOption,
} from 'components/atoms/base-select-box/types';

export type ParentClinicSelectOption = IBaseSelectBoxOption<
  string,
  ClinicSelect
>;
type ParentClinicSelectBoxProps = IBaseAsyncSelectBoxProps<
  string,
  ClinicSelect
>;

const StaticSelectBox: React.FC<ParentClinicSelectBoxProps> = (props) => {
  return <BaseSelectBox {...props} />;
};

const AsyncSelectBox: React.FC<ParentClinicSelectBoxProps> = (props) => {
  const { onLoaded } = props;

  const { meta, loadClinics } = useClinicSelect();

  const fetchOptions = async (search: string, metaProps: IPaginationMeta) => {
    const clinics = await loadClinics({
      ...metaProps,
      search,
      parentClinicsOnly: true,
    });

    if (clinics)
      return clinics.map((el) => {
        return {
          label: el.label,
          value: el.guid,
          record: el,
        };
      });

    return [];
  };

  return (
    <BaseAsyncSelectBox
      fetchOptions={fetchOptions}
      meta={meta}
      onValueLoaded={onLoaded}
      canSearch
      {...props}
    />
  );
};

const ParentClinicSelectBox: React.FC<ParentClinicSelectBoxProps> = (props) => {
  const { options } = props;
  return options ? (
    <StaticSelectBox {...props} />
  ) : (
    <AsyncSelectBox {...props} />
  );
};

export default ParentClinicSelectBox;
