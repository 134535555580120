import React from 'react';
import classNames from 'classnames';
import CopyableText from 'components/atoms/buttons/copyable-text';

import s from './styles.module.scss';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface IBaseFormItemGroupProps extends IBaseComponentProps {
  label: React.ReactNode;
  copyText?: string;
}

const BaseFormItemGroup: React.FC<IBaseFormItemGroupProps> = (props) => {
  const { label, copyText, children, className } = props;

  const groupClassNames = classNames(s.group, className);
  return (
    <div className={groupClassNames}>
      {copyText ? (
        <CopyableText label={label} className={s.label} value={copyText} />
      ) : (
        <label className={s.label}>{label}</label>
      )}
      {children}
    </div>
  );
};

export default BaseFormItemGroup;
