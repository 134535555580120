import React, { useRef, useState } from 'react';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import { ModuleType } from 'infrastructure/enums';
import { useEdit } from 'infrastructure/providers/edit-provider';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import useUser from 'utils/useUser';

import PatientSubscriptionsFormItem from './item';
import s from './styles.module.scss';

import type { IPatientSubscriptionsFormItemRef } from './item';

const formKey = 'PatientSubscriptionsForm';

interface IAdminPanelPatientInfoFormProps {
  patientGuid: string;
  module: ModuleType;
}

const PatientSubscriptionsForm: React.FC<IAdminPanelPatientInfoFormProps> = (
  props,
) => {
  const { patientGuid, module } = props;
  const { canEdit, setEdit } = useEdit();
  const isAdmin = module === ModuleType.ADMIN;
  const { t } = useTranslation();
  const { loading, patientInfo, updatePatientInfo } = usePatientInfo({
    patientGuid,
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const itemsRef = useRef<IPatientSubscriptionsFormItemRef>(null);

  const { iamPatientAcceptance } = useUser();

  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };

  const onCancel = () => {
    itemsRef.current?.resetForm();
    setIsEdit(false);
    setEdit(false);
  };

  const onSave = async () => {
    const res = itemsRef.current?.validate();
    if (res && res.isValid) {
      const updatedItem = res.value;
      await updatePatientInfo(updatedItem);
      setIsEdit(false);
      setEdit(false);
    }
  };

  return (
    <PatientBlock
      title={t('labels.subscriptions')}
      canEdit={isAdmin}
      isEdit={isEdit}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      loading={loading}
      disabled={!canEdit(formKey) || iamPatientAcceptance}
    >
      <ul className={s.list}>
        {patientInfo && (
          <PatientSubscriptionsFormItem
            ref={itemsRef}
            data={patientInfo}
            isEdit={isEdit}
          />
        )}
      </ul>
    </PatientBlock>
  );
};

export default PatientSubscriptionsForm;
