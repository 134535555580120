import React, { useRef } from 'react';
import BaseContainer from 'components/atoms/base-container';
import ClinicForm from 'components/molecules/clinic/form';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import { useTranslation } from 'react-i18next';
import { Routes } from 'infrastructure/consts/routes';
import { useCreateClinic } from 'infrastructure/hooks/clinic/use-create-clinic';
import { useNavigate } from 'react-router-dom';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import SaveButton from 'components/atoms/controls/save-button';

import type { IClinicFormRef } from 'components/molecules/clinic/form';

const AdminClinicAddPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, createClinic } = useCreateClinic();

  const ref = useRef<IClinicFormRef>(null);

  const onSave = async () => {
    if (ref.current) {
      const res = await ref.current.submit();

      if (res.isValid) {
        const clinic = await createClinic({
          ...res.values,
          clientSuccessOnboarding: res.values.clientSuccessOnboardingGuid,
          salesRepresentative: res.values.salesRepresentativeGuid,
          clientSuccessScaling: res.values.clientSuccessScalingGuid,
        });
        if (clinic) {
          navigate(Routes.adminClinicsDetail(clinic.guid), { replace: true });
        }
      }
    }
  };

  const controls = <SaveButton width="160px" onClick={onSave} />;

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseContainer>
        <AdminNavigationHeader
          goBackPath={Routes.adminClinics}
          title={t('labels.addClinic')}
          controls={controls}
        />
        <ClinicForm ref={ref} />
      </BaseContainer>
    </BaseSpinWrapper>
  );
};

export default AdminClinicAddPage;
