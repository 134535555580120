import PcmTagIcon from '../icons/pcmTabIcon';
import BaseTag from './BaseTag';

import type { FC } from 'react';

interface Props {
  small?: boolean;
}

const PcmTag: FC<Props> = ({ small }) => {
  return (
    <BaseTag
      icon={<PcmTagIcon />}
      text="PCM"
      color="orange"
      dataCy="pcm-tag"
      small={small}
    />
  );
};

export default PcmTag;
