import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { readingTypesDataSource } from 'infrastructure/data-sources/reading-types';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IReadingTypeItem } from 'infrastructure/data-sources/reading-types';
import type { ReadingType } from 'infrastructure/enums';

type Options = IBaseSelectBoxOption<IReadingTypeItem['id'], IReadingTypeItem>[];

interface IReadingTypeSelectBoxProps
  extends Omit<IBaseSelectBoxProps<ReadingType>, 'options'> {}

const ReadingTypeSelectBox: React.FC<IReadingTypeSelectBoxProps> = (props) => {
  const dataSource = useMemo(() => readingTypesDataSource(), []);

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...props} />;
};

export default ReadingTypeSelectBox;
