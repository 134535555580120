import { ICDCode } from './icd-code';

import type { IPatientIcdCode } from 'infrastructure/interfaces';

export class PatientIcdCode {
  guid: string;
  createdAt: Date;
  description: string;
  icdCode: ICDCode;

  constructor(data: IPatientIcdCode) {
    this.guid = data.guid;
    this.createdAt = new Date(data.createdAt);
    this.icdCode = new ICDCode(data.icdCode);
    this.description = data.description;
  }
}
