import React from 'react';
import classNames from 'classnames';

import s from './styles.module.scss';
import BaseTableHeaderBaseColumn from './cols/base';
import BaseTableHeaderSortableColumn from './cols/sortable';

import type { SortState } from 'infrastructure/types';
import type { IBaseTableColumnProps } from '../types';

interface IBaseTableHeaderProps {
  columns: Array<IBaseTableColumnProps>;
  className?: string;
  sticky?: boolean;
  onClick?: (field: string, sort: SortState) => void;
}

const BaseTableHeader: React.FC<IBaseTableHeaderProps> = (props) => {
  const { columns, onClick, className, sticky = true } = props;

  const headerClassNames = classNames(
    s.header,
    {
      [s.sticky]: sticky,
    },
    className,
  );

  const onSort = (field: string, sort: SortState) => {
    if (onClick) {
      onClick(field, sort);
    }
  };

  return (
    <thead className={headerClassNames} data-cy="table-header">
      <tr>
        {columns.map((column) => (
          <th
            style={{ width: column.width }}
            key={column.key}
            className={classNames(
              s.wrapper,
              {
                [s.sortable]: column.sortable,
                [s.fixed]: column.fixed,
              },
              column.tableCollClassName,
            )}
          >
            <div
              className={classNames(s.column, column.tableCollClassName)}
              style={{ width: column.width }}
            >
              {column.sortable ? (
                <BaseTableHeaderSortableColumn
                  {...column}
                  onClick={(sort) => onSort(`${column.sortKey}`, sort)}
                />
              ) : (
                <BaseTableHeaderBaseColumn {...column} />
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default BaseTableHeader;
