export const API_SEARCH_PATIENTS = 'core/search/patients';
export const API_PATIENTS_COMMENTS = 'core/patients/comments';
export const API_PATIENTS_MESSAGES = 'core/patients/messages';
export const API_PATIENTS_PROFILE = 'core/patients/profile';
export const API_PATIENTS_DISCHARGES = 'core/patients/discharges';
export const API_PATIENTS_APPOINTMENTS = 'core/patients/appointments';
export const API_HOSPITAL_PREVENTION = 'core/patients/hospital-prevention';
export const API_CLINICAL_ESCALATION = 'core/patients/clinical-escalation';
export const API_PATIENTS_ORDER_CONSUMABLES = 'core/patients/orders';
export const API_PATIENTS_COVID_STATUS = 'core/patients/covid-status';
export const API_CCM_PATIENT_CALL = 'core/ccm/calls';
export const API_PATIENT_STATUS = 'core/patients/status';
export const API_READINGS_ACTIONS = 'core/patients/readings/actions';
export const API_READINGS_STOP_SLA_TIMER =
  'core/patients/readings/stop-sla-timer';
export const API_PATIENTS_THRESHOLDS = 'core/patients/thresholds';
export const PATIENTS_STATUS_CSV_REPORT = 'reports/reports/status-report';
export const API_PATIENTS_STATUS_COUNT = 'reports/reports/status-report/count';
export const API_PATIENTS_STATUS_REPORT = 'reports/reports/status-report';
export const API_ADMIN_PANEL_PATIENT = 'admins/admin-panel/patients';
export const API_ADMIN_PANEL_PATIENT_CCMDATA =
  'admins/admin-panel/patients/ccm-data';
export const API_PATIENTS_READINGS_RESERVE = 'core/patients/readings/reserve';
export const API_PATIENTS_INTERACTION_STATUS =
  'core/patients/interaction-status';
export const API_PATIENTS_NUTRITION_STATUS = (patientGuid) =>
  `core/ccm/patients/${patientGuid}/nutrition`;
export const API_PATIENTS_PVR_STATE = 'admins/admin-panel/patients/pvr-lite';
export const API_PATIENTS_DO_NOT_CALL =
  'admins/admin-panel/patients/do-not-call';
export const API_PATIENTS_MANUAL_INTERACTIVE_TIME = (guid) =>
  `admins/admin-panel/patients/${guid}/manual-time`;
export const API_CCM_PATIENTS_MANUAL_INTERACTIVE_TIME = (guid) =>
  `core/ccm/patients/${guid}/manual-time`;
export const API_PATIENTS_PCM_STATUS = (guid) =>
  `core/ccm/patients/${guid}/pcm-status`;
export const API_PATIENT_CCM_HOLDING_STATUS =
  'core/patients/ccm-holding-status';
export const API_CREATE_CASE_IN_EHR = (guid) =>
  `core/patients/${guid}/case-in-ehr`;
export const API_PATIENT_KEY_NOTE = (guid) => `core/patients/${guid}/key-note`;
export const API_READING_SUGGEST_COMMENT = (guid) =>
  `core/patients/${guid}/readings/suggest-comment`;
export const API_PATIENTS_SELECT_OPTIONS = 'core/patients/select-options';
export const API_PATIENT_CONSUMABLES_DATE = 'core/patients/consumables-date';
