import React from 'react';
import { Container, Row } from 'reactstrap';
import BillingTable from 'components/templates/ccm/practice/billingReportV2/table';
import Count from 'components/templates/ccm/practice/billingReport/count';
import useTable from 'utils/useTable';
import { API_BILLING_REPORTS_V2 } from 'services/dataService/ccmResources';
import { format, subMonths } from 'date-fns';

const PER_PAGE = 10;
let initialDateFilter;
let timeRanges;

const generateTimeRanges = () => {
  const today = new Date();
  const dateOptions = [];

  for (let i = 0; i < 12; i++) {
    const date = subMonths(today, i);
    const billingYear = date.getFullYear();
    const billingMonth = date.getMonth() + 1;
    const label = format(new Date(date), 'MMM-yyyy');
    dateOptions.push({
      value: [billingYear, billingMonth],
      label,
    });
  }

  initialDateFilter = {
    billingYear: dateOptions[0].value[0],
    billingMonth: dateOptions[0].value[1],
  };

  timeRanges = dateOptions;
};

const Index = () => {
  if (!timeRanges) generateTimeRanges();

  const { data, count, loading, page, setPage, filters, setFilters } = useTable(
    API_BILLING_REPORTS_V2,
    PER_PAGE,
    { ...initialDateFilter },
  );

  return (
    <Container>
      <Row>
        <BillingTable
          timeRanges={timeRanges}
          filters={filters}
          setFilters={setFilters}
          isLoading={loading}
          items={data?.items}
          count={count}
          page={page}
          setPage={setPage}
          itemsPerPage={PER_PAGE}
        />
      </Row>
      <Row>
        <Count
          highCount={data?.highActivityCount || 0}
          mediumCount={data?.mediumActivityCount || 0}
          lowCount={data?.lowActivityCount || 0}
        />
      </Row>
    </Container>
  );
};

export default Index;
