import type {
  DeviceAlarmChangeRequestStatus,
  DeviceStateType,
} from 'infrastructure/enums';
import type { IDeviceLog } from 'infrastructure/interfaces';
import type { DateString, Nullable } from 'infrastructure/types';

export class DeviceLog {
  type: DeviceStateType;
  signalStrength: number;
  cycles: number;
  batteryLevel: Nullable<number>;
  settingChangeStatus: DeviceAlarmChangeRequestStatus;
  localTime: DateString;
  deviceTime: DateString;
  serverTime: DateString;
  constructor(data: IDeviceLog) {
    this.type = data.type;
    this.signalStrength = data.signalStrength;
    this.cycles = data.cycles;
    this.batteryLevel = data.batteryLevel;
    this.settingChangeStatus = data.settingChangeStatus;
    this.localTime = data.localTime;
    this.deviceTime = data.deviceTime;
    this.serverTime = data.serverTime;
  }
}
