import dataService from 'services/dataService';
import { apiGetRunBookLogs } from 'services/dataService/resources';

const ITEMS_PER_PAGE = 10;

const clinicRunBookLogs = {
  state: {
    page: 1,
    items: ITEMS_PER_PAGE,
    reverse: true,
    totalCount: 1,
    totalPages: 0,
    data: [],
    guidValue: null,
  },
  reducers: {
    setRunBookLogs: (state, payload) => ({
      ...state,
      data: [...payload],
    }),
    setPage: (state, payload) => ({
      ...state,
      page: payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    toggleReversed: (state) => ({
      ...state,
      reverse: !state.reverse,
    }),
  },
  effects: (dispatch) => ({
    async getRunBookLogs({ guidValue, isHHAorPractice }, state) {
      const { page, reverse } = state.clinicRunBookLogs;
      const items = isHHAorPractice ? 1 : 10;
      const { data } = await dataService.getList(
        apiGetRunBookLogs(guidValue, items, page, !reverse),
      );
      dispatch.clinicRunBookLogs.setRunBookLogs(data?.data || []);
      const totalCount = data?.count || 0;
      const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
      dispatch.clinicRunBookLogs.setTotalCountAndTotalPages({
        totalCount,
        totalPages,
      });
    },

    async setPageHistories({ pageOffset }, state) {
      const { page, totalPages } = state.clinicRunBookLogs;
      const newPage = page + pageOffset;
      dispatch.clinicRunBookLogs.setPage(
        newPage <= totalPages ? newPage : totalPages,
      );
    },

    async toggleOrder() {
      dispatch.clinicRunBookLogs.toggleReversed();
      dispatch.clinicRunBookLogs.setPage(1);
    },
  }),
};

export default clinicRunBookLogs;
