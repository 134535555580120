import React, { useEffect, useState } from 'react';
import BaseTableNew from 'components/atoms/base-table/new';
import BaseModal from 'components/atoms/base-modal';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import PlusIcon from 'components/atoms/icons/plus';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import { usePatientIcdCode } from 'infrastructure/hooks/patient/use-patient-icd-code';
import useUser from 'utils/useUser';

import { conditionsColumns } from './cols';
import ConditionCreateForm from '../create-form';
import s from './styles.module.scss';
import ConditionUpdateForm from '../update-form';

import type { PatientIcdCode } from 'infrastructure/classes/patient-icd-code';

interface IConditionsTableProps {
  patientGuid: string;
}

const ConditionsTable: React.FC<IConditionsTableProps> = (props) => {
  const { patientGuid } = props;

  const { t } = useTranslation();

  const [open, setIsOpen] = useState(false);
  const [condition, setCondition] = useState<PatientIcdCode | undefined>();

  const modalLabel = condition
    ? t('labels.editCondition')
    : t('labels.createCondition');

  const { Alert, showAlert } = useAlert();

  const {
    loading,
    patientIcdCodes,
    loadPatientIcdCodes,
    deletePatientIcdCode,
  } = usePatientIcdCode({ patientGuid });

  const { iamPatientAcceptance } = useUser();

  const loadData = async () => {
    await loadPatientIcdCodes();
  };

  const onCreate = () => {
    setCondition(undefined);
    setIsOpen(true);
  };

  const onEdit = (record: PatientIcdCode) => {
    setCondition(record);
    setIsOpen(true);
  };

  const onDelete = async (record: PatientIcdCode) => {
    const res = await showAlert({
      title: t('labels.confirmDeleting'),
      messageTitle: t('messages.conditionDelitingConfirm'),
      type: 'danger',
    });
    if (res) {
      await deletePatientIcdCode(record.guid, { patientGuid });
      await loadData();
    }
  };

  const onSaved = async () => {
    setIsOpen(false);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, [patientGuid]);

  return (
    <div>
      <BaseTableNew
        dataCy="conditions-table"
        loading={loading}
        columns={conditionsColumns({
          onEdit,
          onDelete,
        })}
        items={patientIcdCodes}
        bordered
      />
      <div className={s.controls}>
        {!iamPatientAcceptance && (
          <BaseButton
            loading={loading}
            icon={<PlusIcon color="" />}
            label={t('controls.addNewCondition')}
            onClick={onCreate}
            dataCy="addNewCondition-button"
          />
        )}
      </div>
      <BaseModal
        title={modalLabel}
        open={open}
        onCancel={() => setIsOpen(false)}
      >
        {condition ? (
          <ConditionUpdateForm
            data={condition}
            patientGuid={patientGuid}
            onSaved={onSaved}
            onCancel={() => setIsOpen(false)}
          />
        ) : (
          <ConditionCreateForm
            patientGuid={patientGuid}
            onSaved={onSaved}
            onCancel={() => setIsOpen(false)}
          />
        )}
      </BaseModal>
      {Alert}
    </div>
  );
};

export default ConditionsTable;
