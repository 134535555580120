import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChatHeader from 'components/atoms/chat/chatHeader';
import MessagesInput from 'components/molecules/smsDashboard/messagesInput';
import MessagesList from 'components/molecules/smsDashboard/messagesList';
import ChatEmptyIcon from 'components/atoms/icons/chatEmptyIcon';

import type { IConversation } from 'infrastructure/interfaces';
import type { RootState } from 'store';

const Chat = () => {
  const { t } = useTranslation();

  const { currentConversation } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const isCurrentConversationExist = currentConversation.id !== 0;

  return (
    <>
      {!isCurrentConversationExist ? (
        <div className="empty-conversation">
          <div className="message-wrapper">
            <div className="empty-message">
              <ChatEmptyIcon />
              <div className="message">
                {t('smsDashboard.select_a_chat_to_start_a_conversation')}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="chat-wrapper">
          <ChatHeader conversation={currentConversation as IConversation} />
          <MessagesList
            currentConversation={currentConversation as IConversation}
          />
          <MessagesInput conversation={currentConversation as IConversation} />
        </div>
      )}
    </>
  );
};

export default Chat;
