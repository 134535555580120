import React from 'react';
import BaseAsyncSelectBox from 'components/atoms/base-select-box/async';
import { useAgencySelect } from 'infrastructure/hooks/select/use-agency-select';
import BaseSelectBox from 'components/atoms/base-select-box';

import type { AgencySelect } from 'infrastructure/classes/select/agency-select';
import type { IPaginationMeta } from 'infrastructure/interfaces';
import type {
  IBaseAsyncSelectBoxProps,
  IBaseSelectBoxOption,
} from 'components/atoms/base-select-box/types';

export type AgencySelectOption = IBaseSelectBoxOption<string, AgencySelect>;
type AgencySelectBoxProps = IBaseAsyncSelectBoxProps<string, AgencySelect>;

const StaticSelectBox: React.FC<AgencySelectBoxProps> = (props) => {
  return <BaseSelectBox {...props} />;
};

const AsyncSelectBox: React.FC<AgencySelectBoxProps> = (props) => {
  const { onLoaded } = props;

  const { meta, loadAgencies } = useAgencySelect();

  const fetchOptions = async (search: string, metaProps: IPaginationMeta) => {
    const agencies = await loadAgencies({
      ...metaProps,
      search,
    });

    if (agencies)
      return agencies.map((el) => {
        return {
          label: el.label,
          value: el.guid,
          record: el,
        };
      });

    return [];
  };

  return (
    <BaseAsyncSelectBox
      fetchOptions={fetchOptions}
      meta={meta}
      onValueLoaded={onLoaded}
      canSearch
      {...props}
    />
  );
};

const AgencySelectBox: React.FC<AgencySelectBoxProps> = (props) => {
  const { options } = props;
  return options ? (
    <StaticSelectBox {...props} />
  ) : (
    <AsyncSelectBox {...props} />
  );
};

export default AgencySelectBox;
