import React, { useState, useEffect } from 'react';
import MinusIcon from 'components/atoms/icons/minus';
import times from 'lodash/times';
import PropTypes from 'prop-types';
import PlusIcon from 'components/atoms/icons/plus';

import CheckboxInput from './CheckboxInput';
import DateInput from './DateInput';
import RadioInput from './RadioInput';
import TextInput from './TextInput';
import SelectInput from './SelectInput';

const getInputComponent = (type, props) => {
  const components = {
    TEXT: <TextInput {...props} key={props.id} />,
    MULTIPLE_CHOICE: <RadioInput {...props} key={props.id} />,
    CHECKBOX: <CheckboxInput {...props} key={props.id} />,
    DATE: <DateInput {...props} key={props.id} />,
    SELECT: <SelectInput {...props} key={props.id} />,
  };
  return components[type] || null;
};

const Complex = ({ id, setValue, forms, groupId, values, canEditPage }) => {
  const [elementsCount, setElementsCount] = useState(
    values[groupId]?.length || 1,
  );

  const addRow = () => {
    setElementsCount((prev) => prev + 1);
    values[groupId].push({});
    setValue({ [groupId]: values[groupId] });
  };

  const removeRow = (index) => {
    values[groupId].splice(index, 1);
    setValue({ [groupId]: values[groupId] });
    setElementsCount((prev) => prev - 1);
  };

  useEffect(() => {
    const value = values[groupId];
    if (!value) {
      setValue({ [groupId]: [{}] });
    }
  }, []);

  return (
    <div className="complex-inputs-wrapper" data-cy={id}>
      <span className="add-button" onClick={addRow}>
        <PlusIcon width={15} height={15} />
      </span>
      {times(elementsCount, (index) => (
        <div className="complex-inputs">
          {elementsCount > 1 && (
            <span className="remove-button" onClick={() => removeRow(index)}>
              <MinusIcon width={15} height={15} />
            </span>
          )}
          {forms.map((item) =>
            getInputComponent(item.type, {
              ...item,
              setValue: (data) => {
                const [[key, value]] = Object.entries(data);
                values[groupId][index][key] = value;
                setValue({ [groupId]: values[groupId] });
              },
              values: values[groupId]?.[index] || {},
              canEditPage,
            }),
          )}
        </div>
      ))}
    </div>
  );
};

Complex.propTypes = {
  setValue: PropTypes.func,
  forms: PropTypes.array,
  values: PropTypes.shape(),
  canEditPage: PropTypes.bool,
  groupId: PropTypes.string,
  id: PropTypes.string,
};

export default Complex;
