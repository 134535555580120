import PatientAppointment from 'components/atoms/icons/patientAppointmentIcon';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import AppointmentCard from './components/appointment-card';
import s from './styles.module.scss';
import MonthlyAppointmentTable from './components/mothly-appointment-table';

interface Props {
  patientGuid: string;
}

const PatientAppointmentDetails: FC<Props> = ({ patientGuid }) => {
  const { t } = useTranslation();

  return (
    <div className={s.wrapper} id="appointment-details">
      <h4 className={s.title}>
        <PatientAppointment />
        {t('labels.patientAppointmentDetails')}
      </h4>
      <div className={s.divider} />
      <div className={s.body}>
        <AppointmentCard patientGuid={patientGuid} />
        <MonthlyAppointmentTable patientGuid={patientGuid} />
      </div>
    </div>
  );
};
export default PatientAppointmentDetails;
