import classNames from 'classnames';
import BaseLoader from 'components/atoms/base-loader';
import React, { useState } from 'react';
import dataService from 'services/dataService';
import { API_EMAIL_SUBSCRIPTION } from 'services/dataService/resources/public';
import { showResult } from 'infrastructure/hooks/utils';

const EmailUnsubscribedPage = () => {
  const baseClass = 'email-subscription-page';

  const [resubscribed, setResubscribed] = useState(false);
  const [loading, setLoading] = useState(false);

  const resubscribe = async () => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (!token) {
      showResult('Token not found');
      return;
    }
    setLoading(true);
    const { error } = await dataService.createOne(API_EMAIL_SUBSCRIPTION, {
      token,
      subscription: true,
    });
    setLoading(false);
    if (error) {
      showResult(error);
    } else {
      setResubscribed(true);
    }
  };

  const unsubscribedTitle = "You're Unsubscribed";
  const resubscribedTitle = 'You Have Been Resubscribed';

  return (
    <div className={baseClass}>
      <div className={`${baseClass}--container`}>
        <div className={`${baseClass}--logo-container`}>
          <img src="/img/accuhealth-logo.png" alt="logo" height={40} />
        </div>
        <div className={`${baseClass}--image-container`}>
          <img
            src="/img/email-unsubscribe/unsubscribed.png"
            alt="unsubscribe-main"
            className={`${baseClass}--main-image`}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <h1 className={`${baseClass}--title`}>
            {resubscribed ? resubscribedTitle : unsubscribedTitle}
          </h1>
          {resubscribed === false && (
            <>
              <p>You have been unsubscribed from Accuhealth emails.</p>
              <p>
                Please note that you will continue to receive emails only if
                consent is provided to an Accuhealth Agent to send the
                information.
              </p>
              <p>
                For emails resubscribtion text Accuhealth at 90105 or call
                1-888-407-4108
              </p>
            </>
          )}
          {resubscribed === true && (
            <>
              <p>You have been resubscribed to Accuhealth emails.</p>
            </>
          )}
        </div>
        {resubscribed === false && (
          <div className={`${baseClass}--buttons-container`}>
            <button
              onClick={resubscribe}
              className={classNames(
                `${baseClass}--button`,
                loading && `${baseClass}--button`,
              )}
              disabled={loading}
            >
              {loading && (
                <div className={`${baseClass}--button--loader-container`}>
                  <BaseLoader loading width={20} height={20} />
                </div>
              )}
              Re-subscribe
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailUnsubscribedPage;
