import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import useUser from 'utils/useUser';
import { isCriticalRole, isAtRiskRole } from 'utils/accessManager/helpers';
import AutoCompleteClinics from 'components/molecules/autocompleteFilter/clinic';
import AutoComplete from 'components/molecules/autocompleteFilter';
import forms from 'constants/forms';
import common from 'constants/common';
import Button from 'components/atoms/button';
import { getRangeDates } from 'utils';
import BaseSelect from 'components/atoms/baseSelect';
import useConfirm from 'utils/useConfirm';

import TimeFilter from '../../../../organisms/timeFilter';

const DEFAULT_CLINIC_FILTER = forms.clinicFilterOptions[0];
const DEFAULT_FILTER = forms.patientFilterOptions[0];
const DEFAULT_STATUS = forms.statusSelect[0];
const DEFAULT_INSURANCE = forms.clinicInsuranceTypeSelect[0];
const DEFAULT_RANGE = forms.timeFilterRanges[4];
const DEFAULT_ACTION_TIME_QUOTA = forms.actionTimesQuotaSelect[0];

const { startRangeDate, endRangeDate } = getRangeDates(DEFAULT_RANGE);
const showConfirm = useConfirm({
  message: 'You have unsaved changes, are you sure you want to leave?',
  title: 'Confirm',
});

const Sorting = ({ toggleAction, action }) => {
  const { roleNames } = useUser();
  const { insuranceTypes, filters, unsavedComments } = useSelector(
    (state) => state.doctor,
  );
  const timeFilterRef = useRef();
  const clinicChangerRef = useRef();
  const patientChangerRef = useRef();
  const {
    doctor: { applyFilters, applyTimeFilter, clearComments },
  } = useDispatch();

  const patientFilter = DEFAULT_FILTER;
  const clinicsFilter = DEFAULT_CLINIC_FILTER;
  const handleSubmit = (dates) => {
    applyTimeFilter(dates);
  };

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 12,
    colors: {
      ...theme.colors,
      ...common.primaryColors,
    },
  });
  const statusValue = (statusName) => {
    const value = forms.statusSelect.find(
      (element) => element.value === statusName,
    );
    return value;
  };

  const insuranceTypeValue = (currentInsuranceType) => {
    const value = [
      DEFAULT_INSURANCE,
      ...insuranceTypes.map((el) => ({ label: el, value: el })),
    ].find((element) => element.value === currentInsuranceType);
    return value;
  };

  const actionTimesQuotaValue = (quotaName) => {
    const value = forms.actionTimesQuotaSelect.find(
      (element) => element.value === quotaName,
    );
    return value;
  };

  const ignoreUnsavedChanges =
    (ok, cancel) =>
    async (...props) => {
      let confirm = true;
      if (Object.keys(unsavedComments).length) {
        confirm = await showConfirm();
      }
      if (confirm) {
        clearComments();
        return ok && ok(...props);
      }
      return cancel && cancel(...props);
    };

  const statusChanged = async ({ value }) => {
    if (value !== filters.status) {
      let confirm = true;
      if (Object.keys(unsavedComments).length) {
        confirm = await showConfirm();
      }
      if (confirm) {
        clearComments();
        return applyFilters({ status: value });
      }
    }
  };

  const insuranceTypeChanged = async ({ value }) => {
    if (value !== filters.insuranceType) {
      let confirm = true;
      if (Object.keys(unsavedComments).length) {
        confirm = await showConfirm();
      }
      if (confirm) {
        clearComments();
        return applyFilters({ insuranceType: value });
      }
    }
  };

  const timeFilterChanged = async (newDates, range) => {
    let confirm = true;
    if (Object.keys(unsavedComments).length) {
      confirm = await showConfirm();
    }
    if (confirm) {
      clearComments();
      return handleSubmit(newDates);
    }
    timeFilterRef.current.setValue(range);
  };

  const actonTimesQuotaChanged = async ({ value }) => {
    if (value !== filters.actionTimesQuota) {
      let confirm = true;
      if (Object.keys(unsavedComments).length) {
        confirm = await showConfirm();
      }
      if (confirm) {
        clearComments();
        return applyFilters({ actionTimesQuota: value });
      }
    }
  };

  const hideMenu = () => {
    const menu = document.getElementById('dashboard-menu');
    menu.className = 'overview-dashboard-menu-mobile hidden';
  };

  return (
    <div className="overview-dashboard-menu-mobile hidden" id="dashboard-menu">
      <div className="overview-dashboard-menu-mobile-row">
        <button
          className="overview-dashboard-menu-mobile-close"
          onClick={hideMenu}
        >
          &times;
        </button>
      </div>
      <div className="overview-dashboard-menu-mobile-row">
        <div className="overview-dashboard-menu-mobile-header">
          <p>Filter</p>
          <div
            className="overview-dashboard-menu-mobile-header-cancel"
            onClick={() => {
              applyFilters({
                patientGuid: '',
                status: null,
                clinicGuid: '',
                insuranceType: '',
              });
              applyTimeFilter([startRangeDate, endRangeDate]);
            }}
          >
            Cancel All
          </div>
        </div>
      </div>
      <div className="overview-dashboard-menu-mobile-row">
        <p>Report Time Window</p>
        <TimeFilter
          inputRef={patientChangerRef}
          handleSubmit={timeFilterChanged}
          noUTC
        />
      </div>
      <div className="overview-dashboard-menu-mobile-row">
        <p>Patient Filter</p>
        <AutoComplete
          inputRef={patientChangerRef}
          filter={patientFilter}
          handleClick={ignoreUnsavedChanges(
            (patient) => applyFilters({ patientGuid: patient.value }),
            () => {
              patientChangerRef.current.setValue('');
            },
          )}
        />
      </div>
      {!isCriticalRole(roleNames) && !isAtRiskRole(roleNames) && (
        <div className="overview-dashboard-menu-mobile-row">
          <p>Show Status</p>
          <BaseSelect
            options={forms.statusSelect}
            defaultValue={DEFAULT_STATUS}
            theme={(theme) => selectTheme(theme)}
            classNamePrefix="select-type"
            value={statusValue(filters.status)}
            onChange={statusChanged}
          />
        </div>
      )}
      <div className="overview-dashboard-menu-mobile-row">
        <p>Clinic</p>
        <AutoCompleteClinics
          inputRef={clinicChangerRef}
          filter={clinicsFilter}
          handleClick={ignoreUnsavedChanges(
            ({ value }) => {
              applyFilters({ clinicGuid: value });
            },
            () => {
              clinicChangerRef.current.setValue('');
            },
          )}
        />
      </div>
      <div className="overview-dashboard-menu-mobile-row">
        {filters.clinicGuid && (
          <>
            <p>Insurance Provider</p>
            <BaseSelect
              options={[
                DEFAULT_INSURANCE,
                ...insuranceTypes.map((insuranceType) => ({
                  label: insuranceType,
                  value: insuranceType,
                })),
              ]}
              defaultValue={DEFAULT_INSURANCE}
              theme={(theme) => selectTheme(theme)}
              classNamePrefix="select-type"
              onChange={insuranceTypeChanged}
              value={insuranceTypeValue(filters.insuranceType)}
            />
          </>
        )}
      </div>
      {isAtRiskRole(roleNames) && (
        <div className="overview-dashboard-menu-mobile-row">
          <p data-cy="action-quota-filter-label">Time</p>
          <BaseSelect
            options={forms.actionTimesQuotaSelect}
            defaultValue={DEFAULT_ACTION_TIME_QUOTA}
            theme={(theme) => selectTheme(theme)}
            classNamePrefix="select-type"
            value={actionTimesQuotaValue(filters.actionTimesQuota)}
            onChange={actonTimesQuotaChanged}
          />
        </div>
      )}
      <div className="overview-dashboard-menu-mobile-row">
        <p>{!action ? 'Unaction' : 'Actioned'}</p>
        <Switch
          className="evelyn-switch"
          onChange={ignoreUnsavedChanges(toggleAction)}
          checked={action}
          uncheckedIcon={false}
          checkedIcon={false}
          onHandleColor={common.commonColors.green}
          offHandleColor={common.commonColors.gray}
          onColor={common.commonColors.white}
          offColor={common.commonColors.white}
          handleDiameter={24}
          height={30}
          width={59}
        />
      </div>
      <div className="overview-dashboard-menu-mobile-row">
        <Button text="Show" handleClick={hideMenu} />
      </div>
    </div>
  );
};

Sorting.propTypes = {
  toggleAction: PropTypes.func,
  action: PropTypes.bool.isRequired,
};

export default Sorting;
