export const template = [
  {
    key: 'patient',
    value:
      'Care Coordination: Spoke to {{{ patient }}} and notified them they are on a recorded line for quality and compliance purposes.',
  },
  {
    key: 'monthly',
    value: '{{{ monthly }}}.',
  },
  {
    key: 'feeling',
    value: 'Patient stated they are feeling {{{ feeling }}}.',
  },
  {
    key: 'reading',
    value: '{{{ reading }}}',
  },
  {
    key: 'type',
    value: '{{{ type }}}.',
  },
  {
    key: 'deviceeducation',
    value: '{{{ deviceeducation }}}',
  },
  {
    key: 'reminder',
    value: '{{{ patient }}} was reminded to {{{ reminder }}}.',
  },
  {
    key: 'medication',
    value: '{{{ medication }}}.',
  },
  {
    key: 'coordination',
    value: '{{{ coordination }}}.',
  },
  {
    key: 'contact',
    value: 'They stated their preferred contact method is {{{ contact }}}.',
  },
  {
    key: 'comments',
    value: '{{{ comments }}}.',
  },
  {
    key: 'patient',
    value:
      '{{{ patient }}} was reminded that they can reach us at 888-407-4108 or by text 90105.',
  },
];

export const forms = [
  {
    id: 'patient',
    type: 'CHECKBOX',
    title:
      'Did you speak to patient? Choose other if unlisted family member & type in their relationship. (Ex: Daughter in law) Add name of individual you spoke to in other.',
    choices: ['Patient', 'Spouse', 'Caregiver'],
    hasOtherOption: true,
    isRequired: true,
    disabled: false,
  },
  {
    id: 'monthly',
    type: 'CHECKBOX',
    title: 'Monthly Interaction',
    choices: [
      'This is a monthly courtesy call for the Remote Patient Monitoring services you are enrolled in',
      'and please expect another call from Chronic Care Management services you are enrolled in',
    ],
    hasOtherOption: false,
    isRequired: true,
    disabled: false,
  },
  {
    id: 'feeling',
    type: 'TEXT',
    title: 'How are you feeling today?',
    isRequired: true,
    disabled: false,
  },
  {
    id: 'reading',
    type: 'CHECKBOX',
    title: 'Did the patient take a reading?',
    choices: [
      'The patient agreed to take a reading with me over the phone and it was successfully received.',
      'The patient has recent reading that was received and reviewed.',
      'The patient was not available to take a reading at this time.',
      'Call transferred to clinical team: The patient requested to speak to a nurse due to.',
      'Sent to clinical team for a call back: The patient requested to speak to a nurse due to.',
      'The patient declined speaking to a nurse.',
    ],
    hasOtherOption: true,
    isRequired: true,
    disabled: false,
  },
  {
    id: 'type',
    type: 'TEXT',
    title: 'Type/Reading  (Ex: Blood Pressure: 105/64 mmHg Pulse: 55 bpm)',
    isRequired: false,
    disabled: false,
  },
  {
    id: 'deviceeducation',
    type: 'CHECKBOX',
    title:
      'Are you having any issues with your device? Do you need any supplies for your device?',
    choices: [
      'Provided device education to ensure accurate readings on.',
      'Confirmed address and ordered consumables for.',
      'Performed device troubleshooting on.',
      'Patient denied any concerns with device(s).',
    ],
    hasOtherOption: true,
    isRequired: false,
    disabled: false,
  },
  {
    id: 'contact',
    type: 'CHECKBOX',
    title: 'Preferred Contact Method',
    choices: ['Email', 'Phone', 'SMS'],
    hasOtherOption: false,
    isRequired: true,
    disabled: false,
  },
  {
    id: 'reminder',
    type: 'CHECKBOX',
    title: 'Reminders',
    choices: [
      'take their readings daily',
      'allow the device to shut off on its own',
      'charge their device 2-3 hours weekly',
    ],
    hasOtherOption: false,
    isRequired: true,
    disabled: false,
  },
  {
    id: 'medication',
    type: 'MULTIPLE_CHOICE',
    title:
      'Since starting with Accuhealth, have you been more likely to take your medications as prescribed?',
    choices: [
      'Patient states there is no change in how they take their medications since starting with Accuhealth',
      'Patient states they are more compliant with their medications since starting with Accuhealth',
    ],
    hasOtherOption: false,
    isRequired: true,
    disabled: false,
  },
  {
    id: 'coordination',
    type: 'CHECKBOX',
    title: 'How can we improve your experience with our program?',
    choices: [
      'The patient stated they are satisfied and have no recommendations for our services',
    ],
    hasOtherOption: true,
    isRequired: false,
    disabled: false,
  },
  {
    id: 'comments',
    type: 'TEXT',
    title: 'Additional comments',
    isRequired: false,
    disabled: false,
  },
];
