import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { arrUniqEl } from 'infrastructure/functions';
import { ClinicStatus } from 'infrastructure/enums';
import { ClinicSelect } from 'infrastructure/classes/select/clinic-select';

import useApi from '../use-api';

import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IClinicSelectPaginatedData,
} from 'infrastructure/interfaces';

const getClinicsApi = 'core/clinics/select-options';

export interface IUseClinicMeta {
  search?: string;
  guid?: string;
  agencyGuid?: Array<string> | string;
  status?: Array<ClinicStatus>;
  parentClinicsOnly?: boolean;
}

type MergedMeta = IUseClinicMeta & IPaginationMeta;

type UseClinic = {
  loading: boolean;
  meta: MergedMeta;
  loadClinics: (meta: MergedMeta) => Promise<ClinicSelect[]>;
};

interface IUseClinicSelectProps {
  meta?: IUseClinicMeta & IPartialPaginationMeta;
}

export const useClinicSelect = (props?: IUseClinicSelectProps): UseClinic => {
  const { meta: metaProps = {} } = props || {};
  const { loading, loadData, isMounted } = useApi();
  const queryClient = useQueryClient();

  const [meta, setMeta] = useState<MergedMeta>({
    page: 1,
    items: 100,
    totalCount: 0,
    ...metaProps,
  });

  const loadClinics = async (filters: MergedMeta) => {
    const queryKey = [
      getClinicsApi,
      {
        agencyGuid: filters.agencyGuid,
        guid: filters.guid,
        page: filters.page,
        status: filters.status,
        search: filters.search,
        parentClinicsOnly: filters.parentClinicsOnly,
      },
    ];

    const data = await queryClient.fetchQuery(
      queryKey,
      () =>
        loadData<IClinicSelectPaginatedData, Omit<IUseClinicMeta, 'sortField'>>(
          getClinicsApi,
          {
            sortField: JSON.stringify(filters.sortField),
            page: filters.page,
            items: filters.items,
            guid: filters.guid,
            search: filters.search,
            agencyGuid: filters.guid ? undefined : filters.agencyGuid,
            status: filters.guid
              ? [
                  ClinicStatus.Active,
                  ClinicStatus.Delinquent,
                  ClinicStatus.Inactive,
                ]
              : filters.status,
            parentClinicsOnly: filters.parentClinicsOnly,
          },
        ),
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      const newMeta = {
        ...meta,
        ...filters,
        totalCount: data.count,
      };

      setMeta(newMeta);

      return arrUniqEl(data.items, 'guid').map((el) => new ClinicSelect(el));
    }

    return [];
  };

  return {
    loading,
    meta,
    loadClinics,
  };
};
