import React from 'react';
import Icon from '@ant-design/icons';

import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ArrowRightSVG = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4C0 4.27614 0.223858 4.5 0.5 4.5L6.29289 4.5L4.14645 6.64645C3.95118 6.84171 3.95118 7.15829 4.14645 7.35355C4.34171 7.54882 4.65829 7.54882 4.85355 7.35355L7.85355 4.35355C8.04882 4.15829 8.04882 3.84171 7.85355 3.64645L4.85355 0.646447C4.65829 0.451184 4.34171 0.451184 4.14645 0.646447C3.95118 0.841709 3.95118 1.15829 4.14645 1.35355L6.29289 3.5L0.5 3.5C0.223858 3.5 0 3.72386 0 4Z"
        fill="rgba(0, 0, 0, 0.25)"
      />
    </svg>
  );
};

const ArrowRightIcon: React.FC<Partial<CustomIconComponentProps>> = (props) => {
  return <Icon component={ArrowRightSVG} {...props} />;
};

export default ArrowRightIcon;
