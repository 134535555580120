import { useQuery, useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import useApi from '../use-api';

import type {
  IClinic,
  IUpdatePatientClinicInfo,
} from 'infrastructure/interfaces';
import type { UseQueryResult } from '@tanstack/react-query';

const patientClinicInfoApi = (patientGuid: string) =>
  `admins/admin-panel/patients/${patientGuid}/clinic-info`;

interface IUsePatientClinicInfo {
  loading: boolean;
  loadPatientClinicInfo: () => Promise<IClinic | void>;
  updatePatientClinicInfo: (
    data: IUpdatePatientClinicInfo,
  ) => Promise<IUpdatePatientClinicInfo>;
  patientClinicInfo: UseQueryResult<void | IClinic, unknown>;
}

interface IUsePatientClinicInfoProps {
  patientGuid: string;
}

export const getPatientClinicInfoKey = (patientGuid: string) => [
  'patientClinicInfo',
  patientGuid,
];

export const usePatientClinicInfo = (
  props: IUsePatientClinicInfoProps,
): IUsePatientClinicInfo => {
  const { patientGuid } = props;

  const { loading, loadData, updateData } = useApi();

  const queryClient = useQueryClient();
  const queryKey = getPatientClinicInfoKey(patientGuid);

  const loadPatientClinicInfo = async (): Promise<IClinic | void> => {
    const data = await queryClient.fetchQuery(
      queryKey,
      () => loadData<IClinic>(patientClinicInfoApi(patientGuid)),
      { staleTime: STALE_TIME },
    );
    if (data) return data;
  };

  const patientClinicInfo = useQuery({
    queryKey,
    queryFn: async () => loadData<IClinic>(patientClinicInfoApi(patientGuid)),
    staleTime: STALE_TIME,
    enabled: Boolean(patientGuid),
  });

  const updatePatientClinicInfo = async (
    body: IUpdatePatientClinicInfo,
  ): Promise<IUpdatePatientClinicInfo> => {
    const data = await updateData<IUpdatePatientClinicInfo>(
      patientClinicInfoApi(patientGuid),
      body,
    );
    // queryClient.setQueryData(queryKey, data);
    return data;
  };

  return {
    patientClinicInfo,
    loading,
    loadPatientClinicInfo,
    updatePatientClinicInfo,
  };
};
