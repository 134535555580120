import { useState } from 'react';
import dataService from 'services/dataService';
import { RESET_PASSWORD } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';

export const useResetEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const resetPassword = async (email: string) => {
    setIsLoading(true);
    const { data, error } = await dataService.createList(RESET_PASSWORD, {
      email,
    });
    setIsLoading(false);
    if (error) {
      showResult(error);
    }
    if (data) {
      notificationController.success({
        message: "'Reset password' link sent to email",
      });
    }
  };

  return { resetPassword, isLoading };
};
