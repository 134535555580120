import { useFormik } from 'formik';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseTextArea from 'components/atoms/base-textarea';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import BaseModal from 'components/atoms/base-modal';
import { useEffect, type FC } from 'react';
import ConditionSearchSelect from 'components/molecules/conditions-search-select';

import { patientConditionValidationSchema } from './validation-schema';

import type { IConditionForUpdate } from 'infrastructure/interfaces';
import type { FormikHelpers } from 'formik';
import type { Values } from './validation-schema';

interface PatientConditionModalFormProps {
  open: boolean;
  onCancelForm: () => void;
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  loading: boolean;
  defaultValues?: IConditionForUpdate;
  title: string;
}

const PatientConditionModalForm: FC<PatientConditionModalFormProps> = ({
  open,
  loading,
  onCancelForm,
  onSubmit,
  defaultValues,
  title,
}) => {
  const { t } = useTranslation();
  const formik = useFormik<Values>({
    initialValues: patientConditionValidationSchema().cast(defaultValues),
    enableReinitialize: true,
    onSubmit,
    validateOnChange: false,
    validationSchema: patientConditionValidationSchema(),
  });

  const { submitForm, resetForm } = formik;

  const onCancel = () => {
    resetForm();
    onCancelForm();
  };

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  return (
    <>
      <BaseModal
        title={title}
        open={open}
        onCancel={onCancel}
        withFooter={[
          <BaseButton
            dataCy="form-cancel-button"
            label={t('controls.cancel')}
            type="secondary"
            onClick={onCancel}
            disabled={loading}
          />,
          <BaseButton
            dataCy="form-save-button"
            label={t('controls.save')}
            onClick={submitForm}
            disabled={loading}
            loading={loading}
          />,
        ]}
      >
        <BaseForm
          formik={formik}
          withControls
          plaintext={false}
          readonly={false}
        >
          <BaseFormItem
            name="code"
            required
            label={t('labels.icdCode')}
            isBlackLabel
          >
            <ConditionSearchSelect
              onChange={(value, option) => {
                formik.setFieldValue('code', option.label);
                formik.setFieldValue('diagnoseGuid', option.guid);
                formik.setFieldValue('description', option.description);
              }}
              disabled={typeof defaultValues !== 'undefined'}
            />
          </BaseFormItem>
          <BaseFormItem
            name="description"
            required
            label={t('labels.description')}
            isBlackLabel
          >
            <BaseTextArea rows={10} />
          </BaseFormItem>
        </BaseForm>
      </BaseModal>
    </>
  );
};
export default PatientConditionModalForm;
