import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import AutoComplete from 'components/molecules/autocompleteFilter';
import AutoCompleteClinics from 'components/molecules/autocompleteFilter/clinic';
import forms from 'constants/forms';
import common from 'constants/common';
import AutoCompleteDoctorMultiSelect from 'components/molecules/autocompleteFilter/doctorMulti';
import BaseSelect from 'components/atoms/baseSelect';
import { useTranslation } from 'react-i18next';
import { useClinicInfo } from 'infrastructure/hooks/clinic/use-clinic-info';

import TimeFilter from '../../../../organisms/timeFilter';

const DEFAULT_FILTER = forms.patientFilterOptions[0];
const DEFAULT_CLINIC_FILTER = forms.clinicFilterOptions[0];
const DEFAULT_STATUS = forms.statusSelect[0];
const DEFAULT_SORT = forms.sortSelect[0];
const DEFAULT_INSURANCE = forms.clinicInsuranceTypeSelect[0];
const DEFAULT_CHILD_CLINIC = forms.childClinicSelect[0];

const Sorting = () => {
  const [selectedClinic, setSelectedClinic] = useState('');
  const { clinicInfo, loading: clinicInfoLoading } = useClinicInfo({
    clinicGuid: selectedClinic,
  });
  const { insuranceTypes, filters } = useSelector((state) => state.doctor);

  const {
    doctor: { applyFilters, applyTimeFilter },
  } = useDispatch();

  const filter = DEFAULT_FILTER;
  const clinicsFilter = DEFAULT_CLINIC_FILTER;
  const handleSubmit = (dates) => {
    applyTimeFilter(dates);
  };

  const handleOnChange = (option) => {
    if (option) {
      const { value } = option;
      applyFilters({ patientGuid: value });
    }
  };

  const handleClinicChange = ({ value }) => {
    setSelectedClinic(value);

    if (value) {
      applyFilters({ clinicGuid: value, childClinic: true });
    } else {
      applyFilters({
        clinicGuid: '',
        insuranceType: DEFAULT_INSURANCE.value,
        doctorGuid: '',
      });
    }
  };

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 12,
    colors: {
      ...theme.colors,
      ...common.primaryColors,
    },
  });

  const { t } = useTranslation();

  return (
    <Row>
      <Col xl="2" xs="12" md="6">
        <p className="realtime-heading">Report Time Window</p>
        <TimeFilter noUTC handleSubmit={handleSubmit} />
      </Col>
      <Col xl="2" xs="12" md="6">
        <p className="realtime-heading">Patient Filter</p>
        <AutoComplete filter={filter} handleClick={handleOnChange} />
      </Col>
      <Col xl="2" xs="12" md="6">
        <p className="realtime-heading">Show Status</p>
        <BaseSelect
          options={forms.statusSelect}
          defaultValue={DEFAULT_STATUS}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          onChange={({ value }) => applyFilters({ status: value })}
        />
      </Col>
      <Col xl="2" xs="12" md="6">
        <p className="realtime-heading">Sort</p>
        <BaseSelect
          options={forms.sortSelect}
          defaultValue={DEFAULT_SORT}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          onChange={({ value }) => applyFilters({ reverse: value })}
        />
      </Col>

      <Col xl="2" xs="12" md="6">
        <p className="realtime-heading">Clinic</p>
        <AutoCompleteClinics
          filter={clinicsFilter}
          handleClick={handleClinicChange}
        />
      </Col>
      <Col xl="2" xs="12" md="6">
        <p className="realtime-heading">Provider</p>
        <AutoCompleteDoctorMultiSelect
          clinicGuid={filters.clinicGuid}
          onChange={(value) => applyFilters({ doctorGuid: value })}
        />
      </Col>
      {filters.clinicGuid && (
        <Col xl="2" xs="12" md="6">
          <p className="realtime-heading">Insurance Provider</p>
          <BaseSelect
            options={[
              DEFAULT_INSURANCE,
              ...insuranceTypes.map((insuranceType) => ({
                label: insuranceType,
                value: insuranceType,
              })),
            ]}
            defaultValue={DEFAULT_INSURANCE}
            theme={(theme) => selectTheme(theme)}
            classNamePrefix="select-type"
            onChange={({ value }) => applyFilters({ insuranceType: value })}
          />
        </Col>
      )}

      {clinicInfo?.childClinics.length > 0 && !clinicInfoLoading && (
        <Col xl="2" xs="12" md="3">
          <p className="realtime-heading">{t('labels.childClinics')}</p>
          <BaseSelect
            options={forms.childClinicSelect}
            defaultValue={DEFAULT_CHILD_CLINIC}
            theme={(theme) => selectTheme(theme)}
            classNamePrefix="select-type"
            onChange={({ value }) => applyFilters({ childClinic: value })}
          />
        </Col>
      )}
    </Row>
  );
};

export default Sorting;
