import type { ClinicStatus } from 'infrastructure/enums';
import type { IClinicSelect } from 'infrastructure/interfaces';

export class ClinicSelect {
  guid: string;
  label: string;
  status: ClinicStatus;

  constructor(data: IClinicSelect) {
    this.guid = data.guid;
    this.label = data.label;
    this.status = data.record.status;
  }
}
