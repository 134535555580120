import { useState } from 'react';
import { removeEmptyValuesFromObject, filterDefaultValues } from 'utils/object';

import { useQueryParams } from './useQueryParams';

import type { Filters, AppendFilters } from './types';

export const getFiltersCount = (filters: Record<string, any>): number => {
  const { page: _, items: __, ...rest } = filters;
  let count = Object.keys(rest).length;
  if ('year' in rest && 'month' in rest) {
    count -= 1;
  }
  return count;
};

export const useInteractiveTimeDashboardFilters = (
  defaultFilters?: Partial<Filters>,
) => {
  const DEFAULT_FILTERS = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    page: 1,
    items: 100,
    ...defaultFilters,
  };

  const {
    addFiltersToUrl,
    initialFilters,
    initialFiltersCount,
    resetFiltersUrl,
  } = useQueryParams(DEFAULT_FILTERS);

  const [filtersCount, setFiltersCount] = useState<number>(initialFiltersCount);
  const [filters, setFilters] = useState<Filters>(initialFilters);

  const appendFilters: AppendFilters = (filtersToAppend) => {
    const newFilters = removeEmptyValuesFromObject({
      ...DEFAULT_FILTERS,
      ...filters,
      page: 1,
      ...filtersToAppend,
    });

    const filterWithoutDefaultValues = filterDefaultValues({
      values: newFilters,
      defaultValues: DEFAULT_FILTERS,
    });
    addFiltersToUrl(filterWithoutDefaultValues);
    const newFiltersCount = getFiltersCount(filterWithoutDefaultValues);
    setFiltersCount(newFiltersCount);
    setFilters(newFilters as Filters);
  };

  const changePage = (page: number) => {
    appendFilters({ page });
  };

  const resetFilters = () => {
    setFiltersCount(0);
    setFilters(DEFAULT_FILTERS);
    resetFiltersUrl();
  };

  return {
    filters,
    appendFilters,
    resetFilters,
    changePage,
    filtersCount,
  };
};
