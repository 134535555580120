import React from 'react';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { PatientDevice } from 'infrastructure/classes/patient/patient-device';

interface IAlertsStateProps {
  data: PatientDevice;
}

const AlertsState: React.FC<IAlertsStateProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <div data-cy="device-table-alerts-state">
      {data.alarm && (
        <>
          <p className={s.time}>
            <b className={s['alerts-label']}>{t('labels.deviceStatus')}: </b>
            {t(`deviceAlertStatus.${data.alarm.status}`)}
            {data.alarmChange?.status && (
              <> - {t(`deviceAlertChangeStatus.${data.alarmChange.status}`)}</>
            )}
          </p>
        </>
      )}
      {data.alarmChange && (
        <p className={s.time}>
          <b className={s['alerts-label']}>{t('labels.changeStatus')}: </b>
          {t(`deviceAlertChangeStatus.${data.alarmChange.changeStatus}`)}
        </p>
      )}
    </div>
  );
};

export default AlertsState;
