import forms from 'constants/forms';
import dataService from 'services/dataService';
import {
  apiDoctorCcmOnboardingStatus,
  apiDoctorPatients,
  CCM_ONBOARDING_CSV_REPORT,
} from 'services/dataService/resources';
import { generateFullName } from 'utils/badgeHelpers';

const INITIAL_STATE = {
  statuses: {
    'CCM Calling Queue': 0,
    'CCM Patient Refused': 0,
    'CCM Onboarding': 0,
    'CCM Onboarding Unsuccessful': 0,
    'CCM Referral Received': 0,
    'CCM Referral On-Hold': 0,
    'CCM Referral Withdrawn': 0,
    'CCM Patient No Contact': 0,
    'Total CCM Patients': 0,
    Active: 0,
  },
  insurances: [],
  patients: [],
  patientCount: null,
  clinic: null,
  doctor: null,
  patientGuid: null,
  ccmStatus: [forms.ccmFulfillmentStage[0]],
  pcmStatus: forms.pcmStatusesFilter[0],
  childClinic: null,
  ITEMS_PER_PAGE: 100,
};

const ccmOnboardingStatus = {
  state: INITIAL_STATE,
  reducers: {
    resetState: () => INITIAL_STATE,
    setData: (
      state,
      {
        onboardingStatuses,
        patientsByInsurances,
        enrolledPatientsCount,
        totalOnboardingPatients,
      },
    ) => ({
      ...state,
      statuses: {
        ...onboardingStatuses,
        'Total CCM Patients': totalOnboardingPatients,
        Active: enrolledPatientsCount,
      },
      active: enrolledPatientsCount,
      total: totalOnboardingPatients,
      insurances: patientsByInsurances,
    }),
    setPatientData: (state, { items, count }) => ({
      ...state,
      patients: items.map((item) => ({
        ...item,
        name: `${item.firstName} ${item.lastName}`,
        dob: item.birthDate,
        insType: item.insurances.length ? item.insurances[0].type : null,
        primaryDoctor:
          item.primaryDoctor &&
          generateFullName(
            item.primaryDoctor.title,
            item.primaryDoctor.firstName,
            null,
            item.primaryDoctor.lastName,
          ),
        insProvider: item.insurances.length
          ? item.insurances.map((insurance) => insurance.provider).join(',\n')
          : null,
        insNumber: item.insurances.length
          ? item.insurances.map((insurance) => insurance.number).join(',\n')
          : null,
        practiceEmail: item.primaryDoctor.email,
      })),
      patientCount: count,
    }),
    setPatientGuid: (state, { patientGuid }) => ({
      ...state,
      patientGuid,
    }),
    setClinic: (state, payload) => ({
      ...state,
      clinic: payload,
    }),
    setDoctor: (state, payload) => ({
      ...state,
      doctor: payload,
    }),
    setStatus: (state, payload) => ({
      ...state,
      ccmStatus: payload,
    }),
    setPcmStatus: (state, payload) => ({
      ...state,
      pcmStatus: payload,
    }),
    setChildClinic: (state, payload) => ({
      ...state,
      childClinic: payload,
    }),
  },
  effects: (dispatch) => ({
    async getOnboardingStatusReport(payload, state) {
      const { clinic, doctor, childClinic } = state.ccmOnboardingStatus;
      const { data } = await dataService.getList(
        apiDoctorCcmOnboardingStatus({
          clinicGuid: clinic,
          doctorGuid: doctor,
          childClinic,
        }),
      );

      if (data) {
        dispatch.ccmOnboardingStatus.setData(data);
      }
    },

    async getPatientsReport(payload, state) {
      const {
        clinic,
        doctor,
        ccmStatus,
        ITEMS_PER_PAGE,
        pcmStatus,
        childClinic,
      } = state.ccmOnboardingStatus;
      const { data } = await dataService.getList(
        apiDoctorPatients({
          clinicGuid: clinic,
          ccmStatus: ccmStatus?.map((item) => item.value).join(','),
          patientGuid: payload?.patient,
          doctorGuid: doctor,
          clinicsStatus: 'active,delinquent',
          page: payload?.page || 1,
          items: ITEMS_PER_PAGE,
          status: 'all',
          pcmStatus: pcmStatus?.value,
          childClinic,
        }),
      );

      if (data) {
        dispatch.ccmOnboardingStatus.setPatientData(data);
      }
    },

    async setClinicOnboardingStatus(clinic) {
      dispatch.ccmOnboardingStatus.setClinic(clinic);
      dispatch.ccmOnboardingStatus.setChildClinic(!!clinic);
      dispatch.ccmOnboardingStatus.getOnboardingStatusReport();
      dispatch.ccmOnboardingStatus.getPatientsReport();
    },
    async setDoctorOnboardingStatus(doctor) {
      dispatch.ccmOnboardingStatus.setDoctor(doctor);
      dispatch.ccmOnboardingStatus.getOnboardingStatusReport();
      dispatch.ccmOnboardingStatus.getPatientsReport();
    },
    async setPatient(patient) {
      dispatch.ccmOnboardingStatus.setPatientGuid({ patientGuid: patient });
      dispatch.ccmOnboardingStatus.getPatientsReport({ patient });
    },

    async setStatus() {
      dispatch.ccmOnboardingStatus.getPatientsReport();
    },
    async setChildClinicValue(childClinic) {
      dispatch.ccmOnboardingStatus.setChildClinic(childClinic);
      dispatch.ccmOnboardingStatus.getOnboardingStatusReport();
      dispatch.ccmOnboardingStatus.getPatientsReport();
    },
    async getOnboardingCsvReport(payload, state) {
      const { clinic, ccmStatus, doctor, patientGuid, pcmStatus, childClinic } =
        state.ccmOnboardingStatus;
      const body = {
        status: 'all',
        ccmStatuses: ccmStatus
          ?.map((item) => item.value)
          .join(',')
          .split(','),
        pcmStatus: pcmStatus?.value !== null ? pcmStatus?.value : undefined,
      };
      if (clinic) body.clinicGuid = clinic.split(',');
      if (doctor) body.doctorGuid = doctor;
      if (patientGuid) body.patientGuid = patientGuid;
      if (childClinic) body.childClinic = childClinic;
      const { error, data } = await dataService.createOne(
        CCM_ONBOARDING_CSV_REPORT,
        body,
      );
      return { error, data };
    },
    resetFilters() {
      dispatch.ccmOnboardingStatus.resetState();
    },
  }),
};

export default ccmOnboardingStatus;
