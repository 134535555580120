import React from 'react';
import PatientInsuranceTable from 'components/molecules/admin-panel/forms/patient-insurance/table';

import type { ModuleType } from 'infrastructure/enums';

interface IAdminTabbedFormPatientInsuranceProps {
  patientGuid: string;
  module: ModuleType;
}

const AdminTabbedFormPatientInsurance: React.FC<
  IAdminTabbedFormPatientInsuranceProps
> = (props) => {
  const { patientGuid, module } = props;

  return <PatientInsuranceTable patientGuid={patientGuid} module={module} />;
};

export default AdminTabbedFormPatientInsurance;
