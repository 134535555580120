import React, { useEffect, useState } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseInput from 'components/atoms/base-input';
import { useFormik } from 'formik';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import BaseGrid from 'components/atoms/base-grid';
import BaseSwitch from 'components/atoms/base-switch';
import EmrReportPicker from 'components/molecules/emr-report-picker';
import { PatientEmr } from 'infrastructure/classes/patient/patient-emr';
import { usePatientEMR } from 'infrastructure/hooks/patient/use-patient-emr';
import { ModuleType } from 'infrastructure/enums';
import { useEdit } from 'infrastructure/providers/edit-provider';
import classNames from 'classnames';
import { isMobile } from 'utils/deviceHelper';
import useUser from 'utils/useUser';

import s from './styles.module.scss';
import { emrValidationSchema } from './validation-schema';

const formKey = 'AdminPanelEmrDetailsForm';

interface IAdminPanelEmrDetailsFormProps {
  patientGuid: string;
  module: ModuleType;
}

const AdminPanelEmrDetailsForm: React.FC<IAdminPanelEmrDetailsFormProps> = (
  props,
) => {
  const { patientGuid, module } = props;

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const isAdmin = module === ModuleType.ADMIN;

  const { canEdit, setEdit } = useEdit();
  const { t } = useTranslation();
  const { deviceType, iamPatientAcceptance } = useUser();

  const { loading, loadPatientEmr, updatePatientEmr } = usePatientEMR({
    patientGuid,
  });

  const formik = useFormik({
    initialValues: {} as PatientEmr,
    onSubmit: async (values) => {
      await updatePatientEmr({
        ...values,
        patientGuid,
      }).then(() => {
        formik.resetForm({ values });
      });
      setIsEdit(false);
      setEdit(false);
    },
    validateOnChange: false,
    validationSchema: emrValidationSchema(),
  });

  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };
  const onSave = () => {
    formik.submitForm();
  };
  const onCancel = () => {
    formik.resetForm();
    setIsEdit(false);
    setEdit(false);
  };

  const loadData = async () => {
    const data = await loadPatientEmr();
    if (data) {
      formik.resetForm({ values: new PatientEmr(data) });
    }
  };

  useEffect(() => {
    loadData();
  }, [patientGuid]);

  return (
    <PatientBlock
      title={t('labels.emrDetails')}
      canEdit={isAdmin}
      isEdit={isEdit}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      loading={loading}
      disabled={!canEdit(formKey) || iamPatientAcceptance}
    >
      <BaseForm formik={formik} plaintext={!isEdit} readonly={!isEdit}>
        <BaseGrid columns={2} className={s['inputs-wrapper']}>
          <BaseFormItem
            name="account"
            label={t('labels.patientMrnId')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="name"
            label={t('labels.emrName')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="emrId1"
            label={`${t('labels.emrId')} 1`}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="emrId2"
            label={`${t('labels.emrId')} 2`}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="supervisingProviderId"
            label={t('labels.supervisingProvider')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="renderingProviderId"
            label={t('labels.renderingProvider')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="billing"
            label={t('labels.billing')}
            labelDirection="left"
            className={s.switcher}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseSwitch />
          </BaseFormItem>
          <BaseFormItem
            name="billingCycle"
            label={t('labels.billingCycle')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="readingsFlag"
            label={t('labels.readings')}
            labelDirection="left"
            className={s.switcher}
          >
            <BaseSwitch />
          </BaseFormItem>
          <BaseFormItem
            name="reports"
            label={t('labels.report')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            {isEdit ? (
              <EmrReportPicker />
            ) : (
              <BaseInput
                value={formik.values.reports ?? t('labels.none')}
                className={classNames({ [s['input-wrapper']]: !isEdit })}
              />
            )}
          </BaseFormItem>
        </BaseGrid>
      </BaseForm>
    </PatientBlock>
  );
};

export default AdminPanelEmrDetailsForm;
