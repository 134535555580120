import { useMutation, useQueryClient } from '@tanstack/react-query';

import { saveKeyNotes } from './api/saveKeyNoteFn';
import { getKeyNoteKey } from './use-get-key-note';

import type { IKeyNoteData } from 'infrastructure/interfaces';
import type { ModuleType } from 'infrastructure/enums';

interface Props {
  onSuccess: () => void;
  patientGuid: string;
  module: ModuleType;
}

export const usSaveKeyNote = ({ onSuccess, module, patientGuid }: Props) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: saveKeyNotes,
    onSuccess(data) {
      const queryKey = getKeyNoteKey({ module, patientGuid });
      queryClient.setQueryData<IKeyNoteData>(queryKey, () => {
        if (data) {
          return data;
        }
      });
      onSuccess();
    },
  });
  return { saveNote: mutate, isLoading };
};
