// FIXME: Can be deleted. Unused component

import React, { useState } from 'react';
import ModalPopup from 'components/organisms/modal';
import { useTranslation } from 'react-i18next';
import BookmarksFillIcon from 'components/atoms/icons/bookmarks-fill';

import CommentBookmarkList from '../list';

import type { BookmarkComment } from 'infrastructure/classes/bookmark-comment';
import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface ICommentBookmarkModalProps extends IBaseComponentProps {
  onApply: (data: BookmarkComment) => void;
}

const CommentBookmarkModal: React.FC<ICommentBookmarkModalProps> = (props) => {
  const { className, style, onApply } = props;
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const { t } = useTranslation();

  const apply = (data: BookmarkComment) => {
    onApply(data);
    setIsOpened(false);
  };

  return (
    <>
      <button
        type="button"
        style={style}
        className={className}
        title={t('labels.bookmarkedComments')}
        onClick={() => setIsOpened(true)}
        data-cy="bookmarked-comments-modal-btn"
      >
        <BookmarksFillIcon style={{ transform: 'scale(1.3)' }} />
      </button>
      <ModalPopup
        title={t('labels.bookmarkedComments')}
        isOpen={isOpened}
        toggle={setIsOpened}
      >
        <CommentBookmarkList onApply={apply} />
      </ModalPopup>
    </>
  );
};

export default CommentBookmarkModal;
