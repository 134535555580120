import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import dataService from 'services/dataService';
import { API_TURK_CLEAR_READINGS } from 'services/dataService/resources';
import useUser from 'utils/useUser';
import { isTurkUserRole } from 'utils/userTypeHelper';

import type { RootDispatch } from 'store';
import type { LogoutOptions } from '@auth0/auth0-react';

const useLogout = () => {
  const { logout: auth0Logout } = useAuth0();
  const { roleNames, logoutFlag } = useUser();
  const {
    user: { setLoggingOut },
  } = useDispatch<RootDispatch>();

  const logout = async (options?: LogoutOptions) => {
    const defaultOptions: LogoutOptions = {
      returnTo: window.location.origin,
    };
    if (!logoutFlag) {
      setLoggingOut(true);
    }
    const isTurkUser = isTurkUserRole(roleNames);
    if (isTurkUser) {
      await dataService.deleteObject(API_TURK_CLEAR_READINGS);
    }
    auth0Logout({
      ...defaultOptions,
      ...options,
    });
  };

  return { logout };
};

export default useLogout;
