import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import PatientControlButton from 'components/atoms/patentControlButton';
import ControlButtonDropdown from 'components/atoms/controlPanelDropdownButton/controlButtonDropdown';
import GeneralCommentModal from 'components/templates/rpm/patients/patient/generalComments/modal';
import forms from 'constants/forms';
import { isMobile } from 'utils/deviceHelper';
import useUser from 'utils/useUser';
import EmailsResubscriptionModal from 'components/molecules/resubscribeToEmails/modal';
import CcmInteractionStatusModal from 'components/molecules/ccmInteractionStatus/modal';
import { useAppSelector } from 'store';
import SmsModalMobile from 'components/templates/rpm/patients/patient/messages/modalMobile';
import SmsModal from 'components/templates/rpm/patients/patient/messages/modal';
import classNames from 'classnames';
import { isCcmModule, isRpmModule } from 'infrastructure/functions';
import { isReadOnlyRole } from 'utils/userTypeHelper';
import HoldingStatusModal from 'components/molecules/holding-status-modal';

import ManualEnterCall from '../controlPanel/manualEnteredCallModal';
import { useControlButtons } from './hooks/useControlButtons';
import s from './styles.module.scss';
import { useGetSmsTemplates } from '../../../infrastructure/hooks/control-panel';
import CreateCaseInEhrModal from '../controlPanel/createCaseInEhrModal';
import DischargePatientModalMobile from '../controlPanel/dischargePatientModalMobile';
import DischargePatientModal from '../controlPanel/dischargePatientModal';
import ClinicEscalationFormModal from '../controlPanel/clinicalEscalation/modal';
import { useGetClinicEscalationValues } from './hooks/useGetClinicEscalationValues';
import HospitalPreventionFormModal from '../controlPanel/hospitalPrevention/formModal';
import SmsDoctorModal from '../controlPanel/smsDoctorModal';

import type { ModuleType } from 'infrastructure/enums';
import type { FC } from 'react';

interface PatientControlPanelProps {
  patientGuid: string;
  module: ModuleType;
}

const PatientControlPanel: FC<PatientControlPanelProps> = ({
  patientGuid,
  module,
}) => {
  const { roleNames } = useUser();
  const isReadOnly = isReadOnlyRole(roleNames);
  const device = useAppSelector((state) => state.user.deviceType);
  const { smsTemplates, smsTemplatesLoading } = useGetSmsTemplates();
  const {
    isLoading,
    controlButtons,
    manualEnteredCallPopupOpen,
    commentsPopupOpen,
    interactionStatus,
    commentLoading,
    holdingStatusPopup,
    holdingStatus,
    nextHoldingStatus,
    holdingStatusStartTime,
    holdingStatusEndTime,
    smsPopupOpen,
    smsLoading,
    isPrimaryNumber,
    openSubscriptionModal,
    openCcmInteractionModal,
    subscriptionStatusLoading,
    createCaseInEhrModalOpen,
    CCMDischargePopupOpen,
    clinicalEscalationModalOpen,
    dischargePopupOpen,
    hospitalPreventionModalOpen,
    holdingStatusLoading,
    sendClinicEscalationLoading,
    updateDischargeStatusLoading,
    sendHospitalPreventionLoading,
    ccmStatusLoading,
    ccmHoldingStatus,
    ccmHoldingStatusStartDate,
    doctorSmsPopupOpen,
    sendDoctorSms,
    doctorSmsLoading,
    setDoctorSmsPopupOpen,
    consumablesArrivingDate,
    tempHoldingStatusLoading,
    consumablesDateLoading,
    setHospitalPreventionModalOpen,
    setDischargePopupOpen,
    setClinicalEscalationModalOpen,
    setCCMDischargePopupOpen,
    setCreateCaseInEhrModalOpen,
    setOpenSubscriptionModal,
    sendSms,
    setSmsPopupOpen,
    setHoldingStatusPopup,
    sendComment,
    updateHoldingStatus,
    setCommentsPopupOpen,
    setManualEnteredCallPopupOpen,
    updateSubscriptionStatus,
    setOpenCcmInteractionModal,
    createCaseInEhr,
    createCaseInEhrLoading,
    updateCcmStatus,
    sendClinicEscalation,
    updateDischargeStatus,
    sendHospitalPrevention,
    updateTempHoldStatus,
    updateConsumablesDate,
    callButtonsAlert,
  } = useControlButtons({ patientGuid, module });
  const {
    defaultClinicEscalationValues,
    defaultHospitalPreventionValues,
    loading,
  } = useGetClinicEscalationValues({ patientGuid });

  return (
    <>
      <div className={s.control}>
        <BaseSpinWrapper spinning={isLoading || loading}>
          <div className={s['control-wrapper']}>
            <h4 className={s.title}>Control Panel</h4>
            <div className={s.divider} />
            <div
              className={classNames(s['control-buttons-wrapper'], {
                [s['control-buttons-wrapper-rpm']]: isRpmModule(module),
              })}
            >
              {controlButtons.map((btn) => {
                if (btn.options) {
                  const ToggleButton = (
                    <PatientControlButton
                      key={btn.key}
                      label={btn.label}
                      Icon={btn.Icon}
                      status={btn.status}
                      loading={btn.loading}
                      disabled={btn.disabled}
                    />
                  );
                  return (
                    <ControlButtonDropdown<any>
                      key={btn.key}
                      toggleBtn={ToggleButton}
                      options={btn.options}
                      handleOptionClick={btn.handleClick}
                    />
                  );
                }

                return (
                  <div key={btn.key} className={s['holding-wrapper']}>
                    <PatientControlButton
                      key={btn.key}
                      label={btn.label}
                      Icon={btn.Icon}
                      status={btn.status}
                      handleClick={btn.handleClick}
                      loading={btn.loading}
                      disabled={btn.disabled}
                      extraAccent={btn.extraAccent}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </BaseSpinWrapper>
      </div>

      <GeneralCommentModal
        isOpen={commentsPopupOpen}
        toggle={setCommentsPopupOpen}
        patientGuid={patientGuid}
        loading={commentLoading}
        submit={sendComment}
        isRpmPanel={isRpmModule(module)}
        isCcmPanel={isCcmModule(module)}
        isAdminPanel={false}
        interactionStatus={interactionStatus}
      />
      <ManualEnterCall
        isOpen={manualEnteredCallPopupOpen}
        toggle={setManualEnteredCallPopupOpen}
        patientGuid={patientGuid}
        isRpmPanel={isRpmModule(module)}
      />
      {isMobile(device) && (
        <SmsModalMobile
          isOpen={smsPopupOpen}
          toggle={setSmsPopupOpen}
          patientGuid={patientGuid}
          smsTemplates={smsTemplates ?? []}
          loading={smsTemplatesLoading || smsLoading}
          submit={sendSms}
          isRpmPanel={isRpmModule(module)}
          isPrimaryNumber={isPrimaryNumber}
        />
      )}
      {!isMobile(device) && (
        <SmsModal
          isOpen={smsPopupOpen}
          toggle={setSmsPopupOpen}
          patientGuid={patientGuid}
          smsTemplates={smsTemplates ?? []}
          loading={smsTemplatesLoading || smsLoading}
          submit={sendSms}
          isRpmPanel={isRpmModule(module)}
          isPrimaryNumber={isPrimaryNumber}
        />
      )}
      <SmsDoctorModal
        isOpen={doctorSmsPopupOpen}
        toggle={setDoctorSmsPopupOpen}
        patientGuid={patientGuid}
        loading={doctorSmsLoading}
        submit={sendDoctorSms}
      />
      <EmailsResubscriptionModal
        setIsOpen={setOpenSubscriptionModal}
        isOpen={openSubscriptionModal}
        loading={subscriptionStatusLoading}
        onSubmit={async (d) => updateSubscriptionStatus(d)}
      />
      <CcmInteractionStatusModal
        isOpen={openCcmInteractionModal}
        toggle={setOpenCcmInteractionModal}
        patientGuid={patientGuid}
      />
      <CreateCaseInEhrModal
        isOpen={createCaseInEhrModalOpen}
        toggle={setCreateCaseInEhrModalOpen}
        isRpmPanel={isRpmModule(module)}
        patientGuid={patientGuid}
        submit={createCaseInEhr}
        loading={createCaseInEhrLoading}
      />
      {isMobile(device) && (
        <DischargePatientModalMobile
          isOpen={CCMDischargePopupOpen}
          toggle={setCCMDischargePopupOpen}
          patientGuid={patientGuid}
          options={forms.patientCcmDischargeStatuses}
          loading={ccmStatusLoading}
          submit={updateCcmStatus}
          modalType="ccm"
        />
      )}
      {!isMobile(device) && (
        <DischargePatientModal
          isOpen={CCMDischargePopupOpen}
          toggle={setCCMDischargePopupOpen}
          patientGuid={patientGuid}
          options={forms.patientCcmDischargeStatuses}
          loading={ccmStatusLoading}
          submit={updateCcmStatus}
          modalType="ccm"
        />
      )}
      {clinicalEscalationModalOpen && (
        <ClinicEscalationFormModal
          isOpen={clinicalEscalationModalOpen}
          closeModal={() => setClinicalEscalationModalOpen(false)}
          onSubmit={sendClinicEscalation}
          defaultValue={defaultClinicEscalationValues}
          submitLoading={sendClinicEscalationLoading}
        />
      )}
      {isMobile(device) && (
        <DischargePatientModalMobile
          isOpen={dischargePopupOpen}
          toggle={setDischargePopupOpen}
          patientGuid={patientGuid}
          options={forms.patientDischargeStatuses}
          loading={updateDischargeStatusLoading}
          submit={updateDischargeStatus}
          modalType="rpm"
        />
      )}
      {!isMobile(device) && (
        <DischargePatientModal
          isOpen={dischargePopupOpen}
          toggle={setDischargePopupOpen}
          patientGuid={patientGuid}
          options={forms.patientDischargeStatuses}
          loading={updateDischargeStatusLoading}
          submit={updateDischargeStatus}
          modalType="rpm"
        />
      )}
      {hospitalPreventionModalOpen && (
        <HospitalPreventionFormModal
          isOpen={hospitalPreventionModalOpen}
          closeModal={() => setHospitalPreventionModalOpen(false)}
          onSubmit={sendHospitalPrevention}
          defaultValue={defaultHospitalPreventionValues}
          submitLoading={sendHospitalPreventionLoading}
        />
      )}

      {holdingStatusPopup && (
        <HoldingStatusModal
          isOpen={holdingStatusPopup}
          patientGuid={patientGuid}
          toggle={setHoldingStatusPopup}
          patientHoldingStatus={holdingStatus}
          nextHoldingStatus={nextHoldingStatus}
          holdingStatusStartTime={holdingStatusStartTime}
          holdingStatusEndTime={holdingStatusEndTime}
          updateHoldingStatus={updateHoldingStatus}
          options={forms.patientHoldingStatuses}
          loading={holdingStatusLoading}
          isCcmPage={isCcmModule(module)}
          ccmHoldingStatus={ccmHoldingStatus}
          ccmHoldingStatusStartDate={ccmHoldingStatusStartDate}
          updateTempHoldStatus={updateTempHoldStatus}
          tempHoldingStatusLoading={tempHoldingStatusLoading}
          consumablesArrivingDate={consumablesArrivingDate}
          updateConsumablesDate={updateConsumablesDate}
          isReadOnly={isReadOnly}
          consumablesDateLoading={consumablesDateLoading}
        />
      )}
      {callButtonsAlert}
    </>
  );
};
export default PatientControlPanel;
