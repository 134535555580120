import { Pagination } from 'antd';

import type { FC } from 'react';

const DEFAULT_SIZE_OPTIONS = [2, 3, 4, 5, 6, 7, 8, 9, 10];

interface BasePaginationProps {
  page: number;
  total?: number;
  align?: 'start' | 'center' | 'end';
  pageSize?: number;
  onChange?: (page: number, pageSize: number) => void;
  onShowSizeChange?: (current: number, size: number) => void;
  pageSizeOptions?: string[] | number[];
  showSizeChanger?: boolean;
  hide?: boolean;
}

const BasePagination: FC<BasePaginationProps> = ({
  page,
  total,
  align = 'center',
  pageSize,
  pageSizeOptions = DEFAULT_SIZE_OPTIONS,
  showSizeChanger,
  hide,
  onChange,
  onShowSizeChange,
}) => {
  return (
    <Pagination
      defaultCurrent={page}
      current={page}
      total={total}
      align={align}
      onChange={onChange}
      showSizeChanger={showSizeChanger}
      pageSize={pageSize}
      onShowSizeChange={onShowSizeChange}
      pageSizeOptions={pageSizeOptions}
      hideOnSinglePage={hide}
      data-cy="pagination"
    />
  );
};
export default BasePagination;
