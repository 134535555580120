import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BaseButton from 'components/atoms/baseButton';
import { showResult } from 'infrastructure/hooks/utils';

import withLoader from '../../organisms/withLoader';

import type { RootState } from 'store';

type Props = {
  attachmentNote: string;
  attachmentGuid: string;
  patientGuid: string;
  updateAttachment: (data: any) => { data: any };
  setShowForm: (data: boolean) => void;
};

const AttachmentForm: React.FC<Props> = ({
  attachmentNote,
  attachmentGuid,
  patientGuid,
  updateAttachment,
  setShowForm,
}) => {
  const updateLoading = useSelector(
    (state: RootState) => state.loading.effects.attachments.updateAttachment,
  );
  const [noteValue, setNoteValue] = useState<string>(attachmentNote);

  const handleUpdate = async () => {
    if (noteValue) {
      const { data } = await updateAttachment({
        guid: attachmentGuid,
        patientGuid,
        body: {
          note: noteValue,
        },
      });
      if (data) {
        setShowForm(false);
        showResult();
      }
    }
  };

  return (
    <div className="attachment-form">
      <input
        className="attachment-form-input"
        type="text"
        placeholder="Type your note..."
        value={noteValue}
        onChange={(e) => setNoteValue(e.target.value)}
        data-cy="attachment-form-input"
      />
      {withLoader(
        updateLoading,
        <div className="attachment-form-buttons">
          <BaseButton
            label="Save"
            onClick={handleUpdate}
            data-cy="attachment-save-button"
          />
          <BaseButton
            label="Cancel"
            onClick={() => setShowForm(false)}
            data-cy="attachment-cancel-button"
          />
        </div>,
      )}
    </div>
  );
};

export default AttachmentForm;
