import { theme } from 'antd';
import COLORS from 'assets/styles/color.module.scss';

import type { ThemeConfig } from 'antd';

export const lightTheme: ThemeConfig = {
  algorithm: [theme.defaultAlgorithm],
  token: {
    colorPrimary: COLORS.MIAMI_MARMALADE,
    colorText: COLORS.TELOPEA,
    colorWarning: COLORS.ROCKIN_RED,
    colorError: COLORS.ROCKIN_RED,
    colorTextPlaceholder: COLORS.BUOYANT,
    fontFamily: 'Lato',
  },
  components: {
    Table: {
      borderColor: COLORS.MAGIC_SCENT,
      headerBg: COLORS.BOYSENBERRY_SHADOW,
      fontSize: 14,
    },
  },
};
