import dataService from 'services/dataService';
import { API_ADMIN_PANEL_AGENCIES } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';
import httpCodes from 'constants/httpCodes';
import { useTranslation } from 'react-i18next';
import QueryParams from 'utils/queryParams';

import BaseAutocomplete from './base';

import type { Option } from './base';
import type { FC } from 'react';

const generateLabel = (item: any) => item.legalname;

const fetchAgenciesList = async ({
  search,
  items,
  page,
}: {
  search: string;
  page: number;
  items: number;
}): Promise<{ count: number; data: Option[] } | undefined> => {
  const emptyResult = {
    data: [],
    count: 0,
  };
  if (!search) return emptyResult;
  const url = QueryParams.stringifyUrl(API_ADMIN_PANEL_AGENCIES, {
    items,
    page,
    legalname: search,
  });
  const { data, error, status } = await dataService.getList<{
    count?: number;
    items?: any[];
  }>(url);

  if (status === httpCodes.notFount) {
    return emptyResult;
  }

  if (error) {
    showResult(error);
  }
  if (!data || error) return emptyResult;

  const values: Option[] =
    data.items?.map<Option>((item) => ({
      label: generateLabel(item),
      value: item.guid,
    })) || [];

  return {
    data: values,
    count: data.count || 0,
  };
};

interface Props {
  value?: Option[];
  onChange: (value: Option[]) => void;
  showSelectAll?: boolean;
}

const AgenciesAutocomplete: FC<Props> = ({
  onChange,
  value,
  showSelectAll,
}) => {
  const { t } = useTranslation();
  return (
    <BaseAutocomplete
      label={t('labels.agency')}
      placeholder={t('labels.all')}
      mode="multiple"
      fetchOptions={fetchAgenciesList}
      value={value}
      onChange={(newValues) => {
        const items = Array.isArray(newValues) ? newValues : [newValues];
        onChange(items);
      }}
      showSelectAll={showSelectAll}
    />
  );
};

export default AgenciesAutocomplete;
