import dataService from 'services/dataService';
import { apiPatientClaimStatusLogs } from 'services/dataService/resources';
import { createModel } from '@rematch/core';

import type { RootModel } from 'store/models';

const ITEMS_PER_PAGE = 10;

interface IClaimStatusLogData {
  oldValue: string;
  newValue: string;
  patient: {
    firstName: string;
    lastName: string;
  };
  createdAt: string;
  haveUpdatedBy: boolean;
  updatedByLogin: {
    title: string;
    professionType: string;
    firstName: string;
    lastName: string;
  };
}

interface IState {
  page: number;
  pageStep: number;
  reversed: boolean;
  totalCount: number;
  totalPages: number;
  data: IClaimStatusLogData[];
}

const INITIAL_STATE: IState = {
  page: 1,
  pageStep: ITEMS_PER_PAGE,
  reversed: false,
  totalCount: 1,
  totalPages: 0,
  data: [],
};

interface IPaginationOptions {
  pageOffset: number;
}

interface IClaimStatusResponse {
  count: number;
  data: IClaimStatusLogData[];
}

const patientClaimStatusLogs = createModel<RootModel>()({
  state: INITIAL_STATE,
  reducers: {
    setPatientClaimStatusLogs: (state, payload) => ({
      ...state,
      data: [...payload],
    }),
    setPage: (state, payload) => ({
      ...state,
      page: payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    toggleReversed: (state) => ({
      ...state,
      reversed: !state.reversed,
    }),
  },
  effects: (dispatch) => ({
    async getPatientClaimStatusLogs(patientGuid, state) {
      const { page, reversed } = state.patientClaimStatusLogs;
      const { data } = await dataService.getList<IClaimStatusResponse>(
        apiPatientClaimStatusLogs(patientGuid, page, reversed),
      );

      dispatch.patientClaimStatusLogs.setPatientClaimStatusLogs(
        data?.data || [],
      );
      const totalCount = data?.count || 0;
      const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);

      dispatch.patientClaimStatusLogs.setTotalCountAndTotalPages({
        totalCount,
        totalPages,
      });
    },
    async setPagePatientClaimStatusLogs(
      { pageOffset }: IPaginationOptions,
      state,
    ) {
      const { page, totalPages } = state.patientClaimStatusLogs;
      const newPage = page + pageOffset;
      dispatch.patientClaimStatusLogs.setPage(
        newPage <= totalPages ? newPage : totalPages,
      );
    },
    async toggleOrder() {
      dispatch.patientClaimStatusLogs.toggleReversed();
      dispatch.patientClaimStatusLogs.setPage(1);
    },
  }),
});

export default patientClaimStatusLogs;
