import React from 'react';
import { motion, useIsPresent } from 'framer-motion';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface IProps extends IBaseComponentProps {}

const SpringAnimation: React.FC<IProps> = (props) => {
  const { uniqueKey = Date.now(), children } = props;

  const isPresent = useIsPresent();

  return (
    <motion.div
      key={uniqueKey}
      style={{
        position: isPresent ? 'static' : 'absolute',
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        type: 'spring',
        stiffness: 900,
        damping: 40,
      }}
      layout
    >
      {children}
    </motion.div>
  );
};

export default SpringAnimation;
