import BaseTableReport from 'components/atoms/base-table/report/report';
import { useTranslation } from 'react-i18next';
import { useState, type FC } from 'react';

import s from './styles.module.scss';
import { useColumns } from './columns/useColumns';

import type { IBaseTableReportProps } from 'components/atoms/base-table/report/report';
import type {
  CcmAgentItem,
  Filters,
} from 'infrastructure/hooks/ccmAgentDashboard/types';

interface Props {
  isLoading: boolean;
  count: number | undefined;
  items: CcmAgentItem[] | undefined;
  filters: Filters;
  changePage: (page: number) => void;
  onSort?: IBaseTableReportProps['onSort'];
}

const CcmAgentDashboardTable: FC<Props> = ({
  count = 0,
  items,
  isLoading,
  filters,
  changePage,
  onSort,
}) => {
  const { t } = useTranslation();

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const toggleExpanded = (key: string) => {
    if (expandedRowKeys.some((el) => el === key)) {
      setExpandedRowKeys(expandedRowKeys.filter((el) => el !== key));
    } else {
      setExpandedRowKeys([...expandedRowKeys, key]);
    }
  };

  const columns = useColumns({
    expandedRowKeys,
    toggleExpanded,
  });

  return (
    <div className={s.wrapper}>
      <BaseTableReport
        rowKey="key"
        isLoading={isLoading}
        columns={columns}
        headerTitle={t('labels.patients')}
        dataSource={items}
        pagination={{
          current: filters.page,
          pageSize: filters.items,
          total: count,
          onChange: changePage,
        }}
        rowClassName={(row) => s[row.color]}
        onSort={onSort}
        showCount
        anchor
        expandable={{
          showExpandColumn: false,
          expandedRowKeys,
        }}
      />
    </div>
  );
};

export default CcmAgentDashboardTable;
