import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import forms from 'constants/forms';
import Input from 'components/atoms/input';

const Clinic = ({ errors, getDoctors, clinics, patient, values }) => {
  const clinicForm = [...forms.adminPanelsUser.userEdit.clinic];
  const clinicsList = [...clinics];
  // if patient's clinic is not active his clinic will not be in clinics list
  // so we add his clinic manually to clinics list
  if (
    patient &&
    !clinicsList.find((d) => d.guid === patient?.clinic?.clinicGuid)
  ) {
    clinicsList.unshift({
      ...patient?.clinic,
      guid: patient?.clinic?.clinicGuid,
    });
  }
  return (
    <div className="admin-patient-form-block">
      <span>Clinic</span>
      <Row>
        <Col lg="6" md="6" sm="6" xs="12">
          <Input
            dataCy="clinic-name-input"
            label="Clinic"
            id="clinicGuid"
            type="select"
            options={clinicsList.map((clinic) => ({
              label: clinic.legalname,
              id: clinic.guid,
            }))}
            onChange={(e, setFieldValue) => {
              const [pickedClinic] = clinicsList.filter(
                (clinic) => clinic.guid === e.id,
              );
              setFieldValue('clinicAddress', pickedClinic.addressLine1 || '');
              setFieldValue('clinicState', pickedClinic.state || '');
              setFieldValue('clinicCity', pickedClinic.city || '');

              getDoctors({
                clinicGuid: pickedClinic.guid,
                filter: {
                  roleName:
                    'doctor,nursePractitioner,registeredNurse,physicianAssistant,pharmD',
                },
              });
            }}
          />
          {!values?.clinicGuid && errors.clinicGuid ? (
            <div className="input-error">{errors.clinicGuid}</div>
          ) : null}
        </Col>
        {clinicForm.map((field) => (
          <Col lg="6" md="6" sm="6" xs="12" key={field.id}>
            <Input {...field} disabled />
            {errors[field.id] ? (
              <div className="input-error">{errors[field.id]}</div>
            ) : null}
          </Col>
        ))}
      </Row>
    </div>
  );
};

Clinic.propTypes = {
  errors: PropTypes.shape(),
  clinics: PropTypes.array,
  values: PropTypes.shape(),
  getDoctors: PropTypes.func.isRequired,
  patient: PropTypes.shape(),
};

export default Clinic;
