import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { showResult } from 'infrastructure/hooks/utils';

import { updatePvrStatusFn } from './api';

import type { IPatient } from 'infrastructure/interfaces';

export const useUpdatePvrStatus = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: updatePvrStatusFn,
    onSuccess(data, variables) {
      const patientInfoKey = getPatientInfoKey(variables.patientGuid);
      queryClient.setQueryData<IPatient>(patientInfoKey, (oldData) => {
        if (oldData && data) {
          return {
            ...oldData,
            acceptPvrLite: data.acceptPvrLite,
          };
        }
      });
      showResult();
    },
  });

  return {
    updatePvrStatus: mutate,
    updatePvrStatusLoading: isLoading,
  };
};
