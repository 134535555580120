import { useEffect, useRef, useState } from 'react';

const useCheckMount = () => {
  const isMounted = useRef(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setIsMountedState] = useState(true);

  useEffect(() => {
    isMounted.current = true;
    setIsMountedState(true);

    return () => {
      isMounted.current = false;
      setIsMountedState(false);
    };
  }, []);

  return {
    get isMounted() {
      return isMounted.current;
    },
  };
};

export default useCheckMount;
