import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterIcon from 'components/atoms/icons/filterIcon';
import { Button } from 'antd';
import EggIcon from 'components/atoms/icons/eggIcon';

import s from './styles.module.scss';
import ExpandButton from './expandButton';

interface Props {
  expanded: boolean;
  toggleExpanded: () => void;
  filtersCount: number;
  resetFilters: () => void;
}

const Header: FC<Props> = ({
  expanded,
  toggleExpanded,
  filtersCount,
  resetFilters,
}) => {
  const { t } = useTranslation();
  return (
    <div className={s.wrapper}>
      <Button className={s['title-wrapper']} onClick={toggleExpanded}>
        {filtersCount > 0 && <EggIcon count={filtersCount} />}
        {filtersCount <= 0 && <FilterIcon />}
        <h3 className={s.title}>{t('filters.filters')}</h3>
      </Button>
      <div className={s['expand-wrapper']}>
        {filtersCount > 0 && (
          <Button className={s['reset-button']} onClick={resetFilters}>
            {t('filters.resetFilters')}
          </Button>
        )}
        <ExpandButton expanded={expanded} onClick={toggleExpanded} />
      </div>
    </div>
  );
};

export default Header;
