import { Fragment, useMemo, useState, type FC } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownItem } from 'reactstrap';
import SmallDropdownArrow from 'components/atoms/icons/dropdownSelectArrow';

interface Props {
  label: string;
  options: any[];
  dataCy: string;
}

const RpmReportsDropdown: FC<Props> = ({ label, options, dataCy }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen((prevState) => !prevState);

  const columns = useMemo(() => {
    const c = [];
    for (let i = 0; i < options.length; i += 4) {
      c.push(options.slice(i, i + 4));
    }
    return c;
  }, [options]);

  return (
    <Dropdown
      nav
      inNavbar
      style={{ position: 'static' }}
      isOpen={open}
      toggle={toggleOpen}
    >
      <DropdownToggle nav className="navbar-dropdown-title" data-cy={dataCy}>
        <div className="navbar-dropdown-title-text">{label}</div>
        <SmallDropdownArrow className="dropdown-arrow-svg" />
      </DropdownToggle>
      {open && options.length > 0 && (
        <div className="navbar-dropdown__rpm">
          <p className="navbar-dropdown__rpm-title">{label}</p>
          <div className="navbar-dropdown__rpm-column-box">
            {columns.map((column, index) => {
              return (
                <Fragment key={index}>
                  <div className="navbar-dropdown__rpm-column">
                    {column.map((item, index2) => {
                      if (item.type === 'windows-link') {
                        return (
                          <DropdownItem
                            tag={Link}
                            key={`${index}-${index2}`}
                            href={item.link}
                            target="_blank"
                            data-cy={item.dataCy}
                            className="navbar-dropdown__rpm-item"
                          >
                            {item.label}
                          </DropdownItem>
                        );
                      }
                      return (
                        <DropdownItem
                          tag={Link}
                          key={`${index}-${index2}`}
                          to={item.link}
                          className="navbar-dropdown__rpm-item"
                          data-cy={item.dataCy}
                        >
                          {item.label}
                        </DropdownItem>
                      );
                    })}
                  </div>
                  <div className="navbar-dropdown__rpm-column-divider" />
                </Fragment>
              );
            })}
          </div>
        </div>
      )}
    </Dropdown>
  );
};

export default RpmReportsDropdown;
