import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertTimeToTimeZone, DateFormatter } from 'infrastructure/functions';
import { DateFormats, Timezones } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { Availability } from 'infrastructure/classes/availability';
import type { IWeekDayItem } from 'infrastructure/data-sources/week-days';

interface IProps {
  anytime?: boolean;
  day: IWeekDayItem;
  times: Availability[];
}

const ContactAvailabilityViewDay: React.FC<IProps> = (props) => {
  const { anytime = false, day, times } = props;

  const { t } = useTranslation();

  const convertTime = (time: string) => {
    const date = convertTimeToTimeZone(time, Timezones['America/Chicago']);
    return DateFormatter({
      date,
      format: DateFormats['h:mm a'],
    });
  };

  return (
    <li className={s.day} data-cy={`contactAvailability-block-${day.name}`}>
      <span className={s.name} data-cy="contactAvailability-day">
        {day.name}
      </span>
      <ul>
        {!anytime &&
          times.map((time) => (
            <li
              key={time.key}
              className={s.time}
              data-cy="contactAvailability-time"
            >
              {`${convertTime(time.startTime)} - ${convertTime(time.endTime)}`}
            </li>
          ))}
        {!anytime && !times.length && (
          <li className={s.time} data-cy="contactAvailability-time">
            {t('labels.notAvailable')}
          </li>
        )}
        {anytime && (
          <li className={s.time} data-cy="contactAvailability-time">
            {t('labels.anytime')}
          </li>
        )}
      </ul>
    </li>
  );
};

export default ContactAvailabilityViewDay;
