import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { showResult } from 'infrastructure/hooks/utils';
import common from 'constants/common';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import withLoader from 'components/organisms/withLoader';
import AutoCompleteDoctorMultiSelect from 'components/molecules/autocompleteFilter/doctorMulti';
import AutoCompleteClinicsMulti from 'components/molecules/autocompleteFilter/clinicMulti';
import { useTranslation } from 'react-i18next';
import forms from 'constants/forms';
import BaseSelect from 'components/atoms/baseSelect';

import FulfillmentChart from './fulfillmentChart';
import InsTypeChart from './insTypeChart';

const practiceStats = ({ statusBlocks, insuranceBlocks, patients }) => {
  const DEFAULT_CHILD_CLINIC = forms.childClinicSelect[0];

  const { getOnboardingCsvReport: csvLoading } = useSelector(
    (state) => state.loading.effects.onboardingStatus,
  );
  const {
    onboardingStatus: {
      setClinicOnboardingStatus,
      setDoctorOnboardingStatus,
      resetFilters,
      getOnboardingCsvReport,
      setChildClinicValue,
    },
  } = useDispatch();

  const { clinic, childClinic } = useSelector(
    (state) => state.onboardingStatus,
  );

  const [isShowSwitcher, setIsShowSwitcher] = useState(false);

  const onDoctorChange = (value) => {
    setDoctorOnboardingStatus(value);
  };

  const onClinicChange = (value, records) => {
    const isAnyParentClinic = records.some((record) => record.parentClinic);

    setIsShowSwitcher(isAnyParentClinic);
    setClinicOnboardingStatus(value);
    onDoctorChange(undefined);
  };

  const onChildClinicChange = ({ value }) => {
    setChildClinicValue(value);
  };

  const sendReport = async () => {
    const { error } = await getOnboardingCsvReport();
    return showResult(error, common.submitSuccessTypes.sendReport);
  };

  useEffect(
    () => () => {
      resetFilters();
    },
    [],
  );

  const { t } = useTranslation();

  return (
    <div className="onboarding-table">
      <Card className="card-border">
        <CardBody>
          <div className="performance-review__header">
            <Row className="w-full">
              <Col xs={12} lg={4}>
                <div className="onboarding-status">
                  <div className="onboarding-title">
                    Report: Onboarding Status
                  </div>
                  <Col xs={12} lg={12}>
                    <div>
                      <p>Clinics</p>
                      <AutoCompleteClinicsMulti
                        setClinicFilters={onClinicChange}
                      />
                    </div>
                  </Col>

                  {isShowSwitcher && (
                    <Col xs={12} lg={12}>
                      <div style={{ margin: '20px 0' }}>
                        <p>{t('labels.childClinics')}</p>
                        <BaseSelect
                          options={forms.childClinicSelect}
                          defaultValue={DEFAULT_CHILD_CLINIC}
                          classNamePrefix="select-type"
                          onChange={onChildClinicChange}
                          value={forms.childClinicSelect.find(
                            (item) => Boolean(item.value) === childClinic,
                          )}
                        />
                      </div>
                    </Col>
                  )}

                  <Col xs={12} lg={12}>
                    <div>
                      <p>Provider</p>
                      <AutoCompleteDoctorMultiSelect
                        clinicGuid={clinic}
                        onChange={onDoctorChange}
                      />
                    </div>
                  </Col>
                </div>
              </Col>
              <Col xs={12} lg={8}>
                <Row>
                  <Col xs={12} lg={6}>
                    <InsTypeChart insuranceBlocks={insuranceBlocks} />
                  </Col>
                  <Col xs={12} lg={6}>
                    <FulfillmentChart statusBlocks={statusBlocks} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="performance-review__header-icon">
              {withLoader(
                patients.length < 0 || csvLoading,
                <button onClick={sendReport}>
                  <DownloadRoundedIcon className="performance-review__header-icon-svg" />
                  <br />
                  <span>Download</span>
                </button>,
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

practiceStats.propTypes = {
  statusBlocks: PropTypes.object.isRequired,
  insuranceBlocks: PropTypes.array.isRequired,
  patients: PropTypes.array,
};

export default practiceStats;
