import BgIcon from 'components/atoms/icons/condition-filter-bg.svg?react';
import SlaCrownIcon from 'components/atoms/icons/sla-crown.svg?react';
import classNames from 'classnames';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import { memo, type FC } from 'react';

import s from './styles.module.scss';
import { useButtons } from './use-buttons';

interface RiskConditionsProps {}

const RiskConditionFilter: FC<RiskConditionsProps> = () => {
  const { buttons, countLoading } = useButtons();

  return (
    <BaseSpinWrapper spinning={countLoading}>
      <div className={s.wrapper}>
        {buttons.map(({ active, bgColor, count, status, label, onClick }) => {
          return (
            <button
              className={classNames(s.item, { [s.active]: active })}
              onClick={onClick}
              key={label}
            >
              <p className={s.title}>{label}</p>
              <p className={s.count}>{count}</p>
              <BgIcon className={s.bg} color={bgColor} />
              {status === 'slaReadings' && <SlaCrownIcon className={s.crown} />}
            </button>
          );
        })}
      </div>
    </BaseSpinWrapper>
  );
};
export default memo(RiskConditionFilter);
