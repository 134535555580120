import { phoneRegex } from 'constants/regex';
import * as Yup from 'yup';

const ManualInteractiveTimeSchema = () =>
  Yup.object().shape({
    time: Yup.number().min(0).max(60).required(),
    comment: Yup.string().required(),
  });

export const manualEnterCallSchema = () =>
  Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(phoneRegex, 'Phone number is not correct')
      .required('required'),
  });
export const caseInEhrSchema = () =>
  Yup.object().shape({
    subject: Yup.string().required(),
    description: Yup.string().required(),
  });

export default ManualInteractiveTimeSchema;
