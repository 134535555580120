import { useEffect, useRef, useState } from 'react';
import dataService from 'services/dataService';
import { API_TURK_DASHBOARD_SELECT_READINGS } from 'services/dataService/resources';
import { isAbortError } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';

export interface Root {
  count: number;
  data: Reading[];
}

export interface Reading {
  id: string;
  dateTime: string;
  deviceId: string;
  readingType: string;
  value: any;
  createdAt: string;
  reviewed: boolean;
  statuses: any;
  globalStatus: string;
  patient: {
    guid: string;
    threshold: any;
  };
  doctor: any;
  reserved: any;
  comments: any[];
}

const useTurkDashboardReadings = () => {
  const [readings, setReadings] = useState<Reading[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const abortController = useRef<AbortController | undefined>();

  const getReadings = async () => {
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();
    setIsLoading(true);
    const { error, data, errorName } = await dataService.getList<Root>(
      'core/turk/readings',
      {
        signal: abortController.current.signal,
      },
    );
    setIsLoading(false);
    if (error && !isAbortError(errorName)) {
      showResult(error);
    }
    if (data) {
      setReadings(data.data);
    }
  };

  const selectReadings = async (filters?: {
    states?: Array<string | undefined>;
    startTime: string;
    endTime: string;
  }) => {
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();
    setIsLoading(true);
    const url = API_TURK_DASHBOARD_SELECT_READINGS(filters);
    const { error, data, errorName } = await dataService.getList<Root>(url, {
      signal: abortController.current.signal,
    });
    setIsLoading(false);
    if (error && !isAbortError(errorName)) {
      showResult(error);
    }
    if (data) {
      setReadings(data.data);
    }
  };

  useEffect(() => {
    getReadings();
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  const removeReading = ({
    readingId,
    deviceId,
  }: {
    readingId: string;
    deviceId: string;
  }) => {
    setReadings((prevReadings) => {
      return prevReadings.filter((reading) => {
        if (reading.id === readingId && reading.deviceId === deviceId) {
          return false;
        }
        return true;
      });
    });
  };

  const removeAllReadings = () => {
    setReadings([]);
  };

  return {
    readings,
    isLoading,
    selectReadings,
    removeReading,
    removeAllReadings,
  };
};

export default useTurkDashboardReadings;
