import React, { useEffect, useState } from 'react';
import { usePatientContactAvailability } from 'infrastructure/hooks/patient/use-patient-contact-availability';
import { weekDaysDataSource } from 'infrastructure/data-sources/week-days';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';

import s from './styles.module.scss';
import ContactAvailabilityViewDay from './day';

import type { Availability } from 'infrastructure/classes/availability';
import type { IWeekDayItem } from 'infrastructure/data-sources/week-days';

interface IAdminPanelPatientInfoFormProps {
  patientGuid: string;
}

const ContactAvailabilityViewForm: React.FC<IAdminPanelPatientInfoFormProps> = (
  props,
) => {
  const { patientGuid } = props;

  const [anytime, setAnytime] = useState(false);

  const { loading, availabilities } = usePatientContactAvailability({
    patientGuid,
  });

  const weekDays = weekDaysDataSource();

  const prepareAvailabilities = (day: IWeekDayItem) => {
    const result: Availability[] = [];
    availabilities.forEach((availability) => {
      if (day.index === availability.day) result.push(availability);
    });

    return result;
  };

  useEffect(() => {
    setAnytime(!availabilities.length);
  }, [loading]);

  return (
    <BaseSpinWrapper spinning={loading}>
      <ul className={s.list}>
        {weekDays.map((el) => (
          <ContactAvailabilityViewDay
            key={el.index}
            day={el}
            times={prepareAvailabilities(el)}
            anytime={anytime}
          />
        ))}
      </ul>
    </BaseSpinWrapper>
  );
};

export default ContactAvailabilityViewForm;
