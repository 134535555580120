import { API_SEARCH_PATIENTS } from './constants';

export const apiSearchPatients = (items, page = 1, filters) => {
  let endpoint = `${API_SEARCH_PATIENTS}?items=${items}&page=${page}`;
  Object.keys(filters).forEach((key) => {
    const filterValue = filters[key];
    if (
      (key !== 'ccmStatus' && filterValue && filterValue !== 'all') ||
      (key === 'ccmStatus' && filterValue !== undefined)
    ) {
      endpoint += `&${key}=${filterValue}`;
    }
  });
  return endpoint;
};

export const apiAdminPanelPatients = (items, page = 1, filters) => {
  let endpoint = `admins/admin-panel/patients?items=${items}&page=${page}`;
  Object.keys(filters).forEach((key) => {
    const filterValue = filters[key];
    if (filterValue && filterValue !== 'all') {
      endpoint += `&${key}=${filterValue}`;
    }
  });
  return endpoint;
};
export const apiPatientReadings = (
  guid,
  clinicGuid,
  reviewed,
  items,
  page = 1,
  startTime = null,
  endTime = null,
  userType,
  sortTime,
) => {
  let reviewedData = reviewed;
  if (userType === 'patient') {
    reviewedData = undefined;
  }
  let endpoint = `core/patients/${guid}/readings?clinicGuid=${clinicGuid}&reviewed=${reviewedData}&items=${items}&page=${page}`;
  if (startTime && endTime) {
    endpoint += `&startTime=${startTime}&endTime=${endTime}`;
  }
  if (sortTime) {
    endpoint += `&reverse=${sortTime}`;
  }
  return endpoint;
};
export const apiPatientAverageMetrics = (guid, date) => {
  let path = `core/patients/${guid}/readings/averages`;
  if (date) {
    path += `?endTime=${date.endTime}&timezoneOffset=${date.timezoneOffset}`;
  }

  return path;
};

export const apiPatientComments = (guid, page) =>
  `core/patients/${guid}/comments?page=${page}`;

export const apiPatientCommentAudio = (guid, callId) =>
  `core/patients/${guid}/call-record/${callId}`;

export const apiPatientHoldingStatus = (guid) =>
  `core/patients/holding-status?patientGuid=${guid}`;
export const apiPatientHoldingStatusLogs = (guid, page, reversed) =>
  `core/patients/${guid}/holding-status-logs?page=${page}&reverse=${reversed}`;

export const apiPatientProfile = (guid) => `core/patients/${guid}/profile`;
export const apiPatientDetails = (guid) => `core/patients/${guid}/details`;
export const apiPatientNotes = (guid) => `core/patients/${guid}/notes`;
export const apiPatientBingoNumber = () => `core/patients/bingo-number`;

export const apiPatientConsumableLogs = (guid, page, items) =>
  `core/patients/${guid}/consumable-logs?page=${page}&items=${items}`;
export const apiPatientInternalAdmissionForm = (guid) =>
  `core/patients/${guid}/internal-admission-form`;

export const apiPatientInternalAdmissionFormFile = (guid) =>
  `core/patients/${guid}/internal-admission-form/file`;
export const apiPatientDetailsAdmin = (guid) =>
  `admins/admin-panel/patients?patientGuid=${guid}`;

export const apiPatientMessages = (
  isCcm,
  guid,
  startDate = null,
  endDate = null,
  page,
  items,
) => {
  const path = isCcm ? 'ccm-messages' : 'messages';
  let endpoint = `core/patients/${guid}/${path}?page=${page}&items=${items}`;
  if (startDate && endDate) {
    endpoint += `&startDate=${startDate}&endDate=${endDate}`;
  }
  return endpoint;
};

export const apiPatientStatusLogs = (guid, page, reversed = false) =>
  `core/patients/${guid}/status-logs?page=${page}&reverse=${reversed}`;
export const apiPatientCcmStatusLogs = (guid, page, reversed = false) =>
  `core/patients/${guid}/ccm-status-logs?page=${page}&reverse=${reversed}`;
export const apiPatientClaimStatusLogs = (guid, page, reversed = false) =>
  `core/patients/${guid}/claim-status-logs?page=${page}&reverse=${reversed}`;
export const apiPatientCCMClaimStatusLogs = (guid, page, reversed = false) =>
  `core/patients/${guid}/ccm-claim-status-logs?page=${page}&reverse=${reversed}`;

export const apiAdminPanelPatientEdit = () => `admins/admin-panel/patients`;
export const apiPatientDevices = (guid) => `core/patients/${guid}/devices`;
export const apiPatientCharts = (guid, startTime, endTime) => {
  let endpoint = `core/patients/${guid}/charts?`;
  if (startTime && endTime) {
    endpoint += `&startTime=${startTime}&endTime=${endTime}`;
  }
  return endpoint;
};

export const apiPatientThresholds = (guid) =>
  `core/patients/${guid}/thresholds`;
export const apiPatientClinics = (guid) => `core/patients/${guid}/clinic`;

export const apiPatientPriorAuthorizations = (guid) =>
  `core/patients/${guid}/prior-authorizations`;

export const apiPatientInsurances = (guid) =>
  `core/patients/${guid}/insurances`;

export const apiPatientInteractiveTime = (guid, date) =>
  `core/patients/${guid}/action-time?date=${date}`;
export const apiPatientDiagnosis = (guid) => `core/patients/${guid}/diagnosis`;

export const apiPatientEmr = (guid) => `core/patients/${guid}/emr`;

export const apiPatientVitalsReport = (
  patientGuid,
  type,
  startTime,
  endTime,
  timezoneOffset,
) => {
  let endpoint = `reports/reports/vitals?patientGuid=${patientGuid}`;
  if (type) {
    endpoint += `&type=${type}`;
  }
  if (startTime && endTime) {
    endpoint += `&startTime=${startTime}&endTime=${endTime}`;
  }
  if (timezoneOffset) {
    endpoint += `&timezoneOffset=${timezoneOffset}`;
  }
  return endpoint;
};

export const apiPatientPlanOfCareReport = (
  patientGuid,
  type,
  startTime,
  endTime,
  timezoneOffset,
) => {
  let endpoint = `reports/reports/planOfCare?patientGuid=${patientGuid}`;
  if (type) {
    endpoint += `&type=${type}`;
  }
  if (startTime && endTime) {
    endpoint += `&startTime=${startTime}&endTime=${endTime}`;
  }
  if (timezoneOffset) {
    endpoint += `&timezoneOffset=${timezoneOffset}`;
  }
  return endpoint;
};

export const apiPatientServicePlansByGuid = (
  guid,
  status,
  insuranceProvider,
) => {
  let endpoint = `reports/reports/${guid}/service-plans-summary?status=${status}`;
  endpoint += `&insuranceProvider=${insuranceProvider}`;
  return endpoint;
};

export const apiPatientServicePlans = (status, insuranceProvider) => {
  let endpoint = `reports/reports/service-plans-summary?status=${status}`;
  endpoint += `&insuranceProvider=${insuranceProvider}`;
  return endpoint;
};

export const apiCcmPatients = (items, page = 1, filters) => {
  let endpoint = `core/ccm/activity?items=${items}&page=${page}`;
  Object.keys(filters).forEach((key) => {
    const filterValue = filters[key];
    if (filterValue && filterValue !== 'all') {
      endpoint += `&${key}=${filterValue}`;
    }
  });
  return endpoint;
};

export const apiCcmInteractionStatus = (guid) =>
  `core/ccm/interaction-status/${guid}`;

export const apiPatientManualInteractionTimes = (guid, page, items) =>
  `core/patients/${guid}/manual-time?page=${page}&items=${items}`;

export const apiPatientAttachmentsByPatientGuid = (patientGuid) =>
  `admins/admin-panel/patients/${patientGuid}/files`;

export const apiPatientAttachmentsByGuid = (guid) =>
  `admins/admin-panel/patients/files/${guid}`;

export const apiPatientEmailSubscriptionToggle = (guid) =>
  `core/patients/${guid}/email-subscription-toggle`;
