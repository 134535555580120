import type { FC } from 'react';

const AppointmentNotPlanedIcon: FC<{
  width?: number;
  height?: number;
}> = ({ height = 40, width = 40 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 26.25C13.75 25.5596 14.3096 25 15 25H25C25.6904 25 26.25 25.5596 26.25 26.25C26.25 26.9404 25.6904 27.5 25 27.5H15C14.3096 27.5 13.75 26.9404 13.75 26.25Z"
      fill="#CCCAD7"
    />
    <path
      d="M8.75 0C9.44036 0 10 0.559644 10 1.25V2.5H30V1.25C30 0.559644 30.5596 0 31.25 0C31.9404 0 32.5 0.559644 32.5 1.25V2.5H35C37.7614 2.5 40 4.73858 40 7.5V35C40 37.7614 37.7614 40 35 40H5C2.23858 40 0 37.7614 0 35V7.5C0 4.73858 2.23858 2.5 5 2.5H7.5V1.25C7.5 0.559644 8.05964 0 8.75 0ZM5 5C3.61929 5 2.5 6.11929 2.5 7.5V35C2.5 36.3807 3.61929 37.5 5 37.5H35C36.3807 37.5 37.5 36.3807 37.5 35V7.5C37.5 6.11929 36.3807 5 35 5H5Z"
      fill="#CCCAD7"
    />
    <path
      d="M6.25 10C6.25 9.30964 6.80964 8.75 7.5 8.75H32.5C33.1904 8.75 33.75 9.30964 33.75 10V12.5C33.75 13.1904 33.1904 13.75 32.5 13.75H7.5C6.80964 13.75 6.25 13.1904 6.25 12.5V10Z"
      fill="#CCCAD7"
    />
  </svg>
);

export default AppointmentNotPlanedIcon;
