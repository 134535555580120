import { useMemo, useState } from 'react';

import type { Reading } from './useReadings';

export type Sort = {
  time?: 'ASC' | 'DESC';
  patientGuid?: 'ASC' | 'DESC';
};

const sortReadings = (readings: Reading[], sort: Sort) => {
  return readings.sort((a, b) => {
    if (sort.time) {
      return sort.time === 'ASC'
        ? Number(a.createdAt) - Number(b.createdAt)
        : Number(b.createdAt) - Number(a.createdAt);
    }
    if (sort.patientGuid) {
      return sort.patientGuid === 'ASC'
        ? a.patient.guid.localeCompare(b.patient.guid)
        : b.patient.guid.localeCompare(a.patient.guid);
    }

    return 0;
  });
};

export const useTurkDashboardSort = (readings?: Reading[]) => {
  const [sort, setSort] = useState<Sort>({});

  const toggleSort = (key: keyof Sort) => {
    setSort({
      [key]: sort[key] === 'ASC' ? 'DESC' : 'ASC',
    });
  };

  const sortedReadings = useMemo(() => {
    return sortReadings(readings || [], sort);
  }, [sort, readings]);

  return { sort, toggleSort, sortedReadings } as const;
};
