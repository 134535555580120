import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTabs from 'components/atoms/base-tabs';
import { ModuleType } from 'infrastructure/enums';
import useUser from 'utils/useUser';
import { isPatient } from 'utils/userTypeHelper';
import EditProvider from 'infrastructure/providers/edit-provider';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';

import AdminTabbedFormPatientInfo from './tabs/patient-info';
import AdminTabbedFormClinicInfo from './tabs/clinic-info';
import AdminTabbedFormOtherInfo from './tabs/other-info';
import AdminTabbedFormCcmNextAppointment from './tabs/ccm-next-appointment';
import AdminTabbedFormLogs from './tabs/logs';
import AdminTabbedFormConditions from './tabs/conditions';
import s from './styles.module.scss';
import AdminTabbedFormPatientInsurance from './tabs/patient-insurance';
import AdminTabbedFormDevicesThresholds from './tabs/devices-thresholds';

import type { IBaseTab } from 'components/atoms/base-tabs';

interface IAdminPanelPatientDetailTabsProps {
  patientGuid: string;
  module: ModuleType;
  readOnly?: boolean;
}

const Tabs: React.FC<IAdminPanelPatientDetailTabsProps> = (props) => {
  const { patientGuid, module, readOnly = false } = props;
  const { t } = useTranslation();

  const { patientInfo } = usePatientInfo({
    patientGuid,
  });

  const { userType } = useUser();

  const items = useMemo(() => {
    let tabs: IBaseTab[] = [
      {
        key: 'patientInfo',
        label: t('labels.patientInfo'),
        children: (
          <AdminTabbedFormPatientInfo
            patientGuid={patientGuid}
            module={module}
            readOnly={readOnly}
          />
        ),
      },
      {
        key: 'clinicInfo',
        label: t('labels.clinicInfo'),
        children: (
          <AdminTabbedFormClinicInfo
            patientGuid={patientGuid}
            module={module}
          />
        ),
      },
      {
        key: 'otherInfo',
        label: t('labels.otherInfo'),
        children: (
          <AdminTabbedFormOtherInfo
            patientGuid={patientGuid}
            module={module}
            readOnly={readOnly}
          />
        ),
      },
      {
        key: 'devicesAndThresholds',
        label: t('labels.devicesAndThresholds'),
        children: (
          <AdminTabbedFormDevicesThresholds
            patientGuid={patientGuid}
            module={module}
            rpmStatus={patientInfo?.status}
          />
        ),
      },
      {
        key: 'conditions',
        label: t('labels.conditions'),
        children: <AdminTabbedFormConditions patientGuid={patientGuid} />,
      },
      {
        key: 'ccmNextAppointment',
        label: t('labels.ccmNextAppointment'),
        children: (
          <AdminTabbedFormCcmNextAppointment
            patientGuid={patientGuid}
            module={module}
          />
        ),
      },
      {
        key: 'patientInsurance',
        label: t('labels.patientInsurance'),
        children: (
          <AdminTabbedFormPatientInsurance
            patientGuid={patientGuid}
            module={module}
          />
        ),
      },
      {
        key: 'logs',
        label: t('labels.logs'),
        children: (
          <AdminTabbedFormLogs patientGuid={patientGuid} module={module} />
        ),
      },
    ];

    if (module === ModuleType.RPM) {
      const excludedTabs = ['conditions'];
      tabs = tabs.filter((tab) => !excludedTabs.includes(tab.key as string));
    }

    if (module === ModuleType.CCM) {
      const excludedTabs = [
        'devicesAndThresholds',
        'conditions',
        'ccmNextAppointment',
        'patientInsurance',
      ];
      tabs = tabs.filter((tab) => !excludedTabs.includes(tab.key as string));
    }

    if (isPatient(userType)) {
      const excludedTabs = ['logs'];
      tabs = tabs.filter((tab) => !excludedTabs.includes(tab.key as string));
    }
    return tabs;
  }, [patientGuid, module, readOnly, patientInfo, userType]);

  return (
    <BaseTabs
      key={`${patientGuid}-${readOnly}`}
      tabs={items}
      contentClassName={s.content}
      wrapperClassName={s['tabs-wrapper']}
    />
  );
};

const AdminPanelPatientDetailTabs: React.FC<
  IAdminPanelPatientDetailTabsProps
> = (props) => {
  return (
    <EditProvider>
      <Tabs {...props} />
    </EditProvider>
  );
};
export default AdminPanelPatientDetailTabs;
