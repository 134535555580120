import ExpandableFilterPanel from 'components/molecules/expandable-filter-panel';
import BaseSearchSelect from 'components/atoms/base-search-select';
import BaseSelectBox from 'components/atoms/base-select-box';
import { type FC } from 'react';
import useUser from 'utils/useUser';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';
import { useFilter } from './use-filter';

interface ItemProps {
  label: string;
}
const Item: FC<ItemProps> = ({ label, children }) => (
  <div className={s['item-wrapper']}>
    <label>{label}</label>
    {children}
  </div>
);

interface UsersFilterProps {}

const UsersFilter: FC<UsersFilterProps> = () => {
  const { isPractice } = useUser();
  const { t } = useTranslation();
  const {
    filter,
    updateQueryParams,
    searchDoctorLoading,
    searchClinicLoading,
    statusOptions,
    placeholder,
    debounceSearchDoctor,
    debounceSearchClinic,
    doctorOptions,
    clinicOptions,
    countOfChangedFilterField,
    DEFAULT_DOCTOR_OPTION,
    options,
    roleOptions,
    resetFilter,
  } = useFilter();

  return (
    <ExpandableFilterPanel
      filtersCount={countOfChangedFilterField}
      onReset={resetFilter}
      isExpanded
    >
      <div
        className={classNames(s.wrapper, { [s['is-practice']]: isPractice })}
      >
        <Item label={t('labels.clinicUserFilter')}>
          <div className={s['user-item']}>
            <BaseSelectBox
              options={options}
              defaultValue={DEFAULT_DOCTOR_OPTION.value}
              className={s.dropdown}
              onChange={(v) => {
                updateQueryParams({ doctorKey: v });
              }}
              value={filter.doctorKey}
            />
            <BaseSearchSelect
              isLoading={searchDoctorLoading}
              className={s.search}
              options={doctorOptions}
              placeholder={placeholder}
              value={filter.guid}
              onChange={(searchValue) =>
                debounceSearchDoctor({
                  searchKey: filter.doctorKey,
                  searchValue,
                  status: filter.status,
                  roleName: filter.role,
                })
              }
              onSelect={(v, option) => {
                updateQueryParams({
                  page: undefined,
                  guid: v,
                  doctorLabel: option.label as string,
                });
              }}
              onClear={() => {
                updateQueryParams({
                  page: undefined,
                  guid: undefined,
                  doctorLabel: undefined,
                });
              }}
            />
          </div>
        </Item>
        {!isPractice && (
          <Item label={t('labels.clinic')}>
            <BaseSearchSelect
              isLoading={searchClinicLoading}
              placeholder="Legal Name"
              value={filter.clinicGuid}
              options={clinicOptions}
              onChange={debounceSearchClinic}
              onSelect={(v, opt) => {
                updateQueryParams({
                  page: undefined,
                  clinicGuid: v,
                  clinicLabel: opt.label as string,
                });
              }}
              onClear={() => {
                updateQueryParams({
                  page: undefined,
                  clinicGuid: undefined,
                  clinicLabel: undefined,
                });
              }}
            />
          </Item>
        )}
        <Item label={t('labels.role')}>
          <BaseSelectBox
            options={roleOptions}
            placeholder="Select"
            value={filter.role}
            onChange={(v) => {
              updateQueryParams({ role: v, page: 1 });
            }}
            allowClear
          />
        </Item>
        <Item label={t('labels.status')}>
          <BaseSelectBox
            options={statusOptions}
            placeholder="Select"
            value={filter.status}
            onChange={(v) => {
              updateQueryParams({ status: v, page: 1 });
            }}
            allowClear
          />
        </Item>
      </div>
    </ExpandableFilterPanel>
  );
};
export default UsersFilter;
