import React, { useEffect, useRef, useState } from 'react';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import { PatientPriorAuthorization } from 'infrastructure/classes/patient/patient-prior-authorization';
import PlusIcon from 'components/atoms/icons/plus';
import BaseButton from 'components/atoms/baseButton';
import { DateFormatter, removeItemFromArray } from 'infrastructure/functions';
import { usePatientPriorAuthorization } from 'infrastructure/hooks/patient/use-patient-prior-authorization';
import { DateFormats, ModuleType } from 'infrastructure/enums';
import { useEdit } from 'infrastructure/providers/edit-provider';
import useUser from 'utils/useUser';

import PriorAuthorizationFormItem from './item';
import s from './styles.module.scss';

import type { IPriorAuthorizationFormItemRef } from './item';

const formKey = 'PriorAuthorizationForm';

interface IAdminPanelPatientInfoFormProps {
  patientGuid: string;
  module: ModuleType;
}

const PriorAuthorizationForm: React.FC<IAdminPanelPatientInfoFormProps> = (
  props,
) => {
  const { patientGuid, module } = props;

  const { canEdit, setEdit } = useEdit();

  const isAdmin = module === ModuleType.ADMIN;

  const { t } = useTranslation();

  const {
    loading,
    priorAuthorizations,
    loadPriorAuthorizations,
    updatePriorAuthorizations,
  } = usePatientPriorAuthorization({ patientGuid });

  const { iamPatientAcceptance } = useUser();

  const [localItems, setLocalItems] = useState(priorAuthorizations);
  const [needChange, setNeedChange] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const itemsRef = useRef<Array<IPriorAuthorizationFormItemRef>>([]);

  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };

  const onCancel = () => {
    itemsRef.current.forEach((el) => el.resetForm());
    setLocalItems(priorAuthorizations);
    setIsEdit(false);
    setEdit(false);
  };

  const onCreate = () => {
    const newItem = PatientPriorAuthorization.getInitialValues(patientGuid);
    newItem.setAction('create');
    setLocalItems([...localItems, newItem]);
  };

  const onSave = async () => {
    const values: Array<PatientPriorAuthorization> = [];
    const isValid: Array<boolean> = [];

    itemsRef.current.forEach((el) => {
      const res = el.validate();
      values.push(res.value);
      isValid.push(res.isValid);
    });

    if (isValid.every((el) => el)) {
      setNeedChange(false);
      const updatingItems = values.filter((val) => val.action !== 'delete');
      await updatePriorAuthorizations(
        updatingItems.map((el) => {
          return {
            ...el,
            startDate: DateFormatter({
              date: el.startDate,
              format: DateFormats['MM-dd-yyyy'],
            }),
            endDate: DateFormatter({
              date: el.endDate,
              format: DateFormats['MM-dd-yyyy'],
            }),
          };
        }),
      );
      await loadPriorAuthorizations();
      setNeedChange(true);
      setIsEdit(false);
      setEdit(false);
    }
  };

  const onDelete = (availability: PatientPriorAuthorization, index: number) => {
    availability.setAction('delete');
    itemsRef.current = removeItemFromArray(itemsRef.current, index);
    setLocalItems(localItems.filter((el) => el.action !== 'delete'));
  };

  useEffect(() => {
    loadPriorAuthorizations();
  }, [patientGuid]);

  useEffect(() => {
    if (needChange) setLocalItems(priorAuthorizations);
  }, [needChange, priorAuthorizations]);

  return (
    <PatientBlock
      title={t('labels.priorAuthorization')}
      canEdit={isAdmin}
      isEdit={isEdit}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      loading={loading}
      disabled={!canEdit(formKey) || iamPatientAcceptance}
    >
      <ul className={s.list}>
        {localItems.map(
          (el, index) =>
            el.action !== 'delete' && (
              <PriorAuthorizationFormItem
                ref={(e) => {
                  if (e) itemsRef.current[index] = e;
                }}
                key={el.key}
                data={el}
                isEdit={isEdit}
                onDelete={(availability) => onDelete(availability, index)}
              />
            ),
        )}
      </ul>
      {isEdit && (
        <BaseButton
          label={t('controls.addNewAuthorization')}
          icon={<PlusIcon color="" />}
          onClick={onCreate}
          className={s['create-btn']}
          dataCy="addNewAuthorization-button"
        />
      )}
    </PatientBlock>
  );
};

export default PriorAuthorizationForm;
