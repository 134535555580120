interface IPrepareDoctorNameParams {
  firstName?: string | null;
  lastName?: string | null;
  professionType?: string | null;
  title?: string | null;
}

export const prepareDoctorName = ({
  title,
  firstName,
  lastName,
  professionType,
}: IPrepareDoctorNameParams) => {
  let name = '';

  if (title) {
    name += title;
  }
  if (firstName) {
    name += ` ${firstName}`;
  }
  if (lastName) {
    name += ` ${lastName}`;
  }
  if (professionType) {
    name += ` - ${professionType}`;
  }

  return name;
};
