import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { apiPatientDetails } from 'services/dataService/resources';

interface Props {
  patientGuid: string;
}

export const getFullPatientDetailsKey = (patientGuid: string) => [
  'getFullPatientDetails',
  patientGuid,
];

export const useFullPatientInfo = ({ patientGuid }: Props) => {
  const {
    data: fullPatientDetails,
    refetch,
    fetchStatus,
    isRefetching,
  } = useQuery({
    queryKey: getFullPatientDetailsKey(patientGuid),
    queryFn: async () => {
      const { data } = await dataService.getList(
        apiPatientDetails(patientGuid),
      );
      return data;
    },
    enabled: false,
  });

  return {
    fullPatientDetails,
    fetchFullPatientDetails: refetch,
    patientFullDetailsLoading: fetchStatus === 'fetching' || isRefetching,
  };
};
