import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/atoms/button';
import { forms, template } from 'constants/pcctComment';
import DraggableModal from 'components/organisms/draggableModal/draggableModal';
import Section from 'components/templates/ccm/patientDetails/components/narrativeTemplates/components/Section';
import mustache from 'mustache';
import {
  addSpaceAfterMarks,
  formatTemplateValues,
} from 'utils/templateHelpers';
import withLoader from 'components/organisms/withLoader';

const allRequiredForm = [];
forms.forEach((form) => {
  if (form.isRequired) {
    allRequiredForm.push(form.id);
  }
});

const PcctModal = ({
  isOpen,
  closeModal,
  values,
  setValues,
  setComment,
  setCommentsPopupOpen,
  loading,
}) => {
  const setValue = (value) => setValues((prev) => ({ ...prev, ...value }));

  const disableSaveButton = useMemo(
    () =>
      allRequiredForm.some((formId) => {
        const value = values[formId];
        if (Array.isArray(value) && !value.length) {
          return true;
        }
        if (!value) {
          return true;
        }
        return false;
      }),
    [values],
  );

  const handleSubmit = async () => {
    const commentTemplate = template
      .map((item) =>
        values[item.key] && values[item.key]?.length ? item.value : '',
      )
      .join('');

    const resultText = mustache.render(
      commentTemplate,
      formatTemplateValues(values),
    );
    setComment(addSpaceAfterMarks(resultText));
    closeModal();
    if (setCommentsPopupOpen) {
      setCommentsPopupOpen(true);
    }
  };
  return (
    <DraggableModal
      isOpen={!isOpen}
      toggle={closeModal}
      title="PCCT Notes Generator"
      styleName="popup narrative-popup"
    >
      {withLoader(
        loading,
        <>
          <Section
            forms={forms}
            setValue={setValue}
            values={values}
            canEditPage
          />
          <Button
            text="Save"
            data-cy="save-button"
            onClick={handleSubmit}
            disabled={disableSaveButton}
          />
        </>,
      )}
    </DraggableModal>
  );
};
PcctModal.defaultProps = {
  isOpen: false,
};

PcctModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  values: PropTypes.object,
  setValues: PropTypes.func,
  setComment: PropTypes.func,
  setCommentsPopupOpen: PropTypes.func,
  loading: PropTypes.bool,
};

export default PcctModal;
