import BaseTableNew from 'components/atoms/base-table/new';
import {
  MEDICAL_HISTORY_PER_PAGE,
  useGetMedicalHistory,
  useUpdateMedicalHistory,
} from 'infrastructure/hooks/ccm';
import { type Dispatch, type SetStateAction, type FC, useState } from 'react';
import BaseModal from 'components/atoms/base-modal';
import { t } from 'i18next';
import MedicalHistoryForm from 'components/molecules/admin-panel/forms/medical-history';
import EmptyTablePlaceholder from 'components/atoms/base-empty-table-placeholder';

import { columns } from './columns';

import type { Values } from 'components/molecules/admin-panel/forms/medical-history/validation-schema';
import type { IUpdateMedicalHistory } from 'infrastructure/interfaces';

interface MedicalHistoryTableProps {
  patientGuid: string;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  disableActions?: boolean;
}

type THistoryForUpdate = Omit<IUpdateMedicalHistory, 'patientGuid'>;

const MedicalHistoryTable: FC<MedicalHistoryTableProps> = ({
  patientGuid,
  page,
  setPage,
  disableActions,
}) => {
  const [open, setOpen] = useState(false);
  const [historyForUpdate, setHistoryForUpdate] = useState<THistoryForUpdate>();
  const { medicalHistory, isLoading } = useGetMedicalHistory({
    patientGuid,
    meta: { page },
  });
  const { updateMedicalHistory, updateLoading } = useUpdateMedicalHistory({
    page,
  });

  const toggleOpen = () => setOpen((prev) => !prev);

  const onSubmit = async ({ record }: Values) => {
    if (record && historyForUpdate)
      updateMedicalHistory(
        { ...historyForUpdate, record, patientGuid },
        { onSuccess: () => setOpen(false) },
      );
  };

  const onCancelForm = () => {
    setHistoryForUpdate(undefined);
    setOpen(false);
  };

  return (
    <>
      <BaseTableNew
        loading={isLoading}
        items={medicalHistory?.items ?? []}
        columns={columns({
          setHistoryForUpdate(v) {
            toggleOpen();
            setHistoryForUpdate(v);
          },
          loading: updateLoading,
          updatedItemGuid: historyForUpdate?.guid,
          disableActions,
        })}
        bordered
        pagination={{
          current: page,
          pageSize: MEDICAL_HISTORY_PER_PAGE,
          total: medicalHistory?.count ?? 0,
          position: ['bottomCenter'],
        }}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
        dataCy="medicalHistory"
        // eslint-disable-next-line prettier/prettier
        emptyText={(
          <EmptyTablePlaceholder
            text={t('labels.noMedicalHistoryRecordings')}
          />
          // eslint-disable-next-line prettier/prettier
        )}
      />
      <BaseModal
        title={t('labels.editMedicalHistory')}
        open={open}
        onCancel={toggleOpen}
      >
        <MedicalHistoryForm
          loading={updateLoading}
          onCancelForm={onCancelForm}
          onSubmit={onSubmit}
          defaultValues={historyForUpdate}
        />
      </BaseModal>
    </>
  );
};
export default MedicalHistoryTable;
