import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import BaseWrapper from 'components/atoms/base-wrapper';
import { CommentType, ModuleType } from 'infrastructure/enums';
import { useComment } from 'infrastructure/hooks/use-comment';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTabs from 'components/atoms/base-tabs';
import { endOfDate, startOfDate } from 'infrastructure/functions';
import GeneralCommentsIcon from 'components/atoms/icons/generalCommentsIcon';
import useUser from 'utils/useUser';
import { isPractice } from 'utils/userTypeHelper';
import { useUpdateInteractionStatus } from 'infrastructure/hooks/control-panel';
import useAlert from 'infrastructure/hooks/utils/use-alert';

import GeneralCommentsList from './list/index';
import s from './styles.module.scss';
import GeneralCommentsTextArea from './text-area';
import GeneralCommentsEmptyBlock from './empty-block';

import type { IBaseTab } from 'components/atoms/base-tabs';
import type { DateRange } from 'infrastructure/types';
import type { IGeneralCommentsTextAreaRef } from './text-area';

interface IGeneralCommentsProps {
  patientGuid: string;
  readOnly?: boolean;
  module?: ModuleType;
  interactionStatus?: boolean;
  withSwitcher?: boolean;
}

const DESKTOP_COMMENTS_LIST_HEIGHT = 609;
const MOBILE_COMMENTS_LIST_HEIGHT = 409;

const GeneralComments: React.FC<IGeneralCommentsProps> = (props) => {
  const {
    patientGuid,
    interactionStatus = false,
    module: type,
    readOnly = false,
    withSwitcher = false,
  } = props;

  const { t } = useTranslation();

  const [module, setModule] = useState<ModuleType>(type ?? ModuleType.RPM);
  const [isSending, setIsSending] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [timeRange, setTimeRange] = useState<[number, number] | undefined>();

  const isSameModule = type === module;

  const {
    commentsLoading: load,
    comments,
    meta,
    setMeta,
    sendComment,
  } = useComment({
    patientGuid,
    meta: {
      commentType: type ?? ModuleType.RPM,
    },
  });

  const { userType, isMobileDevice } = useUser();

  const { updateInteractionStatus } = useUpdateInteractionStatus();
  const [bottomPadding, setBottomPadding] = useState(74);
  const { Alert, showAlert } = useAlert();

  const loading = !isNext && load;
  const textAreaLoading = (!isNext && load) || isSending;

  const title = `${t(`labels.${module}`)} ${t('labels.generalComments')}`;
  const commentsHeight = isMobileDevice
    ? MOBILE_COMMENTS_LIST_HEIGHT
    : DESKTOP_COMMENTS_LIST_HEIGHT;

  const [withFilters, setWithFilters] = useState(false);
  const generalCommentsTextAreaRef = useRef<IGeneralCommentsTextAreaRef>(null);

  const tabs: IBaseTab<ModuleType>[] = [
    {
      key: ModuleType.RPM,
      label: t('labels.rpm'),
      className: s.tab,
      disabled: load,
    },
    {
      key: ModuleType.CCM,
      label: t('labels.ccm'),
      className: s.tab,
      disabled: load,
    },
  ];

  const tabIndex = type === ModuleType.RPM ? 0 : 1;

  const loadData = async (page: number, range?: [number, number]) => {
    let startTime;
    let endTime;
    if (range) {
      [startTime, endTime] = range;
    }
    setMeta({
      ...meta,
      page,
      commentType: module,
      startTime,
      endTime,
    });
  };

  const loadNext = async () => {
    setIsNext(true);
    await loadData(meta.page + 1, timeRange);
    setIsNext(false);
  };

  const onSend = async (message: string) => {
    if (message.trim()) {
      setIsSending(true);

      let interactionConfirm: boolean = false;

      if (!isPractice(userType)) {
        if (module === ModuleType.RPM && !interactionStatus) {
          interactionConfirm = await showAlert({
            title: t('labels.monthlyInteraction'),
            messageTitle: t('confirm.clinicalNoteMonthlyInteraction'),
            successBtnLabel: t('controls.yes'),
            rejectBtnLabel: t('controls.no'),
          });
        }
      }

      const response = await sendComment({
        commentType: module,
        message,
        patientGuid,
        type: CommentType.Text,
      });

      if (response && interactionConfirm) {
        updateInteractionStatus({
          patientGuid,
          isInteracted: true,
        });
      }

      if (generalCommentsTextAreaRef) {
        generalCommentsTextAreaRef.current?.resetForm();
      }
      setIsSending(false);
    }
  };

  const changeTimeRange = async (range?: DateRange) => {
    let parsedRange: [number, number] | undefined;
    if (range) {
      parsedRange = [
        startOfDate(range[0]).getTime(),
        endOfDate(range[1]).getTime(),
      ];
    }
    setTimeRange(parsedRange);
    await loadData(1, parsedRange);
    setWithFilters(range !== null);
  };

  const changeModule = (m: ModuleType) => {
    setModule(m);
    setMeta({
      ...meta,
      commentType: m,
      page: 1,
    });
  };

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseWrapper className={s.wrapper}>
        <div className={s.header}>
          <p className={s.title} data-cy={`general-comment-title-${module}`}>
            <GeneralCommentsIcon />
            {title}
          </p>
          <div className={s.controls}>
            <BaseRangeDatepicker
              withPresets
              onChange={(dates) => {
                changeTimeRange(dates);
              }}
            />
            {withSwitcher && (
              <BaseTabs
                tabs={tabs}
                defaultIndex={tabIndex}
                type="rounded"
                onChange={(tab) => changeModule(tab.key)}
              />
            )}
          </div>
        </div>
        <div className={s.body}>
          {comments.length ? (
            <GeneralCommentsList
              key={timeRange?.join()}
              id={`general-comments-list${module}`}
              items={comments}
              totalItems={meta.totalCount}
              loadNext={() => loadNext()}
              height={commentsHeight - bottomPadding}
            />
          ) : (
            <GeneralCommentsEmptyBlock
              withFilters={withFilters}
              height={commentsHeight - bottomPadding}
            />
          )}
        </div>
        {!readOnly && isSameModule && (
          <div className={s.footer} data-cy={`general-comment-input-${module}`}>
            <GeneralCommentsTextArea
              type={module}
              ref={generalCommentsTextAreaRef}
              loading={textAreaLoading}
              onSend={onSend}
              onResize={({ height }) => setBottomPadding(height)}
            />
          </div>
        )}
      </BaseWrapper>
      {Alert}
    </BaseSpinWrapper>
  );
};

export default GeneralComments;
