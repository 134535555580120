import React, { useState } from 'react';
import BaseGrid from 'components/atoms/base-grid';
import AdminPanelCareAgentForm from 'components/molecules/admin-panel/forms/care-agents';
import AdminPanelClinicInstructionsForm from 'components/molecules/admin-panel/forms/clinic-instructions';
import AdminPanelClinicProviderInfoForm from 'components/molecules/admin-panel/forms/clinic-provider-info';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { ModuleType } from 'infrastructure/enums';
import AdminPanelCcmClinicManagerForm from 'components/molecules/admin-panel/forms/ccm-clinical-manager';

import s from './styles.module.scss';

import type { Patient } from 'infrastructure/classes/patient/patient';

interface IAdminTabbedFormClinicInfoProps {
  patientGuid: string;
  module: ModuleType;
}

const AdminTabbedFormClinicInfo: React.FC<IAdminTabbedFormClinicInfoProps> = (
  props,
) => {
  const { patientGuid, module } = props;

  const { loading, patientInfo = {} as Patient } = usePatientInfo({
    patientGuid,
  });

  const [agencyGuid, setAgencyGuid] = useState<string | undefined>();
  const [clinicGuid, setClinicGuid] = useState<string | undefined>();

  const isCcm = module === ModuleType.CCM;
  const cols = isCcm ? 3 : 2;

  const onClinicChange = (guid?: string, agency?: string) => {
    setClinicGuid(guid);
    setAgencyGuid(agency);
  };

  return (
    <BaseGrid columns={1} rowGap={5} className={s.wrapper}>
      <div className={s['border-bottom']}>
        <BaseGrid columns={cols} columnGap={20}>
          <div className={s['border-right']}>
            <AdminPanelClinicProviderInfoForm
              patientGuid={patientGuid}
              clinicGuid={patientInfo.clinicGuid}
              doctorGuid={patientInfo.doctorGuid}
              loading={loading}
              onClinicChange={onClinicChange}
              module={module}
            />
          </div>
          <AdminPanelClinicInstructionsForm
            clinicGuid={clinicGuid ?? patientInfo.clinicGuid}
            loading={loading}
          />
          {isCcm && (
            <div className={s['border-left']}>
              <AdminPanelCcmClinicManagerForm
                agencyGuid={agencyGuid}
                loading={loading}
              />
            </div>
          )}
        </BaseGrid>
      </div>
      <AdminPanelCareAgentForm patientGuid={patientGuid} module={module} />
    </BaseGrid>
  );
};

export default AdminTabbedFormClinicInfo;
