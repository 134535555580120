import React from 'react';
import BaseLoader from 'components/atoms/base-loader';

const withLoader = (loading, Component) => {
  if (loading) {
    return <BaseLoader className="loader" loading />;
  }
  return Component;
};

export default withLoader;
