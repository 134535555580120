import type { IBaseTableColumnProps } from './types';

export const getColumnClassNames = (
  column: IBaseTableColumnProps,
  record: any,
  index: number,
): string => {
  if (column.rowCollClassName) {
    return column.rowCollClassName(record, index);
  }
  return '';
};
