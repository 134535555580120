import React from 'react';

const conditionButtonIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.4163 5.42363C82.6092 -40.2422 140.515 -49.4831 177.511 -15.6342C214.507 18.2146 216.473 111.636 177.511 152.829C138.549 194.021 48.7492 180.806 18.171 152.829C-12.4072 124.852 -3.77648 51.0895 39.4163 5.42363Z"
      fill={props.color}
    />
  </svg>
);

export default conditionButtonIcon;
