import i18next from 'i18next';
import { MaritalStatuses } from 'infrastructure/enums';

export interface IMaritalStatusItem {
  id: MaritalStatuses;
  name: string;
}

export const maritalStatusesDataSource = (): IMaritalStatusItem[] => {
  return [
    {
      id: MaritalStatuses.Married,
      name: i18next.t('maritalStatuses.married'),
    },
    {
      id: MaritalStatuses.Widowed,
      name: i18next.t('maritalStatuses.widowed'),
    },
    {
      id: MaritalStatuses.Separated,
      name: i18next.t('maritalStatuses.separated'),
    },
    {
      id: MaritalStatuses.Divorced,
      name: i18next.t('maritalStatuses.divorced'),
    },
    {
      id: MaritalStatuses.Single,
      name: i18next.t('maritalStatuses.single'),
    },
  ];
};
