import dataService from 'services/dataService';
import {
  apiDoctorAdherenceLast30Days,
  ADHERENCE_REPORT,
} from 'services/dataService/resources';
import { isEmpty } from 'lodash';

const ITEMS_PER_PAGE = 100;

const INITIAL_STATE = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  totalPages: 0,
  totalCount: 0,
  sort: {
    percent: undefined,
  },
  data: [],
  filters: {
    doctorGuids: undefined,
  },
};

const adherenceLast30Days = {
  state: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    setAdherenceReport: (state, payload) => ({
      ...state,
      data: payload,
    }),
    setPage: (state, payload) => ({
      ...state,
      page: payload < 1 ? 1 : payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    toggleAdherenceSort: (state) => ({
      ...state,
      sort: {
        ...state.sort,
        percent:
          !state.sort.percent || state.sort.percent === 'desc' ? 'asc' : 'desc',
      },
    }),
    SET_FILTERS: (state, payload) => ({
      ...state,
      filters: {
        ...payload,
      },
    }),
  },
  effects: (dispatch) => ({
    async getAdherenceReport(_, state) {
      const {
        page,
        itemsPerPage: items,
        sort,
        filters,
      } = state.adherenceLast30Days;
      const { data } = await dataService.getList(
        apiDoctorAdherenceLast30Days(items, page, sort, filters),
      );
      if (data) {
        dispatch.adherenceLast30Days.setAdherenceReport(data?.data || []);
        const totalCount = data?.count || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.adherenceLast30Days.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
      }
    },
    async setPageAdherence({ pageNumber }) {
      dispatch.adherenceLast30Days.setPage(pageNumber);

      return dispatch.adherenceLast30Days.getAdherenceReport();
    },
    async toggleAdherence(_, state) {
      const { sort } = state.adherenceLast30Days;
      dispatch.adherenceLast30Days.toggleAdherenceSort(sort);

      return dispatch.adherenceLast30Days.getAdherenceReport();
    },
    async getAdherenceCsvReport(_, state) {
      const { filters } = state.adherenceLast30Days;
      const doctorGuids = filters?.doctorGuid?.split(',') || undefined;
      const cleanedDoctorGuids =
        doctorGuids && doctorGuids.filter((guid) => guid !== '');

      const postData = {
        ...filters,
        doctorGuid: undefined,
        doctorGuids: isEmpty(cleanedDoctorGuids) ? undefined : doctorGuids,
        isLast30DaysReport: true,
      };

      const { error, data } = await dataService.createOne(
        ADHERENCE_REPORT,
        postData,
      );
      return { error, data };
    },
    async setFilters(payload) {
      dispatch.adherenceLast30Days.SET_FILTERS(payload);
      return dispatch.adherenceLast30Days.getAdherenceReport();
    },
  }),
};

export default adherenceLast30Days;
