import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import common from 'constants/common';

import { calculateAverageData } from './utils';
import FooterInfoBlock from './footerInfoBlock';

const Footer = ({ data, durations }) => {
  const [averageData, setAverageData] = useState({});
  useEffect(() => {
    if (data && durations.length) {
      setAverageData(calculateAverageData(data, durations));
    }
  }, [data, durations]);

  return (
    <div className="sleep-mat--chart__footer">
      <span className="sleep-mat--chart__footer__title">
        Time Spent In Each Stage Of Sleep
      </span>
      <div className="sleep-mat--chart__footer__blocks--wrapper">
        <FooterInfoBlock
          title="Turn Over"
          data={averageData.bodyTurnOver}
          dataInBlock={averageData.bodyTurnOver}
          color={common.chartColors.sleepMat.turnOver}
        />
        <FooterInfoBlock
          title="REM"
          data={`${averageData.remDuration}`}
          dataInBlock={`${averageData.remPercent}%`}
          color={common.chartColors.sleepMat.rem}
        />
        <FooterInfoBlock
          title="Light"
          data={`${averageData.lightSleepDuration}`}
          dataInBlock={`${averageData.lightSleepPercent}%`}
          color={common.chartColors.sleepMat.lightSleep}
        />
        <FooterInfoBlock
          title="Deep"
          data={`${averageData.deepSleepDuration}`}
          dataInBlock={`${averageData.deepSleepPercent}%`}
          color={common.chartColors.sleepMat.deepSleep}
        />
        <FooterInfoBlock
          title="Respiration"
          data={averageData.respiration}
          dataInBlock={averageData.respiration}
          color={common.chartColors.sleepMat.respiration}
        />
      </div>
    </div>
  );
};

Footer.propTypes = {
  data: PropTypes.array,
  durations: PropTypes.array,
};

export default Footer;
