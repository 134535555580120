import React from 'react';
import PropTypes from 'prop-types';

import Countdown from './countdown';
import StopTimerButton from './stopTimerButton';

const SlaTimeRemainingRow = ({ activity }) => {
  if (!activity.slaDeadline) return null;

  return (
    <>
      <Countdown
        activity={activity}
        key={`${activity.id}-${activity.slaDeadline}`}
      />
      <StopTimerButton activity={activity} />
    </>
  );
};

SlaTimeRemainingRow.propTypes = {
  activity: PropTypes.object,
};

export default SlaTimeRemainingRow;
