import React, { useEffect, useMemo, useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import BaseSelect from 'components/molecules/select/base';
import BaseModal from 'components/atoms/base-modal';
import BaseButton from 'components/atoms/baseButton';
import BaseTag from 'components/atoms/base-tag';
import { copyObject } from 'infrastructure/functions/copy-object';
import { useClinicList } from 'infrastructure/hooks/clinic/use-clinic-list';

import s from './styles.module.scss';
import ClinicFilterForm from './form';

import type { ISelectedClinics } from './form';

const emptyState: ISelectedClinics = {
  excluded: [],
  included: [],
  allExcluded: undefined,
};

interface IProps {
  excluded?: string[];
  included?: string[];
  allExcluded?: boolean;
  onChange: (props: ISelectedClinics) => void;
  showOnInteractiveTimeDashboard?: boolean;
  useAdminApi?: boolean;
}

const ClinicFilterSelectBox: React.FC<IProps> = ({
  excluded = [],
  included = [],
  allExcluded = false,
  showOnInteractiveTimeDashboard,
  useAdminApi,
  onChange,
}) => {
  const { t } = useTranslation();
  const { clinics, loadClinics, meta } = useClinicList({
    meta: {
      items: 9999999,
      showOnInteractiveTimeDashboard,
      useAdminApi,
    },
  });

  const [opened, setOpened] = useState(false);
  const [selectedClinics, setSelectedClinics] = useState<ISelectedClinics>({
    excluded,
    included,
  });

  const options = useMemo(() => {
    return [
      {
        label: `${t('labels.included')} (${included.length})`,
        value: 'included',
      },
      {
        label: `${t('labels.excluded')} (${
          allExcluded ? clinics?.length : excluded.length
        })`,
        value: 'excluded',
      },
    ];
  }, [excluded.length, included.length, allExcluded, clinics?.length]);

  const values: any = useMemo(() => {
    const items = [];
    if (included.length) items.push('included');
    if (excluded.length) items.push('excluded');
    if (allExcluded) items.push('excluded');

    return items;
  }, [excluded.length, included.length, allExcluded]);

  const changeSelectedClinics = (val: ISelectedClinics) => {
    setSelectedClinics(val);
  };

  const onApply = () => {
    const filters = copyObject(selectedClinics);
    if (selectedClinics.excluded!.length >= selectedClinics.included!.length) {
      filters.excluded = [];
    }
    if (selectedClinics.excluded!.length < selectedClinics.included!.length) {
      filters.included = [];
    }

    if (
      selectedClinics.excluded!.length === 0 ||
      selectedClinics.included!.length === 0
    ) {
      filters.excluded = [];
      filters.included = [];
    }

    onChange(filters);
    setOpened(false);
  };

  const onClear = () => {
    setSelectedClinics(emptyState);
    onChange(emptyState);
  };

  useEffect(() => {
    setSelectedClinics({
      excluded,
      included,
    });
  }, [excluded.length, included.length]);

  useEffect(() => {
    if (allExcluded) loadClinics(meta);
  }, []);

  return (
    <>
      <BaseSelect
        label={t('labels.clinicsList')}
        placeholder={t('labels.all')}
        options={options}
        value={values}
        mode="multiple"
        allowClear
        onClick={() => setOpened(true)}
        dropdownRender={() => <></>}
        suffixIcon={<SettingOutlined />}
        open={false}
        tagRender={({ label }) => <BaseTag label={`${label}`} />}
        onClear={onClear}
        showSearch={false}
      />
      <BaseModal
        title={t('labels.clinicList')}
        open={opened}
        width="70%"
        outsideClose={false}
        bodyClassNames={s['modal-body']}
        withFooter={[
          <BaseButton
            key="cancel"
            label={t('controls.cancel')}
            type="secondary"
            onClick={() => setOpened(false)}
            className={s.button}
            dataCy="clinicList-cancel-button"
          />,
          <BaseButton
            key="apply"
            label={t('controls.apply')}
            onClick={onApply}
            className={s.button}
            dataCy="clinicList-apply-button"
          />,
        ]}
        onCancel={() => setOpened(false)}
      >
        <ClinicFilterForm
          excluded={excluded}
          included={included}
          allExcluded={allExcluded}
          onChange={changeSelectedClinics}
          useAdminApi={useAdminApi}
          showOnInteractiveTimeDashboard={showOnInteractiveTimeDashboard}
        />
      </BaseModal>
    </>
  );
};

export default ClinicFilterSelectBox;
