import i18next from 'i18next';
import { BloodPressureConsumables } from 'infrastructure/enums';

export interface IBloodPressureConsumablesItem {
  id: BloodPressureConsumables;
  name: string;
}

export const bloodPressureConsumablesDataSource =
  (): IBloodPressureConsumablesItem[] => {
    return [
      {
        id: BloodPressureConsumables.CUFF_SMALL_U807,
        name: i18next.t('consumables.CUFF_SMALL_U807'),
      },
      {
        id: BloodPressureConsumables.CUFF_ADULT_U807,
        name: i18next.t('consumables.CUFF_ADULT_U807'),
      },
      {
        id: BloodPressureConsumables.CUFF_XL_U807,
        name: i18next.t('consumables.CUFF_XL_U807'),
      },
      {
        id: BloodPressureConsumables.CUFF_SMALL_A600N,
        name: i18next.t('consumables.CUFF_SMALL_A600N'),
      },
      {
        id: BloodPressureConsumables.CUFF_ADULT_A600N,
        name: i18next.t('consumables.CUFF_ADULT_A600N'),
      },
      {
        id: BloodPressureConsumables.CUFF_XL_A600N,
        name: i18next.t('consumables.CUFF_XL_A600N'),
      },
      {
        id: BloodPressureConsumables.CUFF_SMALL_BP01,
        name: i18next.t('consumables.CUFF_SMALL_BP01'),
      },
      {
        id: BloodPressureConsumables.CUFF_ADULT_BP01,
        name: i18next.t('consumables.CUFF_ADULT_BP01'),
      },
      {
        id: BloodPressureConsumables.CUFF_XL_BP01,
        name: i18next.t('consumables.CUFF_XL_BP01'),
      },
    ];
  };
