import i18next from 'i18next';

export interface IBooleanItem {
  id: boolean;
  name: string;
}

export const booleansDataSource = (): IBooleanItem[] => {
  return [
    {
      id: true,
      name: i18next.t('controls.true'),
    },
    {
      id: false,
      name: i18next.t('controls.false'),
    },
  ];
};

export const optedDataSource = (): IBooleanItem[] => {
  return [
    {
      id: true,
      name: i18next.t('controls.optedIn'),
    },
    {
      id: false,
      name: i18next.t('controls.optedOut'),
    },
  ];
};
