import React from 'react';

const UploadIcon = (props: any) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.4934 20.9197L41.484 20.8822L36.2247 7.51816C35.9903 6.76348 35.2919 6.24316 34.4997 6.24316H13.1809C12.384 6.24316 11.6762 6.77285 11.4512 7.53691L6.53404 20.765L6.51998 20.7979L6.51061 20.8354C6.44967 21.065 6.43092 21.2994 6.46373 21.5291C6.45904 21.6041 6.45436 21.6791 6.45436 21.7541V38.9057C6.45559 39.6611 6.75626 40.3853 7.29047 40.9195C7.82468 41.4538 8.54887 41.7544 9.30436 41.7557H38.7044C40.2747 41.7557 41.5544 40.476 41.559 38.9057V21.7541C41.559 21.6932 41.559 21.6322 41.5544 21.5807C41.5731 21.351 41.5544 21.1307 41.4934 20.9197ZM27.6278 18.9041L27.6137 19.64C27.5762 21.7447 26.1231 23.1604 23.9997 23.1604C22.9637 23.1604 22.0731 22.8275 21.4309 22.1947C20.7887 21.5619 20.4372 20.6807 20.4184 19.64L20.4044 18.9041H10.7575L14.484 9.84316H33.1965L37.0262 18.9041H27.6278ZM10.0497 22.5041H17.4231C18.5622 25.1807 20.9856 26.7604 24.0044 26.7604C25.584 26.7604 27.0512 26.3197 28.2372 25.4853C29.2778 24.7541 30.0887 23.7322 30.6137 22.5041H37.9497V38.1557H10.0497V22.5041Z"
      fill="#FB9318"
      {...props}
    />
  </svg>
);

export default UploadIcon;
