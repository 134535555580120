import { applyFields } from './apply-fields';

import type React from 'react';
import type { IIndependentFormRef } from 'infrastructure/interfaces/common/i-independent-form';

export const combineFormResults = async <T>(
  forms: React.RefObject<IIndependentFormRef<any>>[],
  withEmpty = false,
): Promise<T> => {
  const res = forms
    .map((el) => {
      if (el.current) {
        return el.current.submit();
      }
      return false;
    })
    .filter((el) => el !== false);

  const responses = await Promise.all(res);

  let results: any = {};
  const notValidForms: string[] = [];

  responses.forEach((el) => {
    if (!el.isValid) notValidForms.push(el.formName);
    results = applyFields(results, el.values, withEmpty);
  });

  if (notValidForms.length) {
    return Promise.reject(
      new Error(`Not valid forms ${notValidForms.join(' , ')}`),
    );
  }

  return Promise.resolve(results);
};
