import React, { useEffect } from 'react';
import BaseContainer from 'components/atoms/base-container';
import AdminPanelPatientDetailTabs from 'components/organisms/admin-panel/patient-detail/tabbed-forms';
import { useNavigate, useParams } from 'react-router-dom';
import BaseGrid from 'components/atoms/base-grid';
import GeneralComments from 'components/molecules/general-comments';
import { HttpStatus, ModuleType } from 'infrastructure/enums';
import AdminPatientKeyNotes from 'components/organisms/adminPanelKeyNotes';
import AdminPatientControlPanel from 'components/organisms/admin-control-panel';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { CustomError } from 'infrastructure/classes/custom-error';
import { isAdmin, isAgencyDoctor } from 'utils/userTypeHelper';
import useUser from 'utils/useUser';
import paths from 'constants/paths';
import { useTranslation } from 'react-i18next';
import PatientTopBanner from 'components/molecules/patientTopBanner';
import MiniNav from 'components/molecules/mini-nav';
import AdminMiniNav from 'components/molecules/admin-mini-nav';
import { useWebsocketRoom } from 'infrastructure/hooks/websockets/use-room';
import { BroadcastTopics } from 'services/wsService';
import { useSelector } from 'react-redux';
import { useQueryUpdatePatientStatus } from 'infrastructure/hooks/patient/use-query-update-patient-status';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import { isValidGuid } from 'infrastructure/functions/is-valid-guid';
import { showResult } from 'infrastructure/hooks/utils';

import s from './styles.module.scss';

import type { RootState } from 'store';

const AdminPatientDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const { userType } = useUser();
  const { patientGuid: _patientGuid } = useParams<{ patientGuid: string }>();
  const patientGuid = _patientGuid!;
  const { patientInfo, error } = usePatientInfo({ patientGuid });
  const { t } = useTranslation();
  useEffect(() => {
    if (!patientGuid || !isValidGuid(patientGuid)) {
      showResult(t('errors.invalidGuid'));
      navigate('/404', { replace: true });
    }
  }, [patientGuid, navigate]);
  if (!patientGuid || !isValidGuid(patientGuid)) {
    return null;
  }
  const socket = useSelector((state: RootState) => state.socket);
  const { updateQueryPatientStatus } = useQueryUpdatePatientStatus({
    patientGuid,
  });

  if (error) {
    if (
      CustomError.isCustomError(error) &&
      error.code === HttpStatus.NOT_FOUND
    ) {
      navigate('/404', { replace: true });
    }
  }

  useWebsocketRoom('rpmPatientProfile', patientGuid);
  useEffect(() => {
    if (socket.isConnected === true && socket.client) {
      socket.client.on(BroadcastTopics.PatientStatusChange, (data: any) => {
        updateQueryPatientStatus(data);
      });
    }

    return () => {
      socket.client?.off(BroadcastTopics.PatientStatusChange);
    };
  }, [socket]);

  return (
    <BaseContainer className={s.wrapper}>
      <MiniNav module={ModuleType.ADMIN} />
      <AdminMiniNav />
      <AdminNavigationHeader
        goBackPath={paths.adminPatients}
        title={t('labels.adminPatientProfileTitle')}
      />
      <BaseGrid columns={1} rowGap={80}>
        <PatientTopBanner patientGuid={patientGuid} module={ModuleType.ADMIN} />
        {(isAdmin(userType) || isAgencyDoctor(userType)) && (
          <AdminPatientKeyNotes patientGuid={patientGuid} />
        )}
        <AdminPatientControlPanel patientGuid={patientGuid} />
        <div id="user-profile">
          <AdminPanelPatientDetailTabs
            patientGuid={patientGuid}
            module={ModuleType.ADMIN}
          />
        </div>
        <div id="general-comments">
          <BaseGrid columns={2}>
            <GeneralComments
              patientGuid={patientGuid}
              module={ModuleType.RPM}
              interactionStatus={patientInfo?.interactionStatus ?? false}
            />
            <GeneralComments
              patientGuid={patientGuid}
              module={ModuleType.CCM}
            />
          </BaseGrid>
        </div>
      </BaseGrid>
    </BaseContainer>
  );
};

export default AdminPatientDetailsPage;
