import image from '../../../../../assets/img/accuhealth.png';

const HeaderConsent = () => (
  <div>
    <div className="logo-wrapper">
      <img className="logo" src={image} alt="accuhealth" />
    </div>
    <div className="title-wrapper">
      <p className="title">Chronic Care Management</p>
      <p className="subtitle">Consent Form / Formulario de consentimiento</p>
    </div>
  </div>
);

export default HeaderConsent;
