import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import debounce from 'lodash/debounce';
import dataService from 'services/dataService';
import List from 'components/atoms/list';
import classNames from 'classnames';
import useOutsideClick from 'utils/useOutsideClick';
import DropdownClosedIcon from 'components/atoms/icons/dropdownClosedIcon';
import DropdownOpenIcon from 'components/atoms/icons/dropdownOpenIcon';

const loadingOptions = [{ id: 'loading', label: 'Loading...' }];
let timer;

const PatientAutocomplete = ({
  filter,
  handleClick,
  showLabel,
  patient,
  dropDownIcon,
  status,
  disabled,
}) => {
  const [query, setQuery] = useState('');
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useOutsideClick(ref, () => {
    setQuery('');
    setShow(false);
  });

  const handleOptionClick = (option) => {
    setQuery('');
    setShow(false);
    return handleClick(option);
  };

  const generateFilterLabel = (item) =>
    `${item.firstName} ${item.lastName} - ${item.birthDate}`;

  const debouncedSearch = debounce(async (string) => {
    const params = new URLSearchParams({
      status: status || '',
      [filter.id]: string,
      patientRequestType: 'search',
      items: 100,
    });
    const { data } = await dataService.getList(
      `core/patients?${params.toString()}`,
    );
    setIsLoading(false);
    if (data) {
      setOptions(
        data.items.map((item) => ({
          id: item.guid,
          value: item.guid,
          label: generateFilterLabel(item),
        })),
      );
    }
  }, 800);

  const handleInputChange = (string) => {
    if (string.target) {
      setQuery(string.target.value);
      setIsLoading(true);
      clearTimeout(timer);
      timer = setTimeout(() => {
        debouncedSearch(string.target ? string.target.value : query);
      }, 500);
    }
  };

  const handleFocus = () => {
    if (filter.id === 'fullName') {
      setShow(true);
    }
  };

  const autoCompleteClass = () => {
    let className = 'card-border';
    if (showLabel) {
      className = 'card-border card-shadow border-0';
    }
    return className;
  };
  const dropdownIcon = () => {
    if (dropDownIcon) {
      return show ? <DropdownOpenIcon /> : <DropdownClosedIcon />;
    }
  };

  return (
    <div
      className="patient-filter-autocomplete"
      ref={ref}
      onClick={handleFocus}
    >
      <div className="dropdown" role="button" aria-hidden>
        <Card className={autoCompleteClass()}>
          <CardBody
            className={classNames({
              'card-body-sort': !patient,
            })}
          >
            {showLabel && <p>{filter.label}</p>}
            <div className="dropdown-body">
              <input
                className={dropDownIcon ? 'arrow' : ''}
                placeholder={filter.placeholder}
                type="text"
                onChange={handleInputChange}
                value={query}
                disabled={disabled}
              />
              {dropdownIcon()}
            </div>
          </CardBody>
        </Card>
      </div>
      {((query.length > 0 && isLoading) || (show && isLoading)) && (
        <div className="patient-filter-dropdown-body">
          <List onChange={() => {}} options={loadingOptions} />
        </div>
      )}
      {((query.length > 0 && !isLoading && options && options.length > 0) ||
        (!isLoading && options && options.length > 0 && show)) && (
        <div
          className="patient-filter-dropdown-body"
          data-cy="patient-filter-results-dropdown"
        >
          <List
            onChange={handleOptionClick}
            options={options}
            dataCy="patient-results"
          />
        </div>
      )}
    </div>
  );
};

PatientAutocomplete.propTypes = {
  filter: PropTypes.shape().isRequired,
  handleClick: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  patient: PropTypes.bool,
  dropDownIcon: PropTypes.bool,
  status: PropTypes.string,
  disabled: PropTypes.bool,
};

export default PatientAutocomplete;
