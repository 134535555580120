import React, { useMemo, useRef, useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import BaseSelect from 'components/molecules/select/base';
import BaseModal from 'components/atoms/base-modal';
import BaseButton from 'components/atoms/baseButton';
import BaseTag from 'components/atoms/base-tag';
import { weekDaysDataSource } from 'infrastructure/data-sources/week-days';
import { convertTime } from 'infrastructure/functions';

import ContactAvailabilityFilterBody from './body';
import s from './styles.module.scss';

import type { IContactAvailabilityFilterBodyRef } from './body';
import type { IAvailableDay } from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface IProps {
  value?: IAvailableDay[];
  onChange: (times: IAvailableDay[]) => void;
}

const ContactAvailabilityFilterSelectBox: React.FC<IProps> = ({
  value = [],
  onChange,
}) => {
  const { t } = useTranslation();
  const [times, setTimes] = useState<IAvailableDay[]>(value);
  const [opened, setOpened] = useState(false);
  const ref = useRef<IContactAvailabilityFilterBodyRef>(null);

  const weekDays = useMemo(
    () =>
      weekDaysDataSource().map((el, index) => ({
        label: el.name,
        value: index,
      })),
    [],
  );

  const values = useMemo(
    () =>
      value.map((el) => {
        return {
          label: `${el.name} 
          ${convertTime(el.startTime)} - ${convertTime(el.endTime)}`,
          value: el.day,
        };
      }),
    [value],
  );

  const onApply = () => {
    if (ref.current?.validate()) {
      setOpened(false);
      onChange?.(times);
    }
  };

  const onClear = () => {
    setTimes([]);
    onChange?.([]);
  };

  return (
    <>
      <BaseSelect
        label={t('labels.contactAvailability')}
        placeholder={t('labels.all')}
        options={weekDays}
        value={values}
        mode="multiple"
        allowClear
        onClick={() => setOpened(true)}
        dropdownRender={() => <></>}
        suffixIcon={<SettingOutlined />}
        open={false}
        tagRender={({ label }) => <BaseTag label={`${label}`} />}
        onClear={onClear}
        showSearch={false}
      />
      <BaseModal
        title={t('labels.contactAvailability')}
        open={opened}
        withFooter={[
          <BaseButton
            key="cancel"
            label={t('controls.cancel')}
            type="secondary"
            onClick={() => setOpened(false)}
            className={s.button}
          />,
          <BaseButton
            key="apply"
            label={t('controls.apply')}
            onClick={onApply}
            className={s.button}
          />,
        ]}
        onCancel={() => setOpened(false)}
      >
        <ContactAvailabilityFilterBody
          ref={ref}
          value={value}
          onChange={setTimes}
        />
      </BaseModal>
    </>
  );
};

export default ContactAvailabilityFilterSelectBox;
