import i18next from 'i18next';
import { WorkingStatuses } from 'infrastructure/enums';

export interface IWorkingStatusItem {
  id: WorkingStatuses;
  name: string;
}

export const workingStatusesDataSource = (): IWorkingStatusItem[] => {
  return [
    {
      id: WorkingStatuses.PermanentEmployee,
      name: i18next.t('workingStatuses.permanent_employee'),
    },
    {
      id: WorkingStatuses.TemporaryEmployee,
      name: i18next.t('workingStatuses.temporary_employee'),
    },
    {
      id: WorkingStatuses.SelfEmployed,
      name: i18next.t('workingStatuses.self_employed'),
    },
    {
      id: WorkingStatuses.Retired,
      name: i18next.t('workingStatuses.retired'),
    },
    {
      id: WorkingStatuses.Internship,
      name: i18next.t('workingStatuses.internship'),
    },
    {
      id: WorkingStatuses.Freelance,
      name: i18next.t('workingStatuses.freelance'),
    },
    {
      id: WorkingStatuses.Unemployed,
      name: i18next.t('workingStatuses.unemployed'),
    },
  ];
};
