import type { SVGProps } from 'react';

const BucketIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7905 8.98291V9.60791V11.3657L12.2968 10.4874L12.8367 10.1726L13.457 11.2578L12.9171 11.5726L11.4087 12.4522L12.9163 13.3313L13.4562 13.6461L12.8359 14.7313L12.2959 14.4165L10.7905 13.5387V15.2956V15.9206H9.54052V15.2956V13.5415L8.03999 14.4165L7.50008 14.7313L6.87972 13.6461L7.41964 13.3313L8.92725 12.4522L7.41882 11.5726L6.87891 11.2578L7.49926 10.1726L8.03918 10.4874L9.54052 11.3629V9.60791V8.98291H10.7905Z"
      fill="#667180"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 0.139526C3.05964 0.139526 2.5 0.699171 2.5 1.38953V3.88953C2.5 4.57988 3.05964 5.13953 3.75 5.13953L3.75 18.2645C3.75 19.3001 4.58947 20.1395 5.625 20.1395H14.375C15.4105 20.1395 16.25 19.3001 16.25 18.2645V5.13953C16.9404 5.13953 17.5 4.57988 17.5 3.88953V1.38953C17.5 0.69917 16.9404 0.139526 16.25 0.139526H3.75ZM15 5.13953H5V18.2645C5 18.6097 5.27982 18.8895 5.625 18.8895H14.375C14.7202 18.8895 15 18.6097 15 18.2645V5.13953ZM3.75 3.88953H4.375H15.625H16.25V1.38953H3.75V3.88953Z"
      fill="#667180"
    />
  </svg>
);

export default BucketIcon;
