import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { useAppDispatch, useAppSelector } from 'store';
import useUser from 'utils/useUser';
import { showResult } from 'infrastructure/hooks/utils';

import { apiUrlGenerator } from '../../use-api';

import type { IPartialPaginationMeta } from 'infrastructure/interfaces';
import type { IRpmPatientActivity } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface Props {
  patientGuid: string;
  clinicGuid?: string;
  reviewed: boolean;
  deviceIds: string;
  reverse?: boolean;
  meta: IPartialPaginationMeta;
}

export type TActivityFilter = {
  patientGuid: string;
  clinicGuid?: string;
  startTime: number;
  endTime: number;
  reviewed: boolean;
  deviceIds: string;
  userType: string;
  reverse?: boolean;
} & IPartialPaginationMeta;

export type TRpmPatientActivityQueryKey = [string, TActivityFilter];

export const ACTIVITY_PER_PAGE = 100;

const getRpmPatientActivity = async ({
  patientGuid,
  ...filter
}: TActivityFilter) => {
  const url = `core/patients/${patientGuid}/readings`;
  const { data, error } = await dataService.getList<IRpmPatientActivity>(
    apiUrlGenerator(url, filter),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useRpmPatientActivityKey = (
  filter: TActivityFilter,
): TRpmPatientActivityQueryKey => ['getRpmPatientActivity', filter];

export const useRpmPatientActivity = ({
  patientGuid,
  clinicGuid,
  deviceIds,
  reviewed,
  reverse,
  meta,
}: Props) => {
  const { userType } = useUser();
  const [startTime, endTime] = useAppSelector<[number, number]>(
    (state) => state.patient.dates,
  );

  const {
    activity: { setActivities, setTotalCountAndTotalPages },
  } = useAppDispatch();

  const filter: TActivityFilter = {
    ...meta,
    patientGuid,
    clinicGuid,
    startTime,
    endTime,
    reviewed,
    deviceIds,
    userType,
    reverse,
    items: ACTIVITY_PER_PAGE,
  };

  const queryKey = useRpmPatientActivityKey(filter);

  const { data: activity, ...other } = useQuery({
    queryKey,
    queryFn: async () => getRpmPatientActivity(filter),
    enabled: Boolean(patientGuid) && Boolean(clinicGuid),
  });

  setActivities(activity?.data || []);
  const totalCount = activity?.count || 0;
  const totalPages =
    totalCount === 0 ? 0 : Math.ceil(totalCount / ACTIVITY_PER_PAGE);
  setTotalCountAndTotalPages({
    totalCount,
    totalPages,
  });

  return {
    activity,
    queryKey,
    ...other,
  };
};
