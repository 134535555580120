import ClockIcon from 'components/atoms/icons/clock.svg?react';

import useSlaTimer from './use-sla-timer';
import s from './styles.module.scss';

import type { FC } from 'react';

type Props = {
  slaDeadline: string;
  slaReviewedTime?: string;
  serviceDeliveryThresholds: number;
};

const Countdown: FC<Props> = ({ ...activity }) => {
  const { backgroundColor, color, countdown } = useSlaTimer(activity);
  return (
    <p
      data-cy="reading-priority-countdown"
      className={s.wrapper}
      style={{
        color,
        backgroundColor,
      }}
    >
      <ClockIcon /> {countdown}
    </p>
  );
};

export default Countdown;
