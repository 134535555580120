import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'reactstrap';
import ArrowUpIcon from 'components/atoms/icons/arrowUpIcon';
import ArrowDownIcon from 'components/atoms/icons/arrowDownIcon';

import s from './styles.module.scss';

import type { SortState } from 'infrastructure/types';
import type { IBaseTableColumnProps } from '../../types';

interface IBaseTableHeaderSortableColumnProps extends IBaseTableColumnProps {
  onClick?: (sortState: SortState) => void;
}

const BaseTableHeaderSortableColumn: React.FC<
  IBaseTableHeaderSortableColumnProps
> = (props) => {
  const { label, onClick } = props;
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string>(
    t('controls.clickToSortingASC'),
  );
  const [sortState, setSortState] = useState<SortState>('');
  const iconClassNames = classNames(s.icon, s[sortState]);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const ref = useRef(null);

  const getNextSort = () => {
    let sort: SortState = 'DESC';
    if (sortState === '') {
      sort = 'ASC';
      setTooltipText(t('controls.clickToSortingDESC'));
    }
    if (sortState === 'ASC') {
      sort = 'DESC';
      setTooltipText(t('controls.clickToCancelSorting'));
    }
    if (sortState === 'DESC') {
      sort = '';
      setTooltipText(t('controls.clickToSortingASC'));
    }
    setSortState(sort);
    if (onClick) onClick(sort);
  };

  return (
    <div>
      <button ref={ref} className={s.sort} onClick={getNextSort}>
        <span>{label}</span>
        <i className={iconClassNames}>
          <i className={s.up}>
            <ArrowUpIcon />
          </i>
          <i className={s.down}>
            <ArrowDownIcon />
          </i>
        </i>
      </button>
      <Tooltip
        target={ref}
        isOpen={tooltipOpen}
        toggle={toggle}
        autohide
        placement="top"
      >
        {tooltipText}
      </Tooltip>
    </div>
  );
};

export default BaseTableHeaderSortableColumn;
