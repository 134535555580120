import { useState } from 'react';
import { Link } from 'react-router-dom';
import ResetPasswordIcon from 'components/atoms/icons/resetPasswordIcon';
import ResetPasswordModal from 'components/organisms/resetPassword';
import { capitalize } from 'lodash';

import type { AgencyDoctor } from 'utils/hooks/adminPanel/useAgencyDoctors';
import type { FC } from 'react';

interface Props {
  agencyDoctor: AgencyDoctor;
}

const Row: FC<Props> = ({ agencyDoctor }) => {
  const [resetPasswordPopupOpen, setResetPasswordPopupOpen] = useState(false);

  return (
    <>
      <ResetPasswordModal
        isOpen={resetPasswordPopupOpen}
        toggle={setResetPasswordPopupOpen}
        email={agencyDoctor.email}
      />
      <tr>
        <td data-label="Full Name">
          <div className="adminPanel__row">
            <Link
              data-cy="admin-panel__agency-user-link"
              to={`/admin/turk-users/${agencyDoctor.guid}`}
            >
              <strong>{`${agencyDoctor.title} ${agencyDoctor.firstName} ${agencyDoctor.lastName}`}</strong>
            </Link>
          </div>
        </td>
        <td data-label="Agency">
          <div className="adminPanel__row adminPanel__row--scroll">
            {agencyDoctor.agencies.join(', ')}
          </div>
        </td>
        <td data-label="Email">
          <div className="adminPanel__row">{agencyDoctor.email}</div>
        </td>
        <td data-label="Reset Email">
          <div className="button-wrapper">
            <button
              className="adminPanel__row resend-button"
              onClick={() => {
                setResetPasswordPopupOpen(true);
              }}
            >
              <ResetPasswordIcon />
            </button>
          </div>
        </td>
        <td data-label="Status">
          <div className="adminPanel__row adminPanel__row--status">
            {capitalize(agencyDoctor.status)}
          </div>
        </td>
      </tr>
    </>
  );
};

export default Row;
