import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/atoms/button';
import { scrollToErrorIfExist } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';
import { Formik, Form } from 'formik';
import { Card, CardBody, Table } from 'reactstrap';
import Info from 'components/organisms/adminPanels/turkUser/components/info';
import Registration from 'components/organisms/adminPanels/turkUser/components/registration';
import Profession from 'components/organisms/adminPanels/turkUser/components/profession';
import turkUserSchema from 'components/organisms/adminPanels/turkUser/validationSchema';
import useAgencies from 'utils/hooks/adminPanel/useAgencies';
import turkUserRequestGenerator from 'components/organisms/adminPanels/turkUser/requestBodyGenerator';
import dataService from 'services/dataService';
import { API_ADMIN_PANEL_AGENCY_DOCTORS } from 'services/dataService/resources';
import { useState } from 'react';
import useAgencyDoctor from 'utils/hooks/adminPanel/useAgencyDoctor';
import withLoader from 'components/organisms/withLoader';
import AdminPanelResetEmailButton from 'components/molecules/adminPanelResetEmailButton';

import ActiveStatusButton from '../userEdit/components/activeStatusButton';

import type { TurkUserSchema } from 'components/organisms/adminPanels/turkUser/validationSchema';

const AdminTurkUserEdit = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const { agentGuid } = useParams<{ agentGuid: string }>();

  const { agencies, isLoading: agenciesLoading } = useAgencies({
    noLimit: true,
  });
  const {
    agencyDoctor,
    isLoading: agencyDoctorLoading,
    disableAndRemoveCache,
  } = useAgencyDoctor({
    guid: agentGuid!,
  });

  const submitHandler = async (doctorRequest: TurkUserSchema) => {
    const turkRoleGuid = agencyDoctor?.roles[0].guid;
    if (!turkRoleGuid) {
      showResult(
        'Technical error occurred. Please refresh the page and try again.',
      );
      return;
    }
    const body = turkUserRequestGenerator(doctorRequest, {
      roleGuid: turkRoleGuid,
    });
    setSubmitLoading(true);
    const { error, data } = await dataService.updateOnly(
      API_ADMIN_PANEL_AGENCY_DOCTORS,
      body,
    );
    setSubmitLoading(false);

    if (error) {
      showResult(error);
      return;
    }
    disableAndRemoveCache();
    showResult();
    if (data.guid) {
      navigate(`/admin/turk-users/${data.guid}`);
    } else {
      navigate('/admin/turk-users');
    }
  };

  return (
    <div className="admin-agent">
      {withLoader(
        agencyDoctorLoading || agenciesLoading,
        agencyDoctor && (
          <Card className="card-border">
            <CardBody>
              <Formik
                onSubmit={submitHandler}
                validationSchema={turkUserSchema}
                initialValues={{
                  // @ts-ignore
                  agencies: agencyDoctor.agencies.map(
                    ({ guid, legalname }) => ({
                      value: guid,
                      label: legalname,
                    }),
                  ),
                  email: agencyDoctor.email,
                  firstName: agencyDoctor.firstName,
                  lastName: agencyDoctor.lastName,
                  ratePerReading: agencyDoctor.ratePerReading,
                  title: agencyDoctor.title,
                  guid: agencyDoctor.guid,
                  middleName: agencyDoctor.middleName,
                  professionType: agencyDoctor.professionType || '',
                  primaryLanguage: agencyDoctor.primaryLanguage,
                  secondaryLanguage: agencyDoctor.secondaryLanguage,
                  timezone: agencyDoctor.timezone,
                  createdAt: agencyDoctor.createdAt,
                  status: agencyDoctor.status,
                }}
              >
                {({ errors, values }) => (
                  <Form>
                    <div className="admin-agent-header admin-user-edit__header__wrapper">
                      <span className="admin-header__title">
                        Edit Turk User Page
                      </span>
                      <div className="admin-agent-header-actions">
                        <div className="admin-patient-header-actions-call">
                          <AdminPanelResetEmailButton
                            email={agencyDoctor.email}
                          />
                        </div>
                        <div className="admin-agent-header-actions-status">
                          <ActiveStatusButton status={values.status} />
                        </div>
                      </div>
                    </div>
                    <div className="admin-agent-edit-form">
                      <Table>
                        <tbody>
                          <tr>
                            <td rowSpan={2}>
                              <Info errors={errors} />
                            </td>
                            <td>
                              <Profession
                                agencies={agencies}
                                errors={errors}
                                agenciesLoading={agenciesLoading}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Registration />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className="text-center">
                      <Button
                        disabled={
                          submitLoading || agenciesLoading || agenciesLoading
                        }
                        type="submit"
                        text="Save"
                        handleClick={scrollToErrorIfExist}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        ),
      )}
    </div>
  );
};

export default AdminTurkUserEdit;
