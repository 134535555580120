import BaseDatePicker from 'components/atoms/datePicker';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { FC } from 'react';

interface Value {
  year: number;
  month: number;
}

interface Props {
  value: Value;
  onChange: (value: Value | null) => void;
}

const MonthPicker: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <div style={{ width: '100%' }}>
      <label className={s.label}>{t('labels.timeRange')}</label>
      <BaseDatePicker
        style={{ width: '100%' }}
        value={new Date(value.year, value.month - 1)}
        onChange={(newValue) => {
          if (!newValue) {
            onChange(null);
            return;
          }
          const date = newValue;
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          onChange({ year, month });
        }}
        picker="month"
      />
    </div>
  );
};

export default MonthPicker;
