import RadioButton from 'components/atoms/radioButton';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const RadioInput = ({
  id,
  title,
  setValue,
  values,
  isRequired,
  requiredIf,
  disabled,
  hasOtherOption,
  renderOtherOptionFirst,
  otherOptionLabel = 'Other: ',
  canEditPage,
  choices,
  noteOption,
  helpText,
}) => {
  let required = isRequired;
  if (requiredIf) {
    required = isRequired || values[requiredIf.key] === requiredIf.value;
  }
  const otherInputValue =
    hasOtherOption && choices.find((choice) => choice === values[id])
      ? ''
      : values[id];
  const OtherOption = hasOtherOption && (
    <div
      className={classnames('other-input-wrapper', {
        'pd-b-10': renderOtherOptionFirst,
      })}
    >
      <label htmlFor={id}>{otherOptionLabel}</label>
      <input
        id={id}
        className="form-control"
        type="text"
        autoComplete="off"
        disabled={disabled}
        value={otherInputValue || ''}
        onChange={(e) => {
          setValue({ [id]: e.target.value });
        }}
      />
    </div>
  );

  const NoteOption = noteOption && (
    <div>
      <label htmlFor={id}>{noteOption.label}</label>
      <input
        id={id}
        className="form-control"
        type="text"
        autoComplete="off"
        disabled={disabled}
        value={values[noteOption.id]}
        onChange={(e) => {
          setValue({ [noteOption.id]: e.target.value });
        }}
      />
    </div>
  );
  return (
    <div className="input-wrapper" data-cy={id}>
      <label htmlFor={id}>
        {title} {required && <span className="color-red">*</span>}
      </label>
      {helpText && <p className="help-text">{helpText}</p>}
      {renderOtherOptionFirst && OtherOption}
      {choices.map((choice) => (
        <RadioButton
          id={`${id}${choice}`}
          label={choice}
          isChecked={values[id] === choice}
          onChange={() => {}}
          onClick={() => {
            if (values[id] === choice) {
              setValue({ [id]: undefined });
            } else {
              setValue({ [id]: choice });
            }
          }}
          key={`${id}${choice}`}
          name={`${id}${choice}`}
          disabled={disabled}
          canEditPage={canEditPage}
        />
      ))}
      {!renderOtherOptionFirst && OtherOption}
      {NoteOption}
    </div>
  );
};

RadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  helpText: PropTypes.string,
  choices: PropTypes.array,
  setValue: PropTypes.func,
  isRequired: PropTypes.bool,
  requiredIf: PropTypes.object,
  hasOtherOption: PropTypes.bool,
  renderOtherOptionFirst: PropTypes.bool,
  otherOptionLabel: PropTypes.string,
  noteOption: PropTypes.object,
  disabled: PropTypes.bool,
  canEditPage: PropTypes.bool,
  values: PropTypes.shape(),
};

export default RadioInput;
