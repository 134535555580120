import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';

interface ICustomLocationPromptProps {
  when: boolean;
  callback: () => void;
  message?: string;
  messageTitle?: string;
}

const CustomLocationPrompt: FC<ICustomLocationPromptProps> = ({
  when,
  message,
  messageTitle,
  callback,
}) => {
  const { Alert, showAlert } = useAlert();
  const { t } = useTranslation();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      when &&
      (currentLocation.pathname !== nextLocation.pathname ||
        currentLocation.search !== nextLocation.search),
  );

  useEffect(() => {
    const run = async () => {
      if (blocker.state !== 'blocked') {
        return;
      }
      const res = await showAlert({
        title: t('confirm.title'),
        messageTitle: messageTitle ?? t('messages.confirmUnsavedChangesTitle'),
        message: message ?? t('messages.confirmUnsavedChangesMessage'),
        successBtnLabel: t('controls.yes'),
        rejectBtnLabel: t('controls.no'),
      });
      if (res) {
        callback?.();
        blocker.proceed?.();
      } else {
        blocker.reset?.();
      }
    };
    run();
  }, [blocker.state]);

  return Alert;
};

export default CustomLocationPrompt;
