import BaseIconButton from 'components/atoms/base-icon-button';
import ExpandIcon from 'components/atoms/icons/activity-mob-expand-comment.svg?react';
import { useState, type FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import CommentsColumn from '.';
import s from './styles.module.scss';

import type { IActivityComments } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface ICommentsColumnMobileProps {
  comments: IActivityComments[];
  patientGuid: string;
}

const CommentsColumnMobile: FC<ICommentsColumnMobileProps> = ({
  comments,
  patientGuid,
}) => {
  const [expand, setExpand] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={s['comments-wrapper-mob']}>
      <div className={s['comments-mob-header']}>
        <p>{t('labels.comments')}</p>
        <div className={s['expand-btn-wrapper']}>
          <p>{expand ? t('labels.hide') : t('labels.show')}</p>
          <BaseIconButton
            icon={
              <ExpandIcon
                width={24}
                height={24}
                className={classNames(s['expand-icon'], {
                  [s.rotate]: !expand,
                })}
              />
            }
            onClick={() => setExpand((prev) => !prev)}
          />
        </div>
      </div>
      <div
        className={classNames(s['comment-mob-body'], {
          [s.expand]: expand,
        })}
      >
        <CommentsColumn comments={comments} patientGuid={patientGuid} />
      </div>
    </div>
  );
};

export default CommentsColumnMobile;
