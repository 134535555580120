import dataService from 'services/dataService';
import {
  apiAdminPanelPatients,
  apiPatientDetails,
  apiPatientDetailsAdmin,
  API_ADMIN_PANEL_PATIENT,
  apiGetDoctors,
  apiAdminPanelPatientEdit,
  API_ADMIN_PANEL_AGENCY_DOCTORS,
  API_PATIENTS_INTERACTION_STATUS,
  API_PATIENTS_PVR_STATE,
  apiCcmInteractionStatus,
  API_PATIENTS_DO_NOT_CALL,
  apiPatientInternalAdmissionForm,
  apiPatientInternalAdmissionFormFile,
  apiPatientEmailSubscriptionToggle,
  API_PATIENTS_NUTRITION_STATUS,
} from 'services/dataService/resources';

const ITEMS_PER_PAGE = 100;
const INITIAL_STATE = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  filters: {
    status: null,
    clinicGuid: null,
    patientGuid: null,
  },
  data: [],
  ccmData: null,
  totalPages: 0,
  totalCount: 0,
  patient: {
    careAgents: [],
  },
  doctors: [],
  careAgentOptions: {
    hhaManagers: [],
    ccmManagers: [],
  },
};

const adminPanelPatients = {
  state: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    setPage: (state, payload) => ({
      ...state,
      page: payload < 1 ? 1 : payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    setPatients: (state, payload) => ({
      ...state,
      data: payload,
    }),
    setFilters: (state, payload) => ({
      ...state,
      filters: { ...state.filters, ...payload },
    }),
    clearFilters: (state) => ({
      ...state,
      filters: { status: null, clinicGuid: null, patientGuid: null },
    }),
    setPatient: (state, payload) => ({
      ...state,
      patient: payload,
    }),
    setDoctors: (state, payload) => ({
      ...state,
      doctors: payload,
    }),
    setCareAgents: (state, payload) => ({
      ...state,
      careAgentOptions: { ...state.careAgentOptions, ...payload },
    }),
    setCovidStatus(state, covidStatus) {
      return {
        ...state,
        patient: {
          ...state.patient,
          profile: {
            ...state.patient.profile,
            covidStatus,
          },
        },
      };
    },
    setCcmData(state, payload) {
      return {
        ...state,
        patient: {
          ...state.patient,
          profile: {
            ...state.patient.profile,
            ...payload,
          },
        },
      };
    },
    setPatientStatus(state, payload) {
      return {
        ...state,
        patient: {
          ...state.patient,
          profile: {
            ...state.patient.profile,
            status: payload,
          },
        },
      };
    },
    setPatientCcmStatus(state, payload) {
      return {
        ...state,
        patient: {
          ...state.patient,
          profile: {
            ...state.patient.profile,
            ccmStatus: payload,
          },
        },
      };
    },
    setPatientPcmStatus(state, payload) {
      return {
        ...state,
        patient: {
          ...state.patient,
          profile: {
            ...state.patient.profile,
            pcmStatus: payload,
          },
        },
      };
    },
    setPatientAppointmentDetails(state, payload) {
      return {
        ...state,
        patient: {
          ...state.patient,
          profile: {
            ...state.patient.profile,
            appointmentDetails: payload,
          },
        },
      };
    },
    updatePatientField(state, payload) {
      const { data, field } = payload;
      if (!field) return;
      return {
        ...state,
        patient: {
          ...state.patient,
          profile: {
            ...state.patient.profile,
            [field]: data,
          },
        },
      };
    },
  },
  effects: (dispatch) => ({
    async sort({ value, desc }, state) {
      let data = [...state.adminPanelPatients.data];
      data = data.sort((a, b) => {
        if (desc) {
          return a[value] > b[value] ? 1 : -1;
        }
        return b[value] > a[value] ? 1 : -1;
      });
      dispatch.adminPanelPatients.setPatients(data);
    },
    async getPatients({ items = ITEMS_PER_PAGE, signal }, state) {
      const { filters } = state.adminPanelPatients;
      const { page } = state.adminPanelPatients;
      const { data } = await dataService.getList(
        apiAdminPanelPatients(items, page, filters),
        { signal },
      );
      if (data) {
        dispatch.adminPanelPatients.setPatients(data?.items || []);
        const totalCount = data?.count || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.adminPanelPatients.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
      } else {
        dispatch.adminPanelPatients.setPatients([]);
        dispatch.adminPanelPatients.setTotalCountAndTotalPages({
          totalCount: 0,
          totalPages: 0,
        });
      }
    },
    async setPatientsPage(
      { patientGuid, clinicGuid, pageOffset, userType },
      state,
    ) {
      const { adminPanelPatients: adminPanelPatientsState } = state;
      const { page, totalPages } = adminPanelPatientsState;
      const newPage = page + pageOffset;
      dispatch.adminPanelPatients.setPage(
        newPage <= totalPages ? newPage : totalPages,
      );

      return dispatch.adminPanelPatients.getPatients({
        patientGuid,
        clinicGuid,
        page: newPage,
        userType,
      });
    },
    async getInternalAdmissionFormFile(patientGuid) {
      const { data, error } = await dataService.getOnly(
        apiPatientInternalAdmissionFormFile(patientGuid),
      );
      return { data, error };
    },
    async getPatient({ patientGuid }) {
      const { data, error } = await dataService.getList(
        apiPatientDetails(patientGuid),
      );
      if (data) {
        dispatch.adminPanelPatients.setPatient(data);
      }
      return {
        data,
        error,
      };
    },
    async getPatientDetailsWithCcmData(patientGuid) {
      const { data } = await dataService.getList(
        apiPatientDetailsAdmin(patientGuid),
      );

      if (data) {
        dispatch.adminPanelPatients.setCcmData({
          ccmConsentFormStatus: data.items[0].ccmConsentFormStatus,
          ccmStatus: data.items[0].ccmStatus,
          consentFormData: {
            signedDate: data.items[0].consentFormData?.signedDate,
            signedBy: data.items[0].consentFormData?.signedBy,
          },
        });
      }
    },
    async getDoctors({ clinicGuid, filter = {} }) {
      const newFilter = {
        status: 'active',
        ...filter,
      };
      if (!clinicGuid) return;
      const { error, data } = await dataService.getList(
        apiGetDoctors(clinicGuid, newFilter),
      );
      if (data) {
        dispatch.adminPanelPatients.setDoctors(data);
      }
      if (error) {
        dispatch.adminPanelPatients.setDoctors([]);
      }
    },
    async getCareAgents() {
      await Promise.all([
        (async () => {
          const { error, data } = await dataService.getList(
            `${API_ADMIN_PANEL_AGENCY_DOCTORS}?noLimit=true`,
          );
          if (data) {
            dispatch.adminPanelPatients.setCareAgents({
              hhaManagers: data.items,
            });
          }
          if (error) {
            dispatch.adminPanelPatients.setCareAgents({
              hhaManagers: [],
            });
          }
        })(),
        (async () => {
          const { error, data } = await dataService.getList(
            `${API_ADMIN_PANEL_AGENCY_DOCTORS}?roleName=ccm&noLimit=true`,
          );
          if (data) {
            dispatch.adminPanelPatients.setCareAgents({
              ccmManagers: data.items,
            });
          }
          if (error) {
            dispatch.adminPanelPatients.setCareAgents({
              ccmManagers: [],
            });
          }
        })(),
      ]);
    },
    async togglePatientInteractionStatus({ patientGuid, isInteracted }) {
      const postData = {
        patientGuid,
        isInteracted,
      };

      const { error, data } = await dataService.updateOnly(
        API_PATIENTS_INTERACTION_STATUS,
        postData,
      );

      if (!error) {
        dispatch.adminPanelPatients.updatePatientField({
          field: 'interactionStatus',
          data: data.interactionStatus,
        });
      }

      return { error, data };
    },
    async togglePatientNutritionAccount({ patientGuid }) {
      const { error, data } = await dataService.updateOnly(
        API_PATIENTS_NUTRITION_STATUS(patientGuid),
        {},
      );

      if (!error) {
        dispatch.adminPanelPatients.updatePatientField({
          field: 'weekMealStatus',
          data: data.weekMealStatus,
        });
      }
      return { error, data };
    },
    async togglePvr({ patientGuid, acceptPvrLite }) {
      const postData = {
        patientGuid,
        acceptPvrLite,
      };

      const { error, data } = await dataService.updateOnly(
        API_PATIENTS_PVR_STATE,
        postData,
      );

      if (!error) {
        dispatch.adminPanelPatients.updatePatientField({
          field: 'acceptPvrLite',
          data: data.acceptPvrLite,
        });
      }
      return { error, data };
    },
    async toggleDoNotCall({ patientGuid, doNotCall }) {
      const postData = {
        patientGuid,
        doNotCall,
      };

      const { error, data } = await dataService.updateOnly(
        API_PATIENTS_DO_NOT_CALL,
        postData,
      );

      if (!error) {
        dispatch.adminPanelPatients.updatePatientField({
          field: 'doNotCall',
          data: data.doNotCall,
        });
      }
      return { error, data };
    },
    async emailResubscribe({ patientGuid, reason }) {
      const putData = {
        subscription: true,
        reason,
      };
      const { error, data } = await dataService.updateOnly(
        apiPatientEmailSubscriptionToggle(patientGuid),
        putData,
      );
      if (!error) {
        dispatch.adminPanelPatients.updatePatientField({
          field: 'emailSubscription',
          data: data.subscription,
        });
      }
      return { error };
    },
    async togglePatientCcmInteractionStatus({ patientGuid, postData }) {
      const url = apiCcmInteractionStatus(patientGuid);
      const { error, data } = await dataService.updateOnly(url, postData);

      if (!error) {
        dispatch.adminPanelPatients.updatePatientField({
          field: 'ccmInteractionStatus',
          data: data.ccmInteractionStatus,
        });
      }
      return { error, data };
    },
    async updatePatientHoldingStatus(data) {
      dispatch.adminPanelPatients.updatePatientField({
        field: 'holdingStatus',
        data: {
          status: data.currentStatus,
          endTime: data.endTime,
        },
      });
      return {
        status: data.currentStatus,
        endTime: data.endTime,
      };
    },
    async createPatient(postData) {
      const { error, data } = await dataService.createOne(
        API_ADMIN_PANEL_PATIENT,
        postData,
      );
      return { error, data };
    },
    async createInternalAdmissionForm({ patientGuid, data: postData }) {
      const { error, data } = await dataService.createOne(
        apiPatientInternalAdmissionForm(patientGuid),
        postData,
      );
      return { error, data };
    },
    async editPatient(inData) {
      const { error, data } = await dataService.updateOnly(
        apiAdminPanelPatientEdit(inData.patientGuid),
        inData.postData,
      );

      return { error, data };
    },
  }),
};

export default adminPanelPatients;
