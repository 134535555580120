import React, { useEffect } from 'react';
import BaseTableNew from 'components/atoms/base-table/new';
import { useDeviceLog } from 'infrastructure/hooks/device/use-device-logs';

import { logsColumns } from './cols';
import s from './styles.module.scss';

interface IDevicesLogsTableProps {
  deviceId: string;
}

const DevicesLogsTable: React.FC<IDevicesLogsTableProps> = (props) => {
  const { deviceId } = props;

  const { loading, meta, deviceLogs, loadDeviceLogs } = useDeviceLog();

  const loadData = async (page: number) => {
    await loadDeviceLogs(deviceId, { ...meta, page });
  };

  useEffect(() => {
    loadData(1);
  }, [deviceId]);

  return (
    <div className={s.wrapper}>
      <BaseTableNew
        dataCy="devices-logs-table"
        loading={loading}
        columns={logsColumns()}
        bordered
        items={deviceLogs}
        onChangePage={(page) => loadData(page)}
        pagination={{
          current: meta.page,
          pageSize: meta.items,
          total: meta.totalCount,
        }}
      />
    </div>
  );
};

export default DevicesLogsTable;
