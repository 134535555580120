const SearchResetIcon = (props) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="reset-search-icon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.218851 6.71767C-0.0740708 7.01054 -0.0742438 7.48554 0.218465 7.77861C0.511174 8.07169 0.985922 8.07187 1.27884 7.779L3.99949 5.05889L6.71964 7.77798C7.01259 8.07082 7.48775 8.07101 7.78094 7.77842C8.07413 7.48582 8.07433 7.01124 7.78138 6.7184L5.06025 3.99834L7.77924 1.27989C8.07216 0.987021 8.07233 0.512022 7.77963 0.218944C7.48692 -0.0741328 7.01217 -0.0743057 6.71925 0.218559L3.99938 2.93789L1.28035 0.219918C0.9874 -0.0729182 0.512237 -0.0731138 0.219045 0.219481C-0.0741464 0.512075 -0.0743421 0.98666 0.218608 1.2795L2.93862 3.99844L0.218851 6.71767Z"
      fill="#000000"
    />
  </svg>
);

export default SearchResetIcon;
