import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { API_READINGS_ACTIONS } from 'services/dataService/resources';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { useTranslation } from 'react-i18next';
import useUser from 'utils/useUser';
import { generateRandomId } from 'utils/random';
import { useRef } from 'react';
import { createWaitPromise } from 'utils/helpers/timer';
import { useUpdateInteractionStatus } from 'infrastructure/hooks/control-panel';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import { isPatientAcceptance, isPractice } from 'utils/userTypeHelper';

import {
  setComment,
  setPatientInteractionStatus,
  setSingleSlaReviewedTime,
  undoComment,
} from './helpers/update-cache';

import type { IUpdateInteractionResponse } from 'infrastructure/hooks/control-panel/api';
import type {
  IAddCommentData,
  IAddCommentResponse,
  IRpmPatientActivity,
} from 'infrastructure/interfaces/i-rpm-patient-activity';
import type { QueryKey, UseMutateAsyncFunction } from '@tanstack/react-query';

const addActivityCommentFn = async ({
  currentInteractionStatus: _,
  ...postData
}: IAddCommentData) => {
  const url = API_READINGS_ACTIONS;
  const { error, data } = await dataService.createOne<IAddCommentResponse>(
    url,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

const UNDO_TIMEOUT_SECONDS = 5;

export type TAddCommentFn = UseMutateAsyncFunction<
  IAddCommentResponse | undefined,
  unknown,
  IAddCommentData,
  unknown
>;

interface Props {
  geActivityQueryKey: QueryKey;
}

export const useAddActivityComment = ({ geActivityQueryKey }: Props) => {
  const {
    loginGuid,
    firstName,
    lastName,
    professionType,
    title,
    roleNames,
    userType,
  } = useUser();
  const { t } = useTranslation();
  const mockCommentIdRef = useRef(generateRandomId());
  const queryClient = useQueryClient();
  const { updateInteractionStatus } = useUpdateInteractionStatus();
  const { Alert, showAlert } = useAlert();

  const makeMockComment = (data: IAddCommentData): IAddCommentResponse => ({
    guid: mockCommentIdRef.current,
    message: data?.message ?? '',
    readingId: data.readingId,
    deviceId: data.deviceId,
    type: 'text',
    reviewed: false,
    callId: null,
    createdAt: new Date().toISOString(),
    createdBy: loginGuid,
    createdByFirstName: firstName,
    createdByLastName: lastName,
    createdByProfessionType: professionType,
    createdByTitle: title,
    patientGuid: '',
  });

  const appendComment = (data: IAddCommentResponse) => {
    queryClient.setQueryData<IRpmPatientActivity>(
      geActivityQueryKey,
      (oldData) =>
        setComment({ oldData, data: { ...data, actionType: 'newComment' } }),
    );
  };

  const removeComment = (data: IAddCommentResponse) => {
    queryClient.setQueryData<IRpmPatientActivity>(
      geActivityQueryKey,
      (oldData) => undoComment({ oldData, data }),
    );
  };

  const setInteractionStatus = (data: IUpdateInteractionResponse) => {
    queryClient.setQueryData<IRpmPatientActivity>(
      geActivityQueryKey,
      (oldData) => setPatientInteractionStatus({ oldData, data }),
    );
  };

  const setReviewed = (data: IAddCommentResponse) => {
    queryClient.setQueryData<IRpmPatientActivity>(
      geActivityQueryKey,
      (oldData) => {
        if (oldData) {
          const newData = structuredClone(oldData);
          if (data?.slaReviewedTime) {
            const { deviceId, readingId, slaReviewedTime } = data;
            setSingleSlaReviewedTime({
              newData,
              data: { deviceId, readingId, slaReviewedTime },
            });
          }

          return setComment({
            oldData: newData,
            data: { ...data, actionType: 'newComment' },
          });
        }
      },
    );
  };

  const updateInteractionStatusIfNeed = async (
    patientGuid: string,
    patientInteractionStatus?: boolean,
  ) => {
    if (
      patientInteractionStatus === false &&
      !isPractice(userType) &&
      !isPatientAcceptance(roleNames)
    ) {
      const alertAnswer = await showAlert({
        title: t('labels.monthlyInteraction'),
        messageTitle: t('confirm.clinicalNoteMonthlyInteraction'),
        successBtnLabel: t('controls.yes'),
        rejectBtnLabel: t('controls.no'),
      });
      if (alertAnswer)
        updateInteractionStatus(
          { patientGuid, isInteracted: true },
          {
            onSuccess(data) {
              if (data) setInteractionStatus(data);
            },
          },
        );
    }
  };

  const { mutateAsync, isLoading, ...other } = useMutation({
    mutationFn: addActivityCommentFn,
    async onMutate(variables) {
      if (!variables.reviewed) {
        const { cancel, promise: undoTimeoutPromise } = createWaitPromise(
          UNDO_TIMEOUT_SECONDS * 1_000,
        );

        notificationController.showUndo({
          duration: UNDO_TIMEOUT_SECONDS,
          message: t('labels.commentSubmitting'),
          onUndo: () => {
            cancel();
            notificationController.info({
              message: t('labels.commentUndone'),
            });
          },
        });

        appendComment(makeMockComment(variables));
        await undoTimeoutPromise;
      }
    },
    async onSuccess(responseData, variables) {
      if (responseData && !variables.reviewed) {
        removeComment(makeMockComment(responseData));
        appendComment(responseData);
        notificationController.success({
          message: t('labels.commentSubmitted'),
        });
        await updateInteractionStatusIfNeed(
          responseData.patientGuid,
          variables.currentInteractionStatus,
        );
      }
      if (responseData && responseData?.reviewed) {
        notificationController.success({
          message: t('labels.readingReviewed'),
        });
        setReviewed(responseData);
      }
    },
    onError(error, variables) {
      if (!variables.reviewed) removeComment(makeMockComment(variables));
    },
  });

  return {
    InteractionStatusAlert: Alert,
    addActivityComment: mutateAsync,
    addCommentLoading: isLoading,
    ...other,
  };
};
