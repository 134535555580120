import { API_ADMIN_PANEL_AGENCY_DOCTORS } from 'services/dataService/resources';
import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { useState } from 'react';
import QueryParams from 'utils/queryParams';

export interface AgencyDoctor {
  guid: string;
  title: string;
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  agencies: string[];
  roles: string;
  status: string;
}
export interface Data {
  items: AgencyDoctor[];
  count: number;
}

export type AgencyDoctorFilter = {
  page: number;
  items: number;
  roleName?: string;
  guid?: string;
  agencyGuid?: string;
};

const useAgencyDoctors = ({
  page = 1,
  items = 100,
  roleName,
}: Partial<AgencyDoctorFilter>) => {
  const [filters, setFilters] = useState<AgencyDoctorFilter>({
    page,
    items,
    roleName,
  });
  const updateFilters = (newFilter: Partial<AgencyDoctorFilter>) => {
    setFilters({ ...filters, ...newFilter });
  };

  const { data, error, isLoading, isPreviousData } = useQuery({
    queryKey: [API_ADMIN_PANEL_AGENCY_DOCTORS, filters],
    keepPreviousData: true,
    queryFn: async () => {
      const filtersString = QueryParams.stringify(filters);
      const { error: localError, data: localData } =
        await dataService.getList<Data>(
          `${API_ADMIN_PANEL_AGENCY_DOCTORS}?${filtersString}`,
        );
      if (localError) {
        throw new Error(localError);
      }
      return localData;
    },
  });

  return {
    agencyDoctors: data?.items,
    isLoading: isLoading || isPreviousData,
    error,
    count: data?.count,
    updateFilters,
    filters,
  };
};

export default useAgencyDoctors;
