import { useTranslation } from 'react-i18next';

import ColumnForm, { ColumnFormItem } from '../form';

import type { CcmAgentItem } from 'infrastructure/hooks/ccmAgentDashboard/types';
import type { FC } from 'react';

interface Props {
  item: CcmAgentItem;
}

const CareAgents: FC<Props> = ({ item }) => {
  const { enrollmentDate, dischargeDate } = item.patient;
  const { t } = useTranslation();

  return (
    <ColumnForm>
      <ColumnFormItem
        label={t('labels.ccmEnrolmentDate')}
        value={enrollmentDate || '—'}
      />
      <ColumnFormItem
        label={t('labels.ccmDischargeDate')}
        value={dischargeDate || '—'}
      />
      <ColumnFormItem
        label={t('labels.caseManager')}
        value={item.ccmManager?.fullName || '—'}
      />
      <ColumnFormItem
        label={t('labels.onboardingAgent')}
        value={item.ccmOnboardingAgent?.fullName || '—'}
      />
    </ColumnForm>
  );
};

export default CareAgents;
