import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Input as NativeInput } from 'reactstrap';

const CheckboxInput = ({ id, label, options, error, note }) => {
  const onChange = (name, value, oldValue, setFieldValue) => {
    const restValue = oldValue ?? [];
    const index = restValue.indexOf(value);
    let newValue = null;
    if (index === -1) {
      newValue = [...restValue, value];
    } else {
      restValue.splice(index, 1);
      newValue = restValue.length === 0 ? null : restValue;
    }
    setFieldValue(name, newValue);
  };
  return (
    <div className="input-wrapper">
      <Field
        name={id}
        render={({ form, field }) => (
          <>
            <label htmlFor={id}>{label}</label>
            {options.map((option) => (
              <div
                className="input-checkbox-wrapper"
                key={`${id}-${option.id}`}
              >
                <NativeInput
                  type="checkbox"
                  id={`${id}-${option.id}`}
                  value={option.value}
                  checked={
                    !!(field.value && field.value.indexOf(option.value) !== -1)
                  }
                  onChange={() =>
                    onChange(id, option.value, field.value, form.setFieldValue)
                  }
                />
                <label htmlFor={`${id}-${option.id}`}>
                  <span className="input-checkbox-checkmark" />
                  {option.label}
                </label>
              </div>
            ))}
          </>
        )}
      />
      {error && <div className="input-error">{error}</div>}
      {note && <div className="input-note">{note}</div>}
    </div>
  );
};
CheckboxInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  note: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};
export default CheckboxInput;
