const RpmIcon = (props) => (
  <svg
    width="17"
    height="23"
    viewBox="0 0 17 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.875 2.875H11.3333C11.3333 1.28926 10.0628 0 8.5 0C6.93724 0 5.66667 1.28926 5.66667 2.875H2.125C0.951823 2.875 0 3.84082 0 5.03125V20.8438C0 22.0342 0.951823 23 2.125 23H14.875C16.0482 23 17 22.0342 17 20.8438V5.03125C17 3.84082 16.0482 2.875 14.875 2.875ZM8.5 1.79688C9.0888 1.79688 9.5625 2.27754 9.5625 2.875C9.5625 3.47246 9.0888 3.95312 8.5 3.95312C7.9112 3.95312 7.4375 3.47246 7.4375 2.875C7.4375 2.27754 7.9112 1.79688 8.5 1.79688ZM12.75 15.4531C12.75 15.6508 12.5906 15.8125 12.3958 15.8125H9.91667V18.3281C9.91667 18.5258 9.75729 18.6875 9.5625 18.6875H7.4375C7.24271 18.6875 7.08333 18.5258 7.08333 18.3281V15.8125H4.60417C4.40937 15.8125 4.25 15.6508 4.25 15.4531V13.2969C4.25 13.0992 4.40937 12.9375 4.60417 12.9375H7.08333V10.4219C7.08333 10.2242 7.24271 10.0625 7.4375 10.0625H9.5625C9.75729 10.0625 9.91667 10.2242 9.91667 10.4219V12.9375H12.3958C12.5906 12.9375 12.75 13.0992 12.75 13.2969V15.4531ZM12.75 6.82812C12.75 7.02578 12.5906 7.1875 12.3958 7.1875H4.60417C4.40937 7.1875 4.25 7.02578 4.25 6.82812V6.10938C4.25 5.91172 4.40937 5.75 4.60417 5.75H12.3958C12.5906 5.75 12.75 5.91172 12.75 6.10938V6.82812Z"
      fill="#FB9318"
    />
  </svg>
);

export default RpmIcon;
