import BaseTableNew from 'components/atoms/base-table/new';
import {
  MEDICAL_HISTORY_PER_PAGE,
  useGetAllergyLogs,
  useUpdateAllergyLog,
} from 'infrastructure/hooks/ccm';
import { type Dispatch, type SetStateAction, type FC, useState } from 'react';
import BaseModal from 'components/atoms/base-modal';
import { t } from 'i18next';
import { isObjectValid } from 'infrastructure/functions';
import AllergyLogForm from 'components/molecules/admin-panel/forms/allergy-log';
import EmptyTablePlaceholder from 'components/atoms/base-empty-table-placeholder';

import { columns } from './columns';

import type { Values } from 'components/molecules/admin-panel/forms/allergy-log/validation-schema';
import type { IUpdateAllergyLog } from 'infrastructure/interfaces';

interface AllergyLogTableProps {
  patientGuid: string;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  disableActions: boolean;
}

const AllergyLogTable: FC<AllergyLogTableProps> = ({
  patientGuid,
  page,
  setPage,
  disableActions,
}) => {
  const [open, setOpen] = useState(false);
  const [logForUpdate, setLogForUpdate] = useState<IUpdateAllergyLog>();
  const { allergyLogs, isLoading } = useGetAllergyLogs({
    patientGuid,
    meta: { page },
  });
  const { updateAllergyLog, updateLoading } = useUpdateAllergyLog({
    page,
  });
  const toggleOpen = () => setOpen((prev) => !prev);

  const onSubmit = async (values: Values) => {
    if (isObjectValid(values) && logForUpdate && isObjectValid(logForUpdate))
      updateAllergyLog(
        { ...logForUpdate, ...values },
        { onSuccess: () => setOpen(false) },
      );
  };

  const onCancelForm = () => {
    setLogForUpdate(undefined);
    setOpen(false);
  };

  return (
    <>
      <BaseTableNew
        loading={isLoading}
        items={allergyLogs?.items ?? []}
        columns={columns({
          loading: updateLoading,
          updatedItemGuid: logForUpdate?.guid,
          setLogForUpdate: (v) => {
            toggleOpen();
            setLogForUpdate(v);
          },
          disableActions,
        })}
        bordered
        pagination={{
          current: page,
          pageSize: MEDICAL_HISTORY_PER_PAGE,
          total: allergyLogs?.count ?? 0,
          position: ['bottomCenter'],
        }}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
        dataCy="allergyLog"
        // eslint-disable-next-line prettier/prettier
        emptyText={(
          <EmptyTablePlaceholder text={t('labels.noAllergiesLogged')} />
          // eslint-disable-next-line prettier/prettier
        )}
      />
      <BaseModal
        title={t('labels.editAllergy')}
        open={open}
        onCancel={toggleOpen}
      >
        <AllergyLogForm
          loading={updateLoading}
          onSubmit={onSubmit}
          onCancelForm={onCancelForm}
          defaultValues={logForUpdate}
        />
      </BaseModal>
    </>
  );
};
export default AllergyLogTable;
