import { ActivityStatusEnum } from 'infrastructure/enums';
import { usePatientDevices } from 'infrastructure/hooks/device/use-patient-devices';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { useRpmPatientActivity } from 'infrastructure/hooks/rpm';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'store';

import type { IBaseSelectBoxOption } from 'components/atoms/base-select-box/types';

const OPTIONS = [
  { value: true, label: ActivityStatusEnum.ACTIONED },
  { value: false, label: ActivityStatusEnum.UNACTIONED },
];

export type TOption = (typeof OPTIONS)[number];

export const usePatientActivity = (patientGuid: string) => {
  const [sortByDate, setSortByDate] = useState(false);
  const [status, setStatus] = useState<TOption>(OPTIONS[1]);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const { patientInfo, loading: patientInfoLoading } = usePatientInfo({
    patientGuid,
  });
  const { loading, devices } = usePatientDevices({ patientGuid });
  const deviceIds = devices?.map((device) => device.deviceId).join(',') ?? '';
  const {
    activity,
    isLoading: isLoadingActivity,
    isFetching: isFetchingActivity,
    remove,
    queryKey,
  } = useRpmPatientActivity({
    clinicGuid: patientInfo?.clinicGuid,
    deviceIds,
    patientGuid,
    reviewed: status.value,
    reverse: sortByDate,
    meta: { page },
  });
  const { Alert: UnsavedCommentAlert, showAlert } = useAlert();
  const actions = useAppSelector((state) => state.activityActions.actions);
  const {
    activityActions: { resetActions },
  } = useAppDispatch();

  const isLoading =
    patientInfoLoading || loading || isLoadingActivity || isFetchingActivity;
  const hasUnsavedMessage = Object.values(actions).some((action) =>
    Boolean(action?.message?.length),
  );

  const handleChangeType = async (
    v: any,
    opt: IBaseSelectBoxOption<any, any>,
  ) => {
    if (hasUnsavedMessage) {
      const answer = await showAlert({
        title: t('confirm.title'),
        message: t('messages.confirmUnsavedChanges'),
        successBtnLabel: t('controls.yes'),
        rejectBtnLabel: t('controls.no'),
      });

      if (!answer) return;

      setPage(1);
      setStatus(opt as TOption);
      resetActions();
      remove();
    }

    setPage(1);
    setStatus(opt as TOption);
    remove();
  };

  useEffect(() => {
    return () => {
      resetActions();
    };
  }, []);

  return {
    activity,
    queryKey,
    UnsavedCommentAlert,
    isLoading,
    OPTIONS,
    status,
    page,
    setPage,
    handleChangeType,
    setSortByDate,
  };
};
