export const convertSecondsToTime = (seconds) => {
  if (!seconds) return '00:00:00';
  return new Date(seconds * 1000).toISOString().substr(11, 8);
};

export const isCurrentMonthSelected = (dates, action) =>
  dates.length > 0 &&
  new Date().getMonth() === new Date(dates[0]).getMonth() &&
  action;

export const parseDateSafe = (dateString) => {
  if (!dateString) return undefined;
  try {
    const date = new Date(dateString);
    if (Number.isNaN(date.getTime())) {
      return undefined;
    }

    return date;
  } catch {
    return undefined;
  }
};
