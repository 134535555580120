import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import QueryParams from 'utils/queryParams';
import { API_INTERACTIVE_TIME_DASHBOARD } from 'services/dataService/resources';
import dataService from 'services/dataService';

import { generateQueryFromFilters } from './utils';

import type { Filters, InteractiveTimeItem } from './types';
import type { RootDispatch } from 'store';

export interface InteractiveTimeDashboardData {
  count: number;
  data: InteractiveTimeItem[];
}

const getItems = async (filters: Filters) => {
  if (filters.allExcluded) {
    return {
      data: [],
      count: 0,
    };
  }
  const url = QueryParams.stringifyUrl(
    API_INTERACTIVE_TIME_DASHBOARD,
    generateQueryFromFilters(filters),
  );
  const { error, data } =
    await dataService.getList<InteractiveTimeDashboardData>(url);
  if (error) {
    throw new Error(error);
  }
  return data;
};

export const useInteractiveTimeDashboardData = (filters: Filters) => {
  const queryKey = ['interactiveTimeDashboard', filters] as const;
  const {
    queryKeys: { setQueryKey },
  } = useDispatch<RootDispatch>();

  const { data, error, isLoading, isPreviousData } = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async () => {
      setQueryKey({ key: 'interactiveTimeDashboardData', value: queryKey });
      const response = await getItems(filters);
      return response;
    },
  });

  return {
    data: {
      data: data?.data.map((el) => ({
        ...el,
        contactAvailabilities: el.contactAvailabilities ?? [],
      })),
      count: data?.count,
    },
    isLoading: isLoading || isPreviousData,
    error,
    isPreviousData,
  };
};
