import React from 'react';
import PropTypes from 'prop-types';
import withLoader from 'components/organisms/withLoader';

import PatientRow from './patientRow';
import Sorting from './sorting';

const Patients = ({ compliance, loading }) => {
  const { data } = compliance;
  const dates =
    data && data.length > 0
      ? data[0].readingsCount.map((reading) => reading.date)
      : [];

  return (
    <div className="compliance-table">
      <div className="compliance-body">
        <div className="sort-row">
          <Sorting />
        </div>
        {withLoader(
          loading,
          <div className="compliance-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th className="compliance-table-name">Patient Name</th>
                  {dates.map((date) => (
                    <th key={date}>{date}</th>
                  ))}
                  <th className="compliance-table-total">Total</th>
                </tr>
              </thead>
              <tbody>
                {data.map((patientRow) => (
                  <PatientRow
                    key={patientRow.patient.guid}
                    report={patientRow}
                  />
                ))}
              </tbody>
            </table>
          </div>,
        )}
      </div>
    </div>
  );
};
Patients.propTypes = {
  compliance: PropTypes.shape(),
  loading: PropTypes.bool,
};

export default Patients;
