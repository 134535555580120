import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_PATIENT_DIAGNOSES } from 'services/dataService/ccmResources';
import { useAppSelector } from 'store';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import { showResult } from 'infrastructure/hooks/utils';

import { getConditionsKey, CONDITIONS_PER_PAGE } from './use-get-conditions';

import type {
  IEditCondition,
  ICondition,
  IConditionResponse,
} from 'infrastructure/interfaces';

interface Props {
  page: number;
}

export const editConditionFn = async (postData: IEditCondition) => {
  const { data, error } = await dataService.updateOnly<ICondition>(
    `${API_PATIENT_DIAGNOSES}/${postData.patientGuid}`,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useEditCondition = ({ page }: Props) => {
  const [startTime, endTime] = useAppSelector<[Date, Date] | []>(
    (state) => state.ccmPatientDetails.dates,
  );
  const queryClient = useQueryClient();

  const filter = {
    page,
    items: CONDITIONS_PER_PAGE,
    startTime: DateFormatter({
      date: startTime,
      format: DateFormats['yyyy-MM-dd HH:mm:s'],
    }),
    endTime: DateFormatter({
      date: endTime,
      format: DateFormats['yyyy-MM-dd HH:mm:s'],
    }),
  };

  const {
    mutate: editCondition,
    mutateAsync: editConditionAsync,
    isLoading,
    ...other
  } = useMutation({
    mutationFn: editConditionFn,
    onSuccess(data, variables) {
      const queryKey = getConditionsKey({
        ...filter,
        patientGuid: variables.patientGuid,
      });
      queryClient.setQueryData<IConditionResponse>(queryKey, (oldData) => {
        if (oldData && data) {
          const newItemsData = oldData.items.map((i) => {
            if (i.guid === variables.guid) {
              return data;
            }
            return i;
          });
          oldData.items = newItemsData;
        }
        return oldData;
      });
      showResult();
    },
  });

  return {
    editCondition,
    editConditionAsync,
    editLoading: isLoading,
    ...other,
  };
};
