import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';

import type { IConditionIcdCodeResponse } from 'infrastructure/interfaces';

const searchIcdTenCode = async (searchValue: string) => {
  const url = `core/ccm/icd-ten-codes?code=${searchValue.trim()}`;

  const { data, error } = await dataService.getList<IConditionIcdCodeResponse>(
    url,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useSearchIcdTenCode = () => {
  const { mutate, data, isLoading } = useMutation({
    mutationFn: searchIcdTenCode,
  });
  return { search: mutate, data, isLoading };
};
