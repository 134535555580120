import { Table } from 'reactstrap';

import TableHeader from './header';
import TableBody from './body';

import type { AgencyDoctor } from 'utils/hooks/adminPanel/useAgencyDoctors';
import type { FC } from 'react';

interface Props {
  agencyDoctors: AgencyDoctor[] | undefined;
}

const AdminPanelTurkUsersTable: FC<Props> = ({ agencyDoctors }) => {
  return (
    <Table bordered cellSpacing="0">
      <TableHeader />
      <TableBody agencyDoctors={agencyDoctors} />
    </Table>
  );
};

export default AdminPanelTurkUsersTable;
