import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface IProps extends IBaseComponentProps {
  opened: boolean;
  initialHeight?: number;
}

const CollapseAnimation: React.FC<IProps> = (props) => {
  const { opened, initialHeight = 0, children, uniqueKey } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState<number>(initialHeight);

  useEffect(() => {
    setTimeout(() => {
      if (Boolean(initialHeight) && ref.current) {
        setHeight(ref.current.clientHeight);
      }
    }, 50);
  }, [ref, uniqueKey]);

  return (
    <motion.div
      initial={{ height: `${height}px` }}
      animate={{
        height: opened ? 'auto' : `${height + 2}px`,
      }}
      exit={{ height: `${height}px` }}
      transition={{ duration: 0.3 }}
      style={{ overflow: 'hidden' }}
    >
      <div ref={ref}>{children}</div>
    </motion.div>
  );
};

export default CollapseAnimation;
