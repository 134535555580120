import BaseGrid from 'components/atoms/base-grid';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { combineFormResults } from 'infrastructure/functions/combine-form-results';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import { scrollToErrorIfExist } from 'utils';

import ClinicContactInformationForm from './contact-information';
import ClinicAddressForm from './address';
import ClinicDetailForm from './detail';
import ClinicInstructionsForm from './instructions';
import ClinicRepresentativesForm from './representatives';
import ClinicMappingsForm from './mappings';
import ClinicThresholdsForm from './thresholds';
import ClinicSettingsForm from './settings';

import type { ClinicThresholds } from 'infrastructure/classes/clinic-thresholds';
import type { Clinic } from 'infrastructure/classes/clinic';
import type { IClinic, IClinicThreshold } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const FormDisplayName = 'ClinicForm';

export interface IClinicFormRef
  extends IIndependentFormRef<IClinic & IClinicThreshold> {}

interface IProps extends IIndependentFormProps {
  info?: Clinic;
  thresholds?: ClinicThresholds;
  loading?: boolean;
}

const ClinicForm = forwardRef<IClinicFormRef, IProps>((props, ref) => {
  const { info, thresholds, loading = false } = props;

  const hasInfo = Boolean(info);

  const settingsRef = useRef<IIndependentFormRef>(null);
  const contactInfoRef = useRef<IIndependentFormRef>(null);
  const contactAddressRef = useRef<IIndependentFormRef>(null);
  const clinicDetailRef = useRef<IIndependentFormRef>(null);
  const clinicInstructionsRef = useRef<IIndependentFormRef>(null);
  const clinicMappingsFormRef = useRef<IIndependentFormRef>(null);
  const clinicRepresentativesFormRef = useRef<IIndependentFormRef>(null);
  const clinicThresholdsFormRef = useRef<IIndependentFormRef>(null);

  const submitForm = async () => {
    const res = await combineFormResults<IClinic & IClinicThreshold>(
      [
        settingsRef,
        contactInfoRef,
        contactAddressRef,
        clinicDetailRef,
        clinicInstructionsRef,
        clinicMappingsFormRef,
        clinicRepresentativesFormRef,
        clinicThresholdsFormRef,
      ],
      hasInfo,
    )
      .then((values) => {
        return {
          values,
          formName: FormDisplayName,
          isValid: true,
        };
      })
      .catch((err) => {
        scrollToErrorIfExist();
        return err;
      });

    return res;
  };

  const resetForm = () => {
    settingsRef.current?.reset();
    contactInfoRef.current?.reset();
    contactAddressRef.current?.reset();
    clinicDetailRef.current?.reset();
    clinicInstructionsRef.current?.reset();
    clinicMappingsFormRef.current?.reset();
    clinicRepresentativesFormRef.current?.reset();
    clinicThresholdsFormRef.current?.reset();
  };

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: resetForm,
  }));

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseGrid columns={1} rowGap={40}>
        <ClinicSettingsForm ref={settingsRef} data={info} />
        <BaseGrid columns={2} columnGap={20}>
          <ClinicContactInformationForm ref={contactInfoRef} data={info} />
          <ClinicAddressForm ref={contactAddressRef} data={info} />
        </BaseGrid>
        <ClinicDetailForm ref={clinicDetailRef} data={info} />
        <ClinicInstructionsForm ref={clinicInstructionsRef} data={info} />
        <BaseGrid columns={2} columnGap={20}>
          <ClinicMappingsForm ref={clinicMappingsFormRef} data={info} />
          <ClinicRepresentativesForm
            ref={clinicRepresentativesFormRef}
            data={info}
          />
        </BaseGrid>
        <ClinicThresholdsForm ref={clinicThresholdsFormRef} data={thresholds} />
      </BaseGrid>
    </BaseSpinWrapper>
  );
});

ClinicForm.displayName = FormDisplayName;

export default ClinicForm;
