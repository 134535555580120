import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const adminPanelAccountInfoValidationSchema = () =>
  createValidationSchema({
    status: commonValidationSchemas.string({ required: true }),
    ccmStatus: commonValidationSchemas.string({ required: true }),
    claimStatus: commonValidationSchemas.string({ required: true }),
    ccmClaimStatus: commonValidationSchemas.string({ required: true }),
    pcmStatus: commonValidationSchemas.boolean(),
  });
