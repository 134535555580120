export function isNotUndefined<T = any>(arg: T): arg is Exclude<T, undefined> {
  return typeof arg !== 'undefined';
}

export function isNotNull<T = any>(arg: T): arg is NonNullable<T> {
  return arg !== null;
}

export const isNotUndefinedOrNull = (val: any) =>
  isNotUndefined(val) && isNotNull(val);

export function isObjectValid<T extends object>(
  obj: T,
): obj is { [K in keyof T]: NonNullable<T[K]> } {
  return Object.values(obj).every((v) => isNotNull(v) && isNotUndefined(v));
}
