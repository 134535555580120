import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/atoms/input';

const DatePickerInput = ({ label, id, required, note, error }) => (
  <div className="input-wrapper">
    <Input type="date" id={id} label={label} markRequired={required} />
    {note && <div className="input-note">{note}</div>}
    {error && <div className="input-error">{error}</div>}
  </div>
);
DatePickerInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  note: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
};
export default DatePickerInput;
