import { useQuery } from '@tanstack/react-query';

import { getCount } from './fetchers';

import type { Filters, CcmAgentCountData } from './types';

export interface InteractiveTimeData {
  interactionStatusFalseCount: number;
  interactionStatusTrueCount: number;
}

export const useCcmAgentDashboardCount = (filters: Filters) => {
  const { data, error, isLoading } = useQuery({
    queryKey: [
      'ccmAgentDashboardCount',
      {
        ...filters,
        page: undefined,
        items: undefined,
      },
    ],
    queryFn: () => getCount(filters),
    initialData: {
      appointmentCompleted: {
        enrolledInCurrentMonth: {
          currentMonth: 0,
          last1Day: 0,
          last2Days: 0,
          last3Days: 0,
        },
        enrolledInPreviousMonth: {
          currentMonth: 0,
          last1Day: 0,
          last2Days: 0,
          last3Days: 0,
        },
      },
      totalCount: 0,
    } satisfies CcmAgentCountData,
  });

  return { data, isLoading, error };
};
