import i18next from 'i18next';
import { type SelectProps } from 'antd';

export const useSelectAll = ({
  showSelectAll = false,
  withSelectedData = false,
  options = [],
  value,
  onChange,
}: { showSelectAll?: boolean; withSelectedData?: boolean } & SelectProps) => {
  const handleSelectAll = () => {
    const mappedOptions = options.map((option) => option);
    const values =
      withSelectedData && value ? [...value, ...mappedOptions] : mappedOptions;
    onChange?.(values, values);
  };

  const enhancedOptions = () => {
    if (!showSelectAll || !options.length) return options;

    return [
      {
        label: (
          <div onClick={() => handleSelectAll()}>
            {i18next.t('labels.selectAll')}
          </div>
        ),
      },
      ...options,
    ];
  };

  return {
    options: enhancedOptions(),
    value,
    onChange,
  };
};
