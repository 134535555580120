import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import useOutsideClick from 'utils/useOutsideClick';
import common from 'constants/common';
import format from 'date-fns/format';
import CustomDatePickerWithTime from 'components/molecules/dateSelectorWithTime/range';
import classNames from 'classnames';

const DatePickerWithTime = ({ value = '', valueChanged, timeCaption }) => {
  const [showDateSelector, setShowDateSelector] = useState(false);
  const onSelect = (date) => {
    valueChanged(date ? format(new Date(date), common.dateFormats.full) : date);
    setShowDateSelector(false);
  };
  const ref = useRef();
  useOutsideClick(ref, () => {
    setShowDateSelector(false);
  });
  return (
    <div ref={ref}>
      <div
        onClick={() => {
          setShowDateSelector(true);
        }}
      >
        <Input
          className="main-field-input datepicker-input"
          value={value}
          onChange={(e) => {
            valueChanged(e.target.value);
          }}
        />
      </div>
      <div
        className={classNames('datepicker with-time', {
          hidden: !showDateSelector,
        })}
      >
        <CustomDatePickerWithTime
          inDate={value}
          onSelect={onSelect}
          timeCaption={timeCaption}
        />
      </div>
    </div>
  );
};

DatePickerWithTime.propTypes = {
  timeCaption: PropTypes.string,
  value: PropTypes.string,
  valueChanged: PropTypes.func,
};

export default DatePickerWithTime;
