import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';

import { showResult } from '../utils';
import { apiUrlGenerator } from '../use-api';

import type {
  IAdminPanelClinicDoctorsResponse,
  IPartialPaginationMeta,
} from 'infrastructure/interfaces';

export type TAdminPanelUsersFilter = {
  guid?: string;
  clinicGuid?: string;
  status?: string;
  role?: string;
} & IPartialPaginationMeta;

export const ADMIN_PANEL_DOCTORS_PER_PAGE = 100;

const getAdminPanelDoctorsFn = async (filter: TAdminPanelUsersFilter) => {
  const url = 'admins/admin-panel/doctors';
  const { data, error } =
    await dataService.getList<IAdminPanelClinicDoctorsResponse>(
      apiUrlGenerator(url, filter),
    );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
export const getAdminPanelDoctorsKey = (filter: TAdminPanelUsersFilter) => [
  'getAdminPanelDoctors',
  filter,
];

export const useGetAdminPanelDoctors = (filter: TAdminPanelUsersFilter) => {
  const filterWithItems: TAdminPanelUsersFilter = {
    ...filter,
    items: ADMIN_PANEL_DOCTORS_PER_PAGE,
  };

  const { data: doctors, ...other } = useQuery({
    queryKey: getAdminPanelDoctorsKey(filterWithItems),
    queryFn: async () => getAdminPanelDoctorsFn(filterWithItems),
    keepPreviousData: true,
  });

  return { doctors, ...other };
};
