import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import withLoader from 'components/organisms/withLoader';
import { showResult } from 'infrastructure/hooks/utils';
import EditButton from 'components/atoms/editButton';
import { Card, CardBody, Table } from 'reactstrap';

import Contact from './components/contact';
import Registration from './components/registration';
import Address from './components/address';
import IRS from './components/irs';

const Agency = () => {
  const navigate = useNavigate();
  const { agencyGuid } = useParams();
  const {
    adminPanelAgencies: { getAgency },
  } = useDispatch();
  useEffect(() => {
    const run = async () => {
      try {
        await getAgency(agencyGuid);
      } catch (e) {
        showResult(e);
        navigate('/404');
      }
    };
    run();
  }, [getAgency]);
  const { agency } = useSelector((state) => state.adminPanelAgencies);
  const { getAgency: agencyDetailsLoading } = useSelector(
    (state) => state.loading.effects.adminPanelAgencies,
  );
  return (
    <div className="admin-patient">
      <Card className="card-border">
        <CardBody>
          <div className="admin-patient-header">
            <span>HHA's Page</span>
            <div className="admin-patient-header-actions">
              <div className="admin-patient-header-actions-status badge">
                Active
              </div>
              <div className="admin-patient-header-actions-edit">
                <Link
                  data-cy="agency-edit-btn"
                  to={`/admin/agency/${agencyGuid}/edit`}
                >
                  <EditButton />
                </Link>
              </div>
            </div>
          </div>
          {withLoader(
            agencyDetailsLoading,
            <>
              {agency && (
                <div className="admin-patient-edit-form admin-panel-agency-guid-table__outer">
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <Contact data={agency} />
                        </td>
                        <td>
                          <Address data={agency} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Registration data={agency} />
                        </td>

                        <td rowSpan="2">
                          <IRS data={agency} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
            </>,
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Agency;
