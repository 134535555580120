import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

import type { TSchema } from 'infrastructure/functions';

export const medicalHistoryValidationSchema = () =>
  createValidationSchema({
    record: commonValidationSchemas.string({ required: true }),
  });

export type Values = TSchema<ReturnType<typeof medicalHistoryValidationSchema>>;
