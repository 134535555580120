import React from 'react';
import PropTypes from 'prop-types';
import { RadialChart } from 'react-vis';
import common from 'constants/common';

const StatusTypeChart = ({ statusBlocks }) => {
  const keys = Object.keys(statusBlocks).filter(
    (item) => item !== 'total' && statusBlocks[item] > 0,
  );

  const chartData = keys.map((key) => ({
    angle: parseInt(statusBlocks[key], 10),
    label: common.statusNames[key].label,
    color: common.statusNames[key].color,
    value: key,
  }));

  return (
    <div className="onboarding-chart">
      <RadialChart
        data={chartData}
        width={220}
        height={220}
        colorType="literal"
      />
      <div className="onboarding-chart-legend">
        {keys.map((key) => (
          <div key={key} className="onboarding-chart-legend-item">
            <div
              style={{ backgroundColor: common.statusNames[key].color }}
              className="onboarding-chart-legend-item-dot"
            />
            <div className="onboarding-chart-legend-item-title">
              {common.statusNames[key].label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

StatusTypeChart.propTypes = {
  statusBlocks: PropTypes.object.isRequired,
};

export default StatusTypeChart;
