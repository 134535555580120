import React from 'react';
import PropTypes from 'prop-types';

const List = ({ onChange, options, dataCy }) => (
  <div className="list">
    <ul>
      {options.map((option) => (
        <li key={option.id} data-cy={dataCy} onClick={() => onChange(option)}>
          {option.label}
        </li>
      ))}
    </ul>
  </div>
);

List.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
  dataCy: PropTypes.string,
};

export default List;
