import React, { useEffect, useMemo, useRef } from 'react';
import { CommentType } from 'infrastructure/enums';
import { groupArrayByFieldname } from 'infrastructure/functions';
import InfiniteScroll from 'react-infinite-scroll-component';
import BaseLoader from 'components/atoms/base-loader';

import s from './styles.module.scss';
import GeneralCommentsListMessageItem from './message-item';
import GeneralCommentsListCallItem from './call-item';
import DateBlock from './date-block';

import type { Comment } from 'infrastructure/classes/comments/comment';

interface IGeneralCommentsListProps {
  id: string;
  items: Array<Comment>;
  totalItems: number;
  height?: number;
  loadNext?: () => void;
}

const GeneralCommentsList: React.FC<IGeneralCommentsListProps> = (props) => {
  const { id, items, totalItems = 0, height = 535, loadNext } = props;

  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [items.length]);

  const groupedItems = useMemo(
    () => groupArrayByFieldname(items, 'createdDate'),
    [items],
  );

  const loadMoreData = () => {
    if (loadNext) loadNext();
  };

  return (
    <div id={id} style={{ height }} className={s.list} ref={scrollRef}>
      <InfiniteScroll
        scrollableTarget={id}
        inverse
        style={{
          overflow: 'hidden',
        }}
        dataLength={items.length}
        hasMore={items.length < totalItems}
        loader={<BaseLoader loading />}
        next={loadMoreData}
        className={s['scroll-wrapper']}
      >
        {Object.keys(groupedItems).map((createdDate) => (
          <React.Fragment key={createdDate}>
            {groupedItems[createdDate].map((el) => (
              <React.Fragment key={el.key}>
                {el.type === CommentType.Text && (
                  <GeneralCommentsListMessageItem data={el} />
                )}
                {el.type === CommentType.Call && (
                  <GeneralCommentsListCallItem data={el} />
                )}
              </React.Fragment>
            ))}
            <DateBlock date={createdDate} />
          </React.Fragment>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default GeneralCommentsList;
