const RoleTypes = [
  { value: 'all', label: 'All' },
  { value: 'admin-panel-access', label: 'Admin Panel Access' },
  { value: 'adminTools', label: 'Admin Tools' },
  { value: 'biller', label: 'Biller' },
  { value: 'doctor', label: 'Physician' },
  { value: 'nursePractitioner', label: 'Nurse Practitioner' },
  { value: 'physicianAssistant', label: 'Physician Assistant' },
  { value: 'QHCP', label: 'QHCP' },
  { value: 'support', label: 'Support' },
  { value: 'onboarding', label: 'Onboarding' },
  { value: 'registeredNurse', label: 'Registered Nurse' },
  { value: 'patientAcceptance', label: 'Patient Acceptance' },
  { value: 'criticalAlerting', label: 'Critical Alerting' },
  { value: 'atRiskAlerting', label: 'At Risk Alerting' },
  {
    value: 'clientSuccessOnboarding',
    label: 'Client Success Onboarding',
  },
  {
    value: 'clientSuccessScaling',
    label: 'Client Success Scaling',
  },
  { value: 'salesRepresentative', label: 'Sales Representative' },
  { value: 'ccm-biller', label: 'CCM Biller' },
  { value: 'ccm-doctor', label: 'CCM Physician' },
  { value: 'ccm-nursePractitioner', label: 'CCM Nurse Practitioner' },
  { value: 'ccm-physicianAssistant', label: 'CCM Physician Assistant' },
  { value: 'ccm-QHCP', label: 'CCM QHCP' },
  { value: 'ccm-support', label: 'CCM Support' },
  { value: 'ccm-registeredNurse', label: 'CCM Registered Nurse' },
  { value: 'nutritionist', label: 'Nutritionist' },
  { value: 'smsDashboard', label: 'SMS Dashboard' },
  { value: 'smsDashboardUnregistered', label: 'SMS Dashboard Unregistered' },
];

export const ccmRoles = [
  'ccm-biller',
  'ccm-doctor',
  'ccm-nursePractitioner',
  'ccm-physicianAssistant',
  'ccm-QHCP',
  'ccm-support',
  'ccm-registeredNurse',
];

export const appointmentCompletedByAllowedRoles = [
  'ccm-QHCP',
  'ccm-registeredNurse',
];

export const readOnlyRoles = [
  'ccm-biller',
  'biller',
  'clientSuccessOnboarding',
  'salesRepresentative',
  'clientSuccessScaling',
];
export const smsDashboardRoles = ['smsDashboard', 'smsDashboardUnregistered'];

export const patientProfileRpm = [
  'active',
  'onboarding',
  'inactive_bad_no_signal_connectivity',
  'inactive_changed_doctor',
  'inactive_client_request_inconvenient',
  'inactive_client_request_moving',
  'inactive_client_request_other',
  'inactive_expired',
  'inactive_health_plan_request_goals_met',
  'inactive_health_plan_request_non_compliant',
  'inactive_health_plan_request_other',
  'inactive_hospice',
  'inactive_copay',
  'inactive_long_term_care_rehab',
  'inactive_loss_of_eligibility',
  'inactive_physician_request',
  'pending_review',
];

export const patientProfileСсm = [
  'enrolled',
  'ccm_onboarding',
  'ccm_onboarding_unsuccessful_no_contact',
  'ccm_onboarding_unsuccessful_patient_refused',
  'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
  'ccm_onboarding_unsuccessful_changed_doctor',
  'ccm_onboarding_unsuccessful_other',
  'ccm_unenrolled_changed_doctor',
  'ccm_unenrolled_client_request',
  'ccm_unenrolled_copay_loss_of_insurance',
  'ccm_unenrolled_non_adherent',
  'ccm_unenrolled_expired',
  'ccm_unenrolled_hospice',
  'ccm_unenrolled_long_term_skilled_nursing_facility_rehab',
  'ccm_unenrolled_physician_clinic_request',
  'ccm_unenrolled_client_request_moving',
  'ccm_unenrolled_health_plan_request_goals_met',
  'ccm_unenrolled_other',
];

export default RoleTypes;
