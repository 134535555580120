import SmsIcon from 'components/atoms/icons/smsIcon';
import { makeid } from 'utils/helpers/makeid';
import { isPractice } from 'utils/userTypeHelper';

interface Props {
  doctorWorkNumber?: string;
  isAdminPanel: boolean;
  patientHasSecondaryNumber: boolean;
  userType: string;
  setSmsPopupOpen: (arg: boolean) => void;
  setIsPrimaryNumber: (arg: boolean) => void;
  setDoctorSmsPopupOpen?: (arg: boolean) => void;
}

export const useSmsButtons = ({
  doctorWorkNumber,
  isAdminPanel,
  patientHasSecondaryNumber,
  userType,
  setSmsPopupOpen,
  setIsPrimaryNumber,
  setDoctorSmsPopupOpen,
}: Props) => {
  const smsButtons = [
    {
      id: makeid(5),
      render: true,
      label: 'SMS patient primary phone number',
      handleClick: () => {
        setSmsPopupOpen(true);
        setIsPrimaryNumber(true);
      },
    },
    {
      id: makeid(5),
      render: !!patientHasSecondaryNumber,
      label: 'SMS patient secondary phone number',
      handleClick: () => {
        setSmsPopupOpen(true);
        setIsPrimaryNumber(false);
      },
    },
    {
      id: makeid(5),
      render: doctorWorkNumber && !isPractice(userType) && !isAdminPanel,
      label: 'SMS Primary Doctor',
      icon: SmsIcon,
      handleClick: () => setDoctorSmsPopupOpen?.(true),
    },
  ].filter(({ render }) => render);

  return smsButtons;
};
