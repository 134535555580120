import { ccmPageActionAllowedStatues } from 'constants/patient_statuses';
import {
  patientProfileRpm,
  patientProfileСсm,
  readOnlyRoles,
  smsDashboardRoles,
} from 'constants/roleTypes';

export const isPatient = (userType) => userType === 'patient';
export const isPractice = (userType) => userType === 'practice';
export const isAdmin = (userType) => userType === 'admin';
export const isAgencyDoctor = (userType) => userType === 'homeHealth';
export const isRoleNameAdministrator = (roleNames) =>
  roleNames.includes('admin-panel-access');
export const isRoleNameAdminTools = (rolesNames) =>
  rolesNames.includes('adminTools');
export const isOnboardingPatient = (onboardingStatuses, status) =>
  onboardingStatuses.includes(status);
export const isCcmAgencyDoctor = (userType, roleNames) =>
  userType === 'homeHealth' &&
  roleNames.some((roleName) => roleName.startsWith('ccm-'));
export const agencyDoctorWithRPMAndCCMRoles = (userType, roleNames) =>
  userType === 'homeHealth' &&
  roleNames.some(
    (roleName) =>
      !roleName.includes('ccm-') && !roleName.includes('admin-panel-access'),
  ) &&
  roleNames.some((roleName) => roleName.includes('ccm-'));
export const isBiller = (roleNames) =>
  roleNames?.every(
    (roleName) => roleName === 'biller' || roleName === 'ccm-biller',
  );
export const isPatientAcceptance = (roleNames) =>
  roleNames.includes('patientAcceptance');
export const isCcmActionAllowedStatus = (ccmStatus) =>
  ccmPageActionAllowedStatues.includes(ccmStatus);

export const isReadOnlyRole = (roleNames) => {
  if (!roleNames || !roleNames.length) {
    return false;
  }
  return roleNames.every((role) => readOnlyRoles.includes(role));
};

export const isTurkUserRole = (roleNames) => roleNames?.includes('turk');
export const isNutritionist = (rolesNames) =>
  rolesNames.includes('nutritionist');

export const isSmsDashboardOrUnregisteredRole = (rolesNames) => {
  if (!rolesNames || !rolesNames.length) {
    return false;
  }

  return smsDashboardRoles.some((role) => rolesNames.includes(role));
};

export const isSmsDashboardUnregisteredRole = (rolesNames) => {
  if (!rolesNames || !rolesNames.length) {
    return false;
  }
  return rolesNames.some((role) => role === 'smsDashboardUnregistered');
};

export const isRoleNameOnboarding = (rolesNames) =>
  rolesNames.includes('onboarding');

export const hasPatientProfileRpm = (rpmStatus) =>
  patientProfileRpm.includes(rpmStatus);

export const hasPatientProfileCcm = (ccmStatus) =>
  patientProfileСсm.includes(ccmStatus);
