import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { apiPatientAverageMetrics } from 'services/dataService/resources';

import { PATIENT_DATA_STALE_TIME } from './config';

export interface PatientAverage {
  name: string;
  value: string;
}
export interface PatientAverages {
  averages30: PatientAverage[];
  averages90: PatientAverage[];
}

interface Props {
  patientGuid: string;
  dates: {
    endTime: string;
    timezoneOffset: number;
  };
}

const getAverages = async ({ patientGuid, dates }: Props) => {
  const url = apiPatientAverageMetrics(patientGuid, dates);
  const { error: localError, data: localData } =
    await dataService.getList<PatientAverages>(url);
  if (localError) {
    throw new Error(localError);
  }
  return localData;
};

const useTurkDashboardAverages = ({ patientGuid, dates }: Props) => {
  const { data, error, isLoading } = useQuery({
    queryKey: [
      'turkDashboardAverages',
      patientGuid,
      dates.timezoneOffset,
      dates.endTime,
    ],
    queryFn: async () => {
      return getAverages({ patientGuid, dates });
    },
    staleTime: PATIENT_DATA_STALE_TIME,
  });

  return { data, isLoading, error };
};

export default useTurkDashboardAverages;
