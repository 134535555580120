import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { glucometerConsumablesDataSource } from 'infrastructure/data-sources/glucometer-consumables';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IGlucometerConsumablesItem } from 'infrastructure/data-sources/glucometer-consumables';

type Options = IBaseSelectBoxOption<
  IGlucometerConsumablesItem['id'],
  IGlucometerConsumablesItem
>[];

interface IGlucometerConsumablesSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {}

const GlucometerConsumablesSelectBox: React.FC<
  IGlucometerConsumablesSelectBoxProps
> = (props) => {
  const dataSource = useMemo(() => glucometerConsumablesDataSource(), []);

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...props} />;
};

export default GlucometerConsumablesSelectBox;
