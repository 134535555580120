import s from './styles.module.scss';
import ButtonLoadingIcon from '../icons/buttonLoadingIcon';

import type { FC, ReactNode } from 'react';

interface IBaseIconButtonProps {
  title?: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  icon: ReactNode;
  className?: string;
  dataCy?: string;
}

const BaseIconButton: FC<IBaseIconButtonProps> = ({
  icon,
  disabled,
  loading,
  onClick,
  title,
  className,
  dataCy,
}) => {
  return (
    <button
      data-cy={dataCy}
      title={title}
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {loading ? (
        <ButtonLoadingIcon className={s['spin-loading-icon']} />
      ) : (
        icon
      )}
    </button>
  );
};

export default BaseIconButton;
