import React, { useState, useRef } from 'react';
import HintIcon from 'components/atoms/icons/hintIcon';
import { PropTypes } from 'prop-types';
import CrossStitchIcon from 'components/atoms/icons/crossStitchIcon';
import useOutsideClick from 'utils/useOutsideClick';
import classNames from 'classnames';

const DescriptionPopup = ({ msg, colorBtn, right }) => {
  const [showDescription, setShowDescription] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => {
    setShowDescription(false);
  });

  return (
    <div className="mini-popup" ref={ref}>
      <button
        className={`mini-popup__icon ${
          showDescription ? 'mini-popup__icon--active' : ''
        }`}
        onClick={() => setShowDescription(!showDescription)}
      >
        <HintIcon color={colorBtn} />
      </button>
      {showDescription && (
        <div className="mini-popup__popup-wrapper">
          <div className={classNames('mini-popup__popup', { right })}>
            <button
              className="mini-popup__close-btn"
              onClick={() => setShowDescription(false)}
            >
              <CrossStitchIcon />
            </button>
            <div className="mini-popup__msg">{msg}</div>
          </div>
        </div>
      )}
    </div>
  );
};

DescriptionPopup.propTypes = {
  msg: PropTypes.string.isRequired,
  colorBtn: PropTypes.string,
  right: PropTypes.bool,
};

export default DescriptionPopup;
