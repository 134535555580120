import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { format } from 'date-fns';

const Registration = ({ data }) => (
  <Table borderless border="0" cellSpacing="0" cellPadding="0">
    <thead>
      <tr>
        <th colSpan="2">
          <div className="pagination-container">
            <h5>Registration</h5>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td />
        <td>
          {data.createdAt && (
            <>
              <span className="subheader">Registered At: </span>
              <span data-cy="registered-at">
                {format(new Date(data.createdAt), 'MMM dd yyyy h:m:s')}
              </span>
            </>
          )}
          {data.email && (
            <>
              <span className="subheader">Email: </span>
              <span
                data-cy="registration-email"
                className="text-transform-none"
              >
                {data.email}
              </span>
            </>
          )}
        </td>
      </tr>
    </tbody>
  </Table>
);

Registration.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Registration;
