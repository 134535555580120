import React from 'react';
import { Container, Row } from 'reactstrap';
import Table from 'components/templates/rpm/practice/medicareBilling/table';
import Count from 'components/templates/rpm/practice/medicareBilling/count';

const MedicareBilling = () => (
  <Container>
    <Row>
      <Table />
    </Row>
    <Row>
      <Count />
    </Row>
  </Container>
);

export default MedicareBilling;
