import { useTranslation } from 'react-i18next';
import common from 'constants/common';

import ColumnForm, { ColumnFormEmptyItem, ColumnFormItem } from '../form';

import type { CcmStatuses, RpmStatuses } from 'infrastructure/enums';
import type { CcmAgentItem } from 'infrastructure/hooks/ccmAgentDashboard/types';
import type { FC } from 'react';

const CcmStatus: FC<{ ccmStatus: string }> = ({ ccmStatus }) => {
  const ccmStatusObj = common.ccmStatus[ccmStatus as CcmStatuses] || undefined;
  if (!ccmStatusObj) {
    return null;
  }
  const { color, label } = ccmStatusObj;
  const dataCy = label.toLowerCase().split(' ').join('-');
  return (
    <span style={{ color }} data-cy={`${dataCy}-value`}>
      {label}
    </span>
  );
};

const RpmStatus: FC<{ status: string }> = ({ status }) => {
  const statusObj = common.statusPatients[status as RpmStatuses] || undefined;
  if (!statusObj) {
    return null;
  }
  const { label } = statusObj;
  const dataCy = label.toLowerCase().split(' ').join('-');
  return <span data-cy={`${dataCy}-value`}>{label}</span>;
};

interface IProps {
  item: CcmAgentItem;
}

const StatusesInfo: FC<IProps> = ({ item }) => {
  const { ccmInteractionStatus, ccmStatus, status } = item.patient;
  const { t } = useTranslation();

  return (
    <ColumnForm>
      <ColumnFormItem
        label={t('labels.ccmStatus')}
        value={<CcmStatus ccmStatus={ccmStatus} />}
      />
      <ColumnFormItem
        label={t('labels.ccmInteractionStatus')}
        value={ccmInteractionStatus ? t('controls.true') : t('controls.false')}
      />
      <ColumnFormItem
        label={t('labels.rpmStatus')}
        value={<RpmStatus status={status} />}
      />
      <ColumnFormEmptyItem />
    </ColumnForm>
  );
};

export default StatusesInfo;
