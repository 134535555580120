import { useQuery, useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import useApi from '../use-api';

import type { IClinic } from 'infrastructure/interfaces';

const getClinicByGuidApi = (guid: string) => `core/clinics/${guid}`;

interface IUseClinicInfoProps {
  clinicGuid: string;
}

type UseClinic = {
  loading: boolean;
  loadClinicInfo: (params: IUseClinicInfoProps) => Promise<IClinic | undefined>;
  clinicInfo?: IClinic;
};

export const getClinicInfoKey = (params: IUseClinicInfoProps) => [
  'loadClinicInfo',
  params,
];

export const useClinicInfo = (props: IUseClinicInfoProps): UseClinic => {
  const { loading, loadData } = useApi();
  const queryClient = useQueryClient();

  const loadClinicInfo = async (params: IUseClinicInfoProps) => {
    const data = await queryClient.fetchQuery(
      getClinicInfoKey(params),
      () => loadData<IClinic>(getClinicByGuidApi(params.clinicGuid)),
      { staleTime: STALE_TIME },
    );

    if (data) return data;
  };

  const { data: clinicInfo = undefined, isLoading } = useQuery({
    queryKey: getClinicInfoKey(props),
    queryFn: () => loadData<IClinic>(getClinicByGuidApi(props.clinicGuid)),
    staleTime: STALE_TIME,
    enabled: Boolean(props.clinicGuid),
  });

  return {
    clinicInfo,
    loading: loading || isLoading,
    loadClinicInfo,
  };
};
