import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';
import { rpmDashboardFilterDoctorSearchRoles } from 'infrastructure/enums';
import { fullNamePresenter } from 'utils';

import type { Nullable } from 'infrastructure/types';
import type { IDoctorInfo } from 'infrastructure/interfaces';

interface ISearchProps {
  clinicGuid?: Nullable<string>;
  fullName: string;
}

const searchDoctorsFn = async ({ fullName, clinicGuid }: ISearchProps) => {
  const { data, error } = await dataService.getList<IDoctorInfo[]>(
    apiUrlGenerator('core/doctors', {
      roleName: rpmDashboardFilterDoctorSearchRoles.join(','),
      items: 50,
      fullName,
      clinicGuid: clinicGuid ?? '',
    }),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data?.map((doctor) => ({
    value: doctor.guid,
    label: fullNamePresenter({
      firstName: doctor.firstName,
      lastName: doctor.lastName,
    }),
  }));
};

export const useSearchDoctors = (clinicGuid?: string) => {
  const { mutate, data, isLoading, reset } = useMutation({
    mutationFn: (fullName: string) => searchDoctorsFn({ fullName, clinicGuid }),
  });

  return {
    searchDoctors: mutate,
    doctors: data,
    searchDoctorsLoading: isLoading,
    resetSearchDoctors: reset,
  };
};
