import { useInfiniteQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { PlatformEnums } from 'infrastructure/enums';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';

import type {
  ConversationType,
  IConversationsListRes,
} from 'infrastructure/interfaces';

interface Props {
  cursor: Date;
  type: ConversationType;
  read: string;
  closed: string;
  lastMessageDate: string;
  searchQuery: string;
}

const PAGE_SIZE = 5;
const API_CONVERSATIONS = 'core/conversations';
const API_UNREGISTERED_CONVERSATIONS = 'core/conversations-unregistered';

export const getSmsDashboardConversationsFn = async (props: Props) => {
  const url =
    props.type === PlatformEnums.UNREGISTERED
      ? API_UNREGISTERED_CONVERSATIONS
      : API_CONVERSATIONS;

  const { data, error } = await dataService.getList<IConversationsListRes>(
    apiUrlGenerator(url, { ...props, pageSize: PAGE_SIZE }),
  );

  if (error) {
    showResult('Unable to retrieve conversations');
    throw new Error(error);
  }
  return data;
};

export const getSmsDashboardConversationsKey = ({
  type,
  read,
  closed,
  lastMessageDate,
  searchQuery,
}: {
  type: ConversationType;
  read: string;
  closed: string;
  lastMessageDate: string;
  searchQuery: string;
}) => [
  'getSmsDashboardConversations',
  type,
  read,
  closed,
  lastMessageDate,
  searchQuery,
];

export const useSmsDashboardConversations = (props: Props) => {
  const {
    data: conversations,
    refetch,
    isFetching,
    isLoading,
    isRefetching,
    ...other
  } = useInfiniteQuery({
    queryKey: getSmsDashboardConversationsKey({
      type: props.type,
      read: props.read,
      closed: props.closed,
      lastMessageDate: props.lastMessageDate,
      searchQuery: props.searchQuery,
    }),
    queryFn: async ({ pageParam = 0 }) =>
      getSmsDashboardConversationsFn({
        ...props,
        cursor:
          pageParam ||
          (props.lastMessageDate === 'oldest' ? undefined : props.cursor),
      }),
    getNextPageParam: (lastPage) => {
      return lastPage?.hasMore
        ? lastPage.items[lastPage.items.length - 1]?.lastMessageDate
        : false;
    },
  });

  return {
    conversations: conversations?.pages.flat(),
    fetchConversations: refetch,
    conversationsLoading: isLoading || isFetching || isRefetching,
    isLoading,
    isFetching,
    ...other,
  };
};
