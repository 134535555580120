import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { FormGroup, Label, Input } from 'reactstrap';

import CustomSelectBox from './types/selectbox';
import CustomDatePicker from './types/datePicker';
import CustomTimePicker from './types/timePicker';
import DatePickerWithTime from './types/datePickerWithTime';

const FieldInput = ({
  id,
  type,
  label,
  options,
  disabled,
  onChange,
  isMulti,
  isDotIcon,
  isClearable,
  dataCy,
  min,
  markRequired,
  max,
  format,
  isLoading,
  timeCaption,
  timeIntervals,
  rows,
}) => {
  const inputProps = {
    className: 'main-field-input',
    type,
    id,
    name: id,
  };
  let ComponentByType;
  if (type === 'select') {
    ComponentByType = (value, setFieldValue) => (
      <CustomSelectBox
        isClearable={isClearable}
        defaultValue={
          typeof value === 'string' ||
          typeof value === 'boolean' ||
          typeof value === 'number' ||
          value === null
            ? {
                value,
                label: options.find(
                  (el) =>
                    el?.id === value || el?.value === value || el === value,
                )?.label,
              }
            : value
        }
        valueChanged={(e) => {
          setFieldValue(id, e?.id);
          if (onChange) onChange(e, setFieldValue);
        }}
        options={options}
        disabled={disabled}
        isMulti={isMulti}
        isDotIcon={isDotIcon}
        isLoading={isLoading}
        dataCy={dataCy}
      />
    );
  } else if (type === 'multiselect') {
    ComponentByType = (value, setFieldValue) => (
      <CustomSelectBox
        isClearable={isClearable}
        valueChanged={(e) => {
          setFieldValue(id, e);
        }}
        defaultValue={value}
        options={options}
        disabled={disabled}
        isMulti
        isDotIcon={isDotIcon}
        isLoading={isLoading}
        dataCy={dataCy}
      />
    );
  } else if (type === 'dateWithTime') {
    ComponentByType = (value, setFieldValue) => (
      <DatePickerWithTime
        valueChanged={(e) => {
          setFieldValue(id, e);
          if (onChange) onChange(e, setFieldValue);
        }}
        value={value}
        dataCy={dataCy}
        timeCaption={timeCaption}
      />
    );
  } else if (type === 'date') {
    ComponentByType = (value, setFieldValue) => (
      <CustomDatePicker
        valueChanged={(e) => {
          setFieldValue(id, e);
          if (onChange) onChange(e, setFieldValue);
        }}
        value={value}
        dataCy={dataCy}
        format={format}
        disabled={disabled}
      />
    );
  } else if (type === 'time') {
    ComponentByType = (value, setFieldValue) => (
      <CustomTimePicker
        valueChanged={(e) => {
          setFieldValue(id, e);
          if (onChange) onChange(e, setFieldValue);
        }}
        format={format}
        value={value}
        dataCy={dataCy}
        timeCaption={timeCaption}
        timeIntervals={timeIntervals}
        disabled={disabled}
      />
    );
  } else {
    ComponentByType = (value, setFieldValue) => (
      <Input
        {...inputProps}
        rows={rows}
        onChange={(e) => {
          setFieldValue(id, e.target.value);
          if (onChange) onChange(e, setFieldValue);
        }}
        value={value}
        disabled={disabled}
        autoComplete="off"
        data-cy={dataCy}
        min={min}
        max={max}
      />
    );
  }
  return (
    <Field id={id} name={id} type={type}>
      {({ field: { value }, form: { setFieldValue } }) => (
        <FormGroup className="main-field">
          <Label className="main-field-label" for={id}>
            {label} {markRequired && <span className="mark-required">*</span>}
          </Label>
          {ComponentByType(value, setFieldValue)}
        </FormGroup>
      )}
    </Field>
  );
};

FieldInput.defaultProps = {
  isDotIcon: false,
  disabled: false,
  markRequired: false,
};

FieldInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  rows: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]),
  options: PropTypes.arrayOf(PropTypes.shape()),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isDotIcon: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  timeCaption: PropTypes.string,
  timeIntervals: PropTypes.number,
  dataCy: PropTypes.string,
  min: PropTypes.number,
  markRequired: PropTypes.bool,
  max: PropTypes.number,
  format: PropTypes.string,
};

export default FieldInput;
