import dataService from 'services/dataService';
import { API_PATIENTS_COVID_STATUS } from 'services/dataService/resources';

const covid = {
  state: {},
  reducers: {
    setCovid: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch) => ({
    async submit(postData, state) {
      const { error, data } = await dataService.updateOnly(
        API_PATIENTS_COVID_STATUS,
        postData,
      );
      if (!error) {
        dispatch.patient.setProfile({
          ...state.patient.profile,
          covidFlagActive: false,
        });
      }
      return { error, data };
    },
  }),
};

export default covid;
