import React, { useRef } from 'react';
import { Switch } from 'antd';
import classNames from 'classnames';

import s from './styles.module.scss';

import type { ICommonInputProps } from 'infrastructure/interfaces';

interface IBaseSwitchProps extends ICommonInputProps<boolean> {
  size?: 'small';
  checked?: boolean;
  defaultChecked?: boolean;
  labelClassName?: string;
  title?: string;
}

const BaseSwitch: React.FC<IBaseSwitchProps> = (props) => {
  const {
    value,
    disabled,
    readonly,
    onChange,
    className,
    style,
    label,
    labelClassName,
    dataCy = 'base-switch-button',
    size,
    checked,
    defaultChecked,
    title,
  } = props;

  const ref = useRef<any>(null);

  const labelClassNames = classNames(s.label, labelClassName);

  return (
    <div className={s.wrapper}>
      {label && (
        <label className={labelClassNames} htmlFor={ref.current}>
          {label}
        </label>
      )}
      <Switch
        value={value}
        disabled={disabled || readonly}
        className={className}
        style={style}
        onChange={onChange}
        data-cy={dataCy}
        size={size}
        checked={checked}
        defaultChecked={defaultChecked}
        title={title}
      />
    </div>
  );
};

export const BaseSwitchDisplayName = 'BaseSwitch';
BaseSwitch.displayName = BaseSwitchDisplayName;

export default BaseSwitch;
