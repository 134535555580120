import React from 'react';
import { Checkbox } from 'antd';

import type { ICommonInputProps } from 'infrastructure/interfaces';

interface IBaseCheckboxProps extends ICommonInputProps<boolean> {
  defaultChecked?: boolean;
  dataCy?: string;
}

const BaseCheckbox: React.FC<IBaseCheckboxProps> = (props) => {
  const {
    value,
    placeholder,
    disabled,
    onChange,
    className,
    style,
    defaultChecked,
    dataCy,
  } = props;

  return (
    <Checkbox
      data-cy={dataCy}
      checked={value}
      disabled={disabled}
      className={className}
      style={style}
      onChange={(e) => {
        if (onChange) onChange(e.target.checked);
      }}
      defaultChecked={defaultChecked}
    >
      {placeholder}
    </Checkbox>
  );
};

export const BaseCheckboxDisplayName = 'BaseCheckbox';
BaseCheckbox.displayName = BaseCheckboxDisplayName;

export default BaseCheckbox;
