import type { IQuestionGroup } from 'infrastructure/interfaces';

interface IData {
  groups: IQuestionGroup[];
  score: number;
}
// reverse answers order No/Yes to Yes/No
export const reverseQuestionnaireAnswers = (data?: IData) => {
  data?.groups.forEach((group) => {
    group.questions.forEach((question) => {
      if (
        Array.isArray(question.answers) &&
        question.answers[0]?.answer === 'No'
      ) {
        question.answers.reverse();
      }
    });
  });

  return data;
};
