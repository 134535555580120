import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const BuildingIcon = ({ color = COLORS.ARANCIO, ...props }: IIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 2.5C4 2.22386 4.22386 2 4.5 2H5.5C5.77614 2 6 2.22386 6 2.5V3.5C6 3.77614 5.77614 4 5.5 4H4.5C4.22386 4 4 3.77614 4 3.5V2.5Z"
      fill={color}
    />
    <path
      d="M7 2.5C7 2.22386 7.22386 2 7.5 2H8.5C8.77614 2 9 2.22386 9 2.5V3.5C9 3.77614 8.77614 4 8.5 4H7.5C7.22386 4 7 3.77614 7 3.5V2.5Z"
      fill={color}
    />
    <path
      d="M10.5 2C10.2239 2 10 2.22386 10 2.5V3.5C10 3.77614 10.2239 4 10.5 4H11.5C11.7761 4 12 3.77614 12 3.5V2.5C12 2.22386 11.7761 2 11.5 2H10.5Z"
      fill={color}
    />
    <path
      d="M4 5.5C4 5.22386 4.22386 5 4.5 5H5.5C5.77614 5 6 5.22386 6 5.5V6.5C6 6.77614 5.77614 7 5.5 7H4.5C4.22386 7 4 6.77614 4 6.5V5.5Z"
      fill={color}
    />
    <path
      d="M7.5 5C7.22386 5 7 5.22386 7 5.5V6.5C7 6.77614 7.22386 7 7.5 7H8.5C8.77614 7 9 6.77614 9 6.5V5.5C9 5.22386 8.77614 5 8.5 5H7.5Z"
      fill={color}
    />
    <path
      d="M10 5.5C10 5.22386 10.2239 5 10.5 5H11.5C11.7761 5 12 5.22386 12 5.5V6.5C12 6.77614 11.7761 7 11.5 7H10.5C10.2239 7 10 6.77614 10 6.5V5.5Z"
      fill={color}
    />
    <path
      d="M4.5 8C4.22386 8 4 8.22386 4 8.5V9.5C4 9.77614 4.22386 10 4.5 10H5.5C5.77614 10 6 9.77614 6 9.5V8.5C6 8.22386 5.77614 8 5.5 8H4.5Z"
      fill={color}
    />
    <path
      d="M7 8.5C7 8.22386 7.22386 8 7.5 8H8.5C8.77614 8 9 8.22386 9 8.5V9.5C9 9.77614 8.77614 10 8.5 10H7.5C7.22386 10 7 9.77614 7 9.5V8.5Z"
      fill={color}
    />
    <path
      d="M10.5 8C10.2239 8 10 8.22386 10 8.5V9.5C10 9.77614 10.2239 10 10.5 10H11.5C11.7761 10 12 9.77614 12 9.5V8.5C12 8.22386 11.7761 8 11.5 8H10.5Z"
      fill={color}
    />
    <path
      d="M2 1C2 0.447716 2.44771 0 3 0H13C13.5523 0 14 0.447715 14 1V15C14 15.5523 13.5523 16 13 16H3C2.44772 16 2 15.5523 2 15V1ZM13 1L3 1V15H6V12.5C6 12.2239 6.22386 12 6.5 12H9.5C9.77614 12 10 12.2239 10 12.5V15H13V1Z"
      fill={color}
    />
  </svg>
);

export default BuildingIcon;
