import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import withLoader from 'components/organisms/withLoader';
import Button from 'components/atoms/button';
import { scrollToErrorIfExist } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';
import { Formik, Form } from 'formik';
import { Card, CardBody, Table } from 'reactstrap';
import validationSchema from 'components/organisms/adminPanels/users/validationSchema';
import requestBodyGenerator from 'components/organisms/adminPanels/users/requestBodyGenerator';
import PersonalInfo from 'components/organisms/adminPanels/users/components/personalInfo';
import Address from 'components/organisms/adminPanels/users/components/address';
import Emr from 'components/organisms/adminPanels/users/components/emr';
import IRS from 'components/organisms/adminPanels/users/components/irs';
import Registration from 'components/organisms/adminPanels/users/components/registration';
import ProfessionInfo from 'components/organisms/adminPanels/users/components/professionInfo';

const AddPatient = () => {
  const navigate = useNavigate();

  const {
    adminPanelUsers: { getUser, getRoles, addUser },
  } = useDispatch();
  const doctor = useSelector((state) => state.adminPanelUsers.user);
  const [emailAlerts, setEmailAlerts] = useState(false);
  const [smsAlerts, setSmsAlerts] = useState(false);

  useEffect(() => {
    getUser();
    getRoles();
  }, []);

  const {
    clinics: { getClinics },
  } = useDispatch();

  const { getUser: doctorDetailsLoading, addUser: submitLoading } = useSelector(
    (state) => state.loading.effects.adminPanelUsers,
  );

  const submitHandler = async (doctorRequest) => {
    const body = requestBodyGenerator(doctorRequest, emailAlerts, smsAlerts);
    const { data, error } = await addUser(body);
    if (error) return showResult(error);
    if (data) {
      showResult();
      navigate(`/admin/user/${data.guid}`);
    }
  };

  useEffect(() => {
    getClinics();
  }, []);
  return (
    <div className="admin-patient">
      {withLoader(
        doctorDetailsLoading,
        <>
          {doctor && (
            <Card className="card-border">
              <CardBody>
                <div className="admin-patient-header">
                  <span>Add Clinic's User Page</span>
                </div>
                <Formik
                  onSubmit={submitHandler}
                  initialValues={{ ...doctor }}
                  validationSchema={validationSchema}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form>
                      <div className="admin-patient-edit-form">
                        <Table>
                          <tbody>
                            <tr>
                              <td rowSpan={2}>
                                <PersonalInfo errors={errors} />
                              </td>
                              <td>
                                <ProfessionInfo errors={errors} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Registration errors={errors} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Address errors={errors} />
                              </td>
                              <td>
                                <IRS
                                  emailAlert={emailAlerts}
                                  setEmailAlert={setEmailAlerts}
                                  smsAlert={smsAlerts}
                                  setSmsAlert={setSmsAlerts}
                                  errors={errors}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Emr
                                  errors={errors}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="text-center">
                        <Button
                          disabled={submitLoading && errors}
                          type="submit"
                          text="Save"
                          data-cy="save-button"
                          handleClick={scrollToErrorIfExist}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </>,
      )}
    </div>
  );
};

export default AddPatient;
