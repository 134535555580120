import React from 'react';

const ArrowUpIcon = (props) => (
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 1.57298L0.853553 4.36983C0.658291 4.54339 0.341709 4.54339 0.146447 4.36983C-0.0488155 4.19626 -0.0488155 3.91485 0.146447 3.74129L3.64645 0.630175C3.84171 0.456608 4.15829 0.456608 4.35355 0.630175L7.85355 3.74129C8.04882 3.91485 8.04882 4.19626 7.85355 4.36983C7.65829 4.54339 7.34171 4.54339 7.14645 4.36983L4 1.57298Z"
      fill="#413B56"
      {...props}
    />
  </svg>
);

export default ArrowUpIcon;
