import common from 'constants/common';
import { format } from 'date-fns';
import { useRunbookLog } from 'infrastructure/hooks/clinic/use-runbook-logs';
import { useDoctorInfo } from 'infrastructure/hooks/doctor/use-doctor-info';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { formatAuthorName } from 'utils/formatters';
import { parseDateSafe } from 'utils/timeHelper';

const contactMethodOptions = {
  email: 'Email',
  phone: 'Phone',
  sms: 'SMS',
} as const;

const formattedToday = format(new Date(), common.dateFormats.birthDate);

interface Props {
  patientGuid: string;
}

export const useGetClinicEscalationValues = ({ patientGuid }: Props) => {
  const { patientInfo } = usePatientInfo({ patientGuid });
  const { doctorInfo } = useDoctorInfo({
    doctorGuid: patientInfo?.doctorGuid,
  });
  const { runbookLogs, loading, loadRunbookLogs, meta } = useRunbookLog({
    meta: {
      items: 1,
    },
  });
  const author = useAppSelector((state) => state.user);

  const contactMethod = runbookLogs[0]?.contactMethod;
  const phoneOrEmailOfPersonYouAreCalling = runbookLogs[0]?.contactMethodValue;

  const patientContactMethod = runbookLogs[0]?.contactMethod;
  const contactMethodValue = runbookLogs[0]?.contactMethodValue;
  const criticalBusinessHours = runbookLogs[0]?.criticalBusinessHours;
  const criticalAfterHours = runbookLogs[0]?.criticalAfterHours;
  const afterHours = runbookLogs[0]?.afterHours;
  const businessHours = runbookLogs[0]?.businessHours;
  const notes = runbookLogs[0]?.notes;
  const dateNoteLogged = runbookLogs[0]?.dateNoteLogged;
  const clinicGuid = patientInfo?.clinicGuid;
  const nurseName = formatAuthorName(author);
  const agentEmail = author.email;
  const patientFirstPhone = patientInfo?.loginInfo?.phone;
  const patientSecondPhone =
    patientInfo?.homeNumber || patientInfo?.emergencyContactNumber;
  const birthDate = parseDateSafe(patientInfo?.loginInfo?.birthDate);
  const patientBirthDate = birthDate
    ? format(birthDate, common.dateFormats.birthDate)
    : '';
  const patientName = formatAuthorName({
    firstName: patientInfo?.loginInfo?.firstName,
    lastName: patientInfo?.loginInfo?.lastName,
    professionType: '',
    title: patientInfo?.loginInfo?.title,
  });
  const doctorName = formatAuthorName({
    firstName: doctorInfo?.firstName,
    lastName: doctorInfo?.lastName,
    professionType: doctorInfo?.professionType,
    title: doctorInfo?.title,
  });
  const doctorGuid = doctorInfo?.guid;
  const patientPreferContactMethod = patientInfo?.preferredContactMethod;
  const preferredContactMethod = patientPreferContactMethod
    ? contactMethodOptions[patientPreferContactMethod]
    : undefined;

  const defaultClinicEscalationValues = {
    patientGuid,
    patientName,
    nurseName,
    patientBirthDate,
    patientFirstPhone,
    patientSecondPhone,
    contactMethod,
    phoneOrEmailOfPersonYouAreCalling,
    notes,
    dateNoteLogged,
    criticalBusinessHours,
    criticalAfterHours,
    afterHours,
    businessHours,
    doctorName,
    doctorGuid,
    preferredContactMethod,
    patientContactMethod,
    contactMethodValue,
  };

  const defaultHospitalPreventionValues = {
    patientGuid,
    patientName,
    agentName: nurseName,
    agentEmail,
    dateOfInstance: formattedToday,
    contactMethod: patientContactMethod,
    contactMethodValue,
    notes,
    dateNoteLogged,
    criticalBusinessHours,
    criticalAfterHours,
    afterHours,
    businessHours,
    doctorName,
    doctorGuid,
    preferredContactMethod,
  };

  const loadRunbookLogsData = async (pageParam: number) => {
    await loadRunbookLogs(
      {
        ...meta,
        page: pageParam,
        clinicGuid,
      },
      true,
    );
  };

  useEffect(() => {
    if (clinicGuid) loadRunbookLogsData(1);
  }, [clinicGuid]);

  return {
    defaultClinicEscalationValues,
    defaultHospitalPreventionValues,
    loading,
  };
};
