import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardBody, Table } from 'reactstrap';
import Pagination from 'components/molecules/paginationTransition';
import withLoader from 'components/organisms/withLoader';
import common from 'constants/common';
import { showResult } from 'infrastructure/hooks/utils';
import { useCommentGenerationLimit } from 'utils/hooks/dashboard/useCommentGenerationLimit';

import ActivityRow from '../realTimePatientReadings/activityRow';

const Activity = ({ onCallPatients }) => {
  const [toggleAssignLoadingByPatient, setToggleAssignLoadingByPatient] =
    useState([]);
  const activity = useSelector((state) => state.doctor);
  const { reviewed } = activity;
  const {
    getActivities: activitiesLoading,
    submitComment: submitCommentLoading,
    actionReview: actionReviewLoading,
  } = useSelector((state) => state.loading.effects.doctor);

  const {
    doctor: {
      setActivitiesPage,
      submitComment,
      actionReview,
      togglePatientReserved,
    },
    comments: { submit: submitConnectComment },
  } = useDispatch();

  const setPage = (pageOffset) => {
    setActivitiesPage({ pageOffset });
  };

  const handleCommentSubmit = async (id, deviceId, values) => {
    const postData = {
      readingId: id,
      deviceId,
      message: values.comment,
      generatedByModel: values.generatedByModel,
      originalGeneratedText: values.originalGeneratedText,
    };
    const { error } = await submitComment(postData);
    if (error) {
      showResult(error);
    }
  };

  const handleReviewedCheck = async (id, deviceId) => {
    const postData = {
      readingId: id,
      deviceId,
      reviewed: true,
    };
    const { error } = await actionReview(postData);
    showResult(error, common.submitSuccessTypes.reviewed);
  };
  const columnNumber = (index) =>
    activity.page * activity.itemsPerPage + index + 1 - activity.itemsPerPage;

  const { isCommentGenerationLimitReached, onCommentGenerationStatusChange } =
    useCommentGenerationLimit();

  return (
    <div className="activity ">
      <Card className="card-border">
        <CardBody className="card-outer">
          <div className="activity-header">
            <h3>Real time patient readings</h3>
          </div>
          {withLoader(
            activitiesLoading,
            <div className="activity-body">
              <Table borderless cellSpacing="0" cellPadding="0">
                <thead>
                  <tr>
                    <th className="table-status-column">№</th>
                    <th className="table-status-column">Status</th>
                    <th className="table-patient-column">Patient</th>
                    {!reviewed && (
                      <th className="table-reviewed-column">Reviewed</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {activity.data.map((activityRow, index) => (
                    <ActivityRow
                      isCommentGenerationLimitReached={
                        isCommentGenerationLimitReached
                      }
                      onCommentGenerationStatusChange={
                        onCommentGenerationStatusChange
                      }
                      key={`${activityRow.id}-${index}`}
                      columnNumber={columnNumber(index)}
                      activity={activityRow}
                      handleCommentSubmit={handleCommentSubmit}
                      handleReviewedCheck={handleReviewedCheck}
                      reviewed={reviewed}
                      togglePatientReserved={togglePatientReserved}
                      setReviewedLoading={actionReviewLoading}
                      submitCommentLoading={
                        submitCommentLoading || actionReviewLoading
                      }
                      // thresholds={thresholds}
                      submitConnectComment={submitConnectComment}
                      isOnCall={
                        onCallPatients.includes(activityRow.patient.guid) ||
                        activityRow.patient.isOnCall
                      }
                      toggleAssignLoading={toggleAssignLoadingByPatient.find(
                        (item) => item === activityRow.patient.guid,
                      )}
                      setToggleAssignLoading={({ patientGuid, state }) => {
                        if (state === true) {
                          setToggleAssignLoadingByPatient((oldValue) => {
                            return [...oldValue, patientGuid];
                          });
                        } else {
                          setToggleAssignLoadingByPatient((oldValue) => {
                            return oldValue.filter(
                              (item) => item !== patientGuid,
                            );
                          });
                        }
                      }}
                    />
                  ))}
                </tbody>
              </Table>
            </div>,
          )}
          <Pagination
            title=""
            page={activity.page}
            pageTotal={activity.data.length}
            itemsPerPage={activity.itemsPerPage}
            total={activity.totalCount}
            handleFirstClick={() => setPage(-(activity.page - 1))}
            handleLastClick={() =>
              setPage(Math.ceil(activity.totalCount / activity.itemsPerPage))
            }
            handlePrevClick={() => setPage(-1)}
            handleNextClick={() => setPage(+1)}
            loading={activitiesLoading}
          />
        </CardBody>
      </Card>
    </div>
  );
};

Activity.propTypes = {
  onCallPatients: PropTypes.array,
};

export default Activity;
