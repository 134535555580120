const userRequestGenerator = (
  {
    guid,
    title,
    firstName,
    lastName,
    middleName,
    birthDate,
    mobileNumber,
    workNumber,
    email,
    primaryLanguage,
    secondaryLanguage,
    addressLine1,
    addressLine2,
    state,
    city,
    zip,
    npiNumber,
    emrName,
    emrId1,
    emrId2,
    renderingProviderId,
    supervisingProviderId,
    professionType,
    emrReadingsFlag,
    emrReports,
    emrBilling,
    emrBillingCycle,
    roles,
    clinics,
    timezone,
    status,
  },
  emailAlerts,
  smsAlerts,
) => {
  const body = {
    guid,
    title,
    firstName,
    lastName,
    middleName,
    birthDate: birthDate || undefined,
    mobileNumber,
    workNumber,
    email,
    primaryLanguage,
    secondaryLanguage,
    addressLine1,
    addressLine2,
    state,
    city,
    zip,
    npiNumber,
    emrName,
    emrId1,
    emrId2,
    renderingProviderId,
    supervisingProviderId,
    emrReadingsFlag: emrReadingsFlag || false,
    emrReports,
    emrBilling: emrBilling || false,
    emrBillingCycle,
    alertEmail: emailAlerts,
    alertSms: smsAlerts,
    professionType,
    roles: roles?.map(({ value }) => value),
    timezone,
    clinics: clinics?.map(({ value }) => value),
    status,
  };
  return body;
};
export default userRequestGenerator;
