// TODO rewrite to BaseReportTable

import BaseTableNew from 'components/atoms/base-table/new';
import {
  ADMIN_PANEL_PATIENTS_PER_PAGE,
  useGetAdminPanelPatients,
} from 'infrastructure/hooks/patient/use-admin-panel-patients';
import BasePagination from 'components/atoms/base-pagination';
import { useTranslation } from 'react-i18next';
import EmptyTable from 'components/atoms/base-table/report/empty';

import { adminPanelPatientsColumns } from './columns';
import s from './styles.module.scss';
import { usePatientsFilter } from '../patients-filter/use-patients-filter';

const AdminPatientsTable = () => {
  const { filter, updateQueryParams } = usePatientsFilter();
  const {
    status,
    page,
    ccmStatus,
    patientGuid,
    clinicGuid,
    excludeClinicGuid,
    allExcluded,
  } = filter;
  const { patients, isFetching } = useGetAdminPanelPatients({
    status,
    page,
    ccmStatus,
    clinicGuid,
    excludeClinicGuid,
    patientGuid,
  });
  const { t } = useTranslation();
  const tableItems = allExcluded ? [] : patients?.items ?? [];

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <p className={s.patients}>
          {t('labels.patients')}: <span>{patients?.count ?? ''}</span>
        </p>
        <BasePagination
          page={filter?.page}
          total={patients?.count}
          align="end"
          onChange={async (newPage) => {
            updateQueryParams({ page: newPage });
          }}
          pageSize={ADMIN_PANEL_PATIENTS_PER_PAGE}
          pageSizeOptions={[]}
          hide={false}
          showSizeChanger={false}
        />
      </div>
      <div className={s.divider} />
      <BaseTableNew
        columns={adminPanelPatientsColumns}
        bordered
        loading={isFetching}
        items={tableItems}
        pagination={{
          current: page,
          pageSize: ADMIN_PANEL_PATIENTS_PER_PAGE,
          total: patients?.count ?? 0,
          position: ['bottomCenter'],
        }}
        emptyText={<EmptyTable />}
        className={s.table}
        onChangePage={(newPage) => {
          updateQueryParams({ page: newPage });
        }}
      />
    </div>
  );
};
export default AdminPatientsTable;
