import BaseTableActions from 'components/atoms/base-table/actions';
import i18next from 'i18next';
import { DateFormats } from 'infrastructure/enums';
import { DateFormatter } from 'infrastructure/functions';

import s from './styles.module.scss';

import type { PatientIcdCode } from 'infrastructure/classes/patient-icd-code';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

interface IConditionsColumnsProps {
  onEdit: (record: PatientIcdCode) => void;
  onDelete: (record: PatientIcdCode) => void;
}

export const conditionsColumns = ({
  onEdit,
  onDelete,
}: IConditionsColumnsProps): IBaseTableColumns<PatientIcdCode> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'createdAt',
      width: '120px',
      render: (_, record) =>
        DateFormatter({
          date: record.createdAt,
          format: DateFormats['MM-dd-yyyy HH:mm:ss'],
        }),
    },
    {
      label: i18next.t('labels.icdCode'),
      key: 'icdCode.code',
      width: '120px',
      render: (_, record) => record.icdCode.code,
    },
    {
      label: i18next.t('labels.description'),
      key: 'description',
      render: (value) => {
        return <div className={s.description}>{value}</div>;
      },
    },
    {
      label: i18next.t('labels.actions'),
      key: 'actions',
      align: 'center',
      width: '120px',
      render: (_, record) => (
        <BaseTableActions
          onEdit={() => onEdit(record)}
          onDelete={() => onDelete(record)}
        />
      ),
    },
  ];
};
