import React from 'react';
import Select from 'react-select';

import SelectDropdownIndicator from '../selectDropdownIndicator';

import type { GroupBase, Props as SelectProps } from 'react-select';

const BaseSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group>,
) => {
  return (
    <Select
      components={{ DropdownIndicator: SelectDropdownIndicator }}
      {...props}
    />
  );
};

export default BaseSelect;
