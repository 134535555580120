import { useState } from 'react';
import { PatientIcdCode } from 'infrastructure/classes/patient-icd-code';
import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { replaceOrAddObject } from 'infrastructure/functions';

import useApi from '../use-api';

import type {
  IPatientIcdCode,
  ICreatePatientIcdCode,
  IDeletePatientIcdCode,
  IUpdatePatientIcdCode,
  IPatientIcdCodePaginatedData,
} from 'infrastructure/interfaces';

type patientIcdCodes = Array<PatientIcdCode>;

const patientIcdCodesApi = `core/ccm/diagnoses`;
const patientIcdCodesByGuidApi = (guid: string) =>
  `${patientIcdCodesApi}/${guid}`;

type UsePatientIcdCode = {
  loading: boolean;
  patientIcdCodes: patientIcdCodes;
  loadPatientIcdCodes: () => Promise<patientIcdCodes>;
  createPatientIcdCode: (body: ICreatePatientIcdCode) => Promise<void>;
  updatePatientIcdCode: (
    guid: string,
    body: IUpdatePatientIcdCode,
  ) => Promise<void>;
  deletePatientIcdCode: (
    guid: string,
    body: IDeletePatientIcdCode,
  ) => Promise<void>;
};

interface IUsePatientIcdCodeProps {
  patientGuid: string;
}

export const usePatientIcdCode = (
  props: IUsePatientIcdCodeProps,
): UsePatientIcdCode => {
  const { patientGuid } = props;

  const [patientIcdCodes, setPatientIcdCodes] = useState<Array<PatientIcdCode>>(
    [],
  );

  const {
    loading,
    isMounted,
    loadData,
    createData,
    updateData,
    deleteDataWithBody,
  } = useApi();

  const queryClient = useQueryClient();
  const queryKey = ['patientIcdCode', patientGuid];

  const loadPatientIcdCodes = async () => {
    const data = await queryClient.fetchQuery(
      queryKey,
      () =>
        loadData<IPatientIcdCodePaginatedData>(patientIcdCodesApi, {
          patientGuid,
        }).then((res) => {
          if (res) {
            return res.items.map((el) => new PatientIcdCode(el));
          }
        }),
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      setPatientIcdCodes(data);
      return data;
    }
    return [];
  };

  const createPatientIcdCode = async (body: ICreatePatientIcdCode) => {
    const data = await createData<IPatientIcdCode>(patientIcdCodesApi, body);

    setPatientIcdCodes([...patientIcdCodes, new PatientIcdCode(data)]);

    queryClient.setQueryData<patientIcdCodes>(queryKey, (oldData) => [
      ...(oldData ?? []),
      new PatientIcdCode(data),
    ]);
  };

  const updatePatientIcdCode = async (
    guid: string,
    body: IUpdatePatientIcdCode,
  ) => {
    const data = await updateData<IPatientIcdCode>(
      patientIcdCodesByGuidApi(guid),
      body,
    );

    const newData = replaceOrAddObject({
      array: patientIcdCodes,
      field: 'guid',
      value: guid,
      newObject: new PatientIcdCode(data),
    });

    setPatientIcdCodes(newData);

    queryClient.setQueryData<patientIcdCodes>(queryKey, (oldData) =>
      replaceOrAddObject({
        array: oldData ?? [],
        field: 'guid',
        value: guid,
        newObject: new PatientIcdCode(data),
      }),
    );
  };

  const deletePatientIcdCode = async (
    guid: string,
    body: IDeletePatientIcdCode,
  ) => {
    await deleteDataWithBody(patientIcdCodesByGuidApi(guid), body).then(() => {
      setPatientIcdCodes(patientIcdCodes.filter((el) => el.guid !== guid));
      queryClient.setQueryData<patientIcdCodes>(queryKey, (oldData) =>
        oldData?.filter((el) => el.guid !== guid),
      );
    });
  };

  return {
    loading,
    patientIcdCodes,
    loadPatientIcdCodes,
    createPatientIcdCode,
    updatePatientIcdCode,
    deletePatientIcdCode,
  };
};
