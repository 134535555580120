import React from 'react';

const AddButtonIcon = () => (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="27.5" cy="27.5" r="27.5" fill="#FB9318" />
    <path
      d="M27.5008 19V36"
      stroke="white"
      strokeWidth="2.9"
      strokeLinecap="round"
    />
    <path
      d="M19 27.4992H36"
      stroke="white"
      strokeWidth="2.9"
      strokeLinecap="round"
    />
  </svg>
);

export default AddButtonIcon;
