import React, { useEffect, useState } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseInput from 'components/atoms/base-input';
import { useFormik } from 'formik';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import BaseDatepicker from 'components/atoms/base-datepicker';
import GenderSelectBox from 'components/molecules/gender-select-box';
import RaceSelectBox from 'components/molecules/race-select-box';
import MaritalStatusSelectBox from 'components/molecules/marital-status-select-box';
import LanguageSelectBox from 'components/molecules/language-select-box';
import WorkingStatusSelectBox from 'components/molecules/working-status-select-box';
import TitleSelectBox from 'components/molecules/title-select-box';
import BaseFormItemGroup from 'components/atoms/base-form/item-group';
import BaseGrid from 'components/atoms/base-grid';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { useEdit } from 'infrastructure/providers/edit-provider';
import { usePatientDevices } from 'infrastructure/hooks/device/use-patient-devices';
import { prepareArrToCopy } from 'infrastructure/functions/prepare-arr-to-copy';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import classNames from 'classnames';
import { isMobile } from 'utils/deviceHelper';
import useUser from 'utils/useUser';

import s from './styles.module.scss';
import { adminPanelPatientInfoValidationSchema } from './validation-schema';

import type { Patient } from 'infrastructure/classes/patient/patient';

const formKey = 'AdminPanelPatientInfoForm';

interface IAdminPanelPatientInfoFormProps {
  data: Patient;
  loading?: boolean;
  readOnly: boolean;
}

const AdminPanelPatientInfoForm: React.FC<IAdminPanelPatientInfoFormProps> = (
  props,
) => {
  const { data, loading: loadingProp = false, readOnly } = props;

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { canEdit, setEdit } = useEdit();

  const { t } = useTranslation();

  const { loading, updatePatientInfo } = usePatientInfo({
    patientGuid: data.guid,
  });
  const { deviceType } = useUser();
  const { refetch } = usePatientDevices({
    patientGuid: data.guid,
    initialLoading: false,
  });

  const formik = useFormik({
    initialValues: data,
    onSubmit: async (values) => {
      await updatePatientInfo(values).then(() => {
        formik.resetForm({ values });
      });
      if (data.status !== values.status) {
        refetch();
      }
      setIsEdit(false);
      setEdit(false);
    },
    validationSchema: adminPanelPatientInfoValidationSchema(),
    validateOnChange: false,
  });

  const copyText = prepareArrToCopy([
    formik.values.loginInfo?.firstName,
    formik.values.loginInfo?.lastName,
    DateFormatter({
      date: formik.values.loginInfo?.birthDate,
      format: DateFormats['MM-dd-yyyy'],
    }),
    formik.values.guid,
    formik.values.loginInfo?.phone,
    formik.values.loginInfo?.email,
    formik.values.address,
    formik.values?.loginInfo?.city,
    formik.values?.loginInfo?.state,
    formik.values?.loginInfo?.zipPostalCode,
  ]);

  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };
  const onSave = () => {
    formik.submitForm();
  };
  const onCancel = () => {
    formik.resetForm();
    setIsEdit(false);
    setEdit(false);
  };

  const selectBoxPlaceholder = isEdit ? undefined : '';

  useEffect(() => {
    formik.resetForm({ values: data });
  }, [data]);

  return (
    <PatientBlock
      title={t('labels.patientInfo')}
      isEdit={isEdit}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      canEdit={!readOnly}
      loading={loading || loadingProp}
      disabled={!canEdit(formKey)}
      copyText={copyText}
    >
      <BaseForm formik={formik} plaintext={!isEdit} readonly={!isEdit}>
        <BaseGrid>
          <BaseFormItemGroup
            className={s['border-bottom']}
            label={t('labels.personalInfo')}
          >
            <BaseGrid columns={2} className={s['inputs-wrapper']}>
              <BaseFormItem
                required
                name="loginInfo.title"
                label={t('labels.title')}
                className={classNames({ [s['input-wrapper']]: !isEdit })}
                showErrorBlock={isMobile(deviceType) ? isEdit : true}
              >
                <TitleSelectBox placeholder={selectBoxPlaceholder} />
              </BaseFormItem>
              <BaseFormItem
                name="loginInfo.firstName"
                label={t('labels.firstName')}
                className={classNames({ [s['input-wrapper']]: !isEdit })}
                showErrorBlock={isMobile(deviceType) ? isEdit : true}
              >
                <BaseInput />
              </BaseFormItem>
              <BaseFormItem
                name="loginInfo.middleName"
                label={t('labels.middleName')}
                className={classNames({ [s['input-wrapper']]: !isEdit })}
                showErrorBlock={isMobile(deviceType) ? isEdit : true}
              >
                <BaseInput />
              </BaseFormItem>
              <BaseFormItem
                name="loginInfo.lastName"
                label={t('labels.lastName')}
                className={classNames({ [s['input-wrapper']]: !isEdit })}
                showErrorBlock={isMobile(deviceType) ? isEdit : true}
              >
                <BaseInput />
              </BaseFormItem>
              <BaseFormItem
                name="loginInfo.birthDate"
                label={t('labels.dob')}
                className={classNames({ [s['input-wrapper']]: !isEdit })}
                showErrorBlock={isMobile(deviceType) ? isEdit : true}
              >
                <BaseDatepicker />
              </BaseFormItem>
            </BaseGrid>
          </BaseFormItemGroup>
          <BaseFormItemGroup
            label={t('labels.characteristics')}
            className={classNames({
              [s['border-bottom']]: isMobile(deviceType),
            })}
          >
            <BaseGrid columns={2} className={s['inputs-wrapper']}>
              <BaseFormItem
                name="gender"
                label={t('labels.gender')}
                className={classNames({ [s['input-wrapper']]: !isEdit })}
                showErrorBlock={isMobile(deviceType) ? isEdit : true}
              >
                <GenderSelectBox placeholder={selectBoxPlaceholder} />
              </BaseFormItem>
              <BaseFormItem
                name="loginInfo.language"
                label={t('labels.preferredLanguage')}
                className={classNames({ [s['input-wrapper']]: !isEdit })}
                showErrorBlock={isMobile(deviceType) ? isEdit : true}
              >
                <LanguageSelectBox placeholder={selectBoxPlaceholder} />
              </BaseFormItem>
              <BaseFormItem
                name="race"
                label={t('labels.race')}
                className={classNames({ [s['input-wrapper']]: !isEdit })}
                showErrorBlock={isMobile(deviceType) ? isEdit : true}
              >
                <RaceSelectBox
                  maxWidth={isMobile(deviceType) ? undefined : '260px'}
                  placeholder={selectBoxPlaceholder}
                />
              </BaseFormItem>
              <BaseFormItem
                name="ethnicity"
                label={t('labels.ethnicity')}
                className={classNames({ [s['input-wrapper']]: !isEdit })}
                showErrorBlock={isMobile(deviceType) ? isEdit : true}
              >
                <BaseInput />
              </BaseFormItem>
              <BaseFormItem
                name="martialStatus"
                label={t('labels.maritalStatus')}
                className={classNames({ [s['input-wrapper']]: !isEdit })}
                showErrorBlock={isMobile(deviceType) ? isEdit : true}
              >
                <MaritalStatusSelectBox placeholder={selectBoxPlaceholder} />
              </BaseFormItem>
              <BaseFormItem
                name="workingStatus"
                label={t('labels.workingStatus')}
                className={classNames({ [s['input-wrapper']]: !isEdit })}
                showErrorBlock={isMobile(deviceType) ? isEdit : true}
              >
                <WorkingStatusSelectBox placeholder={selectBoxPlaceholder} />
              </BaseFormItem>
            </BaseGrid>
          </BaseFormItemGroup>
        </BaseGrid>
      </BaseForm>
    </PatientBlock>
  );
};

export default AdminPanelPatientInfoForm;
