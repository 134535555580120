import type { FC } from 'react';

const FilterIcon: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C12 20.4477 12.4477 20 13 20H19C19.5523 20 20 20.4477 20 21C20 21.5523 19.5523 22 19 22H13C12.4477 22 12 21.5523 12 21Z"
        fill="currentColor"
      />
      <path
        d="M8 15C8 14.4477 8.44772 14 9 14H23C23.5523 14 24 14.4477 24 15C24 15.5523 23.5523 16 23 16H9C8.44772 16 8 15.5523 8 15Z"
        fill="currentColor"
      />
      <path
        d="M4 9C4 8.44772 4.44772 8 5 8H27C27.5523 8 28 8.44772 28 9C28 9.55228 27.5523 10 27 10H5C4.44772 10 4 9.55228 4 9Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FilterIcon;
