import React, { useState } from 'react';
import { Container } from 'reactstrap';
import AdherenceReportTable from 'components/templates/rpm/practice/adherenceReport/table';
import { useTranslation } from 'react-i18next';
import DownloadButton from 'components/atoms/buttons/download';
import withLoader from 'components/organisms/withLoader';
import BaseCard from 'components/atoms/base-card';
import BaseTitle from 'components/atoms/base-title';
import { useReports } from 'infrastructure/hooks';
import { remove } from 'lodash';
import { getUTCDates } from 'utils';
import { endOfMonth, startOfMonth } from 'date-fns';

import s from './styles.module.scss';

import type { IUseAdherenceMeta } from 'infrastructure/hooks';
import type { IAdherenceReportFilters } from 'infrastructure/interfaces';

const today = new Date();
const [startTime, endTime] = getUTCDates([
  startOfMonth(today),
  endOfMonth(today),
]);

const DEFAULT_FILTER = {
  startTime: startTime.getTime(),
  endTime: endTime.getTime(),
  doctorGuids: [],
};

const AdherenceReport = () => {
  const [filters, setFilters] = useState<IAdherenceReportFilters | undefined>(
    DEFAULT_FILTER,
  );
  const { loading, createAdherenceReport } = useReports();

  const { t } = useTranslation();

  const onFilterChange = (changeFilters: IUseAdherenceMeta) => {
    setFilters({
      doctorGuids: changeFilters.doctorGuid?.split(',') ?? [],
      startTime: changeFilters.startTime!,
      endTime: changeFilters.endTime!,
    });
  };

  const createReport = async () => {
    if (filters) {
      remove(filters.doctorGuids, (guid) => guid === '');

      await createAdherenceReport(filters);
    }
  };

  return (
    <Container>
      <BaseCard>
        <div className={s.header}>
          <BaseTitle>{t('labels.reportAdherence')}</BaseTitle>
          {withLoader(loading, <DownloadButton onClick={createReport} />)}
        </div>

        <AdherenceReportTable onFilterChange={onFilterChange} />
      </BaseCard>
    </Container>
  );
};

export default AdherenceReport;
