import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table } from 'reactstrap';
import withLoader from 'components/organisms/withLoader';

import AgencyRow from './components/agencyRow';
import AgencyToolbar from './components/toolbar';
import AgencyHeader from './components/header';
import AgencyFooter from './components/footer';

const AdminPanelAgencies = ({ agencies, loading }) => (
  <div className="adminPanel">
    <Card className="card-border">
      <CardBody className="card-outer">
        <AgencyHeader />
        <AgencyToolbar />
        <div className="adminPanel__table">
          {withLoader(
            loading,
            <Table bordered cellSpacing="0">
              <thead>
                <tr>
                  <th>
                    <div className="adminPanel__row">HHA Name</div>
                  </th>
                  <th>
                    <div className="adminPanel__row ">Email</div>
                  </th>
                  <th>
                    <div className="adminPanel__row ">HHA Id</div>
                  </th>
                  <th>
                    <div className="adminPanel__row ">Address</div>
                  </th>
                  <th>
                    <div className="adminPanel__row ">Entry Time</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {agencies &&
                  agencies.map((agencyRow, index) => (
                    <AgencyRow
                      key={`${agencyRow?.id}-${index}`}
                      data={agencyRow}
                    />
                  ))}
              </tbody>
            </Table>,
          )}
        </div>
        <AgencyFooter />
      </CardBody>
    </Card>
  </div>
);

AdminPanelAgencies.propTypes = {
  agencies: PropTypes.arrayOf({
    guid: PropTypes.string,
    legalname: PropTypes.string,
    email: PropTypes.email,
    agencyId: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

export default AdminPanelAgencies;
