import { prepareDoctorName } from 'infrastructure/functions';

import type { CommentType } from 'infrastructure/enums';
import type { ICallComment } from 'infrastructure/interfaces';
import type { DateString } from 'infrastructure/types';

export class CallComment {
  callId: string;
  type: CommentType;
  message: string;
  createdAt: DateString;
  createdBy: string;
  createdByTitle?: string;
  createdByProfessionType?: string;
  createdByFirstName?: string;
  createdByLastName?: string;
  createdFullName: string;

  constructor(data: ICallComment) {
    this.callId = data.callId;
    this.type = data.type;
    this.message = data.message;
    this.createdAt = data.createdAt;
    this.createdBy = data.createdBy;
    this.createdByTitle = data.createdByTitle;
    this.createdByProfessionType = data.createdByProfessionType;
    this.createdByFirstName = data.createdByFirstName;
    this.createdByLastName = data.createdByLastName;
    this.createdFullName = prepareDoctorName({
      firstName: data.createdByFirstName,
      lastName: data.createdByLastName,
      professionType: data.createdByProfessionType,
      title: data.createdByTitle,
    });
  }
}
