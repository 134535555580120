import {
  TimeZones,
  genders,
  maritalStatuses,
  insuranceProviders,
  workingStatuses,
  races,
  insuranceTypes,
} from 'constants/forms';
import languages from 'constants/languages';

import { usStatesWithNone } from '../../../../../constants/states';

const appendItemIdFromValue = (options) =>
  options.map((item) => ({ ...item, id: item.value }));

const internalAdmissionFormInsuranceProviders =
  appendItemIdFromValue(insuranceProviders);

const internalAdmissionFormInsuranceTypes =
  appendItemIdFromValue(insuranceTypes);

export const conditions = [
  {
    id: 'diabetes',
    label: 'Diabetes',
    value: 'Diabetes',
  },
  {
    id: 'hypertension',
    label: 'Hypertension',
    value: 'Hypertension',
  },
  {
    id: 'Other',
    label: 'Other',
    value: 'Other',
  },
];

export const answers = [
  { id: 'yes', label: 'Yes', value: 'Yes' },
  { id: 'no', label: 'No', value: 'No' },
];

export const providedBy = [
  { id: 'patient', label: 'Patient', value: 'patient' },
  { id: 'caregiver', label: 'Caregiver', value: 'caregiver' },
];
export default {
  generalInformation: {
    title: 'General Information:',
    fields: {
      firstName: {
        id: 'firstName',
        type: 'text',
        label: 'First Name',
        required: true,
      },
      lastName: {
        id: 'lastName',
        type: 'text',
        label: 'Last Name',
        required: true,
      },
      dateOfBirth: {
        id: 'dateOfBirth',
        label: 'Date of Birth',
        type: 'date',
        required: true,
      },
      gender: {
        id: 'gender',
        label: 'Gender',
        type: 'select',
        required: true,
        options: [...genders],
      },
      street: {
        id: 'street',
        label: 'Street',
        type: 'text',
        required: true,
      },
      city: {
        id: 'city',
        label: 'City',
        type: 'text',
        required: true,
      },
      state: {
        id: 'state',
        label: 'State',
        type: 'select',
        options: usStatesWithNone,
        disabled: false,
        required: true,
      },
      zip: {
        id: 'zip',
        label: 'Zip',
        type: 'text',
        required: true,
      },
      patientPrimaryPhone: {
        id: 'patientPrimaryPhone',
        label: 'Primary Phone',
        type: 'text',
        required: true,
      },
      patientSecondaryPhone: {
        id: 'patientSecondaryPhone',
        label: 'Secondary Phone',
        type: 'text',
      },
      patientEmail: {
        id: 'patientEmail',
        label: 'Patient Email',
        type: 'text',
      },
      insurances: {
        label: 'Insurances',
        id: 'insurances',
        type: 'group',
        items: {
          insuranceProvider: {
            id: 'insuranceProvider',
            label: 'Insurance Provider',
            type: 'select',
            required: true,
            options: [...internalAdmissionFormInsuranceProviders],
          },
          insuranceType: {
            id: 'insuranceType',
            label: 'Insurance Type',
            type: 'select',
            options: [...internalAdmissionFormInsuranceTypes],
          },
          insuranceNumber: {
            id: 'insuranceNumber',
            label: 'Insurance Number',
            type: 'text',
            required: true,
            note: 'Add Multiple Insurance if applicable (i.e 1234567 / BA34566)',
          },
        },
        emptyMessage: 'Patient does not have insurances.',
        appendFields: null,
      },
      primaryLanguage: {
        id: 'primaryLanguage',
        label: 'Preferred Language',
        type: 'select',
        required: true,
        options: [...languages],
      },
      race: {
        id: 'race',
        label: 'Race',
        type: 'select',
        required: true,
        options: [...races],
      },
      ethnicity: {
        id: 'ethnicity',
        label: 'Ethnicity',
        type: 'text',
        required: true,
      },
      workingStatus: {
        id: 'workingStatus',
        type: 'select',
        label: 'Working Status',
        note: 'If unknown or disabled, select Retired',
        required: true,
        options: [...workingStatuses],
      },
      timeZone: {
        id: 'timeZone',
        label: 'Time Zone',
        type: 'select',
        required: true,
        options: [...TimeZones],
      },
      maritalStatus: {
        id: 'maritalStatus',
        label: 'Marital Status',
        type: 'select',
        required: true,
        options: [...maritalStatuses],
      },
      physicianFirstName: {
        id: 'physicianFirstName',
        label: 'Physician First Name',
        type: 'text',
        required: true,
      },
      physicianLastName: {
        id: 'physicianLastName',
        label: 'Physician Last Name',
        type: 'text',
        required: true,
      },
      physicianNPI: {
        id: 'physicianNPI',
        label: 'Physician NPI',
        type: 'text',
      },
      patientWillingToProvideAnEmergencyContact: {
        id: 'patientWillingToProvideAnEmergencyContact',
        label:
          'Is the patient willing to provide an emergency contact at this time?',
        type: 'select',
        options: [...answers],
        required: true,
      },
      emergencyContactCaregiverFirstName: {
        id: 'emergencyContactCaregiverFirstName',
        label: 'Emergency Contact / Caregiver First Name',
        type: 'text',
        requiredIf: {
          key: 'patientWillingToProvideAnEmergencyContact',
          value: 'yes',
        },
      },
      emergencyContactCaregiverLastName: {
        id: 'emergencyContactCaregiverLastName',
        label: 'Emergency Contact / Caregiver Last Name',
        type: 'text',
        requiredIf: {
          key: 'patientWillingToProvideAnEmergencyContact',
          value: 'yes',
        },
      },
      emergencyContactCaregiverPhone: {
        id: 'emergencyContactCaregiverPhone',
        label: 'Emergency Contact / Caregiver Phone',
        type: 'text',
        requiredIf: {
          key: 'patientWillingToProvideAnEmergencyContact',
          value: 'yes',
        },
      },
      emergencyContactCaregiverRelationship: {
        id: 'emergencyContactCaregiverRelationship',
        label: 'Emergency Contact / Caregiver Relationship',
        type: 'text',
        requiredIf: {
          key: 'patientWillingToProvideAnEmergencyContact',
          value: 'yes',
        },
      },
    },
  },
  tmphPatientQuestionnaire: {
    title: 'TMHP - Patient Questionnaire: ',
    fields: {
      doYouHaveAnyOfTheseConditions: {
        id: 'doYouHaveAnyOfTheseConditions',
        label: 'Do you have any of these conditions?',
        type: 'checkbox',
        options: [...conditions],
      },
      doYouAlwaysTakeYourMedicationsAsDirectedByYourPhysician: {
        id: 'doYouAlwaysTakeYourMedicationsAsDirectedByYourPhysician',
        label:
          'Do you always take your medications as directed by your physician?',
        type: 'radio',
        options: [...answers],
      },
      doYouHaveTroubleGettingYourMedications: {
        id: 'doYouHaveTroubleGettingYourMedications',
        label: 'Do you have trouble getting your medications?',
        type: 'radio',
        options: [...answers],
      },
      haveYouBeenHospitalizedInTheLast12Months: {
        id: 'haveYouBeenHospitalizedInTheLast12Months',
        label: 'Have you been hospitalized in the last 12 months?',
        type: 'radio',
        options: [...answers],
      },
      haveYouBeenInTheEmergencyRoomInTheLast6Months: {
        id: 'haveYouBeenInTheEmergencyRoomInTheLast6Months',
        label: 'Have you been in the emergency room in the last 6 months?',
        type: 'radio',
        options: [...answers],
      },
      haveYouHadTwoOrMoreFallsInTheLast6Months: {
        id: 'haveYouHadTwoOrMoreFallsInTheLast6Months',
        label: 'Have you had 2 or more falls in the last 6 months?',
        type: 'radio',
        options: [...answers],
      },
      doYouHaveABloodPressureOrGlucometerForPersonalUse: {
        id: 'doYouHaveABloodPressureOrGlucometerForPersonalUse',
        label: 'Do you have a blood pressure or  glucometer for personal use?',
        type: 'radio',
        options: [...answers],
      },
      doYouLiveAlone: {
        id: 'doYouLiveAlone',
        label: 'Do you live alone?',
        type: 'radio',
        options: [...answers],
      },
      doYouHaveProvider: {
        id: 'doYouHaveProvider',
        label: 'Do you have provider?',
        type: 'radio',
        options: [...answers],
      },
      whoDoYouRelyOnForTransportation: {
        id: 'whoDoYouRelyOnForTransportation',
        label: 'Who do you rely on for transportation?',
        type: 'radio',
        options: [...answers],
      },
      doYouHavePowerOfAttorney: {
        id: 'doYouHavePowerOfAttorney',
        label: 'Do you have power of attorney?',
        type: 'radio',
        options: [...answers],
      },
      powerOfAttorneyFullNameAndPhoneNumber: {
        id: 'powerOfAttorneyFullNameAndPhoneNumber',
        label: '(If Yes) Full Name and Phone Number',
        type: 'text',
        showDepends: {
          field: 'doYouHavePowerOfAttorney',
          handler: (value) => value === 'Yes',
        },
      },
      verbalAuthorizationProvidedBy: {
        id: 'verbalAuthorizationProvidedBy',
        label: 'Verbal Authorization Provided By',
        type: 'radio',
        options: [...providedBy],
      },
      verbalAuthorizationDate: {
        id: 'verbalAuthorizationDate',
        label: 'Verbal Authorization Date',
        type: 'date',
      },
      verbalAuthorizationTime: {
        id: 'verbalAuthorizationTime',
        label: 'Verbal Authorization Time',
        type: 'time',
      },
      lastMdVisit: {
        id: 'lastMdVisit',
        label: 'Last MD Visit',
        type: 'date',
      },
    },
  },
  careCoordination: {
    title: 'Care Coordination (Other Home Health, ADC, PAS, etc): ',
    fields: {
      companyType: {
        id: 'companyType',
        label: 'Company Type',
        type: 'text',
      },
      representativeName: {
        id: 'representativeName',
        label: 'Representative name',
        type: 'text',
      },
      companyNumberCalled: {
        id: 'companyNumberCalled',
        label: 'Company Number Called',
        type: 'text',
      },
      date: {
        id: 'date',
        label: 'Date',
        type: 'date',
      },
      time: {
        id: 'time',
        label: 'Time (approx)',
        type: 'time',
      },
      additionalComments: {
        id: 'additionalComments',
        label: 'Additional Comments (if any)',
        type: 'text',
      },
    },
  },
};
