export const usStates = [
  {
    value: 'Alabama',
    id: 'Alabama',
    label: 'Alabama',
  },
  {
    value: 'Alaska',
    id: 'Alaska',
    label: 'Alaska',
  },
  {
    value: 'American Samoa',
    id: 'American Samoa',
    label: 'American Samoa',
  },
  {
    value: 'Arizona',
    id: 'Arizona',
    label: 'Arizona',
  },
  {
    value: 'Arkansas',
    id: 'Arkansas',
    label: 'Arkansas',
  },
  {
    value: 'California',
    id: 'California',
    label: 'California',
  },
  {
    value: 'Colorado',
    id: 'Colorado',
    label: 'Colorado',
  },
  {
    value: 'Connecticut',
    id: 'Connecticut',
    label: 'Connecticut',
  },
  {
    value: 'Delaware',
    id: 'Delaware',
    label: 'Delaware',
  },
  {
    value: 'District Of Columbia',
    id: 'District Of Columbia',
    label: 'District Of Columbia',
  },
  {
    value: 'Federated States Of Micronesia',
    id: 'Federated States Of Micronesia',
    label: 'Federated States Of Micronesia',
  },
  {
    value: 'Florida',
    id: 'Florida',
    label: 'Florida',
  },
  {
    value: 'Georgia',
    id: 'Georgia',
    label: 'Georgia',
  },
  {
    value: 'Guam',
    id: 'Guam',
    label: 'Guam',
  },
  {
    value: 'Hawaii',
    id: 'Hawaii',
    label: 'Hawaii',
  },
  {
    value: 'Idaho',
    id: 'Idaho',
    label: 'Idaho',
  },
  {
    value: 'Illinois',
    id: 'Illinois',
    label: 'Illinois',
  },
  {
    value: 'Indiana',
    id: 'Indiana',
    label: 'Indiana',
  },
  {
    value: 'Iowa',
    id: 'Iowa',
    label: 'Iowa',
  },
  {
    value: 'Kansas',
    id: 'Kansas',
    label: 'Kansas',
  },
  {
    value: 'Kentucky',
    id: 'Kentucky',
    label: 'Kentucky',
  },
  {
    value: 'Louisiana',
    id: 'Louisiana',
    label: 'Louisiana',
  },
  {
    value: 'Maine',
    id: 'Maine',
    label: 'Maine',
  },
  {
    value: 'Marshall Islands',
    id: 'Marshall Islands',
    label: 'Marshall Islands',
  },
  {
    value: 'Maryland',
    id: 'Maryland',
    label: 'Maryland',
  },
  {
    value: 'Massachusetts',
    id: 'Massachusetts',
    label: 'Massachusetts',
  },
  {
    value: 'Michigan',
    id: 'Michigan',
    label: 'Michigan',
  },
  {
    value: 'Minnesota',
    id: 'Minnesota',
    label: 'Minnesota',
  },
  {
    value: 'Mississippi',
    id: 'Mississippi',
    label: 'Mississippi',
  },
  {
    value: 'Missouri',
    id: 'Missouri',
    label: 'Missouri',
  },
  {
    value: 'Montana',
    id: 'Montana',
    label: 'Montana',
  },
  {
    value: 'Nebraska',
    id: 'Nebraska',
    label: 'Nebraska',
  },
  {
    value: 'Nevada',
    id: 'Nevada',
    label: 'Nevada',
  },
  {
    value: 'New Hampshire',
    id: 'New Hampshire',
    label: 'New Hampshire',
  },
  {
    value: 'New Jersey',
    id: 'New Jersey',
    label: 'New Jersey',
  },
  {
    value: 'New Mexico',
    id: 'New Mexico',
    label: 'New Mexico',
  },
  {
    value: 'New York',
    id: 'New York',
    label: 'New York',
  },
  {
    value: 'North Carolina',
    id: 'North Carolina',
    label: 'North Carolina',
  },
  {
    value: 'North Dakota',
    id: 'North Dakota',
    label: 'North Dakota',
  },
  {
    value: 'Northern Mariana Islands',
    id: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
  },
  {
    value: 'Ohio',
    id: 'Ohio',
    label: 'Ohio',
  },
  {
    value: 'Oklahoma',
    id: 'Oklahoma',
    label: 'Oklahoma',
  },
  {
    value: 'Oregon',
    id: 'Oregon',
    label: 'Oregon',
  },
  {
    value: 'Palau',
    id: 'Palau',
    label: 'Palau',
  },
  {
    value: 'Pennsylvania',
    id: 'Pennsylvania',
    label: 'Pennsylvania',
  },
  {
    value: 'Puerto Rico',
    id: 'Puerto Rico',
    label: 'Puerto Rico',
  },
  {
    value: 'Rhode Island',
    id: 'Rhode Island',
    label: 'Rhode Island',
  },
  {
    value: 'South Carolina',
    id: 'South Carolina',
    label: 'South Carolina',
  },
  {
    value: 'South Dakota',
    id: 'South Dakota',
    label: 'South Dakota',
  },
  {
    value: 'Tennessee',
    id: 'Tennessee',
    label: 'Tennessee',
  },
  {
    value: 'Texas',
    id: 'Texas',
    label: 'Texas',
  },
  {
    value: 'Utah',
    id: 'Utah',
    label: 'Utah',
  },
  {
    value: 'Vermont',
    id: 'Vermont',
    label: 'Vermont',
  },
  {
    value: 'Virgin Islands',
    id: 'Virgin Islands',
    label: 'Virgin Islands',
  },
  {
    value: 'Virginia',
    id: 'Virginia',
    label: 'Virginia',
  },
  {
    value: 'Washington',
    id: 'Washington',
    label: 'Washington',
  },
  {
    value: 'West Virginia',
    id: 'West Virginia',
    label: 'West Virginia',
  },
  {
    value: 'Wisconsin',
    id: 'Wisconsin',
    label: 'Wisconsin',
  },
  {
    value: 'Wyoming',
    id: 'Wyoming',
    label: 'Wyoming',
  },
];

export const usStatesWithNone = [
  {
    value: null,
    id: 'none',
    label: 'None',
  },
  ...usStates,
];

export const usStatesSelectWithAll = [
  {
    value: null,
    id: 'All',
    label: 'All',
  },
  ...usStates,
];

export const usStatesSelectWithCompact = [
  {
    value: undefined,
    id: 'All',
    label: 'All',
  },
  {
    value: 'compact',
    id: 'compact',
    label: 'Compact',
  },
  ...usStates,
];
