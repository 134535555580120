import { useTranslation } from 'react-i18next';
import { useState, type FC } from 'react';
import { SMS_ACTIVITIES_PER_PAGE } from 'infrastructure/hooks/ccm';
import BaseTableNew from 'components/atoms/base-table/new';
import BaseButton from 'components/atoms/baseButton';
import CcmNarrativeModal from 'components/templates/ccm/patientDetails/components/narrativeTemplates/modal';
import { MAX_TEXT_LENGTH_FOR_EXPAND } from 'infrastructure/consts/tables';
import EmptyTablePlaceholder from 'components/atoms/base-empty-table-placeholder';

import s from './styles.module.scss';
import { columns } from './columns';
import ClinicalNotesModalForm from '../admin-panel/forms/clinical-notes';
import CcmInteractionStatusModal from '../ccmInteractionStatus/modal';
import { useClinicNotes } from './hooks/use-clinic-notes';

import type { ColumnsProps } from './columns';
import type { IClinicNotes } from 'infrastructure/interfaces';

interface ClinicalNotesLoggedProps {
  patientGuid: string;
}

const ClinicalNotesLogged: FC<ClinicalNotesLoggedProps> = ({ patientGuid }) => {
  const [expandedRecord, setExpandedRecord] =
    useState<ColumnsProps['expandedRecord']>();
  const {
    clinicalNotes,
    isLoading,
    defaultValue,
    Alert,
    addLoading,
    patientInfoLoading: loading,
    disableButton,
    narrativeModalOpen,
    isNextAppointmentModalOpen,
    open,
    page,
    onUseTemplate,
    onSubmit,
    toggleOpen,
    setPage,
    onCancelForm,
    toggleOpenNarrativeModal,
    setDefaultValue,
    setIsNextAppointmentModalOpen,
  } = useClinicNotes({ patientGuid });
  const { t } = useTranslation();

  return (
    <>
      <div className={s.wrapper}>
        <div className={s['title-wrapper']}>
          <h3 className={s.title} data-cy="clinical-notes-section">
            {t('labels.clinicalNotesLogged')}
          </h3>
          <BaseButton
            label={`+ ${t('labels.addNote')}`}
            dataCy="addNote-button"
            disabled={disableButton || isLoading || loading}
            onClick={toggleOpen}
            loading={addLoading}
          />
        </div>
        <BaseTableNew<IClinicNotes>
          items={clinicalNotes?.items ?? []}
          columns={columns({ expandedRecord })}
          bordered
          loading={isLoading || loading}
          pagination={{
            current: page,
            pageSize: SMS_ACTIVITIES_PER_PAGE,
            total: clinicalNotes?.count ?? 0,
            position: ['bottomCenter'],
          }}
          onChangePage={(newPage) => {
            setPage(newPage);
          }}
          dataCy="clinic-notes-table"
          expandedRowRender={(record) => {
            return (
              <span style={{ whiteSpace: 'pre-line' }}>{record.note}</span>
            );
          }}
          rowExpandable={(record) =>
            record.note.length >= MAX_TEXT_LENGTH_FOR_EXPAND
          }
          onExpand={(expanded, record) => {
            setExpandedRecord({ expanded, guid: record.guid });
          }}
          // eslint-disable-next-line prettier/prettier
          emptyText={
            <EmptyTablePlaceholder text={t('labels.noClinicalNotesLogged')} />
            // eslint-disable-next-line prettier/prettier
          }
        />
      </div>
      <ClinicalNotesModalForm
        loading={addLoading}
        onCancelForm={onCancelForm}
        onSubmit={onSubmit}
        onUseTemplate={onUseTemplate}
        defaultValues={defaultValue ? { note: defaultValue } : undefined}
        open={open}
      />
      {narrativeModalOpen && (
        <CcmNarrativeModal
          isOpen={narrativeModalOpen}
          closeModal={toggleOpenNarrativeModal}
          onSubmit={(value) => {
            setDefaultValue(value);
          }}
          patientGuid={patientGuid}
        />
      )}
      {isNextAppointmentModalOpen && (
        <CcmInteractionStatusModal
          isOpen={isNextAppointmentModalOpen}
          toggle={setIsNextAppointmentModalOpen}
          patientGuid={patientGuid}
        />
      )}
      {Alert}
    </>
  );
};
export default ClinicalNotesLogged;
