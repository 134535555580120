import type { InsuranceType } from 'infrastructure/enums';
import type { IAdherenceInsurance } from 'infrastructure/interfaces';

export class AdherenceInsurance {
  number: string;

  provider: string;

  type: InsuranceType | null;

  constructor(data: IAdherenceInsurance) {
    this.number = data.number;
    this.provider = data.provider;
    this.type = data.type;
  }
}
