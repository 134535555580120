import RoleTypes from 'constants/roleTypes';

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
const splitByUpperCase = (s) => s.split(/(?=[A-Z])/);
const isFirstLetterUpperCase = (s) => s === s.toUpperCase();

const findExistRoleNameInRoleTypes = (roleName) => {
  const foundRoleNameInRoleTypes = RoleTypes.find((e) => e.value === roleName);
  return foundRoleNameInRoleTypes?.label || null;
};

// physicianAssistant -> Physician Assistant
export const formatRoleName = (roleName) => {
  const foundRoleNameInRoleTypes = findExistRoleNameInRoleTypes(roleName);
  if (foundRoleNameInRoleTypes) {
    return foundRoleNameInRoleTypes;
  }

  if (isFirstLetterUpperCase(roleName)) {
    return roleName;
  }

  const splittedStrings = splitByUpperCase(roleName);
  const formatted = splittedStrings.map((s) => capitalize(s)).join(' ');
  return formatted;
};

export const formatAuthorName = ({
  firstName,
  lastName,
  professionType,
  title,
} = {}) => {
  let result = '';
  if (title) {
    result += `${title} `;
  }
  if (firstName) {
    result += `${firstName} `;
  }
  if (lastName) {
    result += `${lastName} `;
  }
  if (professionType) {
    result += `- ${professionType}`;
  }
  return result;
};
