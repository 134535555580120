import i18next from 'i18next';
import { ProviderServiceType } from 'infrastructure/enums';

export interface IProviderServiceTypeItem {
  id: ProviderServiceType;
  name: string;
}

export const providerServiceTypesDataSource =
  (): IProviderServiceTypeItem[] => {
    return [
      {
        id: ProviderServiceType.Cardiology,
        name: i18next.t('providerServiceTypes.cardiology'),
      },
      {
        id: ProviderServiceType.EndocrinologyAndMetabolism,
        name: i18next.t('providerServiceTypes.endocrinologyAndMetabolism'),
      },
      {
        id: ProviderServiceType.FamilyMedicine,
        name: i18next.t('providerServiceTypes.familyMedicine'),
      },
      {
        id: ProviderServiceType.Gastroenterology,
        name: i18next.t('providerServiceTypes.gastroenterology'),
      },
      {
        id: ProviderServiceType.GeneralInternalMedicine,
        name: i18next.t('providerServiceTypes.generalInternalMedicine'),
      },
      {
        id: ProviderServiceType.GeriatricMedicine,
        name: i18next.t('providerServiceTypes.geriatricMedicine'),
      },
      {
        id: ProviderServiceType.InfectiousDisease,
        name: i18next.t('providerServiceTypes.infectiousDisease'),
      },
      {
        id: ProviderServiceType.Oncology,
        name: i18next.t('providerServiceTypes.oncology'),
      },
      {
        id: ProviderServiceType.Nephrology,
        name: i18next.t('providerServiceTypes.nephrology'),
      },
      {
        id: ProviderServiceType.Neurology,
        name: i18next.t('providerServiceTypes.neurology'),
      },
      {
        id: ProviderServiceType.PainSpecialist,
        name: i18next.t('providerServiceTypes.painSpecialist'),
      },
      {
        id: ProviderServiceType.Pediatrics,
        name: i18next.t('providerServiceTypes.pediatrics'),
      },
      {
        id: ProviderServiceType.Psychiatry,
        name: i18next.t('providerServiceTypes.psychiatry'),
      },
      {
        id: ProviderServiceType.Pulmonology,
        name: i18next.t('providerServiceTypes.pulmonology'),
      },
      {
        id: ProviderServiceType.Rheumatology,
        name: i18next.t('providerServiceTypes.rheumatology'),
      },
      {
        id: ProviderServiceType.Urology,
        name: i18next.t('providerServiceTypes.urology'),
      },
      {
        id: ProviderServiceType.RuralHealthcareCenter,
        name: i18next.t('providerServiceTypes.ruralHealthcareCenter'),
      },
      {
        id: ProviderServiceType.Academia,
        name: i18next.t('providerServiceTypes.academia'),
      },
      {
        id: ProviderServiceType.FQHC,
        name: i18next.t('providerServiceTypes.fqhc'),
      },
      {
        id: ProviderServiceType.HealthAndWellness,
        name: i18next.t('providerServiceTypes.healthAndWellness'),
      },
      {
        id: ProviderServiceType.Surgical,
        name: i18next.t('providerServiceTypes.surgical'),
      },
    ];
  };
