import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Card, CardBody, Table } from 'reactstrap';
import common from 'constants/common';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import Pagination from 'components/molecules/pagination';
import withLoader from 'components/organisms/withLoader';
import ActivityRow from 'components/templates/ccm/practice/ccmInteractiveReport/activityRow';
import Sorting from 'components/templates/ccm/practice/ccmInteractiveReport/sorting';
import useTable from 'utils/useTable';
import { API_CCM_INTERACTIVE_TIME_REPORT } from 'services/dataService/resources';
import { format, subMonths } from 'date-fns';
import { showResult } from 'infrastructure/hooks/utils';
import { omitBy } from 'lodash';

const headings = [
  'Patient Name',
  'CCM Status',
  'RPM Status',
  'CCM Onboarding Agent',
  'CCM Case Manager',
  'Patient DOB',
  'Clinic Name',
  'Clinic State',
  'CCM Interaction Status',
  'PCM Status',
  'Next Month Appointment Method',
  'Next Appointment Date',
  'Next Appointment Time',
  'Next Appointment Scheduled With',
  'Interactive Time Billing Group',
  'Total Interactive Time',
  'CCM Temporary On-Hold Status',
  'CCM Temporary On-Hold Date',
];
const PER_PAGE = 100;

let initialDateFilter;
let timeRanges;

const generateTimeRanges = () => {
  const today = new Date();
  const dateOptions = [];

  for (let i = 0; i < 12; i++) {
    const date = subMonths(today, i);
    const billingYear = date.getFullYear();
    const billingMonth = date.getMonth() + 1;
    const label = format(new Date(date), 'MMM-yyyy');
    dateOptions.push({
      value: [billingYear, billingMonth],
      label,
    });
  }

  initialDateFilter = {
    billingYear: dateOptions[0].value[0],
    billingMonth: dateOptions[0].value[1],
  };

  timeRanges = dateOptions;
};

const Activity = () => {
  if (!timeRanges) generateTimeRanges();
  const { getCcmInteractiveCsvReport: csvLoading } = useSelector(
    (state) => state.loading.effects.ccmInteractiveTime,
  );

  const {
    ccmInteractiveTime: { getCcmInteractiveCsvReport },
  } = useDispatch();

  const {
    data: { items },
    count,
    loading,
    page,
    filters,
    setPage,
    setFilters,
  } = useTable(API_CCM_INTERACTIVE_TIME_REPORT, PER_PAGE, {
    ...initialDateFilter,
  });

  const sendReport = async () => {
    const cleanedFilters = omitBy(filters, (value) => value === '');

    const { error } = await getCcmInteractiveCsvReport(cleanedFilters);
    return showResult(error, common.submitSuccessTypes.sendReport);
  };
  return (
    <Container>
      <div className="activity-report">
        <Card className="card-border">
          <CardBody className="card-outer">
            <div className="performance-review__header">
              <div className="activity-header">
                <h3>Report: CCM Interactive Time</h3>
              </div>
              <div className="performance-review__header-icon">
                {withLoader(
                  items.length < 0 || csvLoading,
                  <button onClick={sendReport} data-cy="download-button">
                    <DownloadRoundedIcon className="performance-review__header-icon-svg" />
                    <br />
                    <span>Download</span>
                  </button>,
                )}
              </div>
            </div>
            <div className="activity-body">
              <div className="sort-row">
                <Sorting
                  filters={filters}
                  setFilters={(newFilters) =>
                    setFilters({ ...filters, ...newFilters })
                  }
                  timeRanges={timeRanges}
                />
              </div>
              <div className="table-sticky-header interactive-time-table">
                {withLoader(
                  loading,
                  <Table borderless cellSpacing="0" cellPadding="0">
                    <thead data-cy="ccm-interactive-time-table-header">
                      <tr>
                        {headings &&
                          headings.map((heading, i) => (
                            <th
                              className="table-status-column"
                              key={`table-heading-${i}`}
                            >
                              <div className="sort-div">{heading}</div>
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody data-cy="ccm-interactive-time-table-body">
                      {items.map((activityRow, i) => (
                        <ActivityRow
                          key={`table-row-${i}`}
                          activity={activityRow}
                        />
                      ))}
                    </tbody>
                  </Table>,
                )}
              </div>
              <div className="billing-table-button-pagination flex-row-reverse">
                <Pagination
                  title=""
                  page={page}
                  pageTotal={items.length}
                  itemsPerPage={PER_PAGE}
                  total={count}
                  handleFirstClick={() => setPage(1)}
                  handleLastClick={() => setPage(Math.ceil(count / PER_PAGE))}
                  handlePrevClick={() => setPage(page - 1)}
                  handleNextClick={() => setPage(page + 1)}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default Activity;
