import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import BaseDropdown from 'components/atoms/base-dropdown';
import ContactAvailabilityIcon from 'components/atoms/icons/contact-availability';

import s from './styles.module.scss';
import ContactAvailabilityColBody from './body';

import type { InteractiveTimeItem } from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface IProps {
  record: InteractiveTimeItem;
}

const ContactAvailabilityCol: React.FC<IProps> = (props) => {
  const { record } = props;

  const { t } = useTranslation();

  const [height, setHeight] = useState(80);
  const [opened, setOpened] = useState(false);

  const buttonRef = useRef<HTMLDivElement>(null);

  const buttonClassNames = classNames(s.button, {
    [s.opened]: opened,
  });

  const text = opened ? t('labels.hide') : t('labels.show');
  const icon = opened ? '&larr;' : '&rarr;';

  const onClick = () => {
    setOpened(!opened);
    if (buttonRef.current) {
      setHeight(buttonRef.current.clientHeight);
    }
  };

  const content = (
    <ContactAvailabilityColBody
      height={height}
      availabilities={record.contactAvailabilities}
      timezone={record.patient.timezone}
    />
  );

  return (
    <div className={s.wrap}>
      <BaseDropdown
        content={content}
        placement="topLeft"
        offset={[104, `100%`]}
        onOpenChange={onClick}
      >
        <div ref={buttonRef} className={buttonClassNames}>
          <div>
            <ContactAvailabilityIcon />
            <br />
            <motion.div
              className={s.icon}
              key={`${opened}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
            >
              <span>
                {text} <i dangerouslySetInnerHTML={{ __html: icon }} />
              </span>
            </motion.div>
          </div>
        </div>
      </BaseDropdown>
    </div>
  );
};

export default ContactAvailabilityCol;
