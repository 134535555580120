import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const Address = ({ data }) => (
  <div className="admin-agent-block">
    <span>Address</span>
    <Table>
      <tbody>
        {data.addressLine1 && (
          <tr>
            <td>User Address Line 1</td>
            <td data-cy="agency-user-addressOne">{data.addressLine1}</td>
          </tr>
        )}
        {data.addressLine2 && (
          <tr>
            <td>User Address Line 2</td>
            <td data-cy="agency-user-addressTwo">{data.addressLine2}</td>
          </tr>
        )}
        {data.city && (
          <tr>
            <td>City</td>
            <td data-cy="agency-user-city">{data.city}</td>
          </tr>
        )}
        {data.state && (
          <tr>
            <td>State</td>
            <td data-cy="agency-user-state">{data.state}</td>
          </tr>
        )}
        {data.zip && (
          <tr>
            <td>Zip</td>
            <td data-cy="agency-user-zip">{data.zip}</td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);

Address.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Address;
