import React from 'react';
import Pagination from 'components/molecules/paginationTransition';
import { API_ACTIVITY } from 'services/dataService/ccmResources';
import forms from 'constants/forms';
import useTable from 'utils/useTable';
import withLoader from 'components/organisms/withLoader';
import useUser from 'utils/useUser';
import { isAdmin, isBiller, isCcmAgencyDoctor } from 'utils/userTypeHelper';
import { ccmPageAvailableStatuses } from 'constants/patient_statuses';

import Toolbar from './components/toolbar';
import HeaderRow from './components/headerRow';
import BodyRow from './components/bodyRow';

const STATUS_ENROLLED = forms.ccmPatientStatuses[1];
const PER_PAGE = 100;

const PatientsActivity = () => {
  const { userType, roleNames } = useUser();
  const hasAccessToCareAgent =
    isCcmAgencyDoctor(userType, roleNames) || isAdmin(userType);

  const date = new Date();
  const billingMonth = date.getMonth() + 1;
  const billingYear = date.getFullYear();
  const {
    data: { items },
    count,
    loading,
    page,
    setPage,
    filters,
    setFilters,
  } = useTable(API_ACTIVITY, PER_PAGE, {
    ccmStatus: STATUS_ENROLLED.value,
    billingMonth,
    billingYear,
    activityTimeSort: null,
    ccmStatusSort: null,
    patientGuid: null,
    clinicGuid: null,
    doctorGuid: null,
    caseManagerGuid: null,
    admissionManagerGuid: null,
  });

  const hasCcmPage = (ccmStatus) =>
    ccmPageAvailableStatuses.includes(ccmStatus);

  const defaultFilters = {
    ccmStatus: STATUS_ENROLLED.value,
    billingMonth,
    billingYear,
    activityTimeSort: null,
    ccmStatusSort: null,
    patientGuid: null,
    clinicGuid: null,
    doctorGuid: null,
    caseManagerGuid: null,
    admissionManagerGuid: null,
  };

  return (
    <div>
      <Toolbar
        filters={filters}
        defaultFilters={defaultFilters}
        hasAccessToCareAgent={hasAccessToCareAgent}
        setFilters={(newFilters) => setFilters({ ...filters, ...newFilters })}
      />
      {withLoader(
        loading,
        <div className="ccm-activity__services-table">
          <table className="table-rounded">
            <thead>
              <HeaderRow
                activityTimeSort={filters.activityTimeSort}
                ccmStatusSort={filters.ccmStatusSort}
                hasAccessToCareAgent={hasAccessToCareAgent}
                setSorts={(newSorts) => setFilters({ ...filters, ...newSorts })}
              />
            </thead>
            <tbody>
              {items &&
                items.map((row, index) => (
                  <BodyRow
                    disabled={!hasCcmPage(row.patient.ccmStatus)}
                    hasAccessToCareAgent={hasAccessToCareAgent}
                    isBiller={isBiller(roleNames)}
                    row={row}
                    key={index}
                  />
                ))}
            </tbody>
          </table>
        </div>,
      )}
      <div className="ccm-table-section__pagination">
        <Pagination
          title=""
          page={page}
          pageTotal={items.length}
          itemsPerPage={PER_PAGE}
          total={count}
          handleFirstClick={() => setPage(1)}
          handleLastClick={() => setPage(Math.ceil(count / PER_PAGE))}
          handlePrevClick={() => setPage(page - 1)}
          handleNextClick={() => setPage(page + 1)}
          handleGoToPage={(numberPage) => setPage(numberPage)}
        />
      </div>
    </div>
  );
};

export default PatientsActivity;
