import BaseButton from 'components/atoms/baseButton';
import CsvUploadModal from 'components/molecules/csvUploadModal';
import { showResult } from 'infrastructure/hooks/utils';
import { useState } from 'react';
import dataService from 'services/dataService';
import { API_ELIGIBILITY_PROCESS_CREATE } from 'services/dataService/resources';
import { useTranslation } from 'react-i18next';
import { SampleFilesUrl } from 'infrastructure/consts/sampleFiles';

import s from './style.module.scss';

import type { FC } from 'react';

interface Props {
  onUpload: () => any;
}

const EligibilityUpload: FC<Props> = ({ onUpload }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prev) => !prev);

  const uploadCsvFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const { error, data } = await dataService.uploadFile(
      API_ELIGIBILITY_PROCESS_CREATE,
      formData,
      'POST',
    );

    if (error) {
      return { error };
    }
    if (data) {
      setIsOpen(false);
      showResult();
      onUpload();
    }
    return {};
  };

  return (
    <div className={s['upload-button-wrapper']}>
      <BaseButton
        onClick={toggle}
        type="primary"
        label={t('labels.createNewProcess')}
      />
      <CsvUploadModal
        isOpen={isOpen}
        toggle={toggle}
        title={t('labels.eligibilityTool')}
        sampleFileUrl={SampleFilesUrl.eligibilityTemplate}
        uploadCsvFile={uploadCsvFile}
      />
    </div>
  );
};

export default EligibilityUpload;
