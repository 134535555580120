import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  DateFormatter,
  openInNewTab,
  formatTime,
} from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import TelephoneOutboundIcon from 'components/atoms/icons/telephone-outbound';
import TelephoneXIcon from 'components/atoms/icons/telephone-x';
import PlayFillIcon from 'components/atoms/icons/play-fill';
import XFillIcon from 'components/atoms/icons/x-fill';
import { useComment } from 'infrastructure/hooks/use-comment';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import AmazonConnectIcon from 'components/atoms/icons/amazon-connect';
import ChatDotsIcon from 'components/atoms/icons/chat-dots';
import CollapseAnimation from 'components/atoms/base-animations/collapse';

import s from './styles.module.scss';
import GeneralCommentsListMessageItem from './message-item';

import type { Comment } from 'infrastructure/classes/comments/comment';

interface IGeneralCommentsListCallItemProps {
  data: Comment;
}

const GeneralCommentsListCallItem: React.FC<
  IGeneralCommentsListCallItemProps
> = (props) => {
  const { data } = props;

  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  const { loading, getAudioCommentUrl } = useComment({
    patientGuid: data.patientGuid,
  });

  const itemClassNames = classNames(s.item, s.message);

  const hasAudio = !!data.duration;

  const telephoneIcon = hasAudio ? (
    <TelephoneOutboundIcon />
  ) : (
    <TelephoneXIcon />
  );

  const trackIcon = hasAudio ? <PlayFillIcon /> : <XFillIcon />;
  const trackMessage = hasAudio ? data.message : '--';

  const openCall = async () => {
    const res = await getAudioCommentUrl(data.message);
    if (res) openInNewTab(res.url);
  };

  const onComment = () => {
    setOpened(!opened);
  };

  return (
    <div className={itemClassNames} data-cy="general-call-comment-item">
      <div className={s.head} data-cy="general-call-comment-header">
        <p className={s.title}>
          {telephoneIcon} {t(`labels.call`)}
        </p>
        <AmazonConnectIcon />
      </div>

      <BaseSpinWrapper spinning={loading}>
        <div className={s['track-info']} data-cy="general-call-comment-track">
          <div className={s.track}>
            {!data.hidden && !hasAudio && (
              <p className={s.text}>{t('labels.attemptedToCall')}</p>
            )}
            {data.hidden && (
              <p className={s.text}>{t('labels.waitingForCallDetails')}</p>
            )}
            <button className={s.title} onClick={() => hasAudio && openCall()}>
              {trackIcon} {trackMessage}
            </button>
          </div>
          <p className={s.text}>{formatTime(data.duration)}</p>
        </div>
      </BaseSpinWrapper>

      <p className={s.footer}>
        <span>
          {t(`labels.callBy`)}: {data.createdFullName}
        </span>
        <span>
          {DateFormatter({
            date: data.createdAt,
            format: DateFormats['MM-dd-yyyy | h:mm a'],
          })}
        </span>
      </p>

      {!!data.callComments?.length && (
        <div>
          <button className={s['call-comments']} onClick={onComment}>
            <ChatDotsIcon />
            {`${data.callComments.length} ${t('labels.callComments')}`}
          </button>
          <CollapseAnimation opened={opened}>
            <ul className={s.sublist}>
              {data.callComments.map((el, index) => (
                <GeneralCommentsListMessageItem key={index} data={el} />
              ))}
            </ul>
          </CollapseAnimation>
        </div>
      )}
    </div>
  );
};

export default GeneralCommentsListCallItem;
