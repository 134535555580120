const EmailIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2C0.895431 2 0 2.89543 0 4V12L2.58386e-05 12.0103C0.00555998 13.1101 0.898859 14 2 14H7.5C7.77614 14 8 13.7761 8 13.5C8 13.2239 7.77614 13 7.5 13H2C1.53715 13 1.14774 12.6855 1.03376 12.2586L6.67417 8.7876L8 9.5831L15 5.3831V8.5C15 8.77614 15.2239 9 15.5 9C15.7761 9 16 8.77614 16 8.5V4C16 2.89543 15.1046 2 14 2H2ZM5.70808 8.20794L1 11.1052V5.3831L5.70808 8.20794ZM1 4.2169V4C1 3.44772 1.44772 3 2 3H14C14.5523 3 15 3.44772 15 4V4.2169L8 8.4169L1 4.2169Z"
      fill="#BD363B"
    />
    <path
      d="M14.9749 10.0251C13.608 8.65829 11.392 8.65829 10.0251 10.0251C8.65829 11.392 8.65829 13.608 10.0251 14.9749C11.392 16.3417 13.608 16.3417 14.9749 14.9749C16.3417 13.608 16.3417 11.392 14.9749 10.0251ZM10.7322 10.7322C11.5875 9.877 12.9082 9.77094 13.8789 10.414L10.414 13.8789C9.77094 12.9082 9.877 11.5875 10.7322 10.7322ZM11.1211 14.586L14.586 11.1211C15.2291 12.0918 15.123 13.4125 14.2678 14.2678C13.4125 15.123 12.0918 15.2291 11.1211 14.586Z"
      fill="#BD363B"
    />
  </svg>
);

export default EmailIcon;
