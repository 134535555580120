// FIXME: Can be deleted. Unused component

import SendIcon from 'components/atoms/icons/sendIcon';
import { COMMENT_SUBMIT_ERROR_NO_CHANGES } from 'constants/messages';

import type { FC } from 'react';

interface Props {
  submitLoading: boolean;
  disabled?: boolean;
  submitCommentLockByAi?: boolean;
}

const SubmitButton: FC<Props> = ({
  submitLoading,
  disabled,
  submitCommentLockByAi,
}) => {
  let title = 'Submit comment';
  if (submitLoading) {
    title = 'Submitting comment...';
  }
  if (submitCommentLockByAi) {
    title = COMMENT_SUBMIT_ERROR_NO_CHANGES;
  }

  return (
    <button
      type="submit"
      disabled={submitLoading || disabled || submitCommentLockByAi}
      className="activity-comment-form--submit-button"
      title={title}
    >
      <SendIcon />
    </button>
  );
};

export default SubmitButton;
