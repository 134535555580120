import React from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { clinicStatusesDataSource } from 'infrastructure/data-sources/clinic-statuses';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IClinicStatusItem } from 'infrastructure/data-sources/clinic-statuses';

interface IClinicStatusSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {
  withAll?: boolean;
}

const ClinicStatusSelectBox: React.FC<IClinicStatusSelectBoxProps> = (
  props,
) => {
  const { withAll } = props;
  const clinicStatuses = clinicStatusesDataSource(withAll);

  const options: IBaseSelectBoxOption<string, IClinicStatusItem>[] =
    clinicStatuses.map((clinicStatus) => ({
      value: clinicStatus.id,
      label: clinicStatus.name,
      record: clinicStatus,
    }));

  return <BaseSelectBox options={options} {...props} />;
};

export default ClinicStatusSelectBox;
