import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { convertSecondsToTime } from 'utils/timeHelper';

const ActivityRow = ({ patient, date }) => {
  const timeSpented = (type) => {
    let value;
    const time = patient.billing.totalTime
      ? parseInt(patient.billing.totalTime)
      : 0;
    switch (type) {
      case 20:
        value = time >= 1200 ? 'Y: ' : 'No: ';
        break;
      case 40:
        value = time >= 2400 ? 'Y: ' : 'No: ';
        break;
      case 60:
        value = time >= 3600 ? 'Y: ' : 'No: ';
        break;
      case 'total':
        value = `${convertSecondsToTime(time)} `;
        break;
      default:
        break;
    }
    value += date;
    return value;
  };
  return (
    <tr className="activity-row">
      <td data-label="Medicare ID">{patient.insurance?.number}</td>
      <td data-label="DX Codes">
        {Object.values(patient.diagnosis).join(',  ')}
      </td>
      <td data-label="Patient Name">
        <Link to={`/patient/${patient.patientGuid}`}>
          <strong>
            {patient.firstName} {patient.lastName}
          </strong>
        </Link>
      </td>
      <td data-label="MRN">{patient.emr.account}</td>
      <td data-label="99453">
        {patient.billing.initDate || 'No Patient Reading'}
      </td>
      <td data-label="99454">
        {patient.billing.endPeriod || 'No Patient Reading'}
      </td>
      <td data-label="(20 Min) 99457">{timeSpented(20)}</td>
      <td data-label="(40 Min) 99458">{timeSpented(40)}</td>
      <td data-label="(60 Min) 99458">{timeSpented(60)}</td>
      <td data-label="MD Chart Review">
        {`${patient.reviewCount > 0 ? 'Y:' : 'No:'} ${date}`}
      </td>
      <td data-label="Total Readings Days">
        {patient.billing.daysNumber === '' ? 0 : patient.billing.daysNumber}
      </td>
      <td data-label="Time Spent">{timeSpented('total')}</td>
    </tr>
  );
};
ActivityRow.propTypes = {
  patient: PropTypes.shape().isRequired,
  date: PropTypes.string.isRequired,
};

export default ActivityRow;
