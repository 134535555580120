import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';

import { apiUrlGenerator } from '../../use-api';

import type { IPartialPaginationMeta } from 'infrastructure/interfaces';
import type {
  IRpmActivityCountResponse,
  IRpmPatientActivity,
} from 'infrastructure/interfaces/i-rpm-patient-activity';

export type TDashboardActivityFilter = {
  reviewed: boolean;
  applySpecialFilter: boolean;
  startTime?: number;
  endTime?: number;
  clinicGuid?: string;
  patientGuid?: string;
  clinicState?: string;
  readingType?: string;
  doctorGuid?: string[];
  insuranceType?: string;
  rangeLabel?: string;
  assignee?: string;
  status?: string;
  slaReadings?: string;
  reverse?: boolean;
} & IPartialPaginationMeta;

export const DASHBOARD_ACTIVITY_PER_PAGE = 100;

export type TDashboardActivityFilterWithDoctorString = Omit<
  TDashboardActivityFilter,
  'doctorGuid'
> & {
  doctorGuid?: string;
};

export type TRpmDashboardActivityQueryKey = [
  string,
  TDashboardActivityFilterWithDoctorString,
];

const getRpmActivity = async (
  filter: TDashboardActivityFilterWithDoctorString,
) => {
  const url = `core/patients/readings`;
  const { data, error } = await dataService.getList<IRpmPatientActivity>(
    apiUrlGenerator(url, filter),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

const getActivityCountFn = async (
  filter: TDashboardActivityFilterWithDoctorString,
) => {
  const url = 'core/patients/readings/count';
  const { data, error } = await dataService.getList<IRpmActivityCountResponse>(
    apiUrlGenerator(url, filter),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const getRpmActivityKey = (
  filter: TDashboardActivityFilterWithDoctorString,
): TRpmDashboardActivityQueryKey => ['getRpmActivity', filter];
export const getRpmActivityCountKey = (
  filter: TDashboardActivityFilterWithDoctorString,
) => ['getRpmActivityCount', filter];

export const useRpmActivity = (filter: TDashboardActivityFilter) => {
  const filterWithItems: TDashboardActivityFilterWithDoctorString = {
    ...filter,
    doctorGuid: filter.doctorGuid?.join(','),
    items: DASHBOARD_ACTIVITY_PER_PAGE,
  };

  const queryKey = getRpmActivityKey(filterWithItems);

  const {
    data: activity,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: async () => getRpmActivity(filterWithItems),
    enabled: Boolean(filter.startTime || filter.endTime),
    keepPreviousData: true,
  });

  const { data: activityCount, isLoading: countLoading } = useQuery({
    queryKey: getRpmActivityCountKey(filterWithItems),
    queryFn: async () => getActivityCountFn(filterWithItems),
    enabled: Boolean(filter.startTime || filter.endTime),
  });

  return {
    activity,
    queryKey,
    isLoading,
    isFetching,
    activityCount,
    countLoading,
  };
};
