import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showResult } from 'infrastructure/hooks/utils';

import StopIcon from './stopIcon';

const StopTimerButton = ({ activity }) => {
  const timerAlreadyStopped = activity.slaReviewedTime;
  const [loading, setLoading] = useState(false);
  const {
    doctor: { stopSlaTimer },
  } = useDispatch();

  const stopTimer = async () => {
    if (loading) return;

    setLoading(true);
    const { error } = await stopSlaTimer(activity);
    if (error) {
      showResult(error);
    }
    setLoading(false);
  };

  return (
    <>
      {!timerAlreadyStopped && (
        <button
          title="Stop SLA Timer for this reading (please note that this action cannot be undone)"
          className="sla-timer-stop-button"
          onClick={stopTimer}
          disabled={loading}
        >
          <StopIcon loading={loading} />
          <div className="sla-timer-stop-button--text">Stop</div>
        </button>
      )}
    </>
  );
};

StopTimerButton.propTypes = {
  activity: PropTypes.object,
};

export default StopTimerButton;
