import { Card, CardBody } from 'reactstrap';
import withLoader from 'components/organisms/withLoader';
import useAgencyDoctors from 'utils/hooks/adminPanel/useAgencyDoctors';

import AgencyDoctorsHeader from './components/header';
import AgencyDoctorsFooter from './components/footer';
import AgencyDoctorsToolbar from './components/toolbar';
import AdminPanelTurkUsersTable from './components/table';

const AdminTurkUsers = () => {
  const {
    isLoading,
    agencyDoctors,
    filters,
    updateFilters,
    count = 0,
  } = useAgencyDoctors({
    roleName: 'turk',
  });

  return (
    <div className="adminPanel">
      <Card className="card-border">
        <CardBody className="card-outer">
          <AgencyDoctorsHeader count={count} />
          <AgencyDoctorsToolbar
            count={count}
            filters={filters}
            updateFilters={updateFilters}
          />
          <div className="adminPanel__table">
            {withLoader(
              isLoading,
              <AdminPanelTurkUsersTable agencyDoctors={agencyDoctors} />,
            )}
          </div>
          <AgencyDoctorsFooter />
        </CardBody>
      </Card>
    </div>
  );
};

export default AdminTurkUsers;
