import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { convertSecondsToTime } from 'utils/timeHelper';
import { getPatientPcmStatus, getPatientCcmStatus } from 'utils';
import common from 'constants/common';

const ActivityRow = ({ activity }) => {
  const styleRange = (timeInSeconds) => {
    const timeInMinutes = timeInSeconds / 60;
    if (timeInMinutes <= 20) return 'rangeRed';
    if (timeInMinutes <= 40) return 'rangeYellow';
    if (timeInMinutes <= 60) return 'rangeGreen';
    return 'rangeLightGreen';
  };

  const getRange = (timeInSeconds) => {
    if (timeInSeconds === 0) return 'No Interactive Time';
    const timeInMinutes = timeInSeconds / 60;
    if (timeInMinutes <= 20) return '1 to 20 Mins';
    if (timeInMinutes <= 40) return '21 to 40 mins';
    if (timeInMinutes <= 60) return '41 to 60 Mins';
    return 'Greater than 60 Mins';
  };

  const ccmInteractionStatus = activity.patient.ccmInteractionStatus
    ? 'True'
    : 'False';

  const ccmHoldingStatus = activity.patient.ccmHoldingStatus ? 'True' : 'False';

  const { label: patientCcmStatus } = getPatientCcmStatus(
    activity.patient.ccmStatus,
  );
  const appointmentMethodMap = {
    phone_or_video_conference: 'Phone/Video Conference',
    sms: 'SMS',
    email: 'Email',
  };

  const appointmentMethod =
    appointmentMethodMap[activity.nextAppointment?.appointmentMethod] ||
    activity.nextAppointment?.appointmentMethod;
  return (
    <tr className="activity-row">
      <td className="w15" data-label="Patient Name">
        <Link to={`/ccm/patient/${activity.patient.guid}`}>
          <strong>
            {activity.patient.firstName} {activity.patient.lastName}
          </strong>
        </Link>
      </td>
      <td data-label="CCM Status">{patientCcmStatus}</td>
      <td data-label="RPM Status">
        {common.statusPatients[activity.patient.status]?.label}
      </td>
      <td data-label="CCM Onboarding Agent">{activity.ccmOnboardingAgent}</td>
      <td data-label="CCM Case Manager">{activity.ccmCaseManager}</td>
      <td className="w8" data-label="Patient DOB">
        {activity.patient.birthDate}
      </td>
      <td className="w20" data-label="Clinic Name">
        {activity.clinic.clinicName}
      </td>
      <td className="w8" data-label="Clinic State">
        {activity.clinic.clinicState}
      </td>
      <td data-label="CCM Interaction Status">{ccmInteractionStatus}</td>
      <td data-label="PCM Status">
        {getPatientPcmStatus(activity.patient.pcmStatus)}
      </td>
      <td data-label="Next Month Appointment Method">
        {activity.nextAppointment && <>{appointmentMethod}</>}
      </td>
      <td data-label="Next Appointment Date">
        {activity.nextAppointment && (
          <>{activity.nextAppointment.appointmentDate}</>
        )}
      </td>
      <td data-label="Next Appointment Time">
        {activity.nextAppointment && (
          <>{activity.nextAppointment.appointmentTime}</>
        )}
      </td>
      <td data-label="Next Appointment Scheduled With">
        {activity.nextAppointment && (
          <>{activity.nextAppointment.scheduledWith}</>
        )}
      </td>
      <td
        data-label="Range"
        className={`${styleRange(activity.totalTime)} range`}
      >
        {getRange(activity.totalTime)}
      </td>
      <td data-label="Total time">
        {convertSecondsToTime(activity.totalTime)}
      </td>
      <td data-label="CCM Temporary On-Hold Status">{ccmHoldingStatus}</td>
      <td data-label="CCM Temporary On-Hold Date">
        {activity.patient.ccmHoldingStatusStartDate && (
          <>{activity.patient.ccmHoldingStatusStartDate}</>
        )}
      </td>
    </tr>
  );
};

ActivityRow.propTypes = {
  activity: PropTypes.shape().isRequired,
};

export default ActivityRow;
