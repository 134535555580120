import { format, endOfMonth } from 'date-fns';

export const generateTimeRanges = () => {
  const date = new Date();
  const values = [];
  for (let i = 0; i < 12; i++) {
    const startTime = new Date(date.getFullYear(), date.getMonth() - i, 1);
    const endTime = i === 0 ? new Date() : endOfMonth(new Date(startTime));
    const label = format(new Date(startTime), 'MMM-yyyy');
    values.push({
      value: [
        format(new Date(startTime), 'yyyy-MM-dd'),
        format(new Date(endTime), 'yyyy-MM-dd'),
      ],
      label,
    });
  }
  return values;
};

export const generateTimeRangesYearMonth = () => {
  const date = new Date();
  const values = [];
  for (let i = 0; i < 12; i++) {
    const startTime = new Date(date.getFullYear(), date.getMonth() - i, 1);
    const label = format(new Date(startTime), 'MMM-yyyy');
    values.push({
      value: [startTime.getFullYear(), startTime.getMonth() + 1],
      label,
    });
  }
  return values;
};
