/* eslint-disable no-console */
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import paths from 'constants/paths';

const SSOLogin = () => {
  const { loginWithRedirect } = useAuth0();

  const login = async (targetUrl, connection) => {
    try {
      console.log('Start SSO login', connection);

      const options = {};

      if (connection) {
        options.connection = connection;
      }

      if (targetUrl) {
        options.appState = { returnTo: targetUrl };
      }

      await loginWithRedirect(options);
    } catch (err) {
      console.error('Log in failed', err);
    }
  };

  useEffect(() => {
    return login(paths.sso, process.env.REACT_APP_SAML_CONNECTION);
  }, []);

  return null;
};

export default SSOLogin;
