import React from 'react';
import { motion } from 'framer-motion';

import s from './styles.module.scss';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface IProps extends IBaseComponentProps {}

const FadeAnimation: React.FC<IProps> = (props) => {
  const { uniqueKey = Date.now(), children } = props;

  return (
    <motion.div
      className={s.fade}
      key={uniqueKey}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
      }}
    >
      {children}
    </motion.div>
  );
};

export default FadeAnimation;
