import { useRef, useState, type FC } from 'react';
import InfoItalicInfo from 'components/atoms/icons/infoItalicIcon';
import useTurkDashboardSettings from 'utils/hooks/turkDashboard/useSettings';
import useOutsideClick from 'utils/useOutsideClick';
import CrossStitchIcon from 'components/atoms/icons/crossStitchIcon';

import s from './styles.module.scss';

const Info: FC = () => {
  const { turkConfiguration, DEFAULT_REFRESH_RATE } =
    useTurkDashboardSettings();
  const { minimumReadingBatchRefreshRate = DEFAULT_REFRESH_RATE } =
    turkConfiguration || {};

  const [showDescription, setShowDescription] = useState(false);
  const ref = useRef<any>();
  useOutsideClick(ref, () => {
    setShowDescription(false);
  });

  return (
    <>
      <div className={s['info-wrapper']} ref={ref}>
        <button className={s.info} onClick={() => setShowDescription(true)}>
          <InfoItalicInfo />
        </button>
        {showDescription && (
          <div className={s['popup-body']}>
            <button
              className={s['close-button']}
              onClick={() => setShowDescription(false)}
            >
              <CrossStitchIcon />
            </button>
            <div>
              <h5>The “Get Readings” button will only become available if:</h5>
              <p> ● You have no unactioned readings assigned to you;</p>
              <p>
                ● You have not reviewed the maximum number of reading for the
                day;
              </p>
              <p>
                ● It has been at least {minimumReadingBatchRefreshRate} minutes
                since the last batch of readings was assigned to you.
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Info;
