import Sort from './sort';
import Stats from './stats';
import s from './styles.module.scss';

import type { FC } from 'react';

interface Props {
  stats: {
    readingsCount: number;
    earnings: number;
  };
  getReadingsTimeoutTime: number | undefined;
  getReadings: (filter: { states: Array<string | undefined> }) => any;
  disabled?: boolean;
}

const TurkDashboardHeader: FC<Props> = ({
  stats,
  getReadingsTimeoutTime,
  getReadings,
  disabled,
}) => {
  return (
    <div>
      <h3>Readings</h3>
      <div className={s.wrapper}>
        <Sort
          getReadingsTimeoutTime={getReadingsTimeoutTime}
          getReadings={getReadings}
          isLoading={disabled}
        />
        <Stats earnings={stats.earnings} readingsCount={stats.readingsCount} />
      </div>
    </div>
  );
};

export default TurkDashboardHeader;
