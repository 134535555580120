export const clinicRevenueChannels = [
  'Medicare',
  'Texas Medicaid',
  'Commercial',
  'FQHC',
  'Academia',
  'Channel Partner',
  'Self-Insured Payer',
  'Payer',
  'Private Pay',
];

export const clinicRevenueChannelOptions = [
  { label: 'Medicare', value: 'Medicare' },
  { label: 'Texas Medicaid', value: 'Texas Medicaid' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'FQHC', value: 'FQHC' },
  { label: 'Academia', value: 'Academia' },
  { label: 'Channel Partner', value: 'Channel Partner' },
  {
    label: 'Self-Insured Payer',
    value: 'Self-Insured Payer',
  },
  { label: 'Payer', value: 'Payer' },
  { label: 'Private Pay', value: 'Private Pay' },
];
