import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const PersonCheckIcon = ({ color = COLORS.ARANCIO, ...props }: IIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 16C14.433 16 16 14.433 16 12.5C16 10.567 14.433 9 12.5 9C10.567 9 9 10.567 9 12.5C9 14.433 10.567 16 12.5 16ZM14.1787 11.5072L12.8435 13.7327C12.5924 14.1511 12.0151 14.2222 11.67 13.8771L10.8964 13.1036C10.7012 12.9083 10.7012 12.5917 10.8964 12.3964C11.0917 12.2012 11.4083 12.2012 11.6036 12.3964L12.1507 12.9436L13.3213 10.9928C13.4633 10.756 13.7705 10.6792 14.0072 10.8213C14.244 10.9633 14.3208 11.2705 14.1787 11.5072Z"
      fill={color}
    />
    <path
      d="M11 5C11 6.65685 9.65685 8 8 8C6.34315 8 5 6.65685 5 5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5ZM8 7C9.10457 7 10 6.10457 10 5C10 3.89543 9.10457 3 8 3C6.89543 3 6 3.89543 6 5C6 6.10457 6.89543 7 8 7Z"
      fill={color}
    />
    <path
      d="M8.25606 14C8.14314 13.6805 8.06527 13.3445 8.02708 12.9965H3C3.00142 12.7497 3.15375 12.0104 3.8321 11.3321C4.48435 10.6798 5.71088 10 7.99999 10C8.26049 10 8.50723 10.0088 8.74092 10.0254C8.96608 9.68403 9.23648 9.37517 9.54358 9.10733C9.07708 9.03817 8.56399 9 8 9C3 9 2 12 2 13C2 14 3 14 3 14H8.25606Z"
      fill={color}
    />
  </svg>
);

export default PersonCheckIcon;
