import React from 'react';
import { Container } from 'reactstrap';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface IBaseContainerProps extends IBaseComponentProps {}

const BaseContainer: React.FC<IBaseContainerProps> = (props) => {
  const { children, className } = props;
  return <Container className={className}>{children}</Container>;
};

export default BaseContainer;
