import React, { useEffect, useState } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseInput from 'components/atoms/base-input';
import { useFormik } from 'formik';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import BaseGrid from 'components/atoms/base-grid';
import { PatientDiagnosis } from 'infrastructure/classes/patient/patient-diagnosis';
import { usePatientDiagnosis } from 'infrastructure/hooks/patient/use-patient-diagnosis';
import { ModuleType } from 'infrastructure/enums';
import { useEdit } from 'infrastructure/providers/edit-provider';
import classNames from 'classnames';
import { isMobile } from 'utils/deviceHelper';
import useUser from 'utils/useUser';

import s from './styles.module.scss';
import { diagnosisValidationSchema } from './validation-schema';

const formKey = 'AdminPanelDiagnosisCodesForm';

interface IAdminPanelDiagnosisCodesFormProps {
  patientGuid: string;
  module: ModuleType;
}

const AdminPanelDiagnosisCodesForm: React.FC<
  IAdminPanelDiagnosisCodesFormProps
> = (props) => {
  const { patientGuid, module } = props;

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const isAdmin = module === ModuleType.ADMIN;

  const { canEdit, setEdit } = useEdit();
  const { t } = useTranslation();
  const { deviceType, iamPatientAcceptance } = useUser();

  const { loading, loadPatientDiagnosis, updatePatientDiagnosis } =
    usePatientDiagnosis({ patientGuid });

  const formik = useFormik({
    initialValues: {} as PatientDiagnosis,
    onSubmit: async (values) => {
      await updatePatientDiagnosis({
        ...values,
        patientGuid,
      }).then(() => {
        formik.resetForm({ values });
      });
      setIsEdit(false);
      setEdit(false);
    },
    validateOnChange: false,
    validationSchema: diagnosisValidationSchema(),
  });

  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };
  const onSave = () => {
    formik.submitForm();
  };
  const onCancel = () => {
    formik.resetForm();
    setIsEdit(false);
    setEdit(false);
  };

  const loadData = async () => {
    const data = await loadPatientDiagnosis();
    if (data) {
      formik.resetForm({ values: new PatientDiagnosis(data) });
    }
  };

  useEffect(() => {
    loadData();
  }, [patientGuid]);

  return (
    <PatientBlock
      title={t('labels.dxCodes')}
      canEdit={isAdmin}
      isEdit={isEdit}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      loading={loading}
      disabled={!canEdit(formKey) || iamPatientAcceptance}
    >
      <BaseForm formik={formik} plaintext={!isEdit} readonly={!isEdit}>
        <BaseGrid columns={2} className={s['inputs-wrapper']}>
          <BaseFormItem
            name="bloodGlucose"
            label={t('labels.bloodGlucose')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="bloodPressure"
            label={t('labels.bloodPressure')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="weight"
            label={t('labels.weight')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="pulmonary"
            label={t('labels.pulmonary')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="sleep"
            label={t('labels.sleep')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="allCodes"
            label={t('labels.allCodes')}
            className={classNames({ [s['input-wrapper']]: !isEdit })}
            showErrorBlock={isMobile(deviceType) ? isEdit : true}
          >
            <BaseInput />
          </BaseFormItem>
        </BaseGrid>
      </BaseForm>
    </PatientBlock>
  );
};

export default AdminPanelDiagnosisCodesForm;
