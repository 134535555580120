import i18next from 'i18next';
import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

import type { TSchema } from 'infrastructure/functions';

export const clinicalNotesValidationSchema = () =>
  createValidationSchema({
    note: commonValidationSchemas
      .string({ required: true })
      .min(
        50,
        i18next.t('validation.noteMustBeAtLeastCharacters', { value: 50 }),
      ),
  });

export type Values = TSchema<ReturnType<typeof clinicalNotesValidationSchema>>;
