import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import {
  XYPlot,
  LineSeries,
  XAxis,
  YAxis,
  DiscreteColorLegend,
  HorizontalGridLines,
  MarkSeries,
} from 'react-vis';
import common from 'constants/common';
import BaseLoader from 'components/atoms/base-loader';
import { useAppSelector } from 'store';

import ChartHint from './chartHint';
import StickyTemperatureYAxis from './temperatureStickyYAxis';

const legendItems = [
  {
    title: 'Temperature',
    color: common.chartColors.temperature.main,
    strokeStyle: 'solid',
  },
  {
    title: 'Temperature High',
    color: common.chartColors.temperature.high,
    strokeStyle: 'dashed',
  },
  {
    title: 'Temperature Low',
    color: common.chartColors.temperature.low,
    strokeStyle: 'dashed',
  },
];

const normalizeChartData = (data, dates) => {
  const deviceData = data;
  const [startTime, endTime] = dates;

  let temperatureData = deviceData.map((item) => ({
    value: item.data.temperature,
    createdAt: item.createdAt,
  }));
  if (temperatureData.length < 1) {
    const emptyData = [
      {
        value: null,
        createdAt: startTime.getTime(),
      },
      {
        value: null,
        createdAt: endTime.getTime(),
      },
    ];
    temperatureData = emptyData;
  }
  const temperatureLow = temperatureData.map((item) => ({
    x: item.createdAt,
    y: common.temperatureCriticals.min,
  }));
  const temperatureHigh = temperatureData.map((item) => ({
    x: item.createdAt,
    y: common.temperatureCriticals.max,
  }));
  const temperatureMain = temperatureData.map((item) => ({
    x: item.createdAt,
    y: item.value,
  }));
  if (temperatureHigh.length === 1) {
    const xValue = temperatureHigh[0].x;
    temperatureHigh.unshift({
      x: xValue - 86400000,
      y: common.temperatureCriticals.max,
    });
    temperatureHigh.push({
      x: parseInt(xValue) + 86400000,
      y: common.temperatureCriticals.max,
    });
    temperatureLow.unshift({
      x: xValue - 86400000,
      y: common.temperatureCriticals.min,
    });
    temperatureLow.push({
      x: parseInt(xValue) + 86400000,
      y: common.temperatureCriticals.min,
    });
  }
  return {
    temperature: {
      low: {
        data: temperatureLow,
        stroke: common.chartColors.temperature.low,
        strokeStyle: 'dashed',
      },
      high: {
        data: temperatureHigh,
        stroke: common.chartColors.temperature.high,
        strokeStyle: 'dashed',
      },
      main: {
        data: temperatureMain,
        stroke: common.chartColors.temperature.main,
        strokeStyle: 'solid',
      },
    },
  };
};

const TemperatureChart = ({ data, loading }) => {
  const dates = useAppSelector((store) => store.patient.dates);
  const isHoveringOverLine = useRef({});
  const [hoveredPoint, setHoveredPoint] = useState(null);
  const [hintType, setHintType] = useState('');
  const yScale = common.chartYaxisScales.temperature;
  let chartDate = '';

  if (loading) return <BaseLoader className="loader" loading />;
  const chartData = normalizeChartData(data, dates);

  return (
    <div className="chart" data-cy="temperature-chart">
      <div className="chart-header">
        <div className="chart-header-title" data-cy="temperature-chart-title">
          <p>Temperature</p>
          <p>F</p>
        </div>
        <DiscreteColorLegend items={legendItems} orientation="horizontal" />
      </div>
      <div className="chart-main">
        <StickyTemperatureYAxis />
        <XYPlot
          xType="time"
          height={common.temperatureChartHeight}
          onMouseLeave={() => {
            setHoveredPoint(null);
          }}
          yDomain={yScale}
          width={common.chartWidth}
        >
          <HorizontalGridLines tickTotal={17} />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => {
              const dayMonth = format(d, 'MMM-dd');
              if (dayMonth !== chartDate) {
                chartDate = dayMonth;
                return format(d, common.dateFormats.chartXDate);
              }
              return '';
            }}
            tickSizeOuter={5}
            tickSizeInner={0}
            tickPadding={24}
          />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => format(d, common.dateFormats.chartXTime)}
            tickTotal={15}
            tickSizeOuter={5}
            tickSizeInner={0}
          />
          <YAxis
            attr="y"
            attrAxis="x"
            orientation="left"
            tickTotal={17}
            className="hidden-axis"
          />
          <LineSeries curve={null} opacity={1} {...chartData.temperature.low} />
          <LineSeries
            curve={null}
            opacity={1}
            {...chartData.temperature.high}
          />
          {chartData.temperature.main.data[0].y !== null &&
            data.length === 1 && (
              <MarkSeries
                color={common.chartColors.temperature.main}
                data={chartData.temperature.main.data}
                opacity={1}
                size={2.5}
                onNearestXY={(datapoint) => {
                  setHoveredPoint(datapoint);
                  setHintType('Temperature');
                }}
              />
            )}
          {chartData.temperature.main.data[0].y !== null && data.length > 1 && (
            <LineSeries
              curve={null}
              opacity={1}
              {...chartData.temperature.main}
              onSeriesMouseOver={() => {
                isHoveringOverLine.current[0] = true;
              }}
              onSeriesMouseOut={() => {
                isHoveringOverLine.current[0] = false;
              }}
              onNearestXY={(datapoint) => {
                if (isHoveringOverLine.current[0]) {
                  setHoveredPoint(datapoint);
                  setHintType('Temperature');
                }
              }}
            />
          )}
          {hoveredPoint && (
            <ChartHint
              hoveredPoint={hoveredPoint}
              type={hintType}
              yScales={common.chartYaxisScales.temperature}
            />
          )}
        </XYPlot>
      </div>
    </div>
  );
};

TemperatureChart.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default TemperatureChart;
