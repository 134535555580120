import dataService from 'services/dataService';
import { API_PATIENTS_INTERACTION_STATUS } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface DataProps {
  patientGuid: string;
  isInteracted: boolean;
}

export interface IUpdateInteractionResponse {
  interactionStatus: boolean;
  patientGuid: string;
}

export const updateInteractionStatusFn = async (postData: DataProps) => {
  const { error, data } =
    await dataService.updateOnly<IUpdateInteractionResponse>(
      API_PATIENTS_INTERACTION_STATUS,
      postData,
    );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
