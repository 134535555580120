import type { LoginStatus } from 'infrastructure/enums/login-status';
import type { IDoctorAndAgencyDoctorSelect } from 'infrastructure/interfaces';

export class DoctorAndAgencyDoctorSelect {
  guid: string;
  label: string;
  type: IDoctorAndAgencyDoctorSelect['type'];
  status: LoginStatus;

  constructor(data: IDoctorAndAgencyDoctorSelect) {
    this.guid = data.guid;
    this.label = data.fullName;
    this.type = data.type;
    this.status = data.status;
  }
}
