import { createModel } from '@rematch/core';

import type { QueryKey } from '@tanstack/react-query';
import type { RootModel } from 'store/models';

interface State {
  interactiveTimeDashboardData: QueryKey;
}

const queryKeys = createModel<RootModel>()({
  state: {
    interactiveTimeDashboardData: ['interactiveTimeDashboard', {}] as QueryKey,
  } as State,
  reducers: {
    setQueryKey: (state, payload: { key: keyof State; value: QueryKey }) => ({
      ...state,
      [payload.key]: payload.value,
    }),
  },
});

export default queryKeys;
