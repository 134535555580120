const MedIcon = (props) => (
  <svg
    width="79"
    height="105"
    viewBox="0 0 79 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M58.6648 15.6232C58.6648 17.0964 58.6648 18.5083 58.6648 19.9201C58.6446 21.6185 57.8778 22.437 56.2232 22.437C45.0038 22.437 33.8247 22.437 22.6053 22.437C20.9708 22.437 20.1435 21.5776 20.1435 19.9201C20.1233 18.5083 20.1435 17.0759 20.1435 15.5004M40.6653 1.77046C42.5016 2.24109 44.3782 2.69125 45.7706 4.06219C46.8199 5.06482 47.7279 6.25161 48.5754 7.4384C48.9185 7.92949 49.201 8.13411 49.7861 8.13411C51.9453 8.09318 54.0842 8.11364 56.2434 8.13411C57.8778 8.13411 58.6446 8.91166 58.6648 10.5486C58.685 12.1651 58.6648 13.7611 58.6648 15.4799C61.4293 15.4799 64.0929 15.4185 66.7565 15.5004C68.048 15.5413 69.3596 15.705 70.5905 16.0529C74.4648 17.1578 77.2293 20.9023 77.2293 24.9742C77.2495 47.8915 77.2495 70.8293 77.2293 93.7466C77.2293 99.1485 72.8707 103.241 67.1601 103.241C59.25 103.261 51.3197 103.241 43.4096 103.241C32.957 103.241 22.5044 103.241 12.0316 103.241C9.40836 103.241 7.02727 102.545 5.00939 100.765C3.07222 99.0667 2.00274 96.8977 1.78078 94.3195C1.74042 93.8898 1.7606 93.4601 1.7606 93.0304C1.7606 70.5633 1.7606 48.0961 1.7606 25.6495C1.7606 20.5954 4.4242 17.0555 9.24693 15.7664C10.155 15.5208 11.1034 15.4799 12.0518 15.4595C14.3925 15.4185 16.7333 15.439 19.0942 15.439C19.417 15.439 19.7197 15.4185 20.1435 15.3981C20.1435 13.802 20.1435 12.2469 20.1435 10.7123C20.1435 8.89119 20.9103 8.11364 22.686 8.09318C24.8451 8.09318 26.9841 8.11364 29.1432 8.07272C29.4661 8.07272 29.9504 7.8681 30.0715 7.62256C31.6858 4.43051 34.2283 2.52755 37.699 1.89323C37.7798 1.87277 37.8605 1.79092 37.9412 1.75C38.8492 1.77046 39.7573 1.77046 40.6653 1.77046Z"
      stroke="#FC7013"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.8448 43.6465C48.4175 43.6465 50.8636 43.6465 53.3098 43.6465C55.4819 43.6465 56.1778 44.3403 56.1778 46.5058C56.1778 48.8185 56.1778 51.1102 56.1778 53.4229C56.1778 55.4832 55.4186 56.2611 53.352 56.2611C51.2011 56.2611 49.0712 56.2611 46.9202 56.2611C46.5828 56.2611 46.2665 56.2822 45.8237 56.3032C45.8237 58.763 45.8237 61.1598 45.8237 63.5566C45.8237 65.8693 45.191 66.5 42.8503 66.5C40.5096 66.5 38.1477 66.5 35.807 66.5C33.9091 66.5 33.171 65.7431 33.171 63.8299C33.171 61.349 33.171 58.8892 33.171 56.2401C32.7704 56.2401 32.4119 56.2401 32.0534 56.2401C29.8813 56.2401 27.6882 56.2611 25.5162 56.2401C23.6394 56.2191 22.8591 55.4412 22.838 53.57C22.8169 51.1312 22.8169 48.6924 22.838 46.2325C22.8591 44.3614 23.6183 43.6255 25.5373 43.6045C28.0467 43.6045 30.5561 43.6045 33.171 43.6045C33.171 41.3128 33.171 39.1053 33.171 36.9187C33.171 36.5193 33.171 36.0988 33.171 35.6993C33.2132 34.1435 34.0145 33.3026 35.575 33.2815C38.1899 33.2395 40.8259 33.2395 43.4408 33.2815C45.0013 33.3026 45.8026 34.1646 45.8237 35.7204C45.8658 38.3484 45.8448 40.9134 45.8448 43.6465Z"
      stroke="#FC7013"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.5104 84.002C46.4394 84.002 53.3892 84.002 60.3181 84.002C60.7146 84.002 61.132 83.9844 61.5286 84.0547C62.5512 84.1953 63.2399 84.9336 63.1982 85.8125C63.1773 86.6562 62.4886 87.3242 61.4868 87.4473C61.0486 87.5 60.6103 87.5 60.172 87.5C46.3976 87.5 32.6232 87.5 18.8488 87.5C18.4106 87.5 17.9723 87.5 17.534 87.4473C16.5114 87.3242 15.8435 86.6738 15.8018 85.8301C15.76 84.9512 16.4488 84.2129 17.4714 84.0723C17.8679 84.0195 18.2853 84.0195 18.6819 84.0195C25.6317 84.002 32.5606 84.002 39.5104 84.002Z"
      stroke="#FC7013"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.6367 75.25C34.8787 75.25 44.1407 75.25 53.3827 75.25C53.762 75.25 54.1612 75.2676 54.5405 75.3203C55.5785 75.4785 56.1973 76.1463 56.1773 77.025C56.1773 77.9036 55.4986 78.589 54.4806 78.712C54.1413 78.7648 53.782 78.7472 53.4227 78.7472C48.7917 78.7472 44.1407 78.7472 39.5097 78.7472C34.739 78.7472 29.9883 78.7472 25.1976 78.7472C23.7005 78.7472 22.8422 78.097 22.8222 77.025C22.8222 76.1814 23.421 75.496 24.3792 75.3379C24.7984 75.2676 25.2175 75.25 25.6367 75.25Z"
      stroke="#FC7013"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MedIcon;
