import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Input from 'components/atoms/input';
import { fullNamePresenter } from 'utils';

const Physician = ({ errors, doctors, patient, values }) => {
  const { getDoctors: doctorsLoading } = useSelector(
    (state) => state.loading.effects.adminPanelPatients,
  );
  const doctorsList = [...doctors];
  // if patient's doctor is not active his doctor will not be in doctors list
  // so we add his doctor manually to doctors list
  if (
    patient &&
    values?.clinicGuid === patient?.clinic?.clinicGuid &&
    !doctorsList.find((d) => d.guid === patient?.doctor?.doctorGuid)
  ) {
    doctorsList.unshift({
      ...patient?.doctor,
      guid: patient?.doctor?.doctorGuid,
    });
  }
  return (
    <div className="admin-patient-form-block">
      <span>Provider Information</span>
      <Row>
        <Col lg="6" md="6" sm="6" xs="12">
          <Input
            dataCy="provider-name-input"
            label="Provider Name"
            id="doctorGuid"
            type="select"
            isLoading={doctorsLoading}
            options={doctorsList.map((doctor) => ({
              label: fullNamePresenter({
                title: doctor.title,
                firstName: doctor.firstName,
                lastName: doctor.lastName,
              }),
              id: doctor.guid,
            }))}
            onChange={(e, setFieldValue) => {
              const [pickedDoctor] = doctorsList.filter(
                (doctor) => doctor.guid === e.id,
              );
              if (pickedDoctor.roleNames) {
                setFieldValue(
                  'practiceCategory',
                  pickedDoctor.roleNames.join(','),
                );
              }
              setFieldValue(
                'professionType',
                pickedDoctor.professionType || '',
              );
            }}
          />
          {!values?.doctorGuid && errors.doctorGuid ? (
            <div className="input-error">{errors.doctorGuid}</div>
          ) : null}
        </Col>
        <Col lg="6" md="6" sm="6" xs="12">
          <Input label="Category" id="professionType" disabled />
        </Col>
      </Row>
    </div>
  );
};

Physician.propTypes = {
  errors: PropTypes.shape(),
  doctors: PropTypes.array,
  values: PropTypes.shape(),
  patient: PropTypes.shape(),
};

export default Physician;
