export const Routes = {
  adminClinics: '/admin/clinics',
  patientDetail: (patientGuid: string) => `/patient/${patientGuid}`,
  ccmPatientDetail: (patientGuid: string) => `/ccm/patient/${patientGuid}`,
  adminClinicsDetail: (clinicGuid: string) => `/admin/clinic/${clinicGuid}`,
  adminClinicsEdit: (clinicGuid: string) => `/admin/clinic/${clinicGuid}/edit`,
  adminClinicDetail: (clinicGuid: string) => `/admin/clinic/${clinicGuid}`,
  adminAgencyDetail: (agencyGuid: string) => `/admin/agency/${agencyGuid}`,
  adminPatientDetail: (patientGuid: string) => `/admin/patient/${patientGuid}`,
  consentForm: (patientGuid: string) => `/ccm/consent/${patientGuid}`,
  eligibilityToolsItem: (processGuid: string) =>
    `/eligibility-tool/${processGuid}`,
};

export const moduleRoutes = {
  ccm: '/ccm',
} as const;
