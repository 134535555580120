import React from 'react';
import { Card, CardBody } from 'reactstrap';
import classNames from 'classnames';

import s from './styles.module.scss';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

const BaseCard: React.FC<IBaseComponentProps> = (props) => {
  const { className, children } = props;

  const cardClassNames = classNames(s.card, className);
  return (
    <Card className={cardClassNames}>
      <CardBody className={s['card-outer']}>{children}</CardBody>
    </Card>
  );
};

export default BaseCard;
