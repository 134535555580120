import dataService from 'services/dataService';
import {
  apiAdminPanelAgenciesGet,
  apiGetAgencyByGuid,
  API_ADMIN_PANEL_AGENCIES,
} from 'services/dataService/resources';

const ITEMS_PER_PAGE = 100;
const INITIAL_STATE = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  filters: {
    guid: null,
  },
  data: [],
  totalPages: 0,
  totalCount: 0,
  agency: {},
};

const adminPanelAgencies = {
  state: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    setPage: (state, payload) => ({
      ...state,
      page: payload < 1 ? 1 : payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    setAgencies: (state, payload) => ({
      ...state,
      data: payload,
    }),
    setFilters: (state, payload) => ({
      ...state,
      filters: { ...state.filters, ...payload },
    }),
    setAgency: (state, payload) => ({
      ...state,
      agency: payload,
    }),
  },
  effects: (dispatch) => ({
    async sort({ value, desc }, state) {
      let data = [...state.adminPanelAgencies.data];
      data = data.sort((a, b) => {
        if (desc) {
          return a[value] > b[value] ? 1 : -1;
        }
        return b[value] > a[value] ? 1 : -1;
      });
      dispatch.adminPanelAgencies.setAgencies(data);
    },
    async getAgencies({ items = ITEMS_PER_PAGE, signal }, state) {
      const { filters } = state.adminPanelAgencies;
      const { page } = state.adminPanelAgencies;
      const { data } = await dataService.getList(
        apiAdminPanelAgenciesGet(items, page, filters),
        { signal },
      );
      if (data) {
        dispatch.adminPanelAgencies.setAgencies(data?.items || []);
        const totalCount = data?.count || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.adminPanelAgencies.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
      } else {
        dispatch.adminPanelAgencies.setAgencies([]);
        dispatch.adminPanelAgencies.setTotalCountAndTotalPages({
          totalCount: 0,
          totalPages: 0,
        });
      }
    },
    async setAgenciesPage({ agencyGuid, pageOffset, getList = true }, state) {
      const { adminPanelAgencies: adminPanelAgenciesState } = state;
      const { page, totalPages } = adminPanelAgenciesState;
      const newPage = page + pageOffset;
      dispatch.adminPanelAgencies.setPage(
        newPage <= totalPages ? newPage : totalPages,
      );
      if (getList) {
        return dispatch.adminPanelAgencies.getAgencies({
          agencyGuid,
          page: newPage,
        });
      }
    },

    async getAgency(agencyGuid) {
      if (agencyGuid) {
        const { data, error } = await dataService.getList(
          apiGetAgencyByGuid(agencyGuid),
        );
        if (error) throw error;
        if (data) {
          dispatch.adminPanelAgencies.setAgency(data);
        }
      } else dispatch.adminPanelAgencies.setAgency({});
    },
    async editAgency(body) {
      const { error, data } = await dataService.updateOnly(
        `${API_ADMIN_PANEL_AGENCIES}`,
        body,
      );
      return { error, data };
    },
    async createAgency(body) {
      const { error, data } = await dataService.createOne(
        API_ADMIN_PANEL_AGENCIES,
        body,
      );
      return { error, data };
    },
  }),
};

export default adminPanelAgencies;
