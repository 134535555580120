import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { CONSENT_FORM_PDF_GENERATE } from 'services/dataService/resources';
import { downloadPDF } from 'utils';

interface Props {
  patientGuid: string;
}

export const useConsentFormReport = ({ patientGuid }: Props) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['ccmConsentFormReport'],
    mutationFn: async () => {
      const { error, data } = await dataService.createOne(
        CONSENT_FORM_PDF_GENERATE,
        { patientGuid },
      );

      if (error) {
        throw new Error(error);
      }

      const reportName = `consent-form-${patientGuid}.pdf`;
      downloadPDF(data, reportName);

      return data;
    },
  });

  return { sendConsentFormReport: mutate, isLoading };
};
