import { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import Input from 'components/atoms/input';

import type { FC } from 'react';

interface Props {
  errors: {
    professionType?: string;
    agencies?: string | any;
    ratePerReading?: string;
  };
  agenciesLoading?: boolean;
  agencies?: any[];
}

const Profession: FC<Props> = ({
  errors,
  agenciesLoading = false,
  agencies,
}) => {
  const agencyOptions = useMemo(() => {
    return agencies?.map(({ guid, legalname }) => ({
      value: guid,
      label: legalname,
    }));
  }, [agencies]);

  return (
    <div className="admin-patient-form-block">
      <span>Profession Info</span>
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <Input
            label="Agencies"
            id="agencies"
            type="select"
            isMulti
            isLoading={agenciesLoading}
            options={agencyOptions}
            onChange={(e, setFieldValue) => {
              setFieldValue('agencies', e);
            }}
          />
          {errors.agencies ? (
            <div className="input-error">{errors.agencies}</div>
          ) : null}
        </Col>
        <Col lg="12" md="12" sm="12" xs="12">
          <Input
            id="ratePerReading"
            label="Rate per readings (leave empty to use default rate)"
            onChange={(e, setFieldValue) =>
              setFieldValue('ratePerReading', e.target.value)
            }
          />
          {errors.ratePerReading ? (
            <div className="input-error">
              {errors.ratePerReading.split('.')[0]}
            </div>
          ) : null}
        </Col>
        <Col lg="12" md="12" sm="12" xs="12">
          <Input
            id="professionType"
            label="Profession"
            onChange={(e, setFieldValue) =>
              setFieldValue('professionType', e.target.value)
            }
          />
          {errors.professionType ? (
            <div className="input-error">
              {errors.professionType.split('.')[0]}
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default Profession;
