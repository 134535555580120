import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Routes } from 'infrastructure/consts/routes';

import s from './styles.module.scss';

import type { Clinic } from 'infrastructure/classes/clinic';

interface IProps {
  data: Clinic;
}

const InfoColumn: React.FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className={s.info} data-cy="clinicListTable-info">
      {data.agencyGuid && (
        <p className={s.item}>
          {`${t('labels.agency')}: `}
          <Link
            data-cy="clinicListTable-agency-link"
            to={Routes.adminAgencyDetail(data.agencyGuid)}
          >
            <span>
              <b>{data.agencyName}</b>
            </span>
          </Link>
        </p>
      )}
      <p className={s.item} data-cy="clinicListTable-serviceDeliveryModel">
        {`${t('labels.serviceDeliveryModel')}: `}
        <span>{data.serviceDeliveryModel}</span>
      </p>
      {!!data.referralTypes?.length && (
        <p className={s.item} data-cy="clinicListTable-referralTypes">
          {`${t('labels.referralTypes')}: `}
          <span>{data.referralTypes?.join(', ')}</span>
        </p>
      )}
    </div>
  );
};

export default InfoColumn;
