import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const MessagesSearchIcon = ({
  color = COLORS.BUOYANT,
  ...props
}: IIconProps) => (
  <svg
    width="83"
    height="84"
    viewBox="0 0 83 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M60.9125 54.1589C65.0145 48.5741 67.4375 41.6794 67.4375 34.2188C67.4375 15.5964 52.3411 0.5 33.7188 0.5C15.0964 0.5 0 15.5964 0 34.2188C0 52.8411 15.0964 67.9375 33.7188 67.9375C41.1814 67.9375 48.0778 65.5132 53.6634 61.4091L53.6589 61.4125C53.8119 61.6199 53.9823 61.8185 54.1701 62.0063L74.1444 81.9806C76.1702 84.0065 79.4548 84.0065 81.4806 81.9806C83.5065 79.9548 83.5065 76.6702 81.4806 74.6444L61.5063 54.6701C61.3185 54.4823 61.1199 54.3119 60.9125 54.1589ZM62.25 34.2188C62.25 49.9761 49.4761 62.75 33.7188 62.75C17.9614 62.75 5.1875 49.9761 5.1875 34.2188C5.1875 18.4614 17.9614 5.6875 33.7188 5.6875C49.4761 5.6875 62.25 18.4614 62.25 34.2188Z"
      fill={color}
    />
  </svg>
);

export default MessagesSearchIcon;
