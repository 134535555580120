import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({
  onClick,
  onChange,
  id,
  name,
  isChecked,
  label,
  disabled,
  dataCy,
  canEditPage = true,
}) => (
  <div className="radio-button">
    <label htmlFor={id}>
      <input
        data-cy={dataCy}
        type="radio"
        id={id}
        name={name}
        className="radio-button__input"
        checked={isChecked}
        onChange={onChange}
        onClick={onClick}
        disabled={disabled}
      />
      <span
        className={
          canEditPage && !disabled
            ? 'radio-button__checkmark'
            : 'radio-button__checkmark disabled'
        }
      >
        <span />
      </span>
      <span
        className={
          canEditPage && !disabled
            ? 'radio-button__label'
            : 'radio-button__label disabled'
        }
      >
        {label}
      </span>
    </label>
  </div>
);

RadioButton.defaultProps = {
  onClick: () => {},
};

RadioButton.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  canEditPage: PropTypes.bool,
  dataCy: PropTypes.string,
};

export default RadioButton;
