import React from 'react';
import PropTypes from 'prop-types';
import common from 'constants/common';
import { Card, CardBody } from 'reactstrap';
import classnames from 'classnames';

const commonStatuses = {
  ...common.statusPatients,
  patient_refused: {
    label: 'Patient Refused',
    color: '#61B753',
  },
};
const Tiles = ({ tiles, setFilter, filters }) => (
  <div className="tiles-wrapper">
    <div className="tiles">
      {tiles &&
        Object.keys(tiles).map((key, index) => (
          <button
            className={classnames('tiles__item-btn', {
              'tiles__item-btn--active': filters.status === key,
            })}
            data-cy="status-report__tile-btn"
            key={index}
            onClick={() => {
              if (key !== filters.status) {
                setFilter({ status: key });
                return;
              }
              setFilter({ status: '' });
            }}
          >
            <Card className="card-border">
              <CardBody className="tiles__item card-outer">
                <div
                  className="tiles__item-title"
                  data-cy="status-report__tile-title"
                >
                  {commonStatuses[key].label}
                </div>
                <div
                  className="tiles__item-value"
                  data-cy="status-report__tile-count"
                >
                  {tiles[key]}
                </div>
              </CardBody>
            </Card>
          </button>
        ))}
    </div>
  </div>
);

Tiles.defaultProps = {
  setFilter: () => {},
};

Tiles.propTypes = {
  tiles: PropTypes.object,
  setFilter: PropTypes.func,
  filters: PropTypes.object,
};

export default Tiles;
