import { Container } from 'reactstrap';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import { useTranslation } from 'react-i18next';
import AdminAgencyUsersTable from 'components/organisms/admin-panel/agency-users-table';
import AgencyUsersFilter from 'components/organisms/admin-panel/agency-users-filter';
import ScrollToTopButton from 'components/atoms/scroll-to-top-button';

import s from './styles.module.scss';

const AdminAgencyDoctors = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <AdminNavigationHeader title={t('labels.agencyUsersList')} />
      <div className={s.body}>
        <AgencyUsersFilter />
        <AdminAgencyUsersTable />
      </div>
      <ScrollToTopButton />
    </Container>
  );
};

export default AdminAgencyDoctors;
