import {
  DateFormatter,
  isNotNull,
  isNotUndefined,
  isObjectEmpty,
} from 'infrastructure/functions';
import { AppointmentMethod, DateFormats } from 'infrastructure/enums';
import { NextAppointment } from 'infrastructure/classes/next-appointment';
import { useEffect, useMemo, useState } from 'react';
import AppointmentNotPlanedIcon from 'components/atoms/icons/appointmentNotPlanedIcon';
import AppointmentSmsIcon from 'components/atoms/icons/appointmentSmsIcon';
import AppointmentWebcamIcon from 'components/atoms/icons/appointmentWebcamIcon';
import AppointmentEmailIcon from 'components/atoms/icons/appointmentEmailIcon';
import { appointmentMethodMap } from 'constants/common';
import { useDoctorAndAgencyDoctorSelect } from 'infrastructure/hooks/select/use-doctor-and-agency-doctor';
import { usePatientNextAppointment } from 'infrastructure/hooks/patient/use-patient-next-appointment';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { isCcmActionAllowedStatus } from 'utils/userTypeHelper';
import { utcToZonedTime } from 'date-fns-tz';

import { ccmNextAppointmentValidationSchema } from '../validation-schema';

import type { DoctorAndAgencyDoctorSelect } from 'infrastructure/classes/select/doctor-and-agency-doctor';
import type { IBaseSelectBoxOption } from 'components/atoms/base-select-box/types';

interface Props {
  patientGuid: string;
}

export const useAppointment = ({ patientGuid }: Props) => {
  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [doctor, setDoctor] = useState<DoctorAndAgencyDoctorSelect>();
  const { Alert, showAlert } = useAlert();
  const {
    nextAppointment,
    loading: updateLoading,
    updatePatientNextAppointment,
  } = usePatientNextAppointment({
    patientGuid,
  });
  const { loading, loadDoctorsAndAgencyDoctors } =
    useDoctorAndAgencyDoctorSelect({
      patientGuid,
    });
  const { t } = useTranslation();
  const { patientInfo, loading: patientInfoLoading } = usePatientInfo({
    patientGuid,
  });
  const disableButton = !isCcmActionAllowedStatus(patientInfo?.ccmStatus);

  const toggleOpen = () => setOpen((prev) => !prev);

  const appointmentMethod =
    isNotUndefined(nextAppointment?.appointmentMethod) &&
    isNotNull(nextAppointment?.appointmentMethod)
      ? appointmentMethodMap[nextAppointment.appointmentMethod]
      : '';
  const emptyData = !nextAppointment || isObjectEmpty(nextAppointment);

  const appointmentMap = {
    [AppointmentMethod.Email]: <AppointmentEmailIcon />,
    [AppointmentMethod.PhoneOrVideoConference]: <AppointmentWebcamIcon />,
    [AppointmentMethod.SMS]: <AppointmentSmsIcon />,
    default: <AppointmentNotPlanedIcon />,
  };

  const Icon = appointmentMap[nextAppointment?.appointmentMethod ?? 'default'];

  const loadDoctors = async (doctorGuid: string) => {
    const doctors = await loadDoctorsAndAgencyDoctors({
      guid: doctorGuid,
      page: 1,
      items: 1,
      totalCount: 0,
    });
    setDoctor(doctors[0]);
  };

  useEffect(() => {
    if (nextAppointment?.doctorGuid) loadDoctors(nextAppointment.doctorGuid);
    if (nextAppointment?.agencyDoctorGuid)
      loadDoctors(nextAppointment.agencyDoctorGuid);
    if (!nextAppointment?.doctorGuid || !nextAppointment?.agencyDoctorGuid)
      setDoctor(undefined);
  }, [nextAppointment?.doctorGuid, nextAppointment?.agencyDoctorGuid]);

  const initialData = useMemo(
    () =>
      !isNew && nextAppointment
        ? new NextAppointment(patientGuid, nextAppointment)
        : new NextAppointment(patientGuid),
    [isNew, nextAppointment],
  );

  const formik = useFormik<NextAppointment>({
    initialValues: initialData,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const scheduledWithData = values.getScheduledWithData();
      await updatePatientNextAppointment({
        patientGuid,
        appointmentDetails: values.appointment
          ? {
              guid: values.appointment.guid,
              ...scheduledWithData,
              appointmentMethod: values.appointment.appointmentMethod,
              scheduledDate: values.prepareDate(
                values.appointment.scheduledDate,
                values.appointment.scheduledTime,
              ),
            }
          : undefined,
      });
      formik.resetForm({ values });
      if (values.appointment) toggleOpen();
      setIsNew(false);
    },
    validateOnChange: false,
    validationSchema: ccmNextAppointmentValidationSchema(),
  });

  const { submitForm, resetForm } = formik;

  const onCancelForm = () => {
    resetForm();
    toggleOpen();
  };

  const deleteHandler = async () => {
    formik.setValues(new NextAppointment(patientGuid));
    updatePatientNextAppointment({ patientGuid });
  };

  const onDelete = async () => {
    const res = await showAlert({
      title: t('labels.deleteAppointment'),
      messageTitle: t('messages.confirmDeleteAppointment'),
      type: 'danger',
    });

    if (res) {
      await deleteHandler();
    }
  };

  const getDateAndTime = (scheduledDate: string) => {
    return DateFormatter({
      date: utcToZonedTime(scheduledDate, 'UTC'),
      format: DateFormats['MMM dd yyyy, hh:mm a'],
    });
  };

  const onChangeSelect = (
    value: any,
    option: IBaseSelectBoxOption<any, any>,
  ) => {
    formik.setFieldValue('appointment.scheduledWith', value);
    formik.setFieldValue('selectedDoctor', option.record);
  };

  const addNexAppointment = () => {
    setIsNew(true);
    toggleOpen();
  };

  const popupTitle = useMemo(
    () => (isNew ? t('labels.nextAppointment') : t('labels.editAppointment')),
    [isNew],
  );

  return {
    open,
    Icon,
    emptyData,
    appointmentMethod,
    doctor,
    updateLoading,
    isLoading: loading || patientInfoLoading,
    Alert,
    nextAppointment,
    formik,
    popupTitle,
    disableButton,
    getDateAndTime,
    onCancelForm,
    onDelete,
    submitForm,
    toggleOpen,
    onChangeSelect,
    addNexAppointment,
  };
};
