import { DateFormats, ModuleType } from 'infrastructure/enums';
import { DateFormatter } from 'infrastructure/functions';
import {
  SMS_ACTIVITIES_PER_PAGE,
  useAddClinicalNotes,
  useGetClinicalNotes,
  useQuestions,
} from 'infrastructure/hooks/ccm';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import useAlert from 'infrastructure/hooks/utils/use-alert';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fullNamePresenter } from 'utils';
import { isCcmActionAllowedStatus } from 'utils/userTypeHelper';
import i18next from 'i18next';

import s from '../styles.module.scss';

import type { Values } from 'components/molecules/admin-panel/forms/clinical-notes/validation-schema';

interface Props {
  patientGuid: string;
}

type TAlertMessage = { name: string; dob: string; score: number };

const genSaveAlertMessage = ({ name, dob, score }: TAlertMessage) => (
  <p className={s['alert-message']}>
    <p className={s['alert-title']}>
      {i18next.t('confirm.confirmSaveClinicalNoteTitle')}
    </p>
    <p className={s['alert-title']} style={{ paddingBottom: 16 }}>
      {i18next.t('confirm.confirmSaveClinicalNoteSubTitle')}
    </p>
    <p style={{ paddingBottom: 16 }}>
      {i18next.t('confirm.saveClinicalNoteInfoTitle')}
    </p>
    <p>{name}</p>
    <p>
      {i18next.t('confirm.dob')} {dob}
    </p>
    <p>
      {i18next.t('confirm.riskScore')} {score}
    </p>
  </p>
);

export const useClinicNotes = ({ patientGuid }: Props) => {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [defaultValue, setDefaultValue] = useState<string>();
  const [narrativeModalOpen, setNarrativeModalOpen] = useState(false);
  const [isNextAppointmentModalOpen, setIsNextAppointmentModalOpen] =
    useState(false);
  const { clinicalNotes, isLoading } = useGetClinicalNotes({
    patientGuid,
    meta: { items: SMS_ACTIVITIES_PER_PAGE, page },
  });
  const { patientInfo, loading } = usePatientInfo({ patientGuid });
  const { questions } = useQuestions({
    patientGuid,
    module: ModuleType.CCM,
  });
  const riskScore = questions?.score ?? 0;
  const { addClinicalNotesAsync, addLoading } = useAddClinicalNotes({ page });
  const { t } = useTranslation();
  const { Alert, showAlert } = useAlert();

  const disableButton = !isCcmActionAllowedStatus(patientInfo?.ccmStatus);

  const toggleOpen = () => setOpen((prev) => !prev);
  const toggleOpenNarrativeModal = () => setNarrativeModalOpen((prev) => !prev);

  const onCancelForm = () => {
    setDefaultValue(undefined);
    setOpen(false);
  };

  const onSubmit = async ({ note }: Values) => {
    const patientInteractionStatus = patientInfo?.ccmInteractionStatus;
    const fullPatientName = fullNamePresenter({
      firstName: patientInfo?.loginInfo?.firstName,
      middleName: patientInfo?.loginInfo?.middleName,
      lastName: patientInfo?.loginInfo?.lastName,
      title: patientInfo?.loginInfo?.title,
    });
    const birthDate = DateFormatter({
      date: patientInfo?.loginInfo?.birthDate,
      format: DateFormats['MM.dd.yyyy'],
    });
    const saveConfirmRes = await showAlert({
      title: t('labels.saveNote'),
      customMessage: genSaveAlertMessage({
        name: fullPatientName,
        score: riskScore,
        dob: birthDate,
      }),
      rejectBtnLabel: t('controls.no'),
      successBtnLabel: `${t('controls.yes')}, ${t('controls.save')}`,
    });

    if (note && saveConfirmRes)
      await addClinicalNotesAsync(
        { patientGuid, note },
        {
          onSuccess: onCancelForm,
        },
      );

    if (saveConfirmRes && !patientInteractionStatus) {
      const interactionConfirmRes = await showAlert({
        title: t('labels.monthlyInteraction'),
        messageTitle: t('confirm.clinicalNoteMonthlyInteraction'),
        successBtnLabel: t('controls.yes'),
        rejectBtnLabel: t('controls.no'),
      });
      if (interactionConfirmRes) setIsNextAppointmentModalOpen(true);
    }
  };

  const onUseTemplate = () => {
    toggleOpenNarrativeModal();
  };

  return {
    clinicalNotes,
    isLoading,
    defaultValue,
    Alert,
    addLoading,
    patientInfoLoading: loading,
    disableButton,
    narrativeModalOpen,
    isNextAppointmentModalOpen,
    open,
    page,
    onUseTemplate,
    onSubmit,
    toggleOpen,
    setPage,
    onCancelForm,
    toggleOpenNarrativeModal,
    setDefaultValue,
    setIsNextAppointmentModalOpen,
  };
};
