import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Input from 'components/atoms/input';
import ModalPopup from 'components/organisms/modal';
import Button from 'components/atoms/button';
import { showResult } from 'infrastructure/hooks/utils';
import useConfirm from 'utils/useConfirm';
import { useManualClinicNotesLog } from 'infrastructure/hooks/logs/use-manual-clinic-notes-log';
import { ModuleType } from 'infrastructure/enums';
import { useSelector } from 'react-redux';
import { currentMonthRange } from 'infrastructure/consts/current-month-range';

import validationSchema from './validationSchema';

const MANUAL_INTERACTIVE_TIME_FIELDS = [
  { id: 'time', type: 'number', label: 'Time', min: 0, max: 60 },
  { id: 'comment', type: 'textarea', label: 'Comment', rows: 8 },
];

const showConfirm = useConfirm({
  message:
    'Confirm that you would like to save these changes. Once saved, the entry cannot be changed or deleted',
  title: 'Confirm',
});

const ManualInteractionTimeModal = ({
  isOpen,
  toggle,
  patientGuid,
  isRpmPanel,
  loading,
  submit,
}) => {
  const dateRange = useSelector((state) => state.sms.dates);

  const { refetch } = useManualClinicNotesLog({
    patientGuid,
    meta: {
      dateRange: dateRange.length ? dateRange : currentMonthRange,
      module: ModuleType.RPM,
    },
  });

  const handleSubmit = async (values) => {
    const confirm = await showConfirm();
    if (confirm) {
      const postData = {
        patientGuid,
        isRpm: isRpmPanel,
        timeInMinutes: values.time,
        comment: values.comment,
      };
      const { error } = await submit(postData);
      showResult(error, null, toggle);
      if (!error) {
        refetch();
      }
    }
  };
  return (
    <ModalPopup
      isOpen={isOpen}
      toggle={toggle}
      title="Manual Interactive Time"
      className="popup manual-interactive-popup"
    >
      <Formik
        initialValues={{ time: 0, comment: '' }}
        validationSchema={validationSchema()}
        onSubmit={handleSubmit}
      >
        {({ errors }) => (
          <Form>
            {MANUAL_INTERACTIVE_TIME_FIELDS.map((field, index) => (
              <>
                <Input key={`input-${index}`} {...field} />
                {errors[field.id] ? (
                  <div key={`error-${index}`} className="input-error">
                    {errors[field.id]}
                  </div>
                ) : null}
              </>
            ))}
            <div className="text-center">
              <Button
                type="submit"
                text="Send"
                data-cy="send-button"
                disabled={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </ModalPopup>
  );
};
ManualInteractionTimeModal.defaultProps = {
  isOpen: false,
};

ManualInteractionTimeModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  patientGuid: PropTypes.string.isRequired,
  isRpmPanel: PropTypes.bool,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};

export default ManualInteractionTimeModal;
