import React from 'react';
import AdminPanelCcmNextAppointmentForm from 'components/molecules/admin-panel/forms/ccm-next-appointment';

import type { ModuleType } from 'infrastructure/enums';

interface IAdminTabbedFormCcmNextAppointmentProps {
  patientGuid: string;
  module: ModuleType;
}

const AdminTabbedFormCcmNextAppointment: React.FC<
  IAdminTabbedFormCcmNextAppointmentProps
> = (props) => {
  const { patientGuid, module } = props;

  return (
    <div>
      <AdminPanelCcmNextAppointmentForm
        patientGuid={patientGuid}
        module={module}
      />
    </div>
  );
};

export default AdminTabbedFormCcmNextAppointment;
