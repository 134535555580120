import type { SVGProps } from 'react';

const SmallDropdownArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="6"
    viewBox="0 0 9 6"
    // fill="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L4.5 3.08579L7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893C9.09763 0.683417 9.09763 1.31658 8.70711 1.70711L5.20711 5.20711C4.81658 5.59763 4.18342 5.59763 3.79289 5.20711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
      fill="#2C2543"
    />
  </svg>
);

export default SmallDropdownArrow;
