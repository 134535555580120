import { useState } from 'react';
import AllergyIcon from 'components/atoms/icons/allergyIcon';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import BaseModal from 'components/atoms/base-modal';
import { useAddAllergyLog } from 'infrastructure/hooks/ccm';
import { isObjectValid } from 'infrastructure/functions';
import AllergyLogForm from 'components/molecules/admin-panel/forms/allergy-log';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { isCcmActionAllowedStatus } from 'utils/userTypeHelper';

import AllergyLogTable from './components/allergy-table';
import s from './styles.module.scss';

import type { Values } from 'components/molecules/admin-panel/forms/allergy-log/validation-schema';
import type { FC } from 'react';

interface PatientAllergyLogProps {
  patientGuid: string;
}

const PatientAllergyLog: FC<PatientAllergyLogProps> = ({ patientGuid }) => {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { addAllergyLog, addLoading } = useAddAllergyLog();
  const { patientInfo, loading } = usePatientInfo({ patientGuid });
  const disableButton = !isCcmActionAllowedStatus(patientInfo?.ccmStatus);

  const toggleOpen = () => setOpen((prev) => !prev);

  const onSubmit = async (values: Values) => {
    if (isObjectValid(values))
      addAllergyLog({ ...values, patientGuid }, { onSuccess: toggleOpen });
  };

  const onCancelForm = () => {
    toggleOpen();
  };

  return (
    <>
      <div className={s.wrapper} id="allergy-history">
        <div className={s['title-wrapper']}>
          <h4 className={s.title} data-cy="allergy-log-section">
            <AllergyIcon />
            {t('labels.allergyLog')}
          </h4>
          <BaseButton
            label={`+ ${t('labels.addAllergy')}`}
            dataCy="addAllergy-button"
            onClick={toggleOpen}
            loading={addLoading}
            data-cy="add-allergy-log-btn"
            disabled={disableButton || loading}
          />
        </div>
        <div className={s.divider} />
        <AllergyLogTable
          patientGuid={patientGuid}
          page={page}
          setPage={setPage}
          disableActions={disableButton}
        />
      </div>
      <BaseModal
        title={t('labels.addAllergy')}
        open={open}
        onCancel={toggleOpen}
      >
        <AllergyLogForm
          loading={addLoading}
          onSubmit={onSubmit}
          onCancelForm={onCancelForm}
        />
      </BaseModal>
    </>
  );
};
export default PatientAllergyLog;
