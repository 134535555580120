import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { formatRoleName } from 'utils/formatters';

const ProfessionInfo = ({ data }) => (
  <Table borderless border="0" cellSpacing="0" cellPadding="0">
    <thead>
      <tr>
        <th colSpan="2">
          <div className="pagination-container">
            <h5>Profession Info</h5>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td />
        <td>
          {data.clinics.length && (
            <>
              <span className="subheader">Clinics: </span>
              <span data-cy="clinics">
                {data.clinics.map(({ label }) => label).join(', ')}
              </span>
            </>
          )}
          {data.professionType && (
            <>
              <span className="subheader">Profession: </span>
              <span data-cy="profession">{data.professionType}</span>
            </>
          )}
          {data.roles?.length && (
            <>
              <span className="subheader">Roles: </span>
              <span data-cy="roles">
                {data.roles.map(({ name }) => formatRoleName(name)).join(', ')}
              </span>
            </>
          )}
          {data.workNumber && (
            <>
              <span className="subheader">Work Number: </span>
              <span data-cy="user-clinic-work-number">{data.workNumber}</span>
            </>
          )}
          {data.secondaryLanguage && (
            <>
              <span className="subheader">Secondary Language: </span>
              <span data-cy="user-clinic-secondary-language">
                {data.secondaryLanguage}
              </span>
            </>
          )}
        </td>
      </tr>
    </tbody>
  </Table>
);

ProfessionInfo.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default ProfessionInfo;
