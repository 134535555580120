import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { timesDataSource } from 'infrastructure/data-sources/times';
import ClockCircleIcon from 'components/atoms/icons/clock-circle-icon';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { ITimeItem } from 'infrastructure/data-sources/times';

interface ITimeSelectBoxProps extends Omit<IBaseSelectBoxProps, 'options'> {
  disabledOptions?: string[];
  disabledUntil?: string;
  disabledAfter?: string;
  labelPrefix?: string;
  dataCy?: string;
}

const TimeSelectBox: React.FC<ITimeSelectBoxProps> = (props) => {
  const {
    disabledOptions = [],
    labelPrefix,
    disabledUntil,
    disabledAfter,
    dataCy,
    ...args
  } = props;
  const times = timesDataSource();

  const disabled = useMemo<string[]>(() => {
    const values = [...disabledOptions];
    let untilIndex: number;
    let afterIndex: number;

    if (disabledUntil) {
      untilIndex = times.findIndex((el) => el.id === disabledUntil);
    }
    if (disabledAfter) {
      afterIndex = times.findIndex((el) => el.id === disabledAfter);
    }

    times.forEach((el, index) => {
      if (untilIndex && untilIndex >= 0 && untilIndex > index) {
        values.push(el.id);
      }
      if (afterIndex && afterIndex >= 0 && afterIndex < index) {
        values.push(el.id);
      }
    });

    return values;
  }, [disabledOptions, disabledUntil, disabledAfter]);

  const options: IBaseSelectBoxOption<string, ITimeItem>[] = times.map(
    (val) => ({
      value: val.id,
      label: val.name,
      record: val,
      disabled: disabled.includes(val.id),
    }),
  );

  return (
    <BaseSelectBox
      options={options}
      dataCy={dataCy}
      value={undefined}
      placeholder="--:--"
      {...args}
      icon={<ClockCircleIcon />}
      labelRender={
        labelPrefix
          ? ({ label }) => {
              return `${labelPrefix} ${label}`;
            }
          : undefined
      }
    />
  );
};

export default TimeSelectBox;
