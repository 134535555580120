import s from './styles.module.scss';

import type { FC } from 'react';

interface Props {
  timer?: string;
  showTimer?: boolean;
}

const Timer: FC<Props> = ({ timer, showTimer }) => {
  if (!timer || !showTimer) return null;

  return (
    <div>
      <div className={s.timer}>{timer}</div>
    </div>
  );
};

export default Timer;
