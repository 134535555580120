import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const clinicInstructionsValidationSchema = () =>
  createValidationSchema({
    criticalBusinessHours: commonValidationSchemas.string({ nullable: true }),
    criticalAfterHours: commonValidationSchemas.string({ nullable: true }),
    businessHours: commonValidationSchemas.string({ nullable: true }),
    afterHours: commonValidationSchemas.string({ nullable: true }),
    contactMethod: commonValidationSchemas.string({ nullable: true }),
    contactMethodValue: commonValidationSchemas.string({ nullable: true }),
    notes: commonValidationSchemas.string({ required: true, nullable: true }),
    dateNoteLogged: commonValidationSchemas.string({
      required: true,
      nullable: true,
    }),
  });
