import dataService from 'services/dataService';
import { API_PATIENTS_DISCHARGES } from 'services/dataService/resources';

const discharge = {
  state: {},
  reducers: {
    setDischarge: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch) => ({
    async submit(postData, state) {
      const { error, data } = await dataService.createOne(
        API_PATIENTS_DISCHARGES,
        postData,
      );
      if (!error) {
        dispatch.patient.setProfile({
          ...state.patient.profile,
        });
      }
      return { error, data };
    },
  }),
};

export default discharge;
