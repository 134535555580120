import { Outlet } from 'react-router-dom';

import AuthProvider from '.';

const AuthProviderLayout = () => (
  <AuthProvider>
    <Outlet />
  </AuthProvider>
);

export default AuthProviderLayout;
