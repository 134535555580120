import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import StatusCount from 'components/templates/ccm/practice/onboardingStatus/statusCount';
import OnboardingTable from 'components/templates/ccm/practice/onboardingStatus/patientsTable';
import PracticeStats from 'components/templates/ccm/practice/onboardingStatus/practiceStats';

const CcmOnboardingStatus = () => {
  const statuses = useSelector((state) => state.ccmOnboardingStatus.statuses);
  const insurances = useSelector(
    (state) => state.ccmOnboardingStatus.insurances,
  );
  const { patients, patientCount } = useSelector(
    (state) => state.ccmOnboardingStatus,
  );
  const {
    ccmOnboardingStatus: { getOnboardingStatusReport },
  } = useDispatch();

  useEffect(() => {
    getOnboardingStatusReport();
  }, []);

  return (
    <Container>
      <Row>
        <Col xs="12">
          <PracticeStats
            patients={patients}
            statusBlocks={statuses}
            insuranceBlocks={insurances}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <StatusCount statusBlocks={statuses} />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <OnboardingTable patients={patients} count={patientCount} />
        </Col>
      </Row>
    </Container>
  );
};

export default CcmOnboardingStatus;
