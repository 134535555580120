import { useEffect } from 'react';

const isIgnoredElement = (e, ignoredClassName) => {
  if (
    e.target.className ===
    'react-datepicker__navigation react-datepicker__navigation--next'
  ) {
    return true;
  }
  if (e.target.classList.contains(ignoredClassName)) {
    return true;
  }
  return false;
};

const useOutsideClick = (
  ref,
  callback,
  ignoredClassName = 'ignore-onClickOutside',
) => {
  const handleClick = (e) => {
    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      !isIgnoredElement(e, ignoredClassName)
    ) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, callback, ignoredClassName]);
};

export default useOutsideClick;
