import AppointmentEmailIcon from 'components/atoms/icons/appointmentEmailIcon';
import AppointmentSmsIcon from 'components/atoms/icons/appointmentSmsIcon';
import AppointmentWebcamIcon from 'components/atoms/icons/appointmentWebcamIcon';
import { AppointmentMethod } from 'infrastructure/enums';
import React from 'react';

import type { Nullable } from 'infrastructure/types';

const appointmentIconMap: Record<string, React.ReactNode> = {
  [AppointmentMethod.Email]: <AppointmentEmailIcon width={18} height={18} />,
  [AppointmentMethod.PhoneOrVideoConference]: (
    <AppointmentWebcamIcon width={18} height={18} />
  ),
  [AppointmentMethod.SMS]: <AppointmentSmsIcon width={18} height={18} />,
};

interface IProps {
  method?: Nullable<AppointmentMethod>;
}

const AppointmentIconMap: React.FC<IProps> = ({ method }) => {
  const Icon = method && appointmentIconMap[method];

  return <> {Icon} </>;
};

export default AppointmentIconMap;
