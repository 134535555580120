import React from 'react';

const CrossIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.146447 0.853554C-0.0488156 0.658291 -0.0488156 0.341709 0.146447 0.146447C0.341709 -0.0488155 0.658292 -0.0488155 0.853554 0.146447L6 5.29289L11.1464 0.146447C11.3417 -0.0488155 11.6583 -0.0488155 11.8536 0.146447C12.0488 0.341709 12.0488 0.658291 11.8536 0.853554L6.70711 6L11.8536 11.1464C12.0488 11.3417 12.0488 11.6583 11.8536 11.8536C11.6583 12.0488 11.3417 12.0488 11.1464 11.8536L6 6.70711L0.853554 11.8536C0.658292 12.0488 0.341709 12.0488 0.146447 11.8536C-0.048815 11.6583 -0.048815 11.3417 0.146447 11.1464L5.29289 6L0.146447 0.853554Z"
      fill="#2C2543"
    />
  </svg>
);

export default CrossIcon;
