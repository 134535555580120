import i18n from 'infrastructure/i18n';
import useUser from 'utils/useUser';
import { type ReadingType } from 'infrastructure/enums';

import { useCellHeights } from './hooks/use-cell-heights';
import ReadingCell from './reading-cell';
import PatientDateColumn from './components/date-col/patient';
import PatientTimeCol from './components/patient-time-col';
import PatientMobileCell from './patient-mobile-cell';

import type {
  TRpmDashboardActivityQueryKey,
  TRpmPatientActivityQueryKey,
} from 'infrastructure/hooks/rpm';
import type { ReadingStatus } from 'infrastructure/enums/reading-statuses';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';
import type { IActivityData } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface Props {
  getActivityQueryKey:
    | TRpmDashboardActivityQueryKey
    | TRpmPatientActivityQueryKey;
}

export const usePatientActivityColumns = ({ getActivityQueryKey }: Props) => {
  const { rowRefs } = useCellHeights(getActivityQueryKey);
  const { isMobileDevice } = useUser();
  const [_, { page, items }] = getActivityQueryKey;
  const currentPage = page ?? 1;
  const itemIndexPrefix = (currentPage - 1) * (items ?? 100);

  const patientActivityColumnsMobile: IBaseTableColumns<IActivityData> = [
    {
      label: i18n.t('labels.time'),
      key: 'createdAt',
      width: '95px',
      sortable: true,
      sortKey: 'createdAt',
      onCell: () => ({
        colSpan: 2,
      }),
      render: (createdAt: string, record, idx) => (
        <PatientMobileCell
          createdAt={createdAt}
          record={record}
          idx={itemIndexPrefix + idx + 1}
        />
      ),
    },
    {
      label: i18n.t('labels.reading'),
      key: 'readingType',
      onCell: () => ({
        colSpan: 0,
      }),
    },
  ];

  const patientActivityColumns: IBaseTableColumns<IActivityData> = [
    {
      label: i18n.t('labels.status'),
      key: 'globalStatus',
      width: '120px',
      render: (globalStatus: ReadingStatus) => (
        <PatientDateColumn globalStatus={globalStatus} />
      ),
    },
    {
      label: i18n.t('labels.time'),
      key: 'createdAt',
      width: '375px',
      sortable: true,
      sortKey: 'createdAt',
      render: (createdAt: string, record, idx) => (
        <PatientTimeCol createdAt={createdAt} idx={itemIndexPrefix + idx + 1} />
      ),
    },
    {
      label: i18n.t('labels.reading'),
      key: 'readingType',
      render: (readingType: ReadingType, record, idx) => (
        <ReadingCell
          record={record}
          idx={idx}
          view="patient"
          getActivityQueryKey={getActivityQueryKey}
          hideCommentsColumn
          slaReadings={null}
          readingType={readingType}
          rowRefs={rowRefs}
        />
      ),
    },
  ];

  return isMobileDevice ? patientActivityColumnsMobile : patientActivityColumns;
};
