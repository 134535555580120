import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { showResult } from 'infrastructure/hooks/utils';

import { updatePatientStatusFn } from './api';

import type { IPatient } from 'infrastructure/interfaces';

export const useUpdatePatientStatus = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: updatePatientStatusFn,
    onSuccess(data, variables) {
      const patientInfoKey = getPatientInfoKey(variables.patientGuid);
      queryClient.setQueryData<IPatient>(patientInfoKey, (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            status: variables.status,
          };
        }
      });
      showResult();
    },
  });

  return { updateStatus: mutate, statusLoading: isLoading };
};
