import { type FC } from 'react';
import useUser from 'utils/useUser';
import { Switch, Tooltip } from 'antd';
import classNames from 'classnames';
import { useInteractiveTimeDashboardAssign } from 'infrastructure/hooks/interactiveTimeDashboard/useAssign';
import { isReadOnlyRole } from 'utils/userTypeHelper';
import { useAssignee } from 'infrastructure/hooks/rpm/useAssignee';

import s from './styles.module.scss';

import type { InteractiveTimeItem } from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface Props {
  interactiveTimeItem: InteractiveTimeItem;
}

const Assignee: FC<Props> = ({ interactiveTimeItem }) => {
  const { loginGuid, roleNames } = useUser();
  const isReadOnly = isReadOnlyRole(roleNames);
  const { assigneeType, timer } = useAssignee({
    loginGuid,
    assignee: interactiveTimeItem.assignee,
  });

  const { assign, isLoading } = useInteractiveTimeDashboardAssign({
    patientGuid: interactiveTimeItem.patient.guid,
    year: interactiveTimeItem.year,
    month: interactiveTimeItem.month,
  });

  const checked = assigneeType === 'me';

  return (
    <div className={s.wrapper}>
      <Tooltip title={timer}>
        <div>
          <span
            className={classNames(s['assignee-text'], {
              [s['gray-color']]: assigneeType === 'anotherUser',
            })}
          >
            {assigneeType === null ? 'Assign' : 'Assigned'}
          </span>
          {assigneeType === 'me' && <div className={s['sub-text']}>to me</div>}
          {assigneeType === 'anotherUser' && (
            <div className={s['sub-text']}>
              {interactiveTimeItem.assignee?.fullName}
            </div>
          )}
        </div>
      </Tooltip>
      {assigneeType !== 'anotherUser' && (
        <Switch
          disabled={isReadOnly}
          loading={isLoading}
          value={checked}
          onChange={() => {
            assign(checked ? 'unassign' : 'assign');
          }}
        />
      )}
    </div>
  );
};

export default Assignee;
