import { useQuery, useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import useApi from '../use-api';

import type {
  INextAppointment,
  IUpdateNextAppointment,
} from 'infrastructure/interfaces';

const patientNextAppointmentsApi = (patientGuid: string) =>
  `admins/admin-panel/patients/${patientGuid}/next-appointments`;

interface IUsePatientNextAppointment {
  loading: boolean;
  loadPatientNextAppointment: () => Promise<INextAppointment | void>;
  updatePatientNextAppointment: (body: IUpdateNextAppointment) => Promise<void>;
  nextAppointment: INextAppointment | void;
}

interface IUsePatientNextAppointmentProps {
  patientGuid: string;
}

export const getNextAppointmentKey = (patientGuid: string) => [
  'patientNextAppointment',
  patientGuid,
];

export const usePatientNextAppointment = (
  props: IUsePatientNextAppointmentProps,
): IUsePatientNextAppointment => {
  const { patientGuid } = props;

  const { loading, loadData, updateData } = useApi();

  const queryClient = useQueryClient();
  const queryKey = getNextAppointmentKey(patientGuid);

  const loadPatientNextAppointment =
    async (): Promise<INextAppointment | void> => {
      const data = await queryClient.fetchQuery(
        queryKey,
        () =>
          loadData<INextAppointment>(patientNextAppointmentsApi(patientGuid)),
        { staleTime: STALE_TIME },
      );
      if (data) return data;
    };

  const { data: nextAppointment, isLoading } = useQuery({
    queryKey,
    queryFn: async () =>
      loadData<INextAppointment>(patientNextAppointmentsApi(patientGuid)),
  });

  const updatePatientNextAppointment = async (
    body: IUpdateNextAppointment,
  ): Promise<void> => {
    const data = await updateData<INextAppointment>(
      patientNextAppointmentsApi(patientGuid),
      body,
    );

    if (body.appointmentDetails) {
      queryClient.setQueryData(queryKey, data);
    } else {
      queryClient.invalidateQueries(queryKey);
    }
  };

  return {
    nextAppointment,
    loading: loading || isLoading,
    loadPatientNextAppointment,
    updatePatientNextAppointment,
  };
};
