import React, { useRef, useState } from 'react';
import {
  CalendarOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import { DateFormats } from 'infrastructure/enums';
import classNames from 'classnames';
import { convertToDate } from 'infrastructure/functions';

import s from './styles.module.scss';

import type { ICommonInputProps } from 'infrastructure/interfaces';

const DatePicker = generatePicker(dateFnsGenerateConfig);

interface IBaseInputProps extends ICommonInputProps<string> {
  format?: DateFormats;
  onChange?: (date: any, dateString: string | string[]) => void;
  minDate?: Date;
  maxDate?: Date;
  defaultValue?: string;
}

const BaseDatepicker: React.FC<IBaseInputProps> = (props) => {
  const {
    value,
    disabled,
    plaintext,
    readonly,
    invalid,
    onChange,
    className,
    style,
    placeholder = '',
    minDate,
    maxDate,
    format = DateFormats['MM-dd-yyyy'],
    defaultValue,
  } = props;

  const [localPlaceholder, setLocalPlaceholder] = useState(placeholder);

  const ref = useRef<any>(null);

  const selectBoxClassNames = classNames(
    {
      [s.readonly]: readonly,
    },
    className,
  );

  const setDataCy = () => {
    if (ref.current) {
      const monthBtn = document.querySelectorAll('.ant-picker-month-btn');
      const yearBtn = document.querySelectorAll('.ant-picker-year-btn');
      if (monthBtn.length) {
        monthBtn.forEach((el) => {
          el.setAttribute('data-cy', 'range-picker-month-btn');
        });
      }
      if (yearBtn.length) {
        yearBtn.forEach((el) => {
          el.setAttribute('data-cy', 'range-picker-year-btn');
        });
      }
    }
  };

  const prepareValue = (val: any, ft: DateFormats) => {
    if (typeof val === 'string') {
      return convertToDate(val, ft);
    }
    return val;
  };

  return (
    <DatePicker
      ref={ref}
      onOpenChange={setDataCy}
      format={{
        format,
        type: 'mask',
      }}
      style={{ width: '100%', ...style }}
      placeholder={localPlaceholder}
      value={prepareValue(value, format)}
      disabled={disabled}
      className={selectBoxClassNames}
      onChange={onChange}
      variant={plaintext ? 'borderless' : 'outlined'}
      status={invalid ? 'error' : undefined}
      minDate={minDate}
      maxDate={maxDate}
      nextIcon={<RightOutlined data-cy="next" />}
      superNextIcon={<DoubleRightOutlined data-cy="super-next" />}
      prevIcon={<LeftOutlined data-cy="prev" />}
      superPrevIcon={<DoubleLeftOutlined data-cy="super-prev" />}
      cellRender={(val, info) => {
        return React.cloneElement(info.originNode, {
          'data-cy': `date-picker-${info.type}-${info.originNode.props.children}`,
          style: {
            borderRadius: info.type === 'date' ? '50%' : 0,
          },
        });
      }}
      suffixIcon={plaintext ? <></> : <CalendarOutlined />}
      onFocus={() => {
        setLocalPlaceholder(format);
      }}
      onBlur={() => {
        setLocalPlaceholder('');
      }}
      defaultValue={defaultValue}
    />
  );
};

export const BaseDatepickerDisplayName = 'BaseDatepicker';
BaseDatepicker.displayName = BaseDatepickerDisplayName;

export default BaseDatepicker;
