import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import C from 'classnames';
import PropTypes from 'prop-types';
import * as amazonConnect from 'services/connectService';
import useUser from 'utils/useUser';
import { isReadOnlyRole } from 'utils/userTypeHelper';

const Connect = ({
  isOpen,
  phoneNumber,
  patientGuid,
  setCallId,
  isRpmCall,
  deviceId,
  readingId,
  windowExpanded,
}) => {
  const [initialized, setInitialized] = useState(false);
  const socket = useSelector((state) => state.socket);
  const { roleNames } = useUser();
  const readOnlyRole = isReadOnlyRole(roleNames);

  useEffect(() => {
    if (isOpen && !initialized) {
      amazonConnect.init(
        phoneNumber,
        patientGuid,
        setCallId,
        isRpmCall,
        deviceId,
        readingId,
        socket.client,
        readOnlyRole,
      );
      setInitialized(true);
    }

    if (!isOpen && initialized) {
      amazonConnect.terminate();
      setInitialized(false);
    }
  }, [isOpen, phoneNumber]);

  return (
    <div
      className={C('connect', windowExpanded ? 'expanded' : 'collapsed')}
      id="connect-el"
    />
  );
};

Connect.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  phoneNumber: PropTypes.string,
  patientGuid: PropTypes.string.isRequired,
  setCallId: PropTypes.func,
  isRpmCall: PropTypes.bool,
  deviceId: PropTypes.string,
  readingId: PropTypes.string,
  windowExpanded: PropTypes.bool,
};

export default Connect;
