import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTextArea from 'components/atoms/base-textarea';
import BaseButton from 'components/atoms/baseButton';
import SendIcon from 'components/atoms/icons/send';
import ChatLeftTextIcon from 'components/atoms/icons/chat-left-text';
import PcctModal from 'components/templates/rpm/patients/patient/generalComments/pcctModal';
import { ModuleType } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { TSize } from 'components/atoms/base-textarea';

interface IGeneralCommentsTextAreaProps {
  onResize?: (size: TSize) => void;
  onSend?: (message: string) => void;
  loading?: boolean;
  type: ModuleType;
}

export interface IGeneralCommentsTextAreaRef {
  resetForm: () => void;
}

const GeneralCommentsTextArea = forwardRef<
  IGeneralCommentsTextAreaRef,
  IGeneralCommentsTextAreaProps
>((props, ref) => {
  const { type, loading, onSend, onResize } = props;

  const [opened, setOpened] = useState(false);
  const [pcctValues, setPcctValues] = useState({});
  const [message, setMessage] = useState('');

  const isRPM = type === ModuleType.RPM;

  const { t } = useTranslation();

  const resetForm = () => {
    setMessage('');
  };

  const handleSend = () => {
    if (onSend) onSend(message);
  };

  useImperativeHandle(ref, () => ({ resetForm }));

  return (
    <>
      <div className={s.wrapper}>
        <BaseTextArea
          value={message}
          placeholder={t('controls.typeYourComment')}
          plaintext
          onChange={setMessage}
          readonly={loading}
          rows={3}
          maxRows={13}
          onResize={onResize}
        />
        <div className={s.controls}>
          {isRPM && (
            <BaseButton
              loading={loading}
              type="secondary"
              icon={<ChatLeftTextIcon />}
              onClick={() => setOpened(true)}
              className={s['generate-btn']}
              dataCy="generate-general-comment-button"
            />
          )}
          <BaseButton
            loading={loading}
            icon={<SendIcon />}
            onClick={handleSend}
            className={s['send-btn']}
            type="secondary"
            dataCy="send-general-comment-button"
          />
        </div>
      </div>

      {isRPM && opened && (
        <PcctModal
          isOpen={opened}
          closeModal={() => setOpened(false)}
          values={pcctValues}
          setValues={setPcctValues}
          setComment={setMessage}
        />
      )}
    </>
  );
});

GeneralCommentsTextArea.displayName = 'GeneralCommentsTextArea';

export default GeneralCommentsTextArea;
