import s from './styles.module.scss';

import type { FC, ReactNode } from 'react';

interface ItemWrapperProps {
  label: string;
  children: ReactNode;
}

const ItemWrapper: FC<ItemWrapperProps> = ({ label, children }) => {
  return (
    <div className={s.wrapper}>
      <label className={s.label} data-cy={`dashboard-filter-${label}`}>
        {label}
        {children}
      </label>
    </div>
  );
};
export default ItemWrapper;
