import React from 'react';
import { AnimatePresence } from 'framer-motion';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface IProps extends IBaseComponentProps {}

const ListAnimation: React.FC<IProps> = (props) => {
  const { children } = props;

  return <AnimatePresence>{children}</AnimatePresence>;
};

export default ListAnimation;
