import common from 'constants/common';
import languages from 'constants/languages';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import dataService from 'services/dataService';
import { API_TURK_PROFILE } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

import type { RootDispatch } from 'store';

type LanguageState =
  | {
      id: string;
      label: string;
    }
  | undefined
  | null;

const useTurkProfileUpdate = ({
  primaryLanguage,
  secondaryLanguage,
}: {
  primaryLanguage: string | null;
  secondaryLanguage: string | null;
}) => {
  const [primaryLanguageValue, setPrimaryLanguageValue] =
    useState<LanguageState>(languages.find((l) => l.id === primaryLanguage));
  const [secondaryLanguageValue, setSecondaryLanguageValue] =
    useState<LanguageState>(languages.find((l) => l.id === secondaryLanguage));
  const [isLoading, setIsLoading] = useState(false);

  const {
    user: { setUser },
  } = useDispatch<RootDispatch>();

  const onUpdate = async () => {
    const body = {
      primaryLanguage: primaryLanguageValue?.id || null,
      secondaryLanguage: secondaryLanguageValue?.id || null,
    };
    setIsLoading(true);
    const { error } = await dataService.updateOnly(API_TURK_PROFILE, body);
    setIsLoading(false);
    if (!error) {
      setUser({
        primaryLanguage: body.primaryLanguage,
        secondaryLanguage: body.secondaryLanguage,
      });
    }
    showResult(error, common.submitSuccessTypes.changed);
  };

  return {
    primaryLanguageValue,
    setPrimaryLanguageValue,
    secondaryLanguageValue,
    setSecondaryLanguageValue,
    onUpdate,
    isLoading,
  };
};

export default useTurkProfileUpdate;
