import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { apiPatientNotes } from 'services/dataService/resources';

import { showResult } from '../utils';
import { getPatientNotesKey } from './use-patient-notes';

interface Props {
  onSuccess: () => void;
}

const createPatientNotesFn = async ({
  patientGuid,
  note,
}: {
  patientGuid: string;
  note: string;
}) => {
  const { data, error } = await dataService.createOne(
    apiPatientNotes(patientGuid),
    { note },
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const usePatientNotesCreate = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: createPatientNotesFn,
    onSuccess(data, variables) {
      const patientNotesKey = getPatientNotesKey(variables.patientGuid);
      onSuccess();
      queryClient.invalidateQueries({ queryKey: patientNotesKey });
    },
  });

  return {
    createNote: mutate,
    createLoading: isLoading,
    ...other,
  };
};
