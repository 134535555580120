import { useMutation } from '@tanstack/react-query';

import { sendSmsFn } from './api';

export const useSendSms = () => {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: sendSmsFn,
  });

  return { sendSms: mutateAsync, smsLoading: isLoading };
};
