import dataService from 'services/dataService';
import {
  API_PATIENTS_MANUAL_INTERACTIVE_TIME,
  API_CCM_PATIENTS_MANUAL_INTERACTIVE_TIME,
  apiPatientManualInteractionTimes,
} from 'services/dataService/resources';

const manualInteractiveTime = {
  state: {
    page: 1,
    pageStep: 10,
    totalCount: 1,
    data: [],
  },
  reducers: {
    setPage: (state, payload) => {
      const page = payload < 1 ? 1 : payload;
      return {
        ...state,
        page,
      };
    },
    setTotalCount: (state, payload) => ({
      ...state,
      totalCount: payload,
    }),
    setManualInteractionTimes: (state, payload) => ({
      ...state,
      data: payload,
    }),
  },
  effects: (dispatch) => ({
    async submit(postData) {
      const url = postData.isRpm
        ? API_PATIENTS_MANUAL_INTERACTIVE_TIME(postData.patientGuid)
        : API_CCM_PATIENTS_MANUAL_INTERACTIVE_TIME(postData.patientGuid);
      const { error, data } = await dataService.createOne(url, {
        timeInMinutes: postData.timeInMinutes,
        comment: postData.comment,
      });
      return { error, data };
    },
    async getManualInteractionTimes(patientGuid, state) {
      const { data } = await dataService.getList(
        apiPatientManualInteractionTimes(
          patientGuid,
          state.manualInteractiveTime.page,
          state.manualInteractiveTime.pageStep,
        ),
      );
      dispatch.manualInteractiveTime.setManualInteractionTimes(
        data?.data || [],
      );
      dispatch.manualInteractiveTime.setTotalCount(data?.count || 0);
    },
  }),
};

export default manualInteractiveTime;
