import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { weekDaysDataSource } from 'infrastructure/data-sources/week-days';
import { replaceOrAddObject } from 'infrastructure/functions';

import s from './styles.module.scss';
import ContactAvailabilityFilterDay from './day';

import type { IWeekDayItem } from 'infrastructure/data-sources/week-days';
import type { IContactAvailabilityFilterDayRef } from './day';
import type { IAvailableDay } from 'infrastructure/hooks/interactiveTimeDashboard/types';

export interface IContactAvailabilityFilterBodyRef {
  validate: () => boolean;
}

interface IBodyProps {
  value?: IAvailableDay[];
  onChange: (times: IAvailableDay[]) => void;
}

const ContactAvailabilityFilterBody = forwardRef<
  IContactAvailabilityFilterBodyRef,
  IBodyProps
>(({ value = [], onChange }, ref) => {
  const [times, setTimes] = useState<IAvailableDay[]>(value);
  const itemsRef = useRef<IContactAvailabilityFilterDayRef[]>([]);

  const changeTimes = (data: IAvailableDay) => {
    const newTimes = replaceOrAddObject({
      array: times,
      field: 'day',
      value: data.day,
      newObject: data,
    }).filter((el) => el.active);
    setTimes(newTimes);
    onChange(newTimes);
  };

  const prepareData = (day: IWeekDayItem, index: number): IAvailableDay => {
    const val = value.find((el) => el.day === index);

    const defaultVal: IAvailableDay = {
      value: {
        day: index,
        startTime: undefined,
        endTime: undefined,
      },
      active: false,
      day: index,
      name: day.name,
      startTime: undefined,
      endTime: undefined,
    };

    if (val) {
      Object.assign(defaultVal, val);
    }

    return defaultVal;
  };

  const validate = () => itemsRef.current.every((el) => el.validate());

  useImperativeHandle(ref, () => ({ validate }));

  return (
    <ul className={s['contact-availability']}>
      {weekDaysDataSource().map((el, index) => (
        <ContactAvailabilityFilterDay
          key={el.id}
          ref={(e) => {
            if (e) itemsRef.current[index] = e;
          }}
          data={prepareData(el, index)}
          onChange={changeTimes}
        />
      ))}
    </ul>
  );
});

ContactAvailabilityFilterBody.displayName = 'ContactAvailabilityFilterBody';

export default ContactAvailabilityFilterBody;
