import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import BaseLoader from '../base-loader';
import InputSearchIcon from '../icons/inputSearchIcon';
import s from './styles.module.scss';

import type { BaseSearchSelectProps } from './types';
import type { FC } from 'react';

const BaseSearchSelect: FC<BaseSearchSelectProps> = ({
  options,
  isLoading,
  onChange,
  onSelect,
  value,
  placeholder,
  disabled,
  onClear,
  mode,
  onDeselect,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <Select
      popupClassName="certain-category-search-dropdown"
      style={{ width: '100%' }}
      options={options ?? []}
      size="middle"
      placeholder={placeholder}
      notFoundContent={
        isLoading ? (
          <BaseLoader loading={isLoading} height={20} width={20} />
        ) : (
          t('labels.bannerNoData')
        )
      }
      onSelect={onSelect}
      value={value}
      optionRender={(option) => (
        <div data-cy="autocomplete-option">{option.label}</div>
      )}
      onSearch={onChange}
      showSearch
      suffixIcon={<InputSearchIcon />}
      filterOption={false}
      disabled={disabled}
      allowClear={Boolean(onClear)}
      onClear={onClear}
      mode={mode}
      onDeselect={onDeselect}
      className={classNames(s['search-input'], className)}
    />
  );
};
export default BaseSearchSelect;
