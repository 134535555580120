import { interactiveTimeGroupPresenter } from 'infrastructure/services/interactiveTime';

import { ColumnFormBoldItem } from '../form';

import type { CcmAgentItem } from 'infrastructure/hooks/ccmAgentDashboard/types';
import type { FC } from 'react';

interface Props {
  item: CcmAgentItem;
}

const InteractiveTimeGroup: FC<Props> = ({ item }) => {
  return (
    <ColumnFormBoldItem
      value={interactiveTimeGroupPresenter(item.actionTime)}
    />
  );
};

export default InteractiveTimeGroup;
