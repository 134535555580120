import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addQueryParams } from 'utils/queryHelpers';

export type TUsersFilter = {
  page: number;
  role?: string;
  status?: string;
  guid?: string;
  doctorLabel?: string;
  doctorKey?: string;
  clinicGuid?: string;
  clinicLabel?: string;
};

export const useClinicUsersFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    page,
    status,
    role,
    clinicGuid,
    clinicLabel,
    guid,
    doctorLabel,
    doctorKey,
  } = queryString.parse(location.search);

  const parseSearchStringValue = (value: string | (string | null)[] | null) => {
    if (typeof value === 'string') return value;
  };

  const filter = useMemo(
    () => ({
      page: Number(page || 1),
      role: parseSearchStringValue(role),
      status: parseSearchStringValue(status),
      guid: parseSearchStringValue(guid),
      clinicGuid: parseSearchStringValue(clinicGuid),
      doctorLabel: parseSearchStringValue(doctorLabel),
      clinicLabel: parseSearchStringValue(clinicLabel),
      doctorKey: parseSearchStringValue(doctorKey) ?? 'fullName',
    }),
    [page, status, role, clinicGuid, clinicLabel, guid, doctorLabel, doctorKey],
  );

  const resetFilter = () => {
    navigate(
      {
        search: queryString.stringify({}),
      },
      {
        replace: true,
      },
    );
  };

  const updateQueryParams = (newFilter: Partial<TUsersFilter>) => {
    addQueryParams({ navigate, location }, newFilter);
  };

  const exclude = ['page', 'doctorLabel', 'doctorKey', 'clinicLabel'];

  const countOfChangedFilterField = Object.entries(filter).filter(
    ([k, v]) => !exclude.includes(k) && Boolean(v),
  ).length;

  return {
    filter,
    updateQueryParams,
    resetFilter,
    countOfChangedFilterField,
  };
};
