import React, { useMemo } from 'react';
// import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isMobile } from 'utils/deviceHelper';

const generateAddress = (addressArr) =>
  addressArr.filter((el) => !!el).join(', ');

const AgencyRow = ({ data }) => {
  const address = useMemo(
    () => generateAddress([data.addressLine1, data.addressLine2]),
    [data.addressLine1, data.addressLine2],
  );
  const device = useSelector((state) => state.user.deviceType);

  return (
    <tr>
      <td data-label="HHA Name">
        <div className="adminPanel__row">
          <Link
            data-cy="admin-panel__agency-link"
            to={`/admin/agency/${data.guid}`}
          >
            <strong>{data.legalname}</strong>
          </Link>
        </div>
      </td>
      <td data-label="Email">
        <div className="adminPanel__row">
          {data.email}
          {isMobile(device) && !data.email && '-'}
        </div>
      </td>
      <td data-label="HHA Id">
        <div className="adminPanel__row">
          {data.agencyId}
          {isMobile(device) && !data.agencyId && '-'}
        </div>
      </td>
      <td data-label="Address">
        <div className="adminPanel__row">
          {address}
          {isMobile(device) && !address && '-'}
        </div>
      </td>
      <td data-label="Entry Time">
        <div className="adminPanel__row">{data.createdAt}</div>
      </td>
    </tr>
  );
};
AgencyRow.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default AgencyRow;
