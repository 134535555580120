import classNames from 'classnames';
import { getActivityStatus } from 'utils';

import s from './styles.module.scss';

import type { ReadingStatus } from 'infrastructure/enums/reading-statuses';
import type { FC } from 'react';

interface PatientDateColumnProps {
  globalStatus: ReadingStatus;
}

const PatientDateColumn: FC<PatientDateColumnProps> = ({ globalStatus }) => {
  return (
    <div>
      <div className={s['patient-date-wrapper']}>
        <p className={classNames(s[`status-${globalStatus}`], s.status)}>
          {getActivityStatus(globalStatus)}
        </p>
      </div>
    </div>
  );
};
export default PatientDateColumn;
