import { useAddAppointmentCompletion } from 'infrastructure/hooks/patient/use-add-patient-appointment-completion';
import { useAppointmentCompletion } from 'infrastructure/hooks/patient/use-patient-appointment-completion';
import { useState } from 'react';
import { useFormik } from 'formik';
import {
  isNotUndefined,
  isCompletedByAgencyDoctor,
  isCompletedByDoctor,
} from 'infrastructure/functions';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { isCcmActionAllowedStatus } from 'utils/userTypeHelper';

import { addAppointmentCompletionValidationSchema } from '../validation-schema';

import type { Values } from '../validation-schema';
import type { IAddAppointmentCompletion } from 'infrastructure/interfaces';

interface Props {
  patientGuid: string;
}

const PER_PAGE = 5;

export const useMonthlyAppointments = ({ patientGuid }: Props) => {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  const { appointmentCompletionData, isLoading } = useAppointmentCompletion({
    patientGuid,
    meta: { items: PER_PAGE, page },
  });
  const { addAppointmentCompletion, addingLoading } =
    useAddAppointmentCompletion({
      onSuccess() {
        toggleOpen();
      },
    });
  const { patientInfo, loading } = usePatientInfo({ patientGuid });
  const disableButton = !isCcmActionAllowedStatus(patientInfo?.ccmStatus);

  const formik = useFormik<Values>({
    initialValues: {
      appointmentMethod: undefined,
      completedDate: undefined,
      completedBy: undefined,
      type: undefined,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const { appointmentMethod, completedBy, completedDate, type } = values;
      if (
        !isNotUndefined(appointmentMethod) ||
        !isNotUndefined(completedDate) ||
        !completedDate
      )
        return;

      const data: IAddAppointmentCompletion = {
        appointmentMethod,
        completedDate: completedDate.toDateString(),
        patientGuid,
        completedByAgencyDoctorGuid:
          isCompletedByAgencyDoctor(type) && completedBy
            ? completedBy
            : undefined,
        completedByDoctorGuid:
          isCompletedByDoctor(type) && completedBy ? completedBy : undefined,
      };
      const res = await addAppointmentCompletion(data);
      if (res?.created) formik.resetForm();
    },
    validateOnChange: false,
    validationSchema: addAppointmentCompletionValidationSchema,
  });

  const { submitForm, resetForm } = formik;

  const onCancelForm = () => {
    resetForm();
    toggleOpen();
  };

  return {
    appointmentCompletionData,
    open,
    page,
    isLoading: loading || isLoading,
    addingLoading,
    PER_PAGE,
    formik,
    disableButton,
    toggleOpen,
    setPage,
    submitForm,
    onCancelForm,
  };
};
