import ExpandableFilterPanel from 'components/molecules/expandable-filter-panel';
import BaseSearchSelect from 'components/atoms/base-search-select';
import BaseSelectBox from 'components/atoms/base-select-box';
import { type FC, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ClinicFilterSelectBox from 'components/molecules/include-exclude-clinic-select-box';

import s from './styles.module.scss';
import { useFilter } from './use-filter';

interface ItemProps {
  label?: string;
  children: ReactNode;
}
const Item: FC<ItemProps> = ({ label, children }) => (
  <div className={s['item-wrapper']}>
    {label && <label data-cy={`patientFilter-${label}`}>{label}</label>}
    {children}
  </div>
);

interface PatientsFilterProps {}

const PatientsFilter: FC<PatientsFilterProps> = () => {
  const { t } = useTranslation();
  const {
    filter,
    updateQueryParams,
    searchPatientsLoading,
    statusOptions,
    ccmStatusOptions,
    placeholder,
    debounceSearchPatients,
    patientOptions,
    countOfChangedFilterField,
    DEFAULT_PATIENT_KEY,
    options,
    resetFilter,
  } = useFilter();

  return (
    <ExpandableFilterPanel
      filtersCount={countOfChangedFilterField}
      onReset={resetFilter}
      isExpanded
    >
      <div className={s.wrapper}>
        <Item label={t('labels.patientFilter')}>
          <div className={s['patient-item']}>
            <BaseSelectBox
              options={options}
              defaultValue={DEFAULT_PATIENT_KEY.value}
              className={s.dropdown}
              onChange={(v) => {
                updateQueryParams({ patientKey: v });
              }}
              value={filter.patientKey}
            />
            <BaseSearchSelect
              isLoading={searchPatientsLoading}
              className={s.search}
              options={patientOptions}
              placeholder={placeholder}
              value={filter.patientGuid}
              onChange={(v) =>
                debounceSearchPatients({
                  searchValue: v,
                  searchKey: filter.patientKey,
                })
              }
              onSelect={(v, option) => {
                updateQueryParams({
                  page: undefined,
                  patientGuid: v,
                  patientLabel: option.label as string,
                });
              }}
              onClear={() => {
                updateQueryParams({
                  page: undefined,
                  patientGuid: undefined,
                  patientLabel: undefined,
                });
              }}
            />
          </div>
        </Item>
        <Item>
          <ClinicFilterSelectBox
            useAdminApi
            showOnInteractiveTimeDashboard={false}
            excluded={filter?.excludeClinicGuid?.map((el) => el)}
            included={filter?.clinicGuid?.map((el) => el)}
            allExcluded={filter?.allExcluded}
            onChange={({
              included = [],
              excluded = [],
              allExcluded = false,
            }) => {
              updateQueryParams({
                clinicGuid: included,
                excludeClinicGuid: excluded,
                allExcluded,
              });
            }}
          />
        </Item>
        <div className={s['combine-item-wrapper']}>
          <Item label={t('labels.rpmStatus')}>
            <BaseSelectBox
              options={statusOptions}
              placeholder={t('labels.all')}
              value={filter.status}
              onChange={(v: string[]) =>
                updateQueryParams({ status: v.length ? v : undefined })
              }
              allowClear
              mode="multiple"
              maxTagTextLength={40}
            />
          </Item>
          <Item label={t('labels.ccmStatus')}>
            <BaseSelectBox
              options={ccmStatusOptions}
              placeholder={t('labels.all')}
              value={filter.ccmStatus}
              onChange={(v) =>
                updateQueryParams({ ccmStatus: v.length ? v : undefined })
              }
              allowClear
              mode="multiple"
              maxTagTextLength={40}
            />
          </Item>
        </div>
      </div>
    </ExpandableFilterPanel>
  );
};
export default PatientsFilter;
