import React from 'react';
import ReceptionIcon from 'components/atoms/icons/reception';

import type { DeviceLog } from 'infrastructure/classes/device-log';

interface ICellularSignalProps {
  data: DeviceLog;
}

const CellularSignal: React.FC<ICellularSignalProps> = (props) => {
  const { data } = props;
  return (
    <div>
      <i>
        <ReceptionIcon level={data.signalStrength} />
      </i>{' '}
      {data.signalStrength}
    </div>
  );
};

export default CellularSignal;
