import { forwardRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import TrashIcon from 'components/atoms/icons/trashIcon';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import BaseInputNumber from 'components/atoms/base-input-number';
import BaseDatepicker from 'components/atoms/base-datepicker';
import classNames from 'classnames';
import { isMobile } from 'utils/deviceHelper';
import useUser from 'utils/useUser';

import s from './styles.module.scss';
import { adminPanelPriorAuthorizationValidationSchema } from './validation-schema';

import type { PatientPriorAuthorization } from 'infrastructure/classes/patient/patient-prior-authorization';

interface IPriorAuthorizationFormItemProps {
  data: PatientPriorAuthorization;
  isEdit?: boolean;
  onDelete?: (data: PatientPriorAuthorization) => void;
}

export interface IPriorAuthorizationFormItemRef {
  resetForm: () => void;
  validate: () => {
    isValid: boolean;
    value: PatientPriorAuthorization;
  };
}

const PriorAuthorizationFormItem = forwardRef<
  IPriorAuthorizationFormItemRef,
  IPriorAuthorizationFormItemProps
>((props, ref) => {
  const { data, onDelete, isEdit = false } = props;

  const { t } = useTranslation();
  const { deviceType } = useUser();

  const formik = useFormik({
    initialValues: data,
    onSubmit: () => {},
    isInitialValid: !!data.guid,
    validationSchema: adminPanelPriorAuthorizationValidationSchema(),
  });

  if (data.action !== 'create') {
    formik.dirty
      ? formik.values.setAction('update')
      : formik.values.setAction(undefined);
  }

  const validate = () => {
    formik.validateForm();
    return {
      isValid: formik.isValid,
      value: formik.values,
    };
  };

  const resetForm = () => {
    formik.resetForm();
  };

  useImperativeHandle(ref, () => ({ validate, resetForm }));

  return (
    <li>
      <BaseForm
        formik={formik}
        plaintext={!isEdit}
        readonly={!isEdit}
        className={s.item}
      >
        <BaseFormItem
          name="authorizationNumber"
          label={t('labels.authorizationNumber')}
          className={classNames({ [s['input-wrapper']]: !isEdit })}
          showErrorBlock={isMobile(deviceType) ? isEdit : true}
        >
          <BaseInputNumber />
        </BaseFormItem>
        <BaseFormItem
          name="startDate"
          label={t('labels.startDate')}
          className={classNames({ [s['input-wrapper']]: !isEdit })}
          showErrorBlock={isMobile(deviceType) ? isEdit : true}
        >
          <BaseDatepicker maxDate={formik.values.endDate} />
        </BaseFormItem>
        <BaseFormItem
          name="endDate"
          label={t('labels.endDate')}
          className={classNames({ [s['input-wrapper']]: !isEdit })}
          showErrorBlock={isMobile(deviceType) ? isEdit : true}
        >
          <BaseDatepicker minDate={formik.values.startDate} />
        </BaseFormItem>
        <div className={s['delete-btn']}>
          {isEdit && (
            <BaseButton
              icon={<TrashIcon />}
              onClick={() => {
                if (onDelete) onDelete(data);
              }}
              type="secondary"
              title={t('controls.delete')}
              dataCy="delete-prior-auth-button"
            />
          )}
        </div>
      </BaseForm>
    </li>
  );
});

PriorAuthorizationFormItem.displayName = 'PriorAuthorizationFormItem';

export default PriorAuthorizationFormItem;
