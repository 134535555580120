import { useState } from 'react';
import { removeEmptyValuesFromObject, filterDefaultValues } from 'utils/object';
import { prepareSortOrder } from 'infrastructure/functions/prepare-sort-order';

import { useQueryParams } from './useQueryParams';

import type { SortParam } from 'infrastructure/types';
import type { Filters, AppendFilters } from './types';

export const getFiltersCount = (filters: Record<string, any>): number => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { page, items, sort, ...rest } = filters;

  const filteredKeys = Object.keys(rest).filter(
    (key) => key !== 'nextAppointmentDateFrom',
  );
  const count = filteredKeys.length;

  return count;
};

export const useCcmAgentDashboardFilters = () => {
  const DEFAULT_FILTERS = {
    page: 1,
    items: 100,
  };

  const {
    addFiltersToUrl,
    initialFilters,
    initialFiltersCount,
    resetFiltersUrl,
  } = useQueryParams(DEFAULT_FILTERS);

  const [filtersCount, setFiltersCount] = useState<number>(initialFiltersCount);
  const [filters, setFilters] = useState<Filters>(initialFilters);

  const appendFilters: AppendFilters = (filtersToAppend) => {
    const newFilters = removeEmptyValuesFromObject({
      ...DEFAULT_FILTERS,
      ...filters,
      page: 1,
      ...filtersToAppend,
    });

    const filterWithoutDefaultValues = filterDefaultValues({
      values: newFilters,
      defaultValues: DEFAULT_FILTERS,
    });
    addFiltersToUrl(filterWithoutDefaultValues);
    const newFiltersCount = getFiltersCount(filterWithoutDefaultValues);

    setFiltersCount(newFiltersCount);
    setFilters(newFilters as Filters);
  };

  const changePage = (page: number) => {
    appendFilters({ page });
  };

  const handleSort = (sortParam?: SortParam) => {
    const sortField = sortParam
      ? JSON.stringify(prepareSortOrder(sortParam, true))
      : undefined;

    appendFilters({ sort: sortField });
  };

  const resetFilters = () => {
    setFiltersCount(0);
    setFilters(DEFAULT_FILTERS);
    resetFiltersUrl();
  };

  return {
    filters,
    appendFilters,
    resetFilters,
    changePage,
    handleSort,
    filtersCount,
  };
};
