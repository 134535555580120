import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import common from 'constants/common';
import { scrollMessagesToBottom } from 'components/molecules/smsDashboard/messagesList';
import ChatInput from 'components/atoms/chat/input';
import useCacheString from 'utils/useCacheString';
import BaseSelect from 'components/atoms/baseSelect';
import SwitchWithText from 'components/atoms/switch/SwitchWithText';
import { PlatformEnums } from 'infrastructure/enums/platformEnums';
import BaseButton from 'components/atoms/baseButton';
import CopyImg from 'components/atoms/icons/copyOutlinedIcon';
import { useSendMessage } from 'infrastructure/hooks/smsDashboard/useSendMessage';

import SmsDashboardTemplate from '../smsDashboardTemplate';

import type { RootDispatch, RootState } from 'store';
import type { IConversation } from 'infrastructure/interfaces';

const chatInputId = 'smsDashboard.messagesInput';

const selectTheme = (theme: any) => ({
  ...theme,
  borderRadius: 12,
  colors: {
    ...theme.colors,
    ...common.primaryColors,
  },
});

interface IMessageInputProps {
  conversation: IConversation;
}

const MessagesInput: React.FC<IMessageInputProps> = (props) => {
  const { conversation } = props;
  const { t } = useTranslation();

  const patient = useSelector((state: RootState) => state.patient);
  const { currentConversation, conversationType } = useSelector(
    (state: RootState) => state.smsDashboard,
  );
  const { getDetails: patientDetailsLoading } = useSelector(
    (state: RootState) => state.loading.effects.patient,
  );
  const {
    smsDashboard: { changeCurrentConversation },
  } = useDispatch<RootDispatch>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const messageConversationType =
    conversationType !== PlatformEnums.UNREGISTERED
      ? conversationType
      : PlatformEnums.RPM;
  const [phoneNumber, setPhoneNumber] = useState<{
    label: string;
    value: string | null;
  } | null>({ label: '', value: '' });
  const [inputValue, setInputValue] = useState<string>('');
  const [messageType, setMessageType] = useState<
    PlatformEnums.CCM | PlatformEnums.RPM | string
  >(messageConversationType);

  const { cacheLoading, deleteCache } = useCacheString({
    cacheId: `${chatInputId}.${conversation.id}`,
    value: inputValue,
    setValue: setInputValue,
  });

  const { sendMessage, sendLoading } = useSendMessage({
    onSuccess() {
      setInputValue('');
      scrollMessagesToBottom();
      deleteCache();
      changeCurrentConversation({ ...currentConversation, closed: false });
    },
  });

  const onSubmit = async (value: string) => {
    if (!value) return;
    sendMessage({
      conversationId: conversation.id,
      message: value,
      from: messageType,
      patientPhone: phoneNumber?.value || conversation.patient.patientPhone,
    });
  };

  useEffect(() => {
    setInputValue('');

    setTimeout(() => {
      document.getElementById(chatInputId)?.focus();
    }, 50);
  }, [currentConversation]);

  const phoneOptions = () => {
    if (!patientDetailsLoading) {
      if (patient.profile.homeNumber && patient.profile.homeNumber !== '') {
        return [
          {
            label: `${patient.profile.phone}`,
            value: `${patient.profile.phone}`,
          },
          {
            label: `${patient.profile.homeNumber}`,
            value: `${patient.profile.homeNumber}`,
          },
        ];
      }
    }
    return [];
  };

  const onSwitch = () => {
    setMessageType(
      messageType === PlatformEnums.RPM ? PlatformEnums.CCM : PlatformEnums.RPM,
    );
  };

  const modalSubmit = async (data: string) => {
    setIsModalOpen(false);
    setInputValue(data);
    await onSubmit(data);
  };

  return (
    <div className="messages-input-wrapper">
      {false &&
        !patientDetailsLoading &&
        phoneOptions().length > 1 &&
        conversationType !== PlatformEnums.UNREGISTERED && (
          <div className="messages-input-phone">
            <p>{t('smsDashboard.phone_number_for_reply_to')}:</p>
            <BaseSelect
              options={phoneOptions()}
              theme={(theme) => selectTheme(theme)}
              defaultValue={{
                label: `${patient.profile.phone}`,
                value: `${patient.profile.phone}`,
              }}
              classNamePrefix="select-type"
              onChange={(value) =>
                setPhoneNumber(
                  value as unknown as {
                    label: string;
                    value: string | null;
                  } | null,
                )
              }
            />
          </div>
        )}
      {conversationType === PlatformEnums.UNREGISTERED && (
        <div className="messages-input-phone">
          <p>
            {t('smsDashboard.before_sending_a_message_select_a_destination')}:
          </p>
          <SwitchWithText
            dataCy="patient-key-note-switch"
            onChange={onSwitch}
            checked={messageType === PlatformEnums.CCM}
            onText={PlatformEnums.CCM.toUpperCase()}
            offText={PlatformEnums.RPM.toUpperCase()}
            onBackgroundColor={common.activeIconColor}
            offBackgroundColor={common.activeIconColor}
            loading={sendLoading || cacheLoading}
          />
        </div>
      )}
      <div className="chat-form-wrapper">
        <BaseButton
          icon={<CopyImg />}
          type="secondary"
          className="message-template-button"
          onClick={() => setIsModalOpen(true)}
        />
        <ChatInput
          onSubmit={() => onSubmit(inputValue)}
          value={inputValue}
          setValue={setInputValue}
          loading={sendLoading || cacheLoading}
          id={chatInputId}
        />
      </div>
      <SmsDashboardTemplate
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        onSubmit={(data) => modalSubmit(data)}
        modalLabel={t('smsDashboard.sms_template')}
      />
    </div>
  );
};

export default MessagesInput;
