import type { FC, SVGProps } from 'react';

const CopyIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6673 5.83325V3.96659C10.6673 3.21985 10.6673 2.84648 10.522 2.56126C10.3942 2.31038 10.1902 2.10641 9.93931 1.97858C9.65409 1.83325 9.28072 1.83325 8.53398 1.83325H3.46732C2.72058 1.83325 2.34721 1.83325 2.062 1.97858C1.81111 2.10641 1.60714 2.31038 1.47931 2.56126C1.33398 2.84648 1.33398 3.21985 1.33398 3.96659V9.03325C1.33398 9.77999 1.33398 10.1534 1.47931 10.4386C1.60714 10.6895 1.81111 10.8934 2.062 11.0213C2.34721 11.1666 2.72058 11.1666 3.46732 11.1666H5.33398M7.46732 15.1666H12.534C13.2807 15.1666 13.6541 15.1666 13.9393 15.0213C14.1902 14.8934 14.3942 14.6895 14.522 14.4386C14.6673 14.1534 14.6673 13.78 14.6673 13.0333V7.96659C14.6673 7.21985 14.6673 6.84648 14.522 6.56126C14.3942 6.31038 14.1902 6.10641 13.9393 5.97858C13.6541 5.83325 13.2807 5.83325 12.534 5.83325H7.46732C6.72058 5.83325 6.34721 5.83325 6.062 5.97858C5.81111 6.10641 5.60714 6.31038 5.47931 6.56126C5.33398 6.84648 5.33398 7.21985 5.33398 7.96659V13.0333C5.33398 13.78 5.33398 14.1534 5.47931 14.4386C5.60714 14.6895 5.81111 14.8934 6.062 15.0213C6.34721 15.1666 6.72058 15.1666 7.46732 15.1666Z"
        stroke="#2C2543"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
