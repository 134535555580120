import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { interactionStatusOptions } from 'constants/forms';

import BaseSelect from './base';

import type { Option } from './base';

interface Props {
  value?: Option | null;
  onChange: (e: Option | null) => void;
}

const CcmInteractionStatusSelect: FC<Props> = ({ onChange, value }) => {
  const { t } = useTranslation();

  return (
    <BaseSelect
      label={t('labels.ccmInteractionStatus')}
      placeholder={t('labels.all')}
      options={interactionStatusOptions}
      value={value}
      onChange={(newValue) => {
        if (!newValue) {
          return onChange(null);
        }
        const items = Array.isArray(newValue) ? newValue : [newValue];
        onChange(items[0]);
      }}
    />
  );
};

export default CcmInteractionStatusSelect;
