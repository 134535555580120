import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import {
  XYPlot,
  LineSeries,
  MarkSeries,
  XAxis,
  YAxis,
  DiscreteColorLegend,
  HorizontalGridLines,
} from 'react-vis';
import common from 'constants/common';

import ChartHint from './chartHint';
import StickyGlucoseYAxis from './bloodGlucoseStickyYAxis';

const legendItems = [
  {
    title: 'Glucose',
    color: common.chartColors.glucose.main,
    strokeStyle: 'solid',
  },
  {
    title: 'High Glucose',
    color: common.chartColors.glucose.high,
    strokeStyle: 'dashed',
  },
  {
    title: 'Low Glucose',
    color: common.chartColors.glucose.low,
    strokeStyle: 'dashed',
  },
];

const normalizeChartData = (data, thresholds) => {
  const glucoseChartData = data;

  let deviceData = [];
  if (glucoseChartData) {
    deviceData = glucoseChartData;
  }

  let glucoseData = deviceData.map((item) => ({
    value: parseInt(item.data.glucose),
    createdAt: item.createdAt,
  }));

  if (glucoseData.length < 1) {
    const emptyData = [
      {
        value: null,
        createdAt: 0,
      },
      {
        value: null,
        createdAt: 43200000,
      },
      {
        value: null,
        createdAt: 86400000,
      },
    ];
    glucoseData = emptyData;
  }

  const low = glucoseData.map((item) => ({
    x: item.createdAt,
    y: thresholds.minCriticalGlucose,
  }));
  const high = glucoseData.map((item) => ({
    x: item.createdAt,
    y: thresholds.maxCriticalGlucose,
  }));
  const main = glucoseData.map((item) => ({
    actualTime: item.createdAt,
    x: item.createdAt,
    y: item.value,
  }));
  if (high.length === 1) {
    const xValue = high[0].x;
    high.unshift({
      x: xValue - 86400000,
      y: thresholds.maxCriticalGlucose,
    });
    high.push({
      x: parseInt(xValue) + 86400000,
      y: thresholds.maxCriticalGlucose,
    });
    low.unshift({
      x: xValue - 86400000,
      y: thresholds.minCriticalGlucose,
    });
    low.push({
      x: parseInt(xValue) + 86400000,
      y: thresholds.minCriticalGlucose,
    });
  }
  return {
    low,
    high,
    main,
  };
};

const BloodPressureChart = ({ data, thresholds }) => {
  const [hoveredPoint, setHoveredPoint] = useState(null);
  const [hintType, setHintType] = useState('');
  const yScale = common.chartYaxisScales.glucose;

  const chartData = useMemo(
    () => normalizeChartData(data, thresholds),
    [data, thresholds],
  );

  return (
    <div className="chart" data-cy="bloodGlucose-chart">
      <div className="chart-header">
        <div className="chart-header-title" data-cy="bloodGlucose-chart-title">
          <p>Blood Glucose</p>
          <p>mg/dl</p>
        </div>
        <DiscreteColorLegend items={legendItems} orientation="horizontal" />
      </div>
      <div className="chart-main">
        <StickyGlucoseYAxis />
        <XYPlot
          xType="time"
          height={common.chartHeight + 60}
          onMouseLeave={() => {
            setHoveredPoint(null);
          }}
          yDomain={yScale}
          margin={{ bottom: 60, left: 60 }}
          width={common.chartWidth}
        >
          <HorizontalGridLines tickTotal={9} />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => format(d, common.dateFormats.chartXTimeAMPM)}
            tickSizeOuter={5}
            tickSizeInner={0}
            tickPadding={24}
          />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => format(d, common.dateFormats.chartXTimeOnly)}
            tickSizeOuter={5}
            tickSizeInner={0}
          />
          <YAxis
            attr="y"
            attrAxis="x"
            orientation="left"
            tickTotal={9}
            className="hidden-axis"
          />
          <LineSeries
            curve={null}
            data={chartData.low}
            opacity={1}
            stroke={common.chartColors.glucose.low}
            strokeStyle="dashed"
          />
          <LineSeries
            curve={null}
            data={chartData.high}
            opacity={1}
            stroke={common.chartColors.glucose.high}
            strokeStyle="dashed"
          />
          {chartData.main[0].y !== null && (
            <MarkSeries
              color={common.chartColors.glucose.main}
              data={chartData.main}
              opacity={1}
              size={2.5}
              onNearestXY={(datapoint) => {
                setHoveredPoint(datapoint);
                // TODO: update with constants when more charts added
                setHintType('Glucose');
              }}
            />
          )}
          {hoveredPoint && (
            <ChartHint hoveredPoint={hoveredPoint} type={hintType} />
          )}
        </XYPlot>
      </div>
    </div>
  );
};

BloodPressureChart.propTypes = {
  data: PropTypes.array,
  thresholds: PropTypes.shape(),
};

export default BloodPressureChart;
