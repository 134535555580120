import React from 'react';
import { components as SelectComponents } from 'react-select';
import DropdownClosedIcon from 'components/atoms/icons/dropdownClosedIcon';
import DropdownOpenIcon from 'components/atoms/icons/dropdownOpenIcon';

import type { DropdownIndicatorProps, GroupBase } from 'react-select';

const SelectDropdownIndicator = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => {
  const { selectProps } = props;
  return (
    <SelectComponents.DropdownIndicator {...props}>
      {selectProps.menuIsOpen ? <DropdownOpenIcon /> : <DropdownClosedIcon />}
    </SelectComponents.DropdownIndicator>
  );
};

export default SelectDropdownIndicator;
