import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/atoms/input';

const SelectInput = ({ id, options, label, note, error, required }) => (
  <div className="input-wrapper">
    <Input
      id={id}
      options={options}
      type="select"
      label={label}
      isClearable
      markRequired={required}
    />
    {error && <div className="input-error">{error}</div>}
    {note && <div className="input-note">{note}</div>}
  </div>
);
SelectInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  note: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.string,
};
export default SelectInput;
