import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { replaceOrAddObject } from 'infrastructure/functions';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { useTranslation } from 'react-i18next';

import useApi from '../use-api';
import { showResult } from '../utils';

import type {
  ICareLocation,
  ICareLocationPaginatedData,
  ICreateCareLocation,
  IDeleteCareLocation,
  IUpdateCareLocation,
  TCareLocations,
} from 'infrastructure/interfaces';

const patientInsuranceGetApi = (patientGuid: string) =>
  `core/ccm/care-locations?patientGuid=${patientGuid}`;
const patientInsuranceDeleteApi = `core/ccm/care-locations`;

interface IUseCareLocation {
  loading: boolean;
  loadCareLocation: () => Promise<TCareLocations | void>;
  createCareLocation: (body: ICreateCareLocation) => Promise<void>;
  updateCareLocation: (body: IUpdateCareLocation) => Promise<void>;
  deleteCareLocation: (body: IDeleteCareLocation) => Promise<void>;
}

interface IUseCareLocationProps {
  patientGuid: string;
}

export const useCareLocation = (
  props: IUseCareLocationProps,
): IUseCareLocation => {
  const { patientGuid } = props;
  const { loading, loadData, createData, updateData, deleteDataWithBody } =
    useApi();

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const queryKey = ['patientInsurance', patientGuid];

  const loadCareLocation = async (): Promise<TCareLocations | void> => {
    const data = await queryClient.fetchQuery(
      queryKey,
      () =>
        loadData<ICareLocationPaginatedData>(
          patientInsuranceGetApi(patientGuid),
        ).then((res) => {
          if (res) return res.items;
        }),
      { staleTime: STALE_TIME },
    );
    if (data) return data;
  };

  const createCareLocation = async (
    body: ICreateCareLocation,
  ): Promise<void> => {
    const data = await createData<ICareLocation>(
      patientInsuranceDeleteApi,
      body,
    );

    queryClient.setQueryData<TCareLocations>(queryKey, (oldData) => {
      if (oldData) {
        oldData.push(data);
      }
      return oldData;
    });
    showResult();
  };

  const updateCareLocation = async (
    body: IUpdateCareLocation,
  ): Promise<void> => {
    await updateData<ICareLocation>(patientInsuranceDeleteApi, body).then(
      () => {
        queryClient.setQueryData<TCareLocations>(queryKey, (oldData) => {
          let newData = oldData;
          if (oldData) {
            newData = replaceOrAddObject({
              array: oldData,
              field: 'guid',
              value: body.guid,
              newObject: body,
            });
          }
          return newData;
        });
        showResult();
      },
    );
  };

  const deleteCareLocation = async (
    body: IDeleteCareLocation,
  ): Promise<void> => {
    await deleteDataWithBody(patientInsuranceDeleteApi, body).then(() => {
      queryClient.setQueryData<TCareLocations>(queryKey, (oldData) =>
        oldData?.filter((el) => el.guid !== body.guid),
      );
      notificationController.info({
        message: t('messages.deleteNoticeMessage', { delete: 'Care Location' }),
      });
    });
  };

  return {
    loading,
    loadCareLocation,
    createCareLocation,
    updateCareLocation,
    deleteCareLocation,
  };
};
