import i18next from 'i18next';

export interface ITimeItem {
  id: string;
  name: string;
  index: number;
}

export type ITimeDataSourceArray = ITimeItem[] & {
  findById: (id: string) => ITimeItem | undefined;
};

export const timesDataSource = (): ITimeDataSourceArray => {
  const res: ITimeDataSourceArray = [] as any;

  res.findById = function findById(id: string): ITimeItem | undefined {
    return this.find((item) => {
      return item.id === id;
    });
  };

  for (let i = 0; i < 24 * 4; i++) {
    const hours = Math.floor(i / 4) % 12 || 12;
    const hours24 = Math.floor(i / 4);

    const minutes = (i % 4) * 15;
    const ampm = i >= 12 * 4 ? i18next.t('dates.pm') : i18next.t('dates.am');

    const optionText = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} ${ampm}`;

    const optionValue = `${hours24.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;

    res.push({
      id: optionValue,
      name: optionText,
      index: i,
    });
  }

  return res;
};
