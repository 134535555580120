import i18next from 'i18next';

import BatteryLevel from './battery-level';
import CellularSignal from './cellular-signal';

import type { IBaseTableColumns } from 'components/atoms/base-table/types';
import type { DeviceLog } from 'infrastructure/classes/device-log';

export const logsColumns = (): IBaseTableColumns<DeviceLog> => {
  return [
    {
      label: i18next.t('labels.deviceCheckInTime'),
      key: 'deviceTime',
    },
    {
      label: i18next.t('labels.serverDateTime'),
      key: 'serverTime',
    },
    {
      label: i18next.t('labels.cellularSignal'),
      key: 'signalStrength',
      align: 'center',
      render: (_, record) => <CellularSignal data={record} />,
    },
    {
      label: i18next.t('labels.batteryLevel'),
      key: 'batteryLevel',
      align: 'center',
      render: (_, record) => <BatteryLevel data={record} />,
    },
    {
      label: i18next.t('labels.localDateTime'),
      key: 'localTime',
      align: 'right',
    },
  ];
};
