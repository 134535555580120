import { objectToArray } from 'utils/helpers/object-to-array';

import type { IStatusReportStatistic } from 'infrastructure/interfaces';

const unsuccessfulKey = 'ccm_onboarding_unsuccessful';
const patientRefusedKey = 'ccm_patient_refused';
const unenrolledKey = 'ccm_unenrolled';

const unsuccessfulFilters = [
  'ccm_onboarding_unsuccessful_changed_doctor',
  'ccm_onboarding_unsuccessful_no_contact',
  'ccm_onboarding_unsuccessful_other',
  'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
  'ccm_onboarding_unsuccessful_patient_refused',
];
const patientRefusedFilters = [
  'ccm_patient_refused_changed_doctor',
  'ccm_patient_refused_copay_insurance',
  'ccm_patient_refused_expired',
  'ccm_patient_refused_no_contact',
  'ccm_patient_refused_not_interested_not_necessary_suspect_scam',
  'ccm_patient_refused_other',
  'ccm_patient_refused_wrong_contact_information',
  'ccm_patient_refused_wants_to_discuss_with_physician',
];
const unenrolledFilters = [
  'ccm_unenrolled_changed_doctor',
  'ccm_unenrolled_client_request',
  'ccm_unenrolled_copay_loss_of_insurance',
  'ccm_unenrolled_expired',
  'ccm_unenrolled_hospice',
  'ccm_unenrolled_long_term_skilled_nursing_facility_rehab',
  'ccm_unenrolled_non_adherent',
  'ccm_unenrolled_other',
  'ccm_unenrolled_physician_clinic_request',
  'ccm_unenrolled_client_request_moving',
  'ccm_unenrolled_health_plan_request_goals_met',
];

export class StatusReportStatistic {
  ccm_calling_queue: number;

  ccm_onboarding: number;

  ccm_onboarding_unsuccessful_changed_doctor: number;

  ccm_onboarding_unsuccessful_no_contact: number;

  ccm_onboarding_unsuccessful_other: number;

  ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm: number;

  ccm_onboarding_unsuccessful_patient_refused: number;

  ccm_patient_no_contact: number;

  ccm_patient_refused_changed_doctor: number;

  ccm_patient_refused_copay_insurance: number;

  ccm_patient_refused_expired: number;

  ccm_patient_refused_no_contact: number;

  ccm_patient_refused_not_interested_not_necessary_suspect_scam: number;

  ccm_patient_refused_other: number;

  ccm_patient_refused_wrong_contact_information: number;

  ccm_referral_on_hold: number;

  ccm_referral_received: number;

  ccm_referral_withdrawn: number;

  ccm_unenrolled_changed_doctor: number;

  ccm_unenrolled_client_request: number;

  ccm_unenrolled_copay_loss_of_insurance: number;

  ccm_unenrolled_expired: number;

  ccm_unenrolled_hospice: number;

  ccm_unenrolled_long_term_skilled_nursing_facility_rehab: number;

  ccm_unenrolled_non_adherent: number;

  ccm_unenrolled_other: number;

  ccm_unenrolled_physician_clinic_request: number;

  enrolled: number;

  ccm_patient_refused_wants_to_discuss_with_physician: number;

  ccm_unenrolled_client_request_moving: number;

  ccm_unenrolled_health_plan_request_goals_met: number;

  constructor(data: IStatusReportStatistic) {
    this.ccm_calling_queue = data.ccm_calling_queue;
    this.ccm_onboarding = data.ccm_onboarding;
    this.ccm_onboarding_unsuccessful_changed_doctor =
      data.ccm_onboarding_unsuccessful_changed_doctor;
    this.ccm_onboarding_unsuccessful_no_contact =
      data.ccm_onboarding_unsuccessful_no_contact;
    this.ccm_onboarding_unsuccessful_other =
      data.ccm_onboarding_unsuccessful_other;
    this.ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm =
      data.ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm;
    this.ccm_onboarding_unsuccessful_patient_refused =
      data.ccm_onboarding_unsuccessful_patient_refused;
    this.ccm_patient_no_contact = data.ccm_patient_no_contact;
    this.ccm_patient_refused_changed_doctor =
      data.ccm_patient_refused_changed_doctor;
    this.ccm_patient_refused_copay_insurance =
      data.ccm_patient_refused_copay_insurance;
    this.ccm_patient_refused_expired = data.ccm_patient_refused_expired;
    this.ccm_patient_refused_no_contact = data.ccm_patient_refused_no_contact;
    this.ccm_patient_refused_not_interested_not_necessary_suspect_scam =
      data.ccm_patient_refused_not_interested_not_necessary_suspect_scam;
    this.ccm_patient_refused_other = data.ccm_patient_refused_other;
    this.ccm_patient_refused_wrong_contact_information =
      data.ccm_patient_refused_wrong_contact_information;
    this.ccm_referral_on_hold = data.ccm_referral_on_hold;
    this.ccm_referral_received = data.ccm_referral_received;
    this.ccm_referral_withdrawn = data.ccm_referral_withdrawn;
    this.ccm_unenrolled_changed_doctor = data.ccm_unenrolled_changed_doctor;
    this.ccm_unenrolled_client_request = data.ccm_unenrolled_client_request;
    this.ccm_unenrolled_copay_loss_of_insurance =
      data.ccm_unenrolled_copay_loss_of_insurance;
    this.ccm_unenrolled_expired = data.ccm_unenrolled_expired;
    this.ccm_unenrolled_hospice = data.ccm_unenrolled_hospice;
    this.ccm_unenrolled_long_term_skilled_nursing_facility_rehab =
      data.ccm_unenrolled_long_term_skilled_nursing_facility_rehab;
    this.ccm_unenrolled_non_adherent = data.ccm_unenrolled_non_adherent;
    this.ccm_unenrolled_other = data.ccm_unenrolled_other;
    this.ccm_unenrolled_physician_clinic_request =
      data.ccm_unenrolled_physician_clinic_request;
    this.enrolled = data.enrolled;
    this.ccm_patient_refused_wants_to_discuss_with_physician =
      data.ccm_patient_refused_wants_to_discuss_with_physician;
    this.ccm_unenrolled_client_request_moving =
      data.ccm_unenrolled_client_request_moving;
    this.ccm_unenrolled_health_plan_request_goals_met =
      data.ccm_unenrolled_health_plan_request_goals_met;
  }

  private sumCcmPatientRefusedFields() {
    return (
      this.ccm_patient_refused_changed_doctor +
      this.ccm_patient_refused_copay_insurance +
      this.ccm_patient_refused_expired +
      this.ccm_patient_refused_no_contact +
      this.ccm_patient_refused_not_interested_not_necessary_suspect_scam +
      this.ccm_patient_refused_other +
      this.ccm_patient_refused_wrong_contact_information +
      this.ccm_patient_refused_wants_to_discuss_with_physician
    );
  }

  private sumCcmOnboardingUnsuccessfulFields() {
    return (
      this.ccm_onboarding_unsuccessful_changed_doctor +
      this.ccm_onboarding_unsuccessful_no_contact +
      this.ccm_onboarding_unsuccessful_other +
      this.ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm +
      this.ccm_onboarding_unsuccessful_patient_refused
    );
  }

  private sumCcmUnenrolled() {
    return (
      this.ccm_unenrolled_changed_doctor +
      this.ccm_unenrolled_client_request +
      this.ccm_unenrolled_copay_loss_of_insurance +
      this.ccm_unenrolled_expired +
      this.ccm_unenrolled_hospice +
      this.ccm_unenrolled_long_term_skilled_nursing_facility_rehab +
      this.ccm_unenrolled_non_adherent +
      this.ccm_unenrolled_other +
      this.ccm_unenrolled_physician_clinic_request +
      this.ccm_unenrolled_client_request_moving +
      this.ccm_unenrolled_health_plan_request_goals_met
    );
  }

  getMergeStatistic() {
    return {
      enrolled: this.enrolled,
      ccm_calling_queue: this.ccm_calling_queue,
      [patientRefusedKey]: this.sumCcmPatientRefusedFields(),
      ccm_referral_on_hold: this.ccm_referral_on_hold,
      ccm_referral_received: this.ccm_referral_received,
      ccm_referral_withdrawn: this.ccm_referral_withdrawn,
      ccm_patient_no_contact: this.ccm_patient_no_contact,
      ccm_onboarding: this.ccm_onboarding,
      [unsuccessfulKey]: this.sumCcmOnboardingUnsuccessfulFields(),
      [unenrolledKey]: this.sumCcmUnenrolled(),
    };
  }

  getStatisticItems() {
    const mergedStatistic = this.getMergeStatistic();
    const items = objectToArray(mergedStatistic);
    return items.map((item) => {
      let filter = item.key;
      if (item.key === unsuccessfulKey) {
        filter = unsuccessfulFilters.join();
      }
      if (item.key === patientRefusedKey) {
        filter = patientRefusedFilters.join();
      }
      if (item.key === unenrolledKey) {
        filter = unenrolledFilters.join();
      }
      return {
        ...item,
        filter,
      };
    });
  }
}
