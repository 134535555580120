import type { FC, SVGProps } from 'react';

const HoldingControlStatusIcon: FC<SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5922 4.62403C9.62437 -0.473549 16.3707 -1.50509 20.6809 2.27339C24.9911 6.05187 25.2201 16.4803 20.6809 21.0786C16.1416 25.6768 5.67952 24.2016 2.11701 21.0786C-1.4455 17.9555 -0.439978 9.72162 4.5922 4.62403Z"
        fill="#FB9318"
      />
      <path
        d="M7.5 7.125C7.5 6.91789 7.66789 6.75 7.875 6.75H16.125C16.3321 6.75 16.5 6.91789 16.5 7.125C16.5 7.33211 16.3321 7.5 16.125 7.5H15.375V8.25C15.375 9.59258 14.591 10.7513 13.4576 11.2948C13.2402 11.399 13.125 11.5776 13.125 11.7369V12.2631C13.125 12.4224 13.2402 12.601 13.4576 12.7052C14.591 13.2487 15.375 14.4074 15.375 15.75V16.5L16.125 16.5C16.3321 16.5 16.5 16.6679 16.5 16.875C16.5 17.0821 16.3321 17.25 16.125 17.25L7.875 17.25C7.66789 17.25 7.5 17.0821 7.5 16.875C7.5 16.6679 7.66789 16.5 7.875 16.5H8.625V15.75C8.625 14.4074 9.40896 13.2487 10.5424 12.7052C10.7598 12.601 10.875 12.4224 10.875 12.2631V11.7369C10.875 11.5776 10.7598 11.399 10.5424 11.2948C9.40896 10.7513 8.625 9.59258 8.625 8.25V7.5H7.875C7.66789 7.5 7.5 7.33211 7.5 7.125ZM9.375 7.5V8.25C9.375 9.29323 9.98354 10.1951 10.8667 10.6185C11.2664 10.8102 11.625 11.2117 11.625 11.7369V12.2631C11.625 12.7883 11.2664 13.1898 10.8667 13.3815C9.98354 13.805 9.375 14.7068 9.375 15.75V16.5H14.625V15.75C14.625 14.7068 14.0165 13.805 13.1333 13.3815C12.7336 13.1898 12.375 12.7883 12.375 12.2631V11.7369C12.375 11.2117 12.7336 10.8102 13.1333 10.6185C14.0165 10.1951 14.625 9.29323 14.625 8.25V7.5H9.375Z"
        fill="white"
      />
    </svg>
  );
};

export default HoldingControlStatusIcon;
