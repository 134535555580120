import type { FC } from 'react';

const AppointmentEmailIcon: FC<{ width?: number; height?: number }> = ({
  height = 40,
  width = 40,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C7.23858 0 5 2.23858 5 5V7.83321L2.64706 9.08811C1.01774 9.95708 0 11.6533 0 13.4999V34.9999C0 37.7613 2.23858 39.9999 5 39.9999H35C37.7614 39.9999 40 37.7613 40 34.9999V13.4999C40 11.6533 38.9823 9.95708 37.3529 9.08811L35 7.83321V5C35 2.23858 32.7614 0 30 0H10ZM35 10.6665L36.1765 11.294C36.9911 11.7285 37.5 12.5766 37.5 13.4999V15.5421L35 17.0421V10.6665ZM32.5 18.5421L23.125 24.1671L20 22.2921L16.875 24.1671L7.5 18.5421V5C7.5 3.61929 8.61929 2.5 10 2.5H30C31.3807 2.5 32.5 3.61929 32.5 5V18.5421ZM5 17.0421L2.5 15.5421V13.4999C2.5 12.5766 3.00887 11.7285 3.82353 11.294L5 10.6665V17.0421ZM37.5 18.4576V32.7921L25.5546 25.6249L37.5 18.4576ZM37.4131 35.6555C37.1251 36.7182 36.1538 37.4999 35 37.4999H5C3.84616 37.4999 2.87489 36.7182 2.58685 35.6555L20 25.2076L37.4131 35.6555ZM2.5 32.7921V18.4576L14.4454 25.6249L2.5 32.7921Z"
      fill="#2C2543"
    />
  </svg>
);

export default AppointmentEmailIcon;
