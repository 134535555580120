import queryString from 'query-string';
import { pickBy, identity } from 'lodash';

import { API_ADMIN_PANEL_AGENCY_DOCTORS } from './constants';

export const apiGetDoctorByFilter = (clinicGuid, filter) =>
  `core/clinics/${clinicGuid}/doctors?${queryString.stringify(filter)}`;

export const apiGetDoctorsByFilter = (filter) =>
  `core/doctors?${queryString.stringify(pickBy(filter, identity))}`;

export const apiGetAgencyDoctorsByFilter = (filter) =>
  `${API_ADMIN_PANEL_AGENCY_DOCTORS}?${queryString.stringify(filter)}`;

export const apiGetDoctors = (clinicGuid, filter) =>
  `core/clinics/${clinicGuid}/doctors?${queryString.stringify(filter)}`;

export const apiDoctorPatientReadingsCount = (
  reviewed,
  startTime = null,
  endTime = null,
  filters,
  applySpecialFilter,
) => {
  let endpoint = `core/patients/readings/count?reviewed=${reviewed}`;
  if (startTime && endTime) {
    endpoint += `&startTime=${startTime}&endTime=${endTime}`;
  }
  if (applySpecialFilter) {
    endpoint += `&applySpecialFilter=${applySpecialFilter}`;
  }
  Object.keys(filters).forEach((key) => {
    const filterValue = filters[key];
    if (key !== 'status' && filterValue) endpoint += `&${key}=${filterValue}`;
  });
  return endpoint;
};
export const apiDoctorMedicareReadings = (
  items,
  page = 1,
  clinicGuid = null,
  doctorGuid = null,
  startTime = null,
  endTime = null,
  childClinic = null,
) => {
  let endpoint = `reports/reports/medicare-billings?items=${items}&page=${page}`;
  if (startTime && endTime) {
    endpoint += `&startTime=${startTime}&endTime=${endTime}`;
  }
  if (clinicGuid && clinicGuid !== 'all') {
    endpoint += `&clinicGuid=${clinicGuid}`;
  }
  if (doctorGuid && doctorGuid !== 'all') {
    endpoint += `&doctorGuid=${doctorGuid}`;
  }
  if (childClinic) {
    endpoint += `&childClinic=${childClinic}`;
  }
  return endpoint;
};

export const apiDoctorInteractiveTime = (
  items,
  page = 1,
  startTime = null,
  endTime = null,
  filters,
) => {
  const query = queryString.stringify(
    pickBy(
      {
        ...filters,
        items,
        page,
      },
      identity,
    ),
  );

  let endpoint = `reports/reports/interactive-time?${query}`;
  if (startTime && endTime) {
    endpoint += `&startTime=${startTime}&endTime=${endTime}`;
  }
  return endpoint;
};
export const apiDoctorOnboardingStatus = ({
  clinicGuid,
  doctorGuid,
  childClinic,
}) => {
  const endpoint = `reports/reports/onboarding-status`;
  const query = queryString.stringify(
    pickBy(
      {
        clinicGuid,
        doctorGuid,
        childClinic,
      },
      identity,
    ),
  );
  return `${endpoint}?${query}`;
};

export const apiDoctorCcmOnboardingStatus = ({
  clinicGuid,
  doctorGuid,
  childClinic,
}) => {
  const endpoint = `reports/ccm/reports/onboarding-status`;
  const query = queryString.stringify(
    pickBy(
      {
        clinicGuid,
        doctorGuid,
        childClinic,
      },
      identity,
    ),
  );
  return `${endpoint}?${query}`;
};

export const apiDoctorCompliance = (
  startTime = null,
  endTime = null,
  filters,
) => {
  let endpoint = `reports/reports/compliance`;
  if (startTime && endTime) {
    endpoint += `?startTime=${startTime}&endTime=${endTime}`;
  }
  Object.keys(filters).forEach((key) => {
    const filterValue = filters[key];
    if (filterValue) endpoint += `&${key}=${filterValue}`;
  });
  return endpoint;
};

export const apiAdminPanelUsers = (items, page = 1, filters) => {
  let endpoint = `admins/admin-panel/doctors?items=${items}&page=${page}`;
  Object.keys(filters).forEach((key) => {
    const filterValue = filters[key];
    if (filterValue && filterValue !== 'all') {
      endpoint += `&${key}=${filterValue}`;
    }
  });
  return endpoint;
};

export const apiDoctorAdherenceLast30Days = (
  items,
  page = 1,
  sort,
  filters,
) => {
  const query = queryString.stringify(
    pickBy(
      {
        ...filters,
        isLast30DaysReport: true,
        items,
        page,
        sort: sort.percent,
      },
      identity,
    ),
  );

  const endpoint = `reports/reports/adherence?${query}`;
  return endpoint;
};

export const apiDoctorAdherence = (
  items,
  page = 1,
  startTime = null,
  endTime = null,
) => {
  let endpoint = `reports/reports/adherence?items=${items}&page=${page}`;
  if (startTime && endTime) {
    endpoint += `&startTime=${startTime}&endTime=${endTime}`;
  }
  return endpoint;
};

export const apiDoctorClinicalMetrics = (filters) =>
  `reports/reports/clinical-metrics?${queryString.stringify(filters)}`;
export const apiDoctorProfile = (guid) => `core/doctors/${guid}/profile`;

export const apiAdminPanelAgencyDoctors = (items, page = 1, filters) => {
  let endpoint = `admins/admin-panel/agency-doctors?items=${items}&page=${page}`;
  Object.keys(filters).forEach((key) => {
    const filterValue = filters[key];
    if (filterValue) {
      endpoint += `&${key}=${filterValue}`;
    }
  });
  return endpoint;
};

export const apiAgencyDoctorProfile = (guid) =>
  `admins/admin-panel/agency-doctors/${guid}`;
export const apiPatientDoctors = (guid) => `core/patients/${guid}/doctors`;
export const apiDoctorPatientReadings = (
  reviewed,
  items,
  page = 1,
  startTime = null,
  endTime = null,
  timeSorting = true,
  nameSorting = 'ASC',
  readingTypeSorting = null,
  filters,
  applySpecialFilter,
) => {
  let endpoint = `core/patients/readings?items=${items}&page=${page}`;
  if (reviewed !== 'all') {
    endpoint += `&reviewed=${reviewed}`;
  }
  if (startTime && endTime) {
    endpoint += `&startTime=${startTime}&endTime=${endTime}`;
  }
  if (timeSorting) {
    endpoint += `&reverse=${timeSorting}`;
  }
  if (nameSorting) {
    endpoint += `&name=${nameSorting}`;
  }
  if (filters && filters.readingType && readingTypeSorting) {
    endpoint += `&readingTypeSort=${readingTypeSorting}`;
  }
  if (applySpecialFilter) {
    endpoint += `&applySpecialFilter=${applySpecialFilter}`;
  }
  Object.keys(filters).forEach((key) => {
    const filterValue = filters[key];
    if (filterValue) endpoint += `&${key}=${filterValue}`;
  });
  return endpoint;
};
export const apiDoctorPatients = ({
  clinicGuid,
  patientGuid,
  status,
  doctorGuid,
  clinicsStatus,
  page,
  items,
  referralDateSort,
  ccmStatus,
  pcmStatus,
  childClinic,
}) => {
  const endpoint = 'core/patients';
  const query = queryString.stringify(
    pickBy(
      {
        clinicGuid,
        guid: patientGuid,
        doctorGuid,
        status,
        clinicsStatus,
        page,
        items,
        referralDateSort,
        ccmStatus,
        pcmStatus,
        childClinic,
      },
      identity,
    ),
  );
  return `${endpoint}?${query}`;
};
