import React from 'react';
import C from 'classnames';
import PropTypes from 'prop-types';

const Button = ({ status, count, text, handleClick, ...rest }) => {
  return (
    <button
      onClick={() => handleClick(status)}
      className={C('condition-button', { disabled: rest.disabled })}
      {...rest}
    >
      <div className={C('condition-button-mobile', status)}>
        <div className="condition-button-mobile-label">{text}</div>
        <div className="condition-button-mobile-count">{count}</div>
      </div>
    </button>
  );
};

Button.defaultProps = {
  handleClick: () => {},
};

Button.propTypes = {
  status: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

export default Button;
