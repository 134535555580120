import { Popover } from 'antd';
import { HashLink } from 'react-router-hash-link';
import { Fragment, type FC } from 'react';

import { useMiniNav } from './hooks/use-mini-nav';
import s from './styles.module.scss';

import type { ModuleType } from 'infrastructure/enums';

interface MiniNavProps {
  charts?: Record<string, any[]>;
  module: ModuleType;
}

const smoothScroll = (target: number, duration = 500) => {
  const start = window.scrollY;
  const distance = target - start;
  let startTime: number | null = null; // track the start time of the animation

  const scroll = (currentTime: number) => {
    if (!startTime) startTime = currentTime; // if it's the first frame, set the start time
    const timeElapsed = currentTime - startTime; // time elapsed since the start of the animation
    const progress = Math.min(timeElapsed / duration, 1); // progress of the animation (clamped between 0 and 1)
    const easing = progress * (2 - progress); // smooth deceleration easeOutQuad
    window.scrollTo(0, start + distance * easing); // update the scroll position based on easing

    // continue animation until the duration is reached
    if (timeElapsed < duration) {
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll); // start the animation
};

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
  const yOffset = -110; // header + mini banner height
  smoothScroll(yCoordinate + yOffset);
};

const MiniNav: FC<MiniNavProps> = ({ module, charts = {} }) => {
  const { linksList } = useMiniNav({ charts });

  const links = linksList[module as keyof typeof linksList];

  return (
    <div className={s.wrapper}>
      {links.map((link, idx, arr) => (
        <Fragment key={link.key}>
          <Popover
            placement="right"
            trigger="hover"
            title={link.title}
            arrow={false}
            getPopupContainer={(trigger) => trigger}
          >
            <HashLink
              to={link.to}
              scroll={(el: HTMLElement) => scrollWithOffset(el)}
            >
              <div className={s.item} data-cy={link.dataCy}>
                {link.icon}
              </div>
            </HashLink>
          </Popover>
          {idx !== arr.length - 1 && <div className={s.divider} />}
        </Fragment>
      ))}
    </div>
  );
};
export default MiniNav;
