import EmailIcon from '../icons/emailIcon';
import BaseTag from './BaseTag';

import type { FC } from 'react';

interface Props {
  small?: boolean;
}

const EmailUnsubscribedTag: FC<Props> = ({ small }) => {
  return (
    <BaseTag
      icon={<EmailIcon />}
      text="Unsubscribed"
      title="Email Unsubscribed"
      color="red"
      dataCy="email-unsubscribed-tag"
      small={small}
    />
  );
};

export default EmailUnsubscribedTag;
