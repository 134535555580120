import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { useDispatch } from 'react-redux';
import Button from 'components/atoms/button';
import ModalPopup from 'components/organisms/modal';
import { showResult } from 'infrastructure/hooks/utils';

const DischargePatientModal = ({
  isOpen,
  toggle,
  patientGuid,
  options,
  loading,
  submit,
  modalType = 'rpm',
}) => {
  const {
    patient: { getDetails },
    patientStatusLogs: { getPatientStatusLogs },
  } = useDispatch();

  const buttonLabel = modalType === 'rpm' ? 'Discharge' : 'Unenroll';

  const handleSubmit = async (values) => {
    const postData = {
      patientGuid,
      dischargeStatus: values.status,
    };
    const { error } = await submit(postData);
    await getDetails(patientGuid);
    await getPatientStatusLogs(patientGuid);
    showResult(error, null, null, toggle);
  };

  return (
    <ModalPopup
      isOpen={isOpen}
      toggle={toggle}
      title="Status"
      className="popup discharge-popup discharge-popup__modal"
    >
      <Formik initialValues={{ status: '' }} onSubmit={handleSubmit}>
        {({ dirty }) => (
          <Form>
            <div className="discharge-popup-content">
              <FormGroup tag="fieldset">
                {options.map((option) => (
                  <FormGroup check key={option.id}>
                    <Label check>
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="status"
                        value={option.id}
                        disabled={loading}
                      />
                      {option.label}
                    </Label>
                  </FormGroup>
                ))}
              </FormGroup>
            </div>
            <div className="text-center">
              <Button
                type="submit"
                text={buttonLabel}
                data-cy="discharge-button"
                disabled={!dirty || loading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </ModalPopup>
  );
};

DischargePatientModal.defaultProps = {
  isOpen: false,
};

DischargePatientModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  patientGuid: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
  submit: PropTypes.func.isRequired,
  modalType: PropTypes.string,
};

export default DischargePatientModal;
