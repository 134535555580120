import React, { forwardRef, useImperativeHandle } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseGrid from 'components/atoms/base-grid';
import BaseTextArea from 'components/atoms/base-textarea';
import ICDCodeSelectBox from 'components/molecules/icd-code-select-box';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import { usePatientIcdCode } from 'infrastructure/hooks/patient/use-patient-icd-code';

import { conditionsValidationSchema } from './validation-schema';

import type { PatientIcdCode } from 'infrastructure/classes/patient-icd-code';
import type { IUpdatePatientIcdCode } from 'infrastructure/interfaces';

export interface IConditionUpdateFormRef {
  submit: () => Promise<void>;
  reset: () => void;
}

interface IConditionUpdateFormProps {
  data: PatientIcdCode;
  patientGuid: string;
  onCancel?: () => void;
  onSaved?: () => void;
}

const ConditionUpdateForm = forwardRef<
  IConditionUpdateFormRef,
  IConditionUpdateFormProps
>((props, ref) => {
  const { patientGuid, data, onCancel, onSaved } = props;

  const { t } = useTranslation();

  const { loading, updatePatientIcdCode } = usePatientIcdCode({ patientGuid });

  const formik = useFormik<IUpdatePatientIcdCode>({
    initialValues: {
      patientGuid,
      guid: data.guid,
      diagnoseGuid: data.icdCode.guid,
      description: data.description,
    },
    onSubmit: async (values) => {
      await updatePatientIcdCode(data.guid, values).then(() => {
        if (onSaved) onSaved();
      });
    },
    validateOnChange: false,
    validationSchema: conditionsValidationSchema(),
  });

  const { submitForm, resetForm } = formik;

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: resetForm,
  }));

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseForm
        loading={loading}
        formik={formik}
        plaintext={false}
        readonly={false}
        onSave={submitForm}
        onCancel={onCancel}
        withControls
      >
        <BaseGrid columns={1}>
          <BaseFormItem name="diagnoseGuid" label={t('labels.icdCode')}>
            <ICDCodeSelectBox
              readonly
              plaintext
              onChange={(value, option) => {
                formik.setFieldValue('diagnoseGuid', value);
                formik.setFieldValue('description', option.record?.description);
              }}
            />
          </BaseFormItem>
          <BaseFormItem name="description" label={t('labels.description')}>
            <BaseTextArea />
          </BaseFormItem>
        </BaseGrid>
      </BaseForm>
    </BaseSpinWrapper>
  );
});

ConditionUpdateForm.displayName = 'ConditionUpdateForm';

export default ConditionUpdateForm;
