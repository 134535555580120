import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseForm from 'components/atoms/base-form';
import BaseFormGroup from 'components/atoms/base-form/group';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseGrid from 'components/atoms/base-grid';
import BaseInput from 'components/atoms/base-input';
import { mergeFieldsValue } from 'infrastructure/functions';

import { clinicContactInformationValidationSchema } from './validation-schema';

import type { Clinic } from 'infrastructure/classes/clinic';
import type { IClinicContactInformation } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const prepareValue = (data?: Clinic): IClinicContactInformation => {
  let defaultValue: IClinicContactInformation = {
    legalname: '',
    email: null,
    phone: null,
    callerId: null,
    fax: null,
    crmId: null,
  };
  if (data) {
    defaultValue = mergeFieldsValue(defaultValue, data);
  }

  return defaultValue;
};

const FormDisplayName = 'ClinicContactInformationForm';

interface IProps extends IIndependentFormProps {
  data?: Clinic;
}

const ClinicContactInformationForm = forwardRef<
  IIndependentFormRef<IClinicContactInformation>,
  IProps
>((props, ref) => {
  const { data } = props;
  const { t } = useTranslation();

  const formik = useFormik<IClinicContactInformation>({
    initialValues: prepareValue(data),
    onSubmit: () => {},
    validateOnChange: false,
    validationSchema: clinicContactInformationValidationSchema(),
  });

  const submitForm = async () => {
    await formik.submitForm();
    const isValid = await formik
      .validateForm()
      .then((res) => !Object.keys(res).length);

    return {
      values: formik.values,
      formName: FormDisplayName,
      isValid,
    };
  };

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: formik.resetForm,
  }));

  useEffect(() => {
    if (data) formik.resetForm({ values: prepareValue(data) });
  }, [data]);

  return (
    <BaseForm formik={formik}>
      <BaseFormGroup label={t('labels.contactInformation')}>
        <BaseGrid columns={2}>
          <BaseFormItem
            name="legalname"
            label={t('labels.legalName')}
            required
            isBlackLabel
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem name="fax" label={t('labels.businessFax')} isBlackLabel>
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="email"
            label={t('labels.businessEmail')}
            isBlackLabel
          >
            <BaseInput />
          </BaseFormItem>
          <BaseFormItem
            name="callerId"
            label={t('labels.clinicCallerID')}
            isBlackLabel
          >
            <BaseInput placeholder={t('labels.phoneNumber')} />
          </BaseFormItem>
          <BaseFormItem
            name="phone"
            label={t('labels.businessPhone')}
            isBlackLabel
          >
            <BaseInput placeholder={t('labels.phoneNumber')} />
          </BaseFormItem>
          <BaseFormItem name="crmId" label={t('labels.crmId')} isBlackLabel>
            <BaseInput />
          </BaseFormItem>
        </BaseGrid>
      </BaseFormGroup>
    </BaseForm>
  );
});

ClinicContactInformationForm.displayName = FormDisplayName;

export default ClinicContactInformationForm;
