import React from 'react';
import { useTranslation } from 'react-i18next';
import common from 'constants/common';
import { isNotUndefined } from 'infrastructure/functions';

import s from './styles.module.scss';

import type { IAdminPanelPatient } from 'infrastructure/interfaces';

interface IProps {
  record: IAdminPanelPatient;
}

const CcmStatus: React.FC<IProps> = ({ record }) => {
  const { t } = useTranslation();

  const status = isNotUndefined(record.ccmStatus)
    ? common.ccmStatus[record.ccmStatus]
    : undefined;
  const background = status?.color;

  return (
    <div className={s['ccm-status-wrapper']}>
      <div className={s['ccm-status']}>
        <p className={s.status} style={{ background }}>
          {status?.label}
        </p>
      </div>
      <div className={s['pcm-status']}>
        <p>{t('labels.pcm')}</p>
        <p>{record.pcmStatus ? t('controls.true') : t('controls.false')}</p>
      </div>
      <div className={s.consent}>
        <p>{t('labels.consent')}</p>
        <p>
          {record.ccmConsentFormStatus
            ? t('controls.confirmed')
            : t('controls.unconfirmed')}
        </p>
      </div>
    </div>
  );
};

export default CcmStatus;
