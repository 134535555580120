import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { apiPatientCharts } from 'services/dataService/resources';
import { toFirstLowerCase } from 'infrastructure/functions';

import { PATIENT_DATA_STALE_TIME } from './config';

import type {
  IRpmPatientChart,
  NormalizeChartsData,
} from 'infrastructure/interfaces/i-rpm-patient-charts';

const useTurkDashboardCharts = ({
  patientGuid,
  enabled,
  startTime,
  endTime,
}: {
  patientGuid: string;
  enabled: boolean;
  startTime: string;
  endTime: string;
}) => {
  const { data, error, isLoading } = useQuery({
    enabled,
    queryKey: ['turkDashboardCharts', patientGuid, startTime, endTime],
    queryFn: async ({ signal }) => {
      // @ts-ignore
      const url = apiPatientCharts(patientGuid, startTime, endTime);
      const { error: localError, data: localData } = await dataService.getList<
        IRpmPatientChart[]
      >(url, {
        signal,
      });
      if (localError) {
        throw new Error(localError);
      }

      const normalizedData = localData?.reduce<NormalizeChartsData>(
        (acc, chart) => {
          const key = toFirstLowerCase(chart.readingType);
          // @ts-ignore
          acc[key] = chart.data;
          return acc;
        },
        {} as NormalizeChartsData,
      );

      return normalizedData;
    },
    staleTime: PATIENT_DATA_STALE_TIME,
  });

  return { data, isLoading, error };
};

export default useTurkDashboardCharts;
