import { ModuleType, timePresetKeys } from 'infrastructure/enums';
import { debounce } from 'lodash';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { isCurrentMonthSelected } from 'utils/timeHelper';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { endOfDay, startOfDay } from 'date-fns';

import { options, useSearchPatient } from './use-search-patient';

import type { PresetKeyEnum } from 'infrastructure/enums';

interface Props {
  module: ModuleType;
  patientGuid: string;
  clinicGuid?: string;
}

const DEFAULT_FILTER = options[0];
const DEFAULT_RANGE: [Date, Date] = [
  dayjs().startOf('M').toDate(),
  dayjs().endOf('M').toDate(),
];

export const useRpmCcmHeader = ({ module, clinicGuid, patientGuid }: Props) => {
  const [searchKey, setSearchKey] = useState(DEFAULT_FILTER.value);
  const [searchValue, setSearchValue] = useState('');
  const [openPicker, setOpenPicker] = useState(false);
  const [dateRange, setDateRange] = useState<[Date, Date] | undefined>(
    DEFAULT_RANGE,
  );

  const {
    ccmPatientDetails: { applyDateFilter },
    patient: { applyTimeFilter },
    activity: { changeReviewed },
    sms: { setTimeRange },
  } = useAppDispatch();
  const { reviewed } = useAppSelector((state) => state.activity);
  const {
    activityActions: { resetActions },
  } = useAppDispatch();
  const navigate = useNavigate();

  const { search, dataOptions, isLoading } = useSearchPatient({ module });

  const placeholder = useMemo(
    () => options.find((o) => o.value === searchKey)?.placeholder,
    [searchKey],
  );
  const debounceSearch = useCallback(debounce(search, 800), []);

  useEffect(() => {
    setSearchValue('');
  }, [searchKey]);

  useEffect(() => {
    if (module === ModuleType.CCM) applyDateFilter(dateRange);
    if (module === ModuleType.RPM) {
      applyTimeFilter(dateRange);
      setTimeRange(dateRange);
    }
  }, []);

  const onCalendarChange = (
    dates: [Date | null, Date | null],
    dateString: [string, string],
    info: { range?: 'start' | 'end' },
    presetKey?: string,
  ) => {
    const [startDate, endDate] = dates;

    if (startDate === null && endDate === null) {
      return;
    }

    const isTimePreset =
      presetKey && timePresetKeys.includes(presetKey as PresetKeyEnum);

    const start = isTimePreset ? startDate! : startOfDay(startDate!);
    const end = isTimePreset ? endDate! : endOfDay(endDate!);
    setDateRange([start, end]);
  };

  const onApply = () => {
    if (!dateRange) return;

    if (module === ModuleType.RPM) {
      applyTimeFilter(dateRange);
      setTimeRange(dateRange);
      if (isCurrentMonthSelected(dateRange, reviewed) && clinicGuid) {
        changeReviewed({ patientGuid, clinicGuid });
      }
    }

    if (module === ModuleType.CCM) {
      applyDateFilter(dateRange);
    }

    setOpenPicker(false);
    resetActions();
  };

  const onSelect = (
    name: string,
    option: {
      value: string;
      label: string;
    },
  ) => {
    const { label, value } = option;
    setSearchValue(label);
    const urlPath = `/patient/${value}`;
    navigate(module === ModuleType.CCM ? `/ccm${urlPath}` : urlPath);
  };

  return {
    debounceSearch,
    dataOptions,
    isLoading,
    placeholder,
    openPicker,
    options,
    dateRange,
    DEFAULT_RANGE,
    DEFAULT_FILTER,
    searchValue,
    searchKey,
    onApply,
    setSearchKey,
    setDateRange,
    setSearchValue,
    setOpenPicker,
    onSelect,
    onCalendarChange,
  };
};
