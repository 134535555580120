import React from 'react';
import LogTable from 'components/atoms/log-table';
import { useCcmClaimStatusLog } from 'infrastructure/hooks/logs/use-ccm-claim-status-log';
import useUpdateEffect from 'infrastructure/hooks/utils/use-update-effect';

interface IPatientCcmClaimStatusLogProps {
  patientGuid: string;
  dateRange?: [Date, Date];
}

const PatientCcmClaimStatusLog: React.FC<IPatientCcmClaimStatusLogProps> = (
  props,
) => {
  const { patientGuid, dateRange } = props;

  const { loading, items, meta, setMeta } = useCcmClaimStatusLog({
    patientGuid,
    meta: {
      dateRange,
    },
  });

  const onChangePage = (page: number) => {
    setMeta({
      ...meta,
      page,
      dateRange,
    });
  };

  useUpdateEffect(() => {
    setMeta({
      ...meta,
      page: 1,
      dateRange,
    });
  }, [dateRange]);

  return (
    <LogTable
      dataCy="patient-ccm-сlaim-status-log-table"
      items={items}
      loading={loading}
      pagination={{
        current: meta.page,
        pageSize: meta.items,
        total: meta.totalCount,
        position: 'bottomCenter',
      }}
      onChangePage={onChangePage}
    />
  );
};

export default PatientCcmClaimStatusLog;
