import i18next from 'i18next';

import DeviceIdsCol from './device-id';
import s from './styles.module.scss';

import type { Adherence } from 'infrastructure/classes';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

export const adherenceReportColumns = (): IBaseTableColumns<Adherence> => {
  return [
    {
      label: i18next.t('labels.patientName'),
      key: 'patient.fullName',
      width: '105px',
      fixed: true,
    },
    {
      label: i18next.t('labels.mrn'),
      key: 'patient.mrn',
      width: '115px',
    },
    {
      label: i18next.t('labels.dob'),
      key: 'patient.birthDate',
      width: '85px',
    },
    {
      label: i18next.t('labels.insurancePlanProvider'),
      key: 'insurance.provider',
      width: '105px',
    },
    {
      label: i18next.t('labels.insurancePlanNumber'),
      key: 'insurance.number',
      width: '140px',
    },
    {
      label: i18next.t('labels.language'),
      key: 'patient.languageLabel',
      width: '75px',
    },
    {
      label: i18next.t('labels.provider'),
      key: 'doctor.label',
      width: '125px',
    },
    {
      label: i18next.t('labels.phoneNumber'),
      key: 'patient.phone',
      width: '120px',
    },
    {
      label: i18next.t('labels.secondaryPhoneNumber'),
      key: 'patient.homeNumber',
      width: '135px',
    },
    {
      label: i18next.t('labels.email'),
      key: 'patient.email',
      width: '200px',
    },
    {
      label: i18next.t('labels.preferContactMethod'),
      key: 'patient.preferredContactMethod',
      width: '95px',
    },
    {
      label: i18next.t('labels.devicesId'),
      key: 'devicesId',
      width: '80px',
      render: (_, record) => <DeviceIdsCol data={record} />,
    },
    {
      label: i18next.t('labels.devicesBrand'),
      key: 'devicesBrand',
      width: '80px',
      render: (_, record) => <>{record.devices?.manufacturers.join(', ')}</>,
    },
    {
      label: i18next.t('labels.adherence'),
      key: 'adherencePercent',
      rowCollClassName: (record) => s[record.adherenceStatus],
      render: (_, record) => <>{record.adherencePercent}%</>,
    },
    {
      label: i18next.t('labels.lastReading'),
      key: 'lastReading',
    },
  ];
};
