import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  XYPlot,
  LineSeries,
  XAxis,
  YAxis,
  DiscreteColorLegend,
  HorizontalGridLines,
} from 'react-vis';
import common from 'constants/common';
import BaseLoader from 'components/atoms/base-loader';

import ChartHint from './chartHint';
import StickyBloodOrPulseYAxis from './bloodOrPulseStickyYAxis';

const legendItems = [
  {
    title: 'Systolic',
    color: common.chartColors.systolic.main,
    strokeStyle: 'solid',
  },
  {
    title: 'High Systolic',
    color: common.chartColors.systolic.high,
    strokeStyle: 'dashed',
  },
  {
    title: 'Low Systolic',
    color: common.chartColors.systolic.low,
    strokeStyle: 'dashed',
  },
  {
    title: 'Diastolic',
    color: common.chartColors.diastolic.main,
    strokeStyle: 'solid',
  },
  {
    title: 'High Diastolic',
    color: common.chartColors.diastolic.high,
    strokeStyle: 'dashed',
  },
  {
    title: 'Low Diastolic',
    color: common.chartColors.diastolic.low,
    strokeStyle: 'dashed',
  },
];

const normalizeChartData = (data, thresholds, dates) => {
  const deviceData = data;
  const [startTime, endTime] = dates;

  let systolicData = deviceData.map((item) => ({
    value: item.data.systolic,
    createdAt: item.createdAt,
  }));

  let diastolicData = deviceData.map((item) => ({
    value: item.data.diastolic,
    createdAt: item.createdAt,
  }));

  if (systolicData < 1) {
    const emptyData = [
      {
        value: null,
        createdAt: startTime.getTime(),
      },
      {
        value: null,
        createdAt: endTime.getTime(),
      },
    ];
    systolicData = emptyData;
  }

  if (diastolicData < 1) {
    const emptyData = [
      {
        value: null,
        createdAt: startTime.getTime(),
      },
      {
        value: null,
        createdAt: endTime.getTime(),
      },
    ];
    diastolicData = emptyData;
  }

  const systolicLow = systolicData.map((item, i) => ({
    x: +item.createdAt + i * 5000,
    y: thresholds.minCriticalSystolic,
  }));
  const systolicHigh = systolicData.map((item, i) => ({
    x: +item.createdAt + i * 5000,
    y: thresholds.maxCriticalSystolic,
  }));
  const systolicMain = systolicData.map((item, i) => ({
    x: +item.createdAt + i * 5000,
    y: item.value,
  }));

  const diastolicLow = diastolicData.map((item, i) => ({
    x: +item.createdAt + i * 5000,
    y: thresholds.minCriticalDiastolic,
  }));
  const diastolicHigh = diastolicData.map((item, i) => ({
    x: +item.createdAt + i * 5000,
    y: thresholds.maxCriticalDiastolic,
  }));
  const diastolicMain = diastolicData.map((item, i) => ({
    x: +item.createdAt + i * 5000,
    y: item.value,
  }));

  return {
    systolic: {
      low: {
        data: systolicLow,
        stroke: common.chartColors.systolic.low,
        strokeStyle: 'dashed',
      },
      high: {
        data: systolicHigh,
        stroke: common.chartColors.systolic.high,
        strokeStyle: 'dashed',
      },
      main: {
        data: systolicMain,
        stroke: common.chartColors.systolic.main,
        strokeStyle: 'solid',
      },
    },
    diastolic: {
      low: {
        data: diastolicLow,
        stroke: common.chartColors.diastolic.low,
        strokeStyle: 'dashed',
      },
      high: {
        data: diastolicHigh,
        stroke: common.chartColors.diastolic.high,
        strokeStyle: 'dashed',
      },
      main: {
        data: diastolicMain,
        stroke: common.chartColors.diastolic.main,
        strokeStyle: 'solid',
      },
    },
  };
};

const BloodPressureChart = ({ data, thresholds, loading }) => {
  const dates = useSelector((state) => state.patient.dates);

  const isHoveringOverLine = useRef({});
  const [hoveredPoint, setHoveredPoint] = useState(null);
  const [hintType, setHintType] = useState('');
  const yScale = common.chartYaxisScales.bloodPressure;
  if (loading) return <BaseLoader className="loader" loading />;
  const chartData = normalizeChartData(data, thresholds, dates);
  let chartDate = '';

  return (
    <div className="chart" data-cy="bloodPressure-chart">
      <div className="chart-header">
        <div className="chart-header-title" data-cy="bloodPressure-chart-title">
          <p>Blood pressure</p>
          <p>mmHg</p>
        </div>
        <DiscreteColorLegend items={legendItems} orientation="horizontal" />
      </div>
      <div className="chart-main">
        <StickyBloodOrPulseYAxis />
        <XYPlot
          xType="time"
          height={common.chartHeight}
          onMouseLeave={() => {
            setHoveredPoint(null);
          }}
          yDomain={yScale}
          width={common.chartWidth}
        >
          <HorizontalGridLines tickTotal={11} />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => {
              const dayMonth = format(d, 'MMM-dd');
              if (dayMonth !== chartDate) {
                chartDate = dayMonth;
                return format(d, common.dateFormats.chartXDate);
              }
              return '';
            }}
            tickSizeOuter={5}
            tickSizeInner={0}
            tickPadding={24}
          />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => format(d, common.dateFormats.chartXTime)}
            tickTotal={15}
            tickSizeOuter={5}
            tickSizeInner={0}
          />
          <YAxis
            attr="y"
            attrAxis="x"
            orientation="left"
            tickTotal={11}
            className="hidden-axis"
          />
          <LineSeries curve={null} opacity={1} {...chartData.systolic.low} />
          <LineSeries curve={null} opacity={1} {...chartData.systolic.high} />
          {chartData.systolic.main.data[0].y !== undefined && (
            <LineSeries
              curve={null}
              opacity={1}
              {...chartData.systolic.main}
              onSeriesMouseOver={() => {
                isHoveringOverLine.current[0] = true;
              }}
              onSeriesMouseOut={() => {
                isHoveringOverLine.current[0] = false;
              }}
              onNearestXY={(datapoint) => {
                if (isHoveringOverLine.current[0]) {
                  setHoveredPoint(datapoint);
                  setHintType('Systolic');
                }
              }}
            />
          )}
          <LineSeries curve={null} opacity={1} {...chartData.diastolic.low} />
          <LineSeries curve={null} opacity={1} {...chartData.diastolic.high} />
          {chartData.diastolic.main.data[0].y !== undefined && (
            <LineSeries
              curve={null}
              opacity={1}
              {...chartData.diastolic.main}
              onSeriesMouseOver={() => {
                isHoveringOverLine.current[1] = true;
              }}
              onSeriesMouseOut={() => {
                isHoveringOverLine.current[1] = false;
              }}
              onNearestXY={(datapoint) => {
                if (isHoveringOverLine.current[1]) {
                  setHoveredPoint(datapoint);
                  setHintType('Diastolic');
                }
              }}
            />
          )}
          {hoveredPoint && (
            <ChartHint hoveredPoint={hoveredPoint} type={hintType} />
          )}
        </XYPlot>
      </div>
    </div>
  );
};

BloodPressureChart.propTypes = {
  data: PropTypes.array,
  thresholds: PropTypes.shape(),
  loading: PropTypes.bool,
};

export default BloodPressureChart;
