import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import useApi from '../use-api';

import type { ICareAgents, IUpdateCareAgents } from 'infrastructure/interfaces';

const patientCareAgentApi = (patientGuid: string) =>
  `admins/admin-panel/patients/${patientGuid}/care-agents`;

interface IUsePatientCareAgents {
  loading: boolean;
  loadCareAgents: () => Promise<ICareAgents | void>;
  updateCareAgents: (body: IUpdateCareAgents) => Promise<ICareAgents>;
}

interface IUsePatientCareAgentsProps {
  patientGuid: string;
}

export const usePatientCareAgents = (
  props: IUsePatientCareAgentsProps,
): IUsePatientCareAgents => {
  const { patientGuid } = props;

  const { loading, loadData, updateData } = useApi();

  const queryClient = useQueryClient();
  const queryKey = ['patientCareAgents', patientGuid];

  const loadCareAgents = async (): Promise<ICareAgents | void> => {
    const data = await queryClient.fetchQuery(
      queryKey,
      () => loadData<ICareAgents>(patientCareAgentApi(patientGuid)),
      { staleTime: STALE_TIME },
    );
    if (data) return data;
  };

  const updateCareAgents = async (
    body: IUpdateCareAgents,
  ): Promise<ICareAgents> => {
    const data = await updateData<ICareAgents>(
      patientCareAgentApi(patientGuid),
      body,
    );
    queryClient.setQueryData(queryKey, data);
    return data;
  };

  return {
    loading,
    loadCareAgents,
    updateCareAgents,
  };
};
