import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isOnboardingPatient } from 'utils/userTypeHelper';
import { onboardingStatuses } from 'constants/patient_statuses';

import PatientStatus from '../patientsStatusReport/table/status';

const ActivityRow = ({ patient, doctor, clinic }) => (
  <tr className="activity-row">
    <td data-label="Patient GUID">{patient.guid}</td>
    <td data-label="MRN">{patient.mrn}</td>
    <td data-label="Full Name" className="patient-name">
      {isOnboardingPatient(onboardingStatuses, patient.status) ? (
        <strong>{patient.fullName}</strong>
      ) : (
        <Link to={`/patient/${patient.guid}`}>
          <strong>{patient.fullName}</strong>
        </Link>
      )}
    </td>
    <td data-label="DOB">{patient.birthDate}</td>
    <td data-label="Primary phone">{patient.primaryPhone}</td>
    <td data-label="Secondary phone">{patient.secondaryPhone}</td>
    <td data-label="Preferred contact method">
      {patient.preferredContactMethod}
    </td>
    <PatientStatus status={patient.status} />
    <td
      data-label="Interaction status"
      style={{
        backgroundColor: patient.interactionStatus ? '#84E075' : '#a60303',
        color: '#fff',
      }}
    >
      {patient.interactionStatus ? 'True' : 'False'}
    </td>
    <td data-label="Interaction Status Change Date">
      {patient.interactionStatusChangeDate}
    </td>
    <td data-label="Clinic Name">{clinic.name}</td>
    <td data-label="Clinic GUID">{clinic.guid}</td>
    <td data-label="Provider Name">{doctor.fullName} </td>
    <td data-label="Provider GUID">{doctor.guid}</td>
  </tr>
);

ActivityRow.propTypes = {
  patient: PropTypes.object,
  doctor: PropTypes.object,
  clinic: PropTypes.object,
};

export default ActivityRow;
