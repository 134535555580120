import { Formik, Field, Form } from 'formik';
import ModalPopup from 'components/organisms/modal';
import TextArea from 'components/atoms/textarea';
import Button from 'components/atoms/button';
import * as yup from 'yup';

import type { FunctionComponent } from 'react';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  loading?: boolean;
  onSubmit: (data: { reason: string }) => Promise<void>;
};

const schema = yup.object().shape({
  reason: yup.string().required('Required').trim(),
});

const EmailsResubscriptionModal: FunctionComponent<Props> = ({
  isOpen,
  setIsOpen,
  loading,
  onSubmit,
}) => {
  const handleSubmit = async ({ reason }: { reason: string }) => {
    await onSubmit({ reason });
    setIsOpen(false);
  };

  return (
    <ModalPopup
      isOpen={isOpen}
      toggle={setIsOpen}
      title="Resubscribe to Emails"
      className="popup sms-popup"
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{ reason: '' }}
      >
        {({ errors }) => (
          <Form>
            <div className="sms-popup-content">
              <div>
                <Field
                  className="sms-textarea"
                  id="reason"
                  name="reason"
                  type="text"
                  as={TextArea}
                  rows="8"
                  placeholder="How the permission from the patient for re-subscribing was received?"
                />
                {errors.reason ? (
                  <div className="input-error-message">{errors.reason}</div>
                ) : null}
              </div>
            </div>
            <div className="text-center">
              <Button
                type="submit"
                text="Send"
                data-cy="send-button"
                disabled={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </ModalPopup>
  );
};

export default EmailsResubscriptionModal;
