import { Dropdown } from 'antd';
import React, { useState } from 'react';

import s from './styles.module.scss';

import type { PatientControlButtonProps } from '../patentControlButton';
import type { MenuProps } from 'antd';
import type { ReactElement } from 'react';

type Option<T> = {
  id: string;
  label: string;
  value: T;
  disabled?: boolean;
  handleClick?: () => void;
};

interface ControlButtonDropdownProps<TOption extends Option<boolean | string>> {
  options: TOption[];
  toggleBtn: ReactElement<PatientControlButtonProps>;
  handleOptionClick?: (value: TOption['value']) => void;
}

const ControlButtonDropdown = <TOption extends Option<boolean | string>>({
  options,
  toggleBtn,
  handleOptionClick,
}: ControlButtonDropdownProps<TOption>) => {
  const [open, setOpen] = useState(false);
  const items: MenuProps['items'] = options.map((option) => ({
    label: option.label,
    key: option.id,
    disabled: option.disabled,
    'data-cy': 'select-option',
    onClick: () => {
      handleOptionClick?.(option.value);
      option?.handleClick?.();
    },
  }));

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      rootClassName={s.menu}
      getPopupContainer={(trigger) => trigger}
      onOpenChange={setOpen}
    >
      <div onClick={(e) => e.preventDefault()}>
        {toggleBtn && React.isValidElement(toggleBtn)
          ? React.cloneElement(toggleBtn, { active: open })
          : toggleBtn}
      </div>
    </Dropdown>
  );
};
export default ControlButtonDropdown;
