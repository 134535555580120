import React, { useCallback, useState } from 'react';
import BaseAsyncSelectBox from 'components/atoms/base-select-box/async';
import { useAgencyDoctorSelect } from 'infrastructure/hooks/select/use-agency-doctor-select';
import { useTranslation } from 'react-i18next';
import { LoginStatus } from 'infrastructure/enums/login-status';

import s from './styles.module.scss';

import type { AgencyDoctorSelect } from 'infrastructure/classes/select/agency-doctor-select';
import type { IPaginationMeta } from 'infrastructure/interfaces';
import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { Roles } from 'infrastructure/enums';

export type AgencyDoctorSelectOption = IBaseSelectBoxOption<
  string,
  AgencyDoctorSelect
>;

interface IAgencyDoctorSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {
  showInactiveWarning?: boolean;
  agencyGuid?: string;
  roles?: Array<Roles>;
  status?: Array<LoginStatus>;
  onChange?: (value: string, option: AgencyDoctorSelectOption) => void;
  onLoaded?: (
    items: Array<AgencyDoctorSelectOption>,
    selected?: AgencyDoctorSelectOption,
  ) => void;
}

const AgencyDoctorSelectBox: React.FC<IAgencyDoctorSelectBoxProps> = (
  props,
) => {
  const {
    agencyGuid,
    roles,
    status,
    onLoaded,
    onChange,
    showInactiveWarning = true,
  } = props;

  const { t } = useTranslation();

  const { meta, loadAgencyDoctors } = useAgencyDoctorSelect();

  const [isInactive, setIsInactive] = useState<boolean>(false);

  const fetchOptions = useCallback(
    async (search: string, metaProps: IPaginationMeta) => {
      const agencyDoctors = await loadAgencyDoctors({
        ...metaProps,
        search,
        agencyGuid,
        roles,
        status,
      });

      if (agencyDoctors)
        return agencyDoctors.map((el) => {
          return {
            label: el.label,
            value: el.guid,
            record: el,
          };
        });

      return [];
    },
    [agencyGuid, roles, status],
  );

  const onValueLoaded = (
    items: Array<AgencyDoctorSelectOption>,
    selected?: AgencyDoctorSelectOption,
  ) => {
    if (selected?.record?.status === LoginStatus.Inactive) {
      setIsInactive(true);
    } else {
      setIsInactive(false);
    }
    if (onLoaded) onLoaded(items, selected);
  };
  const onValueChange = (value: string, option: AgencyDoctorSelectOption) => {
    if (option?.record?.status === LoginStatus.Inactive) {
      setIsInactive(true);
    } else {
      setIsInactive(false);
    }
    if (onChange) onChange(value, option);
  };

  return (
    <div className={s.wrapper}>
      <BaseAsyncSelectBox
        key={agencyGuid}
        fetchOptions={fetchOptions}
        meta={meta}
        canSearch
        {...props}
        onValueLoaded={onValueLoaded}
        onChange={onValueChange}
      />
      {showInactiveWarning && isInactive && (
        <div className={s.error}>*{t('errors.currentlyInactive')}</div>
      )}
    </div>
  );
};

export default AgencyDoctorSelectBox;
