import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { addQueryParams } from 'utils/queryHelpers';

export type TParseFunctions<T> = {
  [key in keyof T]?: (value: any) => any;
};

type TExcludeCount<T> = (keyof T)[];

export const useQueryFilterNew = <TFilter extends Record<string, any>>(
  defaultFilter: TFilter,
  parseFunctions: TParseFunctions<TFilter>,
  excludeFromCount?: TExcludeCount<TFilter>,
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const parsedQuery = queryString.parse(location.search) as TFilter;
  let filter: TFilter = defaultFilter;

  filter = useMemo(() => {
    const parsedFilter = structuredClone(parsedQuery);
    for (const key in defaultFilter) {
      const parseFn = parseFunctions[key];
      if (parseFn) {
        const newValue =
          parsedQuery[key] !== undefined
            ? parseFn(parsedQuery[key])
            : defaultFilter[key];

        parsedFilter[key] = newValue;
      }
    }
    return parsedFilter;
  }, [location.search]);

  const resetFilter = () => {
    navigate(
      {
        search: queryString.stringify({}),
      },
      {
        replace: true,
      },
    );
    filter = defaultFilter;
  };

  const updateFilter = (newFilter: Partial<TFilter>) => {
    addQueryParams({ navigate, location }, newFilter);
  };

  const countOfChangedFilterField = Object.entries(filter).filter(
    ([k, v]) =>
      Boolean(v) && v !== defaultFilter[k] && !excludeFromCount?.includes(k),
  ).length;

  return {
    count: countOfChangedFilterField,
    filter,
    updateFilter,
    resetFilter,
  };
};
