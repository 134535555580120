import { makeid } from 'utils/helpers/makeid';
import CallControlIcon from 'components/atoms/icons/callControlIcon';
import SubscriptionControlIcon from 'components/atoms/icons/subscriptionControlIcon';
import EHRControlIcon from 'components/atoms/icons/ehrControlIcon';
import { useAppSelector } from 'store';
import { isMobile } from 'utils/deviceHelper';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { useState } from 'react';
import {
  useEmailSubscriptionStatus,
  useUpdatePvrStatus,
  useResetPassword,
  useUpdateInteractionStatus,
} from 'infrastructure/hooks/control-panel';
import useUser from 'utils/useUser';
import { isPatientAcceptance } from 'utils/userTypeHelper';
import FalseMonthlyInteractionIcon from 'components/atoms/icons/falseMontlyInteractionIcon';
import { CcmStatuses } from 'infrastructure/enums';
import { Routes } from 'infrastructure/consts/routes';

interface Props {
  patientGuid: string;
  onIafFormOpen?: () => void;
  iafFormLoading?: boolean;
}

export const useProfileActionButtons = ({
  patientGuid,
  onIafFormOpen,
  iafFormLoading,
}: Props) => {
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [openCcmInteractionModal, setOpenCcmInteractionModal] = useState(false);
  const [openIafModal, setOpenIafModal] = useState(false);
  const device = useAppSelector((state) => state.user.deviceType);
  const { updateInteractionStatus, updateInteractionStatusLoading } =
    useUpdateInteractionStatus();
  const { updatePvrStatus, updatePvrStatusLoading } = useUpdatePvrStatus();
  const { patientInfo } = usePatientInfo({ patientGuid });
  const { updateSubscriptionStatus, subscriptionStatusLoading } =
    useEmailSubscriptionStatus({
      patientGuid,
      onSuccess() {
        setOpenSubscriptionModal(false);
      },
    });
  const { resetPassword, resetPassLoading } = useResetPassword();
  const { togglePatientCcmInteractionStatus: updateLoading } = useAppSelector(
    (state) => state.loading.effects.adminPanelPatients,
  );
  const { roleNames, iamPatientAcceptance } = useUser();

  const interactionStatus = patientInfo?.interactionStatus;
  const ccmInteractionStatus = patientInfo?.ccmInteractionStatus;
  const acceptPvrLiteStatus = patientInfo?.acceptPvrLite;
  const ccmConsentFormStatus = patientInfo?.ccmConsentFormStatus;
  const emailSubscriptionStatus = patientInfo?.emailSubscription;
  const disabledCreateInternalAdmissionForms =
    patientInfo?.disabledCreateInternalAdmissionForms;
  const ccmStatus = patientInfo?.ccmStatus;

  const profileActionButtons = [
    {
      key: makeid(5),
      label: 'Monthly Interaction',
      Icon: interactionStatus ? CallControlIcon : FalseMonthlyInteractionIcon,
      status: interactionStatus ? 'Yes' : 'No',
      loading: updateInteractionStatusLoading,
      handleClick: () => {
        updateInteractionStatus({
          patientGuid,
          isInteracted: !interactionStatus,
        });
      },
      disabled: interactionStatus,
      render: !isMobile(device) && !isPatientAcceptance(roleNames),
      extraAccent: !interactionStatus,
    },
    {
      key: makeid(5),
      label: 'PVR Lite Opt In/Out',
      Icon: CallControlIcon,
      status: acceptPvrLiteStatus ? 'Opted In' : 'Opted out',
      loading: updatePvrStatusLoading,
      handleClick: () => {
        updatePvrStatus({
          patientGuid,
          acceptPvrLite: !acceptPvrLiteStatus,
        });
      },
      disabled: iamPatientAcceptance,
      render: true,
    },
    {
      key: makeid(5),
      label: 'Consent Form',
      Icon: CallControlIcon,
      status: ccmConsentFormStatus ? 'Confirmed' : 'Unconfirmed',
      loading: false,
      handleClick: () => {
        const canPressNo = ccmStatus !== CcmStatuses.Enrolled;
        const newHref = Routes.consentForm(patientGuid);
        window.history.pushState({ canPressNo }, '', newHref);
        window.location.href = newHref;
      },
      disabled: false,
      render: true,
    },
    {
      key: makeid(5),
      label: 'Reset Password',
      Icon: CallControlIcon,
      loading: resetPassLoading,
      handleClick: () => {
        if (patientInfo?.loginInfo?.email)
          resetPassword({ email: patientInfo.loginInfo.email });
      },
      disabled: iamPatientAcceptance,
      render: true,
    },
    {
      key: makeid(5),
      label: 'Email Subscription',
      Icon: SubscriptionControlIcon,
      status: emailSubscriptionStatus ? 'Subscribed' : 'Unsubscribed',
      loading: subscriptionStatusLoading,
      handleClick: () => {
        setOpenSubscriptionModal(true);
      },
      disabled: emailSubscriptionStatus || iamPatientAcceptance,
      render: true,
    },
    {
      key: makeid(5),
      label: 'Follow-up Appointment Complete',
      Icon: ccmInteractionStatus
        ? CallControlIcon
        : FalseMonthlyInteractionIcon,
      status: ccmInteractionStatus ? 'Yes' : 'No',
      loading: updateLoading,
      handleClick: () => {
        setOpenCcmInteractionModal(true);
      },
      disabled: ccmInteractionStatus || iamPatientAcceptance,
      render: !isMobile(device),
      extraAccent: !ccmInteractionStatus,
    },
    {
      key: makeid(5),
      label: 'IAF Form',
      Icon: EHRControlIcon,
      loading: iafFormLoading,
      handleClick: () => {
        setOpenIafModal(true);
        onIafFormOpen?.();
      },
      disabled: disabledCreateInternalAdmissionForms,
      render: !isMobile(device),
    },
  ].filter(({ render }) => render);

  return {
    profileActionButtons,
    interactionStatus,
    acceptPvrLiteStatus,
    openSubscriptionModal,
    subscriptionStatusLoading,
    openCcmInteractionModal,
    openIafModal,
    updateSubscriptionStatus,
    setOpenSubscriptionModal,
    setOpenCcmInteractionModal,
    setOpenIafModal,
  };
};
