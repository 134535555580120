import classNames from 'classnames';
import { getActivityStatus } from 'utils';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import s from './styles.module.scss';
import Countdown from '../countdown';
import StopSlaButton from '../stop-btn';

import type {
  TRpmDashboardActivityQueryKey,
  TRpmPatientActivityQueryKey,
} from 'infrastructure/hooks/rpm';
import type { TServiceDeliveryThresholds } from 'infrastructure/interfaces/i-rpm-patient-activity';
import type { ReadingStatus } from 'infrastructure/enums/reading-statuses';
import type { FC } from 'react';

interface DateColumnProps {
  globalStatus: ReadingStatus;
  idx: number;
  createdAt: string;
  view: 'patient' | 'dashboard';
  deviceId: string;
  readingId: string;
  getActivityQueryKey:
    | TRpmDashboardActivityQueryKey
    | TRpmPatientActivityQueryKey;
  slaDeadline?: string;
  slaReviewedTime?: string;
  serviceDeliveryThresholds?: TServiceDeliveryThresholds;
  height?: number;
  hideCommentsColumn: boolean;
}

const DateColumn: FC<DateColumnProps> = ({
  globalStatus,
  idx,
  createdAt,
  view,
  slaDeadline,
  slaReviewedTime,
  serviceDeliveryThresholds,
  height = 240,
  readingId,
  deviceId,
  getActivityQueryKey,
  hideCommentsColumn,
}) => {
  const location = useLocation();
  const { slaReadings } = queryString.parse(location.search);
  const [_, { page, items }] = getActivityQueryKey;

  const status = slaReadings === 'true' ? 'slaReading' : globalStatus;
  const showCountdown =
    view === 'dashboard' &&
    slaReadings === 'true' &&
    slaDeadline &&
    serviceDeliveryThresholds;
  const currentHeight = hideCommentsColumn ? 180 : height;
  const currentPage = page ?? 1;
  const itemIndexPrefix = (currentPage - 1) * (items ?? 100);

  return (
    <div className={s['date-cell']} style={{ height: currentHeight }}>
      <div
        className={classNames(s['date-wrapper'], {
          [s['p-t-26']]: view === 'dashboard',
          [s['p-t-24']]: hideCommentsColumn,
        })}
      >
        <p
          data-cy="reading-status"
          className={classNames(s[`status-${status}`], s.status)}
          style={{ marginBottom: hideCommentsColumn ? 16 : 25 }}
        >
          {getActivityStatus(status)}
        </p>
        <p data-cy="reading-number" className={s['item-number']}>
          #{itemIndexPrefix + idx + 1}
        </p>
        <p data-cy="reading-date" className={s.date}>
          {DateFormatter({
            date: new Date(parseInt(createdAt)),
            format: DateFormats['MM-dd-yyyy hh:mm:ss a'],
          })}
        </p>
        {showCountdown && (
          <>
            <Countdown
              slaDeadline={slaDeadline}
              slaReviewedTime={slaReviewedTime}
              serviceDeliveryThresholds={serviceDeliveryThresholds}
            />
            {!slaReviewedTime && (
              <StopSlaButton
                deviceId={deviceId}
                readingId={readingId}
                getActivityQueryKey={getActivityQueryKey}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default DateColumn;
