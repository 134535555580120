import { useTranslation } from 'react-i18next';
import { useStopSlaTimer } from 'infrastructure/hooks/rpm';
import ButtonLoadingIcon from 'components/atoms/icons/buttonLoadingIcon';
import StopIcon from 'components/atoms/icons/stop.svg?react';

import s from './style.module.scss';

import type { FC } from 'react';
import type { QueryKey } from '@tanstack/react-query';

interface StopSlaButtonProps {
  deviceId: string;
  readingId: string;
  getActivityQueryKey: QueryKey;
}

const StopSlaButton: FC<StopSlaButtonProps> = ({
  getActivityQueryKey,
  deviceId,
  readingId,
}) => {
  const { stopSlaTimer, isLoading } = useStopSlaTimer({ getActivityQueryKey });
  const { t } = useTranslation();

  return (
    <button
      data-cy="reading-priority-stop-button"
      className={s['stop-btn']}
      disabled={isLoading}
      onClick={() => stopSlaTimer({ deviceId, readingId })}
    >
      {isLoading ? (
        <ButtonLoadingIcon className={s['spin-loading-icon']} />
      ) : (
        <StopIcon />
      )}
      {t('controls.stop')}
    </button>
  );
};
export default StopSlaButton;
