import React from 'react';

const QuestionnaireIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 0C14.6642 0 15 0.335787 15 0.75C15 1.16421 15.3358 1.5 15.75 1.5C16.1642 1.5 16.5 1.83579 16.5 2.25L16.5 3C16.5 3.41421 16.1642 3.75 15.75 3.75L8.25 3.75C7.83579 3.75 7.5 3.41421 7.5 3L7.5 2.25C7.5 1.83579 7.83579 1.5 8.25 1.5C8.66421 1.5 9 1.16421 9 0.75C9 0.335787 9.33579 0 9.75 0L14.25 0Z"
      fill="currentColor"
    />
    <path
      d="M4.5 3.75C4.5 3.33579 4.83579 3 5.25 3H6C6.41421 3 6.75 2.66421 6.75 2.25C6.75 1.83579 6.41421 1.5 6 1.5H5.25C4.00736 1.5 3 2.50736 3 3.75L3 21.75C3 22.9926 4.00736 24 5.25 24H18.75C19.9926 24 21 22.9926 21 21.75L21 3.75C21 2.50736 19.9926 1.5 18.75 1.5H18C17.5858 1.5 17.25 1.83579 17.25 2.25C17.25 2.66421 17.5858 3 18 3H18.75C19.1642 3 19.5 3.33579 19.5 3.75L19.5 21.75C19.5 22.1642 19.1642 22.5 18.75 22.5H5.25C4.83579 22.5 4.5 22.1642 4.5 21.75L4.5 3.75Z"
      fill="currentColor"
    />
    <path
      d="M11.9969 9.06882C11.1879 9.06942 10.766 9.31958 10.5434 9.55644C10.3092 9.80646 10.239 10.0992 10.2392 10.2598C10.2393 10.4175 10.1776 10.5688 10.0677 10.6805C9.95793 10.7921 9.80884 10.8549 9.65342 10.855C9.49792 10.8551 9.34874 10.7926 9.23875 10.6811C9.12877 10.5696 9.06683 10.4184 9.06672 10.2607C9.06639 9.82642 9.23014 9.22672 9.69868 8.73267C10.179 8.22433 10.9291 7.87996 11.996 7.87916C13.0629 7.87837 13.8135 8.22281 14.2946 8.72924C14.7639 9.2226 14.9285 9.82205 14.9288 10.2563C14.9298 11.5019 14.0743 12.0985 13.4333 12.4202C13.2754 12.501 13.1208 12.5696 12.9866 12.629L12.9739 12.6347L12.961 12.6406C12.8238 12.7002 12.7171 12.7467 12.6292 12.792L12.5859 12.8158L12.5866 13.827C12.5867 13.9847 12.5251 14.1361 12.4153 14.2477C12.3054 14.3593 12.1564 14.4222 12.0009 14.4223C11.8454 14.4224 11.6963 14.3598 11.5862 14.2483C11.4762 14.1368 11.4144 13.9856 11.4143 13.8279L11.4134 12.6382C11.4148 12.5179 11.4408 12.3992 11.4897 12.2896C11.5387 12.18 11.6095 12.0819 11.6978 12.0015C11.8191 11.8919 11.9552 11.8005 12.1021 11.73C12.2267 11.6673 12.3533 11.6088 12.4818 11.5549L12.5075 11.5429C12.6432 11.4846 12.7772 11.4226 12.9096 11.357C13.4405 11.0878 13.7568 10.7972 13.7564 10.2572C13.7563 10.0965 13.6857 9.80395 13.4511 9.55427C13.2281 9.31891 12.8059 9.06821 11.9969 9.06882Z"
      fill="currentColor"
    />
    <path
      d="M12.6247 17.1346C12.4599 17.302 12.2363 17.3962 12.0031 17.3964C11.7699 17.3966 11.5462 17.3027 11.3811 17.1355C11.2161 16.9683 11.1233 16.7414 11.1231 16.5048C11.1229 16.2681 11.2154 16.0412 11.3802 15.8737C11.545 15.7062 11.7686 15.6121 12.0018 15.6119C12.235 15.6117 12.4587 15.7056 12.6237 15.8728C12.7888 16.04 12.8816 16.2668 12.8817 16.5035C12.8819 16.7401 12.7895 16.9671 12.6247 17.1346Z"
      fill="currentColor"
    />
  </svg>
);

export default QuestionnaireIcon;
