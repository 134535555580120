import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import { useTranslation } from 'react-i18next';
import BaseButton from 'components/atoms/baseButton';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import ArrowRightIcon from 'components/atoms/icons/arrow-right';
import { type FC } from 'react';
import Calendar4Icon from 'components/atoms/icons/calendar4Icon';
import { isAfter } from 'date-fns';

import s from './styles.module.scss';

import type { IItemBodyProps } from './types';

const ItemBody: FC<IItemBodyProps> = ({
  mode,
  values,
  editModDates,
  changeTimeRange,
  setMode,
  handleRemove,
  handleSave,
  loading,
}) => {
  const { isEnabled, isDefault, isEdit, isRemoved } = mode;
  const { t } = useTranslation();

  const startTimeFormatted = DateFormatter({
    date: values.dateRange?.at(0),
    format: DateFormats['MM-dd-yyyy'],
  });

  const endTimeFormatted = DateFormatter({
    date: values.dateRange?.at(1),
    format: DateFormats['MM-dd-yyyy'],
  });

  return (
    <div className={s.body}>
      <div className={s['body-dates']} data-cy="date-range-inputs">
        {(isDefault || isRemoved || isEdit) && (
          <BaseRangeDatepicker
            onChange={(dates) => changeTimeRange(dates)}
            defaultValue={editModDates || values.dateRange}
            disabledDate={(current, { from }) => {
              if (from) {
                return current && !isAfter(current, new Date());
              }
              return false;
            }}
          />
        )}
        {isEnabled && (
          <>
            <span className={s.calendar}>
              <Calendar4Icon />
            </span>
            <span>{startTimeFormatted}</span>
            <ArrowRightIcon />
            <span>{endTimeFormatted}</span>
          </>
        )}
      </div>
      <div className={s.divider} />
      <div className={s['body-controls']}>
        {(isDefault || isRemoved) && (
          <BaseButton
            dataCy="apply-button"
            width="170px"
            label={t('controls.apply')}
            onClick={() => setMode('enabled')}
            key={t('controls.addStatus')}
            type="secondary"
            disabled={!(values.holdingStatus !== 'active' && values.dateRange)}
          />
        )}

        {isEnabled && (
          <>
            <BaseButton
              dataCy="edit-button"
              width="84px"
              label={t('controls.edit')}
              onClick={() => {
                setMode('edit');
              }}
              key={t('controls.edit')}
              type="secondary"
              loading={loading}
            />
            <BaseButton
              dataCy="remove-Button"
              width="84px"
              label={t('controls.remove')}
              onClick={handleRemove}
              key={t('controls.remove')}
              type="light-danger"
              loading={loading}
            />
          </>
        )}

        {isEdit && (
          <>
            <BaseButton
              width="84px"
              label={t('controls.apply')}
              onClick={handleSave}
              key={t('controls.addStatus')}
              type="primary"
              disabled={!editModDates}
            />
            <BaseButton
              width="84px"
              label={t('controls.cancel')}
              onClick={() => {
                setMode('enabled');
                changeTimeRange(undefined);
              }}
              key={t('controls.addStatus')}
              type="secondary"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ItemBody;
