import { useEffect, useState } from 'react';
import { showResult } from 'infrastructure/hooks/utils';

export default function useFetch(
  fetch,
  { initialRequest } = {
    initialRequest: true,
  },
) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const doRequest = async () => {
    try {
      setLoading(true);
      const { data: response, error } = await fetch(page);
      if (!error) setData(response);
      else showResult(error);
    } finally {
      setLoading(false);
    }
  };

  const refresh = () => {
    doRequest(page);
  };

  useEffect(() => {
    if (initialRequest) {
      doRequest();
    }
  }, [page]);

  return {
    data,
    loading,
    page,
    setPage,
    refresh,
  };
}
