import type { IIconProps } from 'infrastructure/interfaces';

const ChatEmptyIcon = ({ ...props }: IIconProps) => (
  <svg
    width="350"
    height="350"
    viewBox="0 0 350 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8638_153564)">
      <path
        d="M224.97 174.461C252.579 146.851 252.579 102.087 224.97 74.4768C197.36 46.867 152.595 46.867 124.986 74.4768C97.3758 102.087 97.3758 146.851 124.986 174.461C152.595 202.071 197.36 202.071 224.97 174.461Z"
        fill="#FB9318"
      />
      <path
        d="M250.11 267.26H96.7399C94.4999 267.26 92.6099 265.44 92.6099 263.13C92.6099 260.89 94.4299 259 96.7399 259H250.11C252.35 259 254.24 260.82 254.24 263.13C254.17 265.44 252.35 267.26 250.11 267.26Z"
        fill="#FFF4E8"
      />
      <path
        d="M68.7396 88.0595L69.9996 88.4795C70.5596 88.6895 70.5596 89.5295 69.9996 89.6695L68.7396 90.0895C67.1296 90.5795 65.8696 91.8395 65.3796 93.4495L64.9596 94.7095C64.7496 95.2695 63.9096 95.2695 63.7696 94.7095L63.3496 93.4495C62.8596 91.8395 61.5996 90.5795 59.9896 90.0895L58.7296 89.6695C58.1696 89.4595 58.1696 88.6195 58.7296 88.4795L59.9896 88.0595C61.5996 87.5695 62.8596 86.3095 63.3496 84.6995L63.7696 83.4395C63.9796 82.8795 64.8196 82.8795 64.9596 83.4395L65.3796 84.6995C65.8696 86.3095 67.1296 87.5695 68.7396 88.0595Z"
        fill="white"
      />
      <path
        d="M251.37 80.8496L252.63 81.2696C253.19 81.4796 253.19 82.3196 252.63 82.4596L251.37 82.8796C249.76 83.3696 248.5 84.6296 248.01 86.2396L247.59 87.4996C247.38 88.0596 246.54 88.0596 246.4 87.4996L245.98 86.2396C245.49 84.6296 244.23 83.3696 242.62 82.8796L241.36 82.4596C240.8 82.2496 240.8 81.4096 241.36 81.2696L242.62 80.8496C244.23 80.3596 245.49 79.0996 245.98 77.4896L246.4 76.2296C246.61 75.6696 247.45 75.6696 247.59 76.2296L248.01 77.4896C248.57 79.0996 249.76 80.2896 251.37 80.8496Z"
        fill="white"
      />
      <path
        d="M334.6 178.219L335.37 178.429C335.72 178.569 335.72 178.989 335.37 179.129L334.6 179.339C333.69 179.619 332.92 180.389 332.64 181.299L332.43 182.069C332.29 182.419 331.87 182.419 331.73 182.069L331.52 181.299C331.24 180.389 330.47 179.619 329.56 179.339L328.79 179.129C328.44 178.989 328.44 178.569 328.79 178.429L329.56 178.219C330.47 177.939 331.24 177.169 331.52 176.259L331.73 175.489C331.87 175.139 332.29 175.139 332.43 175.489L332.64 176.259C332.92 177.169 333.62 177.939 334.6 178.219Z"
        fill="white"
      />
      <path
        d="M41.7898 227.43L42.5598 227.64C42.9098 227.78 42.9098 228.2 42.5598 228.34L41.7898 228.55C40.8798 228.83 40.1098 229.6 39.8298 230.51L39.6198 231.28C39.4798 231.63 39.0598 231.63 38.9198 231.28L38.7098 230.51C38.4298 229.6 37.6598 228.83 36.7498 228.55L35.9798 228.34C35.6298 228.2 35.6298 227.78 35.9798 227.64L36.7498 227.43C37.6598 227.15 38.4298 226.38 38.7098 225.47L38.9198 224.7C39.0598 224.35 39.4798 224.35 39.6198 224.7L39.8298 225.47C40.1798 226.38 40.8798 227.15 41.7898 227.43Z"
        fill="white"
      />
      <path
        d="M262.57 221.06H84.28C81.13 221.06 78.54 218.47 78.54 215.32V99.4002C78.54 96.2502 81.13 93.6602 84.28 93.6602H262.57C265.72 93.6602 268.31 96.2502 268.31 99.4002V215.32C268.31 218.47 265.72 221.06 262.57 221.06Z"
        fill="#2E344B"
      />
      <path
        d="M259.63 214.97H87.15C85.54 214.97 84.21 213.64 84.21 212.03V102.69C84.21 101.08 85.54 99.75 87.15 99.75H259.63C261.24 99.75 262.57 101.08 262.57 102.69V211.96C262.64 213.64 261.31 214.97 259.63 214.97Z"
        fill="#FFF8F3"
      />
      <path
        d="M209.02 133.91H172.69C171.15 133.91 169.68 133.07 168.91 131.74L166.46 127.47C165.9 126.49 164.92 125.93 163.8 125.93H142.31C140.63 125.93 139.23 127.33 139.23 129.01V138.04V140.14V177.31C139.23 179.55 141.05 181.37 143.29 181.37H208.95C211.19 181.37 213.01 179.55 213.01 177.31V138.11C213.08 135.73 211.26 133.91 209.02 133.91Z"
        fill="#231E36"
      />
      <path
        d="M200.27 190.54H137.97C134.75 190.54 132.16 187.95 132.16 184.73V148.89C132.16 145.67 134.75 143.08 137.97 143.08H200.27C203.49 143.08 206.08 145.67 206.08 148.89V184.73C206.01 187.95 203.42 190.54 200.27 190.54Z"
        fill="#9692A1"
      />
      <path
        d="M201.95 190.54H208.53C211.05 190.54 213.08 188.51 213.08 185.99V176.26C213.08 176.26 212.45 180.6 209.51 180.6C206.57 180.6 206.01 178.36 206.01 176.47L201.95 190.54Z"
        fill="#9692A1"
      />
      <path
        d="M259.84 99.75H87.01C85.47 99.75 84.21 101.01 84.21 102.55V106.26H262.64V102.55C262.64 101.01 261.38 99.75 259.84 99.75Z"
        fill="#8EADD5"
      />
      <path
        d="M253.82 104.58H257.88C258.37 104.58 258.79 104.16 258.79 103.67V102.13C258.79 101.64 258.37 101.22 257.88 101.22H253.82C253.33 101.22 252.91 101.64 252.91 102.13V103.67C252.91 104.16 253.33 104.58 253.82 104.58Z"
        fill="white"
      />
      <path
        d="M245.7 104.089H249.76C250.25 104.089 250.67 103.669 250.67 103.179V102.689C250.67 102.199 250.25 101.779 249.76 101.779H245.7C245.21 101.779 244.79 102.199 244.79 102.689V103.179C244.72 103.669 245.14 104.089 245.7 104.089Z"
        fill="white"
      />
      <path
        d="M238.07 104.089H242.13C242.62 104.089 243.04 103.669 243.04 103.179V102.689C243.04 102.199 242.62 101.779 242.13 101.779H238.07C237.58 101.779 237.16 102.199 237.16 102.689V103.179C237.09 103.669 237.51 104.089 238.07 104.089Z"
        fill="white"
      />
      <path
        d="M230.44 104.089H234.5C234.99 104.089 235.41 103.669 235.41 103.179V102.689C235.41 102.199 234.99 101.779 234.5 101.779H230.44C229.95 101.779 229.53 102.199 229.53 102.689V103.179C229.46 103.669 229.88 104.089 230.44 104.089Z"
        fill="white"
      />
      <path
        d="M89.2498 104.161H93.3098C93.7998 104.161 94.2199 103.741 94.2199 103.251V102.761C94.2199 102.271 93.7998 101.851 93.3098 101.851H89.2498C88.7598 101.851 88.3398 102.271 88.3398 102.761V103.251C88.3398 103.741 88.7598 104.161 89.2498 104.161Z"
        fill="white"
      />
      <path
        d="M96.9499 104.161H101.01C101.5 104.161 101.92 103.741 101.92 103.251V102.761C101.92 102.271 101.5 101.851 101.01 101.851H96.9499C96.4599 101.851 96.0399 102.271 96.0399 102.761V103.251C95.9699 103.741 96.3899 104.161 96.9499 104.161Z"
        fill="white"
      />
      <path
        d="M208.04 213.43C204.4 210.28 198.45 211.05 194.74 214.2C194.46 214.48 194.11 214.76 193.83 215.04H259.77C261.31 215.04 262.57 213.78 262.57 212.24V183.05C257.74 184.17 253.4 187.11 251.44 191.66C248.64 188.93 243.25 189.77 241.5 193.27C236.46 190.19 229.67 190.26 224.77 193.55C219.87 196.77 217.14 203 217.98 208.81C214.27 206.78 208.95 209.23 208.04 213.43Z"
        fill="white"
      />
      <path
        d="M151.76 212.52C148.47 210.56 143.85 210.49 141.05 213.15C139.65 207.62 133.14 204.05 127.75 205.87C124.25 200.55 115.85 199.29 111.02 203.42C109.9 199.78 105.07 197.82 101.71 199.64C100.38 191.45 92.3301 185.22 84.1401 185.08V212.17C84.1401 213.71 85.4001 214.97 86.9401 214.97H154.7C153.86 213.99 152.88 213.15 151.76 212.52Z"
        fill="white"
      />
      <path d="M195.72 221.061H151.13V253.541H195.72V221.061Z" fill="#6B667B" />
      <path
        d="M242.97 263.2H102.97C100.31 263.2 98.1401 261.03 98.1401 258.37C98.1401 255.71 100.31 253.54 102.97 253.54H242.97C245.63 253.54 247.8 255.71 247.8 258.37C247.8 261.03 245.63 263.2 242.97 263.2Z"
        fill="#2E344B"
      />
      <path d="M195.72 221.061L151.13 227.011V221.061H195.72Z" fill="#2E344B" />
      <path
        d="M301.84 265.509C309.958 265.509 316.54 263.88 316.54 261.87C316.54 259.859 309.958 258.229 301.84 258.229C293.721 258.229 287.14 259.859 287.14 261.87C287.14 263.88 293.721 265.509 301.84 265.509Z"
        fill="#FFF4E8"
      />
      <path
        d="M307.02 198.73C308 195.93 309.33 193.27 310.87 190.75C312.2 188.65 313.74 186.55 315.77 185.15C316.75 184.45 318.08 183.96 319.13 184.59C319.76 185.01 320.04 185.78 320.11 186.48C320.32 188.58 318.92 190.54 317.59 192.22C314.3 196.42 311.22 200.83 308.91 205.66C306.53 210.49 304.99 215.67 304.85 221.06C304.85 222.46 305.06 224.56 304.15 225.68C302.96 224.63 303.59 221.76 303.66 220.36C303.73 217.91 303.87 215.39 304.15 212.94C304.5 208.04 305.34 203.28 307.02 198.73Z"
        fill="#2E344B"
      />
      <path
        d="M300.159 196.281C299.389 193.411 298.339 190.611 297.009 187.951C295.889 185.711 294.559 183.541 292.599 181.931C291.689 181.161 290.359 180.531 289.309 181.091C288.679 181.441 288.329 182.211 288.189 182.911C287.839 185.011 289.099 187.041 290.219 188.861C293.089 193.341 295.819 197.961 297.779 203.001C299.739 207.971 300.859 213.361 300.509 218.681C300.439 220.081 300.019 222.111 300.789 223.371C302.049 222.391 301.699 219.521 301.769 218.051C301.909 215.601 301.979 213.081 301.909 210.631C301.839 205.801 301.419 200.971 300.159 196.281Z"
        fill="#2E344B"
      />
      <path
        d="M292.67 216.3C290.71 214.55 288.61 213.01 286.37 211.68C284.48 210.56 282.45 209.65 280.28 209.37C279.23 209.23 277.97 209.37 277.48 210.35C277.2 210.91 277.27 211.61 277.48 212.24C278.25 213.99 280.07 214.83 281.82 215.6C286.09 217.49 290.29 219.66 294.14 222.39C297.92 225.12 301.35 228.55 303.66 232.61C304.29 233.66 304.92 235.41 306.11 235.9C306.6 234.57 304.92 232.61 304.29 231.56C303.17 229.67 302.05 227.85 300.86 226.03C298.41 222.53 295.82 219.17 292.67 216.3Z"
        fill="#2E344B"
      />
      <path
        d="M311.149 217.14C313.109 215.39 315.209 213.85 317.449 212.52C319.339 211.4 321.369 210.49 323.539 210.21C324.589 210.07 325.849 210.21 326.339 211.19C326.619 211.75 326.549 212.45 326.339 213.08C325.569 214.83 323.749 215.67 321.999 216.44C317.729 218.33 313.529 220.5 309.679 223.23C305.829 225.96 302.469 229.39 300.159 233.45C299.529 234.5 298.899 236.25 297.709 236.74C297.219 235.41 298.899 233.45 299.529 232.4C300.649 230.51 301.769 228.69 302.959 226.87C305.339 223.3 307.999 219.94 311.149 217.14Z"
        fill="#2E344B"
      />
      <path
        d="M299.53 190.961C299.39 184.801 299.53 178.431 302.33 172.971C303.73 170.311 305.83 167.861 308.63 166.811C311.43 165.761 315.07 166.531 316.68 169.051C317.87 170.871 317.87 173.251 317.45 175.351C316.54 180.041 313.88 184.171 311.57 188.441C309.05 193.131 306.88 198.031 305.62 203.211C304.29 208.601 303.94 214.271 304.01 219.801C304.08 224.491 305.41 229.741 304.92 234.361C301.21 230.58 301.56 221.34 301.07 216.511C300.23 208.041 299.74 199.501 299.53 190.961Z"
        fill="#4F6E59"
      />
      <path
        d="M318.08 193.06C320.39 190.26 323.05 187.53 326.48 186.41C327.95 185.92 329.7 185.78 330.89 186.83C332.15 187.95 332.15 189.91 331.8 191.59C330.47 198.17 325.57 203.35 320.95 208.25C317.87 211.47 314.79 214.69 311.71 217.91C308.63 221.13 305.34 224.7 304.64 229.18C304.29 231.28 304.64 233.59 303.73 235.55C302.54 229.04 304.78 220.71 306.74 214.48C309.12 206.64 312.97 199.36 318.08 193.06Z"
        fill="#4F6E59"
      />
      <path
        d="M297.429 207.97C295.189 199.57 292.599 190.54 285.949 185.01C284.619 183.89 282.799 182.84 281.119 183.54C279.019 184.38 278.669 187.18 278.949 189.42C279.439 192.99 280.699 196.35 282.169 199.64C284.129 203.98 286.509 208.18 289.169 212.17C293.089 217.84 297.779 223.16 299.739 229.74C300.089 230.79 301.069 237.3 302.469 236.74C303.659 236.32 303.239 231.56 303.169 230.72C302.539 223.09 299.389 215.25 297.429 207.97Z"
        fill="#4F6E59"
      />
      <path
        d="M313.81 261.871H289.94L287.91 227.011H315.84L313.81 261.871Z"
        fill="#6B667B"
      />
      <path
        d="M315.28 235.97C314.72 235.76 314.16 235.62 313.6 235.55C312.13 235.27 310.87 235.55 309.47 235.97C308.7 236.25 307.93 236.46 307.09 236.39C306.32 236.32 305.62 236.04 304.92 235.76C303.66 235.27 302.47 234.71 301.07 234.78C298.34 234.85 296.1 237.16 293.3 236.25C292.04 235.83 290.85 235.2 289.52 235.27C289.1 235.27 288.75 235.41 288.33 235.48L288.4 236.18C289.1 235.97 289.8 235.83 290.5 236.04C291.69 236.32 292.74 237.16 293.93 237.37C296.52 237.86 298.55 235.83 301.07 235.76C303.66 235.69 305.76 237.79 308.42 237.3C309.96 237.02 311.22 236.32 312.83 236.46C313.67 236.53 314.44 236.74 315.14 237.02L315.28 235.97Z"
        fill="white"
      />
      <path
        d="M288.26 233.03C289.03 232.75 289.8 232.54 290.71 232.68C292.18 232.89 293.44 233.73 294.91 233.94C296.31 234.15 297.43 233.66 298.69 233.03C300.09 232.33 301.35 232.26 302.89 232.61C305.41 233.17 307.93 234.43 310.59 233.73C312.13 233.31 313.39 232.61 315 232.75C315.21 232.75 315.35 232.82 315.56 232.82L315.63 231.84C314.86 231.7 314.16 231.7 313.46 231.77C312.2 231.91 311.15 232.54 309.96 232.75C308.77 232.96 307.51 232.75 306.39 232.4C304.36 231.84 302.19 231 300.09 231.42C298.83 231.7 297.85 232.54 296.66 232.89C295.33 233.24 294.07 232.68 292.81 232.26C291.55 231.84 289.8 231.56 288.33 231.98L288.26 233.03Z"
        fill="white"
      />
      <path
        d="M60.3399 283.22C72.7111 283.22 82.7399 280.932 82.7399 278.11C82.7399 275.288 72.7111 273 60.3399 273C47.9688 273 37.9399 275.288 37.9399 278.11C37.9399 280.932 47.9688 283.22 60.3399 283.22Z"
        fill="#FFF4E8"
      />
      <path
        d="M45.64 239.96H77.21C77.84 239.96 78.4 240.45 78.4 241.15V275.24C78.4 277.34 76.65 279.09 74.55 279.09H48.37C46.27 279.09 44.52 277.34 44.52 275.24V241.15C44.52 240.45 45.01 239.96 45.64 239.96Z"
        fill="#2C2543"
      />
      <path
        d="M49.9099 246.19H37.8699C35.1399 246.19 32.8999 248.43 32.8999 251.16V266.84C32.8999 269.57 35.1399 271.81 37.8699 271.81H49.9099C52.6399 271.81 54.8799 269.57 54.8799 266.84V251.16C54.8799 248.43 52.6399 246.19 49.9099 246.19ZM49.9099 264.25C49.9099 265.65 48.7899 266.77 47.3899 266.77H40.3899C38.9899 266.77 37.8699 265.65 37.8699 264.25V253.75C37.8699 252.35 38.9899 251.23 40.3899 251.23H47.3899C48.7899 251.23 49.9099 252.35 49.9099 253.75V264.25Z"
        fill="#2C2543"
      />
      <path d="M44.5201 246.19V251.23H43.3301L44.5201 246.19Z" fill="#2C2543" />
      <path d="M44.5201 266.77V271.81H43.3301L44.5201 266.77Z" fill="#2C2543" />
      <path
        d="M308.07 294.77C311.08 288.54 309.12 278.04 296.38 274.96C296.03 274.89 295.68 274.82 295.33 274.75C294.91 274.68 294.49 274.54 294 274.47C293.93 274.47 293.86 274.47 293.86 274.47C293.79 274.47 293.79 274.47 293.72 274.47C288.26 273.63 283.99 273.91 280.7 274.89C273.42 277.06 271.04 282.73 271.67 287.98C271.67 288.26 271.74 288.54 271.81 288.82C271.95 289.59 272.16 290.29 272.37 291.06C275.24 299.25 296.59 302.4 304.08 299.04C305.48 298.41 306.67 297.29 307.58 295.82C307.72 295.47 307.93 295.12 308.07 294.77Z"
        fill="#FFF4E8"
      />
      <path
        d="M295.68 271.39C275.87 268.31 271.6 279.58 274.4 287.63C277.2 295.68 298.13 298.76 305.55 295.47C312.97 292.18 314.51 274.33 295.68 271.39Z"
        fill="white"
      />
      <path
        d="M295.68 271.39C295.61 271.39 295.47 271.39 295.4 271.32C295.4 271.32 295.4 271.32 295.33 271.32C293.65 271.95 292.11 272.72 290.57 273.7C289.73 273.14 288.68 272.72 287.77 272.37C286.72 271.95 285.67 271.67 284.55 271.53C284.34 271.46 284.13 271.46 283.85 271.46C282.94 271.67 282.1 271.88 281.33 272.23C282.31 272.3 283.36 272.44 284.34 272.65C285.32 272.86 286.3 273.14 287.28 273.49C288.05 273.77 288.75 274.19 289.45 274.54C288.96 274.89 288.4 275.31 287.91 275.73C285.81 277.48 283.99 279.65 282.59 281.96C281.96 283.08 281.33 284.27 280.84 285.46C280.35 286.72 279.79 288.05 279.79 289.45C279.79 289.73 280.14 289.8 280.28 289.59C280.91 288.47 281.19 287.21 281.75 286.02C282.24 284.83 282.87 283.71 283.57 282.59C284.97 280.42 286.65 278.53 288.54 276.85C290.57 275.1 293.02 273.7 295.54 272.72C296.31 272.44 297.08 272.16 297.85 271.95C297.29 271.67 296.52 271.53 295.68 271.39Z"
        fill="#B9CFE6"
      />
      <path
        d="M308.98 292.32C309.19 292.04 309.33 291.69 309.47 291.34C304.99 292.95 300.02 293.37 295.33 293.44C288.82 293.58 282.31 292.25 277.13 287.98C275.94 287 274.82 285.88 273.84 284.69C273.77 284.62 273.77 284.55 273.7 284.55C273.77 285.11 273.84 285.67 273.98 286.23C274.47 286.86 275.03 287.42 275.59 287.91C277.83 290.01 280.49 291.62 283.29 292.67C289.73 295.12 297.29 294.84 303.94 293.58C305.69 293.3 307.37 292.88 308.98 292.32Z"
        fill="#B9CFE6"
      />
      <path
        d="M243.67 283.709C243.39 283.359 243.04 283.149 242.62 283.149H99.3999C98.9799 283.149 98.5599 283.359 98.3499 283.709L85.1899 302.259V304.289C85.1899 304.779 85.6099 305.199 86.0999 305.199H255.92C256.41 305.199 256.83 304.779 256.83 304.289V302.259L243.67 283.709Z"
        fill="#FFF4E8"
      />
      <path
        d="M257.95 299.95H86.2397L99.3997 281.4C99.6797 281.05 100.03 280.84 100.45 280.84H243.67C244.09 280.84 244.51 281.05 244.72 281.4L257.95 299.95Z"
        fill="#B9CFE6"
      />
      <path
        d="M253.61 298.27H90.8599L101.5 282.45H242.69L253.61 298.27Z"
        fill="white"
      />
      <path
        d="M87.2197 302.891H256.97C257.53 302.891 257.95 302.471 257.95 301.911V299.881H86.2397V301.911C86.2397 302.471 86.6597 302.891 87.2197 302.891Z"
        fill="#6B667B"
      />
      <path d="M246.82 284.41H98.21V284.76H246.82V284.41Z" fill="#B9CFE6" />
      <path d="M248.99 287.49H95.3398V287.84H248.99V287.49Z" fill="#B9CFE6" />
      <path
        d="M251.44 291.061H93.5195V291.411H251.44V291.061Z"
        fill="#B9CFE6"
      />
      <path
        d="M252.91 294.631H92.1196V294.981H252.91V294.631Z"
        fill="#B9CFE6"
      />
      <path d="M172.41 281.75H172.06V284.62H172.41V281.75Z" fill="#B9CFE6" />
      <path d="M172.27 287.7H171.92V291.27H172.27V287.7Z" fill="#B9CFE6" />
      <path
        d="M167.077 291.209L166.396 294.784L166.739 294.85L167.421 291.274L167.077 291.209Z"
        fill="#B9CFE6"
      />
      <path
        d="M166.74 287.7L166.39 287.63L167.09 284.55L167.37 284.62L166.74 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M161.63 284.621L161.35 284.551L161.98 281.681L162.33 281.751L161.63 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M161.892 287.638L161.208 291.213L161.551 291.279L162.236 287.703L161.892 287.638Z"
        fill="#B9CFE6"
      />
      <path
        d="M157.01 291.224L156.329 294.8L156.672 294.865L157.354 291.29L157.01 291.224Z"
        fill="#B9CFE6"
      />
      <path
        d="M156.66 287.7L156.31 287.63L157.01 284.55L157.36 284.62L156.66 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M151.62 284.621L151.27 284.551L151.97 281.681L152.25 281.751L151.62 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M151.824 287.653L151.14 291.229L151.484 291.294L152.168 287.719L151.824 287.653Z"
        fill="#B9CFE6"
      />
      <path
        d="M146.957 291.174L146.276 294.75L146.62 294.816L147.301 291.24L146.957 291.174Z"
        fill="#B9CFE6"
      />
      <path
        d="M146.58 287.7L146.23 287.63L146.93 284.55L147.28 284.62L146.58 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M141.54 284.621L141.19 284.551L141.89 281.681L142.24 281.751L141.54 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M141.757 287.669L141.072 291.244L141.416 291.31L142.1 287.735L141.757 287.669Z"
        fill="#B9CFE6"
      />
      <path
        d="M136.889 291.186L136.208 294.762L136.552 294.827L137.233 291.252L136.889 291.186Z"
        fill="#B9CFE6"
      />
      <path
        d="M136.5 287.7L136.22 287.63L136.85 284.55L137.2 284.62L136.5 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M131.46 284.621L131.11 284.551L131.81 281.681L132.16 281.751L131.46 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M131.702 287.615L131.018 291.19L131.361 291.256L132.046 287.681L131.702 287.615Z"
        fill="#B9CFE6"
      />
      <path
        d="M126.823 291.202L126.141 294.777L126.485 294.843L127.166 291.267L126.823 291.202Z"
        fill="#B9CFE6"
      />
      <path
        d="M126.49 287.7L126.14 287.63L126.84 284.55L127.12 284.62L126.49 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M121.38 284.621L121.1 284.551L121.73 281.681L122.08 281.751L121.38 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M121.635 287.631L120.95 291.206L121.294 291.272L121.978 287.697L121.635 287.631Z"
        fill="#B9CFE6"
      />
      <path
        d="M116.756 291.217L116.075 294.793L116.419 294.858L117.1 291.283L116.756 291.217Z"
        fill="#B9CFE6"
      />
      <path
        d="M116.41 287.7L116.06 287.63L116.76 284.55L117.04 284.62L116.41 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M111.37 284.621L111.02 284.551L111.72 281.681L112 281.751L111.37 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M111.567 287.647L110.883 291.223L111.227 291.288L111.911 287.713L111.567 287.647Z"
        fill="#B9CFE6"
      />
      <path
        d="M106.702 291.164L106.021 294.739L106.365 294.805L107.046 291.229L106.702 291.164Z"
        fill="#B9CFE6"
      />
      <path
        d="M106.33 287.7L105.98 287.63L106.68 284.55L107.03 284.62L106.33 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M238.979 291.194L238.635 291.26L239.314 294.836L239.658 294.77L238.979 291.194Z"
        fill="#B9CFE6"
      />
      <path
        d="M239.33 287.7L238.7 284.62L238.98 284.55L239.68 287.63L239.33 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M234.29 284.621L233.66 281.751L233.94 281.681L234.64 284.551L234.29 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M233.791 287.619L233.447 287.685L234.13 291.26L234.473 291.194L233.791 287.619Z"
        fill="#B9CFE6"
      />
      <path
        d="M228.968 291.184L228.624 291.249L229.303 294.825L229.647 294.76L228.968 291.184Z"
        fill="#B9CFE6"
      />
      <path
        d="M229.32 287.7L228.62 284.62L228.97 284.55L229.6 287.63L229.32 287.7Z"
        fill="#B9CFE6"
      />
      <path d="M223.93 281.75H223.58V284.62H223.93V281.75Z" fill="#B9CFE6" />
      <path
        d="M223.711 287.622L223.367 287.688L224.05 291.263L224.394 291.197L223.711 287.622Z"
        fill="#B9CFE6"
      />
      <path
        d="M218.889 291.187L218.545 291.252L219.224 294.828L219.568 294.763L218.889 291.187Z"
        fill="#B9CFE6"
      />
      <path
        d="M219.24 287.7L218.54 284.62L218.89 284.55L219.59 287.63L219.24 287.7Z"
        fill="#B9CFE6"
      />
      <path d="M213.85 281.75H213.5V284.62H213.85V281.75Z" fill="#B9CFE6" />
      <path
        d="M213.696 287.616L213.352 287.682L214.035 291.257L214.379 291.191L213.696 287.616Z"
        fill="#B9CFE6"
      />
      <path
        d="M208.809 291.191L208.465 291.256L209.145 294.832L209.488 294.766L208.809 291.191Z"
        fill="#B9CFE6"
      />
      <path
        d="M209.16 287.7L208.46 284.62L208.81 284.55L209.51 287.63L209.16 287.7Z"
        fill="#B9CFE6"
      />
      <path d="M203.77 281.75H203.42V284.62H203.77V281.75Z" fill="#B9CFE6" />
      <path
        d="M203.622 287.614L203.278 287.68L203.961 291.255L204.304 291.189L203.622 287.614Z"
        fill="#B9CFE6"
      />
      <path
        d="M198.725 291.197L198.381 291.263L199.061 294.839L199.404 294.773L198.725 291.197Z"
        fill="#B9CFE6"
      />
      <path
        d="M199.08 287.7L198.45 284.62L198.73 284.55L199.43 287.63L199.08 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M194.04 284.621L193.34 281.751L193.69 281.681L194.39 284.551L194.04 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M193.542 287.617L193.198 287.683L193.881 291.258L194.225 291.192L193.542 287.617Z"
        fill="#B9CFE6"
      />
      <path
        d="M188.719 291.183L188.375 291.248L189.055 294.824L189.399 294.759L188.719 291.183Z"
        fill="#B9CFE6"
      />
      <path
        d="M189 287.7L188.37 284.62L188.72 284.55L189.35 287.63L189 287.7Z"
        fill="#B9CFE6"
      />
      <path
        d="M183.96 284.621L183.33 281.751L183.61 281.681L184.31 284.551L183.96 284.621Z"
        fill="#B9CFE6"
      />
      <path
        d="M183.463 287.62L183.119 287.686L183.802 291.261L184.146 291.195L183.463 287.62Z"
        fill="#B9CFE6"
      />
      <path
        d="M141.706 294.847L141.022 298.422L141.366 298.488L142.049 294.912L141.706 294.847Z"
        fill="#B9CFE6"
      />
      <path
        d="M131.651 294.793L130.967 298.368L131.311 298.434L131.995 294.859L131.651 294.793Z"
        fill="#B9CFE6"
      />
      <path
        d="M121.583 294.809L120.899 298.384L121.243 298.45L121.927 294.874L121.583 294.809Z"
        fill="#B9CFE6"
      />
      <path
        d="M111.516 294.824L110.832 298.399L111.176 298.465L111.859 294.89L111.516 294.824Z"
        fill="#B9CFE6"
      />
      <path
        d="M233.787 294.838L233.443 294.903L234.126 298.479L234.47 298.413L233.787 294.838Z"
        fill="#B9CFE6"
      />
      <path
        d="M223.708 294.841L223.364 294.906L224.047 298.481L224.39 298.416L223.708 294.841Z"
        fill="#B9CFE6"
      />
      <path
        d="M213.722 294.813L213.378 294.879L214.061 298.454L214.405 298.388L213.722 294.813Z"
        fill="#B9CFE6"
      />
      <path
        d="M203.618 294.833L203.274 294.898L203.957 298.474L204.301 298.408L203.618 294.833Z"
        fill="#B9CFE6"
      />
      <path
        d="M178.64 291.187L178.296 291.252L178.975 294.828L179.319 294.763L178.64 291.187Z"
        fill="#B9CFE6"
      />
      <path
        d="M178.99 287.7L178.29 284.62L178.64 284.55L179.27 287.63L178.99 287.7Z"
        fill="#B9CFE6"
      />
    </g>
    <defs>
      <clipPath id="clip0_8638_153564">
        <rect width="350" height="350" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ChatEmptyIcon;
