import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ArrowDownIcon from './arrowDownIcon';
import ArrowUpIcon from './arrowUpIcon';

const InfoTileItem = ({ data, title, prevDate, dataCy }) => (
  <div className="info-tiles__item" data-cy="yesterdays-stats__info-tile">
    <h4 className="info-tiles__title">{title}</h4>
    <div className="info-tiles__values-wrapper">
      <div className="info-tiles__value" data-cy={`${dataCy}-current`}>
        {data.currentCount}
      </div>
      <div>
        <div className="info-tiles__prev-values">
          <span className="info-tiles__prev-value" data-cy={`${dataCy}-prev`}>
            {data.previousCount}
          </span>
          {data.percentageIncrease !== null && (
            <>
              <span> | </span>
              <span
                className={classnames('info-tiles__prev-percent', {
                  'info-tiles__prev-percent--red': data.percentageIncrease < 0,
                  'info-tiles__prev-percent--green':
                    data.percentageIncrease > 0,
                })}
                data-cy={`${dataCy}-percentage`}
              >
                {data.percentageIncrease > 0 && (
                  <span className="info-tiles__percent-icon">
                    <ArrowUpIcon />
                  </span>
                )}
                {data.percentageIncrease < 0 && (
                  <span className="info-tiles__percent-icon">
                    <ArrowDownIcon />
                  </span>
                )}
                {Math.round(data.percentageIncrease * 10) / 10}%
              </span>
            </>
          )}
        </div>
        <div data-cy={`${dataCy}-date`} className="info-tiles__prev-value-date">
          {prevDate}
        </div>
      </div>
    </div>
  </div>
);

InfoTileItem.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  prevDate: PropTypes.string,
  dataCy: PropTypes.string,
};

export default InfoTileItem;
