import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dataService from 'services/dataService';
import { API_EMAIL_SUBSCRIPTION } from 'services/dataService/resources/public';
import { showResult } from 'infrastructure/hooks/utils';
import classNames from 'classnames';
import BaseLoader from 'components/atoms/base-loader';

const EmailUnsubscribePage = () => {
  const baseClass = 'email-subscription-page';
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const unsubscribe = async () => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (!token) {
      showResult('Token not found');
      return;
    }
    setLoading(true);
    const { error } = await dataService.createOne(API_EMAIL_SUBSCRIPTION, {
      token,
      subscription: false,
    });
    setLoading(false);
    if (error) {
      showResult(error);
    } else {
      navigate(`/email-unsubscribed?token=${token}`);
    }
  };

  return (
    <div className={baseClass}>
      <div className={`${baseClass}--container`}>
        <div className={`${baseClass}--logo-container`}>
          <img src="/img/accuhealth-logo.png" alt="logo" height={40} />
        </div>
        <div className={`${baseClass}--image-container`}>
          <img
            src="/img/email-unsubscribe/main.png"
            alt="unsubscribe-main"
            className={`${baseClass}--main-image`}
          />
        </div>
        <div className={`${baseClass}--text-container`}>
          <h1 className={`${baseClass}--title`}>Unsubscribe</h1>
          <p>Are you sure you want to unsubscribe from the mailing list?</p>
        </div>
        <div className={`${baseClass}--buttons-container`}>
          {showConfirm === false && (
            <button
              onClick={() => setShowConfirm(true)}
              className={`${baseClass}--button`}
            >
              Unsubscribe
            </button>
          )}
          {showConfirm === true && (
            <>
              <button
                onClick={() => setShowConfirm(false)}
                className={classNames(
                  `${baseClass}--button`,
                  `${baseClass}--button--outline`,
                )}
                disabled={loading}
              >
                No
              </button>
              <button
                onClick={unsubscribe}
                className={`${baseClass}--button`}
                disabled={loading}
              >
                {loading && (
                  <div className={`${baseClass}--button--loader-container`}>
                    <BaseLoader loading width={20} height={20} />
                  </div>
                )}
                Yes
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailUnsubscribePage;
