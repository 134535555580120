import { useQuery } from '@tanstack/react-query';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';
import dataService from 'services/dataService';
import { API_MEDICATION } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';

import type {
  IMedicationResponse,
  IPartialPaginationMeta,
} from 'infrastructure/interfaces';

interface Props {
  patientGuid: string;
  meta: IPartialPaginationMeta;
  isActive?: boolean;
}

type TFilter = {
  patientGuid: string;
  isActive?: boolean;
} & IPartialPaginationMeta;

const getMedicationFn = async (filter: TFilter) => {
  const url = API_MEDICATION;
  const { error, data } = await dataService.getList<IMedicationResponse>(
    apiUrlGenerator(url, filter),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const getMedicationKey = (filter: TFilter) => ['getMedication', filter];

export const MEDICATION_PER_PAGE = 5;

export const useGetMedication = ({ patientGuid, meta, isActive }: Props) => {
  const filter: TFilter = {
    ...meta,
    patientGuid,
    isActive,
  };

  const {
    data: medication,
    isLoading,
    ...other
  } = useQuery({
    queryKey: getMedicationKey(filter),
    queryFn: async () => getMedicationFn(filter),
    staleTime: 10_000,
  });

  return {
    medication,
    isLoading,
    ...other,
  };
};
