import { phoneRegex } from 'constants/regex';
import * as Yup from 'yup';

const UserSchema = Yup.object().shape({
  title: Yup.string().required('required').typeError('required'),
  firstName: Yup.string().required('required'),
  lastName: Yup.string().required('required'),
  middleName: Yup.string().nullable(),
  birthDate: Yup.string().matches(
    /^(0[1-9]|1[012])[-]([012][0-9]|3[01])[-]([0-9]{4})$/,
    'Birth Date must be in the format MM-DD-YYYY.',
  ),
  mobileNumber: Yup.string()
    .matches(phoneRegex, 'Phone number is not correct')
    .required('required'),
  workNumber: Yup.string()
    .matches(phoneRegex, 'Phone number is not correct')
    .required('required'),
  email: Yup.string()
    .email('Email is not correct')
    .required('required')
    .test('sign', '+ sign not allowed', (val) => val?.indexOf('+') === -1),
  clinics: Yup.array()
    .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
    .min(1),
  city: Yup.string().when('zip', {
    is: (zip) => !!zip,
    then: (schema) => schema.required().nullable(),
    otherwise: (schema) => schema.nullable(),
  }),
  state: Yup.string().when(['zip', 'city'], {
    is: (zip, city) => !!zip || !!city,
    then: Yup.string().required().nullable(),
    otherwise: Yup.string().nullable(),
  }),
  zip: Yup.string().nullable(),
  primaryLanguage: Yup.string().nullable(),
  secondaryLanguage: Yup.string().nullable(),
  addressLine1: Yup.string().nullable(),
  addressLine2: Yup.string().nullable(),
  alertEmail: Yup.bool().nullable(),
  alertSms: Yup.bool().nullable(),
  professionType: Yup.string().nullable(),
  roles: Yup.array()
    .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
    .min(1),
  npiNumber: Yup.string().nullable(),
  emrName: Yup.string().nullable(),
  emrId1: Yup.string().nullable(),
  emrId2: Yup.string().nullable(),
  renderingProviderId: Yup.string().nullable(),
  supervisingProviderId: Yup.string().nullable(),
  readingsFlag: Yup.boolean().nullable(),
  billing: Yup.boolean().nullable(),
  billingCycle: Yup.string().nullable(),
  reports: Yup.string().nullable(),
});

export default UserSchema;
