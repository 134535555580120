const getStatus = (systolic, diastolic) => {
  if (systolic === 'critical' || diastolic === 'critical') {
    return 'critical';
  }
  if (systolic === 'risk' || diastolic === 'risk') {
    return 'risk';
  }
  return 'normal';
};

export default getStatus;
