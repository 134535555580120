import type { SVGProps } from 'react';

const AverageRiskIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.3333 1.6665C9.68516 1.6665 8.07399 2.15525 6.70358 3.07092C5.33318 3.9866 4.26507 5.28809 3.63434 6.81081C3.00361 8.33353 2.83858 10.0091 3.16013 11.6256C3.48167 13.2421 4.27534 14.727 5.44078 15.8924C6.60622 17.0578 8.09108 17.8515 9.70758 18.173C11.3241 18.4946 12.9996 18.3296 14.5224 17.6988C16.0451 17.0681 17.3466 16 18.2622 14.6296C19.1779 13.2592 19.6667 11.648 19.6667 9.99984C19.6642 7.79045 18.7854 5.67227 17.2232 4.10999C15.6609 2.54772 13.5427 1.66896 11.3333 1.6665ZM11.3333 16.6665C10.0148 16.6665 8.72586 16.2755 7.62954 15.543C6.53321 14.8104 5.67872 13.7692 5.17414 12.5511C4.66956 11.3329 4.53753 9.99244 4.79477 8.69924C5.052 7.40603 5.68694 6.21814 6.61929 5.28579C7.55164 4.35344 8.73953 3.7185 10.0327 3.46127C11.3259 3.20403 12.6664 3.33606 13.8846 3.84064C15.1027 4.34522 16.1439 5.19971 16.8765 6.29604C17.609 7.39236 18 8.6813 18 9.99984C17.998 11.7673 17.295 13.4618 16.0451 14.7116C14.7953 15.9615 13.1008 16.6645 11.3333 16.6665ZM11.3333 9.58317C11.1123 9.58317 10.9004 9.67097 10.7441 9.82725C10.5878 9.98353 10.5 10.1955 10.5 10.4165V12.9165C10.5 13.1375 10.5878 13.3495 10.7441 13.5058C10.9004 13.662 11.1123 13.7498 11.3333 13.7498C11.5544 13.7498 11.7663 13.662 11.9226 13.5058C12.0789 13.3495 12.1667 13.1375 12.1667 12.9165V10.4165C12.1667 10.1955 12.0789 9.98353 11.9226 9.82725C11.7663 9.67097 11.5544 9.58317 11.3333 9.58317ZM11.3333 6.24984C11.1273 6.24984 10.9259 6.31093 10.7546 6.42539C10.5833 6.53985 10.4498 6.70254 10.371 6.89288C10.2921 7.08322 10.2715 7.29266 10.3117 7.49472C10.3519 7.69679 10.4511 7.88239 10.5968 8.02807C10.7424 8.17375 10.9281 8.27296 11.1301 8.31316C11.3322 8.35335 11.5416 8.33272 11.732 8.25388C11.9223 8.17504 12.085 8.04152 12.1995 7.87022C12.3139 7.69892 12.375 7.49753 12.375 7.2915C12.375 7.01524 12.2653 6.75029 12.0699 6.55493C11.8746 6.35958 11.6096 6.24984 11.3333 6.24984Z"
      fill="#E28416"
    />
  </svg>
);

export default AverageRiskIcon;
