import { fullNamePresenter } from 'utils';

import DashboardReadingColumn from './components/dashboard-reading-col';
import DateColumnMobile from './components/date-col/mobile';
import ReviewedCheckbox from './components/reviewed-checkbox';
import Countdown from './components/countdown';
import StopSlaButton from './components/stop-btn';
import PatientReadingColumn from './components/patient-reading-col';
import CommentsColumnMobile from './components/comments-col/mobile';
import CommentColumn from './components/comment-col';
import s from './styles.module.scss';

import type { IActivityData } from 'infrastructure/interfaces/i-rpm-patient-activity';
import type { FC } from 'react';
import type {
  TAddCommentFn,
  TRpmDashboardActivityQueryKey,
  TRpmPatientActivityQueryKey,
} from 'infrastructure/hooks/rpm';

interface IMobileCellProps {
  createdAt: string;
  record: IActivityData;
  idx: number;
  addComment: TAddCommentFn;
  showReviewCol: boolean;
  view: 'patient' | 'dashboard';
  getActivityQueryKey:
    | TRpmDashboardActivityQueryKey
    | TRpmPatientActivityQueryKey;
  hideCommentsColumn: boolean;
  slaReadings: string | (string | null)[] | null;
}

const MobileCell: FC<IMobileCellProps> = ({
  createdAt,
  idx,
  record,
  addComment,
  getActivityQueryKey,
  showReviewCol,
  view,
  hideCommentsColumn,
  slaReadings,
}) => {
  const [_, { page, items }] = getActivityQueryKey;
  const currentPage = page ?? 1;
  const itemIndexPrefix = (currentPage - 1) * (items ?? 100);

  return (
    <div className={s['mob-wrapper']}>
      <DateColumnMobile
        createdAt={createdAt}
        globalStatus={record.globalStatus}
        idx={itemIndexPrefix + idx + 1}
      >
        {showReviewCol && (
          <div className={s.reviewed} data-view="mobile">
            <ReviewedCheckbox
              key={record.id + record.deviceId}
              addComment={addComment}
              deviceId={record.deviceId}
              readingId={record.id}
              idx={idx}
              currentInteractionStatus={record.patient.interactionStatus}
            />
          </div>
        )}
      </DateColumnMobile>
      {view === 'dashboard' && (
        <div style={{ position: 'relative' }}>
          <DashboardReadingColumn
            globalStatus={record.globalStatus}
            readingType={record.readingType}
            statuses={record.statuses}
            thresholds={record.patient.threshold}
            values={record.value}
            key={`reading-col-${idx}`}
            patient={record.patient}
            provider={fullNamePresenter(record.doctor)}
            reserved={record.reserved}
            getActivityQueryKey={getActivityQueryKey}
            deviceId={record.deviceId}
            readingId={record.id}
            hideCommentsColumn={hideCommentsColumn}
          />
          {view === 'dashboard' &&
            slaReadings === 'true' &&
            record.slaDeadline &&
            record.serviceDeliveryThresholds && (
              <div
                className={s['mob-countdown-wrapper']}
                style={{ top: hideCommentsColumn ? 16 : 40 }}
              >
                <Countdown
                  slaDeadline={record.slaDeadline}
                  slaReviewedTime={record.slaReviewedTime}
                  serviceDeliveryThresholds={record.serviceDeliveryThresholds}
                />
                {!record.slaReviewedTime && (
                  <StopSlaButton
                    deviceId={record.deviceId}
                    readingId={record.id}
                    getActivityQueryKey={getActivityQueryKey}
                  />
                )}
              </div>
            )}
        </div>
      )}
      <PatientReadingColumn
        globalStatus={record.globalStatus}
        readingType={record.readingType}
        statuses={record.statuses}
        thresholds={record.patient.threshold}
        values={record.value}
        key={`reading-col-${idx}`}
      />
      {!hideCommentsColumn && (
        <CommentsColumnMobile
          comments={record.comments}
          patientGuid={record.patient.guid}
        />
      )}
      {!hideCommentsColumn && (
        <div className={s['comment-mob-wrapper']}>
          <CommentColumn
            addComment={addComment}
            deviceId={record.deviceId}
            readingId={record.id}
            idx={idx}
            key={`comment-col-${idx}`}
            createdAt={record.createdAt}
            hideAiButton={showReviewCol}
            currentInteractionStatus={record.patient.interactionStatus}
            patientGuid={record.patient.guid}
          />
        </div>
      )}
    </div>
  );
};

export default MobileCell;
