/* eslint-disable prefer-promise-reject-errors */
import httpCodes from 'constants/httpCodes';
import logger from 'services/logger';
import { isAbortError } from 'utils';

import getHeaders from './getHeaders';

const MAX_RETRIES = 5;

const httpClient = async (url, options = {}, upload, retries = 0) => {
  try {
    const response = await fetch(url, {
      headers: getHeaders(true, upload),
      ...options,
    });

    if (response.status === httpCodes.notAuthorized) {
      if (retries >= MAX_RETRIES) {
        logger.error(new Error('Internal error: Not authorized'));
        return Promise.reject({
          message: 'Internal error: Not authorized',
        });
      }

      if (typeof window.getAccessTokenSilently === 'function') {
        await window.getAccessTokenSilently({
          ignoreCache: true,
        });
        return httpClient(url, options, upload, retries + 1);
      }
    }

    let json = {};
    let body = {};

    body = await response.text();
    try {
      json = JSON.parse(body);
    } catch (e) {
      json = body;
    }

    if (response.status === httpCodes.badRequest) {
      logger.error(json);
      return Promise.reject({
        message: json.message,
      });
    }

    if (
      response.status < httpCodes.minSuccess ||
      response.status > httpCodes.maxSuccess
    ) {
      logger.error(json);
      return Promise.reject({
        message: json.message,
        status: response.status,
      });
    }
    return Promise.resolve({
      status: response.status,
      headers: response.headers,
      body,
      json,
    });
  } catch (error) {
    if (isAbortError(error.name) === false) {
      logger.error(error);
    }
    return Promise.reject({
      message: error.message,
      name: error.name,
      status: error.status,
    });
  }
};

export default httpClient;
