import type { SortParam, SortState } from 'infrastructure/types';
import type { SorterField } from '../interfaces/i-modified-sorter-result';

export const createSortOrder = (
  sorterField: SorterField,
): SortParam | undefined => {
  let sortType: SortState = 'asc';

  if (!Array.isArray(sorterField) && sorterField.order && sorterField.column) {
    sortType = sorterField.order === 'descend' ? 'desc' : 'asc';
    return [`${sorterField.column.sortKey}`, sortType];
  }

  return undefined;
};
