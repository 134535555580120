import { useQueries } from '@tanstack/react-query';
import { API_PATIENT_KEY_NOTE } from 'services/dataService/resources';
import { ModuleType } from 'infrastructure/enums';

import { getKeyNotes } from './api/getKeyNoteFn';

interface Props {
  patientGuid: string;
  module: ModuleType;
  canChangeModule?: boolean;
}
export const getKeyNoteKey = ({ patientGuid, module }: Props) => [
  'getKeyNoteData',
  patientGuid,
  module,
];

export const usGetKeyNote = ({
  patientGuid,
  module,
  canChangeModule,
}: Props) => {
  const apiUrl = API_PATIENT_KEY_NOTE(patientGuid);

  const [rpmQueryData, ccmQueryData] = useQueries({
    queries: [
      {
        queryKey: getKeyNoteKey({ patientGuid, module: ModuleType.RPM }),
        queryFn: () =>
          getKeyNotes({ url: `${apiUrl}?module=${ModuleType.RPM}` }),
        enabled: canChangeModule || module === ModuleType.RPM,
      },
      {
        queryKey: getKeyNoteKey({ patientGuid, module: ModuleType.CCM }),
        queryFn: () =>
          getKeyNotes({ url: `${apiUrl}?module=${ModuleType.CCM}` }),
        enabled: canChangeModule || module === ModuleType.CCM,
      },
    ],
  });
  return [rpmQueryData, ccmQueryData];
};
