const connect = {
  state: {
    connectPopoutOpen: false,
    connectDetails: {
      phone: '',
      guid: '',
      readingId: '',
      deviceId: '',
    },
  },
  reducers: {
    setConnectPopoutOpen: (state, payload) => ({
      ...state,
      connectPopoutOpen: payload,
    }),
    setConnectDetails: (state, payload) => ({
      ...state,
      connectDetails: payload,
    }),
    setWsClient: (state, payload) => ({
      ...state,
      wsClient: payload,
    }),
  },
  effects: (dispatch) => ({
    handleConnectPopoutOpen(status) {
      dispatch.connect.setConnectPopoutOpen(status);
    },
    connectDetails(details) {
      dispatch.connect.setConnectDetails(details);
    },
  }),
};

export default connect;
