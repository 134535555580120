import PropTypes from 'prop-types';
import { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import InfoIcon from '../icons/infoIcon';

const LabelWithTooltip = ({ htmlFor, children, tooltipText }) => {
  const ref = useRef(null);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label htmlFor={htmlFor}>{children}</label>
        <button
          ref={ref}
          style={{
            height: '1rem',
            display: 'flex',
            alignItems: 'flex-end',
            padding: 0,
            border: 'none',
            background: 'none',
            marginLeft: '3px',
          }}
        >
          <InfoIcon height="14px" width="14px" color="#667180" />
        </button>
        <UncontrolledTooltip placement="right" target={ref}>
          {tooltipText}
        </UncontrolledTooltip>
      </div>
    </>
  );
};

LabelWithTooltip.propTypes = {
  children: PropTypes.any,
  htmlFor: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default LabelWithTooltip;
