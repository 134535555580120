const CommentIcon = (props) => (
  <svg
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.77273 6.39872V7.19856C7.77273 7.91114 6.89143 8.268 6.376 7.76413L4.97928 6.39872H1.22727C0.549469 6.39872 0 5.86157 0 5.19896V1.19976C0 0.537151 0.549469 0 1.22727 0H7.77273C8.45053 0 9 0.537151 9 1.19976V5.19896C9 5.86157 8.45053 6.39872 7.77273 6.39872Z"
      fill="#413B56"
    />
  </svg>
);

export default CommentIcon;
