import { useActivityFilter, useRpmActivity } from 'infrastructure/hooks/rpm';
import { useTranslation } from 'react-i18next';
import useUser from 'utils/useUser';

enum Status {
  Critical = 'critical',
  Risk = 'risk',
  Normal = 'normal',
  SlaReadings = 'slaReadings',
}
enum AllowedRole {
  Critical = 'critical',
  Risk = 'risk',
}

export const useButtons = () => {
  const { filter, updateQueryParams } = useActivityFilter();
  const { isSlaReadingsAllowed, isCriticalRole, isAtRiskRole } = useUser();
  const { activityCount, countLoading } = useRpmActivity(filter);
  const { t } = useTranslation();

  const { status, slaReadings } = filter;

  const isPriority = status === 'critical' && Boolean(slaReadings);
  const isCritical = status === 'critical' && !slaReadings;
  const isRisk = status === 'risk';
  const isNormal = status === 'normal';

  const onClick = async (newStatus: Status) => {
    if (newStatus === 'slaReadings') {
      slaReadings
        ? updateQueryParams({
            status: undefined,
            slaReadings: undefined,
            page: undefined,
          })
        : updateQueryParams({
            status: 'critical',
            slaReadings: 'true',
            page: undefined,
          });
      return;
    }

    if (status === newStatus && slaReadings) {
      updateQueryParams({
        status: newStatus,
        slaReadings: undefined,
        page: undefined,
      });
      return;
    }

    status === newStatus
      ? updateQueryParams({ status: undefined, slaReadings: undefined })
      : updateQueryParams({
          status: newStatus,
          slaReadings: undefined,
          page: undefined,
        });
  };

  let buttons = [
    {
      status: Status.SlaReadings,
      count: activityCount?.slaReadings ?? 0,
      active: isPriority,
      label: t('labels.priority'),
      bgColor: '#165BCD',
      render: isSlaReadingsAllowed,
      role: AllowedRole.Critical,
      onClick: () => onClick(Status.SlaReadings),
    },
    {
      status: Status.Critical,
      count: activityCount?.critical ?? 0,
      active: isCritical,
      label: t('labels.critical'),
      bgColor: '#B20109',
      render: true,
      role: AllowedRole.Critical,
      onClick: () => onClick(Status.Critical),
    },
    {
      status: Status.Risk,
      count: activityCount?.risk ?? 0,
      active: isRisk,
      label: t('labels.at-risk'),
      bgColor: '#FF7A00',
      render: true,
      role: AllowedRole.Risk,
      onClick: () => onClick(Status.Risk),
    },
    {
      status: Status.Normal,
      count: activityCount?.normal ?? 0,
      active: isNormal,
      label: t('labels.normal'),
      bgColor: '#61B753',
      render: true,
      role: AllowedRole.Risk,
      onClick: () => onClick(Status.Normal),
    },
  ];

  if (!isCriticalRole && !isAtRiskRole)
    buttons = buttons.filter((b) => b.render);
  if (isCriticalRole)
    buttons = buttons.filter((b) => b.role === AllowedRole.Critical);
  if (isAtRiskRole)
    buttons = buttons.filter((b) => b.role === AllowedRole.Risk);

  return { buttons, countLoading };
};
