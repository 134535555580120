import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_ALLERGY_LOG } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';

import {
  ALLERGY_LOG_PER_PAGE,
  getAllergyLogsKey,
} from './use-get-allergy-logs';

import type {
  IAllergyLogResponse,
  IAllergyLog,
  IAddAllergyLog,
} from 'infrastructure/interfaces';

const addAllergyLogFn = async (postData: IAddAllergyLog) => {
  const url = API_ALLERGY_LOG;
  const { error, data } = await dataService.createOne<IAllergyLog>(
    url,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useAddAllergyLog = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: async (postData: IAddAllergyLog) => addAllergyLogFn(postData),
    onSuccess(data, variables) {
      const queryKey = getAllergyLogsKey({
        patientGuid: variables.patientGuid,
        items: ALLERGY_LOG_PER_PAGE,
        page: 1,
      });
      queryClient.setQueryData<IAllergyLogResponse>(queryKey, (oldData) => {
        if (oldData && data) {
          const newData = structuredClone(oldData);
          newData.items.unshift(data);
          newData.count += 1;

          if (newData.items.length >= ALLERGY_LOG_PER_PAGE)
            newData.items.length = ALLERGY_LOG_PER_PAGE;

          return newData;
        }
        return oldData;
      });
      showResult();
    },
  });
  return {
    addAllergyLog: mutate,
    addLoading: isLoading,
    ...other,
  };
};
