import type { IAgencySelect } from 'infrastructure/interfaces';

export class AgencySelect {
  guid: string;
  label: string;

  constructor(data: IAgencySelect) {
    this.guid = data.guid;
    this.label = data.label;
  }
}
