import { useTranslation } from 'react-i18next';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';

import type { FC } from 'react';

interface Props {
  sendReportIsLoading: boolean;
  sendReportToEmail: () => void;
}

const DownloadButton: FC<Props> = ({
  sendReportIsLoading,
  sendReportToEmail,
}) => {
  const { t } = useTranslation();

  return (
    <button onClick={sendReportToEmail} disabled={sendReportIsLoading}>
      <DownloadRoundedIcon className="performance-review__header-icon-svg" />
      <br />
      <span>{t('controls.download')}</span>
    </button>
  );
};

export default DownloadButton;
