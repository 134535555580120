import { useQuery } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import { getSmsTemplatesFn } from './api';

export const getDoctorInfoKey = () => ['getSmsTemplate'];

export const useGetSmsTemplates = () => {
  const { data: smsTemplates, isLoading } = useQuery({
    queryKey: getDoctorInfoKey(),
    queryFn: getSmsTemplatesFn,
    staleTime: STALE_TIME,
  });

  return {
    smsTemplates,
    smsTemplatesLoading: isLoading,
  };
};
