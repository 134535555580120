import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import forms from 'constants/forms';
import { convertSecondsToTime } from 'utils/timeHelper';
import AutoCompleteClinics from 'components/molecules/autocompleteFilter/clinic';
import { useTranslation } from 'react-i18next';
import BaseSelect from 'components/atoms/baseSelect';

const ClinicTime = ({ totalTime, avgTime }) => {
  const DEFAULT_CLINIC_FILTER = forms.clinicFilterOptions[0];
  const DEFAULT_CHILD_CLINIC = forms.childClinicSelect[0];
  const [isShowSwitcher, setIsShowSwitcher] = useState(false);

  const clinicsFilter = DEFAULT_CLINIC_FILTER;

  const {
    clinicalMetrics: { applyFilters },
  } = useDispatch();

  const { t } = useTranslation();
  return (
    <Card className="card-border">
      <CardBody className="clinical-metrics">
        <Row>
          <Col sm={12} md={6}>
            <div className="clinical-metrics-header">
              Report: Clinical Metrics
            </div>
            <div className="clinical-metrics-clinic">
              <p>Clinic</p>
              <AutoCompleteClinics
                filter={clinicsFilter}
                handleClick={({ value, parentClinic }) => {
                  applyFilters({
                    clinicGuid: value,
                    childClinic: parentClinic,
                  });
                  setIsShowSwitcher(parentClinic);
                }}
              />
            </div>
            {isShowSwitcher && (
              <div className="clinical-metrics-clinic">
                <p> {t('labels.childClinics')}</p>
                <BaseSelect
                  options={forms.childClinicSelect}
                  defaultValue={DEFAULT_CHILD_CLINIC}
                  classNamePrefix="select-type"
                  onChange={({ value }) => applyFilters({ childClinic: value })}
                />
              </div>
            )}
          </Col>
          <Col sm={12} md={3}>
            <div className="clinical-metrics-time-title">
              Total Time Spent With All Patients
            </div>
            <div className="clinical-metrics-time-duration">Last 30 Days</div>
            <div className="clinical-metrics-time-timer">
              {convertSecondsToTime(totalTime)}
            </div>
          </Col>
          <Col sm={12} md={3}>
            <div className="clinical-metrics-time-title">
              Average Time Spent With A Single Patient
            </div>
            <div className="clinical-metrics-time-duration">Last 30 Days</div>
            <div className="clinical-metrics-time-timer">
              {convertSecondsToTime(avgTime)}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

ClinicTime.propTypes = {
  totalTime: PropTypes.number,
  avgTime: PropTypes.number,
};

export default ClinicTime;
