import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { useSelector } from 'react-redux';

import { getSmsDashboardConversationsKey } from './useConversations';
import { showResult } from '../utils';

import type { IMessagesListPages } from 'infrastructure/interfaces';
import type { RootState } from 'store';

interface Props {
  onSuccess: (data: any) => void;
}

interface FnProps {
  patientPhone: string;
  patientGuid: string | undefined | null;
  from: string | undefined | null;
}

const createConversationFn = async ({
  patientPhone,
  patientGuid,
  from,
}: FnProps) => {
  const API_CONVERSATION = `core/conversations`;

  const { data, error } = await dataService.createOne(API_CONVERSATION, {
    patientPhone,
    patientGuid,
    from,
  });

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useCreateConversation = (props: Props) => {
  const { conversationType, filters } = useSelector(
    (state: RootState) => state.smsDashboard,
  );
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: createConversationFn,
    onSuccess(data) {
      const сonversationKey = getSmsDashboardConversationsKey({
        type: conversationType,
        read: filters.read,
        closed: filters.closed,
        lastMessageDate: filters.lastMessageDate,
        searchQuery: filters.searchQuery,
      });
      props.onSuccess(data);
      queryClient.setQueryData<IMessagesListPages>(
        сonversationKey,
        (oldConversations) => {
          if (!oldConversations) return;

          oldConversations.pages.forEach((page) => {
            page.items = page.items.filter((item) => item.id !== data.data.id);
          });

          const updatedPages = [
            {
              ...oldConversations.pages[0],
              items: [data.data, ...oldConversations.pages[0].items],
            },
            ...oldConversations.pages.slice(0),
          ];

          return { ...oldConversations, pages: updatedPages };
        },
      );
    },
  });

  return {
    createConversation: mutate,
    createConversationLoading: isLoading,
    ...other,
  };
};
