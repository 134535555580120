import CheckIcon from 'components/atoms/icons/checkIcon';
import TurkConfigurationInput from 'components/molecules/turkConfiguration/input';
import TurkConfigurationButton from 'components/molecules/turkConfiguration/button';

import { useTurkConfigurationEdit } from './useEdit';

import type { TurkConfigurations } from 'utils/hooks/turkDashboard/useSettings';
import type { FC } from 'react';

interface Props {
  turkConfiguration: TurkConfigurations;
  changeModeToView: () => void;
  onSubmit: () => void;
}

const TurkConfigurationEdit: FC<Props> = ({
  turkConfiguration,
  changeModeToView,
  onSubmit,
}) => {
  const { formik, loading } = useTurkConfigurationEdit({
    turkConfiguration,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="turk-configuration--control">
        <TurkConfigurationButton
          title="Cancel"
          variant="outline"
          type="button"
          onClick={changeModeToView}
          style={{
            marginRight: 10,
          }}
          disabled={loading}
        />
        <TurkConfigurationButton
          type="submit"
          title="Save"
          loading={loading}
          icon={<CheckIcon height={14} width={14} />}
        />
      </div>
      <TurkConfigurationInput
        label="Default Rate Per Reading"
        formik={formik}
        name="defaultRatePerReading"
        required
        type="number"
        disabled={loading}
      />
      <TurkConfigurationInput
        label="Maximum Daily Readings"
        formik={formik}
        name="maximumDailyReadings"
        required
        type="number"
        disabled={loading}
      />
      <TurkConfigurationInput
        label="Maximum Readings Per Batch"
        formik={formik}
        name="maximumReadingsPerBatch"
        required
        type="number"
        disabled={loading}
      />
      <TurkConfigurationInput
        label="Minimum Reading Batch Refresh Rate (Minutes)"
        formik={formik}
        name="minimumReadingBatchRefreshRate"
        required
        type="number"
        disabled={loading}
      />
      <TurkConfigurationInput
        label="Maximum Reading Review Response Time (Minutes)"
        formik={formik}
        name="maximumReadingReviewResponseTime"
        required
        type="number"
        disabled={loading}
      />
      <TurkConfigurationInput
        label="Maximum Allowable Idle Time (Minutes)"
        formik={formik}
        name="maximumAllowableIdleTime"
        required
        type="number"
        disabled={loading}
      />
    </form>
  );
};

export default TurkConfigurationEdit;
