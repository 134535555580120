import React, { useState } from 'react';
import classNames from 'classnames';
import { validateFile } from 'utils/files';
import { cropText } from 'infrastructure/functions/text-crop';
import { MAX_FILE_SIZE_MB } from 'constants/fileUpload';
import { showResult } from 'infrastructure/hooks/utils';

import { convertFileToBase64 } from '../../../utils/convertFileToBase64';
import common from '../../../constants/common';
import BaseButton from '../baseButton';
import UploadIcon from '../icons/uploadIcon';
import BaseLoader from '../base-loader';

type Props = {
  patientGuid: string;
  createAttachment: (body: {
    patientGuid: string;
    body: { fileBase64: string; fileName: string };
  }) => Promise<any>;
};

const DragAndDrop: React.FC<Props> = ({ patientGuid, createAttachment }) => {
  const [dragging, setDragging] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [fileName, setFileName] = useState<{
    edit: boolean;
    value: string;
    index: number;
  }>({
    edit: false,
    value: '',
    index: 0,
  });
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  const handleDrop: React.DragEventHandler<HTMLDivElement> = (e) => {
    if (!uploadLoading) {
      e.preventDefault();
      setDragging(false);
      const { files } = e.dataTransfer;
      Array.from(files).forEach((file) => {
        const isFileValid = validateFile(file);
        if (isFileValid) {
          setAttachments((prevState) => [...prevState, file]);
        }
      });
    }
  };

  const handleFileInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    if (!uploadLoading) {
      const { files } = e.target;
      if (files) {
        Array.from(files).forEach((file) => {
          const isFileValid = validateFile(file);
          if (isFileValid) {
            setAttachments((prevState) => [...prevState, file]);
          }
        });
      }
    }
  };

  const removeFileByIndex = (indexToRemove: number) => {
    setAttachments((prevItems: File[]) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(indexToRemove, 1);
      return updatedItems;
    });
  };

  const renameFileByIndex = (indexToRename: number) => {
    setAttachments((prevItems) => {
      const updatedItems = prevItems.map((item, index) => {
        if (index === indexToRename) {
          const updatedFile = new File([item], fileName.value, {
            type: item.type,
          });
          return updatedFile;
        }
        return item;
      });
      return updatedItems;
    });
    setFileName({ edit: false, index: 0, value: '' });
  };

  const handleSubmit = async (indexToSubmit: number) => {
    setUploadLoading(true);
    const file = attachments[indexToSubmit];
    const convertedFile = await convertFileToBase64(file);
    const { data, error } = await createAttachment({
      patientGuid,
      body: {
        fileBase64: convertedFile,
        fileName: file.name,
      },
    });
    if (data) {
      showResult(null, null);
      removeFileByIndex(indexToSubmit);
    }
    if (error) {
      showResult(error);
    }
    setUploadLoading(false);
  };

  return (
    <>
      <div
        className={classNames('drag-container', {
          'drag-container-dragging': dragging,
          'drag-container-disabled': uploadLoading,
        })}
        onDragLeave={(e) => {
          e.preventDefault();
          setDragging(false);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setDragging(true);
        }}
        onDrop={handleDrop}
      >
        <label
          className="drag-label-container"
          style={{ width: '100%' }}
          htmlFor="fileInput"
        >
          <div className="drag-title">
            <div className="drag-icon">
              <UploadIcon
                fill={
                  uploadLoading
                    ? common.commonColors.gray
                    : common.activeIconColor
                }
              />
            </div>
            <p className="drag-text">
              Click or drag file to this area to upload
            </p>
          </div>
          <p className="drag-helper-text">{MAX_FILE_SIZE_MB} max file size</p>
        </label>
        <input
          id="fileInput"
          type="file"
          title="browse files"
          onChange={handleFileInputChange}
          style={{ display: 'none' }}
          data-cy="attachment-file-input"
          multiple
          disabled={uploadLoading}
        />
      </div>
      {attachments.length > 0 && (
        <div className="attachment-item-container">
          {uploadLoading && (
            <div className="attachment-item-loader">
              <BaseLoader height={30} width={30} loading />
            </div>
          )}
          {!uploadLoading &&
            attachments.map((file, index) => (
              <div
                className="attachment-form"
                key={file.name}
                style={{ marginTop: '0px' }}
              >
                {fileName.edit && fileName.index === index && (
                  <div className="attachment-form-edit">
                    <input
                      type="text"
                      className="attachment-form-input"
                      defaultValue={fileName.value}
                      value={fileName.value}
                      onChange={(e) =>
                        setFileName({ ...fileName, value: e.target.value })
                      }
                    />
                    <BaseButton
                      type="secondary"
                      label="Save"
                      onClick={() => {
                        renameFileByIndex(index);
                      }}
                    />
                  </div>
                )}
                {!(fileName.edit && fileName.index === index) && (
                  <p className="attachment-text">{cropText(file.name, 80)}</p>
                )}
                <div className="attachment-form-buttons">
                  <BaseButton
                    type="secondary"
                    label="Remove"
                    onClick={() => removeFileByIndex(index)}
                    data-cy="attachment-remove-button"
                  />
                  <BaseButton
                    type="secondary"
                    label="Rename"
                    onClick={() =>
                      setFileName({ edit: true, value: file.name, index })
                    }
                    data-cy="attachment-rename-button"
                  />
                  <BaseButton
                    type="secondary"
                    label="Upload"
                    onClick={() => handleSubmit(index)}
                    data-cy="attachment-upload-button"
                  />
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default DragAndDrop;
