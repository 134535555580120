import React from 'react';
import classNames from 'classnames';
import common from 'constants/common';

import s from './style.module.scss';
import ButtonLoadingIcon from '../icons/buttonLoadingIcon';

import type { BaseButtonSize, BaseButtonType } from './types';

export interface IBaseButtonProps {
  label?: string;
  width?: string | number;
  height?: string | number;
  type?: BaseButtonType;
  size?: BaseButtonSize;
  rtl?: boolean;
  loading?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  dataCy?: string;
  title?: string;
  className?: string;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

const BaseButton: React.FC<IBaseButtonProps> = (props) => {
  const {
    label = '',
    width,
    height,
    icon = null,
    type = 'primary',
    size = 'default',
    rtl = false,
    loading = false,
    disabled = false,
    rounded = false,
    onClick,
    dataCy,
    title,
    className,
    rightIcon,
  } = props;

  const buttonClassName = classNames(
    s.button,
    s[type],
    s[size],
    {
      [s.icon]: icon && !label,
      [s.rounded]: rounded,
      [s.rtl]: rtl,
      [s['no-label-padding']]: !label,
    },
    className,
  );

  const buttonIcon = () => {
    if (loading) {
      return (
        <ButtonLoadingIcon
          color={common.disabledIconColor}
          className={s['spin-loading-button']}
        />
      );
    }
    return icon;
  };

  return (
    <button
      data-cy={dataCy}
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled || loading}
      title={title}
      style={{ width, height }}
      type="button"
    >
      {buttonIcon()}
      {label}
      {rightIcon}
    </button>
  );
};

export default BaseButton;
