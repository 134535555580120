import ControlButtonDropdown from 'components/atoms/controlPanelDropdownButton/controlButtonDropdown';
import ControlPanelSuccessIcon from 'components/atoms/icons/controlPanelSuccessIcon';
import PatientControlButton from 'components/atoms/patentControlButton';
import React, { useMemo, useState } from 'react';
import { booleansDataSource } from 'infrastructure/data-sources/booleans';
import { useTranslation } from 'react-i18next';

import type { JSXElementConstructor, SVGProps } from 'react';

interface IProps {
  value: boolean;
  label: string;
  icon?: JSXElementConstructor<SVGProps<SVGSVGElement>>;
  onChange?: (val: boolean) => void;
}

const OptedControlButton: React.FC<IProps> = (props) => {
  const { label, value, icon, onChange } = props;

  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  const booleans = booleansDataSource();

  const getLabel = (opted: boolean) => {
    return opted ? t('controls.optedIn') : t('controls.optedOut');
  };

  const ToggleButton = useMemo(
    () => (
      <PatientControlButton
        label={label}
        status={getLabel(value)}
        Icon={icon ?? ControlPanelSuccessIcon}
        handleClick={() => setOpened(!opened)}
      />
    ),
    [value],
  );

  const handleChange = (val: boolean) => {
    if (onChange) onChange(val);
    setOpened(false);
  };

  return (
    <div>
      <ControlButtonDropdown
        toggleBtn={ToggleButton}
        options={booleans.map((el) => ({
          id: el.name,
          value: el.id,
          label: getLabel(el.id),
        }))}
        handleOptionClick={handleChange}
      />
    </div>
  );
};

export default OptedControlButton;
