import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const AdminCommentIcon = ({ color = COLORS.WHITE, ...props }: IIconProps) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.53442 0.679993C11.4004 0.679993 14.5344 4.26171 14.5344 8.67999C14.5344 9.49675 14.4273 10.2849 14.2283 11.0272C14.5242 11.611 15.0928 12.9523 15.4622 15.2083C15.4948 15.4073 15.2866 15.5596 15.1008 15.481C14.2643 15.1269 13.1506 14.8075 12.1345 14.7102C10.9043 15.9369 9.29529 16.68 7.53442 16.68C3.66843 16.68 0.534424 13.0983 0.534424 8.67999C0.534424 4.26171 3.66843 0.679993 7.53442 0.679993ZM7.53442 11.68C6.98214 11.68 6.53442 12.1277 6.53442 12.68C6.53442 13.2323 6.98214 13.68 7.53442 13.68C8.08671 13.68 8.53442 13.2323 8.53442 12.68C8.53442 12.1277 8.08671 11.68 7.53442 11.68ZM7.53442 7.67999C6.98214 7.67999 6.53442 8.12771 6.53442 8.67999C6.53442 9.23228 6.98214 9.67999 7.53442 9.67999C8.08671 9.67999 8.53442 9.23228 8.53442 8.67999C8.53442 8.12771 8.08671 7.67999 7.53442 7.67999ZM8.53442 4.67999C8.53442 4.12771 8.08671 3.67999 7.53442 3.67999C6.98214 3.67999 6.53442 4.12771 6.53442 4.67999C6.53442 5.23228 6.98214 5.67999 7.53442 5.67999C8.08671 5.67999 8.53442 5.23228 8.53442 4.67999Z"
      fill={color}
    />
  </svg>
);

export default AdminCommentIcon;
