import React from 'react';

const StickyGlucoseYAxis = () => (
  <div className="custom-axis-container">
    <svg className="glucose-axis-svg-container">
      <g
        transform="translate(0,10)"
        className="rv-xy-plot__axis rv-xy-plot__axis--vertical"
      >
        <line
          x1="40"
          x2="40"
          y1="0"
          y2="190"
          className="rv-xy-plot__axis__line"
        />
        <g transform="translate(40, 0)" className="rv-xy-plot__axis__ticks">
          <g transform="translate(0, 190)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              0
            </text>
          </g>
          <g
            transform="translate(0, 166.25)"
            className="rv-xy-plot__axis__tick"
          >
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              50
            </text>
          </g>
          <g transform="translate(0, 142.5)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              100
            </text>
          </g>
          <g
            transform="translate(0, 118.75)"
            className="rv-xy-plot__axis__tick"
          >
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              150
            </text>
          </g>
          <g transform="translate(0, 95)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              200
            </text>
          </g>
          <g transform="translate(0, 71.25)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              250
            </text>
          </g>
          <g transform="translate(0, 47.5)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              300
            </text>
          </g>
          <g transform="translate(0, 23.75)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              350
            </text>
          </g>
          <g transform="translate(0, 0)" className="rv-xy-plot__axis__tick">
            <line
              y1="0"
              y2="0"
              x1="6"
              x2="-6"
              className="rv-xy-plot__axis__tick__line"
            />
            <text
              textAnchor="end"
              dy="0.32em"
              transform="translate(-14, 0)"
              className="rv-xy-plot__axis__tick__text"
            >
              400
            </text>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export default StickyGlucoseYAxis;
