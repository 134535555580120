import { useState } from 'react';
import format from 'date-fns/format';
import ActivityComments from 'components/molecules/comments';
import ActivityStatus from 'components/atoms/activityStatus';
import common from 'constants/common';
import { getActivityThresholdsValues } from 'utils';
import ReadingItem from 'components/atoms/readingItem';
import ActivityCommentForm from 'components/molecules/activityCommentForm';
import useDisclosure from 'utils/hooks/common/useDisclosure';

import PatientModal from '../../patientModal';

import type { Reading } from 'utils/hooks/turkDashboard/useReadings';
import type { FC } from 'react';

interface Props {
  handleCommentSubmit: (values: {
    comment: string;
    generatedByModel: string | undefined;
    originalGeneratedText: string | undefined;
  }) => any;
  reading: Reading;
  isCommentGenerationLimitReached: boolean;
  onCommentGenerationStatusChange: (action: 'start' | 'end') => void;
  dates: {
    startTime: string;
    endTime: string;
    timezoneOffset: number;
  };
}

const Row: FC<Props> = ({
  reading,
  handleCommentSubmit,
  isCommentGenerationLimitReached,
  onCommentGenerationStatusChange,
  dates,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitCommentLockByAi, setSubmitCommentLockByAi] = useState(false);

  const {
    isOpen: isPatientModalOpen,
    open: openPatientModal,
    toggle: togglePatientModal,
  } = useDisclosure();

  return (
    <tr className="activity-row">
      <td data-label="Status" className="status-row">
        <ActivityStatus status={reading.globalStatus} />
      </td>
      <td data-label="Time">
        <span>
          R:{' '}
          {format(
            new Date(parseInt(reading.createdAt)),
            common.dateFormats.dayOfWeek,
          )}
        </span>
        <span>
          {format(
            new Date(parseInt(reading.createdAt)),
            common.dateFormats.timeAPM,
          )}
        </span>
      </td>
      <td data-label="Patient Guid">
        <button
          className="button-reset text-left text-underline"
          onClick={() => {
            openPatientModal();
          }}
        >
          {reading.patient.guid}
        </button>
        <PatientModal
          patientGuid={reading.patient.guid}
          toggle={togglePatientModal}
          isOpen={isPatientModalOpen}
          dates={dates}
        />
      </td>
      <td data-label="Reading">
        <ReadingItem activity={reading} />
        {getActivityThresholdsValues(
          reading.globalStatus,
          reading.value,
          reading.statuses,
          reading.patient?.threshold,
          reading.readingType,
        ).map((threshold) => (
          <span key={threshold}>{`(${threshold})`}</span>
        ))}
      </td>
      <td data-label="Comment" className="rpm-comment">
        <ActivityComments
          comments={reading.comments}
          patientGuid={reading.patient.guid}
        />
        <ActivityCommentForm
          valueChanged={() => {}}
          handleSubmit={handleCommentSubmit}
          activity={reading}
          submitLoading={submitLoading}
          setSubmitLoading={setSubmitLoading}
          submitCommentLockByAi={submitCommentLockByAi}
          setSubmitCommentLockByAi={setSubmitCommentLockByAi}
          isCommentGenerationLimitReached={isCommentGenerationLimitReached}
          onCommentGenerationStatusChange={onCommentGenerationStatusChange}
          dates={[dates.startTime, dates.endTime]}
        />
      </td>
    </tr>
  );
};

export default Row;
