import type { FC } from 'react';

const TableHeader: FC = () => {
  return (
    <thead>
      <tr>
        <th>
          <div className="adminPanel__row adminPanel__row--sortng">
            Full Name
          </div>
        </th>
        <th>
          <div className="adminPanel__row adminPanel__row--sortng">Agency</div>
        </th>
        <th>
          <div className="adminPanel__row adminPanel__row--sortng">Email</div>
        </th>
        <th>
          <div className="adminPanel__row adminPanel__row--sortng">
            Reset Email
          </div>
        </th>
        <th>
          <div className="adminPanel__row adminPanel__row--sortng">Status</div>
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
