import { useQuery } from '@tanstack/react-query';

import { getCount } from './fetchers';

import type { Filters, InteractiveTimeCountData } from './types';

export interface InteractiveTimeData {
  interactionStatusFalseCount: number;
  interactionStatusTrueCount: number;
}

export const useInteractiveTimeDashboardCount = (filters: Filters) => {
  const { data, error, isLoading } = useQuery({
    queryKey: [
      'interactiveTimeDashboardCount',
      {
        ...filters,
        page: undefined,
        items: undefined,
      },
    ],
    queryFn: () => getCount(filters),
    initialData: {
      interactionStatusFalseCount: 0,
      interactionStatusTrueCount: 0,
    } satisfies InteractiveTimeCountData,
  });

  return { data, isLoading, error };
};
