import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Input from 'components/atoms/input';
import Switch from 'react-switch';
import common from 'constants/common';

const IRS = ({ emailAlert, setEmailAlert, smsAlert, setSmsAlert, errors }) => (
  <div className="admin-agent-form-block">
    <span>IRS</span>
    <Row>
      <Col lg="12" md="12" sm="12" xs="12">
        <Input id="npiNumber" type="text" label="NPI" />
        {errors.npiNumber ? (
          <div className="input-error">{errors.npiNumber.split('.')[0]}</div>
        ) : null}
      </Col>
    </Row>
    <Row>
      <Col lg="12" md="12" sm="12" xs="12">
        <div
          className="admin-agent-edit-form-label"
          data-cy="irs-user-alert-email-switch"
        >
          User Alert Email
        </div>
        <div className="admin-agent-edit-form-readings">
          <div>
            {emailAlert && `Yes`}
            {!emailAlert && `No`}
          </div>
          <Switch
            className="evelyn-switch"
            onChange={() => {
              setEmailAlert(!emailAlert);
            }}
            checked={emailAlert}
            uncheckedIcon={false}
            checkedIcon={false}
            onHandleColor={common.commonColors.green}
            offHandleColor={common.commonColors.gray}
            onColor={common.commonColors.white}
            offColor={common.commonColors.white}
            handleDiameter={24}
            height={30}
            width={59}
            activeBoxShadow="0"
          />
        </div>
      </Col>
    </Row>
    <Row>
      <Col lg="12" md="12" sm="12" xs="12">
        <div
          className="admin-agent-edit-form-label"
          data-cy="irs-user-alert-sms-switch"
        >
          User Alert Sms
        </div>
        <div className="admin-agent-edit-form-readings">
          <div>
            {smsAlert && `Yes`}
            {!smsAlert && `No`}
          </div>
          <Switch
            className="evelyn-switch"
            onChange={() => {
              setSmsAlert(!smsAlert);
            }}
            checked={smsAlert}
            uncheckedIcon={false}
            checkedIcon={false}
            onHandleColor={common.commonColors.green}
            offHandleColor={common.commonColors.gray}
            onColor={common.commonColors.white}
            offColor={common.commonColors.white}
            handleDiameter={24}
            height={30}
            width={59}
            activeBoxShadow="0"
          />
        </div>
      </Col>
    </Row>
  </div>
);

IRS.propTypes = {
  emailAlert: PropTypes.bool.isRequired,
  smsAlert: PropTypes.bool.isRequired,
  setEmailAlert: PropTypes.func.isRequired,
  setSmsAlert: PropTypes.func.isRequired,
  errors: PropTypes.shape({ npiNumber: PropTypes.string }).isRequired,
};

export default IRS;
