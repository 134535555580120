import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_MEDICAL_HISTORY } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';

import {
  MEDICAL_HISTORY_IS_ACTIVE,
  MEDICAL_HISTORY_PER_PAGE,
  getMedicalHistoryKey,
} from './use-get-medical-history';

import type {
  IMedicalHistory,
  IMedicalHistoryResponse,
  IUpdateMedicalHistory,
} from 'infrastructure/interfaces';

interface Props {
  page: number;
}

const updateMedicalHistoryFn = async (postData: IUpdateMedicalHistory) => {
  const url = API_MEDICAL_HISTORY;
  const { error, data } = await dataService.updateOnly<IMedicalHistory>(
    url,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useUpdateMedicalHistory = ({ page }: Props) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: async (postData: IUpdateMedicalHistory) =>
      updateMedicalHistoryFn(postData),
    onSuccess(data, variables) {
      const queryKey = getMedicalHistoryKey({
        patientGuid: variables.patientGuid,
        isActive: MEDICAL_HISTORY_IS_ACTIVE,
        items: MEDICAL_HISTORY_PER_PAGE,
        page,
      });
      queryClient.setQueryData<IMedicalHistoryResponse>(queryKey, (oldData) => {
        if (oldData) {
          oldData.items.map((i) => {
            if (i.guid === variables.guid) {
              i.record = variables.record;
            }
            return i;
          });
        }

        return oldData;
      });
      showResult();
    },
  });
  return {
    updateMedicalHistory: mutate,
    updateLoading: isLoading,
    ...other,
  };
};
