import ClinicalActivityIcon from 'components/atoms/icons/clinicalActivityIcon';
import { useTranslation } from 'react-i18next';
import VoiceCallsLogged from 'components/molecules/voice-calls-logged';
import SmsActivityLogged from 'components/molecules/sms-activity-logged';
import ClinicalNotesLogged from 'components/molecules/clinical-notes-logged';
import ManualClinicalNotesLogged from 'components/molecules/manual-clinical-notes-logged';

import s from './styles.module.scss';

import type { FC } from 'react';

interface ClinicalActivityAndInterventionProps {
  patientGuid: string;
}

const ClinicalActivityAndInterventions: FC<
  ClinicalActivityAndInterventionProps
> = ({ patientGuid }) => {
  const { t } = useTranslation();

  return (
    <div className={s.wrapper} id="clinical-activity">
      <div className={s['title-wrapper']}>
        <h4 className={s.title} data-cy="clinical-activity-section">
          <ClinicalActivityIcon />
          {t('labels.clinicalActivityAndClinicalInterventions')}
        </h4>
        <div className={s.divider} />
      </div>
      <div className={s.body}>
        <VoiceCallsLogged patientGuid={patientGuid} />
        <div className={s.divider} />
        <SmsActivityLogged patientGuid={patientGuid} />
        <div className={s.divider} />
        <ClinicalNotesLogged patientGuid={patientGuid} />
        <div className={s.divider} />
        <ManualClinicalNotesLogged patientGuid={patientGuid} />
      </div>
    </div>
  );
};
export default ClinicalActivityAndInterventions;
