import s from './styles.module.scss';
import Timer from './timer';
import Info from './info';

import type { FC } from 'react';

type Props = {
  onClick: () => any;
  disabled?: boolean;
  timerText?: string;
  isTimerRunning?: boolean;
};

const GetReadingsButton: FC<Props> = ({
  onClick,
  disabled,
  timerText,
  isTimerRunning,
}) => {
  return (
    <div className={s['button-wrapper']}>
      <button className={s.button} onClick={onClick} disabled={disabled}>
        Get Readings
      </button>
      {disabled && (
        <>
          <Info />
          <Timer timer={timerText} showTimer={isTimerRunning} />
        </>
      )}
    </div>
  );
};

export default GetReadingsButton;
