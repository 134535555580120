import React from 'react';
import AdminPanelDiagnosisCodesForm from 'components/molecules/admin-panel/forms/diagnosis-codes';
import AdminPanelEmrDetailsForm from 'components/molecules/admin-panel/forms/emr-details';
import BaseGrid from 'components/atoms/base-grid';
import PriorAuthorizationForm from 'components/molecules/admin-panel/forms/prior-authorization';
import PatientSubscriptionsForm from 'components/molecules/admin-panel/forms/patient-subscriptions';
import Attachments from 'components/templates/admin/patient/components/attachments';
import { ModuleType } from 'infrastructure/enums';
import CareLocationTable from 'components/molecules/admin-panel/forms/care-location/table';
import classNames from 'classnames';

import s from './styles.module.scss';

interface IAdminTabbedFormOtherInfoProps {
  patientGuid: string;
  module: ModuleType;
  readOnly: boolean;
}

const AdminTabbedFormOtherInfo: React.FC<IAdminTabbedFormOtherInfoProps> = (
  props,
) => {
  const { patientGuid, module, readOnly } = props;

  return (
    <BaseGrid rowGap={5} className={s.wrapper}>
      <div className={s['border-bottom']}>
        <BaseGrid columns={2} columnGap={20}>
          <div className={s['border-right']}>
            <AdminPanelDiagnosisCodesForm
              patientGuid={patientGuid}
              module={module}
            />
          </div>
          <AdminPanelEmrDetailsForm patientGuid={patientGuid} module={module} />
        </BaseGrid>
      </div>
      <div className={classNames(s['border-bottom'], s['p-b'])}>
        {module === ModuleType.CCM ? (
          <CareLocationTable patientGuid={patientGuid} readOnly={readOnly} />
        ) : (
          <PriorAuthorizationForm patientGuid={patientGuid} module={module} />
        )}
      </div>
      <div className={classNames(s['border-bottom'], s['p-b'])}>
        <PatientSubscriptionsForm patientGuid={patientGuid} module={module} />
      </div>
      {module === ModuleType.ADMIN && <Attachments patientGuid={patientGuid} />}
    </BaseGrid>
  );
};

export default AdminTabbedFormOtherInfo;
