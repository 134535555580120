import type { FC, SVGProps } from 'react';

const SmsControlIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5922 4.62403C9.62437 -0.473549 16.3707 -1.50509 20.6809 2.27339C24.9911 6.05187 25.2201 16.4803 20.6809 21.0786C16.1416 25.6768 5.67952 24.2016 2.11701 21.0786C-1.4455 17.9555 -0.439978 9.72162 4.5922 4.62403Z"
        fill="#FB9318"
      />
      <path
        d="M6 9C6 8.17157 6.67157 7.5 7.5 7.5H16.5C17.3284 7.5 18 8.17157 18 9V15C18 15.8284 17.3284 16.5 16.5 16.5H7.5C6.67157 16.5 6 15.8284 6 15V9ZM7.5 8.25C7.08579 8.25 6.75 8.58579 6.75 9V9.16268L12 12.3127L17.25 9.16268V9C17.25 8.58579 16.9142 8.25 16.5 8.25H7.5ZM17.25 10.0373L13.7189 12.156L17.25 14.3289V10.0373ZM17.2247 15.194L12.9944 12.5907L12 13.1873L11.0056 12.5907L6.77532 15.194C6.86081 15.5142 7.15286 15.75 7.5 15.75H16.5C16.8471 15.75 17.1392 15.5142 17.2247 15.194ZM6.75 14.3289L10.2811 12.156L6.75 10.0373V14.3289Z"
        fill="white"
      />
    </svg>
  );
};

export default SmsControlIcon;
