import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { BaseFormItemDisplayName } from './item';
import BaseButton from '../baseButton';
import s from './styles.module.scss';

import type { FormikProps } from 'formik';
import type { IBaseComponentProps } from 'infrastructure/interfaces';
import type { IPrivateProps, ReactElementWithName } from './types';

const renderBaseFormItem = (
  child: ReactElementWithName,
  privateProps: IPrivateProps,
) => {
  if (!child) return;

  const hasManyChildren =
    Array.isArray(child.props.children) &&
    React.Children.count(child.props.children) > 0;

  const hasValidChildren = hasManyChildren
    ? child.props.children.some((children: ReactElementWithName) =>
        React.isValidElement(children),
      )
    : React.isValidElement(child.props.children);

  if (
    child.type.displayName &&
    child.type.displayName === BaseFormItemDisplayName
  ) {
    return React.cloneElement(child, { privateProps });
  }

  if (hasValidChildren) {
    const modifiedChildren: React.ReactElement[] = React.Children.map(
      child.props.children,
      (childElement: any) => {
        return renderBaseFormItem(childElement, privateProps);
      },
    );
    return React.cloneElement(child, {}, modifiedChildren);
  }

  return child;
};

interface IBaseFormProps extends IBaseComponentProps {
  formik: FormikProps<any>;
  disabled?: boolean;
  readonly?: boolean;
  showError?: boolean;
  showErrorBlock?: boolean;
  plaintext?: boolean;
  loading?: boolean;
  withControls?: boolean;
  controlsClassName?: string;
  onCancel?: () => void;
  onSave?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  children?: React.ReactElement | Array<React.ReactElement> | React.ReactNode;
}

const BaseForm: React.FC<IBaseFormProps> = (props) => {
  const { t } = useTranslation();
  const {
    formik,
    children,
    className,
    disabled = false,
    readonly = false,
    showError = true,
    showErrorBlock = true,
    plaintext = false,
    loading = false,
    withControls = false,
    controlsClassName = '',
    confirmLabel = t('controls.save'),
    cancelLabel = t('controls.cancel'),
    onCancel,
    onSave,
  } = props;

  const { errors, values, setFieldValue } = formik;

  const controlsClassNames = classNames(s.controls, controlsClassName);

  return (
    <form className={className} onSubmit={(e) => e.preventDefault()}>
      {children &&
        React.Children.map(children, (child) =>
          renderBaseFormItem(child as ReactElementWithName, {
            errors,
            values,
            setFieldValue,
            disabled,
            readonly,
            plaintext,
            showError,
            showErrorBlock,
          }),
        )}

      {withControls && (
        <div className={controlsClassNames}>
          {onCancel && (
            <BaseButton
              type="secondary"
              label={cancelLabel}
              disabled={loading}
              onClick={onCancel}
              dataCy="form-cancel-button"
            />
          )}
          {onSave && (
            <BaseButton
              label={confirmLabel}
              loading={loading}
              onClick={onSave}
              disabled={!formik.dirty}
              dataCy="form-save-button"
            />
          )}
        </div>
      )}
    </form>
  );
};

export default BaseForm;
