const DoubleCheckIcon = (props) => (
  <svg
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 5.30004L4.47938 8.77942L12.2588 1" stroke="#FC7013" />
    <path d="M8.07959 7.656L9.47938 8.77942L17.2588 1" stroke="#FC7013" />
  </svg>
);

export default DoubleCheckIcon;
