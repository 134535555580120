import i18next from 'i18next';
import { capitalize } from 'utils';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import type { ISmsActivity } from 'infrastructure/interfaces';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

export const columns = (): IBaseTableColumns<ISmsActivity> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'createdAt',
      width: '8%',
      render(value) {
        return (
          <span data-cy="sms-date">
            {DateFormatter({
              date: value,
              format: DateFormats['MM-dd-yyyy h:mm a'],
            })}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.smsTextElectronicallySignedBy'),
      key: 'message',
      width: '52%',
      render(value) {
        return <span data-cy="sms-message">{value}</span>;
      },
    },
    {
      label: i18next.t('labels.from'),
      key: 'from',
      width: '15%',
      render(value: any) {
        return <span data-cy="sms-from">{value}</span>;
      },
    },
    {
      label: i18next.t('labels.to'),
      key: 'to',
      width: '15%',
      render(value: any) {
        return <span data-cy="sms-to">{value}</span>;
      },
    },
    {
      label: i18next.t('labels.status'),
      key: 'status',
      width: '10%',
      render(value: string) {
        return <span data-cy="sms-status">{capitalize(value)}</span>;
      },
    },
  ];
};
