import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const diagnosisValidationSchema = () =>
  createValidationSchema({
    bloodGlucose: commonValidationSchemas.string().max(255),
    bloodPressure: commonValidationSchemas.string().max(255),
    weight: commonValidationSchemas.string().max(255),
    pulmonary: commonValidationSchemas.string().max(255),
    sleep: commonValidationSchemas.string().max(255),
    allCodes: commonValidationSchemas.string().max(255),
  });
