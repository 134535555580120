import InteractiveTimeReportFilters from 'components/templates/rpm/practice/interactiveReportV2/filters';
import InteractiveTimeReportHeader from 'components/templates/rpm/practice/interactiveReportV2/header';
import InteractiveTimeReportTable from 'components/templates/rpm/practice/interactiveReportV2/table';
import { useInteractiveTimeDashboardData } from 'infrastructure/hooks/interactiveTimeDashboard/useData';
import { useInteractiveTimeDashboardFilters } from 'infrastructure/hooks/interactiveTimeDashboard/useFilters';
import { useInteractiveTimeDashboardSendReport } from 'infrastructure/hooks/interactiveTimeDashboard/useSendReport';

import type { FC } from 'react';

const InteractiveTimeReportV2: FC = () => {
  const { resetFilters, appendFilters, changePage, filters, filtersCount } =
    useInteractiveTimeDashboardFilters();
  const { data, isLoading } = useInteractiveTimeDashboardData(filters);
  const { isLoading: sendReportIsLoading, sendReportToEmail } =
    useInteractiveTimeDashboardSendReport(filters);

  return (
    <>
      <InteractiveTimeReportHeader
        sendReportIsLoading={sendReportIsLoading}
        sendReportToEmail={sendReportToEmail}
      />
      <InteractiveTimeReportFilters
        appendFilters={appendFilters}
        filtersCount={filtersCount}
        resetFilters={resetFilters}
        filters={filters}
      />
      <InteractiveTimeReportTable
        interactiveTime={data}
        filters={filters}
        isLoading={isLoading}
        changePage={changePage}
      />
    </>
  );
};

export default InteractiveTimeReportV2;
