import { useQuery, useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { ClinicThresholds } from 'infrastructure/classes/clinic-thresholds';

import useApi from '../use-api';

import type { IClinicThresholds } from 'infrastructure/interfaces';

const clinicThresholdsGetApi = (clinicGuid: string) =>
  `core/clinics/${clinicGuid}/thresholds`;

const clinicThresholdsUpdateApi = `core/clinics/thresholds`;

export const getClinicThresholdsQueryKey = (clinicGuid: string) => [
  'clinicThresholds',
  clinicGuid,
];

interface IUseClinicThresholds {
  thresholds: ClinicThresholds;
  loading: boolean;
  updateClinicThresholds: (body: IClinicThresholds) => Promise<void>;
}

interface IUseClinicThresholdsProps {
  clinicGuid: string;
}

export const useClinicThresholds = (
  props: IUseClinicThresholdsProps,
): IUseClinicThresholds => {
  const { clinicGuid } = props;

  const { loadData, updateData } = useApi();

  const queryClient = useQueryClient();
  const queryKey = getClinicThresholdsQueryKey(clinicGuid);

  const { data: thresholds = {} as IClinicThresholds, isLoading } = useQuery({
    queryKey,
    queryFn: async () =>
      loadData<IClinicThresholds>(clinicThresholdsGetApi(clinicGuid)).then(
        (res) => {
          if (res) {
            return new ClinicThresholds(res);
          }
        },
      ),
    enabled: Boolean(clinicGuid),
    staleTime: STALE_TIME,
  });

  const updateClinicThresholds = async (
    body: IClinicThresholds,
  ): Promise<void> => {
    const data = await updateData(clinicThresholdsUpdateApi, body);
    queryClient.setQueryData(queryKey, data);
  };

  return {
    thresholds,
    loading: isLoading,
    updateClinicThresholds,
  };
};
