import i18next from 'i18next';
import { Genders } from 'infrastructure/enums';

export interface IGenderItem {
  id: Genders;
  name: string;
}

export const gendersDataSource = (): IGenderItem[] => {
  return [
    {
      id: Genders.Male,
      name: i18next.t('genders.male'),
    },
    {
      id: Genders.Female,
      name: i18next.t('genders.female'),
    },
  ];
};
