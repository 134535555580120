import React, { Suspense, useEffect, useState } from 'react';
import Loader from 'components/atoms/Loader';
import BaseButton from 'components/atoms/baseButton';
import BaseRichTextEditor from 'components/atoms/base-rich-text-editor';
import CheckIcon from 'components/atoms/icons/checkIcon';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

interface IEditModeProps {
  onSubmit: (value: string) => void;
  onCancel: () => void;
  defaultValue: string;
  loading: boolean;
}

const EditMode: React.FC<IEditModeProps> = ({
  defaultValue,
  onSubmit,
  onCancel,
  loading,
}) => {
  const [value, setValue] = useState(defaultValue);
  const { t } = useTranslation();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className={s['edit-mode-wrapper']}>
      <Suspense fallback={<Loader />}>
        <BaseRichTextEditor
          value={value}
          setValue={setValue}
          disabled={loading}
        />
      </Suspense>

      <div className={s['edit-mode-actions']}>
        <BaseButton
          dataCy="patient-key-note-save-button"
          type="secondary"
          onClick={() => onCancel()}
          disabled={loading}
          label={t('labels.cancel')}
        />

        <BaseButton
          dataCy="patient-key-note-save-button"
          onClick={() => {
            onSubmit(value);
          }}
          disabled={loading}
          label={loading ? t('labels.saving') : t('labels.save')}
          icon={<CheckIcon />}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default EditMode;
