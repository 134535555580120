import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import {
  DateFormatter,
  endOfDate,
  startOfDate,
} from 'infrastructure/functions';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/interactiveTimeDashboard/types';
import type { FC } from 'react';
import type { DateRange } from 'infrastructure/types';

interface Props {
  filters: Filters;
  appendFilters: AppendFilters;
}

const InteractionDateRange: FC<Props> = ({ appendFilters, filters }) => {
  const changeTimeRange = async (range?: DateRange) => {
    if (range) {
      appendFilters({
        interactionFlagStartTime: startOfDate(range[0]).getTime(),
        interactionFlagEndTime: endOfDate(range[1]).getTime(),
      });
    }
  };

  const selectedRange: [string, string] | undefined =
    filters.interactionFlagStartTime && filters.interactionFlagEndTime
      ? [
          DateFormatter({ date: new Date(filters.interactionFlagStartTime) }),
          DateFormatter({ date: new Date(filters.interactionFlagEndTime) }),
        ]
      : undefined;

  const { t } = useTranslation();

  return (
    <div style={{ width: '100%' }}>
      <div className={s['interaction-date-range']}>
        <label htmlFor="interactionDateRange">
          {t('labels.interactionDateRange')}
        </label>
        <div className={s['input-wrapper']}>
          <BaseRangeDatepicker
            withPresets
            defaultValue={selectedRange}
            onChange={(dates) => changeTimeRange(dates)}
          />
        </div>
      </div>
    </div>
  );
};

export default InteractionDateRange;
