import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const ChatFilterActiveIcon = ({
  color = COLORS.TELOPEA,
  ...props
}: IIconProps) => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 3.5C1.5 3.22386 1.72386 3 2 3H14C14.2761 3 14.5 3.22386 14.5 3.5V5.5C14.5 5.62352 14.4543 5.74267 14.3716 5.83448L10 10.6919V15.5C10 15.7152 9.86228 15.9063 9.65811 15.9743L6.65811 16.9743C6.50564 17.0252 6.33803 16.9996 6.20764 16.9056C6.07726 16.8116 6 16.6607 6 16.5V10.6919L1.62835 5.83448C1.54572 5.74267 1.5 5.62352 1.5 5.5V3.5ZM2.5 4V5.30813L6.87165 10.1655C6.95428 10.2573 7 10.3765 7 10.5V15.8063L9 15.1396V10.5C9 10.3765 9.04572 10.2573 9.12835 10.1655L13.5 5.30813V4H2.5Z"
      fill={color}
    />
    <circle cx="13.5" cy="3.5" r="3.5" fill="#FB9318" />
  </svg>
);

export default ChatFilterActiveIcon;
