import React from 'react';
import PropTypes from 'prop-types';
import common from 'constants/common';

const PatientStatus = ({ status }) => (
  <td
    data-cy="status-report__table-status-col"
    data-label="Patient Status"
    style={{
      backgroundColor: common.statusPatients[status]?.color,
      color: common.statusPatients[status]?.color ? '#fff' : '#000',
    }}
  >
    {common.statusPatients[status]?.label || status}
  </td>
);

PatientStatus.propTypes = {
  status: PropTypes.string,
};

export default PatientStatus;
