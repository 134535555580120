export enum DeviceAlertStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Snoozed = 'snoozed',
}

export enum DeviceChangeAlertStatus {
  ChangeInProgress = 'change-in-progress',
  ChangeRequestFailed = 'change-failed',
}
