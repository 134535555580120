import { useEffect, useState } from 'react';
import { del, get, set } from 'idb-keyval';

const useCacheString = ({ value, setValue, cacheId }) => {
  const deleteCache = () => del(cacheId);
  const [cacheLoading, setCacheLoading] = useState(false);

  useEffect(() => {
    if (!cacheId) return;
    setCacheLoading(true);
    get(cacheId)
      .then((valueFromCache) => {
        if (valueFromCache) setValue(valueFromCache);
      })
      .finally(() => setCacheLoading(false));
  }, [cacheId]);

  useEffect(() => {
    if (!cacheId || cacheLoading) return;
    if (!value) {
      deleteCache();
      return;
    }
    set(cacheId, value);
  }, [value]);

  return {
    cacheLoading,
    deleteCache,
  };
};

export default useCacheString;
