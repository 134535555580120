/* eslint-disable no-useless-catch */
// @ts-ignore
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { skipEmptyStrings } from 'utils/queryHelpers';
import dataService from 'services/dataService';
import { CustomError } from 'infrastructure/classes/custom-error';

import useCheckMount from './utils/use-check-mount';
import { showResult } from './utils';

import type { IFilters } from 'infrastructure/interfaces';
import type { IResponse } from 'services/dataService';

export const apiUrlGenerator = (url: string, filters?: IFilters) => {
  let endpoint = url;

  if (filters) {
    const trimmedFilters = skipEmptyStrings(filters);
    endpoint = `${endpoint}?${queryString.stringify(trimmedFilters)}`;
  }
  return endpoint;
};

type UseApi = {
  loadData: <TResponse, TFilters = any>(
    url: string,
    filters?: TFilters & IFilters,
  ) => Promise<TResponse | void>;
  createData: <TResponse = any>(url: string, body: any) => Promise<TResponse>;
  updateData: <TResponse = any>(url: string, body: any) => Promise<TResponse>;
  deleteData: (url: string, guid: string) => Promise<void>;
  deleteDataWithBody: (url: string, body: any) => Promise<void>;
  loading: boolean;
  isMounted: boolean;
};

interface IUseApiProps {
  showError?: boolean;
  canAbort?: boolean;
}

const useApi = (props?: IUseApiProps): UseApi => {
  const { showError = true, canAbort = false } = props ?? {};
  const { isMounted } = useCheckMount();

  const [loading, setLoading] = useState(false);
  const abortController: AbortController = new AbortController();

  const setLoadingState = (state: boolean) => {
    if (isMounted) {
      setLoading(state);
    }
  };

  const catchError = (res: IResponse<any>) => {
    const customError = {} as Omit<IResponse<any>, 'data'>;
    const { error, errorName, status } = res;

    if (error) Object.assign(customError, { error });
    if (errorName) Object.assign(customError, { errorName });
    if (status) Object.assign(customError, { status });

    if (Object.keys(customError).length) {
      if (customError.errorName !== 'AbortError' && showError) {
        showResult(customError.error);
      }

      throw new CustomError(customError);
    }
  };

  const fetchingData = async (
    url: string,
    filters?: IFilters,
    signal?: AbortSignal,
  ) => {
    const res = await dataService.getList(apiUrlGenerator(url, filters), {
      signal,
    });

    catchError(res);

    return res;
  };

  const loadData = async <TResponse, TFilters = any>(
    url: string,
    filters?: TFilters & IFilters,
  ) => {
    try {
      const signal = abortController ? abortController.signal : undefined;
      setLoadingState(true);
      const { data } = await fetchingData(url, filters, signal);
      return data as TResponse;
    } catch (error) {
      throw error;
    } finally {
      setLoadingState(false);
    }
  };

  const createData = async (url: string, body: any) => {
    try {
      setLoadingState(true);
      const res = await dataService.createOne(url, body);
      catchError(res);
      return res.data;
    } catch (error) {
      throw error;
    } finally {
      setLoadingState(false);
    }
  };

  const updateData = async (url: string, body: any) => {
    try {
      setLoadingState(true);
      const res = await dataService.updateOnly(url, body);
      catchError(res);
      return res.data;
    } catch (error) {
      throw error;
    } finally {
      setLoadingState(false);
    }
  };

  const deleteData = async (url: string, guid: string) => {
    try {
      setLoadingState(true);
      const res = await dataService.deleteOne(url, { id: guid });
      catchError(res);
    } catch (error) {
      throw error;
    } finally {
      setLoadingState(false);
    }
  };

  const deleteDataWithBody = async (url: string, body: any) => {
    try {
      setLoadingState(true);
      const res = await dataService.deleteObject(url, { ...body });
      catchError(res);
    } catch (error) {
      throw error;
    } finally {
      setLoadingState(false);
    }
  };

  useEffect(() => {
    return () => {
      if (abortController && canAbort) {
        abortController.abort();
      }
    };
  }, []);

  return {
    loading,
    isMounted,
    loadData,
    createData,
    updateData,
    deleteData,
    deleteDataWithBody,
  };
};

export default useApi;
