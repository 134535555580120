import type { IThresholds } from 'infrastructure/interfaces';

export class Thresholds {
  maxCriticalSystolic: number;
  minCriticalSystolic: number;
  maxCriticalDiastolic: number;
  minCriticalDiastolic: number;
  maxCriticalPulse: number;
  minCriticalPulse: number;
  maxCriticalSleepPulse: number;
  minCriticalSleepPulse: number;
  maxCriticalGlucose: number;
  minCriticalGlucose: number;
  maxCriticalWeight: number;
  minCriticalWeight: number;
  maxRiskSystolic: number;
  minRiskSystolic: number;
  maxRiskDiastolic: number;
  minRiskDiastolic: number;
  maxRiskPulse: number;
  minRiskPulse: number;
  maxRiskSleepPulse: number;
  minRiskSleepPulse: number;
  maxRiskGlucose: number;
  minRiskGlucose: number;
  maxRiskWeight: number;
  minRiskWeight: number;
  criticalBloodoxygen: number;
  riskBloodoxygen: number;
  frequencyHypertension: number;
  frequencyDiabetes: number;
  frequencyPulse: number;
  frequencyBloodoxygen: number;
  frequencyWeight: number;
  baseLineWeight: number;
  minRiskPef: number;
  minCriticalPef: number;
  minCriticalFev1: number;

  constructor(data: IThresholds) {
    this.maxCriticalSystolic = data.maxCriticalSystolic;
    this.minCriticalSystolic = data.minCriticalSystolic;
    this.maxCriticalDiastolic = data.maxCriticalDiastolic;
    this.minCriticalDiastolic = data.minCriticalDiastolic;
    this.maxCriticalPulse = data.maxCriticalPulse;
    this.minCriticalPulse = data.minCriticalPulse;
    this.maxCriticalSleepPulse = data.maxCriticalSleepPulse;
    this.minCriticalSleepPulse = data.minCriticalSleepPulse;
    this.maxCriticalGlucose = data.maxCriticalGlucose;
    this.minCriticalGlucose = data.minCriticalGlucose;
    this.maxCriticalWeight = data.maxCriticalWeight;
    this.minCriticalWeight = data.minCriticalWeight;
    this.maxRiskSystolic = data.maxRiskSystolic;
    this.minRiskSystolic = data.minRiskSystolic;
    this.maxRiskDiastolic = data.maxRiskDiastolic;
    this.minRiskDiastolic = data.minRiskDiastolic;
    this.maxRiskPulse = data.maxRiskPulse;
    this.minRiskPulse = data.minRiskPulse;
    this.maxRiskSleepPulse = data.maxRiskSleepPulse;
    this.minRiskSleepPulse = data.minRiskSleepPulse;
    this.maxRiskGlucose = data.maxRiskGlucose;
    this.minRiskGlucose = data.minRiskGlucose;
    this.maxRiskWeight = data.maxRiskWeight;
    this.minRiskWeight = data.minRiskWeight;
    this.criticalBloodoxygen = data.criticalBloodoxygen;
    this.riskBloodoxygen = data.riskBloodoxygen;
    this.frequencyHypertension = data.frequencyHypertension;
    this.frequencyDiabetes = data.frequencyDiabetes;
    this.frequencyPulse = data.frequencyPulse;
    this.frequencyBloodoxygen = data.frequencyBloodoxygen;
    this.frequencyWeight = data.frequencyWeight;
    this.baseLineWeight = data.baseLineWeight;
    this.minRiskPef = data.minRiskPef;
    this.minCriticalPef = data.minCriticalPef;
    this.minCriticalFev1 = data.minCriticalFev1;
  }

  static getDefaultThresholds() {
    return new Thresholds({
      maxCriticalSystolic: 160,
      minCriticalSystolic: 85,
      maxCriticalDiastolic: 90,
      minCriticalDiastolic: 50,
      maxCriticalPulse: 100,
      minCriticalPulse: 50,
      maxCriticalSleepPulse: 130,
      minCriticalSleepPulse: 20,
      maxCriticalGlucose: 350,
      minCriticalGlucose: 60,
      maxRiskSystolic: 130,
      minRiskSystolic: 100,
      maxRiskDiastolic: 88,
      minRiskDiastolic: 60,
      maxRiskPulse: 90,
      minRiskPulse: 60,
      maxRiskSleepPulse: 119,
      minRiskSleepPulse: 30,
      maxRiskGlucose: 200,
      minRiskGlucose: 80,
      criticalBloodoxygen: 93,
      riskBloodoxygen: 94,
      maxCriticalWeight: 3,
      minCriticalWeight: 3,
      maxRiskWeight: 2,
      minRiskWeight: 2,
      frequencyHypertension: 0,
      frequencyDiabetes: 0,
      frequencyBloodoxygen: 0,
      frequencyPulse: 0,
      frequencyWeight: 0,
      minRiskPef: 120,
      minCriticalPef: 85,
      minCriticalFev1: 1.0,
      baseLineWeight: 0,
    });
  }
}
