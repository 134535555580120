import { type FC } from 'react';
import EllipseIcon from 'components/atoms/icons/ellipses';

import s from './styles.module.scss';

interface Props {
  title: string;
  count: string | number;
  color: string;
}

const Tile: FC<Props> = ({ title, count, color }) => {
  return (
    <div className={s['tile-wrapper']}>
      <div className={s['title-wrapper']}>{title}</div>
      <div className={s['count-wrapper']}>
        <div className={s['ellipse-wrapper']} style={{ color }}>
          <EllipseIcon />
        </div>
        <div className={s['count-number']}>{count}</div>
      </div>
    </div>
  );
};

export default Tile;
