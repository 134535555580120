import React from 'react';
import PropTypes from 'prop-types';

import HeaderItem from './headerItem';

const ReportHeader = ({ count }) => (
  <>
    {count && (
      <>
        <HeaderItem
          data={count.activePatients}
          title="Total Active Patients"
          dataCy="performance-review__header__activePatients"
        />
        <HeaderItem
          data={count.patientWithReadings}
          title="Patients With Readings"
          dataCy="performance-review__header__patientsWithReading"
        />
        <HeaderItem
          data={count.totalReadingCount}
          title="#Of Readings"
          dataCy="performance-review__header__readingCount"
        />
        <HeaderItem
          data={count.totalPatientChurn}
          title="Patient Churn"
          dataCy="performance-review__header__patientChurn"
          reversed
        />
        <HeaderItem
          data={count.revenue}
          isRevenue
          title="Revenue"
          dataCy="performance-review__header__revenue"
        />
      </>
    )}
  </>
);

ReportHeader.propTypes = {
  count: PropTypes.object,
};

export default ReportHeader;
