import React from 'react';
import ConversationHeader from 'components/atoms/chat/conversationHeader';
import ConversationsList from 'components/molecules/smsDashboard/conversationsList';
import ConversationTabs from 'components/atoms/chat/conversationTabs';

const Conversations = () => {
  return (
    <div className="conversations">
      <ConversationTabs />
      <ConversationHeader />
      <ConversationsList />
    </div>
  );
};

export default Conversations;
