import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withLoader from 'components/organisms/withLoader';
import { scrollToErrorIfExist } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';
import Button from 'components/atoms/button';
import { Formik, Form } from 'formik';
import forms from 'constants/forms';
import { Card, CardBody, Table } from 'reactstrap';
import validationSchema from 'components/organisms/adminPanels/agencies/validationSchema';
import requestBodyGenerator from 'components/organisms/adminPanels/agencies/requestBodyGenerator';
import Contact from 'components/organisms/adminPanels/agencies/components/contact';
import Address from 'components/organisms/adminPanels/agencies/components/address';
import IRS from 'components/organisms/adminPanels/agencies/components/irs';
import Registration from 'components/organisms/adminPanels/agencies/components/registration';
import { useNavigate } from 'react-router-dom';

const EditAgency = () => {
  const navigate = useNavigate();
  const formRegistration = [...forms.adminPanelsAgency.agencyEdit.registration];
  const {
    adminPanelAgencies: { editAgency },
  } = useDispatch();
  const agency = useSelector((state) => state.adminPanelAgencies.agency);

  const { getAgency: agencyDetailsLoading, editAgency: submitLoading } =
    useSelector((state) => state.loading.effects.adminPanelAgencies);

  const submitHandler = async (agencyRequest) => {
    const body = requestBodyGenerator(agencyRequest);
    const { data, error } = await editAgency(body);
    if (error) return showResult(error);
    if (data) {
      showResult();
      navigate(`/admin/agency/${data.guid}`);
    }
  };

  return (
    <div className="admin-patient">
      {withLoader(
        agencyDetailsLoading,
        <>
          {agency && (
            <Card className="card-border">
              <CardBody>
                <div className="admin-patient-header">
                  <span>Edit HHA's Page</span>
                </div>
                <Formik
                  onSubmit={submitHandler}
                  initialValues={{ ...agency }}
                  validationSchema={validationSchema()}
                >
                  {({ errors, values }) => (
                    <Form>
                      <div className="admin-patient-edit-form">
                        <Table>
                          <tbody>
                            <tr>
                              <td>
                                <Contact errors={errors} values={values} />
                              </td>
                              <td>
                                <Address errors={errors} values={values} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Registration
                                  formRegistration={formRegistration}
                                  errors={errors}
                                  values={values}
                                />
                              </td>
                              <td>
                                <IRS errors={errors} values={values} />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="text-center">
                        <Button
                          disabled={submitLoading && errors}
                          type="submit"
                          text="Save"
                          data-cy="save-button"
                          handleClick={scrollToErrorIfExist}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </>,
      )}
    </div>
  );
};

export default EditAgency;
