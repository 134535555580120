import React from 'react';
import PropTypes from 'prop-types';

import CompareWeeks from './compareWeeks';

const WEEKLY_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const ReadingsTable = ({ weeks, displayPercentage = false, dataCy }) => (
  <div className="table-outer">
    <table
      data-cy={dataCy}
      className="table-border table-zebra"
      cellSpacing="0"
      cellPadding="0"
    >
      <thead>
        <tr>
          <th> </th>
          {WEEKLY_DAYS.map((day, index) => (
            <th key={index}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {weeks.map((week, index) => (
          <tr key={index}>
            <td>{week.weekDate}</td>
            <CompareWeeks
              current={week}
              previous={weeks[index + 1]}
              percent={displayPercentage}
            />
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

ReadingsTable.propTypes = {
  weeks: PropTypes.array,
  displayPercentage: PropTypes.bool,
  dataCy: PropTypes.string,
};

export default ReadingsTable;
