import common from 'constants/common';
import classNames from 'classnames';

import s from './styles.module.scss';
import ButtonLoadingIcon from '../icons/buttonLoadingIcon';

import type { FC, JSXElementConstructor, SVGProps } from 'react';

export interface PatientControlButtonProps {
  label: string;
  status?: string;
  Icon: JSXElementConstructor<SVGProps<SVGSVGElement>>;
  handleClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  extraAccent?: boolean;
  active?: boolean;
}

const PatientControlButton: FC<PatientControlButtonProps> = ({
  label,
  status,
  Icon,
  handleClick,
  loading,
  disabled,
  extraAccent,
  active,
}) => {
  return (
    <button
      className={classNames(s.wrapper, {
        [s['extra-accent']]: extraAccent,
        [s.active]: active,
      })}
      data-cy={`control-panel-button-${label
        .toLowerCase()
        .split(' ')
        .join('-')}`}
      onClick={handleClick}
      disabled={loading || disabled}
    >
      {status ? (
        <div className={s.box}>
          <p className={s.label}>{label}</p>
          <p className={s.status}>{status}</p>
        </div>
      ) : (
        <p className={s['simple-label']}>{label}</p>
      )}
      <div className={s.icon}>
        {loading ? (
          <ButtonLoadingIcon
            color={common.disabledIconColor}
            className={s['spin-loading-button']}
          />
        ) : (
          <Icon />
        )}
      </div>
    </button>
  );
};
export default PatientControlButton;
