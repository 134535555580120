import { makeid } from 'utils/helpers/makeid';
import { prepareDoctorName, DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import { CallComment } from './call-comment';

import type { DateString } from 'infrastructure/types';
import type { IComment } from 'infrastructure/interfaces';
import type { ModuleType, CommentType } from 'infrastructure/enums';

export class Comment {
  key: string;
  patientGuid: string;
  commentType: ModuleType;
  type: CommentType;
  message: string;
  createdDate: DateString;
  createdAt: DateString;
  createdBy: string;
  createdFullName: string;
  createdByTitle?: string;
  createdByProfessionType?: string;
  createdByFirstName?: string;
  createdByLastName?: string;
  hidden: boolean;
  duration?: number;
  callComments?: Array<CallComment>;

  constructor(data: IComment) {
    this.key = makeid(5);
    this.patientGuid = data.patientGuid;
    this.commentType = data.commentType;
    this.type = data.type;
    this.message = data.message;
    this.createdFullName = prepareDoctorName({
      firstName: data.createdByFirstName,
      lastName: data.createdByLastName,
      professionType: data.createdByProfessionType,
      title: data.createdByTitle,
    });
    this.createdBy = data.createdBy;
    this.createdDate = DateFormatter({
      date: data.createdAt,
      format: DateFormats['MMM dd, yyyy'],
    });
    this.createdAt = data.createdAt;
    this.createdByTitle = data.createdByTitle;
    this.createdByProfessionType = data.createdByProfessionType;
    this.createdByFirstName = data.createdByFirstName;
    this.createdByLastName = data.createdByLastName;
    this.hidden = data.hidden;
    this.duration = data.duration;
    this.callComments =
      data.callComments && data.callComments.map((el) => new CallComment(el));
  }
}
