import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import BaseModal from 'components/atoms/base-modal';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseTextArea from 'components/atoms/base-textarea';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import BaseButton from 'components/atoms/baseButton';

import { clinicReferralTypeChangesReasonValidationSchema } from './validation-schema';

import type { IClinicReferralTypesChangesReason } from 'infrastructure/interfaces';

const FormName = 'ClinicReferralTypeChangesReason';

export interface IClinicReferralTypeChangesReasonRef {
  open: () => Promise<IClinicReferralTypesChangesReason>;
}

interface IProps {}

const ClinicReferralTypeChangesReason = forwardRef<
  IClinicReferralTypeChangesReasonRef,
  IProps
>((props, ref) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const popupController = useRef<{
    resolve: (value: IClinicReferralTypesChangesReason) => void;
    reject: (reason?: any) => void;
  } | null>(null);

  const formik = useFormik<IClinicReferralTypesChangesReason>({
    initialValues: {
      referralTypeDeleteReason: '',
    },
    onSubmit: (val) => {
      popupController.current?.resolve(val);
      setVisible(false);
    },
    validateOnChange: false,
    validationSchema: clinicReferralTypeChangesReasonValidationSchema(),
  });

  const open = async () => {
    setVisible(true);
    return new Promise((resolve, reject) => {
      popupController.current = { resolve, reject };
    }) as Promise<IClinicReferralTypesChangesReason>;
  };

  const close = (data: any) => {
    setVisible(false);
    if (popupController.current) {
      popupController.current.resolve(data);
    }
  };

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <BaseModal
      open={visible}
      smallBodyPaddingWithFooter
      title={t('labels.clinicReferralTypeUpdate')}
      onCancel={() => close(false)}
      withFooter={[
        <BaseButton
          dataCy="form-save-button"
          label={t('controls.save')}
          onClick={formik.submitForm}
        />,
      ]}
    >
      <BaseForm formik={formik}>
        <BaseFormItem name="referralTypeDeleteReason">
          <BaseTextArea
            placeholder={t('messages.pleaseIndicateTheReasonForTheUpdate')}
            rows={8}
          />
        </BaseFormItem>
      </BaseForm>
    </BaseModal>
  );
});

ClinicReferralTypeChangesReason.displayName = FormName;

export default ClinicReferralTypeChangesReason;
