import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PatientInfo from './patientInfo';
import StatusesInfo from './statuses';
import patientInfoStyles from './patientInfo/style.module.scss';
import InteractiveTime from './interactiveTime';
import InteractiveTimeGroup from './interactiveTimeGroup';
import CareAgents from './careAgents';
import ProviderClinicInfo from './providerClinicInfo';
import Appointments from './appointments';

import type { IModifiedTableColumn } from 'infrastructure/antd/interfaces/i-modified-table-column';
import type { CcmAgentItem } from 'infrastructure/hooks/ccmAgentDashboard/types';

const isMain = (rec: CcmAgentItem) => {
  if (!rec?.children) {
    return false;
  }
  return !!rec?.children.length;
};

interface IProps {
  expandedRowKeys: string[];
  toggleExpanded: (guid: string) => void;
}

export const useColumns = ({
  expandedRowKeys,
  toggleExpanded,
}: IProps): IModifiedTableColumn<CcmAgentItem>[] => {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    const isExpanded = (key: string) =>
      expandedRowKeys.some((el) => el === key);
    const cols: IModifiedTableColumn<CcmAgentItem>[] = [
      {
        title: t('labels.patientInfo'),
        width: '285px',
        onCell: (rec) => ({
          colSpan: isMain(rec) ? 6 : 1,
          className: isMain(rec) ? patientInfoStyles.td : undefined,
          width: 285,
        }),
        render: (_, item) => {
          return isMain(item) ? (
            <PatientInfo
              expanded={isExpanded(item.key)}
              item={item}
              onToggle={toggleExpanded}
            />
          ) : (
            <StatusesInfo item={item} />
          );
        },
      },
      {
        title: t('labels.careAgents'),
        width: '190px',
        onCell: (rec) => ({
          colSpan: isMain(rec) ? 0 : 1,
          width: 190,
        }),
        render: (_, item) => <CareAgents item={item} />,
      },
      {
        title: t('labels.providerClinicInfo'),
        width: '200px',
        onCell: (rec) => ({
          colSpan: isMain(rec) ? 0 : 1,
          width: 200,
        }),
        render: (_, item) => <ProviderClinicInfo item={item} />,
      },
      {
        title: t('labels.interactiveTimeBillingGroup'),
        width: '150px',
        align: 'center',
        onCell: (rec) => ({
          colSpan: isMain(rec) ? 0 : 1,
          width: 150,
        }),
        render: (_, item) => <InteractiveTimeGroup item={item} />,
      },
      {
        title: t('labels.totalInteractiveTime'),
        width: '150px',
        align: 'center',
        onCell: (rec) => ({
          colSpan: isMain(rec) ? 0 : 1,
          width: 150,
        }),
        render: (_, item) => <InteractiveTime item={item} />,
      },
      {
        title: t('labels.appointments'),
        sorter: true,
        sortKey: 'NextAppointment.scheduledDate',
        onCell: (rec) => ({
          colSpan: isMain(rec) ? 0 : 1,
          style: {
            padding: 0,
          },
        }),
        render: (_, item) => <Appointments item={item} />,
      },
    ];
    return cols;
  }, [expandedRowKeys, toggleExpanded]);
  return columns;
};
