import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isOnboardingPatient } from 'utils/userTypeHelper';
import { onboardingStatuses } from 'constants/patient_statuses';

import PatientStatus from '../patientsStatusReport/table/status';

const ActivityRow = ({ patient }) => (
  <tr className="activity-row">
    <td data-label="GUID">{patient.guid}</td>
    <td data-label="Patient Name" className="patient-name">
      {isOnboardingPatient(onboardingStatuses, patient.status) ? (
        <strong>{patient.patientName}</strong>
      ) : (
        <Link to={`/patient/${patient.guid}`}>
          <strong>{patient.patientName}</strong>
        </Link>
      )}
    </td>
    <td data-label="DOB">{patient.birthDate}</td>
    <td data-label="MRN">{patient.mrn}</td>
    <PatientStatus status={patient.status} />
    <td data-label="Clinic Name">{patient.clinicName}</td>
    <td data-label="Available Days" className="zebra available-days">
      {patient.availableDays}
    </td>
    <td data-label="# Of Actual Readings" className="zebra">
      {patient.readingCount}
    </td>
    <td data-label="Adherence" className="zebra">
      {patient.adherence}
    </td>
  </tr>
);
ActivityRow.propTypes = {
  patient: PropTypes.shape().isRequired,
};

export default ActivityRow;
