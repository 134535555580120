import React from 'react';
import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminAgencyDoctorUpdateBlock from 'components/templates/admin/hhaDoctorEdit';

const AdminAgencyDoctorEdit = () => (
  <Container>
    <AdminHeader />
    <AdminAgencyDoctorUpdateBlock />
  </Container>
);

export default AdminAgencyDoctorEdit;
