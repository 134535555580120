import React, { useState, useEffect } from 'react';
import BaseButton from 'components/atoms/baseButton';
import ChevronDoubleDownIcon from 'components/atoms/icons/chevronDoubleDownIcon';

import s from './styles.module.scss';

interface IExpandButton {
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  scrollableElementRef: any;
  dependencies: any;
}

const ExpandButton: React.FC<IExpandButton> = ({
  expanded,
  setExpanded,
  scrollableElementRef,
  dependencies,
}) => {
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const element = scrollableElementRef.current;

    if (element) {
      const isElementScrollable =
        element.scrollHeight >= element.clientHeight ||
        element.scrollWidth >= element.clientWidth;

      setIsScrollable(isElementScrollable);
    } else {
      setIsScrollable(false);
    }
  }, dependencies);

  return isScrollable ? (
    <div className={s['expand-button-wrapper']}>
      <BaseButton
        dataCy="patient-key-note-expand-button"
        icon={<ChevronDoubleDownIcon />}
        onClick={() => {
          setExpanded(!expanded);
          if (scrollableElementRef.current) {
            scrollableElementRef.current.scrollTop = 0;
          }
        }}
      />
    </div>
  ) : (
    <></>
  );
};

export default ExpandButton;
