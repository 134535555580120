import i18next from 'i18next';
import { ReadingType } from 'infrastructure/enums';

export interface IReadingTypeItem {
  id: ReadingType;
  name: string;
}

export const readingTypesDataSource = (): IReadingTypeItem[] => {
  return [
    {
      id: ReadingType.BloodPressure,
      name: i18next.t('readingTypes.BloodPressure'),
    },
    {
      id: ReadingType.BloodGlucose,
      name: i18next.t('readingTypes.BloodGlucose'),
    },
    {
      id: ReadingType.Weight,
      name: i18next.t('readingTypes.Weight'),
    },
    {
      id: ReadingType.SpO2,
      name: i18next.t('readingTypes.SpO2'),
    },
    {
      id: ReadingType.Temperature,
      name: i18next.t('readingTypes.Temperature'),
    },
    {
      id: ReadingType.SleepingMat,
      name: i18next.t('readingTypes.SleepingMat'),
    },
    {
      id: ReadingType.PeakFlow,
      name: i18next.t('readingTypes.PeakFlow'),
    },
  ];
};
