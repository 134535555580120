import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { getLanguageLabelById, normalizeTimezone } from 'utils';

const fullName = ({ title, firstName, middleName, lastName }) =>
  `${title || ''} ${firstName || ''} ${middleName || ''} ${lastName || ''}`;
const Info = ({ data }) => (
  <div className="admin-agent-block">
    <span>Personal Info</span>
    <Table>
      <tbody>
        <tr>
          <td>Name</td>
          <td data-cy="agency-user-name">{fullName(data)}</td>
        </tr>
        {data.mobileNumber && (
          <tr>
            <td>User Phone</td>
            <td data-cy="agency-user-phone">{data.mobileNumber}</td>
          </tr>
        )}
        {data.email && (
          <tr>
            <td>User Email</td>
            <td data-cy="agency-user-email">{data.email}</td>
          </tr>
        )}
        {data.primaryLanguage && (
          <tr>
            <td>Primary Language</td>
            <td data-cy="agency-user-primaryLanguage">
              {getLanguageLabelById(data.primaryLanguage)}
            </td>
          </tr>
        )}
        {data.secondaryLanguage && (
          <tr>
            <td>Secondary Language</td>
            <td data-cy="agency-user-secondaryLanguage">
              {getLanguageLabelById(data.secondaryLanguage)}
            </td>
          </tr>
        )}
        {data.birthDate && (
          <tr>
            <td>DOB</td>
            <td data-cy="agency-user-dob">{data.birthDate}</td>
          </tr>
        )}
        {data.timezone && (
          <tr>
            <td>Timezone</td>
            <td data-cy="agency-user-timezone">
              {normalizeTimezone(data.timezone)}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);

Info.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Info;
