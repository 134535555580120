import { Radio } from 'antd';

import s from './styles.module.scss';

import type { IQuestion } from 'infrastructure/interfaces';
import type { FormikProps } from 'formik';
import type { FC } from 'react';

interface Props {
  questions: IQuestion[];
  formik: FormikProps<any>;
  disabled: boolean;
}
const AnswersColumn: FC<Props> = ({ formik, questions, disabled }) => (
  <div className={s.wrapper}>
    {questions.map((q) => {
      const options = q.answers.map((a) => ({
        label: a.answer,
        value: a.guid,
      }));

      if (options.length === 2) {
        return null;
      }

      return (
        <div className={s.body}>
          <p className={s.question}>{q.question}</p>
          <div className={s.answer}>
            <Radio.Group
              disabled={disabled}
              data-cy="questionareOptionsAnswers"
              options={options}
              name={q.guid}
              className={s.radio}
              onChange={formik.handleChange}
              value={formik.values[q.guid]}
            />
          </div>
        </div>
      );
    })}
  </div>
);

export default AnswersColumn;
