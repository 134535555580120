import React from 'react';
import { motion } from 'framer-motion';

interface IBaseExpandAnimationProps {
  opened: boolean;
  children: React.ReactNode;
}

const BaseExpandAnimation: React.FC<IBaseExpandAnimationProps> = (props) => {
  const { opened, children } = props;
  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: opened ? 'auto' : 0, opacity: opened ? 1 : 0 }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ duration: 0.3 }}
      style={{ overflow: 'hidden' }}
    >
      <div>{children}</div>
    </motion.div>
  );
};

export default BaseExpandAnimation;
