export enum CcmStatuses {
  Enrolled = 'enrolled',
  NotEnrolled = 'not_enrolled',
  CCMCallingQueue = 'ccm_calling_queue',
  CCMPatientRefusedCoPayInsurance = 'ccm_patient_refused_copay_insurance',
  CCMPatientRefusedExpired = 'ccm_patient_refused_expired',
  CCMPatientRefusedChangedDoctor = 'ccm_patient_refused_changed_doctor',
  CCMPatientRefusedNotInterestedNotNecessarySuspectScam = 'ccm_patient_refused_not_interested_not_necessary_suspect_scam',
  CCMPatientRefusedWrongContactInformation = 'ccm_patient_refused_wrong_contact_information',
  CCMPatientRefusedNoContact = 'ccm_patient_refused_no_contact',
  CCMPatientRefusedWantsToDiscussWithPhysician = 'ccm_patient_refused_wants_to_discuss_with_physician',
  CCMPatientRefusedOther = 'ccm_patient_refused_other',
  CCMOnboarding = 'ccm_onboarding',
  CCMOnboardingUnsuccessfulNoContact = 'ccm_onboarding_unsuccessful_no_contact',
  CCMOnboardingUnsuccessfulPatientRefused = 'ccm_onboarding_unsuccessful_patient_refused',
  CCMOnboardingUnsuccessfulPatientNotQualifiedForCCM = 'ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm',
  CCMOnboardingUnsuccessfulChangedDoctor = 'ccm_onboarding_unsuccessful_changed_doctor',
  CCMOnboardingUnsuccessfulOther = 'ccm_onboarding_unsuccessful_other',
  CCMUnenrolledChangedDoctor = 'ccm_unenrolled_changed_doctor',
  CCMUnenrolledClientRequest = 'ccm_unenrolled_client_request',
  CCMUnenrolledCopayLossOfInsurance = 'ccm_unenrolled_copay_loss_of_insurance',
  CCMUnenrolledNonAdherent = 'ccm_unenrolled_non_adherent',
  CCMUnenrolledExpired = 'ccm_unenrolled_expired',
  CCMUnenrolledHospice = 'ccm_unenrolled_hospice',
  CCMUnenrolledLongTermSkilledNursingFacilityRehab = 'ccm_unenrolled_long_term_skilled_nursing_facility_rehab',
  CCMUnenrolledPhysicianClinicRequest = 'ccm_unenrolled_physician_clinic_request',
  CCMUnenrolledClientRequestMoving = 'ccm_unenrolled_client_request_moving',
  CCMUnenrolledHealthPlanRequestGoalsMet = 'ccm_unenrolled_health_plan_request_goals_met',
  CCMUnenrolledOther = 'ccm_unenrolled_other',
  CCMReferralOnHold = 'ccm_referral_on_hold',
  CCMReferralReceived = 'ccm_referral_received',
  CCMReferralWithdrawn = 'ccm_referral_withdrawn',
  CCMPatientNoContact = 'ccm_patient_no_contact',
}
