import BaseModal from 'components/atoms/base-modal';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import { useResetEmail } from 'utils/hooks/email/useResetEmail';

import s from './style.module.scss';

import type { FC } from 'react';

interface ResetPasswordModalProps {
  open: boolean;
  toggleOpen: () => void;
  email: string;
  to: string;
}

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({
  open,
  toggleOpen,
  email,
  to,
}) => {
  const { isLoading, resetPassword } = useResetEmail();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    await resetPassword(email);
    toggleOpen();
  };

  return (
    <BaseModal
      title={t('confirm.resetPassword')}
      open={open}
      onCancel={toggleOpen}
      withFooter={[
        <BaseButton
          dataCy="reset-pass-cancel-button"
          label={t('controls.cancel')}
          type="secondary"
          onClick={toggleOpen}
        />,
        <BaseButton
          dataCy="reset-pass-send-button"
          label={t('controls.send')}
          onClick={handleSubmit}
          disabled={isLoading}
          loading={isLoading}
        />,
      ]}
    >
      <p className={s.text}>{t('messages.resetPass', { to })}</p>
    </BaseModal>
  );
};
export default ResetPasswordModal;
