import { ICDCode } from 'infrastructure/classes/icd-code';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { arrUniqEl } from 'infrastructure/functions';

import useApi from './use-api';

import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IICDCodePaginatedData,
} from 'infrastructure/interfaces';

const getICDCodesApi = 'core/ccm/icd-ten-codes';

export interface IUseICDCodeMeta {
  icdCodeGuid?: string;
  code?: string;
}

type mergedMeta = IUseICDCodeMeta & IPaginationMeta;

type UseICDCode = {
  icdCodes: Array<ICDCode>;
  loading: boolean;
  meta: mergedMeta;
  loadICDCodes: (meta: mergedMeta, reset?: boolean) => Promise<Array<ICDCode>>;
};

interface IUseICDCodeProps {
  meta?: IUseICDCodeMeta & IPartialPaginationMeta;
}

export const useICDCode = (props?: IUseICDCodeProps): UseICDCode => {
  const options = props || {};
  const metaProps = options.meta || {};

  const { loading, loadData, isMounted } = useApi();
  const queryClient = useQueryClient();

  const [icdCodes, setICDCodes] = useState<Array<ICDCode>>([]);
  const [meta, setMeta] = useState<mergedMeta>({
    page: 1,
    items: 100,
    totalCount: 0,
    ...metaProps,
  });

  const loadICDCodes = async (filters: mergedMeta, reset = false) => {
    const queryKey = ['loadICDCodes', filters];

    const data = await queryClient.fetchQuery(
      queryKey,
      () =>
        loadData<IICDCodePaginatedData, Omit<IUseICDCodeMeta, 'sortField'>>(
          getICDCodesApi,
          {
            page: filters.page,
            items: filters.items,
            icdCodeGuid: filters.guid,
            code: filters.code,
          },
        ).then((res) => {
          if (res) {
            const { count, items: rows } = res;
            return {
              items: rows.map((el) => new ICDCode(el)),
              count,
            };
          }
        }),
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      setMeta({
        ...meta,
        ...filters,
        totalCount: data.count,
      });

      const newRows = reset
        ? data.items
        : arrUniqEl([...icdCodes, ...data.items], 'guid');
      setICDCodes(newRows);

      return newRows;
    }
    return [];
  };

  return {
    icdCodes,
    loading,
    meta,
    loadICDCodes,
  };
};
