const EmptyNoteIcon = () => (
  <svg
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M68.1621 4.86111C70.836 4.86111 73.0036 7.03751 73.0036 9.72222V48.6111C73.0036 51.2958 70.836 53.4722 68.1621 53.4722H21.752C19.1839 53.4722 16.7209 54.4965 14.905 56.3198L5.22192 66.042V9.72222C5.22192 7.03751 7.38956 4.86111 10.0635 4.86111H68.1621ZM10.0635 0C4.71564 0 0.380371 4.35279 0.380371 9.72222V71.9099C0.380371 74.0753 2.98788 75.1597 4.51289 73.6286L18.3285 59.7571C19.2365 58.8455 20.4679 58.3333 21.752 58.3333H68.1621C73.5099 58.3333 77.8452 53.9805 77.8452 48.6111V9.72222C77.8452 4.35279 73.5099 0 68.1621 0H10.0635Z"
      fill="#CCCAD7"
    />
  </svg>
);

export default EmptyNoteIcon;
