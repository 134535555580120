import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';

interface IDataProps {
  type: string;
  loginGuid: string;
}

const url = 'core/patients/rpm-dashboard-statistic';

const rpmDashboardStatisticFn = async (postData: IDataProps) => {
  const { data, error } = await dataService.createOne<{}>(url, postData);

  if (error) {
    throw new Error(error);
  }

  return data;
};

export const useRpmDashboardStatistic = () => {
  const { mutate } = useMutation({
    mutationFn: rpmDashboardStatisticFn,
  });

  return { sendStatistic: mutate };
};
