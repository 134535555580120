import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseWrapper from 'components/atoms/base-wrapper';
import PatientSmsActivityLog from 'components/molecules/admin-panel/forms/sms-activity-log';
import HospitalIcon from 'components/atoms/icons/hospital';
import PatientManualClinicNotesLog from 'components/molecules/admin-panel/forms/manual-clinic-notes-log';
import { currentMonthRange } from 'infrastructure/consts/current-month-range';

import s from './styles.module.scss';

import type { DateRange } from 'infrastructure/types';
import type { ModuleType } from 'infrastructure/enums';

interface IClinicalActivityAndInterventionsProps {
  patientGuid: string;
  module: ModuleType;
  dateRange?: DateRange;
}

const ClinicalActivityAndInterventions: React.FC<
  IClinicalActivityAndInterventionsProps
> = (props) => {
  const { patientGuid, module, dateRange = currentMonthRange } = props;

  const { t } = useTranslation();

  return (
    <BaseWrapper className={s.wrapper}>
      <div className={s.header}>
        <h3 className={s.title}>
          <i>
            <HospitalIcon />
          </i>
          {t('labels.clinicalActivityAndInterventions')}
        </h3>
      </div>
      <PatientSmsActivityLog
        patientGuid={patientGuid}
        module={module}
        dateRange={dateRange}
      />
      <div className={s['manual-clinic']}>
        {dateRange && (
          <PatientManualClinicNotesLog
            patientGuid={patientGuid}
            module={module}
            dateRange={dateRange}
          />
        )}
      </div>
    </BaseWrapper>
  );
};

export default React.memo(ClinicalActivityAndInterventions);
