import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isMacOS } from 'utils/deviceHelper';

const enterKeyCode = 13;

const TextAreaAutoHeight = ({
  value,
  height = '100px',
  maxHeight = '200px',
  hideHint,
  loading,
  onSubmit,
  ...props
}) => {
  const deviceOS = useSelector((state) => state.user.deviceOS);
  const enterKeyName = isMacOS(deviceOS) ? 'Return' : 'Enter';

  const textareaRef = useRef(null);
  useEffect(() => {
    textareaRef.current.focus();
    textareaRef.current.style.height = height ?? '100px';
    textareaRef.current.style.maxHeight = maxHeight ?? '200px';
    const { scrollHeight } = textareaRef.current;
    textareaRef.current.style.height = `${scrollHeight}px`;
  }, [value, textareaRef, loading]);

  return (
    <>
      <textarea
        value={value}
        ref={textareaRef}
        onKeyDown={(e) => {
          if (e.which === enterKeyCode && !e.shiftKey) {
            e.preventDefault();
            if (!value.trim() || loading) {
              return;
            }
            onSubmit();
          }
        }}
        disabled={loading}
        {...props}
      />
      {!hideHint && (
        <small>
          {value.length > 2 && (
            <>
              <strong>Shift + {enterKeyName}</strong> to add a new line
            </>
          )}
        </small>
      )}
    </>
  );
};

TextAreaAutoHeight.propTypes = {
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  value: PropTypes.string,
  loading: PropTypes.bool,
  hideHint: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default TextAreaAutoHeight;
