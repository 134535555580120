import React from 'react';
import DevicesThresholdsTable from 'components/molecules/admin-panel/forms/devices/table';
import BaseGrid from 'components/atoms/base-grid';
import { ModuleType } from 'infrastructure/enums';
import AdminPanelPatientThresholdsForm from 'components/molecules/admin-panel/forms/patient-thresholds';

import s from '../styles.module.scss';
import DeviceOrderSpecification from './device-order-specification';

import type { RpmStatuses } from 'infrastructure/enums';

interface IAdminTabbedFormDevicesThresholdsProps {
  patientGuid: string;
  module: ModuleType;
  rpmStatus?: RpmStatuses;
}

const AdminTabbedFormDevicesThresholds: React.FC<
  IAdminTabbedFormDevicesThresholdsProps
> = (props) => {
  const { patientGuid, module, rpmStatus } = props;

  return (
    <BaseGrid
      columns={1}
      rowGap={22}
      className={s['devises-thresholds-wrapper']}
    >
      <DevicesThresholdsTable
        patientGuid={patientGuid}
        module={module}
        rpmStatus={rpmStatus}
      />
      {module === ModuleType.ADMIN && (
        <DeviceOrderSpecification patientGuid={patientGuid} />
      )}
      <AdminPanelPatientThresholdsForm
        patientGuid={patientGuid}
        module={module}
      />
    </BaseGrid>
  );
};

export default AdminTabbedFormDevicesThresholds;
