import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBookmarkedComments } from 'infrastructure/hooks/use-bookmarked-comments';
import { makeid } from 'utils/helpers/makeid';
import Loader from 'components/atoms/Loader';
import classNames from 'classnames';

import s from './styles.module.scss';
import CommentBookmarkCard from '../card';

import type { BookmarkComment } from 'infrastructure/classes/bookmark-comment';

interface ICommentBookmarkCardProps {
  onApply: (data: BookmarkComment) => void;
}

const CommentBookmarkList: React.FC<ICommentBookmarkCardProps> = (props) => {
  const { onApply } = props;
  const { t } = useTranslation();

  const [comment, setComment] = useState<string>('');

  const {
    bookmarkComments,
    loading,
    addComment,
    updateComment,
    removeComment,
  } = useBookmarkedComments();

  const hasItems = !!bookmarkComments.length;

  const formClassNames = classNames(s.form, {
    [s.disabled]: loading,
  });

  const onSave = () => {
    addComment({
      guid: makeid(5),
      message: comment,
    });
    setComment('');
  };
  const onRemove = (data: BookmarkComment) => {
    removeComment(data);
  };

  return (
    <div>
      <ul className={s.list}>
        {loading && (
          <li className={s.loader}>
            <Loader width={50} height={50} />
          </li>
        )}
        {!loading &&
          hasItems &&
          bookmarkComments.map((message, index) => (
            <li
              key={message.key}
              data-cy={`bookmarked-comments-comment-card-${index}`}
            >
              <CommentBookmarkCard
                data={message}
                onDelete={onRemove}
                onSave={updateComment}
                onApply={onApply}
              />
            </li>
          ))}
        {!loading && !hasItems && (
          <span className={s.loader}>{t('controls.noComments')}</span>
        )}
      </ul>
      <div className={formClassNames}>
        <textarea
          className={s.area}
          rows={2}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          data-cy="bookmarked-comments-create-area"
        />
        <button
          className={s.btn}
          onClick={onSave}
          data-cy="bookmarked-comments-save-btn"
        >
          {t('controls.save')}
        </button>
      </div>
    </div>
  );
};

export default CommentBookmarkList;
