import paths from '../../constants/paths';

export const ADMIN_PANEL_USERS_ROUTES = [
  paths.smsDashboard,
  paths.adminUsers,
  paths.adminUser,
  paths.adminUserEdit,
  paths.adminUserAdd,
];

export const ADMIN_PANEL_AGENCY_DOCTORS_ROUTES = [
  paths.adminAgencyDoctorList,
  paths.adminAgencyDoctor,
  paths.adminAgencyDoctorAdd,
  paths.adminAgencyDoctorEdit,
];

export const ADMIN_PANEL_CLINICS_ROUTES = [
  paths.adminClinics,
  paths.adminClinic,
  paths.adminClinicAdd,
  paths.adminClinicEdit,
];

export const ADMIN_PANEL_AGENCIES_ROUTES = [
  paths.adminAgencies,
  paths.adminAgency,
  paths.adminAgencyEdit,
  paths.adminAgencyAdd,
];

export const ADMIN_PANEL_PATIENTS_ROUTES = [
  paths.adminPatients,
  paths.adminPatientAdd,
  paths.adminPatient,
  paths.adminPatientEdit,
  paths.adminPatientNew,
  paths.adminBulkUpload,
  paths.ccmConsent,
];

export const PATIENT_ACCEPTANCE_ROUTES = [
  paths.adminPatients,
  paths.adminPatient,
  paths.adminPatientNew,
  paths.adminPatientEdit,
  paths.profile,
  paths.ccmConsent,
];

export const RPM_ROUTES = [
  paths.profile,
  paths.patientGuid,
  paths.patientDetails,
  paths.medicareBilling,
  paths.interactiveReport,
  paths.activityReport,
  paths.adherenceReport,
  paths.adherenceReportLast30Days,
  paths.complianceReport,
  paths.onboardingStatus,
  paths.dashboard,
  paths.ccm,
  paths.clinicalMetrics,
  paths.statisticsReport,
  paths.performanceReview,
  paths.patientsStatusReport,
  paths.interactionStatus,
  paths.interactiveTimeDashboard,
];

export const RPM_PRACTICE_REPORT_ROUTES = [
  paths.profile,
  paths.patientGuid,
  paths.medicareBilling,
  paths.interactiveReport,
  paths.activityReport,
  paths.adherenceReport,
  paths.adherenceReportLast30Days,
  paths.complianceReport,
  paths.onboardingStatus,
  paths.clinicalMetrics,
  paths.statisticsReport,
  paths.performanceReview,
  paths.patientsStatusReport,
  paths.interactionStatus,
];

export const RPM_ADD_ROUTES = [paths.servicePlans, paths.practiceOverview];

export const CCM_ROUTES = [
  paths.profile,
  paths.ccm,
  paths.ccmPatient,
  paths.ccmBillingReport,
  paths.ccmBillingReportV2,
  paths.ccmInteractiveTimeReport,
  paths.dashboard,
  paths.ccmOnboardingStatus,
  paths.ccmStatusReport,
];

export const CCM_ROUTES_ADMIN_HHA = [paths.ccmAgentDashboard];

export const TURK_ADMIN_ROUTES = [
  paths.adminTurkConfigurations,
  paths.adminTurkUsersAdd,
  paths.adminTurkUsersEdit,
  paths.adminTurkUserList,
  paths.adminTurkUser,
];

export const TURK_USER_ROUTES = [paths.turkDashboard];
export const SMS_DASHBOARD_ROUTES = [paths.smsDashboard];
export const ADMIN_TOOLS_ROUTES = [
  paths.eligibilityTool,
  paths.eligibilityToolItems,
];
