import { Container } from 'reactstrap';

import Content from '../../../components/templates/ccm/consentForm';

const ConsentForm = () => (
  <div>
    <Container>
      <Content />
    </Container>
  </div>
);

export default ConsentForm;
