import React from 'react';
import { PropTypes } from 'prop-types';

const CalendarIcon = ({ width, height, ...props }) => (
  <svg
    width={width ?? '13'}
    height={height ?? '14'}
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="3.06245"
      y="0.34028"
      width="0.680561"
      height="1.41946"
      fill="#D8D8D8"
      stroke="#979797"
      strokeWidth="0.680561"
    />
    <rect
      x="3.06245"
      y="0.34028"
      width="0.680561"
      height="1.41946"
      fill="#D8D8D8"
      stroke="#2C2543"
      strokeWidth="0.680561"
    />
    <rect
      x="8.50727"
      y="0.34028"
      width="0.680561"
      height="1.41946"
      fill="#D8D8D8"
      stroke="#2C2543"
      strokeWidth="0.680561"
    />
    <rect
      x="0.5"
      y="1.8999"
      width="11.2501"
      height="11.6001"
      stroke="#2C2543"
    />
    <rect y="2.1001" width="12.2501" height="3.50003" fill="#2C2543" />
    <rect
      x="2.38227"
      y="7.34028"
      width="0.680561"
      height="0.71945"
      fill="#D8D8D8"
      stroke="#2C2543"
      strokeWidth="0.680561"
    />
    <rect
      x="5.78462"
      y="7.34028"
      width="0.680561"
      height="0.71945"
      fill="#D8D8D8"
      stroke="#2C2543"
      strokeWidth="0.680561"
    />
    <rect
      x="9.18745"
      y="7.34028"
      width="0.680561"
      height="0.71945"
      fill="#D8D8D8"
      stroke="#2C2543"
      strokeWidth="0.680561"
    />
  </svg>
);

CalendarIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default CalendarIcon;
