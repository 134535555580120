import { Radio } from 'antd';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseInput from 'components/atoms/base-input';
import BaseTextArea from 'components/atoms/base-textarea';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  MAX_CHARACTERS,
  allergyLogValidationSchema,
} from './validation-schema';
import s from './styles.module.scss';

import type { IUpdateAllergyLog } from 'infrastructure/interfaces';
import type { FormikHelpers } from 'formik';
import type { FC } from 'react';
import type { Values } from './validation-schema';

interface AllergyLogFormProps {
  onCancelForm: () => void;
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  loading: boolean;
  defaultValues?: IUpdateAllergyLog;
}

const AllergyLogForm: FC<AllergyLogFormProps> = ({
  onCancelForm,
  onSubmit,
  loading,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const formik = useFormik<Values>({
    initialValues: allergyLogValidationSchema().cast(defaultValues),
    enableReinitialize: true,
    onSubmit,
    validateOnChange: false,
    validationSchema: allergyLogValidationSchema(),
  });

  const { submitForm, resetForm } = formik;

  const onCancel = () => {
    resetForm();
    onCancelForm();
  };

  const radioOptions = [
    {
      label: t('controls.yes'),
      value: true,
    },
    {
      label: t('controls.no'),
      value: false,
    },
  ];

  const allergyFieldInfo = `${t('labels.charactersCount')}: ${
    formik.values.allergy?.length ?? 0
  }/${MAX_CHARACTERS}`;

  return (
    <BaseForm
      formik={formik}
      withControls
      plaintext={false}
      readonly={false}
      onSave={submitForm}
      onCancel={onCancel}
      loading={loading}
    >
      <BaseFormItem
        name="allergy"
        label={t('labels.allergy')}
        labelInfo={allergyFieldInfo}
        required
        isBlackLabel
      >
        <BaseInput maxLength={MAX_CHARACTERS} />
      </BaseFormItem>
      <BaseFormItem
        name="allergicResponse"
        label={t('labels.allergicResponse')}
        required
        isBlackLabel
      >
        <BaseTextArea rows={8} />
      </BaseFormItem>
      <BaseFormItem
        name="epipenRequired"
        label={t('labels.epipenRequired')}
        className={s['radio-input']}
        isBlackLabel
      >
        <Radio.Group
          options={radioOptions}
          name="epipenRequired"
          onChange={formik.handleChange}
          value={formik.values.epipenRequired}
        />
      </BaseFormItem>
    </BaseForm>
  );
};
export default AllergyLogForm;
