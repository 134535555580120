import React, { useEffect, useState } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import BaseGrid from 'components/atoms/base-grid';
import { NextAppointment } from 'infrastructure/classes/next-appointment';
import BaseDatepicker from 'components/atoms/base-datepicker';
import AppointmentMethodSelectBox from 'components/molecules/appointment-method-select-box';
import DoctorAndAgencyDoctorSelectBox from 'components/molecules/all-doctor-select-box';
import { usePatientNextAppointment } from 'infrastructure/hooks/patient/use-patient-next-appointment';
import { DateFormats, ModuleType } from 'infrastructure/enums';
import { useEdit } from 'infrastructure/providers/edit-provider';
import BaseTimePicker from 'components/atoms/base-timepicker';

import { ccmNextAppointmentValidationSchema } from './validation-schema';
import s from './styles.module.scss';

const formKey = 'AdminPanelCcmNextAppointmentForm';

interface IAdminPanelCcmNextAppointmentFormProps {
  patientGuid: string;
  module: ModuleType;
}

const AdminPanelCcmNextAppointmentForm: React.FC<
  IAdminPanelCcmNextAppointmentFormProps
> = (props) => {
  const { patientGuid, module } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const isAdmin = module === ModuleType.ADMIN;

  const { canEdit, setEdit } = useEdit();
  const { t } = useTranslation();

  const { loading, loadPatientNextAppointment, updatePatientNextAppointment } =
    usePatientNextAppointment({ patientGuid });

  const formik = useFormik<NextAppointment>({
    initialValues: new NextAppointment(patientGuid),
    onSubmit: async (values) => {
      const scheduledWithData = values.getScheduledWithData();

      await updatePatientNextAppointment({
        patientGuid,
        appointmentDetails: values.appointment
          ? {
              guid: values.appointment.guid,
              ...scheduledWithData,
              appointmentMethod: values.appointment.appointmentMethod,
              scheduledDate: values.prepareDate(
                values.appointment.scheduledDate,
                values.appointment.scheduledTime,
              ),
            }
          : undefined,
      }).then(() => {
        formik.resetForm({ values });
      });
      setIsEdit(false);
      setEdit(false);
    },
    validateOnChange: false,
    validationSchema: ccmNextAppointmentValidationSchema(),
  });

  const onDelete = () => {
    formik.setValues(new NextAppointment(patientGuid));
    formik.submitForm();
  };
  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };
  const onSave = () => {
    formik.submitForm();
  };
  const onCancel = () => {
    formik.resetForm();
    setIsEdit(false);
    setEdit(false);
  };

  const loadData = async () => {
    const data = await loadPatientNextAppointment();
    if (data) {
      formik.resetForm({ values: new NextAppointment(patientGuid, data) });
    }
  };

  useEffect(() => {
    loadData();
  }, [patientGuid]);

  return (
    <PatientBlock
      title={t('labels.ccmNextAppointment')}
      canEdit={isAdmin}
      isEdit={isEdit}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      onDelete={onDelete}
      loading={loading}
      canDelete
      disabled={!canEdit(formKey)}
    >
      <BaseForm formik={formik} plaintext={!isEdit} readonly={!isEdit}>
        <BaseGrid columns={4} className={s['inputs-wrapper']}>
          <BaseFormItem
            required
            name="appointment.scheduledDate"
            label={t('labels.appointmentDate')}
          >
            <BaseDatepicker />
          </BaseFormItem>
          <BaseFormItem
            required
            name="appointment.scheduledTime"
            label={t('labels.appointmentTime')}
          >
            <BaseTimePicker format={DateFormats['HH:mm']} />
          </BaseFormItem>
          <BaseFormItem
            required
            name="appointment.scheduledWith"
            label={t('labels.scheduledWith')}
          >
            <DoctorAndAgencyDoctorSelectBox
              patientGuid={patientGuid}
              onChange={(value, option) => {
                formik.setFieldValue('appointment.scheduledWith', value);
                formik.setFieldValue('selectedDoctor', option.record);
              }}
            />
          </BaseFormItem>
          <BaseFormItem
            required
            name="appointment.appointmentMethod"
            label={t('labels.appointmentMethod')}
          >
            <AppointmentMethodSelectBox />
          </BaseFormItem>
        </BaseGrid>
      </BaseForm>
    </PatientBlock>
  );
};

export default AdminPanelCcmNextAppointmentForm;
