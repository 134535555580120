import EligibilityHeader from 'components/templates/eligibility/process/header';
import EligibilityProcessesTable from 'components/templates/eligibility/process/table';
import EligibilityUpload from 'components/templates/eligibility/process/upload';
import { useEligibilityProcessesFilters } from 'infrastructure/hooks/eligibilityTool/useFilters';
import { useEligibilityData } from 'infrastructure/hooks/eligibilityTool/useProcessData';

const EligibilityPage = () => {
  const { changePage, filters } = useEligibilityProcessesFilters();
  const { data, isLoading, refetch } = useEligibilityData(filters);

  return (
    <div>
      <EligibilityHeader />
      <EligibilityUpload onUpload={() => refetch()} />
      <EligibilityProcessesTable
        changePage={changePage}
        filters={filters}
        count={data?.count}
        data={data?.data}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EligibilityPage;
