import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import ConditionButton from 'components/atoms/conditionButton';
import ConditionButtonMobile from 'components/atoms/conditionButton/mobile';
import { isMobile } from 'utils/deviceHelper';
import useConfirm from 'utils/useConfirm';
import { addQueryParams } from 'utils/queryHelpers';
import useUser from 'utils/useUser';
import { isSlaReadingsAllowed } from 'constants/permissions';
import { isAtRiskRole, isCriticalRole } from 'utils/accessManager/helpers';
import BaseButton from 'components/atoms/baseButton';
import { PropTypes } from 'prop-types';
import DesktopIcon from 'components/atoms/icons/desktop.svg?react';

const showConfirm = useConfirm({
  message: 'You have unsaved changes, are you sure you want to leave?',
  title: 'Confirm',
});

const ConditionFilter = ({ setMode }) => {
  const { userType, roleNames } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState('');
  const { unsavedComments } = useSelector((state) => state.doctor);
  const device = useSelector((state) => state.user.deviceType);
  const readingCount = useSelector((state) => state.doctor.readingsCount);

  const {
    doctor: { applyFilters, clearComments },
  } = useDispatch();
  const handleClick = (status) => {
    if (status === selectedStatus) {
      setSelectedStatus(null);
      applyFilters({ status: null, slaReadings: null });
      addQueryParams(
        { navigate, location },
        { status: null, page: 1, slaReadings: null },
      );
    } else {
      setSelectedStatus(status);
      applyFilters({ status, slaReadings: null });
      addQueryParams(
        { navigate, location },
        { status, page: 1, slaReadings: null },
      );
    }
  };

  const handleClickSlaReadings = () => {
    if (selectedStatus === 'slaReadings') {
      setSelectedStatus(null);
      applyFilters({ status: null, slaReadings: null });
      addQueryParams(
        { navigate, location },
        { status: null, page: 1, slaReadings: null },
      );
    } else {
      setSelectedStatus('slaReadings');
      applyFilters({ status: 'critical', slaReadings: 'true' });
      addQueryParams(
        { navigate, location },
        { status: 'critical', page: 1, slaReadings: 'true' },
      );
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get('status');
    const slaReadings = params.get('slaReadings');
    if (slaReadings === 'true' && status === 'critical') {
      setSelectedStatus('slaReadings');
    } else if (status) {
      setSelectedStatus(status);
    }
  }, []);

  useEffect(() => {
    if (location.search === '') setSelectedStatus('');
  }, [location]);

  const ignoreUnsavedChanges =
    (ok, cancel) =>
    async (...props) => {
      let confirm = true;
      if (Object.keys(unsavedComments).length) {
        confirm = await showConfirm();
      }
      if (confirm) {
        clearComments();
        return ok && ok(...props);
      }
      return cancel && cancel(...props);
    };

  const showSlaFilter = isSlaReadingsAllowed(userType);
  let buttonsCount = showSlaFilter ? 4 : 3;

  const conditionButtons = [];

  if (!isAtRiskRole(roleNames) && !isCriticalRole(roleNames))
    return (
      <div>
        <div className="activity-header">
          <h1>Real Time Patient Readings</h1>
          <BaseButton
            label="To New Design"
            type="secondary"
            onClick={() => setMode('new')}
            rtl
            icon={<DesktopIcon width={20} height={20} />}
            className="mode-btn"
          />
        </div>
        <Row>
          {showSlaFilter && (
            <Col xs={12 / buttonsCount}>
              {isMobile(device) && (
                <ConditionButtonMobile
                  status="slaReadings"
                  count={readingCount.slaReadings}
                  text="Priority"
                  handleClick={ignoreUnsavedChanges(handleClickSlaReadings)}
                />
              )}
              {!isMobile(device) && (
                <ConditionButton
                  status="slaReadings"
                  count={readingCount.slaReadings}
                  text="Priority"
                  handleClick={ignoreUnsavedChanges(handleClickSlaReadings)}
                  selectedStatus={selectedStatus}
                />
              )}
            </Col>
          )}
          <Col xs={12 / buttonsCount}>
            {isMobile(device) && (
              <ConditionButtonMobile
                status="critical"
                count={readingCount.critical}
                text="Critical"
                handleClick={ignoreUnsavedChanges(handleClick)}
              />
            )}
            {!isMobile(device) && (
              <ConditionButton
                status="critical"
                count={readingCount.critical}
                text="Critical"
                handleClick={ignoreUnsavedChanges(handleClick)}
                selectedStatus={selectedStatus}
              />
            )}
          </Col>
          <Col xs={12 / buttonsCount}>
            {isMobile(device) && (
              <ConditionButtonMobile
                status="risk"
                count={readingCount.risk}
                text="At-Risk"
                handleClick={ignoreUnsavedChanges(handleClick)}
              />
            )}
            {!isMobile(device) && (
              <ConditionButton
                status="risk"
                count={readingCount.risk}
                text="At-Risk"
                handleClick={ignoreUnsavedChanges(handleClick)}
                selectedStatus={selectedStatus}
              />
            )}
          </Col>
          <Col xs={12 / buttonsCount}>
            {isMobile(device) && (
              <ConditionButtonMobile
                status="normal"
                count={readingCount.normal}
                text="Normal"
                handleClick={ignoreUnsavedChanges(handleClick)}
              />
            )}
            {!isMobile(device) && (
              <ConditionButton
                status="normal"
                count={readingCount.normal}
                text="Normal"
                handleClick={ignoreUnsavedChanges(handleClick)}
                selectedStatus={selectedStatus}
              />
            )}
          </Col>
        </Row>
      </div>
    );

  if (isCriticalRole(roleNames)) {
    buttonsCount = isAtRiskRole(roleNames) ? 4 : 2;
    conditionButtons.push(
      <Col xs={12 / buttonsCount}>
        {isMobile(device) && (
          <ConditionButtonMobile
            status="slaReadings"
            count={readingCount.slaReadings}
            text="Priority"
            handleClick={ignoreUnsavedChanges(handleClickSlaReadings)}
          />
        )}
        {!isMobile(device) && (
          <ConditionButton
            status="slaReadings"
            count={readingCount.slaReadings}
            text="Priority"
            handleClick={ignoreUnsavedChanges(handleClickSlaReadings)}
            selectedStatus={selectedStatus}
          />
        )}
      </Col>,
    );
    conditionButtons.push(
      <Col xs={12 / buttonsCount}>
        {isMobile(device) && (
          <ConditionButtonMobile
            status="critical"
            count={readingCount.critical}
            text="Critical"
            handleClick={ignoreUnsavedChanges(handleClick)}
          />
        )}
        {!isMobile(device) && (
          <ConditionButton
            status="critical"
            count={readingCount.critical}
            text="Critical"
            handleClick={ignoreUnsavedChanges(handleClick)}
            selectedStatus={selectedStatus}
          />
        )}
      </Col>,
    );
  }
  if (isAtRiskRole(roleNames)) {
    buttonsCount = isCriticalRole(roleNames) ? 4 : 2;
    conditionButtons.push(
      <Col xs={12 / buttonsCount}>
        {isMobile(device) && (
          <ConditionButtonMobile
            status="risk"
            count={readingCount.risk}
            text="At-Risk"
            handleClick={ignoreUnsavedChanges(handleClick)}
          />
        )}
        {!isMobile(device) && (
          <ConditionButton
            status="risk"
            count={readingCount.risk}
            text="At-Risk"
            handleClick={ignoreUnsavedChanges(handleClick)}
            selectedStatus={selectedStatus}
          />
        )}
      </Col>,
    );
    conditionButtons.push(
      <Col xs={12 / buttonsCount}>
        {isMobile(device) && (
          <ConditionButtonMobile
            status="normal"
            count={readingCount.normal}
            text="Normal"
            handleClick={ignoreUnsavedChanges(handleClick)}
          />
        )}
        {!isMobile(device) && (
          <ConditionButton
            status="normal"
            count={readingCount.normal}
            text="Normal"
            handleClick={ignoreUnsavedChanges(handleClick)}
            selectedStatus={selectedStatus}
          />
        )}
      </Col>,
    );
  }

  return (
    <div>
      <div className="activity-header">
        <h1>Real Time Patient Readings</h1>
        <BaseButton
          label="To New Design"
          type="secondary"
          onClick={() => setMode('new')}
          rtl
          icon={<DesktopIcon width={20} height={20} />}
          className="mode-btn"
        />
      </div>
      <Row>
        {conditionButtons.map((button, i) => (
          <React.Fragment key={i}>{button}</React.Fragment>
        ))}
      </Row>
    </div>
  );
};

ConditionFilter.propTypes = {
  setMode: PropTypes.func.isRequired,
};

export default ConditionFilter;
