import type { FC } from 'react';

const AppointmentSmsIcon: FC<{
  width?: number;
  height?: number;
}> = ({ height = 40, width = 40 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35 2.5C36.3807 2.5 37.5 3.61929 37.5 5V25C37.5 26.3807 36.3807 27.5 35 27.5H28.75C27.1762 27.5 25.6943 28.241 24.75 29.5L20 35.8333L15.25 29.5C14.3057 28.241 12.8238 27.5 11.25 27.5H5C3.61929 27.5 2.5 26.3807 2.5 25V5C2.5 3.61929 3.61929 2.5 5 2.5H35ZM5 0C2.23858 0 0 2.23858 0 5V25C0 27.7614 2.23858 30 5 30H11.25C12.0369 30 12.7779 30.3705 13.25 31L18 37.3333C19 38.6667 21 38.6667 22 37.3333L26.75 31C27.2221 30.3705 27.9631 30 28.75 30H35C37.7614 30 40 27.7614 40 25V5C40 2.23858 37.7614 0 35 0H5Z"
      fill="#2C2543"
    />
    <path
      d="M7.5 8.75C7.5 8.05964 8.05964 7.5 8.75 7.5H31.25C31.9404 7.5 32.5 8.05964 32.5 8.75C32.5 9.44036 31.9404 10 31.25 10H8.75C8.05964 10 7.5 9.44036 7.5 8.75ZM7.5 15C7.5 14.3096 8.05964 13.75 8.75 13.75H31.25C31.9404 13.75 32.5 14.3096 32.5 15C32.5 15.6904 31.9404 16.25 31.25 16.25H8.75C8.05964 16.25 7.5 15.6904 7.5 15ZM7.5 21.25C7.5 20.5596 8.05964 20 8.75 20H21.25C21.9404 20 22.5 20.5596 22.5 21.25C22.5 21.9404 21.9404 22.5 21.25 22.5H8.75C8.05964 22.5 7.5 21.9404 7.5 21.25Z"
      fill="#2C2543"
    />
  </svg>
);

export default AppointmentSmsIcon;
