import { type FC } from 'react';
import AgenciesAutocomplete from 'components/molecules/autocompleteFilter/agencies';
import InteractiveTimeGroupSelect from 'components/molecules/select/interactiveTimeGroup';
import StateSelect from 'components/molecules/select/state';
import ServiceDeliveryModelSelect from 'components/molecules/select/serviceDeliveryModel';
import TimezoneSelect from 'components/molecules/select/timezone';
import LanguageSelect from 'components/molecules/select/language';
import AssigneeSelect from 'components/molecules/select/assignee';
import InsuranceSelect from 'components/molecules/select/insurance';
import InteractionStatusSelect from 'components/molecules/select/interactionStatus';
import { isInteractiveTimeAssignAllowed } from 'constants/permissions';
import useUser from 'utils/useUser';
import { isAdmin, isRoleNameAdministrator } from 'utils/userTypeHelper';

import ContactAvailabilityFilterSelectBox from './contact-availability-filter';
import PatientsAutocomplete from './patients';
import s from './styles.module.scss';
import AvailableBillableMinutes from './availableBillableMinutes';
import InteractionDateRange from './interactionDateRange';
import ClinicFilterSelectBox from '../../../../../molecules/include-exclude-clinic-select-box';

import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface Props {
  filters: Filters;
  appendFilters: AppendFilters;
}

const Wrapper: FC = ({ children }) => {
  return <div className={s['filter-wrapper']}>{children}</div>;
};

const Body: FC<Props> = ({ appendFilters, filters }) => {
  const { userType, roleNames } = useUser();

  const showAssigneeFilter = isInteractiveTimeAssignAllowed(userType);
  const isAdminRole = isRoleNameAdministrator(roleNames);
  const isUserAdmin = isAdmin(userType);
  const showAgenciesFilter =
    isUserAdmin || (userType !== 'practice' && isAdminRole);

  return (
    <div className={s.wrapper}>
      <Wrapper>
        <PatientsAutocomplete
          value={filters.patientGuid}
          onChange={(e) => appendFilters({ patientGuid: e })}
          showSelectAll
          withSelectedData
          showOnInteractiveTimeDashboard
        />
      </Wrapper>
      <Wrapper>
        <InteractionDateRange filters={filters} appendFilters={appendFilters} />
      </Wrapper>
      <Wrapper>
        <AvailableBillableMinutes
          filters={filters}
          appendFilters={appendFilters}
        />
      </Wrapper>
      <Wrapper>
        <InteractiveTimeGroupSelect
          value={filters.interactiveTimeRange}
          onChange={(e) => appendFilters({ interactiveTimeRange: e })}
          showSelectAll
        />
      </Wrapper>
      <Wrapper>
        <ClinicFilterSelectBox
          excluded={filters?.excludeClinicGuid?.map((el) => el.value)}
          included={filters?.clinicGuid?.map((el) => el.value)}
          allExcluded={filters?.allExcluded}
          onChange={({ included = [], excluded = [], allExcluded }) => {
            appendFilters({
              clinicGuid: included.map((el) => ({ label: el, value: el })),
              excludeClinicGuid: excluded.map((el) => ({
                label: el,
                value: el,
              })),
              allExcluded: allExcluded || undefined,
            });
          }}
        />
      </Wrapper>

      <Wrapper>
        {showAgenciesFilter && (
          <AgenciesAutocomplete
            value={filters.agencyGuid}
            onChange={(e) => appendFilters({ agencyGuid: e })}
            showSelectAll
          />
        )}
      </Wrapper>

      <Wrapper>
        <LanguageSelect
          value={filters.language}
          onChange={(e) => appendFilters({ language: e })}
          showSelectAll
        />
        <TimezoneSelect
          value={filters.timezone}
          onChange={(e) => appendFilters({ timezone: e })}
          showSelectAll
        />
      </Wrapper>

      <Wrapper>
        <StateSelect
          value={filters.clinicState}
          onChange={(e) => appendFilters({ clinicState: e })}
          showSelectAll
        />
      </Wrapper>

      <Wrapper>
        <ContactAvailabilityFilterSelectBox
          value={filters.contactAvailability}
          onChange={(e) => appendFilters({ contactAvailability: e })}
        />
      </Wrapper>

      <Wrapper>
        <ServiceDeliveryModelSelect
          value={filters.clinicServiceDeliveryModel}
          onChange={(e) => appendFilters({ clinicServiceDeliveryModel: e })}
          showSelectAll
        />
      </Wrapper>

      <Wrapper>
        <InteractionStatusSelect
          value={filters.interactionStatus}
          onChange={(e) => appendFilters({ interactionStatus: e })}
        />
        {showAssigneeFilter && (
          <AssigneeSelect
            value={filters.assignee}
            onChange={(e) => appendFilters({ assignee: e })}
          />
        )}
      </Wrapper>

      <Wrapper>
        <InsuranceSelect
          value={filters.insurance}
          onChange={(e) => appendFilters({ insurance: e })}
          showSelectAll
        />
      </Wrapper>
    </div>
  );
};

export default Body;
