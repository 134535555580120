import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { optedDataSource } from 'infrastructure/data-sources/booleans';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IBooleanItem } from 'infrastructure/data-sources/booleans';

type Options = IBaseSelectBoxOption<boolean, IBooleanItem>[];

interface IBooleanSelectBoxProps extends Omit<IBaseSelectBoxProps, 'options'> {}

const BooleanOptedSelectBox: React.FC<IBooleanSelectBoxProps> = (props) => {
  const dataSource = useMemo(() => optedDataSource(), []);

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...props} />;
};

export default BooleanOptedSelectBox;
