import common from 'constants/common';
import React, { useEffect, useState, useMemo } from 'react';
import {
  HorizontalGridLines,
  LineSeries,
  VerticalBarSeries,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis';
import PropTypes from 'prop-types';

import SleepMatHint from './hint';
import { normalizeChartData } from './utils';

const Chart = ({ durations, data }) => {
  const [hoveredPoint, setHoveredPoint] = useState(null);
  const [currentReading, setCurrentReading] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [maxDuration, setMaxDuration] = useState(0);

  useEffect(() => {
    if (durations.length) {
      setChartData(normalizeChartData(data, durations));
      setMaxDuration(Math.ceil(Math.max(...durations)));
    }
  }, [durations, data]);
  useEffect(() => {
    if (hoveredPoint && hoveredPoint.x !== currentReading?.formattedDateTime) {
      setCurrentReading(
        chartData.find((e) => e.formattedDateTime === hoveredPoint.x),
      );
    }
  }, [hoveredPoint]);

  const handleCustomScale = (tick) => {
    const factor = maxDuration / common.chartHeight;
    const number = Math.round(tick / factor);
    const roundedNumber = (parseInt(number / 10, 10) + 1) * 10;
    return roundedNumber;
  };

  const sleepPulseData = useMemo(
    () => chartData.filter((item) => item.pulse),
    [chartData],
  );

  return (
    <XYPlot
      className="sleep-mat-chart"
      margin={{ bottom: 30, right: 40 }}
      xType="ordinal"
      height={common.sleepMatChartHeight}
      onMouseLeave={() => {
        setHoveredPoint(null);
      }}
      yDomain={[0, maxDuration]}
      width={chartData.length * 58}
      stackBy="y"
    >
      <VerticalBarSeries
        barWidth={0.9}
        color={common.chartColors.sleepMat.rem}
        data={chartData.map((e) => ({
          x: e.formattedDateTime,
          y: e.remHeight,
        }))}
        onNearestX={(point) => {
          setHoveredPoint(point);
        }}
      />
      <VerticalBarSeries
        barWidth={0.9}
        color={common.chartColors.sleepMat.lightSleep}
        data={chartData.map((e) => ({
          x: e.formattedDateTime,
          y: e.lightSleepHeight,
        }))}
        onNearestX={(point) => {
          setHoveredPoint(point);
        }}
      />
      <VerticalBarSeries
        barWidth={0.9}
        color={common.chartColors.sleepMat.deepSleep}
        onNearestX={(point) => {
          setHoveredPoint(point);
        }}
        data={chartData.map((e) => ({
          x: e.formattedDateTime,
          y: e.deepSleepHeight,
        }))}
      />
      <HorizontalGridLines tickTotal={11} />

      {hoveredPoint && currentReading?.duration && (
        <SleepMatHint
          currentReading={currentReading}
          hoveredPoint={hoveredPoint}
        />
      )}
      <LineSeries
        style={{
          strokeWidth: 3,
        }}
        color={common.chartColors.pulse.high}
        yDomain={[0, common.chartHeight]}
        data={sleepPulseData.map((e) => {
          if (Number(e.pulse)) {
            return {
              x: e.formattedDateTime,
              y: e.pulse,
            };
          }
          return {};
        })}
      />
      <XAxis
        attr="x"
        attrAxis="y"
        orientation="bottom"
        tickTotal={chartData.length}
        tickSizeOuter={0}
        tickSizeInner={0}
      />
      <YAxis
        attr="y"
        attrAxis="x"
        orientation="left"
        tickTotal={9}
        title="Hours"
      />
      <YAxis
        attr="y"
        attrAxis="x"
        orientation="right"
        tickTotal={10}
        title="Pulse"
        tickFormat={(v) => handleCustomScale(v)}
      />
    </XYPlot>
  );
};

Chart.propTypes = {
  durations: PropTypes.array,
  data: PropTypes.array,
};

export default Chart;
