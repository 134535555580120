import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { useRef, useState, useMemo } from 'react';
import {
  XYPlot,
  LineSeries,
  XAxis,
  YAxis,
  DiscreteColorLegend,
  HorizontalGridLines,
  MarkSeries,
} from 'react-vis';
import common from 'constants/common';
import BaseLoader from 'components/atoms/base-loader';

import ChartHint from '../chartHint';

const yScale = common.chartYaxisScales.pef;
const hintType = 'PEF';

const legendItems = [
  {
    title: 'PEF',
    color: common.chartColors.pef.main,
    strokeStyle: 'solid',
  },
  {
    title: 'PEF Critical',
    color: common.chartColors.pef.critical,
    strokeStyle: 'dashed',
  },
  {
    title: 'PEF Risk',
    color: common.chartColors.pef.risk,
    strokeStyle: 'dashed',
  },
];

const normalizeChartData = (deviceData, thresholds) => {
  if (!deviceData || !thresholds) {
    return { main: [], minCritical: [], minRisk: [] };
  }

  const normalizedData = deviceData.map((item) => ({
    value: item.data.pef,
    createdAt: item.createdAt,
  }));

  const minCritical = normalizedData.map((item) => ({
    x: item.createdAt,
    y: thresholds.minCriticalPef,
  }));

  const minRisk = normalizedData.map((item) => ({
    x: item.createdAt,
    y: thresholds.minRiskPef,
  }));

  const mainData = normalizedData.map((item) => ({
    x: item.createdAt,
    y: item.value,
  }));

  return {
    minCritical: {
      data: minCritical,
      stroke: common.chartColors.pef.critical,
      strokeStyle: 'dashed',
    },
    minRisk: {
      data: minRisk,
      stroke: common.chartColors.pef.risk,
      strokeStyle: 'dashed',
    },
    main: {
      data: mainData,
      stroke: common.chartColors.pef.main,
      strokeStyle: 'solid',
    },
  };
};

const PefChart = ({ data, thresholds, loading }) => {
  const isHoveringOverLine = useRef({});
  const [hoveredPoint, setHoveredPoint] = useState(null);
  let chartDate = '';

  const chartData = useMemo(
    () => normalizeChartData(data, thresholds),
    [data, thresholds],
  );

  if (loading) return <BaseLoader className="loader" loading />;

  return (
    <div className="chart" data-cy="pef-chart">
      <div className="chart-header">
        <div className="chart-header-title" data-cy="pef-chart-title">
          <p>
            PEF <span className="chart-header-subtitle">(L/min)</span>
          </p>
          <p>Normal: &gt; {thresholds.minRiskPef}</p>
        </div>
        <DiscreteColorLegend items={legendItems} orientation="horizontal" />
      </div>
      <div className="chart-main">
        <XYPlot
          xType="time"
          height={common.chartHeight}
          onMouseLeave={() => {
            setHoveredPoint(null);
          }}
          yDomain={yScale}
          width={common.chartWidth}
        >
          <HorizontalGridLines style={{ background: 'red' }} tickTotal={9} />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => {
              const dayMonth = format(d, common.dateFormats.chartXDate);
              if (dayMonth !== chartDate) {
                chartDate = dayMonth;
                return dayMonth;
              }
              return '';
            }}
            tickSizeOuter={5}
            tickSizeInner={0}
            tickPadding={24}
          />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => format(d, common.dateFormats.chartXTime)}
            tickTotal={15}
            tickSizeOuter={5}
            tickSizeInner={0}
          />
          <LineSeries curve={null} opacity={1} {...chartData.minCritical} />
          <LineSeries curve={null} opacity={1} {...chartData.minRisk} />
          {chartData.main.data[0].y !== null && data.length === 1 && (
            <MarkSeries
              color={common.chartColors.pef.main}
              data={chartData.main.data}
              opacity={1}
              size={2.5}
              onNearestXY={(datapoint) => {
                setHoveredPoint(datapoint);
              }}
            />
          )}
          {chartData.main.data[0].y !== null &&
            chartData.main.data.length > 1 && (
              <LineSeries
                opacity={1}
                {...chartData.main}
                onSeriesMouseOver={() => {
                  isHoveringOverLine.current[0] = true;
                }}
                onSeriesMouseOut={() => {
                  isHoveringOverLine.current[0] = false;
                }}
                onNearestXY={(datapoint) => {
                  if (isHoveringOverLine.current[0]) {
                    setHoveredPoint(datapoint);
                  }
                }}
              />
            )}
          {hoveredPoint && (
            <ChartHint hoveredPoint={hoveredPoint} type={hintType} />
          )}
          <YAxis attr="y" attrAxis="x" orientation="left" tickTotal={9} />
        </XYPlot>
      </div>
    </div>
  );
};

PefChart.propTypes = {
  data: PropTypes.array,
  thresholds: PropTypes.shape(),
  loading: PropTypes.bool,
};

export default PefChart;
