import React from 'react';
import { Dropdown } from 'antd';

import BaseButton from '.';

import type { IBaseButtonProps } from '.';

export interface IBaseDropdownButtonProps extends IBaseButtonProps {
  items: Array<{
    key: string;
    label: React.ReactNode;
    className?: string;
    dataCy?: string;
    disabled?: boolean;
    visible?: boolean;
    onClick?: (...args: any) => void;
  }>;
}

const BaseDropdownButton: React.FC<IBaseDropdownButtonProps> = (props) => {
  const { items, dataCy = 'base-dropdown-button', ...args } = props;

  const mapedItems = items
    .filter((el) => !el.visible)
    .map((el) => {
      const cy = el.dataCy ?? `${dataCy}-${el.key}`;
      return {
        ...el,
        label: (
          <div data-cy={cy} className={el.className}>
            {el.label}
          </div>
        ),
      };
    });

  return (
    <>
      <Dropdown trigger={['click']} menu={{ items: mapedItems }}>
        <BaseButton {...args} dataCy="dropdown-button" />
      </Dropdown>
    </>
  );
};

export default BaseDropdownButton;
