import { useMutation } from '@tanstack/react-query';
import common from 'constants/common';
import { format } from 'date-fns';
import dataService from 'services/dataService';
import { API_CARE_PLAN } from 'services/dataService/ccmResources';
import { useAppSelector } from 'store';
import { downloadPDF } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';

interface QueryParams {
  patientGuid: string;
  type: 'email' | null;
}

export const useDownloadReport = () => {
  const [startTime, endTime] = useAppSelector<[Date, Date]>(
    (state) => state.ccmPatientDetails.dates,
  );

  const { mutate, isLoading } = useMutation({
    mutationFn: async ({ patientGuid, type }: QueryParams) => {
      const { error, data } = await dataService.getOnly<{
        response: string;
      }>(API_CARE_PLAN, {
        patientGuid,
        type,
        startTime: format(startTime, 'yyyy-MM-dd HH:mm:s'),
        endTime: format(endTime, 'yyyy-MM-dd HH:mm:s'),
      });

      if (error) {
        showResult(error);
        throw new Error(error);
      }

      if (type === 'email') {
        return showResult(error, common.submitSuccessTypes.sendReport);
      }

      const reportName = `careplan-report-${patientGuid}.pdf`;
      downloadPDF(data, reportName);

      return data;
    },
  });

  return { isLoading, mutate };
};
