import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const EmailCreateIcon = ({ color = COLORS.ARANCIO, ...props }: IIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 2C0.895431 2 0 2.89543 0 4V12L2.58386e-05 12.0103C0.00555998 13.1101 0.898859 14 2 14H7.5C7.77614 14 8 13.7761 8 13.5C8 13.2239 7.77614 13 7.5 13H2C1.53715 13 1.14774 12.6855 1.03376 12.2586L6.67417 8.7876L8 9.5831L15 5.3831V8.5C15 8.77614 15.2239 9 15.5 9C15.7761 9 16 8.77614 16 8.5V4C16 2.89543 15.1046 2 14 2H2ZM5.70808 8.20794L1 11.1052V5.3831L5.70808 8.20794ZM1 4.2169V4C1 3.44772 1.44772 3 2 3H14C14.5523 3 15 3.44772 15 4V4.2169L8 8.4169L1 4.2169Z"
      fill={color}
    />
    <path
      d="M16 12.5C16 14.433 14.433 16 12.5 16C10.567 16 9 14.433 9 12.5C9 10.567 10.567 9 12.5 9C14.433 9 16 10.567 16 12.5ZM12.5 10.5C12.2239 10.5 12 10.7239 12 11V12H11C10.7239 12 10.5 12.2239 10.5 12.5C10.5 12.7761 10.7239 13 11 13H12V14C12 14.2761 12.2239 14.5 12.5 14.5C12.7761 14.5 13 14.2761 13 14V13H14C14.2761 13 14.5 12.7761 14.5 12.5C14.5 12.2239 14.2761 12 14 12H13V11C13 10.7239 12.7761 10.5 12.5 10.5Z"
      fill={color}
    />
  </svg>
);

export default EmailCreateIcon;
