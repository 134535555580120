import common from 'constants/common';
import { SUCCESS_MESSAGE, successMessages } from 'constants/messages';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';

const hiddenErrors = ['patient not exists', "patient's thresholds not exists"];

export const showResult = (
  error?: string | null,
  type?: keyof typeof common.submitSuccessTypes | null,
  successCallback?: () => void,
  callback?: () => void,
) => {
  if (error && hiddenErrors.includes(error)) {
    return;
  }
  if (error && error.startsWith('Warning:')) {
    notificationController.warning({ message: error });
    return;
  }

  if (error) {
    const text = error.startsWith('Error:')
      ? error
      : `Error: ${error.charAt(0).toUpperCase() + error.slice(1)}`;
    switch (error) {
      case 'Failed to fetch':
        notificationController.error({ message: `Connection error` });
        break;
      default:
        notificationController.error({ message: text });
        break;
    }
  } else {
    switch (type) {
      case common.submitSuccessTypes.changed:
        notificationController.success({
          message: successMessages.changes,
        });
        break;
      case common.submitSuccessTypes.reviewed:
        notificationController.success({ message: successMessages.reviewed });
        break;
      case common.submitSuccessTypes.sendReport:
        notificationController.success({ message: successMessages.sendReport });
        break;
      case common.submitSuccessTypes.deleted:
        notificationController.success({ message: successMessages.deleted });
        break;
      default:
        notificationController.success({ message: SUCCESS_MESSAGE });
        break;
    }
  }
  if (callback) callback();

  if (successCallback && !error) {
    successCallback();
  }
};
