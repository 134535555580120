import PatientGoalsIcon from 'components/atoms/icons/patientGoalsIcon';
import { useTranslation } from 'react-i18next';
import HealthGoals from 'components/molecules/health-goals';
import PersonalGoals from 'components/molecules/personal-goals';
import ConditionGoals from 'components/molecules/conditions-goals';

import s from './styles.module.scss';

import type { FC } from 'react';

interface PatientSelfGoalsProps {
  patientGuid: string;
}

const PatientSelfGoals: FC<PatientSelfGoalsProps> = ({ patientGuid }) => {
  const { t } = useTranslation();

  return (
    <div className={s.wrapper} id="patient-goals">
      <div className={s['title-wrapper']}>
        <h4 className={s.title} data-cy="patient-goals-section">
          <PatientGoalsIcon />
          {t('labels.patientSelfDirectedGoals')}
        </h4>
        <div className={s.divider} />
      </div>
      <div className={s.body}>
        <HealthGoals patientGuid={patientGuid} />
        <div className={s.divider} />
        <PersonalGoals patientGuid={patientGuid} />
        <div className={s.divider} />
        <ConditionGoals patientGuid={patientGuid} />
      </div>
    </div>
  );
};
export default PatientSelfGoals;
