import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const ChatDotsIcon = ({
  color = COLORS.TANGERINE_HAZE,
  ...props
}: IIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 8C16 11.866 12.4183 15 8 15C7.18324 15 6.39508 14.8929 5.65284 14.6939C5.06898 14.9897 3.72774 15.5584 1.47172 15.9277C1.27271 15.9603 1.12037 15.7521 1.19898 15.5664C1.55308 14.7299 1.87251 13.6162 1.96979 12.6001C0.743061 11.3699 0 9.76087 0 8C0 4.13401 3.58172 1 8 1C12.4183 1 16 4.13401 16 8ZM5 8C5 7.44772 4.55228 7 4 7C3.44772 7 3 7.44772 3 8C3 8.55228 3.44772 9 4 9C4.55228 9 5 8.55228 5 8ZM9 8C9 7.44772 8.55229 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9C8.55229 9 9 8.55228 9 8ZM12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
      fill={color}
    />
  </svg>
);

export default ChatDotsIcon;
