import { useState } from 'react';
import { Modal } from 'antd';
import i18next from 'i18next';
import BaseButton from 'components/atoms/baseButton';
import common from 'constants/common';

import s from './styles.module.scss';

import type { ReactNode } from 'react';

interface IAlertModalProps {
  open: boolean;
  title: string | ReactNode;
  message?: string | ReactNode;
  type: btnType;
  handleOk: () => void;
  handleCancel: () => void;
  successBtnLabel?: string;
  rejectBtnLabel?: string;
  messageTitle?: string | ReactNode;
  customMessage?: string | ReactNode;
}

type btnType = 'primary' | 'danger';

const AlertModal = ({
  open,
  title,
  type,
  message,
  successBtnLabel,
  rejectBtnLabel,
  handleOk,
  handleCancel,
  messageTitle,
  customMessage,
}: IAlertModalProps) => {
  const modalStyles = {
    body: {
      padding: '32px 24px 64px 24px',
    },
    footer: {
      borderTop: `1px solid ${common.commonColors.gray}`,
      padding: '24px',
    },
  };

  return (
    <Modal
      title={title}
      open={open}
      onCancel={handleCancel}
      footer={[
        <BaseButton
          type="secondary"
          label={rejectBtnLabel ?? i18next.t('controls.cancel')}
          onClick={handleCancel}
          dataCy="form-cancel-button"
        />,
        <BaseButton
          type={type}
          label={
            successBtnLabel ??
            (type === 'danger'
              ? i18next.t('controls.delete')
              : i18next.t('controls.ok'))
          }
          onClick={handleOk}
          dataCy="form-delete-button"
        />,
      ]}
      zIndex={1011}
      styles={modalStyles}
    >
      <div>
        <p className={s['alert-message']}>
          {customMessage ?? (
            <>
              {messageTitle && (
                <p
                  className={s['alert-title']}
                  style={{ paddingBottom: message ? 10 : 0 }}
                >
                  {messageTitle}
                </p>
              )}
              <p>{message}</p>
            </>
          )}
        </p>
      </div>
    </Modal>
  );
};

interface IAlertOptions {
  title: string | ReactNode;
  message?: string | ReactNode;
  type?: 'primary' | 'danger';
  successBtnLabel?: string;
  rejectBtnLabel?: string;
  customMessage?: string | ReactNode;
  messageTitle?: string | ReactNode;
}

const useAlert = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<IAlertOptions>({
    title: '',
    message: '',
    type: 'primary',
  });
  const [popupController, setPopupController] = useState<{
    resolve: any;
    reject: any;
  }>({ resolve: null, reject: null });

  const showAlert = async (optionsProps: IAlertOptions): Promise<boolean> => {
    setOptions(optionsProps);
    setOpen(true);
    const popupPromise: Promise<boolean> = new Promise((ok, fail) => {
      setPopupController({
        resolve: ok,
        reject: fail,
      });
    });

    return popupPromise;
  };
  const handleOk = () => {
    popupController.resolve(true);
    setOpen(false);
  };

  const handleCancel = () => {
    popupController.resolve(false);
    setOpen(false);
  };

  return {
    showAlert,
    Alert: (
      <AlertModal
        open={open}
        type={options.type ?? 'primary'}
        title={options.title}
        message={options?.message}
        handleOk={handleOk}
        handleCancel={handleCancel}
        successBtnLabel={options?.successBtnLabel}
        rejectBtnLabel={options?.rejectBtnLabel}
        messageTitle={options?.messageTitle}
        customMessage={options?.customMessage}
      />
    ),
  };
};

export default useAlert;
