export enum Races {
  AmericanIndianOrAlaskaNative = 'american_indian_or_alaska_native',
  Asian = 'asian',
  BlackOrAfricanAmerican = 'black_or_african_american',
  HispanicOrLatino = 'hispanic_or_latino',
  NativeHawaiianOrOtherPacificIslander = 'native_hawaiian_or_other_pacific_islander',
  White = 'white',
  Dynamic = 'dynamic',
  Other = 'other',
}
