export const CONSENT_FORM_PDF_GENERATE = 'reports/reports/consentForm';
export const ADHERENCE_REPORT = 'reports/reports/adherence';
export const ACTIVITY_CSV_REPORT = 'reports/reports/activity';
export const COMPLIANCE_CSV_REPORT = 'reports/reports/compliance';
export const INTERACTIVE_TIME_CSV_REPORT = 'reports/reports/interactive-time';
export const MEDICARE_BILLINGS_CSV_REPORT = 'reports/reports/medicare-billings';
export const YESTERDAYS_STATS_CSV_REPORT = 'reports/reports/yesterdays-stats';
export const API_PRACTICE_OVERVIEW = 'reports/reports/practice-overview';
export const ONBOARDING_CSV_REPORT = 'reports/reports/onboarding-status';
export const CCM_ONBOARDING_CSV_REPORT =
  'reports/ccm/reports/onboarding-status';
export const API_CRITICAL_READINGS = 'reports/reports/critical-readings';
export const BATCH_CSV_REPORT = 'reports/reports/batch-create';
export const SOP_CSV_REPORT = 'reports/reports/sop-check-patients';
export const RESEND_EMR_CSV_REPORT = 'core/integrations/resend-emr';
export const RESET_INTERACTION_STATUS =
  'reports/reports/interaction-status-create';
export const API_CCM_INTERACTIVE_TIME_REPORT =
  'reports/ccm/reports/interactive-time';
export const API_CCM_INTERACTIVE_TIME_CSV_REPORT =
  'reports/ccm/reports/interactive-time';
export const API_INTERACTION_STATUS_V2 =
  'reports/reports/interaction-status-v2';
export const CCM_PATIENTS_STATUS_CSV_REPORT =
  'reports/ccm/reports/status-report';
export const API_CCM_PATIENTS_STATUS_COUNT =
  'reports/ccm/reports/status-report/count';
export const API_CCM_PATIENTS_STATUS_REPORT =
  'reports/ccm/reports/status-report';
export const API_REPORTS_HIDE_PATIENT_DATA =
  'reports/reports/hide-patient-data';
