import dataService from 'services/dataService';
import { API_PATIENT_CONSUMABLES_DATE } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

export interface IUpdateConsumablesDateProps {
  patientGuid: string;
}

export const updateConsumablesDateFn = async (
  postData: IUpdateConsumablesDateProps,
) => {
  const { error, data } = await dataService.createOne(
    API_PATIENT_CONSUMABLES_DATE,
    postData,
  );

  if (error) {
    showResult(error);
  }

  return data;
};
