import React from 'react';
import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminAgencyBlock from 'components/templates/admin/agency';

const AdminAgency = () => (
  <Container>
    <AdminHeader title=" " />
    <AdminAgencyBlock />
  </Container>
);

export default AdminAgency;
