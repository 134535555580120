import {
  MAX_FILE_SIZE_MB,
  ALLOWED_FILE_EXTENSIONS,
} from 'constants/fileUpload';
import { showResult } from 'infrastructure/hooks/utils';

const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

const getFileExtension = (fileName: string): string => {
  const dotIndex = fileName.lastIndexOf('.');
  if (dotIndex === -1) return '';
  return fileName.slice(dotIndex);
};

const validateFileSize = (size: number): boolean => {
  const isSizeValid = size <= MAX_FILE_SIZE;
  if (!isSizeValid) {
    showResult(
      `The file size is greater than ${MAX_FILE_SIZE_MB} MB. Please reduce the file size and try again.`,
    );
  }
  return isSizeValid;
};

const validateFileExtension = (fileName: string): boolean => {
  const extension = getFileExtension(fileName);
  const isExtensionValid = ALLOWED_FILE_EXTENSIONS.includes(extension);
  if (!isExtensionValid) {
    const formattedExtensions = ALLOWED_FILE_EXTENSIONS.join(' ');
    showResult(
      `The file extension is not supported. Supported file extensions: ${formattedExtensions}.`,
    );
  }
  return isExtensionValid;
};

export const validateFile = (file: File): boolean => {
  const isSizeValid = validateFileSize(file.size);
  const isExtensionValid = validateFileExtension(file.name);
  return isSizeValid && isExtensionValid;
};
