import { Thresholds } from './thresholds';

import type { IClinicThresholds } from 'infrastructure/interfaces';

export class ClinicThresholds extends Thresholds {
  clinicGuid: string;

  constructor(data: IClinicThresholds) {
    super(data);
    this.clinicGuid = data.clinicGuid;
  }
}
