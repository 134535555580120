import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';

const PlayFillIcon = ({
  color = COLORS.MIAMI_MARMALADE,
  ...props
}: IIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="4" fill={color} fillOpacity="0.1" />
    <path
      d="M16.5964 12.6966L10.2328 16.3885C9.6925 16.7019 9 16.3228 9 15.6922V8.30846C9 7.67783 9.6925 7.29871 10.2328 7.61216L16.5964 11.304C17.1345 11.6162 17.1345 12.3845 16.5964 12.6966Z"
      fill={color}
    />
  </svg>
);

export default PlayFillIcon;
