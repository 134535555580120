import type { FC } from 'react';

const EllipseIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="193"
    height="78"
    viewBox="0 0 193 78"
    fill="currentColor"
  >
    <path
      d="M38.077 -21.6591C79.8021 -63.9265 135.74 -72.4797 171.479 -41.1498C207.218 -9.81995 209.117 76.6493 171.479 114.776C133.841 152.904 47.0927 140.671 17.5536 114.776C-11.9856 88.8814 -3.64815 20.6084 38.077 -21.6591Z"
      fill="currentColor"
    />
  </svg>
);

export default EllipseIcon;
