import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';

import { showResult } from '../utils';
import { apiUrlGenerator } from '../use-api';

import type {
  IAdminPanelAgencyDoctorsResponse,
  IPartialPaginationMeta,
} from 'infrastructure/interfaces';

export type TAdminPanelAgencyDoctorsFilter = {
  guid?: string;
  agencyGuid?: string;
  status?: string;
  roleName?: string;
} & IPartialPaginationMeta;

export const ADMIN_PANEL_AGENCY_DOCTORS_PER_PAGE = 100;

const getAdminPanelAgencyDoctorsFn = async (
  filter: TAdminPanelAgencyDoctorsFilter,
) => {
  const url = 'admins/admin-panel/agency-doctors';
  const { data, error } =
    await dataService.getList<IAdminPanelAgencyDoctorsResponse>(
      apiUrlGenerator(url, filter),
    );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
export const getAdminPanelAgencyDoctorsKey = (
  filter: TAdminPanelAgencyDoctorsFilter,
) => ['getAdminPanelAgencyDoctors', filter];

export const useGetAdminPanelAgencyDoctors = (
  filter: TAdminPanelAgencyDoctorsFilter,
) => {
  const filterWithItems: TAdminPanelAgencyDoctorsFilter = {
    ...filter,
    items: ADMIN_PANEL_AGENCY_DOCTORS_PER_PAGE,
  };

  const { data: doctors, ...other } = useQuery({
    queryKey: getAdminPanelAgencyDoctorsKey(filterWithItems),
    queryFn: async () => getAdminPanelAgencyDoctorsFn(filterWithItems),
    keepPreviousData: true,
  });

  return { doctors, ...other };
};
