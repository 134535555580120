import React, { forwardRef, useImperativeHandle } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import BaseInput from 'components/atoms/base-input';
import ReadingTypeSelectBox from 'components/molecules/reading-type-select-box';
import { usePatientDevices } from 'infrastructure/hooks/device/use-patient-devices';
import { PatientDevice } from 'infrastructure/classes/patient/patient-device';

import { deviceValidationSchema } from './validation-schema';

import type { ReadingType } from 'infrastructure/enums';
import type { ICreatePatientDevice } from 'infrastructure/interfaces';

export interface IDeviceCreateFormRef {
  submit: () => Promise<void>;
  reset: () => void;
}

interface IDeviceCreateFormProps {
  patientGuid: string;
  onCancel?: () => void;
  onSaved?: () => void;
}

const DeviceCreateForm = forwardRef<
  IDeviceCreateFormRef,
  IDeviceCreateFormProps
>((props, ref) => {
  const { patientGuid, onCancel, onSaved } = props;

  const { t } = useTranslation();

  const { loading, createPatientDevices } = usePatientDevices({
    patientGuid,
    initialLoading: false,
  });

  const formik = useFormik<ICreatePatientDevice>({
    initialValues: {
      patientGuid,
      deviceId: '',
      manufacturer: '',
      readingTypes: [],
    },
    onSubmit: async (values) => {
      const res = await createPatientDevices({
        ...values,
        readingTypes: PatientDevice.prepareReadingTypes(values.readingTypes),
      });
      if (onSaved && res) onSaved();
    },
    validateOnChange: false,
    validationSchema: deviceValidationSchema(),
  });

  const { submitForm, resetForm } = formik;

  const setReadingType = (readingType: ReadingType) => {
    formik.setFieldValue('readingTypes', [readingType]);
  };

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: resetForm,
  }));

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseForm
        loading={loading}
        formik={formik}
        plaintext={false}
        readonly={false}
        onSave={submitForm}
        onCancel={onCancel}
        withControls
      >
        <BaseFormItem name="manufacturer" label={t('labels.deviceName')}>
          <BaseInput />
        </BaseFormItem>
        <BaseFormItem name="deviceId" label={t('labels.deviceId')}>
          <BaseInput />
        </BaseFormItem>
        <BaseFormItem name="readingTypes" label={t('labels.readingType')}>
          <ReadingTypeSelectBox
            onChange={(value) => {
              setReadingType(value);
            }}
          />
        </BaseFormItem>
      </BaseForm>
    </BaseSpinWrapper>
  );
});

DeviceCreateForm.displayName = 'DeviceCreateForm';

export default DeviceCreateForm;
