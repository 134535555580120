import { Thresholds } from '../thresholds';

import type { IPatientThresholds } from 'infrastructure/interfaces';

export class PatientThresholds extends Thresholds {
  patientGuid: string;

  constructor(data: IPatientThresholds) {
    super(data);
    this.patientGuid = data.patientGuid;
  }
}
