import React from 'react';
import { useTranslation } from 'react-i18next';

import BaseButton from '../baseButton';
import CheckIcon from '../icons/checkIcon';

import type { IBaseButtonProps } from '../baseButton';

const SaveButton: React.FC<IBaseButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <BaseButton
      dataCy="save-button"
      icon={<CheckIcon />}
      label={t('controls.save')}
      size="large"
      {...props}
    />
  );
};

export default SaveButton;
