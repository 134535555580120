import useConfirm from './useConfirm';

const useMonthlyInteractionConfirm = () =>
  useConfirm({
    message:
      'Does this note complete the monthly interaction for this patient?',
    title: 'Monthly Interaction',
  });

export default useMonthlyInteractionConfirm;
