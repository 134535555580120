import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import DatePicker from 'components/molecules/datepicker/range';
import DatePickerMobile from 'components/molecules/datepicker/rangeMobile';
import forms from 'constants/forms';
import Button from 'components/atoms/button';
import useOutsideClick from 'utils/useOutsideClick';
import { getRangeDates, getUTCDates } from 'utils';
import { isMobile } from 'utils/deviceHelper';
import CalendarIcon from 'components/atoms/icons/calendarIcon';
import DropdownClosedIcon from 'components/atoms/icons/dropdownClosedIcon';
import DropdownOpenIcon from 'components/atoms/icons/dropdownOpenIcon';

const DEFAULT_RANGE = forms.timeFilterRanges[4];

const TimeFilter = ({ handleSubmit, inputRef, noUTC, isRpmPanel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [range, setRange] = useState(DEFAULT_RANGE);
  const [tempRange, setTempRange] = useState(DEFAULT_RANGE);
  const [dates, setDates] = useState([]);
  const location = useLocation();
  if (inputRef)
    inputRef.current = {
      setValue: () => {
        setRange(DEFAULT_RANGE);
        const { startRangeDate, endRangeDate } = getRangeDates(DEFAULT_RANGE);
        const utcDates = getUTCDates([startRangeDate, endRangeDate]);
        setTempRange(utcDates);
        setDates([startRangeDate, endRangeDate]);
      },
    };
  const device = useSelector((state) => state.user.deviceType);

  useEffect(() => {
    let { startRangeDate, endRangeDate } = getRangeDates(range);
    const { startDate, endDate, rangeLabel, page } = queryString.parse(
      location.search,
    );
    const haveQueryParams = Boolean(startDate && endDate);
    if (haveQueryParams) {
      startRangeDate = new Date(+startDate);
      endRangeDate = new Date(+endDate);
    }
    const rangeFromQuery =
      rangeLabel === 'custom'
        ? { label: 'Custom range date' }
        : forms.timeFilterRanges.find((i) => i.label === rangeLabel);
    const currentRange = rangeFromQuery || range;
    if (rangeFromQuery) {
      setRange(rangeFromQuery);
    }
    setDates([startRangeDate, endRangeDate]);
    if (noUTC) {
      handleSubmit(
        [startRangeDate, endRangeDate],
        currentRange,
        haveQueryParams,
        page,
      );
    } else {
      const utcDates = getUTCDates([startRangeDate, endRangeDate]);
      handleSubmit(utcDates, currentRange, haveQueryParams, page);
    }

    return () => {
      handleSubmit([]);
    };
  }, []);

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };

  const ref = useRef();
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  const submitFilter = () => {
    setRange(tempRange);
    if (noUTC) {
      handleSubmit(dates, tempRange, true);
    } else {
      const utcDates = getUTCDates(dates);
      handleSubmit(utcDates, tempRange, true);
    }
    setIsOpen(false);
  };

  return (
    <div className="time-filter" ref={ref}>
      {isRpmPanel ? (
        <div
          className="time-filter sort-row-small-item"
          data-cy="report-time-window"
          onClick={() => setIsOpen(!isOpen)}
        >
          <CalendarIcon width="24" height="26" />
        </div>
      ) : (
        <Card className="card-border">
          <CardBody onClick={toggleFilter} className="card-body-sort-arrow">
            {range?.label ?? 'Custom Date Range'}
            {isOpen ? <DropdownOpenIcon /> : <DropdownClosedIcon />}
          </CardBody>
        </Card>
      )}
      {isOpen && (
        <div className="time-filter-body">
          {isMobile(device) && (
            <DatePickerMobile
              rangeValue={tempRange}
              handleChange={setTempRange}
              setDates={setDates}
              selectedDates={dates}
            />
          )}
          {!isMobile(device) && (
            <DatePicker
              rangeValue={tempRange}
              handleChange={setTempRange}
              setDates={setDates}
              selectedDates={dates}
            />
          )}
          <div className="time-filter-button">
            <Button
              type="button"
              text="Apply Filter"
              handleClick={submitFilter}
              disabled={dates.some((date) => !date)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

TimeFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  inputRef: PropTypes.shape(),
  noUTC: PropTypes.bool,
  isRpmPanel: PropTypes.bool,
};

export default TimeFilter;
