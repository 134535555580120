import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DatePicker from 'components/molecules/datepicker/range';
import DatePickerMobile from 'components/molecules/datepicker/rangeMobile';
import Dropdown from 'components/atoms/dropdown';
import forms from 'constants/forms';
import Button from 'components/atoms/button';
import useOutsideClick from 'utils/useOutsideClick';
import { getRangeDates, getUTCDates } from 'utils';
import { isMobile } from 'utils/deviceHelper';

const DEFAULT_RANGE = forms.timeFilterRanges[4];

const TimeFilter = ({ handleSubmit, noUTC }) => {
  const device = useSelector((state) => state.user.deviceType);

  const [isOpen, setIsOpen] = useState(false);
  const [range, setRange] = useState(DEFAULT_RANGE);
  const [tempRange, setTempRange] = useState(DEFAULT_RANGE);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const { startRangeDate, endRangeDate } = getRangeDates(range);
    if (noUTC) {
      handleSubmit([startRangeDate, endRangeDate]);
    } else {
      const utcDates = getUTCDates([startRangeDate, endRangeDate]);
      handleSubmit(utcDates);
    }
  }, []);

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };

  const ref = useRef();
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  const submitFilter = () => {
    setRange(tempRange);
    if (noUTC) {
      handleSubmit(dates, tempRange);
    } else {
      const utcDates = getUTCDates(dates);
      handleSubmit(utcDates, tempRange);
    }
    setIsOpen(false);
  };

  return (
    <div className="time-filter" data-cy="time-report-filter" ref={ref}>
      <Dropdown
        // label="Report Time Window"
        value={range?.label ?? 'Custom Date Range'}
        handleClick={toggleFilter}
        isOpen={isOpen}
      />
      {isOpen && (
        <div className="time-filter-body">
          {isMobile(device) && (
            <DatePickerMobile
              rangeValue={tempRange}
              handleChange={setTempRange}
              setDates={setDates}
              selectedDates={dates}
            />
          )}
          {!isMobile(device) && (
            <DatePicker
              rangeValue={tempRange}
              handleChange={setTempRange}
              setDates={setDates}
              selectedDates={dates}
              showAppointmentList
            />
          )}
          <div className="time-filter-button">
            <Button
              type="button"
              text="Apply Filter"
              dataCy="apply-time-filter"
              handleClick={submitFilter}
              disabled={dates.some((date) => !date)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

TimeFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  noUTC: PropTypes.bool,
};

export default TimeFilter;
