import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from 'components/atoms/icons/checkIcon';
import BaseButton from 'components/atoms/baseButton';
import PencilIcon from 'components/atoms/icons/pencilIcon';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import TrashIcon from 'components/atoms/icons/trashIcon';
import classNames from 'classnames';
import FadeAnimation from 'components/atoms/base-animations/fade';
import CopyableText from 'components/atoms/buttons/copyable-text';

import s from './style.module.scss';

import type { ReactNode } from 'react';

interface IPatientBlockProps {
  title: string;
  copyText?: string;
  loading?: boolean;
  canEdit?: boolean;
  disabledSave?: boolean;
  canDelete?: boolean;
  disabled?: boolean;
  isEdit?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
  additionalControls?: ReactNode;
  children?: ReactNode;
  mobileTwoHeaderRows?: boolean;
}

const PatientBlock: React.FC<IPatientBlockProps> = (props) => {
  const {
    title,
    copyText,
    canEdit = true,
    canDelete = false,
    isEdit = false,
    disabledSave = false,
    disabled = false,
    loading = false,
    children,
    onEdit,
    onSave,
    onCancel,
    onDelete,
    additionalControls,
    mobileTwoHeaderRows,
  } = props;

  const { t } = useTranslation();

  const [nativeDisable, setNativeDisable] = useState(false);

  const buttonsClassNames = classNames(s.buttons, {
    [s.disabled]: disabled || nativeDisable,
  });

  useEffect(() => {
    setNativeDisable(true);
    setTimeout(() => {
      setNativeDisable(false);
    }, 500);
  }, [isEdit]);

  return (
    <div className={s.block}>
      <div
        className={classNames(s.header, {
          [s['two-mob-rows']]: mobileTwoHeaderRows && isEdit,
        })}
      >
        {copyText ? (
          <CopyableText label={title} className={s.title} value={copyText} />
        ) : (
          <div className={s.title}>{title}</div>
        )}
        {canEdit && (
          <FadeAnimation uniqueKey={`${isEdit}`}>
            <div className={buttonsClassNames}>
              {isEdit && (
                <>
                  {additionalControls}
                  {canDelete && (
                    <BaseButton
                      type="danger"
                      icon={<TrashIcon />}
                      label={t('controls.delete')}
                      onClick={onDelete}
                      loading={loading}
                      dataCy="delete-tab-item-button"
                    />
                  )}
                  <BaseButton
                    type="secondary"
                    label={t('controls.cancel')}
                    onClick={onCancel}
                    loading={loading}
                    dataCy="cancel-tab-item-button"
                  />
                  {onSave && (
                    <BaseButton
                      type="success"
                      label={t('controls.save')}
                      onClick={onSave}
                      icon={<CheckIcon />}
                      loading={loading}
                      disabled={disabledSave}
                      dataCy="save-tab-item-button"
                    />
                  )}
                </>
              )}
              {!isEdit && !disabled && (
                <BaseButton
                  type="secondary"
                  onClick={onEdit}
                  icon={<PencilIcon />}
                  loading={loading}
                  dataCy={`edit-tab-item-button-${title}`}
                />
              )}
            </div>
          </FadeAnimation>
        )}
      </div>
      <BaseSpinWrapper spinning={loading}>
        <div className={s.body}>{children}</div>
      </BaseSpinWrapper>
    </div>
  );
};

export default PatientBlock;
