/* eslint-disable no-shadow */
export enum PlatformEnums {
  RPM = 'rpm',
  CCM = 'ccm',
  UNREGISTERED = 'unregistered',
}

export enum PlatformCodeEnums {
  CCM = '78616',
  RPM = '90105',
}
