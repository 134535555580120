import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadialChart, Hint } from 'react-vis';
import common from 'constants/common';

const StatusTypeChart = ({ statusBlocks }) => {
  const keys = Object.keys(statusBlocks).filter(
    (item) => item !== 'Total CCM Patients' && statusBlocks[item] > 0,
  );
  const [hintState, setHintState] = useState({
    show: false,
    value: {},
  });

  const [disabledKeys, setDisabledKeys] = useState([]);

  const chartData = keys
    .filter((key) => !disabledKeys.includes(key))
    .map((key) => ({
      angle: parseInt(statusBlocks[key], 10),
      label: common.ccmStatusNames[key].label,
      color: common.ccmStatusNames[key].color,
      value: {
        Status: common.ccmStatusNames[key].label,
        Patients: statusBlocks[key],
      },
    }));
  return (
    <div className="onboarding-chart">
      <RadialChart
        className="radial-chart-with-hover"
        data={chartData}
        width={220}
        height={220}
        colorType="literal"
        onValueMouseOver={({ value }) => {
          setHintState({ show: true, value });
        }}
        onValueMouseOut={() => {
          setHintState({ show: false });
        }}
      >
        {hintState.show && <Hint value={hintState.value} />}
      </RadialChart>
      <div className="onboarding-chart-legend">
        {keys.map((key) => (
          <div
            key={key}
            className="onboarding-chart-legend-item"
            style={{
              cursor: 'pointer',
              textDecoration: disabledKeys.includes(key)
                ? 'line-through'
                : 'none',
            }}
            onClick={() => {
              if (disabledKeys.includes(key)) {
                setDisabledKeys(disabledKeys.filter((item) => item !== key));
              } else {
                setDisabledKeys([...disabledKeys, key]);
              }
            }}
          >
            <div
              style={{ backgroundColor: common.ccmStatusNames[key]?.color }}
              className="onboarding-chart-legend-item-dot"
            />
            <div className="onboarding-chart-legend-item-title">
              {common.ccmStatusNames[key]?.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

StatusTypeChart.propTypes = {
  statusBlocks: PropTypes.object.isRequired,
};

export default StatusTypeChart;
