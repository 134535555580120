import { useTranslation } from 'react-i18next';
import { assigneeOptions } from 'constants/forms';

import BaseSelect from './base';

import type { Option } from './base';
import type { FC } from 'react';

interface Props {
  value?: Option | null;
  showSelectAll?: boolean;
  onChange: (e: Option | null) => void;
}

const AssigneeSelect: FC<Props> = ({ onChange, value, showSelectAll }) => {
  const { t } = useTranslation();
  return (
    <BaseSelect
      label={t('labels.assignee')}
      placeholder={t('labels.all')}
      showSelectAll={showSelectAll}
      options={assigneeOptions}
      value={value}
      onChange={(newValue) => {
        if (!newValue) {
          return onChange(null);
        }
        const items = Array.isArray(newValue) ? newValue : [newValue];
        onChange(items[0]);
      }}
    />
  );
};

export default AssigneeSelect;
