import { useFormik } from 'formik';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseTextArea from 'components/atoms/base-textarea';

import { medicalHistoryValidationSchema } from './validation-schema';

import type { FormikHelpers } from 'formik';
import type { Values } from './validation-schema';
import type { IUpdateMedicalHistory } from 'infrastructure/interfaces';
import type { FC } from 'react';

interface MedicalHistoryFormProps {
  onCancelForm: () => void;
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
  loading: boolean;
  defaultValues?: Omit<IUpdateMedicalHistory, 'patientGuid'>;
}

const MedicalHistoryForm: FC<MedicalHistoryFormProps> = ({
  loading,
  onCancelForm,
  onSubmit,
  defaultValues,
}) => {
  const formik = useFormik<Values>({
    initialValues: medicalHistoryValidationSchema().cast(defaultValues),
    enableReinitialize: true,
    onSubmit,
    validateOnChange: true,
    validationSchema: medicalHistoryValidationSchema(),
  });

  const { submitForm, resetForm } = formik;

  const onCancel = () => {
    resetForm();
    onCancelForm();
  };
  return (
    <BaseForm
      formik={formik}
      withControls
      plaintext={false}
      readonly={false}
      onSave={submitForm}
      onCancel={onCancel}
      loading={loading}
    >
      <BaseFormItem name="record">
        <BaseTextArea rows={10} />
      </BaseFormItem>
    </BaseForm>
  );
};
export default MedicalHistoryForm;
