import { useCallback, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, unstable_usePrompt } from 'react-router-dom';
import queryString from 'query-string';
import Button from 'components/atoms/button';
import { BroadcastTopics, SocketRoutes } from 'services/wsService';
import ConditionFilter from 'components/templates/rpm/dashboard/conditionFilter';
import RealTimePatientReadings from 'components/templates/rpm/dashboard/realTimePatientReadings';
import Sorting from 'components/templates/rpm/dashboard/realTimePatientReadingsMobile/sorting';
import RealTimePatientReadingsMobile from 'components/templates/rpm/dashboard/realTimePatientReadingsMobile';
import { isMobile, isTablet } from 'utils/deviceHelper';
import logger from 'services/logger';
import { useWebsocketRoom } from 'infrastructure/hooks/websockets/use-room';
import { PropTypes } from 'prop-types';

const OverviewDashboardPageOld = ({ setMode }) => {
  const location = useLocation();
  const socket = useSelector((state) => state.socket);
  const activity = useSelector((state) => state.doctor);
  const device = useSelector((state) => state.user.deviceType);
  const { reviewed, unsavedComments, onCallPatients } = activity;

  const {
    doctor: {
      getActivities,
      getActivitiesCount,
      changeReviewed,
      getInsuranceTypes,
      clearComments,
      addOnCallPatient,
      removeOnCallPatient,
      UPDATE_READING_SLA_REVIEWED_TIME,
      UPDATE_PATIENT_SLA_REVIEWED_TIME,
    },
  } = useDispatch();

  const getDoctorPatientsActivity = useCallback(() => {
    if (activity.dates.length > 0) {
      const { page, action } = queryString.parse(location.search);
      getActivities({
        applySpecialFilter: true,
        page: Number(page || 1),
        reviewed: action,
      });
      getActivitiesCount({ applySpecialFilter: true });
    }
  }, [activity.filters, activity.dates]);

  useEffect(() => {
    getInsuranceTypes();
    if (socket.client && socket.isConnected) {
      socket.client.on(BroadcastTopics.CallInit, (data) => {
        if (data.isRpmCall) {
          logger.info('CALL INITIALIZED', data);
        }
      });
      socket.client.on(BroadcastTopics.Call, (data) => {
        if (data.isRpmCall) {
          addOnCallPatient(data.patientGuid);
        }
      });
      socket.client.on(BroadcastTopics.CallEnded, (data) => {
        if (data.isRpmCall) {
          removeOnCallPatient(data.patientGuid);
        }
      });
      socket.client.on(BroadcastTopics.SlaTimerStop, (data) => {
        if (data.slaReviewedTime) {
          if (data.type === 'single') {
            UPDATE_READING_SLA_REVIEWED_TIME({
              id: data.readingId,
              deviceId: data.deviceId,
              patientGuid: data.patientGuid,
              readingId: data.readingId,
              slaReviewedTime: data.slaReviewedTime,
            });
          }
          if (data.type === 'all') {
            UPDATE_PATIENT_SLA_REVIEWED_TIME({
              patientGuid: data.patientGuid,
              slaReviewedTime: data.slaReviewedTime,
            });
          }
        }
      });
    }

    return () => {
      if (socket.client && socket.isConnected === true) {
        socket.client.off(BroadcastTopics.CallInit);
        socket.client.off(BroadcastTopics.Call);
        socket.client.off(BroadcastTopics.CallEnded);
        socket.client.off(BroadcastTopics.SlaTimerStop);
      }
    };
  }, [activity.filters.clinicGuid, socket]);

  useWebsocketRoom('rpmDashboard');

  useEffect(() => {
    if (socket.client && socket.isConnected && activity.filters.slaReadings) {
      socket.client.send(SocketRoutes.JoinToRoom, { room: 'rpmDashboardSla' });
    }
  }, [socket.isConnected, activity.filters.slaReadings]);

  useEffect(() => {
    return () => {
      if (
        socket.client &&
        socket.isConnected === true &&
        activity.filters.slaReadings
      ) {
        socket.client.send(SocketRoutes.LeaveRoom, { room: 'rpmDashboardSla' });
      }
    };
  }, [activity.filters.slaReadings]);

  useEffect(() => {
    getDoctorPatientsActivity();
    return () => clearComments();
  }, [getDoctorPatientsActivity]);

  unstable_usePrompt({
    message: 'You have unsaved changes, are you sure you want to leave?',
    when: ({ currentLocation, nextLocation }) =>
      !!Object.keys(unsavedComments).length &&
      currentLocation.pathname !== nextLocation.pathname,
  });

  const showMobileMenu = () => {
    const menu = document.getElementById('dashboard-menu');
    menu.className = 'overview-dashboard-menu-mobile';
  };

  return (
    <>
      {(isMobile(device) || isTablet(device)) && (
        <Sorting action={reviewed} toggleAction={changeReviewed} />
      )}
      <Container className="overview-dashboard-container">
        <Row>
          <Col xs={12}>
            <ConditionFilter setMode={setMode} />
          </Col>
        </Row>
        {(isMobile(device) || isTablet(device)) && (
          <Row className="overview-dashboard-filter">
            <Col xs="12">
              <Button text="Filters" handleClick={showMobileMenu} />
            </Col>
          </Row>
        )}
        <Row className="patient-container">
          <Col xs="12">
            {isMobile(device) && (
              <RealTimePatientReadingsMobile onCallPatients={onCallPatients} />
            )}
            {!isMobile(device) && (
              <RealTimePatientReadings
                onCallPatients={onCallPatients}
                device={device}
                toggleAction={changeReviewed}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

OverviewDashboardPageOld.propTypes = {
  setMode: PropTypes.func.isRequired,
};

export default OverviewDashboardPageOld;
