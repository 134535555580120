import dataService from 'services/dataService';
import { apiPatientEmailSubscriptionToggle } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface DataProps {
  reason: string;
  patientGuid: string;
}

export const emailSubscriptionStatusFn = async ({
  reason,
  patientGuid,
}: DataProps) => {
  const { error, data } = await dataService.updateOnly(
    apiPatientEmailSubscriptionToggle(patientGuid),
    {
      reason,
      subscription: true,
    },
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
