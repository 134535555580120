import { useTranslation } from 'react-i18next';
import BaseButton from 'components/atoms/baseButton';
import { useState, type FC } from 'react';
import BaseTableNew from 'components/atoms/base-table/new';
import {
  PATIENT_GOALS_PER_PAGE,
  useAddPatientGoal,
  useGetPatientGoals,
} from 'infrastructure/hooks/ccm';
import EmptyTablePlaceholder from 'components/atoms/base-empty-table-placeholder';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { isCcmActionAllowedStatus } from 'utils/userTypeHelper';

import s from './styles.module.scss';
import { columns } from './columns';
import PatientGoalModalForm from '../admin-panel/forms/patient-goal';

import type { FormikHelpers } from 'formik';
import type { Values } from '../admin-panel/forms/patient-goal/validation-schema';

interface PersonalGoalsProps {
  patientGuid: string;
}

const PersonalGoals: FC<PersonalGoalsProps> = ({ patientGuid }) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const { goals, isLoading } = useGetPatientGoals({
    patientGuid,
    items: PATIENT_GOALS_PER_PAGE,
    page,
    type: 'personal',
  });
  const { addPatientGoal, addLoading } = useAddPatientGoal({ page });

  const onCancelForm = () => {
    setOpen(false);
  };

  const onSubmit = ({ log }: Values, formikHelpers: FormikHelpers<Values>) => {
    if (log)
      addPatientGoal(
        {
          log,
          patientGuid,
          type: 'personal',
          goalDateTime: new Date().toISOString(),
        },
        {
          onSuccess: () => {
            formikHelpers.resetForm();
            onCancelForm();
          },
        },
      );
  };

  const { patientInfo } = usePatientInfo({ patientGuid });
  const disableButton = !isCcmActionAllowedStatus(patientInfo?.ccmStatus);

  return (
    <>
      <div className={s.wrapper}>
        <div className={s['title-wrapper']}>
          <h3 className={s.title} data-cy="personal-goals-section">
            {t('labels.personalGoals')}
          </h3>
          <BaseButton
            label={`+ ${t('labels.addGoal')}`}
            dataCy="personal-goals-add-btn"
            onClick={() => setOpen(true)}
            disabled={isLoading || addLoading || disableButton}
          />
        </div>
        <BaseTableNew
          items={goals?.items ?? []}
          columns={columns()}
          bordered
          loading={isLoading}
          pagination={{
            current: page,
            pageSize: PATIENT_GOALS_PER_PAGE,
            total: goals?.count ?? 0,
            position: ['bottomCenter'],
          }}
          onChangePage={(newPage) => {
            setPage(newPage);
          }}
          dataCy="voice-calls-table"
          // eslint-disable-next-line prettier/prettier
          emptyText={
            <EmptyTablePlaceholder text={t('labels.noPersonalGoalsLogged')} />
            // eslint-disable-next-line prettier/prettier
          }
        />
      </div>
      <PatientGoalModalForm
        loading={addLoading}
        onSubmit={onSubmit}
        onCancelForm={onCancelForm}
        open={open}
        title={t('labels.addPatientPersonalGoal')}
      />
    </>
  );
};
export default PersonalGoals;
