const PercentIcon = (props) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M6.6447 2.62884C6.30706 0.953677 4.66971 -0.134673 2.99455 0.203062C2.18414 0.366362 1.48523 0.836343 1.02662 1.52646C0.568006 2.21655 0.405379 3.04287 0.568679 3.85316C0.73198 4.6636 1.20193 5.3625 1.89199 5.82109C2.40718 6.16348 2.99821 6.34087 3.60199 6.34087C3.80692 6.3409 4.01346 6.32042 4.21884 6.27906C5.89368 5.9415 6.98191 4.3041 6.6447 2.62884ZM3.6902 3.65467C3.46201 3.70052 3.23903 3.55246 3.19309 3.32444C3.14716 3.09642 3.29534 2.87347 3.52342 2.82739C3.75158 2.78154 3.97429 2.92978 4.02032 3.15756C4.0662 3.38576 3.9181 3.60876 3.6902 3.65467Z"
        fill="#2C2543"
      />
      <path
        d="M14.4322 11.1469C14.2689 10.3364 13.799 9.63753 13.1089 9.17895C12.4188 8.72036 11.5925 8.55773 10.7821 8.72101C9.10724 9.05857 8.01901 10.696 8.35622 12.3712C8.65238 13.8407 9.94862 14.8584 11.3932 14.8584C11.5954 14.8584 11.8007 14.8385 12.0064 14.797C12.8168 14.6337 13.5157 14.1637 13.9743 13.4736C14.4329 12.7835 14.5955 11.9571 14.4322 11.1469ZM11.4776 12.1726C11.2497 12.2187 11.0266 12.0703 10.9806 11.8424C10.9346 11.6143 11.0828 11.3913 11.3108 11.3453C11.539 11.2994 11.7619 11.4476 11.8078 11.6756C11.8537 11.9036 11.7055 12.1266 11.4776 12.1726Z"
        fill="#2C2543"
      />
      <path
        d="M12.3357 0.224271C12.0379 0.0263417 11.6809 -0.0438829 11.3303 0.0268105C10.9798 0.097416 10.6778 0.300297 10.4798 0.598158L2.2905 12.9203C1.88228 13.5351 2.05006 14.3675 2.66444 14.7757C2.88672 14.9234 3.1419 15 3.4027 15C3.49152 15 3.581 14.9911 3.67003 14.9732C4.02054 14.9024 4.32253 14.6995 4.5204 14.4018L12.7097 2.0797C13.1179 1.46488 12.9501 0.632494 12.3357 0.224271Z"
        fill="#2C2543"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PercentIcon;
