import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import CommentForm from 'components/molecules/commentForm';
import { showResult } from 'infrastructure/hooks/utils';
import common from 'constants/common';
import MessageIcon from 'components/atoms/icons/messageIcon';
import PcctModal from 'components/templates/rpm/patients/patient/generalComments/pcctModal';

const ConnectComments = ({
  patientGuid,
  isOpen,
  callId,
  isRpmCall,
  readingId,
  deviceId,
  pcctValues,
  setPcctValues,
}) => {
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState('');
  const [pcctPopupOpen, setPcctPopupOpen] = useState(false);
  const {
    activity: { submitComment: submitReadingAction },
    comments: { submit: submitComment },
    ccmPatients: { submitCallNote },
  } = useDispatch();
  const { submitComment: submitReadingLoading } = useSelector(
    (state) => state.loading.effects.activity,
  );
  const { submit: submitCommentLoading } = useSelector(
    (state) => state.loading.effects.comments,
  );
  const { submitCallNote: submitCallNoteLoading } = useSelector(
    (state) => state.loading.effects.ccmPatients,
  );

  useEffect(() => {
    if (!isOpen) setComments([]);
  }, [isOpen]);

  useEffect(() => {
    setComment('');
    setComments([]);
    setPcctValues({});
    setPcctPopupOpen(false);
  }, [patientGuid]);

  const handleSubmit = async (values, { resetForm }) => {
    const handleResponse = (data) => {
      if (data) {
        const newComment = isRpmCall
          ? data
          : {
              createdByFirstName: data.author.firstName,
              createdByLastName: data.author.lastName,
              createdAt: data.createdAt,
              message: data.note,
            };
        setComments([...comments, newComment]);
        resetForm();
        setPcctValues({});
        setPcctPopupOpen(false);
        setComment('');
      }
    };

    const postData = {
      patientGuid,
      ...(isRpmCall
        ? {
            readingId,
            deviceId,
            type: 'text',
            commentType: 'rpm',
            message: values.comment,
            callId,
          }
        : {
            note: values.comment,
            noteActionType: 'patient-call-notes',
          }),
    };

    let data;
    let error;
    if (isRpmCall) {
      ({ data, error } =
        readingId && deviceId
          ? await submitReadingAction(postData)
          : await submitComment(postData));
    } else {
      ({ data, error } = await submitCallNote(postData));
    }

    if (error) showResult(error);
    handleResponse(data);
  };

  const commentChanged = (value) => {
    setComment(value);
  };

  return (
    <div className="connect-modal-comments">
      <div className="connect-modal-comments-history">
        {comments.map((c) => (
          <div className="comment-item">
            <div className="comment-header">
              <strong>
                {c.createdByFirstName} {c.createdByLastName}
              </strong>
              <p>{format(new Date(c.createdAt), common.dateFormats.full)}</p>
            </div>
            <div className="comment-body">
              <span className="pre">{c.message}</span>
            </div>
          </div>
        ))}
      </div>
      <CommentForm
        handleSubmit={handleSubmit}
        valueChanged={commentChanged}
        disabled={
          submitCallNoteLoading ||
          submitCommentLoading ||
          submitReadingLoading ||
          !callId
        }
        isRpmCall={isRpmCall}
        comment={comment}
      />
      {isRpmCall && (
        <span
          className="connect-modal-comments-button"
          onClick={() => setPcctPopupOpen(!pcctPopupOpen)}
        >
          <MessageIcon />
        </span>
      )}
      {pcctPopupOpen && (
        <PcctModal
          isOpen={pcctPopupOpen}
          closeModal={() => setPcctPopupOpen(false)}
          values={pcctValues}
          setValues={setPcctValues}
          setComment={setComment}
        />
      )}
    </div>
  );
};

ConnectComments.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  patientGuid: PropTypes.string.isRequired,
  callId: PropTypes.string,
  isRpmCall: PropTypes.bool,
  readingId: PropTypes.string,
  deviceId: PropTypes.string,
  pcctValues: PropTypes.object,
  setPcctValues: PropTypes.func,
};

export default ConnectComments;
