import React, {
  useCallback,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { components as SelectComponents } from 'react-select';
import { apiGetDoctorsByFilter } from 'services/dataService/resources';
import { debounce } from 'lodash';
import dataService from 'services/dataService';
import common from 'constants/common';
import SearchIcon from 'components/atoms/icons/searchIcon';

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '49px',
  }),
  menu: (base) => {
    return { ...base, zIndex: 9999 };
  },
};

const selectTheme = (theme) => ({
  ...theme,
  borderRadius: 12,
  colors: {
    ...theme.colors,
    ...common.primaryColors,
    neutral50: '#cccad7',
  },
});

const searchDoctors = async (filter, { signal }) => {
  const { data } = await dataService.getList(apiGetDoctorsByFilter(filter), {
    signal,
  });
  return data || [];
};

const AutoCompleteDoctorMultiSelect = forwardRef((props, ref) => {
  const {
    onChange,
    clinicGuid,
    roleName = 'doctor,nursePractitioner,registeredNurse,physicianAssistant,pharmD',
  } = props;

  const [selectedValues, setSelectedValues] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const abortControllerRef = useRef(undefined);
  const loadSuggestions = useCallback(
    debounce(({ doctorName, callback }) => {
      const filter = {
        roleName,
        fullName: doctorName,
        items: 50,
        clinicGuid,
      };
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      searchDoctors(filter, {
        signal: abortControllerRef.current.signal,
      }).then((response) => {
        const data = response.map((item) => ({
          id: item.guid,
          value: item.guid,
          label: `${item.firstName} ${item.lastName}`,
        }));
        return callback(data);
      });
    }, 400),
    [clinicGuid],
  );

  const reset = () => {
    setInputValue('');
    setSelectedValues([]);
  };

  useImperativeHandle(ref, () => ({
    reset,
  }));

  const customSelectComponents = {
    DropdownIndicator: (defaultProps) => (
      <SelectComponents.DropdownIndicator
        {...defaultProps}
        className="select-type__search-indicator"
      >
        <SearchIcon />
      </SelectComponents.DropdownIndicator>
    ),
    Menu: (defaultProps) => {
      if (inputValue.length > 0) {
        return <SelectComponents.Menu {...defaultProps} />;
      }
      return null;
    },
  };

  const onSelect = (values) => {
    // if previous value is empty and current value is empty, do nothing
    if (selectedValues.length === 0 && values.length === 0) return;
    setSelectedValues(values);
    const doctorGuids = values.map((value) => value.id).join(',');
    onChange(doctorGuids);
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        classNamePrefix="select-type"
        className="select-type__search"
        theme={(theme) => selectTheme(theme)}
        isMulti
        styles={selectStyles}
        loadOptions={(doctorName, callback) => {
          loadSuggestions({ doctorName, callback });
        }}
        onChange={onSelect}
        placeholder="John Doe"
        components={customSelectComponents}
        value={selectedValues}
        inputValue={inputValue}
        onInputChange={(value) => {
          setInputValue(value);
        }}
      />
    </>
  );
});

AutoCompleteDoctorMultiSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  clinicGuid: PropTypes.string,
  roleName: PropTypes.string,
};

export default AutoCompleteDoctorMultiSelect;
