import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { chargerConsumablesDataSource } from 'infrastructure/data-sources/charger-consumables';

import type { ReadingType } from 'infrastructure/enums';
import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IChargerConsumablesItem } from 'infrastructure/data-sources/charger-consumables';

type Options = IBaseSelectBoxOption<
  IChargerConsumablesItem['id'],
  IChargerConsumablesItem
>[];

interface IChargerConsumablesSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {
  readingType?: ReadingType;
}

const ChargerConsumablesSelectBox: React.FC<
  IChargerConsumablesSelectBoxProps
> = (props) => {
  const { readingType, ...args } = props;

  const dataSource = useMemo(
    () => chargerConsumablesDataSource({ readingType }),
    [readingType],
  );

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...args} />;
};

export default ChargerConsumablesSelectBox;
