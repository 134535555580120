import AdminHeader from 'components/organisms/adminHeader';
import TurkConfiguration from 'components/templates/admin/turkConfiguration';
import { Container } from 'reactstrap';

function TurkConfigurationPage() {
  return (
    <Container>
      <AdminHeader />
      <TurkConfiguration />
    </Container>
  );
}

export default TurkConfigurationPage;
