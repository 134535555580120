import React from 'react';
import { useICDCode } from 'infrastructure/hooks/use-icd-code';
import BaseAsyncSelectBox from 'components/atoms/base-select-box/async';

import type { IPaginationMeta } from 'infrastructure/interfaces';
import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { ICDCode } from 'infrastructure/classes/icd-code';

type ICDCodeSelectOption = IBaseSelectBoxOption<string, ICDCode>;

interface IICDCodeSelectBoxProps extends Omit<IBaseSelectBoxProps, 'options'> {
  onLoaded?: (items: Array<ICDCodeSelectOption>) => void;
  onChange?: (
    value: string,
    option: IBaseSelectBoxOption<string, ICDCode>,
  ) => void;
}

const ICDCodeSelectBox: React.FC<IICDCodeSelectBoxProps> = (props) => {
  const { onLoaded } = props;

  const { meta, loadICDCodes } = useICDCode({
    meta: {
      items: 50,
    },
  });

  const fetchOptions = async (
    searchValue: string,
    metaProps: IPaginationMeta,
  ) => {
    const icdCodes = await loadICDCodes(
      {
        ...metaProps,
        code: searchValue,
      },
      true,
    );

    if (icdCodes)
      return icdCodes.map((el) => {
        return {
          label: el.code,
          value: el.guid,
          record: el,
        };
      });

    return [];
  };

  return (
    <BaseAsyncSelectBox
      fetchOptions={fetchOptions}
      meta={meta}
      onValueLoaded={onLoaded}
      canSearch
      {...props}
    />
  );
};

export default ICDCodeSelectBox;
