import withLoader from 'components/organisms/withLoader';
import Averages from 'components/templates/rpm/patients/patient/averageMetrics/averages';
import useTurkDashboardAverages from 'utils/hooks/turkDashboard/useAverages';

import type { FC } from 'react';

interface Props {
  patientGuid: string;
  dates: {
    startTime: string;
    endTime: string;
    timezoneOffset: number;
  };
}

const PatientAverages: FC<Props> = ({ patientGuid, dates }) => {
  const { data, isLoading } = useTurkDashboardAverages({
    patientGuid,
    dates,
  });

  return withLoader(isLoading, data ? <Averages averages={data} /> : null);
};

export default PatientAverages;
