import dataService from 'services/dataService';
import { API_PATIENTS_APPOINTMENTS } from 'services/dataService/resources';

const appointment = {
  state: {},
  reducers: {
    setDischarge: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch) => ({
    async submit(postData, state) {
      const { error, data } = await dataService.createOne(
        API_PATIENTS_APPOINTMENTS,
        postData,
      );
      if (!error) {
        dispatch.patient.setProfile({
          ...state.patient.profile,
          appointmentsFlagActive: false,
        });
        dispatch.ccmPatientDetails.setPatientDetails({
          ...state.ccmPatientDetails.patient,
          profile: {
            ...state.ccmPatientDetails.patient.profile,
            appointmentsFlagActive: false,
          },
        });
      }
      return { error, data };
    },
  }),
};

export default appointment;
