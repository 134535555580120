import type { IPatientEmr } from 'infrastructure/interfaces';
import type { Nullable } from 'infrastructure/types';

export class PatientEmr {
  patientGuid: string;
  name: Nullable<string>;
  account: Nullable<string>;
  emrId1: Nullable<string>;
  emrId2: Nullable<string>;
  readingsFlag: Nullable<boolean>;
  reports: Nullable<string>;
  billing: Nullable<boolean>;
  billingCycle: Nullable<string>;
  renderingProviderId: Nullable<string>;
  supervisingProviderId: Nullable<string>;

  constructor(data: IPatientEmr) {
    this.patientGuid = data.patientGuid;
    this.name = data.name;
    this.account = data.account;
    this.emrId1 = data.emrId1;
    this.emrId2 = data.emrId2;
    this.readingsFlag = data.readingsFlag;
    this.reports = data.reports;
    this.billing = data.billing;
    this.billingCycle = data.billingCycle;
    this.renderingProviderId = data.renderingProviderId;
    this.supervisingProviderId = data.supervisingProviderId;
  }
}
