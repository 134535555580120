import { languagesMap } from 'constants/languages';
import CopyableText from 'components/atoms/buttons/copyable-text';
import { Link } from 'react-router-dom';
import { Routes } from 'infrastructure/consts/routes';
import ExpandButton from 'components/molecules/expand-button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { appointmentMethodPresenter } from 'infrastructure/functions/appointments';
import AppointmentIconMap from 'components/molecules/appointment-icon-map';
import classNames from 'classnames';

import s from './style.module.scss';

import type { FC, ReactNode } from 'react';
import type { CcmAgentItem } from 'infrastructure/hooks/ccmAgentDashboard/types';

interface IItem {
  item: CcmAgentItem;
}

const Info: React.FC<IItem> = ({ item }) => {
  const { patient } = item;
  const { language, guid, dob, fullName } = patient;
  const languagePresented = languagesMap[language || ''] || language;

  return (
    <ItemWrapper>
      <LineWrapper>
        <Link to={Routes.ccmPatientDetail(guid)} className={s.link}>
          <Title>{fullName}</Title>
        </Link>
        <div>
          <Secondary>{dob}</Secondary>
          <Secondary divider>{languagePresented}</Secondary>
        </div>
      </LineWrapper>
      <CopyableText value={guid} />
    </ItemWrapper>
  );
};

const UpcomingAppointment: FC<IItem> = ({ item }) => {
  const { t } = useTranslation();
  const { appointmentMethod, date, scheduledWith } =
    item.upcomingAppointment || {};
  const method = appointmentMethodPresenter(appointmentMethod);

  return (
    <ItemWrapper>
      <LineWrapper between>
        <Secondary>{t('labels.upcomingAppointment')}:</Secondary>
        <Secondary>
          <AppointmentIconMap method={appointmentMethod} /> {method}
        </Secondary>
      </LineWrapper>
      <LineWrapper between>
        <Text>{date || t('labels.notPlanned')}</Text>
        <Text>{scheduledWith}</Text>
      </LineWrapper>
    </ItemWrapper>
  );
};

interface IProps {
  item: CcmAgentItem;
  expanded: boolean;
  onToggle: (patientGuid: string) => void;
}

const PatientInfo: FC<IProps> = ({ item, expanded, onToggle }) => {
  const { key } = item;

  return (
    <div className={s.wrapper}>
      <Info item={item} />
      <LineWrapper between>
        <div className={s.grow}>
          <UpcomingAppointment item={item} />
        </div>
        <ExpandButton
          className={s.btn}
          expanded={expanded}
          onClick={() => onToggle(key)}
        />
      </LineWrapper>
    </div>
  );
};
interface ITextProps {
  children: ReactNode;
  divider?: boolean;
}

interface ILineWrapperProps {
  children: ReactNode;
  between?: boolean;
}

const Title: FC = ({ children }) => <span className={s.title}>{children}</span>;

const Text: FC<ITextProps> = ({ children, divider }) => (
  <span
    className={classNames(s.text, {
      [s.divider]: divider,
    })}
  >
    {children}
  </span>
);

const Secondary: FC<ITextProps> = ({ children, divider }) => (
  <span
    className={classNames(s['secondary-text'], {
      [s.divider]: divider,
    })}
  >
    {children}
  </span>
);

const LineWrapper: FC<ILineWrapperProps> = ({ children, between }) => (
  <div
    className={classNames(s['line-wrapper'], {
      [s.between]: between,
    })}
  >
    {children}
  </div>
);

const ItemWrapper: FC = ({ children }) => (
  <div className={s['item-wrapper']}>{children}</div>
);

export default PatientInfo;
