import { useMemo, type FC } from 'react';
import { format } from 'date-fns';

import s from './styles.module.scss';

interface Props {
  readingsCount: number;
  earnings: number;
}

const Stats: FC<Props> = ({ earnings, readingsCount }) => {
  const currentMonthAndYear = useMemo(
    () => format(new Date(), 'MMMM yyyy'),
    [],
  );
  return (
    <div className={s.wrapper}>
      <div className={s.header}>{currentMonthAndYear}</div>
      <div className={s.body}>
        <div className={s.item}>
          <div className={s.label}>Total readings:</div>
          <div className={s.value}>{readingsCount}</div>
        </div>
        <div className={s.item}>
          <div className={s.label}>Total dollars earned:</div>
          <div className={s.value}>${earnings?.toFixed?.(2)}</div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
