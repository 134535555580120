import i18next from 'i18next';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { TimerCancelledError } from 'infrastructure/classes/timer-cancelled-error';
import { readingCommentWithUndo } from 'infrastructure/functions/reading-comment';
import dataService from 'services/dataService';
import {
  apiPatientReadings,
  API_READINGS_ACTIONS,
  apiPatientAverageMetrics,
  API_PATIENTS_INTERACTION_STATUS,
} from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';
import store from 'store';

const ITEMS_PER_PAGE = 100;

const INITIAL_STATE = {
  reviewed: false,
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  sort: {
    time: false,
  },
  data: [],
  totalPages: 0,
  totalCount: 0,
  averageMetrics: {},
};

const activity = {
  state: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    setReviewed: (state, payload) => ({
      ...state,
      reviewed: payload,
    }),
    setPage: (state, payload) => ({
      ...state,
      page: payload < 1 ? 1 : payload,
    }),
    setTotalCountAndTotalPages: (state, payload) => ({
      ...state,
      totalCount: payload.totalCount,
      totalPages: payload.totalPages,
    }),
    setActivities: (state, payload) => ({
      ...state,
      data: payload,
    }),
    setAverageMetrics: (state, payload) => ({
      ...state,
      averageMetrics: payload,
    }),
    toggleTimeSort: (state) => ({
      ...state,
      sort: {
        ...state.sort,
        time: !state.sort.time,
      },
    }),
    changeInteractionStatusForPatient: (
      state,
      { patientGuid, interactionStatus },
    ) => ({
      ...state,
      data: state.data.map((item) => {
        if (item.patient.guid === patientGuid) {
          return {
            ...item,
            patient: {
              ...item.patient,
              interactionStatus,
            },
          };
        }
        return item;
      }),
    }),
  },
  effects: (dispatch) => ({
    async getActivities(
      { patientGuid, clinicGuid, reviewed, items = ITEMS_PER_PAGE, userType },
      state,
    ) {
      const { page, sort, reviewed: stateReviewed } = state.activity;
      const { dates } = state.patient;
      const [startTime, endTime] = dates;
      const { data } = await dataService.getList(
        apiPatientReadings(
          patientGuid,
          clinicGuid,
          reviewed ?? stateReviewed,
          items,
          page,
          startTime,
          endTime,
          userType,
          sort.time,
        ),
      );
      if (data) {
        dispatch.activity.setActivities(data?.data || []);
        const totalCount = data?.count || 0;
        const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
        dispatch.activity.setTotalCountAndTotalPages({
          totalCount,
          totalPages,
        });
      } else {
        dispatch.activity.setActivities([]);
        dispatch.activity.setTotalCountAndTotalPages({
          totalCount: 0,
          totalPages: 0,
        });
      }
    },
    async getAverageMetrics({ patientGuid }, state) {
      const timezoneOffset = new Date().getTimezoneOffset();
      const [, endTime] = state.patient.dates;
      const { data } = await dataService.getList(
        apiPatientAverageMetrics(patientGuid, {
          endTime,
          timezoneOffset,
        }),
      );
      dispatch.activity.setAverageMetrics(data);
    },
    async submitComment(postData) {
      const appendComment = (data) => {
        const state = store.getState();
        const updatedData = state.activity.data.map((item) =>
          item.id === data.readingId && item.deviceId === data.deviceId
            ? { ...item, comments: [data, ...item.comments] }
            : item,
        );
        dispatch.activity.setActivities(updatedData);
      };
      const removeComment = (data) => {
        const state = store.getState();
        const updatedData = state.activity.data.map((item) =>
          item.id === data.readingId && item.deviceId === data.deviceId
            ? {
                ...item,
                comments: item.comments.filter(
                  (comment) => comment.guid !== data.guid,
                ),
              }
            : item,
        );
        dispatch.activity.setActivities(updatedData);
      };
      const state = store.getState();

      const { mockComment, undoTimeoutPromise } = readingCommentWithUndo({
        message: postData.message,
        readingId: postData.readingId,
        deviceId: postData.deviceId,
        createdBy: state.user.loginGuid,
        createdByFirstName: state.user.firstName,
        createdByLastName: state.user.lastName,
        createdByProfessionType: state.user.professionType,
        createdByTitle: state.user.title,
      });
      appendComment(mockComment);
      try {
        await undoTimeoutPromise;
        const { error, data } = await dataService.createOne(
          API_READINGS_ACTIONS,
          postData,
        );
        removeComment(mockComment);
        if (!error) {
          appendComment(data);
          notificationController.success({
            message: i18next.t('labels.commentSubmitted'),
          });
        }
        return { error, data };
      } catch (error) {
        removeComment(mockComment);
        if (error instanceof TimerCancelledError) {
          return {};
        }
        return { error };
      }
    },
    async actionReview(postData, state) {
      const { error, data } = await dataService.createOne(
        API_READINGS_ACTIONS,
        postData,
      );
      if (!error) {
        const updatedData = state.activity.data.map((item) => {
          if (item.id !== data.readingId || item.deviceId !== data.deviceId)
            return item;
          return {
            ...item,
            disabled: true,
          };
        });
        dispatch.activity.setActivities(updatedData);
      }
      return { error, data };
    },
    async changeReviewed({ patientGuid, clinicGuid }, state) {
      const { reviewed, averageMetrics } = state.activity;

      dispatch.activity.reset();
      dispatch.activity.setReviewed(!reviewed);
      dispatch.activity.setAverageMetrics(averageMetrics);

      dispatch.activity.getActivities({
        patientGuid,
        reviewed: !reviewed,
        clinicGuid,
      });
    },
    async setActivitiesPage(
      { patientGuid, clinicGuid, pageOffset, userType, goToPage },
      state,
    ) {
      const { activity: activityState } = state;
      const { page, reviewed, totalPages } = activityState;
      const newPage = goToPage || page + pageOffset;

      dispatch.activity.setPage(newPage <= totalPages ? newPage : totalPages);

      return dispatch.activity.getActivities({
        patientGuid,
        clinicGuid,
        page: newPage,
        reviewed,
        userType,
      });
    },
    async toggleActivitiesTimeSort(
      { patientGuid, clinicGuid, userType },
      state,
    ) {
      const { reviewed } = state.activity;

      dispatch.activity.toggleTimeSort();
      return dispatch.activity.getActivities({
        patientGuid,
        clinicGuid,
        reviewed,
        userType,
      });
    },
    async togglePatientInteractionStatus({ patientGuid, interactionStatus }) {
      const { error } = await dataService.updateOnly(
        API_PATIENTS_INTERACTION_STATUS,
        {
          patientGuid,
          isInteracted: interactionStatus,
        },
      );

      if (error) {
        showResult(error);
        return;
      }
      dispatch.activity.changeInteractionStatusForPatient({
        patientGuid,
        interactionStatus,
      });
    },
  }),
};

export default activity;
