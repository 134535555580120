import { useDispatch, useSelector } from 'react-redux';
import { TabContent, TabPane } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { isMobile } from 'utils/deviceHelper';
import { INITIAL_STATE } from 'store/smsDashboard';
import Conversation from 'components/atoms/chat/conversation';
import InfiniteScroll from 'components/atoms/infinityScroll';
import MessagesSearchIcon from 'components/atoms/icons/messagesSearchEmptyIcon';
import ConversationsEmptyIcon from 'components/atoms/icons/conversationsEmptyIcon';
import { useSmsDashboardConversations } from 'infrastructure/hooks/smsDashboard/useConversations';
import BaseLoader from 'components/atoms/base-loader';
import { useMemo } from 'react';
import { useMarkConversation } from 'infrastructure/hooks/smsDashboard/useMarkConversation';

import type {
  IConversation,
  IConversationsListRes,
} from 'infrastructure/interfaces';
import type { RootDispatch, RootState } from 'store';

const formatLastMessage = (message: string, isMobileView: boolean) => {
  if (!message || message.length === 0) {
    return i18n.t('smsDashboard.no_messages_yet');
  }
  if (isMobileView) {
    const mobileMessagePreviewLength = 35;
    return message.length <= mobileMessagePreviewLength
      ? message
      : `${message.substr(0, mobileMessagePreviewLength - 4)} ...`;
  }
  return message;
};

const ConversationsList = () => {
  const { t } = useTranslation();
  const {
    smsDashboard: { changeCurrentConversation },
  } = useDispatch<RootDispatch>();

  const device = useSelector((state: RootState) => state.user.deviceType);
  const deviceIsMobile: boolean = isMobile(device);

  const { conversationType, conversationTypes, currentConversation, filters } =
    useSelector((state: RootState) => state.smsDashboard);

  const memoizedDate = useMemo(() => {
    const currentDate = new Date();
    return currentDate;
  }, [
    conversationType,
    filters.closed,
    filters.read,
    filters.lastMessageDate,
    filters.searchQuery,
  ]);

  const isFiltersDefaultState =
    filters.read === INITIAL_STATE.filters.read &&
    filters.closed === INITIAL_STATE.filters.closed &&
    filters.lastMessageDate === INITIAL_STATE.filters.lastMessageDate &&
    filters.searchQuery === INITIAL_STATE.filters.searchQuery;

  const {
    conversations,
    conversationsLoading,
    fetchNextPage,
    isError,
    isLoading,
  } = useSmsDashboardConversations({
    cursor: memoizedDate,
    type: conversationType,
    read: filters.read,
    closed: filters.closed,
    lastMessageDate: filters.lastMessageDate,
    searchQuery: filters.searchQuery,
  });

  const { markConversation } = useMarkConversation({});

  const onClickConversation = (conversationProp: IConversation) => {
    changeCurrentConversation(conversationProp);
    if (conversationProp && conversationProp.read === false) {
      markConversation({ conversationId: conversationProp.id, read: true });
    }
  };

  const conversationListMessage = isFiltersDefaultState ? (
    <div className="empty-message">
      <ConversationsEmptyIcon />
      <div className="message">
        <Trans
          i18nKey="smsDashboard.empty_conversation_message"
          values={{
            description1: t('smsDashboard.you_dont_have_any_active_chats_yet'),
            description2: t('smsDashboard.press_button_to_create_a_new_one'),
          }}
          components={{
            primary: <span />,
            secondary: <span />,
          }}
        />
      </div>
    </div>
  ) : (
    <div className="empty-message">
      <MessagesSearchIcon />
      <div className="message">
        <Trans
          i18nKey="smsDashboard.empty_conversation_message"
          values={{
            description1: `${t('smsDashboard.no_result_found')},`,
            description2: t('smsDashboard.change_filter'),
          }}
          components={{
            primary: <span />,
            secondary: <span />,
          }}
        />
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className="empty-message">
        <BaseLoader
          style={{ textAlign: 'center', padding: '1rem' }}
          height={30}
          width={30}
          loading
        />
      </div>
    );
  }

  return (
    <TabContent activeTab={conversationType} className="conversations-messages">
      {conversationTypes.map((type: string) => {
        return (
          <TabPane tabId={type} key={type} hidden={conversationType !== type}>
            {conversations?.map(
              (conversationData: IConversationsListRes | undefined) => {
                return conversationData?.items.map((conversation) => (
                  <Conversation
                    key={conversation.id}
                    selected={
                      (currentConversation as unknown as IConversation)?.id ===
                      conversation.id
                    }
                    patientName={
                      conversation.patient?.fullNameWithOnlyTitle ||
                      conversation.patient?.patientPhone
                    }
                    lastMessage={formatLastMessage(
                      conversation.lastMessage,
                      deviceIsMobile,
                    )}
                    lastMessageDate={conversation.lastMessageDate}
                    unreadMessageCount={conversation.unreadMessageCount}
                    hasPatientMessage={conversation.hasPatientMessage}
                    isRead={conversation.read}
                    closed={conversation.closed}
                    onClick={() => onClickConversation(conversation)}
                  />
                ));
              },
            )}
            {conversations?.[0]?.items.length === 0 && (
              <div className="empty-conversations">
                {conversationListMessage}
              </div>
            )}

            <InfiniteScroll
              loadMore={fetchNextPage}
              disabled={conversationsLoading}
              allLoaded={
                conversations === undefined ||
                !conversations[conversations.length - 1]?.hasMore
              }
              uniqueId={`conversations-list-${conversationType}`}
              manualMode={isError || conversationType !== type}
            />
          </TabPane>
        );
      })}
    </TabContent>
  );
};

export default ConversationsList;
