import { convertSecondsToTime } from 'utils/timeHelper';
import { Link } from 'react-router-dom';
import { convertUTCToLocal, getLatestDate } from 'utils/date';
import { format } from 'date-fns';
import common from 'constants/common';
import classNames from 'classnames';

import s from './style.module.scss';

import type { InteractiveTimeItem } from 'infrastructure/hooks/interactiveTimeDashboard/types';
import type { TableProps } from 'antd';

export const useColumns = (): TableProps<InteractiveTimeItem>['columns'] => {
  const lastCallTime = (actionTimes: InteractiveTimeItem['actionTimes']) => {
    const patientCallDate = convertUTCToLocal(
      actionTimes.patientCall.updatedAt,
    );
    const readingCallDate = convertUTCToLocal(
      actionTimes.readingCall.updatedAt,
    );
    if (!patientCallDate && !readingCallDate) return null;
    const lastCall = getLatestDate(patientCallDate, readingCallDate);
    if (!lastCall) return null;
    return format(lastCall, common.dateFormats.dateAndTime);
  };

  const styleRange = (time: number) => {
    if (time / 60 < 10) return 'rangeRed';
    if (time / 60 < 20) return 'rangeOrange';
    if (time / 60 < 40) return 'rangeYellow';
    if (time / 60 < 60) return 'rangeGreen';
    return 'rangeLightGreen';
  };

  const getRange = (time: number) => {
    if (time / 60 < 10) return '< 10 min';
    if (time / 60 < 20) return '< 20 min';
    if (time / 60 < 40) return '< 40 min';
    if (time / 60 < 60) return '< 60 min';
    return '> 60 min';
  };

  return [
    {
      title: 'Patient Name',
      render: (_, record) => (
        <Link to={`/patient/${record.patient.guid}`} className={s.link}>
          {record.patient.fullName}
        </Link>
      ),
    },
    {
      title: 'MRN',
      render: (_, record) => <span>{record.patient.mrn}</span>,
    },
    {
      title: 'Contact',
      render: (_, record) => <span>{record.patient.phone}</span>,
    },
    {
      title: 'Insurance Plan Provider',
      render: (_, record) => <span>{record.insurances?.[0]?.provider}</span>,
    },
    {
      title: 'Insurance Plan Number',
      render: (_, record) => <span>{record.insurances?.[0]?.number}</span>,
    },
    {
      title: 'Provider',
      render: (_, record) => <span>{record.doctor.fullName}</span>,
    },

    {
      title: 'Number Of Reviews',
      render: (_, record) => (
        <span>{record.actionTimes.readingReview.count}</span>
      ),
    },
    {
      title: 'Total Review Time',
      render: (_, record) => (
        <span>
          {convertSecondsToTime(record.actionTimes.readingReview.time)}
        </span>
      ),
    },

    {
      title: 'Number Of Comments',
      render: (_, record) => (
        <span>
          {record.actionTimes.readingComment.count +
            record.actionTimes.patientComment.count}
        </span>
      ),
    },
    {
      title: 'Total Comment Time',
      render: (_, record) => (
        <span>
          {convertSecondsToTime(
            record.actionTimes.readingComment.time +
              record.actionTimes.patientComment.time,
          )}
        </span>
      ),
    },

    {
      title: 'Number Of Manuals',
      render: (_, record) => <span>{record.actionTimes.manual.count}</span>,
    },
    {
      title: 'Total Manual Time',
      render: (_, record) => (
        <span>{convertSecondsToTime(record.actionTimes.manual.time)}</span>
      ),
    },

    {
      title: 'Number Of Calls',
      render: (_, record) => (
        <span>
          {record.actionTimes.patientCall.count +
            record.actionTimes.readingCall.count}
        </span>
      ),
    },
    {
      title: 'Total Call Time',
      render: (_, record) => (
        <span>
          {convertSecondsToTime(
            record.actionTimes.patientCall.time +
              record.actionTimes.readingCall.time,
          )}
        </span>
      ),
    },

    {
      title: 'Last Call Time',
      render: (_, record) => <span>{lastCallTime(record.actionTimes)}</span>,
    },
    {
      title: 'Range',
      render: (_, record) => {
        return {
          props: {
            className: classNames(
              s[styleRange(record.actionTimes.total)],
              s['range-cell'],
            ),
          },
          children: <div>{getRange(record.actionTimes.total)}</div>,
        };
      },
    },
    {
      fixed: 'right',
      title: 'Total Time',
      render: (_, record) => (
        <span>{convertSecondsToTime(record.actionTimes.total)}</span>
      ),
    },
  ];
};
