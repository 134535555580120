import React from 'react';
import { motion } from 'framer-motion';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

interface IProps extends IBaseComponentProps {
  direction: 'left' | 'right';
}

const SlideAnimation: React.FC<IProps> = (props) => {
  const { uniqueKey = Date.now(), direction, children } = props;

  const slideAnimation = {
    initial: { opacity: 0, x: direction === 'left' ? -100 : 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: direction === 'left' ? 100 : -100 },
  };

  return (
    <motion.div
      key={uniqueKey}
      initial={slideAnimation.initial}
      animate={slideAnimation.animate}
      exit={slideAnimation.exit}
      transition={{
        duration: 0.3,
      }}
    >
      {children}
    </motion.div>
  );
};

export default SlideAnimation;
