import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FingerPrintIcon from 'components/atoms/icons/fingerPrintIcon';

import SelectStatusPopup from './selectStatusPopup';

const ActiveStatusButton = ({ status = '' }) => {
  const [isPopupOpen, setIsPopUpOpen] = useState(false);

  const openPopup = (e) => {
    e.preventDefault();
    setIsPopUpOpen(true);
  };

  return (
    <div className="select-status">
      <button
        onClick={openPopup}
        className="active-status-button"
        type="button"
        data-cy="clinic-status-button"
      >
        <span>
          Status
          <br />
          {status ? status.charAt(0).toUpperCase() + status.slice(1) : 'Active'}
        </span>
        <FingerPrintIcon />
      </button>

      {isPopupOpen && <SelectStatusPopup toggle={setIsPopUpOpen} />}
    </div>
  );
};

ActiveStatusButton.propTypes = {
  status: PropTypes.string,
};

export default ActiveStatusButton;
