import type { SVGProps } from 'react';

const ControlClinicalEscalationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.92423 4.62403C9.9564 -0.473549 16.7027 -1.50509 21.0129 2.27339C25.3231 6.05187 25.5522 16.4803 21.0129 21.0786C16.4737 25.6768 6.01155 24.2016 2.44904 21.0786C-1.11347 17.9555 -0.107947 9.72162 4.92423 4.62403Z"
      fill="#FB9318"
    />
    <path
      d="M12.2858 7.51177C12.2989 7.50454 12.3151 7.5 12.3332 7.5C12.3512 7.5 12.3675 7.50454 12.3805 7.51177C12.3918 7.51802 12.4066 7.52944 12.4216 7.55484L17.5643 16.3048C17.591 16.3504 17.5905 16.398 17.5659 16.4423C17.5536 16.4646 17.5381 16.4793 17.5249 16.4875C17.5138 16.4943 17.4996 16.5 17.4759 16.5H7.19047C7.16681 16.5 7.15256 16.4943 7.14152 16.4875C7.12825 16.4793 7.11282 16.4646 7.10049 16.4423C7.07588 16.398 7.07534 16.3504 7.10209 16.3048L12.2448 7.55484C12.2597 7.52944 12.2746 7.51802 12.2858 7.51177ZM13.0682 7.17482C12.7353 6.60839 11.9311 6.60839 11.5982 7.17482L6.4555 15.9248C6.11273 16.508 6.52424 17.25 7.19047 17.25H17.4759C18.1421 17.25 18.5537 16.508 18.2109 15.9248L13.0682 7.17482Z"
      fill="white"
    />
    <path
      d="M11.5832 15C11.5832 14.5858 11.919 14.25 12.3332 14.25C12.7474 14.25 13.0832 14.5858 13.0832 15C13.0832 15.4142 12.7474 15.75 12.3332 15.75C11.919 15.75 11.5832 15.4142 11.5832 15Z"
      fill="white"
    />
    <path
      d="M11.6567 10.4963C11.6167 10.0967 11.9305 9.75 12.332 9.75C12.7336 9.75 13.0474 10.0967 13.0074 10.4963L12.7443 13.1269C12.7232 13.3387 12.5449 13.5 12.332 13.5C12.1191 13.5 11.9409 13.3387 11.9197 13.1269L11.6567 10.4963Z"
      fill="white"
    />
  </svg>
);

export default ControlClinicalEscalationIcon;
