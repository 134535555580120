import { phoneRegex } from 'constants/regex';
import * as Yup from 'yup';

const AgencyDoctorSchema = Yup.object().shape({
  title: Yup.string().required('required').typeError('required'),
  firstName: Yup.string().required('required'),
  lastName: Yup.string().required('required'),
  agencies: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
    )
    .min(1),
  middleName: Yup.string().nullable(),
  mobileNumber: Yup.string()
    .matches(phoneRegex, 'Phone number is not correct')
    .required('required'),
  workNumber: Yup.string()
    .matches(phoneRegex, 'Phone number is not correct')
    .required('required'),
  email: Yup.string()
    .email('Email is not correct')
    .required('required')
    .test('sign', '+ sign not allowed', (val) => val?.indexOf('+') === -1),
  birthDate: Yup.string().matches(
    /^(0[1-9]|1[012])[-]([012][0-9]|3[01])[-]([0-9]{4})$/,
    'Birth Date must be in the format MM-DD-YYYY.',
  ),
  npiNumber: Yup.string().nullable(),
  professionType: Yup.string().nullable(),
  roles: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
    )
    .min(1),
  primaryLanguage: Yup.string().nullable(),
  secondaryLanguage: Yup.string().nullable(),
  addressLine1: Yup.string().nullable(),
  addressLine2: Yup.string().nullable(),
  city: Yup.string().when('zip', {
    is: (zip) => !!zip,
    then: (schema) => schema.required().nullable(),
    otherwise: (schema) => schema.nullable(),
  }),
  state: Yup.string().when(['zip', 'city'], {
    is: (zip, city) => !!zip || !!city,
    then: Yup.string().required().nullable(),
    otherwise: Yup.string().nullable(),
  }),
  zip: Yup.string().nullable(),
  timezone: Yup.string().nullable(),
  alertEmail: Yup.bool().nullable(),
  alertSms: Yup.bool().nullable(),
});

export default AgencyDoctorSchema;
