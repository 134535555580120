import type { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const AdminHeaderAddIcon = ({ ...props }: Props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" rx="8" fill="#FB9318" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12.5C20.3452 12.5 20.625 12.7798 20.625 13.125V19.375H26.875C27.2202 19.375 27.5 19.6548 27.5 20C27.5 20.3452 27.2202 20.625 26.875 20.625H20.625V26.875C20.625 27.2202 20.3452 27.5 20 27.5C19.6548 27.5 19.375 27.2202 19.375 26.875V20.625H13.125C12.7798 20.625 12.5 20.3452 12.5 20C12.5 19.6548 12.7798 19.375 13.125 19.375H19.375V13.125C19.375 12.7798 19.6548 12.5 20 12.5Z"
      fill="white"
    />
  </svg>
);

export default AdminHeaderAddIcon;
