import i18next from 'i18next';
import { GlucometerConsumables } from 'infrastructure/enums';

export interface IGlucometerConsumablesItem {
  id: GlucometerConsumables;
  name: string;
}

export const glucometerConsumablesDataSource =
  (): IGlucometerConsumablesItem[] => {
    return [
      {
        id: GlucometerConsumables.CBLS_G427B,
        name: i18next.t('consumables.CBLS_G427B'),
      },
    ];
  };
