import React from 'react';

const ControlPanelIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 3V6H3V3H6ZM7.5 21V18C7.5 17.1716 6.82843 16.5 6 16.5H3C2.17157 16.5 1.5 17.1716 1.5 18V21C1.5 21.8284 2.17157 22.5 3 22.5H6C6.82843 22.5 7.5 21.8284 7.5 21ZM7.5 13.5V10.5C7.5 9.67157 6.82843 9 6 9H3C2.17157 9 1.5 9.67157 1.5 10.5L1.5 13.5C1.5 14.3284 2.17157 15 3 15H6C6.82843 15 7.5 14.3284 7.5 13.5ZM7.5 6V3C7.5 2.17157 6.82843 1.5 6 1.5L3 1.5C2.17157 1.5 1.5 2.17157 1.5 3L1.5 6C1.5 6.82843 2.17157 7.5 3 7.5H6C6.82843 7.5 7.5 6.82843 7.5 6ZM15 21V18C15 17.1716 14.3284 16.5 13.5 16.5H10.5C9.67157 16.5 9 17.1716 9 18V21C9 21.8284 9.67157 22.5 10.5 22.5H13.5C14.3284 22.5 15 21.8284 15 21ZM15 13.5V10.5C15 9.67157 14.3284 9 13.5 9H10.5C9.67157 9 9 9.67157 9 10.5V13.5C9 14.3284 9.67157 15 10.5 15H13.5C14.3284 15 15 14.3284 15 13.5ZM15 6V3C15 2.17157 14.3284 1.5 13.5 1.5L10.5 1.5C9.67157 1.5 9 2.17157 9 3V6C9 6.82843 9.67157 7.5 10.5 7.5H13.5C14.3284 7.5 15 6.82843 15 6ZM13.5 3V6H10.5V3L13.5 3ZM21 3V6H18V3H21ZM6 10.5V13.5H3L3 10.5H6ZM13.5 10.5V13.5H10.5V10.5H13.5ZM21 10.5H18V13.5H21V10.5ZM6 18V21H3V18H6ZM13.5 18V21H10.5V18H13.5ZM21 18V21H18V18H21ZM18 1.5C17.1716 1.5 16.5 2.17157 16.5 3V6C16.5 6.82843 17.1716 7.5 18 7.5H21C21.8284 7.5 22.5 6.82843 22.5 6V3C22.5 2.17157 21.8284 1.5 21 1.5H18ZM16.5 10.5C16.5 9.67157 17.1716 9 18 9H21C21.8284 9 22.5 9.67157 22.5 10.5V13.5C22.5 14.3284 21.8284 15 21 15H18C17.1716 15 16.5 14.3284 16.5 13.5V10.5ZM18 16.5C17.1716 16.5 16.5 17.1716 16.5 18V21C16.5 21.8284 17.1716 22.5 18 22.5H21C21.8284 22.5 22.5 21.8284 22.5 21V18C22.5 17.1716 21.8284 16.5 21 16.5H18Z"
      fill="currentColor"
    />
  </svg>
);

export default ControlPanelIcon;
