const ProfileIcon = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.12293 6.16538C12.8325 -0.631399 21.8276 -2.00679 27.5745 3.03118C33.3215 8.06915 33.6269 21.9738 27.5745 28.1048C21.5222 34.2357 7.5727 32.2688 2.82268 28.1048C-1.92733 23.9407 -0.586638 12.9622 6.12293 6.16538Z"
      fill="#FB9318"
    />
    <path
      d="M21.3333 22C21.3333 21.0696 21.3333 20.6044 21.2185 20.2259C20.9599 19.3736 20.293 18.7067 19.4407 18.4482C19.0622 18.3333 18.597 18.3333 17.6666 18.3333H14.3333C13.4029 18.3333 12.9377 18.3333 12.5592 18.4482C11.7069 18.7067 11.04 19.3736 10.7815 20.2259C10.6666 20.6044 10.6666 21.0696 10.6666 22M19 13C19 14.6569 17.6568 16 16 16C14.3431 16 13 14.6569 13 13C13 11.3431 14.3431 10 16 10C17.6568 10 19 11.3431 19 13Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProfileIcon;
