import { useQuery } from '@tanstack/react-query';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';
import dataService from 'services/dataService';
import { API_MESSAGES_RESPONSE } from 'services/dataService/ccmResources';
import { showResult } from 'infrastructure/hooks/utils';
import { useAppSelector } from 'store';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import type {
  ISmsActivityResponse,
  IPartialPaginationMeta,
} from 'infrastructure/interfaces';

interface Props {
  patientGuid: string;
  meta: IPartialPaginationMeta;
}

type TFilter = {
  patientGuid: string;
  startTime?: string;
  endTime?: string;
} & IPartialPaginationMeta;

export const getSmsActivitiesFn = async ({ ...filter }: TFilter) => {
  const url = API_MESSAGES_RESPONSE;
  const { data, error } = await dataService.getList<ISmsActivityResponse>(
    apiUrlGenerator(url, filter),
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const getSmsActivitiesKey = (filter: TFilter) => [
  'getSmsActivities',
  filter,
];

export const SMS_ACTIVITIES_PER_PAGE = 5;

export const useGetSmsActivities = ({ patientGuid, meta }: Props) => {
  const [startTime, endTime] = useAppSelector<[Date, Date] | []>(
    (state) => state.ccmPatientDetails.dates,
  );

  const filter: TFilter = {
    ...meta,
    patientGuid,
    items: SMS_ACTIVITIES_PER_PAGE,
    startTime: DateFormatter({
      date: startTime,
      format: DateFormats['yyyy-MM-dd HH:mm:s'],
    }),
    endTime: DateFormatter({
      date: endTime,
      format: DateFormats['yyyy-MM-dd HH:mm:s'],
    }),
  };

  const {
    data: smsActivity,
    isLoading,
    isFetching,
    isRefetching,
    ...other
  } = useQuery({
    queryKey: getSmsActivitiesKey(filter),
    queryFn: async () => getSmsActivitiesFn({ ...filter }),
    enabled: Boolean(startTime) && Boolean(endTime),
  });

  return {
    smsActivity,
    isLoading: isLoading || isFetching || isRefetching,
    isFetching,
    ...other,
  };
};
