import React from 'react';
import COLORS from 'assets/styles/color.module.scss';

import type { IIconProps } from 'infrastructure/interfaces';
import type { Nullable } from 'infrastructure/types';

const level0: React.FC<IReceptionIconProps> = ({
  color = COLORS.BUOYANT,
  ...props
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 13.5C0.5 13.2239 0.723858 13 1 13H3C3.27614 13 3.5 13.2239 3.5 13.5C3.5 13.7761 3.27614 14 3 14H1C0.723858 14 0.5 13.7761 0.5 13.5Z"
        fill={color}
      />
      <path
        d="M4.5 13.5C4.5 13.2239 4.72386 13 5 13H7C7.27614 13 7.5 13.2239 7.5 13.5C7.5 13.7761 7.27614 14 7 14H5C4.72386 14 4.5 13.7761 4.5 13.5Z"
        fill={color}
      />
      <path
        d="M8.5 13.5C8.5 13.2239 8.72386 13 9 13H11C11.2761 13 11.5 13.2239 11.5 13.5C11.5 13.7761 11.2761 14 11 14H9C8.72386 14 8.5 13.7761 8.5 13.5Z"
        fill={color}
      />
      <path
        d="M12.5 13.5C12.5 13.2239 12.7239 13 13 13H15C15.2761 13 15.5 13.2239 15.5 13.5C15.5 13.7761 15.2761 14 15 14H13C12.7239 14 12.5 13.7761 12.5 13.5Z"
        fill={color}
      />
    </svg>
  );
};

const level1: React.FC<IReceptionIconProps> = ({
  color = COLORS.BUOYANT,
  ...props
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 11.5C0.5 11.2239 0.723858 11 1 11H3C3.27614 11 3.5 11.2239 3.5 11.5V13.5C3.5 13.7761 3.27614 14 3 14H1C0.723858 14 0.5 13.7761 0.5 13.5V11.5Z"
        fill={color}
      />
      <path
        d="M4.5 13.5C4.5 13.2239 4.72386 13 5 13H7C7.27614 13 7.5 13.2239 7.5 13.5C7.5 13.7761 7.27614 14 7 14H5C4.72386 14 4.5 13.7761 4.5 13.5Z"
        fill={color}
      />
      <path
        d="M8.5 13.5C8.5 13.2239 8.72386 13 9 13H11C11.2761 13 11.5 13.2239 11.5 13.5C11.5 13.7761 11.2761 14 11 14H9C8.72386 14 8.5 13.7761 8.5 13.5Z"
        fill={color}
      />
      <path
        d="M12.5 13.5C12.5 13.2239 12.7239 13 13 13H15C15.2761 13 15.5 13.2239 15.5 13.5C15.5 13.7761 15.2761 14 15 14H13C12.7239 14 12.5 13.7761 12.5 13.5Z"
        fill={color}
      />
    </svg>
  );
};

const level2: React.FC<IReceptionIconProps> = ({
  color = COLORS.BUOYANT,
  ...props
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 11.5C0.5 11.2239 0.723858 11 1 11H3C3.27614 11 3.5 11.2239 3.5 11.5V13.5C3.5 13.7761 3.27614 14 3 14H1C0.723858 14 0.5 13.7761 0.5 13.5V11.5Z"
        fill={color}
      />
      <path
        d="M4.5 8.5C4.5 8.22386 4.72386 8 5 8H7C7.27614 8 7.5 8.22386 7.5 8.5V13.5C7.5 13.7761 7.27614 14 7 14H5C4.72386 14 4.5 13.7761 4.5 13.5V8.5Z"
        fill={color}
      />
      <path
        d="M8.5 13.5C8.5 13.2239 8.72386 13 9 13H11C11.2761 13 11.5 13.2239 11.5 13.5C11.5 13.7761 11.2761 14 11 14H9C8.72386 14 8.5 13.7761 8.5 13.5Z"
        fill={color}
      />
      <path
        d="M12.5 13.5C12.5 13.2239 12.7239 13 13 13H15C15.2761 13 15.5 13.2239 15.5 13.5C15.5 13.7761 15.2761 14 15 14H13C12.7239 14 12.5 13.7761 12.5 13.5Z"
        fill={color}
      />
    </svg>
  );
};

const level3: React.FC<IReceptionIconProps> = ({
  color = COLORS.BUOYANT,
  ...props
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 11.5C0.5 11.2239 0.723858 11 1 11H3C3.27614 11 3.5 11.2239 3.5 11.5V13.5C3.5 13.7761 3.27614 14 3 14H1C0.723858 14 0.5 13.7761 0.5 13.5V11.5Z"
        fill={color}
      />
      <path
        d="M4.5 8.5C4.5 8.22386 4.72386 8 5 8H7C7.27614 8 7.5 8.22386 7.5 8.5V13.5C7.5 13.7761 7.27614 14 7 14H5C4.72386 14 4.5 13.7761 4.5 13.5V8.5Z"
        fill={color}
      />
      <path
        d="M8.5 5.5C8.5 5.22386 8.72386 5 9 5H11C11.2761 5 11.5 5.22386 11.5 5.5V13.5C11.5 13.7761 11.2761 14 11 14H9C8.72386 14 8.5 13.7761 8.5 13.5V5.5Z"
        fill={color}
      />
      <path
        d="M12.5 13.5C12.5 13.2239 12.7239 13 13 13H15C15.2761 13 15.5 13.2239 15.5 13.5C15.5 13.7761 15.2761 14 15 14H13C12.7239 14 12.5 13.7761 12.5 13.5Z"
        fill={color}
      />
    </svg>
  );
};

const level4: React.FC<IReceptionIconProps> = ({
  color = COLORS.BUOYANT,
  ...props
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 11.5C0.5 11.2239 0.723858 11 1 11H3C3.27614 11 3.5 11.2239 3.5 11.5V13.5C3.5 13.7761 3.27614 14 3 14H1C0.723858 14 0.5 13.7761 0.5 13.5V11.5Z"
        fill={color}
      />
      <path
        d="M4.5 8.5C4.5 8.22386 4.72386 8 5 8H7C7.27614 8 7.5 8.22386 7.5 8.5V13.5C7.5 13.7761 7.27614 14 7 14H5C4.72386 14 4.5 13.7761 4.5 13.5V8.5Z"
        fill={color}
      />
      <path
        d="M8.5 5.5C8.5 5.22386 8.72386 5 9 5H11C11.2761 5 11.5 5.22386 11.5 5.5V13.5C11.5 13.7761 11.2761 14 11 14H9C8.72386 14 8.5 13.7761 8.5 13.5V5.5Z"
        fill={color}
      />
      <path
        d="M12.5 2.5C12.5 2.22386 12.7239 2 13 2H15C15.2761 2 15.5 2.22386 15.5 2.5V13.5C15.5 13.7761 15.2761 14 15 14H13C12.7239 14 12.5 13.7761 12.5 13.5V2.5Z"
        fill={color}
      />
    </svg>
  );
};

interface IReceptionIconProps extends IIconProps {
  level?: Nullable<number>;
}

const ReceptionIcon: React.FC<IReceptionIconProps> = (props) => {
  const { level = 0 } = props;

  let icon = level0(props);

  if (level) {
    if (level > 0) {
      icon = level1(props);
    }
    if (level > 1) {
      icon = level2(props);
    }
    if (level > 2) {
      icon = level3(props);
    }
    if (level > 3) {
      icon = level4(props);
    }
  }

  return icon;
};

export default ReceptionIcon;
