import i18next from 'i18next';
import { ModifiedArray } from 'infrastructure/classes/common/modified-array';
import { ClinicStatus } from 'infrastructure/enums';

export interface IClinicStatusItem {
  id: ClinicStatus;
  name: string;
  color?: string;
}

type ClinicStatusArray = ModifiedArray<IClinicStatusItem>;

export const clinicStatusesDataSource = (
  withAll = false,
): ClinicStatusArray => {
  const dataSource = new ModifiedArray<IClinicStatusItem>(
    {
      id: ClinicStatus.Active,
      name: i18next.t('clinicStatuses.active'),
      color: '#2F8521',
    },
    {
      id: ClinicStatus.Inactive,
      name: i18next.t('clinicStatuses.inactive'),
      color: '#BD363B',
    },
    {
      id: ClinicStatus.Delinquent,
      name: i18next.t('clinicStatuses.delinquent'),
      color: '#667180',
    },
  );

  if (withAll) {
    dataSource.unshift({
      id: ClinicStatus.All,
      name: i18next.t('clinicStatuses.all'),
    });
  }

  return dataSource;
};
