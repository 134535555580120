import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import useApi from '../use-api';

import type { IPatientEmr } from 'infrastructure/interfaces';

const patientPatientEmrApi = (patientGuid: string) =>
  `admins/admin-panel/patients/${patientGuid}/patient-emrs`;

interface IUsePatientEMR {
  loading: boolean;
  loadPatientEmr: () => Promise<IPatientEmr | void>;
  updatePatientEmr: (body: IPatientEmr) => Promise<void>;
}

interface IUsePatientEMRProps {
  patientGuid: string;
}

export const usePatientEMR = (props: IUsePatientEMRProps): IUsePatientEMR => {
  const { patientGuid } = props;

  const { loading, loadData, updateData } = useApi({ showError: false });

  const queryClient = useQueryClient();
  const queryKey = ['patientEMR', patientGuid];

  const loadPatientEmr = async () => {
    const data = await queryClient.fetchQuery(
      queryKey,
      () => loadData<IPatientEmr>(patientPatientEmrApi(patientGuid)),
      { staleTime: STALE_TIME },
    );
    if (data) return data;
  };

  const updatePatientEmr = async (body: IPatientEmr) => {
    const data = await updateData<IPatientEmr>(
      patientPatientEmrApi(patientGuid),
      body,
    );
    queryClient.setQueryData(queryKey, data);
  };

  return {
    loading,
    loadPatientEmr,
    updatePatientEmr,
  };
};
