import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Col, Row } from 'reactstrap';
import AutoCompleteClinicsMulti from 'components/molecules/autocompleteFilter/clinicMulti';
import AutoCompleteCareAgent from 'components/atoms/autocomplete';
import forms from 'constants/forms';
import common from 'constants/common';
import { isPractice } from 'utils/userTypeHelper';
import useUser from 'utils/useUser';
import { API_ADMIN_PANEL_AGENCY_DOCTORS } from 'services/dataService/resources';
import BaseSelect from 'components/atoms/baseSelect';
import { useTranslation } from 'react-i18next';
import { CCMRoles } from 'infrastructure/enums';

const DEFAULT_CCM_INTERACTION_STATUS = forms.ccmInteractionStatuses[0];
const DEFAULT_PATIENT_FILTER = forms.patientFilterOptions[0];
const DEFAULT_CCM_HOLDING_STATUS = forms.ccmHoldingStatuses[0];
const DEFAULT_CHILD_CLINIC = forms.childClinicSelect[0];

const filteredCcmStatuses = forms.ccmStatusOptions.filter(
  (ccmStatus) => ccmStatus.id !== 'not_enrolled',
);

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '52px',
  }),
};

const selectTheme = (theme) => ({
  ...theme,
  borderRadius: 12,
  colors: {
    ...theme.colors,
    ...common.primaryColors,
  },
});

const ccmInteractionStatusValue = (ccmInteractionStatus) => {
  const value = forms.ccmInteractionStatuses.find(
    (element) => element.value === ccmInteractionStatus,
  );
  return value;
};

const ccmStatusValue = (values) => {
  const ccmStatuses = (values ? values.split(',') : []).map((status) => status);
  const value = forms.ccmStatusOptions.filter((element) =>
    ccmStatuses.includes(element.value),
  );
  return value;
};

const Filters = ({ setFilters, filters }) => {
  const { userType } = useUser();
  const [isShowSwitcher, setIsShowSwitcher] = useState(false);

  const ccmInteractionStatusChanged = async ({ value }) =>
    setFilters({ ...filters, ccmInteractionStatus: value });

  const pcmStatusChanged = async ({ value }) =>
    setFilters({ ...filters, pcmStatus: value });

  const ccmStatusChanged = async (values) => {
    const ccmStatuses = values.map((value) => value.id).join(',');
    setFilters({ ...filters, ccmStatuses });
  };

  const ccmHoldingStatusChanged = async ({ value }) =>
    setFilters({ ...filters, ccmHoldingStatus: value });

  const clinicChanged = async (value, records) => {
    const isAnyParentClinic = records.some((record) => record.parentClinic);
    setIsShowSwitcher(isAnyParentClinic);

    setFilters({ ...filters, clinicGuids: value, childClinic: true });
  };

  const childClinicChanged = async ({ value }) =>
    setFilters({ ...filters, childClinic: value });

  const { t } = useTranslation();

  const ccmRoleNames = CCMRoles.map(
    (role) => `roleName=${encodeURIComponent(role)}`,
  ).join('&');

  return (
    <Row>
      {!isPractice(userType) && (
        <>
          <Col
            xl="4"
            lg="4"
            md="6"
            sm="6"
            xs="12"
            className="report__filter ccm"
          >
            <p
              className="mb-1"
              data-cy="ccm-status-report__admission-agent-filter-filter-label"
            >
              {t('labels.ccmOnboardingAgent')}
            </p>
            <AutoCompleteCareAgent
              filter={DEFAULT_PATIENT_FILTER}
              handleClick={({ value }) => {
                setFilters({ ...filters, ccmOnboardingAgentGuid: value });
              }}
              getApi={(queryString) =>
                `${API_ADMIN_PANEL_AGENCY_DOCTORS}?fullName=${queryString}&roleName=patientAcceptance&${ccmRoleNames}`
              }
              dataCy="ccm-status-report-header__admission-agent-filter"
            />
          </Col>
          <Col
            xl="4"
            lg="4"
            md="6"
            sm="6"
            xs="12"
            className="report__filter ccm"
          >
            <p
              className="mb-1"
              data-cy="ccm-status-report__case-manager-filter-label"
            >
              {t('labels.ccmCaseManager')}
            </p>
            <AutoCompleteCareAgent
              filter={DEFAULT_PATIENT_FILTER}
              handleClick={({ value }) => {
                setFilters({ ...filters, ccmCaseManagerGuid: value });
              }}
              getApi={(queryString) =>
                `${API_ADMIN_PANEL_AGENCY_DOCTORS}?fullName=${queryString}&${ccmRoleNames}`
              }
              dataCy="ccm-status-report-header__case-manager-filter"
            />
          </Col>
          <Col
            xl="4"
            lg="4"
            md="6"
            sm="6"
            xs="12"
            className="report__filter ccm"
          >
            <p
              className="mb-1"
              data-cy="ccm-status-report__pat-manager-filter-label"
            >
              {t('labels.ccmPatAgent')}
            </p>
            <AutoCompleteCareAgent
              filter={DEFAULT_PATIENT_FILTER}
              handleClick={({ value }) => {
                setFilters({ ...filters, ccmPatManagerGuid: value });
              }}
              getApi={(queryString) =>
                `${API_ADMIN_PANEL_AGENCY_DOCTORS}?fullName=${queryString}`
              }
              dataCy="ccm-status-report-header__pat-manager-filter"
            />
          </Col>
        </>
      )}
      <Col xl="4" lg="4" md="6" sm="6" xs="12" className="report__filter ccm">
        <p className="mb-1" data-cy="ccm-status-report__ccmStatus-filter-label">
          {t('labels.ccmStatus')}
        </p>
        <BaseSelect
          isMulti
          options={filteredCcmStatuses}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          styles={selectStyles}
          value={ccmStatusValue(filters.ccmStatuses)}
          onChange={ccmStatusChanged}
        />
      </Col>
      <Col xl="4" lg="4" md="6" sm="6" xs="12" className="report__filter ccm">
        <p className="mb-1" data-cy="ccm-status-report__clinic-filter-label">
          {t('labels.clinic')}
        </p>
        <AutoCompleteClinicsMulti setClinicFilters={clinicChanged} />
      </Col>
      <Col xl="4" lg="4" md="6" sm="6" xs="12" className="report__filter ccm">
        <p
          className="mb-1"
          data-cy="ccm-status-report__ccmInteractionStatus-filter-label"
        >
          {t('labels.ccmInteractionStatus')}
        </p>
        <BaseSelect
          options={forms.ccmInteractionStatuses}
          defaultValue={DEFAULT_CCM_INTERACTION_STATUS}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          styles={selectStyles}
          value={ccmInteractionStatusValue(filters.ccmInteractionStatus)}
          onChange={ccmInteractionStatusChanged}
        />
      </Col>
      <Col xl="4" lg="4" md="6" sm="6" xs="12" className="report__filter ccm">
        <p className="mb-1" data-cy="ccm-status-report__pcmStatus-filter-label">
          {t('labels.pcmStatus')}
        </p>
        <BaseSelect
          options={forms.ccmInteractionStatuses}
          defaultValue={DEFAULT_CCM_INTERACTION_STATUS}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          styles={selectStyles}
          value={ccmInteractionStatusValue(filters.pcmStatus)}
          onChange={pcmStatusChanged}
        />
      </Col>
      <Col xl="4" lg="4" md="6" sm="6" xs="12" className="report__filter ccm">
        <p
          className="mb-1"
          data-cy="ccm-status-report__ccmHoldingStatus-filter-label"
        >
          {t('labels.ccmTemporaryOnHoldStatus')}
        </p>
        <BaseSelect
          options={forms.ccmHoldingStatuses}
          defaultValue={DEFAULT_CCM_HOLDING_STATUS}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          styles={selectStyles}
          onChange={ccmHoldingStatusChanged}
        />
      </Col>
      {isShowSwitcher && (
        <Col xl="4" lg="4" md="6" sm="6" xs="12" className="report__filter ccm">
          <p
            className="mb-1"
            data-cy="ccm-status-report__childClinic-filter-label"
          >
            {t('labels.childClinics')}
          </p>
          <BaseSelect
            options={forms.childClinicSelect}
            defaultValue={DEFAULT_CHILD_CLINIC}
            theme={(theme) => selectTheme(theme)}
            classNamePrefix="select-type"
            onChange={childClinicChanged}
          />
        </Col>
      )}
    </Row>
  );
};

Filters.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

export default Filters;
