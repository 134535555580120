export enum AppointmentMethod {
  PhoneOrVideoConference = 'phone_or_video_conference',
  SMS = 'sms',
  Email = 'email',
}

export enum AppointmentCompletionMethod {
  PhoneOrVideoConference = 'phone_or_video_conference',
  SMSOrEmail = 'sms_or_email',
}
