export enum ServiceDeliveryThreshold {
  'All' = 'all',
  '15M' = 15,
  '2H' = 120,
  '4H' = 240,
  '8H' = 480,
}

export type ServiceDeliveryThresholdWithoutAll = Exclude<
  ServiceDeliveryThreshold,
  ServiceDeliveryThreshold.All
>;
