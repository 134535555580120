import BaseButton from 'components/atoms/baseButton';
import QuestionnaireIcon from 'components/atoms/icons/questionnaireIcon';
import classNames from 'classnames';
import { type FC } from 'react';
import BaseForm from 'components/atoms/base-form';
import ChevronRightIcon from 'components/atoms/icons/chevronRight';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';
import AnswersRow from './components/answer-row';
import AnswersColumn from './components/answer-column';
import { useQuestionnaire } from './hooks/use-questionnaire';

interface Props {
  patientGuid: string;
}

const PatientHealthQuestionnaire: FC<Props> = ({ patientGuid }) => {
  const {
    isLoading,
    isSmokingQuestion,
    disabledIncrease,
    sendLoading,
    formik,
    groups,
    currentStep,
    currentQuestionGroup,
    patientInfoLoading,
    disableButton,
    isFallRiskQuestion,
    setCurrentStep,
    decreaseStep,
    increaseStep,
  } = useQuestionnaire({ patientGuid });
  const { t } = useTranslation();

  return (
    <BaseSpinWrapper spinning={isLoading || patientInfoLoading}>
      <div className={s.wrapper} id="questionnaire">
        <div className={s.header}>
          <div className={s['title-wrapper']}>
            <QuestionnaireIcon />
            <h3 className={s.title}>
              {t('labels.patientHealthQuestionnaire')}
            </h3>
          </div>
          <BaseButton
            dataCy="saveQuuestionare-button"
            label={t('controls.save')}
            onClick={() => formik.submitForm()}
            className={s['submit-btn']}
            disabled={disableButton || !formik.dirty}
            loading={sendLoading}
          />
        </div>
        <div className={s.body}>
          <div className={s.left}>
            <ul className={s['left-list']}>
              {groups?.map((group, idx) => (
                <li
                  key={idx}
                  data-cy="questionareList"
                  className={classNames(s['left-list-item'], {
                    [s['active-item']]: idx === currentStep,
                  })}
                  onClick={() => setCurrentStep(idx)}
                >
                  {group.name}
                </li>
              ))}
            </ul>
          </div>
          <div className={s.right}>
            <div className={s['right-header']}>
              <div className={s['right-title-wrapper']}>
                <p
                  className={s['right-title']}
                  data-cy={`questionareTitle-${currentQuestionGroup?.name}`}
                >
                  {currentQuestionGroup?.name}
                </p>
                <p className={s['right-desc']}>
                  {currentQuestionGroup?.description}
                </p>
              </div>
              <div className={s['right-header-btns']}>
                <BaseButton
                  dataCy="previousQuestionareSection"
                  icon={<ChevronRightIcon color="#000" className={s.rotate} />}
                  disabled={currentStep === 0}
                  onClick={decreaseStep}
                  type="secondary"
                />
                <BaseButton
                  dataCy="nextQuestionareSection"
                  icon={<ChevronRightIcon color="#000" />}
                  disabled={disabledIncrease}
                  onClick={increaseStep}
                  type="secondary"
                />
              </div>
            </div>
            <BaseForm formik={formik}>
              <AnswersRow
                formik={formik}
                questions={currentQuestionGroup?.questions ?? []}
                disabled={disableButton || sendLoading}
                isSmokingQuestion={isSmokingQuestion}
                isFallRiskQuestion={isFallRiskQuestion}
              />
              <AnswersColumn
                formik={formik}
                questions={currentQuestionGroup?.questions ?? []}
                disabled={disableButton || sendLoading}
              />
            </BaseForm>
          </div>
        </div>
      </div>
    </BaseSpinWrapper>
  );
};
export default PatientHealthQuestionnaire;
