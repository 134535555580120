import { useEffect, useRef, useState } from 'react';

import type { QueryKey } from '@tanstack/react-query';

export const useCellHeights = (getActivityQueryKey: QueryKey) => {
  const [rowHeights, setRowHeights] = useState<Map<number, number>>(new Map());
  const rowRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());

  useEffect(() => {
    const newRowHeights = new Map<number, number>();
    rowRefs.current.forEach((ref, idx) => {
      if (ref) {
        const { height } = ref.getBoundingClientRect();
        newRowHeights.set(idx, height);
      }
    });
    setRowHeights(newRowHeights);
  }, [getActivityQueryKey]);

  return { rowHeights, rowRefs };
};
