import { getUserRoutes } from './helpers';

const hasAccess = (path, user) => {
  if (getUserRoutes(user).includes(path)) return true;
  return false;
};

export const userHasAccess = (user, path) => hasAccess(path, user);

export const filterRoutes = (user, routes) =>
  routes.filter(({ link }) => {
    return getUserRoutes(user).includes(link);
  });
