import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { clinicReferralTypesDataSource } from 'infrastructure/data-sources/clinic-referral-types';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IClinicReferralTypeItem } from 'infrastructure/data-sources/clinic-referral-types';

type Options = IBaseSelectBoxOption<
  IClinicReferralTypeItem['id'],
  IClinicReferralTypeItem
>[];

interface IClinicReferralTypeSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {}

const ClinicReferralTypeSelectBox: React.FC<
  IClinicReferralTypeSelectBoxProps
> = (props) => {
  const dataSource = useMemo(() => clinicReferralTypesDataSource(), []);

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...props} />;
};

export default ClinicReferralTypeSelectBox;
