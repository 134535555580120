import InteractiveTimeDashboardFilters from 'components/templates/rpm/interactiveTimeDashboard/filters';
import InteractiveTimeDashboardHeader from 'components/templates/rpm/interactiveTimeDashboard/header';
import InteractiveTimeDashboardTable from 'components/templates/rpm/interactiveTimeDashboard/table';
import { useInteractiveTimeDashboardCount } from 'infrastructure/hooks/interactiveTimeDashboard/useCount';
import { useInteractiveTimeDashboardData } from 'infrastructure/hooks/interactiveTimeDashboard/useData';
import { useInteractiveTimeDashboardFilters } from 'infrastructure/hooks/interactiveTimeDashboard/useFilters';

import type { FC } from 'react';

const InteractiveTimeDashboardPage: FC = () => {
  const { resetFilters, appendFilters, changePage, filters, filtersCount } =
    useInteractiveTimeDashboardFilters({
      type: 'dashboard',
    });
  const { data, isLoading } = useInteractiveTimeDashboardData(filters);
  const { data: count } = useInteractiveTimeDashboardCount(filters);

  return (
    <>
      <InteractiveTimeDashboardHeader count={count} />
      <InteractiveTimeDashboardFilters
        appendFilters={appendFilters}
        filtersCount={filtersCount}
        resetFilters={resetFilters}
        filters={filters}
      />
      <InteractiveTimeDashboardTable
        interactiveTime={data}
        filters={filters}
        isLoading={isLoading}
        changePage={changePage}
      />
    </>
  );
};

export default InteractiveTimeDashboardPage;
