import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const fullName = ({ title, firstName, middleName, lastName }) =>
  `${title || ''} ${firstName || ''} ${middleName || ''} ${lastName || ''}`;
const PersonalInfo = ({ data }) => (
  <Table borderless border="0" cellSpacing="0" cellPadding="0">
    <thead>
      <tr>
        <th colSpan="2">
          <div className="pagination-container">
            <h5>Personal Info</h5>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td />
        <td>
          <span className="subheader">Name: </span>
          <span data-cy="name">{fullName(data)}</span>
          {data.mobileNumber && (
            <>
              <span className="subheader">User Mobile: </span>
              <span data-cy="user-mobile">{data.mobileNumber}</span>
            </>
          )}
          {data.workNumber && (
            <>
              <span className="subheader">User Work Number: </span>
              <span data-cy="user-work-number">{data.workNumber}</span>
            </>
          )}
          {data.email && (
            <>
              <span className="subheader">User Email: </span>
              <span className="text-transform-none" data-cy="user-email">
                {data.email}
              </span>
            </>
          )}
          {data.primaryLanguage && (
            <>
              <span className="subheader">Primary Language: </span>
              <span data-cy="primary-language">{data.primaryLanguage}</span>
            </>
          )}
          {data.secondaryLanguage && (
            <>
              <span className="subheader">Secondary Language: </span>
              <span data-cy="secondary-language">{data.secondaryLanguage}</span>
            </>
          )}
          {data.birthDate && (
            <>
              <span className="subheader">DOB: </span>
              <span data-cy="dob">{data.birthDate}</span>
            </>
          )}
          {data.timezone && (
            <>
              <span className="subheader">Timezone: </span>
              <span data-cy="timezone">{data.timezone}</span>
            </>
          )}
        </td>
      </tr>
    </tbody>
  </Table>
);

PersonalInfo.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default PersonalInfo;
