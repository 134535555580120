import type { TurkUserSchema } from './validationSchema';

const turkUserRequestGenerator = (
  {
    guid,
    agencies,
    email,
    firstName,
    lastName,
    middleName,
    primaryLanguage,
    professionType,
    ratePerReading,
    secondaryLanguage,
    title,
    timezone,
    status,
  }: TurkUserSchema,
  { roleGuid }: { roleGuid: string },
) => {
  const body = {
    guid,
    agencyGuids: agencies?.map(({ value }) => value),
    title,
    firstName,
    lastName,
    middleName,
    ratePerReading: ratePerReading || null,
    email,
    primaryLanguage,
    secondaryLanguage,
    professionType,
    roles: [roleGuid],
    timezone,
    status,
  };
  return body;
};
export default turkUserRequestGenerator;
