export const parseSearchStringValue = <T = string>(
  value: string | (string | null)[] | null,
): T | undefined => {
  if (typeof value === 'string') return value as T;
};

export const parseSearchArrayValue = (
  value: string | (string | null)[] | null,
) => {
  if (typeof value === 'string') return [value];
  if (Array.isArray(value) && value.every((i) => typeof i === 'string'))
    return value;
};

export const parseSearchBooleanValue = (
  value: string | (string | null)[] | null,
) => {
  if (typeof value === 'boolean') return value;
  if (typeof value === 'string') return value === 'true';
};

export const parseSearchNumberValue = <T extends number>(
  value: string | (string | null)[] | null,
): T | undefined => {
  if (typeof value === 'string') {
    const parsed = Number(value);
    return Number.isNaN(parsed) ? undefined : (parsed as T);
  }
  return undefined;
};
