const CsvIcon = (props) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.875 14.25V7.5C19.875 7.29291 19.7071 7.125 19.5 7.125H5.25C5.04291 7.125 4.875 7.29291 4.875 7.5V14.25C4.875 14.4571 5.04291 14.625 5.25 14.625H19.5C19.7071 14.625 19.875 14.4571 19.875 14.25ZM6.75 12.75H8.25C8.45709 12.75 8.625 12.5821 8.625 12.375C8.625 12.1679 8.79291 12 9 12C9.20709 12 9.375 12.1679 9.375 12.375C9.375 12.9963 8.87128 13.5 8.25 13.5H6.75C6.12872 13.5 5.625 12.9963 5.625 12.375V9.375C5.625 8.75372 6.12872 8.25 6.75 8.25H8.25C8.87128 8.25 9.375 8.75372 9.375 9.375C9.375 9.58209 9.20709 9.75 9 9.75C8.79291 9.75 8.625 9.58209 8.625 9.375C8.625 9.16791 8.45709 9 8.25 9H6.75C6.54291 9 6.375 9.16791 6.375 9.375V12.375C6.375 12.5821 6.54291 12.75 6.75 12.75ZM13.875 12.375C13.875 12.9963 13.3713 13.5 12.75 13.5H11.25C10.6353 13.4978 10.1353 13.0043 10.125 12.3897C10.1223 12.1822 10.2867 12.011 10.494 12.0053H10.5C10.7034 12.0028 10.871 12.1648 10.875 12.3682C10.8734 12.5772 11.0411 12.748 11.25 12.75H12.75C12.9571 12.75 13.125 12.5821 13.125 12.375V11.625C13.125 11.4179 12.9571 11.25 12.75 11.25H11.25C10.6287 11.25 10.125 10.7463 10.125 10.125V9.375C10.125 8.75372 10.6287 8.25 11.25 8.25H12.769C13.067 8.25018 13.3522 8.3703 13.5604 8.58344C13.7686 8.79657 13.882 9.0846 13.875 9.38251C13.873 9.5896 13.7034 9.75586 13.4963 9.75366C13.2891 9.75165 13.123 9.58209 13.125 9.375C13.1296 9.27558 13.093 9.17871 13.0237 9.1073C12.9569 9.03845 12.865 8.99982 12.769 9H11.25C11.0429 9 10.875 9.16791 10.875 9.375V10.125C10.875 10.3321 11.0429 10.5 11.25 10.5H12.75C13.3713 10.5 13.875 11.0037 13.875 11.625V12.375ZM19.0961 8.76929L17.2211 13.2693C17.1628 13.409 17.0264 13.5 16.875 13.5C16.7236 13.5 16.5872 13.409 16.5289 13.2693L14.6539 8.76929C14.5741 8.57813 14.6645 8.35858 14.8557 8.27893C15.0469 8.1991 15.2664 8.28955 15.3461 8.48071L16.875 12.15L18.4039 8.48071C18.4836 8.28955 18.7031 8.1991 18.8943 8.27893C19.0854 8.35858 19.1759 8.57813 19.0961 8.76929Z"
      fill="#FB9318"
    />
    <path
      d="M5.25067 3.37585V0.482422L1.70996 3.75085H4.87567C5.08276 3.75085 5.25067 3.58295 5.25067 3.37585Z"
      fill="#FB9318"
    />
    <path
      d="M1.125 20.625C1.125 20.8321 1.29291 21 1.5 21H17.25C17.4571 21 17.625 20.8321 17.625 20.625V15.375H5.25C4.62872 15.375 4.125 14.8713 4.125 14.25V7.5C4.125 6.87872 4.62872 6.375 5.25 6.375H17.625V0.375C17.625 0.167908 17.4571 0 17.25 0H6V3.375C6 3.99628 5.49628 4.5 4.875 4.5H1.125V20.625Z"
      fill="#FB9318"
    />
  </svg>
);

export default CsvIcon;
