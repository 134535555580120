import classNames from 'classnames';

import type { FunctionComponent, ReactElement } from 'react';

type Props = {
  icon: ReactElement;
  text: string;
  title?: string;
  color: 'red' | 'gold' | 'red' | 'gray' | 'green' | 'orange';
  small?: boolean;
  dataCy?: string;
};

const BaseTag: FunctionComponent<Props> = ({
  icon: Icon,
  text,
  title,
  color: type,
  small,
  dataCy,
}) => {
  return (
    <div
      className={classNames('tag-wrapper', `tag-wrapper--${type}`, {
        'tag-wrapper--small': small,
      })}
      data-cy={dataCy}
      title={title}
    >
      <div className="tag-icon-wrapper">{Icon}</div>
      <div className="tag-text-wrapper">{text}</div>
    </div>
  );
};

export default BaseTag;
