import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';

import type { IKeyNoteData } from 'infrastructure/interfaces';

interface DataProps {
  url: string;
}

export const getKeyNotes = async ({ url }: DataProps) => {
  const { error, data } = await dataService.getList<IKeyNoteData>(url);

  if (error) {
    showResult(error);
  }

  return data;
};
