import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from 'reactstrap';
import Pagination from 'components/molecules/paginationTransition';
import withLoader from 'components/organisms/withLoader';
import common from 'constants/common';
import ArrowUpIcon from 'components/atoms/icons/arrowUpIcon';
import ArrowDownIcon from 'components/atoms/icons/arrowDownIcon';
import { showResult } from 'infrastructure/hooks/utils';
import { isTablet } from 'utils/deviceHelper';
import useUser from 'utils/useUser';
import { isCriticalOrAtRiskAlertingRole } from 'utils/accessManager/helpers';
import { addQueryParams } from 'utils/queryHelpers';
import useMonthlyInteractionConfirm from 'utils/useMonthlyInteractionConfirm';
import { useCommentGenerationLimit } from 'utils/hooks/dashboard/useCommentGenerationLimit';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { isPractice, isPatientAcceptance } from 'utils/userTypeHelper';
import { useTranslation } from 'react-i18next';

import ActivityRow from './activityRow';
import Sorting from './sorting';
import RpmCommentModal from '../../patients/patient/activity/modal';

const Activity = ({ onCallPatients, device, toggleAction }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userType, roleNames } = useUser();
  const activity = useSelector((state) => state.doctor);
  const [isTemplateModalOpen, setTemplateModalOpen] = useState(false);
  const [modalActivity, setModalActivity] = useState(null);
  const [toggleAssignLoadingByPatient, setToggleAssignLoadingByPatient] =
    useState([]);
  const { reviewed, sort, filters } = activity;
  const { readingType } = filters;
  const { getActivities: activitiesLoading } = useSelector(
    (state) => state.loading.effects.doctor,
  );
  const { t } = useTranslation();

  const {
    doctor: {
      setActivitiesPage,
      toggleActivitiesTimeSort,
      toggleActivitiesNameSort,
      toggleActivitiesReadingTypeSort,
      togglePatientInteractionStatus,
      submitComment,
      actionReview,
      togglePatientReserved,
    },
  } = useDispatch();

  const setPage = (pageOffset, goToPage) => {
    addQueryParams({ navigate, location }, { page: goToPage });
    setActivitiesPage({ pageOffset, goToPage });
  };

  const handleCommentSubmit = async (id, deviceId, values) => {
    const postData = {
      readingId: id,
      deviceId,
      message: values.comment,
      generatedByModel: values.generatedByModel,
      originalGeneratedText: values.originalGeneratedText,
    };

    let interactionConfirm = false;
    const items = activity.data.find(
      (item) => item.id === id && item.deviceId === deviceId,
    );

    if (!isPractice(userType) && !isPatientAcceptance(roleNames)) {
      if (!items?.patient?.interactionStatus) {
        const monthlyInteraction = useMonthlyInteractionConfirm();
        interactionConfirm = await monthlyInteraction();
      }
    }
    const response = await submitComment(postData);

    if (response.data && interactionConfirm) {
      const res = await togglePatientInteractionStatus({
        patientGuid: items?.patient?.guid,
        interactionStatus: true,
      });

      if (res.error) showResult(res.error);
      else
        notificationController.success({
          message: t('responseMessages.interactionStatusUpdate'),
        });
    }
    if (response.error) {
      showResult(response.error);
    }
  };

  const handleReviewedCheck = async (id, deviceId) => {
    const postData = {
      readingId: id,
      deviceId,
      reviewed: true,
    };
    const { error } = await actionReview(postData);
    showResult(error, common.submitSuccessTypes.reviewed);
  };

  const openTemplateModal = (data) => {
    if (!isTemplateModalOpen) {
      setModalActivity(data);
      setTemplateModalOpen(true);
    }
  };

  const columnNumber = (index) =>
    activity.page * activity.itemsPerPage + index + 1 - activity.itemsPerPage;

  const { isCommentGenerationLimitReached, onCommentGenerationStatusChange } =
    useCommentGenerationLimit();

  const slaFilterActive = filters.slaReadings === 'true';
  return (
    <div className="activity">
      <div className="activity-header">
        <Pagination
          title=""
          page={activity.page}
          pageTotal={activity.data.length}
          itemsPerPage={activity.itemsPerPage}
          total={activity.totalCount}
          handleFirstClick={() => setPage(-(activity.page - 1))}
          handleLastClick={() =>
            setPage(0, Math.ceil(activity.totalCount / activity.itemsPerPage))
          }
          handlePrevClick={() => setPage(-1)}
          handleNextClick={() => setPage(+1)}
          loading={activitiesLoading}
          handleGoToPage={(num) => setPage(null, num)}
          isRpmPanel
        />
      </div>
      <div className="activity-body">
        {!isTablet(device) && (
          <Sorting
            action={reviewed}
            toggleAction={toggleAction}
            loading={activitiesLoading}
          />
        )}
        {withLoader(
          activitiesLoading,
          <Table
            borderless
            cellSpacing="0"
            cellPadding="0"
            className="rpm-table"
          >
            <thead>
              <tr>
                <th className="table-status-column">№</th>
                {slaFilterActive && (
                  <th className="table-status-column">Time Remaining in SLA</th>
                )}
                <th className="table-status-column">Status</th>
                <th className="table-time-column">
                  Time
                  {!slaFilterActive && (
                    <button
                      className="button-sort-arrow"
                      onClick={() => toggleActivitiesTimeSort()}
                    >
                      {sort.time ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </button>
                  )}
                </th>
                <th className="table-patient-column">
                  Patient
                  {!slaFilterActive && (
                    <button
                      className="button-sort-arrow"
                      onClick={() => toggleActivitiesNameSort()}
                    >
                      {sort.name === 'ASC' ? (
                        <ArrowUpIcon />
                      ) : (
                        <ArrowDownIcon />
                      )}
                    </button>
                  )}
                </th>
                <th className="table-reading-column">
                  Reading
                  {readingType && (
                    <button
                      className="button-sort-arrow"
                      onClick={() => toggleActivitiesReadingTypeSort()}
                    >
                      {sort.readingType === 'ASC' ? (
                        <ArrowUpIcon />
                      ) : (
                        <ArrowDownIcon />
                      )}
                    </button>
                  )}
                </th>
                {!isCriticalOrAtRiskAlertingRole(roleNames) && (
                  <th className="table-comment-column">Comment</th>
                )}
                {!isCriticalOrAtRiskAlertingRole(roleNames) && !reviewed && (
                  <th className="table-reviewed-column">Reviewed</th>
                )}
              </tr>
            </thead>
            <tbody data-cy="readings-table">
              {activity.data.map((activityRow, index) => (
                <ActivityRow
                  isCommentGenerationLimitReached={
                    isCommentGenerationLimitReached
                  }
                  onCommentGenerationStatusChange={
                    onCommentGenerationStatusChange
                  }
                  key={`${activityRow.id}-${index}`}
                  columnNumber={columnNumber(index)}
                  activity={activityRow}
                  reviewed={reviewed}
                  handleCommentSubmit={handleCommentSubmit}
                  handleReviewedCheck={handleReviewedCheck}
                  consumablesArrivingDate={
                    activityRow.patient.consumablesArrivingDate
                  }
                  togglePatientReserved={togglePatientReserved}
                  isOnCall={
                    onCallPatients.includes(activityRow.patient.guid) ||
                    activityRow.patient.isOnCall
                  }
                  openTemplateModal={openTemplateModal}
                  disabledTemplateModalButton={isTemplateModalOpen}
                  toggleAssignLoading={toggleAssignLoadingByPatient.some(
                    (item) => item === activityRow.patient.guid,
                  )}
                  setToggleAssignLoading={({ patientGuid, state }) => {
                    if (state === true) {
                      setToggleAssignLoadingByPatient((oldValue) => {
                        return [...oldValue, patientGuid];
                      });
                    } else {
                      setToggleAssignLoadingByPatient((oldValue) => {
                        return oldValue.filter((item) => item !== patientGuid);
                      });
                    }
                  }}
                />
              ))}
            </tbody>
          </Table>,
        )}
      </div>
      {isTemplateModalOpen && (
        <RpmCommentModal
          isOpen={isTemplateModalOpen}
          closeModal={() => setTemplateModalOpen(false)}
          activityProps={modalActivity}
        />
      )}
    </div>
  );
};

Activity.propTypes = {
  onCallPatients: PropTypes.array,
  device: PropTypes.string,
  toggleAction: PropTypes.func.isRequired,
};

export default Activity;
