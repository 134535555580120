import BaseTableNew from 'components/atoms/base-table/new';
import {
  ACTIVITY_PER_PAGE,
  useAddActivityComment,
} from 'infrastructure/hooks/rpm';
import { scrollToTopAsync } from 'infrastructure/functions';
import { type FC, type Dispatch, type SetStateAction } from 'react';
import { useAppDispatch } from 'store';
import EmptyTablePlaceholder from 'components/atoms/base-empty-table-placeholder';
import { useTranslation } from 'react-i18next';
import SearchIcon from 'components/atoms/icons/search.svg?react';
import useUser from 'utils/useUser';
import classNames from 'classnames';

import s from './styles.module.scss';
import { useActivityColumns } from './columns';
import { usePatientActivityColumns } from './patient-columns';

import type {
  TRpmDashboardActivityQueryKey,
  TRpmPatientActivityQueryKey,
} from 'infrastructure/hooks/rpm';
import type { IRpmPatientActivity } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface ActivityTableProps {
  loading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setSortByDate: Dispatch<SetStateAction<boolean>>;
  itemsQueryKey: TRpmDashboardActivityQueryKey | TRpmPatientActivityQueryKey;
  showReviewCol: boolean;
  activity?: IRpmPatientActivity;
  view: 'patient' | 'dashboard';
  pageSize?: number;
}

const ActivityTable: FC<ActivityTableProps> = ({
  activity,
  loading,
  page,
  setPage,
  setSortByDate,
  itemsQueryKey,
  showReviewCol,
  view,
  pageSize = ACTIVITY_PER_PAGE,
}) => {
  const { isPatient } = useUser();
  const { addActivityComment, addCommentLoading, InteractionStatusAlert } =
    useAddActivityComment({
      geActivityQueryKey: itemsQueryKey,
    });
  const activityColumns = useActivityColumns({
    addComment: addActivityComment,
    addCommentLoading,
    showReviewCol,
    view,
    getActivityQueryKey: itemsQueryKey,
  });
  const patientActivityColumns = usePatientActivityColumns({
    getActivityQueryKey: itemsQueryKey,
  });
  const {
    activityActions: { resetActions },
  } = useAppDispatch();
  const { t } = useTranslation();

  return (
    <>
      <BaseTableNew
        dataCy="activity-table"
        loading={loading}
        columns={isPatient ? patientActivityColumns : activityColumns}
        items={activity?.data ?? []}
        bordered
        pagination={{
          current: page,
          pageSize,
          total: activity?.count ?? 0,
          position: ['bottomCenter'],
        }}
        onChangePage={(newPage) => {
          resetActions();
          scrollToTopAsync('activity', 70).then(() => {
            setPage(newPage);
          });
        }}
        emptyText={
          <EmptyTablePlaceholder
            text={t('labels.noReadingsFound')}
            icon={<SearchIcon width={100} height={100} />}
            column
          />
        }
        onSort={() => {
          setSortByDate((prev) => !prev);
        }}
        rowClassName={classNames(s['table-row'], {
          [s['is-patient']]: isPatient,
        })}
        rowHoverable={false}
      />
      {InteractionStatusAlert}
    </>
  );
};
export default ActivityTable;
