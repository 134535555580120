/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import C from 'classnames';
import { Formik, Field, Form } from 'formik';
import PropTypes from 'prop-types';
import useUser from 'utils/useUser';
import { isAdmin, isPractice } from 'utils/userTypeHelper';
import {
  hasAdminPanelAccess,
  hasClinicalRole,
  hasPatientAcceptanceRole,
} from 'utils/accessManager/helpers';

const CommentForm = ({
  handleSubmit,
  valueChanged,
  disabled,
  isRpmCall,
  minified,
  comment,
  commentType,
  adminPage,
  handleCommentType,
  hideCount,
}) => {
  const { userType, roleNames } = useUser();
  const showSwitcher = useMemo(() => {
    return (
      hasPatientAcceptanceRole(roleNames) ||
      hasAdminPanelAccess(roleNames) ||
      hasClinicalRole(roleNames) ||
      isAdmin(userType)
    );
  }, [roleNames, userType]);
  return (
    <div className={C('inline-form', { mini: minified })}>
      <Formik
        initialValues={{
          comment: comment || '',
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div>
            <Form>
              <Field
                onChange={(e) => {
                  if (valueChanged) valueChanged(e.target.value, props);
                  props.handleChange(e);
                }}
                name="comment"
                type="comment"
                data-cy="general-comment-input"
                // https://accuhealth-force.monday.com/boards/1779118642/pulses/2635671117/posts/1529052302
                // input field have autocomplete, textarea does not
                // userType practice should have autocomplete
                // do not change this
                as={isPractice(userType) ? 'input' : 'textarea'}
                style={adminPage && { width: '86%', height: '62px' }}
                className={
                  adminPage ? 'inline-input admin-input' : 'inline-input'
                }
                placeholder="Type your comment..."
              />
              <div className="admin-buttons">
                {showSwitcher && adminPage && (
                  <div className="switch-box" onClick={handleCommentType}>
                    <p
                      data-cy="general-comment-rpm-switch"
                      className={C('switch', {
                        'switch-active': commentType === 'rpm',
                      })}
                    >
                      RPM
                    </p>
                    <p
                      data-cy="general-comment-ccm-switch"
                      className={C('switch', {
                        'switch-active': commentType === 'ccm',
                      })}
                    >
                      CCM
                    </p>
                  </div>
                )}
                <button
                  type="submit"
                  data-cy="send-general-comment-button"
                  className={
                    adminPage ? 'inline-button admin-button' : 'inline-button'
                  }
                  disabled={
                    disabled ||
                    props.values.comment === '' ||
                    (!hideCount &&
                      !isRpmCall &&
                      props.values.comment.trim().length < 50)
                  }
                >
                  Send
                </button>
              </div>
            </Form>
            {!isRpmCall && !hideCount && (
              <span
                className={C(
                  'call-comment',
                  {
                    'call-comment-rpm': isRpmCall,
                  },
                  {
                    'call-comment--red':
                      props.values.comment.trim().length < 50,
                  },
                  {
                    'call-comment--green':
                      props.values.comment.trim().length >= 50,
                  },
                )}
              >
                Character Count: {props.values.comment.trim().length}
              </span>
            )}
          </div>
        )}
      </Formik>
    </div>
  );
};

CommentForm.propTypes = {
  valueChanged: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  minified: PropTypes.bool,
  isRpmCall: PropTypes.bool,
  adminPage: PropTypes.bool,
  comment: PropTypes.string,
  commentType: PropTypes.string,
  handleCommentType: PropTypes.func,
  hideCount: PropTypes.bool,
};

CommentForm.defaultProps = {
  minified: true,
};

export default CommentForm;
