import React from 'react';
import { Row, Col } from 'reactstrap';
import forms from 'constants/forms';
import Input from 'components/atoms/input';

const Registration = () => {
  const reg = [...forms.adminTurkUser.userEdit.registration];
  return (
    <div className="admin-agent-form-block">
      <span>Registration</span>
      <Row>
        {reg.map((field) => (
          <Col lg="12" md="12" sm="12" xs="12" key={field.id}>
            <Input disabled {...field} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Registration;
