import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { batteriesConsumablesDataSource } from 'infrastructure/data-sources/batteries-consumables';

import type { ReadingType } from 'infrastructure/enums';
import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IBatteriesConsumablesItem } from 'infrastructure/data-sources/batteries-consumables';

type Options = IBaseSelectBoxOption<
  IBatteriesConsumablesItem['id'],
  IBatteriesConsumablesItem
>[];

interface IBatteriesConsumablesSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {
  readingType?: ReadingType;
}

const BatteriesConsumablesSelectBox: React.FC<
  IBatteriesConsumablesSelectBoxProps
> = (props) => {
  const { readingType, ...args } = props;
  const dataSource = useMemo(
    () => batteriesConsumablesDataSource({ readingType }),
    [],
  );

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...args} />;
};

export default BatteriesConsumablesSelectBox;
