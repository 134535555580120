import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardBody, Table } from 'reactstrap';
import { format, endOfMonth } from 'date-fns';
import common from 'constants/common';
import forms from 'constants/forms';
import withLoader from 'components/organisms/withLoader';
import Pagination from 'components/molecules/paginationTransition';
import { showResult } from 'infrastructure/hooks/utils';
import AutoCompleteClinics from 'components/molecules/autocompleteFilter/clinic';
import AsteriskIcon from 'components/atoms/icons/asteriskIcon';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import { isPractice } from 'utils/userTypeHelper';
import useUser from 'utils/useUser';
import AutoCompleteDoctorMultiSelect from 'components/molecules/autocompleteFilter/doctorMulti';
import BaseSelect from 'components/atoms/baseSelect';
import { useTranslation } from 'react-i18next';

import ActivityRow from './activityRow';

const BillingCount = () => {
  const DEFAULT_CLINIC_FILTER = forms.clinicFilterOptions[0];
  const DEFAULT_CHILD_CLINIC = forms.childClinicSelect[0];

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 12,
    colors: {
      ...theme.colors,
      ...common.primaryColors,
    },
  });
  const clinicsFilter = DEFAULT_CLINIC_FILTER;
  const reports = useSelector((state) => state.medicare.medicareReport);
  const allActiveMedicarePatientsCount = useSelector(
    (state) => state.medicare.activeMedicare,
  );

  const {
    itemsPerPage,
    page,
    dates,
    clinic: clinicGuid,
    totalCount: totalPageCount,
  } = useSelector((state) => state.medicare);

  const [isParentClinicSelected, setIsParentClinicSelected] = useState(false);

  const { userType } = useUser();

  const {
    medicare: {
      setPageMedicare,
      setDatesMedicare,
      setClinicMedicare,
      setChildClinicMedicare,
      setDoctorMedicare,
      getMedicareBillingsCsvReport,
    },
  } = useDispatch();

  const {
    getMedicareReport: reportLoading,
    getMedicareBillingsCsvReport: csvLoading,
  } = useSelector((state) => state.loading.effects.medicare);

  const [timeRanges, setTimeRanges] = useState({});
  const [dateValue, setDateValue] = useState(timeRanges[0]);
  const [dateLabel, setDateLabel] = useState(format(new Date(), 'MMM-yyyy'));

  const date = new Date();
  const generateTimeRanges = () => {
    const values = [];
    for (let i = 0; i < 12; i++) {
      const startTime = new Date(date.getFullYear(), date.getMonth() - i, 1);
      const endTime = endOfMonth(new Date(startTime));
      const label = format(new Date(startTime), 'MMM-yyyy');
      values.push({
        value: [
          format(new Date(startTime), 'yyyy-MM-dd'),
          format(new Date(endTime), 'yyyy-MM-dd'),
        ],
        label,
      });
    }
    setTimeRanges(values);
    setDateValue(values[0]);
    setDatesMedicare({ newDates: values[0].value });
  };

  useEffect(() => {
    generateTimeRanges();
  }, []);

  const setPage = (pageNumber) => {
    setPageMedicare({ pageNumber });
  };

  const onDateChange = (value) => {
    setDateValue(value);
    setDatesMedicare({ newDates: value.value });
    setDateLabel(value.label);
  };

  const onClinicChange = (value) => {
    setClinicMedicare({ clinic: value.value });
    if (value.parentClinic) {
      setChildClinicMedicare({ childClinic: true });
      setIsParentClinicSelected(true);
    } else {
      setChildClinicMedicare({ childClinic: null });
      setIsParentClinicSelected(false);
    }
    if (!value.value) {
      setIsParentClinicSelected(false);
      setChildClinicMedicare({ childClinic: null });
    }
  };

  const onChildClinicChange = ({ value }) => {
    setChildClinicMedicare({ childClinic: value });
  };

  const onDoctorChange = (value) => {
    setDoctorMedicare({ doctor: value });
  };

  const sendReport = async () => {
    const { error } = await getMedicareBillingsCsvReport();
    return showResult(error, common.submitSuccessTypes.sendReport);
  };

  const { t } = useTranslation();

  return (
    <div className="billing-table">
      <Card className="card-border">
        <CardBody>
          <div className="performance-review__header">
            <div className="billing-table-header">
              <div>Report: Practice Medicare Billing</div>
            </div>
            <div className="performance-review__header-icon">
              {withLoader(
                csvLoading || dates.length < 1,
                <button onClick={sendReport}>
                  <DownloadRoundedIcon className="performance-review__header-icon-svg" />
                  <br />
                  <span>Download</span>
                </button>,
              )}
            </div>
          </div>
          <div className="billing-table-sorting">
            <div className="billing-table-sorting-select">
              <div className="billing-table-sorting-select-element">
                <p data-cy="clinic-filter-label">Clinic</p>
                <AutoCompleteClinics
                  filter={clinicsFilter}
                  handleClick={onClinicChange}
                />
              </div>
              <div className="billing-table-sorting-select-element">
                <p data-cy="provider-filter-label">Provider</p>
                <AutoCompleteDoctorMultiSelect
                  clinicGuid={clinicGuid}
                  onChange={onDoctorChange}
                />
              </div>
              <div className="billing-table-sorting-select-element">
                <p data-cy="time-range-filter-label">Time Range</p>
                <BaseSelect
                  options={timeRanges}
                  defaultValue={timeRanges[0]}
                  value={dateValue}
                  theme={(theme) => selectTheme(theme)}
                  classNamePrefix="select-type"
                  onChange={onDateChange}
                />
              </div>
              {isParentClinicSelected && (
                <div className="billing-table-sorting-select-element">
                  <p data-cy="clinic-filter-label">
                    {t('labels.childClinics')}
                  </p>
                  <BaseSelect
                    options={forms.childClinicSelect}
                    defaultValue={DEFAULT_CHILD_CLINIC}
                    theme={(theme) => selectTheme(theme)}
                    classNamePrefix="select-type"
                    onChange={onChildClinicChange}
                  />
                </div>
              )}
            </div>
            <div className="billing-table-active-medicare">
              <div
                className="billing-table-active-medicare-count"
                data-cy="active-medicare-counter"
              >
                {allActiveMedicarePatientsCount}
              </div>
              <div>Active Medicare</div>
            </div>
          </div>
          <div>
            <div className="table-sticky-header">
              {withLoader(
                reportLoading,
                <Table borderless cellSpacing="0" cellPadding="0">
                  <thead data-cy="medicare-table-header">
                    <tr>
                      <th>Insurance Number</th>
                      <th>DX Codes</th>
                      <th>Patient Name</th>
                      <th>MRN</th>
                      <th>99453</th>
                      <th>99454</th>
                      <th>(20 Min) 99457</th>
                      <th>(40 Min) 99458</th>
                      <th>(60 Min) 99458</th>
                      <th>MD Chart Review</th>
                      <th>Total Readings Days</th>
                      <th>Time Spent</th>
                    </tr>
                  </thead>
                  <tbody data-cy="medicare-table-body">
                    {reports.map((patient, id) => (
                      <ActivityRow
                        key={id}
                        patient={patient}
                        date={dateLabel}
                      />
                    ))}
                  </tbody>
                </Table>,
              )}
            </div>
            {isPractice(userType) && (
              <strong>
                <span className="billing-table-warning-icon">
                  <AsteriskIcon />
                </span>
                For referential purposes only, please access your Final Billing
                Report processed and uploaded to your Files.com
              </strong>
            )}
          </div>
          <div className="billing-table-button-pagination flex-row-reverse">
            <Pagination
              title=""
              page={page}
              pageTotal={reports.length}
              itemsPerPage={itemsPerPage}
              total={totalPageCount - 1}
              handleFirstClick={() => setPage(1)}
              handleLastClick={() =>
                setPage(Math.ceil(totalPageCount / itemsPerPage))
              }
              handlePrevClick={() => setPage(page - 1)}
              handleNextClick={() => setPage(page + 1)}
              handleGoToPage={(pageNumber) => setPage(pageNumber)}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default BillingCount;
