import classNames from 'classnames';

import s from './style.module.scss';

import type { FC, ReactNode } from 'react';

interface IProps {
  label: ReactNode;
  value: ReactNode;
  oneLine?: boolean;
}

export const ColumnFormEmptyItem: FC = () => {
  return <div className={s.empty} />;
};

export const ColumnFormItem: FC<IProps> = ({ label, value, oneLine }) => {
  return (
    <ItemWrapper oneLine={oneLine}>
      <span className={s['secondary-text']}>{label}:</span>
      <span className={s.text}>{value}</span>
    </ItemWrapper>
  );
};

export const ColumnFormBoldItem: FC<{ value: IProps['value'] }> = ({
  value,
}) => {
  return (
    <span className={s.text}>
      <b>{value}</b>
    </span>
  );
};

const ColumnForm: FC<{ short?: boolean }> = ({ children, short = false }) => {
  return (
    <div
      className={classNames(s['main-wrapper'], {
        [s.short]: short,
      })}
    >
      {children}
    </div>
  );
};

const ItemWrapper: FC<{
  oneLine?: boolean;
}> = ({ children, oneLine = false }) => (
  <div
    className={classNames(s['item-wrapper'], {
      [s['one-line']]: oneLine,
    })}
  >
    {children}
  </div>
);

export default ColumnForm;
