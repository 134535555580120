import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_ELIGIBILITY_PROCESS_DOWNLOAD_CSV } from 'services/dataService/resources';
import { downloadCSV } from 'utils';
import QueryParams from 'utils/queryParams';

export const useEligibilityDownload = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (processGuid: string) => downloadReport(processGuid),
  });
  return { download: mutate, isLoading };
};

const downloadReport = async (processGuid: string) => {
  const url = QueryParams.stringifyUrl(API_ELIGIBILITY_PROCESS_DOWNLOAD_CSV, {
    processGuid,
  });
  const { error, data } = await dataService.createOne(url);
  if (error) {
    throw new Error(error);
  }

  if (data) {
    const filename = `eligibility-${processGuid}.csv`;
    downloadCSV(data, filename);
  }
};
