import { makeid } from 'utils/helpers/makeid';

import type { ICareLocation } from 'infrastructure/interfaces';
import type { Nullable } from 'infrastructure/types';

export class CareLocation {
  key: string;
  guid: string;
  patientGuid: string;
  facilityName: string;
  facilityAddress: Nullable<string>;
  facilityNumber: Nullable<string>;
  city: Nullable<string>;
  state: Nullable<string>;
  zipCode: Nullable<string>;

  constructor(data: ICareLocation) {
    this.key = makeid(5);
    this.guid = data.guid;
    this.patientGuid = data.patientGuid;
    this.facilityName = data.facilityName;
    this.facilityAddress = data.facilityAddress;
    this.facilityNumber = data.facilityNumber;
    this.city = data.city;
    this.state = data.state;
    this.zipCode = data.zipCode;
  }
}
