import { useRpmDashboardStatistic } from 'infrastructure/hooks/rpm';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import useUser from 'utils/useUser';

const localStorageModeKey = 'preferRpmDashboardMode';
const localStorageUpdateFlagKey = (loginGuid: string) =>
  `${loginGuid}-rpmDashboardModeStatisticSent`;
const defaultMode = 'new';

export const useDashboardMode = () => {
  const { loginGuid } = useUser();
  const [mode, setMode] = useLocalStorage(localStorageModeKey, defaultMode);
  const [isStatisticSent, setStatisticSent, removeStatisticSent] =
    useLocalStorage<boolean>(localStorageUpdateFlagKey(loginGuid));
  const [_, setSearchParam] = useSearchParams();

  const { sendStatistic } = useRpmDashboardStatistic();

  const setPreferMode = (preferMode: string) => {
    setMode(preferMode);
    setSearchParam({});

    if (isStatisticSent) {
      removeStatisticSent();
    }
    sendStatistic(
      { loginGuid, type: preferMode },
      {
        onSuccess: () => {
          setStatisticSent(true);
        },
      },
    );
  };

  useEffect(() => {
    if (isStatisticSent || !mode) return;

    sendStatistic(
      { loginGuid, type: mode },
      {
        onSuccess: () => {
          setStatisticSent(true);
        },
      },
    );
  }, []);

  return {
    mode,
    setPreferMode,
  };
};
