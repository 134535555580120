import { useQuery } from '@tanstack/react-query';
import { makeid } from 'utils/helpers/makeid';

import { getItems } from './fetchers';

import type { Filters, CcmAgentItem } from './types';

export interface CcmAgentDashboardData {
  count: number;
  data: CcmAgentItem[];
}

export const useCcmAgentDashboardData = (filters: Filters) => {
  const { data, error, isLoading, isPreviousData } = useQuery({
    queryKey: ['ccmAgentDashboard', filters],
    keepPreviousData: true,
    queryFn: async () => {
      const response = await getItems(filters);
      if (response) {
        const items: CcmAgentItem[] = response.data.map((element, index) => {
          const color = (index + 1) % 2 !== 0 ? 'gray' : 'white';
          return {
            ...element,
            color,
            key: makeid(5),
            children: structuredClone([{ ...element, color }]),
          };
        });
        return {
          count: response.count,
          data: items,
        };
      }
    },
  });

  return {
    data: {
      data: data?.data,
      count: data?.count,
    },
    isLoading: isLoading || isPreviousData,
    error,
    isPreviousData,
  };
};
