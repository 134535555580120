import format from 'date-fns/format';
import React from 'react';
import PropTypes from 'prop-types';
import { Hint } from 'react-vis';
import common from 'constants/common';

const getY = (y, yScales) => {
  if (!yScales) return y;

  if (Number(y) < yScales[0]) {
    return yScales[0];
  }
  if (Number(y) > yScales[1]) {
    return yScales[1];
  }

  return y;
};

const ChartHint = ({ hoveredPoint, type, yScales, ...rest }) => {
  let date;
  switch (type) {
    case 'Glucose':
      date = format(
        new Date(parseInt(hoveredPoint.actualTime)),
        common.dateFormats.dateAndTime,
      );
      break;
    default:
      date = format(
        new Date(parseInt(hoveredPoint.x)),
        common.dateFormats.dateAndTime,
      );
      break;
  }
  return (
    <Hint
      value={{
        ...hoveredPoint,
        y: getY(hoveredPoint?.y, yScales),
      }}
      {...rest}
    >
      <div className="chart-hint">
        <p>{date}</p>
        <p>
          <strong>{type}:</strong> {hoveredPoint.y}
        </p>
      </div>
    </Hint>
  );
};

ChartHint.propTypes = {
  type: PropTypes.string.isRequired,
  yScales: PropTypes.array,
  hoveredPoint: PropTypes.shape().isRequired,
};

export default ChartHint;
