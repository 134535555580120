import i18n from 'infrastructure/i18n';
import useUser from 'utils/useUser';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import s from './styles.module.scss';
import DateColumn from './components/date-col';
import { useCellHeights } from './hooks/use-cell-heights';
import MobileCell from './mobile-cell';
import ReadingCell from './reading-cell';
import CommentCell from './comment-cell';

import type {
  TAddCommentFn,
  TRpmDashboardActivityQueryKey,
  TRpmPatientActivityQueryKey,
} from 'infrastructure/hooks/rpm';
import type { ReadingType } from 'infrastructure/enums';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';
import type {
  IActivityComments,
  IActivityData,
} from 'infrastructure/interfaces/i-rpm-patient-activity';

interface Props {
  addComment: TAddCommentFn;
  showReviewCol: boolean;
  addCommentLoading?: boolean;
  view: 'patient' | 'dashboard';
  getActivityQueryKey:
    | TRpmDashboardActivityQueryKey
    | TRpmPatientActivityQueryKey;
}

export const useActivityColumns = ({
  addComment,
  showReviewCol,
  view,
  getActivityQueryKey,
}: Props) => {
  const location = useLocation();
  const { slaReadings } = queryString.parse(location.search);
  const { rowHeights, rowRefs } = useCellHeights(getActivityQueryKey);
  const { isCriticalOrAtRiskAlertingRole, isMobileDevice } = useUser();
  const hideCommentsColumn =
    isCriticalOrAtRiskAlertingRole && view === 'dashboard';

  const activityColumnsMobile: IBaseTableColumns<IActivityData> = [
    {
      label: i18n.t('labels.time'),
      key: 'createdAt',
      width: '95px',
      sortable: !slaReadings,
      sortKey: 'createdAt',
      onCell: () => ({
        colSpan: 3,
      }),
      render: (createdAt: string, record, idx) => (
        <MobileCell
          createdAt={createdAt}
          record={record}
          idx={idx}
          addComment={addComment}
          showReviewCol={showReviewCol}
          view={view}
          getActivityQueryKey={getActivityQueryKey}
          hideCommentsColumn={hideCommentsColumn}
          slaReadings={slaReadings}
        />
      ),
    },
    {
      label: i18n.t('labels.reading'),
      key: 'readingType',
      onCell: () => ({
        colSpan: 0,
      }),
    },
    {
      label: (
        <p className={s['comments-title']}>
          {showReviewCol && <span>{i18n.t('labels.reviewed')}</span>}
        </p>
      ),
      key: 'comments',
      width: '80px',
      onCell: () => ({
        colSpan: 0,
      }),
    },
  ];

  const activityColumns: IBaseTableColumns<IActivityData> = [
    {
      label: i18n.t('labels.time'),
      key: 'createdAt',
      width: '120px',
      sortable: !slaReadings,
      sortKey: 'createdAt',
      render: (createdAt: string, record, idx) => (
        <DateColumn
          createdAt={createdAt}
          globalStatus={record.globalStatus}
          idx={idx}
          view={view}
          slaDeadline={record.slaDeadline}
          slaReviewedTime={record.slaReviewedTime}
          serviceDeliveryThresholds={record.serviceDeliveryThresholds}
          height={rowHeights.get(idx)}
          deviceId={record.deviceId}
          readingId={record.id}
          getActivityQueryKey={getActivityQueryKey}
          hideCommentsColumn={hideCommentsColumn}
        />
      ),
    },
    {
      label: i18n.t('labels.reading'),
      key: 'readingType',
      width: hideCommentsColumn ? undefined : '235px',
      render: (readingType: ReadingType, record, idx) => (
        <ReadingCell
          record={record}
          idx={idx}
          view={view}
          getActivityQueryKey={getActivityQueryKey}
          hideCommentsColumn={hideCommentsColumn}
          slaReadings={slaReadings}
          readingType={readingType}
          rowRefs={rowRefs}
        />
      ),
    },
    {
      label: (
        <p className={s['comments-title']}>
          <span>{i18n.t('labels.comments')}</span>
          {showReviewCol && <span>{i18n.t('labels.reviewed')}</span>}
        </p>
      ),
      key: 'comments',
      render: (comments: IActivityComments[], record, idx) => (
        <CommentCell
          addComment={addComment}
          createdAt={record.createdAt}
          idx={idx}
          record={record}
          showReviewCol={showReviewCol}
        />
      ),
    },
  ];

  return isMobileDevice
    ? activityColumnsMobile
    : activityColumns.filter((col) =>
        hideCommentsColumn ? col.key !== 'comments' : true,
      );
};
