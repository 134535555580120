import React from 'react';
import PropTypes from 'prop-types';
import SortableColumn from 'components/atoms/sortableColumn';
import { generateFullName } from 'utils/badgeHelpers';
import { Link } from 'react-router-dom';
import { isOnboardingPatient } from 'utils/userTypeHelper';
import { onboardingStatuses } from 'constants/patient_statuses';
import { isMobile } from 'utils/deviceHelper';
import { useSelector } from 'react-redux';

import PatientStatus from './status';

const PatientsTable = ({ data, setFilters, filters }) => {
  const device = useSelector((state) => state.user.deviceType);

  return (
    <div className="report-table table-sticky-header patient-status-table-wrapper">
      <table
        cellSpacing="0"
        cellPadding="0"
        className="table-border"
        data-cy="status-report__table"
      >
        <thead>
          <tr>
            <th data-cy="status-report__table-header">Patient GUID</th>
            <th
              data-cy="status-report__table-header"
              className="report-table__created-at"
            >
              Created At Date
            </th>
            <th
              data-cy="status-report__table-header"
              className="report-table__insurance-number"
            >
              Insurance Number
            </th>
            <th data-cy="status-report__table-header">Patient Name</th>
            <th data-cy="status-report__table-header">MRN</th>
            <th data-cy="status-report__table-header">
              <SortableColumn
                title="Patient Last Reading"
                dataCy="status-report__table-sort-last-reading"
                handler={(sort) =>
                  setFilters({
                    lastReadingDateSort: sort,
                  })
                }
                sort={filters.lastReadingDateSort}
              />
            </th>
            <th data-cy="status-report__table-header">Clinic Name</th>
            <th data-cy="status-report__table-header">Patient Status</th>
            <th
              data-cy="status-report__table-header"
              className="report-table__status-update"
            >
              <SortableColumn
                title="Status Update Date"
                dataCy="status-report__table-sort-update-date"
                handler={(sort) =>
                  setFilters({
                    statusUpdatedDateSort: sort,
                  })
                }
                sort={filters.statusUpdatedDateSort}
              />
            </th>
            <th data-cy="status-report__table-header">
              <SortableColumn
                title="Days Since Last Reading"
                dataCy="status-report__table-sort-since-reading"
                handler={(sort) =>
                  setFilters({
                    lastReadingDateSort: sort,
                  })
                }
                sort={filters.lastReadingDateSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td data-label="Patient GUID">{row.guid}</td>
              <td data-label="Created At Date">{row.createdAt}</td>
              <td data-label="Insurance Number">
                {isMobile(device) && !row.insurances?.length && '-'}

                {row.insurances
                  ?.map((insurance) => insurance.number)
                  .join(', ')}
              </td>
              <td data-label="Patient Name" className="report-table__patient">
                {!isOnboardingPatient(onboardingStatuses, row.status) ? (
                  <Link to={`/patient/${row.guid}`}>
                    {generateFullName(
                      row.title,
                      row.firstName,
                      null,
                      row.lastName,
                    )}
                  </Link>
                ) : (
                  generateFullName(row.title, row.firstName, null, row.lastName)
                )}
              </td>
              <td data-label="MRN" data-cy="status-report__table-MRN">
                {row.mrn}
                {isMobile(device) && !row.mrn && '-'}
              </td>
              <td
                data-label="Patient Last Reading"
                data-cy="status-report__table-since-reading-col"
              >
                {row.lastReadingDate}
                {isMobile(device) && !row.lastReadingDate && '-'}
              </td>
              <td
                data-label="Clinic Name"
                data-cy="status-report__table-clinic-col"
              >
                {row.clinic?.legalname}
              </td>
              <PatientStatus status={row.status} />
              <td
                data-label="Status Update Date"
                data-cy="status-report__table-status-update-col"
              >
                {row.statusUpdatedDate}
              </td>
              <td data-label="Days Since Last Reading">
                {isMobile(device) && !row.lastReadingDate && '-'}
                {row.lastReadingDate
                  ? Math.round(
                      (Date.now() - Date.parse(row.lastReadingDate)) / 86400000,
                    )
                  : undefined}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

PatientsTable.defaultProps = {
  setFilters: () => {},
};

PatientsTable.propTypes = {
  data: PropTypes.array,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default PatientsTable;
