import { API_ROLES } from 'services/dataService/resources';
import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';

import type { UserType } from 'infrastructure/enums';

interface Role {
  guid: string;
  name: string;
  userType: UserType;
}

const useRoles = (userType: UserType) => {
  const { data, error, isLoading } = useQuery({
    queryKey: [userType, API_ROLES] as const,
    queryFn: async () => {
      const { data: localData, error: localError } = await dataService.getList<
        Role[]
      >(`${API_ROLES}?userType=${userType}`);

      if (localError) {
        throw new Error(localError);
      }
      return localData;
    },
  });

  return {
    roles: data,
    isLoading,
    error,
  };
};

export default useRoles;
