import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';

import type { IAdminPanelSearchAgencyResponse } from 'infrastructure/interfaces';

const aminPanelSearchAgencyFn = async (searchValue: string) => {
  const { data, error } =
    await dataService.getList<IAdminPanelSearchAgencyResponse>(
      apiUrlGenerator('admins/admin-panel/agencies', {
        legalname: searchValue,
      }),
    );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  const preparedData =
    data?.items?.map((agency) => ({
      value: agency.guid,
      label: agency.legalname,
    })) ?? [];

  if (data) {
    return { items: preparedData, count: data.count };
  }
};

export const useAminPanelSearchAgency = () => {
  const { mutate, data, isLoading, ...other } = useMutation({
    mutationFn: aminPanelSearchAgencyFn,
  });

  return {
    searchAgency: mutate,
    agencies: data,
    searchAgencyLoading: isLoading,
    ...other,
  };
};
