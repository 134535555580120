import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DateFormats, ModuleType } from 'infrastructure/enums';
import { SmsActivityLog } from 'infrastructure/classes/logs/sms-activity-log';
import { DateFormatter } from 'infrastructure/functions';

import useApi from '../use-api';

import type { DateRange } from 'infrastructure/types';
import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IPaginatedSmsLogs,
} from 'infrastructure/interfaces';
import type { Dispatch, SetStateAction } from 'react';

const smsActivityLogApi = (patientGuid: string, isCcm: boolean) => {
  let endpoint = `core/patients/${patientGuid}/messages`;
  if (isCcm) {
    endpoint = 'core/ccm/sms-responses';
  }
  return endpoint;
};

const prepareDateRange = (isCcm: boolean, dateRange?: DateRange) => {
  let startTime;
  let endTime;
  let startDate;
  let endDate;
  if (dateRange && dateRange.length) {
    if (isCcm) {
      startTime = DateFormatter({
        date: dateRange[0],
        format: DateFormats['yyyy-MM-dd HH:mm:s'],
      });
      endTime = DateFormatter({
        date: dateRange[1],
        format: DateFormats['yyyy-MM-dd HH:mm:s'],
      });
    } else {
      startDate = DateFormatter({
        date: dateRange[0],
        format: DateFormats['MM-dd-yyyy'],
      });
      endDate = DateFormatter({
        date: dateRange[1],
        format: DateFormats['MM-dd-yyyy'],
      });
    }
  }

  return {
    startTime,
    endTime,
    startDate,
    endDate,
  };
};

export interface IUseLogMeta {
  module?: ModuleType;
  dateRange?: DateRange;
}

type mergedMeta = IUseLogMeta & IPaginationMeta;

type UseLog = {
  loading: boolean;
  meta: mergedMeta;
  items: Array<SmsActivityLog>;
  setMeta: Dispatch<SetStateAction<mergedMeta>>;
};

interface IUseLogProps {
  patientGuid: string;
  meta?: IUseLogMeta & IPartialPaginationMeta;
}

export const useSmsActivityLog = (props: IUseLogProps): UseLog => {
  const options = props;
  const { patientGuid, meta: metaProps = {} } = options;

  const [meta, setMeta] = useState<mergedMeta>({
    page: 1,
    items: 15,
    totalCount: 0,
    ...metaProps,
  });

  const isCcm = meta.module === ModuleType.CCM;

  const { loadData } = useApi();

  const queryKey = [
    smsActivityLogApi(patientGuid, isCcm),
    prepareDateRange(isCcm, meta.dateRange),
    meta.page,
  ];

  const { data = [], isLoading } = useQuery({
    queryKey,
    queryFn: async () =>
      loadData<IPaginatedSmsLogs, Omit<IUseLogMeta, 'sortField'>>(
        smsActivityLogApi(patientGuid, isCcm),
        {
          sortField: JSON.stringify(meta.sortField),
          page: meta.page,
          items: meta.items,
          patientGuid: isCcm ? patientGuid : undefined,
          ...prepareDateRange(isCcm, meta.dateRange),
        },
      ).then((res) => {
        if (res) {
          const { count, data: rows } = res;

          setMeta({
            ...meta,
            totalCount: count,
          });

          return rows.map((el) => {
            return new SmsActivityLog(el);
          });
        }
      }),
    enabled: Boolean(patientGuid),
  });

  return {
    items: data,
    loading: isLoading,
    meta,
    setMeta,
  };
};
