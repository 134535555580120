import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_APPOINTMENT_COMPLETIONS } from 'services/dataService/ccmResources';

import { getAppointmentCompletionKey } from './use-patient-appointment-completion';
import { showResult } from '../utils';

import type { IAddAppointmentCompletion } from 'infrastructure/interfaces';

interface IResponse {
  created: boolean;
}

interface Props {
  onSuccess: () => void;
}

const addPatientAppointmentCompletionFn = async (
  postData: IAddAppointmentCompletion,
) => {
  const path = `${API_APPOINTMENT_COMPLETIONS}/${postData.patientGuid}`;
  const { data, error } = await dataService.createOne<IResponse>(path, {
    appointmentCompletion: postData,
  });

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useAddAppointmentCompletion = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (postData: IAddAppointmentCompletion) =>
      addPatientAppointmentCompletionFn(postData),
    onSuccess(data, variables) {
      onSuccess();
      const queryKey = getAppointmentCompletionKey(variables.patientGuid, 1);
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return { addAppointmentCompletion: mutateAsync, addingLoading: isLoading };
};
