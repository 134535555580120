import dataService from 'services/dataService';
import { API_READINGS_ACTIONS } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

export const useReadingComment = () => {
  const submitComment = async (values: {
    message: string;
    generatedByModel: string | undefined;
    originalGeneratedText: string | undefined;
    deviceId: string;
    readingId: string;
    reviewed: boolean;
  }) => {
    const { error, data } = await dataService.createOne(
      API_READINGS_ACTIONS,
      values,
    );
    if (error) {
      showResult(error);
    }
    return { data, error };
  };

  return { submitComment };
};
