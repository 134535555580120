import { useMemo, type FC } from 'react';
import { calculateCompletedPercentage } from 'utils/math';
import { useTranslation } from 'react-i18next';
import { colors } from 'constants/common';

import s from './styles.module.scss';
import Tile from './tile';
import CompletedAppointments from './completedAppointments';

import type { CcmAgentCountData } from 'infrastructure/hooks/ccmAgentDashboard/types';

interface Props {
  count: CcmAgentCountData;
}

const Count: FC<Props> = ({ count }) => {
  const total = count.totalCount;
  const currentMonthCount =
    count.appointmentCompleted.enrolledInCurrentMonth.currentMonth;
  const previousMonth =
    count.appointmentCompleted.enrolledInPreviousMonth.currentMonth;
  const completed = currentMonthCount + previousMonth;

  const completedPercent = useMemo(() => {
    return calculateCompletedPercentage(completed, total);
  }, [completed, total]);

  const { t } = useTranslation();

  return (
    <div className={s.wrapper}>
      <div className={s['tiles-wrapper']}>
        <Tile
          color={colors.dullOrange}
          count={total}
          title={t('ccmAgentDashboard.totalPatients')}
        />
        <Tile
          color={colors.dullGreen}
          count={`${completedPercent}%`}
          title={t('ccmAgentDashboard.percentCompleted')}
        />
        <Tile
          color={colors.dullGray}
          count={previousMonth}
          title={t('ccmAgentDashboard.followUpAppointmentsCompleted')}
        />
        <Tile
          color={colors.dullGray}
          count={currentMonthCount}
          title={t('ccmAgentDashboard.onboardingAppointmentsCompleted')}
        />
      </div>
      <CompletedAppointments data={count.appointmentCompleted} />
    </div>
  );
};

export default Count;
