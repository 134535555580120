import { makeid } from 'utils/helpers/makeid';

import type { IBaseLog } from 'infrastructure/interfaces';

export class BaseLog {
  key: string;
  from?: string;
  to: string;
  date: string;
  who: string;

  constructor(data: IBaseLog) {
    this.key = makeid(5);
    this.from = data.from;
    this.to = data.to;
    this.date = data.date;
    this.who = data.who;
  }
}
