import type { LoginStatus } from 'infrastructure/enums/login-status';
import type { IAgencyDoctorSelect } from 'infrastructure/interfaces';

export class AgencyDoctorSelect {
  guid: string;
  label: string;
  status: LoginStatus;

  constructor(data: IAgencyDoctorSelect) {
    this.guid = data.guid;
    this.label = data.label;
    this.status = data.record.status;
  }
}
