import classNames from 'classnames';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import { useState, type FC } from 'react';
import { convertSecondsToTime } from 'utils/timeHelper';
import { format } from 'date-fns';
import common from 'constants/common';
import BaseButton from 'components/atoms/baseButton';
import {
  isAdmin,
  isCcmActionAllowedStatus,
  isPractice,
} from 'utils/userTypeHelper';
import useUser from 'utils/useUser';
import { isCcmModule, isRpmModule } from 'infrastructure/functions';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { useTranslation } from 'react-i18next';

import {
  useGetCcmInteractionTime,
  useGetRpmInteractionTime,
  useSetManualInteractionTime,
} from './hooks';
import s from './styles.module.scss';
import ManualInteractionTimeModal from '../controlPanel/manualInteractionTimeModal';

import type { ModuleType } from 'infrastructure/enums';

interface Props {
  patientGuid: string;
  module: ModuleType;
}

const InteractiveTimeNew: FC<Props> = ({ patientGuid, module }) => {
  const [manualInteractionsPopupOpen, setManualInteractionsPopupOpen] =
    useState(false);
  const { userType } = useUser();
  const { ccmInteractionTime, isFetching: isFetchCcm } =
    useGetCcmInteractionTime({
      patientGuid,
      enabled: isCcmModule(module),
    });
  const { rpmInteractionTime, isFetching: isFetchRpm } =
    useGetRpmInteractionTime({
      patientGuid,
      enabled: isRpmModule(module),
    });
  const { setManualInteractionTime, isLoading } = useSetManualInteractionTime({
    onSuccess() {
      setManualInteractionsPopupOpen(false);
    },
  });
  const { patientInfo } = usePatientInfo({ patientGuid });

  const hideAddTimeButton = isCcmModule(module)
    ? !isCcmActionAllowedStatus(patientInfo?.ccmStatus)
    : false;

  const today = new Date();
  const data = ccmInteractionTime || rpmInteractionTime;

  const { t } = useTranslation();

  return (
    <div className={s.time}>
      <BaseSpinWrapper spinning={isFetchCcm || isFetchRpm}>
        <div className={s['time-wrapper']} data-cy="interactive-time-block">
          <h4 className={classNames(s.title, s['time-title'])}>
            {t('controls.interactiveTime')}
          </h4>
          <div className={s.divider} />
          <div className={s['time-body']}>
            <div className={s['time-body-item']}>
              <p className={s['time-body-title']}>{t('controls.total')}</p>
              <div
                className={s['time-body-count']}
                data-cy="total-interactive-time"
              >
                {convertSecondsToTime(data?.totalTime)}
              </div>
            </div>
            <div className={s['time-divider']}>
              <div className={s.divider} />
              <div className={s['time-month']}>
                {format(today, common.dateFormats.interactiveTime)}
              </div>
              <div className={s.divider} />
            </div>
            <div className={s['time-body-item']}>
              <p className={s['time-body-title']}>{t('controls.clinic')}</p>
              <div
                className={s['time-body-count']}
                data-cy="clinic-interactive-time"
              >
                {convertSecondsToTime(data?.clinicTime)}
              </div>
            </div>
            {!hideAddTimeButton &&
              (isPractice(userType) || isAdmin(userType)) && (
                <BaseButton
                  label={t('labels.addTime')}
                  dataCy="add-manual-time-button"
                  onClick={() => setManualInteractionsPopupOpen(true)}
                  loading={isLoading}
                />
              )}
          </div>
        </div>
      </BaseSpinWrapper>
      <ManualInteractionTimeModal
        isOpen={manualInteractionsPopupOpen}
        toggle={setManualInteractionsPopupOpen}
        patientGuid={patientGuid}
        isRpmPanel={isRpmModule(module)}
        loading={isLoading}
        submit={setManualInteractionTime}
      />
    </div>
  );
};

export default InteractiveTimeNew;
