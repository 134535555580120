import { generateMessage } from 'utils/formatReportMessage';

export const SUCCESS_MESSAGE = 'Your request was successfully submitted';
export const FAILURE_MESSAGE = 'Something went wrong. Please check console';
export const REFRESH_MESSAGE =
  'New data just came. Please press on this notification to refresh the page';
export const successMessages = {
  changes: 'Changes were successfully saved',
  reviewed: 'This reading has been successfully reviewed',
  sendReport: 'Your report will be created. Please check your email shortly.',
  deleted: 'Successfully deleted',
  reportReady: (reportType) => generateMessage(reportType),
};
export const errorMessages = {
  report: (reportType, message) =>
    `Error when generate report ${reportType}: ${message}`,
};

export const CCM_INVALID_STATUS = `Patient's CCM status is not valid to perform this action`;
export const NOT_AUTHORIZED =
  'Error: User is not authorized to access this resource';

export const COMMENT_SUBMIT_ERROR_NO_CHANGES =
  'Cannot submit without making changes to the comment.';

export const MESSAGING_NOT_ALLOWED =
  'Messaging on Unregistered Number is temporarily not allowed.';
