import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Input from 'components/atoms/input';
import { formatRoleName } from 'utils/formatters';

const ProfessionInfo = ({ errors }) => {
  const roles = useSelector((state) => state.adminPanelUsers.roles);
  const rolesOptions = useMemo(() =>
    roles.map(({ guid, name }) => ({
      value: guid,
      label: formatRoleName(name),
    })),
  );
  const clinics = useSelector((state) => state.clinics.data);
  const clinicsOptions = useMemo(() =>
    clinics.map(({ guid, legalname }) => ({
      value: guid,
      label: legalname,
    })),
  );
  return (
    <div className="admin-patient-form-block">
      <span>Profession Info</span>
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <Input
            dataCy="users-input-clinic"
            label="Clinics"
            id="clinics"
            type="select"
            isMulti
            options={clinicsOptions}
            onChange={(e, setFieldValue) => setFieldValue('clinics', e)}
          />
          {errors.clinics ? (
            <div className="input-error">{errors.clinics}</div>
          ) : null}
        </Col>
        <Col lg="12" md="12" sm="12" xs="12">
          <Input
            dataCy="users-input-profession"
            id="professionType"
            label="Profession"
            onChange={(e, setFieldValue) =>
              setFieldValue('professionType', e.target.value)
            }
          />
          {errors.professionType ? (
            <div className="input-error">
              {errors.professionType.split('.')[0]}
            </div>
          ) : null}
        </Col>
        <Col lg="12" md="12" sm="12" xs="12">
          <Input
            dataCy="users-input-roles"
            id="roles"
            type="select"
            label="Roles"
            isMulti
            options={rolesOptions}
            onChange={(e, setFieldValue) => setFieldValue('roles', e)}
          />
          {errors.roles ? (
            <div className="input-error">{errors.roles.split('.')[0]}</div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

ProfessionInfo.propTypes = {
  errors: PropTypes.shape({
    professionType: PropTypes.string,
    clinics: PropTypes.string,
    roles: PropTypes.string,
  }).isRequired,
};

export default ProfessionInfo;
