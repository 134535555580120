import dataService from 'services/dataService';
import {
  API_CCM_MESSAGES,
  API_PATIENTS_MESSAGES,
} from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface DataProps {
  patientGuid: string;
  topic: string;
  type: 'sms';
  message: string;
  isRpmPanel: boolean;
  isPrimaryNumber: boolean;
}

export const sendSmsFn = async ({ isRpmPanel, ...other }: DataProps) => {
  const endpoint = isRpmPanel ? API_PATIENTS_MESSAGES : API_CCM_MESSAGES;
  const { error, data } = await dataService.createOne(endpoint, other);

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
