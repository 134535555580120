import i18next from 'i18next';
import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';
import { phoneNumberRegExp } from 'infrastructure/regexp';

export const clinicContactInformationValidationSchema = () =>
  createValidationSchema({
    legalname: commonValidationSchemas.string({ required: true }),
    email: commonValidationSchemas.email({ nullable: true }),
    fax: commonValidationSchemas.string({ nullable: true }),
    phone: commonValidationSchemas.string({
      nullable: true,
      matches: {
        regexp: phoneNumberRegExp,
      },
    }),
    callerId: commonValidationSchemas.string({
      fieldName: i18next.t('labels.clinicCallerID'),
      nullable: true,
      matches: {
        regexp: phoneNumberRegExp,
      },
    }),
    crmId: commonValidationSchemas.string({ nullable: true }),
  });
