import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import useTurkDashboardPatientDetails from 'utils/hooks/turkDashboard/usePatient';

import PatientAverages from './averages';
import PatientCharts from './charts';
import PatientInfo from './patientInfo/info';
import s from './styles.module.scss';

import type { FC } from 'react';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  patientGuid: string;
  dates: {
    startTime: string;
    endTime: string;
    timezoneOffset: number;
  };
}

const PatientModal: FC<Props> = ({ isOpen, toggle, patientGuid, dates }) => {
  const { data, isLoading } = useTurkDashboardPatientDetails({
    patientGuid,
    enabled: isOpen,
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={s.modal}>
      <ModalHeader toggle={toggle} className={s.header}>
        <PatientInfo patient={data} />
      </ModalHeader>
      <ModalBody className={s['modal-body']}>
        <PatientAverages patientGuid={patientGuid} dates={dates} />
        <PatientCharts
          patientGuid={patientGuid}
          devicesLoading={isLoading}
          dates={dates}
        />
      </ModalBody>
    </Modal>
  );
};

export default PatientModal;
