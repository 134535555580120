type PromiseWithResolvers<T> = ReturnType<typeof Promise.withResolvers<T>>;

export const promiseWithResolvers = <T>(): PromiseWithResolvers<T> => {
  if (typeof Promise.withResolvers !== 'undefined') {
    return Promise.withResolvers<T>();
  }

  let resolve: PromiseWithResolvers<T>['resolve'];
  let reject: PromiseWithResolvers<T>['reject'];
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  // @ts-ignore
  return { promise, resolve, reject };
};
