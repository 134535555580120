import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { useRef, useState, useMemo } from 'react';
import {
  XYPlot,
  LineSeries,
  XAxis,
  YAxis,
  DiscreteColorLegend,
  HorizontalGridLines,
  MarkSeries,
} from 'react-vis';
import common from 'constants/common';
import BaseLoader from 'components/atoms/base-loader';

import ChartHint from '../chartHint';

const yScale = common.chartYaxisScales.fev1;
const hintType = 'FEV1';

const legendItems = [
  {
    title: 'FEV1',
    color: common.chartColors.pef.main,
    strokeStyle: 'solid',
  },
  {
    title: 'FEV1 Critical',
    color: common.chartColors.pef.critical,
    strokeStyle: 'dashed',
  },
];

const normalizeChartData = (deviceData, thresholds) => {
  if (!deviceData || !thresholds) {
    return { main: [], minCritical: [] };
  }
  const normalizedData = deviceData.map((item) => ({
    value: item.data.fev1,
    createdAt: item.createdAt,
  }));

  const minCritical = normalizedData.map((item) => ({
    x: item.createdAt,
    y: thresholds.minCriticalFev1,
  }));

  const mainData = normalizedData.map((item) => ({
    x: item.createdAt,
    y: item.value,
  }));

  return {
    minCritical: {
      data: minCritical,
      stroke: common.chartColors.fev1.critical,
      strokeStyle: 'dashed',
    },

    main: {
      data: mainData,
      stroke: common.chartColors.fev1.main,
      strokeStyle: 'solid',
    },
  };
};
const Fev1Chart = ({ data, thresholds, loading }) => {
  const isHoveringOverLine = useRef({});
  const [hoveredPoint, setHoveredPoint] = useState(null);
  let chartDate = '';

  const chartData = useMemo(
    () => normalizeChartData(data, thresholds),
    [data, thresholds],
  );

  if (loading) return <BaseLoader className="loader" loading />;

  return (
    <div className="chart" data-cy="fev-chart">
      <div className="chart-header">
        <div className="chart-header-title" data-cy="fev-chart-title">
          <p>
            <span style={{ paddingRight: '0.5rem', position: 'relative' }}>
              FEV
              <span
                style={{
                  fontSize: '0.6rem',
                  position: 'absolute',
                  top: '0.5rem',
                }}
              >
                1
              </span>
            </span>
            <span className="chart-header-subtitle">(L)</span>
          </p>
          <p>Normal: &gt; {thresholds.minCriticalFev1}</p>
        </div>
        <DiscreteColorLegend items={legendItems} orientation="horizontal" />
      </div>
      <div className="chart-main">
        <XYPlot
          xType="time"
          height={common.chartHeight}
          onMouseLeave={() => {
            setHoveredPoint(null);
          }}
          yDomain={yScale}
          width={common.chartWidth}
        >
          <HorizontalGridLines tickTotal={7} />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => {
              const dayMonth = format(d, 'MMM-dd');
              if (dayMonth !== chartDate) {
                chartDate = dayMonth;
                return format(d, common.dateFormats.chartXDate);
              }
              return '';
            }}
            tickSizeOuter={5}
            tickSizeInner={0}
            tickPadding={24}
          />
          <XAxis
            attr="x"
            attrAxis="y"
            orientation="bottom"
            tickFormat={(d) => format(d, common.dateFormats.chartXTime)}
            tickTotal={15}
            tickSizeOuter={5}
            tickSizeInner={0}
          />
          <LineSeries curve={null} opacity={1} {...chartData.minCritical} />
          {chartData.main.data[0].y !== null && chartData.main.length === 1 && (
            <MarkSeries
              color={common.chartColors.pef.main}
              data={chartData.main.data}
              opacity={1}
              size={2.5}
              onNearestXY={(datapoint) => {
                setHoveredPoint(datapoint);
              }}
            />
          )}
          {chartData.main.data[0].y !== null &&
            chartData.main.data.length > 1 && (
              <LineSeries
                curve={null}
                opacity={1}
                {...chartData.main}
                onSeriesMouseOver={() => {
                  isHoveringOverLine.current[0] = true;
                }}
                onSeriesMouseOut={() => {
                  isHoveringOverLine.current[0] = false;
                }}
                onNearestXY={(datapoint) => {
                  if (isHoveringOverLine.current[0]) {
                    setHoveredPoint(datapoint);
                  }
                }}
              />
            )}
          {hoveredPoint && (
            <ChartHint hoveredPoint={hoveredPoint} type={hintType} />
          )}
          <YAxis attr="y" attrAxis="x" orientation="left" tickTotal={9} />
        </XYPlot>
      </div>
    </div>
  );
};

Fev1Chart.propTypes = {
  data: PropTypes.array,
  thresholds: PropTypes.shape(),
  loading: PropTypes.bool,
};

export default Fev1Chart;
