import type { FC } from 'react';

interface Props {
  count: number;
}

const Header: FC<Props> = ({ count }) => {
  return (
    <div className="adminPanel__header">
      <h5>
        Turk Users List: <strong>{count}</strong>
      </h5>
    </div>
  );
};

export default Header;
