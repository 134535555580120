import React, { useState } from 'react';
import BaseButton from 'components/atoms/baseButton';
import PencilIcon from 'components/atoms/icons/pencilIcon';
import BaseLoader from 'components/atoms/base-loader';
import { showResult } from 'infrastructure/hooks/utils';

import ShareIcon from '../../atoms/icons/shareIcon';
import TrashIcon from '../../atoms/icons/trashIcon';
import { downloadFileFromUrl } from '../../../utils';

type Props = {
  showForm: boolean;
  setShowForm: (data: boolean) => void;
  showDelete: boolean;
  setShowDelete: (data: boolean) => void;
  fileGuid: string;
  downloadAttachment: (data: any) => {
    url: string;
    error: any;
  };
};

const AttachmentIcons: React.FC<Props> = ({
  showForm,
  setShowForm,
  showDelete,
  setShowDelete,
  fileGuid,
  downloadAttachment,
}) => {
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const handleDownload = async () => {
    if (downloadLoading) return;

    setDownloadLoading(true);
    const { url, error } = await downloadAttachment({ guid: fileGuid });
    if (error) {
      showResult(error);
    }
    if (url) {
      downloadFileFromUrl(url);
    }
    setDownloadLoading(false);
  };

  return (
    <>
      <div className="attachment-icons">
        {downloadLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '0 0 0 15px',
            }}
          >
            <BaseLoader height={17} width={17} loading />
          </div>
        ) : (
          <BaseButton
            onClick={handleDownload}
            icon={<ShareIcon />}
            type="secondary"
          />
        )}
        <BaseButton
          icon={<PencilIcon />}
          type="secondary"
          onClick={() => {
            setShowDelete(false);
            setShowForm(!showForm);
          }}
        />
        <BaseButton
          icon={<TrashIcon stroke={showDelete} />}
          type="secondary"
          onClick={() => {
            setShowForm(false);
            setShowDelete(!showDelete);
          }}
        />
      </div>
    </>
  );
};

export default AttachmentIcons;
