import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_PATIENT_DIAGNOSES } from 'services/dataService/ccmResources';
import { useAppSelector } from 'store';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import { showResult } from 'infrastructure/hooks/utils';

import { getConditionsKey, CONDITIONS_PER_PAGE } from './use-get-conditions';

import type {
  IAddCondition,
  ICondition,
  IConditionResponse,
} from 'infrastructure/interfaces';

interface Props {
  page: number;
}

export const addConditionFn = async (postData: IAddCondition) => {
  const { data, error } = await dataService.createOne<ICondition>(
    API_PATIENT_DIAGNOSES,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useAddCondition = ({ page }: Props) => {
  const [startTime, endTime] = useAppSelector<[Date, Date] | []>(
    (state) => state.ccmPatientDetails.dates,
  );
  const queryClient = useQueryClient();

  const filter = {
    page,
    items: CONDITIONS_PER_PAGE,
    startTime: DateFormatter({
      date: startTime,
      format: DateFormats['yyyy-MM-dd HH:mm:s'],
    }),
    endTime: DateFormatter({
      date: endTime,
      format: DateFormats['yyyy-MM-dd HH:mm:s'],
    }),
  };

  const {
    mutate: addCondition,
    mutateAsync: addConditionAsync,
    isLoading,
    ...other
  } = useMutation({
    mutationFn: addConditionFn,
    onSuccess(data, variables) {
      const queryKey = getConditionsKey({
        ...filter,
        patientGuid: variables.patientGuid,
      });
      queryClient.setQueryData<IConditionResponse>(queryKey, (oldData) => {
        if (oldData && data) {
          const newData = structuredClone(oldData);
          newData.count += 1;
          newData.items.unshift(data);

          if (newData.items.length >= CONDITIONS_PER_PAGE)
            newData.items.length = CONDITIONS_PER_PAGE;

          return newData;
        }
        return oldData;
      });
      showResult();
    },
  });

  return {
    addCondition,
    addConditionAsync,
    addLoading: isLoading,
    ...other,
  };
};
