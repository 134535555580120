import ControlPanelButton from 'components/atoms/controlPanelButton';
import ResetPasswordIcon from 'components/atoms/icons/resetPassword';
import { useResetEmail } from 'utils/hooks/email/useResetEmail';

import type { FC } from 'react';

interface Props {
  email: string;
}

const AdminPanelResetEmailButton: FC<Props> = ({ email }) => {
  const { isLoading, resetPassword } = useResetEmail();
  return (
    <ControlPanelButton
      label="Send Password Reset Email"
      icon={ResetPasswordIcon}
      loading={isLoading}
      disabled={isLoading}
      handleClick={(e) => {
        e.preventDefault();
        resetPassword(email);
      }}
    />
  );
};

export default AdminPanelResetEmailButton;
