import i18next from 'i18next';
import { Languages } from 'infrastructure/enums';

export interface ILanguageItem {
  id: Languages;
  name: string;
}

export const languagesDataSource = (): ILanguageItem[] => {
  const languages = (
    Object.keys(Languages) as Array<keyof typeof Languages>
  ).map((langugae) => ({
    id: Languages[langugae],
    name: i18next.t(`languages.${Languages[langugae]}`),
  }));

  return languages;
};
