import React from 'react';
import ErrorImg from 'assets/img/error.svg';
import { Container, Card, CardBody, Row, Col, CardImg } from 'reactstrap';

const NoActiveClinic = () => (
  <Container>
    <div className="not-found">
      <Card className="card-border">
        <CardBody className="card-outer">
          <Row>
            <Col xl={6} md={6} className="not-found-text">
              <div className="not-found-text-first">
                Your Clinic Has Tech Issues
                <br />
                On Evelyn Platform.
              </div>
              <div className="not-found-text-second">
                Please Contact Your Admin.
              </div>
              <div className="not-found-text-line" />
              <div className="not-found-text-info">
                <div className="not-found-text-info-phone">
                  <span className="not-found-text-bold">Phone: </span>
                  <span>1-888-407-4108 x8</span>
                </div>
                <div className="">
                  <span className="not-found-text-bold">Headquarters: </span>
                  <br />
                  <span>
                    200 South 10th St, Suite 103, McAllen, TX 78501, US
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={6} md={6}>
              <CardImg src={ErrorImg} alt="error" />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  </Container>
);

export default NoActiveClinic;
