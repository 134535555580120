import classNames from 'classnames';

import s from './styles.module.scss';

import type { FC } from 'react';

interface RiskScoreTagProps {
  score: number;
}

const RiskScoreTag: FC<RiskScoreTagProps> = ({ score }) => {
  return (
    <div
      data-cy="risk-score"
      className={classNames(s.wrapper, {
        [s['wrapper-yellow']]: score >= 25,
        [s['wrapper-red']]: score >= 50,
      })}
    >
      <p data-cy="risk-score-label" className={s.label}>
        Risk Score:
      </p>
      <p data-cy="risk-score-value" className={s.value}>
        {score}
      </p>
    </div>
  );
};
export default RiskScoreTag;
