import PropTypes from 'prop-types';

const TextInput = ({
  id,
  title,
  setValue,
  values,
  isRequired,
  disabled,
  requiredIf,
  helpText,
}) => {
  let required = isRequired;
  if (requiredIf) {
    required = isRequired || values[requiredIf.key] === requiredIf.value;
  }

  return (
    <div className="input-wrapper" data-cy={id}>
      <label htmlFor={id}>
        {title} {required && <span className="color-red">*</span>}
      </label>
      {helpText && <p className="help-text">{helpText}</p>}
      <input
        id={id}
        className="form-control"
        type="text"
        autoComplete="off"
        disabled={disabled}
        value={values[id] || ''}
        onChange={(e) => {
          setValue({ [id]: e.target.value });
        }}
      />
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  helpText: PropTypes.string,
  setValue: PropTypes.func,
  isRequired: PropTypes.bool,
  requiredIf: PropTypes.object,
  disabled: PropTypes.bool,
  values: PropTypes.shape(),
};

export default TextInput;
