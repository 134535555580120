type ObjectWithOptionalFields<T> = {
  [K in keyof T]?: T[K];
};

export const applyFields = <T extends Record<string, any>>(
  target: T,
  source: ObjectWithOptionalFields<T>,
  withEmpty = false,
): T => {
  const result = structuredClone(target);
  const keys = Object.keys(source) as (keyof T)[];

  for (const key of keys) {
    const value = source[key];
    if (value !== undefined && value !== null && value !== '') {
      result[key] = value;
    } else if (withEmpty) {
      result[key] = value as any;
    }
  }

  return result;
};
