import { useState } from 'react';
import { Comment } from 'infrastructure/classes/comments/comment';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ModuleType } from 'infrastructure/enums';
import { generalCommentWithUndo } from 'infrastructure/functions/general-comment-with-undo';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import i18next from 'i18next';
import { API_PATIENTS_COMMENTS } from 'services/dataService/resources';

import useApi from './use-api';

import type { Dispatch, SetStateAction } from 'react';
import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  ICommentPaginatedData,
  ICreateComment,
  IDownloadAudioComment,
} from 'infrastructure/interfaces';

const generalCommentsApi = (patientGuid: string) =>
  `core/patients/${patientGuid}/comments`;

const generalAudioCommentApi = (patientGuid: string, callId: string) =>
  `core/patients/${patientGuid}/call-record/${callId}`;
export interface IUseCommentMeta {
  reverse?: boolean;
  commentType?: ModuleType;
  startTime?: number;
  endTime?: number;
}

type mergedMeta = IUseCommentMeta & IPaginationMeta;

type UseComment = {
  comments: Array<Comment>;
  commentsLoading: boolean;
  loading: boolean;
  meta: mergedMeta;
  setMeta: Dispatch<SetStateAction<mergedMeta>>;
  getAudioCommentUrl: (callId: string) => Promise<IDownloadAudioComment | void>;
  sendComment: (body: ICreateComment) => Promise<boolean>;
  refetch: () => void;
};

interface IUseCommentProps {
  meta?: IUseCommentMeta & IPartialPaginationMeta;
  patientGuid: string;
  enabled?: boolean;
}

export const useComment = (props: IUseCommentProps): UseComment => {
  const { patientGuid, meta: metaProps, enabled = true } = props;

  const [meta, setMeta] = useState<mergedMeta>({
    page: 1,
    items: 50,
    totalCount: 0,
    ...metaProps,
  });
  const { loading, isMounted, loadData, createData } = useApi({
    canAbort: true,
  });

  const queryClient = useQueryClient();

  const queryKey = [
    generalCommentsApi(patientGuid),
    meta.page,
    meta.commentType,
    meta.startTime,
    meta.endTime,
  ];

  const {
    data: comments = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: async () =>
      loadData<ICommentPaginatedData, Omit<IUseCommentMeta, 'sortField'>>(
        generalCommentsApi(patientGuid),
        {
          ...meta,
          page: meta.page,
          items: meta.items,
        },
      ).then((res) => {
        if (res) {
          const { count, data: rows } = res;

          setMeta({
            ...meta,
            totalCount: count,
          });

          return rows.map((el) => new Comment(el));
        }
      }),
    staleTime: 10000,
    enabled:
      enabled &&
      Boolean(patientGuid) &&
      Boolean(meta.commentType) &&
      meta.commentType !== ModuleType.ADMIN,
  });

  const getAudioCommentUrl = async (callId: string) => {
    const data = await loadData<IDownloadAudioComment>(
      generalAudioCommentApi(patientGuid, callId),
    );
    if (isMounted && data) {
      return data;
    }
  };

  const sendComment = async (body: ICreateComment): Promise<boolean> => {
    const { mockComment, undoTimeoutPromise } = generalCommentWithUndo(body);

    queryClient.setQueryData<Comment[]>(queryKey, (oldData) =>
      oldData ? [mockComment, ...oldData] : [mockComment],
    );

    try {
      await undoTimeoutPromise;

      const res = await createData(API_PATIENTS_COMMENTS, body);
      queryClient.setQueryData<Comment[]>(queryKey, (oldData) =>
        oldData
          ? [
              new Comment(res),
              ...oldData.filter((comment) => comment.key !== mockComment.key),
            ]
          : [new Comment(res)],
      );
      notificationController.success({
        message: i18next.t('labels.commentSubmitted'),
      });

      return true;
    } catch (error) {
      queryClient.setQueryData<Comment[]>(
        queryKey,
        (oldData) =>
          oldData?.filter((comment) => comment.key !== mockComment.key) || [],
      );

      return false;
    }
  };

  return {
    comments,
    commentsLoading: isLoading,
    loading,
    meta,
    setMeta,
    getAudioCommentUrl,
    sendComment,
    refetch,
  };
};
