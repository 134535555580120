import i18next from 'i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { ManualClinicNotesLog } from 'infrastructure/classes/logs/manual-clinic-notes-log';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

export const manualClinicNotesLogColumns =
  (): IBaseTableColumns<ManualClinicNotesLog> => {
    return [
      {
        label: i18next.t('labels.dateTime'),
        key: 'date',
        width: '120px',
        render(date) {
          return (
            <div className={s.date}>
              {DateFormatter({
                date,
                format: DateFormats['MM-dd-yyyy h:mm a'],
              })}
            </div>
          );
        },
      },
      {
        label: i18next.t('labels.electronicallySignedBy'),
        key: 'author',
        width: '250px',
        render(value) {
          return <div className={s.text}>{value}</div>;
        },
      },
      {
        label: i18next.t('labels.duration'),
        key: 'timeInMinutes',
        width: '150px',
        align: 'center',
        render(value) {
          return <div className={s.text}>{value}</div>;
        },
      },
      {
        label: i18next.t('labels.comment'),
        key: 'comment',
        width: '500px',
        responsive: ['md'],
        render(value) {
          return <div className={s.message}>{value}</div>;
        },
      },
    ];
  };
