import { makeid } from 'utils/helpers/makeid';

import type { IConsumableLog } from 'infrastructure/interfaces';
import type { DateString, Nullable } from 'infrastructure/types';

export class ConsumableLog {
  key: string;
  guid: string;
  consumableName: string;
  orderedUser: string;
  patientGuid: string;
  orderDate: DateString;
  updatedAt: DateString;
  deliveryDate: Nullable<DateString>;
  trackingNumber: Nullable<string>;
  constructor(data: IConsumableLog) {
    this.key = makeid(5);
    this.guid = data.guid;
    this.consumableName = data.consumableName;
    this.orderedUser = data.orderedUser;
    this.patientGuid = data.patientGuid;
    this.orderDate = data.orderDate;
    this.updatedAt = data.updatedAt;
    this.deliveryDate = data.deliveryDate;
    this.trackingNumber = data.trackingNumber;
  }
}
