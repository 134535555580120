import { DateFormats } from 'infrastructure/enums';
import { DateFormatter } from 'infrastructure/functions';
import { parse } from 'date-fns';
import { dateRegExp } from 'infrastructure/regexp';

import type { Languages, NumberTypes, Timezones } from 'infrastructure/enums';
import type { ILogin } from 'infrastructure/interfaces';
import type { Nullable } from 'infrastructure/types';

export class Login {
  title: Nullable<string>;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  middleName: Nullable<string>;
  birthDate?: Date;
  email: string;
  phone: Nullable<string>;
  primaryNumberType: Nullable<NumberTypes>;
  city: Nullable<string>;
  state: Nullable<string>;
  zipPostalCode: Nullable<string>;
  language: Nullable<Languages>;
  timezone: Nullable<Timezones>;
  auth0Enabled: boolean;
  constructor(data: ILogin) {
    this.auth0Enabled = data.auth0Enabled;
    this.birthDate = this.normalizeDOB(data.birthDate);
    this.city = data.city;
    this.state = data.state;
    this.zipPostalCode = data.zipPostalCode;
    this.email = data.email;
    this.title = data.title;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.middleName = data.middleName;
    this.language = data.language;
    this.primaryNumberType = data.primaryNumberType;
    this.phone = data.phone;
    this.timezone = data.timezone;
  }

  static prepareToUpdate(data: Login): ILogin {
    return {
      auth0Enabled: data.auth0Enabled,
      birthDate: data.birthDate
        ? DateFormatter({
            date: data.birthDate,
            format: DateFormats['MM-dd-yyyy'],
          })
        : undefined,
      city: data.city,
      state: data.state === '' ? null : data.state,
      zipPostalCode: data.zipPostalCode,
      email: data.email,
      title: data.title,
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
      language: data.language,
      primaryNumberType: data.primaryNumberType,
      phone: data.phone,
      timezone: data.timezone,
    };
  }

  private normalizeDOB(dob?: string) {
    if (dob) {
      return dateRegExp.test(dob)
        ? parse(dob, 'MM-dd-yyyy', new Date())
        : new Date(dob);
    }
  }
}
