const BannerProfileIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 5.25C1.5 4.00736 2.50736 3 3.75 3L7.8959 3C9.33255 3 10.5371 3.83994 11.3631 4.77596C11.9779 5.47258 12.7181 6 13.5 6L20.251 6C21.4944 6 22.5 7.00804 22.5 8.25V18.75C22.5 19.9926 21.4926 21 20.25 21H3.75C2.50736 21 1.5 19.9926 1.5 18.75L1.5 5.25ZM3.75 4.5C3.33579 4.5 3 4.83579 3 5.25L3 9H21V8.25C21 7.83511 20.6646 7.5 20.251 7.5H13.5C12.0542 7.5 10.9342 6.5569 10.2384 5.76848C9.56071 5.0005 8.73188 4.5 7.8959 4.5H3.75ZM21 10.5H3L3 18.75C3 19.1642 3.33579 19.5 3.75 19.5H20.25C20.6642 19.5 21 19.1642 21 18.75V10.5Z"
      fill="currentColor"
    />
  </svg>
);

export default BannerProfileIcon;
