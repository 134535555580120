import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { statusColor } from 'utils';
import ConditionButtonIcon from 'components/atoms/icons/conditionButtonIcon';
import classNames from 'classnames';

import CrownOutlinedIcon from '../icons/crownOutlinedIcon';

const Button = ({
  status,
  selectedStatus,
  count,
  text,
  handleClick,
  ...rest
}) => {
  const color = statusColor(status);

  return (
    <button
      onClick={() => handleClick(status)}
      className={classNames('condition-button', {
        disabled: rest.disabled,
      })}
      data-cy={`filter-readings-${status}`}
      {...rest}
    >
      <Card className="card-border card-shadow border-0 condition-button-card">
        <CardBody
          className={classNames('condition-button-card-body')}
          style={{
            borderColor: selectedStatus === status ? `${color}` : undefined,
          }}
        >
          <Row style={{ height: '100%' }}>
            <Col xs={6}>
              <div className="condition-button-text text-nowrap">
                {status === 'slaReadings' && (
                  <span className="sla-readings-icon-wrapper">
                    <CrownOutlinedIcon width={24} height={24} />
                  </span>
                )}
                <div className="condition-button-status">{text}</div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="condition-button-count-container">
                <div className="condition-button-background">
                  <ConditionButtonIcon color={color} />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: '-60%',
                      backgroundColor: color,
                      height: '1000px',
                      width: '100%',
                    }}
                  />
                </div>
                <div className="condition-button-count">{count}</div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </button>
  );
};

Button.defaultProps = {
  handleClick: () => {},
};

Button.propTypes = {
  status: PropTypes.string.isRequired,
  selectedStatus: PropTypes.string,
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

export default Button;
