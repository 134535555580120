import { DoctorSelectBox } from 'infrastructure/classes/doctor/select-box';
import { prepareDoctorName } from 'infrastructure/functions';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import useApi from '../use-api';

import type { IClinicDoctors } from 'infrastructure/interfaces';
import type { UseQueryResult } from '@tanstack/react-query';

const getClinicDoctorsApi = (clinicGUid: string) =>
  `core/clinics/${clinicGUid}/doctors`;

export interface IUseDoctorMeta {
  guid?: string;
  roleName?: string;
  fullName?: string;
  agencyGuid?: string;
  status?: string;
  noLimit?: string;
  email?: string;
}

type UseDoctor = {
  loading: boolean;
  loadClinicDoctors: () => Promise<Array<DoctorSelectBox>>;
  clinicDoctorsInfo: UseQueryResult<DoctorSelectBox[], unknown>;
};

interface IUseClinicDoctorProps {
  clinicGuid: string;
}

export const getClinicDoctorsInfoKey = (patientGuid: string) => [
  'clinicDoctor',
  patientGuid,
];

export const useClinicDoctor = (props: IUseClinicDoctorProps): UseDoctor => {
  const { clinicGuid } = props;

  const { loading, isMounted, loadData } = useApi();

  const queryClient = useQueryClient();
  const queryKey = getClinicDoctorsInfoKey(clinicGuid);

  const loadClinicDoctors = async () => {
    const data = await queryClient.fetchQuery(
      queryKey,
      () => loadData<IClinicDoctors>(getClinicDoctorsApi(clinicGuid)),
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      return data.map(
        (el) =>
          new DoctorSelectBox({
            guid: el.guid,
            name: prepareDoctorName({
              title: el.title,
              firstName: el.firstName,
              lastName: el.lastName,
            }),
          }),
      );
    }
    return [];
  };

  const clinicDoctorsInfo = useQuery({
    queryKey,
    queryFn: async () => {
      const data = await loadData<IClinicDoctors>(
        getClinicDoctorsApi(clinicGuid),
      );
      return data
        ? data.map(
            (el) =>
              new DoctorSelectBox({
                guid: el.guid,
                name: prepareDoctorName({
                  title: el.title,
                  firstName: el.firstName,
                  lastName: el.lastName,
                }),
              }),
          )
        : [];
    },
    staleTime: STALE_TIME,
    enabled: Boolean(clinicGuid),
  });

  return {
    clinicDoctorsInfo,
    loading,
    loadClinicDoctors,
  };
};
