import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { Row, Col } from 'reactstrap';
import PlusIcon from 'components/atoms/icons/plus';
import forms from 'constants/forms';
import Input from 'components/atoms/input';

const actions = [
  { value: 'enable', id: 'enable', label: 'Activate' },
  { value: 'disable', id: 'disable', label: 'Deactivate' },
  { value: 'delete', id: 'delete', label: 'Delete' },
];

const decideActions = (deviceId, deviceAction) => {
  if (deviceId === '') {
    return [{ value: 'remove', id: 'remove', label: 'Remove' }];
  }

  if (deviceAction === 'disable') {
    return actions.filter((action) => action.id !== 'disable');
  }

  return actions.filter((action) => action.id !== 'enable');
};

const Devices = ({ values, errors }) => (
  <FieldArray name="devices">
    {({ remove, push }) => (
      <div
        className="admin-patient-form-block"
        style={{ marginBottom: '10px' }}
      >
        <div className="admin-patient-form-block-add">
          <span>Devices</span>
          <span
            className="admin-patient-form__add-button"
            data-cy="admin-patient-form__add-devices-button"
            onClick={() => {
              push({
                isNew: true,
                manufacturer: '',
                deviceId: '',
                readingTypes: undefined,
                action: 'enable',
              });
            }}
          >
            <PlusIcon />
          </span>
        </div>
        {values.devices.length > 0 &&
          values.devices.map((device, index) => {
            const fieldsetErrors = errors.devices?.[index];
            return device.action === 'delete' ? null : (
              <Row key={`devices.${index}.${values.devices.length}`}>
                <Col lg="3" md="3" sm="3" xs="12">
                  <Input
                    label="Device Name"
                    id={`devices.${index}.manufacturer`}
                  />
                  {fieldsetErrors ? (
                    <div className="input-error">
                      {fieldsetErrors.manufacturer}
                    </div>
                  ) : null}
                </Col>
                <Col lg="4" md="4" sm="4" xs="12">
                  <Input
                    label="Number"
                    disabled={!values.devices[index]?.isNew}
                    id={`devices.${index}.deviceId`}
                  />
                  {fieldsetErrors ? (
                    <div className="input-error">{fieldsetErrors.deviceId}</div>
                  ) : null}
                </Col>
                <Col lg="5" md="5" sm="5" xs="12">
                  <div className="device-action">
                    <div className="action">
                      <Input
                        id={`devices.${index}.action`}
                        type="select"
                        options={decideActions(device.deviceId, device.action)}
                        onChange={(e, setFieldValue) => {
                          if (e.value === 'remove') {
                            remove(index);
                          } else {
                            setFieldValue(`devices.${index}.action`, e.value);
                          }
                        }}
                        isDotIcon
                      />
                    </div>
                  </div>
                  <div className="admin-patient-form-block-remove-dropdown">
                    <Input
                      label="Reading Types"
                      id={`devices.${index}.readingTypes`}
                      type="select"
                      isMulti
                      options={forms.readingTypes}
                      onChange={(e, setFieldValue) => {
                        if (!e.length) {
                          setFieldValue(
                            `devices.${index}.readingTypes`,
                            undefined,
                          );
                        } else {
                          setFieldValue(`devices.${index}.readingTypes`, e);
                        }
                      }}
                    />
                    {fieldsetErrors?.readingTypes ||
                    !device?.readingTypes?.length ? (
                      <div className="input-error">
                        {fieldsetErrors?.readingTypes}
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Row>
            );
          })}
      </div>
    )}
  </FieldArray>
);

Devices.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
};

export default Devices;
