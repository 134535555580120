import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

import type { TSchema } from 'infrastructure/functions';

export const MAX_CHARACTERS = 50;

export const allergyLogValidationSchema = () =>
  createValidationSchema({
    allergicResponse: commonValidationSchemas.string({ required: true }),
    allergy: commonValidationSchemas
      .string({ required: true })
      .max(MAX_CHARACTERS),
    epipenRequired: commonValidationSchemas.boolean().default(false),
  });

export type Values = TSchema<ReturnType<typeof allergyLogValidationSchema>>;
