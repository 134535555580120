import type { FC } from 'react';

type Props = {
  label: string;
  value: number;
};

const LabelValueElement: FC<Props> = ({ label, value }) => {
  return (
    <div className="turk-configuration--label-value-element">
      <div className="turk-configuration--label">{label}:</div>
      <div className="turk-configuration--value">{value}</div>
    </div>
  );
};

export default LabelValueElement;
