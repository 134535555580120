import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import withLoader from 'components/organisms/withLoader';
import EditButton from 'components/atoms/editButton';
import { Card, CardBody, Col, Row } from 'reactstrap';

import PersonalInfo from './components/personalInfo';
import ProfessionInfo from './components/professionInfo';
import Registration from './components/registration';
import Address from './components/address';
import Emr from './components/emr';
import IRS from './components/irs';

const User = () => {
  const {
    adminPanelUsers: { getUser },
  } = useDispatch();
  const { userGuid } = useParams();
  useEffect(() => {
    getUser(userGuid);
  }, [getUser]);

  const doctor = useSelector((state) => state.adminPanelUsers.user);
  const { getUser: doctorDetailsLoading } = useSelector(
    (state) => state.loading.effects.adminPanelUsers,
  );
  return (
    <div className="admin-patient">
      <Card className="card-border">
        <CardBody>
          {withLoader(
            doctorDetailsLoading,
            <>
              {doctor && (
                <>
                  <div className="admin-patient-header">
                    <span>Clinic's User Page</span>
                    <div className="admin-patient-header-actions">
                      <div
                        className="admin-patient-header-actions-status badge"
                        data-cy="admin-user-header__status"
                      >
                        {doctor.status
                          ? doctor.status.charAt(0).toUpperCase() +
                            doctor.status.slice(1)
                          : 'Active'}
                      </div>
                      <div className="admin-patient-header-actions-edit">
                        <Link
                          data-cy="admin-patient-header__edit"
                          to={`/admin/user/${doctor.guid}/edit`}
                        >
                          <EditButton data={doctor} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="admin-user-profile-block admin-patient-block">
                    <Row>
                      <Col>
                        <PersonalInfo data={doctor} />
                        <Address data={doctor} />
                        <Emr data={doctor} />
                      </Col>
                      <Col>
                        <ProfessionInfo data={doctor} />
                        <Registration data={doctor} />
                        <IRS data={doctor} />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </>,
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default User;
