import i18next from 'i18next';
import { ClaimStatus } from 'infrastructure/enums';

export interface IClaimStatusItem {
  id: ClaimStatus;
  name: string;
}

export const claimStatusesDataSource = (): IClaimStatusItem[] => {
  return [
    {
      id: ClaimStatus.FeeForService,
      name: i18next.t('claimStatuses.feeForService'),
    },
    {
      id: ClaimStatus.PMPM,
      name: i18next.t('claimStatuses.pmpm'),
    },
    {
      id: ClaimStatus.ValueBased,
      name: i18next.t('claimStatuses.valueBased'),
    },
    {
      id: ClaimStatus.Grant,
      name: i18next.t('claimStatuses.grant'),
    },
    {
      id: ClaimStatus.NotBillable,
      name: i18next.t('claimStatuses.notBillable'),
    },
  ];
};
