import React from 'react';
import PropTypes from 'prop-types';
import common from 'constants/common';

const ArrowIcon = ({ color }) => {
  let fillColor = '#2C2543';
  switch (color) {
    case 'green':
      fillColor = common.commonColors.green;
      break;
    case 'red':
      fillColor = common.commonColors.red;
      break;
    default:
      fillColor = common.commonColors.dargGray;
      break;
  }
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0369 0.571805L18.1618 7.7054C18.308 7.85183 18.308 8.09065 18.1618 8.23802L11.0369 15.4176C10.2704 16.19 9.02319 16.194 8.25669 15.4266C7.49019 14.6592 7.49019 13.4064 8.25669 12.634L10.9911 9.87855L2.14653 9.90712C1.11306 9.91046 0.271579 9.06795 0.271579 8.02988C0.271579 6.9918 1.11306 6.14385 2.14653 6.14051L10.9912 6.11194L8.25673 3.37416C7.49023 2.60672 7.49023 1.35395 8.25673 0.581563C9.02245 -0.190823 10.2704 -0.194855 11.0369 0.571805Z"
        fill={fillColor}
      />
    </svg>
  );
};

ArrowIcon.propTypes = {
  color: PropTypes.string,
};

export default ArrowIcon;
