import React from 'react';
import { Container } from 'reactstrap';
import AdminHeader from 'components/organisms/adminHeader';
import AdminAgencyDoctorCreateBlock from 'components/templates/admin/hhaDoctorAdd';

const AdminAgencyDoctorCreate = () => (
  <Container>
    <AdminHeader />
    <AdminAgencyDoctorCreateBlock />
  </Container>
);

export default AdminAgencyDoctorCreate;
