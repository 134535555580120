/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from 'prop-types';

const SelectInput = ({
  id,
  title,
  setValue,
  values,
  isRequired,
  disabled,
  requiredIf,
  choices,
  helpText,
}) => {
  let required = isRequired;
  if (requiredIf) {
    required = isRequired || values[requiredIf.key] === requiredIf.value;
  }

  return (
    <div className="input-wrapper" data-cy={id}>
      <label htmlFor={id}>
        {title} {required && <span className="color-red">*</span>}
        {helpText && <p className="help-text">{helpText}</p>}
      </label>
      <select
        id={id}
        className="form-control"
        type="select"
        disabled={disabled}
        value={values[id]}
        onChange={(e) => {
          setValue({ [id]: e.target.value });
        }}
      >
        <option selected value={null} />
        {choices.map((choice) => {
          return <option value={choice}>{choice}</option>;
        })}
      </select>
    </div>
  );
};

SelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  helpText: PropTypes.string,
  choices: PropTypes.array,
  setValue: PropTypes.func,
  isRequired: PropTypes.bool,
  requiredIf: PropTypes.object,
  disabled: PropTypes.bool,
  values: PropTypes.shape(),
};

export default SelectInput;
